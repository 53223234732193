import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPEditor } from "@/components/TPEditor/TPEditor";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
    EmailTemplateInputDTO,
    EmailTemplateInputDTOValidator,
} from "@/models/EmailTemplates/EmailTemplateInputDTO";
import { EmailTemplateViewModel } from "@/models/EmailTemplates/EmailTemplateModels";
import {
    SequenceGeneratorSequencesNameEnum, TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { EmailTemplateService } from "@/services/EmailTemplateService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { OutboundMailAccountService } from "@/services/OutboundMailAccountService";
import { SequenceService } from "@/services/SequenceService";
import { FC, useEffect, useRef, useState } from "react";
import { MultilingualTextBoxEvents } from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";
import { CustomSpan } from "@/components/misc/CustomSpan";
import {
    TPStyledForm,
    TPStyledFormButtonFooter,
    TPStyledFormRow,
    TPStyledInnerInputForm,
    TPStyledSubtitle,
} from "@/components/styled/StyledForms";
import allThemes from "@/assets/styles/theme";
import { TPOptionButtonPairStyled } from "@/components/bootstrap/forms/submit/TPOptionButtonPair";
import { areStringsValid } from "@/helpers/StringHelper";
import { extractTextFromHTML } from "@/helpers/ExtractTextFromHTML";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TaskService } from "@/services/TaskService";
import TPTextBoxSelectEmail from "./Components/TPTextBoxSelectEmail";



export const RESOURCE_SET: string = "EmailTemplatesInsertUpdateComponent";
const MULTILANG_TABLE_NAME: string = "EMailTEmplate";
const MULTILANG_DESC_FIELD: string = "Description_EMTE";
const FILE_NAME: string = "EmailTemplatesInsertUpdate.tsx";

type InsertUpdateProps = {
    mode: string;
    recordId: string;
    languageId: string;
    callBackResult: Function;
    preview?: boolean;
    caseNumber?: string;
    handleCaseNumber?: any;
    referenceId?: any;
    setReferenceId: Function;
};

type screenStateProps = {
    id: string;
    idAccount: string;
    description: string;
    isActive: boolean;
    isAddable: boolean;
    attachCaseInformation: number;
    descriptionLanguageList: Array<RecordLocalizedModel>;
    subjectLanguageList: Array<RecordLocalizedModel>;
    bodyLanguageList: Array<RecordLocalizedModel>;
    to: string;
    cc: string;
    bcc: string;
    subject: string;
    languageId: string;
    tagGroup: string;
    tag: string;
    body: string;
    [key: string]: any;
    recordLanguageList: Array<TPKeyValue>;
    idErrorMessage: string;
    idAccountErrorMessage: string;
    descriptionErrorMessages: Array<string>;
    attachCaseInformationErrorMessage: string;
    toErrorMessage: string;
    ccErrorMessage: string;
    bccErrorMessage: string;
    languageIdErrorMessage: string;
    bodyErrorMessage: string;
    subjectErrorMessage: string;
    tagGroupErrorMessage: string;
    tagErrorMessage: string;
    nameErrorMessages: Array<string>;
};

const EmailTemplatesInsertUpdate: FC<InsertUpdateProps> = ({
    mode,
    recordId,
    callBackResult,
    languageId,
    preview,
    caseNumber,
    handleCaseNumber,
    referenceId,
    setReferenceId,
}) => {
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [bodyLabel, setBodyLabel] = useState("");
    const [parametersSectionLabel, setParametersSectionLabel] = useState("");
    const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
    const [emailAccountLabel, setEmailAccountLabel] = useState("");
    const [caseNumberLabel, setCaseNumberLabel] = useState("");
    const [attachCaseInformationLabel, setAttachCaseInformationLabel] =
        useState("");
    const [previewLabel, setPreviewLabel] = useState<string>("");
    const [enterAValue, setEnterAValue] = useState<string>("");
    const [toLabel, setToLabel] = useState("");
    const [ccLabel, setCcLabel] = useState("");
    const [bccLabel, setBccLabel] = useState("");
    const [subjectLabel, setSubjectLabel] = useState("");
    const [languageLabel, setLanguageLabel] = useState("");
    const [isAddableLabel, setIsAddableLabel] = useState("");
    const [isActiveLabel, setIsActiveLabel] = useState("");
    const [idLabel, setIdLabel] = useState("");
    const [emailAccountOptions, setEmailAccountOptions] = useState<Array<TPKeyValue>>([]);
    const [attachmentOptions, setAttachmentOptions] = useState<Array<TPKeyValue>>([]);
    const [storeResponse, setStoreResponse] = useState<any>(null);
    const [previewLoading, setPreviewLoading] = useState(false);
    const [languageOptions, setLanguageOptions] = useState<Array<TPKeyValue>>([]);
    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [realMode, setRealMode] = useState(mode);
    const [realRecordId, setRealRecordId] = useState(recordId);
    const [templateDetailLabel, setTemplateDetailLabel] = useState("");
    const editorSubjectRef = useRef<HTMLTextAreaElement>(null);
    const [resourcesLoaded, setResourcesLoaded] = useState<boolean>(false);
    const [defaultLanguageIndex, setDefaultLanguageIndex] = useState<number>(0);
    const nameInputRef = useRef<MultilingualTextBoxEvents>();

    let screenStateInitialState: screenStateProps = {
        id: "",
        idAccount: "",
        languageId: "",
        description: "",
        isActive: false,
        isAddable: false,
        attachCaseInformation: 0,
        to: "",
        cc: "",
        bcc: "",
        subject: "",
        body: "",
        recordLanguageList: [],
        idErrorMessage: "",
        idAccountErrorMessage: "",
        attachCaseInformationErrorMessage: "",
        toErrorMessage: "",
        ccErrorMessage: "",
        bccErrorMessage: "",
        languageIdErrorMessage: "",
        bodyErrorMessage: "",
        subjectErrorMessage: "",
        nameErrorMessages: [],
        descriptionLanguageList: [],
        subjectLanguageList: [],
        bodyLanguageList: [],
        descriptionErrorMessages: [],
        tagGroup: "",
        tag: "",
        tagErrorMessage: "",
        tagGroupErrorMessage: "",
    };

    const [screenState, setScreenState] = useState<screenStateProps>(
        screenStateInitialState
    );

    const editorRef = useRef<any>(null);

    const loadResourcesAndLoadEmailTemplatesInfo = async () => {
        let i: number;
        setSaveButtonLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"));
        setCaseNumberLabel(await TPI18N.GetText("CaseViewerComponent", "TitleLabel"));
        setBodyLabel(await TPI18N.GetText(RESOURCE_SET, "BodyLabel"));
        setIdLabel(await TPI18N.GetText(RESOURCE_SET, "IdLabel"));
        setIsActiveLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ShowAsActive"));
        setIsAddableLabel(await TPI18N.GetText(RESOURCE_SET, "IsAddableLabel"));
        setCancelButtonLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"));
        setParametersSectionLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "ParametersSectionLabel"));
        setDescriptionSectionLabel(await TPI18N.GetText(RESOURCE_SET, "DescriptionSectionLabel"));
        setEmailAccountLabel(await TPI18N.GetText(RESOURCE_SET, "EmailAccountLabel"));
        setAttachCaseInformationLabel(await TPI18N.GetText(RESOURCE_SET, "AttachCaseInformationLabel"));
        setToLabel(await TPI18N.GetText(RESOURCE_SET, "ToLabel"));
        setCcLabel(await TPI18N.GetText(RESOURCE_SET, "CcLabel"));
        setBccLabel(await TPI18N.GetText(RESOURCE_SET, "BccLabel"));
        setSubjectLabel(await TPI18N.GetText(RESOURCE_SET, "SubjectLabel"));
        setLanguageLabel(await TPI18N.GetText(RESOURCE_SET, "LanguageLabel"));
        setTemplateDetailLabel(await TPI18N.GetText(RESOURCE_SET, "TemplateDetail"));
        setPreviewLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Preview"))
        setEnterAValue(await TPI18N.GetText(TPGlobal.globalResourceSet, "EnterAValue"))

        if (realMode === "Update") {
            await getEmailTemplateById(realRecordId);
        }

        if (realMode === "Insert") {
            let newInsertUpdateState = { ...screenState };
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }
            setScreenState(newInsertUpdateState);
            setIsLoadingScreen(false);
        }

        let serviceClient = new OutboundMailAccountService();
        let expectedCodes: Array<number> = [200, 404];
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getAllMailAccounts(
                false,
                true,
                expectedCodes
            );
            let newEmailAccountListState: Array<TPKeyValue> = [];
            responseRequest.forEach((item: any) => {
                newEmailAccountListState.push({
                    key: item.idAccount,
                    value: item.description,
                    ...item,
                });
            });
            newEmailAccountListState.unshift({ key: "", value: "--" });
            setEmailAccountOptions(newEmailAccountListState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${FILE_NAME} getAllMailAccounts ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${FILE_NAME} getAllMailAccounts ex`);
            setIsLoadingScreen(false);
        }

        let _serviceClient = new EmailTemplateService();
        let _expectedCodes: Array<number> = [200, 404];
        try {
            let responseRequest = await _serviceClient.getLanguagesOption(
                "C",
                false,
                true,
                _expectedCodes
            );
            let languageOptions = responseRequest.map(
                (template: EmailTemplateViewModel) => ({
                    key: template.id,
                    value: template.name,
                })
            );

            languageOptions.unshift({
                key: "--",
                value: "--",
            });

            setLanguageOptions(languageOptions);
        } catch (error) {
            console.error("error", error);
        }

        let newAttachmentOptions: Array<TPKeyValue> = [
            {
                key: "0",
                value: await TPI18N.GetText(RESOURCE_SET, "NoneItemKeyValue"),
            },
            {
                key: "1",
                value: await TPI18N.GetText(RESOURCE_SET, "CaseDetailsItemKeyValue"),
            },
            {
                key: "2",
                value: await TPI18N.GetText(
                    RESOURCE_SET,
                    "CaseDetailsAndTaskDetailsKeyValue"
                ),
            },
        ];
        setAttachmentOptions(newAttachmentOptions);
        setIsLoadingScreen(false);
        setResourcesLoaded(true);
    };

    const handlePreviewAPI = () => {
        if (caseNumber) {
            setPreviewLoading(true);
            const fetchData = async () => {
                let taskService = new TaskService();
                let expectedCodes: Array<number> = [200];
                const data = {
                    EmailTemplateId: recordId,
                    LanguageId: languageId,
                    CaseId: Number(caseNumber),
                    TaskId: null,
                    UserSessionGuid: TPGlobal.currentUserGuid,
                    RemoveDecisionTaskArea: false,
                };
                try {
                    let responseRequest = await taskService.renderEmailTemplate(
                        data,
                        false,
                        true,
                        expectedCodes
                    );
                    setStoreResponse(responseRequest);
                } catch (error) {
                    console.error("Error:", error);
                } finally {
                    setPreviewLoading(false);
                }
            };
            fetchData();
        }
    };

    const handleCase = (e: any) => {
        handleCaseNumber(e.target.value);
    };
    const handleInputTextChange = async (propertyName: string, newValue: any) => {
        if (!resourcesLoaded) return;
        if (propertyName === "tagGroup") {
            setScreenState({
                ...screenState,
                [propertyName]: newValue,
                [propertyName + "ErrorMessage"]: "",
            });
        }

        setScreenState({
            ...screenState,
            [propertyName]: newValue,
            [propertyName + "ErrorMessage"]: "",
        });
    };

    const handleIsActiveChange = () => {
        let newInsertUpdateState = { ...screenState };
        newInsertUpdateState.isActive = !screenState.isActive;
        setScreenState(newInsertUpdateState);
    };
    const handleIsAddableChange = () => {
        let newInsertUpdateState = { ...screenState };
        newInsertUpdateState.isAddable = !screenState.isAddable;
        setScreenState(newInsertUpdateState);
    };

    const handleOnOkButtonClick = async () => {
        if (nameInputRef.current?.isInvalid()) {
            nameInputRef.current?.markAsTouched();
            return;
        }

        const languages = nameInputRef.current?.getValue();
        const mainLanguage = languages?.find(
            ({ id }) => id === TPGlobal.TPClientDefaultLanguage
        );
        let isInValid: boolean = false;
        let i: number;
        let n: number;

        screenState.body = screenState.body.replace(
            /<img [^>]*img_blobid="([^"]+)"[^>]*style="[^"]*width:\s*([^;]+)px;\s*height:\s*([^;]+)px[^"]*"[^>]*>/g,
            (match, blobid, width, height) => {
                return `[ImageEmbedded|${blobid}|${width}|${height}]`;
            }
        );

        let recordInputDTO: EmailTemplateInputDTO = {
            id: realMode === "Insert" ? referenceId : recordId,
            languageId: screenState.languageId,
            description: mainLanguage?.value!,
            idAccount: screenState.idAccount,
            to: screenState.to,
            cc: screenState.cc,
            bcc: screenState.bcc,
            subject: TPGlobal.stringToUTF8(screenState.subject).toString(),
            body: TPGlobal.stringToUTF8(
                extractTextFromHTML(screenState.body) ?? ""
            ).toString(),
            isAddable: screenState.isAddable,
            attachCaseInformation: screenState.attachCaseInformation,
            isActive: screenState.isActive,
            descriptionLocalizedValues: [
                {
                    languageId: mainLanguage?.id!,
                    localizedValue: mainLanguage?.value ?? "",
                    order: 1,
                },
                ...languages!
                    .filter(({ id }) => id !== TPGlobal.TPClientDefaultLanguage)
                    .map(({ id, value }, order) => ({
                        languageId: id,
                        localizedValue: value ?? "",
                        order: order + 2,
                    })),
            ],
        };

        recordInputDTO.descriptionLocalizedValues =
            recordInputDTO.descriptionLocalizedValues.filter(
                (s) => s.localizedValue != ""
            );

        let inputDTOValidator = new EmailTemplateInputDTOValidator();
        let resultValidator = inputDTOValidator.validate(recordInputDTO);

        if (!TPGlobal.TPIsEmpty(resultValidator)) {
            let newInsertUpdateState = { ...screenState };
            if (resultValidator.id) {
                newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
                    resultValidator.id
                );
            } else {
                newInsertUpdateState.idErrorMessage = "";
            }
            if (resultValidator.idAccount) {
                newInsertUpdateState.idAccountErrorMessage = await TPI18N.GetResource(
                    resultValidator.idAccount
                );
            } else {
                newInsertUpdateState.idAccountErrorMessage = "";
            }
            if (resultValidator.languageId) {
                newInsertUpdateState.languageIdErrorMessage = await TPI18N.GetResource(
                    resultValidator.languageId
                );
            } else {
                newInsertUpdateState.languageIdErrorMessage = "";
            }
            if (resultValidator.subject) {
                newInsertUpdateState.subjectErrorMessage = await TPI18N.GetResource(
                    resultValidator.subject
                );
            } else {
                newInsertUpdateState.subjectErrorMessage = "";
            }
            newInsertUpdateState = {
                ...newInsertUpdateState,
                body: newInsertUpdateState.body.replace(/<[^>]*>/g, ""),
            };
            if (newInsertUpdateState.body === "") {
                resultValidator = {
                    ...resultValidator,
                    body: "EmailTemplateInputDTOValidator|InputDTOBodyEmpty",
                };
                if (resultValidator.body) {
                    newInsertUpdateState.bodyErrorMessage = await TPI18N.GetResource(
                        resultValidator.body
                    );
                }
            } else {
                newInsertUpdateState.bodyErrorMessage = "";
            }
            setScreenState(newInsertUpdateState);
            isInValid = true;
        }

        const fixedDto = {
            ...recordInputDTO,
            body: TPGlobal.stringToUTF8(screenState.body).toString(),
        };

        if (!isInValid) {
            if (realMode === "Insert") {
                await insertEmailTemplate(fixedDto);
            } else {
                await updateEmailTemplate(fixedDto);
            }
        }
    };

    const insertEmailTemplate = async (inputDTO: EmailTemplateInputDTO) => {
        let serviceClient = new EmailTemplateService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.insertEmailTemplate(
                inputDTO,
                true,
                true,
                expectedCodes
            );
            if (responseRequest.responseResult) {
                callBackResult({
                    result: "save_general_insert_and_change_mode",
                    recordId: responseRequest.responseData.keyList[0].value,
                    recordDescription: inputDTO.description,
                });
                setRealRecordId(responseRequest.responseData.keyList[0].value);
                setRealMode("Update");
            }
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${FILE_NAME} insertEmailTemplate ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${FILE_NAME} insertEmailTemplate ex`);
            setIsLoadingScreen(false);
        }
    };

    const updateEmailTemplate = async (inputDTO: EmailTemplateInputDTO) => {
        let serviceClient = new EmailTemplateService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.updateEmailTemplate(
                inputDTO,
                true,
                true,
                expectedCodes
            );

            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                callBackResult({
                    result: "save_general_update",
                    recordId: inputDTO.id,
                });
            }
        } catch (error) {
            TPLog.Log(`Error ${FILE_NAME} update ex`, TPLogType.ERROR, error);
            console.error(`Error ${FILE_NAME} update ex`);
            setIsLoadingScreen(false);
        }
    };

    const getEmailTemplateById = async (pRecordId: string) => {
        let serviceClient = new EmailTemplateService();
        let expectedCodes: Array<number> = [200];
        let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
        let i: number;
        let j: number;

        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getEmailTemplateById(
                pRecordId,
                languageId,
                false,
                true,
                expectedCodes
            );
            let recordInfo: EmailTemplateViewModel;
            recordInfo = { ...responseRequest };

            let newScreenState = { ...screenState };
            newScreenState.recordLanguageList = [];
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newScreenState.recordLanguageList.push(keyValueElement);
            }
            newScreenState.attachCaseInformation = recordInfo.attachCaseInformation;
            newScreenState.languageId = recordInfo.languageId;
            newScreenState.bcc = recordInfo.bcc;
            newScreenState.body = recordInfo.body;
            newScreenState.cc = recordInfo.cc;
            newScreenState.recordLanguageList[defaultLanguageIndex].value =
                recordInfo.description;
            newScreenState.id = recordInfo.id;
            newScreenState.idAccount = recordInfo.idAccount;
            newScreenState.isActive = recordInfo.isActive;
            newScreenState.isAddable = recordInfo.isAddable;
            newScreenState.subject = recordInfo.subject;
            newScreenState.to = recordInfo.to;

            recordLanguagesList = await getRecordLanguageList(pRecordId);
            if (recordLanguagesList.length == 0) {
                TPLog.Log(
                    `Error ${FILE_NAME} getFunctionById getRecordLanguageList`,
                    TPLogType.ERROR,
                    "recordLanguagesList is empty"
                );
                console.error(
                    `Error ${FILE_NAME} getFunctionById getRecordLanguageList is empty`
                );
                setIsLoadingScreen(false);
                return;
            }
            for (i = 0; i <= newScreenState.recordLanguageList.length - 1; i++) {
                for (j = 0; j <= recordLanguagesList.length - 1; j++) {
                    if (
                        newScreenState.recordLanguageList[i].key.toLowerCase() ==
                        recordLanguagesList[j].languageId.toLowerCase()
                    ) {
                        newScreenState.recordLanguageList[i].value =
                            recordLanguagesList[j].recordDescription;
                        break;
                    }
                }
            }
            setScreenState(newScreenState);
            setIsLoadingScreen(false);
            return;
        } catch (error) {
            TPLog.Log(
                `Error ${FILE_NAME} getEmailTemplateById ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${FILE_NAME} getEmailTemplateById ex`);

            setIsLoadingScreen(false);
            return null;
        }
    };

    const getRecordLanguageList = async (
        pRecordId: string
    ): Promise<Array<MultilanguageFieldValueViewModel>> => {
        let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
        let expectedCodes: Array<number> = [200];

        try {
            let responseRequest =
                await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
                    MULTILANG_TABLE_NAME,
                    MULTILANG_DESC_FIELD,
                    pRecordId,
                    false,
                    true,
                    expectedCodes
                );

            let recordInfoLanguage: Array<MultilanguageFieldValueViewModel>;
            recordInfoLanguage = [...responseRequest];
            return recordInfoLanguage;
        } catch (error) {
            TPLog.Log(
                `Error ${FILE_NAME} getRecordLanguageList ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${FILE_NAME} getRecordLanguageList ex`);
            return [];
        }
    };

    const handleCancelButtonClick = () => {
        callBackResult({ result: "cancel_general", recordId: realRecordId });
    };

    useEffect(() => {
        if (!resourcesLoaded) {
            loadResourcesAndLoadEmailTemplatesInfo();
            realMode === "Insert" && generalAutomaticId();
        }
    }, [storeResponse]);

    useEffect(() => {
        const updateDefaultLanguageIndex = () => {
            const index = TPGlobal.TPClientAvailableLanguages.findIndex(
                (item) => item.id === TPGlobal.TPClientDefaultLanguage
            );
            setDefaultLanguageIndex(index >= 0 ? index : 0);
        };

        updateDefaultLanguageIndex();
    }, [TPGlobal.TPClientAvailableLanguages, TPGlobal.TPClientDefaultLanguage]);

    const generalAutomaticId = async () => {
        let serviceClient = new SequenceService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.generalAutomaticId(
                false,
                true,
                expectedCodes,
                SequenceGeneratorSequencesNameEnum.SQEMTE
            );

            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                let result = responseRequest?.responseData?.data[0]?.sequenceCode;
                setReferenceId(result);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${FILE_NAME} updatetFunction ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${FILE_NAME} updatetFunction ex`);
            setIsLoadingScreen(false);
        }
    };

    return (
        <TPLoadingOverlay wrapperStyle={{ padding: 0 }} active={isLoadingScreen || previewLoading}>
            <div>
                {!preview ? (
                    <TPStyledForm className="pb-3">
                        <TPStyledInnerInputForm>
                            <TPStyledSubtitle>{descriptionSectionLabel}</TPStyledSubtitle>
                            <TPStyledFormRow>
                                <TPTextBox
                                    id="email-templates-id-input"
                                    disabled
                                    labelText={idLabel}
                                    isMandatory
                                    value={realMode === "Insert" ? referenceId : recordId}
                                    onChange={() => { }}
                                    maxLength={20}
                                    errorMessage={screenState.idErrorMessage}
                                />
                            </TPStyledFormRow>
                            <TPStyledFormRow>
                                <MultilingualTextBox
                                    id="email-templates-desc-input"
                                    labels={{ inputLabel: descriptionSectionLabel }}
                                    ref={nameInputRef}
                                    value={screenState.recordLanguageList?.map(
                                        ({ key, value }) => ({ id: key, value })
                                    )}
                                />
                            </TPStyledFormRow>
                        </TPStyledInnerInputForm>
                        <TPStyledInnerInputForm>
                            <TPStyledSubtitle>{parametersSectionLabel}</TPStyledSubtitle>
                            <TPStyledFormRow>
                                <TPSelect
                                    id="email-templates-account-input"
                                    isMandatory={true}
                                    labelText={emailAccountLabel}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleInputTextChange("idAccount", e.target.value)
                                    }
                                    dataSource={emailAccountOptions}
                                    value={screenState.idAccount}
                                    errorMessage={screenState.idAccountErrorMessage}
                                />
                                <TPSelect
                                    id="email-templates-attach-case-info"
                                    labelText={attachCaseInformationLabel}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleInputTextChange(
                                            "attachCaseInformation",
                                            e.target.value
                                        )
                                    }
                                    dataSource={attachmentOptions}
                                    value={String(screenState.attachCaseInformation)}
                                    errorMessage={screenState.attachCaseInformationErrorMessage}
                                />
                            </TPStyledFormRow>
                            <TPStyledFormRow>

                                <TPTextBoxSelectEmail
                                    id={"to"}
                                    ico={TPIconTypes.ioMdPeople}
                                    labelText={toLabel}
                                    isMandatory={true}
                                    height={"38px"}
                                    marginBottom={"1px"}
                                    placeholder={enterAValue}
                                    value={screenState.to}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleInputTextChange("to", e)
                                    }
                                    error={ screenState.toErrorMessage }
                                />

                                <TPTextBoxSelectEmail
                                    id={"cc-input"}
                                    ico={TPIconTypes.ioMdPeople}
                                    labelText={ccLabel}
                                    value={screenState.cc}
                                    placeholder={enterAValue}
                                    height={"38px"}
                                    marginBottom={"1px"}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleInputTextChange("cc", e)
                                    }
                                    error={screenState.ccErrorMessage}
                                />

                            </TPStyledFormRow>
                            <TPStyledFormRow>

                                <TPTextBoxSelectEmail
                                    id={"bcc-input"}
                                    ico={TPIconTypes.ioMdPeople}
                                    labelText={bccLabel}
                                    value={screenState.bcc}
                                    placeholder={enterAValue}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleInputTextChange("bcc", e)
                                    }
                                    height={"38px"}
                                    marginBottom={"1px"}
                                    error={screenState.bccErrorMessage}
                                />
                            </TPStyledFormRow>
                        </TPStyledInnerInputForm>
                        <TPStyledInnerInputForm>
                            <TPStyledFormRow wrap>
                                <TPCheckBox
                                    id="email-templates-can-be-used-checkbox"
                                    labelText={isAddableLabel}
                                    checked={screenState.isAddable}
                                    onChange={() => handleIsAddableChange()}
                                />
                                <TPCheckBox
                                    id="email-templates-active-checkbox"
                                    labelText={isActiveLabel}
                                    checked={screenState.isActive}
                                    onChange={() => handleIsActiveChange()}
                                />
                            </TPStyledFormRow>
                        </TPStyledInnerInputForm>
                        <TPStyledInnerInputForm
                            style={{
                                border: `1px solid ${allThemes.base.borderGray}`,
                                borderRadius: "4px",
                                padding: "16px",
                            }}
                        >
                            <TPStyledSubtitle>{templateDetailLabel}</TPStyledSubtitle>
                            <TPStyledFormRow>
                                <TPSelect
                                    disabled={mode === "Update"}
                                    id="email-templates-detail-lang-select"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleInputTextChange("languageId", e.target.value)
                                    }
                                    dataSource={languageOptions}
                                    isMandatory={true}
                                    value={screenState.languageId}
                                    labelText={languageLabel}
                                    errorMessage={screenState.languageIdErrorMessage}
                                />
                            </TPStyledFormRow>
                            <TPStyledFormRow className="flex-column gap-0">
                                <div className="d-flex" style={{ fontSize: 13 }}>
                                    <label
                                        htmlFor="email-templates-subject-input"
                                        style={{ fontSize: "13px" }}
                                    >
                                        {subjectLabel}
                                    </label>
                                    <CustomSpan style={{ color: "red", margin: 0 }}>*</CustomSpan>
                                </div>
                                <TPTextBox
                                    id="email-templates-subject-input"
                                    inputRef={editorSubjectRef}
                                    isTag
                                    isMandatory={true}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleInputTextChange("subject", e.target.value)
                                    }
                                    value={screenState.subject}
                                    errorMessage={screenState.subjectErrorMessage}
                                    maxLength={500}
                                    isHorizontal
                                />
                            </TPStyledFormRow>
                            <TPStyledFormRow className="flex-column gap-0">
                                <TPLabel labelText={bodyLabel} isMandatory={true} />
                                <TPEditor
                                    referece={(editor: any) => (editorRef.current = editor)}
                                    placeholder=""
                                    value={screenState.body}
                                    onChange={(value: any) =>
                                        handleInputTextChange("body", value)
                                    }
                                    initialValue=""
                                    isVisible={true}
                                    onTagSelect={(tag: string) =>
                                        handleInputTextChange(
                                            "body",
                                            screenState.body.concat(` [${tag}]`)
                                        )
                                    }
                                />
                                <CustomSpan style={{ color: "#dc3545", fontSize: "14px" }}>
                                    {screenState.bodyErrorMessage}
                                </CustomSpan>
                            </TPStyledFormRow>
                        </TPStyledInnerInputForm>
                        <TPStyledFormButtonFooter>
                            <TPOptionButtonPairStyled
                                cancelLabel={cancelButtonLabel}
                                confirmLabel={saveButtonLabel}
                                onCancel={handleCancelButtonClick}
                                onConfirm={handleOnOkButtonClick}
                                confirmDisabled={
                                    !areStringsValid(
                                        screenState.attachCaseInformationErrorMessage,
                                        screenState.bccErrorMessage,
                                        screenState.bodyErrorMessage,
                                        screenState.ccErrorMessage,
                                        ...screenState.descriptionErrorMessages,
                                        screenState.idAccountErrorMessage,
                                        screenState.idErrorMessage,
                                        screenState.languageIdErrorMessage,
                                        ...screenState.nameErrorMessages,
                                        screenState.subjectErrorMessage,
                                        screenState.toErrorMessage
                                    )
                                }
                            />
                        </TPStyledFormButtonFooter>
                    </TPStyledForm>
                ) : (
                    <TPStyledForm>
                        <TPStyledInnerInputForm>
                            <TPStyledFormRow className="align-items-end">
                                <TPTextBox
                                    id="email-templates-preview-case-number-input"
                                    isMandatory={false}
                                    onChange={handleCase}
                                    value={caseNumber}
                                    labelText={caseNumberLabel}
                                    errorMessage={""}
                                    maxLength={30}
                                />
                                <TPButton
                                    id="email-templates-preview-button"
                                    onClick={handlePreviewAPI}
                                    isDesignSystem
                                    style={{ padding: "11px 16px", height: "37px", textWrap: "nowrap" }}
                                    disabled={!caseNumber || Number.isNaN(Number(caseNumber))}
                                >
                                    {previewLabel}
                                </TPButton>
                            </TPStyledFormRow>
                            <TPStyledFormRow>
                                <TPTextBox
                                    id="email-templates-preview-to-output"
                                    disabled
                                    onChange={() => { }}
                                    value={storeResponse?.responseData?.renderedTo || ""}
                                    labelText={toLabel}
                                />
                            </TPStyledFormRow>
                            <TPStyledFormRow>
                                <TPTextBox
                                    id="email-templates-preview-cc-output"
                                    disabled
                                    onChange={() => { }}
                                    value={storeResponse?.responseData?.renderedCc}
                                    labelText={ccLabel}
                                />
                            </TPStyledFormRow>
                            <TPStyledFormRow>
                                <TPTextBox
                                    id="email-templates-preview-bcc-output"
                                    disabled
                                    onChange={() => { }}
                                    value={storeResponse?.responseData?.renderedBcc}
                                    labelText={bccLabel}
                                />
                            </TPStyledFormRow>
                            <TPStyledFormRow>
                                <TPTextBox
                                    id="email-templates-preview-subject-output"
                                    disabled
                                    onChange={() => { }}
                                    value={storeResponse?.responseData?.renderedSubject}
                                    labelText={subjectLabel}
                                />
                            </TPStyledFormRow>
                            <TPStyledFormRow className="flex-column gap-0">
                                <TPLabel labelText={bodyLabel} isMandatory />
                                <TPEditor value={storeResponse?.responseData?.renderedBody} isEdit={false} />
                            </TPStyledFormRow>
                        </TPStyledInnerInputForm>
                    </TPStyledForm>
                )}
            </div>
        </TPLoadingOverlay>
    );
};

export default EmailTemplatesInsertUpdate;
