import PlusIcon from "@/assets/images/TPIcons/PlusIcon"
import TPButton from "@/components/bootstrap/components/buttons/TPButton"
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon"
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay"
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox"
import TPSelect from "@/components/bootstrap/forms/select/TPSelect"
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox"
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete"
import { TPChip } from "@/components/TPChip/TPChip"
import TPGlobal from "@/helpers/TPGlobal"
import { TPKeyValue } from "@/helpers/TPKeyValue"
import { TPLog, TPLogType } from "@/helpers/TPLog"
import TPModalQuestion, { TPModalQuestionState } from "@/layouts/ModalQuestion/TPModalQuestion"
import TPModal from "@/layouts/TPModal/TPModal"
import { AdditionalDataTypeEnum, ModalSizeEnum, TPActiveOptions, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums"
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable"
import { AdditionalDataService } from "@/services/AdditionalDataService"
import { TPI18N } from "@/services/I18nService"
import { StructureService } from "@/services/StructureService"
import FileSaver from "file-saver"
import React, { ChangeEventHandler, FC, useEffect, useReducer, useState } from "react"
import * as XLSX from "xlsx"
import "./style.css"
import { CIMTitleSection } from "@/components/TPPage/tpPageStyles"
import AdditionalDataPopUp from "@/components/bootstrap/forms/aditionalDataPopUp/AditionalDataPopUp";
import {dA} from "@fullcalendar/core/internal-common";
import { TPTextBoxStyled } from "@/components/bootstrap/forms/textbox/tpTextboxStyles"
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel"
import { TPFormControlContainerStyled } from "@/helpers/generalStyles"

type StructureAdminUpdateInsertDetailsProps = {
    mode: string
    recordInput: string
    recordDescription?: string
    callBackComands: Function,
    openModal: boolean,
    callbackModal: (setstatus: boolean) => void;
}

type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<any>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};

enum commandsEnum {
    "set_filterIsLoaded" = 0,
    "setup_grid_columns" = 1,
    "change_filterByLanguage" = 2,
    "reload_grid" = 3,
    "change_search_pattern" = 4,
    "change_selectedFilter" = 5,
}

type commandType = {
    type: commandsEnum;
    payload: any;
};

type CustomTextBoxType = {
    id?: string;
    isMandatory: boolean;
    labelText: string;
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    errorMessage: string;
}

const CustomTextBox: FC<CustomTextBoxType> = ({
    id,
    isMandatory,
    labelText,
    value,
    onChange,
    errorMessage
}) => {
    
    return (
        <>
            <TPFormControlContainerStyled
                isHorizontal={false}
                isTag={false}
            >
                <TPLabel
                htmlFor={id ? id : "structure-admin-update-insert-details-custom-text-box"}
                isMandatory={isMandatory}
                labelText={labelText}
                />
                <TPTextBoxStyled
                    id={id ?? "structure-admin-update-insert-details-custom-text-box"}
                    className={`form-control ${( errorMessage) ? "is-invalid" : ""}`}
                    value={value}
                    onChange={onChange}
                />
                {errorMessage && (
                    <>
                        <div
                            className="invalid-feedback"
                            style={{ display: "block"}}
                        >
                            {errorMessage}
                        </div>
                    </>
                )}
            </TPFormControlContainerStyled>
            
        </>
    )
}

const StructureAdminUpdateInsertDetails: FC<StructureAdminUpdateInsertDetailsProps> = ({
    mode,
    recordInput,
    recordDescription,
    callBackComands,
    openModal,
    callbackModal
}) => {

    const componentFileName: string = "StructureAdminUpdateInsertDetails.tsx";
    const resourceSet: string = "StructureAdminUpdateInsertDetails";

    const [detailsMode, setDetailsMode] = useState("Insert");

    const [isLoadingScreen, setIsLoadingScreen] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [newItemLabel, setNewItemLabel] = useState<string>("");
    const [updateItemLabel, setUpdateItemLabel] = useState<string>("");

    const [exportLabel, setExportLabel] = useState<string>("");
    const [refreshLabel, setRefreshLabel] = useState<string>("");
    const [searchLabel, setSearchLabel] = useState<string>("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");

    const [actionsLabel, setActionsLabel] = useState<string>("");
    const [deleteLabel, setDeleteLabel] = useState<string>("");
    const [idLabel, setIdLabel] = useState<string>("");
    const [orderLabel, setOrderLabel] = useState<string>("");
    const [additionalDataLabel, setAdditionalDataLabel] = useState<string>("");
    const [fieldNameLabel, setFieldNameLabel] = useState<string>("");
    const [mandatoryLabel, setMandatoryLabel] = useState<string>("");
    const [determinesCustomerKeyLabel, setdeterminesCustomerKeyLabel] = useState<string>("");
    const [displayAgentLabel, setDisplayAgentLabel] = useState<string>("");
    const [displayAgentOrderLabel, setDisplayAgentOrderLabel] = useState<string>("");

    const [clientCodeLabel, setClientCodeLabel] = useState<string>("");

    const [thereAreNoRecordsToShowLabel, setThereAreNoRecordsToShowLabel] = useState<string>("");

    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState<string>("");

    const [saveAndCloseLabel, setSaveAndCloseLabel] = useState<string>("");
    const [addNewItemLabel, setAddNewItemLabel] = useState<string>("");

    const [autocompleteToOptions, setAutocompleteToOptions] = useState<Array<TPKeyValue>>([]);
    const [selectedAutocompleteTaskTypeOption, setSelectedAutocompleteTaskTypeOption] = useState("");
    const [selectedAuctocomplete, setSelectedAutocomplete] = useState<Array<TPKeyValue>>([]);

    const [orderID, setOrderID] = useState(0);
    const [loadStructureId, setLoadStructureId] = useState("");

    const [orderValue, setOrderValue] = useState("");
    const [fieldNameValue, setFieldNameValue] = useState("");
    const [displayAgentValue, setDisplayAgentValue] = useState("");
    const [mandatoryValue, setMandatoryValue] = useState(false);
    const [clientCodeValue, setClientCodeValue] = useState(false);
    const [orderNumberValue, setOrderNumberValue] = useState("");

    const [orderErrorMessage, setOrderErrorMessage] = useState("");
    const [fieldNameErrorMessage, setFieldNameErrorMessage] = useState("");
    const [additionalDataErrorMessage, setAdditionalDataErrorMessage] = useState("");
    const [displayAgentErrorMessage, setDisplayAgentErrorMessage] = useState("");
    const [orderNumberErrorMessage, setOrderNumberErrorMessage] = useState("");

    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    const [noDataLabel, setNoDataLabel] = useState("");

    const [cancelLabel, setCancelLabel] = useState("Cancel");

    const loadResources = async () => {
        setDeleteTitle(await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"));
        setDeleteOkLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"));
        setDeleteCancelLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"));

        setSearchLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"));
        setExportLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"));
        setRefreshLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"));
        setDeleteLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"));
        setUpdateLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'UpdateLabel'));

        setNewItemLabel(await TPI18N.GetText(resourceSet, "NewItemButton"));
        setUpdateItemLabel(await TPI18N.GetText(resourceSet, "UpdateItemLabel"));
        setActionsLabel(await TPI18N.GetText(resourceSet, "ActionsLabel"));
        setIdLabel(await TPI18N.GetText(resourceSet, "IDLabel"));
        setOrderLabel(await TPI18N.GetText(resourceSet, "OrderLabel"));
        setAdditionalDataLabel(await TPI18N.GetText(resourceSet, "AdditionalDataLabel"));
        setFieldNameLabel(await TPI18N.GetText(resourceSet, "FieldNameLabel"));
        setMandatoryLabel(await TPI18N.GetText(resourceSet, "MandatoryLabel"));
        setdeterminesCustomerKeyLabel(await TPI18N.GetText(resourceSet, "DeterminesCustomerKeyLabel"));
        setDisplayAgentLabel(await TPI18N.GetText(resourceSet, "DisplayAgentLabel"));
        setDisplayAgentOrderLabel(await TPI18N.GetText(resourceSet, "DisplayAgentOrderLabel"));
        setClientCodeLabel(await TPI18N.GetText(resourceSet, "ClientCodeLabel"));
        setThereAreNoRecordsToShowLabel(await TPI18N.GetText(resourceSet, "NoRecordsToShowLabel"));
        setDeleteQuestion(await TPI18N.GetText(resourceSet, "DeleteQuestionLabel"));

        setSaveAndCloseLabel(await TPI18N.GetText(resourceSet, "SaveAndCloseLabel"));
        setAddNewItemLabel(await TPI18N.GetText(resourceSet, "AddNewItemLabel"));
        setUpdateItemLabel(await TPI18N.GetText(resourceSet, "UpdateItemLabel"));

        setYesLabel(await TPI18N.GetText(resourceSet, "YesLabel"));
        setNoLabel(await TPI18N.GetText(resourceSet, "NoLabel"));

        setNoDataLabel(await TPI18N.GetText(resourceSet, "NoDataLabel"));

        setCancelLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
        );


        let newFilterKeyValue: Array<TPKeyValue> = [];
        newFilterKeyValue.push({
            key: TPActiveOptions.ALL.toString(),
            value: await TPI18N.GetText(resourceSet, "All"),
        });
        newFilterKeyValue.push({
            key: TPActiveOptions.ACTIVE.toString(),
            value: await TPI18N.GetText(resourceSet, "Active"),
        });
        newFilterKeyValue.push({
            key: TPActiveOptions.INACTIVE.toString(),
            value: await TPI18N.GetText(resourceSet, "Inactive"),
        });
        setFilterKeyValue(newFilterKeyValue);
    };

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

    const handleOnAutocompleteChange = (value: Array<TPKeyValue>) => {
        let newModalCreateDetailState = { ...modalCreateDetailState };
        newModalCreateDetailState.additionalData = value;
        newModalCreateDetailState.additionalDataError = "";
        setModalCreateDetailState(newModalCreateDetailState);
    };

    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    const [displayToAgentOptions, setDisplayToAgentOptions] = useState<Array<TPKeyValue>>([
        {
            key: "0",
            value: "No",
        },
        {
            key: "1",
            value: "Yes",
        },
    ]);

    let modalQuestionInitialState: TPModalQuestionState = {
        isShown: false,
        callBackData: {},
    };

    const [modalQuestionState, setModalQuestionState] = useState(
        modalQuestionInitialState
    );

    const setupGridColumns = (prevState: AdminStateType) => {
        try {
            let newState: AdminStateType;
            newState = { ...prevState };
            let newColumns: Array<any> = [];

            //function
            newColumns.push({
                name: actionsLabel,
                center: true,
                cell: (row: { [x: string]: any }) => {
                    return (
                        <div className="dropdown">
                            <TPButton
                                id="IdButton"
                                dataBsToggle={true}
                                type={TPButtonTypes.empty}
                                onClick={() => {
                                    TPGlobal.foo();
                                }}
                                className={"menu-button"}
                            >
                                <TPIcon iconType={TPIconTypes.moreVert} />
                            </TPButton>
                            <ul className="dropdown-menu">
                                <li>
                                    <a
                                        onClick={() => handleUpdateClick(row["id"])}
                                        className="dropdown-item"
                                        href="#"
                                    >
                                        {updateLabel}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => handleDeleteClick(row["id"])}
                                        className="dropdown-item"
                                        href="#"
                                    >
                                        {deleteLabel}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    );
                }
            });

            //id
            newColumns.push({
                name: idLabel,
                cell: (row: { [x: string]: any }) => (
                    <div
                        onClick={() => handleUpdateClick(row["id"])}
                        style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                    >
                        {row["id"]}
                    </div>
                ),
                selector: (row: { [x: string]: any }) => row["id"],
                sortable: true,
            });

            //Order
            newColumns.push({
                name: orderLabel,
                selector: (row: { [x: string]: any }) => row["order"],
                cell: (row: { [x: string]: any }) => row["order"],
                center: true,
                sortable: true,
            });

            //Additional Data
            newColumns.push({
                name: additionalDataLabel,
                selector: (row: { [x: string]: any }) => row["additionalData"],
                cell: (row: { [x: string]: any }) => row["additionalDataDescription"],
                sortable: true,
            });

            //Column Name
            newColumns.push({
                name: fieldNameLabel,
                selector: (row: { [x: string]: any }) => row["columnName"],
                cell: (row: { [x: string]: any }) => row["columnName"],
                sortable: true,
            });

            //Is Mandatory
            newColumns.push({
                name: mandatoryLabel,
                selector: (row: { [x: string]: any }) => row["isMandatory"],
                cell: (row: { [x: string]: any }) => {
                    return (
                        <TPChip label={row["isMandatory"] ? yesLabel : noLabel} backgroundColor={row["isMandatory"] ? "lightgreen" : "#ffd7dd"} />
                    );
                },
                center: true,
                sortable: true,
            });

            //Determines PK Field Customer
            newColumns.push({
                name: determinesCustomerKeyLabel,
                selector: (row: { [x: string]: any }) => row["determinesPKFieldCustomer"],
                cell: (row: { [x: string]: any }) => {
                    return (
                        <TPChip label={row["determinesPKFieldCustomer"] ? yesLabel : noLabel} backgroundColor={row["determinesPKFieldCustomer"] ? "lightgreen" : "#ffd7dd"} />
                    );
                },
                center: true,
                sortable: true,
            });

            //Display Agent
            newColumns.push({
                name: displayAgentLabel,
                selector: (row: { [x: string]: any }) => row["displayAgentField"],
                cell: (row: { [x: string]: any }) => {
                    return (
                        <TPChip label={row["displayAgentField"] ? yesLabel : noLabel} backgroundColor={row["displayAgentField"] ? "lightgreen" : "#ffd7dd"} />
                    );
                },
                center: true,
                sortable: true,
            });

            //Display Angent Order
            newColumns.push({
                name: displayAgentOrderLabel,
                selector: (row: { [x: string]: any }) => row["displayAgentOrder"],
                cell: (row: { [x: string]: any }) => row["displayAgentOrder"],
                sortable: true,
                center: true
            });

            newState.gridColumns = [...newColumns];
            return newState;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} setupGridColumns ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} setupGridColumns ex`);
            return prevState;
        }
    };



    const handleDeleteClick = (id: string) => {
        callBackComands(2);
        let newModalQuestionState: TPModalQuestionState;
        newModalQuestionState = { ...modalQuestionState };
        newModalQuestionState.isShown = true;
        newModalQuestionState.callBackData = { recordId: id };
        setModalQuestionState(newModalQuestionState);
    };

    const filteredData = () => {
        let searcheableColumns: Array<string> = [
            "additionalData",
            "columnName",
        ];
        let i: number;
        let search: string;
        search = adminState.searchPattern.trim();
        return adminState.gridData.filter(function (item, index) {
            if (search == "" || search.length <= 2) {
                return item;
            }
            for (i = 0; i <= searcheableColumns.length - 1; i++) {
                let itemany: any;
                itemany = item;
                if (
                    itemany[searcheableColumns[i]]
                        ? itemany[searcheableColumns[i]]
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        : false
                ) {
                    return item;
                }
            }
        });
    };

    const reloadDataGrid = async (filter: string) => {
        let serviceClient = new StructureService();
        let expectedCodes = [200, 404];

        try {
            callBackComands(2);
            setIsLoadingScreen(true);
            let result = await serviceClient.getStructureDetails(
                recordInput,
                false,
                true,
                expectedCodes
            );
            setIsLoadingScreen(false);
            return result;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} reloadDataGrid ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} reloadDataGrid ex`);
            setIsLoadingScreen(false);
            return [];
        }
    }

    const reloadGridCommand = () => {
        reloadDataGrid(adminState.selectedFilter)
            .then(function (result) {
                let command1: commandType = {
                    type: commandsEnum.reload_grid,
                    payload: result,
                };
                dispatchCommand(command1);
            })
            .catch(function (error) {
                TPLog.Log(
                    `Error ${componentFileName} reloadGridCommand ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} reloadGridCommand ex`);
            });
    };


    const initialStateBLL: AdminStateType = {
        filterIsLoaded: false,
        columnsAreLoaded: false,
        selectedFilter: TPActiveOptions.ALL.toString(),
        gridColumns: [],
        gridData: [],
        searchPattern: "",
    };

    const [adminState, dispatchCommand] = useReducer(
        doCommand,
        initialStateBLL
    );

    function doCommand(prevState: AdminStateType, command: commandType) {
        switch (command.type) {
            case commandsEnum.set_filterIsLoaded:
                let newStateFilter: AdminStateType;
                newStateFilter = { ...prevState };
                newStateFilter.filterIsLoaded = true;
                return newStateFilter;
                break;
            case commandsEnum.setup_grid_columns:
                let newStateColumns: AdminStateType = setupGridColumns(prevState);
                newStateColumns.columnsAreLoaded = true;
                return newStateColumns;
                break;
            case commandsEnum.reload_grid:
                let newStateGrid: AdminStateType;
                newStateGrid = { ...prevState };
                newStateGrid.gridData = command.payload;
                return newStateGrid;
                break;
            case commandsEnum.change_selectedFilter:
                let newStateChangeFilter: AdminStateType;
                newStateChangeFilter = { ...prevState };
                newStateChangeFilter.selectedFilter = command.payload;
                return newStateChangeFilter;
            case commandsEnum.change_search_pattern:
                let newStatePattern: AdminStateType;
                newStatePattern = { ...prevState };
                newStatePattern.searchPattern = command.payload;
                return newStatePattern;
                break;
            default:
                return prevState;
        }
    }

    useEffect(() => {
        if (adminState.columnsAreLoaded) {
            reloadGridCommand();
        }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    useEffect(() => {
        loadResources()
            .then(function () {
                //set filter is loaded
                let command1: commandType = {
                    type: commandsEnum.set_filterIsLoaded,
                    payload: null,
                };
                dispatchCommand(command1);
            })
            .catch(function (error) {
                TPLog.Log(
                    `Error ${componentFileName} loadResourcesAndUsersFilter ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${componentFileName} loadResourcesAndUsersFilter ex`
                );
            });
    }, []);

    const updateDetail = async () => {
        let serviceClient = new StructureService();
        let expectedCodes = [200, 404];

        let inputDTO: any = {
            id: orderID,
            LoadStructureId: loadStructureId,
            order: orderValue,
            columnName: fieldNameValue,
            additionalData: modalCreateDetailState.additionalData[0].key,
            displayAgentField: displayAgentValue === "1" ? true : false,
            displayAgentOrder: displayAgentValue === "1" ? orderNumberValue : 0,
            isMandatory: mandatoryValue,
            determinesPKFieldCustomer: clientCodeValue,
            isActive: true
        }

        setOrderErrorMessage("");
        setFieldNameErrorMessage("");
        setAdditionalDataErrorMessage("");
        setOrderNumberErrorMessage("");

        try {
            let responseRequest = await serviceClient.updateStructureDetails(
                inputDTO,
                true,
                true,
                expectedCodes
            );

            return responseRequest;
        } catch (error) {
            TPLog.Log(`Error ${componentFileName} insertEmailTemplate ex`, TPLogType.ERROR, error);
            console.error(`Error ${componentFileName} insertEmailTemplate ex`);
        }
    }

    const addNewDetail = async () => {
        let serviceClient = new StructureService();
        let expectedCodes = [200, 404];


        let inputDTO: any = {
            LoadStructureId: recordInput,
            order: orderValue,
            columnName: fieldNameValue,
            additionalData: modalCreateDetailState.additionalData[0].key,
            displayAgentField: displayAgentValue === "1" ? true : false,
            displayAgentOrder: displayAgentValue === "1" ? orderNumberValue : 0,
            isMandatory: mandatoryValue,
            determinesPKFieldCustomer: clientCodeValue,
            isActive: true
        }

        setOrderErrorMessage("");
        setFieldNameErrorMessage("");
        setAdditionalDataErrorMessage("");
        setOrderNumberErrorMessage("");

        try {
            let responseRequest = await serviceClient.addStructureDetails(
                inputDTO,
                true,
                true,
                expectedCodes
            );

            return responseRequest;
        } catch (error) {
            TPLog.Log(`Error ${componentFileName} insertEmailTemplate ex`, TPLogType.ERROR, error);
            console.error(`Error ${componentFileName} insertEmailTemplate ex`);
        }
    }


    const updateSelectedAutocomplete = (selectedOption: TPKeyValue) => {
        setModalCreateDetailState(prevState => ({
            ...prevState,
            additionalData: [selectedOption]
        }));
    };

    const getAdditionalData = async (query: any, data?: any) => {

        setAutocompleteToOptions(new Array<TPKeyValue>);
        let serviceClient = new AdditionalDataService();
        let expectedCodes: Array<number> = [200, 404];

        try {
            let responseRequest = await serviceClient.getAll(
                false,
                true,
                expectedCodes,
            );

            const additionalDataTypes = Object.values(AdditionalDataTypeEnum);

            const filteredResponse = responseRequest.filter(item => additionalDataTypes.includes(item.additionalDataTypeId as AdditionalDataTypeEnum));


            let newToKeyValueList: Array<TPKeyValue> = filteredResponse.map(item => {
                return { key: item.id, value: `${item.localizedDescription}` };
            })

            setAutocompleteToOptions(newToKeyValueList);

            if (data) {
                const selectedOption = newToKeyValueList.find(item => item.key === data.id);
                if (selectedOption) {
                    updateSelectedAutocomplete(selectedOption);
                }
            }

            return newToKeyValueList;

        } catch (error) {
            TPLog.Log(
                `Error ${resourceSet} reloadDataGrid ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${resourceSet} reloadDataGrid ex`);

            return [];
        }
    }

    const handleRowsPerPageChanged = (e: any) => {
        const recordSize = fixedHeaderScrollHeight / 10;
        const newRecordSize = recordSize * e;
        setFixedHeaderScrollHeight(newRecordSize);
    };

    useEffect(() => {
        if (adminState.filterIsLoaded) {
            let command1: commandType = {
                type: commandsEnum.setup_grid_columns,
                payload: null,
            };
            dispatchCommand(command1);
        }
    }, [adminState.filterIsLoaded]);

    const handleFilterChange = (e: any) => {
        let command1: commandType = {
            type: commandsEnum.change_selectedFilter,
            payload: e.target.value,
        };
        dispatchCommand(command1);
    };

    const handleCallBackModal = async (
        confirmDelete: boolean,
        callBackData: any
    ) => {
        callBackComands(0);
        let newModalQuestionState: TPModalQuestionState;
        newModalQuestionState = { ...modalQuestionState };
        newModalQuestionState.isShown = false;
        newModalQuestionState.callBackData = {};
        setModalQuestionState(newModalQuestionState);
        if (confirmDelete) {
            await deleteItem(callBackData.recordId);
            reloadGridCommand();
        }
    };

    const deleteItem = async (id: any) => {
        let sserviceClient = new StructureService();
        let expectedCodes = [200, 404];

        try {
            let responseRequest = await sserviceClient.deleteSturctureDetails(
                id,
                true,
                true,
                expectedCodes
            );

            return responseRequest;
        } catch (error) {
            TPLog.Log(`Error ${componentFileName} deleteItem ex`, TPLogType.ERROR, error);
            console.error(`Error ${componentFileName} deleteItem ex`);
        }
    }

    const initalModalCreateDetailState = {
        additionalData: new Array<TPKeyValue>(),
        additionalDataError: ""
    };
    const [modalCreateDetailState, setModalCreateDetailState] = useState(initalModalCreateDetailState);

    const handleCallbackAnswer = async (status: boolean, data: any) => {

        setOrderErrorMessage("");
        setFieldNameErrorMessage("");
        setAdditionalDataErrorMessage("");
        setOrderNumberErrorMessage("");

        if (detailsMode === "Insert") {
            if (data !== "cancel") {

                let errors = 0;

                if (orderValue === "") {
                    setOrderErrorMessage(await TPI18N.GetText(resourceSet, "OrderEmptyLabel"));

                    errors = errors + 1;
                }
                if (fieldNameValue === "") {
                    setFieldNameErrorMessage(await TPI18N.GetText(resourceSet, "FieldNameEmptyLabel"));

                    errors = errors + 1;
                }
                if (modalCreateDetailState.additionalData.length === 0) {
                    setAdditionalDataErrorMessage(await TPI18N.GetText(resourceSet, "AdditionalDataEmptyLabel"));

                    errors = errors + 1;
                }
                if (displayAgentValue === "1") {
                    if (orderNumberValue === "") {
                        setOrderNumberErrorMessage(await TPI18N.GetText(resourceSet, "OrderNumberEmptyLabel"));

                        errors = errors + 1;
                    }
                }

                if (errors > 0) {
                    return;
                }

                await addNewDetail();

                reloadGridCommand();
            }

            callbackModal(false)
        } else if (detailsMode === "Update") {

            if (status) {

                let errors = 0;

                if (orderValue === "") {
                    setOrderErrorMessage(await TPI18N.GetText(resourceSet, "OrderEmptyLabel"));

                    errors = errors + 1;
                }
                if (fieldNameValue === "") {
                    setFieldNameErrorMessage(await TPI18N.GetText(resourceSet, "FieldNameEmptyLabel"));

                    errors = errors + 1;
                }
                if (modalCreateDetailState.additionalData.length === 0) {
                    setAdditionalDataErrorMessage(await TPI18N.GetText(resourceSet, "AdditionalDataEmptyLabel"));

                    errors = errors + 1;
                }
                if (displayAgentValue === "1") {
                    if (orderNumberValue === "") {
                        setOrderNumberErrorMessage(await TPI18N.GetText(resourceSet, "OrderNumberEmptyLabel"));

                        errors = errors + 1;
                    }
                }

                if (errors > 0) {
                    return;
                }

                await updateDetail();

                reloadGridCommand();
            }
        }

        setOrderValue("");
        setFieldNameValue("");
        setSelectedAutocompleteTaskTypeOption("");
        setSelectedAutocomplete([]);
        setDisplayAgentValue("");
        setOrderNumberValue("");
        setMandatoryValue(false);
        setClientCodeValue(false);
        setModalCreateDetailState(initalModalCreateDetailState);
        setIsModalOpen(status);
    }

    const handleNewItemClick = () => {
        setDetailsMode("Insert");
        setIsModalOpen(true);
    }

    useEffect(() => {
        if (openModal) {
            handleNewItemClick();
        }
    }, [openModal])

    const handleOrderChange = (e: any) => {
        if (isNaN(e.target.value)) {
            return;
        }
        setOrderValue(e.target.value);
    }

    const handleFieldNameChange = (e: any) => {
        setFieldNameValue(e.target.value);
    }

    const handleDisplayAgentChange = (e: any) => {
        setDisplayAgentValue(e.target.value);
    }

    const handleOrderNumberChange = (e: any) => {
        if (isNaN(e.target.value)) {
            return;
        }
        setOrderNumberValue(e.target.value);
    }

    const handleMandatoryChange = (e: any) => {
        setMandatoryValue(!mandatoryValue);
    }

    const handleClientCodeChange = (e: any) => {
        setClientCodeValue(!clientCodeValue);
    }

    const handleSearchPatternChange = (newValue: string) => {
        let command1: commandType = {
            type: commandsEnum.change_search_pattern,
            payload: newValue,
        };
        dispatchCommand(command1);
    };

    const handleUpdateClick = async (id: string) => {
        let serviceStructure = new StructureService();
        let expectedCodes = [200, 404];
        try {

            setDetailsMode("Update")

            let responseRequest = await serviceStructure.getStructureDetailById(
                id,
                false,
                true,
                expectedCodes
            )

            setOrderID(responseRequest[0].id);
            setLoadStructureId(responseRequest[0].idEVLS);

            setOrderValue(responseRequest[0].order.toString());
            setFieldNameValue(responseRequest[0].columnName);

            let newModalCreateDetailState = { ...modalCreateDetailState };
            newModalCreateDetailState.additionalData = [{
                key: responseRequest[0].additionalData,
                value: responseRequest[0].additionalDataDescription
            }]
            newModalCreateDetailState.additionalDataError = "";
            setModalCreateDetailState(newModalCreateDetailState);

            setDisplayAgentValue(responseRequest[0].displayAgentField ? "1" : "0");
            setOrderNumberValue(responseRequest[0].displayAgentOrder.toString());
            setMandatoryValue(responseRequest[0].isMandatory);
            setClientCodeValue(responseRequest[0].determinesPKFieldCustomer);

            setIsModalOpen(true);

            return responseRequest;

        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} reloadDataGrid ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} reloadDataGrid ex`);
            setIsLoadingScreen(false);
            return [];
        }
    }

    const getStructureDetails = async (id: string) => {
      let serviceClient = new StructureService();
      let expectedCodes = [200, 404];

      try {
        let result = await serviceClient.getStructureDetails(
          id,
          false,
          true,
          expectedCodes
        );

        return result;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getStructureDetails ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getStructureDetails ex`);
        setIsLoadingScreen(false);
        return [];
      }
    }

    const handleDownloadClick = async (id: string) => {
      let data = await getStructureDetails(id);
      let templateName: string;

      if (data) {
        templateName = `structure-template-${id}`;
        const columnNameObject = TPGlobal.createColumnNameObject(data);
        exportToCSV(columnNameObject, templateName)
      }
    }

    const exportToCSV = (apiData = filteredData(), fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8") => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        /* custom headers */
        XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
        FileSaver.saveAs(data, fileName + ".xlsx");
    };

    useEffect(() => {
        if (!isLoadingScreen)  {
            setTimeout(() => {
                callBackComands(0);
            }, 1500)
        }
    }, [isLoadingScreen])

    return (
        <>
            <TPModalQuestion
                id="IdModalQuestion"
                title={deleteTitle}
                yesLabel={deleteOkLabel}
                noLabel={deleteCanceLabel}
                question={deleteQuestion.replace("{recordId}", modalQuestionState.callBackData.recordId)}
                callBackData={modalQuestionState.callBackData}
                isShown={modalQuestionState.isShown}
                callBackAnswer={handleCallBackModal}
            ></TPModalQuestion>
            <TPModal
                modalState={{
                    titleModal: detailsMode === "Update" ? updateItemLabel : newItemLabel,
                    cancelLabel: detailsMode === "Update" ? cancelLabel : saveAndCloseLabel,
                    acceptLabel: detailsMode === "Update" ? updateItemLabel : addNewItemLabel,
                    callBackAnswer: handleCallbackAnswer,
                    isShown: isModalOpen,
                    modalWidth: ModalSizeEnum.MODALLG,
                    showSecondaryButton: detailsMode === "Update" ? false : true,
                    secondaryButtonLabel: cancelLabel,
                    secondaryAction: "cancel",
                    hideXButton: true
                }}
            >
                <div className="row" style={{display: "flex", flexDirection: "row"}}>
                    <div className="col-6">
                        {/* <TPTextBox // TODO: Fix this
                            id="IdTextBox"
                            isMandatory={true}
                            labelText={orderLabel}
                            value={orderValue}
                            onChange={handleOrderChange}
                            errorMessage={orderErrorMessage}
                        /> */}
                        <CustomTextBox
                            id="IdTextBox"
                            isMandatory={true}
                            labelText={orderLabel}
                            value={orderValue}
                            onChange={handleOrderChange}
                            errorMessage={orderErrorMessage}
                        />
                    </div>
                    <div className="col-6">
                        <CustomTextBox
                            id="IdTextBox"
                            isMandatory={true}
                            labelText={fieldNameLabel}
                            value={fieldNameValue}
                            onChange={handleFieldNameChange}
                            errorMessage={fieldNameErrorMessage}
                        />
                    </div>
                </div>
                <div className="row" style={{display: "flex", alignItems: "center"}}>
                    <div className="col-6">
                        <TPAutoComplete
                            isMandatory={true}
                            labelText={additionalDataLabel}
                            onValueChange={(value: any) => handleOnAutocompleteChange(value)}
                            onSearch={(event: any) => getAdditionalData(event)}
                            isLoading={false}
                            options={autocompleteToOptions}
                            withIcon={true}
                            downArrowClick={() => {
                            }}
                            onKeyDown={() => {
                            }}
                            selected={modalCreateDetailState.additionalData}
                            errorMessage={additionalDataErrorMessage}
                            emptyLabel={""}
                        />
                    </div>
                    <div className="col-auto" style={{ marginTop: '22px' }}>
                        <AdditionalDataPopUp
                            callBackResult={(success: any, data: any) => getAdditionalData("", success.data)}
                        />
                    </div>
                </div>

                <div className="row" style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                    <div className="col-6">
                    <TPSelect
                            id="IdSelect"
                            isMandatory={true}
                            labelText={displayAgentLabel}
                            value={displayAgentValue}
                            dataSource={displayToAgentOptions}
                            onChange={(e: any) => handleDisplayAgentChange(e)}
                        />
                    </div>
                    {displayAgentValue == "1" && (
                        <div className="col-6">
                            <TPTextBox
                                id="IdTextBox"
                                isMandatory={true}
                                labelText={orderLabel}
                                value={orderNumberValue}
                                onChange={handleOrderNumberChange}
                                errorMessage={orderNumberErrorMessage}
                            />
                        </div>
                    )}
                </div>
                <br/>
                <div className="row" style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                    <div className="col-6">
                        <TPCheckBox
                            id="IdCheckBox"
                            labelText={mandatoryLabel}
                            checked={mandatoryValue}
                            onChange={(e: any) => handleMandatoryChange(e)}
                        />
                    </div>
                    <div className="col-6">
                    <TPCheckBox
                            id="IdCheckBox"
                            labelText={clientCodeLabel}
                            checked={clientCodeValue}
                            onChange={(e: any) => handleClientCodeChange(e)}
                        />
                    </div>
                </div>
            </TPModal>
            <TPLoadingOverlay active={isLoadingScreen}>
                <DynamicTable
                    data={filteredData().map(item => {
                        return {
                            id: item.id,
                            order: item.order,
                            additionalData: item.additionalDataDescription,
                            columnName: item.columnName,
                            isMandatory: item.isMandatory,
                            determinesPKFieldCustomer: item.determinesPKFieldCustomer,
                            displayAgentField: item.displayAgentField,
                            displayAgentOrder: item.displayAgentOrder
                        }
                    })}
                    withPreferences
                    hideAllTableOnNoData
                    noDataMessage={noDataLabel}
                    columnNames={{
                        additionalData: additionalDataLabel,
                        columnName: fieldNameLabel,
                        determinesPKFieldCustomer: determinesCustomerKeyLabel,
                        displayAgentField: displayAgentLabel,
                        displayAgentOrder: displayAgentOrderLabel,
                        id: idLabel,
                        isMandatory: mandatoryLabel,
                        order: orderLabel
                    }}
                    minorOptions={[
                        {
                            key: updateLabel,
                            onOptionChange: (e) => handleUpdateClick(e.id),
                            type: 'update',
                            icon: TPIconTypes.edit
                        },
                        {
                            key: deleteLabel,
                            onOptionChange: (e) => handleDeleteClick(e.id),
                            type: 'delete',
                            icon: TPIconTypes.delete
                        },
                    ]}
                    columnStyles={{
                        id: ({item, value}) => (
                            <button
                                style={{border:'none', background:'none'}}
                                onClick={() => handleUpdateClick(item.id)}
                            >
                                <b><u style={{color: '#780096'}}>{item.id}</u></b>
                            </button>
                        ),
                        isMandatory: ({item, value}) => (
                            <TPChip
                                backgroundColor={item.isMandatory ? '#B1F2D7' : '#FFD7DD'}
                                label={item.isMandatory ? yesLabel : noLabel}
                            />
                        ),
                        determinesPKFieldCustomer: ({item, value}) => (
                            <TPChip
                                backgroundColor={item.determinesPKFieldCustomer ? '#B1F2D7' : '#FFD7DD'}
                                label={item.determinesPKFieldCustomer ? yesLabel : noLabel}
                            />
                        ),
                        displayAgentField: ({item, value}) => (
                            <TPChip
                                backgroundColor={item.displayAgentField ? '#B1F2D7' : '#FFD7DD'}
                                label={item.displayAgentField ? yesLabel : noLabel}
                            />
                        )
                    }}
                    onIconClicked={(icon) => {
                        if (icon === TPIconTypes.loop || icon === TPIconTypes.refresh) reloadGridCommand();
                        if (icon === TPIconTypes.downloadTemplate) handleDownloadClick(recordInput);
                    }}
                    icons={[{
                        type: TPIconTypes.downloadTemplate,
                        status: true,
                    }]}
                />
            </TPLoadingOverlay>
        </>
    );
}

export default StructureAdminUpdateInsertDetails;