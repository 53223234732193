import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  AdditionalDataRelateViewModel,
  AdditionalDataTaskTypeViewModel,
} from "@/models/AdditionalData/AdditionalDataModels";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import {
  AdditionalDataService,
  AdditionalDataTaskTypeService,
} from "@/services/AdditionalDataService";
import { TPI18N } from "@/services/I18nService";
import { FC, useEffect, useReducer, useState } from "react";
import DynamicTable, {
  ColumnStyles,
  CustomColumnNames,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { MinorOption } from "@/modules/core/components/dynamic-table/TableActionItem";
import { Menu, MenuItem } from "@mui/material";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TaskTypeAdditionalDataInsertUpdate from "../TaskType/TaskTypeAdditionalDataInsertUpdate";
import { TaskTypeService } from "@/services/TaskTypeService";
import AdditionalDataRelateAdminFormModal from "./AdditionalDataRelateAdminFormModal";
import {
  TaskTypeAdditionalDataModel,
  TaskTypeAdditionalDataType,
} from "@/models/TaskType/TaskTypeAdditionalDataModel";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import FormView from "../FormDesigner/FormView/FormView";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPYesNoTaskAutomatic } from "@/components/TPTasks/TPYesNoTaskAutomatic";
import { TPYesNoTask } from "@/components/TPTasks/TPYesNoTask";
import { TPYesNoRetryTask } from "@/components/TPTasks/TPYesNoRetryTask";
import { TPEmailTask } from "@/components/TPTasks/TPEmailTask";
import { TPStandardTask } from "@/components/TPTasks/TPStandardTask";
import { TaskTypeViewModel } from "@/models/TaskType/TaskTypeModels";

export enum AdditionalDataRelatedTypesEnum {
  taskType = 1,
  Brnach = 2,
}

type AdditionalDataRelatedProps = {
  type: AdditionalDataRelatedTypesEnum;
  inputRecord: string;
  callBackCommands: Function;
};

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<AdditionalDataRelateViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_search_pattern" = 3,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

const AdditionalDataRelateAdmin: FC<AdditionalDataRelatedProps> = ({
  type,
  inputRecord, //Parent Id
  callBackCommands,
}) => {
  const componentFileName: string = "TaskTypeAdmin.tsx";
  const resourceSet: string = "AdditionalDataRelateAdmin";
  const resourceSetAdDaTaskTypeInsertUpdate: string =
    "AdditionalDataTaskTypeInsertUpdate";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  //Screen resources
  const [titleLabel, setTitleLabel] = useState("");
  const [refreshLabel, setRefreshLabel] = useState("");
  const [newLabel, setNewLabel] = useState("");
  const [exportLabel, setExportLabel] = useState("");
  const [deleteLabel, setDeleteLabel] = useState("");
  const [updateLabel, setUpdateLabel] = useState("");
  const [clickingHereLabel, setClickingHereLabel] = useState("");
  const [notificationViewingPreviewLabel, setNotificationViewingPreviewLabel] = useState("");
  const [customerComplaintLabel, setCustomerComplaintLabel] = useState("");
  const [additionalDataLabel, setAdditionalDataLabel] = useState("");
  const [formLabel, setFormLabel] = useState("");
  const [previewLabel, setPreviewLabel] = useState("");
  const [newMenuLabel, setNewMenuLabel] = useState("");

  const [searchLabel, setSearchLabel] = useState("");
  const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
  //modal resources
  const [deleteQuestion, setDeleteQuestion] = useState("");
  const [deleteQuestionForm, setDeleteQuestionForm] = useState("");
  const [deleteTitle, setDeleteTitle] = useState("");
  const [deleteOkLabel, setDeleteOkLabel] = useState("");
  const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

  //State modal
  let modalQuestionInitialState: TPModalQuestionState = {
    isShown: false,
    callBackData: {},
  };
  const [modalQuestionState, setModalQuestionState] = useState(
    modalQuestionInitialState
  );

  //modal TasktypeInsertUpdate state
  const [showDetail, setShowDetail] = useState(false);
  const [showAdditionalFormModal, setShowAdditionalFormModal] = useState(false);
  const [titleDetailInsert, setTitleDetailInsert] = useState("");
  const [titleDetailUpdate, setTitleDetailUpdate] = useState("");
  const [recordId, setRecordId] = useState("");
  const [detailMode, setDetailMode] = useState("Insert");

  //grid columns
  const [idColumnLabel, setIdColumnLabel] = useState("");
  const [
    additionalDataDescriptionColumnLabel,
    setAdditionalDataDescriptionColumnLabel,
  ] = useState("");
  const [orderColumnLabel, setOrderColumnLabel] = useState("");
  const [isMandatoryColumnLabel, setIsMandatoryColumnLabel] = useState("");

  const [preview, setPreview] = useState<any>();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };
  const [sourceAdditionalDatas, setSourceAdditionalDatas] = useState<any>({});
  const [additionalDatas, setAdditionalDatas] = useState<
    TaskTypeAdditionalDataModel[]
  >([]);
  const [decisionTask, setDecisionTask] = useState<TaskTypeViewModel>();
  const [forms, setForms] = useState<TaskTypeAdditionalDataModel[]>([]);

  //Load Resources and fill Active Filter
  const loadResourcesAndFilter = async () => {
    //modal
    setDeleteQuestion(await TPI18N.GetText(resourceSet, "RecordDeleteConfirm"));
    setDeleteQuestionForm(await TPI18N.GetText(resourceSet, "RecordDeleteConfirmForm"));
    setDeleteTitle(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle")
    );
    setDeleteOkLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
    );
    setDeleteCancelLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
    );
    //screen
    setDeleteLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel")
    );
    setUpdateLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel")
    );
    setClickingHereLabel(
        await TPI18N.GetText(resourceSet, "ClickingHereLabel")
    )
    setNotificationViewingPreviewLabel(
        await TPI18N.GetText(resourceSet, "NotificationViewingPreview")
    )
    setCustomerComplaintLabel(
        await TPI18N.GetText(resourceSet, "CustomerComplaintLabel")
    )
    setAdditionalDataLabel(
        await TPI18N.GetText(resourceSet, "AdditionalDataLabel")
    )
    setFormLabel(
        await TPI18N.GetText(resourceSet, "FormLabel")
    )
    setPreviewLabel(
        await TPI18N.GetText(resourceSet, "PreviewLabel")
    )
    setNewMenuLabel(
        await TPI18N.GetText(resourceSet, "NewLabel")
    )
    setRefreshLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton")
    );
    setExportLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton")
    );
    setSearchLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"));
    setThereAreNoRecordsToShow(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "DataTableNoCurrentData")
    );

    //grid columns
    setIdColumnLabel(
      await TPI18N.GetText(resourceSet, "additionalDataIdLabel")
    );
    setAdditionalDataDescriptionColumnLabel(
      await TPI18N.GetText(resourceSet, "LocalizedAddionalDescriptionLabel")
    );
    setOrderColumnLabel(await TPI18N.GetText(resourceSet, "OrderLabel"));
    setIsMandatoryColumnLabel(
      await TPI18N.GetText(resourceSet, "IsMandatoryLabel")
    );

    switch (type) {
      case AdditionalDataRelatedTypesEnum.taskType:
        var resourceSetAddaTaskType = "AdditionalDataRelateAdmin";
        setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
        setTitleLabel(
          await TPI18N.GetText(resourceSetAddaTaskType, `TitleLabel`)
        );
        //Modal Additiona data InsertUpdate
        setTitleDetailInsert(
          await TPI18N.GetText(resourceSetAdDaTaskTypeInsertUpdate, "TitleNew")
        );
        setTitleDetailUpdate(
          await TPI18N.GetText(resourceSetAdDaTaskTypeInsertUpdate, "TitleEdit")
        );
        break;

      default:
        break;
    }
  };

  const columns: CustomColumnNames<TaskTypeAdditionalDataModel> = {
    id: `${idColumnLabel}`,
    type: `${"Type"}`,
    description: `${additionalDataDescriptionColumnLabel}`,
    order: `${orderColumnLabel}`,
    isMandatory: `${isMandatoryColumnLabel}`,
  };



  const columnStyles: ColumnStyles<TaskTypeAdditionalDataModel> = {
    id: ({ value, item }) =>
      item.type === TaskTypeAdditionalDataType.ADDITIONAL_DATA ? (
        <TablePrimaryItem
          value={value}
          onClick={() => handleUpdateClick(item.id)}
        />
      ) : (
        <>{value}</>
      ),
    type: ({ value, item }) => (
      <>
        {value === TaskTypeAdditionalDataType.FORM ? `Form` : `Additional Data`}
      </>
    ),
    order: ({ value, item }) => value ?? "",
    isMandatory: ({ value, item }) =>
      value !== null && value !== undefined ? (
        <TableChip
          value={value}
          onLabel={"Yes"}
          offLabel={"No"}
          justify="flex-start"
        />
      ) : (
        <></>
      ),
  };

  const options: MinorOption<TaskTypeAdditionalDataModel>[] = [
    {
      key: `${deleteLabel}`,
      type: "delete",
      icon: TPIconTypes.delete,
      onOptionChange: ({ id }) => handleDeleteClick(id),
    },
    {
      key: `${updateLabel}`,
      type: "edit",
      icon: TPIconTypes.edit,
      onOptionChange: ({ id }) => handleUpdateClick(id),
    },
  ];

  const optionsForm: MinorOption<TaskTypeAdditionalDataModel>[] = [
    {
      key: `${deleteLabel}`,
      type: "delete",
      icon: TPIconTypes.delete,
      onOptionChange: ({ id }) => handleDeleteClick(id),
    },
  ];

  const onClickIconHandler = (event: TPIconTypes) => {
    if (event === TPIconTypes.loop) handleRefreshClick();
  };

  function handlePreview(preview?: any) {
    if (preview) {
      setPreview(preview);
      return;
    }

    const type =
      forms.length > 0
        ? TaskTypeAdditionalDataType.FORM
        : TaskTypeAdditionalDataType.ADDITIONAL_DATA;
    const dataSources =
      forms.length > 0 ? [...forms] : additionalDatas.map((val) => {
        return {
          key: val.id,
          value: null,
          value2: {
            ...sourceAdditionalDatas[val.id],
            description: val.description,
            isMandatory: val.isMandatory,
            localizedDescription: val.description,
            order: val.order,
          },
        }
      });

    setPreview({
      type,
      dataSources,
    });
  }

  //Modal Question to delete
  const handleDeleteClick = (id: string) => {
    let newModalQuestionState: TPModalQuestionState;
    newModalQuestionState = { ...modalQuestionState };
    newModalQuestionState.isShown = true;
    newModalQuestionState.callBackData = { recordId: id };
    setModalQuestionState(newModalQuestionState);
  };

  //Delete function after question confirmation
  const handleCallBackModal = async (
    confirmDelete: boolean,
    callBackData: any
  ) => {
    switch (type) {
      case AdditionalDataRelatedTypesEnum.taskType:
        deleteTaskTypeRecord(confirmDelete, callBackData);
        break;
      default:
        break;
    }
  };

  function deleteForm() {
    const { deleteAdditionalForm } = new TaskTypeService();
    try {
      setIsLoadingScreen(true);
      deleteAdditionalForm(inputRecord, forms[0].id)
        .then(() => {
          setIsLoadingScreen(false);
          reloadGridCommand();
        })
        .catch((error) => {
          TPLog.Log(
            `Error ${componentFileName} deleteForm ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} deleteForm ex`);
          setIsLoadingScreen(false);
        });
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} deleteForm ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} deleteForm ex`);
      setIsLoadingScreen(false);
    }
  }

  const deleteTaskTypeRecord = async (
    confirmDelete: boolean,
    callBackData: any
  ) => {
    let expectedCodes: Array<number> = [200];
    let newModalQuestionState: TPModalQuestionState;
    newModalQuestionState = { ...modalQuestionState };
    newModalQuestionState.isShown = false;
    newModalQuestionState.callBackData = {};
    setModalQuestionState(newModalQuestionState);
    if (confirmDelete) {
      if (forms.length > 0) {
        deleteForm();
      } else {
        try {
          setIsLoadingScreen(true);
          let serviceClient = new AdditionalDataTaskTypeService();
          let responseRequest =
            await serviceClient.deleteAdditionalDataTaskTypeById(
              inputRecord,
              callBackData.recordId,
              true,
              true,
              expectedCodes
            );
          setIsLoadingScreen(false);
          reloadGridCommand();
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    }
  };

  //Get task type by Filter
  const reloadDataGrid = async () => {
    switch (type) {
      case AdditionalDataRelatedTypesEnum.taskType:
        return getByTaskTypeId();
      default:
        break;
    }
  };

  const getByTaskTypeId = async () => {
    const { getTaskTypeById } = new TaskTypeService();
    const responseTaskDesicion = await getTaskTypeById(
      inputRecord,
      false,
      true,
      [200, 404]
    );
    if (responseTaskDesicion) {
      setDecisionTask(responseTaskDesicion);
    }

    try {
      const { getAdditionalForm } = new TaskTypeService();
      const responseForms = await getAdditionalForm(inputRecord);
      setForms(
        responseForms.map(
          (item) =>
            ({
              id: item.id,
              type: TaskTypeAdditionalDataType.FORM,
              description: item.description,
              order: null,
              isMandatory: null,
              json: (item as any)?.jsonDesigner
                ? JSON.parse((item as any)?.jsonDesigner)
                : null,
            }) as TaskTypeAdditionalDataModel
        )
      );

      if (responseForms.length === 0) {
        const { getAll } = new AdditionalDataService();
        let responseRequest = await getAll(false, true, [200, 404]);
        const result: any = {};
        responseRequest.forEach((item) => {
          result[item.id] = item;
        });
        setSourceAdditionalDatas(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getByTaskTypeId ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getByTaskTypeId ex`);
    }

    let serviceClient = new AdditionalDataTaskTypeService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getByTaskTypeId(
        inputRecord,
        false,
        true,
        expectedCodes
      );
      setIsLoadingScreen(false);
      setAdditionalDatas(
        responseRequest?.map(
          (item) =>
            ({
              id: item.additionalDataId,
              type: TaskTypeAdditionalDataType.ADDITIONAL_DATA,
              description: item.localizedAdditionalDataDescription,
              order: item.order,
              isMandatory: item.isMandatory,
            }) as TaskTypeAdditionalDataModel
        ) ?? []
      );
      return [...responseRequest];
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getByTaskTypeId ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getByTaskTypeId ex`);
      setIsLoadingScreen(false);
    }
  };

  const reloadGridCommand = () => {
    reloadDataGrid()
      .then(function (result) {
        let command1: commandType = {
          type: commandsEnum.reload_grid,
          payload: result,
        };
        dispatchCommand(command1);
      })
      .catch(function (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadGridCommand ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadGridCommand ex`);
      });
  };

  //Update
  const handleUpdateClick = (id: string) => {
    // let command: any = { command: "update", recordId: id };
    // callBackCommands(command);
    setRecordId(id);
    setDetailMode("Update");
    setShowDetail(true);
  };

  //New
  const handleNewClick = (type: "data" | "form") => {
    // let command: any = { command: "new" };
    // callBackCommands(command);
    setMenuAnchorEl(null);
    setDetailMode("Insert");
    if (type === "form") {
      setShowAdditionalFormModal(true);
    } else {
      setShowDetail(true);
    }
  };
  //Refresh
  const handleRefreshClick = () => {
    reloadDataGrid();
  };

  const handleCallbackDetailResult = (modalResult: any) => {
    switch (modalResult.result) {
      case "CANCEL":
        setShowDetail(false);
        break;
      case "OK":
        setShowDetail(false);
        reloadGridCommand();
        break;
      default:
        break;
    }
  };

  //State grid and current filter
  const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: "",
    columnsAreLoaded: false,
  };
  //reducer definition
  const [adminState, dispatchCommand] = useReducer(doCommand, initialStateBLL);
  function doCommand(prevState: AdminStateType, command: commandType) {
    switch (command.type) {
      case commandsEnum.set_filterIsLoaded:
        let newStateFilter: AdminStateType;
        newStateFilter = { ...prevState };
        newStateFilter.filterIsLoaded = true;
        return newStateFilter;
      case commandsEnum.setup_grid_columns:
        let newStateColumns = { ...prevState };
        newStateColumns.columnsAreLoaded = true;
        return newStateColumns;
      case commandsEnum.reload_grid:
        let newStateGrid: AdminStateType;
        newStateGrid = { ...prevState, gridData: [] };
        const tmpAdditionaldata = [...command.payload];
        for (let i = 0; i < tmpAdditionaldata.length; i++) {
          const element: AdditionalDataTaskTypeViewModel = tmpAdditionaldata[i];
          const additionalDataElement: AdditionalDataRelateViewModel = {
            ...element,
            recordId: element.taskTypeId,
          };
          newStateGrid.gridData.push(additionalDataElement);
        }
        //newStateGrid.gridData = command.payload;
        return newStateGrid;
      case commandsEnum.change_search_pattern:
        let newStatePattern: AdminStateType;
        newStatePattern = { ...prevState };
        newStatePattern.searchPattern = command.payload;
        return newStatePattern;
      default:
        return prevState;
    }
  }

  //Run only once to load resources and active filters
  useEffect(() => {
    loadResourcesAndFilter()
      .then(function () {
        //set filter is loaded
        let command1: commandType = {
          type: commandsEnum.set_filterIsLoaded,
          payload: null,
        };
        dispatchCommand(command1);
      })
      .catch(function (error) {
        TPLog.Log(
          `Error ${componentFileName} loadResourcesAndFilter ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} loadResourcesAndFilter ex`);
      });
  }, []);

  //Run when filter is loaded to get columns
  useEffect(() => {
    if (adminState.filterIsLoaded) {
      let command1: commandType = {
        type: commandsEnum.setup_grid_columns,
        payload: null,
      };
      dispatchCommand(command1);
    }
  }, [adminState.filterIsLoaded]);

  //Run to populate grid columns when columns are loaded or
  //user change filter
  useEffect(() => {
    if (adminState.columnsAreLoaded) {
      reloadGridCommand();
    }
  }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

  return (
    <>
      {preview && (
        <div
          id="Preview"
          style={{
            background: "#ffffff",
            width: "100%",
            height: "calc(100% + 120px)",
            zIndex: "3",
            position: "absolute",
            top: "0",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              padding: "20px",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              borderRadius: "5px",
              background: "#fff7da",
            }}
          >
            <TPIcon
              iconType={TPIconTypes.warning}
              style={{ color: "#664700", fontSize: "25px" }}
            />
            <p style={{ color: "#664700", margin: 0 }}>
              {notificationViewingPreviewLabel} {" "}
              <strong
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => setPreview(null)}
              >
                {clickingHereLabel}
              </strong>
            </p>
          </div>

          <div
            style={{
              width: "95%",
              maxWidth: "800px",
              display: "flex",
              flexDirection: "column",
              border: "1px solid #bfbfbf",
              margin: "10px",
              padding: "15px",
              borderRadius: "5px",
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              {customerComplaintLabel}
            </p>

            {/* Decision Retry Task */}
            {decisionTask?.componentId === "CAREDERETRY" && (
              <TPYesNoRetryTask
                caseNumber={0}
                mode={"PREVIEW"}
                isWorkflowTask={false}
                taskIdToView={0}
                closeTaskCallBack={() => {}}
                saveTaskCallBack={() => {
                  return new Promise((resolve, reject) => {
                    resolve(true);
                  });
                }}
                additionalDataValuesSend={
                  preview.type === TaskTypeAdditionalDataType.ADDITIONAL_DATA
                    ? preview.dataSources
                    : null
                }
                formValueSend={
                  preview.type === TaskTypeAdditionalDataType.FORM
                    ? preview.dataSources[0].id
                    : null
                }
              />
            )}

            {/* Decision Task */}
            {decisionTask?.componentId === "CAREDETA" && (
              <TPYesNoTask
                caseNumber={0}
                mode={"PREVIEW"}
                isWorkflowTask={false}
                taskIdToView={0}
                closeTaskCallBack={() => {}}
                saveTaskCallBack={() => {}}
                additionalDataValuesSend={
                  preview.type === TaskTypeAdditionalDataType.ADDITIONAL_DATA
                    ? preview.dataSources
                    : null
                }
                formValueSend={
                  preview.type === TaskTypeAdditionalDataType.FORM
                    ? preview.dataSources[0].id
                    : null
                }
              />
            )}

            {/* Send email Task */}
            {decisionTask?.componentId === "CARESEEMAIL" && (
              <TPEmailTask
                caseNumber={0}
                mode={"PREVIEW"}
                taskIdToView={0}
                isWorkflowTask={false}
                closeTaskCallBack={() => {}}
                saveTaskCallBack={() => {}}
                additionalDataValuesSend={
                  preview.type === TaskTypeAdditionalDataType.ADDITIONAL_DATA
                    ? preview.dataSources
                    : null
                }
                formValueSend={
                  preview.type === TaskTypeAdditionalDataType.FORM
                    ? preview.dataSources[0].id
                    : null
                }
              />
            )}

            {/* Standard Comments Task */}
            {decisionTask?.componentId === "CARESTCO" && (
              <TPStandardTask
                caseNumber={0}
                mode={"PREVIEW"}
                taskIdToView={0}
                isWorkflowTask={false}
                closeTaskCallBack={() => {}}
                saveTaskCallBack={() => {}}
                additionalDataValuesSend={
                  preview.type === TaskTypeAdditionalDataType.ADDITIONAL_DATA
                    ? preview.dataSources
                    : null
                }
                formValueSend={
                  preview.type === TaskTypeAdditionalDataType.FORM
                    ? preview.dataSources[0].id
                    : null
                }
              />
            )}
          </div>
        </div>
      )}

      {showDetail && (
        <div
          className={showDetail ? "modal show" : "modal"}
          tabIndex={-1}
          data-bs-backdrop="static"
          style={showDetail ? { display: "block" } : { display: "none" }}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header justify-content-between border-0">
                <h5 className="modal-title">
                  {detailMode == "Insert"
                    ? titleDetailInsert
                    : titleDetailUpdate}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => setShowDetail(false)}
                ></button>
              </div>
              <div className="modal-body">
                {type == AdditionalDataRelatedTypesEnum.taskType && (
                  <TaskTypeAdditionalDataInsertUpdate
                    topic={type}
                    parentRecordId={inputRecord}
                    recordId={recordId}
                    mode={detailMode}
                    callBackResult={handleCallbackDetailResult}
                  ></TaskTypeAdditionalDataInsertUpdate>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <AdditionalDataRelateAdminFormModal
        showAdditionalFormModal={showAdditionalFormModal}
        setShowAdditionalFormModal={setShowAdditionalFormModal}
        taskTypeId={inputRecord}
        loadDataSource={getByTaskTypeId}
        handlePreview={handlePreview}
      />

      <div className={showDetail ? "modal-backdrop show" : ""}></div>
      <TPModalQuestion
        title={deleteTitle}
        yesLabel={deleteOkLabel}
        noLabel={deleteCanceLabel}
        question={modalQuestionState.callBackData?.recordId
            ? (modalQuestionState.callBackData.recordId.includes("ADF")
                ? deleteQuestionForm.replace(
                    "{recordId}",
                    modalQuestionState.callBackData.recordId
                )
                : deleteQuestion.replace(
                    "{recordId}",
                    modalQuestionState.callBackData.recordId
                ))
            : ""}
        callBackData={modalQuestionState.callBackData}
        isShown={modalQuestionState.isShown}
        callBackAnswer={handleCallBackModal}
      />

      <TPLoadingOverlay active={isLoadingScreen}>
        <DynamicTable
          data={forms.length > 0 ? forms : additionalDatas}
          columnNames={columns}
          hiddenColumns={
            forms.length > 0
                ? ["json", "isMandatory"]
                : ["json"]
          }
          columnStyles={columnStyles}
          minorOptions={forms.length ? optionsForm : options}
          onIconClicked={onClickIconHandler}
          childrenPosition="right"
          exportNameFile={"Task Type Additional Data"}
        >
          <TPButton
            id="AdditionalDataRelateAdminPreviewNewButton"
            key="AdditionalDataRelateAdminPreviewButton"
            isDesignSystem
            customType={ButtonCustomType.tertiary}
            disabled={forms.length === 0 && additionalDatas.length === 0}
            onClick={() => handlePreview()}
          >
            {previewLabel}
          </TPButton>

          <TPButton
            id="basic-button"
            aria-controls={menuOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
            customType={ButtonCustomType.secondary}
            isDesignSystem
            disabled={forms.length === 1}
            onClick={handleClick}
            style={{ padding: "11px 16px "}}
          >
            {newMenuLabel}
          </TPButton>

          <Menu
            id="basic-menu"
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              disabled={forms.length > 0}
              onClick={() => {
                handleNewClick("data");
              }}
            >
              {additionalDataLabel}
            </MenuItem>
            <MenuItem
              disabled={additionalDatas.length > 0}
              onClick={() => {
                handleNewClick("form");
              }}
            >
              {formLabel}
            </MenuItem>
          </Menu>
        </DynamicTable>
      </TPLoadingOverlay>
    </>
  );
};

export default AdditionalDataRelateAdmin;
