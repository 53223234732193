import TPGlobal from "@/helpers/TPGlobal";
import {
  RightVerticalMenuContainer,
  RightVerticalMenuLi,
  RightVerticalMenuUl,
  RightVerticalMenuWrapper,
} from "@/layouts/Main/mainMenuStyles";
import { TPI18N } from "@/services/I18nService";
import React, { FC, useEffect, useReducer, useState } from "react";
import InboundMailboxesAssignRules from "./InboundMailboxesAssignRules";
import InboundMailboxesInsertUpdate from "./InboundMailboxesInsertUpdate";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { TPPageSubTitle, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPHorizontalTabs from "@/components/TPTabs/TPHorizontalTabs";
import "./InboundMailboxesStyles.css";

type InboundMailboxesInsertUpdateContainerProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
  tabId: string;
};
enum asideMenuItemTypes {
  "general" = 0,
  "case_assign_rules" = 1,
}

enum commandsEnum {
  "create_general" = 0,
  "create_case_assign_rules_data_and_change_mode" = 1,
  "set_active_general" = 2,
  "set_active_case_assign_rules" = 3,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

interface asideMenuItemsModel {
  itemLabel: string;
  itemType: asideMenuItemTypes;
  isActive: boolean;
  isEnabled: boolean;
  isVisible: boolean;
  onClickHandler: Function;
}

enum inboundMailboxesTabs {
  "general" = 0,
  "caseAssignRules" = 1,
}

const InboundMailboxesInsertUpdateContainer: FC<
  InboundMailboxesInsertUpdateContainerProps
> = ({ mode, recordId, callBackResult, tabId }) => {
  //control state
  const resourceSet: string = "InboundMailboxesInsertUpdateContainer";
  const [titleGeneralInformation, setTitleGeneralInformation] = useState("");
  const [titleAssignRules, setTitleAssignRules] = useState("");
  const [exitLabel, setExitLabel] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [realRecordId, setRealRecordId] = useState(recordId);
  const [realRecordDescription, setRealRecordDescription] = useState("");

  const [pageTitle, setPageTitle] = useState("");
  const [pageSubtitle, setPageSubtitle] = useState("");
  const [assignTitle, setAssignTitle] = useState("");

  const [currentTab, setCurrentTab] = useState<inboundMailboxesTabs>(inboundMailboxesTabs.general);

  // handler to receive the Description
  const handleDescriptionFetched = (description: string) => {
    setRealRecordDescription(description);
  };

  //end control state

  const loadResources = async () => {
    //todo recursos del asidemenu

    setAssignTitle(
      await TPI18N.GetText("InboundMailboxesAssignRulesComponent", "TitleAssignRulesLabel"),
    );

    setPageTitle(
      await TPI18N.GetText("InboundMailboxesInsertUpdateComponent", "Title" + mode + "Label"),
    );

    setTitleGeneralInformation(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "GeneralInformationTitle",
      ),
    );
    setExitLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ExitLabel"));
    setTitleAssignRules(await TPI18N.GetText(resourceSet, "AssignRulesTitle"));
    //flag isReady
    setIsReady(true);
  };

  //handler to receive commands from child tabs
  const handleCallBackCommands = (theCommand: any) => {
    if (theCommand.result === "save_general_insert_and_change_mode") {
      //create holidays content
      let command: commandType = {
        type: commandsEnum.create_case_assign_rules_data_and_change_mode,
        payload: { recordId: theCommand.recordId },
      };
      dispatchCommand(command);
      callBackResult({
        result: "ChangeToUpdateMode",
        recordId: theCommand.recordId,
        tabId: tabId,
      });
      return;
    }

    if (theCommand.result === "save_general_insert") {
      callBackResult({ result: "OK", recordId: "" });
      return;
    }
    if (theCommand.result === "save_general_update") {
      return;
    }
    if (theCommand.result === "cancel_general") {
      callBackResult({ result: "CANCEL", recordId: theCommand.recordId });
      return;
    }
  };

  const onGeneralAsideItemClick = () => {
    let currentActive: asideMenuItemTypes;
    if (asideMenuState.filter((x: any) => x.isActive === true).length >= 1) {
      currentActive = asideMenuState.filter((x: any) => x.isActive === true)[0]
        .itemType;
      if (currentActive === asideMenuItemTypes.general) {
        //already on general
        return;
      }
    }
    let command: commandType = {
      type: commandsEnum.set_active_general,
      payload: null,
    };
    dispatchCommand(command);
    return;
  };

  const onDetailsAsideItemClick = () => {
    let command: commandType = {
      type: commandsEnum.set_active_case_assign_rules,
      payload: null,
    };
    dispatchCommand(command);
  };

  const handleOnExitClick = () => {
    callBackResult({ result: "CANCEL", recordId: realRecordId });
  };

  //create home content
  const realCreateGeneralContentCommand = (
    prevState: Array<asideMenuItemsModel>,
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let verticalElement: asideMenuItemsModel = {
      itemLabel: titleGeneralInformation,
      itemType: asideMenuItemTypes.general,
      isActive: true,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onGeneralAsideItemClick,
    };
    newStateHome.push(verticalElement);
    if (mode === "Update") {
      let verticalElement2: asideMenuItemsModel = {
        itemLabel: titleAssignRules,
        itemType: asideMenuItemTypes.case_assign_rules,
        isActive: false,
        isVisible: true,
        isEnabled: true,
        onClickHandler: onDetailsAsideItemClick,
      };
      newStateHome.push(verticalElement2);
    }
    return newStateHome;
  };

  //create details content
  const realCreateDetailsContentCommand = (
    prevState: Array<asideMenuItemsModel>,
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
    }

    let verticalElement: asideMenuItemsModel = {
      itemLabel: titleAssignRules,
      itemType: asideMenuItemTypes.case_assign_rules,
      isActive: true,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onDetailsAsideItemClick,
    };
    newStateHome.push(verticalElement);
    return newStateHome;
  };

  //set active item
  const setActiveContent = (
    prevState: Array<asideMenuItemsModel>,
    itemType: asideMenuItemTypes,
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
      if (itemType === newStateHome[i].itemType) {
        newStateHome[i].isActive = true;
      }
    }
    return newStateHome;
  };

  //aside menu state with reducer
  let initialState: Array<asideMenuItemsModel> = [];
  const [asideMenuState, dispatchCommand] = useReducer(doCommand, initialState);

  function doCommand(
    prevState: Array<asideMenuItemsModel>,
    command: commandType,
  ) {
    switch (command.type) {
      case commandsEnum.create_general:
        return realCreateGeneralContentCommand(prevState);
      case commandsEnum.create_case_assign_rules_data_and_change_mode:
        const newRecordId: string = command.payload.recordId;
        setRealRecordId(newRecordId);
        return realCreateDetailsContentCommand(prevState);
      case commandsEnum.set_active_general:
        return setActiveContent(prevState, asideMenuItemTypes.general);
      case commandsEnum.set_active_case_assign_rules:
        return setActiveContent(
          prevState,
          asideMenuItemTypes.case_assign_rules,
        );
    }
  }

  //run once to include functionsAdmin in first tab
  useEffect(() => {
    //load resources
    if (!isReady) {
      loadResources();
    } else {
      let command: commandType = {
        type: commandsEnum.create_general,
        payload: null,
      };
      dispatchCommand(command);
    }
  }, [isReady]);

  const subtitleCallback = (theSubtitle: string) => {
    setPageSubtitle(theSubtitle);
  };

  return (
    <>
      <ContentVerticalTabInsertUpdateStyled>
        <div style={{ paddingLeft: "16px", paddingRight: "32px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: "10px" }}>
            <div>
              {currentTab === inboundMailboxesTabs.general ? (
                <TPPageTitle>{pageTitle}</TPPageTitle>
              ) : (
                <TPPageTitle>{assignTitle}</TPPageTitle>
              )}
              
              {mode === "Update" ? (
                <TPPageSubTitle>
                  {pageSubtitle}
                </TPPageSubTitle>
              ) : null}
            </div>
          </div>
          <TPHorizontalTabs
            labels={[titleGeneralInformation, titleAssignRules]}
            activeTab={currentTab}
            onChange={(_, t) => {
              setCurrentTab(t);
            }}
            disabledTabs={mode === "Insert" ? [titleAssignRules] : []}
          >
          </TPHorizontalTabs>
          <div className="inbound-mailboxes-tab" style={{ width: "100%" }}>
            {currentTab === inboundMailboxesTabs.general && (
              <InboundMailboxesInsertUpdate
                callBackResult={handleCallBackCommands}
                mode={mode}
                recordId={realRecordId}
                onDescriptionFetched={handleDescriptionFetched}
                subtitleCallback={subtitleCallback}
              />
            )}
            {currentTab === inboundMailboxesTabs.caseAssignRules && (
              <InboundMailboxesAssignRules
                recordId={realRecordId}
                recordDescription={realRecordDescription}
              ></InboundMailboxesAssignRules>
            )}
          </div>
        </div>
      </ContentVerticalTabInsertUpdateStyled>
      {/* <div className="row">
        <div className="col-10">
          <ul>
            {asideMenuState.map(function (item, index) {
              let jsxFragment: any;
              let visibilityStyle: any;
              if (item.isActive) {
                visibilityStyle = { display: "block" };
              } else {
                visibilityStyle = { display: "none" };
              }
              switch (item.itemType) {
                case asideMenuItemTypes.general:
                  jsxFragment = (
                    <li key={"asidecontent" + index.toString()}>
                      <div style={visibilityStyle}>
                        <InboundMailboxesInsertUpdate
                          callBackResult={handleCallBackCommands}
                          mode={mode}
                          recordId={realRecordId}
                          onDescriptionFetched={handleDescriptionFetched}
                          subtitleCallback={subtitleCallback}
                        />
                      </div>
                    </li>
                  );
                  break;
                case asideMenuItemTypes.case_assign_rules:
                  jsxFragment = (
                    <li key={"asidecontent" + index.toString()}>
                      <div style={visibilityStyle}>
                        <InboundMailboxesAssignRules
                          recordId={realRecordId}
                          recordDescription={realRecordDescription}
                        ></InboundMailboxesAssignRules>
                      </div>
                    </li>
                  );
                  break;
                default:
                  jsxFragment = (
                    <li key={"asidecontent" + index.toString()}>
                      <div style={visibilityStyle}>
                        <span>error this should not be here</span>
                      </div>
                    </li>
                  );
                  break;
              }
              return jsxFragment;
            })}
          </ul>
        </div>

        <RightVerticalMenuContainer className="col-2 mt-4">
          <RightVerticalMenuWrapper>
            <RightVerticalMenuUl>
              {asideMenuState.map(function (item, index) {
                //render aside menu
                let jsxFragment: any;
                let visibilityStyle: any;
                if (item.isVisible) {
                  visibilityStyle = {
                    display: "block",
                    borderBottom: "1px solid #e8e8e8",
                  };
                } else {
                  visibilityStyle = {
                    display: "none",
                    borderBottom: "1px solid #e8e8e8",
                  };
                }
                switch (item.itemType) {
                  case asideMenuItemTypes.general:
                    jsxFragment = (
                      <RightVerticalMenuLi
                        className={item.isActive ? "active" : ""}
                        key={"asidemenuitem" + index.toString()}
                      >
                        <div style={visibilityStyle}>
                          <span onClick={() => item.onClickHandler()}>
                            {item.itemLabel}
                          </span>
                        </div>
                      </RightVerticalMenuLi>
                    );
                    break;
                  case asideMenuItemTypes.case_assign_rules:
                    jsxFragment = (
                      <RightVerticalMenuLi
                        className={item.isActive ? "active" : ""}
                        key={"asidemenuitem" + index.toString()}
                      >
                        <div style={visibilityStyle}>
                          <span onClick={() => item.onClickHandler()}>
                            {item.itemLabel}
                          </span>
                        </div>
                      </RightVerticalMenuLi>
                    );
                    break;
                  default:
                    jsxFragment = (
                      <RightVerticalMenuLi
                        className={item.isActive ? "active" : ""}
                        key={"asidemenuitem" + index.toString()}
                      >
                        <div style={visibilityStyle}>
                          <span>error this should not be here</span>
                        </div>
                      </RightVerticalMenuLi>
                    );
                    break;
                }
                return jsxFragment;
              })}
              <RightVerticalMenuLi
                key={"asidemenuitem" + asideMenuState.length.toString()}
                style={{ marginTop: "30px" }}
              ></RightVerticalMenuLi>
              <RightVerticalMenuLi
                key={"asidemenuitem" + (asideMenuState.length + 1).toString()}
              >
                <span onClick={() => handleOnExitClick()}>{exitLabel}</span>
              </RightVerticalMenuLi>
            </RightVerticalMenuUl>
          </RightVerticalMenuWrapper>
        </RightVerticalMenuContainer>
      </div> */}
    </>
  );
};

export default InboundMailboxesInsertUpdateContainer;
