import questionImg from "@/assets/images/question.png";
import DefaultAvatarIcon from "@/assets/images/TPIcons/DefaultAvatarIcon";
import DownArrowIcon from "@/assets/images/TPIcons/DownArrowIcon";
import UpArrowIcon from "@/assets/images/TPIcons/UpArrowIcon";
import allThemes from "@/assets/styles/theme";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import { IsActiveIcon } from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPAddtionalDataUIModes } from "@/components/TPAdditionalData/TPAdditionalDataUImodes";
import TPRenderAdditionalDataSwitch from "@/components/TPAdditionalData/TPRenderAdditionalDataSwitch";
import FileUploader from "@/components/TPDragAndDropUploadFile/FileUploader";
import TPFixedTable from "@/components/TPFixedTable/TPFixedTable";
import { TPGenAIHeader } from "@/components/TPGenAIMailKeyPoints/TPGenAIHeader";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPEmailTask } from "@/components/TPTasks/TPEmailTask";
import { TPStandardTask } from "@/components/TPTasks/TPStandardTask";
import { TPStandardTaskAutomatic } from "@/components/TPTasks/TPStandardTaskAutomatic";
import { TPYesNoRetryTask } from "@/components/TPTasks/TPYesNoRetryTask";
import { TPYesNoTask } from "@/components/TPTasks/TPYesNoTask";
import { TPYesNoTaskAutomatic } from "@/components/TPTasks/TPYesNoTaskAutomatic";
import { TPTooltip } from "@/components/TPTooltip/TPTooltip";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import TPModal, { TPModalProps } from "@/layouts/TPModal/TPModal";
import TPModalAddTask, {
  TPModalAddTaskState,
} from "@/layouts/TPModalAddTask/TPModalAddTask";
import TPModalAddTaskComment, {
  TPModalAddTaskCommentsState,
} from "@/layouts/TPModalAddTaskComment/TPModalAddTaskComment";
import TPModalPDFViewer, {
  TPModalPDFViewerStateType,
} from "@/layouts/TPModalPDFViewer/TPModalPDFViewer";
import { TPModalTaskStatusConventionsStateType } from "@/layouts/TPModalTaskStatusConventions/TPModalTaskStatusConventions";
import {
  TabsCaseViewerSlice,
  TabsCaseViewerStateModel,
} from "@/layouts/VerticalTabs/_redux/TabsCaseViewerSlice";
import {
  AdditionalDataCaseViewModel,
  AdditionalDataViewModel,
} from "@/models/AdditionalData/AdditionalDataModels";
import {
  AttachmentInputDTO,
  AttachmentInputDTODTOValidator,
} from "@/models/Attachments/AttachmentInputDTO";
import { CaseStatus } from "@/models/Cases/CaseStatus";
import { CasesViewModel } from "@/models/Cases/CasesViewModels";
import { ClientAdminViewModel } from "@/models/Client/ClientAdminViewModel";
import {
  ActionTypeEnum,
  AttachmentType,
  ModalSizeEnum,
  SignalRSubtopicEnum,
  taskStatusEnum,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { ReminderResponse } from "@/models/MyReminders/MyReminders";
import {
  AdditionalDataCompareViewModel,
  TaskViewModel,
} from "@/models/Task/TaskModels";
import { WorkflowDetailViewModel } from "@/models/Workflow/WorkflowTypeModels";
import { MenuItemButton } from "@/modules/core/components/dynamic-table/dynamic-table-styles";
import { CaseIcon } from "@/modules/core/components/dynamic-table/TableIcons";
import { TaskReminderButton } from "@/modules/core/components/task-reminder/TaskReminderButton";
import { camelCaseToId } from "@/modules/core/utils/text-regex";
import { StoreModel } from "@/redux/store";
import { AdditionalDataCaseService } from "@/services/AdditionalDataCaseService";
import { AttachmentService } from "@/services/AttachmentService";
import { CaseService } from "@/services/CaseService";
import { ClientService } from "@/services/ClientService";
import { ContactsService } from "@/services/ContactsService";
import { DownloadFileService } from "@/services/DownloadFileService";
import { TPI18N } from "@/services/I18nService";
import { RemindersService } from "@/services/MyRemindersService";
import { StorageService } from "@/services/StorageService";
import { TaskService } from "@/services/TaskService";
import { WorkflowDetailPredecessorService } from "@/services/WorkflowDetailPredecessorService";
import { WorkflowDetailService } from "@/services/WorkflowDetailService";
import { WorkflowTypeService } from "@/services/WorkflowTypeService";
import { Icon } from "@iconify/react";
import {
  AccordionDetails,
  Popover,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { TPSendEmailAutomatic } from "../../components/TPTasks/ TPSendEmailAutomatic";
import TPModalNewClient from "../../layouts/TPModalNewClient/TPModalNewClient";
import VerticalTabCaseTimeListener from "../../layouts/VerticalTabs/VerticalTabCaseTimeListener";
import {
  GroupTakeNextSlice,
  GroupTakeNextSliceModel,
} from "../GroupTakeNext/GroupTakeNextSlice";
import {
  MyRemindersSlice,
  MyRemindersSliceModel,
} from "../MyReminders/MyRemindersSlice";
import {
  StyledAccordion,
  StyledAccordionHeader,
  StyledAccordionHeaderOptions,
  StyledTimelineContent,
  StyledTimelineHeader,
} from "./case-viewer-styles";
import CaseStatusModal from "./CaseStatusModal";
import ReassignCase from "./ReassignCase";
import ReclassifyCase from "./ReclassifyCase";
import StatusCircle, { CaseStatusType } from "./StatusCircle";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { UserViewModel } from "@/models/Users/UserModels";
import { UserService } from "@/services/UserService";
import ReassignRejectModal from "./ReassignRejectModal";
import { CaseToolbarService } from "@/services/CaseToolbarService";
import * as availableIcons from "react-icons/md";
import { IconType } from "react-icons";
import {
  CaseToolbarSlice,
  CaseToolbarSliceModel,
} from "../CaseToolbar/Assets/CaseToolbarSlice";

enum commandsCaseAndCustomerInfoEnum {
  "load_case_info" = 0,
  "load_customer_info" = 1,
  "change_currentTab" = 2,
  "change_case_accordion_info" = 3,
  "change_customer_accordion_info" = 4,
  "change_case_status" = 5,
}

type commandCaseAndCustomerInfoType = {
  type: commandsCaseAndCustomerInfoEnum;
  payload: any;
};

enum currentTabsEnum {
  "case" = 0,
  "customer" = 1,
  "workflow" = 2,
}

enum currentAccordionsCaseEnum {
  "NONE" = 0,
  "CLASSIFICATION" = 1,
  "COMMENTS" = 2,
  "TPGENIA" = 2,
  "ATTACHMENTS" = 3,
  "ADDITIONALDATA" = 4,
}

enum currentAccordionsCustomerEnum {
  "NONE" = 0,
  "HISTORY" = 1,
  "CONTACTS" = 2,
  "ATTACHMENTS" = 3,
}

enum currentAccordionsEmailMenu {
  "ORIGIN" = 0,
  "TRANSLATION" = 1,
}

type TaskByStatus = {
  SO: string;
  PE: string;
  EX: string;
  LA: string;
};

const TOOLBAR_COMPONENT_TYPE = "CASETOOLBARITEMLINK";

export type CaseInfoStateType = {
  caseNumber: number;
  caseStatusId: string;
  caseStatusLocalizedDescription: string;
  workflowTypeId: string;
  workflowTypeLocalizedDescription: string;
  classificator1: string;
  classificator2: string;
  classificator3: string;
  classificator4: string;
  classificator5: string;
  comments: string;
  clientId: number;
  subsidiaryId: number;
  subSidiaryOrgId: number;
  promiseDate: string;
  promiseDateFormatted: string;
  insertDateFormatted: string;
  closeDateFormatted: string;
  closeDate: string;
  typistUserId: string;
  typistName: string;
  caseResponsibleName: string;
  baseLevelId: string;
  baseLevelDescription: string;
  classificator1Label: string;
  classificator1Value: string;
  classificator2Label: string;
  classificator2Value: string;
  classificator3Label: string;
  classificator3Value: string;
  classificator4Label: string;
  classificator4Value: string;
  classificator5Label: string;
  classificator5Value: string;
  gridCustomerAttachmentsData: Array<any>;
  gridCaseAttachmentsData: Array<any>;
  additionalDataValues: Array<TPKeyValue>;
  customerNames: string;
  customerType: string;
  customerDocumentType: string;
  customerDocument: string;
  customerEmail: string;
  customerPhone: string;
  customerInternalCode: string;
  isAnonymousCustomer: boolean;
  gridCustomerContacts: Array<any>;
  gridCustomerHistory: Array<any>;
  currentTab: currentTabsEnum;
  alreadyClickCustomerTab: boolean;
  alreadyClickClassifiers: boolean;
  alreadyClickCaseAttachments: boolean;
  alreadyClickAdditionalDataCase: boolean;
  alreadyClickCustomerAttachments: boolean;
  alreadyClickCustomerContacts: boolean;
  alreadyClickCustomerHistory: boolean;
  currentAccordionCase: currentAccordionsCaseEnum;
  currentAccordionCustomer: currentAccordionsCustomerEnum;
  contactId: number;
  contactName: string;
};

enum commandsTimelineEnum {
  "load_timeline" = 0,
  "change_ordered_Timeline" = 1,
}

type commandTimelineType = {
  type: commandsTimelineEnum;
  payload: any;
};

enum taskTypeENUM {
  "DecisionRetryTask" = "CAREDERETRY",
  "DecisionTask" = "CAREDETA",
  "SendEmailTask" = "CARESEEMAIL",
  "SendEmailAutomatic" = "CARESEEMAUTA",
  "StandarCommentTask" = "CARESTCO",
  "SystemTask" = "CARESYTA",
  "AutomaticAnswerTask" = "CAREAUANTA",
  "DecisionTypeAutomatic" = "CAREDEAUTA",
  "ReclassifyCase" = "CARERECACO",
}

type TimelineStateType = {
  items: Array<TaskViewModel>;
  currentOrder: string;
};

enum commandsTaskViewerEnum {
  "add_task" = 0,
  "click_existing_task" = 1,
  "remove_task" = 2,
}

type commandTaskViewerType = {
  type: commandsTaskViewerEnum;
  payload: any;
};

type TaskViewerStateType = {
  items: Array<TaskViewModel>;
};

type workflowInfoStateType = {
  alreadyClickWorkflowTab: boolean;
  currentWorkflowImage: string;
  gridData: Array<WorkflowDetailViewModel>;
  predecessors: Array<TPKeyValue>;
};

type DiagramFullScreenModalType = {
  classModalDiagram: string;
  styleModalDiagram: any;
  backdropClass: string;
  title: string;
};

type CaseAttachmentType = {
  sender: string;
  description: string;
  filesDraggedList: Array<any>;
  senderErrorMessage: string;
  descriptionErrorMessage: string;
  filesDraggedListErrorMessage: Array<string>;
  notFilesErrorMessage: string;
  [key: string]: any;
};

interface CaseViewerInterface {
  caseNumberToView: number;
}

interface ExpandedState {
  [key: string]: boolean;
}

const componentFileName: string = "CaseViewer.tsx";
const resourceSet: string = "CaseViewerComponent";
const resourceSetNewCase: string = "NewCaseComponent";

type PanelOptions = {
  id: string;
  label: string;
  onOptionClick: (event: React.MouseEvent<HTMLLIElement>) => void;
  icon: string;
};

type TaskStatusId = "SO" | "PE" | "EX" | "LA";

type MenuItemProps = {
  label: string;
  actionType: ActionTypeEnum;
  id: string;
  handleCloseTaskPanel: (
    e:
      | React.MouseEvent<HTMLLIElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement>,
  ) => void;
  onClick: (actionType: ActionTypeEnum) => void;
};

const CaseViewer = ({ caseNumberToView }: CaseViewerInterface) => {
  const { seconds } = useSelector(
    (s: StoreModel) => s[TabsCaseViewerSlice.name],
  ) as TabsCaseViewerStateModel;

  const initialCaseAndCustomerInfoState: CaseInfoStateType = {
    caseNumber: 0,
    caseStatusId: "",
    caseStatusLocalizedDescription: "",
    workflowTypeId: "",
    workflowTypeLocalizedDescription: "",
    classificator1: "",
    classificator2: "",
    classificator3: "",
    classificator4: "",
    classificator5: "",
    comments: "",
    clientId: 0,
    subsidiaryId: 0,
    subSidiaryOrgId: 0,
    promiseDate: "",
    promiseDateFormatted: "",
    insertDateFormatted: "",
    closeDateFormatted: "",
    closeDate: "",
    typistUserId: "",
    typistName: "",
    caseResponsibleName: "",
    baseLevelId: "",
    baseLevelDescription: "",
    classificator1Label: "",
    classificator1Value: "",
    classificator2Label: "",
    classificator2Value: "",
    classificator3Label: "",
    classificator3Value: "",
    classificator4Label: "",
    classificator4Value: "",
    classificator5Label: "",
    classificator5Value: "",
    gridCustomerAttachmentsData: [],
    gridCaseAttachmentsData: [],
    additionalDataValues: [],
    customerNames: "",
    customerType: "",
    customerDocumentType: "",
    customerDocument: "",
    customerEmail: "",
    customerPhone: "",
    customerInternalCode: "",
    isAnonymousCustomer: false,
    gridCustomerContacts: [],
    gridCustomerHistory: [],
    currentTab: currentTabsEnum.case,
    alreadyClickCustomerTab: false,
    alreadyClickClassifiers: false,
    alreadyClickCaseAttachments: false,
    alreadyClickAdditionalDataCase: false,
    alreadyClickCustomerAttachments: false,
    alreadyClickCustomerContacts: false,
    alreadyClickCustomerHistory: false,
    currentAccordionCase: currentAccordionsCaseEnum.NONE,
    currentAccordionCustomer: currentAccordionsCustomerEnum.NONE,
    contactId: 0,
    contactName: "",
  };

  const [caseAndCustomerInfoState, dispatchCaseAndCustomerInfoCommand] =
    useReducer(doCaseInfoCommand, initialCaseAndCustomerInfoState);

  //new customer
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [isShownModalNewClient, setIsShownModalNewClient] = useState(false);
  const [clientMode, setClientMode] = useState("Insert");
  const [currentSubsidiaryOrgId, setCurrentSubsidiaryOrgId] = useState(-1);

  const childRefArray: any = useRef([]);
  const reclassifyCaseRef = useRef<any>(null);
  const reassignCaseRef = useRef<any>(null);
  const dispatch = useDispatch();
  const casecontext: any = React.useContext(TPCaseViewerContext);
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  const [isLoadingScreenModalAttachment, setIsLoadingScreenModalAttachment] =
    useState(false);
  const [isTaskLoading, setIsTaskLoading] = useState<boolean>(false);

  const [titleLabel, setTitleLabel] = useState("");
  const [timelineTitleLabel, setTimelineTitleLabel] = useState("");
  const [visitedTaskTitleLabel, setVisitedTaskTitleLabel] = useState("");
  const [workflowTaskLabel, setWorkflowTaskLabel] = useState("");
  const [workflowLabel, setWorkflowLabel] = useState("");
  const [showAllLabel, setShowAllLabel] = useState("");
  const [hideAllLabel, setHideAllLabel] = useState("");
  const [creationDateLabel, setCreationDateLabel] = useState("");
  const [startDateLabel, setStartDateLabel] = useState("");
  const [readerLabel, setReaderLabel] = useState("");
  const [workflowDiagramLabel, setWorkflowDiagramLabel] = useState("");
  const [contactNameLabel, setContactNameLabel] = useState("");
  const [caseTabLabel, setCaseTabLabel] = useState("");
  const [customerTabLabel, setCustomerTabLabel] = useState("");
  const [customerNamesLabel, setCustomerNamesLabel] = useState("");
  const [classificationCaseLabel, setClassificationCaseLabel] = useState("");
  const [commentsCaseLabel, setCommentsCaseLabel] = useState("");
  const [casePrintOutLabel, setCasePrintOutLabel] = useState("");
  const [reclassifyCaseLabel, setReclassifyCaseLabel] = useState("");
  const [reassignCaseLabel, setReassignCaseLabel] = useState("");
  const [handleTimeLabel, setHandleTimeLabel] = useState("");
  const [attachmentsCaseLabel, setAttachmentsCaseLabel] = useState("");
  const [addNewAttachmentsCaseLabel, setAddCaseAttachmentLabel] = useState("");
  const [additionalDataCaseLabel, setAdditionalDataCaseLabel] = useState("");
  const [baseLevelLabel, setBaseLevelLabel] = useState("");
  const [customerResultsNamesLabel, setCustomerResultsNamesLabel] =
    useState("");
  const [customerResultsTypeLabel, setCustomerResultsTypeLabel] = useState("");
  const [
    customerResultsDocumentTypeLabel,
    setCustomerResultsDocumentTypeLabel,
  ] = useState("");
  const [customerResultsDocumentLabel, setCustomerResultsDocumentLabel] =
    useState("");
  const [customerResultsPhoneLabel, setCustomerResultsPhoneLabel] =
    useState("");
  const [customerResultsEmailLabel, setCustomerResultsEmailLabel] =
    useState("");
  const [
    customerResultsInternalCodeLabel,
    setCustomerResultsInternalCodeLabel,
  ] = useState("");
  const [actionPanelTitleLabel, setActionPanelTitleLabel] = useState("");
  const [addCommentActionPanelLabel, setAddCommentActionPanelLabel] =
    useState("");
  const [addTaskActionPanelLabel, setAddTaskActionPanelLabel] = useState("");
  const [deleteQuestion, setDeleteQuestion] = useState("");
  const [deleteTitle, setDeleteTitle] = useState("");
  const [deleteOkLabel, setDeleteOkLabel] = useState("");
  const [deleteCanceLabel, setDeleteCancelLabel] = useState("");
  const [newAttachmentTitleLabel, setNewAttachmentTitleLabel] = useState("");
  const [tabAttachmentsLabel, setTabAttachmentsLabel] = useState("");
  const [orderColumnLabel, setOrderColumnLabel] = useState("");
  const [taskTypeColumnLabel, setTaskTypeColumnLabel] = useState("");
  const [slaColumnLabel, setSlaColumnLabel] = useState("");
  const [predecessorsColumnLabel, setPredecessorsColumnLabel] = useState("");
  const [senderLabel, setSenderLabel] = useState("");
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [dropFilesHereLabel, setDropFilesHereLabel] = useState("");
  const [uploadFileButtonLabel, setUploadFileButtonLabel] = useState("");
  const [closeCustomerColumnLabel, setCloseCustomerColumnLabel] = useState("");
  const [currentClassificationCaseLabel, setCurrentClassificationCaseLabel] =
    useState("");
  const [caseCreatorLabel, setCaseCreatorLabel] = useState("");
  const [caseDateLabel, setCaseDateLabel] = useState("");
  const [customerPromiseSolvingdateLabel, setCustomerPromiseSolvingdateLabel] =
    useState("");
  const [closingdateLabel, setClosingDateLabel] = useState("");
  const [caseResponsibleLabel, setCaseResponsibleLabel] = useState("");
  const [defineResponsibleColumnLabel, setDefineResponsibleColumnLabel] =
    useState("");
  const [contactsLabel, setContactsLabel] = useState("");
  const [deleteTitleColumnLabel, setDeleteTitleColumnLabel] = useState("");
  const [deleteAttachmentConfirmLabel, setDeleteAttachmentConfirmLabel] =
    useState("");
  const [historyCaseLabel, setHistoryCaseLabel] = useState("");
  const [refreshTimeLabel, setRefreshTimeLabel] = useState("");
  const [orderTimeLabel, setOrderTimeLabel] = useState("");
  const [taskStatusTimeLabel, setTaskStatusTimeLabel] = useState("");
  const [responsibleTimeLabel, setResponsibleTimeLabel] = useState("");
  const [dueDateTimeLabel, setDueDateTimeLabel] = useState("");
  const [reassignMenuItemLabel, setReassignMenuItemLabel] = useState("");
  const [rejectMenuItemLabel, setRejectMenuItemLabel] = useState("");

  const [caseViewerGuid, setCaseViewerGuid] = useState(
    uuidv4().replaceAll("-", ""),
  );
  const [caseLabel, setCaseLabel] = useState("");

  const [caseType, setCaseType] = useState("");
  const [formattedTime, setFormattedTime] = useState<string | null>(null);

  const initialWorkflowState: workflowInfoStateType = {
    alreadyClickWorkflowTab: false,
    currentWorkflowImage: "",
    gridData: [],
    predecessors: [],
  };
  const [workflowState, setWorkflowState] =
    useState<workflowInfoStateType>(initialWorkflowState);

  const initialDiagramModal: DiagramFullScreenModalType = {
    classModalDiagram: "modal",
    styleModalDiagram: { display: "none" },
    backdropClass: "",
    title: "",
  };
  const [diagramModal, setDiagramModal] =
    useState<DiagramFullScreenModalType>(initialDiagramModal);

  const initialPDFModal: TPModalPDFViewerStateType = {
    isShown: false,
    title: "",
    pdfBase64: "",
    fileName: "",
    callBackFunction: TPGlobal.foo,
  };
  const [modalPDF, setModalPDF] =
    useState<TPModalPDFViewerStateType>(initialPDFModal);

  const [emailStatus, setEmailStatus] = useState<currentAccordionsEmailMenu>(
    currentAccordionsEmailMenu.TRANSLATION,
  );
  const [originalEmail, setOriginalEmail] = useState<string>("");
  const [insertionType, setInsertionType] = useState<string>("NEWCASE");

  let modalQuestionInitialState: TPModalQuestionState = {
    isShown: false,
    callBackData: {},
  };
  const [modalQuestionState, setModalQuestionState] = useState(
    modalQuestionInitialState,
  );
  const [
    modalQuestionDeleteAttachmentState,
    setModalQuestionDeleteAttachmentState,
  ] = useState(modalQuestionInitialState);

  let modalNewTaskInitalState: TPModalAddTaskState = {
    isShown: false,
    callBackData: undefined,
  };
  const [modalNewTaskState, setModalNewTaskState] = useState(
    modalNewTaskInitalState,
  );

  let modalNewCommentState: TPModalAddTaskCommentsState = {
    isShown: false,
    callBackData: undefined,
  };
  const [modalNewCommentsState, setModalNewCommentsState] =
    useState(modalNewCommentState);

  let modalTaskStatusConventionsInitialState: TPModalTaskStatusConventionsStateType =
    {
      isShown: false,
      title: "",
      yesLabel: "",
      callBackFunction: TPGlobal.foo,
    };
  const [modalTaskStatusConventions, setModalTaskStatusConventions] = useState(
    modalTaskStatusConventionsInitialState,
  );
  const [expandedCards, setExpandedCards] = useState<ExpandedState>({});
  const [gridContactsColumns, setGridContactsColumns] = useState<Array<any>>(
    [],
  );
  const [gridHistoryColumns, setGridHistoryColumns] = useState<Array<any>>([]);

  const [gridCustomerAttachmentsColumns, setCustomerAttachmentsColumns] =
    useState<Array<any>>([]);
  const [gridCaseAttachmentsColumns, setCaseAttachmentsColumns] = useState<
    Array<any>
  >([]);
  const [isSownModalAttachment, setIsSownModalAttachment] = useState(false);
  const [currentSelectedTaskId, setCurrentSelectedTaskId] = useState(0);
  const [toggleCaseZone, setToogleCaseZone] = useState(true);
  const [toogleActionPanel, setToogleActionPanel] = useState(false);

  const initialStateModalDifferences: TPModalProps = {
    isShown: false,
    titleModal: "",
    acceptLabel: "",
    cancelLabel: "",
    callBackAnswer: TPGlobal.foo,
  };
  const [modalDifferences, setModalDifferences] = useState<TPModalProps>(
    initialStateModalDifferences,
  );
  const [questionModalDifferencesLabel, setQuestionModalDifferencesLabel] =
    useState("");

  const initialStateModalReclassifyCase: TPModalProps = {
    isShown: false,
    titleModal: "Reclassify Case",
    acceptLabel: "Ok",
    cancelLabel: "Cancel",
    callBackAnswer: TPGlobal.foo,
    modalWidth: ModalSizeEnum.MODALXL,
  };

  const [isReclassifying, setIsReclassifying] = useState(false);

  const initialStateModalReassignCase: TPModalProps = {
    isShown: false,
    titleModal: "Reassign Case",
    acceptLabel: "Ok",
    cancelLabel: "Cancel",
    callBackAnswer: TPGlobal.foo,
    modalWidth: ModalSizeEnum.MODALXL,
    enableAcceptButton: true,
  };

  const [modalReclassifyProps, setModalReclassifyProps] =
    useState<TPModalProps>(initialStateModalReclassifyCase);
  const [modalReassignProps, setModalReassignProps] = useState<TPModalProps>(
    initialStateModalReassignCase,
  );
  const [additionalDataDifferences, setAdditionalDataDifferences] = useState<
    Array<AdditionalDataCompareViewModel>
  >([]);

  const initialCaseAttachmentType: CaseAttachmentType = {
    sender: TPGlobal.currentUserName,
    description: "",
    senderErrorMessage: "",
    descriptionErrorMessage: "",
    filesDraggedList: [],
    filesDraggedListErrorMessage: [],
    notFilesErrorMessage: "",
  };
  const [caseAttachmentState, setCaseAttachmentState] = useState(
    initialCaseAttachmentType,
  );

  const loadResourcesAndInitialInfo = async () => {
    let newTimelineData: Array<TaskViewModel>;
    let newGridAttachmentsColumns: Array<any> = [];
    let newGridCaseAttachmentsColumns: Array<any> = [];
    let newGridContactsColumns: Array<any> = [];
    let newGridHistoryColumns: Array<any> = [];
    let newCaseInfo: CasesViewModel | null;

    setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
    setTimelineTitleLabel(
      await TPI18N.GetText(resourceSet, "TimelineTitleLabel"),
    );
    setVisitedTaskTitleLabel(
      await TPI18N.GetText(resourceSet, "VisitedTaskTitleLabel"),
    );

    setWorkflowTaskLabel(
      await TPI18N.GetText(resourceSet, "WorkflowTaskLabel"),
    );
    setWorkflowLabel(await TPI18N.GetText(resourceSet, "WorkflowLabel"));
    setShowAllLabel(await TPI18N.GetText(resourceSet, "ShowMoreLabel"));
    setHideAllLabel(await TPI18N.GetText(resourceSet, "HideAllLabel"));
    setCreationDateLabel(
      await TPI18N.GetText(resourceSet, "CreationDateLabel"),
    );
    setStartDateLabel(await TPI18N.GetText(resourceSet, "StartDateLabel"));
    setReaderLabel(await TPI18N.GetText(resourceSet, "ReaderLabel"));
    setWorkflowDiagramLabel(
      await TPI18N.GetText(resourceSet, "WorkflowDiagramLabel"),
    );
    setCaseLabel(await TPI18N.GetText(resourceSet, "CaseLabel"));
    setContactNameLabel(await TPI18N.GetText(resourceSet, "ContactNameLabel"));
    setCaseTabLabel(await TPI18N.GetText(resourceSet, "CaseTabLabel"));
    setCustomerTabLabel(await TPI18N.GetText(resourceSet, "CustomerTabLabel"));
    setCustomerNamesLabel(
      await TPI18N.GetText(resourceSet, "CustomerNamesLabel"),
    );
    setReminderCountLabel(await TPI18N.GetText(resourceSet, "ReminderCount"));
    setClassificationCaseLabel(
      await TPI18N.GetText(resourceSet, "ClassificationCaseLabel"),
    );
    setCommentsCaseLabel(
      await TPI18N.GetText(resourceSet, "CommentsCaseLabel"),
    );
    setCasePrintOutLabel(
      await TPI18N.GetText(resourceSet, "CasePrintOutLabel"),
    );
    setReclassifyCaseLabel(
      await TPI18N.GetText(resourceSet, "ReclassifyCaseLabel"),
    );
    setReassignCaseLabel(
      await TPI18N.GetText(resourceSet, "ReassignCaseLabel"),
    );
    setHandleTimeLabel(await TPI18N.GetText(resourceSet, "HandleTimeLabel"));

    setAttachmentsCaseLabel(
      await TPI18N.GetText(resourceSet, "AttachmentsCaseLabel"),
    );
    setAddCaseAttachmentLabel(
      await TPI18N.GetText(resourceSet, "AddCaseAttachmentLabel"),
    );
    setAdditionalDataCaseLabel(
      await TPI18N.GetText(resourceSet, "AdditionalDataCaseLabel"),
    );
    setCustomerResultsNamesLabel(
      await TPI18N.GetText(resourceSetNewCase, "CustomerResultsNamesLabel"),
    );
    setCustomerResultsTypeLabel(
      await TPI18N.GetText(resourceSetNewCase, "CustomerResultsTypeLabel"),
    );
    setCustomerResultsDocumentTypeLabel(
      await TPI18N.GetText(
        resourceSetNewCase,
        "CustomerResultsDocumentTypeLabel",
      ),
    );
    setCustomerResultsDocumentLabel(
      await TPI18N.GetText(resourceSetNewCase, "CustomerResultsDocumentLabel"),
    );
    setCustomerResultsEmailLabel(
      await TPI18N.GetText(resourceSetNewCase, "CustomerResultsEmailLabel"),
    );
    setCustomerResultsPhoneLabel(
      await TPI18N.GetText(resourceSetNewCase, "CustomerResultsPhoneLabel"),
    );
    setCustomerResultsInternalCodeLabel(
      await TPI18N.GetText(
        resourceSetNewCase,
        "CustomerResultsInternalCodeLabel",
      ),
    );
    setBaseLevelLabel(await TPI18N.GetText(resourceSet, "BaseLevelLabel"));
    setTabAttachmentsLabel(
      await TPI18N.GetText(resourceSetNewCase, "TabAttachmentsLabel"),
    );

    setOrderColumnLabel(await TPI18N.GetText(resourceSet, "OrderColumnLabel"));
    setTaskTypeColumnLabel(
      await TPI18N.GetText(resourceSet, "TaskTypeColumnLabel"),
    );
    setSlaColumnLabel(await TPI18N.GetText(resourceSet, "SLAColumnLabel"));
    setPredecessorsColumnLabel(
      await TPI18N.GetText(resourceSet, "PredecessorsColumnLabel"),
    );
    setNewAttachmentTitleLabel(
      await TPI18N.GetText(resourceSet, "NewAttachmentTitleLabel"),
    );
    setDropFilesHereLabel(
      await TPI18N.GetText(resourceSetNewCase, "DropFilesHereLabel"),
    );
    setUploadFileButtonLabel(
      await TPI18N.GetText(resourceSetNewCase, "UploadFileButtonLabel"),
    );
    setCloseCustomerColumnLabel(
      await TPI18N.GetText(resourceSet, "CloseCustomerColumnLabel"),
    );
    setDefineResponsibleColumnLabel(
      await TPI18N.GetText(resourceSet, "DefineResponsibleColumnLabel"),
    );
    setDeleteTitleColumnLabel(
      await TPI18N.GetText(resourceSet, "DeleteTitleColumnLabel"),
    );
    setDeleteAttachmentConfirmLabel(
      await TPI18N.GetText(resourceSet, "DeleteAttachmentConfirmLabel"),
    );
    setActionPanelTitleLabel(
      await TPI18N.GetText(resourceSet, "ActionPanelTitleLabel"),
    );
    setAddCommentActionPanelLabel(
      await TPI18N.GetText(resourceSet, "AddCommentActionPanelLabel"),
    );
    setAddTaskActionPanelLabel(
      await TPI18N.GetText(resourceSet, "AddTaskActionPanelLabel"),
    );
    //modal
    setDeleteQuestion(
      await TPI18N.GetText(resourceSet, "RemoveTaskViewConfirm"),
    );
    setDeleteTitle(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
    );
    setDeleteOkLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
    );
    setDeleteCancelLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setQuestionModalDifferencesLabel(
      await TPI18N.GetText(resourceSet, "QuestionModalDifferencesLabel"),
    );
    setContactsLabel(await TPI18N.GetText(resourceSet, "ContactsLabel"));
    setHistoryCaseLabel(
      await TPI18N.GetText(resourceSetNewCase, "HistoryCaseLabel"),
    );
    setRefreshTimeLabel(await TPI18N.GetText(resourceSet, "RefreshTimeLabel"));
    setOrderTimeLabel(await TPI18N.GetText(resourceSet, "OrderTimeLabel"));
    setTaskStatusTimeLabel(
      await TPI18N.GetText(resourceSet, "TaskStatusTimeLabel"),
    );
    setResponsibleTimeLabel(
      await TPI18N.GetText(resourceSet, "ResponsibleTimeLabel"),
    );
    setDueDateTimeLabel(await TPI18N.GetText(resourceSet, "DueDateTimeLabel"));
    setReassignMenuItemLabel(
      await TPI18N.GetText("ReassignTaskModal", "ReassignMenuItemLabel"),
    );
    setRejectMenuItemLabel(
      await TPI18N.GetText("ReassignTaskModal", "RejectMenuItemLabel"),
    );
    setCurrentClassificationCaseLabel(
      await TPI18N.GetText(resourceSet, "CurrentClassificationCaseLabel"),
    );
    setCaseCreatorLabel(await TPI18N.GetText(resourceSet, "CaseCreatorLabel"));
    setCaseDateLabel(await TPI18N.GetText(resourceSet, "CaseDateLabel"));
    setCustomerPromiseSolvingdateLabel(
      await TPI18N.GetText(resourceSet, "CustomerPromiseSolvingdateLabel"),
    );
    setClosingDateLabel(await TPI18N.GetText(resourceSet, "ClosingDateLabel"));
    setCaseResponsibleLabel(
      await TPI18N.GetText(resourceSet, "CaseResponsibleLabel"),
    );
    setSenderLabel(await TPI18N.GetText(resourceSet, "SenderLabel"));
    setDescriptionLabel(await TPI18N.GetText(resourceSet, "DescriptionLabel"));

    //New client Modal
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );

    // Updating task responsible
    await UpdateTaskGroupsAndTypist();
    await handleGetOriginalEmail();

    newGridHistoryColumns.push({
      name: await TPI18N.GetText(
        resourceSetNewCase,
        "HistoryCaseNumberColumnLabel",
      ),
      selector: (row: { [x: string]: any }) => row["caseId"],
      cell: (row: { [x: string]: any }) => {
        return (
          <label
            onClick={() =>
              casecontext.handleAddNewCaseViewerCallBack(row["caseId"])
            }
            style={{ textDecoration: "underline" }}
          >
            {row["caseId"]}
          </label>
        );
      },
    });
    newGridHistoryColumns.push({
      name: await TPI18N.GetText(
        resourceSetNewCase,
        "HistoryCaseStatusColumnLabel",
      ),
      selector: (row: { [x: string]: any }) =>
        row["localizedCaseStatusDescription"],
      cell: (row: { [x: string]: any }) => {
        return row["localizedCaseStatusDescription"];
      },
    });
    newGridHistoryColumns.push({
      name: await TPI18N.GetText(
        resourceSetNewCase,
        "HistoryCaseCreationDateColumnLabel",
      ),
      selector: (row: { [x: string]: any }) => row["insertDateFormatted"],
      cell: (row: { [x: string]: any }) => {
        return row["insertDateFormatted"];
      },
    });
    setGridHistoryColumns(newGridHistoryColumns);
    newGridAttachmentsColumns.push({
      name: await TPI18N.GetText(
        resourceSetNewCase,
        "AttachmentsCaseNumberColumnLabel",
      ),
      selector: (row: { [x: string]: any }) => row["caseId"],
      cell: (row: { [x: string]: any }) => {
        return (
          <label
            onClick={() =>
              casecontext.handleAddNewCaseViewerCallBack(row["caseId"])
            }
            style={{ textDecoration: "underline" }}
          >
            {row["caseId"]}
          </label>
        );
      },
    });
    newGridAttachmentsColumns.push({
      name: await TPI18N.GetText(
        resourceSetNewCase,
        "AttachmentsFileNameColumnLabel",
      ),
      selector: (row: { [x: string]: any }) => row["fileName"],
      cell: (row: { [x: string]: any }) => {
        return (
          <label
            onClick={() =>
              handleCustomerAttachmentClick(
                row["blobId"],
                row["fileName"],
                row["pathFile"],
              )
            }
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {row["fileName"]}
          </label>
        );
      },
    });
    newGridAttachmentsColumns.push({
      name: await TPI18N.GetText(
        resourceSetNewCase,
        "AttachmentsCreationDateColumnLabel",
      ),
      selector: (row: { [x: string]: any }) => row["insertDateFormatted"],
      cell: (row: { [x: string]: any }) => {
        return row["insertDateFormatted"];
      },
    });
    setCustomerAttachmentsColumns(newGridAttachmentsColumns);
    newGridCaseAttachmentsColumns.push({
      name: await TPI18N.GetText(
        resourceSetNewCase,
        "AttachmentsCaseNumberColumnLabel",
      ),
      selector: (row: { [x: string]: any }) => row["caseId"],
      cell: (row: { [x: string]: any }) => {
        return (
          <label
            onClick={() =>
              casecontext.handleAddNewCaseViewerCallBack(row["caseId"])
            }
            style={{ textDecoration: "underline" }}
          >
            {row["caseId"]}
          </label>
        );
      },
    });
    newGridCaseAttachmentsColumns.push({
      name: await TPI18N.GetText(
        resourceSetNewCase,
        "AttachmentsFileNameColumnLabel",
      ),
      selector: (row: { [x: string]: any }) => row["filename"],
      cell: (row: { [x: string]: any }) => {
        return (
          <label
            onClick={() =>
              handleCustomerAttachmentClick(
                row["blobId"],
                row["filename"],
                row["pathFile"],
              )
            }
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {row["filename"]}
          </label>
        );
      },
    });
    newGridCaseAttachmentsColumns.push({
      name: await TPI18N.GetText(
        resourceSetNewCase,
        "AttachmentsCreationDateColumnLabel",
      ),
      selector: (row: { [x: string]: any }) => row["dateFormatted"],
      cell: (row: { [x: string]: any }) => {
        return row["dateFormatted"];
      },
    });
    newGridCaseAttachmentsColumns.push({
      name: await TPI18N.GetText(resourceSet, "AttachmentsDeleteColumnLabel"),
      selector: (row: { [x: string]: any }) => row["canDeleteAttachment"],
      cell: (row: { [x: string]: any }) => {
        let jsxElement: any;
        if (row["canDeleteAttachment"]) {
          <b>{row["pathFile"]}</b>;
          jsxElement = (
            <>
              {typeof row["pathFile"] === "string" &&
                !row["pathFile"].includes(TPGlobal.Attachment.MAILREADER) && (
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() => handleDeleteAttachment(row["blobId"])}
                    tooltip={deleteTitleColumnLabel}
                    icon={TPIconTypes.delete}
                  />
                )}
            </>
          );
        } else {
          jsxElement = <></>;
        }

        return jsxElement;
      },
    });
    setCaseAttachmentsColumns(newGridCaseAttachmentsColumns);
    newGridContactsColumns.push({
      name: await TPI18N.GetText(resourceSetNewCase, "ContactNameColumnLabel"),
      selector: (row: { [x: string]: any }) => row["name"],
      cell: (row: { [x: string]: any }) => {
        return <label>{row["name"]}</label>;
      },
    });
    newGridContactsColumns.push({
      name: await TPI18N.GetText(resourceSetNewCase, "ContactEmailColumnLabel"),
      selector: (row: { [x: string]: any }) => row["email"],
      cell: (row: { [x: string]: any }) => {
        return row["email"];
      },
    });
    newGridContactsColumns.push({
      name: await TPI18N.GetText(resourceSetNewCase, "ContactPhoneColumnLabel"),
      selector: (row: { [x: string]: any }) => row["phone"],
      cell: (row: { [x: string]: any }) => {
        return row["phone"];
      },
    });
    setGridContactsColumns(newGridContactsColumns);

    newCaseInfo = await getCaseInfoByNumber(caseNumberToView);

    if (newCaseInfo)
      setCurrentSubsidiaryOrgId(newCaseInfo.subsidiaryOrganizationId);

    if (newCaseInfo) {
      let command1: commandCaseAndCustomerInfoType = {
        type: commandsCaseAndCustomerInfoEnum.load_case_info,
        payload: {
          newCaseNumber: caseNumberToView,
          newCaseStatusId: newCaseInfo.caseStatusId,
          newCaseStatusLocalizedDescription:
            newCaseInfo.caseStatusLocalizedDescription,
          newWorkflowTypeId: newCaseInfo.workflowTypeId,
          newWorkflowTypeLocalizedDescription:
            newCaseInfo.workflowTypeLocalizedDescription,
          newClientId: newCaseInfo.clientId,
          newSubsidiaryId: newCaseInfo.subsidiaryId,
          newSubSidiaryOrgId: newCaseInfo.subsidiaryOrganizationId,
          newPromiseDate: newCaseInfo.promiseDate,
          newPromiseDateFormatted: newCaseInfo.promiseDateFormatted,
          newInsertDateFormatted: newCaseInfo.insertDateFormatted,
          newCloseDateFormatted: newCaseInfo.closeDateFormatted,
          newCloseDate: newCaseInfo.closeDate,
          newTypistUserId: newCaseInfo.typistUserId,
          newTypistName: newCaseInfo.typistName,
          newCaseResponsibleName: newCaseInfo.caseResponsibleName,
          newBaseLevelId: newCaseInfo.baseLevelId,
          newBaseLevelLocalizedDescription:
            newCaseInfo.baseLevelLocalizedDescription,
          newClassificator1: newCaseInfo.classify1BranchId,
          newClassificator1Label:
            newCaseInfo.classifier1BaseLevelLocalizedDescription,
          newClassificator1Value: newCaseInfo.classify1LocalizedDescription,
          newClassificator2: newCaseInfo.classify2BranchId,
          newClassificator2Label:
            newCaseInfo.classifier2BaseLevelLocalizedDescription,
          newClassificator2Value: newCaseInfo.classify2LocalizedDescription,
          newClassificator3: newCaseInfo.classify3BranchId,
          newClassificator3Label:
            newCaseInfo.classifier3BaseLevelLocalizedDescription,
          newClassificator3Value: newCaseInfo.classify3LocalizedDescription,
          newClassificator4: newCaseInfo.classify4BranchId,
          newClassificator4Label:
            newCaseInfo.classifier4BaseLevelLocalizedDescription,
          newClassificator4Value: newCaseInfo.classify4LocalizedDescription,
          newClassificator5: newCaseInfo.classify5BranchId,
          newClassificator5Label:
            newCaseInfo.classifier5BaseLevelLocalizedDescription,
          newClassificator5Value: newCaseInfo.classify5LocalizedDescription,
          newComments: newCaseInfo.comments,
          newIsAnonymousCustomer: newCaseInfo.isAnonymized,
          newContactId: newCaseInfo.contactId,
          newContactName: newCaseInfo.contactName,
        },
      };
      dispatchCaseAndCustomerInfoCommand(command1);
      let newCustomerInfo = await getCustomerInfo(
        newCaseInfo.subsidiaryOrganizationId,
      );
      if (newCustomerInfo) {
        let command1: commandCaseAndCustomerInfoType = {
          type: commandsCaseAndCustomerInfoEnum.load_customer_info,
          payload: {
            newCustomerNames: newCustomerInfo.customerName,
            newCustomerType: newCustomerInfo.customerTypeDescription,
            newCustomerDocument: newCustomerInfo.document,
            newCustomerEmail: newCustomerInfo.preferredEmail,
            newCustomerPhone: newCustomerInfo.preferredPhone,
            newCustomerInternalCode: newCustomerInfo.internalCode,
            newCustomerDocumentType: newCustomerInfo.documentTypeDescription,
          },
        };
        dispatchCaseAndCustomerInfoCommand(command1);
      }
    }

    newTimelineData = await getTaskTimelineByNumber(caseNumberToView);
    let command2: commandTimelineType = {
      type: commandsTimelineEnum.load_timeline,
      payload: {
        newItems: newTimelineData,
      },
    };
    dispatchTimelineCommand(command2);
    let newTaskItems: Array<TaskViewModel>;
    newTaskItems = [];
    for (let i: number = 0; i <= newTimelineData.length - 1; i++) {
      // if (newTimelineData[i].canResolve) {

      // }
      newTaskItems.push(newTimelineData[i]);
    }
    if (newTaskItems.length >= 1) {
      newTaskItems = newTaskItems.sort((a: TaskViewModel, b: TaskViewModel) => {
        if (moment(a.creationDate).isAfter(moment(b.creationDate))) {
          return -1;
        }
        return 1;
      });
      let command3: commandTaskViewerType = {
        type: commandsTaskViewerEnum.add_task,
        payload: {
          newClickedItem: newTaskItems[0].id,
          newItems: newTaskItems,
        },
      };
      dispatchTaskViewerCommand(command3);
    }
    setIsLoadingScreen(false);
  };

  const actionIcons: CaseIcon[] = [
    {
      type: TPIconTypes.refresh,
      tooltip: refreshTimeLabel,
      status: true,
      onIconClick: () => handleRefreshTimelineClick(),
    },
    {
      type: TPIconTypes.swapVert,
      tooltip: orderTimeLabel,
      status: true,
      onIconClick: () => handleToggleOrderClick(),
    },
    {
      type: TPIconTypes.help,
      tooltip: taskStatusTimeLabel,
      status: true,
      onIconClick: () => handleConventionsHelpClick(),
    },
  ];

  const taskByStatus: TaskByStatus = {
    SO: "solved",
    PE: "pending",
    EX: "expired",
    LA: "lag",
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [caseStatusModal, setCaseStatusModal] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const panelOpen: boolean = Boolean(anchorEl);

  const handleOpenPanel = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePanel = () => {
    setAnchorEl(null);
  };

  const [anchorTaskEl, setAnchorTaskEl] = useState<null | HTMLElement>(null);
  const [taskPanelSelected, setTaskPanelSelected] = useState<number | null>(
    null,
  );
  const panelTaskOpen: boolean = Boolean(anchorTaskEl);

  const handleOpenTaskPanel = (
    event: React.MouseEvent<HTMLElement>,
    i: number,
  ) => {
    setAnchorTaskEl(event.currentTarget);
    setTaskPanelSelected(i);
  };

  const handleCloseTaskPanel = (e: any) => {
    e.stopPropagation();
    setAnchorTaskEl(null);
    setTaskPanelSelected(null);
  };

  const { contentLoaded: toolbarItemsLoaded } = useSelector(
    (s: StoreModel) => s[CaseToolbarSlice.name],
  ) as CaseToolbarSliceModel;
  const [toolbarOptions, setToolbarOptions] = useState<PanelOptions[]>([]);
  const [toolbarOptionsLoading, setToolbarOptionsLoading] = useState(false);

  const getToolbarOptions = async function () {
    setToolbarOptionsLoading(true);
    CaseToolbarService.getItemsByUserGuid(
      TPGlobal.currentUserGuid,
      false,
      true,
      [200, 404],
    )
      .then((options) => {
        const newToolbarOptions: PanelOptions[] = [];
        options
          .sort((oA, oB) => {
            if (Number(oA.order_PCTI) < Number(oB.order_PCTI)) return -1;
            if (Number(oA.order_PCTI) > Number(oB.order_PCTI)) return 1;
            return 0;
          })
          .forEach((o) => {
            const newOption: PanelOptions = {
              id: o.id_CTBI,
              label: o.localizedDescription || o.description_CTBI || "???",
              onOptionClick: () => {},
              icon: o.image_CTBI,
            };
            switch (o.urlLink_CTBI) {
              case "TPClientCloudMain.Client.Components.Layouts.TPModalAddTaskComment":
                newOption.onOptionClick = (e) =>
                  handleAddCommentsOnClick(e, caseNumberToView);
                break;
              case "TPClientCloudMain.Client.Components.Layouts.TPModalAddTask":
                newOption.onOptionClick = (e) =>
                  handleAddTaskOnClick(e, caseNumberToView);
                break;
              case "TPClientCloudMain.Client.Components.Layouts.TPModalPDFViewer":
                newOption.onOptionClick = (e) =>
                  handleExportToPDF(e, caseNumberToView);
                break;
              case "TPClientCloudMain.Client.Components.Layouts.TPModalReassignCase":
                newOption.onOptionClick = (e) =>
                  handleReassignCase(e, caseNumberToView);
                break;
              case "TPClientCloudMain.Client.Components.Layouts.TPModalReclassifyCase":
                newOption.onOptionClick = (e) =>
                  handleReclassifyCase(e, caseNumberToView);
                break;
            }
            newOption.id.trim().length > 0 && newToolbarOptions.push(newOption);
          });
        setToolbarOptions(newToolbarOptions);
      })
      .catch((err) => console.error(err))
      .finally(() => setToolbarOptionsLoading(false));
  };

  const UpdateTaskGroupsAndTypist = async () => {
    let taskService = new TaskService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest = await taskService.UpdateTaskGroupsAndTypist(
        {
          caseid: caseAndCustomerInfoState.caseNumber,
          typistGuidUser: TPGlobal.currentUserGuid,
        },
        false,
        true,
        expectedCodes,
      );
      if (responseRequest) {
        return responseRequest;
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} UpdateTaskGroupsAndTypist ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} UpdateTaskGroupsAndTypist ex`);
      setIsLoadingScreen(false);
    }
  };

  const getCaseInfoByNumber = async (
    caseNumber: number,
  ): Promise<CasesViewModel | null> => {
    let serviceClient = new CaseService();
    let expectedCodes: Array<number> = [200];
    let responseRequest: CasesViewModel;
    try {
      setIsLoadingScreen(true);
      responseRequest = await serviceClient.getCaseInfoByCaseNumber(
        caseNumber,
        false,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest) setCaseType(responseRequest.caseType);
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`,
      );
      setIsLoadingScreen(false);
      return null;
    }
  };

  const getCustomerInfo = async (subSidiaryOrgId: number) => {
    try {
      let fullCustomerData: ClientAdminViewModel | null;
      fullCustomerData =
        await getCustomerByClientIdSubsidiaryIdAndSubOrg(subSidiaryOrgId);
      return fullCustomerData ? fullCustomerData : null;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCustomerInfo ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getCustomerInfo ex`);
      setIsLoadingScreen(false);
      return null;
    }
  };

  const getCustomerByClientIdSubsidiaryIdAndSubOrg = async (
    subsidiaryOrganizationId: number,
  ) => {
    let serviceClient = new ClientService();
    let expectedCodes: Array<number> = [200];
    let i: number;
    try {
      setIsLoadingScreen(true);
      let responseRequest =
        await serviceClient.getClientBySubsidiaryOrganizationId(
          subsidiaryOrganizationId,
          false,
          true,
          expectedCodes,
        );
      setIsLoadingScreen(false);
      return responseRequest[0];
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`,
      );
      setIsLoadingScreen(false);
      return null;
    }
  };

  const { currentCase } = useSelector(
    (state: StoreModel) => state[GroupTakeNextSlice.name],
  ) as GroupTakeNextSliceModel;

  const getTaskTimelineByNumber = async (caseNumber: number) => {
    let taskService = new TaskService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);
      let responseRequest = await taskService.getByCaseAndFilterIsWorkFlowTask(
        caseNumber,
        1,
        false,
        true,
        expectedCodes,
      );
      if (responseRequest && responseRequest.length >= 1) {
        let myPendingTasks = responseRequest
          .filter(
            (task) => task.taskStatusId == "PE" || task.taskStatusId == "EX",
          )
          .filter((t) => t.writerUserGuid == TPGlobal.currentUserGuid);
        if (myPendingTasks == null || myPendingTasks.length <= 0) {
          if (Number(caseNumberToView) == Number(casecontext.currentTakeNext)) {
            dispatch(
              TabsCaseViewerSlice.actions.close(caseNumberToView.toString()),
            );
            casecontext.handleRemoveNewCaseViewerCallBack(caseNumberToView);
          }
          if (Number(caseNumberToView) === currentCase)
            dispatch(GroupTakeNextSlice.actions.setCurrentCase(-1));
        }

        for (let i = 0; i <= responseRequest.length - 1; i++) {
          for (let j = 0; j <= taskViewerState.items.length - 1; j++) {
            if (responseRequest[i].id === taskViewerState.items[j].id) {
              let element: string = responseRequest[i].id.toString();
              const keys = Object.keys(childRefArray.current);
              if (keys && keys.findIndex((x: any) => x === element) !== -1) {
                let controlref: any = childRefArray.current[element];
                controlref.refreshTaskFromParent();
                break;
              }
            }
          }
        }
      }
      setIsLoadingScreen(false);
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getTaskTimelineByNumber ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getTaskTimelineByNumber ex`);
      setIsLoadingScreen(false);
      return [];
    }
  };
  const getWorkflowDiagram = async () => {
    let serviceClient = new WorkflowTypeService();
    let expectedCodes: Array<number> = [200];

    try {
      let responseRequest =
        await serviceClient.getDiagramByBaseLevelAndClassifiers(
          caseAndCustomerInfoState.workflowTypeId,
          caseAndCustomerInfoState.baseLevelId,
          caseAndCustomerInfoState.classificator1,
          caseAndCustomerInfoState.classificator2,
          caseAndCustomerInfoState.classificator3,
          caseAndCustomerInfoState.classificator4,
          caseAndCustomerInfoState.classificator5,
          true,
          "TB", //top bottom
          false,
          true,
          expectedCodes,
        );

      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getWorkflowDiagram ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getWorkflowDiagram ex`);

      return null;
    }
  };

  const loadWorkflowDetails = async (workflowTypeId: string) => {
    let serviceClient = new WorkflowDetailService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      let responseRequest = await serviceClient.getByWorkflowType(
        workflowTypeId,
        false,
        true,
        expectedCodes,
      );
      return [...responseRequest];
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadWorkflowDetails ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} loadWorkflowDetails ex`);
      return [];
    }
  };

  const getWorkflowDetailPredecessors = async (pIdWorkflowDetail: string) => {
    let serviceClient = new WorkflowDetailPredecessorService();
    let expectedCodes: Array<number> = [200, 404];
    let predecessors: string = "";
    try {
      let responseRequest = await serviceClient.getPredecessorByWorkflowDetail(
        pIdWorkflowDetail,
        false,
        true,
        expectedCodes,
      );

      if (responseRequest) {
        for (let i = 0; i <= responseRequest.length - 1; i++) {
          if (predecessors !== "") {
            predecessors = predecessors + " , ";
          }
          predecessors = predecessors + responseRequest[i].order.toString();
          if (responseRequest[i].conditionalPath.trim() !== "") {
            predecessors =
              predecessors + "/" + responseRequest[i].conditionalPath;
          }
        }
      }
      return predecessors;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getWorkflowDetailPredecessors ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} getWorkflowDetailPredecessors ex`,
      );
      return predecessors;
    }
  };

  const handleCustomerAttachmentClick = async (
    blobId: string,
    fileName: string,
    pathFile: string,
  ) => {
    const storageService = new StorageService();
    const downloadFileService = new DownloadFileService();
    let expectedCodes: Array<number> = [200];
    let attachmentType: AttachmentType;
    switch (pathFile) {
      case "MailReader/EML": {
        attachmentType = AttachmentType.MAILREADEREML;
        break;
      }
      case "MailReader/Attachments": {
        attachmentType = AttachmentType.MAILREADERATTACHMENT;
        break;
      }
      default: {
        attachmentType = AttachmentType.ATTACHMENT;
        break;
      }
    }
    try {
      let responseRequest = await storageService.getFileUriSas(
        blobId,
        attachmentType,
        false,
        true,
        expectedCodes,
      );
      if (responseRequest) {
        const { sasUri, mimeType } = responseRequest;
        await downloadFileService.downloadFileUri({
          sasUri,
          originalFileName: fileName,
          mimeType,
        });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleCustomerAttachmentClick ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} handleCustomerAttachmentClick ex`,
      );
      return null;
    }
  };

  const handleChangeTab = async (newCurrentTab: currentTabsEnum) => {
    if (newCurrentTab === currentTabsEnum.workflow) {
      let imageData: any;
      let workflowDetails: Array<WorkflowDetailViewModel> = [];
      if (!workflowState.alreadyClickWorkflowTab) {
        setIsLoadingScreen(true);
        let newWorkflowState: workflowInfoStateType = { ...workflowState };
        imageData = await getWorkflowDiagram();
        if (imageData) {
          newWorkflowState.currentWorkflowImage = imageData.value;
        }
        workflowDetails = await loadWorkflowDetails(
          caseAndCustomerInfoState.workflowTypeId,
        );
        if (workflowDetails) {
          newWorkflowState.gridData = workflowDetails;
          for (let i: number = 0; i <= workflowDetails.length - 1; i++) {
            let predecessorsvalue: string = await getWorkflowDetailPredecessors(
              workflowDetails[i].id.toString(),
            );
            if (predecessorsvalue) {
              if (predecessorsvalue.indexOf(",") !== -1) {
                if (workflowDetails[i].requiresAllPredecessors) {
                  predecessorsvalue = predecessorsvalue.replaceAll(
                    " , ",
                    " " +
                      (await TPI18N.GetText(
                        TPGlobal.globalResourceSet,
                        "AndOperatorText",
                      )) +
                      " ",
                  );
                  predecessorsvalue =
                    predecessorsvalue +
                    " (" +
                    (await TPI18N.GetText(resourceSet, "WhenAllLabel")) +
                    ")";
                } else {
                  predecessorsvalue = predecessorsvalue.replaceAll(
                    " , ",
                    " " +
                      (await TPI18N.GetText(
                        TPGlobal.globalResourceSet,
                        "OrOperatorText",
                      )) +
                      " ",
                  );
                  predecessorsvalue =
                    predecessorsvalue +
                    " (" +
                    (await TPI18N.GetText(resourceSet, "TheFirstLabel")) +
                    ")";
                }
              }
            }
            newWorkflowState.predecessors.push({
              key: workflowDetails[i].id.toString(),
              value: predecessorsvalue,
            });
          }
        }
        newWorkflowState.alreadyClickWorkflowTab = true;
        setWorkflowState(newWorkflowState);
        setIsLoadingScreen(false);
      }
    }
    let command1: commandCaseAndCustomerInfoType = {
      type: commandsCaseAndCustomerInfoEnum.change_currentTab,
      payload: {
        newCurrentTab: newCurrentTab,
      },
    };
    dispatchCaseAndCustomerInfoCommand(command1);
  };

  const getCustomerAttachments = async (customerId: number) => {
    let serviceClient = new ClientService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getAttachmentsClient(
        caseAndCustomerInfoState.clientId,
        TPGlobal.currentUserGuid,
        false,
        true,
        expectedCodes,
      );
      if (responseRequest && responseRequest.length > 0) {
        for (let i = 0; i <= responseRequest.length - 1; i++) {
          responseRequest[i].tpguidrow =
            "tpcustomerattachmentsrow" + uuidv4().replaceAll("-", "");
        }
      }
      setIsLoadingScreen(false);
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCustomerAttachments ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getCustomerAttachments ex`);
      setIsLoadingScreen(false);
      return null;
    }
  };

  const getCaseAttachments = async (caseId: number) => {
    let serviceClient = new CaseService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getAttachmentsByCaseId(
        caseId,
        false,
        true,
        expectedCodes,
      );
      if (responseRequest && responseRequest.length > 0) {
        for (let i = 0; i <= responseRequest.length - 1; i++) {
          responseRequest[i].tpguidrow =
            "tpCaseAttachmentsrow" + uuidv4().replaceAll("-", "");
        }
      }
      setIsLoadingScreen(false);
      return responseRequest; //responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCaseAttachments ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getCaseAttachments ex`);
      setIsLoadingScreen(false);
      return null;
    }
  };

  const getCustomerContacts = async () => {
    let serviceClient = new ContactsService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getCustomerContacts(
        caseAndCustomerInfoState.clientId,
        caseAndCustomerInfoState.subsidiaryId,
        false,
        true,
        expectedCodes,
      );
      if (responseRequest && responseRequest.length > 0) {
        for (let i = 0; i <= responseRequest.length - 1; i++) {
          responseRequest[i].tpguidrow =
            "contactsrow" + uuidv4().replaceAll("-", "");
          if (
            caseAndCustomerInfoState.contactId &&
            caseAndCustomerInfoState.contactId === responseRequest[i].contactId
          ) {
            responseRequest[i].tpselectedRow = true;
          } else {
            responseRequest[i].tpselectedRow = false;
          }
        }
      }
      setIsLoadingScreen(false);
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCustomerContacts ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getCustomerContacts ex`);
      setIsLoadingScreen(false);
      return null;
    }
  };

  const getCustomerHistory = async () => {
    let serviceClient = new CaseService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);
      let responseRequest =
        await serviceClient.getHistoryCasesBySubsidiaryOrgId(
          caseAndCustomerInfoState.subSidiaryOrgId,
          TPGlobal.currentUserGuid,
          TPActiveOptions.ALL,
          false,
          true,
          expectedCodes,
        );
      if (responseRequest && responseRequest.length > 0) {
        for (let i = 0; i <= responseRequest.length - 1; i++) {
          responseRequest[i].tpguidrow =
            "historyrow" + uuidv4().replaceAll("-", "");
        }
      }
      setIsLoadingScreen(false);
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCustomerHistory ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getCustomerHistory ex`);
      setIsLoadingScreen(false);
      return null;
    }
  };

  const getAdditionalDataCase = async (
    caseNumber: number,
  ): Promise<Array<AdditionalDataCaseViewModel>> => {
    let serviceClient = new AdditionalDataCaseService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);
      let responseRequest: Array<AdditionalDataCaseViewModel> =
        await serviceClient.getByCaseId(caseNumber, false, true, expectedCodes);
      setIsLoadingScreen(false);
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getAdditionalDataCase ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getAdditionalDataCase ex`);
      setIsLoadingScreen(false);
      return [];
    }
  };

  const loadCaseAttachments = async (reload: boolean = false) => {
    try {
      if (!caseAndCustomerInfoState.alreadyClickCaseAttachments || reload) {
        const attachmentData = await getCaseAttachments(
          caseAndCustomerInfoState.caseNumber,
        );
        if (Array.isArray(attachmentData)) {
          let command1: commandCaseAndCustomerInfoType = {
            type: commandsCaseAndCustomerInfoEnum.change_case_accordion_info,
            payload: {
              newAccordion: currentAccordionsCaseEnum.ATTACHMENTS,
              newData: attachmentData,
            },
          };
          dispatchCaseAndCustomerInfoCommand(command1);
        } else {
          TPLog.Log(
            `Error ${componentFileName} loadCaseAttachments unexpected data format`,
            TPLogType.ERROR,
            new Error(
              `Unexpected format for attachments data: ${JSON.stringify(attachmentData)}`,
            ),
          );
          console.error(
            "Unexpected format for attachments data:",
            attachmentData,
          );
        }
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadCaseAttachments ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} loadCaseAttachments ex`);
    }
  };

  const handleAccordionCaseClick = async (
    newAccordionCase: currentAccordionsCaseEnum,
  ) => {
    let newData: any;
    let additionalDataCase: Array<AdditionalDataCaseViewModel>;
    switch (newAccordionCase) {
      case currentAccordionsCaseEnum.CLASSIFICATION:
        if (!caseAndCustomerInfoState.alreadyClickClassifiers) {
          let command1: commandCaseAndCustomerInfoType = {
            type: commandsCaseAndCustomerInfoEnum.change_case_accordion_info,
            payload: {
              newAccordion: newAccordionCase,
              newData: newData,
            },
          };
          dispatchCaseAndCustomerInfoCommand(command1);
        }
        break;
      case currentAccordionsCaseEnum.ATTACHMENTS:
        await loadCaseAttachments();
        break;
      case currentAccordionsCaseEnum.ADDITIONALDATA:
        if (!caseAndCustomerInfoState.alreadyClickAdditionalDataCase) {
          additionalDataCase = await getAdditionalDataCase(
            caseAndCustomerInfoState.caseNumber,
          );
          let newAdditionalDataStructure: Array<TPKeyValue> = [];
          for (let i = 0; i < additionalDataCase.length; i++) {
            const element = additionalDataCase[i];
            let newAdditionalDataViewModel: AdditionalDataViewModel = {
              id: element.additionalDataId,
              description: element.additionalDataLocalizedDescription,
              validationURL: "",
              comments: "",
              additionalDataTypeId: element.additionalDataTypeId,
              additionalDataTypeDescription:
                element.additionalDataTypeLocalizedDescription,
              jsonParameters: element.additionalDataJsonParameters,
              categoryId: element.categoryId,
              enableEditingModifyBasicData: false,
              isSystemRecord: false,
              localizedDescription: element.additionalDataLocalizedDescription,
              order: i,
              isMandatory: false,
              additionalDataCategoryDescription: "",
            };
            newAdditionalDataStructure.push({
              key: element.additionalDataId,
              value: element.value,
              value2: newAdditionalDataViewModel,
              value3: true,
            });
          }
          newData = newAdditionalDataStructure;
          let command1: commandCaseAndCustomerInfoType = {
            type: commandsCaseAndCustomerInfoEnum.change_case_accordion_info,
            payload: {
              newAccordion: newAccordionCase,
              newData: newData,
            },
          };
          dispatchCaseAndCustomerInfoCommand(command1);
        }
        break;
    }
  };

  const handleAccordionCustomerClick = async (
    newAccordionCustomer: currentAccordionsCustomerEnum,
  ) => {
    let newData: any;
    let attachmentData: any;
    let contactsData: any;
    let historyData: any;
    switch (newAccordionCustomer) {
      case currentAccordionsCustomerEnum.HISTORY:
        if (!caseAndCustomerInfoState.alreadyClickCustomerHistory) {
          historyData = await getCustomerHistory();
          if (historyData) {
            newData = historyData;
            let command1: commandCaseAndCustomerInfoType = {
              type: commandsCaseAndCustomerInfoEnum.change_customer_accordion_info,
              payload: {
                newAccordion: newAccordionCustomer,
                newData: newData,
              },
            };
            dispatchCaseAndCustomerInfoCommand(command1);
          }
        }
        break;
      case currentAccordionsCustomerEnum.ATTACHMENTS:
        if (!caseAndCustomerInfoState.alreadyClickCustomerAttachments) {
          attachmentData = await getCustomerAttachments(
            caseAndCustomerInfoState.clientId,
          );
          newData = attachmentData;
          let command1: commandCaseAndCustomerInfoType = {
            type: commandsCaseAndCustomerInfoEnum.change_customer_accordion_info,
            payload: {
              newAccordion: newAccordionCustomer,
              newData: newData,
            },
          };
          dispatchCaseAndCustomerInfoCommand(command1);
        }
        break;
      case currentAccordionsCustomerEnum.CONTACTS:
        if (!caseAndCustomerInfoState.alreadyClickCustomerContacts) {
          contactsData = await getCustomerContacts();
          if (contactsData) {
            newData = contactsData;
            let command1: commandCaseAndCustomerInfoType = {
              type: commandsCaseAndCustomerInfoEnum.change_customer_accordion_info,
              payload: {
                newAccordion: newAccordionCustomer,
                newData: newData,
              },
            };
            dispatchCaseAndCustomerInfoCommand(command1);
          }
        }
        break;
    }
  };

  const handleShowDiagram = async () => {
    let newState: DiagramFullScreenModalType = {
      classModalDiagram: "modal show",
      styleModalDiagram: { display: "block" },
      backdropClass: "modal-backdrop show",
      title: `${workflowLabel} : ${caseAndCustomerInfoState.workflowTypeLocalizedDescription}`,
    };
    setDiagramModal(newState);
  };
  const renderCaseTabContent = () => {
    let jsxElement: any;
    jsxElement = (
      <div
        className={`tab-pane fade ${caseAndCustomerInfoState.currentTab === currentTabsEnum.case ? "show active" : ""}`}
        id="nav-case"
        role="tabpanel"
        aria-labelledby="nav-case-tab"
        style={{
          border: "1px solid #dee2e6",
          padding: "10px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div className="row mt-2">
          <div className="col-12 text-center">
            <DefaultAvatarIcon />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col" style={{ fontSize: "24px", padding: "20px" }}>
            <label className="tpbold">{customerNamesLabel}</label>
            {":"}&nbsp;
            <label style={{ textTransform: "uppercase" }}>
              {caseAndCustomerInfoState.customerNames}
            </label>
          </div>
        </div>
        <div
          style={{
            border: "1px solid  #dee2e6",
            borderTop: "none",
            height: "400px",
          }}
        >
          <div className="accordion">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={`accordion-button ${caseAndCustomerInfoState.currentAccordionCase === currentAccordionsCaseEnum.CLASSIFICATION ? "" : "collapsed"}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse1"
                  onClick={() =>
                    handleAccordionCaseClick(
                      currentAccordionsCaseEnum.CLASSIFICATION,
                    )
                  }
                >
                  {classificationCaseLabel}
                </button>
              </h2>
              <div id="collapse1" className="accordion-collapse collapse">
                <div
                  className={`accordion-body  ${caseAndCustomerInfoState.currentAccordionCase === currentAccordionsCaseEnum.CLASSIFICATION ? "show" : ""}`}
                >
                  <div className="row">
                    <div className="col">
                      <label className="tpbold">{baseLevelLabel}</label>
                      <br />
                      <label>
                        {caseAndCustomerInfoState.baseLevelDescription}
                      </label>
                      <hr style={{ marginTop: "2px", marginBottom: "2px" }} />
                    </div>
                  </div>
                  {caseAndCustomerInfoState.classificator1Label && (
                    <div className="row">
                      <div className="col">
                        <label className="tpbold">
                          {caseAndCustomerInfoState.classificator1Label}
                        </label>
                        <br />
                        <label>
                          {caseAndCustomerInfoState.classificator1Value}
                        </label>
                        <hr style={{ marginTop: "2px", marginBottom: "2px" }} />
                      </div>
                    </div>
                  )}
                  {caseAndCustomerInfoState.classificator2Label && (
                    <div className="row">
                      <div className="col">
                        <label className="tpbold">
                          {caseAndCustomerInfoState.classificator2Label}
                        </label>
                        <br />
                        <label>
                          {caseAndCustomerInfoState.classificator2Value}
                        </label>
                        <hr style={{ marginTop: "2px", marginBottom: "2px" }} />
                      </div>
                    </div>
                  )}
                  {caseAndCustomerInfoState.classificator3Label && (
                    <div className="row">
                      <div className="col">
                        <label className="tpbold">
                          {caseAndCustomerInfoState.classificator3Label}
                        </label>
                        <br />
                        <label>
                          {caseAndCustomerInfoState.classificator3Value}
                        </label>
                        <hr style={{ marginTop: "2px", marginBottom: "2px" }} />
                      </div>
                    </div>
                  )}
                  {caseAndCustomerInfoState.classificator4Label && (
                    <div className="row">
                      <div className="col">
                        <label className="tpbold">
                          {caseAndCustomerInfoState.classificator4Label}
                        </label>
                        <br />
                        <label>
                          {caseAndCustomerInfoState.classificator4Value}
                        </label>
                        <hr style={{ marginTop: "2px", marginBottom: "2px" }} />
                      </div>
                    </div>
                  )}
                  {caseAndCustomerInfoState.classificator5Label && (
                    <div className="row">
                      <div className="col">
                        <label className="tpbold">
                          {caseAndCustomerInfoState.classificator5Label}
                        </label>
                        <br />
                        <label>
                          {caseAndCustomerInfoState.classificator5Value}
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={`accordion-button ${caseAndCustomerInfoState.currentAccordionCase === currentAccordionsCaseEnum.COMMENTS ? "" : "collapsed"}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse2"
                  onClick={() =>
                    handleAccordionCaseClick(currentAccordionsCaseEnum.COMMENTS)
                  }
                >
                  {commentsCaseLabel}
                </button>
              </h2>
              <div id="collapse2" className="accordion-collapse collapse">
                <div
                  className={`accordion-body  ${caseAndCustomerInfoState.currentAccordionCase === currentAccordionsCaseEnum.COMMENTS ? "show" : ""}`}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <TPGenAIHeader
                      id={"comments"}
                      caseNumber={caseNumberToView}
                    />
                    {Boolean(TPGlobal.TPGenAIEnabled) && (
                      <div
                        className="container"
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <button
                          type="button"
                          style={{
                            ...allThemes.base.accordion.collapseButton,
                            borderRadius: "4px",
                            width: "100%",
                            marginTop: "16px",
                            marginBottom: "16px",
                          }}
                          onClick={() =>
                            setEmailStatus(
                              emailStatus ==
                                currentAccordionsEmailMenu.TRANSLATION
                                ? currentAccordionsEmailMenu.ORIGIN
                                : currentAccordionsEmailMenu.TRANSLATION,
                            )
                          }
                        >
                          {emailStatus ==
                          currentAccordionsEmailMenu.TRANSLATION ? (
                            <Icon icon="ph:caret-up-bold" />
                          ) : (
                            <Icon icon="ph:caret-down-bold" />
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                  {currentAccordionsEmailMenu.TRANSLATION === emailStatus ? (
                    <div
                      style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                      dangerouslySetInnerHTML={{
                        __html: caseAndCustomerInfoState.comments,
                      }}
                    />
                  ) : (
                    <div
                      style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                      dangerouslySetInnerHTML={{ __html: originalEmail }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={`accordion-button ${caseAndCustomerInfoState.currentAccordionCase === currentAccordionsCaseEnum.ATTACHMENTS ? "" : "collapsed"}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse3"
                  onClick={() =>
                    handleAccordionCaseClick(
                      currentAccordionsCaseEnum.ATTACHMENTS,
                    )
                  }
                >
                  {attachmentsCaseLabel}
                </button>
              </h2>
              <div id="collapse3" className="accordion-collapse collapse">
                <div
                  className={`accordion-body  ${caseAndCustomerInfoState.currentAccordionCase === currentAccordionsCaseEnum.ATTACHMENTS ? "show" : ""}`}
                >
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={handleNewAttahcment}
                    text={addNewAttachmentsCaseLabel}
                    icon={TPIconTypes.newEntity}
                  />
                  {caseAndCustomerInfoState.alreadyClickCaseAttachments && (
                    <TPFixedTable
                      dataGrid={
                        caseAndCustomerInfoState.gridCaseAttachmentsData
                      }
                      columns={gridCaseAttachmentsColumns}
                      tableHeight={"200px"}
                      keyField={"tpguidrow"}
                      highlightOnHover={true}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={`accordion-button ${caseAndCustomerInfoState.currentAccordionCase === currentAccordionsCaseEnum.ADDITIONALDATA ? "" : "collapsed"}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse4"
                  onClick={() =>
                    handleAccordionCaseClick(
                      currentAccordionsCaseEnum.ADDITIONALDATA,
                    )
                  }
                >
                  {additionalDataCaseLabel}
                </button>
              </h2>
              <div id="collapse4" className="accordion-collapse collapse">
                <div
                  className={`accordion-body  ${caseAndCustomerInfoState.currentAccordionCase === currentAccordionsCaseEnum.ADDITIONALDATA ? "show" : ""}`}
                >
                  <div className="row new-case__additional_data_container">
                    {caseAndCustomerInfoState.additionalDataValues.map(
                      function (item) {
                        let elementJsx;
                        let index: number;
                        let value: any;
                        index =
                          caseAndCustomerInfoState.additionalDataValues.findIndex(
                            (x: TPKeyValue) => x.key == item.key,
                          );
                        value =
                          caseAndCustomerInfoState.additionalDataValues[index]
                            .value;
                        elementJsx = (
                          <div
                            className="col-6 pb-2"
                            style={{ border: "1px solid #f5f5f5" }}
                            key={
                              "adda" +
                              caseAndCustomerInfoState.additionalDataValues[
                                index
                              ].key +
                              item.key
                            }
                          >
                            <TPRenderAdditionalDataSwitch
                              guidControl={caseAndCustomerInfoState.caseNumber.toString()}
                              modeUI={TPAddtionalDataUIModes.View1}
                              defaultValue={value}
                              itemToRender={item}
                              onChange={(
                                idControl: any,
                                newValue: string,
                                addtionalDataId: string,
                              ) => TPGlobal.foo()}
                            />
                          </div>
                        );
                        return elementJsx;
                      },
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return jsxElement;
  };

  const renderCustomerTabContent = () => {
    let jsxElement: any;
    jsxElement = (
      <div
        className={`tab-pane fade ${caseAndCustomerInfoState.currentTab === currentTabsEnum.customer ? "show active" : ""}`}
        id="nav-customer"
        role="tabpanel"
        aria-labelledby="nav-customer-tab"
        style={{
          border: "1px solid #dee2e6",
          padding: "10px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div className="row mt-2">
          <div className="col-12 text-center">
            <DefaultAvatarIcon />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TPTextBox
              id="IdTextBox"
              onChange={() => {
                TPGlobal.foo();
              }}
              value={caseAndCustomerInfoState.customerNames}
              labelText={customerResultsNamesLabel}
              disabled={true}
            />
          </div>
          {/*  123*/}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "auto",
              alignItems: "center",
              paddingTop: "22px",
            }}
          >
            <TPIcon
              style={{ color: "#a00095", fontSize: "30px", cursor: "pointer" }}
              iconType={TPIconTypes.export}
              onClick={handleEditCustomerOnClick}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TPTextBox
              onChange={() => {
                TPGlobal.foo();
              }}
              value={caseAndCustomerInfoState.customerType}
              labelText={customerResultsTypeLabel}
              disabled={true}
            />
          </div>
          <div className="col-6">
            <TPTextBox
              id="IdTextBox"
              onChange={() => {
                TPGlobal.foo();
              }}
              value={caseAndCustomerInfoState.customerInternalCode}
              labelText={customerResultsInternalCodeLabel}
              disabled={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TPTextBox
              id="IdTextBox"
              onChange={() => {
                TPGlobal.foo();
              }}
              value={caseAndCustomerInfoState.customerDocumentType}
              labelText={customerResultsDocumentTypeLabel}
              disabled={true}
            />
          </div>
          <div className="col-6">
            <TPTextBox
              id="IdTextBox"
              onChange={() => {
                TPGlobal.foo();
              }}
              value={caseAndCustomerInfoState.customerDocument}
              labelText={customerResultsDocumentLabel}
              disabled={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TPTextBox
              id="IdTextBox"
              onChange={() => {
                TPGlobal.foo();
              }}
              value={caseAndCustomerInfoState.customerEmail}
              labelText={customerResultsEmailLabel}
              disabled={true}
            />
          </div>
          <div className="col-6">
            <TPTextBox
              id="IdTextBox"
              onChange={() => {
                TPGlobal.foo();
              }}
              value={caseAndCustomerInfoState.customerPhone}
              labelText={customerResultsPhoneLabel}
              disabled={true}
            />
          </div>
        </div>
        <div
          className="mt-3"
          style={{
            border: "1px solid  #dee2e6",
            borderTop: "none",
            height: "400px",
          }}
        >
          <div className="accordion">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={`accordion-button ${caseAndCustomerInfoState.currentAccordionCustomer === currentAccordionsCustomerEnum.HISTORY ? "" : "collapsed"}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse5"
                  onClick={() =>
                    handleAccordionCustomerClick(
                      currentAccordionsCustomerEnum.HISTORY,
                    )
                  }
                >
                  {historyCaseLabel}
                </button>
              </h2>
              <div id="collapse5" className="accordion-collapse collapse">
                <div className="accordion-body">
                  {caseAndCustomerInfoState.alreadyClickCustomerHistory && (
                    <TPFixedTable
                      dataGrid={caseAndCustomerInfoState.gridCustomerHistory}
                      columns={gridHistoryColumns}
                      tableHeight={"200px"}
                      keyField={"tpguidrow"}
                      highlightOnHover={true}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={`accordion-button ${caseAndCustomerInfoState.currentAccordionCustomer === currentAccordionsCustomerEnum.CONTACTS ? "" : "collapsed"}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse6"
                  onClick={() =>
                    handleAccordionCustomerClick(
                      currentAccordionsCustomerEnum.CONTACTS,
                    )
                  }
                >
                  {contactsLabel}
                </button>
              </h2>
              <div id="collapse6" className="accordion-collapse collapse">
                <div className="accordion-body">
                  <div className="mt-1">
                    {caseAndCustomerInfoState.alreadyClickCustomerContacts && (
                      <>
                        <div className="row">
                          <div className="col">
                            <TPFixedTable
                              dataGrid={
                                caseAndCustomerInfoState.gridCustomerContacts
                              }
                              columns={gridContactsColumns}
                              tableHeight={"200px"}
                              keyField={"tpguidrow"}
                              highlightOnHover={true}
                              selectableRows={false}
                              multipleSelection={false}
                              styleSelectedRow={{
                                backgroundColor: "#cff4fc",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={`accordion-button ${caseAndCustomerInfoState.currentAccordionCustomer === currentAccordionsCustomerEnum.ATTACHMENTS ? "" : "collapsed"}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse7"
                  onClick={() =>
                    handleAccordionCustomerClick(
                      currentAccordionsCustomerEnum.ATTACHMENTS,
                    )
                  }
                >
                  {tabAttachmentsLabel}
                </button>
              </h2>
              <div id="collapse7" className="accordion-collapse collapse">
                <div className="accordion-body">
                  {caseAndCustomerInfoState.alreadyClickCustomerAttachments && (
                    <TPFixedTable
                      dataGrid={
                        caseAndCustomerInfoState.gridCustomerAttachmentsData
                      }
                      columns={gridCustomerAttachmentsColumns}
                      tableHeight={"200px"}
                      keyField={"tpguidrow"}
                      highlightOnHover={true}
                    />
                  )}
                  h1
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return jsxElement;
  };

  const renderWorkflowTabContent = () => {
    let jsxElement: any;
    jsxElement = (
      <div
        className={`tab-pane fade ${caseAndCustomerInfoState.currentTab === currentTabsEnum.workflow ? "show active" : ""}`}
        id="nav-workflow"
        role="tabpanel"
        aria-labelledby="nav-workflow-tab"
        style={{
          border: "1px solid #dee2e6",
          padding: "10px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div className="row mt-2">
          <div className="col-12 d-flex justify-content-end">
            <label
              style={{
                fontSize: "12px",
                fontWeight: "400",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleShowDiagram}
            >
              {" "}
              {workflowDiagramLabel}
            </label>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            {workflowState.gridData && workflowState.gridData.length >= 1 && (
              <table className="tp-fixed-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>{orderColumnLabel}</th>
                    <th>{taskTypeColumnLabel}</th>

                    <th>{slaColumnLabel}</th>
                    <th>{predecessorsColumnLabel}</th>
                    <th>{closeCustomerColumnLabel}</th>
                    <th>{defineResponsibleColumnLabel}</th>
                  </tr>
                </thead>
                <tbody>
                  {workflowState.gridData.map(function (item, index) {
                    let predecessors: string;
                    predecessors = workflowState.predecessors
                      .filter((x: TPKeyValue) => x.key == item.id.toString())[0]
                      .value.toString();
                    return (
                      <tr key={"wfdetail" + index.toString()}>
                        <td>{item.order}</td>
                        <td>{item.taskDescription}</td>
                        <td>
                          {item.term}
                          {item.termUnit}
                        </td>
                        <td>{predecessors}</td>
                        <td>
                          <IsActiveIcon
                            className={
                              item.closesCustomer ? "active" : "inactive"
                            }
                            iconType={TPIconTypes.activeInactive}
                          />
                        </td>
                        <td>
                          <IsActiveIcon
                            className={
                              item.definesResponsible ? "active" : "inactive"
                            }
                            iconType={TPIconTypes.activeInactive}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
    return jsxElement;
  };

  const handleToggleOrderClick = () => {
    let newItemsOrdered: Array<TaskViewModel> = [...timelineState.items];
    if (timelineState.currentOrder === "ASC") {
      newItemsOrdered = newItemsOrdered.sort(
        (a: TaskViewModel, b: TaskViewModel) => {
          if (moment(a.creationDate).isAfter(moment(b.creationDate))) {
            return -1;
          } else {
            return 1;
          }
        },
      );
    } else {
      newItemsOrdered = newItemsOrdered.sort(
        (a: TaskViewModel, b: TaskViewModel) => {
          if (moment(a.creationDate).isBefore(moment(b.creationDate))) {
            return -1;
          } else {
            return 1;
          }
        },
      );
    }
    let command1: commandTimelineType = {
      type: commandsTimelineEnum.change_ordered_Timeline,
      payload: {
        newItems: newItemsOrdered,
        newCurrentOrder: timelineState.currentOrder === "ASC" ? "DES" : "ASC",
      },
    };
    dispatchTimelineCommand(command1);
  };

  const handleTimelineItemClick = (taskId: number) => {
    let found: boolean;
    let newItems: Array<TaskViewModel>;
    found = taskViewerState.items.some((x: TaskViewModel) => x.id === taskId);
    if (!found) {
      newItems = [...taskViewerState.items];
      newItems.push(
        timelineState.items.find(
          (x: TaskViewModel) => x.id === taskId,
        ) as TaskViewModel,
      );
      newItems = newItems.sort((a: TaskViewModel, b: TaskViewModel) => {
        if (moment(a.creationDate).isAfter(moment(b.creationDate))) {
          return -1;
        }
        return 1;
      });
      let command1: commandTaskViewerType = {
        type: commandsTaskViewerEnum.add_task,
        payload: {
          newClickedItem: taskId,
          newItems: newItems,
        },
      };
      dispatchTaskViewerCommand(command1);
    } else {
      const keys = Object.keys(childRefArray.current);
      if (keys && keys.findIndex((x: any) => x === taskId.toString()) !== -1) {
        let controlref: any = childRefArray.current[taskId.toString()];
        let canClose: boolean = controlref.canClose();
        if (canClose) {
          let command1: commandTaskViewerType = {
            type: commandsTaskViewerEnum.remove_task,
            payload: {
              newTaskId: taskId,
            },
          };
          dispatchTaskViewerCommand(command1);
        } else {
          let newModalQuestionState: TPModalQuestionState;
          newModalQuestionState = { ...modalQuestionState };
          newModalQuestionState.isShown = true;
          newModalQuestionState.callBackData = { taskId: taskId };
          setModalQuestionState(newModalQuestionState);
        }
      }
    }
    setCurrentSelectedTaskId(taskId);
    // setTimeout(() => {
    //     var element = document.querySelector("#task" + caseViewerGuid + taskId);
    //     if (element) {
    //         element.scrollIntoView({
    //             behavior: "auto",
    //             block: "center",
    //             inline: "center",
    //         });
    //     }
    // }, 1000);
  };

  const handleShowMore = (e: any, cardId: any) => {
    e.stopPropagation();
    setExpandedCards((prevExpandedCards) => ({
      ...prevExpandedCards,
      [cardId]: !prevExpandedCards[cardId],
    }));
  };

  const handleTaskClose = (taskId: number, askToClose: boolean) => {
    if (!askToClose) {
      let command1: commandTaskViewerType = {
        type: commandsTaskViewerEnum.remove_task,
        payload: {
          newTaskId: taskId,
        },
      };
      dispatchTaskViewerCommand(command1);
    } else {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { taskId: taskId };
      setModalQuestionState(newModalQuestionState);
    }
  };

  const handleSaveTask = async (inputDTO: any, isMailTask: boolean = false) => {
    let controlref: any = childRefArray.current[inputDTO.id.toString()];
    try {
      setIsLoadingScreen(true);
      await realSaveTask(inputDTO, false, isMailTask);
      controlref.clearHasBeenModified();
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleSaveTask ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} handleSaveTask ex`);

      controlref.clearHasBeenModified();
      setIsLoadingScreen(false);
    }
  };

  const handleActionsTaskCallBack = async (actionName: ActionTypeEnum) => {
    if (actionName == ActionTypeEnum.Reassign) {
      handleRefreshTimelineClick();
    }
  };

  const realSaveTask = async (
    inputDTO: any,
    hasDifferences: boolean,
    isMailTask: boolean = false,
  ) => {
    let serviceClient = new TaskService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      setIsTaskLoading(true);
      let responseRequest: any;
      inputDTO.tEmpAdditionalDataTask = handleTempAdditionalDataTaskUtf8(
        inputDTO.tEmpAdditionalDataTask,
      );
      if (!isMailTask) {
        responseRequest = await serviceClient.UpdateTaskReport(
          inputDTO,
          true,
          true,
          expectedCodes,
        );
      } else {
        responseRequest = await serviceClient.UpdateSendMailTaskReport(
          inputDTO,
          true,
          true,
          expectedCodes,
        );
      }
      if (responseRequest && responseRequest.responseResult) {
        const keys = Object.keys(childRefArray.current);
        if (
          keys &&
          keys.findIndex((x: any) => x === inputDTO.id.toString()) !== -1
        ) {
          let controlref: any = childRefArray.current[inputDTO.id.toString()];
          controlref.clearHasBeenModified();
        }
        if (hasDifferences) {
          if (caseAndCustomerInfoState.alreadyClickAdditionalDataCase) {
            let newData: Array<TPKeyValue> = [
              ...caseAndCustomerInfoState.additionalDataValues,
            ];
            for (
              let i = 0;
              i <= inputDTO.tEmpAdditionalDataTask.length - 1;
              i++
            ) {
              for (let j = 0; j <= newData.length - 1; j++) {
                if (
                  inputDTO.tEmpAdditionalDataTask[
                    i
                  ].additionalDataId.toUpperCase() ===
                  newData[j].key.toUpperCase()
                ) {
                  newData[j].value = inputDTO.tEmpAdditionalDataTask[i].value;
                }
              }
            }

            let command1: commandCaseAndCustomerInfoType = {
              type: commandsCaseAndCustomerInfoEnum.change_case_accordion_info,
              payload: {
                newAccordion: currentAccordionsCaseEnum.ADDITIONALDATA,
                newData: newData,
              },
            };
            dispatchCaseAndCustomerInfoCommand(command1);
          }
          for (let i = 0; i <= taskViewerState.items.length - 1; i++) {
            if (inputDTO.id !== taskViewerState.items[i].id) {
              const keys = Object.keys(childRefArray.current);
              if (
                keys &&
                keys.findIndex(
                  (x: any) => x === taskViewerState.items[i].id.toString(),
                ) !== -1
              ) {
                let controlref: any =
                  childRefArray.current[taskViewerState.items[i].id.toString()];
                controlref.refreshAdditionalData(
                  inputDTO.tEmpAdditionalDataTask,
                );
              }
            }
          }
        }
      } else {
        try {
          const keys = Object.keys(childRefArray.current);
          if (
            keys &&
            keys.findIndex((x: any) => x === inputDTO.id.toString()) !== -1
          ) {
            let controlref: any = childRefArray.current[inputDTO.id.toString()];
            controlref.activeSaveButton();
          }
        } catch (error) {
          console.error("Error in set save button active", error);
        }
      }
      setIsLoadingScreen(false);
      setIsTaskLoading(false);
      handleRefreshTimelineClick();
      let newCaseInfo: CasesViewModel | null;
      newCaseInfo = await getCaseInfoByNumber(caseNumberToView);
      if (newCaseInfo) {
        let command3: commandCaseAndCustomerInfoType = {
          type: commandsCaseAndCustomerInfoEnum.change_case_status,
          payload: {
            newCaseStatusId: newCaseInfo.caseStatusId,
            newCaseStatusLocalizedDescription:
              newCaseInfo.caseStatusLocalizedDescription,
          },
        };
        dispatchCaseAndCustomerInfoCommand(command3);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} realSaveTask ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} realSaveTask ex`);
      setIsLoadingScreen(false);
      setIsTaskLoading(false);
    }
  };

  const handleTempAdditionalDataTaskUtf8 = (data: any) => {
    let result =
      data?.map((e: any) => {
        e.value = TPGlobal.stringToUTF8(String(e.value)).toString();
        return e;
      }) || [];
    return result;
  };

  const handleRefreshTimelineClick = async () => {
    let newTimelineData: Array<TaskViewModel>;
    newTimelineData = await getTaskTimelineByNumber(caseNumberToView);
    let command2: commandTimelineType = {
      type: commandsTimelineEnum.load_timeline,
      payload: {
        newItems: newTimelineData,
      },
    };
    dispatchTimelineCommand(command2);
  };

  const [caseReminders, setCaseReminders] = useState<ReminderResponse[]>([]);
  const [reminderCountLabel, setReminderCountLabel] = useState("");
  const { remindersLoaded } = useSelector(
    (s: StoreModel) => s[MyRemindersSlice.name],
  ) as MyRemindersSliceModel;

  const getCaseReminders = function () {
    RemindersService.getRemindersByCaseId(
      caseNumberToView,
      false,
      true,
      [200, 404],
    )
      .then((r) => {
        if (r) {
          setCaseReminders(r);
          dispatch(MyRemindersSlice.actions.setContentLoaded(true));
        }
      })
      .catch((e) => console.error(e));
  };
  useEffect(() => {
    getCaseReminders();
  }, []);

  useEffect(() => {
    if (!remindersLoaded) getCaseReminders();
  }, [remindersLoaded]);

  const handleChange =
    (panel: string, id: number, isTaskLoaded: boolean) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      !isTaskLoaded && handleTimelineItemClick(id);
    };

  const renderTimeline = () => {
    let jsxElement: any;
    jsxElement = (
      <>
        <div
          style={{
            display: "block",
            overflowY: "scroll",
            width: "calc(100% - 10px)",
          }}
        >
          {timelineState.items.map(function (item: any, index) {
            let imageStatus: any;
            let foundTask: boolean = false;

            let taskIndex: number = taskViewerState.items.findIndex(
              (x: TaskViewModel) => x.id === item.id,
            );
            if (taskIndex != -1) {
              foundTask = true;
            }
            switch (item.taskStatusId) {
              case taskStatusEnum.Solved:
                imageStatus = (
                  <TPIcon
                    iconType={TPIconTypes.checkCircle}
                    style={{ color: "#69c169" }}
                    inline={true}
                  />
                );
                break;
              case taskStatusEnum.Pending:
                imageStatus = (
                  <TPIcon
                    iconType={TPIconTypes.watchLater}
                    style={{ color: "rgb(11 122 187)" }}
                    inline={true}
                  />
                );
                break;
              case taskStatusEnum.Expired:
                imageStatus = (
                  <TPIcon
                    iconType={TPIconTypes.warning}
                    style={{ color: "red" }}
                    inline={true}
                  />
                );
                break;
              case taskStatusEnum.Lag:
                imageStatus = (
                  <TPIcon
                    iconType={TPIconTypes.hourglassTop}
                    style={{ color: "#e57348" }}
                    inline={true}
                  />
                );
                break;
            }

            return (
              <div key={"timeline" + (index + 1).toString()}>
                {foundTask && (
                  <div className="d-flex align-items-start">
                    <div
                      className={`tptimeline ${currentSelectedTaskId === item.id ? "timeline-current-task" : ""}`}
                      onClick={() => handleTimelineItemClick(item.id)}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        {imageStatus}
                        {/* {(item.taskStatusId != taskStatusEnum.Solved) &&
                                                    (item.componentId != taskTypeENUM.AutomaticAnswerTask) &&
                                                    (item.componentId != taskTypeENUM.DecisionTypeAutomatic) &&
                                                    <TaskReminderButton caseId={item.caseId} taskId={item.id} />} */}
                      </div>
                      <label
                        className={"tpbold"}
                        style={{ wordBreak: "break-all" }}
                      >
                        {item.taskTypeLocalizedDescription}
                      </label>
                      <div>
                        <TPIcon
                          iconType={TPIconTypes.identity}
                          inline={true}
                          style={{ color: "rgb(173 168 168)" }}
                        />{" "}
                        <label
                          style={{ wordBreak: "break-all", fontSize: "14px" }}
                        >
                          {item.writerUserName}
                        </label>
                      </div>
                      <div>
                        <TPIcon
                          iconType={TPIconTypes.calendar}
                          inline={true}
                          style={{ color: "#FF0000" }}
                        />{" "}
                        <label
                          style={{ wordBreak: "break-all", fontSize: "14px" }}
                        >
                          {item.limitDateFormatted}
                        </label>
                      </div>
                      <div className="workflow-task-show-more-box">
                        {item.workflowDetailId && (
                          <div className="tptimeline-workflow-task">
                            {workflowTaskLabel}
                          </div>
                        )}
                        <div
                          className="show-more"
                          onClick={(e) => handleShowMore(e, item.id)}
                        >
                          {expandedCards[item.id] ? (
                            <>
                              {hideAllLabel} <UpArrowIcon />
                            </>
                          ) : (
                            <>
                              {showAllLabel} <DownArrowIcon />
                            </>
                          )}
                        </div>
                      </div>
                      {expandedCards[item.id] ? (
                        <div>
                          <div>
                            <h6 className="tptimeline-subcontent-title">
                              {creationDateLabel}
                            </h6>
                            <label
                              style={{
                                wordBreak: "break-all",
                                fontSize: "11px",
                              }}
                            >
                              {item.creationDateFormatted}
                            </label>
                          </div>
                          <div>
                            <h6 className="tptimeline-subcontent-title">
                              {startDateLabel}
                            </h6>
                            <label
                              style={{
                                wordBreak: "break-all",
                                fontSize: "11px",
                              }}
                            >
                              {item.startDateFormatted}
                            </label>
                          </div>
                          <div>
                            <h6 className="tptimeline-subcontent-title">
                              {readerLabel}
                            </h6>
                            <label
                              style={{
                                wordBreak: "break-all",
                                fontSize: "11px",
                              }}
                            >
                              {item.readerUserName}
                            </label>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      style={{
                        width: "calc(100% - 250px)",
                      }}
                    >
                      {taskViewerState.items.map(function (item2, index2) {
                        if (item2.id === item.id) {
                          foundTask = true;
                          let taskToRender: any = null;
                          switch (item2.componentId) {
                            case taskTypeENUM.DecisionTypeAutomatic:
                              taskToRender = (
                                <TPYesNoTaskAutomatic
                                  caseNumber={caseNumberToView}
                                  mode={item.canResolve ? "EDIT" : "VIEW"}
                                  isWorkflowTask={
                                    item.workflowDetailId ? true : false
                                  }
                                  taskIdToView={item2.id}
                                  closeTaskCallBack={handleTaskClose}
                                  saveTaskCallBack={handleSaveTask}
                                  ref={(element: any) => {
                                    childRefArray.current[item2.id.toString()] =
                                      element;
                                  }}
                                  actionsTaskCallBack={
                                    handleActionsTaskCallBack
                                  }
                                />
                              );
                              break;
                            case taskTypeENUM.AutomaticAnswerTask:
                              taskToRender = (
                                <TPStandardTaskAutomatic
                                  caseNumber={caseNumberToView}
                                  mode={item.canResolve ? "EDIT" : "VIEW"}
                                  taskIdToView={item2.id}
                                  isWorkflowTask={
                                    item.workflowDetailId ? true : false
                                  }
                                  closeTaskCallBack={handleTaskClose}
                                  saveTaskCallBack={handleSaveTask}
                                  ref={(element: any) => {
                                    childRefArray.current[item2.id.toString()] =
                                      element;
                                  }}
                                  actionsTaskCallBack={
                                    handleActionsTaskCallBack
                                  }
                                />
                              );
                              break;
                            case taskTypeENUM.SendEmailTask:
                              taskToRender = (
                                <TPEmailTask
                                  caseNumber={caseNumberToView}
                                  caseType={caseType}
                                  mode={item.canResolve ? "EDIT" : "VIEW"}
                                  taskIdToView={item2.id}
                                  isWorkflowTask={
                                    item.workflowDetailId ? true : false
                                  }
                                  closeTaskCallBack={handleTaskClose}
                                  saveTaskCallBack={handleSaveTask}
                                  ref={(element: any) => {
                                    childRefArray.current[item2.id.toString()] =
                                      element;
                                  }}
                                  actionsTaskCallBack={
                                    handleActionsTaskCallBack
                                  }
                                  loadingStatus={isTaskLoading}
                                />
                              );
                              break;
                            case taskTypeENUM.SendEmailAutomatic:
                              taskToRender = (
                                <TPSendEmailAutomatic
                                  caseNumber={caseNumberToView}
                                  caseType={caseType}
                                  mode={item.canResolve ? "EDIT" : "VIEW"}
                                  taskIdToView={item2.id}
                                  isWorkflowTask={
                                    item.workflowDetailId ? true : false
                                  }
                                  closeTaskCallBack={handleTaskClose}
                                  saveTaskCallBack={handleSaveTask}
                                  ref={(element: any) => {
                                    childRefArray.current[item2.id.toString()] =
                                      element;
                                  }}
                                  actionsTaskCallBack={
                                    handleActionsTaskCallBack
                                  }
                                />
                              );
                              break;
                            case taskTypeENUM.StandarCommentTask:
                              taskToRender = (
                                <TPStandardTask
                                  caseNumber={caseNumberToView}
                                  mode={item.canResolve ? "EDIT" : "VIEW"}
                                  taskIdToView={item2.id}
                                  isWorkflowTask={
                                    item.workflowDetailId ? true : false
                                  }
                                  closeTaskCallBack={handleTaskClose}
                                  saveTaskCallBack={handleSaveTask}
                                  ref={(element: any) => {
                                    childRefArray.current[item2.id.toString()] =
                                      element;
                                  }}
                                  actionsTaskCallBack={
                                    handleActionsTaskCallBack
                                  }
                                />
                              );
                              break;
                            case taskTypeENUM.DecisionTask:
                              taskToRender = (
                                <TPYesNoTask
                                  caseNumber={caseNumberToView}
                                  mode={item.canResolve ? "EDIT" : "VIEW"}
                                  isWorkflowTask={
                                    item.workflowDetailId ? true : false
                                  }
                                  taskIdToView={item2.id}
                                  closeTaskCallBack={handleTaskClose}
                                  saveTaskCallBack={handleSaveTask}
                                  ref={(element: any) => {
                                    childRefArray.current[item2.id.toString()] =
                                      element;
                                  }}
                                  actionsTaskCallBack={
                                    handleActionsTaskCallBack
                                  }
                                />
                              );
                              break;
                            case taskTypeENUM.DecisionRetryTask:
                              taskToRender = (
                                <TPYesNoRetryTask
                                  caseNumber={caseNumberToView}
                                  mode={item.canResolve ? "EDIT" : "VIEW"}
                                  isWorkflowTask={
                                    item.workflowDetailId ? true : false
                                  }
                                  taskIdToView={item2.id}
                                  closeTaskCallBack={handleTaskClose}
                                  saveTaskCallBack={handleSaveTask}
                                  ref={(element: any) => {
                                    childRefArray.current[item2.id.toString()] =
                                      element;
                                  }}
                                  actionsTaskCallBack={
                                    handleActionsTaskCallBack
                                  }
                                />
                              );
                              break;
                            case taskTypeENUM.SystemTask:
                              taskToRender = (
                                <TPEmailTask
                                  caseNumber={caseNumberToView}
                                  mode={"VIEW"}
                                  isWorkflowTask={
                                    item.workflowDetailId ? true : false
                                  }
                                  taskIdToView={item2.id}
                                  closeTaskCallBack={handleTaskClose}
                                  saveTaskCallBack={handleSaveTask}
                                  ref={(element: any) => {
                                    childRefArray.current[item2.id.toString()] =
                                      element;
                                  }}
                                  actionsTaskCallBack={
                                    handleActionsTaskCallBack
                                  }
                                />
                              );
                              break;
                          }
                          return (
                            <div
                              className="d-flex justify-content-between"
                              key={"task" + index2.toString()}
                            >
                              <div style={{ position: "relative" }}>
                                <div
                                  style={{
                                    position: "absolute",
                                    width: "50px",
                                    height: "1px",
                                    left: "0",
                                    top: "45px",

                                    border: "1px solid #dbd5d5",
                                    borderBottom: "none",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    position: "absolute",
                                    width: "1px",
                                    height: "calc(100% + 30px)",
                                    left: "25px",
                                    top: "0",

                                    border: "1px solid #dbd5d5",
                                    borderRight: "none",
                                    minHeight: "150px",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    position: "absolute",
                                    width: "10px",
                                    height: "10px",
                                    left: "20px",
                                    top: "41px",
                                    backgroundColor: "#a00095",
                                    border: "1px solid  #a00095",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                              </div>
                              {taskToRender !== null && (
                                <div
                                  className="tptimeline-task"
                                  id={"task" + caseViewerGuid + item2.id}
                                >
                                  {taskToRender}
                                </div>
                              )}
                              {taskToRender == null && (
                                <div
                                  className="tptimeline-task"
                                  id={"task" + caseViewerGuid + item2.id}
                                  style={{ display: "none" }}
                                >
                                  xxxx
                                </div>
                              )}
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                )}
                {!foundTask && (
                  <div className="d-flex align-items-start">
                    <div
                      className="tptimeline"
                      onClick={() => handleTimelineItemClick(item.id)}
                      style={{ position: "relative" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        {imageStatus}
                        {/* {(item.taskStatusId != taskStatusEnum.Solved) &&
                                                    (item.componentId != taskTypeENUM.AutomaticAnswerTask) &&
                                                    (item.componentId != taskTypeENUM.DecisionTypeAutomatic) &&
                                                    <TaskReminderButton caseId={item.caseId} taskId={item.id} />} */}
                      </div>
                      <label
                        className={"tpbold"}
                        style={{ wordBreak: "break-all" }}
                      >
                        {" "}
                        {item.taskTypeLocalizedDescription}
                      </label>
                      <div>
                        <TPIcon
                          iconType={TPIconTypes.identity}
                          inline={true}
                          style={{ color: "rgb(173 168 168)" }}
                        />{" "}
                        <label
                          style={{ wordBreak: "break-all", fontSize: "14px" }}
                        >
                          {item.writerUserName}
                        </label>
                      </div>
                      <div>
                        <TPIcon
                          iconType={TPIconTypes.calendar}
                          inline={true}
                          style={{ color: "#FF0000" }}
                        />{" "}
                        <label
                          style={{ wordBreak: "break-all", fontSize: "14px" }}
                        >
                          {item.limitDateFormatted}
                        </label>
                      </div>
                      <div className="workflow-task-show-more-box">
                        {item.workflowDetailId && (
                          <div className="tptimeline-workflow-task">
                            {workflowTaskLabel}
                          </div>
                        )}
                        <div
                          className="show-more"
                          onClick={(e) => handleShowMore(e, item.id)}
                        >
                          {expandedCards[item.id] ? (
                            <>
                              {hideAllLabel} <UpArrowIcon />
                            </>
                          ) : (
                            <>
                              {showAllLabel} <DownArrowIcon />
                            </>
                          )}
                        </div>
                      </div>
                      {expandedCards[item.id] ? (
                        <div>
                          <div>
                            <h6 className="tptimeline-subcontent-title">
                              {creationDateLabel}
                            </h6>
                            <label
                              style={{
                                wordBreak: "break-all",
                                fontSize: "11px",
                              }}
                            >
                              {item.creationDateFormatted}
                            </label>
                          </div>
                          <div>
                            <h6 className="tptimeline-subcontent-title">
                              {startDateLabel}
                            </h6>
                            <label
                              style={{
                                wordBreak: "break-all",
                                fontSize: "11px",
                              }}
                            >
                              {item.startDateFormatted}
                            </label>
                          </div>
                          <div>
                            <h6 className="tptimeline-subcontent-title">
                              {readerLabel}
                            </h6>
                            <label
                              style={{
                                wordBreak: "break-all",
                                fontSize: "11px",
                              }}
                            >
                              {item.readerUserName}
                            </label>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "calc(100% + 2px)",
                          width: "26px",
                          height: "1px",
                          border: "1px solid #dbd5d5",
                        }}
                      ></div>
                      <div
                        style={{
                          position: "absolute",
                          top: "-10.23px",
                          left: "calc(100% + 27px)",
                          width: "1px",
                          height: "calc(100% + 15px)",
                          border: "1px solid #dbd5d5",
                          borderLeft: "none",
                          minHeight: "150px",
                        }}
                      ></div>
                      <div
                        style={{
                          position: "absolute",
                          width: "10px",
                          height: "10px",
                          left: "calc(100% + 23px)",
                          top: "calc(50% - 4px)",
                          backgroundColor: "#a00095",
                          border: "1px solid  #a00095",
                          borderRadius: "50%",
                        }}
                      ></div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </>
    );
    return jsxElement;
  };

  const getTooltipBy = (text: string | null) => {
    if (!text) return null;

    return (
      <Typography sx={{ fontFamily: "Noto Sans", fontSize: "10px" }}>
        {text}
      </Typography>
    );
  };

  const MenuItem: React.FC<MenuItemProps> = ({
    label,
    actionType,
    id,
    handleCloseTaskPanel,
    onClick,
  }) => (
    <MenuItemButton
      style={{ minWidth: "9em" }}
      id={id}
      onClick={(e) => {
        e.stopPropagation();
        handleCloseTaskPanel(e);
        onClick(actionType);
      }}
    >
      {label}
    </MenuItemButton>
  );

  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [selectedActionType, setSelectedActionType] = useState<ActionTypeEnum>(
    ActionTypeEnum.Reassign,
  );

  const [isReassignRejectModal, setIsReassignRejectModal] = useState(false);

  const getMenuItemButtons = (
    taskComponentId: taskTypeENUM,
    task: any,
    controlRef: any,
    handleCloseTaskPanel: (e: any) => void,
  ) => {
    const buttons: JSX.Element[] = [];

    if (!controlRef) return <></>;

    if (
      taskComponentId === taskTypeENUM.AutomaticAnswerTask ||
      taskComponentId === taskTypeENUM.DecisionRetryTask ||
      taskComponentId === taskTypeENUM.DecisionTask ||
      taskComponentId === taskTypeENUM.DecisionTypeAutomatic ||
      taskComponentId === taskTypeENUM.StandarCommentTask
    ) {
      if (task.canResolve && TPGlobal.globalPermissions.canReassignTask) {
        buttons.push(
          <MenuItem
            key="reassign-email"
            label={reassignMenuItemLabel}
            actionType={ActionTypeEnum.Reassign}
            id="reassign-email-menu-item"
            handleCloseTaskPanel={handleCloseTaskPanel}
            onClick={() => {
              setSelectedTask(task);
              setSelectedActionType(ActionTypeEnum.Reassign);
              setIsReassignRejectModal(true);
            }}
          />,
        );
      }

      if (!task.canResolve && TPGlobal.globalPermissions.canRejectTask) {
        buttons.push(
          <MenuItem
            key="reject-system"
            label={rejectMenuItemLabel}
            actionType={ActionTypeEnum.Reject}
            id="reject-system-menu-item"
            handleCloseTaskPanel={handleCloseTaskPanel}
            onClick={() => {
              setSelectedTask(task);
              setSelectedActionType(ActionTypeEnum.Reject);
              setIsReassignRejectModal(true);
            }}
          />,
        );
      }
    }

    if (
      taskComponentId === taskTypeENUM.SystemTask &&
      TPGlobal.globalPermissions.canRejectTask
    ) {
      buttons.push(
        <MenuItem
          key="reject-system"
          label={rejectMenuItemLabel}
          actionType={ActionTypeEnum.Reject}
          id="reject-system-menu-item"
          handleCloseTaskPanel={handleCloseTaskPanel}
          onClick={controlRef.optionClicked}
        />,
      );
    }

    if (taskComponentId === taskTypeENUM.SendEmailTask) {
      if (TPGlobal.globalPermissions.canReassignTask) {
        buttons.push(
          <MenuItem
            key="reassign-email"
            label={reassignMenuItemLabel}
            actionType={ActionTypeEnum.Reassign}
            id="reassign-email-menu-item"
            handleCloseTaskPanel={handleCloseTaskPanel}
            onClick={controlRef.optionClicked}
          />,
        );
      }

      if (!task.canResolve && TPGlobal.globalPermissions.canRejectTask) {
        buttons.push(
          <MenuItem
            key="reject-email"
            label={rejectMenuItemLabel}
            actionType={ActionTypeEnum.Reject}
            id="reject-email-menu-item"
            handleCloseTaskPanel={handleCloseTaskPanel}
            onClick={controlRef.optionClicked}
          />,
        );
      }
    }

    return buttons;
  };

  const handleReclassifySave = async (reclassifyCaseRef: any) => {
    try {
      const result = await reclassifyCaseRef.current.reclassifyCase();
      if (result) {
        handleCancelReclassify("ReclassifyPanel", caseNumberToView);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} internalReclassifyCase ex`,
        TPLogType.ERROR,
        error,
      );
    }
  };

  const handleCancelReclassify = (panel: string, id: number) => {
    const fakeEvent = {
      preventDefault: () => {},
      stopPropagation: () => {},
    } as React.SyntheticEvent;
    handleChange(panel, id, true)(fakeEvent, false);
  };

  const renderTimelines = () => {
    let jsxElement: any;

    const tasks: TaskViewModel[] = timelineState.items;
    jsxElement = (
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        {tasks.map((task, i) => {
          let taskId: string = `${task.id}-${i}`;
          let isOpened: boolean = expanded === taskId;
          let taskStatus = taskByStatus[
            task.taskStatusId as TaskStatusId
          ] as CaseStatusType;

          let taskIndex: number = taskViewerState.items.findIndex(
            (viewerTask: TaskViewModel) => viewerTask.id === task.id,
          );

          let taskComponentId: string | undefined =
            taskViewerState.items[taskIndex]?.componentId;

          let controlRef: any =
            childRefArray.current[
              taskViewerState.items[taskIndex]?.id.toString()
            ];

          let isTaskLoaded: boolean = taskIndex !== -1;

          return (
            <StyledTimelineContent key={taskId} isExpanded={!toggleCaseZone}>
              <div className="task-index">{i + 1}</div>
              <StyledAccordion
                isActive={isOpened}
                expanded={isOpened}
                onChange={handleChange(taskId, task.id, isTaskLoaded)}
                sx={{
                  boxShadow: "none",
                  width: "100%",
                  margin: "0 !important",
                  borderRadius: "6px !important",
                }}
              >
                <StyledAccordionHeader
                  aria-controls={`${taskId}-content`}
                  id={`${taskId}-header`}
                  key={`task-${taskId}`}
                >
                  <StyledAccordionHeaderOptions>
                    <div className="principal">
                      <div className="task-init">
                        <StatusCircle type={taskStatus} />
                        <Tooltip
                          title={getTooltipBy(
                            task.taskTypeLocalizedDescription,
                          )}
                          placement="right"
                        >
                          <p className="task-title">
                            {task.taskTypeLocalizedDescription}
                          </p>
                        </Tooltip>
                      </div>
                      <div className="task-controls">
                        <TPIcon
                          className={isOpened ? "rotate" : ""}
                          iconType={TPIconTypes.chevron}
                        />
                        <TPIcon
                          style={{
                            color: task.workflowDetailId
                              ? "#2e2e2e"
                              : "lightgray",
                            cursor: task.workflowDetailId
                              ? "pointer"
                              : "default",
                          }}
                          iconType={TPIconTypes.more}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            handleOpenTaskPanel(e, i); // TODO: adjust this
                          }}
                        />
                        {task.workflowDetailId &&
                          taskPanelSelected === i &&
                          taskComponentId &&
                          (taskComponentId === taskTypeENUM.SystemTask ||
                            taskComponentId === taskTypeENUM.SendEmailTask ||
                            taskComponentId ===
                              taskTypeENUM.AutomaticAnswerTask ||
                            taskComponentId ===
                              taskTypeENUM.DecisionRetryTask ||
                            taskComponentId === taskTypeENUM.DecisionTask ||
                            taskComponentId ===
                              taskTypeENUM.DecisionTypeAutomatic ||
                            taskComponentId === taskTypeENUM.ReclassifyCase ||
                            taskComponentId ===
                              taskTypeENUM.StandarCommentTask) && (
                            <Popover
                              id="options-task-panel"
                              open={panelTaskOpen}
                              anchorEl={anchorTaskEl}
                              onClose={handleCloseTaskPanel}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              {getMenuItemButtons(
                                taskComponentId,
                                task,
                                controlRef,
                                handleCloseTaskPanel,
                              )}
                            </Popover>
                          )}
                      </div>
                    </div>
                    <div className="secondary">
                      <div className="task-responsible">
                        <TPIcon iconType={TPIconTypes.identity} />
                        <Tooltip
                          title={getTooltipBy(task.writerUserName)}
                          placement="right"
                        >
                          <p className="name">
                            <strong>{responsibleTimeLabel}:</strong>
                            <br />
                            {task.writerUserName}
                          </p>
                        </Tooltip>
                      </div>

                      <div className="task-extra">
                        <div className="task-calendar">
                          <TPIcon iconType={TPIconTypes.calendar} />
                          <p className="name">
                            <strong>{dueDateTimeLabel}:</strong>
                            <br />
                            {task.limitDateFormatted}
                          </p>
                        </div>
                        {remindersLoaded ? (
                          <TaskReminderButton
                            caseId={task.caseId}
                            taskId={task.id}
                            date={
                              caseReminders.find((r) => r.taskId === task.id)
                                ?.date
                            }
                            visible={
                              (task.taskStatusId !== "SO" &&
                                task.componentId !=
                                  taskTypeENUM.AutomaticAnswerTask &&
                                task.componentId !=
                                  taskTypeENUM.DecisionTypeAutomatic) ||
                              Boolean(
                                caseReminders.find((r) => r.taskId === task.id),
                              )
                            }
                          />
                        ) : (
                          <Skeleton
                            variant="rectangular"
                            width="25%"
                            height="100%"
                            animation="wave"
                          />
                        )}
                        <div className="show-more">
                          <TPButton
                            onClick={(e: any) => handleShowMore(e, task.id)}
                            customType={ButtonCustomType.tertiary}
                            isDesignSystem
                          >
                            {expandedCards[task.id]
                              ? hideAllLabel
                              : showAllLabel}
                          </TPButton>
                        </div>
                      </div>
                    </div>
                    {expandedCards[task.id] && (
                      <div className="secondary">
                        <div className="task-responsible">
                          <TPIcon iconType={TPIconTypes.calendar} />
                          <p className="name">
                            <strong>{creationDateLabel}:</strong>
                            <br />
                            {task.creationDateFormatted}
                          </p>
                        </div>

                        <div className="task-extra">
                          <div className="task">
                            <TPIcon iconType={TPIconTypes.calendar} isTooltip />
                            <p className="name">
                              <strong>{startDateLabel}:</strong>
                              <br />
                              {task.startDateFormatted}
                            </p>
                          </div>
                          <div className="task">
                            <TPIcon iconType={TPIconTypes.reader} />
                            <Tooltip
                              title={getTooltipBy(task.readerUserName)}
                              placement="right"
                            >
                              <p className="name">
                                <strong>{readerLabel}:</strong>
                                <br />
                                {task.readerUserName
                                  ? task.readerUserName
                                  : "--"}
                              </p>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    )}
                  </StyledAccordionHeaderOptions>
                </StyledAccordionHeader>
                <AccordionDetails>
                  {taskViewerState.items.map((item2, index2) => {
                    if (item2.id === task.id) {
                      let taskToRender: any = null;
                      switch (item2.componentId) {
                        case taskTypeENUM.SendEmailAutomatic:
                          taskToRender = (
                            <TPSendEmailAutomatic
                              caseNumber={caseNumberToView}
                              caseType={caseType}
                              mode={item2.canResolve ? "EDIT" : "VIEW"}
                              taskIdToView={item2.id}
                              isWorkflowTask={
                                item2.workflowDetailId ? true : false
                              }
                              closeTaskCallBack={handleTaskClose}
                              saveTaskCallBack={handleSaveTask}
                              ref={(element: any) => {
                                childRefArray.current[item2.id.toString()] =
                                  element;
                              }}
                              actionsTaskCallBack={handleActionsTaskCallBack}
                            />
                          );
                          break;
                        case taskTypeENUM.DecisionTypeAutomatic:
                          taskToRender = (
                            <TPYesNoTaskAutomatic
                              caseNumber={caseNumberToView}
                              mode={task.canResolve ? "EDIT" : "VIEW"}
                              isWorkflowTask={
                                task.workflowDetailId ? true : false
                              }
                              taskIdToView={item2.id}
                              closeTaskCallBack={handleTaskClose}
                              saveTaskCallBack={handleSaveTask}
                              ref={(element: any) => {
                                childRefArray.current[item2.id.toString()] =
                                  element;
                              }}
                              actionsTaskCallBack={handleActionsTaskCallBack}
                            />
                          );
                          break;
                        case taskTypeENUM.AutomaticAnswerTask:
                          taskToRender = (
                            <TPStandardTaskAutomatic
                              caseNumber={caseNumberToView}
                              mode={task.canResolve ? "EDIT" : "VIEW"}
                              taskIdToView={item2.id}
                              isWorkflowTask={
                                task.workflowDetailId ? true : false
                              }
                              closeTaskCallBack={handleTaskClose}
                              saveTaskCallBack={handleSaveTask}
                              ref={(element: any) => {
                                childRefArray.current[item2.id.toString()] =
                                  element;
                              }}
                              actionsTaskCallBack={handleActionsTaskCallBack}
                            />
                          );
                          break;
                        case taskTypeENUM.SendEmailTask:
                          taskToRender = (
                            <TPEmailTask
                              key={`email-task-${item2.id}`}
                              caseNumber={caseNumberToView}
                              mode={task.canResolve ? "EDIT" : "VIEW"}
                              taskIdToView={item2.id}
                              isWorkflowTask={
                                task.workflowDetailId ? true : false
                              }
                              closeTaskCallBack={handleTaskClose}
                              saveTaskCallBack={handleSaveTask}
                              ref={(element: any) => {
                                childRefArray.current[item2.id.toString()] =
                                  element;
                              }}
                              actionsTaskCallBack={handleActionsTaskCallBack}
                              loadingStatus={isTaskLoading}
                            />
                          );
                          break;
                        case taskTypeENUM.StandarCommentTask:
                          taskToRender = (
                            <TPStandardTask
                              caseNumber={caseNumberToView}
                              mode={task.canResolve ? "EDIT" : "VIEW"}
                              taskIdToView={item2.id}
                              isWorkflowTask={
                                task.workflowDetailId ? true : false
                              }
                              closeTaskCallBack={handleTaskClose}
                              saveTaskCallBack={handleSaveTask}
                              ref={(element: any) => {
                                childRefArray.current[item2.id.toString()] =
                                  element;
                              }}
                              actionsTaskCallBack={handleActionsTaskCallBack}
                              isCIM
                            />
                          );
                          break;
                        case taskTypeENUM.DecisionTask:
                          taskToRender = (
                            <TPYesNoTask
                              caseNumber={caseNumberToView}
                              mode={task.canResolve ? "EDIT" : "VIEW"}
                              isWorkflowTask={
                                task.workflowDetailId ? true : false
                              }
                              taskIdToView={item2.id}
                              closeTaskCallBack={handleTaskClose}
                              saveTaskCallBack={handleSaveTask}
                              ref={(element: any) => {
                                childRefArray.current[item2.id.toString()] =
                                  element;
                              }}
                              actionsTaskCallBack={handleActionsTaskCallBack}
                            />
                          );
                          break;
                        case taskTypeENUM.DecisionRetryTask:
                          taskToRender = (
                            <TPYesNoRetryTask
                              caseNumber={caseNumberToView}
                              mode={task.canResolve ? "EDIT" : "VIEW"}
                              isWorkflowTask={
                                task.workflowDetailId ? true : false
                              }
                              taskIdToView={item2.id}
                              closeTaskCallBack={handleTaskClose}
                              saveTaskCallBack={handleSaveTask}
                              ref={(element: any) => {
                                childRefArray.current[item2.id.toString()] =
                                  element;
                              }}
                              actionsTaskCallBack={handleActionsTaskCallBack}
                            />
                          );
                          break;
                        case taskTypeENUM.SystemTask:
                          taskToRender = (
                            <TPEmailTask
                              caseNumber={caseNumberToView}
                              mode={"VIEW"}
                              isWorkflowTask={
                                task.workflowDetailId ? true : false
                              }
                              taskIdToView={item2.id}
                              closeTaskCallBack={handleTaskClose}
                              saveTaskCallBack={handleSaveTask}
                              ref={(element: any) => {
                                childRefArray.current[item2.id.toString()] =
                                  element;
                              }}
                              actionsTaskCallBack={handleActionsTaskCallBack}
                              loadingStatus={isTaskLoading}
                            />
                          );
                          break;
                        case taskTypeENUM.ReclassifyCase:
                          taskToRender = (
                            <div>
                              <ReclassifyCase
                                key={`reclassify-task-${item2.id}`}
                                caseId={caseAndCustomerInfoState.caseNumber}
                                caseInfo={caseAndCustomerInfoState}
                                mode={task.canResolve ? "EDIT" : "VIEW"}
                                isWorkflowTask={
                                  task.workflowDetailId ? true : false
                                }
                                currentBaseLevelId={
                                  caseAndCustomerInfoState.baseLevelId
                                }
                                currentClientId={
                                  caseAndCustomerInfoState.clientId
                                }
                                currentSubsidiaryId={
                                  caseAndCustomerInfoState.subsidiaryId
                                }
                                currentSubsidiaryOrganizationId={
                                  caseAndCustomerInfoState.subSidiaryOrgId
                                }
                                ref={reclassifyCaseRef}
                                loadingStatus={isTaskLoading}
                              >
                                <>
                                  <h2 className="accordion-header">
                                    <button
                                      className={`accordion-button`}
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseReclassify1"
                                      onClick={() => TPGlobal.foo()}
                                    >
                                      {currentClassificationCaseLabel}
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseReclassify1"
                                    className="accordion-collapse collapse"
                                  >
                                    <div
                                      className={`accordion-body  ${
                                        caseAndCustomerInfoState.currentAccordionCase ===
                                        currentAccordionsCaseEnum.CLASSIFICATION
                                          ? "show"
                                          : ""
                                      }`}
                                    >
                                      <div className="row">
                                        <div className="col-3">
                                          <label className="tpbold tpfont12">
                                            {baseLevelLabel}
                                          </label>
                                          <br />
                                          <label className="tpfont12">
                                            {
                                              caseAndCustomerInfoState.baseLevelDescription
                                            }
                                          </label>
                                        </div>
                                        <div className="col-3">
                                          <label className="tpbold tpfont12">
                                            {caseCreatorLabel}
                                          </label>
                                          <br />
                                          <label className="tpfont12">
                                            {
                                              caseAndCustomerInfoState.caseNumber
                                            }
                                          </label>
                                        </div>
                                        {caseAndCustomerInfoState.classificator1Label && (
                                          <div className="col-3">
                                            <label className="tpbold tpfont12">
                                              {
                                                caseAndCustomerInfoState.classificator1Label
                                              }
                                            </label>
                                            <br />
                                            <label className="tpfont12">
                                              {
                                                caseAndCustomerInfoState.classificator1Value
                                              }
                                            </label>
                                          </div>
                                        )}
                                        {caseAndCustomerInfoState.classificator2Label && (
                                          <div className="col-3">
                                            <label className="tpbold tpfont12">
                                              {
                                                caseAndCustomerInfoState.classificator2Label
                                              }
                                            </label>
                                            <br />
                                            <label className="tpfont12">
                                              {
                                                caseAndCustomerInfoState.classificator2Value
                                              }
                                            </label>
                                          </div>
                                        )}
                                      </div>
                                      <div className="row mt-2">
                                        {caseAndCustomerInfoState.classificator3Label && (
                                          <div className="col-3">
                                            <label className="tpbold tpfont12">
                                              {
                                                caseAndCustomerInfoState.classificator3Label
                                              }
                                            </label>
                                            <br />
                                            <label className="tpfont12">
                                              {
                                                caseAndCustomerInfoState.classificator3Value
                                              }
                                            </label>
                                          </div>
                                        )}
                                        {caseAndCustomerInfoState.classificator4Label && (
                                          <div className="col-3">
                                            <label className="tpbold tpfont12">
                                              {
                                                caseAndCustomerInfoState.classificator4Label
                                              }
                                            </label>
                                            <br />
                                            <label className="tpfont12">
                                              {
                                                caseAndCustomerInfoState.classificator4Value
                                              }
                                            </label>
                                          </div>
                                        )}
                                        {caseAndCustomerInfoState.classificator5Label && (
                                          <div className="col-3">
                                            <label className="tpbold tpfont12">
                                              {
                                                caseAndCustomerInfoState.classificator5Label
                                              }
                                            </label>
                                            <br />
                                            <label className="tpfont12">
                                              {
                                                caseAndCustomerInfoState.classificator5Value
                                              }
                                            </label>
                                          </div>
                                        )}
                                        <div className="col-3">
                                          <label className="tpbold tpfont12">
                                            {caseDateLabel}
                                          </label>
                                          <br />
                                          <label className="tpfont12">
                                            {
                                              caseAndCustomerInfoState.insertDateFormatted
                                            }
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row mt-2">
                                        <div className="col-3">
                                          <label className="tpbold tpfont12">
                                            {customerPromiseSolvingdateLabel}
                                          </label>
                                          <br />
                                          <label className="tpfont12">
                                            {
                                              caseAndCustomerInfoState.promiseDateFormatted
                                            }
                                          </label>
                                        </div>
                                        <div className="col-3">
                                          <label className="tpbold tpfont12">
                                            {closingdateLabel}
                                          </label>
                                          <br />
                                          <label className="tpfont12">
                                            {
                                              caseAndCustomerInfoState.closeDateFormatted
                                            }
                                          </label>
                                        </div>
                                        <div className="col-4">
                                          <label className="tpbold tpfont12">
                                            {caseResponsibleLabel}
                                          </label>
                                          <br />
                                          <label className="tpfont12">
                                            {
                                              caseAndCustomerInfoState.caseResponsibleName
                                            }
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              </ReclassifyCase>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  marginRight: "8px",
                                  gap: 10,
                                  padding: "16px",
                                }}
                              >
                                <TPButton
                                  onClick={() =>
                                    handleCancelReclassify(
                                      "ReclassifyPanel",
                                      caseNumberToView,
                                    )
                                  }
                                  isDesignSystem
                                  style={{
                                    paddingLeft: "16px",
                                    paddingRight: "16px",
                                    backgroundColor: "white",
                                    color: "purple",
                                  }}
                                >
                                  {cancelButtonLabel}
                                </TPButton>
                                <TPButton
                                  onClick={() =>
                                    handleReclassifySave(reclassifyCaseRef)
                                  }
                                  isDesignSystem
                                  style={{
                                    paddingLeft: "16px",
                                    paddingRight: "16px",
                                  }}
                                >
                                  {saveButtonLabel}
                                </TPButton>
                              </div>
                            </div>
                          );
                      }
                      return (
                        <div
                          className="d-flex justify-content-between"
                          key={"task" + index2.toString()}
                        >
                          {taskToRender ? (
                            <div
                              className="task-details-container"
                              id={"task" + caseViewerGuid + item2.id}
                            >
                              {taskToRender}
                            </div>
                          ) : (
                            <p>No task type found</p>
                          )}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </AccordionDetails>
              </StyledAccordion>
            </StyledTimelineContent>
          );
        })}
      </div>
    );
    return jsxElement;
  };

  const handleCallBackModal = async (
    confirmDelete: boolean,
    callBackData: any,
  ) => {
    let newModalQuestionState: TPModalQuestionState;
    newModalQuestionState = { ...modalQuestionState };
    newModalQuestionState.isShown = false;
    newModalQuestionState.callBackData = {};
    setModalQuestionState(newModalQuestionState);
    if (confirmDelete) {
      let command1: commandTaskViewerType = {
        type: commandsTaskViewerEnum.remove_task,
        payload: {
          newTaskId: callBackData.taskId,
        },
      };
      dispatchTaskViewerCommand(command1);
    }
  };

  const handleAddTaskOnClick = (e: any, caseNumber: number) => {
    setToogleActionPanel(false);
    let newModalNewTaskState: TPModalAddTaskState;
    newModalNewTaskState = { ...modalNewTaskState };
    newModalNewTaskState.isShown = true;
    newModalNewTaskState.callBackData = { newCaseNumber: caseNumber };
    setModalNewTaskState(newModalNewTaskState);
  };

  const handleCallBackNewTaskModal = async (
    confirmAdd: boolean,
    callBackData: any,
  ) => {
    let newModalNewTaskState: TPModalAddTaskState;
    newModalNewTaskState = { ...modalNewTaskState };
    newModalNewTaskState.isShown = false;
    newModalNewTaskState.callBackData = { callBackData };
    setModalNewTaskState(newModalNewTaskState);

    if (confirmAdd && callBackData) {
      setIsLoadingScreen(false);
      handleRefreshTimelineClick();
    }
  };

  const handleAddCommentsOnClick = (e: any, caseNumber: number) => {
    setToogleActionPanel(false);
    let newModalNewCommentsState: TPModalAddTaskCommentsState;
    newModalNewCommentsState = { ...modalNewCommentsState };
    newModalNewCommentsState.isShown = true;
    newModalNewCommentsState.callBackData = { newCaseNumber: caseNumber };
    setModalNewCommentsState(newModalNewCommentsState);
  };

  const handleCallBackNewCommentModal = async (
    confirmAdd: boolean,
    callBackData: any,
  ) => {
    let newModalNewCommentsState: TPModalAddTaskCommentsState;
    newModalNewCommentsState = { ...modalNewCommentsState };
    newModalNewCommentsState.isShown = false;
    newModalNewCommentsState.callBackData = { callBackData };
    setModalNewCommentsState(newModalNewCommentsState);

    if (confirmAdd && callBackData) {
      setIsLoadingScreen(false);
      handleRefreshTimelineClick();
    }
  };

  const handleCallBackNewAttachmentModal = async (confirmAdd: boolean) => {
    if (confirmAdd) {
      let serviceClient = new CaseService();
      let expectedCodes: Array<number> = [200];
      let blobId: string;
      let filename: string;
      let extension: string;
      let parts: Array<string> = [];
      let attachmentData: any;
      let newData: any;
      let hasError: boolean = false;
      let newCaseAttachmentState = { ...caseAttachmentState };
      if (
        caseAttachmentState.filesDraggedList &&
        caseAttachmentState.filesDraggedList.length > 0
      ) {
        setIsLoadingScreenModalAttachment(true);
        for (let i = 0; i < caseAttachmentState.filesDraggedList.length; i++) {
          const element = caseAttachmentState.filesDraggedList[i];
          filename = element.name;
          parts = filename.split(".");
          if (parts.length === 1) {
            extension = "";
          } else {
            extension = parts[parts.length - 1];
          }
          blobId = "caseblob" + uuidv4().replaceAll("-", "");
          const fileData = new FormData();
          fileData.append("fileData", element);
          fileData.append("blobId", blobId);
          fileData.append("filename", filename);
          fileData.append("extensionName", extension);
          fileData.append("sender", caseAttachmentState.sender);
          fileData.append("description", caseAttachmentState.description);
          fileData.append("guid_USER", TPGlobal.currentUserGuid);
          fileData.append(
            "caseId",
            caseAndCustomerInfoState.caseNumber.toString(),
          );
          let recordInputDTO: AttachmentInputDTO = {
            caseId: caseAndCustomerInfoState.caseNumber,
            blobId: blobId,
            filename: filename,
            extensionName: extension,
            sender: caseAttachmentState.sender,
            description: caseAttachmentState.description,
            guid_USER: TPGlobal.currentUserGuid,
            fileData: element,
          };
          let inputDTOValidator = new AttachmentInputDTODTOValidator();
          let resultValidator = inputDTOValidator.validate(recordInputDTO);
          if (!TPGlobal.TPIsEmpty(resultValidator)) {
            var listPropertyNames = Object.keys(resultValidator);
            if (listPropertyNames) {
              for (let index = 0; index < listPropertyNames.length; index++) {
                const element = listPropertyNames[index];
                if (resultValidator[element]) {
                  newCaseAttachmentState[element + "ErrorMessage"] =
                    await TPI18N.GetResource(
                      resultValidator[element] as string,
                    );
                } else {
                  newCaseAttachmentState[element + "ErrorMessage"] = "";
                }
              }
            }
            if (hasError) {
            }
            setCaseAttachmentState(newCaseAttachmentState);
            setIsLoadingScreenModalAttachment(false);
            return;
          }
          let responseRequest = await serviceClient.insertAttachment(
            fileData,
            false,
            true,
            expectedCodes,
          );
          if (responseRequest.responseResult) {
            showToast(
              responseRequest.responseData.responseMessage.message,
              TPToastTypes.success,
            );
            attachmentData = await getCaseAttachments(
              caseAndCustomerInfoState.caseNumber,
            );
            newData = attachmentData;
            let command1: commandCaseAndCustomerInfoType = {
              type: commandsCaseAndCustomerInfoEnum.change_case_accordion_info,
              payload: {
                newAccordion: currentAccordionsCaseEnum.ATTACHMENTS,
                newData: newData,
              },
            };
            dispatchCaseAndCustomerInfoCommand(command1);
          }
        }
        setIsLoadingScreenModalAttachment(false);
      } else {
        newCaseAttachmentState.notFilesErrorMessage = await TPI18N.GetText(
          resourceSet,
          "NotFilesErrorMessageLabel",
        );
        setCaseAttachmentState(newCaseAttachmentState);
        return;
      }
    }
    setCaseAttachmentState(initialCaseAttachmentType);
    setIsSownModalAttachment(false);
  };

  const handleCallbackModalPDF = () => {
    let newModalPDF: TPModalPDFViewerStateType = initialPDFModal;
    setModalPDF(newModalPDF);
  };

  const handleReclassifyCase = async (e: any, caseNumber: number) => {
    setToogleActionPanel(false);
    let newModalReclassifyProps: TPModalProps = {
      ...modalReclassifyProps,
      enableAcceptButton: true,
      callBackAnswer: handleCallBackModalReclassify,
      isShown: true,
      titleModal: await TPI18N.GetText(resourceSet, "TitleReclassifyCaseModal"),
      acceptLabel: await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      cancelLabel: await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "CancelButton",
      ),
    };
    setModalReclassifyProps(newModalReclassifyProps);
  };

  const handleCallBackModalReclassify = async (
    acceptButton: boolean,
    callBackData: any,
  ) => {
    if (acceptButton) {
      if (reclassifyCaseRef) {
        let acceptModalReclassify: TPModalProps = {
          ...modalReclassifyProps,
          isShown: true,
          enableAcceptButton: false,
        };
        setModalReclassifyProps(acceptModalReclassify);
        let reclassifyResponse =
          await reclassifyCaseRef.current.reclassifyCase();
        if (reclassifyResponse) {
          let newModalReclassifyProps: TPModalProps = {
            ...modalReclassifyProps,
            isShown: false,
            enableAcceptButton: true,
          };
          setModalReclassifyProps(newModalReclassifyProps);
        } else {
          let newModalReclassifyProps: TPModalProps = {
            ...modalReclassifyProps,
            isShown: true,
            callBackAnswer: handleCallBackModalReclassify,
            enableAcceptButton: true,
          };
          setModalReclassifyProps(newModalReclassifyProps);
        }
        loadResourcesAndInitialInfo();
      }
    } else {
      let newModalReclassifyProps: TPModalProps = {
        ...modalReclassifyProps,
        isShown: false,
      };
      setModalReclassifyProps(newModalReclassifyProps);
    }
  };

  const handleReassignCase = async (e: any, caseNumber: number) => {
    setToogleActionPanel(false);
    let newModalReassignProps: TPModalProps = {
      ...modalReassignProps,
      callBackAnswer: handleCallBackModalReassign,
      isShown: true,
      titleModal: await TPI18N.GetText(resourceSet, "TitleReassignCaseModal"),
      acceptLabel: await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      cancelLabel: await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "CancelButton",
      ),
    };
    setModalReassignProps(newModalReassignProps);
  };

  const handleCallBackModalAlertReassign = (
    acceptButton: boolean,
    callBackData: any,
  ) => {
    handleCallBackModalReassign(acceptButton, callBackData);
  };

  const handleCallBackModalReassign = async (
    confirm: boolean,
    callBackData: any,
  ) => {
    if (confirm) {
      if (reassignCaseRef) {
        let reassignResponse = await reassignCaseRef.current.reassignCase();
        if (reassignResponse) {
          let newModalReclassifyProps: TPModalProps = {
            ...modalReassignProps,
            isShown: false,
          };
          setModalReassignProps(modalReassignProps);
        }
        loadResourcesAndInitialInfo();
      }
    } else {
      let newModalReassignProps: TPModalProps = {
        ...modalReassignProps,
        isShown: false,
      };
      setModalReassignProps(newModalReassignProps);
    }
  };

  const handleExportToPDF = async (e: any, caseNumber: number) => {
    setToogleActionPanel(false);
    let serviceClient = new CaseService();
    let expectedCodes: Array<number> = [200];
    let fileName: string;
    try {
      fileName = caseNumber.toString() + ".pdf";
      setIsLoadingScreen(true);
      let casePDF = await serviceClient.getPDFCase(
        caseNumberToView,
        true,
        true,
        true,
        true,
        true,
        false,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      let newModalPDF: TPModalPDFViewerStateType = {
        isShown: true,
        title: casePrintOutLabel,
        pdfBase64: casePDF,
        fileName: "case_" + caseNumberToView.toString() + ".pdf",
        callBackFunction: handleCallbackModalPDF,
      };
      setModalPDF(newModalPDF);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleExportToPDF ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} handleExportToPDF ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleCallBackTaskConventionsModal = async () => {
    let newModalState: TPModalTaskStatusConventionsStateType = {
      ...modalTaskStatusConventions,
    };
    newModalState.isShown = false;
    setModalTaskStatusConventions(newModalState);
  };

  const handleConventionsHelpClick = async () => {
    let newModalState: TPModalTaskStatusConventionsStateType = {
      ...modalTaskStatusConventions,
    };
    newModalState.isShown = true;
    newModalState.title = await TPI18N.GetText(resourceSet, "TaskConventions");
    newModalState.yesLabel = await TPI18N.GetText(
      TPGlobal.globalResourceSet,
      "OkButton",
    );
    newModalState.callBackFunction = handleCallBackTaskConventionsModal;
    setModalTaskStatusConventions(newModalState);
    setCaseStatusModal(true);
  };

  const renderCaseStatusIcon = () => {
    let jsxElement: any;
    switch (caseAndCustomerInfoState.caseStatusId) {
      case "SO":
        jsxElement = (
          <TPIcon
            iconType={TPIconTypes.checkCircle}
            style={{ color: "#69c169", fontSize: "18px" }}
            inline={true}
          />
        );
        break;
      case "EX":
        jsxElement = (
          <TPIcon
            iconType={TPIconTypes.warning}
            style={{ color: "red", fontSize: "18px" }}
            inline={true}
          />
        );
        break;
      case "PE":
        jsxElement = (
          <TPIcon
            iconType={TPIconTypes.watchLater}
            style={{ color: "#69c169", fontSize: "18px" }}
            inline={true}
          />
        );
        break;
      default:
        jsxElement = null;
    }
    return jsxElement;
  };

  const handleNewAttahcment = () => {
    setIsSownModalAttachment(true);
  };

  const handleDeleteAttachment = async (blobId: string) => {
    let newModalQuestionState: TPModalQuestionState;
    newModalQuestionState = { ...modalQuestionDeleteAttachmentState };
    newModalQuestionState.isShown = true;
    newModalQuestionState.callBackData = { blobId: blobId };
    setModalQuestionDeleteAttachmentState(newModalQuestionState);
  };

  const handleCallBackModalDeleteAttachment = async (
    confirmDelete: boolean,
    callBackData: any,
  ) => {
    let newModalQuestionState: TPModalQuestionState;
    newModalQuestionState = { ...modalQuestionDeleteAttachmentState };
    newModalQuestionState.isShown = false;
    newModalQuestionState.callBackData = {};
    setModalQuestionDeleteAttachmentState(newModalQuestionState);
    if (confirmDelete) {
      await deleteAttachment(callBackData.blobId);
    }
  };

  const deleteAttachment = async (blobId: string) => {
    let serviceClient = new AttachmentService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.deleteAttachmentByBlobId(
        blobId,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      await loadCaseAttachments(true);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} deleteAttachment ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} deleteAttachment ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleOnAttachmentValueChange = (
    newValue: string,
    propertyName: string,
  ) => {
    let newCaseAttachmentState = { ...caseAttachmentState };
    newCaseAttachmentState[propertyName] = newValue;
    newCaseAttachmentState[`${propertyName}ErrorMessage`] = "";
    setCaseAttachmentState(newCaseAttachmentState);
  };

  const handleUploadFilesDraggedChange = (filesList: any) => {
    let newCaseAttachmentState = { ...caseAttachmentState };
    newCaseAttachmentState.filesDraggedList = filesList;
    newCaseAttachmentState.notFilesErrorMessage = "";
    setCaseAttachmentState(newCaseAttachmentState);
  };

  const handleOnDeleteAttachment = (itemName: string) => {
    let newCaseAttachmentState = { ...caseAttachmentState };
    let newFilesDraggedList = [...newCaseAttachmentState.filesDraggedList];
    newFilesDraggedList = newFilesDraggedList.filter(
      (x: any) => x.name !== itemName,
    );
    newCaseAttachmentState.filesDraggedList = newFilesDraggedList;
    setCaseAttachmentState(newCaseAttachmentState);
  };

  function doCaseInfoCommand(
    prevState: CaseInfoStateType,
    command: commandCaseAndCustomerInfoType,
  ) {
    let newCaseAndCustomerInfoState: CaseInfoStateType;
    switch (command.type) {
      case commandsCaseAndCustomerInfoEnum.load_case_info:
        newCaseAndCustomerInfoState = { ...prevState };
        newCaseAndCustomerInfoState.caseNumber = command.payload.newCaseNumber;
        newCaseAndCustomerInfoState.caseStatusId =
          command.payload.newCaseStatusId;
        newCaseAndCustomerInfoState.caseStatusLocalizedDescription =
          command.payload.newCaseStatusLocalizedDescription;
        newCaseAndCustomerInfoState.workflowTypeId =
          command.payload.newWorkflowTypeId;
        newCaseAndCustomerInfoState.workflowTypeLocalizedDescription =
          command.payload.newWorkflowTypeLocalizedDescription;
        newCaseAndCustomerInfoState.clientId = command.payload.newClientId;
        newCaseAndCustomerInfoState.subsidiaryId =
          command.payload.newSubsidiaryId;
        newCaseAndCustomerInfoState.subSidiaryOrgId =
          command.payload.newSubSidiaryOrgId;
        newCaseAndCustomerInfoState.promiseDate =
          command.payload.newPromiseDate;
        newCaseAndCustomerInfoState.promiseDateFormatted =
          command.payload.newPromiseDateFormatted;
        newCaseAndCustomerInfoState.insertDateFormatted =
          command.payload.newInsertDateFormatted;
        newCaseAndCustomerInfoState.closeDateFormatted =
          command.payload.newCloseDateFormatted;
        newCaseAndCustomerInfoState.closeDate = command.payload.newCloseDate;
        newCaseAndCustomerInfoState.typistUserId =
          command.payload.newTypistUserId;
        newCaseAndCustomerInfoState.typistName = command.payload.newTypistName;
        newCaseAndCustomerInfoState.caseResponsibleName =
          command.payload.newCaseResponsibleName;
        newCaseAndCustomerInfoState.baseLevelId =
          command.payload.newBaseLevelId;
        newCaseAndCustomerInfoState.baseLevelDescription =
          command.payload.newBaseLevelLocalizedDescription;
        newCaseAndCustomerInfoState.classificator1 =
          command.payload.newClassificator1;
        newCaseAndCustomerInfoState.classificator1Label =
          command.payload.newClassificator1Label;
        newCaseAndCustomerInfoState.classificator1Value =
          command.payload.newClassificator1Value;
        newCaseAndCustomerInfoState.classificator2 =
          command.payload.newClassificator2;
        newCaseAndCustomerInfoState.classificator2Label =
          command.payload.newClassificator2Label;
        newCaseAndCustomerInfoState.classificator2Value =
          command.payload.newClassificator2Value;
        newCaseAndCustomerInfoState.classificator3 =
          command.payload.newClassificator3;
        newCaseAndCustomerInfoState.classificator3Label =
          command.payload.newClassificator3Label;
        newCaseAndCustomerInfoState.classificator3Value =
          command.payload.newClassificator3Value;
        newCaseAndCustomerInfoState.classificator4 =
          command.payload.newClassificator4;
        newCaseAndCustomerInfoState.classificator4Label =
          command.payload.newClassificator4Label;
        newCaseAndCustomerInfoState.classificator4Value =
          command.payload.newClassificator4Value;
        newCaseAndCustomerInfoState.classificator5 =
          command.payload.newClassificator5;
        newCaseAndCustomerInfoState.classificator5Label =
          command.payload.newClassificator5Label;
        newCaseAndCustomerInfoState.classificator5Value =
          command.payload.newClassificator5Value;
        newCaseAndCustomerInfoState.comments = command.payload.newComments;
        newCaseAndCustomerInfoState.isAnonymousCustomer =
          command.payload.newIsAnonymousCustomer;
        newCaseAndCustomerInfoState.contactId = command.payload.newContactId;
        newCaseAndCustomerInfoState.contactName =
          command.payload.newContactName;
        return newCaseAndCustomerInfoState;
      case commandsCaseAndCustomerInfoEnum.load_customer_info:
        newCaseAndCustomerInfoState = { ...prevState };
        newCaseAndCustomerInfoState.alreadyClickCustomerTab = true;
        newCaseAndCustomerInfoState.customerNames =
          command.payload.newCustomerNames;
        newCaseAndCustomerInfoState.customerType =
          command.payload.newCustomerType;
        newCaseAndCustomerInfoState.customerDocument =
          command.payload.newCustomerDocument;
        newCaseAndCustomerInfoState.customerEmail =
          command.payload.newCustomerEmail;
        newCaseAndCustomerInfoState.customerPhone =
          command.payload.newCustomerPhone;
        newCaseAndCustomerInfoState.customerInternalCode =
          command.payload.newCustomerInternalCode;
        newCaseAndCustomerInfoState.customerDocumentType =
          command.payload.newCustomerDocumentType;
        return newCaseAndCustomerInfoState;
      case commandsCaseAndCustomerInfoEnum.change_currentTab:
        newCaseAndCustomerInfoState = { ...prevState };
        newCaseAndCustomerInfoState.currentTab = command.payload.newCurrentTab;
        return newCaseAndCustomerInfoState;
      case commandsCaseAndCustomerInfoEnum.change_case_accordion_info:
        newCaseAndCustomerInfoState = { ...prevState };
        switch (command.payload.newAccordion) {
          case currentAccordionsCaseEnum.CLASSIFICATION:
            newCaseAndCustomerInfoState.alreadyClickClassifiers = true;
            break;
          case currentAccordionsCaseEnum.ATTACHMENTS:
            newCaseAndCustomerInfoState.alreadyClickCaseAttachments = true;
            newCaseAndCustomerInfoState.gridCaseAttachmentsData =
              command.payload.newData;
            break;
          case currentAccordionsCaseEnum.ADDITIONALDATA:
            newCaseAndCustomerInfoState.alreadyClickAdditionalDataCase = true;
            newCaseAndCustomerInfoState.additionalDataValues = [
              ...command.payload.newData,
            ];
            break;
          default:
            break;
        }
        return newCaseAndCustomerInfoState;
      case commandsCaseAndCustomerInfoEnum.change_customer_accordion_info:
        newCaseAndCustomerInfoState = { ...prevState };
        switch (command.payload.newAccordion) {
          case currentAccordionsCustomerEnum.HISTORY:
            newCaseAndCustomerInfoState.alreadyClickCustomerHistory = true;
            newCaseAndCustomerInfoState.gridCustomerHistory =
              command.payload.newData;
            break;
          case currentAccordionsCustomerEnum.ATTACHMENTS:
            newCaseAndCustomerInfoState.alreadyClickCustomerAttachments = true;
            newCaseAndCustomerInfoState.gridCustomerAttachmentsData =
              command.payload.newData;
            break;
          case currentAccordionsCustomerEnum.CONTACTS:
            newCaseAndCustomerInfoState.alreadyClickCustomerContacts = true;
            newCaseAndCustomerInfoState.gridCustomerContacts =
              command.payload.newData;
            break;
        }
        return newCaseAndCustomerInfoState;
      case commandsCaseAndCustomerInfoEnum.change_case_status:
        newCaseAndCustomerInfoState = { ...prevState };
        newCaseAndCustomerInfoState.caseStatusId =
          command.payload.newCaseStatusId;
        newCaseAndCustomerInfoState.caseStatusLocalizedDescription =
          command.payload.newCaseStatusLocalizedDescription;
        return newCaseAndCustomerInfoState;
    }
  }

  const initalTimelineState: TimelineStateType = {
    items: [],
    currentOrder: "ASC",
  };
  const [timelineState, dispatchTimelineCommand] = useReducer(
    doTimelineCommand,
    initalTimelineState,
  );
  function doTimelineCommand(
    prevState: TimelineStateType,
    command: commandTimelineType,
  ) {
    let newTimelineState: TimelineStateType;
    switch (command.type) {
      case commandsTimelineEnum.load_timeline:
        newTimelineState = { ...prevState };
        newTimelineState.items = command.payload.newItems;
        return newTimelineState;
      case commandsTimelineEnum.change_ordered_Timeline:
        newTimelineState = { ...prevState };
        newTimelineState.currentOrder = command.payload.newCurrentOrder;
        newTimelineState.items = command.payload.newItems;
        return newTimelineState;
    }
  }

  const initialTaskViewerState: TaskViewerStateType = {
    items: [],
  };
  const [taskViewerState, dispatchTaskViewerCommand] = useReducer(
    doTaskViewerCommand,
    initialTaskViewerState,
  );
  function doTaskViewerCommand(
    prevState: TaskViewerStateType,
    command: commandTaskViewerType,
  ) {
    let newTaskViewerState: TaskViewerStateType;
    switch (command.type) {
      case commandsTaskViewerEnum.add_task:
        newTaskViewerState = { ...prevState };
        newTaskViewerState.items = [...command.payload.newItems];
        setCurrentSelectedTaskId(command.payload.newClickedItem);
        setTimeout(() => {
          var element = document.querySelector(
            "#taskli" + caseViewerGuid + command.payload.newClickedItem,
          );
          if (element) {
            element.scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
          }
        }, 500);
        return newTaskViewerState;
      case commandsTaskViewerEnum.click_existing_task:
        newTaskViewerState = { ...prevState };
        setTimeout(() => {
          var element = document.querySelector(
            "#taskli" + caseViewerGuid + command.payload.newClickedItem,
          );
          if (element) {
            element.scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
          }
        }, 500);
        return newTaskViewerState;
      case commandsTaskViewerEnum.remove_task:
        newTaskViewerState = { ...prevState };
        let taskId: number = command.payload.newTaskId;
        newTaskViewerState.items = newTaskViewerState.items.filter(
          (x) => x.id !== taskId,
        );
        return newTaskViewerState;
    }
  }

  //Update Customer
  const handleEditCustomerOnClick = () => {
    setClientMode("Update");
    setIsShownModalNewClient(true);
  };

  //Email
  const handleGetOriginalEmail = async () => {
    let taskService = new TaskService();
    let __expectedCodes: Array<number> = [200, 404];
    var responseRequest = new Array();
    try {
      responseRequest = await taskService.getInboundMailboxReadByCaseId(
        caseNumberToView,
        false,
        true,
        __expectedCodes,
      );
      let insertionTypeTemp = responseRequest.find(
        (s) => s.insertionType == insertionType,
      );
      if (insertionTypeTemp) {
        setOriginalEmail(insertionTypeTemp.htmlBody);
      }
    } catch (error) {
      setOriginalEmail("...");
    }
  };

  useEffect(() => {
    loadResourcesAndInitialInfo();
    handleGetOriginalEmail();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await refreshScreenInformation();
    };
    if (casecontext.updateTimeLine > 0) {
      switch (casecontext.messageType) {
        case SignalRSubtopicEnum.NEXT_TASKS:
          fetchData().catch(console.error);
          break;
        case SignalRSubtopicEnum.ADD_TASK:
          handleRefreshTimelineClick();
          break;
        case SignalRSubtopicEnum.REJECT_TASK:
          handleRefreshTimelineClick();
          break;
        case SignalRSubtopicEnum.RECLASSIFY_CASE:
          fetchData().catch(console.error);
          break;
        default:
          break;
      }
    }
  }, [casecontext.updateTimeLine]);

  const formatTime = (totalSeconds: number): string => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const fetchTimerSeconds = () => {
    const savedTimers = localStorage.getItem("timers");

    if (savedTimers) {
      const timers: any[] = JSON.parse(savedTimers);
      const timer = timers.find(
        (timer) => timer.caseNumber === caseAndCustomerInfoState?.caseNumber,
      );
      if (timer && !formattedTime) {
        setFormattedTime(formatTime(timer.seconds));
      }
    }
  };

  useEffect(() => {
    // fetchTimerSeconds();
    // const intervalId = setInterval(() => {
    //     fetchTimerSeconds();
    // }, 500);
    // return () => clearInterval(intervalId);
    getToolbarOptions();
  }, []);

  useEffect(() => {
    if (!toolbarItemsLoaded) getToolbarOptions();
  }, [toolbarItemsLoaded]);

  const refreshScreenInformation = async () => {
    handleRefreshTimelineClick();
    let newCaseInfo: CasesViewModel | null;
    newCaseInfo = await getCaseInfoByNumber(caseNumberToView);
    if (newCaseInfo) {
      let command3: commandCaseAndCustomerInfoType = {
        type: commandsCaseAndCustomerInfoEnum.change_case_status,
        payload: {
          newCaseStatusId: newCaseInfo.caseStatusId,
          newCaseStatusLocalizedDescription:
            newCaseInfo.caseStatusLocalizedDescription,
        },
      };
      dispatchCaseAndCustomerInfoCommand(command3);
    }
  };

  const [autocompleteToOptions, setAutocompleteToOptions] = useState<
    Array<TPKeyValue>
  >([]);

  const handleToOnAutocompleteQuery = async (query: string) => {
    let tasktypeService = new UserService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      //Load users by search
      let responseRequest =
        await tasktypeService.getActiveUsersBySearchParameter(
          query,
          false,
          true,
          expectedCodes,
        );
      let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(function (
        item: UserViewModel,
      ) {
        return {
          key: item.userGuid,
          value: `${item.name}`,
        };
      });
      setAutocompleteToOptions(newToKeyValueList);
      return newToKeyValueList;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
      );
      return [];
    }
  };

  return (
    <>
      {/* Delete attachment modal 123 */}

      {/* TODO: modal goes here */}

      <ReassignRejectModal
        currentActionType={selectedActionType}
        currentTask={selectedTask}
        isShown={isReassignRejectModal}
        closeCallBack={() => setIsReassignRejectModal(false)}
      />

      {isShownModalNewClient && currentSubsidiaryOrgId > -1 && (
        <TPModalNewClient
          mode={clientMode}
          acceptLabel={saveButtonLabel}
          cancelLabel={cancelButtonLabel}
          isShown={isShownModalNewClient}
          callBackAnswer={(e: any) => {
            setIsShownModalNewClient(false);
            loadResourcesAndInitialInfo();
          }}
          subsidiaryOrgId={currentSubsidiaryOrgId}
        ></TPModalNewClient>
      )}

      {/* Modals of CaseViewer  */}

      {modalNewTaskState.isShown && (
        <TPModalAddTask
          caseNumber={caseNumberToView}
          acceptLabel={deleteOkLabel}
          cancelLabel={deleteCanceLabel}
          isShown={modalNewTaskState.isShown}
          callBackAnswer={handleCallBackNewTaskModal}
        />
      )}

      {/* {modalTaskStatusConventions.isShown && (
                <TPModalCaseStatusConventions
                    modalState={{
                        isShown: modalTaskStatusConventions.isShown,
                        title: modalTaskStatusConventions.title,
                        yesLabel: modalTaskStatusConventions.yesLabel,
                        callBackFunction: modalTaskStatusConventions.callBackFunction,
                    }}
                />
            )} */}

      {modalNewCommentsState.isShown && (
        <TPModalAddTaskComment
          caseNumber={caseNumberToView}
          acceptLabel={deleteOkLabel}
          cancelLabel={deleteCanceLabel}
          isShown={modalNewCommentsState.isShown}
          callBackData={modalNewCommentsState.callBackData}
          callBackAnswer={handleCallBackNewCommentModal}
        />
      )}

      {modalDifferences.isShown && (
        <TPModal modalState={modalDifferences}>
          <div className="row">
            <div className="col-3">
              <img src={questionImg} width={80}></img>
            </div>
            <div className="col">
              <p className="tpbold">{questionModalDifferencesLabel}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div
                className="tpFixedHeadTable highlightOnHover table-responsive"
                style={{ height: "200px" }}
              >
                <table className="tp-fixed-table">
                  <thead>
                    <tr>
                      <th>
                        <TPLanguage
                          resourceSet={resourceSet}
                          resourceId="SourceLabel"
                        />
                      </th>
                      <th>
                        <TPLanguage
                          resourceSet={resourceSet}
                          resourceId="SourceValueLabel"
                        />
                      </th>
                      <th>
                        <TPLanguage
                          resourceSet={resourceSet}
                          resourceId="AdditionalDataLabel"
                        />
                      </th>
                      <th>
                        <TPLanguage
                          resourceSet={resourceSet}
                          resourceId="CurrentValueLabel"
                        />
                      </th>
                      <th>
                        <TPLanguage
                          resourceSet={resourceSet}
                          resourceId="NewValueLabel"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {additionalDataDifferences.map(function (
                      item: AdditionalDataCompareViewModel,
                      index,
                    ) {
                      return (
                        <tr key={"tr" + index.toString()}>
                          <td>{item.source}</td>
                          <td>{item.sourceValue}</td>
                          <td>{`${item.additionalDataId} - ${item.additionalDataLocalizedDescription}`}</td>
                          <td>{item.currentValue}</td>
                          <td>{item.newValue}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </TPModal>
      )}

      {modalReclassifyProps.isShown && (
        <TPModal modalState={modalReclassifyProps}>
          <ReclassifyCase
            ref={reclassifyCaseRef}
            caseId={caseAndCustomerInfoState.caseNumber}
            caseInfo={caseAndCustomerInfoState}
            currentBaseLevelId={caseAndCustomerInfoState.baseLevelId}
            currentClientId={caseAndCustomerInfoState.clientId}
            currentSubsidiaryId={caseAndCustomerInfoState.subsidiaryId}
            currentSubsidiaryOrganizationId={
              caseAndCustomerInfoState.subSidiaryOrgId
            }
          >
            <>
              <h2 className="accordion-header">
                <button
                  className={`accordion-button`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseReclassify1"
                  onClick={() => TPGlobal.foo()}
                >
                  {currentClassificationCaseLabel}
                </button>
              </h2>
              <div
                id="collapseReclassify1"
                className="accordion-collapse collapse"
              >
                <div
                  className={`accordion-body  ${
                    caseAndCustomerInfoState.currentAccordionCase ===
                    currentAccordionsCaseEnum.CLASSIFICATION
                      ? "show"
                      : ""
                  }`}
                >
                  <div className="row">
                    <div className="col-3">
                      <label className="tpbold tpfont12">
                        {baseLevelLabel}
                      </label>
                      <br />
                      <label className="tpfont12">
                        {caseAndCustomerInfoState.baseLevelDescription}
                      </label>
                    </div>
                    <div className="col-3">
                      <label className="tpbold tpfont12">
                        {caseCreatorLabel}
                      </label>
                      <br />
                      <label className="tpfont12">
                        {caseAndCustomerInfoState.caseNumber}
                      </label>
                    </div>
                    {caseAndCustomerInfoState.classificator1Label && (
                      <div className="col-3">
                        <label className="tpbold tpfont12">
                          {caseAndCustomerInfoState.classificator1Label}
                        </label>
                        <br />
                        <label className="tpfont12">
                          {caseAndCustomerInfoState.classificator1Value}
                        </label>
                      </div>
                    )}
                    {caseAndCustomerInfoState.classificator2Label && (
                      <div className="col-3">
                        <label className="tpbold tpfont12">
                          {caseAndCustomerInfoState.classificator2Label}
                        </label>
                        <br />
                        <label className="tpfont12">
                          {caseAndCustomerInfoState.classificator2Value}
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="row mt-2">
                    {caseAndCustomerInfoState.classificator3Label && (
                      <div className="col-3">
                        <label className="tpbold tpfont12">
                          {caseAndCustomerInfoState.classificator3Label}
                        </label>
                        <br />
                        <label className="tpfont12">
                          {caseAndCustomerInfoState.classificator3Value}
                        </label>
                      </div>
                    )}
                    {caseAndCustomerInfoState.classificator4Label && (
                      <div className="col-3">
                        <label className="tpbold tpfont12">
                          {caseAndCustomerInfoState.classificator4Label}
                        </label>
                        <br />
                        <label className="tpfont12">
                          {caseAndCustomerInfoState.classificator4Value}
                        </label>
                      </div>
                    )}
                    {caseAndCustomerInfoState.classificator5Label && (
                      <div className="col-3">
                        <label className="tpbold tpfont12">
                          {caseAndCustomerInfoState.classificator5Label}
                        </label>
                        <br />
                        <label className="tpfont12">
                          {caseAndCustomerInfoState.classificator5Value}
                        </label>
                      </div>
                    )}
                    <div className="col-3">
                      <label className="tpbold tpfont12">{caseDateLabel}</label>
                      <br />
                      <label className="tpfont12">
                        {caseAndCustomerInfoState.insertDateFormatted}
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-3">
                      <label className="tpbold tpfont12">
                        {customerPromiseSolvingdateLabel}
                      </label>
                      <br />
                      <label className="tpfont12">
                        {caseAndCustomerInfoState.promiseDateFormatted}
                      </label>
                    </div>
                    <div className="col-3">
                      <label className="tpbold tpfont12">
                        {closingdateLabel}
                      </label>
                      <br />
                      <label className="tpfont12">
                        {caseAndCustomerInfoState.closeDateFormatted}
                      </label>
                    </div>
                    <div className="col-4">
                      <label className="tpbold tpfont12">
                        {caseResponsibleLabel}
                      </label>
                      <br />
                      <label className="tpfont12">
                        {caseAndCustomerInfoState.caseResponsibleName}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </ReclassifyCase>
        </TPModal>
      )}

      {modalReassignProps.isShown && (
        <TPModal modalState={modalReassignProps}>
          <ReassignCase
            ref={reassignCaseRef}
            caseId={caseAndCustomerInfoState.caseNumber}
            caseInfo={caseAndCustomerInfoState}
            currentBaseLevelId={caseAndCustomerInfoState.baseLevelId}
            currentClientId={caseAndCustomerInfoState.clientId}
            currentSubsidiaryId={caseAndCustomerInfoState.subsidiaryId}
            currentSubsidiaryOrganizationId={
              caseAndCustomerInfoState.subSidiaryOrgId
            }
            handleCallBackModalReassign={handleCallBackModalReassign}
          >
            <></>
          </ReassignCase>
        </TPModal>
      )}

      {isSownModalAttachment && (
        <TPModal
          modalState={{
            titleModal: newAttachmentTitleLabel,
            acceptLabel: deleteOkLabel,
            cancelLabel: deleteCanceLabel,
            callBackAnswer: handleCallBackNewAttachmentModal,
            callBackData: null,
            isShown: isSownModalAttachment,
            modalWidth: ModalSizeEnum.MODALMD,
          }}
        >
          {/* Attechment */}
          <TPLoadingOverlay isModal active={isLoadingScreenModalAttachment}>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <TPTextBox
                    id="IdTextBox"
                    onChange={(e: any) =>
                      handleOnAttachmentValueChange(e.target.value, "sender")
                    }
                    value={caseAttachmentState.sender}
                    isMandatory={true}
                    labelText={senderLabel}
                    errorMessage={caseAttachmentState.senderErrorMessage}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <TPTextArea
                    id="IdTextArea"
                    onChange={(e: any) =>
                      handleOnAttachmentValueChange(
                        e.target.value,
                        "description",
                      )
                    }
                    value={caseAttachmentState.description}
                    isMandatory={false}
                    labelText={descriptionLabel}
                    errorMessage={caseAttachmentState.descriptionErrorMessage}
                    rows={4}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FileUploader
                  label={dropFilesHereLabel}
                  multiple={true}
                  handleChange={handleUploadFilesDraggedChange}
                  UploadButtonText={uploadFileButtonLabel}
                />
                {caseAttachmentState.notFilesErrorMessage && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: "0.25rem",
                        fontSize: "0.875em",
                        color: "#DC3545",
                      }}
                    >
                      {caseAttachmentState.notFilesErrorMessage}
                    </div>
                  </div>
                )}
                {caseAttachmentState.filesDraggedList &&
                  caseAttachmentState.filesDraggedList.length >= 1 && (
                    <div className="mt-2">
                      <h5 className="tpbold tp-primary-color">
                        {tabAttachmentsLabel}
                      </h5>
                      <hr />
                      <ul className="new_case__attachments-container">
                        {caseAttachmentState.filesDraggedList.length >= 1 &&
                          caseAttachmentState.filesDraggedList.map(function (
                            item: any,
                            index,
                          ) {
                            return (
                              <li
                                className="d-flex mt-1"
                                key={"lifile" + index.toString()}
                              >
                                <label>{item.name}</label>
                                <label className="new_case__attachments-btn-delete">
                                  <TPIcon
                                    className="tphover"
                                    key={"deletefile" + index.toString()}
                                    style={{
                                      fontSize: "25px",
                                      cursor: "pointer",
                                    }}
                                    iconType={TPIconTypes.delete}
                                    onClick={() =>
                                      handleOnDeleteAttachment(item.name)
                                    }
                                  />
                                </label>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  )}
              </div>
            </div>
          </TPLoadingOverlay>
        </TPModal>
      )}

      <CaseStatusModal
        status={caseStatusModal}
        componentLabels={{
          title: modalTaskStatusConventions.title,
          ok: modalTaskStatusConventions.yesLabel,
        }}
        handleModalClose={() => setCaseStatusModal(false)}
      />

      <TPModalPDFViewer modalState={modalPDF} />

      <TPModalQuestion
        title={deleteTitle}
        yesLabel={deleteOkLabel}
        noLabel={deleteCanceLabel}
        question={deleteAttachmentConfirmLabel}
        callBackData={modalQuestionDeleteAttachmentState.callBackData}
        isShown={modalQuestionDeleteAttachmentState.isShown}
        callBackAnswer={handleCallBackModalDeleteAttachment}
      ></TPModalQuestion>

      <TPModalQuestion
        title={deleteTitle}
        yesLabel={deleteOkLabel}
        noLabel={deleteCanceLabel}
        question={deleteQuestion}
        callBackData={modalQuestionState.callBackData}
        isShown={modalQuestionState.isShown}
        callBackAnswer={handleCallBackModal}
      ></TPModalQuestion>

      <div
        className={diagramModal.classModalDiagram}
        tabIndex={-1}
        data-bs-backdrop={diagramModal.backdropClass}
        style={diagramModal.styleModalDiagram}
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{diagramModal.title}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setDiagramModal(initialDiagramModal);
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                  {workflowState.currentWorkflowImage && (
                    <div className="tpw100per">
                      <img
                        src={
                          "data:image/png;base64," +
                          workflowState.currentWorkflowImage
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              TPClient Copyright &copy; {TPGlobal.currentYear}
            </div>
          </div>
        </div>
      </div>

      {/* Case Viewer principal interface  */}
      <TPLoadingOverlay
        isModal
        active={isLoadingScreen || toolbarOptionsLoading}
      >
        <div className="row">
          <div style={{ margin: "0", padding: "0" }}>
            <TPIcon
              iconType={
                toggleCaseZone
                  ? TPIconTypes.chevronLeft
                  : TPIconTypes.chevronRight
              }
              style={{ cursor: "pointer", fontSize: "25px" }}
              onClick={() => setToogleCaseZone(!toggleCaseZone)}
            ></TPIcon>
          </div>

          <div
            style={{ width: "30%" }}
            className={!toggleCaseZone ? "d-none" : ""}
          >
            <div className={!toggleCaseZone ? "d-none" : ""}>
              <div
                style={{ display: "flex", alignItems: "flex-end", gap: "16px" }}
              >
                <TPPageTitle style={{ margin: "0", fontSize: "18px" }}>
                  {titleLabel}
                  {":"}
                  &nbsp;
                  {caseAndCustomerInfoState.caseNumber}
                  {" - "}
                  {caseAndCustomerInfoState.caseStatusLocalizedDescription}
                  {" - "}
                  {renderCaseStatusIcon()}
                </TPPageTitle>
                {caseReminders.length > 0 && (
                  <TPTooltip
                    text={reminderCountLabel.replace(
                      "x",
                      String(caseReminders.length),
                    )}
                  >
                    <TPIcon
                      iconType={TPIconTypes.clock}
                      style={{ color: "#780096", fontSize: "20px" }}
                    />
                  </TPTooltip>
                )}
              </div>
              <label style={{ fontSize: "14px", fontWeight: "400" }}>
                {workflowLabel}:&nbsp;&nbsp;
              </label>
              <label
                style={{
                  fontSize: "14px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleChangeTab(currentTabsEnum.workflow);
                }}
              >
                {caseAndCustomerInfoState.workflowTypeLocalizedDescription}
              </label>
              {caseAndCustomerInfoState.contactName && (
                <>
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    {contactNameLabel}:&nbsp;&nbsp;
                  </label>
                  <label
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    {caseAndCustomerInfoState.contactName}
                  </label>
                </>
              )}
            </div>
          </div>

          {true && (
            <>
              <StyledTimelineHeader isExpanded={!toggleCaseZone}>
                <p className="title">{timelineTitleLabel}</p>

                <div className="timeline-actions">
                  {seconds[caseAndCustomerInfoState.caseNumber] && (
                    <div
                      className="actions-timer"
                      style={{
                        display:
                          TPGlobal.DisplayCaseAverageHandleTime === "1"
                            ? "flex"
                            : "none",
                      }}
                    >
                      <TPIcon iconType={TPIconTypes.clockOut} />
                      <VerticalTabCaseTimeListener
                        caseNumber={`${caseAndCustomerInfoState.caseNumber}`}
                        isCIM
                      />
                    </div>
                  )}
                  <div className="actions-icons">
                    {actionIcons
                      .filter((icon) => icon.status)
                      .map((icon) => (
                        <TPIcon
                          key={`icon-${camelCaseToId(icon.type)}`}
                          id={`icon-${camelCaseToId(icon.type)}-btn`}
                          iconType={icon.type}
                          tooltip={icon.tooltip}
                          onClick={icon.onIconClick}
                          isTooltip
                        />
                      ))}
                  </div>
                  <div className="actions-panel" onClick={handleOpenPanel}>
                    <TPIcon iconType={TPIconTypes.touch} />
                    <p>{actionPanelTitleLabel}</p>
                  </div>
                  <Popover
                    id="action-panel"
                    open={panelOpen}
                    anchorEl={anchorEl}
                    onClose={handleClosePanel}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {toolbarOptions.map((option, i) => (
                      <MenuItemButton
                        style={{
                          minWidth: "9em",
                          justifyContent: "flex-start",
                        }}
                        id={`${option.id}-menu-item`}
                        key={`${option.id}-menu-item`}
                        onClick={(e) => {
                          handleClosePanel();
                          option.onOptionClick(e);
                        }}
                      >
                        {Boolean(option.icon) &&
                          Boolean(
                            availableIcons[option.icon as keyof IconType],
                          ) &&
                          (
                            availableIcons[
                              option.icon as keyof IconType
                            ] as IconType
                          )({ fontSize: "24px", color: "#2E2E2E" })}
                        {option.label}
                      </MenuItemButton>
                    ))}
                  </Popover>
                </div>
              </StyledTimelineHeader>
            </>
          )}

          {false && (
            <>
              <div className="col-4 d-flex" style={{ flexDirection: "column" }}>
                <div className="d-flex gap-3">
                  <div>
                    <TPPageTitle
                      style={{
                        marginTop: "0",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      {timelineTitleLabel}
                    </TPPageTitle>
                  </div>
                  <div className="d-flex gap-2">
                    <TPIcon
                      iconType={TPIconTypes.refresh}
                      onClick={() => handleRefreshTimelineClick()}
                      style={{
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    ></TPIcon>
                    <TPIcon
                      iconType={TPIconTypes.swapVert}
                      onClick={() => handleToggleOrderClick()}
                      style={{
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    ></TPIcon>
                    <TPIcon
                      iconType={TPIconTypes.help}
                      onClick={() => handleConventionsHelpClick()}
                      style={{
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    ></TPIcon>
                  </div>
                </div>
                {seconds[caseAndCustomerInfoState.caseNumber] && (
                  <p>
                    {caseTabLabel}
                    {": "}
                    {caseAndCustomerInfoState.caseNumber}
                    &nbsp;
                    {handleTimeLabel}
                    {": "}
                    <VerticalTabCaseTimeListener
                      caseNumber={`${caseAndCustomerInfoState.caseNumber}`}
                    />
                  </p>
                )}
              </div>

              <div className="col d-flex justify-content-between">
                <div>
                  <TPPageTitle style={{ marginTop: "0", fontSize: "18px" }}>
                    {visitedTaskTitleLabel}
                  </TPPageTitle>
                </div>
                <div
                  onClick={() => setToogleActionPanel(!toogleActionPanel)}
                  style={{ marginTop: "-6px" }}
                >
                  <TPLabel
                    labelText={actionPanelTitleLabel}
                    style={{
                      color: "#a00095",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  ></TPLabel>

                  <TPIcon
                    iconType={
                      toogleActionPanel
                        ? TPIconTypes.chevronRight
                        : TPIconTypes.chevronLeft
                    }
                    inline={true}
                    style={{
                      color: "#a00095",
                      fontWeight: "bold",
                      fontSize: "25px",
                      cursor: "pointer",
                    }}
                  ></TPIcon>
                </div>
                <div
                  className="col"
                  style={{
                    position: "fixed",
                    border: "1px solid #dee2e6",
                    width: "220px",
                    top: "170px",
                    right: "30px",
                    display: `${toogleActionPanel ? "block" : "none"}`,
                    padding: "0 !important",
                    backgroundColor: "white",
                    zIndex: "10",
                  }}
                >
                  <div>
                    <ul className="tpAction-panel-sticky">
                      <li
                        onClick={(e: any) =>
                          handleAddCommentsOnClick(e, caseNumberToView)
                        }
                      >
                        {addCommentActionPanelLabel}
                      </li>
                      <li
                        onClick={(e: any) =>
                          handleAddTaskOnClick(e, caseNumberToView)
                        }
                      >
                        {addTaskActionPanelLabel}
                      </li>
                      {TPGlobal.globalPermissions.canPrintCase && (
                        <li
                          onClick={(e: any) =>
                            handleExportToPDF(e, caseNumberToView)
                          }
                        >
                          {casePrintOutLabel}
                        </li>
                      )}
                      <li
                        onClick={(e: any) =>
                          handleReclassifyCase(e, caseNumberToView)
                        }
                      >
                        {reclassifyCaseLabel}
                      </li>
                      {caseAndCustomerInfoState.caseStatusId !=
                        CaseStatus.SO && (
                        <li
                          onClick={(e: any) =>
                            handleReassignCase(e, caseNumberToView)
                          }
                        >
                          {reassignCaseLabel}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="row">
          <div
            style={{ width: "30%" }}
            className={toggleCaseZone ? "" : "d-none"}
          >
            <div>
              <div className="row">
                <div className="col-12 nav" style={{ paddingLeft: "12px" }}>
                  <div
                    className="secondary-style-tp-tabs nav nav-tabs ml-3"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      className={`nav-link ${caseAndCustomerInfoState.currentTab === currentTabsEnum.case ? "active" : ""}`}
                      id="nav-case-tab"
                      type="button"
                      role="tab"
                      aria-controls="nav-case"
                      aria-selected="true"
                      onClick={() => handleChangeTab(currentTabsEnum.case)}
                    >
                      {caseTabLabel}
                    </button>
                    {!caseAndCustomerInfoState.isAnonymousCustomer && (
                      <button
                        className={`nav-link ${caseAndCustomerInfoState.currentTab === currentTabsEnum.customer ? "active" : ""}`}
                        id="nav-customer-tab"
                        type="button"
                        role="tab"
                        aria-controls="nav-customer"
                        aria-selected="true"
                        onClick={() =>
                          handleChangeTab(currentTabsEnum.customer)
                        }
                      >
                        {customerTabLabel}
                      </button>
                    )}
                    <button
                      className={`nav-link ${caseAndCustomerInfoState.currentTab === currentTabsEnum.workflow ? "active" : ""}`}
                      id="nav-case-tab"
                      type="button"
                      role="tab"
                      aria-controls="nav-workflow"
                      aria-selected="true"
                      onClick={() => {
                        handleChangeTab(currentTabsEnum.workflow);
                      }}
                    >
                      {workflowLabel}
                    </button>
                  </div>
                </div>
              </div>
              <div className="tab-content ml-3">
                {renderCaseTabContent()}
                {renderCustomerTabContent()}
                {renderWorkflowTabContent()}
              </div>
            </div>
          </div>
          <div className="col">
            {formattedTime && (
              <div>
                {caseLabel}: {caseAndCustomerInfoState.caseNumber} /{" "}
                {handleTimeLabel} {formattedTime}
              </div>
            )}
            {renderTimelines()}
          </div>
        </div>
      </TPLoadingOverlay>
    </>
  );
};

export default CaseViewer;
