import styled from "styled-components";

export const TagSelectorContainerStyled = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: visible;
`

export const TagSelectorFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`

export const TagSelectorTitleStyled = styled.b`
  color: #2E2E2E;
  font-size: 20px;
  line-height: 32px;
  font-family: "Noto Sans";
`