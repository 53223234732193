import styled from "styled-components";

export const ContainerCalendarStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const CalendarStyle = styled.div``;

export const CalendarHeaderStyle = styled.div`
  .fc-more-link {
    color: white;
    background: #a00095;
    padding: 4px;
    text-decoration: none;
    font-weight: 700;
  }

  .fc-more-link:hover {
    background: #a00095;
  }

  .fc-scrollgrid-section td {
    padding: 0px;
  }

  .fc-daygrid-day {
    height: 64px;
  }

  .fc-toolbar-title {
    color: #a00095;
  }

  .fc-event {
    background: none;
    border: none;
  }

  .fc-button {
    background-color: #a00095;
    color: white;
    border-radius: 4px;
    border: none;
    transition:
      background-color 0.3s,
      box-shadow 0.3s;
  }

  .fc-button:hover {
    background-color: #890280;
  }

  .fc-button-primary {
    background-color: #a00095;
  }

  .fc-button-active {
    background-color: #a00095;
  }

  .fc-button-today {
    background-color: #a00095;
    color: white;
  }

  .fc-button-today.fc-button-active {
    background-color: #a00095;
  }

  .fc .fc-button-primary:disabled {
    background-color: #a00095;
  }

  .kSQPXw .fc-toolbar-title {
    color: #a00095;
  }

  .fc .fc-button-primary:not(:disabled).fc-button-active,
  .fc .fc-button-primary:not(:disabled):active {
    background-color: #a00095;
  }

  .fc-popover-close {
    color: transparent;
    background: none;
    border: none;
    position: relative;
  }

  .fc-popover-close::before {
    content: "x";
    color: #a00095;
    font-size: inherit;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .tooltip-container {
    position: relative;
    display: inline-block;
  }

  .my-tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-45%);
    z-index: 9999;
    background: white;
    height: auto;
    color: black;
    width: auto;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px;
    padding: 3px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .fc-event-main:hover .my-tooltip {
    visibility: visible;
    opacity: 1;
  }

  .my-tooltip::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid white;
  }

  a {
    color: black;
  }

  color: #a00095;
  width: 100%;
  overflow: auto;
`;

export const NumberCountRemindersStyle = styled.div``;

export const CountStyle = styled.div`
  background-color: rgb(160, 0, 149);
  padding: 5px;
  border-radius: 5px;
  height: 29px;
  width: 29px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: 8%;
  color: white;
`;
