import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  SequenceGeneratorSequencesNameEnum,
  TaskTypeComponentEnum,
} from "@/models/Global/TPGlobalEnums";
import { ComponentCanAddAdditionalDataConst } from "@/models/Global/TPGlobalModels";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import {
    MultilanguageFieldValueViewModel
} from "@/models/multilanguage/MultilanguageFieldValueModel";
import {
  ExportCaseExtendedTaskModel,
  ExportCaseExtendedTaskValidator,
  ExternalExtendedTaskModel,
  ExternalExtendedTaskValidator,
  SendEmailExtendedTaskModel,
  SendEmailExtendedTaskValidator,
  TaskTypeDecisionTypeAutomatic,
  TaskTypeDecisionTypeAutomaticTemp,
  TaskTypeDecisionTypeAutomaticValidator,
  TaskTypeInputDTO,
  TaskTypeInputDTOValidator,
} from "@/models/TaskType/TaskTypeInputDTO";
import { TaskTypeViewModel } from "@/models/TaskType/TaskTypeModels";
import { ComponentService } from "@/services/ComponentService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { SequenceService } from "@/services/SequenceService";
import { TaskTypeService } from "@/services/TaskTypeService";
import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import { OutboundMailAccountService } from "../../services/OutboundMailAccountService";
import TPAutoComplete from "../../components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { UserService } from "../../services/UserService";
import { EmailTemplateService } from "../../services/EmailTemplateService";
import { EmailTemplateViewModel } from "../../models/EmailTemplates/EmailTemplateModels";
import MultilingualTextBox
from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";
import {
    MultilingualTextBoxEvents
} from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";
import {
  TPStyledForm,
  TPStyledFormButtonFooter,
  TPStyledFormRow,
  TPStyledGrid,
  TPStyledInnerInputForm,
  TPStyledSubtitle,
} from "@/components/styled/StyledForms";
import { TPOptionButtonPairStyled } from "@/components/bootstrap/forms/submit/TPOptionButtonPair";

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};

const RESOURCE_SET: string = "TaskTypeInsertUpdateComponent";
const resourceSetEmailTaskComponent: string = "EmailTaskComponent";
const componentFileName: string = "TaskTypeInsertUpdate.tsx";
const resourceSetTaskViewer = "TaskViewerComponent";
const resourceInputDTOInvalidHelpNotes: string =
    "TaskTypeInsertUpdateComponent|InputDTOInvalidHelpNotes";
const initialModalTemplateScreenState: modalTemplateScreenType = {
    newTemplate: [],
    reportTemplate: "",
    newTemplateErrorMessage: "",
    reportTemplateErrorMessage: "",
};

const ruleListValueMap: { [x: string]: string } = {
  TACASECLAS: "CASE",
  TACUSTDATA: "CUSTOMER",
};

type InsertUpdateStateType = {
  id: string;
  componentId: string;
  isDecision: boolean;
  isDecisionAutoTask: boolean;
  escalates: boolean;
  addable: boolean;
  isCommentRequiered: boolean;
  isPrivate: boolean;
  isVisibleTaskReport: boolean;
  isActive: boolean;
  externalTaskRSAPublicKey: string;
  recordLanguageList: Array<TPKeyValue>;
  recordLanguageHelpNotesList: Array<TPKeyValue>;
  idErrorMessage: string;
  externalTaskRSAPublicKeyErrorMessage: string;
  descriptionErrorMessages: Array<string>;
  componentIdErrorMessage: string;
  helpNotesErrorMessages: Array<string>;
  componentCanAddAdditionalData: boolean;
  automaticAnswer: boolean;
  disabledTaskReport: boolean;
  isExternalTask: boolean;
  isExportCaseTask: boolean;
  isExportCaseAutoTask: boolean;
  isEmailTask: boolean;
  OutboundAccount: string;
  EmailTemplate: string;
};

type modalTemplateScreenType = {
  newTemplate: Array<TPKeyValue>;
  reportTemplate: string;
  newTemplateErrorMessage: string;
  reportTemplateErrorMessage: string;
  [x: string]: any;
};

type SendEmailExtendedTaskType = {
  outboundMailAccountId: string;
  emailTemplateId: string;
  emailTemplateIdErrorMessage: string;
};

type ExportCaseExtendedTaskType = {
  exportDestinationId: string;
  exportComments: string;
  exportDestinationIdErrorMessage: string;
  exportCommentsErrorMessage: string;
};

type ExternalExtendedTaskType = {
  externalPublicKey: string;
  externalTaskURL: string;
  externalTaskURLErrorMessage: string;
};

const TaskTypeInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
}): ReactElement => {

  const [modalTemplateScreenState, setModalTemplateScreenState] =
    useState<modalTemplateScreenType>(initialModalTemplateScreenState);
  const [autocompleteTemplateTopNOptions, setAutocompleteTemplateTopNOptions] =
    useState<Array<TPKeyValue>>([]);
  const [autocompleteTemplateOptions, setAutocompleteTemplateOptions] =
    useState<Array<TPKeyValue>>([]);
  const [templateLanguageValue] = useState("--");
  const [automaticTaskAnswerError] = useState("");
  const [emailAccountError, setEmailAccountError] = useState("");
  const [emailTemplateError, setEmailTemplateError] = useState("");
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  const [isLoadById, setIsLoadById] = useState(false);
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [helpNotesSectionLabel, setHelpNotesSectionLabel] = useState("");
  const [idLabel, setIdLabel] = useState("");
  const [componentLabel, setComponentLabel] = useState("");
  const [exportDestinationLabel, setExportDestinationLabel] = useState("");
  const [outboundMailAccountLabel, setOutboundMailAccountLabel] = useState("");
  const [isDecisionLabel, setIsDecisionLabel] = useState("");
  const [escalatesLabel, setEscalatesLabel] = useState("");
  const [addableLabel, setAddableLabel] = useState("");
  const [isCommentRequieredLabel, setIsCommentRequieredLabel] = useState("");
  const [isPrivateLabel, setIsPrivateLabel] = useState("");
  const [isVisibleTaskReportLabel, setIsVisibleTaskReportLabel] = useState("");
  const [isActiveLabel, setIsActiveLabel] = useState("");
  const [externalTaskURLLabel, setExternalTaskURLLabel] = useState("");
  const [externalTaskRSAPublicKeyLabel, setExternalTaskRSAPublicKeyLabel] = useState("");
  const [comentsToExportLabel, setComentsToExportLabel] = useState("");
  const [commentsToAutomaticAnswerLabel, setCommentsToAutomaticAnswerLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [ruleDefinitionLabel, setRuleDefinitionLabel] = useState("");
  const [realMode, setRealMode] = useState(mode);
  const [realRecordId, setRealRecordId] = useState(recordId);
  const [canAddAdditionalData, setCanAddAdditionalData] = useState(false);
  const [disabledIsPrivate, setDisabledIsPrivate] = useState(false);
  const [referenceId, setReferenceId] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");
  const [require, setRequire] = useState("");
  const [activeInsert, setActiveInsert] = useState(false);
  const [templateLabel, setTemplateLabel] = useState("");
  const [ruleTypeLabel, setRuleTypeLabel] = useState("");
  const [baseFieldLabel, setBaseFieldLabel] = useState("");
  const [comparisonOperatorLabel, setComparisonOperatorLabel] = useState("");
  const [refValueLabel, setRefValueLabel] = useState("");
  const [logicalOperatorLabel, setLogicalOperatorLabel] = useState("");

  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }

  let insertUpdateInitialState: InsertUpdateStateType = {
    id: recordId,
    componentId: "",
    isDecision: false,
    isDecisionAutoTask: false,
    escalates: false,
    addable: false,
    isCommentRequiered: false,
    isPrivate: false,
    isVisibleTaskReport: false,
    isActive: true,
    recordLanguageList: [],
    recordLanguageHelpNotesList: [],
    externalTaskRSAPublicKey: "",
    idErrorMessage: "",
    externalTaskRSAPublicKeyErrorMessage: "",
    descriptionErrorMessages: [...initialErrorMessages],
    componentIdErrorMessage: "",
    helpNotesErrorMessages: [...initialErrorMessages],
    automaticAnswer: false,
    disabledTaskReport: true,
    isExternalTask: false,
    isExportCaseTask: false,
    isExportCaseAutoTask: false,
    isEmailTask: false,
    componentCanAddAdditionalData: false,
    OutboundAccount: "",
    EmailTemplate: "",
  };

  let externalExtendedTaskInitialState: ExternalExtendedTaskType = {
    externalTaskURL: "",
    externalPublicKey: "",
    externalTaskURLErrorMessage: "",
  };
  let exportCaseExtendedTaskTypeInitialState: ExportCaseExtendedTaskType = {
    exportDestinationId: "",
    exportComments: "",
    exportDestinationIdErrorMessage: "",
    exportCommentsErrorMessage: "",
  };
  let sendEmailExtendedTaskTypeInitialState: SendEmailExtendedTaskType = {
    outboundMailAccountId: "",
    emailTemplateId: "",
    emailTemplateIdErrorMessage: "",
  };
  const [insertUpdateState, setInsertUpdateState] = useState(
    insertUpdateInitialState
  );
  const [externalExtendedTaskState, setExternalExtendedTaskState] = useState(
    externalExtendedTaskInitialState
  );
  const [exportCaseExtendedTaskTypeState, setExportCaseExtendedTaskTypeState] =
    useState(exportCaseExtendedTaskTypeInitialState);

  const [automaticTaskAnswer, setAutomaticTaskAnswer] = useState<TPKeyValue>({
    key: "",
    value: "",
  });

  const [sendEmailExtendedTaskTypeState, setSendEmailExtendedTaskTypeState] =
    useState(sendEmailExtendedTaskTypeInitialState);

  let initialStateComponentList: Array<TPKeyValue> = [];
  const [componentKeyValue, setComponentKeyValue] = useState(
    initialStateComponentList
  );

  const [ruleListValue, setRuleListValue] = useState<string>("");
  const [baseListValue, setBaseListValue] = useState<string>("");
  const [comparisionListValue, setComparisionListValue] = useState<string>("");
  const [referenceValue, setReferenceValue] = useState<string>("");
  const [logicalOperatorValue, setLogicalOperatorValue] = useState<string>("");
  const [logicalOperatorValueSecond] = useState<string>("");
  const [ruleListValueSecond, setRuleListValueSecond] = useState<string>("");
  const [baseListValueSecond, setBaseListValueSecond] = useState<string>("");
  const [comparisionListValueSecond, setComparisionListValueSecond] =
    useState<string>("");
  const [referenceValueSecond, setReferenceValueSecond] = useState<string>("");
  const [ruleListError, setRuleListError] = useState("");
  const [baseListError, setBaseListError] = useState("");
  const [comparisionListError, setComparisionListError] = useState("");
  const [referenceValueError, setReferenceValueError] = useState("");
  const [logicalOperatorError, setLogicalOperatorError] = useState("");
  const [ruleListSecondError, setRuleListSecondError] = useState("");
  const [baseListSecondError, setBaseListSecondError] = useState("");
  const [comparisionListSecondError, setComparisionListSecondError] =
    useState("");
  const [referenceValueSecondError, setReferenceValueSecondError] =
    useState("");

  const nameInputRef = useRef<MultilingualTextBoxEvents>();
  const helpNotesInputRef = useRef<MultilingualTextBoxEvents>();
  const rulesDefinitionList: {
    RuleNumber: number;
    RuleType: string;
    LogicalOperatorValue: string;
    BaseField: string;
    ComparisionOperator: string;
    ReferenceValue: string;
    Connector: string;
  }[] = [];
  const ruleDefinitionFirstObj = {
    RuleNumber: 0,
    RuleType: ruleListValue,
    LogicalOperatorValue: logicalOperatorValue,
    BaseField: `${ruleListValueMap[ruleListValue]}.${baseListValue}`,
    ComparisionOperator: comparisionListValue,
    ReferenceValue: referenceValue,
    Connector: "",
  };
  const ruleDefinitionSecondObj = {
    RuleNumber: 1,
    RuleType: ruleListValueSecond,
    LogicalOperatorValue: logicalOperatorValueSecond,
    BaseField: baseListValueSecond,
    ComparisionOperator: comparisionListValueSecond,
    ReferenceValue: referenceValueSecond,
    Connector: "",
  };

  let initialStateFakeList: Array<TPKeyValue> = [];
  const [
    fakeDataExportDestinationListState,
    setFakeDataExportDestinationListState,
  ] = useState(initialStateFakeList);
  const [fakeDataOutboundMailAccountList, setFakeDataOutboundMailAccountList] =
    useState(initialStateFakeList);

  const [fakeDataRuleListFirstState, setFakeDataRuleListFirstState] =
    useState(initialStateFakeList);
  const [fakeDataBaseListFirstState, setFakeDataBaseListFirstState] =
    useState(initialStateFakeList);
  const [fakeDataBaseListFirstState2, setFakeDataBaseListFirstState2] =
    useState(initialStateFakeList);
  const [
    fakeDataComparisionListFirstState,
    setFakeDataComparisionListFirstState,
  ] = useState(initialStateFakeList);
  const [fakeDataLogicalOperator, setFakeDataLogicalOperator] =
    useState(initialStateFakeList);

  const multilanguageTableName: string = "TAskTYpe";
  const multilanguageFieldName: string = "Description_TATY";
  const multilanguageFieldHelp: string = "HelpNotes_TATY";

  const loadResourcesAndLoadInfo = async () => {
    let i: number;
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel"
      )
    );
    setParametersSectionLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ParametersSectionLabel")
    );
    setHelpNotesSectionLabel(
      await TPI18N.GetText(RESOURCE_SET, "HelpNotesSectionLabel")
    );
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton")
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
    );
    setIdLabel(await TPI18N.GetText(RESOURCE_SET, "IdLabel"));
    setIsActiveLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ShowAsActive"));
    setComponentLabel(await TPI18N.GetText(RESOURCE_SET, "ComponentLabel"));
    setIsDecisionLabel(await TPI18N.GetText(RESOURCE_SET, "IsDecisionLabel"));
    setEscalatesLabel(await TPI18N.GetText(RESOURCE_SET, "EscalatesLabel"));
    setAddableLabel(await TPI18N.GetText(RESOURCE_SET, "AddableLabel"));
    setRuleTypeLabel(await TPI18N.GetText(RESOURCE_SET, "RuleType"));
    setBaseFieldLabel(await TPI18N.GetText(RESOURCE_SET, "BaseField"));
    setComparisonOperatorLabel(
      await TPI18N.GetText(RESOURCE_SET, "ComparisonOperator")
    );
    setRefValueLabel(await TPI18N.GetText(RESOURCE_SET, "RefValue"));
    setLogicalOperatorLabel(
      await TPI18N.GetText(RESOURCE_SET, "LogicalOperator")
    );
    setRuleDefinitionLabel(
      await TPI18N.GetText(RESOURCE_SET, "RuleDefinitionLabel")
    );
    setIsCommentRequieredLabel(
      await TPI18N.GetText(RESOURCE_SET, "IsCommentRequieredLabel")
    );
    setIsPrivateLabel(await TPI18N.GetText(RESOURCE_SET, "IsPrivateLabel"));
    setIsVisibleTaskReportLabel(
      await TPI18N.GetText(RESOURCE_SET, "IsVisibleTaskReportLabel")
    );
    setExternalTaskURLLabel(
      await TPI18N.GetText(RESOURCE_SET, "ExternalTaskURL")
    );
    setExternalTaskRSAPublicKeyLabel(
      await TPI18N.GetText(RESOURCE_SET, "ExternalTaskPublicKeyLabel")
    );
    setExportDestinationLabel(
      await TPI18N.GetText(RESOURCE_SET, "ExportDestinationLabel")
    );
    setComentsToExportLabel(
      await TPI18N.GetText(RESOURCE_SET, "ComentsToExportLabel")
    );
    setOutboundMailAccountLabel(
      await TPI18N.GetText(RESOURCE_SET, "OutboundMailAccountLabel")
    );
    setTemplateLabel(
      await TPI18N.GetText(resourceSetEmailTaskComponent, "TemplateLabel")
    );
    setEmptyLabel(await TPI18N.GetText(resourceSetTaskViewer, "EmptyLabel"));
    setRequire(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired")
    );
    setCommentsToAutomaticAnswerLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ParametersSectionLabel")
    );

    let componentService = new ComponentService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest = await componentService.GetByTypeIsActive(
        "1",
        "FRMTYPTSK",
        false,
        true,
        expectedCodes
      );

      let newComponentListState: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return { key: item.id, value: item.localizedDescription };
        }
      );
      newComponentListState.unshift({ key: "", value: "--" });
      setComponentKeyValue(newComponentListState);
      let newFakeDataRulesList: Array<TPKeyValue> = [
        { value: "--", key: "" },
        { value: "Case Classifier", key: "TACASECLAS" },
        { value: "Customer Data", key: "TACUSTDATA" },
      ];
      setFakeDataRuleListFirstState(newFakeDataRulesList);
      let newFakeBaseFieldCaseClasifierList: Array<TPKeyValue> = [
        { value: "--", key: "" },
        { value: "Contact Method", key: "Classify1_Id_BRAN" },
        { value: "Type", key: "Classify2_Id_BRAN" },
        { value: "Process", key: "Classify3_Id_BRAN" },
        { value: "Location", key: "Classify4_Id_BRAN" },
        { value: "Priority", key: "Classify5_Id_BRAN" },
      ];
      let newFakeBaseFieldCustomerDataList: Array<TPKeyValue> = [
        { value: "--", key: "" },
        { value: "Document", key: "DocumentType_Id_PARA" },
        { value: "Name", key: "Name_CLIE" },
        { value: "Last Name", key: "LastName_CLIE" },
        { value: "City", key: "Geography_SUBS" },
        { value: "Email", key: "PreferredEmail_SUBS" },
        { value: "Address", key: "PreferredAddress_SUBS" },
        { value: "Email", key: "PreferredEmail_SUBS" },
        { value: "Phone", key: "PreferredPhone_SUBS" },
      ];
      if (ruleListValue === "TACASECLAS")
        setFakeDataBaseListFirstState(newFakeBaseFieldCaseClasifierList);
      if (ruleListValue === "TACUSTDATA")
        setFakeDataBaseListFirstState(newFakeBaseFieldCustomerDataList);
      if (ruleListValueSecond === "TACASECLAS")
        setFakeDataBaseListFirstState2(newFakeBaseFieldCaseClasifierList);
      if (ruleListValueSecond === "TACUSTDATA")
        setFakeDataBaseListFirstState2(newFakeBaseFieldCustomerDataList);
      let newFakeComparisonOperatorList: Array<TPKeyValue> = [
        { value: "--", key: "" },
        { value: "=", key: "EQUAL" },
        { value: ">", key: "GREATER" },
        { value: "<", key: "LESS" },
        { value: ">=", key: "GREATEROREQUALTHAN" },
        { value: "<=", key: "LESSOREQUALTHAN" },
        { value: "<>", key: "DIFFERENT" },
        { value: "Between", key: "BETWEEN" },
        { value: "Begins with", key: "BEGINS" },
        { value: "Does not begin with", key: "NOBEGINS" },
        { value: "Ends in", key: "ENDS" },
        { value: "Does not end in", key: "NOENDS" },
        { value: "Contains", key: "CONTAINS" },
        { value: "Does not contain", key: "NOCONTAINS" },
        { value: "In List", key: "INLISTS" },
        { value: "Not In List", key: "NOTINLISTS" },
        {
          value: "Matches regular expression .NET",
          key: "REGURALEXPRESSIONPUNTONET",
        },
      ];
      setFakeDataComparisionListFirstState(newFakeComparisonOperatorList);

      let newFakeDataOperatorList: Array<TPKeyValue> = [
        { value: "--", key: "" },
        { value: "And", key: "AND" },
        { value: "Or", key: "OR" },
      ];
      setFakeDataLogicalOperator(newFakeDataOperatorList);

      let newFakeDataExportDestinationList: Array<TPKeyValue> = [
        { value: "--", key: "" },
        { value: "TPClientColombiaxx", key: "EXPOTPCO" },
      ];
      setFakeDataExportDestinationListState(newFakeDataExportDestinationList);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResourcesAndLoadInfo ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} loadResourcesAndLoadInfo ex`);
      return;
    }

    LoadDataOutboundMailAccountList();

    if (realMode == "Update" && !isLoadById) {
      await getById(realRecordId);
    }
    if (realMode == "Insert" && !activeInsert) {
      setActiveInsert(true);
      let newInsertUpdateState = { ...insertUpdateState };
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageHelpNotesList.push(keyValueElement);
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }
  };

  const LoadDataOutboundMailAccountList = async () => {
    let serviceClient = new OutboundMailAccountService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getAllMailAccounts(
        false,
        false,
        expectedCodes
      );

      let tempList = responseRequest.map((data: any) => {
        return {
          key: data.idAccount,
          value: data.fromAddress,
        };
      });

      if (Array.isArray(tempList))
        tempList.push({
          key: "",
          value: "--",
        });
      setFakeDataOutboundMailAccountList(tempList);

      setIsLoadingScreen(false);
      return [...responseRequest];
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} reloadDataGrid ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} reloadDataGrid ex`);
      setIsLoadingScreen(false);
      return [];
    }
  };

  const getEmailById = async (pRecordId: string) => {
    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getEmailTemplateByTemplateId(
        pRecordId,
        false,
        true,
        expectedCodes
      );
      if (!responseRequest) return;
      let recordInfo: EmailTemplateViewModel;
      recordInfo = { ...responseRequest };

      let task = { key: recordInfo.id, value: recordInfo.description };
      let data = { ...modalTemplateScreenState };

      let dataEmail = { ...autocompleteTemplateOptions };
      if (Array.isArray(dataEmail)) dataEmail.push(task);
      else dataEmail = [task];

      data.newTemplate = dataEmail;
      setAutocompleteTemplateOptions(dataEmail);
      setModalTemplateScreenState(data);
      setIsLoadingScreen(false);
      return;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getEmailTemplateById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getEmailTemplateById ex`);

      setIsLoadingScreen(false);
      return null;
    }
  };

  const handleTemplateChange = (newSelectedValue: Array<TPKeyValue>) => {
    let newmodalTemplateScreenState = { ...modalTemplateScreenState };
    newmodalTemplateScreenState.newTemplate = newSelectedValue;
    newmodalTemplateScreenState.newTemplateErrorMessage = "";
    setModalTemplateScreenState(newmodalTemplateScreenState);
    handleEmailTemplateIdChange(newSelectedValue[0].key);
  };

  const handleTemplateOnAutocompleteQuery = async (query: string) => {
    let tasktypeService = new UserService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      let responseRequest = await tasktypeService.getEmailTemplatesBySearch(
        query,
        "1",
        templateLanguageValue,
        false,
        true,
        expectedCodes
      );
      let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return {
            key: item.id,
            value: `${item.localizedDescription}`,
          };
        }
      );
      setAutocompleteTemplateOptions(newToKeyValueList);
      return newToKeyValueList;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleTemplatOnAutocompleteQuery ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} handleTemplateOnAutocompleteQuery ex`
      );
      return [];
    }
  };

  const handleTemplateOnAutocompleteKeyDown = (event: any) => {
    //left arrow 37
    //right arror 39
    //enter 13
    //home 36
    //end  35
    if (
      event.keyCode != 37 &&
      event.keyCode != 39 &&
      event.keyCode != 13 &&
      event.keyCode != 35 &&
      event.keyCode != 36
    ) {
      setAutocompleteTemplateOptions([]);
      let newModalTemplateScreenState = { ...modalTemplateScreenState };
      newModalTemplateScreenState.newTemplate = [];
      newModalTemplateScreenState.newTemplateErrorMessage = "";
      setModalTemplateScreenState(newModalTemplateScreenState);
    }
  };

  const handleAutoCompleteTemplatepNClick = async () => {
    let newTemplatepTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteTemplateTopNOptions.length === 0) {
      newTemplatepTopNOptions = await handleTemplateOnAutocompleteQuery("");
      if (newTemplatepTopNOptions.length >= 1) {
        setAutocompleteTemplateTopNOptions([...newTemplatepTopNOptions]);
        setAutocompleteTemplateOptions([...newTemplatepTopNOptions]);
        let newModalTemplateScreenState = { ...modalTemplateScreenState };
        newModalTemplateScreenState.newTemplate = [];
        newModalTemplateScreenState.newTemplateErrorMessage = "";
        setModalTemplateScreenState(newModalTemplateScreenState);
      }
    } else {
      setAutocompleteTemplateOptions([...autocompleteTemplateTopNOptions]);
      let newModalTemplateScreenState = { ...modalTemplateScreenState };
      newModalTemplateScreenState.newTemplate = [];
      newModalTemplateScreenState.newTemplateErrorMessage = "";
      setModalTemplateScreenState(newModalTemplateScreenState);
    }
  };

  const handleOnIdChange = (newId: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.id = newId;
    newInsertUpdateState.idErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleIsActiveChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActive = !newInsertUpdateState.isActive;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleIsisDecisionChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isDecision = !newInsertUpdateState.isDecision;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleEscalatesChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.escalates = !newInsertUpdateState.escalates;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleAddableChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.addable = !newInsertUpdateState.addable;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleIsCommentRequieredChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isCommentRequiered =
      !newInsertUpdateState.isCommentRequiered;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleIsPrivateChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isPrivate = !newInsertUpdateState.isPrivate;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleIsVisibleTaskReportChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isVisibleTaskReport =
      !newInsertUpdateState.isVisibleTaskReport;
    setInsertUpdateState(newInsertUpdateState);
  };

  const Utf8 = (data: any) => {
    return JSON.stringify(data);
  };

  const handleOkButtonClick = async () => {
    rulesDefinitionList.push(ruleDefinitionFirstObj);
    (logicalOperatorValue === "AND" || logicalOperatorValue === "OR") &&
      rulesDefinitionList.push(ruleDefinitionSecondObj);

    if (nameInputRef.current?.isInvalid()) {
      nameInputRef.current?.markAsTouched();
      return;
    }

    const names = nameInputRef.current?.getValue();
    const helpNotes = helpNotesInputRef.current?.getValue();
    let isInValid: boolean = false;
    let i: number;
    let n: number;
    let recordInputDTO: TaskTypeInputDTO = {
      id: realMode === "Insert" ? referenceId : recordId,
      isActive: insertUpdateState.isActive,
      addable: insertUpdateState.addable,
      componentId: insertUpdateState.componentId,
      description:
        names?.find((h) => h.id === TPGlobal.TPClientDefaultLanguage)?.value ??
        "",
      escalates: insertUpdateState.escalates,
      externalTaskRSAPublicKey: insertUpdateState.externalTaskRSAPublicKey,
      helpNotes:
        helpNotes?.find((h) => h.id === TPGlobal.TPClientDefaultLanguage)
          ?.value ?? "",
      helpNotesLocalizedValues:
        helpNotes?.map((v, idx) => ({
          languageId: v.id,
          localizedValue: v.value ?? "",
          order: idx + 1,
        })) || [],
      isCommentRequired: insertUpdateState.isCommentRequiered,
      isDecision: insertUpdateState.isDecision,
      isPrivate: insertUpdateState.isPrivate,
      isVisibleTaskReport: insertUpdateState.isVisibleTaskReport,
      jSONParameters: Utf8(rulesDefinitionList),
      nameLocalizedValues:
        names?.map((v, idx) => ({
          languageId: v.id,
          localizedValue: v.value ?? "",
          order: idx + 1,
        })) || [],
      OutboundAccount: sendEmailExtendedTaskTypeState.outboundMailAccountId,
    };
    setEmailAccountError("");
    setEmailTemplateError("");
    if (sendEmailExtendedTaskTypeState.outboundMailAccountId == "")
      setEmailAccountError(require);
    if (!sendEmailExtendedTaskTypeState.outboundMailAccountId)
      setEmailAccountError(require);
    if (!handleEmailTemplate()) setEmailTemplateError(require);

    switch (insertUpdateState.componentId) {
      case TaskTypeComponentEnum.caseResponseAnswerAutoTaskComponent: {
        recordInputDTO.jSONParameters = Utf8(automaticTaskAnswer);
        break;
      }
      case TaskTypeComponentEnum.caseResponseDecisionAutoTaskComponent: {
        let inputDataList = new Array<TaskTypeDecisionTypeAutomatic>();
        let inputData: TaskTypeDecisionTypeAutomatic = {
          ruleList: ruleListValue,
          RuleNumber: 0,
          ParenthesisOpen: "",
          BaseField: `${ruleListValueMap[ruleListValue]}.${baseListValue}`,
          ComparisonOperator: comparisionListValue,
          ReferenceValue: referenceValue,
          ParenthesisClose: "",
          Connector: logicalOperatorValue,
        };
        inputDataList.push(inputData);
        if (logicalOperatorValue != "") {
          let inputData2: TaskTypeDecisionTypeAutomatic = {
            ruleList: ruleListValueSecond,
            RuleNumber: 1,
            ParenthesisOpen: "",
            BaseField: `${ruleListValueMap[ruleListValueSecond]}.${baseListValueSecond}`,
            ComparisonOperator: comparisionListValueSecond,
            ReferenceValue: referenceValueSecond,
            ParenthesisClose: "",
            Connector: logicalOperatorValueSecond,
          };

          inputDataList.push(inputData2);
        }
        let recordInputDTOExtendDecisionType: TaskTypeDecisionTypeAutomaticTemp =
          {
            ruleList: ruleListValue,
            baseList: baseListValue,
            comparisonOperatorsType: comparisionListValue,
            referenceValue: referenceValue,
            logicalOperator: logicalOperatorValue,
            ruleListSecond: ruleListValueSecond,
            baseListSecond: baseListValueSecond,
            comparisionListSecond: comparisionListValueSecond,
            referenceSecond: referenceValueSecond,
          };

        let decisionTypeValidator = new TaskTypeDecisionTypeAutomaticValidator(
          inputDataList.length
        );
        let validationResult = decisionTypeValidator.validate(
          recordInputDTOExtendDecisionType
        );

        if (!TPGlobal.TPIsEmpty(validationResult)) {
          if (validationResult.ruleList) {
            setRuleListError(
              await TPI18N.GetResource(validationResult.ruleList)
            );
          }
          if (validationResult.baseList) {
            setBaseListError(
              await TPI18N.GetResource(validationResult.baseList)
            );
          }
          if (validationResult.comparisonOperatorsType) {
            setComparisionListError(
              await TPI18N.GetResource(validationResult.comparisonOperatorsType)
            );
          }
          if (validationResult.referenceValue) {
            setReferenceValueError(
              await TPI18N.GetResource(validationResult.referenceValue)
            );
          }
          if (validationResult.logicalOperator) {
            setLogicalOperatorError(
              await TPI18N.GetResource(validationResult.logicalOperator)
            );
          }
          if (validationResult.ruleListSecond) {
            setRuleListSecondError(
              await TPI18N.GetResource(validationResult.ruleListSecond)
            );
          }
          if (validationResult.baseListSecond) {
            setBaseListSecondError(
              await TPI18N.GetResource(validationResult.baseListSecond)
            );
          }
          if (validationResult.comparisionListSecond) {
            setComparisionListSecondError(
              await TPI18N.GetResource(validationResult.comparisionListSecond)
            );
          }
          if (validationResult.referenceSecond) {
            setReferenceValueSecondError(
              await TPI18N.GetResource(validationResult.referenceSecond)
            );
          }
          isInValid = true;
        } else {
          recordInputDTO.jSONParameters = Utf8(inputDataList);
        }
        break;
      }
      case TaskTypeComponentEnum.caseResponseEmailTaskComponent:
      case TaskTypeComponentEnum.caseResponseEmailAutoTaskComponent: {
        let recordInputDTOExtendedTaskModel: SendEmailExtendedTaskModel = {
          emailTemplateId: handleEmailTemplate() ? handleEmailTemplate() : "",
          outboundMailAccountId:
            sendEmailExtendedTaskTypeState.outboundMailAccountId,
        };
        let inputDTOSendEmailTaskValidator = new SendEmailExtendedTaskValidator(
          insertUpdateState.componentId
        );
        let resultValidatorEmailTask = inputDTOSendEmailTaskValidator.validate(
          recordInputDTOExtendedTaskModel
        );

        if (!TPGlobal.TPIsEmpty(resultValidatorEmailTask)) {
          let newSendEmailExtendedTaskTypeState = {
            ...sendEmailExtendedTaskTypeState,
          };
          if (resultValidatorEmailTask.emailTemplateId) {
            newSendEmailExtendedTaskTypeState.emailTemplateIdErrorMessage =
              await TPI18N.GetResource(
                resultValidatorEmailTask.emailTemplateId
              );
          } else {
            newSendEmailExtendedTaskTypeState.emailTemplateIdErrorMessage = "";
          }
          setSendEmailExtendedTaskTypeState(newSendEmailExtendedTaskTypeState);
        } else {
          recordInputDTO.jSONParameters = Utf8(recordInputDTOExtendedTaskModel);
        }
        isInValid =
          !recordInputDTOExtendedTaskModel.emailTemplateId ||
          !recordInputDTOExtendedTaskModel.outboundMailAccountId;
        break;
      }
      case TaskTypeComponentEnum.caseResponseExchangeTaskComponent:
      case TaskTypeComponentEnum.caseResponseExchangeAutoTaskComponent: {
        let recordInputExportCaseExtendedTaskModel: ExportCaseExtendedTaskModel =
          {
            exportComments: exportCaseExtendedTaskTypeState.exportComments,
            exportDestinationId:
              exportCaseExtendedTaskTypeState.exportDestinationId,
          };
        let inputDTOExportCaseExtendedTaskValidator =
          new ExportCaseExtendedTaskValidator(insertUpdateState.componentId);
        let resultValidatorSendEmail =
          inputDTOExportCaseExtendedTaskValidator.validate(
            recordInputExportCaseExtendedTaskModel
          );
        if (!TPGlobal.TPIsEmpty(resultValidatorSendEmail)) {
          let newExportCaseExtendedTaskTypeState = {
            ...exportCaseExtendedTaskTypeState,
          };
          if (resultValidatorSendEmail.exportComments) {
            newExportCaseExtendedTaskTypeState.exportCommentsErrorMessage =
              await TPI18N.GetResource(resultValidatorSendEmail.exportComments);
          } else {
            newExportCaseExtendedTaskTypeState.exportCommentsErrorMessage = "";
          }
          if (resultValidatorSendEmail.exportDestinationId) {
            newExportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage =
              await TPI18N.GetResource(
                resultValidatorSendEmail.exportDestinationId
              );
          } else {
            newExportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage =
              "";
          }
          setExportCaseExtendedTaskTypeState(
            newExportCaseExtendedTaskTypeState
          );
          isInValid = true;
        } else {
          recordInputDTO.jSONParameters = Utf8(
            recordInputExportCaseExtendedTaskModel
          );
        }
        break;
      }
      case TaskTypeComponentEnum.externalTaskComponent: {
        let recordInputExternalTaskModel: ExternalExtendedTaskModel = {
          externalTaskURL: externalExtendedTaskState.externalTaskURL,
          externalPublicKey: externalExtendedTaskState.externalPublicKey,
        };
        let inputDTOExternalTaskValidator = new ExternalExtendedTaskValidator();
        let resultValidatorExternalTask =
          inputDTOExternalTaskValidator.validate(recordInputExternalTaskModel);
        if (!TPGlobal.TPIsEmpty(resultValidatorExternalTask)) {
          let newexternalExtendedTaskState = {
            ...externalExtendedTaskState,
          };
          if (resultValidatorExternalTask.externalTaskURL) {
            newexternalExtendedTaskState.externalTaskURLErrorMessage =
              await TPI18N.GetResource(
                resultValidatorExternalTask.externalTaskURL
              );
          } else {
            newexternalExtendedTaskState.externalTaskURLErrorMessage = "";
          }

          setExternalExtendedTaskState(newexternalExtendedTaskState);
          isInValid = true;
        } else {
          recordInputDTO.jSONParameters = Utf8(recordInputExternalTaskModel);
        }
        break;
      }
    }

    let inputDTOValidator = new TaskTypeInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      if (resultValidator.id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.id
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }
      if (resultValidator.componentId) {
        newInsertUpdateState.componentIdErrorMessage = await TPI18N.GetResource(
          resultValidator.componentId
        );
      } else {
        newInsertUpdateState.componentIdErrorMessage = "";
      }
      if (resultValidator.externalTaskRSAPublicKey) {
        newInsertUpdateState.externalTaskRSAPublicKeyErrorMessage =
          await TPI18N.GetResource(resultValidator.externalTaskRSAPublicKey);
      } else {
        newInsertUpdateState.externalTaskRSAPublicKeyErrorMessage = "";
      }

      if (resultValidator.helpNotesLocalizedValues) {
        if (
          resultValidator.helpNotesLocalizedValues ==
          resourceInputDTOInvalidHelpNotes
        ) {
          newInsertUpdateState.helpNotesErrorMessages[0] =
            await TPI18N.GetResource(String(resourceInputDTOInvalidHelpNotes));
        } else {
          n = insertUpdateState.recordLanguageHelpNotesList.length;
          for (i = 0; i <= n - 1; i++) {
            if (resultValidator.helpNotesLocalizedValues[i] != null) {
              newInsertUpdateState.helpNotesErrorMessages[i] =
                await TPI18N.GetResource(
                  String(resultValidator.helpNotesLocalizedValues[i])
                );
            }
          }
        }
      } else {
        n = insertUpdateState.recordLanguageHelpNotesList.length;
        for (i = 0; i <= n - 1; i++) {
          newInsertUpdateState.helpNotesErrorMessages[i] = "";
        }
      }

      setInsertUpdateState(newInsertUpdateState);
      isInValid = true;
    }
    if (recordInputDTO.helpNotes == "") {
      recordInputDTO.helpNotesLocalizedValues = [];
    }

    if (!isInValid) {
      if (realMode == "Insert") {
        await insert(recordInputDTO);
      } else {
        await update(recordInputDTO);
      }
    }
  };

  const handleEmailTemplate = () => {
    if (!modalTemplateScreenState) return "";
    if (!modalTemplateScreenState.newTemplate) return "";
    if (modalTemplateScreenState.newTemplate.length <= 0) return "";
    if (!modalTemplateScreenState.newTemplate[0].key) return "";
    if (modalTemplateScreenState.newTemplate[0].key == "") return "";
    return modalTemplateScreenState.newTemplate[0].key;
  };

  const handleExternalTaskChange = (newTask: string) => {
    let newExternalExtendedTaskState = { ...externalExtendedTaskState };
    newExternalExtendedTaskState.externalTaskURL = newTask;
    newExternalExtendedTaskState.externalTaskURLErrorMessage = "";
    setExternalExtendedTaskState(newExternalExtendedTaskState);
  };

  const handleExternalTaskRSAPublicKeyChange = (
    newTaskRSAPublickey: string
  ) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.externalTaskRSAPublicKey = newTaskRSAPublickey;
    setInsertUpdateState(newInsertUpdateState);
  };

  const insert = async (inputDTO: TaskTypeInputDTO) => {
    let serviceClient = new TaskTypeService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.insertTaskType(
        inputDTO,
        true,
        true,
        expectedCodes
      );
      if (responseRequest.responseResult) {
        setCanAddAdditionalData(
          ComponentCanAddAdditionalDataConst.includes(
            insertUpdateState.componentId
          )
        );

        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.componentCanAddAdditionalData =
          canAddAdditionalData;
        setInsertUpdateState(newInsertUpdateState);
        if (
          ComponentCanAddAdditionalDataConst.includes(
            insertUpdateState.componentId
          )
        ) {
          callBackResult({
            result: "save_general_insert_and_change_mode",
            recordId: inputDTO.id,
          });
          setRealRecordId(inputDTO.id);
          setRealMode("Update");
        } else {
          callBackResult({
            result: "save_general_insert",
            recordId: inputDTO.id,
          });
        }
      }

      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} insert ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} insert ex`);
      setIsLoadingScreen(false);
    }
  };

  const update = async (inputDTO: TaskTypeInputDTO) => {
    let serviceClient = new TaskTypeService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.updateTaskType(
        inputDTO,
        true,
        true,
        expectedCodes
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({
          result: "save_general_update",
          recordId: inputDTO.id,
        });
      }
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} update ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} update ex`);
      setIsLoadingScreen(false);
    }
  };

  const getById = async (pRecordId: string) => {
    let serviceClient = new TaskTypeService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let recordLanguagesHelpNotesList: Array<MultilanguageFieldValueViewModel> =
      [];
    let i: number;
    let j: number;
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getTaskTypeById(
        pRecordId,
        false,
        true,
        expectedCodes
      );

      if (
        responseRequest &&
        !["CAREDERETRY", "CAREDETA", "CARESEEMAIL", "CARESTCO"].includes(
          responseRequest.componentId
        )
      ) {
        callBackResult({
          result: "remove_additional_data_item_from_aside_menu",
        });
      }

      if (responseRequest?.id && responseRequest.id != "") setIsLoadById(true);

      let recordInfo: TaskTypeViewModel;
      recordInfo = { ...responseRequest };
      setDisabledIsPrivate(responseRequest.isPrivate);

      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.recordLanguageList = [];
      newInsertUpdateState.recordLanguageHelpNotesList = [];
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
        newInsertUpdateState.recordLanguageHelpNotesList.push({
          ...keyValueElement,
        });
      }
      newInsertUpdateState.addable = recordInfo.isAddable;
      newInsertUpdateState.componentId = recordInfo.componentId;
      newInsertUpdateState.escalates = recordInfo.escalates;
      newInsertUpdateState.externalTaskRSAPublicKey =
        recordInfo.externalTaskRSAPublicKey;
      newInsertUpdateState.id = recordInfo.id;
      newInsertUpdateState.isActive = recordInfo.isActive;
      newInsertUpdateState.isPrivate = recordInfo.isPrivate;
      newInsertUpdateState.isCommentRequiered = recordInfo.isCommentRequired;
      newInsertUpdateState.isDecision = recordInfo.isDecision;
      newInsertUpdateState.isVisibleTaskReport = recordInfo.isVisibleTaskReport;
      newInsertUpdateState.recordLanguageList[0].value = recordInfo.description;
      newInsertUpdateState.recordLanguageHelpNotesList[0].value =
        recordInfo.helpNotes;

      let expectedcodes: Array<number> = [200];
      recordLanguagesList = await getRecordLanguageList(
        pRecordId,
        multilanguageTableName,
        multilanguageFieldName,
        expectedcodes
      );
      if (recordLanguagesList.length == 0) {
        TPLog.Log(
          `Error ${componentFileName} getById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty"
        );
        console.error(
          `Error ${componentFileName} getById getRecordLanguageList is empty`
        );
        setIsLoadingScreen(false);
        return;
      }
      for (
        i = 0;
        i <= newInsertUpdateState.recordLanguageList.length - 1;
        i++
      ) {
        for (j = 0; j <= recordLanguagesList.length - 1; j++) {
          if (
            newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ==
            recordLanguagesList[j].languageId.toLowerCase()
          ) {
            newInsertUpdateState.recordLanguageList[i].value =
              recordLanguagesList[j].recordDescription;
            break;
          }
        }
      }
      let expectedcodes2: Array<number> = [200, 404];
      recordLanguagesHelpNotesList = await getRecordLanguageList(
        pRecordId,
        multilanguageTableName,
        multilanguageFieldHelp,
        expectedcodes2
      );
      if (recordLanguagesHelpNotesList.length == 0) {
        TPLog.Log(
          `Error ${componentFileName} getById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesHelpNotesList is empty"
        );
        console.error(
          `Error ${componentFileName} getById recordLanguagesHelpNotesList is empty`
        );
      }

      for (
        i = 0;
        i <= newInsertUpdateState.recordLanguageHelpNotesList.length - 1;
        i++
      ) {
        for (j = 0; j <= recordLanguagesHelpNotesList.length - 1; j++) {
          if (
            newInsertUpdateState.recordLanguageHelpNotesList[
              i
            ].key.toLowerCase() ==
            recordLanguagesHelpNotesList[j].languageId.toLowerCase()
          ) {
            newInsertUpdateState.recordLanguageHelpNotesList[i].value =
              recordLanguagesHelpNotesList[j].recordDescription;
            break;
          }
        }
      }
      showAdditionalDataMenuItem(recordInfo.componentId);

      switch (recordInfo.componentId) {
        case TaskTypeComponentEnum.caseResponseEmailTaskComponent:
        case TaskTypeComponentEnum.caseResponseEmailAutoTaskComponent: {
          let newSendEmailExtendedTaskTypeState: SendEmailExtendedTaskType =
            JSON.parse(recordInfo.jsonParameters) as SendEmailExtendedTaskType;
          setSendEmailExtendedTaskTypeState({
            ...newSendEmailExtendedTaskTypeState,
          });
          if (newSendEmailExtendedTaskTypeState.emailTemplateId)
            getEmailById(newSendEmailExtendedTaskTypeState.emailTemplateId);

          newInsertUpdateState.isEmailTask = true;
          break;
        }
        case TaskTypeComponentEnum.caseResponseExchangeTaskComponent: {
          let newExportCaseExtendedTaskTypeState: ExportCaseExtendedTaskType =
            JSON.parse(recordInfo.jsonParameters) as ExportCaseExtendedTaskType;
          setExportCaseExtendedTaskTypeState({
            ...newExportCaseExtendedTaskTypeState,
          });
          newInsertUpdateState.isExportCaseTask = true;
          break;
        }
        case TaskTypeComponentEnum.caseResponseExchangeAutoTaskComponent: {
          let newExportCaseExtendedTaskTypeState2: ExportCaseExtendedTaskType =
            JSON.parse(recordInfo.jsonParameters) as ExportCaseExtendedTaskType;
          setExportCaseExtendedTaskTypeState({
            ...newExportCaseExtendedTaskTypeState2,
          });
          newInsertUpdateState.isExportCaseTask = true;
          newInsertUpdateState.isExportCaseAutoTask = true;
          break;
        }
        case TaskTypeComponentEnum.externalTaskComponent: {
          let newExternalExtendedTaskState: ExternalExtendedTaskType =
            JSON.parse(recordInfo.jsonParameters) as ExternalExtendedTaskType;
          setExternalExtendedTaskState({ ...newExternalExtendedTaskState });
          newInsertUpdateState.isExternalTask = true;
          break;
        }
        case TaskTypeComponentEnum.caseResponseAnswerAutoTaskComponent: {
          let data;
          if (recordInfo.jsonParameters) {
            data = JSON.parse(recordInfo.jsonParameters) as TPKeyValue;
            setAutomaticTaskAnswer(data);
          }

          newInsertUpdateState.automaticAnswer = true;
          break;
        }
        case TaskTypeComponentEnum.caseResponseDecisionAutoTaskComponent: {
          if (recordInfo.jsonParameters) {
            let data = JSON.parse(
              recordInfo.jsonParameters
            ) as Array<TaskTypeDecisionTypeAutomatic>;

            const baseFieldData = data[0].BaseField.split(".");

            setRuleListValue(data[0].ruleList || "");
            setBaseListValue(baseFieldData[1] || "");
            setComparisionListValue(data[0].ComparisonOperator || "");
            setReferenceValue(data[0].ReferenceValue || "");
            setLogicalOperatorValue(data[0].ComparisonOperator || "");
            setLogicalOperatorValue(data[0].Connector || "");

            if (data && data.length > 1) {
              const baseFieldData2 = data[1].BaseField.split(".");
              setRuleListValueSecond(data[1].ruleList || "");
              setBaseListValueSecond(baseFieldData2[1] || "");
              setComparisionListValueSecond(data[1].ComparisonOperator || "");
              setReferenceValueSecond(data[1].ReferenceValue || "");
            }
          }

          newInsertUpdateState.isDecisionAutoTask = true;
          break;
        }
      }

      newInsertUpdateState.componentCanAddAdditionalData =
        ComponentCanAddAdditionalDataConst.includes(recordInfo.componentId);

      setInsertUpdateState(newInsertUpdateState);
      if (newInsertUpdateState.recordLanguageList.length > 0)
        nameInputRef.current?.setValue(
          newInsertUpdateState.recordLanguageList.map((v) => ({
            id: v.key,
            value: v.value,
          }))
        );
      if (newInsertUpdateState.recordLanguageHelpNotesList.length > 0)
        helpNotesInputRef.current?.setValue(
          newInsertUpdateState.recordLanguageHelpNotesList.map((v) => ({
            id: v.key,
            value: v.value,
          }))
        );
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getById ex`);
      setIsLoadingScreen(false);
    }
  };

  const showAdditionalDataMenuItem = (componentId: string) => {
    let newComponentCanAdditionalData: boolean =
      ComponentCanAddAdditionalDataConst.includes(componentId);
    setCanAddAdditionalData(newComponentCanAdditionalData);
    if (!newComponentCanAdditionalData) {
      callBackResult({
        result: "remove_item_from_aside_menu",
        itemLabel: "Additional Data",
      });
    } else {
      callBackResult({
        result: "add_additional_data_item_for_aside_menu",
      });
    }
  };

  useEffect(() => {
    showAdditionalDataMenuItem(insertUpdateState.componentId);
  }, [insertUpdateState.componentId]);

  const getRecordLanguageList = async (
    pRecordId: string,
    _multilanguageTableName: string,
    _multilanguageFieldName: string,
    _expectedCodes: Array<number>
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          _multilanguageTableName,
          _multilanguageFieldName,
          pRecordId,
          false,
          true,
          _expectedCodes
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "cancel_general", recordId: recordId });
  };

  const handleComponentChange = (componentId: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.componentId = componentId;

    newInsertUpdateState.isExternalTask = false;
    newInsertUpdateState.isExportCaseTask = false;
    newInsertUpdateState.isExportCaseAutoTask = false;
    newInsertUpdateState.isEmailTask = false;

    newInsertUpdateState.isDecision = false;
    newInsertUpdateState.isCommentRequiered = false;
    newInsertUpdateState.isVisibleTaskReport = true;
    newInsertUpdateState.automaticAnswer = false;
    newInsertUpdateState.isDecisionAutoTask = false;

    newInsertUpdateState.disabledTaskReport = true;
    if (componentId === "") {
      setInsertUpdateState(newInsertUpdateState);
      return;
    }

    if (realMode == "Update") {
      showAdditionalDataMenuItem(componentId);
    }

    newInsertUpdateState.componentIdErrorMessage = "";
    newInsertUpdateState.disabledTaskReport = false;
    switch (componentId) {
      case TaskTypeComponentEnum.caseResponseEmailAutoTaskComponent:
      case TaskTypeComponentEnum.caseResponseEmailTaskComponent:
        newInsertUpdateState.isEmailTask = true;
        break;
      case TaskTypeComponentEnum.externalTaskComponent:
        newInsertUpdateState.isExternalTask = true;
        break;
      case TaskTypeComponentEnum.caseResponseExchangeTaskComponent:
        newInsertUpdateState.isExportCaseTask = true;
        break;
      case TaskTypeComponentEnum.caseResponseExchangeAutoTaskComponent:
        newInsertUpdateState.isExportCaseTask = true;
        newInsertUpdateState.isExportCaseAutoTask = true;
        break;
      case TaskTypeComponentEnum.caseResponseDecisionAutoTaskComponent:
        newInsertUpdateState.isDecision = true;
        newInsertUpdateState.isDecisionAutoTask = true;
        newInsertUpdateState.disabledTaskReport = true;
        break;
      case TaskTypeComponentEnum.caseResponseDecisionRetryTaskComponent:
        newInsertUpdateState.isDecision = true;
        newInsertUpdateState.disabledTaskReport = true;
        break;
      case TaskTypeComponentEnum.caseResponseDecisionTaskComponent:
        newInsertUpdateState.isDecision = true;
        newInsertUpdateState.isVisibleTaskReport = false;
        newInsertUpdateState.disabledTaskReport = true;
        break;
      case TaskTypeComponentEnum.caseResponseAnswerAutoTaskComponent:
        newInsertUpdateState.automaticAnswer = true;
        break;
      case TaskTypeComponentEnum.caseResponseStandardTaskComponent:
        newInsertUpdateState.isVisibleTaskReport = false;
        newInsertUpdateState.disabledTaskReport = true;
        break;
    }
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleExportDestinationChange = (e: any) => {
    let newExportCaseExtendedTaskTypeState = {
      ...exportCaseExtendedTaskTypeState,
    };
    newExportCaseExtendedTaskTypeState.exportDestinationId = e.target.value;
    newExportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage = "";
    setExportCaseExtendedTaskTypeState(newExportCaseExtendedTaskTypeState);
  };

  const handleExportCommentsChange = (newExportComments: string) => {
    let newexportCaseExtendedTaskTypeState = {
      ...exportCaseExtendedTaskTypeState,
    };
    newexportCaseExtendedTaskTypeState.exportComments = newExportComments;
    newexportCaseExtendedTaskTypeState.exportCommentsErrorMessage = "";
    setExportCaseExtendedTaskTypeState(newexportCaseExtendedTaskTypeState);
  };

  const handleOutboundMailAccountIdChange = (e: any) => {
    let newsendEmailExtendedTaskTypeState = {
      ...sendEmailExtendedTaskTypeState,
    };
    newsendEmailExtendedTaskTypeState.outboundMailAccountId = e.target.value;
    setSendEmailExtendedTaskTypeState(newsendEmailExtendedTaskTypeState);
  };

  const handleEmailTemplateIdChange = (id: string) => {
    let newsendEmailExtendedTaskTypeState = {
      ...sendEmailExtendedTaskTypeState,
    };
    newsendEmailExtendedTaskTypeState.emailTemplateId = id;
    newsendEmailExtendedTaskTypeState.emailTemplateIdErrorMessage = "";
    setSendEmailExtendedTaskTypeState(newsendEmailExtendedTaskTypeState);
  };

  const handleRuleListDefinition = (e: any, propertyName: string) => {
    const { value } = e.target;
    switch (propertyName) {
      case "ruleList":
        setRuleListValue(value);
        if (value) setRuleListError("");
        break;
      case "baseList":
        setBaseListValue(value);
        if (value) setBaseListError("");
        break;
      case "comparisionList":
        setComparisionListValue(value);
        if (value) setComparisionListError("");
        break;
      case "reference":
        setReferenceValue(value);
        if (value) setReferenceValueError("");
        break;
      case "logicalOperatorList":
        setLogicalOperatorValue(value);
        if (value) setLogicalOperatorError("");
        break;
      case "ruleListSecond":
        setRuleListValueSecond(value);
        if (value) setRuleListSecondError("");
        break;
      case "baseListSecond":
        setBaseListValueSecond(value);
        if (value) setBaseListSecondError("");
        break;
      case "comparisionListSecond":
        setComparisionListValueSecond(value);
        if (value) setComparisionListSecondError("");
        break;
      case "referenceSecond":
        setReferenceValueSecond(value);
        if (value) setReferenceValueSecondError("");
        break;
      default:
        console.error("Invalid type");
    }
  };
  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQTATY
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  useEffect(() => {
    loadResourcesAndLoadInfo();
    realMode === "Insert" && !activeInsert && generalAutomaticId();
  }, [ruleListValue, ruleListValueSecond]);

  return (
    <TPLoadingOverlay active={isLoadingScreen}>
      <TPStyledForm>
        <TPStyledInnerInputForm>
          <TPStyledSubtitle>{descriptionSectionLabel}</TPStyledSubtitle>
          <TPStyledFormRow>
            <TPTextBox
              id="task-type-id-input"
              labelText={idLabel}
              isMandatory={true}
              value={realMode === "Insert" ? referenceId : recordId}
              onChange={
                realMode === "Insert"
                  ? (e: any) => handleOnIdChange(e.target.value)
                  : () => {}
              }
              maxLength={20}
              disabled
              errorMessage={insertUpdateState.idErrorMessage}
            />
          </TPStyledFormRow>
          <TPStyledFormRow>
            <MultilingualTextBox
              ref={nameInputRef}
              maxLength={200}
              value={insertUpdateState.recordLanguageList?.map(
                ({ key, value }) => ({ id: key, value })
              )}
              shrinkInput={false}
            />
          </TPStyledFormRow>
        </TPStyledInnerInputForm>
        <TPStyledInnerInputForm>
          <TPStyledSubtitle>{parametersSectionLabel}</TPStyledSubtitle>
          <TPStyledFormRow>
            <TPSelect
              id="task-type-render-type-input"
              isMandatory={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleComponentChange(e.target.value)
              }
              dataSource={componentKeyValue}
              value={insertUpdateState.componentId}
              labelText={componentLabel}
              errorMessage={insertUpdateState.componentIdErrorMessage}
            />
          </TPStyledFormRow>
          <TPStyledGrid columns={2}>
            <TPCheckBox
              id="task-type-checkbox-is-desicion"
              labelText={isDecisionLabel}
              checked={insertUpdateState.isDecision}
              onChange={(e: any) => handleIsisDecisionChange()}
              disabled={true}
            />
            <TPCheckBox
              id="task-type-checkbox-escalates"
              labelText={escalatesLabel}
              checked={insertUpdateState.escalates}
              onChange={(e: any) => handleEscalatesChange()}
            />
            <TPCheckBox
              id="task-type-checkbox-addable"
              labelText={addableLabel}
              checked={insertUpdateState.addable}
              onChange={(e: any) => handleAddableChange()}
            />
            <TPCheckBox
              id="task-type-checkbox-comment-required"
              labelText={isCommentRequieredLabel}
              checked={insertUpdateState.isCommentRequiered}
              onChange={(e: any) => handleIsCommentRequieredChange()}
            />
            <TPCheckBox
              id="task-type-checkbox-is-private"
              labelText={isPrivateLabel}
              disabled={disabledIsPrivate}
              checked={insertUpdateState.isPrivate}
              onChange={(e: any) => handleIsPrivateChange()}
            />
            <TPCheckBox
              id="task-type-checkbox-is-visible-report"
              labelText={isVisibleTaskReportLabel}
              checked={insertUpdateState.isVisibleTaskReport}
              onChange={(e: any) => handleIsVisibleTaskReportChange()}
              disabled={insertUpdateState.disabledTaskReport}
            />
            <TPCheckBox
              id="task-type-checkbox-is-active"
              labelText={isActiveLabel}
              checked={insertUpdateState.isActive}
              onChange={(e: any) => handleIsActiveChange()}
            />
          </TPStyledGrid>
        </TPStyledInnerInputForm>
        <TPStyledInnerInputForm>
          <TPStyledSubtitle>{helpNotesSectionLabel}</TPStyledSubtitle>
          <TPStyledFormRow>
            <MultilingualTextBox
              id="task-type-help-notes-input"
              ref={helpNotesInputRef}
              value={insertUpdateState.recordLanguageHelpNotesList?.map(
                ({ key, value }) => ({ id: key, value })
              )}
              optional
              labels={{
                inputLabel: helpNotesSectionLabel,
              }}
              shrinkInput={false}
            />
          </TPStyledFormRow>
          {insertUpdateState.isExternalTask && (
            <>
              <TPStyledFormRow>
                <TPTextBox
                  id="task-type-external-task-url-input"
                  isMandatory
                  labelText={externalTaskURLLabel}
                  value={externalExtendedTaskState.externalTaskURL}
                  onChange={(e: any) =>
                    handleExternalTaskChange(e.target.value)
                  }
                  maxLength={1000}
                  errorMessage={
                    externalExtendedTaskState.externalTaskURLErrorMessage
                  }
                />
              </TPStyledFormRow>
              <TPStyledFormRow>
                <TPTextBox
                  id="task-type-external-task-rsa-key-input"
                  isMandatory={false}
                  labelText={externalTaskRSAPublicKeyLabel}
                  value={insertUpdateState.externalTaskRSAPublicKey}
                  onChange={(e: any) =>
                    handleExternalTaskRSAPublicKeyChange(e.target.value)
                  }
                  maxLength={1000}
                  errorMessage={
                    insertUpdateState.externalTaskRSAPublicKeyErrorMessage
                  }
                />
              </TPStyledFormRow>
            </>
          )}
          {insertUpdateState.isExportCaseTask && (
            <TPStyledFormRow>
              <TPSelect
                id="task-type-export-destination-input"
                isMandatory={
                  insertUpdateState.componentId ===
                  TaskTypeComponentEnum.caseResponseExchangeAutoTaskComponent
                }
                onChange={handleExportDestinationChange}
                dataSource={fakeDataExportDestinationListState}
                value={exportCaseExtendedTaskTypeState.exportDestinationId}
                labelText={exportDestinationLabel}
                errorMessage={
                  exportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage
                }
              />
            </TPStyledFormRow>
          )}
          {insertUpdateState.isExportCaseAutoTask && (
            <TPStyledFormRow>
              <TPTextBox
                id="task-type-export-case-comments-input"
                isMandatory={
                  insertUpdateState.componentId ===
                  TaskTypeComponentEnum.caseResponseExchangeAutoTaskComponent
                }
                labelText={comentsToExportLabel}
                value={exportCaseExtendedTaskTypeState.exportComments}
                onChange={(e: any) =>
                  handleExportCommentsChange(e.target.value)
                }
                maxLength={1000}
                errorMessage={emailAccountError}
              />
            </TPStyledFormRow>
          )}
          {insertUpdateState.isEmailTask && (
            <>
              <TPStyledFormRow>
                <TPSelect
                  id="task-type-mail-acc-input"
                  isMandatory={true}
                  onChange={handleOutboundMailAccountIdChange}
                  dataSource={fakeDataOutboundMailAccountList}
                  value={sendEmailExtendedTaskTypeState.outboundMailAccountId}
                  labelText={outboundMailAccountLabel}
                  errorMessage={emailAccountError}
                />
              </TPStyledFormRow>
              <TPStyledFormRow>
                <div className="w-100">
                  <TPAutoComplete
                    id="task-type-email-template-input"
                    isMandatory={true}
                    labelText={templateLabel}
                    onValueChange={handleTemplateChange}
                    onSearch={(query: string) => {
                      handleTemplateOnAutocompleteQuery(query);
                      handleEmailTemplateIdChange(query);
                    }}
                    isLoading={false}
                    options={autocompleteTemplateOptions}
                    withIcon={true}
                    emptyLabel={emptyLabel}
                    onKeyDown={handleTemplateOnAutocompleteKeyDown}
                    selected={modalTemplateScreenState.newTemplate}
                    errorMessage={emailTemplateError}
                    downArrowClick={handleAutoCompleteTemplatepNClick}
                  />
                </div>
              </TPStyledFormRow>
            </>
          )}
          {insertUpdateState.automaticAnswer && (
            <TPStyledFormRow>
              <TPTextBox
                id="task-type-auto-answer-input"
                isMandatory={false}
                labelText={commentsToAutomaticAnswerLabel}
                value={automaticTaskAnswer.value}
                onChange={(e: any) => {
                  setAutomaticTaskAnswer({
                    value: e.target.value,
                    key: "",
                  });
                }}
                maxLength={1000}
                errorMessage={automaticTaskAnswerError}
              />
            </TPStyledFormRow>
          )}
        </TPStyledInnerInputForm>
        {insertUpdateState.isDecisionAutoTask && (
          <TPStyledInnerInputForm>
            <TPStyledSubtitle>{ruleDefinitionLabel}</TPStyledSubtitle>
            <TPStyledFormRow>
              <TPSelect
                id="task-type-rule-type-1"
                isMandatory={true}
                onChange={(e: any) => {
                  handleRuleListDefinition(e, "ruleList");

                  setBaseListValue("");
                  setComparisionListValue("");
                }}
                dataSource={fakeDataRuleListFirstState}
                value={ruleListValue}
                labelText={ruleTypeLabel + " 1"}
                errorMessage={ruleListError}
              />
            </TPStyledFormRow>
            <TPStyledFormRow>
              <TPSelect
                id="task-type-base-field-1"
                isMandatory={true}
                onChange={(e: any) => handleRuleListDefinition(e, "baseList")}
                dataSource={fakeDataBaseListFirstState}
                value={baseListValue}
                labelText={baseFieldLabel + " 1"}
                errorMessage={baseListError}
              />
            </TPStyledFormRow>
            <TPStyledFormRow>
              <TPSelect
                id="task-type-comparison-operator-1"
                isMandatory={true}
                onChange={(e: any) =>
                  handleRuleListDefinition(e, "comparisionList")
                }
                dataSource={fakeDataComparisionListFirstState}
                value={comparisionListValue}
                labelText={comparisonOperatorLabel + " 1"}
                errorMessage={comparisionListError}
              />
            </TPStyledFormRow>
            <TPStyledFormRow>
              <TPTextBox
                id="task-type-reference-value-1"
                isMandatory={true}
                labelText={refValueLabel + " 1"}
                value={referenceValue}
                onChange={(e: any) => handleRuleListDefinition(e, "reference")}
                maxLength={1000}
                errorMessage={referenceValueError}
              />
            </TPStyledFormRow>
            <TPStyledFormRow justify="center" shrink>
              <TPSelect
                id="task-type-logical-operator-input"
                isMandatory={false}
                onChange={(e: any) =>
                  handleRuleListDefinition(e, "logicalOperatorList")
                }
                dataSource={fakeDataLogicalOperator}
                value={logicalOperatorValue}
                labelText={logicalOperatorLabel}
                errorMessage={logicalOperatorError}
              />
            </TPStyledFormRow>
            {(logicalOperatorValue === "AND" ||
              logicalOperatorValue === "OR") &&
              insertUpdateState.isDecisionAutoTask && (
                <>
                  <TPStyledFormRow>
                    <TPSelect
                      id="task-type-rule-type-2"
                      isMandatory={true}
                      onChange={(e: any) => {
                        handleRuleListDefinition(e, "ruleListSecond");
                        setBaseListValueSecond("");
                        setComparisionListValueSecond("");
                      }}
                      dataSource={fakeDataRuleListFirstState}
                      value={ruleListValueSecond}
                      labelText={ruleTypeLabel + " 2"}
                      errorMessage={ruleListSecondError}
                    />
                  </TPStyledFormRow>
                  <TPStyledFormRow>
                    <TPSelect
                      id="task-type-base-field-2"
                      isMandatory={true}
                      onChange={(e: any) =>
                        handleRuleListDefinition(e, "baseListSecond")
                      }
                      dataSource={fakeDataBaseListFirstState2}
                      value={baseListValueSecond}
                      labelText={baseFieldLabel + " 2"}
                      errorMessage={baseListSecondError}
                    />
                  </TPStyledFormRow>
                  <TPStyledFormRow>
                    <TPSelect
                      id="task-type-comparison-operator-2"
                      isMandatory={true}
                      onChange={(e: any) =>
                        handleRuleListDefinition(e, "comparisionListSecond")
                      }
                      dataSource={fakeDataComparisionListFirstState}
                      value={comparisionListValueSecond}
                      labelText={comparisonOperatorLabel + " 2"}
                      errorMessage={comparisionListSecondError}
                    />
                  </TPStyledFormRow>
                  <TPStyledFormRow>
                    <TPTextBox
                      id="task-type-reference-value-2"
                      isMandatory={true}
                      labelText={refValueLabel + " 2"}
                      value={referenceValueSecond}
                      onChange={(e: any) =>
                        handleRuleListDefinition(e, "referenceSecond")
                      }
                      maxLength={1000}
                      errorMessage={referenceValueSecondError}
                    />
                  </TPStyledFormRow>
                </>
              )}
          </TPStyledInnerInputForm>
        )}
        <TPStyledFormButtonFooter>
          <TPOptionButtonPairStyled
            onCancel={handleCancelButtonClick}
            onConfirm={handleOkButtonClick}
            cancelLabel={cancelButtonLabel}
            confirmLabel={saveButtonLabel}
          />
        </TPStyledFormButtonFooter>
      </TPStyledForm>
    </TPLoadingOverlay>
  );
};

export default TaskTypeInsertUpdate;
