import React, { ReactNode } from "react";
import {
    WizardContainer,
    WizardSteps,
    ProgressBarContainer,
    ProgressBarFill,
    WizardBody
} from "./TPWizardStepperStyles";
import { TPWizardStep } from "./TPWizardStep";
import { TPWizardContent } from "./TPWizardContent";

interface TPWizardStepperProps {
    currentStep: number;
    children: ReactNode;
}

export const TPWizardStepper: React.FC<TPWizardStepperProps> = ({
                                                                    currentStep,
                                                                    children
                                                                }) => {
    const steps = React.Children.toArray(children).filter(
        (child: any) => child.type === TPWizardStep
    );

    const contents = React.Children.toArray(children).filter(
        (child: any) => child.type === TPWizardContent
    );

    const progress = ((currentStep + 1) / steps.length) * 100;

    return (
        <WizardContainer>
            <WizardSteps>
                {steps}
            </WizardSteps>

            <ProgressBarContainer>
                <ProgressBarFill progress={progress} />
            </ProgressBarContainer>

            <WizardBody>
                {contents}
            </WizardBody>
        </WizardContainer>
    );
};


export default TPWizardStepper;








