import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  CIMTitleSection,
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  SequenceGeneratorSequencesNameEnum,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import {
  InboundMailAzureApplicationDTO,
  InboundMailAzureApplicationViewModelValidator,
} from "@/models/InboundMailAzureApplication/InboundMailAzureApplicationDTO";
import { InboundMailAzureApplicationViewModel } from "@/models/InboundMailAzureApplication/InboundMailAzureApplicationViewModel";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { TPI18N } from "@/services/I18nService";
import { InboundMailAzureApplicationService } from "@/services/InboundMailAzureApplicationService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { SequenceService } from "@/services/SequenceService";
import {FC, ReactElement, useEffect, useRef, useState} from "react";
import {MultilingualTextBoxEvents} from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";
import allThemes from "@/assets/styles/theme";
import './InboundMailAzureApplicationAdminInsertUpdate.css';

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};

type InsertUpdateStateType = {
  id: string;
  description: string;
  azureTenantId: string;
  azureClientId: string;
  azureClientSecret: string;
  authenticationURL: string;
  redirectCallbackURL: string;
  // localizedDescription: string;
  recordLanguageList: Array<TPKeyValue>;
  isActive: boolean;

  //validator
  idErrorMessage: string;
  descriptionErrorMessages: Array<string>;
  azureTenantIdErrorMessage: string;
  azureClientIdErrorMessage: string;
  azureClientSecretErrorMessage: string;
  authenticationURLErrorMessage: string;
  redirectCallbackURLErrorMessage: string;
};

const InboundMailAzureApplicationAdminInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
}): ReactElement => {
  //#region  Init
  const componentFileName: string =
    "InboundMailAzureApplicationAdminInsertUpdate.tsx";

  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //Screen resources
  const resourceSet: string =
    "InboundMailAzureApplicationAdminInsertUpdateComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");

  const [idLabel, setIdLabel] = useState("");
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [azureTenantIdLabel, setAzureTenantIdLabel] = useState("");
  const [azureClientIdLabel, setAzureClientIdLabel] = useState("");
  const [azureClientSecretLabel, setAzureClientSecretLabel] = useState("");
  const [authenticationURLLabel, setAuthenticationURLLabel] = useState("");
  const [redirectCallbackURLLabel, setRedirectCallbackURLLabel] = useState("");

  const [isActiveLabel, setIsActiveLabel] = useState("");
  const [showAsActiveLabel, setShowAsActiveLabel] = useState("");
  const [isNameValue, setIsNameValue] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");
  const [referenceId, setReferenceId] = useState("");
  const nameInputRef = useRef<MultilingualTextBoxEvents>();
  //Screen state
  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }

  let insertUpdateInitialState: InsertUpdateStateType = {
    id: recordId,
    description: "",
    recordLanguageList: [],
    isActive: true,
    idErrorMessage: "",
    azureTenantId: "",
    azureClientId: "",
    azureClientSecret: "",
    authenticationURL: "",
    redirectCallbackURL: "",
    // localizedDescription: ""
    descriptionErrorMessages: [...initialErrorMessages],
    azureTenantIdErrorMessage: "",
    azureClientIdErrorMessage: "",
    azureClientSecretErrorMessage: "",
    authenticationURLErrorMessage: "",
    redirectCallbackURLErrorMessage: "",
  };
  const [insertUpdateState, setInsertUpdateState] = useState(
    insertUpdateInitialState,
  );

  //Multilanguage const
  const multilanguageTableName: String = "InboundMailAzureApplication";
  const multilanguageFieldName: String = "Description_IMAA";

  //#endregion

  const loadResourcesAndLoadFunctionInfo = async () => {
    let i: number;
    //resources state
    setTitleLabel(await TPI18N.GetText(resourceSet, "Title" + mode + "Label"));
    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );

    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setDescriptionLabel(await TPI18N.GetText(resourceSet, "DescriptionLabel"));
    setAzureTenantIdLabel(
      await TPI18N.GetText(resourceSet, "AzureTenantIdLabel"),
    );
    setAzureClientIdLabel(
      await TPI18N.GetText(resourceSet, "AzureClientIdLabel"),
    );
    setAzureClientSecretLabel(
      await TPI18N.GetText(resourceSet, "ClientSecretIdLabel"),
    );
    setAuthenticationURLLabel(
      await TPI18N.GetText(resourceSet, "AuthenticationURLLabel"),
    );
    setRedirectCallbackURLLabel(
      await TPI18N.GetText(resourceSet, "RedirectCallbackURLLabel"),
    );
    setIsActiveLabel(await TPI18N.GetText(resourceSet, "IsActiveLabel"));
    setShowAsActiveLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ShowAsActive"));

    //screen state
    if (mode == "Update") {
      await getFunctionById(recordId);
    }
    if (mode == "Insert") {
      let newInsertUpdateState = { ...insertUpdateState };
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }
  };
  const handleInputTextChange = (propertyName: string, newValue: any) => {
    setInsertUpdateState({
      ...insertUpdateState,
      [propertyName]: newValue,
      [propertyName + "ErrorMessage"]: "",
    });
  };
  // const handleOnIdInboundChange = (newIdInbound: string) => {
  //   let newInsertUpdateState = { ...insertUpdateState };
  //   newInsertUpdateState.id= newIdInbound;
  //   newInsertUpdateState.idErrorMessage = "";
  //   setInsertUpdateState(newInsertUpdateState);
  // };

  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.descriptionErrorMessages[index] = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleIsActiveChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActive = !newInsertUpdateState.isActive;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleOkButtonClick = async () => {
    if (nameInputRef.current?.isInvalid()) {
      nameInputRef.current?.markAsTouched();
      return;
    }

    const languages = nameInputRef.current?.getValue();
    const mainLanguage = languages?.find(({id}) => id === TPGlobal.TPClientDefaultLanguage);
    let i: number;
    let n: number;
    let recordInputDTO: InboundMailAzureApplicationDTO = {
      // id: insertUpdateState.id,
      id: mode === "Insert" ? referenceId : recordId,
      isActive: insertUpdateState.isActive,
      // localizedDescription: insertUpdateState.localizedDescription,
      azureTenantId: insertUpdateState.azureTenantId,
      azureClientId: insertUpdateState.azureClientId,
      azureClientSecret: insertUpdateState.azureClientSecret,
      authenticationURL: insertUpdateState.authenticationURL,
      redirectCallbackURL: insertUpdateState.redirectCallbackURL,
      descriptionLocalizedValues: [
        {
          languageId: mainLanguage?.id!,
          localizedValue: mainLanguage?.value ?? "",
          order: 1
        },
        ...languages!
            .filter(({id}) => id !== TPGlobal.TPClientDefaultLanguage)
            .map(({id, value}, order) => ({languageId: id!, localizedValue: value ?? "", order: order + 2}))
      ],
      description: mainLanguage?.value!,
    };


    let inputDTOValidator = new InboundMailAzureApplicationViewModelValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      if (resultValidator.id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.id,
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }
      if (resultValidator.azureTenantId) {
        newInsertUpdateState.azureTenantIdErrorMessage =
          await TPI18N.GetResource(resultValidator.azureTenantId);
      } else {
        newInsertUpdateState.azureTenantIdErrorMessage = "";
      }
      if (resultValidator.azureClientId) {
        newInsertUpdateState.azureClientIdErrorMessage =
          await TPI18N.GetResource(resultValidator.azureClientId);
      } else {
        newInsertUpdateState.azureClientIdErrorMessage = "";
      }
      if (resultValidator.azureClientSecret) {
        newInsertUpdateState.azureClientSecretErrorMessage =
          await TPI18N.GetResource(resultValidator.azureClientSecret);
      } else {
        newInsertUpdateState.azureClientSecretErrorMessage = "";
      }
      if (resultValidator.authenticationURL) {
        newInsertUpdateState.authenticationURLErrorMessage =
          await TPI18N.GetResource(resultValidator.authenticationURL);
      } else {
        newInsertUpdateState.authenticationURLErrorMessage = "";
      }
      if (resultValidator.redirectCallbackURL) {
        newInsertUpdateState.redirectCallbackURLErrorMessage =
          await TPI18N.GetResource(resultValidator.redirectCallbackURL);
      } else {
        newInsertUpdateState.redirectCallbackURLErrorMessage = "";
      }
      // if (resultValidator.localizedDescription) {
      //   newInsertUpdateState.nameErrorMessages[0] = await TPI18N.GetResource(
      //     resultValidator.localizedDescription
      //   );
      // } else {
      //   newInsertUpdateState.nameErrorMessages[0] = "";
      // }

      setInsertUpdateState(newInsertUpdateState);
      return;
    }
    if (mode == "Insert") {
      await insertFunction(recordInputDTO);
    } else {
      await updateFunction(recordInputDTO);
    }
  };

  const insertFunction = async (inputDTO: InboundMailAzureApplicationDTO) => {
    let serviceClient = new InboundMailAzureApplicationService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest =
        await serviceClient.insertInboundMailAzureApplication(
          inputDTO,
          true,
          true,
          expectedCodes,
        );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} insertFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateFunction = async (inputDTO: InboundMailAzureApplicationDTO) => {
    let serviceClient = new InboundMailAzureApplicationService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest =
        await serviceClient.updateInboundMailAzureApplication(
          inputDTO,
          true,
          true,
          expectedCodes,
        );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleChangeValue = (value: any) => {
    setIsNameValue(value);
  };

  const isFormValid = () => {
    return (
      isNameValue &&
      insertUpdateState.azureTenantId &&
      insertUpdateState.azureClientId &&
      insertUpdateState.azureClientSecret &&
      insertUpdateState.authenticationURL &&
      insertUpdateState.redirectCallbackURL
    );
  };

  const getFunctionById = async (pRecordId: string) => {
    let serviceClient = new InboundMailAzureApplicationService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;
    try {
      setIsLoadingScreen(true);

      let responseRequest =
        await serviceClient.getInboundMailAzureApplicationById(
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfo: InboundMailAzureApplicationViewModel;
      recordInfo = { ...responseRequest };

      setOriginalRecordDescription(
        recordInfo.description.length <= 100
          ? recordInfo.description
          : recordInfo.description.substring(0, 100) + "...",
      );

      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.recordLanguageList = [];
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      newInsertUpdateState.id = recordInfo.id;
      newInsertUpdateState.recordLanguageList[0].value = recordInfo.description;
      newInsertUpdateState.azureClientId = recordInfo.azureClientId;
      newInsertUpdateState.azureTenantId = recordInfo.azureTenantId;
      newInsertUpdateState.azureClientSecret = recordInfo.azureClientSecret;
      newInsertUpdateState.authenticationURL = recordInfo.authenticationURL;
      newInsertUpdateState.redirectCallbackURL = recordInfo.redirectCallbackURL;
      newInsertUpdateState.isActive = recordInfo.isActive;
      recordLanguagesList = await getRecordLanguageList(pRecordId);
      if (recordLanguagesList.length == 0) {
        TPLog.Log(
          `Error ${componentFileName} getFunctionById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty",
        );
        console.error(
          `Error ${componentFileName} getFunctionById getRecordLanguageList is empty`,
        );
        setIsLoadingScreen(false);
        return;
      }
      for (
        i = 0;
        i <= newInsertUpdateState.recordLanguageList.length - 1;
        i++
      ) {
        for (j = 0; j <= recordLanguagesList.length - 1; j++) {
          if (
            newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ==
            recordLanguagesList[j].languageId.toLowerCase()
          ) {
            newInsertUpdateState.recordLanguageList[i].value =
              recordLanguagesList[j].recordDescription;
            break;
          }
        }
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getFunctionById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getFunctionById ex`);
      setIsLoadingScreen(false);
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQIMAA,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        // callBackResult({ result: "OK", recordId: recordId });
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResourcesAndLoadFunctionInfo();
    mode === "Insert" && generalAutomaticId();
  }, []);

  return (
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className=" inbound-azure-def-main-form-container">
          <div className=" inbound-azure-def-main-form-inner-container">
            <CIMTitleSection style={{ margin: 0, padding: 0 }}>
              <TPPageTitle style={{ margin: 0, padding: 0 }}>
                {titleLabel}
              </TPPageTitle>
              {mode === "Update" && (
                <TPPageSubTitle>
                  {`${subTitleLabel}: ${recordId} / ${originalRecordDescription}`}
                </TPPageSubTitle>
              )}
            </CIMTitleSection>
  
            <div className="inbound-azure-def-form">
              <TPPageSection>
                <div className="row">
                  <div className="col-12">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={idLabel}
                      isMandatory={true}
                      value={mode === "Insert" ? referenceId : recordId}
                      disabled
                      errorMessage={insertUpdateState.idErrorMessage}
                      onChange={
                      mode === "Insert"
                        ? (e: any) =>
                            handleInputTextChange("id", e.target.value)
                        : () => {
                            TPGlobal.foo();
                          }
                    }
                    />
                  </div>
                </div>
              </TPPageSection>

              <TPPageSection>
              <div className="row">
                <div className="col-12">
                <MultilingualTextBox
                  ref={nameInputRef}
                  maxLength={200}
                  style={{ width: "calc(100% + 56px)" }}
                  onChange={handleChangeValue}
                  value={insertUpdateState.recordLanguageList?.map(({key, value}) => ({id: key, value}))}
                />
                </div>
              </div>
              </TPPageSection>
  
              <TPPageSection>
                <div className="inbound-azure-def-form-row">
                  <div style={{ width: "50%" }}>
                    <TPTextBox
                      id="azure-tenant-id"
                      labelText={azureTenantIdLabel}
                      isMandatory={true}
                      value={insertUpdateState.azureTenantId}
                      onChange={(e:any) => handleInputTextChange("azureTenantId", e.target.value)}
                      maxLength={200}
                      errorMessage={insertUpdateState.azureTenantIdErrorMessage}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <TPTextBox
                      id="azure-client-id"
                      labelText={azureClientIdLabel}
                      isMandatory={true}
                      value={insertUpdateState.azureClientId}
                      onChange={(e:any) => handleInputTextChange("azureClientId", e.target.value)}
                      maxLength={200}
                      errorMessage={insertUpdateState.azureClientIdErrorMessage}
                    />
                  </div>
                </div>
              </TPPageSection>
  
              <TPPageSection>
                <div className="row">
                  <div className="col-12">
                    <TPTextBox
                      id="azure-client-secret"
                      labelText={azureClientSecretLabel}
                      isMandatory={true}
                      value={insertUpdateState.azureClientSecret}
                      onChange={(e:any) => handleInputTextChange("azureClientSecret", e.target.value)}
                      maxLength={200}
                      errorMessage={insertUpdateState.azureClientSecretErrorMessage}
                    />
                  </div>
                </div>
              </TPPageSection>
  
              <TPPageSection>
                <div className="inbound-azure-def-form-row">
                  <div style={{ width: "50%" }}>
                    <TPTextBox
                      id="authentication-url"
                      labelText={authenticationURLLabel}
                      isMandatory={true}
                      value={insertUpdateState.authenticationURL}
                      onChange={(e:any) => handleInputTextChange("authenticationURL", e.target.value)}
                      maxLength={200}
                      errorMessage={insertUpdateState.authenticationURLErrorMessage}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <TPTextBox
                      id="redirect-callback-url"
                      labelText={redirectCallbackURLLabel}
                      isMandatory={true}
                      isInvalidChars={false}
                      value={insertUpdateState.redirectCallbackURL}
                      onChange={(e:any) => handleInputTextChange("redirectCallbackURL", e.target.value)}
                      maxLength={200}
                      errorMessage={insertUpdateState.redirectCallbackURLErrorMessage}
                    />
                  </div>
                </div>
              </TPPageSection>

              {/* <div className="row">
                <div className="col-6">
                  <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
                </div>
              </div> */}
  
              <TPPageSection>
                <div className="inbound-azure-def-form-row">
                  <TPCheckBox
                    id="active-checkbox"
                    labelText={showAsActiveLabel}
                    checked={insertUpdateState.isActive}
                    onChange={handleIsActiveChange}
                  />
                </div>
              </TPPageSection>
  
              <div className="inbound-azure-def-option-buttons">
                <TPButton
                  isDesignSystem
                  id="cancel-button"
                  onClick={handleCancelButtonClick}
                  style={{
                    backgroundColor: "white",
                    color: allThemes.base.purplePrimary,
                    padding: "11px 16px",
                    marginRight: "10px"
                  }}
                >
                  {cancelButtonLabel}
                </TPButton>
                <TPButton
                  isDesignSystem
                  id="save-button"
                  onClick={handleOkButtonClick}
                  style={{ padding: "11px 16px" }}
                  disabled={!isFormValid()}
                >
                  {saveButtonLabel}
                </TPButton>
              </div>
            </div>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>
  );
};

export default InboundMailAzureApplicationAdminInsertUpdate;
