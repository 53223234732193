import { FC } from "react";
import { CSSProperties } from "styled-components";

interface CustomSpanProps {
  style?: CSSProperties;
  children?: React.ReactNode;
}

export const CustomSpan: FC<CustomSpanProps> = function({ style, children }) {
  return <span style={{ ...style }}>{children}</span>;
}