import TPGlobal from "@/helpers/TPGlobal";
import {
    RightVerticalMenuContainer,
    RightVerticalMenuLi,
    RightVerticalMenuUl,
    RightVerticalMenuWrapper,
} from "@/layouts/Main/mainMenuStyles";
import { TPI18N } from "@/services/I18nService";
import { FC, ReactElement, useEffect, useReducer, useState } from "react";
import BranchAdmin from "./BranchAdmin";
import TreesInsertUpdate from "./TreesInsertUpdate";
import ViewImage from "../../components/TPViewImage/ViewImage";
import { ImageActive } from "../../models/ViewImage/ViewImage";

type TreeContainerInsertUpdateProps = {
    mode: string;
    recordId: string;
    branchId: string;
    callBackResult: Function;
    callBackUpdate: Function;
    tabId: string;
    cloned: boolean;
};

enum asideMenuItemTypes {
    "general" = 0,
    "branches" = 1,
    "empty" = 2
}

interface AsideMenuItemsModel {
    itemId: string;
    itemLabel: string;
    itemType: asideMenuItemTypes;
    isActive: boolean;
    isEnabled: boolean;
    isVisible: boolean;
    onClickHandler: Function;
}

enum commandsEnum {
    "create_general" = 0,
    "create_branch_and_change_mode" = 1,
    "set_active_general" = 2,
    "set_active_branch" = 3,
    "set_active_branch_cloned" = 4,
}

type commandType = {
    type: commandsEnum;
    payload: any;
};

type VerticalManagement = {
    general: AsideMenuItemsModel;
    branches: AsideMenuItemsModel;
};

const TreeContainerInsertUpdate: FC<TreeContainerInsertUpdateProps> = ({
    mode,
    recordId,
    callBackResult,
    callBackUpdate,
    tabId,
    cloned,
    branchId,
}): ReactElement => {
    //control state
    const resourceSet: string = "TreeContainerInsertUpdate";
    const [titleGeneralInformation, setTitleGeneralInformation] = useState("");
    const [titleBranch, setTitleBranch] = useState("");
    const [exitLabel, setExitLabel] = useState("Exit");
    const [realRecordId, setRealRecordId] = useState(recordId);
    const [branchSelected, setBranchSelected] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);

    const loadResources = async () => {
        setTitleGeneralInformation(
            await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "GeneralInformationTitle",
            ),
        );

        setTitleBranch(await TPI18N.GetText(resourceSet, "TitleBranch"));
        setExitLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ExitLabel"));
    };

    //handler to receive commands from child tabs
    const handleCallBackCommands = (theCommand: any) => {
        if (theCommand.result === "save_general_insert_and_change_mode") {
            //create details content
            let command: commandType = {
                type: commandsEnum.create_branch_and_change_mode,
                payload: { recordId: theCommand.recordId },
            };
            dispatchCommand(command);
            callBackResult({
                result: "ChangeToUpdateMode",
                recordId: theCommand.recordId,
                tabId: tabId,
            });
            return;
        }
        if (theCommand.result === "save_general_insert") {
            callBackResult({ result: "OK", recordId: "" });
            return;
        }
        if (theCommand.result === "save_general_update") {
            return;
        }
        if (theCommand.result === "cancel_general") {
            callBackResult({ result: "CANCEL", recordId: theCommand.recordId });
            return;
        }
    };

    const onGeneralAsideItemClick = () => {
        let currentActive: asideMenuItemTypes;
        if (asideMenuState.filter((x) => x.isActive === true).length >= 1) {
            currentActive = asideMenuState.filter((x) => x.isActive === true)[0]
                .itemType;
            if (currentActive === asideMenuItemTypes.general) {
                //already on general
                return;
            }
        }
        let command: commandType = {
            type: commandsEnum.set_active_general,
            payload: null,
        };
        dispatchCommand(command);
        return;
    };

    const onBranchAsideItemClick = () => {
        let command: commandType = {
            type: commandsEnum.set_active_branch,
            payload: null,
        };
        dispatchCommand(command);
    };

    const handleOnExitClick = () => {
        callBackResult({ result: "CANCEL", recordId: realRecordId });
    };

    //create general content
    const realCreateGeneralContentCommand = (
        prevState: Array<AsideMenuItemsModel>,
        isCloned: boolean = false,
    ) => {
        let newStateHome: AsideMenuItemsModel[] = [...prevState];

        newStateHome.push(getVerticalTabBy("general", isCloned));

        if (mode === "Update") {
            newStateHome.push(getVerticalTabBy("branches", isCloned));
        }

        return newStateHome;
    };

    const getVerticalTabBy = (
        type: "branches" | "general",
        status: boolean,
    ): AsideMenuItemsModel => {
        let verticals: VerticalManagement = {
            branches: {
                itemId: "branches-section",
                itemLabel: titleBranch,
                itemType: asideMenuItemTypes.branches,
                isActive: status,
                isVisible: true,
                isEnabled: true,
                onClickHandler: onBranchAsideItemClick,
            },
            general: {
                itemId: "general-information-section",
                itemLabel: titleGeneralInformation,
                itemType: asideMenuItemTypes.general,
                isActive: !status,
                isVisible: true,
                isEnabled: true,
                onClickHandler: onGeneralAsideItemClick,
            },
        };

        return verticals[type];
    };



    //create home content
    const realCreateBranchContentCommand = (
        prevState: Array<AsideMenuItemsModel>,
    ) => {
        let newStateHome: AsideMenuItemsModel[] = [...prevState];

        newStateHome.forEach((item) => (item.isActive = false));

        newStateHome.push(getVerticalTabBy("branches", true));

        return newStateHome;
    };

    //set active item
    const setActiveContent = (
        prevState: Array<AsideMenuItemsModel>,
        itemType: asideMenuItemTypes,
    ) => {
        let newStateHome: AsideMenuItemsModel[] = [...prevState];

        newStateHome.forEach((item) => {
            item.isActive = item.itemType === itemType;
        });

        return newStateHome;
    };

    const handleOpenNewTree = (config: any) => {
        const { command, recordId, branch } = config;

        // close the tabs related to cloning
        callBackResult({ result: "CANCEL", recordId: recordId });
        handleOnExitClick();

        callBackUpdate({
            command,
            recordId,
            branch,
            type: "cloned",
        });
    };

    //aside menu state with reducer
    let initialState: Array<AsideMenuItemsModel> = [];
    const [asideMenuState, dispatchCommand] = useReducer(doCommand, initialState);

    function doCommand(
        prevState: Array<AsideMenuItemsModel>,
        command: commandType,
    ) {
        switch (command.type) {
            case commandsEnum.create_general:
                return realCreateGeneralContentCommand(prevState);
            case commandsEnum.create_branch_and_change_mode:
                let newRecordId: string;
                newRecordId = command.payload.recordId;
                setRealRecordId(newRecordId);
                return realCreateBranchContentCommand(prevState);
            case commandsEnum.set_active_general:
                return setActiveContent(prevState, asideMenuItemTypes.general);
            case commandsEnum.set_active_branch:
                return setActiveContent(prevState, asideMenuItemTypes.branches);
            case commandsEnum.set_active_branch_cloned:
                return realCreateGeneralContentCommand(prevState, true);
        }
    }

    //run once to include functionsAdmin in first tab
    useEffect(() => {
        loadResources().then(() => {
            let command: commandType = {
                type: cloned
                    ? commandsEnum.set_active_branch_cloned
                    : commandsEnum.create_general,
                payload: null,
            };
            dispatchCommand(command);
            setBranchSelected(branchId);
        });
    }, [branchId, cloned]);

    return (
        <>
            <div className="row">
                <div className="col-10">
                    <ul>
                        {asideMenuState.map(function (item, index) {
                            let jsxFragment: any;
                            let visibilityStyle: any;
                            if (item.isActive) {
                                visibilityStyle = { display: "block" };
                            } else {
                                visibilityStyle = { display: "none" };
                            }
                            switch (item.itemType) {
                                case asideMenuItemTypes.general:
                                    jsxFragment = (
                                        <li key={"asidecontent" + index.toString()}>
                                            <div style={visibilityStyle}>
                                                <TreesInsertUpdate
                                                    asideMenuState={asideMenuState}
                                                    callBackResult={handleCallBackCommands}
                                                    mode={mode}
                                                    recordId={realRecordId}
                                                    setIsOpenModal={setIsOpenModal}
                                                />
                                            </div>
                                        </li>
                                    );
                                    break;
                                case asideMenuItemTypes.branches:
                                    jsxFragment = (
                                        <li key={"asidecontent" + index.toString()}>
                                            <div style={visibilityStyle}>
                                                <BranchAdmin
                                                    asideMenuState={asideMenuState}
                                                    treeId={realRecordId}
                                                    branchSelected={branchSelected}
                                                    changeTreeSelected={handleOpenNewTree}
                                                    setIsOpenModal={setIsOpenModal}
                                                />
                                            </div>
                                        </li>
                                    );
                                    break;
                                default:
                                    jsxFragment = (
                                        <li key={"asidecontent" + index.toString()}>
                                            <div style={visibilityStyle}>
                                                <span>error this should not be here</span>
                                            </div>
                                        </li>
                                    );
                                    break;
                            }
                            return jsxFragment;
                        })}
                    </ul>
                </div>

                {false &&
                    <RightVerticalMenuContainer className="col-2 mt-4" style={{ zIndex: isOpenModal ? -1 : 1 }}>
                        <RightVerticalMenuWrapper>
                            <RightVerticalMenuUl>
                                {asideMenuState.map(function (item, index) {
                                    //render aside menu
                                    let jsxFragment: any;
                                    let visibilityStyle: any;
                                    if (item.isVisible) {
                                        visibilityStyle = {
                                            display: "block",
                                            borderBottom: "1px solid #e8e8e8",
                                        };
                                    } else {
                                        visibilityStyle = {
                                            display: "none",
                                            borderBottom: "1px solid #e8e8e8",
                                        };
                                    }
                                    switch (item.itemType) {
                                        case asideMenuItemTypes.general:
                                            jsxFragment = (
                                                <RightVerticalMenuLi
                                                    className={item.isActive ? "active" : ""}
                                                    key={"asidemenuitem" + index.toString()}
                                                >
                                                    <div style={visibilityStyle}>
                                                        <span
                                                            onClick={() => item.onClickHandler()}
                                                            id={item.itemId}
                                                        >
                                                            {item.itemLabel}
                                                        </span>
                                                    </div>
                                                </RightVerticalMenuLi>
                                            );
                                            break;
                                        case asideMenuItemTypes.branches:
                                            jsxFragment = (
                                                <RightVerticalMenuLi
                                                    className={item.isActive ? "active" : ""}
                                                    key={"asidemenuitem" + index.toString()}
                                                >
                                                    <div style={visibilityStyle}>
                                                        <a
                                                            href="#"
                                                            id={item.itemId}
                                                            onClick={() => item.onClickHandler()}
                                                        >
                                                            {item.itemLabel}
                                                        </a>
                                                    </div>
                                                </RightVerticalMenuLi>
                                            );
                                            break;
                                        default:
                                            jsxFragment = (
                                                <RightVerticalMenuLi
                                                    className={item.isActive ? "active" : ""}
                                                    key={"asidemenuitem" + index.toString()}
                                                >
                                                    <div style={visibilityStyle}>
                                                        <span>error this should not be here</span>
                                                    </div>
                                                </RightVerticalMenuLi>
                                            );
                                            break;
                                    }
                                    return jsxFragment;
                                })}
                                <RightVerticalMenuLi
                                    key={"asidemenuitem" + asideMenuState.length.toString()}
                                    style={{ marginTop: "30px" }}
                                ></RightVerticalMenuLi>
                                <RightVerticalMenuLi
                                    key={"asidemenuitem" + (asideMenuState.length + 1).toString()}
                                >
                                    <span onClick={() => handleOnExitClick()} id="exit-from-tree">
                                        {exitLabel}
                                    </span>
                                </RightVerticalMenuLi>
                            </RightVerticalMenuUl>
                        </RightVerticalMenuWrapper>
                    </RightVerticalMenuContainer>
                }
            </div>
        </>
    );
};

export default TreeContainerInsertUpdate;

function useCallback(arg0: (event: React.SyntheticEvent, newValue: number) => void, arg1: never[]) {
    throw new Error("Function not implemented.");
}
