import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { FunctionsInputDTO } from "@/models/Functions/FunctionsInputDTO";
import { FunctionsViewModel } from "@/models/Functions/FunctionsModels";
import {
    Data,
    InserFolder,
    InserFolderAndFile,
} from "@/models/ImagesAdmin/ImagesAdminModel";

let expectedCodes: number[];
let showPositiveMessage: boolean;
let showNegativeMessage: boolean;

export class ImageService {
    serviceFileName: string = "ImageService.ts";

    public constructor(
        _expectedCodes: number[],
        _showPositiveMessage: boolean = false,
        _showNegativeMessage = false,
    ) {
        expectedCodes = _expectedCodes;
        showPositiveMessage = _showPositiveMessage;
        showNegativeMessage = _showNegativeMessage;
    }

    //lista de carpetas y archivos
    public async getFilterAllFolderAndFile(
        parentId: number,
        fileType: string = "Folder",
        filterIsActive: number = 2,
        searchTearms: string = "",
    ) {
        let httpclient = new TPHTTPService();
        let url: string;
        url = `/images?filterIsActive=${filterIsActive}&parentId=${parentId}&type=${fileType}&searchTearms=${searchTearms}`;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;

        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getFilterAllFolderAndFile ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${this.serviceFileName} getFilterAllFolderAndFile`);
            throw new Error(
                `Error ${this.serviceFileName} getFilterAllFolderAndFile`,
            );
        }
    }

    //un archivo por id
    public async getById(imagesId: string) {
        let httpclient = new TPHTTPService();
        let url: string;
        url = `/images/${imagesId}`;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getFilterAllFolderAndFile ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${this.serviceFileName} getFilterAllFolderAndFile`);
            throw new Error(
                `Error ${this.serviceFileName} getFilterAllFolderAndFile`,
            );
        }
    }

    //un archivo por nombre
    public async getByName(name: string, fileType: string, parentId: string) {
        let httpclient = new TPHTTPService();
        let url: string;
        url = `${TPGlobal.baseAPITenant}/{tenantIdHex}/images?parentId=${parentId}&fileType=${fileType}`;

        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let response: HTTPResponse;
        try {
            response = await httpclient.getData(
                url,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getFilterAllFolderAndFile ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${this.serviceFileName} getFilterAllFolderAndFile`);
            throw new Error(
                `Error ${this.serviceFileName} getFilterAllFolderAndFile`,
            );
        }
    }

    //un archivo por nombre
    public async getDisableById(file: Data) {
        let httpclient = new TPHTTPService();
        let url: string;
        url = `${TPGlobal.baseAPITenant}/{tenantIdHex}/`;

        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;

        let response: HTTPResponse;
        try {
            response = await httpclient.putData(
                finalURL,
                file,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getFilterAllFolderAndFile ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${this.serviceFileName} getFilterAllFolderAndFile`);
            throw new Error(
                `Error ${this.serviceFileName} getFilterAllFolderAndFile`,
            );
        }
    }

    public async insertFolder(folder: InserFolder, showPositiveMessage: boolean = true, showNegativeMessage: boolean =  true) {      
        let httpclient = new TPHTTPService();
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        let response: HTTPResponse;

        let url = `/images/folders`;
        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;

        try {
            response = await httpclient.postData(
                finalURL,
                folder,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );          
            return response;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} updateFunction ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${this.serviceFileName} updateFunction`);
            throw new Error(`Error ${this.serviceFileName} updateFunction`);
        }
    }

    public async updateFolder(data: InserFolderAndFile, showPositiveMessage: boolean = true,
        showNegativeMessage: boolean = true) {
        let httpclient = new TPHTTPService();
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        let response: HTTPResponse;

        let url = `/images`;
        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;       
        try {
            response = await httpclient.putData(
                finalURL,
                data,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );
            let temp: any = response;

            return response;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} updateFunction ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${this.serviceFileName} updateFunction`);
            throw new Error(`Error ${this.serviceFileName} updateFunction`);
        }
    }

    public async updateFile(file: Data) {
        let httpclient = new TPHTTPService();
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let url = `/images`;
        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;

        let response: HTTPResponse;
        try {
            response = await httpclient.putData(
                finalURL,
                file,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );
            return response;
        } catch (error) {          
            TPLog.Log(
                `Error ${this.serviceFileName} updateFunction ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${this.serviceFileName} updateFunction`);
            throw new Error(`Error ${this.serviceFileName} updateFunction`);
        }
    }

    public async UploadFile(selectedFile: any, parent: number, fileName: string, keywords: string, isActive: boolean) {
        const formData = new FormData();
        formData.append("File", selectedFile);
        formData.append("Parent", parent.toString());
        formData.append("FileName", fileName);
        formData.append("Keywords", keywords);
        formData.append("IsActive", isActive+"");
        formData.append("GuidUserUpload", TPGlobal.currentUserGuid);

        let httpclient = new TPHTTPService();
        let url = `/images`;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;

        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        const contentTypeHeaderIndex = headers.findIndex(
            (header) => header.key === "Content-Type",
        );
        if (contentTypeHeaderIndex !== -1) {
            headers[contentTypeHeaderIndex].value = "multipart/form-data";
        } else {
            headers.push({ key: "Content-Type", value: "multipart/form-data" });
        }

        let response: HTTPResponse;
        try {
            response = await httpclient.postData(
                finalURL,
                formData,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );
            return response;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} UploadFile ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${this.serviceFileName} UploadFile`);
            throw new Error(`Error ${this.serviceFileName} UploadFile`);
        }
    }


    public async UpdateUploadFile(selectedFile: any, id: string, fileName: string, keywords: string, isActive: boolean) {
        const formData = new FormData();
        formData.append("File", selectedFile);
        formData.append("Id", id.toString());
        formData.append("FileName", fileName);
        formData.append("Keywords", keywords);
        formData.append("IsActive", isActive + "");
        formData.append("GuidUserUpload", TPGlobal.currentUserGuid);

        let httpclient = new TPHTTPService();
        let url = `/images/file`;

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;

        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
        const contentTypeHeaderIndex = headers.findIndex(
            (header) => header.key === "Content-Type",
        );
        if (contentTypeHeaderIndex !== -1) {
            headers[contentTypeHeaderIndex].value = "multipart/form-data";
        } else {
            headers.push({ key: "Content-Type", value: "multipart/form-data" });
        }

        let response: HTTPResponse;
        try {
            response = await httpclient.putData(
                finalURL,
                formData,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );
            return response;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} UpdateUploadFile ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${this.serviceFileName} UpdateUploadFile`);
            throw new Error(`Error ${this.serviceFileName} UpdateUploadFile`);
        }
    }

    public async DeleteFileById(
        imagesId: string,
        showPositiveMessage: boolean = false,
        showNegativeMessage: boolean = true
    ) {
        let httpclient = new TPHTTPService();
        let url: string;
        url = `/images/${imagesId}`;
        let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

        let finalURL: string =
            TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;

        let response: HTTPResponse;
        try {
            response = await httpclient.deleteData(
                finalURL,
                expectedCodes,
                showPositiveMessage,
                showNegativeMessage,
                headers,
            );           
            return response.responseData;
        } catch (error) {
            TPLog.Log(
                `Error ${this.serviceFileName} getFilterAllFolderAndFile ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${this.serviceFileName} getFilterAllFolderAndFile`);
            throw new Error(
                `Error ${this.serviceFileName} getFilterAllFolderAndFile`,
            );
        }
    }
}
