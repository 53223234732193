import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";
import { TaskService } from "@/services/TaskService";
import { FC, ReactElement, useEffect, useReducer, useState } from "react";
import EmailTemplatesAdminDetail from "./EmailTemplatesAdminDetail";
import EmailTemplatesInsertUpdate from "./EmailTemplatesInsertUpdate";
import EmailTemplatesAttachment from "./EmailTemplateAttachment";
import TPHorizontalTabs from "@/components/TPTabs/TPHorizontalTabs";
import { TPPageSubTitle, TPPageTitle } from "@/components/TPPage/tpPageStyles";

type EmailTemplateContainerInsertUpdateProps = {
  mode: string;
  recordId: string;
  description?: string;
  callBackResult: Function;
  tabId: string;
  languageId: string;
};

enum asideMenuItemTypes {
  "general" = 0,
  "details" = 1,
  "previews" = 2,
  "clone" = 3,
  "attachment" = 4,
}

enum commandsEnum {
  "create_general" = 0,
  "create_details_and_change_mode" = 1,
  "set_active_general" = 2,
  "set_active_details" = 3,
  "set_preview" = 4,
  "set_active_clone" = 5,
  "set_attachment" = 6,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};
interface AsideMenuItemsModel {
  itemLabel: string;
  itemType: asideMenuItemTypes;
  isActive: boolean;
  isEnabled: boolean;
  isVisible: boolean;
  onClickHandler: Function;
}

const EmailTemplateContainerInsertUpdate: FC<
  EmailTemplateContainerInsertUpdateProps
> = ({
  mode,
  recordId,
  callBackResult,
  tabId,
  languageId,
  description,
}): ReactElement => {
  const resourceSet: string = "EmailTemplateContainerInsertUpdate";
  const [titleGeneralInformation, setTitleGeneralInformation] = useState("");
  const [titleOtherLanguages, setTitleOtherLanguages] = useState("");
  const [titleCloneEmail, setTitleCloneEmail] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [realRecordId, setRealRecordId] = useState(recordId);
  const [realRecordDescription, setRealRecordDescription] = useState("");
  const [previewLabel, setPreviewLabel] = useState<string>("");
  const [attachmentLabel, setAttachmentLabel] = useState<string>("");
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [titleLabel, setTitleLabel] = useState("");
  const [tab, setTab] = useState(0);

  const [caseNumber, setCaseNumber] = useState("");
	const [referenceId, setReferenceId] = useState('');

  const getTitle = function() {
    switch (tab) {
      case 0: return titleGeneralInformation;
      case 1: return titleOtherLanguages;
      case 2: return previewLabel;
      case 3: return attachmentLabel;
    }
    return "";
  }

  const handlePreview = (val: any) => setIsPreview(val);

  const handleCaseNumber = (value: any) => {
    setCaseNumber(value);
  };

  const loadResources = async () => {
    setTitleGeneralInformation(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "GeneralInformationTitle"
      )
    );
    setTitleOtherLanguages(await TPI18N.GetText(resourceSet, "DetailsTitle"));
    setTitleCloneEmail(await TPI18N.GetText(resourceSet, "CloneEmailTitle"));
    setAttachmentLabel(await TPI18N.GetText(resourceSet, "Attachments"));
    setTitleLabel(await TPI18N.GetText("EmailTemplatesInsertUpdateComponent", "TitleUpdateModal"));
    setPreviewLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Preview"))
    setIsReady(true);
  };
  const handleCallBackCommands = (theCommand: any) => {
    if (theCommand.result === "save_general_insert_and_change_mode") {
      let command: commandType = {
        type: commandsEnum.create_details_and_change_mode,
        payload: { recordId: theCommand.recordId },
      };
      dispatchCommand(command);
      callBackResult({
        result: "ChangeToUpdateMode",
        recordId: theCommand.recordId,
        tabId: tabId,
      });
      return;
    }

    if (theCommand.result === "save_general_insert") {
      callBackResult({ result: "OK", recordId: realRecordId });
      return;
    }
    if (theCommand.result === "save_general_update") {
      callBackResult({ result: "OK", recordId: realRecordId });
      return;
    }
    if (theCommand.result === "cancel_general") {
      callBackResult({ result: "cancel_general", recordId: realRecordId });
      return;
    }

    if (theCommand.result === "clone_email_success") {
      callBackResult({ result: "cancel_general", recordId: realRecordId });
      callBackResult({
        result: "clone_email_success",
        recordId: realRecordId,
        languageId,
      });
    }
  };

  const onGeneralAsideItemClick = () => {
    let newStateHome: Array<AsideMenuItemsModel> = [...asideMenuState];
    let itemType: number = 0;
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
      if (itemType == newStateHome[i].itemType) {
        newStateHome[i].isActive = true;
      }
    }
    let command: commandType = {
      type: commandsEnum.set_active_general,
      payload: null,
    };
    handlePreview(true);
    dispatchCommand(command);
    return newStateHome;
  };

  const onPreviewAsideItemClick = () => {
    let newStateHome: Array<AsideMenuItemsModel> = [...asideMenuState];
    let itemType: number = 2;
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
      if (itemType == newStateHome[i].itemType) {
        newStateHome[i].isActive = true;
      }
    }
    let command: commandType = {
      type: commandsEnum.set_preview,
      payload: null,
    };
    handlePreview(false);
    dispatchCommand(command);
  };

  const onAttachmentAsideItemClick = () => {
    let newStateHome: Array<AsideMenuItemsModel> = [...asideMenuState];
    let itemType: number = 2;
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
      if (itemType == newStateHome[i].itemType) {
        newStateHome[i].isActive = true;
      }
    }
    let command: commandType = {
      type: commandsEnum.set_attachment,
      payload: null,
    };
    handlePreview(false);
    dispatchCommand(command);
  };

  const onDetailsAsideItemClick = () => {
    let command: commandType = {
      type: commandsEnum.set_active_details,
      payload: null,
    };
    dispatchCommand(command);
  };

  const onCloneAsideItemClick = () => {
    let command: commandType = {
      type: commandsEnum.set_active_clone,
      payload: null,
    };
    dispatchCommand(command);
  };

  const realCreateGeneralContentCommand = (
    prevState: Array<AsideMenuItemsModel>
  ) => {
    let newStateHome = [...prevState];
    let verticalElement = {
      itemLabel: titleGeneralInformation,
      itemType: asideMenuItemTypes.general,
      isActive: true,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onGeneralAsideItemClick,
    };
    newStateHome.push(verticalElement);
    let verticalElementPreview = {
      itemLabel: previewLabel,
      itemType: asideMenuItemTypes.previews,
      isActive: false,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onPreviewAsideItemClick,
    };
    if (mode == "Update") {
      let verticalElement2 = {
        itemLabel: titleOtherLanguages,
        itemType: asideMenuItemTypes.details,
        isActive: false,
        isVisible: true,
        isEnabled: true,
        onClickHandler: onDetailsAsideItemClick,
      };
      newStateHome.push(verticalElement2, verticalElementPreview);
    }
    if (mode === "CloneEmail") {
      newStateHome[0].isActive = false;
      let verticalElement2 = {
        itemLabel: titleCloneEmail,
        itemType: asideMenuItemTypes.clone,
        isActive: true,
        isVisible: true,
        isEnabled: true,
        onClickHandler: onCloneAsideItemClick,
      };
      newStateHome.push(verticalElement2, verticalElementPreview);
    }
    let verticalElementAttachment = {
      itemLabel: attachmentLabel,
      itemType: asideMenuItemTypes.attachment,
      isActive: false,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onAttachmentAsideItemClick,
    };
    newStateHome.push(verticalElementAttachment);
    return newStateHome;
  };

  const realCreateDetailsContentCommandAndChangeMode = (
    prevState: Array<AsideMenuItemsModel>
  ) => {
    let newStateHome: Array<AsideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
    }
    let verticalElement: AsideMenuItemsModel = {
      itemLabel: titleOtherLanguages,
      itemType: asideMenuItemTypes.details,
      isActive: true,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onDetailsAsideItemClick,
    };
    newStateHome.push(verticalElement);
    return newStateHome;
  };

  const setActiveContent = (
    prevState: Array<AsideMenuItemsModel>,
    itemType: asideMenuItemTypes
  ) => {
    let newStateHome: Array<AsideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
      if (itemType == newStateHome[i].itemType) {
        newStateHome[i].isActive = true;
      }
    }
    return newStateHome;
  };

  let initialState: Array<AsideMenuItemsModel> = [];
  const [asideMenuState, dispatchCommand] = useReducer(doCommand, initialState);
  function doCommand(
    prevState: Array<AsideMenuItemsModel>,
    command: commandType
  ) {
    switch (command.type) {
      case commandsEnum.create_general:
        return realCreateGeneralContentCommand(prevState);
      case commandsEnum.create_details_and_change_mode: {
        const newRecordId: string = command.payload.recordId;
        const newRecordDescription: string = command.payload.recordDescription;
        setRealRecordId(newRecordId);
        setRealRecordDescription(newRecordDescription);
        return realCreateDetailsContentCommandAndChangeMode(prevState);
      }
      case commandsEnum.set_active_general:
        return setActiveContent(prevState, asideMenuItemTypes.general);
      case commandsEnum.set_active_details:
        return setActiveContent(prevState, asideMenuItemTypes.details);
      case commandsEnum.set_active_clone:
        return setActiveContent(prevState, asideMenuItemTypes.clone);
      case commandsEnum.set_preview:
        return setActiveContent(prevState, asideMenuItemTypes.previews);
      case commandsEnum.set_attachment:
        return setActiveContent(prevState, asideMenuItemTypes.attachment);
    }
  }

  useEffect(() => {
    if (!isReady) {
      loadResources();
    } else {
      let command: commandType = {
        type: commandsEnum.create_general,
        payload: null,
      };
      dispatchCommand(command);
    }
  }, [isReady]);

  return (
      <div className="d-flex flex-column p-3" style={{ gap: "24px" }}>
          <div className="d-flex flex-column gap-1">
            <TPPageTitle style={{ margin: 0 }}>{titleLabel} / {getTitle()}</TPPageTitle>
            {mode === "Update" && (
              <TPPageSubTitle style={{ margin: 0 }}>
                {`${recordId || referenceId}`}
              </TPPageSubTitle>
            )}
          </div>
          <TPHorizontalTabs
            activeTab={tab}
            labels={mode === "Update" ?
              [titleGeneralInformation , titleOtherLanguages, previewLabel, attachmentLabel]
              :
              [titleGeneralInformation]}
            onChange={(_, t) => {
              setIsPreview(t === 2);
              setTab(t);
            }}
          />
          <div
            style={{display: (tab === 0 || (tab === 2 && mode === "Update")) ? "block" : " none" }}
          >
            <EmailTemplatesInsertUpdate
              callBackResult={handleCallBackCommands}
              mode={mode}
              recordId={realRecordId}
              languageId={languageId}
              preview={isPreview}
              caseNumber={caseNumber}
              handleCaseNumber={handleCaseNumber}
              referenceId={referenceId}
              setReferenceId={setReferenceId}
            />
          </div>
          {mode === "Update" && (
            <>
              <div style={{ display: tab === 1 ? "block" : " none" }}>
                <EmailTemplatesAdminDetail
                  languageId={languageId}
                  recordInput={realRecordId}
                  recordDescription={realRecordDescription}
                  callBackComands={handleCallBackCommands}
                />
              </div>
              <div style={{ display: tab === 3 ? "block" : " none" }}>
                <EmailTemplatesAttachment
                  recordId={realRecordId}
                  referenceId={referenceId}
                />
              </div>
            </>
          )}
      </div>
  );
};

export default EmailTemplateContainerInsertUpdate;
