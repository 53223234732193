import NoDataImg from '@/assets/images/TPImages/tp-image-grid.png';
import allThemes from "@/assets/styles/theme";
import { TPBadge } from "@/components/TPBadge/TPBadge";
import { TPChip } from "@/components/TPChip/TPChip";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPRadio } from "@/components/TPRadio/TPRadio";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import Circle from "@/components/misc/Circle";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import TPGlobal from "@/helpers/TPGlobal";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { CaseStatus } from "@/models/Cases/CaseStatus";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
    GroupTakeNextGroupModel,
    TakeNextGroupType,
    TakeNextOrder,
    TakeNextTicket,
    UsersReadyByGroupModel,
} from "@/models/GroupTakeNext/GroupTakeNextModel";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import SearchInput from "@/modules/core/design-system/inputs/SearchInput";
import { StoreModel } from "@/redux/store";
import { CaseService } from "@/services/CaseService";
import { FollowUpService } from "@/services/FollowUpService";
import { GroupTakeNextService } from "@/services/GroupTakeNextService";
import { Tooltip } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderColumnsEnum, realFoldersTypeEnum } from "../MyTasks/MyTasks";
import GroupTakeNextCard from "./GroupTakeNextCard";
import {
    GroupTakeNextSlice,
    GroupTakeNextSliceModel,
} from "./GroupTakeNextSlice";
import "./Styles.css";
import { useGroupTakeNextLabels } from "./labelling";

const CASE_BG_COLOR = "#FBEBFF";
const CASE_ICON_COLOR = "#C30091";
const EVENT_BG_COLOR = "#E7FDF2";
const EVENT_ICON_COLOR = "#00826D";
const TAKENEXT_BG_COLOR = "#D3EAFF";
const BACKOFFICE_BG_COLOR = "#B1EFF2";
const FRONTOFFICE_BG_COLOR = "#E2D7FF";

enum TableMode {
    list,
    card,
}

const HIDDEN_COLUMNS: (keyof GroupTakeNextGroupModel)[] = [
    "groupId",
    "priorityWeight",
    "projectId",
    "projectDescription",
];

const DATA_REFRESH_DURATION = 20;
const TAKE_NEXT_DURATION = 15;
const GO_ONLINE_COOLDOWN = 2;

const GroupTakeNextComponent = function () {
    const casecontext: any = useContext(TPCaseViewerContext);
    const dispatch = useDispatch();
    const { currentCase, currentRecord, online, currentMomentId } = useSelector(
        (state: StoreModel) => state[GroupTakeNextSlice.name]
    ) as GroupTakeNextSliceModel;
    const { labels } = useGroupTakeNextLabels();

    const [loading, setLoading] = useState(false);
    const [cycle, setCycle] = useState<TakeNextGroupType>(
        TakeNextGroupType.TAKENEXT
    );
    const [order, setOrder] = useState<TakeNextOrder>(TakeNextOrder.case);
    const [query, setQuery] = useState("");
    const [tableMode, setTableMode] = useState<TableMode>(TableMode.list);
    const [pendingCases, setPendingCases] = useState<number>(0);
    const [takeNextChecked, setTakeNextChecked] = useState(true);
    const [backofficeChecked, setBackofficeChecked] = useState(true);
    const [frontofficeChecked, setFrontofficeChecked] = useState(false);

    const dataRefreshTimer = useRef(DATA_REFRESH_DURATION);
    const takeNextCooldown = useRef(TAKE_NEXT_DURATION);
    const goOnlineCooldown = useRef(GO_ONLINE_COOLDOWN);
    const [timerTrigger, setTimerTrigger] = useState(false);

    const [groups, setGroups] = useState<GroupTakeNextGroupModel[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<GroupTakeNextGroupModel[]>([]);

    const isGroupSelected = function (group: GroupTakeNextGroupModel) {
        return selectedGroups.includes(group);
    }

    const getGroupDescription = function (groupName: string, projectName?: string) {
        if (!projectName) return groupName;
        const fullName = `${groupName}${projectName && String(" / ").concat(projectName)}`;
        return fullName.length > 15 ? String(fullName).slice(0, 15).concat("...") : fullName;
    }

    const getFrontofficeGroupDescription = function (groupName: string, projectName?: string) {
        const fullName = `${labels.GroupHeaderLabel}: ${groupName}${projectName && String(" / ").concat(labels.Project, ": ", projectName)}`;
        return fullName || groupName;
    }

    const updateTimer = function (timerRef: React.MutableRefObject<number>, initialValue: number, resets?: boolean) {
        if (timerRef.current > 0) {
            timerRef.current -= 1;
        } else if (timerRef.current === 0) {
            timerRef.current = resets ? initialValue : -1;
            setTimerTrigger(!timerTrigger);
        }
    }

    const handleTakeNextQueue = function (groupId: number, recordId: number, projectId?: string) {
        if (!groupId) return;
        casecontext.showTakeNextQueue(groupId, recordId, false, projectId);
    };

    const handleCheckboxFilter = function (type: TakeNextGroupType, checked: boolean) {
        if (type === TakeNextGroupType.FRONTOFFICE) {
            setFrontofficeChecked(checked);
            setBackofficeChecked(false);
            setTakeNextChecked(false);

            setSelectedGroups(
                checked ?
                    selectedGroups.filter(g => {
                        return (checked && g?.groupType == type);
                    })
                    :
                    []
            )
        }
        if (type === TakeNextGroupType.BACKOFFICE || type === TakeNextGroupType.TAKENEXT) {
            setFrontofficeChecked(false);
            setBackofficeChecked(type === TakeNextGroupType.BACKOFFICE ? checked : backofficeChecked);
            setTakeNextChecked(type === TakeNextGroupType.TAKENEXT ? checked : takeNextChecked);

            setSelectedGroups(
                selectedGroups.filter(g => {
                    return g.groupType !== TakeNextGroupType.FRONTOFFICE;
                })
            )
        }
    }

    const handleGroupSelection = function (group: GroupTakeNextGroupModel, checked: boolean) {
        if (checked && canSelect(group.groupType as TakeNextGroupType)) {
            setSelectedGroups([...selectedGroups, group]);
        } else {
            setSelectedGroups(selectedGroups.filter(g => g !== group));
        }
    }

    useEffect(() => {
        if (!backofficeChecked && !takeNextChecked) setSelectedGroups([]);
    }, [backofficeChecked, takeNextChecked])

    const canSelect = function (type: TakeNextGroupType) {
        if ((type === TakeNextGroupType.BACKOFFICE || type === TakeNextGroupType.TAKENEXT) &&
            (backofficeChecked || takeNextChecked)) return true;
        if (type === TakeNextGroupType.FRONTOFFICE &&
            frontofficeChecked) return true;
        return false;
    }

    const getGroups = async function () {
        if (loading) return;
        setLoading(true);
        const gtnServiceInstance = new GroupTakeNextService();
        gtnServiceInstance.getTakeNext(false, true, [200, 404])
            .then((newGroups) => {

                let temp = newGroups
                    .filter(g => (TPGlobal.eventsManagerActive == 1) ? true
                        : g.groupType === TakeNextGroupType.TAKENEXT)
                    .map(g => {
                        const oldGroup = groups.find(old => old.groupId === g.groupId)
                        if (oldGroup) {
                            return Object.assign(oldGroup, g);
                        } else {
                            return {
                                groupId: String(g.groupId),
                                description: g.description,
                                ticket:
                                    g.groupType === TakeNextGroupType.FRONTOFFICE ||
                                        g.groupType === TakeNextGroupType.BACKOFFICE
                                        ? TakeNextTicket.event : TakeNextTicket.case,
                                groupType: g.groupType,
                                priorityWeight: g.priorityWeight,
                                ready: g.ready,
                                processing: g.processing,
                                inQueue: g.inQueue,
                                available: false,
                                start: null,
                                projectDescription: g.projectDescription,
                                projectId: g.projectId,
                            } as GroupTakeNextGroupModel
                        }
                    });
                    if (!frontofficeChecked) temp = temp.filter(s => s.groupType != TakeNextGroupType.FRONTOFFICE);
                    if (!backofficeChecked) temp = temp.filter(s => s.groupType != TakeNextGroupType.BACKOFFICE);
                    if (!takeNextChecked) temp = temp.filter(s => s.groupType != TakeNextGroupType.TAKENEXT);
                    setGroups(temp);
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    };

    const getPendingCases = async function () {
        const followUpServiceInstance = new FollowUpService();
        followUpServiceInstance
            .getFollowUpCasesByFolderAndFilter(
                {
                    followUpType: realFoldersTypeEnum.PendingCases,
                    userGuid: TPGlobal.currentUserGuid,
                    startRecord: 1,
                    endRecord: 999,
                    orderedColumn: orderColumnsEnum.CASENUMBER,
                    orderDirection: "asc",
                    tempTaskSearchFilter: [],
                },
                false,
                false,
                [200],
            )
            .then((response) => {
                if (response) setPendingCases(response.length);
            })
            .catch((error) => console.error(error))
    }

    const resetTakeNext = function () {
        dispatch(GroupTakeNextSlice.actions.setCurrentCase(-1));
        takeNextCooldown.current = TAKE_NEXT_DURATION;
        setTimerTrigger(!timerTrigger);
    }

    const fishCase = async function () {
        if ((currentCase > 0) || (currentRecord > 0)) return;
        if (selectedGroups.length > 0) {
            GroupTakeNextService.fishCase(
                {
                    orderType: order,
                    usersReadyByGroup: selectedGroups.map((group) => {
                        return {
                            groupId: Number(group.groupId),
                            usersReady: isGroupSelected(group) && online ? 1 : 0,
                        } as UsersReadyByGroupModel;
                    }),
                    userId: TPGlobal.currentUserGuid,
                },
                false, true, [200])
                .then((response) => {
                    setCycle(TakeNextGroupType.BACKOFFICE);
                    if (response && Number(response.caseId) > 0) {
                        dispatch(GroupTakeNextSlice.actions.setCurrentCase(Number(response.caseId)));
                        dispatch(GroupTakeNextSlice.actions.setCurrentRecord(-1));
                        casecontext.showTakeNextCase(response, resetTakeNext);
                        setTimerTrigger(!timerTrigger);
                    }
                })
                .catch((error) => console.error(error));
        }
    }

    const fishRecord = async function () {
        if ((currentCase > 0) || (currentRecord > 0)) return;
        const takeNextServiceInstance = new GroupTakeNextService();
        takeNextServiceInstance.getRecord({
            orderType: order,
            userId: TPGlobal.currentUserGuid,
            usersReadyByGroup: selectedGroups.map((group) => {
                return {
                    groupId: Number(group.groupId),
                    usersReady: isGroupSelected(group) && online ? 1 : 0,
                };
            })
        }, false, true, [200, 404])
            .then(response => {
                setCycle(TakeNextGroupType.TAKENEXT);
                if (response && Number(response.caseId) > 0) {
                    dispatch(GroupTakeNextSlice.actions.setCurrentRecord(Number(response.caseId)));
                    dispatch(GroupTakeNextSlice.actions.setCurrentCase(-1));
                    handleTakeNextQueue(Number(response.userId), Number(response.caseId));
                    setTimerTrigger(!timerTrigger);
                }
            })
            .catch(err => console.error(err))
    }

    const insertStatistics = function () {
        GroupTakeNextService.insertStatistics(
            currentMomentId,
            {
                isReady: online,
                userId: TPGlobal.currentUserGuid,
                usersReadyByGroup: selectedGroups.map((group) => {
                    return {
                        groupId: Number(group.groupId),
                        usersReady: isGroupSelected(group) && online ? 1 : 0,
                    };
                }),
            },
            false, true, [200]);
    }

    const keepAlive = function () {
        GroupTakeNextService.keepAlive(currentMomentId, false, false, [200]);
        if (currentCase > 0) {
            const caseServiceInstance = new CaseService();
            caseServiceInstance
                .getCaseInfoByCaseNumber(currentCase, false, true, [200])
                .then((caseData) => {
                    if (caseData.caseStatusId == CaseStatus.SO) {
                        resetTakeNext();
                    }
                })
                .catch((error) => console.error(error));
        }
    }

    const insertMoment = function () {
        GroupTakeNextService.insertMoment(
            {
                userId: TPGlobal.currentUserGuid,
                sortOrder: "case",
                isReady: online,
            },
            false, true, [200],
        )
            .then((response) => {
                if (response)
                    dispatch(GroupTakeNextSlice.actions.setCurrentMomentId(response.keyList[0].value || currentMomentId))
            })
            .catch((error) => console.error(error));
    }

    const refreshData = function () {
        getGroups();
        getPendingCases();
    }

    const toggleOnline = function () {
        if (!online) {
            dispatch(GroupTakeNextSlice.actions.setOnline(true));
        } else {
            dispatch(GroupTakeNextSlice.actions.setOnline(false));
            dispatch(GroupTakeNextSlice.actions.setCurrentCase(-1));
            dispatch(GroupTakeNextSlice.actions.setCurrentRecord(-1));
        }
        goOnlineCooldown.current = 2;
        setTimerTrigger(!timerTrigger);
    }

    const fish = function () {
        if (takeNextCooldown.current === TAKE_NEXT_DURATION) {
            if ((selectedGroups.length > 0) && ((currentCase < 0) && (currentRecord < 0)) && online && !frontofficeChecked) {
                if (cycle == TakeNextGroupType.TAKENEXT) {
                    fishCase();
                } else if (cycle == TakeNextGroupType.BACKOFFICE) {
                    fishRecord();
                }
            }
        }
    }

    useEffect(() => {
        getGroups();
    }, [frontofficeChecked, backofficeChecked, takeNextChecked]);

    useEffect(() => {
        if (((currentCase > 0) || (currentRecord > 0)) && takeNextCooldown.current === TAKE_NEXT_DURATION) {
            keepAlive();
        }

        fish();

        if (dataRefreshTimer.current === DATA_REFRESH_DURATION) {
            refreshData();
            if (online) keepAlive();
        }

        const intervalId = setInterval(() => {
            updateTimer(dataRefreshTimer, DATA_REFRESH_DURATION, true);
            updateTimer(takeNextCooldown, TAKE_NEXT_DURATION, true);
            updateTimer(goOnlineCooldown, GO_ONLINE_COOLDOWN);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timerTrigger])

    useEffect(() => {
        insertStatistics();
    }, [online])

    useEffect(() => {
        insertMoment();
        refreshData();
        resetTakeNext();
        dispatch(GroupTakeNextSlice.actions.setCurrentRecord(-1));
        dispatch(GroupTakeNextSlice.actions.setOnline(false));
    }, [])

    return (
        <>
            <ContentVerticalNoTabsStyled>
                <div id="group-take-next" className="container-fluid">
                    <TPLoadingOverlay active={loading}>
                        <TPPageTitle id="GTN-page-title">{labels.PageTitle}</TPPageTitle>
                        <div className="master-container">
                            <div id="GTN-header">
                                <div id="GTN-info">
                                    <TPIcon
                                        iconType={TPIconTypes.alert}
                                        style={{
                                            fontSize: "20px",
                                            color: allThemes.base.alertBlue,
                                        }}
                                    />
                                    <label>{labels.InfoText}</label>
                                </div>
                                <div id="GTN-status-toggle-container">
                                    <button
                                        type="button"
                                        id="GTN-status-select"
                                        style={{
                                            backgroundColor: online
                                                ? allThemes.base.trueGreen
                                                : "#E6E6E6",
                                        }}
                                        className="btn btn-warning"
                                        onClick={() => toggleOnline()}
                                        disabled={selectedGroups.length == 0 || goOnlineCooldown.current > 1}
                                    >
                                        <TPIcon
                                            iconType={TPIconTypes.circle}
                                            style={{
                                                fontSize: "12px",
                                                color: online ? allThemes.base.successGreen : "#989898",
                                            }}
                                        />
                                        {online ? labels.OnlineLabel : labels.GoOnlineLabel}
                                    </button>
                                </div>
                            </div>
                            <div id="GTN-main-controls">
                                <div id="GTN-main-controls-left">
                                    <div className="view-buttons">
                                        <button
                                            id="card-view-button"
                                            className="view-toggle"
                                            type="button"
                                            style={{
                                                backgroundColor: tableMode == TableMode.card ? allThemes.base.purplePrimary : '#F4F4F4',
                                                color: tableMode == TableMode.card ? "white" : "black",
                                            }}
                                            onClick={() => setTableMode(TableMode.card)}
                                        >
                                            <div>
                                                <TPIcon iconType={TPIconTypes.cardsView} style={{ fontSize: '16px' }} />
                                            </div>
                                        </button>
                                        <button
                                            id="list-view-button"
                                            className="view-toggle"
                                            type="button"
                                            style={{
                                                backgroundColor: tableMode == TableMode.list ? allThemes.base.purplePrimary : '#F4F4F4',
                                                color: tableMode == TableMode.list ? "white" : "black",
                                            }}
                                            onClick={() => setTableMode(TableMode.list)}
                                        >
                                            <div style={{ width: '16px', height: '16px' }}>
                                                <TPIcon iconType={TPIconTypes.tableView} style={{ fontSize: '16px' }} />
                                            </div>
                                        </button>
                                    </div>
                                    {takeNextChecked && (
                                        <div className="GTN-radio-group">
                                            <TPRadio
                                                id="GTN-radio-date"
                                                label={labels.OrderByDateLabel}
                                                checked={order == TakeNextOrder.case}
                                                onClick={() => setOrder(TakeNextOrder.case)}
                                            />
                                            <TPRadio
                                                id="GTN-radio-task"
                                                label={labels.OrderByTaskLabel}
                                                checked={order == TakeNextOrder.task}
                                                onClick={() => setOrder(TakeNextOrder.task)}
                                            />
                                        </div>)}
                                </div>
                                <div id="GTN-main-controls-right">
                                    <button
                                        id="GTN-pending-cases-button"
                                        type="button"
                                        onClick={() => casecontext.showMyTasks(labels.MyTasks)}
                                    >
                                        <u>{labels.PendingCasesLabel}</u>
                                    </button>
                                    <TPBadge
                                        label={pendingCases}
                                        backgroundColor={allThemes.base.badgePink}
                                        accentColor="white"
                                    />
                                </div>
                            </div>
                            <div id="GTN-table-container">
                                {tableMode === TableMode.list && (
                                    <DynamicTable
                                        data={groups}
                                        hiddenColumns={HIDDEN_COLUMNS}
                                        persistSelectionAfterFilterChange
                                        selectable
                                        selectedValues={groups.filter(g => selectedGroups.includes(g))}
                                        columnNames={{
                                            description: labels.GroupHeaderLabel,
                                            priorityWeight: labels.PriorityHeaderLabel,
                                            ready: labels.ReadyHeaderLabel,
                                            processing: labels.ProcessingHeaderLabel,
                                            inQueue: labels.InQueueHeaderLabel,
                                            available: labels.ReadyNotReadyHeaderLabel,
                                            groupType: labels.TypeHeaderLabel,
                                            ticket: labels.Ticket,
                                            start: labels.StartLabel
                                        }}
                                        onSelectionChange={newSelection => {
                                            setSelectedGroups(newSelection)
                                        }
                                        }
                                        headerAlignment={{
                                            start: 'right'
                                        }}
                                        withPreferences
                                        hideExport
                                        onIconClicked={(icon) => {
                                            if (icon == TPIconTypes.loop || icon == TPIconTypes.refresh) refreshData();
                                        }}
                                        hideControls={online}
                                        isHeaderCheckboxDisabled={online || (!backofficeChecked && !takeNextChecked && !frontofficeChecked)}
                                        disableSelectionCheckbox={(group) => online || !canSelect(group.groupType as TakeNextGroupType)}
                                        additionalCheckboxes={
                                            (TPGlobal.eventsManagerActive == 1) ? [
                                                {
                                                    checked: (TPGlobal.eventsManagerActive == 1)? takeNextChecked:(TPGlobal.eventsManagerActive == 0),
                                                    key: 'take_next',
                                                    label: labels.TakeNextLabel,
                                                    onChange: (e) =>   handleCheckboxFilter(TakeNextGroupType.TAKENEXT, e.target.checked)
                                                },
                                                {
                                                    checked: backofficeChecked,
                                                    key: 'backoffice',
                                                    label: labels.BackOfficeLabel,
                                                    onChange: (e) => handleCheckboxFilter(TakeNextGroupType.BACKOFFICE, e.target.checked)
                                                },
                                                {
                                                    checked: frontofficeChecked,
                                                    key: 'frontoffice',
                                                    label: labels.FrontOfficeLabel,
                                                    onChange: (e) => handleCheckboxFilter(TakeNextGroupType.FRONTOFFICE, e.target.checked)
                                                },
                                            ]
                                                :
                                                [
                                                    {
                                                        checked: takeNextChecked,
                                                        key: 'take_next',
                                                        label: labels.TakeNextLabel,
                                                        onChange: (e) => handleCheckboxFilter(TakeNextGroupType.TAKENEXT, true)
                                                    }
                                                ]
                                        }
                                        columnStyles={{
                                            description: (item) => {
                                                return item.item.projectId ? (
                                                    <Tooltip title={getFrontofficeGroupDescription(item.item.description, item.item.projectDescription)}>
                                                        <div>
                                                            {getGroupDescription(item.item.description, item.item.projectDescription)}
                                                        </div>
                                                    </Tooltip>
                                                ) : (
                                                    <div>{getGroupDescription(item.item.description)}</div>
                                                )
                                            },
                                            ticket: (row) => (
                                                <TPChip
                                                    backgroundColor={row.item.ticket == TakeNextTicket.case ?
                                                        CASE_BG_COLOR : EVENT_BG_COLOR}
                                                    label={
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                                            <Circle
                                                                backgroundColor={row.item.ticket == TakeNextTicket.case ?
                                                                    CASE_ICON_COLOR : EVENT_ICON_COLOR}
                                                            />
                                                            <label>
                                                                {row.item.ticket == TakeNextTicket.case ?
                                                                    labels.case : labels.event}
                                                            </label>
                                                        </div>}
                                                />
                                            ),
                                            groupType: (row) => {
                                                let bgColor = "";
                                                let label = "";
                                                switch (row.item.groupType) {
                                                    case TakeNextGroupType.TAKENEXT:
                                                        bgColor = TAKENEXT_BG_COLOR;
                                                        label = labels.TakeNextLabel;
                                                        break;
                                                    case TakeNextGroupType.FRONTOFFICE:
                                                        bgColor = FRONTOFFICE_BG_COLOR;
                                                        label = labels.FrontOfficeLabel;
                                                        break;
                                                    case TakeNextGroupType.BACKOFFICE:
                                                        bgColor = BACKOFFICE_BG_COLOR;
                                                        label = labels.BackOfficeLabel;
                                                        break;
                                                }
                                                return (
                                                    <TPChip
                                                        backgroundColor={bgColor}
                                                        label={label}
                                                    />
                                                )
                                            },
                                            available: (item, value) => (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: isGroupSelected(item.item) && online ?
                                                                allThemes.base.trueGreen : allThemes.base.falseRed,
                                                            borderRadius: "50%",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: 'center',
                                                            width: '32px',
                                                            height: '32px'
                                                        }}
                                                    >
                                                        <TPIcon
                                                            style={{ fontSize: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                            iconType={isGroupSelected(item.item) && online ?
                                                                TPIconTypes.circleCheckOutline : TPIconTypes.warningCircleOutline}
                                                        />
                                                    </div>
                                                    {isGroupSelected(item.item) && online ?
                                                        labels.Available : labels.NotAvailable}
                                                </div>
                                            ),
                                            start: (item) => (
                                                <>
                                                    {item.item.groupType == TakeNextGroupType.FRONTOFFICE &&
                                                        <button
                                                            onClick={() => handleTakeNextQueue(Number(item.item.groupId), -1, item.item.projectId)}
                                                            disabled={!(isGroupSelected(item.item) && online)}
                                                            style={{
                                                                border: 'none',
                                                                background: 'none',
                                                                width: 'min-content',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                color: isGroupSelected(item.item) && online ? allThemes.base.purplePrimary : '#BFBFBF',
                                                                cursor: isGroupSelected(item.item) && online ? "pointer" : "default",
                                                            }}>
                                                            <TPIcon
                                                                iconType={TPIconTypes.play}
                                                                style={{ cursor: isGroupSelected(item.item) && online ? "pointer" : "default" }}
                                                            />
                                                        </button>}
                                                </>
                                            )
                                        }}
                                    />
                                )}
                                {tableMode == TableMode.card &&
                                    <div style={{ maxWidth: "98%", display: "flex", flexDirection: "column", gap: "16px" }}>
                                        <div id='GTN-card-view-controls' className="GTN-lower-controls">
                                            <div>
                                                <SearchInput
                                                    placeholder={labels.Search}
                                                    onChange={(query) => setQuery(query)}
                                                />
                                            </div>
                                            <div className="GTN-lower-controls-right">
                                                <div className="GTN-checkbox-group">
                                                    {TPGlobal.eventsManagerActive &&
                                                        <>
                                                            <TPCheckBox
                                                                checked={takeNextChecked}
                                                                labelText={labels.TakeNextLabel}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                    handleCheckboxFilter(TakeNextGroupType.TAKENEXT, e.target.checked)}
                                                            />
                                                            <TPCheckBox
                                                                checked={backofficeChecked}
                                                                labelText={labels.BackOfficeLabel}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                    handleCheckboxFilter(TakeNextGroupType.BACKOFFICE, e.target.checked)}
                                                            />
                                                            <TPCheckBox
                                                                checked={frontofficeChecked}
                                                                labelText={labels.FrontOfficeLabel}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                    handleCheckboxFilter(TakeNextGroupType.FRONTOFFICE, e.target.checked)}
                                                            />
                                                        </>}
                                                    <button
                                                        type="button"
                                                        style={{
                                                            border: 'none',
                                                            background: 'none'
                                                        }}
                                                        onClick={() => refreshData()}
                                                    >
                                                        <TPIcon iconType={TPIconTypes.loop} style={{ fontSize: '22px' }} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {groups.filter(g => g.description.toLowerCase().includes(query.trim().toLowerCase())).length > 0 ? (
                                            <>
                                                <TPCheckBox
                                                    checkboxStyle={{ backgroundColor: online && '#D3D3D3', borderColor: online && '#7b7b7b' }}
                                                    disabled={online || (!backofficeChecked && !takeNextChecked && !frontofficeChecked)}
                                                    checked={(selectedGroups.length == groups.filter(g => canSelect(g.groupType as TakeNextGroupType)).length) &&
                                                        selectedGroups.length > 0}
                                                    labelText={labels.SelectAll}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        e.target.checked ?
                                                            setSelectedGroups(groups.filter(g => canSelect(g.groupType as TakeNextGroupType)))
                                                            : setSelectedGroups([])}
                                                />
                                                <div className='GTN-card-table'>
                                                    {groups.filter(g => g.description.toLowerCase().includes(query.trim().toLowerCase())).map(group => (
                                                        <GroupTakeNextCard
                                                            onPlayClick={() => handleTakeNextQueue(Number(group.groupId), -1, group.projectId)}
                                                            isPlayEnabled={online && (isGroupSelected(group))}
                                                            isUserOnline={online}
                                                            selectionDisabled={
                                                                (
                                                                    (group.groupType == TakeNextGroupType.BACKOFFICE && !(backofficeChecked || takeNextChecked)) ||
                                                                    (group.groupType == TakeNextGroupType.FRONTOFFICE && !frontofficeChecked) ||
                                                                    (group.groupType == TakeNextGroupType.TAKENEXT && !(backofficeChecked || takeNextChecked))
                                                                )
                                                                ||
                                                                online
                                                            }
                                                            checked={selectedGroups.includes(group) && canSelect(group.groupType as TakeNextGroupType)}
                                                            item={group}
                                                            onCheck={(checked) => handleGroupSelection(group, checked)}
                                                        />))}
                                                </div>
                                            </>
                                        ) : (
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "1.5em", height: "50vh" }}>
                                                <img src={NoDataImg}></img>
                                                <p style={{ fontSize: "20px" }}>{labels.NoResultsLabel}</p>
                                            </div>
                                        )}
                                    </div>}
                            </div>
                        </div>
                    </TPLoadingOverlay>
                </div>
            </ContentVerticalNoTabsStyled>
        </>
    );
};

export default GroupTakeNextComponent;
