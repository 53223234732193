import TPGlobal from "@/helpers/TPGlobal";
import { ComplementsRenderTP, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPI18N } from "@/services/I18nService";
import React, { useState, useImperativeHandle, useEffect } from "react";
import TPSelect from "../bootstrap/forms/select/TPSelect";
import TPTextBox from "../bootstrap/forms/textbox/TPTextBox";
import { TPAddtionalDataUIModes } from "./TPAdditionalDataUImodes";

export type TPAdditionalDataNumericListProps = {
    idControl: string;
    modeUI: TPAddtionalDataUIModes;
    labelText: string;
    labelStyles?: Array<TPKeyValue>;
    isMandatory: boolean;
    defaultValue: string;
    onValueChange?: Function | null;
    initValue: string;
    endValue: string;
    complementsRenderTP?: ComplementsRenderTP;
};

const TPAdditionalDataNumericList = React.forwardRef(
    (
        {
            idControl,
            modeUI,
            labelText,
            labelStyles = [],
            isMandatory,
            defaultValue,
            onValueChange = null,
            initValue,
            endValue,
            complementsRenderTP
        }: TPAdditionalDataNumericListProps,
        ref,
    ) => {
        //#region  Init
        //state
        const [selectedValue, setSelectedValue] = useState(defaultValue);
        const [integerList, setIntegerList] = useState<Array<TPKeyValue>>([]);
        const [errorMessageValue, setErrorMessageValue] = useState("");

        //Screen resources
        const [invalidNumberErrorMessage, setInvalidNumberErrorMessage] =
            useState("");
        const [invalidCharactersErrorMessage, setInvalidCharactersErrorMessage] =
            useState("");
        const [mandatoryErrorMessage, setMandatoryErrorMessage] = useState("");
        const [invalidRangeErrorMessage, setInvalidRangeErrorMessage] =
            useState("");
        //#endregion

        const loadResourcesAndData = async () => {
            let i: number;
            let newIntegerList: Array<TPKeyValue> = [];
            let invalidRangeLabel: string;

            setInvalidNumberErrorMessage(
                await TPI18N.GetText(
                    TPGlobal.globalResourceSet,
                    "InputDTOInvalidNumber",
                ),
            );
            setInvalidCharactersErrorMessage(
                await TPI18N.GetText(
                    TPGlobal.globalResourceSet,
                    "InputDTOInvalidIdChar",
                ),
            );
            setMandatoryErrorMessage(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),
            );
            invalidRangeLabel = await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "InputDTOInvalidRange",
            );
            invalidRangeLabel = invalidRangeLabel.replace(
                "{initialvalue}",
                initValue.toString(),
            );
            invalidRangeLabel = invalidRangeLabel.replace(
                "{endvalue}",
                endValue.toString(),
            );
            setInvalidRangeErrorMessage(invalidRangeLabel);

            //select values
            handleRankChange();

            if (selectedValue == null) {
                setSelectedValue("");
            }
        };



        useImperativeHandle(ref, () => ({
            getValueFromParent() {
                return selectedValue;
            },
            validateFromParent() {
                if (isMandatory && (!selectedValue || selectedValue.trim() === "")) {
                    setErrorMessageValue(mandatoryErrorMessage);
                    return false;
                }
                if (selectedValue && selectedValue.trim() !== "") {
                    if (selectedValue !== TPGlobal.TPSanitize(selectedValue)) {
                        setErrorMessageValue(invalidCharactersErrorMessage);
                        return false;
                    }
                    if (isNaN(+selectedValue)) {
                        setErrorMessageValue(invalidNumberErrorMessage);
                        return false;
                    }
                    if (
                        parseInt(selectedValue, 10) < parseInt(initValue, 10) ||
                        parseInt(selectedValue, 10) > parseInt(endValue, 10)
                    ) {
                        setErrorMessageValue(invalidRangeErrorMessage);
                        return false;
                    }
                }

                return true;
            },
        }));

        const handlerOnSelectedChange = (e: any) => {
            setErrorMessageValue("");
            setSelectedValue(e.target.value);
            if (onValueChange) {
                onValueChange(e.target.value, idControl);
            }
        };

        const handleRankChange = () => {
            let i: number;
            let newIntegerList: Array<TPKeyValue> = [];
            for (i = parseInt(initValue, 10); i <= parseInt(endValue, 10); i++) {
                let keyvalue: TPKeyValue = {
                    key: i.toString(),
                    value: i.toString(),
                };
                newIntegerList.push(keyvalue);
            }
            let intitialKeyValue: TPKeyValue = { key: "", value: "--" };

            newIntegerList.unshift(intitialKeyValue);
            setIntegerList(newIntegerList);
        };

        const renderCollectMode = () => {
            let labelStyle: any = null;
            let i: number;
            for (i = 0; i <= labelStyles.length - 1; i++) {
                if (labelStyle == null) {
                    labelStyle = {};
                }
                labelStyle[labelStyles[i].key] = labelStyles[i].value;
            }

            return (
                //todo labelstyles
                //todo horizontal
                <div className="form-group">
                    <TPSelect
                        id="IdSelect"
                        onChange={handlerOnSelectedChange}
                        dataSource={integerList}
                        value={selectedValue}
                        isMandatory={isMandatory}
                        labelText={labelText}
                        errorMessage={errorMessageValue}
                        placeholder={complementsRenderTP?.placeholder}
                    />
                </div>
            );
        };

        const renderViewMode1 = () => {
            let labelStyle: any = null;
            let i: number;
            for (i = 0; i <= labelStyles.length - 1; i++) {
                if (labelStyle == null) {
                    labelStyle = {};
                }
                labelStyle[labelStyles[i].key] = labelStyles[i].value;
            }

            return (
                //todo labelstyles
                //todo horizontal
                <div className="form-group">
                    <TPTextBox
                        id="IdTextBox"
                        labelText={labelText}
                        isMandatory={isMandatory}
                        value={selectedValue}
                        onChange={() => {
                            TPGlobal.foo();
                        }}
                        disabled={true}
                        placeholder={complementsRenderTP?.placeholder}
                    />
                </div>
            );
        };

        const renderViewMode2 = () => {
            let labelStyle: any = null;
            let i: number;
            for (i = 0; i <= labelStyles.length - 1; i++) {
                if (labelStyle == null) {
                    labelStyle = {};
                }
                labelStyle[labelStyles[i].key] = labelStyles[i].value;
            }

            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col">
                            <span
                                className="tpbold"
                                style={labelStyle}
                            >{`${labelText}: `}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span>{selectedValue}</span>
                        </div>
                    </div>
                </React.Fragment>
            );
        };

        useEffect(() => {
            //load validation error messages
            loadResourcesAndData();
        }, []);

        useEffect(() => {
            setSelectedValue(defaultValue);
        }, [defaultValue]);

         useEffect(() => {
             handleRankChange();
         }, [initValue, endValue]);

        return (
            <>
                {modeUI == TPAddtionalDataUIModes.Collect
                    ? renderCollectMode()
                    : modeUI == TPAddtionalDataUIModes.View1
                        ? renderViewMode1()
                        : renderViewMode2()}
            </>
        );
    },
);

export default TPAdditionalDataNumericList;
