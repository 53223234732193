import questionImg from "@/assets/images/question.png";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { FC, ReactElement } from "react";

export type TPModalQuestionState = {
  isShown: boolean;
  callBackData: any;
};

type TPModalQuestionProps = {
  title: string;
  question: string;
  yesLabel: string;
  noLabel: string;
  isShown: boolean;
  callBackData: any;
  callBackAnswer: Function;
  id?: string;
};

const TPModalQuestion: FC<TPModalQuestionProps> = ({
  title,
  question,
  yesLabel,
  noLabel,
  isShown,
  callBackData,
  callBackAnswer,
  id = "",
}): ReactElement => {
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;
  if (isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }
  return (
    <>
      <div
        id={id}
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{title}</h5>
              <TPButton
                id="close-md"
                type={TPButtonTypes.empty}
                onClick={() => callBackAnswer(false, callBackData)}
                className={"text-end"}
              >
                X
              </TPButton>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-3">
                  <img src={questionImg} width={80}></img>
                </div>
                <div className="col">
                  <p className="tpbold">{question}</p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <TPButton
                id="cancel-action"
                type={TPButtonTypes.link}
                onClick={() => callBackAnswer(false, callBackData)}
                isDesignSystem
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  backgroundColor: "white",
                  color: "purple",
                }}
              >
                {noLabel}
              </TPButton>
              <TPButton
                id="confirm-action"
                type={TPButtonTypes.primary}
                onClick={() => callBackAnswer(true, callBackData)}
                isDesignSystem
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {yesLabel}
              </TPButton>
            </div>
          </div>
        </div>
      </div>

      <div className={backdropClass} style={backdropClass != "" ? {display: 'block'} : {display: 'none'}}></div>
    </>
  );
};

export default TPModalQuestion;
