import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";

export type ProfilesInputDTO = {
  id: string;
  profileId_Base: string;
  guid: string;
  description: string;
  defaultMenuItemId: string;
  urlLink: string
  isActive: boolean;
  requireSupplyStationId: boolean;
  nameLocalizedValues: Array<RecordLocalizedModel>;
};

export class ProfilesInputDTOValidator extends Validator<ProfilesInputDTO> {
  constructor() {
    super();

    const resourceSet = "ProfilesInsertUpdateComponent";

    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOProfileIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidProfileId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidProfileIdLength");

      this.ruleFor("profileId_Base")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOProfileIdBaseEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((profileId_Base) => {
          return TPGlobal.TPSanitize(profileId_Base) == profileId_Base;
        })
        .withMessage(resourceSet + "|InputDTOInvalidProfileIdBaseLength")
        .maxLength(100)
        .withMessage(resourceSet + "|InputDTOInvalidProfileIdBase");
      this.ruleFor("urlLink")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTOUrlLink_MEITEmpty")
          .must((id) => {
            return TPGlobal.TPSanitize(id) == id;
          })
          .withMessage(resourceSet + "|InputDTOInvalidUrlLink_MEIT");
    } catch (error) {}
  }
}
