import CheckIcon from "@/assets/images/TPIcons/CheckIcon";
import WarnIcon from "@/assets/images/TPIcons/WarnIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import {
    ProjectDetailEnum as e,
    ProjectDetailModel,
    ProjectModel,
    ProjectType,
    TableProjectDetailModel
} from "@/models/Project/Projects";
import DragFileModal from "@/modules/core/design-system/drag-and-drop/DragFileModal";
import { QueueAdminSlice, QueueAdminSliceModel } from "@/pages/EventsManager/QueueAdmin/QueueAdminSlice";
import ProjectDetailTable from "@/pages/Projects/ProjectDetail/ProjectDetailTable";
import { StoreModel } from "@/redux/store";
import { EventProjectService } from "@/services/EventProjectService";
import { TPI18N } from "@/services/I18nService";
import { StructureService } from "@/services/StructureService";
import { CSSProperties, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {Button} from "@mui/material";
import { setTimeout } from "timers/promises";

/**
 * STYLES
 */

const alertFrame = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    boxSizing: "border-box",
    alignItems: 'center',
    padding: '16px',
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    marginBottom: '40px',
}

const alertText = {
    marginBottom: '0px',
    fontWeight: 400,
    fontSize: '14px',
}

const styles = {
    titleAction: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
        placeContent: "center space-between",
        alignItems: "center",
        marginBottom: "30px",
    } as CSSProperties,
    column: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'column',
    } as CSSProperties,
    row: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
    } as CSSProperties,
    title: {
        margin: "0",
    } as CSSProperties,
    center: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
        placeContent: "center",
        alignItems: "center",
        gap: "20px",
    } as CSSProperties,
    filename: {
        margin: "0",
        display: "flex",
        flexDirection: "row",
        color: "#989898",
        fontSize: "14px",
    } as CSSProperties,
    input: {
        width: "250px",
    } as CSSProperties,
    newButton: {
        padding: "5px 20px",
        backgroundColor: "#A00095",
        borderRadius: 4,
        color: "white",
        fontWeight: 400,
    } as CSSProperties,
    warningFrame: {
        ...alertFrame,
        background: '#FFF7DA',
    } as CSSProperties,
    successFrame: {
        ...alertFrame,
        background: '#CEF3E5',
    } as CSSProperties,
    innerFrame: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px',
        gap: '8px',
        width: '100%',
        height: '24px',
    } as CSSProperties,
    warningText: {
        ...alertText,
        color: '#5B4900',
    } as CSSProperties,
    successText: {
        ...alertText,
        color: '#00472C',
    } as CSSProperties,
};

/**
 * STYLES END
 */

/**
 * SPECIFIC COMPONENT MODELS
 */
/**
 * child component events
 */
export interface ProjectDetailEvents {
    /**
     * allows you to reload the table detail data
     */
    load: () => void;
}

/**
 * properties that the child component needs
 */
export interface ProjectDetailProps {
    /**
     * vertical tab identifier
     */
    tabId: string;
    /**
     * project
     */
    element: ProjectModel | any;
    /**
     * vertical tab event runner
     */
    dispatch: Function;
    callBackCommands: Function;
}
/**
 * SPECIFIC COMPONENT MODELS END
 */

/**
 * component of project detail
 */
const ProjectDetail = forwardRef((
    {
        tabId,
        element,
        dispatch,
        callBackCommands
    }: ProjectDetailProps,
    ref
) => {
    /**
     * ATTRIBUTES
     */
    /**
     * Messages that are rendered in the view depending on the language
     */
    const [m, setMessages] = useState<any>({});
    /**
     * loading event
     */
    const [loading, setLoading] = useState(true);

    const [file, setFile] = useState<boolean>(false);

    const [openFileUpload, setOpenFileUpload] = useState(false);

    const [loadingHistory, setLoadingHistory] = useState(false);

    const [showSuccess, setShowSuccess] = useState(true);

    const [isToReplace, setIsToReplace] = useState(false);
    /**
     * current project
     */
    const [project, setProject] = useState<ProjectModel>(element);
    const [columnName, setColumnName] = useState<any>();
    const [templateName, setTemplateName] = useState<string>("");
    /**
     * detail of the project queues
     */
    const [dataSource, setDataSource] = useState<Array<TableProjectDetailModel>>([]);
    /**
     * filename
     */
    const [filename, setFilename] = useState<string>("");
    /**
     * overall report values
     */
    const [globalReport, setGlobalReport] = useState<ProjectDetailModel | any>();
    /**
     * queues available
     */
    const [queues, setQueues] = useState<Array<TPKeyValue>>([{ key: "", value: "Select" }]);
    /**
     * ATTRIBUTES END
     */

    const { queuesLoaded } = useSelector((s: StoreModel) => s[QueueAdminSlice.name]) as QueueAdminSliceModel;
    const queuesDispatch = useDispatch();

    useEffect(() => {
        if (!queuesLoaded) {
            loadGroups();
            loadDataSource();
            queuesDispatch(QueueAdminSlice.actions.setQueuesLoaded(true));
        }
    }, [queuesLoaded])
    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(ref, () => ({
        load() {
            loadDataSource()
        },
    } as ProjectDetailEvents));
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
        loadResources()
            .then(() => setLoading(false));
        loadProject()
        loadGroups()
        loadFilename()
        loadDataSource()
        alreadyHasFile()
    }, []);
    /**
     * event on component close
     */
    useEffect(() => () => {
        setMessages({});
        setLoading(false)
        setDataSource([])
        setFilename("")
    }, []);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * Function responsible for consulting the resources used in the component
     */
    async function loadResources() {
        const messages = { ...m };
        // title label
        messages[e.TitleLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.TitleLabel);
        // section action labels
        messages[e.ActionInputSearchLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.ActionInputSearchLabel);
        messages[e.ActionButtonLoadNewFileLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.ActionButtonLoadNewFileLabel);
        messages[e.SortByLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.SortByLabel);
        messages[e.SortByMoreEventsLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.SortByMoreEventsLabel);
        messages[e.SortByMoreCustomerExpertsReadyLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.SortByMoreCustomerExpertsReadyLabel);
        messages[e.SortByMorePendingLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.SortByMorePendingLabel);
        messages[e.SortByMoreWorkedLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.SortByMoreWorkedLabel);
        messages[e.SortByLessEventsLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.SortByLessEventsLabel);
        messages[e.SortByLessCustomerExpertsReadyLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.SortByLessCustomerExpertsReadyLabel);
        messages[e.SortByLessPendingLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.SortByLessPendingLabel);
        messages[e.SortByLessWorkedLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.SortByLessWorkedLabel);
        // global report labels
        messages[e.GlobalReportLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.GlobalReportLabel);
        messages[e.GlobalReportEventsLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.GlobalReportEventsLabel);
        messages[e.GlobalReportWorkedLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.GlobalReportWorkedLabel);
        messages[e.GlobalReportPendingLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.GlobalReportPendingLabel);
        messages[e.GlobalReportTotalQueuesLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.GlobalReportTotalQueuesLabel);
        messages[e.GlobalReportCustomerExpertsAvailableLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.GlobalReportCustomerExpertsAvailableLabel);
        // table and cards labels
        messages[e.TableParentColumnCELabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.TableParentColumnCELabel);
        messages[e.TableParentColumnTicketsLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.TableParentColumnTicketsLabel);
        messages[e.TableColumnQueueNameLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnQueueNameLabel);
        messages[e.TableColumnTotalCELabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnTotalCELabel);
        messages[e.TableColumnCEReadyLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnCEReadyLabel);
        messages[e.TableColumnOnlineLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnOnlineLabel);
        messages[e.TableColumnWorkingLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnWorkingLabel);
        messages[e.TableColumnOfflineLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnOfflineLabel);
        messages[e.TableColumnEventsLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnEventsLabel);
        messages[e.TableColumnWorkedLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnWorkedLabel);
        messages[e.TableColumnPendingLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnPendingLabel);
        messages[e.TableColumnTypeLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnTypeLabel);
        messages[e.TableColumnActionReassignCELabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnActionReassignCELabel);
        messages[e.CardTotalCELabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.CardTotalCELabel);
        messages[e.CardCEReadyLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.CardCEReadyLabel);
        // modal labels
        messages[e.ModalTitleLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.ModalTitleLabel);
        messages[e.ModalCurrentQueueLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.ModalCurrentQueueLabel);
        messages[e.ModalOption1Label] = await TPI18N.GetText(e.ProjectDetailComponent, e.ModalOption1Label);
        messages[e.ModalOption2Label] = await TPI18N.GetText(e.ProjectDetailComponent, e.ModalOption2Label);
        messages[e.ModalActionCancelLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.ModalActionCancelLabel);
        messages[e.ModalActionReassignLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.ModalActionReassignLabel);
        messages[e.ModalActionNextPageLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.ModalActionNextPageLabel);
        messages[e.ModalActionSelectAllLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.ModalActionSelectAllLabel);
        messages[e.ModalPaginationSelectAllLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.ModalPaginationSelectAllLabel);

        messages[e.loadingSuccessLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.loadingSuccessLabel);
        messages[e.loadingSuccessLinkLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.loadingSuccessLinkLabel);
        messages[e.loadingWarningLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.loadingWarningLabel);
        messages[e.loadingWarningLinkLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.loadingWarningLinkLabel);

        messages[e.currentFileLabel] = await TPI18N.GetText(e.ProjectDetailComponent, e.currentFileLabel);

        setMessages(messages)
    }

    const setupStructureDownload = async (id: string, structure: any) => {
        let data = structure;
        let templateName: string;

        if (data) {
            // Mapeo de la estructura para reemplazar el additionalDataDateFormat
            data = data.map((item: any) => {
                if (item.additionalDataDateFormat) {
                    // Buscar el formato de fecha correspondiente
                    const dateFormat = TPGlobal.dateFormatsAdditionalData.find(format => format.key === item.additionalDataDateFormat)?.value;

                    if (dateFormat) {
                        // Reemplazar el número por el formato de fecha
                        item.additionalDataDateFormat = dateFormat;
                    }
                }
                return item;
            });

            templateName = `structure-template-${id}`;
            const columnNameObject = TPGlobal.createColumnNameObject(data);
            setColumnName(columnNameObject);
            setTemplateName(templateName);
        }
    }

    function loadStructureDetails(id: string) {
        const { getStructureDetails } = new StructureService()

        getStructureDetails(id, false, true, [200, 404])
            .then(response => {
                setupStructureDownload(id, response)
            })
            .catch(error => console.error(error))
    }

    /**
     * Function responsible for consulting the project
     */
    function loadProject() {
        const { findOne } = new EventProjectService()

        findOne(project.id)
            .then(response => {
                setProject(response)
                if (response.structureId) {
                    loadStructureDetails(response.structureId)
                }
            })
            .catch(error => console.error(error))
    }



    /**
     * Function responsible for consulting the project' filename
     */
    function loadGroups() {
        const { findGroups } = new EventProjectService()
        findGroups(element.id)
            .then(response => {
                setQueues([
                    { key: "", value: "Select" },
                    ...response.map(({ id, name: value }) => ({ key: `${id}`, value }))
                ])
            })
            .catch(error => console.error(error));
    }

    /**
     * Function responsible for consulting the project's filename
     */
    function loadFilename() {
    }

    /**
     * Function responsible for consulting the records that detail the component
     */

    function alreadyHasFile() {
        const { hasFile } = new EventProjectService()

        hasFile(project.id)
            .then(response => {
                if (response[0] && response[0].fileName) {
                    setFilename(response[0].fileName)
                    setFile(true)
                    setShowSuccess(true);
                    if (response[0].status === "PR") {
                        setLoadingHistory(false)
                    } else if (response[0].status === "PE") {
                        setLoadingHistory(true)
                    }
                }
            })
    }

    const [timer, setTimer] = useState(-1);

    useEffect(() => {
        if (timer >= 0) {
            const interval = setInterval(() => {
                setTimer((prev) => prev + 1);
                alreadyHasFile();
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [timer]);

    useEffect(() => {
        if (file && loadingHistory) {
            setTimer(0);
        } else {
            setTimer(-1);
        }
    }, [file, loadingHistory]);

    function loadDataSource(sort?: string) {
        const { findDetail } = new EventProjectService()

        setShowSuccess(true)

        alreadyHasFile()

        setLoading(true)
        findDetail(project.id)
            .then(response => {
                setLoading(false)

                let d = response.map(r => ({
                    ...r,
                    reassign: "",
                } as TableProjectDetailModel));

                switch (sort) {
                    case "1":
                        d.sort(({ events: a }, { events: b }) => Number(a) < Number(b) ? 1 : Number(a) > Number(b) ? -1 : 0);
                        break;
                    case "2":
                        d.sort(({ events: a }, { events: b }) => Number(a) > Number(b) ? 1 : Number(a) < Number(b) ? -1 : 0);
                        break;
                    case "3":
                        d.sort(({ ceReady: a }, { ceReady: b }) => Number(a) < Number(b) ? 1 : Number(a) > Number(b) ? -1 : 0);
                        break;
                    case "4":
                        d.sort(({ ceReady: a }, { ceReady: b }) => Number(a) > Number(b) ? 1 : Number(a) < Number(b) ? -1 : 0);
                        break;
                    case "5":
                        d.sort(({ pending: a }, { pending: b }) => Number(a) < Number(b) ? 1 : Number(a) > Number(b) ? -1 : 0);
                        break;
                    case "6":
                        d.sort(({ pending: a }, { pending: b }) => Number(a) > Number(b) ? 1 : Number(a) < Number(b) ? -1 : 0);
                        break;
                    case "7":
                        d.sort(({ worked: a }, { worked: b }) => Number(a) < Number(b) ? 1 : Number(a) > Number(b) ? -1 : 0);
                        break;
                    case "8":
                        d.sort(({ worked: a }, { worked: b }) => Number(a) > Number(b) ? 1 : Number(a) < Number(b) ? -1 : 0);
                        break;
                    case "9":
                        d.sort(({ queueName: a }, { queueName: b }) => a > b ? 1 : a < b ? -1 : 0);
                        break;
                    case "10":
                        d.sort(({ queueName: a }, { queueName: b }) => a < b ? 1 : a > b ? -1 : 0);
                        break;
                    default:
                        break
                }

                setDataSource(d)

                const g = response.reduce(
                    (p, c) => {
                        p.totalCe = `${Number(p.totalCe) + 1}`;
                        p.ceReady = `${Number(p.ceReady) + Number(c.ceReady)}`;
                        p.online = `${Number(p.online) + Number(c.online)}`;
                        p.working = `${Number(p.working) + Number(c.working)}`;
                        p.offline = `${Number(p.offline) + Number(c.offline)}`;
                        p.events = `${Number(p.events) + Number(c.events)}`;
                        p.worked = `${Number(p.worked) + Number(c.worked)}`;
                        p.pending = `${Number(p.pending) + Number(c.pending)}`;
                        return p;
                    },
                    {
                        queueId: '0',
                        queueName: '0',
                        totalCe: '0',
                        ceReady: '0',
                        online: '0',
                        working: '0',
                        offline: '0',
                        events: '0',
                        worked: '0',
                        pending: '0',
                        type: ProjectType.FRONTOFFICE,
                    } as ProjectDetailModel
                )
                setGlobalReport(g)
            })
            .catch(error => {
                setLoading(false)
                console.error(error)
            })
    }

    /**
     * 
     */
    function onClickButtonLoadNewFileHandler() {
        isToReplaceFile()
        setOpenFileUpload(true)
    }

    function handleDragFileModalClose(status: boolean, code?: number, file?: File) {
        setOpenFileUpload(false)

        if (status && code === 200 && file) {
            setFilename(file.name)
            setFile(true)
            setLoadingHistory(true)
        }
    }

    function isToReplaceFile() {
        const { isToReplace } = new EventProjectService()

        isToReplace(project.id)
            .then(response => {
                if (response[0] && response[0].fileName) {
                    setIsToReplace(true)
                }
            })
            .catch(error => console.error(error));
    }

    

    function onRedirectToLoadHistory() {
        let command: any = {
            command: "new_horizontal_tab",
            recordId: "",
            component:
                "TPClientCloudMain.Client.Components.EventsManager.LoadHistory",
        };
        callBackCommands(command);
    }

    const handleCloseMessage = () => {
        setShowSuccess(false);
    }

    /**
     * FUNCTIONS END
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
        <>
            <DragFileModal
                status={openFileUpload}
                projectId={project.id}
                eventLoadStructureId={project.structureId || ""}
                isToReplaceFile={isToReplace}
                handleModalClose={handleDragFileModalClose}
            ></DragFileModal>
            <TPLoadingOverlay active={loading}>
                <div style={styles.column}>
                    <CIMTitleSection isCIM={file}>
                        <TPPageTitle style={styles.title}>
                            {m?.[e.TitleLabel]} {project?.name}
                        </TPPageTitle>

                        <div style={styles.center}>
                            {filename != "" && (
                                <p style={styles.filename}>
                                    {m?.[e.currentFileLabel] + " " + filename}
                                </p>
                            )}
                        </div>
                    </CIMTitleSection>

                    {file && (
                        <>
                            {loadingHistory ? (
                                <>
                                    <div style={styles.warningFrame}>
                                        <div style={styles.innerFrame}>
                                            <WarnIcon />
                                            <p style={styles.warningText}>
                                                {m?.[e.loadingWarningLabel]}
                                                <u style={{ ...styles.warningText, cursor: "pointer" }} onClick={onRedirectToLoadHistory}>{m?.[e.loadingSuccessLinkLabel]}</u>
                                            </p>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {showSuccess && (
                                        <>
                                            <div style={styles.successFrame}>
                                                <div style={styles.innerFrame}>
                                                    <CheckIcon />
                                                    <p style={styles.successText}>
                                                        {m?.[e.loadingSuccessLabel]}
                                                        <u style={{ ...styles.successText, cursor: "pointer" }} onClick={onRedirectToLoadHistory}>{m?.[e.loadingSuccessLinkLabel]}</u>
                                                    </p>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    aria-label="Close"
                                                    onClick={handleCloseMessage}
                                                ></button>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    <ProjectDetailTable
                        m={m}
                        globalReport={globalReport}
                        queues={queues}
                        dataSource={dataSource}
                        loadingHistory={loadingHistory}
                        loadDataSource={loadDataSource}
                        onClickButtonLoadNewFileHandler={onClickButtonLoadNewFileHandler}
                        structureDetails={columnName}
                        templateName={templateName}
                    />
                </div>
            </TPLoadingOverlay>
        </>
    );
})

export default ProjectDetail;
