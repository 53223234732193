import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPEditor } from "@/components/TPEditor/TPEditor";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import {
    OutboundEmailViewProps,
    OutboundForm,
    OutboundTaskLabels,
    TrackTaskBase,
} from "@/models/EmailTemplates/EmailTemplateModels";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
    MailboxDetails,
    SentimentSentence,
    TaskReportInputDTO,
    TaskSendMailReportInputDTO,
    TemplateObject,
    TemplateResponse,
} from "@/models/Task/TaskReportInputDTO";
import SearchSelect from "@/modules/core/design-system/selects/SearchSelect";
import { OptionType } from "@/modules/core/utils/search-select-styles";
import { StyledOutboundEmailTask } from "@/pages/CaseViewer/case-viewer-styles";
import { TPI18N } from "@/services/I18nService";
import { TaskService } from "@/services/TaskService";
import { UserService } from "@/services/UserService";
import { debounce } from "lodash";
import React, {
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import ComposeEmailModal from "./ComposeEmailModal";
import EmailAttachments, {
    AttachmentValues,
    FileResumed,
} from "./EmailAttachments";
import PreviewEmailModal from "./PreviewEmailModal";
import SentimentAnalysisSection, {
    Sentiment,
    SentimentsPercentage,
} from "./Sentiments/SentimentAnalysisSection";
import TPTextBoxSelectEmail from "../../../pages/EmailTemplates/Components/TPTextBoxSelectEmail";

type TranslationItems = {
    subject: string;
    body: string;
};

const OutboundEmailView: FC<OutboundEmailViewProps> = React.memo(
    ({
        inboundEmail,
        outboundData,
        taskIdToView,
        templateRequirements,
        currentTask,
        mailboxId,
        operationGuid,
        setLoading,
        handleSaveTask,
    }): ReactElement => {
        const component: string = "OutboundEmailView";

        const taskService = new TaskService();
        const taskTypeService = new UserService();

        const ignoreChangeRef = useRef(false);

        const editorIcons: TPIconTypes[] = [TPIconTypes.fullScreen];

        const textboxStyle: React.CSSProperties = {
            minHeight: "32px",
            height: "32px",
            borderRadius: "4px",
            borderColor: "#BFBFBF",
            fontSize: "14px",
        };

        const [resources, setResources] = useState<OutboundTaskLabels>(
            {} as OutboundTaskLabels
        );
        const [showComposeModal, setShowComposeModal] = useState<boolean>(false);
        const [showEmailView, setShowEmailView] = useState<boolean>(false);
        const [showReview, setShowReview] = useState<boolean>(false);
        const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
        const [resourcesLoaded, setResourcesLoaded] = useState<boolean>(false);
        const [isSentimentEnabled, setIsSentimentEnabled] =
            useState<boolean>(false);
        const [translationItems, setTranslationItems] = useState<TranslationItems>({
            subject: "",
            body: "",
        });
        const [translateLanguages, setTranslateLanguages] = useState<TPKeyValue[]>(
            []
        );
        const [templates, setTemplates] = useState<TPKeyValue[]>([]);
        const [replyBody, setReplyBody] = useState<string>("");
        const [templateBody, setTemplateBody] = useState<string>("");
        const [sentimentSentences, setSentimentSentences] = useState<
            SentimentSentence[]
        >([]);
        const [attachments, setAttachments] = useState<FileResumed[]>([]);
        const [checks, setChecks] = useState<AttachmentValues>({
            all: false,
            sendCase: false,
            sendTask: false,
            saveInCase: false,
        });
        const [id, setId] = useState<string | null>(null);

        const [outboundForm, setOutboundForm] = useState<OutboundForm>({
            isTranslate: false,
            translateLanguage: null,
            isInternal: false,
            useTemplate: false,
            templateLanguage: null,
            template: null,
            reply: null,
            isReplyAll: false,
            from: null,
            to: "",
            isCc: false,
            isBcc: false,
            cc: "",
            bcc: "",
            subject: "",
            body: "",
            styledBody: "",
        });

        const [currentEditorValue, setCurrentEditorValue] = useState(
            outboundForm.body
        );
        const [enterAValue, setEnterAValue] = useState<string>("");


        const loadUtilsResources = async () => {
            setEnterAValue(await TPI18N.GetText(TPGlobal.globalResourceSet, "EnterAValue"));
            setResources({
                translationReview: await TPI18N.GetText(component, "TranslationReview"),
                subject: await TPI18N.GetText(component, "Subject"),
                inputPH: await TPI18N.GetText(component, "InputPH"),
                selectPH: await TPI18N.GetText(component, "SelectPH"),
                editorPH: await TPI18N.GetText(component, "EditorPH"),
                message: await TPI18N.GetText(component, "Message"),
                fullView: await TPI18N.GetText(component, "FullView"),
                translateCheck: await TPI18N.GetText(component, "TranslateCheck"),
                languagePH: await TPI18N.GetText(component, "LanguagePH"),
                internal: await TPI18N.GetText(component, "Internal"),
                useTemplate: await TPI18N.GetText(component, "UseTemplate"),
                select: await TPI18N.GetText(component, "Select"),
                templateLanguagePH: await TPI18N.GetText(
                    component,
                    "TemplateLanguagePH"
                ),
                templatePH: await TPI18N.GetText(component, "TemplatePH"),
                templateNoData: await TPI18N.GetText(component, "TemplateNoData"),
                reply: await TPI18N.GetText(component, "Reply"),
                replyAll: await TPI18N.GetText(component, "ReplyAll"),
                from: await TPI18N.GetText(component, "From"),
                to: await TPI18N.GetText(component, "To"),
                cc: await TPI18N.GetText(component, "Cc"),
                bcc: await TPI18N.GetText(component, "Bcc"),
                redactHere: await TPI18N.GetText(component, "RedactHere"),
                translationPreview: await TPI18N.GetText(
                    component,
                    "TranslationPreview"
                ),
                compose: await TPI18N.GetText(component, "Compose"),
                send: await TPI18N.GetText(component, "Send"),
                reviewAndSend: await TPI18N.GetText(component, "ReviewAndSend"),
                composeModal: {
                    title: await TPI18N.GetText(component, "ComposeModalTitle"),
                    keyPoints: await TPI18N.GetText(component, "ComposeModalKeyPoints"),
                    keyPointsPH: await TPI18N.GetText(
                        component,
                        "ComposeModalKeyPointsPH"
                    ),
                    composition: await TPI18N.GetText(
                        component,
                        "ComposeModalComposition"
                    ),
                    compositionPH: await TPI18N.GetText(
                        component,
                        "ComposeModalCompositionPH"
                    ),
                    select: await TPI18N.GetText(component, "ComposeModalSelect"),
                    selectPH: await TPI18N.GetText(component, "Select"),
                    process: await TPI18N.GetText(component, "ComposeModalProcess"),
                    newComposition: await TPI18N.GetText(
                        component,
                        "ComposeModalNewComposition"
                    ),
                    newCompositionPH: await TPI18N.GetText(
                        component,
                        "ComposeModalNewCompositionPH"
                    ),
                    cancel: await TPI18N.GetText(component, "Cancel"),
                    pasteAndClose: await TPI18N.GetText(
                        component,
                        "ComposeModalPasteAndClose"
                    ),
                },
                sentiment: {
                    title: await TPI18N.GetText("SentimentAnalysis", "Title"),
                    positive: await TPI18N.GetText("SentimentAnalysis", "Positive"),
                    neutral: await TPI18N.GetText("SentimentAnalysis", "Neutral"),
                    negative: await TPI18N.GetText("SentimentAnalysis", "Negative"),
                    textAnalysis: await TPI18N.GetText(
                        "SentimentAnalysis",
                        "TextAnalysis"
                    ),
                },
                previewModal: {
                    titlePreview: await TPI18N.GetText("PreviewModal", "Title"),
                    inboundAlert: await TPI18N.GetText("PreviewModal", "InboundAlert"),
                    outboundAlert: await TPI18N.GetText("PreviewModal", "OutboundAlert"),
                    close: await TPI18N.GetText("PreviewModal", "Close"),
                    saveAndSend: await TPI18N.GetText("PreviewModal", "SaveAndSend"),
                    replace: await TPI18N.GetText("PreviewModal", "Replace"),
                    cancel: await TPI18N.GetText("PreviewModal", "Cancel"),
                },
                attachment: {
                    all: await TPI18N.GetText(component, "All"),
                    sendCase: await TPI18N.GetText(component, "SendCase"),
                    sendTask: await TPI18N.GetText(component, "SendTask"),
                    saveInCase: await TPI18N.GetText(component, "SaveInCase"),
                    title: await TPI18N.GetText(component, "AttachmentTitle"),
                    wrong: await TPI18N.GetText(component, "Wrong"),
                    noFiles: await TPI18N.GetText(component, "NoFiles"),
                    loading: await TPI18N.GetText(component, "Loading"),
                    completed: await TPI18N.GetText(component, "Completed"),
                    error: await TPI18N.GetText(component, "AttachmentError"),
                    chargeState: await TPI18N.GetText(component, "ChargeState"),
                },
            });
        };

        const handleInputChange = (
            name: string,
            value: string | boolean | TPKeyValue,
            isTranslation: boolean = false
        ) => {
            if (isTranslation) {
                setTranslationItems((prevForm) => ({
                    ...prevForm,
                    [name]: value,
                }));

                return;
            }

            setOutboundForm((prevForm) => ({
                ...prevForm,
                [name]: value,
            }));

            getFormInformationBy(name, value);
        };

        const getFormInformationBy = (
            identifier: string,
            value: string | boolean | TPKeyValue
        ): void => {
            if (isSentimentEnabled) setIsSentimentEnabled(false);

            switch (identifier) {
                case "reply":
                    handleReplyChange(value as TPKeyValue);
                    break;
                case "template":
                    handleTemplateSelected(value as TPKeyValue);
                    break;
                case "isInternal":
                    setOutboundForm((prevForm) => ({
                        ...prevForm,
                        useTemplate: false,
                        isTranslate: false,
                        isReplyAll: false,
                        isBcc: false,
                        isCc: false,
                    }));
                    break;
                default:
                    break;
            }
        };

        const handleTemplateSelected = (value: TPKeyValue): void => {
            getTemplateData(value).then((response) => {
                if (response.responseData) {
                    const data: TemplateResponse = response.responseData;
                    const textConcatenated: string = getEmailConcatenated(
                        data.renderedBody
                    );

                    setTemplateBody(textConcatenated);

                    const bodyConcatenated: string = `${textConcatenated}${replyBody}`;

                    setOutboundForm((prevForm) => ({
                        ...prevForm,
                        isBcc: data.renderedBcc?.length > 0,
                        bcc: data.renderedBcc,
                        isCc: data.renderedCc?.length > 0,
                        cc: data.renderedCc,
                        subject: data.renderedSubject,
                        body: bodyConcatenated,
                        to: data.renderedTo
                    }));

                    setCurrentEditorValue(bodyConcatenated);
                }
                setLoading(false);
            });
        };

        const getEmailConcatenated = (value: string) => {
            return `${value} <p>_________________________________</p>`;
        };

        const handleReplyChange = (value: TPKeyValue): void => {
            getInboundMailboxDetails(value).then((response) => {
                if (response && response[0]) {
                    const data: MailboxDetails = response[0];
                    setReplyBody(data.htmlBody ?? "");

                    const bodyConcatenated: string = `${templateBody}<p></p>${data.htmlBody ?? ""}`;

                    setOutboundForm((prevForm) => ({
                        ...prevForm,
                        to: data.fromAddress?.split("|")[0] ?? "",
                        subject: data.subject ?? "",
                        isCc: data.addressCC?.length > 0,
                        cc: extractEmails(data.addressCC) ?? "",
                        isBcc: data.addressBCC?.length > 0,
                        bcc: extractEmails(data.addressBCC) ?? "",
                        body: bodyConcatenated,
                    }));

                    setCurrentEditorValue(bodyConcatenated);
                }
                setLoading(false);
            });
        };

        const getInboundMailboxDetails = async (
            value: TPKeyValue
        ): Promise<any> => {
            try {
                setLoading(true);
                return taskService.getInboundMailboxReadById(
                    +value.key,
                    false,
                    true,
                    [200, 404]
                );
            } catch (error) {
                setLoading(false);
                TPLog.Log(
                    `Error ${component} getInboundMailboxDetails ex`,
                    TPLogType.ERROR,
                    error
                );
                return null;
            }
        };

        const getTemplateData = async (
            value: TPKeyValue
        ): Promise<HTTPResponse> => {
            const templateObject: TemplateObject = {
                ...templateRequirements,
                emailTemplateId: value.key,
                languageId: outboundForm.templateLanguage?.key ?? "",
            };

            try {
                setLoading(true);
                return taskService.renderEmailTemplate(
                    templateObject,
                    false,
                    true,
                    [200, 404]
                );
            } catch (error) {
                setLoading(false);
                TPLog.Log(
                    `Error ${component} getInboundMailboxDetails ex`,
                    TPLogType.ERROR,
                    error
                );
                return {} as HTTPResponse;
            }
        };

        const handleEditorChange = useCallback(
            (text: string, isTranslation: boolean = false) => {
                if (ignoreChangeRef.current) return;

                if (isTranslation) {
                    setTranslationItems((prevForm) => ({
                        ...prevForm,
                        body: text,
                    }));

                    return;
                }

                setCurrentEditorValue(text);

                if (!isSentimentEnabled) {
                    setOutboundForm((prevForm) => ({
                        ...prevForm,
                        body: text,
                    }));

                    getFormInformationBy("body", text);
                }
            },
            [isSentimentEnabled]
        );

        const handleTranslateEmail = async (): Promise<void> => {
            const body: string = encryptValue(outboundForm.body);
            const subject: string = encryptValue(outboundForm.subject);

            const from: string = "";
            const to: string = outboundForm.translateLanguage?.key ?? "";

            const taskData: TrackTaskBase = {
                caseId: currentTask?.caseId ?? 0,
                taskId: currentTask?.id ?? 0,
                inboundMailboxReadID: mailboxId,
                guid_USER: TPGlobal.currentUserGuid,
            };

            try {
                setLoading(true);

                const subjectTranslated = await taskService.translateEmail(
                    from,
                    to,
                    subject,
                    taskData,
                    "mailsubject"
                );

                setTranslationItems((prevForm) => ({
                    ...prevForm,
                    subject: subjectTranslated.translatedText,
                }));

                const bodyTranslated = await taskService.translateEmail(
                    from,
                    to,
                    body,
                    taskData
                );

                setTranslationItems((prevForm) => ({
                    ...prevForm,
                    body: bodyTranslated.translatedText,
                }));

                setShowEmailView(true);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                TPLog.Log(
                    `Error ${component} translateEmail ex`,
                    TPLogType.ERROR,
                    error
                );
            }
        };

        const handleTranslation = (
            isReadOnly: boolean,
            showReview: boolean
        ): void => {
            setIsReadOnly(isReadOnly);
            setShowReview(showReview);
            handleTranslateEmail();
        };

        const isEditorEmpty = (): boolean => {
            return (
                outboundForm.body === "<p><br></p>" || outboundForm.body.length === 0
            );
        };

        const getComposeKeyPoints = (): string => {
            if (inboundEmail.tpGenAIKeyPoints) {
                const keyPointsObject = JSON.parse(inboundEmail.tpGenAIKeyPoints);
                return keyPointsObject.items.join("\n");
            }

            return "";
        };

        const modalIsClosed = (response: string | undefined): void => {
            if (response && response.length !== 0) {
                const compositionText: string = response.replace(/\n/g, "<br />");
                const textConcatenated: string = getEmailConcatenated(compositionText);
                setTemplateBody(compositionText);
                handleEditorChange(`${textConcatenated}${replyBody}`);
            }
            setShowComposeModal(false);
        };

        const getTemplatesBy = async (
            inputValue: string
        ): Promise<OptionType[]> => {
            try {
                const response = await taskTypeService.getEmailTemplatesBySearch(
                    inputValue,
                    "1",
                    outboundForm.templateLanguage?.key ?? "",
                    false,
                    true,
                    [200, 404]
                );

                return response.map((item) => ({
                    value: item.id,
                    label: item.localizedDescription,
                }));
            } catch (error) {
                TPLog.Log(
                    `Error ${component} asyncSearchTemplate ex`,
                    TPLogType.ERROR,
                    error
                );
                return [];
            }
        };

        const debouncedTemplates = useCallback(
            debounce(
                (
                    inputValue: string,
                    callback: (options: { value: string; label: string }[]) => void
                ) => {
                    getTemplatesBy(inputValue)
                        .then(callback)
                        .catch(() => callback([]));
                },
                500
            ),
            []
        );

        const asyncSearchTemplates = (
            inputValue: string
        ): Promise<{ value: string; label: string }[]> => {
            return new Promise((resolve) => {
                debouncedTemplates(inputValue, resolve);
            });
        };

        const handleSwitchSentiment = (e: any, checked: boolean) => {
            ignoreChangeRef.current = true;
            setIsSentimentEnabled(checked);

            checked && handleGetSentimentData();

            setTimeout(() => {
                ignoreChangeRef.current = false;
            }, 0);
        };

        const changeEditorDataBy = () => {
            const newValue = isSentimentEnabled
                ? outboundForm.styledBody
                : outboundForm.body;
            setCurrentEditorValue(newValue);
        };

        const handleGetSentimentData = (): void => {
            getSentimentData().then((response) => {
                if (response && response.responseData && response.responseData.data) {
                    const data = response.responseData.data;
                    setSentencesToStyledBody(data.sentences);
                } else {
                    setIsSentimentEnabled(false);
                }
                setLoading(false);
            });
        };

        const getSentimentData = async (): Promise<HTTPResponse> => {
            try {
                setLoading(true);
                return await taskService.getSentimentAnalysis(
                    removeHtmlTags(outboundForm.body),
                    false,
                    true,
                    [200, 404]
                );
            } catch (error) {
                setLoading(false);
                TPLog.Log(
                    `Error ${component} getSentimentData ex`,
                    TPLogType.ERROR,
                    error
                );
                return {} as HTTPResponse;
            }
        };

        const removeHtmlTags = (text: string): string => {
            return text.replace(/<\/p>/gm, "\n").replace(/<[^>]*>?/gm, "");
        };

        const setSentencesToStyledBody = (sentences: SentimentSentence[]) => {
            let highlightedText = "";

            setSentimentSentences(sentences);

            sentences.forEach((sentence: any) => {
                const { sentiment } = sentence;
                const { text } = sentence;
                const backgroundColor: string = getColorBySentiment(sentiment);
                const textFormatted: string = text
                    .replace(/\.(?=\s|$)/g, ".\n")
                    .replace(/\n/gm, "<br/>");

                highlightedText += `<span style="background-color: ${backgroundColor};">${textFormatted}</span>`;
            });

            setOutboundForm((prevForm) => ({
                ...prevForm,
                styledBody: highlightedText,
            }));

            setCurrentEditorValue(highlightedText);
        };

        const getColorBySentiment = (sentiment: string) => {
            switch (sentiment.toLowerCase()) {
                case "neutral":
                    return "rgba(244, 244, 244)";
                case "positive":
                    return "rgba(206, 243, 229)";
                case "negative":
                    return "rgba(255, 215, 215)";
                default:
                    return "transparent";
            }
        };

        const calculateSentimentPercentages = (): SentimentsPercentage => {
            const sentences: SentimentSentence[] = sentimentSentences;

            const initialCount: Record<Sentiment, number> = {
                positive: 0,
                neutral: 0,
                negative: 0,
            };

            const sentimentCount = sentences.reduce((acc, sentence) => {
                const sentiment = sentence.sentiment as Sentiment;
                if (sentiment in acc) {
                    acc[sentiment]++;
                }
                return acc;
            }, initialCount);

            const totalSentences = sentences.length;

            if (totalSentences === 0) {
                return {
                    positive: "0",
                    neutral: "0",
                    negative: "0",
                };
            }

            const percentages: SentimentsPercentage = {
                positive: ((sentimentCount.positive / totalSentences) * 100).toFixed(0),
                neutral: ((sentimentCount.neutral / totalSentences) * 100).toFixed(0),
                negative: ((sentimentCount.negative / totalSentences) * 100).toFixed(0),
            };

            return percentages;
        };

        const isSaveButtonDisabled = (): boolean => {
            const {
                isTranslate,
                translateLanguage,
                isInternal,
                useTemplate,
                templateLanguage,
                template,
                reply,
                from,
                to,
                subject,
            } = outboundForm;
            let isBasicFieldInvalid =
                !from || to === "" || subject === "" || isEditorEmpty();

            if (outboundData.replyList.length && !reply) {
                isBasicFieldInvalid = false;
            }

            if (isInternal) {
                return isEditorEmpty();
            }

            if (isTranslate && useTemplate) {
                const isTemplateFieldInvalid = !templateLanguage || !template;
                return (
                    isTemplateFieldInvalid || !translateLanguage || isBasicFieldInvalid
                );
            }

            if (isTranslate) {
                return !translateLanguage || isBasicFieldInvalid;
            }

            if (useTemplate) {
                const isTemplateFieldInvalid = !templateLanguage || !template;
                return isTemplateFieldInvalid || isBasicFieldInvalid;
            }

            return isBasicFieldInvalid;
        };

        const handleManageModal = (toShow: boolean = false): void => {
            setIsReadOnly(false);
            setShowReview(false);
            setShowEmailView(toShow);
        };

        const handleEditorIconClick = (icon: TPIconTypes): void => {
            if (icon === TPIconTypes.fullScreen) handleManageModal(true);
        };

        const manageOutboundTask = (): void => {
            if (outboundForm.isInternal) {
                saveInternalOutboundTask();
                return;
            }

            if (outboundForm.isTranslate) {
                handleTranslation(false, true);
                return;
            }

            saveOutboundTask();
        };

        const saveOutboundTask = (): void => {
            const {
                isInternal,
                isTranslate,
                isReplyAll,
                useTemplate,
                templateLanguage,
                template,
                reply,
                from,
                to,
                cc,
                bcc,
                body,
                subject,
            } = outboundForm;

            const encryptedBody = encryptValue(
                isTranslate ? translationItems.body : body
            );
            const encryptedSubject = encryptValue(
                isTranslate ? translationItems.subject : subject
            );
            const attachmentsResumed: FileResumed[] = attachments.map((file) => {
                return {
                    ...file,
                    saveInCase: checks.saveInCase,
                };
            });

            const outboundTaskObject: TaskSendMailReportInputDTO = {
                id: taskIdToView,
                useRPCMethod: true,
                report: encryptedBody,
                decisionPath: "",
                escalatedTaskReport: "",
                typistGuidUser: TPGlobal.currentUserGuid,
                tEmpAdditionalDataTask: [],
                fromEmailAccountId: from?.key ?? "",
                to: to,
                cc: cc,
                bcc: bcc,
                subject: encryptedSubject,
                internalReportNotForSending: isInternal,
                replyTo: `${reply?.key ?? ""}`,
                replyToAll: isReplyAll,
                useTemplate: useTemplate,
                templateLanguageId: templateLanguage?.key ?? "",
                templateId: template?.key ?? "",
                sendCase: checks.sendCase,
                sendTasks: checks.sendTask,
                tEmpAttachmentsTask: attachmentsResumed,
            };

            handleSaveTask(outboundTaskObject, true);
        };

        const saveInternalOutboundTask = (): void => {
            const { body } = outboundForm;

            const internalTaskObject: TaskReportInputDTO = {
                id: taskIdToView,
                useRPCMethod: true,
                report: encryptValue(body),
                decisionPath: "",
                escalatedTaskReport: "",
                typistGuidUser: TPGlobal.currentUserGuid,
                tEmpAdditionalDataTask: [],
            };

            handleSaveTask(internalTaskObject, false);
        };

        const encryptValue = (value: string): string => {
            return TPGlobal.stringToUTF8(value).toString();
        };

        const extractEmails = (input: string): string => {
            return input
                .split(";")
                .map((entry) => entry.split("|")[1]?.trim() || "")
                .filter((email) => email !== "")
                .join(";");
        };

        useEffect(() => {
            changeEditorDataBy();
        }, [isSentimentEnabled]);

        useEffect(() => {
            if (!resourcesLoaded) {
                loadUtilsResources().then(() => {
                    if (translateLanguages.length === 0) {
                        let languages: TPKeyValue[] =
                            TPGlobal.StoryFaiAvailableLanguages.split("|").map((item) => {
                                const [key, value] = item.split("$");
                                return { key, value };
                            });

                        setTranslateLanguages(languages);
                        setResourcesLoaded(true);
                    }
                });
            }
        }, [resourcesLoaded]);

        useEffect(() => {
            if (!id) setId(`${uuidv4()}_${Date.now()}`);
        }, [id])




        return (
            <>
                {resourcesLoaded && (
                    <>
                        <ComposeEmailModal
                            status={showComposeModal}
                            keyPoints={getComposeKeyPoints()}
                            baseEmail={inboundEmail.plainTextBody}
                            componentLabels={resources.composeModal}
                            handleModalClose={modalIsClosed}
                        />

                        <PreviewEmailModal
                            status={showEmailView}
                            email={translationItems.body}
                            type="outbound"
                            componentLabels={resources.previewModal}
                            isReview={showReview}
                            handleModalClose={() => handleManageModal()}
                            handleCompleteAction={() => saveOutboundTask()}
                            readOnly={isReadOnly}
                        >
                            {showReview ? (
                                <>
                                    <p className="title">{resources.translationReview}</p>
                                    <div className="input-section">
                                        <TPTextBox
                                            id={`modal-subject-input-text-${id}`}
                                            labelText={resources.subject}
                                            value={translationItems.subject}
                                            placeholder={resources.inputPH}
                                            onChange={(e: any) =>
                                                handleInputChange("subject", e.target.value, true)
                                            }
                                            textStyle={textboxStyle}
                                            containerStyle={{ width: "100%" }}
                                            isMandatory
                                        />
                                    </div>
                                    <div className="editor-container">
                                        <TPLabel
                                            htmlFor={`modal-outbound-email-editor-${id}`}
                                            labelText={resources.message}
                                            isMandatory
                                        />
                                        <TPEditor
                                            id={`modal-outbound-email-editor-${id}`}
                                            placeholder={resources.editorPH}
                                            value={translationItems.body}
                                            onChange={(e: string) => handleEditorChange(e, true)}
                                            icons={editorIcons}
                                            onClickCustomIcon={handleEditorIconClick}
                                            style={{ height: "300px" }}
                                            isInvalidChars={false}
                                            grammarChecking={true}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p className="title">{resources.fullView}</p>
                                    <div className="editor-container">
                                        <TPLabel
                                            htmlFor={`modal-outbound-email-editor-${id}`}
                                            labelText={resources.message}
                                            isMandatory
                                        />
                                        {isSentimentEnabled ? (
                                            <TPEditor
                                                id={`modal-outbound-sentiment-email-editor-${id}`}
                                                placeholder={resources.editorPH}
                                                value={currentEditorValue}
                                                style={{ height: "300px" }}
                                                icons={editorIcons}
                                                onClickCustomIcon={handleEditorIconClick}
                                                isEdit={false}
                                                grammarChecking={true}
                                            />
                                        ) : (
                                            <TPEditor
                                                id={`modal-outbound-email-editor-${id}`}
                                                placeholder={resources.editorPH}
                                                value={currentEditorValue}
                                                onChange={handleEditorChange}
                                                icons={editorIcons}
                                                onClickCustomIcon={handleEditorIconClick}
                                                style={{ height: "300px" }}
                                                isInvalidChars={false}
                                                grammarChecking={true}
                                            />
                                        )}
                                    </div>
                                    <SentimentAnalysisSection
                                        key={`modal-sentiment-analysis-${id}`}
                                        componentLabels={resources.sentiment}
                                        onToggleChange={handleSwitchSentiment}
                                        percentages={calculateSentimentPercentages()}
                                        checked={isSentimentEnabled}
                                    />
                                </>
                            )}
                        </PreviewEmailModal>

                        <StyledOutboundEmailTask>
                            {!outboundForm.isInternal && (
                                <div className="input-section">
                                    <div className="email-checkbox">
                                        <label htmlFor={`translation-check-${id}`}>
                                            <TPCheckBox
                                                id={`translation-check-${id}`}
                                                checked={outboundForm.isTranslate}
                                                onChange={(e: any) =>
                                                    handleInputChange("isTranslate", e.target.checked)
                                                }
                                                disabled={false}
                                            />
                                            <p>{resources.translateCheck}</p>
                                        </label>
                                    </div>

                                    {outboundForm.isTranslate && (
                                        <SearchSelect
                                            id={`translate-languages-${id}`}
                                            options={translateLanguages}
                                            optionSelected={outboundForm.translateLanguage}
                                            placeholder={resources.languagePH}
                                            handleChange={(e: TPKeyValue) =>
                                                handleInputChange("translateLanguage", e)
                                            }
                                            width="340px"
                                        />
                                    )}
                                </div>
                            )}

                            <div className="input-section">
                                <div className="email-checkbox">
                                    <label htmlFor={`internal-report-check-${id}`}>
                                        <TPCheckBox
                                            id={`internal-report-check-${id}`}
                                            checked={outboundForm.isInternal}
                                            onChange={(e: any) =>
                                                handleInputChange("isInternal", e.target.checked)
                                            }
                                            disabled={false}
                                        />
                                        <p>{resources.internal}</p>
                                    </label>
                                </div>
                                {!outboundForm.isInternal && (
                                    <div className="email-checkbox">
                                        <label htmlFor={`use-template-check-${id}`}>
                                            <TPCheckBox
                                                id={`use-template-check-${id}`}
                                                checked={outboundForm.useTemplate}
                                                onChange={(e: any) =>
                                                    handleInputChange("useTemplate", e.target.checked)
                                                }
                                                disabled={false}
                                            />
                                            <p>{resources.useTemplate}</p>
                                        </label>
                                    </div>
                                )}
                            </div>

                            {!outboundForm.isInternal && (
                                <>
                                    {outboundForm.useTemplate && (
                                        <div className="input-section">
                                            <SearchSelect
                                                id={`template-languages-${id}`}
                                                options={outboundData.templateLanguages}
                                                optionSelected={outboundForm.templateLanguage}
                                                placeholder={resources.languagePH}
                                                handleChange={(e: TPKeyValue) =>
                                                    handleInputChange("templateLanguage", e)
                                                }
                                                label={resources.templateLanguagePH}
                                                orientation="vertical"
                                                width="100%"
                                                style={{ width: "calc(50% - 0.5em)" }}
                                                isMandatory
                                            />

                                            <SearchSelect
                                                id={`templates-${id}`}
                                                options={templates}
                                                optionSelected={outboundForm.template}
                                                placeholder={resources.selectPH}
                                                handleChange={(e: TPKeyValue) =>
                                                    handleInputChange("template", e)
                                                }
                                                label={resources.templatePH}
                                                orientation="vertical"
                                                width="100%"
                                                noDataMessage={resources.templateNoData}
                                                loadOptions={asyncSearchTemplates}
                                                style={{ width: "calc(50% - 0.5em)" }}
                                                isMandatory
                                                isAsync
                                            />
                                        </div>
                                    )}

                                    <div className="input-section">
                                        {outboundData.replyList &&
                                            outboundData.replyList.length > 0 && (
                                                <SearchSelect
                                                    id={`reply-to-${id}`}
                                                    options={outboundData.replyList}
                                                    optionSelected={outboundForm.reply}
                                                    placeholder={resources.selectPH}
                                                    handleChange={(e: TPKeyValue) =>
                                                        handleInputChange("reply", e)
                                                    }
                                                    label={resources.reply}
                                                    orientation="vertical"
                                                    isMandatory
                                                    width="340px"
                                                />
                                            )}

                                        {outboundForm.reply && (
                                            <div className="email-checkbox">
                                                <label htmlFor={`reply-all-check-${id}`}>
                                                    <TPCheckBox
                                                        id={`reply-all-check-${id}`}
                                                        checked={outboundForm.isReplyAll}
                                                        onChange={(e: any) =>
                                                            handleInputChange("isReplyAll", e.target.checked)
                                                        }
                                                        disabled={false}
                                                    />
                                                    <p>{resources.replyAll}</p>
                                                </label>
                                            </div>
                                        )}
                                    </div>

                                    <div className="input-section">
                                        <SearchSelect
                                            id={`from-${id}`}
                                            options={outboundData.fromList}
                                            optionSelected={outboundForm.from}
                                            placeholder={resources.selectPH}
                                            handleChange={(e: TPKeyValue) =>
                                                handleInputChange("from", e)
                                            }
                                            label={resources.from}
                                            orientation="vertical"
                                            isMandatory
                                            width="232px"
                                        />
                                        <TPTextBoxSelectEmail
                                            id={`to-input-text-${id}`}
                                            ico={TPIconTypes.ioMdPeople}
                                            labelText={resources.to}
                                            isMandatory={true}
                                            value={outboundForm.to}
                                            placeholder={enterAValue}
                                            height={"34px"}
                                            marginBottom={"3px"}
                                            width={"auto"}
                                            onChange={(e: string) =>
                                                handleInputChange("to", e)
                                            }
                                        />
                                        {/*<TPTextBox*/}
                                        {/*    id={`to-input-text-${id}`}*/}
                                        {/*    labelText={resources.to}*/}
                                        {/*    value={outboundForm.to}*/}
                                        {/*    placeholder={resources.inputPH}*/}
                                        {/*    onChange={(e: any) =>*/}
                                        {/*        handleInputChange("to", e.target.value)*/}
                                        {/*    }*/}
                                        {/*    textStyle={textboxStyle}*/}
                                        {/*    isInvalidChars={false}*/}
                                        {/*    isMandatory*/}
                                        {/*/>*/}

                                        <div className="checkbox-section">
                                            <div className="email-checkbox">
                                                <label htmlFor={`is-cc-check-${id}`}>
                                                    <TPCheckBox
                                                        id={`is-cc-check-${id}`}
                                                        checked={outboundForm.isCc}
                                                        onChange={(e: any) =>
                                                            handleInputChange("isCc", e.target.checked)
                                                        }
                                                    />
                                                    <p>{resources.cc}</p>
                                                </label>
                                            </div>
                                            <div className="email-checkbox">
                                                <label htmlFor={`is-bcc-check-${id}`}>
                                                    <TPCheckBox
                                                        id={`is-bcc-check-${id}`}
                                                        checked={outboundForm.isBcc}
                                                        onChange={(e: any) =>
                                                            handleInputChange("isBcc", e.target.checked)
                                                        }
                                                    />
                                                    <p>{resources.bcc}</p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {((outboundForm.isBcc && outboundForm.isCc) ||
                                        outboundForm.isBcc ||
                                        outboundForm.isCc) && (
                                            <div className="input-section">
                                                {outboundForm.isCc && (

                                                    <TPTextBoxSelectEmail
                                                        id={`cc-input-text-${id}`}
                                                        ico={TPIconTypes.ioMdPeople}
                                                        labelText={resources.cc}
                                                        isMandatory={true}
                                                        value={outboundForm.cc}
                                                        height={"34px"}
                                                        marginBottom={"3px"}
                                                        width={"calc(50% - 0.5em)"}
                                                        placeholder={enterAValue}
                                                        onChange={(e: string) =>
                                                            handleInputChange("cc", e)
                                                        }
                                                    />


                                                    //<TPTextBox
                                                    //    id={`cc-input-text-${id}`}
                                                    //    labelText={resources.cc}
                                                    //    value={outboundForm.cc}
                                                    //    placeholder={resources.inputPH}
                                                    //    onChange={(e: any) =>
                                                    //        handleInputChange("cc", e.target.value)
                                                    //    }
                                                    //    textStyle={textboxStyle}
                                                    //    containerStyle={{ width: "calc(50% - 0.5em)" }}
                                                    //    isInvalidChars={false}
                                                    ///>
                                                )}

                                                {outboundForm.isBcc && (

                                                    <TPTextBoxSelectEmail
                                                        id={`bcc-input-text-${id}`}
                                                        ico={TPIconTypes.ioMdPeople}
                                                        labelText={resources.bcc}
                                                        isMandatory={true}
                                                        value={outboundForm.bcc}
                                                        height={"34px"}
                                                        marginBottom={"3px"}
                                                        placeholder={enterAValue}
                                                        width={"calc(50% - 0.5em)"}
                                                        onChange={(e: string) =>
                                                            handleInputChange("bcc", e)
                                                        }
                                                    />

                                                    //<TPTextBox
                                                    //    id={`bcc-input-text-${id}`}
                                                    //    labelText={resources.bcc}
                                                    //    value={outboundForm.bcc}
                                                    //    placeholder={resources.inputPH}
                                                    //    onChange={(e: any) =>
                                                    //        handleInputChange("bcc", e.target.value)
                                                    //    }
                                                    //    textStyle={textboxStyle}
                                                    //    containerStyle={{ width: "calc(50% - 0.5em)" }}
                                                    //    isInvalidChars={false}
                                                    ///>
                                                )}
                                            </div>
                                        )}

                                    <div className="input-section">
                                        <TPTextBox
                                            id={`subject-input-text-${id}`}
                                            labelText={resources.subject}
                                            value={outboundForm.subject}
                                            placeholder={resources.inputPH}
                                            onChange={(e: any) =>
                                                handleInputChange("subject", e.target.value)
                                            }
                                            textStyle={textboxStyle}
                                            containerStyle={{ width: "100%" }}
                                            isMandatory
                                        />
                                    </div>
                                </>
                            )}

                            <div className="editor-container">
                                <TPLabel
                                    htmlFor={`outbound-email-editor-${id}`}
                                    labelText={resources.message}
                                    isMandatory
                                />
                                {isSentimentEnabled ? (
                                    <TPEditor
                                        id={`outbound-sentiment-email-editor-${id}`}
                                        placeholder={resources.editorPH}
                                        value={currentEditorValue}
                                        style={{ height: "300px" }}
                                        icons={editorIcons}
                                        onClickCustomIcon={handleEditorIconClick}
                                        isEdit={false}
                                        grammarChecking={true}
                                    />
                                ) : (
                                    <TPEditor
                                        id={`outbound-email-editor-${id}`}
                                        placeholder={resources.editorPH}
                                        value={currentEditorValue}
                                        onChange={handleEditorChange}
                                        icons={editorIcons}
                                        onClickCustomIcon={handleEditorIconClick}
                                        style={{ height: "300px" }}
                                        grammarChecking={true}
                                        isInvalidChars={false}
                                    />
                                )}
                            </div>

                            {!outboundForm.isInternal && (
                                <>
                                    <SentimentAnalysisSection
                                        key={`sentiment-analysis-${id}`}
                                        componentLabels={resources.sentiment}
                                        onToggleChange={handleSwitchSentiment}
                                        percentages={calculateSentimentPercentages()}
                                        checked={isSentimentEnabled}
                                    />

                                    <div
                                        className={`additional-options ${outboundForm.isTranslate && "with-translation"}`}
                                    >
                                        {outboundForm.isTranslate && (
                                            <TPButton
                                                id={`open-translation-preview-${id}`}
                                                customType={ButtonCustomType.tertiary}
                                                onClick={() => handleTranslation(true, false)}
                                                style={{ padding: 0 }}
                                                disabled={
                                                    !outboundForm.translateLanguage || isEditorEmpty()
                                                }
                                                isDesignSystem
                                            >
                                                {resources.translationPreview}
                                            </TPButton>
                                        )}
                                        <div className="composition-content">
                                            <TPButton
                                                id={`ai-composition-${id}`}
                                                customType={ButtonCustomType.tertiary}
                                                onClick={() => setShowComposeModal(true)}
                                                withIcon={TPIconTypes.ia}
                                                orientationIcon="right"
                                                isDesignSystem
                                            >
                                                {resources.compose}
                                            </TPButton>
                                        </div>
                                    </div>
                                    <EmailAttachments
                                        key={`email-attachments-${id}`}
                                        operationGuid={operationGuid}
                                        baseLabels={resources.attachment}
                                        onCheckChange={setChecks}
                                        onFilesChange={setAttachments}
                                    />
                                </>
                            )}

                            <div className="action-buttons">
                                <TPButton
                                    id={`review-and-send-${id}`}
                                    onClick={manageOutboundTask}
                                    style={{ padding: "0 16px" }}
                                    disabled={isSaveButtonDisabled()}
                                    isDesignSystem
                                >
                                    {outboundForm.isTranslate
                                        ? resources.reviewAndSend
                                        : resources.send}
                                </TPButton>
                            </div>
                        </StyledOutboundEmailTask>
                    </>
                )}
            </>
        );
    }
);

export default OutboundEmailView;
