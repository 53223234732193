import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import React, { FC, useEffect, useState } from "react";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { LocalizedTextValue, SequenceGeneratorSequencesNameEnum, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import "./Assets/CaseToolbarStyles.css";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import SearchSelect from "@/modules/core/design-system/selects/SearchSelect";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import CockpitRemovableItem from "../SupervisorCockpit/utils/CockpitRemovableItem";
import allThemes from "@/assets/styles/theme";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPLocalizedNamesModal } from "@/components/TPLocalizedNamesModal/TPLocalizedNamesModal";
import { TPIconStyledSelect } from "@/components/TPIconStyledSelect/TPIconStyledSelect";
import { CaseToolbarItemRequest, CaseToolbarItemType, CaseToolbarProfile } from "@/models/CaseToolbar/CaseToolbarModel";
import TPCustomAutocomplete from "@/components/TPCustomAutocomplete/TPCustomAutocomplete";
import { useCaseToolbarLabels } from "./Assets/CaseToolbarLabels";
import { ComponentService } from "@/services/ComponentService";
import { ComponentViewModel } from "@/models/Components/ComponentModel";
import { CaseToolbarService } from "@/services/CaseToolbarService";
import { CaseToolbarSlice, CaseToolbarSliceModel } from "./Assets/CaseToolbarSlice";
import { SequenceService } from "@/services/SequenceService";
import { useSelector, useDispatch } from "react-redux";
import { StoreModel } from "@/redux/store";

const COMPONENT_TYPE = "CASETOOLBARITEMLINK";

const initialForm: CaseToolbarItemRequest = {
  description: "",
  id: "",
  image: "",
  isActive: true,
  descriptionLocalizedValues: [],
  profiles: [],
  type: "",
  urlLink: ""
}

interface CaseToolbarManagementProperties {
  itemId?: string;
  verticalTabCallback: Function;
}

type CaseToolbarFormModel = {
  [x in keyof CaseToolbarItemRequest]: (value: CaseToolbarItemRequest[x]) => void;
}

export const CaseToolbarManagement: FC<CaseToolbarManagementProperties> = function({ itemId, verticalTabCallback }) {
  const dispatch = useDispatch();
  const { availableProfiles } = useSelector((s: StoreModel) => s[CaseToolbarSlice.name]) as CaseToolbarSliceModel;
  const { labels } = useCaseToolbarLabels();
  const [selectedProfiles, setSelectedProfiles] = useState<TPKeyValue[]>([]);
  const [idLoading, setIdLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [isLocalizedNamesModalOpen, setIsLocalizedNamesModalOpen] = useState(false);
  const [components, setComponents] = useState<Array<ComponentViewModel>>([]);
  const [componentsLoading, setComponentsLoading] = useState(false);
  const [mainForm, setMainForm] = useState<CaseToolbarItemRequest>(initialForm);

  const handleMainFormChange: CaseToolbarFormModel = {
    description: value => {
      const newLocalizedValues = [
        ...mainForm.descriptionLocalizedValues.map(n => {
          return n.languageId === TPGlobal.language ?
            { ...n, localizedValue: value } as LocalizedTextValue : n
        })
      ]
      if (!newLocalizedValues.find(l => l.languageId === TPGlobal.language))
        newLocalizedValues.push({ languageId: TPGlobal.language, localizedValue: value, order: 0 })
      setMainForm({
        ...mainForm,
        description: value,
        descriptionLocalizedValues: newLocalizedValues
      })
    },
    id: value => setMainForm({ ...mainForm, id: value }),
    image: value => setMainForm({ ...mainForm, image: value }),
    isActive: value => setMainForm({ ...mainForm, isActive: value }),
    descriptionLocalizedValues: value => setMainForm({ ...mainForm, descriptionLocalizedValues: value}),
    profiles: value => setMainForm({ ...mainForm, profiles: value }),
    type: value => setMainForm({ ...mainForm, type: value }),
    urlLink: value => setMainForm({ ...mainForm, urlLink: value }),
  }

  const generateId = async function() {
    setIdLoading(true);
    const seqServiceInstance = new SequenceService();
    seqServiceInstance.generalAutomaticId(false, false, [200], SequenceGeneratorSequencesNameEnum.SQCTBI)
    .then((response) => response && handleMainFormChange.id(response.responseData.data[0].sequenceCode))
    .catch(err => console.error(err))
    .finally(() => setIdLoading(false));
  }

  const transformProfiles = function(profiles: CaseToolbarProfile[]) {
    return profiles.map(p => ({ key: p.id_PROF, value: 
      availableProfiles.find(avProf => avProf.key === p.id_PROF)?.value || '???'
     }));
  }

  const getItemById = async function() {
    if (!itemId) return;
    setItemLoading(true);
    CaseToolbarService.getItemById(itemId, false, true, [200])
    .then(item => {
      if (!item) return;
      setMainForm({
        description: item.description,
        descriptionLocalizedValues: item.localizedDescriptions,
        id: item.id,
        image: item.image,
        isActive: item.isActive,
        profiles: item.profiles,
        type: item.type,
        urlLink: item.urlLink
      });
      setSelectedProfiles(transformProfiles(item.profiles));
    })
    .catch(err => console.error(err))
    .finally(() => setItemLoading(false))
  }

  const createItem = async function() {
    setItemLoading(true);
    const fixedLocalizedValues = [...mainForm.descriptionLocalizedValues];
    if (!fixedLocalizedValues.find(l => l.languageId === TPGlobal.TPClientDefaultLanguage))
      fixedLocalizedValues.push({ 
        languageId: TPGlobal.TPClientDefaultLanguage, 
        localizedValue: mainForm.description, 
        order: 0 
      })
    CaseToolbarService.createItem(
      {
        ...mainForm,
        descriptionLocalizedValues: fixedLocalizedValues.filter(l => l.localizedValue).map(l => (
          {
            ...l,
            localizedValue: 
              l.languageId === TPGlobal.TPClientDefaultLanguage && 
              l.localizedValue.trim().length === 0 ?
              mainForm.description : l.localizedValue
          }
        )),
      }, 
      true, true, [200])
    .then(() => {
      verticalTabCallback({
        command: 'delete',
        recordId: '--'
      })
      verticalTabCallback({ 
        command: 'update',
        recordId: mainForm.id, 
        languageId: TPGlobal.language,
        recordDescription: mainForm.description
      })
      dispatch(CaseToolbarSlice.actions.setContentLoaded(false))
    })
    .catch(err => console.error(err))
    .finally(() => setItemLoading(false));
  }

  const updateItem = async function() {
    setItemLoading(true);
    const fixedLocalizedValues = [...mainForm.descriptionLocalizedValues];
    if (!fixedLocalizedValues.find(l => l.languageId === TPGlobal.TPClientDefaultLanguage))
      fixedLocalizedValues.push({ 
        languageId: TPGlobal.TPClientDefaultLanguage, 
        localizedValue: mainForm.description, 
        order: 0
      })
    CaseToolbarService.updateItem(
      {
        ...mainForm,
        descriptionLocalizedValues: fixedLocalizedValues.filter(l => l.localizedValue).map(l => (
          {
            ...l,
            localizedValue: 
              l.languageId === TPGlobal.TPClientDefaultLanguage && 
              l.localizedValue.trim().length === 0 ?
              mainForm.description : l.localizedValue
          }
        )),
      },
      true, true, [200])
    .then(() => {
      getItemById();
      dispatch(CaseToolbarSlice.actions.setContentLoaded(false))
    })
    .catch(err => console.error(err))
    .finally(() => setItemLoading(false));
  }

  useEffect(() => {
    if (!selectedProfiles) return;
    handleMainFormChange.profiles([...selectedProfiles.map(
      p => ({ id_CTBI: mainForm.id, id_PROF: p.key, order_PCTI: 1 } as CaseToolbarProfile))]);
  }, [selectedProfiles])

  const removeSelectedProfile = function (profile: TPKeyValue) {
    setSelectedProfiles([
      ...selectedProfiles.filter(p => p !== profile)
    ]);
  }

  const getComponents = async function () {
    setComponentsLoading(true);
    const componentServiceInstance = new ComponentService();
    await componentServiceInstance.GetByTypeIsActive("1",COMPONENT_TYPE, false, true, [200, 404])
      .then((c) => setComponents(c))
      .catch(err => console.error(err))
      .finally(() => setComponentsLoading(false))
  }

  const isFormValid = function() {
    return (
      (mainForm.id?.trim().length > 0) && 
      (mainForm.description?.trim().length > 0) &&
      (mainForm.urlLink?.trim().length > 0) && 
      (mainForm.type?.trim().length > 0) && 
      (mainForm.profiles.length > 0)
    ) 
  }

  useEffect(() => {
    getComponents();
    getItemById();
    if (!itemId) generateId();
  }, [])

  return (
    <>
      {isLocalizedNamesModalOpen && (
        <TPLocalizedNamesModal
          title={labels.LanguageList}
          genericLabel={labels.Description}
          isOpen
          onAction={(data) => {
            if (data) handleMainFormChange.descriptionLocalizedValues(data);
            setIsLocalizedNamesModalOpen(false);
          }}
          initialValues={mainForm.descriptionLocalizedValues}
        />)}
      <TPLoadingOverlay active={idLoading || componentsLoading || itemLoading}>
        <div id="case-toolbar-outer-container" className="row">
          <div id="case-toolbar-inner-container" className="col">

            <CIMTitleSection style={{ margin: 0, padding: 0 }}>
              <TPPageTitle style={{ margin: 0, padding: 0 }}>{labels.CaseToolbarItemInformation}</TPPageTitle>
            </CIMTitleSection>

            <b style={{fontSize:"14px", fontWeight:"700"}}>{labels.Description}</b>

            <div id ="case-toolbar-input-group">
              <div className="case-toolbar-form-row">
                <TPTextBox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                    handleMainFormChange.description(e.target.value)}
                  value={mainForm.descriptionLocalizedValues
                    .find(l => l.languageId === TPGlobal.language)?.localizedValue ?? mainForm.description}
                  placeholder={labels.DescriptionExample}
                  isMandatory
                  labelText={`
                    ${labels.Description} 
                    (${TPGlobal.TPClientAvailableLanguages.find(l => l.id === TPGlobal.language)?.name})`}
                  containerStyle={{width:"100%", flexShrink: "0"}}
                />
                <button 
                  type="button" 
                  className="case-toolbar-language-button"
                  onClick={() => setIsLocalizedNamesModalOpen(true)}
                >
                  <TPIcon iconType={TPIconTypes.language} style={{fontSize: "24px"}} />
                  +{TPGlobal.TPClientAvailableLanguages.length-1}
                </button>
              </div>
              <div>
                <TPSelect
                  dataSource={[
                    { key: "", value: "--" },
                    { key: CaseToolbarItemType.MODAL, value: labels.Modal }
                  ]}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                    handleMainFormChange.type(e.target.value)}
                  value={mainForm.type}
                  isMandatory
                  labelText={labels.Type}
                />
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <TPCustomAutocomplete
                input={mainForm.urlLink}
                onQuery={(text) => 
                  handleMainFormChange.urlLink(text)}
                title={labels.LinkComponent}
                mandatory
                dropdownWidth={"min-content"}
              >
                {components.filter(c => c.component?.trim().length > 0).map(m =>
                (
                  <button
                    key={m.component}
                    type="button"
                    className="case-toolbar-autocomplete-button"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleMainFormChange.urlLink(m.component)
                    }}
                  >
                    <div style={{ fontSize: "11px", pointerEvents: "none" }}>{m.localizedDescription}</div>
                    <div style={{ pointerEvents: "none" }}>{m.component}</div>
                  </button>
                )
                )}
              </TPCustomAutocomplete>
            </div>

            {/* <TPCheckBox 
              checked={mainForm.isActive}
              labelText={labels.ShowAsActive}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                handleMainFormChange.isActive(e.target.checked)}
            /> */}

            <TPIconStyledSelect
              onIconSelected={(icon) => handleMainFormChange.image(icon)}
              selectedIcon={mainForm.image}
              width={"min-content"}
              label={labels.SelectIcon}
            />

            <div style={{ width: "100%", display: "flex", justifyContent: "stretch" }}>
              <SearchSelect
                options={availableProfiles}
                optionSelected={selectedProfiles}
                handleMultiChange={(profiles) => setSelectedProfiles(profiles)}
                id={`case-toolbar-profile-select-${itemId}`}
                isMulti
                isMandatory
                orientation="vertical"
                width="100%"
                style={{width:"100%"}}
                label={labels.ApplyItemToProfiles}
              />
            </div>
            
            <div className="menu-def-selected-profiles-grid">
              {selectedProfiles.map((profile, idx) => (
                <CockpitRemovableItem
                  item={{
                    key: profile.key,
                    value: profile.value,
                    type: "groups"
                  }}
                  onRemoveItem={() => removeSelectedProfile(profile)}
                  key={`${profile.key}-idx`}
                />
              ))}
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
              <div className="case-toolbar-option-buttons">
                <TPButton
                  isDesignSystem
                  onClick={() => {
                    itemId ?
                      verticalTabCallback({
                        command: 'delete',
                        recordId: itemId
                      })
                      :
                      verticalTabCallback({
                        command: 'delete',
                        recordId: '--'
                      })
                  }}
                  style={{
                    backgroundColor: "white",
                    color: allThemes.base.purplePrimary,
                    padding: "11px 16px"
                  }}
                >
                  {labels.Cancel}
                </TPButton>
                <TPButton
                  isDesignSystem
                  onClick={() => itemId ? updateItem() : createItem() }
                  style={{ padding: "11px 16px" }}
                  disabled={!isFormValid()}
                >
                  {labels.Save}
                </TPButton>
              </div>
            </div>
          </div>
        </div>
      </TPLoadingOverlay>
    </>
  )
}