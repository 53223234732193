import TPGlobal from "@/helpers/TPGlobal";
import {ReportIdNames, TPButtonTypes, TPIconTypes} from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useState } from "react";
import TPButton from "../bootstrap/components/buttons/TPButton";
import TPFixedTable from "../TPFixedTable/TPFixedTable";
import {
  TPConditionsContainer,
  TPConditionsTableContainer,
  TPTotalConditions,
} from "./TPConditionsDinamycTableStyles";
import DynamicTable, {ColumnStyles} from "@/modules/core/components/dynamic-table/DynamicTable";
import {CustomColumnNames} from "@/pages/Projects/ProjectDetail/ProjectDetailDynamicTable";
import {CalendarsViewModel} from "@/models/Calendars/CalendarsModels";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";


interface TPCondition {
  id: string;
  organizationRelation: string;
  filterType: string;
  conditionText: string;
}

interface TPConditionsTableProps {
  conditions: TPCondition[];
  onDelete: Function;
  deletable: boolean;
  showQuery: boolean;
}

const TPConditionsDinamycTable = ({
  conditions,
  onDelete,
  deletable,
  showQuery,
}: TPConditionsTableProps) => {
  const resourceSet = "TPConditionsTableComponent";
  //   resources
  const [queryExpressionLabel, setqueryExpressionLabel] = useState("");
  const [andLabel, setAndLabel] = useState("");
  const [orLabel, setOrLabel] = useState("");
  const [conditionLabel, setconditionLabel] = useState("");
  const [filterTypeLabel, setfilterTypeLabel] = useState("");
  const [organizationRelationLabel, setorganizationRelationLabel] =
    useState("");
  const [orderedConditions, setOrderedConditions] = useState<
    Array<TPCondition>
  >([]);

  const [data, setData] = useState<
      Array<any>
  >([]);

  const handleDeleteClickButton = (condition: string) => {
    if (onDelete && typeof onDelete == "function") onDelete(condition);
  };

  const [types, setTypes] = useState<TPCondition[]>();

  const loadResources = async () => {
    setqueryExpressionLabel(
      await TPI18N.GetText(resourceSet, "queryExpressionLabel"),
    );
    setAndLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "AndLabel"));
    setOrLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OrLabel"));
    setconditionLabel(await TPI18N.GetText(resourceSet, "conditionLabel"));
    setfilterTypeLabel(await TPI18N.GetText(resourceSet, "filterTypeLabel"));
    setorganizationRelationLabel(
      await TPI18N.GetText(resourceSet, "organizationRelationLabel"),
    );
  };

  const customerTypeColumns: CustomColumnNames<TPCondition> = {
    organizationRelation: organizationRelationLabel,
    conditionText: conditionLabel,
    filterType: filterTypeLabel,
  };



  const fixedColumns = [
    {
      name: organizationRelationLabel,
      cell: (row: { [x: string]: any }) => {
        return row["organizationRelation"];
      },
      selector: (row: { [x: string]: any }) => row["organizationRelation"],
      sortable: false,
    },
    {
      name: filterTypeLabel,
      cell: (row: { [x: string]: any }) => {
        return row["filterType"];
      },
      selector: (row: { [x: string]: any }) => row["filterType"],
      sortable: false,
    },
    {
      name: conditionLabel,
      cell: (row: { [x: string]: any }) => {
        return row["conditionText"];
      },
      selector: (row: { [x: string]: any }) => row["conditionText"],
      sortable: false,
    },
    {
      style: { width: "100px", height: "150px" },
      name: "",
      cell: (row: { [x: string]: any }) => {
        return (
            <TPButton
                id="IdButton"
                type={TPButtonTypes.icon}
                icon={TPIconTypes.trash}
                onClick={() => {
                  handleDeleteClickButton(row["id"]);
                }}
                style={{ color: "black",height: "20px" }}
            />
        );
      },
      selector: (row: { [x: string]: any }) => row["id"],
      sortable: false,
    },
  ];

  const queryJsx = (
    <div className="row mt-1">
      <TPConditionsContainer style={{marginTop:"15px"}}>
        <div>
          <h5>
            {queryExpressionLabel}
            <TPTotalConditions>{orderedConditions.length}</TPTotalConditions>
          </h5>
        </div>
        <span>
          <span>(</span>
          {types &&
            types.length > 0 &&
            types.map((type: TPCondition, typeIndex: number) => {
              const filteredConditions = orderedConditions.filter(
                (k) => k.filterType == type.filterType,
              );
              return (
                <span key={typeIndex}>
                  <span>{"("}</span>
                  {filteredConditions &&
                    filteredConditions.length > 0 &&
                    filteredConditions.map(
                      (condition: TPCondition, conditionIndex: number) => {
                        return (
                          <span key={conditionIndex}>
                            <strong>{type.filterType}</strong>
                            <span>{" = "}</span>
                            <span style={{ wordBreak: "break-all" }}>
                              {condition.conditionText}
                            </span>
                            {conditionIndex + 1 < filteredConditions.length
                              ? ` ${orLabel} `
                              : ""}
                          </span>
                        );
                      },
                    )}
                  <span>{")"}</span>
                  {typeIndex + 1 < types.length ? ` ${andLabel} ` : ""}
                </span>
              );
            })}
          <span>)</span>
        </span>
      </TPConditionsContainer>
    </div>
  );

  useEffect(() => {
    loadResources();
  }, []);

  useEffect(() => {
    setOrderedConditions(
      [...conditions].sort((a, b) => {
        if (a.filterType < b.filterType) {
          return -1;
        }
        if (a.filterType > b.filterType) {
          return 1;
        }
        return 0;
      }),
    );

    const newTypes: any[] = [];

    conditions.forEach((item: TPCondition) => {
      var indexItem = newTypes.findIndex(
        (k) => k.filterType == item.filterType,
      );
      if (indexItem == -1) {
        newTypes.push(item);
      }
    });

    setTypes(newTypes);
    const filteredData = newTypes.map(item => ({
      id: item.id,
      organizationRelation: item.organizationRelation,
      filterType: item.filterType,
      conditionText: item.conditionText,
      "": item.id

    }));
    setData(filteredData)

  }, [conditions]);

  const customColumns: ColumnStyles<any> = {
    "": ({ value, item }) => (
        <TPButton
            id="IdButton"
            type={TPButtonTypes.icon}
            icon={TPIconTypes.trash}
            onClick={() => {
              handleDeleteClickButton(item.id);
            }}
            style={{ color: "black",height: "20px" }}
        />
    ),
  };

  return (
      <TPConditionsTableContainer>
        {data && data.length > 0 && (
            <DynamicTable
                style={{ height: "200px" }}
                columnNames={customerTypeColumns}
                columnStyles={customColumns}
                hiddenColumns={["id"]}
                data={data}
                hiddenSearch={true}
                hideRefresh={true}
                hideExport={true}
                hidePager={true}
                hideControls={true}
            />
        )}
        {showQuery && queryJsx}
      </TPConditionsTableContainer>
  );
};

export default TPConditionsDinamycTable;
