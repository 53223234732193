import TPModalTreeView, {
    TPModalTreeViewState,
} from "@/layouts/TPModalTreeView/TPModalTreeView";
import { TPActiveOptions, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import React, { useState, useEffect, useImperativeHandle } from "react";
import { TPTreeViewDataModel, TPTreeViewProps } from "../TPTreeView/TPTreeView";
import { v4 as uuidv4 } from "uuid";
import { BranchService } from "@/services/BranchService";
import {
    BranchInfoHierarchyViewModel,
    BranchInfoHierarchyViewModelComplete,
    BranchViewModel,
} from "@/models/Branch/BranchModels";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import TPAutoComplete from "../bootstrap/forms/TPAutoComplete/TPAutoComplete";
import TPGlobal from "@/helpers/TPGlobal";
import TPAutoCompleteTree from "@/components/TPAutoCompleteTree/TPAutoCompleteTree";

export type TPBranchSelectionValue = {
    branchId: string;
    branchDescription: string;
    branchHierarchyDescription: string;
};

interface TPBranchSelectionProps {
    labelText?: string | JSX.Element;
    treeId: string;
    mustSelectLastLevelBranch: boolean;
    value: TPBranchSelectionValue;
    onChange: Function;
    modalTitle: string;
    modalAcceptLabel: string;
    modalCancelLabel: string;
    modalSelectedBranchLabel: string;
    emptyLabel: string;
    errorMessage?: string;
    disabledTextBox?: boolean;
    isMandatory?: boolean;
    autoCloseTreeModalWhenSelect?: boolean;
    onIsLoadingProgress?: any;
    loadChildsFromThisParent?: string;
    withDelete?: boolean;
    onDeleteClick?: Function;
    showSelectRootNodeButton?: boolean;
    selectRootLabel?: string;
    className?: string;
    textStyle?: any;
    withIcon?: boolean;
}

const TPBranchSelectionTree = React.forwardRef(
    (
        {
            labelText = "",
            treeId,
            mustSelectLastLevelBranch,
            value,
            onChange,
            modalTitle,
            modalAcceptLabel,
            modalCancelLabel,
            modalSelectedBranchLabel,
            emptyLabel,
            errorMessage = "",
            disabledTextBox = false,
            isMandatory = false,
            autoCloseTreeModalWhenSelect = false,
            onIsLoadingProgress = null,
            loadChildsFromThisParent: loadChildrenFromThisParent = "",
            withDelete = false,
            onDeleteClick,
            showSelectRootNodeButton = false,
            selectRootLabel = "",
            className = "",
            textStyle,
            withIcon = false,
        }: TPBranchSelectionProps,
        ref,
    ) => {
        useImperativeHandle(ref, () => ({
            resetData() {
                console.log("#eliminando");
                setCurrentSelectedNode1("");
                setCurrentSelectedLabel1("");
                setCurrentSelectedHierarchyLabel1("");
                setAutocompleteOptions([]);
                setSelectedAutocompleteOption([]);

                //top n options for arrow icon
                setAutocompleteTopNOptions([]);
            },
        }));

        const handleDeleteClick = () => {
            if (onDeleteClick && typeof onDeleteClick == "function") {
                onDeleteClick(treeId);
            }
        };

        const componentFileName: string = "TPBranchSelection";

        //tree1
        let modalTreeViewInitialState1: TPModalTreeViewState = {
            isShown: false,
            guidSession: "",
            treeId: "",
        };
        const [modalTreeViewState1, setModalTreeViewState1] = useState(
            modalTreeViewInitialState1,
        );
        const [dataArray1, setTreeviewData1] = useState<Array<TPTreeViewDataModel>>(
            [],
        );
        const [currentSelectedNode1, setCurrentSelectedNode1] = useState(
            value.branchId,
        );
        const [currentSelectedLabel1, setCurrentSelectedLabel1] = useState(
            value.branchDescription,
        );
        const [currentSelectedHierarchyLabel1, setCurrentSelectedHierarchyLabel1] =
            useState(value.branchHierarchyDescription);
        const [backupCurrentSelectedNode1, setBackupCurrentSelectedNode1] =
            useState("");
        const [backupCurrentSelectedLabel1, setBackupCurrentSelectedLabel1] =
            useState("");
        const [
            backupCurrentSelectedHierarchyLabel1,
            setBackupCurrentSelectedHierarchyLabel1,
        ] = useState("");

        //top n options for arrow icon
        const [autocompleteTopNOptions, setAutocompleteTopNOptions] = useState<
            Array<TPKeyValue>
        >([]);

        const [autocompleteOptions, setAutocompleteOptions] = useState<
            Array<TPKeyValue>
        >([
            {
                key: value.branchId,
                value: value.branchHierarchyDescription,
                value2: value.branchDescription,
            },
        ]);

        const [selectedAutocompleteOption, setSelectedAutocompleteOption] =
            useState<Array<TPKeyValue>>([
                {
                    key: value.branchId,
                    value: value.branchHierarchyDescription,
                    value2: value.branchDescription,
                },
            ]);

        const loadHierarchyBranch = async (
            nodeId: string,
        ): Promise<BranchInfoHierarchyViewModel> => {
            let serviceClient = new BranchService();
            let expectedCodes: Array<number> = [200, 404];
            let hierarchyInfo: BranchInfoHierarchyViewModel = {
                hierarchyIds: "",
                hierarchyDescription: "",
            };
            try {
                let responseRequest = await serviceClient.getHierarchyBranch(
                    nodeId,
                    false,
                    true,
                    expectedCodes,
                );
                return responseRequest[0];
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} loadHierarchyBranch ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${componentFileName} loadHierarchyBranch ex`);
                return hierarchyInfo;
            }
        };

        const loadChildBranches = async (
            nodeId: string,
            filterIsActive: number,
            filterIsVisible: number,
        ): Promise<Array<TPTreeViewDataModel>> => {
            let serviceClient = new BranchService();
            let expectedCodes: Array<number> = [200, 404];
            let i: number;
            let finalBranches: Array<TPTreeViewDataModel> = [];
            try {
                let responseRequest = await serviceClient.getChildBranches(
                    nodeId,
                    filterIsActive,
                    filterIsVisible,
                    false,
                    true,
                    expectedCodes,
                );
                //transform BranchModel to TPTreeViewDataModel
                for (i = 0; i <= responseRequest.length - 1; i++) {
                    let nodeElement: BranchViewModel = responseRequest[i];
                    let branchElement: TPTreeViewDataModel = {
                        id: nodeElement.id,
                        label:
                            nodeElement.localizedDescription !== null
                                ? nodeElement.localizedDescription
                                : nodeElement.description,
                        parentId: nodeElement.parentId !== null ? nodeElement.parentId : "",
                        hasChild:
                            nodeElement.hasChildActive && nodeElement.hasChildVisible
                                ? true
                                : false,
                        isExpanded: false,
                        isActive: nodeElement.isActive,
                        isVisible: nodeElement.isVisible,
                        hierarchyIds: nodeElement.hierarchyIds,
                        hierarchyDescription: nodeElement.hierarchyDescription,
                    };
                    finalBranches.push(branchElement);
                }
                return finalBranches;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} loadChildBranches ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${componentFileName} loadChildBranches ex`);
                return [];
            }
        };

        const loadBranchesForFirstLevelAndExpandedCurrentNode = async (): Promise<
            Array<TPTreeViewDataModel>
        > => {
            let serviceClient = new BranchService();
            let expectedCodes: Array<number> = [200, 404];
            let i: number;
            let j: number;
            let finalBranches: Array<TPTreeViewDataModel> = [];
            let needToGetHierarchy: boolean;
            let hierarchyInfo: BranchInfoHierarchyViewModel;
            let hierarchyIds: Array<string> = [];
            let childBranches: Array<TPTreeViewDataModel> = [];
            try {
                let selectedNode: string = "";
                let selectedDescription: string = "";
                let selectedHierarchyDescription: string = "";
                let filterIsActive: number = 1;
                let filterIsVisible: number = 1;
                let responseRequest: Array<BranchViewModel>;
                selectedNode = value.branchId;
                selectedDescription = value.branchDescription;
                selectedHierarchyDescription = value.branchHierarchyDescription;
                setCurrentSelectedNode1(selectedNode);
                setCurrentSelectedLabel1(selectedDescription);
                setCurrentSelectedHierarchyLabel1(selectedHierarchyDescription);

                if (!loadChildrenFromThisParent) {
                    responseRequest = await serviceClient.getFirstLevelBranches(
                        treeId,
                        filterIsActive,
                        filterIsVisible,
                        false,
                        true,
                        expectedCodes,
                    );
                } else {
                    responseRequest = await serviceClient.getChildBranches(
                        loadChildrenFromThisParent,
                        filterIsActive,
                        filterIsVisible,
                        false,
                        true,
                        expectedCodes,
                    );
                }

                // let responseRequest = await serviceClient.getFirstLevelBranches(
                //   treeId,
                //   filterIsActive,
                //   filterIsVisible,
                //   false,
                //   true,
                //   expectedCodes
                // );
                //transform BranchModel to TPTreeViewDataModel
                for (i = 0; i <= responseRequest.length - 1; i++) {
                    let nodeElement: BranchViewModel = responseRequest[i];
                    let branchElement: TPTreeViewDataModel = {
                        id: nodeElement.id,
                        label:
                            nodeElement.localizedDescription !== null
                                ? nodeElement.localizedDescription
                                : nodeElement.description,
                        parentId: nodeElement.parentId !== null ? nodeElement.parentId : "",
                        hasChild:
                            nodeElement.hasChildActive && nodeElement.hasChildVisible
                                ? true
                                : false,
                        isExpanded: false,
                        isActive: nodeElement.isActive,
                        isVisible: nodeElement.isVisible,
                        hierarchyIds: nodeElement.hierarchyIds,
                        hierarchyDescription: nodeElement.hierarchyDescription,
                    };
                    finalBranches.push(branchElement);
                }
                //populate other levels of currentselected node
                if (selectedNode !== "") {
                    //populate other levels of currentselected node
                    //if selected node is one of first level nodes there is not need
                    //to get hierarchy
                    needToGetHierarchy = true;
                    for (i = 0; i <= finalBranches.length - 1; i++) {
                        if (finalBranches[i].id === selectedNode) {
                            needToGetHierarchy = false;
                            break;
                        }
                    }
                    if (needToGetHierarchy) {
                        //get Hierarchy
                        hierarchyInfo = await loadHierarchyBranch(selectedNode);
                        if (hierarchyInfo.hierarchyIds != "") {
                            hierarchyIds = hierarchyInfo.hierarchyIds.split("--");
                            //trim spaces
                            for (i = 0; i <= hierarchyIds.length - 1; i++) {
                                hierarchyIds[i] = hierarchyIds[i].trim();
                                //get childs for each hierarchyIds and add it to finalBranches
                                childBranches = await loadChildBranches(
                                    hierarchyIds[i],
                                    filterIsActive,
                                    filterIsVisible,
                                );
                                for (j = 0; j <= childBranches.length - 1; j++) {
                                    finalBranches.push(childBranches[j]);
                                }
                            }
                            //change expand property for all hierarchyIds except the last one
                            for (i = 0; i <= finalBranches.length - 1; i++) {
                                for (j = 0; j <= hierarchyIds.length - 2; j++) {
                                    if (finalBranches[i].id === hierarchyIds[j]) {
                                        finalBranches[i].isExpanded = true;
                                    }
                                }
                            }
                        } else {
                            //todo
                        }
                    }
                }
                return finalBranches;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} loadBranchesForFirstLevelAndExpandedCurrentNode ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(
                    `Error ${componentFileName} loadBranchesForFirstLevelAndExpandedCurrentNode ex`,
                );
                return [];
            }
        };

        const handleSelectBranchOnClick = async (e: any) => {
            let guidSession: string;
            if (onIsLoadingProgress) {
                onIsLoadingProgress(true);
            }
            //save current selected node and label in other state variables
            setBackupCurrentSelectedNode1(currentSelectedNode1);
            setBackupCurrentSelectedLabel1(currentSelectedLabel1);
            setBackupCurrentSelectedHierarchyLabel1(currentSelectedHierarchyLabel1);
            //Get first level nodes and expanded nodes for currentselected node
            let branches: Array<TPTreeViewDataModel> = [];
            branches = await loadBranchesForFirstLevelAndExpandedCurrentNode();
            setTreeviewData1([...branches]);
            //modal
            let newModalTreeViewState1 = { ...modalTreeViewState1 };
            newModalTreeViewState1.isShown = true;
            guidSession = "tree" + uuidv4().replaceAll("-", "");
            newModalTreeViewState1.guidSession = guidSession;
            newModalTreeViewState1.treeId = treeId;
            setModalTreeViewState1(newModalTreeViewState1);

            if (onIsLoadingProgress) {
                onIsLoadingProgress(false);
            }
            if (currentSelectedNode1 !== "") {
                setTimeout(() => {
                    var element = document.querySelector(
                        "#" + guidSession + "_" + currentSelectedNode1,
                    );
                    if (element) {
                        element.scrollIntoView({
                            behavior: "auto",
                            block: "center",
                            inline: "center",
                        });
                    }
                }, 1000);
            }
        };

        const handleCallBackModalTreeView1 = (
            confirmType: boolean,
            dataToReturn: string,
            labelToReturn: string,
            hierarchyToReturn: string,
        ) => {
            let newModalTreeViewState1 = { ...modalTreeViewState1 };
            if (!confirmType) {
                //recover current selected node and label from other state variables
                setCurrentSelectedNode1(backupCurrentSelectedNode1);
                setCurrentSelectedLabel1(backupCurrentSelectedLabel1);
                setCurrentSelectedHierarchyLabel1(backupCurrentSelectedHierarchyLabel1);
                //clean treeview data
                setTreeviewData1([]);
                //hide modal
                newModalTreeViewState1.isShown = false;
                newModalTreeViewState1.guidSession = "";
                newModalTreeViewState1.treeId = "";
                setModalTreeViewState1(newModalTreeViewState1);
                return;
            }
            //clean treeview data
            setTreeviewData1([]);
            if (confirmType) {
                onChange(dataToReturn, labelToReturn, hierarchyToReturn);
                setCurrentSelectedNode1(dataToReturn);
                setCurrentSelectedLabel1(labelToReturn);
                setCurrentSelectedHierarchyLabel1(hierarchyToReturn);
            }
            //hide modal
            newModalTreeViewState1.isShown = false;
            newModalTreeViewState1.guidSession = "";
            newModalTreeViewState1.treeId = "";
            setModalTreeViewState1(newModalTreeViewState1);
        };

        const handleOnAutocompleteQuery = async (query: string) => {
            let newOptions: Array<TPKeyValue> = [];
            let serviceClient = new BranchService();
            let expectedCodes: Array<number> = [200, 404];
            try {
                let responseRequest =
                    await serviceClient.getSearchContainsInBranchHierarchy(
                        treeId,
                        loadChildrenFromThisParent,
                        query,
                        TPActiveOptions.ACTIVE, //filter is Active
                        1, //filter is visible,
                        mustSelectLastLevelBranch,
                        false,
                        false,
                        expectedCodes,
                    );
                let response: Array<BranchInfoHierarchyViewModelComplete> = [
                    ...responseRequest,
                ];
                response.map(
                    (item: BranchInfoHierarchyViewModelComplete, index: number) => {
                        newOptions.push({
                            key: item.id,
                            value: item.hierarchyDescription,
                            value2: item.description,
                        });
                    },
                );
                setAutocompleteOptions(newOptions);
                return newOptions;
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} handleOnAutocompleteQuery ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(
                    `Error ${componentFileName} handleOnAutocompleteQuery ex`,
                );
                return [];
            }
        };

        const handleOnAutocompleteChange = (selected: any) => {
            if (selected && selected.length && selected.length >= 1) {
                onChange(selected[0].key, selected[0].value2, selected[0].value);
                setCurrentSelectedNode1(selected[0].key);
                setCurrentSelectedLabel1(selected[0].value2);
                setCurrentSelectedHierarchyLabel1(selected[0].value);
                setSelectedAutocompleteOption(selected);
            }
        };

        const handleOnAutocompleteKeyDown = (event: any) => {
            //tab 9
            //left arrow 37
            //right arror 39
            //enter 13
            //home 36
            //end  35
            //ArrowUp  38
            //ArrowDown  40
            if (
                event.keyCode != 9 &&
                event.keyCode != 37 &&
                event.keyCode != 39 &&
                event.keyCode != 13 &&
                event.keyCode != 35 &&
                event.keyCode != 36 &&
                event.keyCode != 38 &&
                event.keyCode != 40
            ) {
                onChange("", "", "");
                setCurrentSelectedNode1("");
                setCurrentSelectedLabel1("");
                setCurrentSelectedHierarchyLabel1("");
                setAutocompleteOptions([]);
                setSelectedAutocompleteOption([]);
            }
        };

        const handleAutoCompleteTopNClick = async () => {
            let newTopNOptions: Array<TPKeyValue> = [];
            if (autocompleteTopNOptions.length === 0) {
                newTopNOptions = await handleOnAutocompleteQuery("");

                if (newTopNOptions.length >= 1) {
                    //save on cache
                    setAutocompleteTopNOptions([...newTopNOptions]);
                    setAutocompleteOptions([...newTopNOptions]);
                    setSelectedAutocompleteOption([]);
                }

                //todo get real values;
                // newTopNOptions.push({
                //   key: "child2",
                //   value: "child1xx--child2xx",
                //   value2: "child2xx",
                // });
                // newTopNOptions.push({
                //   key: "child3",
                //   value: "child1xx--child2xx--child3xx",
                //   value2: "child3xx",
                // });
                // newTopNOptions.push({
                //   key: "child4",
                //   value:
                //     "child1xx--child2xx--child3xx--child4xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxyyy",
                //   value2: "child4xx",
                // });
                // //save on cache
                // setAutocompleteTopNOptions([...newTopNOptions]);
                // setAutocompleteOptions([...newTopNOptions]);
                // setSelectedAutocompleteOption([]);
            } else {
                //use cached values;
                setAutocompleteOptions([...autocompleteTopNOptions]);
                setSelectedAutocompleteOption([]);
            }
        };

        const getTreeProps = (): TPTreeViewProps => {
            return {
                treeId: modalTreeViewState1.treeId,
                dataSource: dataArray1,
                setDataSource: setTreeviewData1,
                selectedNode: currentSelectedNode1,
                setSelectedNode: setCurrentSelectedNode1,
                selectedLabel: currentSelectedLabel1,
                setSelectedLabel: setCurrentSelectedLabel1,
                selectedHierarchyLabel: currentSelectedHierarchyLabel1,
                setSelectedHierarchyLabel: setCurrentSelectedHierarchyLabel1,
                filterIsActive: TPActiveOptions.ACTIVE,
                filterIsVisible: TPActiveOptions.ACTIVE,
                guidTree: modalTreeViewState1.guidSession,
                isAdminMode: false,
                mustSelectLastLevelBranch,
                renderParent: "",
                setIsLoading: () => { },
                handleChangeNodeCallBack: () => { },
            };
        };

        useEffect(() => {
            setCurrentSelectedNode1(value.branchId);
            setCurrentSelectedLabel1(value.branchDescription);
            setCurrentSelectedHierarchyLabel1(value.branchHierarchyDescription);
            if (value.branchId !== "") {
                setAutocompleteOptions([
                    {
                        key: value.branchId,
                        value: value.branchHierarchyDescription,
                        value2: value.branchDescription,
                    },
                ]);
                setSelectedAutocompleteOption([
                    {
                        key: value.branchId,
                        value: value.branchHierarchyDescription,
                        value2: value.branchDescription,
                    },
                ]);
            } else {
                setAutocompleteOptions([]);
                setSelectedAutocompleteOption([]);
            }
        }, [value]);

        useEffect(() => {
            setCurrentSelectedNode1(value.branchId);
            setCurrentSelectedLabel1(value.branchDescription);
            setCurrentSelectedHierarchyLabel1(value.branchHierarchyDescription);

            if (value.branchId != "") {
                setAutocompleteOptions([
                    {
                        key: value.branchId,
                        value: value.branchHierarchyDescription,
                        value2: value.branchDescription,
                    },
                ]);

                setSelectedAutocompleteOption([
                    {
                        key: value.branchId,
                        value: value.branchHierarchyDescription,
                        value2: value.branchDescription,
                    },
                ]);
            }
            else {
                setAutocompleteOptions([]);
                setSelectedAutocompleteOption([]);
            }
            //top n options for arrow icon
            setAutocompleteTopNOptions([]);
        }, [treeId]);



        return (
            <>
                <TPAutoCompleteTree
                    isMandatory={isMandatory}
                    labelText={labelText}
                    onValueChange={(sel: any) => {
                        handleOnAutocompleteChange(sel);
                    }}
                    onSearch={(query: string) => {
                        handleOnAutocompleteQuery(query);
                    }}
                    isLoading={false}
                    options={autocompleteOptions}
                    withIcon={true}
                    icon={
                        withDelete
                            ? [TPIconTypes.plus, TPIconTypes.delete]
                            : TPIconTypes.plus
                    }
                    emptyLabel={emptyLabel}
                    iconClick={
                        withDelete
                            ? !disabledTextBox
                                ? [handleSelectBranchOnClick, handleDeleteClick]
                                : TPGlobal.foo()
                            : !disabledTextBox
                                ? handleSelectBranchOnClick
                                : TPGlobal.foo()
                    }
                    onKeyDown={(event: any) => {
                        !disabledTextBox
                            ? handleOnAutocompleteKeyDown(event)
                            : TPGlobal.foo();
                    }}
                    selected={selectedAutocompleteOption}
                    errorMessage={errorMessage}
                    disabled={disabledTextBox}
                    downArrowClick={() => {
                        !disabledTextBox ? handleAutoCompleteTopNClick() : TPGlobal.foo();
                    }}
                    className={`autocomplete-container ${errorMessage ? "is-invalid" : ""}`}
                    containerStyle={{
                        border: errorMessage ? "1px solid red" : "",
                    }}
                ></TPAutoCompleteTree>
                <TPModalTreeView
                    title={modalTitle}
                    acceptLabel={modalAcceptLabel}
                    cancelLabel={modalCancelLabel}
                    isShown={modalTreeViewState1.isShown}
                    callBackAnswer={handleCallBackModalTreeView1}
                    treeProps={getTreeProps()}
                    selectedNodeIdPlusDescription={modalSelectedBranchLabel}
                    autoCloseWhenSelect={autoCloseTreeModalWhenSelect}
                    loadChildrenFromThisParent={loadChildrenFromThisParent}
                    showSelectRootNodeButton={showSelectRootNodeButton}
                    selectRootLabel={selectRootLabel}
                ></TPModalTreeView>
            </>
        );
    },
);

export default TPBranchSelectionTree;
