import React from "react";
import styled from "styled-components";

export const TPTreeViewStyled = styled.ul`
  //.is-selected.is-active.is-invisible {
  //  border: 3px solid #a00095;
  //  color: black !important;
  //}

  //.is-selected.is-inactive.is-visible {
  //  border: 3px solid #a00095;
  //  background-color: white !important;
  //  color: red !important;
  //}

  //.is-selected.is-inactive.is-invisible {
  //  border: 3px solid #a00095;
  //  color: red !important;
  //}
`;
