import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPAddtionalDataUIModes } from "@/components/TPAdditionalData/TPAdditionalDataUImodes";
import TPRenderAdditionalDataSwitch from "@/components/TPAdditionalData/TPRenderAdditionalDataSwitch";
import TPBranchSelection2 from "@/components/TPBranchSelection/TPBranchSelection2";
import { TPEditor } from "@/components/TPEditor/TPEditor";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import TPModalSuccess from "@/layouts/TPModalSuccess/TPModalSuccess";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import {
  ReclassifyMassiveTasksInputDTO,
  ReclassifyMassiveTasksInputDTOValidator,
} from "@/models/Cases/CaseMassUpdateInputDTO";
import { TEmpAdditionalDataCaseViewModel } from "@/models/Cases/CasesInputDTO";
import {
  SystemParametersEnum,
  TPActiveOptions,
} from "@/models/Global/TPGlobalEnums";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { OrganizationsViewModel } from "@/models/Organizations/OrganizationsModels";
import { OrganizationsRelationsViewModel } from "@/models/OrganizationsRelations/OrganizationsRelationsModels";
import { QuickClassifierViewModel } from "@/models/QuickClassifier/QuickClassifierViewModel";
import { RelationsWithRestrictionsViewModel } from "@/models/Relations/RelationsModels";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import { WorkflowTypeViewModel } from "@/models/Workflow/WorkflowTypeModels";
import { AdditionalDataService } from "@/services/AdditionalDataService";
import { CaseMassUpdateService } from "@/services/CaseMassUpdateService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";
import { OrganizationsService } from "@/services/OrganizationsService";
import { ParametersService } from "@/services/ParametersService";
import { QuickClassifierService } from "@/services/QuickClassifierService";
import { RelationsService } from "@/services/RelationsService";
import { TreeService } from "@/services/TreeService";
import { WorkflowTypeService } from "@/services/WorkflowTypeService";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

interface IReclassifyProps {
  caseList: Array<number>;
  callBackSaveSuccess: Function;
}

interface IAnswerEndpointData {
  caseId: string;
  caseReclassified: boolean;
  message: string;
}

type screenStateType = {
  caseGuid: string;
  baseLevelId: string;
  quickClassifierId: string;
  reclassificationReasonId: string;
  reclassificationOptionSelected: string;
  reclassificationOptionSameWorkflow: string;

  currentTree1: string;
  currentTree2: string;
  currentTree3: string;
  currentTree4: string;
  currentTree5: string;

  mustSelectLastLevelBranch1: boolean;
  mustSelectLastLevelBranch2: boolean;
  mustSelectLastLevelBranch3: boolean;
  mustSelectLastLevelBranch4: boolean;
  mustSelectLastLevelBranch5: boolean;

  currentLabelTree1: string;
  currentLabelTree2: string;
  currentLabelTree3: string;
  currentLabelTree4: string;
  currentLabelTree5: string;

  currentBranch1: string;
  currentBranch2: string;
  currentBranch3: string;
  currentBranch4: string;
  currentBranch5: string;

  currentDescriptionBranch1: string;
  currentDescriptionBranch2: string;
  currentDescriptionBranch3: string;
  currentDescriptionBranch4: string;
  currentDescriptionBranch5: string;

  defaultBranchHierarchyDescription1: string;
  defaultBranchHierarchyDescription2: string;
  defaultBranchHierarchyDescription3: string;
  defaultBranchHierarchyDescription4: string;
  defaultBranchHierarchyDescription5: string;

  //key: additional data id
  //value: addtional data value
  //value2: other properties
  //value3: should show
  additionalDataValues: Array<TPKeyValue>;
  showAdditionalData: boolean;

  currentWorkflowType: string;
  currentWorkflowDescription: string;

  classify1BranchIdErrorMessage: string;
  classify2BranchIdErrorMessage: string;
  classify3BranchIdErrorMessage: string;
  classify4BranchIdErrorMessage: string;
  classify5BranchIdErrorMessage: string;

  baseLevelIdErrorMessage: string;
  organizationIdErrorMessage: string;
  relationIdErrorMessage: string;
  quickClassifierIdErrorMessage: string;
  reclassificationReasonIdErrorMessage: string;
  commentsErrorMessage: string;
  classificationCommentsErrorMessage: string;
  [key: string]: any;
};

enum currentAccordionsEnum {
  "NONE" = 0,
  "CURRENT_CLASSIFICATION" = 1,
  "NEW_CLASSIFICATION" = 2,
}

enum commandsEnum {
  change_property_value,
  change_current_organization_relation,
  change_current_branch,
  organization_relation_clear,
  change_quick_classifier,
  change_additional_data_value,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

const ReclassifyMassive = React.forwardRef(
  ({ caseList, callBackSaveSuccess }: IReclassifyProps, ref) => {
    const componentFileName: string = "ReclassifyMassive.tsx";
    const resourceSet: string = "ReclassifyMassiveComponent";
    const resourceSetReclassifyCase: string = "ReclassifyCaseComponent";
    const resourceSetNewCase: string = "NewCaseComponent";

    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    const [modalSuccessTitle, setModalSuccessTitle] = useState("");
    const [selectedRowsLabel, setSelectedRowsLabel] = useState("");
    const [recordsProcessedLabel, setRecordsProcessedLabel] = useState("");
    const [recordsUnprocessedLabel, setRecordsUnprocessedLabel] = useState("");

    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalConfirmQuestion, setModalConfirmQuestion] = useState("");
    const [modalConfirmTitle, setModalConfirmTitle] = useState("");

    const [okButtonLabel, setOkButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");

    const [editorValue, setEditorValue] = useState("");

    const [answerData, setAnswerData] = useState<Array<IAnswerEndpointData>>(
      []
    );
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState
    );

    const initialScreenState: screenStateType = {
      caseGuid: uuidv4(),
      baseLevelId: "",
      quickClassifierId: "",
      quickClassifierIdErrorMessage: "",
      reclassificationOptionSelected: "S_RECLAOPTDISO",
      reclassificationOptionSameWorkflow: "S_RECLASWOPTCCS",
      currentTree1: "",
      currentTree2: "",
      currentTree3: "",
      currentTree4: "",
      currentTree5: "",
      mustSelectLastLevelBranch1: false,
      mustSelectLastLevelBranch2: false,
      mustSelectLastLevelBranch3: false,
      mustSelectLastLevelBranch4: false,
      mustSelectLastLevelBranch5: false,
      currentLabelTree1: "",
      currentLabelTree2: "",
      currentLabelTree3: "",
      currentLabelTree4: "",
      currentLabelTree5: "",
      currentBranch1: "",
      currentBranch2: "",
      currentBranch3: "",
      currentBranch4: "",
      currentBranch5: "",
      currentDescriptionBranch1: "",
      currentDescriptionBranch2: "",
      currentDescriptionBranch3: "",
      currentDescriptionBranch4: "",
      currentDescriptionBranch5: "",
      defaultBranchHierarchyDescription1: "",
      defaultBranchHierarchyDescription2: "",
      defaultBranchHierarchyDescription3: "",
      defaultBranchHierarchyDescription4: "",
      defaultBranchHierarchyDescription5: "",

      baseLevelIdErrorMessage: "",
      organizationIdErrorMessage: "",
      relationIdErrorMessage: "",
      additionalDataValues: [],
      reclassificationReasonId: "",
      reclassificationReasonIdErrorMessage: "",
      commentsErrorMessage: "",
      classificationCommentsErrorMessage: "",
      currentWorkflowType: "",
      currentWorkflowDescription: "",
      showAdditionalData: false,
      classify1BranchIdErrorMessage: "",
      classify2BranchIdErrorMessage: "",
      classify3BranchIdErrorMessage: "",
      classify4BranchIdErrorMessage: "",
      classify5BranchIdErrorMessage: "",
    };

    //Screen Resources
    const [dummyClassfierLabel, setDummyClassfierLabel] = useState("");
    const [modalAcceptLabel, setModalAcceptLabel] = useState("");
    const [modalCancelLabel, setModalCancelLabel] = useState("");
    const [modalSelectedBranchLabel, setModalSelectedBranchLabel] =
      useState("");
    const [modalTitleLabel, setModalTitleLabel] = useState("");
    const [emptyLabel, setEmptyLabel] = useState("");
    const [newReclassificationCaseLabel, setNewReclassificationCaseLabel] =
      useState("");
    const [organizationsRelationsLabel, setOrganizationsRelationsLabel] =
      useState("");
    const [quickClassfierLabel, setQuickClassfierLabel] = useState("");
    const [selectOptionToApplyLabel, setSelectOptionToApplyLabel] =
      useState("");
    const [ifSameWorkflowLabel, setIfSameWorkflowLabel] = useState("");

    //References
    const childRefArray: any = useRef([]);
    const branch1SelectorRef: any = useRef(null);
    const branch2SelectorRef: any = useRef(null);
    const branch3SelectorRef: any = useRef(null);
    const branch4SelectorRef: any = useRef(null);
    const branch5SelectorRef: any = useRef(null);
    const editorRef = useRef<any>(null);
    const classificationCommentsEditorRef = useRef<any>(null);

    //#region const declarations
    const [quickClassifiersListKeyValue, setQuickClassifiersListKeyValue] =
      useState<Array<TPKeyValue>>([]);

    const [organizationsRelationsKeyValue, setOrganizationsRelationsKeyValue] =
      useState<Array<TPKeyValue>>([]);

    const [reclassificationReasonKeyValue, setReclassificationReasonKeyValue] =
      useState<Array<TPKeyValue>>([]);

    const [
      reclassificationOptionsKeyValue,
      setReclassificationOptionsKeyValue,
    ] = useState<Array<TPKeyValue>>([]);

    const [sameWorkflowOptionsKeyValue, setSameWorkflowOptionsKeyValue] =
      useState<Array<TPKeyValue>>([]);
    //#end region

    //handle ref

    useImperativeHandle(ref, () => ({
      async reclassifyMassiveTasks() {
        let bolR: boolean = await internalReclassifyValidation();
        return bolR;
      },
    }));

    const loadResourcesAndOrganizationAndRelation = async () => {
      setModalConfirmQuestion(
        await TPI18N.GetText(resourceSet, "RecordModalConfirmQuestion")
      );
      setModalSuccessTitle(
        await TPI18N.GetText(resourceSet, "ModalSuccessTitle")
      );
      setSelectedRowsLabel(
        await TPI18N.GetText(resourceSet, "SelectedRowsLabel")
      );
      setRecordsProcessedLabel(
        await TPI18N.GetText(resourceSet, "RecordsProcessedLabel")
      );
      setRecordsUnprocessedLabel(
        await TPI18N.GetText(resourceSet, "RecordsUnprocessedLabel")
      );
      setModalConfirmTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle")
      );
      setCancelButtonLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
      );
      setOkButtonLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
      );
      setYesLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
      );
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));
      setDummyClassfierLabel(
        await TPI18N.GetText(resourceSetNewCase, "ClassifierLabel")
      );
      setModalAcceptLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
      );
      setModalCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
      );
      setModalSelectedBranchLabel(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "ModalSelectedBranchLabel"
        )
      );
      setModalTitleLabel(
        await TPI18N.GetText(
          resourceSetNewCase,
          "SelectBranchClassificatorTitleLabel"
        )
      );
      setEmptyLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel")
      );

      setNewReclassificationCaseLabel(
        await TPI18N.GetText(
          resourceSetReclassifyCase,
          "NewReclassifyCaseLabel"
        )
      );
      setOrganizationsRelationsLabel(
        await TPI18N.GetText(
          resourceSetReclassifyCase,
          "OrganizationsRelationsLabel"
        )
      );
      setQuickClassfierLabel(
        await TPI18N.GetText(resourceSetReclassifyCase, "QuickClassfierLabel")
      );
      setSelectOptionToApplyLabel(
        await TPI18N.GetText(
          resourceSetReclassifyCase,
          "SelectOptionToApplyLabel"
        )
      );
      setIfSameWorkflowLabel(
        await TPI18N.GetText(resourceSetReclassifyCase, "IfSameWorkflowLabel")
      );
      await getOrganizationsRelationsList();
      let newReclassificationValueList = await getKeyValueParams(
        SystemParametersEnum.S_RERE
      );

      if (newReclassificationValueList) {
        setReclassificationReasonKeyValue([...newReclassificationValueList]);
      }
      let newReclassificationOptionsValueList: Array<TPKeyValue> | null =
        await getKeyValueParams(SystemParametersEnum.S_RECLAOPT);
      if (
        newReclassificationOptionsValueList &&
        newReclassificationOptionsValueList.length > 0
      ) {
        newReclassificationOptionsValueList.shift();
        setReclassificationOptionsKeyValue([
          ...newReclassificationOptionsValueList,
        ]);
      }

      let newSameWorkflowOptionsKeyValue: Array<TPKeyValue> | null =
        await getKeyValueParams(SystemParametersEnum.S_RECLASW);
      if (
        newSameWorkflowOptionsKeyValue &&
        newSameWorkflowOptionsKeyValue.length > 0
      ) {
        newSameWorkflowOptionsKeyValue.shift();
        setSameWorkflowOptionsKeyValue([...newSameWorkflowOptionsKeyValue]);
      }
    };

    //called before reclassify case to get additional data values
    const getFinalAdditonalDataValues =
      (): Array<TEmpAdditionalDataCaseViewModel> => {
        let result: Array<TEmpAdditionalDataCaseViewModel> = [];
        for (
          let i: number = 0;
          i <= screenState.additionalDataValues.length - 1;
          i++
        ) {
          let element: TPKeyValue;
          element = screenState.additionalDataValues[i];
          //key: additional data id
          //value: addtional data value
          //value2: other properties
          //value3: should show

          if (element.value3) {
            if (childRefArray.current) {
              const keys = Object.keys(childRefArray.current);
              if (
                keys &&
                keys.findIndex((x: any) => x === element.key) !== -1
              ) {
                let controlref: any = childRefArray.current[element.key];

                let stringValue: string;
                stringValue = controlref.getValueFromParent();
                if (stringValue) {
                  let oneAddData: TEmpAdditionalDataCaseViewModel;
                  oneAddData = {
                    additionalDataId: element.key,
                    value: stringValue,
                    isMandatory: element.value2.isMandatory,
                  };
                  result.push(oneAddData);
                }
              }
            }
          }
        }
        return result;
      };

    const internalReclassifyValidation = async () => {
      let isValid: boolean = true;
      let newTempAdditionalDataValues: Array<TEmpAdditionalDataCaseViewModel> = [];
      newTempAdditionalDataValues = getFinalAdditonalDataValues();
      let recordInputDTO: ReclassifyMassiveTasksInputDTO = {
        caseListToReclassify: caseList,
        clientId: 0,
        subsidiaryId: 0,
        subsidiaryOrganizationId: 0,
        baseLevelId: screenState.baseLevelId,
        classify1BranchId: screenState.currentBranch1.trim().length == 0 ? TPGlobal.Branch.NA_BranchCode : screenState.currentBranch1,
        classify2BranchId: screenState.currentBranch2.trim().length == 0 ? TPGlobal.Branch.NA_BranchCode : screenState.currentBranch2,
        classify3BranchId: screenState.currentBranch3.trim().length == 0 ? TPGlobal.Branch.NA_BranchCode : screenState.currentBranch3,
        classify4BranchId: screenState.currentBranch4.trim().length == 0 ? TPGlobal.Branch.NA_BranchCode : screenState.currentBranch4,
        classify5BranchId: screenState.currentBranch5.trim().length == 0 ? TPGlobal.Branch.NA_BranchCode : screenState.currentBranch5,
        comments: editorRef.current.value,
        reclassificationComments: classificationCommentsEditorRef.current.value,
        reclassificationReasonId: screenState.reclassificationReasonId,
        reclassificationOptionId: screenState.reclassificationOptionSelected,
        reclassificationOptionSameWorkflowId:
          screenState.reclassificationOptionSameWorkflow,
        typistGuidUser: TPGlobal.currentUserGuid,
        tEmpAdditionalDataCase: newTempAdditionalDataValues,
      };
      console.log(recordInputDTO);
      let inputDTOValidator = new ReclassifyMassiveTasksInputDTOValidator();
      let resultValidator = inputDTOValidator.validate(recordInputDTO);
      if (!TPGlobal.TPIsEmpty(resultValidator)) {
        isValid = false;
        var listPropertyNames = Object.keys(resultValidator);
        if (listPropertyNames) {
          for (let index = 0; index < listPropertyNames.length; index++) {
            const element = listPropertyNames[index];
            let payload: any = new Object();
            if (resultValidator[element]) {
              payload.propertyName = element + "ErrorMessage";
              payload.newPropertyValue = await TPI18N.GetResource(
                resultValidator[element] as string
              );
            } else {
              payload.propertyName = element + "ErrorMessage";
              payload.newPropertyValue = "";
            }
            let command: commandType = {
              type: commandsEnum.change_property_value,
              payload: payload,
            };
            dispatch(command);
          }
        }
      }
      //validate additional data
      if (!validateAdditonalData()) {
        isValid = false;
      }
      if (!isValid) {
        return false;
      }

      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      setModalQuestionState(newModalQuestionState);
      return false;
    };

    //called before save case to validate additional data
    const validateAdditonalData = (): boolean => {
      let result: boolean = true;
      for (
        let i: number = 0;
        i <= screenState.additionalDataValues.length - 1;
        i++
      ) {
        let element: TPKeyValue;
        element = screenState.additionalDataValues[i];
        //key: additional data id
        //value: addtional data value
        //value2: other properties
        //value3: should show

        if (element.value3) {
          if (childRefArray.current) {
            const keys = Object.keys(childRefArray.current);
            if (keys && keys.findIndex((x: any) => x === element.key) !== -1) {
              let controlref: any = childRefArray.current[element.key];
              if (!controlref.validateFromParent()) {
                result = false;
                break;
              }
            }
          }
        }
      }
      return result;
    };

    //get Base Level list
    const getOrganizationsRelationsList = async () => {
      let serviceClient = new OrganizationsService();
      let expectedCodes: Array<number> = [200];
      let i: number;
      let j: number;
      setIsLoadingScreen(true);
      try {
        let responseRequest = await serviceClient.getOrganizationsByFilter(
          TPActiveOptions.ACTIVE.toString(),
          false,
          true,
          expectedCodes
        );
        let newOrganizationsRelationsListState: Array<TPKeyValue> = [];
        let organizationsListData: Array<OrganizationsViewModel>;
        organizationsListData = responseRequest;
        for (i = 0; i <= organizationsListData.length - 1; i++) {
          let relationsList: Array<TPKeyValue> = [];
          try {
            relationsList = await getRelationsList(organizationsListData[i].id);
          } catch (error) {}
          if (relationsList) {
            for (j = 0; j <= relationsList.length - 1; j++) {
              let descriptionOrganization: string;

              descriptionOrganization = organizationsListData[i].localizedName
                ? organizationsListData[i].localizedName
                : organizationsListData[i].name;
              let keyvalue: TPKeyValue = {
                key: relationsList[j].key,
                value: descriptionOrganization + " / " + relationsList[j].value,
              };
              newOrganizationsRelationsListState.push(keyvalue);
            }
          }
        }
        let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
        newOrganizationsRelationsListState.unshift(intitialKeyValue);

        setOrganizationsRelationsKeyValue(newOrganizationsRelationsListState);
        setIsLoadingScreen(false);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getOrganizationsRelationsList ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} getOrganizationsRelationsList ex`
        );
        setIsLoadingScreen(false);
      }
    };

    //get relations list bases on selected organization
    const getRelationsList = async (newOrganization: string) => {
      let serviceClient = new RelationsService();
      let expectedCodes: Array<number> = [200];
      let i: number;
      try {
        let responseRequest =
          await serviceClient.getRelationsByOrganizationAndFilter(
            newOrganization,
            TPActiveOptions.ALL.toString(),
            false,
            false,
            expectedCodes
          );

        let newRelationsListState: Array<TPKeyValue> = [];
        let relationsListData: Array<RelationsWithRestrictionsViewModel>;
        relationsListData = responseRequest;
        for (i = 0; i <= relationsListData.length - 1; i++) {
          let keyvalue: TPKeyValue = {
            key: relationsListData[i].baseLevelId,
            value: relationsListData[i].localizedName
              ? relationsListData[i].localizedName
              : relationsListData[i].name,
          };
          newRelationsListState.push(keyvalue);
        }
        return newRelationsListState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getRelationsList ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getRelationsList ex`);
        return [];
      }
    };

    const getKeyValueParams = async (
      parentId: SystemParametersEnum
    ): Promise<Array<TPKeyValue> | null> => {
      let parametersService = new ParametersService();
      let expectedCodes: Array<number> = [200];
      try {
        let responseRequest =
          await parametersService.getByParentIdAndFilterIsActive(
            parentId,
            TPActiveOptions.ACTIVE.toString(),
            false,
            true,
            expectedCodes
          );

        let newTypeKeyValueList: Array<TPKeyValue> = responseRequest.map(
          function (item) {
            return {
              key: item.id,
              value: item.localizedDescription,
            };
          }
        );
        newTypeKeyValueList.unshift({ key: "", value: "--" });
        return newTypeKeyValueList;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getKeyValueParams ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getKeyValueParams ex`);
        return null;
      }
    };

    //Action after question confirmation
    const handleCallBackModalConfirm = async (confirm: boolean) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;

      //Call endpoint to reclassify massive
      let serviceClient = new CaseMassUpdateService();
      let expectedCodes: Array<number> = [200];
      let reclassificationComments: string;
      let comments: string;

      if (editorValue.trim() == "") {
        // setHasBeenModified(true);
      }

      // if (editorRef.current.props.value)
      comments = editorRef.current.props.value;
      comments = comments === null ? "" : comments.trim();

      reclassificationComments =
        classificationCommentsEditorRef.current.props.value;
      reclassificationComments =
        reclassificationComments === null
          ? ""
          : reclassificationComments.trim();

      let recordInputDTO: ReclassifyMassiveTasksInputDTO = {
        caseListToReclassify: caseList,
        clientId: 0,
        subsidiaryId: 0,
        subsidiaryOrganizationId: 0,
        baseLevelId: screenState.baseLevelId,
        classify1BranchId: screenState.currentTree1 === TPGlobal.Tree.NA_TreeCode ? TPGlobal.Branch.NA_BranchCode : screenState.currentBranch1,
        classify2BranchId: screenState.currentTree2 === TPGlobal.Tree.NA_TreeCode ? TPGlobal.Branch.NA_BranchCode : screenState.currentBranch2,
        classify3BranchId: screenState.currentTree3 === TPGlobal.Tree.NA_TreeCode ? TPGlobal.Branch.NA_BranchCode : screenState.currentBranch3,
        classify4BranchId: screenState.currentTree4 === TPGlobal.Tree.NA_TreeCode ? TPGlobal.Branch.NA_BranchCode : screenState.currentBranch4,
        classify5BranchId: screenState.currentTree5 === TPGlobal.Tree.NA_TreeCode ? TPGlobal.Branch.NA_BranchCode : screenState.currentBranch5,
        comments: TPGlobal.stringToUTF8(comments).toString(), //editorRef.current.getContent({ format: "text" }),
        reclassificationComments: TPGlobal.stringToUTF8(
          reclassificationComments
        ).toString(),
        reclassificationReasonId: screenState.reclassificationReasonId,
        reclassificationOptionId: screenState.reclassificationOptionSelected,
        reclassificationOptionSameWorkflowId:
          screenState.reclassificationOptionSameWorkflow,
        typistGuidUser: TPGlobal.currentUserGuid,
        tEmpAdditionalDataCase: [],
      };

      if (confirm) {
        try {
          setIsLoadingScreen(true);

          let responseRequest = await serviceClient.reclassifyMassiveTasks(
            recordInputDTO,
            true,
            true,
            expectedCodes
          );
          setModalSuccess(true);
          if (responseRequest.responseResult) {
            setAnswerData(responseRequest.responseData.data);
            setIsLoadingScreen(false);
            setModalQuestionState(newModalQuestionState);
            return true;
          } else {
            setIsLoadingScreen(false);
            setModalQuestionState(newModalQuestionState);
            return false;
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} internalReclassifyValidation ex`,
            TPLogType.ERROR,
            error
          );
          console.error(
            `Error ${componentFileName} internalReclassifyValidation ex`
          );
          setIsLoadingScreen(false);
          return false;
        }
      } else {
        setModalQuestionState(newModalQuestionState);
      }
    };

    //Reducer definition
    const reducer = (state: screenStateType, action: commandType) => {
      let newScreenState: screenStateType;
      newScreenState = { ...state };
      switch (action.type) {
        case commandsEnum.change_property_value:
          newScreenState[action.payload.propertyName] =
            action.payload.newPropertyValue;
          newScreenState[`${action.payload.propertyName}ErrorMessage`] = "";
          return newScreenState;
        case commandsEnum.change_current_organization_relation:
          newScreenState.baseLevelId = action.payload.newBaseLevelId;
          newScreenState.currentTree1 = action.payload.newTree1;
          newScreenState.currentTree2 = action.payload.newTree2;
          newScreenState.currentTree3 = action.payload.newTree3;
          newScreenState.currentTree4 = action.payload.newTree4;
          newScreenState.currentTree5 = action.payload.newTree5;

          newScreenState.mustSelectLastLevelBranch1 =
            action.payload.newMustSelectLastLevelBranch1;
          newScreenState.mustSelectLastLevelBranch2 =
            action.payload.newMustSelectLastLevelBranch2;
          newScreenState.mustSelectLastLevelBranch3 =
            action.payload.newMustSelectLastLevelBranch3;
          newScreenState.mustSelectLastLevelBranch4 =
            action.payload.newMustSelectLastLevelBranch4;
          newScreenState.mustSelectLastLevelBranch5 =
            action.payload.newMustSelectLastLevelBranch5;

          newScreenState.currentLabelTree1 = action.payload.newLabelTree1;
          newScreenState.currentLabelTree2 = action.payload.newLabelTree2;
          newScreenState.currentLabelTree3 = action.payload.newLabelTree3;
          newScreenState.currentLabelTree4 = action.payload.newLabelTree4;
          newScreenState.currentLabelTree5 = action.payload.newLabelTree5;
          newScreenState.currentWorkflowType = "";
          newScreenState.baseLevelIdErrorMessage = "";

          return newScreenState;
        case commandsEnum.change_current_branch:
          switch (action.payload.treeNumber) {
            case 1:
              newScreenState.currentBranch1 = action.payload.newBranch;
              newScreenState.currentDescriptionBranch1 =
                action.payload.newDescriptionBranch;
              newScreenState.defaultBranchHierarchyDescription1 =
                action.payload.newBranchHierachyDescription;
              newScreenState.classify1BranchIdErrorMessage = "";
              break;
            case 2:
              newScreenState.currentBranch2 = action.payload.newBranch;
              newScreenState.currentDescriptionBranch2 =
                action.payload.newDescriptionBranch;
              newScreenState.defaultBranchHierarchyDescription2 =
                action.payload.newBranchHierachyDescription;
              newScreenState.classify2BranchIdErrorMessage = "";
              break;
            case 3:
              newScreenState.currentBranch3 = action.payload.newBranch;
              newScreenState.currentDescriptionBranch3 =
                action.payload.newDescriptionBranch;
              newScreenState.defaultBranchHierarchyDescription3 =
                action.payload.newBranchHierachyDescription;
              newScreenState.classify3BranchIdErrorMessage = "";
              break;
            case 4:
              newScreenState.currentBranch4 = action.payload.newBranch;
              newScreenState.currentDescriptionBranch4 =
                action.payload.newDescriptionBranch;
              newScreenState.defaultBranchHierarchyDescription4 =
                action.payload.newBranchHierachyDescription;
              newScreenState.classify4BranchIdErrorMessage = "";
              break;
            case 5:
              newScreenState.currentBranch5 = action.payload.newBranch;
              newScreenState.currentDescriptionBranch5 =
                action.payload.newDescriptionBranch;
              newScreenState.defaultBranchHierarchyDescription5 =
                action.payload.newBranchHierachyDescription;
              newScreenState.classify5BranchIdErrorMessage = "";
              break;
          }
          //workflow
          if (action.payload.newWorkflowType !== "") {
            newScreenState.currentWorkflowType = action.payload.newWorkflowType;
            newScreenState.currentWorkflowDescription =
              action.payload.newWorkflowDescription;
            //newScreenState.currentWorkflowImage = "";
          } else {
            // if (newScreenState.currentTab === currentTabsEnum.workflow) {
            //   newScreenState.currentTab = currentTabsEnum.comments;
            // }
            newScreenState.currentWorkflowType = "";
            newScreenState.currentWorkflowDescription = "";
            //newScreenState.currentWorkflowImage = "";
          }

          return newScreenState;
        case commandsEnum.organization_relation_clear:
          newScreenState.baseLevelId = "";
          newScreenState.currentTree1 = "";
          newScreenState.currentTree2 = "";
          newScreenState.currentTree3 = "";
          newScreenState.currentTree4 = "";
          newScreenState.currentTree5 = "";

          newScreenState.mustSelectLastLevelBranch1 = false;
          newScreenState.mustSelectLastLevelBranch2 = false;
          newScreenState.mustSelectLastLevelBranch3 = false;
          newScreenState.mustSelectLastLevelBranch4 = false;
          newScreenState.mustSelectLastLevelBranch5 = false;

          newScreenState.currentLabelTree1 = "";
          newScreenState.currentLabelTree2 = "";
          newScreenState.currentLabelTree3 = "";
          newScreenState.currentLabelTree4 = "";
          newScreenState.currentLabelTree5 = "";
          return newScreenState;
        case commandsEnum.change_quick_classifier:
          let found: boolean;
          newScreenState.quickClassifierId =
            action.payload.newQuickClassifierId;
          newScreenState.currentBranch1 = action.payload.newbranch1;
          newScreenState.currentBranch2 = action.payload.newbranch2;
          newScreenState.currentBranch3 = action.payload.newbranch3;
          newScreenState.currentBranch4 = action.payload.newbranch4;
          newScreenState.currentBranch5 = action.payload.newbranch5;
          newScreenState.currentDescriptionBranch1 =
            action.payload.newDescriptionBranch1;
          newScreenState.currentDescriptionBranch2 =
            action.payload.newDescriptionBranch2;
          newScreenState.currentDescriptionBranch3 =
            action.payload.newDescriptionBranch3;
          newScreenState.currentDescriptionBranch4 =
            action.payload.newDescriptionBranch4;
          newScreenState.currentDescriptionBranch5 =
            action.payload.newDescriptionBranch5;
          newScreenState.defaultBranchHierarchyDescription1 =
            action.payload.newHierarchyDescriptionBranch1;
          newScreenState.defaultBranchHierarchyDescription2 =
            action.payload.newHierarchyDescriptionBranch2;
          newScreenState.defaultBranchHierarchyDescription3 =
            action.payload.newHierarchyDescriptionBranch3;
          newScreenState.defaultBranchHierarchyDescription4 =
            action.payload.newHierarchyDescriptionBranch4;
          newScreenState.defaultBranchHierarchyDescription5 =
            action.payload.newHierarchyDescriptionBranch5;
          newScreenState.classify1BranchIdErrorMessage =
            action.payload.newBranch1ErrorMessage;
          newScreenState.classify2BranchIdErrorMessage =
            action.payload.newBranch2ErrorMessage;
          newScreenState.classify3BranchIdErrorMessage =
            action.payload.newBranch3ErrorMessage;
          newScreenState.classify4BranchIdErrorMessage =
            action.payload.newBranch4ErrorMessage;
          newScreenState.classify5BranchIdErrorMessage =
            action.payload.newBranch5ErrorMessage;

          if (action.payload.newWorkflowType !== "") {
            newScreenState.currentWorkflowType = action.payload.newWorkflowType;
            newScreenState.currentWorkflowDescription =
              action.payload.newWorkflowDescription;
            //newScreenState.currentWorkflowImage = "";
          } else {
            // if (newScreenState.currentTab === currentTabsEnum.workflow) {
            //   newScreenState.currentTab = currentTabsEnum.comments;
            // }
            newScreenState.currentWorkflowType = "";
            newScreenState.currentWorkflowDescription = "";
            //newScreenState.currentWorkflowImage = "";
          }

          //additionalData
          if (action.payload.newAdditionalDataList.length >= 1) {
            newScreenState.showAdditionalData = true;
            //insert new additonal data. Found new elementes that exists on command.payload.newAdditionalDataList
            //that not exists on newAdminState.additionalDataValues
            for (
              let i: number = 0;
              i <= action.payload.newAdditionalDataList.length - 1;
              i++
            ) {
              found = false;
              for (
                let j: number = 0;
                j <= newScreenState.additionalDataValues.length - 1;
                j++
              ) {
                if (
                  action.payload.newAdditionalDataList[i].id.toUpperCase() ===
                  newScreenState.additionalDataValues[j].key.toUpperCase()
                ) {
                  found = true;
                  newScreenState.additionalDataValues[j].value3 = true;
                  break;
                }
              }
              if (!found) {
                newScreenState.additionalDataValues.push({
                  key: action.payload.newAdditionalDataList[i].id,
                  value: "",
                  value2: action.payload.newAdditionalDataList[i],
                  value3: true,
                });
              }
            }
            //change value3 to false for elements from newAdminState.additionalDataValues that does not exists on
            //command.payload.newAdditionalDataList
            for (
              let i: number = 0;
              i <= newScreenState.additionalDataValues.length - 1;
              i++
            ) {
              found = false;
              for (
                let j: number = 0;
                j <= action.payload.newAdditionalDataList.length - 1;
                j++
              ) {
                if (
                  newScreenState.additionalDataValues[i].key.toUpperCase() ===
                  action.payload.newAdditionalDataList[j].id.toUpperCase()
                ) {
                  found = true;
                  break;
                }
              }
              if (!found) {
                newScreenState.additionalDataValues[i].value3 = false;
              }
            }
            //todo order newAdminState.additionalDataValues by order in value2
          } else {
            newScreenState.showAdditionalData = false;
            //dont clear newAdminState.additionalDataValues to cache previously gathered
            //values but hide everything
            for (
              let i: number = 0;
              i <= newScreenState.additionalDataValues.length - 1;
              i++
            ) {
              newScreenState.additionalDataValues[i].value3 = false;
            }
          }

          if (branch1SelectorRef.current) {
            branch1SelectorRef.current.resetData();
          }
          if (branch2SelectorRef.current) {
            branch2SelectorRef.current.resetData();
          }
          if (branch3SelectorRef.current) {
            branch3SelectorRef.current.resetData();
          }
          if (branch4SelectorRef.current) {
            branch4SelectorRef.current.resetData();
          }
          if (branch5SelectorRef.current) {
            branch5SelectorRef.current.resetData();
          }
          return newScreenState;
        case commandsEnum.change_additional_data_value:
          for (
            let i: number = 0;
            i <= newScreenState.additionalDataValues.length - 1;
            i++
          ) {
            if (
              newScreenState.additionalDataValues[i].key.toUpperCase() ===
              action.payload.additionalDataId.toUpperCase()
            ) {
              newScreenState.additionalDataValues[i].value =
                action.payload.newValue;
              break;
            }
          }
          return newScreenState;
        default:
          return state;
      }
    };

    const [screenState, dispatch] = React.useReducer(
      reducer,
      initialScreenState
    );

    //get base level info (organization and relation pair)
    const getBaseLevelInfo = async (BaseLevelId: string) => {
      let serviceClient = new OrganizationsRelationsService();
      let expectedCodes: Array<number> = [200];
      let baseLevelData: OrganizationsRelationsViewModel | null = null;
      try {
        let responseRequest = await serviceClient.getById(
          BaseLevelId,
          false,
          true,
          expectedCodes
        );

        baseLevelData = responseRequest;
        return baseLevelData;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getBaseLevelInfo ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getBaseLevelInfo ex`);
        return null;
      }
    };

    //get a tree info
    const getTreeInfo = async (treeId: string) => {
      let serviceClient = new TreeService();
      let expectedCodes: Array<number> = [200];
      let treeData: TreeViewModel | null = null;
      try {
        let responseRequest = await serviceClient.getTreeById(
          treeId,
          false,
          true,
          expectedCodes
        );
        treeData = responseRequest;
        return treeData;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getTreeInfo ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getTreeInfo ex`);
        return null;
      }
    };

    //get multilanguage field value
    const getRecordLanguageList = async (
      recordId: string,
      multilanguageTableName: string,
      multilanguageFieldName: string
    ): Promise<Array<MultilanguageFieldValueViewModel>> => {
      let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
      let expectedCodes: Array<number> = [200];
      try {
        let responseRequest =
          await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
            multilanguageTableName,
            multilanguageFieldName,
            recordId,
            false,
            true,
            expectedCodes
          );

        let recordInfo: Array<MultilanguageFieldValueViewModel>;
        recordInfo = [...responseRequest];
        return recordInfo;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getRecordLanguageList ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getRecordLanguageList ex`);
        return [];
      }
    };

    const handleReclassificationReasonOnChange = async (e: any) => {
      dispatch({
        type: commandsEnum.change_property_value,
        payload: {
          propertyName: "reclassificationReasonId",
          newPropertyValue: e.target.value,
        },
      });
    };

    //handle classificator branch change
    const handleOnBranchChange = async (
      index: number,
      branchId: string,
      branchDescription: string,
      branchHierachyDescription: string
    ) => {
      let branch1: string;
      let branch2: string;
      let branch3: string;
      let branch4: string;
      let branch5: string;
      let calculateWorkflowAndAdditionalData: boolean = true;
      let newWorkflowType: string;
      let newWorkflowDescription: string;
      let additionalDataList: Array<AdditionalDataViewModel> | null = null;
      let newAdditionalDataList: Array<AdditionalDataViewModel> = [];

      branch1 = "";
      branch2 = "";
      branch3 = "";
      branch4 = "";
      branch5 = "";
      newWorkflowType = "";
      newWorkflowDescription = "";

      //check to calculate workflow and additional data
      if (screenState.currentTree1 !== TPGlobal.Tree.NA_TreeCode) {
        if (index == 1) {
          branch1 = branchId;
        } else {
          branch1 = screenState.currentBranch1;
        }
        if (branch1 === "") {
          calculateWorkflowAndAdditionalData = false;
        }
      }
      if (screenState.currentTree2 !== TPGlobal.Tree.NA_TreeCode) {
        if (index == 2) {
          branch2 = branchId;
        } else {
          branch2 = screenState.currentBranch2;
        }
        if (branch2 === "") {
          calculateWorkflowAndAdditionalData = false;
        }
      }
      if (screenState.currentTree3 !== TPGlobal.Tree.NA_TreeCode) {
        if (index == 3) {
          branch3 = branchId;
        } else {
          branch3 = screenState.currentBranch3;
        }
        if (branch3 === "") {
          calculateWorkflowAndAdditionalData = false;
        }
      }
      if (screenState.currentTree4 !== TPGlobal.Tree.NA_TreeCode) {
        if (index == 4) {
          branch4 = branchId;
        } else {
          branch4 = screenState.currentBranch4;
        }
        if (branch4 === "") {
          calculateWorkflowAndAdditionalData = false;
        }
      }
      if (screenState.currentTree5 !== TPGlobal.Tree.NA_TreeCode) {
        if (index == 5) {
          branch5 = branchId;
        } else {
          branch5 = screenState.currentBranch5;
        }
        if (branch5 === "") {
          calculateWorkflowAndAdditionalData = false;
        }
      }
      //calculate workflow
      if (calculateWorkflowAndAdditionalData) {
        let workflowData: WorkflowTypeViewModel | null;
        workflowData = await getWorkflowType(
          screenState.baseLevelId,
          branch1,
          branch2,
          branch3,
          branch4,
          branch5
        );
        if (workflowData) {
          newWorkflowType = workflowData.id;
          newWorkflowDescription =
            workflowData.localizedDescription &&
            workflowData.localizedDescription !== ""
              ? workflowData.localizedDescription
              : workflowData.description;
        }
      }
      //calculate additional data list
      if (calculateWorkflowAndAdditionalData) {
        additionalDataList = await getAdditionalDataForClassification(
          branch1,
          branch2,
          branch3,
          branch4,
          branch5
        );
        if (additionalDataList && additionalDataList.length >= 1) {
          newAdditionalDataList = [...additionalDataList];
        }
      }

      let command1: commandType = {
        type: commandsEnum.change_current_branch,
        payload: {
          treeNumber: index,
          newBranch: branchId,
          newDescriptionBranch: branchDescription,
          newWorkflowType: newWorkflowType,
          newWorkflowDescription: newWorkflowDescription,
          newAdditionalDataList: newAdditionalDataList,
          newBranchHierachyDescription: branchHierachyDescription,
        },
      };
      dispatch(command1);
    };

    const handleQuickClassifierOnChange = async (e: any) => {
      const newValue: string = e.target.value;
      let index: number;
      let branch1: string;
      let branch2: string;
      let branch3: string;
      let branch4: string;
      let branch5: string;
      let branchDescription1: string;
      let branchDescription2: string;
      let branchDescription3: string;
      let branchDescription4: string;
      let branchDescription5: string;
      let branchHierarchyDescription1: string;
      let branchHierarchyDescription2: string;
      let branchHierarchyDescription3: string;
      let branchHierarchyDescription4: string;
      let branchHierarchyDescription5: string;

      let calculateWorkflowAndAdditionalData: boolean = true;
      let newWorkflowType: string;
      let newWorkflowDescription: string;
      let additionalDataList: Array<AdditionalDataViewModel> | null = null;
      let newAdditionalDataList: Array<AdditionalDataViewModel> = [];

      branch1 = "";
      branch2 = "";
      branch3 = "";
      branch4 = "";
      branch5 = "";
      branchDescription1 = "";
      branchDescription2 = "";
      branchDescription3 = "";
      branchDescription4 = "";
      branchDescription5 = "";

      newWorkflowType = "";
      newWorkflowDescription = "";
      if (newValue != "") {
        index = quickClassifiersListKeyValue.findIndex(
          (x: TPKeyValue) => x.key === newValue
        );
        if (index !== -1) {
          if (
            quickClassifiersListKeyValue[index].value2.classifier1Id &&
            quickClassifiersListKeyValue[index].value2.classifier1Id !== "" &&
            screenState.currentTree1 !== TPGlobal.Tree.NA_TreeCode
          ) {
            branch1 = quickClassifiersListKeyValue[index].value2.classifier1Id;
            branchDescription1 =
              quickClassifiersListKeyValue[index].value3.localizedClassifier1;
            branchHierarchyDescription1 =
              quickClassifiersListKeyValue[index].value4
                .localizedHierarchyClassifier1;
          } else {
            branchHierarchyDescription1 = "";
          }
          if (
            quickClassifiersListKeyValue[index].value2.classifier2Id &&
            quickClassifiersListKeyValue[index].value2.classifier2Id !== "" &&
            screenState.currentTree2 !== TPGlobal.Tree.NA_TreeCode
          ) {
            branch2 = quickClassifiersListKeyValue[index].value2.classifier2Id;
            branchDescription2 =
              quickClassifiersListKeyValue[index].value3.localizedClassifier2;
            branchHierarchyDescription2 =
              quickClassifiersListKeyValue[index].value4
                .localizedHierarchyClassifier2;
          } else {
            branchHierarchyDescription2 = "";
          }

          if (
            quickClassifiersListKeyValue[index].value2.classifier3Id &&
            quickClassifiersListKeyValue[index].value2.classifier3Id !== "" &&
            screenState.currentTree3 !== TPGlobal.Tree.NA_TreeCode
          ) {
            branch3 = quickClassifiersListKeyValue[index].value2.classifier3Id;
            branchDescription3 =
              quickClassifiersListKeyValue[index].value3.localizedClassifier3;
            branchHierarchyDescription3 =
              quickClassifiersListKeyValue[index].value4
                .localizedHierarchyClassifier3;
          } else {
            branchHierarchyDescription3 = "";
          }

          if (
            quickClassifiersListKeyValue[index].value2.classifier4Id &&
            quickClassifiersListKeyValue[index].value2.classifier4Id !== "" &&
            screenState.currentTree4 !== TPGlobal.Tree.NA_TreeCode
          ) {
            branch4 = quickClassifiersListKeyValue[index].value2.classifier4Id;
            branchDescription4 =
              quickClassifiersListKeyValue[index].value3.localizedClassifier4;
            branchHierarchyDescription4 =
              quickClassifiersListKeyValue[index].value4
                .localizedHierarchyClassifier4;
          } else {
            branchHierarchyDescription4 = "";
          }

          if (
            quickClassifiersListKeyValue[index].value2.classifier5Id &&
            quickClassifiersListKeyValue[index].value2.classifier5Id !== "" &&
            screenState.currentTree5 !== TPGlobal.Tree.NA_TreeCode
          ) {
            branch5 = quickClassifiersListKeyValue[index].value2.classifier5Id;
            branchDescription5 =
              quickClassifiersListKeyValue[index].value3.localizedClassifier5;
            branchHierarchyDescription5 =
              quickClassifiersListKeyValue[index].value4
                .localizedHierarchyClassifier5;
          } else {
            branchHierarchyDescription5 = "";
          }

          if (
            screenState.currentTree1 !== TPGlobal.Tree.NA_TreeCode &&
            branch1 === ""
          ) {
            calculateWorkflowAndAdditionalData = false;
          }
          if (
            screenState.currentTree2 !== TPGlobal.Tree.NA_TreeCode &&
            branch2 === ""
          ) {
            calculateWorkflowAndAdditionalData = false;
          }
          if (
            screenState.currentTree3 !== TPGlobal.Tree.NA_TreeCode &&
            branch3 === ""
          ) {
            calculateWorkflowAndAdditionalData = false;
          }
          if (
            screenState.currentTree4 !== TPGlobal.Tree.NA_TreeCode &&
            branch4 === ""
          ) {
            calculateWorkflowAndAdditionalData = false;
          }
          if (
            screenState.currentTree5 !== TPGlobal.Tree.NA_TreeCode &&
            branch5 === ""
          ) {
            calculateWorkflowAndAdditionalData = false;
          }

          //calculate workflow
          if (calculateWorkflowAndAdditionalData) {
            let workflowData: WorkflowTypeViewModel | null;
            workflowData = await getWorkflowType(
              screenState.baseLevelId,
              branch1,
              branch2,
              branch3,
              branch4,
              branch5
            );
            if (workflowData) {
              newWorkflowType = workflowData.id;
              newWorkflowDescription =
                workflowData.localizedDescription &&
                workflowData.localizedDescription !== ""
                  ? workflowData.localizedDescription
                  : workflowData.description;
            }
          }
          //calculate additional data list
          if (calculateWorkflowAndAdditionalData) {
            additionalDataList = await getAdditionalDataForClassification(
              branch1,
              branch2,
              branch3,
              branch4,
              branch5
            );
            if (additionalDataList && additionalDataList.length >= 1) {
              newAdditionalDataList = [...additionalDataList];
            }
          }

          //dispatch command to change 5 classifiers at a time

          let command1: commandType = {
            type: commandsEnum.change_quick_classifier,
            payload: {
              newQuickClassifierId: newValue,
              newbranch1: branch1,
              newbranch2: branch2,
              newbranch3: branch3,
              newbranch4: branch4,
              newbranch5: branch5,
              newDescriptionBranch1: branchDescription1,
              newDescriptionBranch2: branchDescription2,
              newDescriptionBranch3: branchDescription3,
              newDescriptionBranch4: branchDescription4,
              newDescriptionBranch5: branchDescription5,
              newHierarchyDescriptionBranch1: branchHierarchyDescription1,
              newHierarchyDescriptionBranch2: branchHierarchyDescription2,
              newHierarchyDescriptionBranch3: branchHierarchyDescription3,
              newHierarchyDescriptionBranch4: branchHierarchyDescription4,
              newHierarchyDescriptionBranch5: branchHierarchyDescription5,
              newWorkflowType: newWorkflowType,
              newWorkflowDescription: newWorkflowDescription,
              newAdditionalDataList: newAdditionalDataList,
              newBranch1ErrorMessage: branch1
                ? ""
                : screenState.classify1BranchIdErrorMessage,
              newBranch2ErrorMessage: branch2
                ? ""
                : screenState.classify2BranchIdErrorMessage,
              newBranch3ErrorMessage: branch3
                ? ""
                : screenState.classify3BranchIdErrorMessage,
              newBranch4ErrorMessage: branch4
                ? ""
                : screenState.classify4BranchIdErrorMessage,
              newBranch5ErrorMessage: branch5
                ? ""
                : screenState.classify5BranchIdErrorMessage,
            },
          };
          dispatch(command1);
        }
      } else {
        dispatch({
          type: commandsEnum.change_property_value,
          payload: {
            propertyName: "quickClassifierId",
            newPropertyValue: "",
          },
        });
      }
    };

    //check if there is any additional data visible
    //additonal data can appear and dissapear if you change classificators
    //previously enterered values are preserved but hidden if not exist for current classification
    const hasAnyVisibleAdditionalData = () => {
      for (
        let i: number = 0;
        i <= screenState.additionalDataValues.length - 1;
        i++
      ) {
        if ((screenState.additionalDataValues[i].value3 as boolean) === true) {
          return true;
        }
      }
      return false;
    };

    //get quick classificators for a baselevel
    const getQuickClassifiersForOrganizationRelation = async (
      newBaseLevel: string
    ): Promise<Array<TPKeyValue>> => {
      let newQuickClassifierList: Array<TPKeyValue> = [];
      let serviceClient = new QuickClassifierService();
      let expectedCodes: Array<number> = [200];
      let quickData: Array<QuickClassifierViewModel> = [];
      try {
        //todo use another method to get only valid branches
        //due to must select last level branch
        let responseRequest =
          await serviceClient.getQuickClassifiersByBaseLevel(
            newBaseLevel,
            false,
            false,
            expectedCodes
          );
        quickData = responseRequest;
        if (quickData) {
          for (let i: number = 0; i <= quickData.length - 1; i++) {
            if (quickData[i].isActive) {
              newQuickClassifierList.push({
                key: quickData[i].id,
                value: quickData[i].localizedDescription
                  ? quickData[i].localizedDescription
                  : quickData[i].description,
                value2: {
                  classifier1Id: quickData[i].classifier1Id,
                  classifier2Id: quickData[i].classifier2Id,
                  classifier3Id: quickData[i].classifier3Id,
                  classifier4Id: quickData[i].classifier4Id,
                  classifier5Id: quickData[i].classifier5Id,
                },
                value3: {
                  localizedClassifier1: quickData[i].localizedClassifier1,
                  localizedClassifier2: quickData[i].localizedClassifier2,
                  localizedClassifier3: quickData[i].localizedClassifier3,
                  localizedClassifier4: quickData[i].localizedClassifier4,
                  localizedClassifier5: quickData[i].localizedClassifier5,
                },
                value4: {
                  localizedHierarchyClassifier1:
                    quickData[i].localizedHierarchyClassifier1,
                  localizedHierarchyClassifier2:
                    quickData[i].localizedHierarchyClassifier2,
                  localizedHierarchyClassifier3:
                    quickData[i].localizedHierarchyClassifier3,
                  localizedHierarchyClassifier4:
                    quickData[i].localizedHierarchyClassifier4,
                  localizedHierarchyClassifier5:
                    quickData[i].localizedHierarchyClassifier5,
                },
              });
            }
          }
          if (newQuickClassifierList.length >= 1) {
            let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
            newQuickClassifierList.unshift(intitialKeyValue);
          }
        }
        setQuickClassifiersListKeyValue(newQuickClassifierList);
        return newQuickClassifierList;
      } catch (error) {
        newQuickClassifierList = [];
        setQuickClassifiersListKeyValue(newQuickClassifierList);
        TPLog.Log(
          `Error ${componentFileName} getQuickClassifiersForOrganizationRelation ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} getQuickClassifiersForOrganizationRelation ex`
        );
        newQuickClassifierList = [];
        return newQuickClassifierList;
      }
    };

    //get classificators for a baselevel
    const getClassificatorsConfiguration = async (
      newRelation: string,
      newBaseLevel: string
    ) => {
      let newTree1: string;
      let newTree2: string;
      let newTree3: string;
      let newTree4: string;
      let newTree5: string;

      let mustSelectLastBranch1: boolean;
      let mustSelectLastBranch2: boolean;
      let mustSelectLastBranch3: boolean;
      let mustSelectLastBranch4: boolean;
      let mustSelectLastBranch5: boolean;

      let newLabelTree1: string | null;
      let newLabelTree2: string | null;
      let newLabelTree3: string | null;
      let newLabelTree4: string | null;
      let newLabelTree5: string | null;

      let baseLevelData: OrganizationsRelationsViewModel | null = null;
      let treeData: TreeViewModel | null = null;
      let localizedField: Array<MultilanguageFieldValueViewModel> = [];
      let i;
      let found: boolean;

      newTree1 = "";
      newTree2 = "";
      newTree3 = "";
      newTree4 = "";
      newTree5 = "";

      mustSelectLastBranch1 = false;
      mustSelectLastBranch2 = false;
      mustSelectLastBranch3 = false;
      mustSelectLastBranch4 = false;
      mustSelectLastBranch5 = false;

      newLabelTree1 = "";
      newLabelTree2 = "";
      newLabelTree3 = "";
      newLabelTree4 = "";
      newLabelTree5 = "";
      baseLevelData = await getBaseLevelInfo(newBaseLevel);
      if (baseLevelData === null) {
        //todo logs
        return null;
      }
      newTree1 = baseLevelData.tree1Id;
      newTree2 = baseLevelData.tree2Id;
      newTree3 = baseLevelData.tree3Id;
      newTree4 = baseLevelData.tree4Id;
      newTree5 = baseLevelData.tree5Id;
      //get tree Info 1
      if (newTree1 !== TPGlobal.Tree.NA_TreeCode) {
        treeData = await getTreeInfo(newTree1);
        if (treeData === null) {
          //todo logs
          return null;
        } else {
          mustSelectLastBranch1 = treeData.mustSelectLastBranch;
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevel + "_C1",
            "BAseLEvel",
            "Tree1_Classify1Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return null;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree1 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree1 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return null;
            }
          }
        }
      }
      if (newTree2 !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 2
        treeData = await getTreeInfo(newTree2);
        if (treeData === null) {
          //todo logs
          return null;
        } else {
          mustSelectLastBranch2 = treeData.mustSelectLastBranch;
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevel + "_C2",
            "BAseLEvel",
            "Tree2_Classify2Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return null;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree2 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree2 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return null;
            }
          }
        }
      }
      if (newTree3 !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 3
        treeData = await getTreeInfo(newTree3);
        if (treeData === null) {
          //todo logs
          return null;
        } else {
          mustSelectLastBranch3 = treeData.mustSelectLastBranch;
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevel + "_C3",
            "BAseLEvel",
            "Tree3_Classify3Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return null;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree3 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree3 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return null;
            }
          }
        }
      }
      if (newTree4 !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 4
        treeData = await getTreeInfo(newTree4);
        if (treeData === null) {
          //todo logs
          return null;
        } else {
          mustSelectLastBranch4 = treeData.mustSelectLastBranch;
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevel + "_C4",
            "BAseLEvel",
            "Tree4_Classify4Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return null;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree4 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree4 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return null;
            }
          }
        }
      }
      if (newTree5 !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 5
        treeData = await getTreeInfo(newTree5);
        if (treeData === null) {
          //todo logs
          return null;
        } else {
          mustSelectLastBranch5 = treeData.mustSelectLastBranch;
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevel + "_C5",
            "BAseLEvel",
            "Tree5_Classify5Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return null;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree5 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree5 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return null;
            }
          }
        }
      }
      return {
        newTree1,
        newTree2,
        newTree3,
        newTree4,
        newTree5,
        mustSelectLastBranch1,
        mustSelectLastBranch2,
        mustSelectLastBranch3,
        mustSelectLastBranch4,
        mustSelectLastBranch5,
        newLabelTree1,
        newLabelTree2,
        newLabelTree3,
        newLabelTree4,
        newLabelTree5,
      };
    };

    //get additional data based on classificators
    const getAdditionalDataForClassification = async (
      branch1: string,
      branch2: string,
      branch3: string,
      branch4: string,
      branch5: string
    ) => {
      let serviceClient = new AdditionalDataService();
      let expectedCodes: Array<number> = [200, 404];
      let additionalData: Array<AdditionalDataViewModel> | null = null;
      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.getByClassifiers(
          branch1,
          branch2,
          branch3,
          branch4,
          branch5,
          "1", //active
          "1", //visible
          false,
          true,
          expectedCodes
        );

        additionalData = responseRequest;
        setIsLoadingScreen(false);
        return additionalData;
      } catch (error) {
        setIsLoadingScreen(false);
        TPLog.Log(
          `Error ${componentFileName} getAdditionalDataForClassification ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} getAdditionalDataForClassification ex`
        );
        return null;
      }
    };

    //get workflow based on classificators and base level
    const getWorkflowType = async (
      baseLevelId: string,
      branch1: string,
      branch2: string,
      branch3: string,
      branch4: string,
      branch5: string
    ) => {
      let serviceClient = new WorkflowTypeService();
      let expectedCodes: Array<number> = [200, 404];
      let workflowTypeData: WorkflowTypeViewModel | null = null;
      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.getByBaseLevelAndClassifiers(
          baseLevelId,
          branch1,
          branch2,
          branch3,
          branch4,
          branch5,
          false,
          true,
          expectedCodes
        );

        workflowTypeData = responseRequest;
        setIsLoadingScreen(false);
        return workflowTypeData;
      } catch (error) {
        setIsLoadingScreen(false);
        TPLog.Log(
          `Error ${componentFileName} getWorkflowType ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getWorkflowType ex`);
        return null;
      }
    };

    //handle additional data change
    const handleChangeAditionalDataValue = (
      idControl: string,
      newValue: any,
      additionalDataId: string
    ) => {
      let command1: commandType = {
        type: commandsEnum.change_additional_data_value,
        payload: {
          newValue: newValue,
          additionalDataId: additionalDataId,
        },
      };
      dispatch(command1);
    };

    const handleOrganizationRelationChange = async (e: any) => {
      let newBaseLevelId: string = e.target.value;
      if (!newBaseLevelId) {
        setQuickClassifiersListKeyValue([]);
        dispatch({
          type: commandsEnum.organization_relation_clear,
          payload: null,
        });
        return;
      }
      let newRelationId: string = "";
      let baseLevelData: OrganizationsRelationsViewModel | null = null;

      let newLabelTree1: string | null = "";
      let newLabelTree2: string | null = "";
      let newLabelTree3: string | null = "";
      let newLabelTree4: string | null = "";
      let newLabelTree5: string | null = "";

      let newTree1: string = "";
      let newTree2: string = "";
      let newTree3: string = "";
      let newTree4: string = "";
      let newTree5: string = "";

      let mustSelectLastBranch1: boolean = false;
      let mustSelectLastBranch2: boolean = false;
      let mustSelectLastBranch3: boolean = false;
      let mustSelectLastBranch4: boolean = false;
      let mustSelectLastBranch5: boolean = false;

      let classificationData: any;
      setIsLoadingScreen(true);

      baseLevelData = await getBaseLevelInfo(newBaseLevelId);
      if (baseLevelData === null) {
        //todo logs
        return;
      }

      newRelationId = baseLevelData.relationId;
      if (newRelationId) {
        //clasificators
        classificationData = await getClassificatorsConfiguration(
          newRelationId,
          newBaseLevelId
        );
        if (classificationData === null) {
          //todo logs
          return;
        }

        newTree1 = classificationData.newTree1;
        newTree2 = classificationData.newTree2;
        newTree3 = classificationData.newTree3;
        newTree4 = classificationData.newTree4;
        newTree5 = classificationData.newTree5;
        mustSelectLastBranch1 = classificationData.mustSelectLastBranch1;
        mustSelectLastBranch2 = classificationData.mustSelectLastBranch2;
        mustSelectLastBranch3 = classificationData.mustSelectLastBranch3;
        mustSelectLastBranch4 = classificationData.mustSelectLastBranch4;
        mustSelectLastBranch5 = classificationData.mustSelectLastBranch5;
        newLabelTree1 = classificationData.newLabelTree1;
        newLabelTree2 = classificationData.newLabelTree2;
        newLabelTree3 = classificationData.newLabelTree3;
        newLabelTree4 = classificationData.newLabelTree4;
        newLabelTree5 = classificationData.newLabelTree5;

        await getQuickClassifiersForOrganizationRelation(newBaseLevelId);
      } else {
        setQuickClassifiersListKeyValue([]);
      }
      dispatch({
        type: commandsEnum.change_current_organization_relation,
        payload: {
          newBaseLevelId: newBaseLevelId,
          newTree1: newTree1,
          newTree2: newTree2,
          newTree3: newTree3,
          newTree4: newTree4,
          newTree5: newTree5,

          newMustSelectLastLevelBranch1: mustSelectLastBranch1,
          newMustSelectLastLevelBranch2: mustSelectLastBranch2,
          newMustSelectLastLevelBranch3: mustSelectLastBranch3,
          newMustSelectLastLevelBranch4: mustSelectLastBranch4,
          newMustSelectLastLevelBranch5: mustSelectLastBranch5,

          newLabelTree1: newLabelTree1,
          newLabelTree2: newLabelTree2,
          newLabelTree3: newLabelTree3,
          newLabelTree4: newLabelTree4,
          newLabelTree5: newLabelTree5,
        },
      });
      setIsLoadingScreen(false);
    };

    const handleChangeEditor = (value: any) => {
      const filterData = value.replace(/(<([^>]+)>)/gi, "");
      setEditorValue(value);
      screenState.commentsErrorMessage =
        filterData.length > 0 ? "" : screenState.commentsErrorMessage;
    };

    useEffect(() => {
      loadResourcesAndOrganizationAndRelation();
    }, []);

    return (
      <TPLoadingOverlay active={isLoadingScreen} top={"200px"}>
        <div className="row">
          <div className="col-4">
            <TPSelect
              id="IdSelect"
              onChange={handleOrganizationRelationChange}
              dataSource={organizationsRelationsKeyValue}
              value={screenState.baseLevelId}
              labelText={organizationsRelationsLabel}
              isMandatory={true}
              errorMessage={screenState.baseLevelIdErrorMessage}
            ></TPSelect>
          </div>

          <div className="col-4">
            {quickClassifiersListKeyValue.length >= 1 && (
              <TPSelect
                id="IdSelect"
                onChange={handleQuickClassifierOnChange}
                dataSource={quickClassifiersListKeyValue}
                value={screenState.quickClassifierId}
                labelText={quickClassfierLabel}
              />
            )}
          </div>
        </div>

        <div className="row">
          {/* Classificator 1 */}
          {screenState.baseLevelId === "" &&
          screenState.currentTree1 !== TPGlobal.Tree.NA_TreeCode ? (
            <>
              <div className="col-4">
                <div className="form-group">
                  <span className="tpcircle-text">1</span>&nbsp;
                  {"-"}
                  &nbsp;
                  <TPTextBox
                    id="IdTextBox"
                    onChange={() => {
                      TPGlobal.foo();
                    }}
                    value=""
                    disabled
                    labelText={dummyClassfierLabel}
                    isMandatory
                    errorMessage={screenState.classify1BranchIdErrorMessage}
                    withCircleText
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {screenState.currentTree1 !== TPGlobal.Tree.NA_TreeCode && (
                <div className="col-4">
                  <span className="tpcircle-text">1</span>
                  &nbsp;
                  {"-"}
                  &nbsp;
                  <TPBranchSelection2
                    ref={(element: any) => {
                      branch1SelectorRef.current = element;
                    }}
                    labelText={screenState.currentLabelTree1}
                    treeId={screenState.currentTree1}
                    modalAcceptLabel={modalAcceptLabel}
                    modalCancelLabel={modalCancelLabel}
                    modalSelectedBranchLabel={modalSelectedBranchLabel}
                    modalTitle={modalTitleLabel}
                    mustSelectLastLevelBranch={
                      screenState.mustSelectLastLevelBranch1
                    }
                    value={{
                      branchId: screenState.currentBranch1,
                      branchDescription: screenState.currentDescriptionBranch1,
                      branchHierarchyDescription:
                        screenState.defaultBranchHierarchyDescription1,
                    }}
                    onChange={(
                      branchId: string,
                      branchDescription: string,
                      branchHierachyDescription: string
                    ) => {
                      handleOnBranchChange(
                        1,
                        branchId,
                        branchDescription,
                        branchHierachyDescription
                      );
                    }}
                    emptyLabel={emptyLabel}
                    errorMessage={screenState.classify1BranchIdErrorMessage}
                    autoCloseTreeModalWhenSelect={true}
                    onIsLoadingProgress={(value: boolean) => {
                      setIsLoadingScreen(value);
                    }}
                    isMandatory={true}
                  ></TPBranchSelection2>
                </div>
              )}
            </>
          )}
          {/* Classificator 2 */}
          {screenState.baseLevelId === "" &&
          screenState.currentTree2 !== TPGlobal.Tree.NA_TreeCode ? (
            <>
              <div className="col-4">
                <div className="form-group">
                  <span className="tpcircle-text">2</span>&nbsp;
                  {"-"}
                  &nbsp;
                  <TPTextBox
                    id="IdTextBox"
                    onChange={() => {
                      TPGlobal.foo();
                    }}
                    value=""
                    disabled={true}
                    labelText={dummyClassfierLabel}
                    isMandatory={true}
                    errorMessage={screenState.classify2BranchIdErrorMessage}
                    withCircleText
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {screenState.currentTree2 !== TPGlobal.Tree.NA_TreeCode && (
                <div className="col-4">
                  <span className="tpcircle-text">2</span>
                  &nbsp;
                  {"-"}
                  &nbsp;
                  <TPBranchSelection2
                    ref={(element: any) => {
                      branch2SelectorRef.current = element;
                    }}
                    labelText={screenState.currentLabelTree2}
                    treeId={screenState.currentTree2}
                    modalAcceptLabel={modalAcceptLabel}
                    modalCancelLabel={modalCancelLabel}
                    modalSelectedBranchLabel={modalSelectedBranchLabel}
                    modalTitle={modalTitleLabel}
                    mustSelectLastLevelBranch={
                      screenState.mustSelectLastLevelBranch2
                    }
                    value={{
                      branchId: screenState.currentBranch2,
                      branchDescription: screenState.currentDescriptionBranch2,
                      branchHierarchyDescription:
                        screenState.defaultBranchHierarchyDescription2,
                    }}
                    onChange={(
                      branchId: string,
                      branchDescription: string,
                      branchHierachyDescription: string
                    ) => {
                      handleOnBranchChange(
                        2,
                        branchId,
                        branchDescription,
                        branchHierachyDescription
                      );
                    }}
                    emptyLabel={emptyLabel}
                    errorMessage={screenState.classify2BranchIdErrorMessage}
                    autoCloseTreeModalWhenSelect={true}
                    onIsLoadingProgress={(value: boolean) => {
                      setIsLoadingScreen(value);
                    }}
                    isMandatory={true}
                  ></TPBranchSelection2>
                </div>
              )}
            </>
          )}
          {/* Classificator 3 */}
          {screenState.baseLevelId === "" &&
          screenState.currentTree3 !== TPGlobal.Tree.NA_TreeCode ? (
            <>
              <div className="col-4">
                <div className="form-group">
                  <span className="tpcircle-text">3</span>&nbsp;
                  {"-"}
                  &nbsp;
                  <TPTextBox
                    id="IdTextBox"
                    onChange={() => {
                      TPGlobal.foo();
                    }}
                    value=""
                    disabled={true}
                    labelText={dummyClassfierLabel}
                    isMandatory={true}
                    errorMessage={screenState.classify3BranchIdErrorMessage}
                    withCircleText
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {screenState.currentTree3 !== TPGlobal.Tree.NA_TreeCode && (
                <div className="col-4">
                  <span className="tpcircle-text">3</span>
                  &nbsp;
                  {"-"}
                  &nbsp;
                  <TPBranchSelection2
                    ref={(element: any) => {
                      branch3SelectorRef.current = element;
                    }}
                    labelText={screenState.currentLabelTree3}
                    treeId={screenState.currentTree3}
                    modalAcceptLabel={modalAcceptLabel}
                    modalCancelLabel={modalCancelLabel}
                    modalSelectedBranchLabel={modalSelectedBranchLabel}
                    modalTitle={modalTitleLabel}
                    mustSelectLastLevelBranch={
                      screenState.mustSelectLastLevelBranch3
                    }
                    value={{
                      branchId: screenState.currentBranch3,
                      branchDescription: screenState.currentDescriptionBranch3,
                      branchHierarchyDescription:
                        screenState.defaultBranchHierarchyDescription3,
                    }}
                    onChange={(
                      branchId: string,
                      branchDescription: string,
                      branchHierachyDescription: string
                    ) => {
                      handleOnBranchChange(
                        3,
                        branchId,
                        branchDescription,
                        branchHierachyDescription
                      );
                    }}
                    emptyLabel={emptyLabel}
                    errorMessage={screenState.classify3BranchIdErrorMessage}
                    autoCloseTreeModalWhenSelect={true}
                    onIsLoadingProgress={(value: boolean) => {
                      setIsLoadingScreen(value);
                    }}
                    isMandatory={true}
                  ></TPBranchSelection2>
                </div>
              )}
            </>
          )}
        </div>
        <div className="row">
          {/* Classificator 4 */}
          {screenState.baseLevelId === "" &&
          screenState.currentTree4 !== TPGlobal.Tree.NA_TreeCode ? (
            <>
              <div className="col-4">
                <div className="form-group">
                  <span className="tpcircle-text">4</span>&nbsp;
                  {"-"}
                  &nbsp;
                  <TPTextBox
                    id="IdTextBox"
                    onChange={() => {
                      TPGlobal.foo();
                    }}
                    value=""
                    disabled={true}
                    labelText={dummyClassfierLabel}
                    isMandatory={true}
                    errorMessage={screenState.classify4BranchIdErrorMessage}
                    withCircleText
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {screenState.currentTree4 !== TPGlobal.Tree.NA_TreeCode && (
                <div className="col-4">
                  <span className="tpcircle-text">4</span>
                  &nbsp;
                  {"-"}
                  &nbsp;
                  <TPBranchSelection2
                    ref={(element: any) => {
                      branch4SelectorRef.current = element;
                    }}
                    labelText={screenState.currentLabelTree4}
                    treeId={screenState.currentTree4}
                    modalAcceptLabel={modalAcceptLabel}
                    modalCancelLabel={modalCancelLabel}
                    modalSelectedBranchLabel={modalSelectedBranchLabel}
                    modalTitle={modalTitleLabel}
                    mustSelectLastLevelBranch={
                      screenState.mustSelectLastLevelBranch4
                    }
                    value={{
                      branchId: screenState.currentBranch4,
                      branchDescription: screenState.currentDescriptionBranch4,
                      branchHierarchyDescription:
                        screenState.defaultBranchHierarchyDescription4,
                    }}
                    onChange={(
                      branchId: string,
                      branchDescription: string,
                      branchHierachyDescription: string
                    ) => {
                      handleOnBranchChange(
                        4,
                        branchId,
                        branchDescription,
                        branchHierachyDescription
                      );
                    }}
                    emptyLabel={emptyLabel}
                    errorMessage={screenState.classify4BranchIdErrorMessage}
                    autoCloseTreeModalWhenSelect={true}
                    onIsLoadingProgress={(value: boolean) => {
                      setIsLoadingScreen(value);
                    }}
                    isMandatory={true}
                  ></TPBranchSelection2>
                </div>
              )}
            </>
          )}
          {/* Classificator 5 */}
          {screenState.baseLevelId === "" &&
          screenState.currentTree4 !== TPGlobal.Tree.NA_TreeCode ? (
            <>
              <div className="col-4">
                <div className="form-group">
                  <span className="tpcircle-text">5</span>&nbsp;
                  {"-"}
                  &nbsp;
                  <TPTextBox
                    id="IdTextBox"
                    onChange={() => {
                      TPGlobal.foo();
                    }}
                    value=""
                    disabled={true}
                    labelText={dummyClassfierLabel}
                    isMandatory={true}
                    errorMessage={screenState.classify5BranchIdErrorMessage}
                    withCircleText
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {screenState.currentTree5 !== TPGlobal.Tree.NA_TreeCode && (
                <div className="col-4">
                  <span className="tpcircle-text">5</span>
                  &nbsp;
                  {"-"}
                  &nbsp;
                  <TPBranchSelection2
                    ref={(element: any) => {
                      branch5SelectorRef.current = element;
                    }}
                    labelText={screenState.currentLabelTree5}
                    treeId={screenState.currentTree5}
                    modalAcceptLabel={modalAcceptLabel}
                    modalCancelLabel={modalCancelLabel}
                    modalSelectedBranchLabel={modalSelectedBranchLabel}
                    modalTitle={modalTitleLabel}
                    mustSelectLastLevelBranch={
                      screenState.mustSelectLastLevelBranch5
                    }
                    value={{
                      branchId: screenState.currentBranch5,
                      branchDescription: screenState.currentDescriptionBranch5,
                      branchHierarchyDescription:
                        screenState.defaultBranchHierarchyDescription5,
                    }}
                    onChange={(
                      branchId: string,
                      branchDescription: string,
                      branchHierachyDescription: string
                    ) => {
                      handleOnBranchChange(
                        5,
                        branchId,
                        branchDescription,
                        branchHierachyDescription
                      );
                    }}
                    emptyLabel={emptyLabel}
                    errorMessage={screenState.classify5BranchIdErrorMessage}
                    autoCloseTreeModalWhenSelect={true}
                    onIsLoadingProgress={(value: boolean) => {
                      setIsLoadingScreen(value);
                    }}
                    isMandatory={true}
                  ></TPBranchSelection2>
                </div>
              )}
            </>
          )}
          <div className="col-4">
            <TPSelect
              id="IdSelect"
              isMandatory={true}
              onChange={handleReclassificationReasonOnChange}
              dataSource={reclassificationReasonKeyValue}
              value={screenState.reclassificationReasonId}
              labelText={
                <TPLanguage
                  resourceSet={resourceSetReclassifyCase}
                  resourceId={"ReclasificationReasonlabel"}
                />
              }
              errorMessage={screenState.reclassificationReasonIdErrorMessage}
            ></TPSelect>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <TPRadioGroup
              id="IdRadioGroup"
              labelText={selectOptionToApplyLabel}
              value={screenState.reclassificationOptionSelected}
              source={reclassificationOptionsKeyValue}
              onChange={(e: any) =>
                dispatch({
                  type: commandsEnum.change_property_value,
                  payload: {
                    propertyName: "reclassificationOptionSelected",
                    newPropertyValue: e.target.value,
                  },
                })
              }
            />
          </div>
          {(screenState.reclassificationOptionSelected === "S_RECLAOPTKEW" ||
            screenState.reclassificationOptionSelected === "S_RECLAOPTRW") && (
            <div className="col-4">
              <TPRadioGroup
                id="IdRadioGroup"
                labelText={ifSameWorkflowLabel}
                value={screenState.reclassificationOptionSameWorkflow}
                source={sameWorkflowOptionsKeyValue}
                onChange={(e: any) =>
                  dispatch({
                    type: commandsEnum.change_property_value,
                    payload: {
                      propertyName: "reclassificationOptionSameWorkflow",
                      newPropertyValue: e.target.value,
                    },
                  })
                }
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-6">
            <div>
              <TPLabel
                labelText={
                  <TPLanguage
                    resourceSet={resourceSetReclassifyCase}
                    resourceId={"ReclassificationCommentsLabel"}
                  />
                }
              />
            </div>
            <TPEditor
              referece={(editor: any) => (editorRef.current = editor)}
              value={editorValue}
              placeholder=""
              onChange={(value: any) => {
                dispatch({
                  type: commandsEnum.change_property_value,
                  payload: {
                    propertyName: "commentsErrorMessage",
                    newPropertyValue: "",
                  },
                });
              }}
              initialValue=""
              isVisible={false}
            />
            <span style={{ color: "#dc3545", fontSize: "14px" }}>
              {screenState.commentsErrorMessage}
            </span>
          </div>
          <div className="col-6">
            <div>
              <TPLabel
                labelText={
                  <TPLanguage
                    resourceSet={resourceSetReclassifyCase}
                    resourceId={"MessageLabel"}
                  />
                }
              />
            </div>
            <TPEditor
              referece={(editor: any) =>
                (classificationCommentsEditorRef.current = editor)
              }
              value={editorValue}
              placeholder=""
              onChange={() => {
                dispatch({
                  type: commandsEnum.change_property_value,
                  payload: {
                    propertyName: "classificationCommentsErrorMessage",
                    newPropertyValue: "",
                  },
                });
              }}
              initialValue=""
              isVisible={false}
            />
            <span style={{ color: "#dc3545", fontSize: "14px" }}>
              {screenState.classificationCommentsErrorMessage}
            </span>
          </div>
        </div>
        {hasAnyVisibleAdditionalData() && (
          <>
            <div className="row mt-1">
              <div className="col-12">
                {hasAnyVisibleAdditionalData() && (
                  <h2 className="tpbold tpfont16">
                    <TPLanguage
                      resourceSet={resourceSetNewCase}
                      resourceId={"AdditionalDataLabel"}
                    />
                  </h2>
                )}
              </div>
            </div>
            <div className="row new-case__additional_data_container">
              {screenState.additionalDataValues.map(function (item) {
                let elementJsx;
                let index: number;
                let value: any;
                if (item.value3 === true) {
                  index = screenState.additionalDataValues.findIndex(
                    (x: TPKeyValue) => x.key == item.key
                  );
                  value = screenState.additionalDataValues[index].value;
                  elementJsx = (
                    <div
                      className="col-4 mb-2"
                      key={"adda" + screenState.caseGuid + item.key}
                    >
                      <TPRenderAdditionalDataSwitch
                        guidControl={screenState.caseGuid}
                        modeUI={TPAddtionalDataUIModes.Collect}
                        defaultValue={value}
                        itemToRender={item}
                        ref={(element: any) => {
                          childRefArray.current[item.key] = element;
                        }}
                        onChange={(
                          idControl: any,
                          newValue: string,
                          addtionalDataId: string
                        ) =>
                          handleChangeAditionalDataValue(
                            idControl,
                            newValue,
                            item.key
                          )
                        }
                      />
                    </div>
                  );
                } else {
                  elementJsx = null;
                }
                return elementJsx;
              })}
            </div>
          </>
        )}
        <div className="row mt-1">
          <div className="col-12 d-flex justify-content-between align-items-center gap-4 ">
            <div className="new-case__footer_buttons-actions">
              {screenState.currentWorkflowType != "" && (
                <div className="d-flex align-items-center gap-2">
                  <span className="tpbold" style={{ fontSize: "14px" }}>
                    <TPLanguage
                      resourceSet={resourceSetNewCase}
                      resourceId={"WorkflowTypeLabel"}
                    />
                    :
                  </span>

                  <a
                    style={{
                      fontSize: "14px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={(e: any) => {
                      e.preventDefault();
                    }}
                  >
                    {screenState.currentWorkflowDescription}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        <TPModalQuestion
          title={modalConfirmTitle}
          yesLabel={okButtonLabel}
          noLabel={cancelButtonLabel}
          question={modalConfirmQuestion}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModalConfirm}
        />

        {modalSuccess && (
          <TPModalSuccess
            modalState={{
              title: modalSuccessTitle,
              yesLabel: okButtonLabel,
              isShown: modalSuccess,
              callBackFunction: () => {
                callBackSaveSuccess();
                setModalSuccess(false);
              },
            }}
          >
            <div>
              <div>
                {selectedRowsLabel} : {caseList.length}
              </div>
              <div>
                {recordsProcessedLabel} :{" "}
                {
                  answerData.filter((item) => Boolean(item.caseReclassified))
                    .length
                }
              </div>
              <div>
                {recordsUnprocessedLabel} :{" "}
                {
                  answerData.filter((item) => Boolean(!item.caseReclassified))
                    .length
                }
              </div>
            </div>
          </TPModalSuccess>
        )}
      </TPLoadingOverlay>
    );
  }
);

export default ReclassifyMassive;
