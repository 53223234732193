import { FC, useState } from "react";
import { CSSProperties } from "styled-components";
import TPTextBox from "../../../components/bootstrap/forms/textbox/TPTextBox";
import TPLabel from "../../../components/bootstrap/forms/TPLabel/TPLabel";
import { TPIconTypes } from "../../../models/Global/TPGlobalEnums";
import DistributedListSelector from "../../DistributionListAdministration/Components/DistributionListSelector/DistributionListSelector";
import { TableDistributionModel, TypeActionDistributionSelector } from "../../../models/DistributionListAdmin/DistributionListAdmin";
import TPButton from "../../../components/bootstrap/components/buttons/TPButton";



interface Model {
    id?: string
    labelText?: string,
    ico: TPIconTypes,
    value?: string,
    onChange: Function,
    error?: string
    placeholder?: string
    isMandatory?: boolean
    width?: string
    height?: string
    marginBottom?: string
}

const TPTextBoxSelectEmail: FC<Model> = function ({
    id,
    labelText,
    ico,
    value,
    onChange,
    error,
    isMandatory,
    placeholder,
    width="100%",
    height = "auto",
    marginBottom

}) {

    const [active, setActive] = useState(false);


    return (
        <>
            {active &&
                <>
                    <DistributedListSelector
                        dataSource={value}
                        onChange={(e) => {
                            let item = e as { action: TypeActionDistributionSelector, value: any };                           
                            if (TypeActionDistributionSelector.ok == item.action) {
                                if (onChange) onChange(item.value);
                            } else if (TypeActionDistributionSelector.cancel == item.action) {

                            }
                            setActive(false);
                        }}
                    />
                </>
            }

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", width: width }} className="focused-div" tabIndex={0} >
                <TPLabel
                    labelText={labelText}
                    isMandatory={isMandatory}
                />
                <TPTextBox
                    id={"email-templates_" + id}
                    icon={ico}
                    iconClick={() => {
                        setActive(true);
                    }}
                    isInvalidChars={false}
                    withIcon={true}
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(e.target.value)
                    }                    
                    styleIco={{
                        backgroundColor: "rgb(120, 0, 150)",
                        borderRadius: "5px",
                        display: "flex",
                        height: "80%",                       
                        padding: "1%",
                        color: "white",
                        fontSize:"20px",
                        marginRight: "-7px",
                        width: "30px",
                        marginBottom: marginBottom || "0px"
                    }}
                    containerStyle={{ height: height, overflow:"hidden"  }}
                    textStyle={{
                        transform: "translateX(1px)",
                        border: "none"
                    }}
                    errorMessage={error}
                    isHorizontal={true}
                    placeholder={placeholder}
                />
            </div>
        </>
    )
}

export default TPTextBoxSelectEmail;