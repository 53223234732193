import { useEffect, useState } from "react";
import {
    ContainerFilesGridDivStyle,
    ContainerFilesListDivStyle,
    ContainerSliderStyle,
    ContainerViewDetail,
    TextPStyle,
} from "./StyleImageAdmin";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import ImagesAdminDetail from "./ImagesAdminDetail";
import ImagesAdminSlider from "./ImagesAdminSlider";
import { DataType, OperationMode } from "./useStatusImagesAdmin";
import UploadImage from "./imageAdminUploadImage";
import { Data, RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import DynamicTable, { ColumnStyles } from "../../modules/core/components/dynamic-table/DynamicTable";
import DefaultTableCard from "../../modules/core/design-system/cards/DefaultTableCard";
import ImagesAdminFile from "./ImagesAdminFile";
import TableChip from "../../modules/core/utils/table-micro-components/TableChip";
import TPGlobal from "../../helpers/TPGlobal";
import { TPI18N } from "../../services/I18nService";
import TablePrimaryItem from "../../modules/core/utils/table-micro-components/TablePrimaryItem";
import TPButton from "../../components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes, TPIconTypes } from "../../models/Global/TPGlobalEnums";
import TPTextBox from "../../components/bootstrap/forms/textbox/TPTextBox";
import { TPLog, TPLogType } from "../../helpers/TPLog";
import { MinorOption } from "../../modules/core/components/dynamic-table/TableActionItem";
import TPCheckBox from "../../components/bootstrap/forms/checkbox/TPCheckBox";
import { TableViews } from "../../modules/core/components/dynamic-table/SwitchTableView";
import TPLabel from "../../components/bootstrap/forms/TPLabel/TPLabel";
import UploadFile from "./UploadFile";

interface ImagesAdminInterface {
    ChangeImageSelection?: Function;
    mode?: string;
    startFolder?: string;
    onSelect: (selectedImage: RecursiveRoutes) => void;
}
type ContainerFolderArgs = {
    ChangeImageSelection?: Function;
    mode?: string;
    btnSelectLabel: string;
    startFolder?: string;
};

const ImagesAdminContainerFiles = ({ ChangeImageSelection, mode, startFolder, btnSelectLabel }: ContainerFolderArgs) => {
    const componentFileName: string = "ImagesAdminContainerFiles.tsx";
    const resourceSet: string = "ImagesAdminComponent";
    const resourceSetAdmin: string = "ImagesAdmin";
    const resourceSetGeneral: string = "General";


    const [filterActive, setFilterActive] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [activeUpload, setActiveUpload] = useState(false);
    const [activeFolderDelete, setActiveFolderDelete] = useState<boolean>(false);

    const [msgDelete, setMsgDelete] = useState("");
    const [optionNoLabel, setOptionNoLabel] = useState("");
    const [optionYesLabel, setOptionYesLabel] = useState("");
    const [uploadButtonTextLabel, setUploadButtonTextLabel] = useState("");
    const [fileUpload, setFileUpload] = useState<File | null>();


    const [titleModalFile, setTitleModalFile] = useState("");
    const [fileNameLabel, setfileNameLabel] = useState("");
    const [ActiveOnlyLabel, setActiveOnlyLabel] = useState("");
    const [listFilesModelTemp, setListFilesModelTemp] = useState(new Array<RecursiveRoutes>());
    const [fileUpdate, setFileUpdate] = useState(new RecursiveRoutes());
    const [delteFile, setDelteFile] = useState<RecursiveRoutes | null>();
    const [keywords, setKeywords] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileNameTemp, setFileNameTemp] = useState("");
    const [activeFile, setActiveFile] = useState(false);


    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [keywordsLabel, setKeywordsLabel] = useState("");
    const [isActivelabel, setIsActivelabel] = useState("");
    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [listFolderTemp, selectedFolderTemp] = useState<Array<RecursiveRoutes>>(
        Array<RecursiveRoutes>,
    );


    const loadResources = async () => {
        setSaveButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
        );
        setCancelButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        );
        setYesLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
        );
        setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));
        setTitleModalFile(
            await TPI18N.GetText(resourceSetAdmin, "TitleFileLabel"),
        );
        setKeywordsLabel(
            await TPI18N.GetText(resourceSetAdmin, "KeywordsLabel"),
        );
        setfileNameLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "Name"),
        );
        setIsActivelabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "Active"),
        );
        setActiveOnlyLabel(
            await TPI18N.GetText(resourceSetAdmin, "ActiveOnlyLabel"),
        );
        setDeleteLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
        );
        setMsgDelete(
            await TPI18N.GetText(resourceSetAdmin, "MsgDeleteItemLabel"),
        );
        setOptionNoLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"),
        );
        setOptionYesLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes"),
        );
        setUploadButtonTextLabel(
            await TPI18N.GetText(resourceSetGeneral, "UploadFileButtonLabel"),
        );
    }

    const value = useAppConctextImageAdmin();
    const {
        listFilesModel,
        modeSelect,
        handleMultiSelectedFileClear,
        selectedItem,
        operationMode,
        containerFolderArgs,
        handleSelected,
        handleUpdateFile,
        listMultiFileSelected,
        handleUploadImage,
        handleMultiSelectedFileAll,
        setContainerFolderArgs,
        DeleteFileOrFolderById
    } = value;

    useEffect(() => {
        selectedFolderTemp(listFilesModel);
    }, [listFilesModel]);

    const GetFilter = () => {
        if (filterActive) return listFilesModel.filter(s => s.isActive == true);
        return listFilesModel;
    }


    const handleDrop = (data: any) => {
        if (data) {
            if (fileName == "") {
                setFileName(data.name);
            }
            setFileNameTemp(data.name);
            setFileUpload(data);
        }
    };

    function handleUpdateClick(item: RecursiveRoutes): void {
        setActiveUpload(true);
        setFileUpdate(item);
        setFileName(item.fileName);
        setFileNameTemp(item.fileName);
        setKeywords(item.keywords);
        setActiveFile(item.isActive);
    }
    function handleCancelOnClick(): void {
        setActiveUpload(false);
        handleMultiSelectedFileClear();
        setActiveFolderDelete(false);
        setFileUpdate(new RecursiveRoutes());
        setDelteFile(null);
    }
    function handleDeleteFile(item: RecursiveRoutes): void {
        if (activeFolderDelete == false) {
            setActiveFolderDelete(true);
            setDelteFile(item);
        } else {
            item.isActive = false;
            setActiveFolderDelete(false);
            handleDeleteAllFile();
            setDelteFile(null);
        }
    }

    function handleDeleteAllFile(): void {
        if (!delteFile || delteFile.id + "" == "") {
            listMultiFileSelected.forEach((e) => {
                DeleteFileOrFolderById(e.id + "", DataType.file);
            });
        } else {
            DeleteFileOrFolderById(delteFile.id + "", DataType.file);
        }
        setActiveFolderDelete(false);
        handleMultiSelectedFileClear();
    }
    function handleAcceptOnClick(): void {
        let temp = fileUpdate;
        temp.fileName = fileName;
        temp.keywords = keywords;
        temp.isActive = activeFile;
        if (fileUpload)
            handleUploadImage(fileUpload, temp.fileName, temp.keywords, temp.isActive, true, temp.id);
        else
            handleUpdateFile(temp);

        setActiveUpload(false);
        setFileUpdate(new RecursiveRoutes());
    }
    function ValidateFile() {
        if (fileUpdate.fileName == "") return true;
        if (fileNameTemp == "") return true;
        return false;
    }

    const customColumns: ColumnStyles<RecursiveRoutes> = {
        fileName: ({ value, item }) => (
            <TablePrimaryItem
                value={value}
                onClick={() => handleSeletedFileAction(item)}
            />
        ),
        isActive: ({ value }) => (
            <TableChip
                value={value}
                onLabel={yesLabel}
                offLabel={noLabel}
                justify="flex-start"
            />
        ),
    };

    const handleSeletedFileAction = (fileAction: RecursiveRoutes) => {
        if (ChangeImageSelection) ChangeImageSelection(fileAction);
        handleSelected(fileAction, OperationMode.SelectFile);
    };

    const minorOptions: MinorOption<RecursiveRoutes>[] = [
        {
            key: "Edit",
            type: "edit",
            icon: TPIconTypes.edit,
            onOptionChange: (item: RecursiveRoutes) => handleUpdateClick(item),
        }
        ,
        {
            key: "Delete",
            type: "delete",
            icon: TPIconTypes.delete,
            onOptionChange: (item: RecursiveRoutes) => handleDeleteFile(item),
        },
    ];

    const capitalizeFirstLetter = (text: string): string => {
        if (!text) return "";
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    const action = (event: any, item: RecursiveRoutes) => {
        if (event == "Edit") {
            handleUpdateClick(item);
        }
        if (event == "Delete") {

            handleDeleteFile(item);
        }
        if (event == "Select") {
            handleSeletedFileAction(item);
        }

    }

    const GetTypeImage = (): string[] => {
        let list: string[] = [];
        if (TPGlobal.allowedImageExtensions) {
            list = TPGlobal.allowedImageExtensions.split("|");
        }
        return list;
    };

    useEffect(() => {
        if (loadData) {
            let temp = { ...containerFolderArgs };
            temp.mode = mode;
            temp.startFolder = startFolder;
            setContainerFolderArgs(temp)
        }
    }, [mode, startFolder, loadData]);

    useEffect(() => {
        loadResources()
            .then(function () {
                setLoadData(true);
            })
            .catch(function (error) {
                TPLog.Log(
                    `Error ${resourceSet} loadResources ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${resourceSet} loadResources ex`
                );
            });

    }, []);

    return (

        <div style={{ padding: "5px", marginLeft: "10px" }}>

            {activeUpload ? (
                <ContainerSliderStyle
                    style={{ paddingRight: "0px", position: "fixed", marginLeft: "10px" }}
                >
                    <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div style={{
                            backgroundColor: "white",
                            padding: "20px",
                            borderRadius: "5px",
                            height: "auto",
                            width: "768px",
                            display: "flex",
                            gap: "24px",

                        }} className="modal-content">
                            <div className="modal-header justify-content-between">
                                <TPLabel
                                    labelText={capitalizeFirstLetter(titleModalFile)}
                                    style={{
                                        fontSize: "20px",
                                        color: "rgba(160, 0, 149, 1)",
                                        fontWeight: "700"
                                    }}
                                />
                            </div>
                            <div className="modal-body"
                                style={{
                                    paddingTop: "1.5px",
                                    gap: "24px",
                                    display: "flex",
                                    flexDirection: "column"
                                }}>

                                <TPTextBox
                                    id="id-name-file-upload"
                                    isMandatory={true}
                                    labelText={fileNameLabel}
                                    value={fileName}
                                    onChange={(e: any) => {
                                        setFileName(e.target.value);
                                    }}
                                    containerStyle={{ with: "90%" }}
                                    maxLength={100}
                                    placeholder={""}
                                    disabled={false}
                                    errorMessage={""}
                                />
                                <TPTextBox
                                    id="id-keywords-file-upload"
                                    isMandatory={false}
                                    labelText={keywordsLabel}
                                    value={keywords}
                                    onChange={(e: any) => {
                                        setKeywords(e.target.value);
                                    }}
                                    containerStyle={{ with: "90%" }}
                                    maxLength={100}
                                    placeholder={""}
                                    errorMessage={""}
                                />


                                <UploadFile
                                    accept={GetTypeImage()}
                                    selectFileLabel={uploadButtonTextLabel}
                                    maxMBFileSize={Math.floor(TPGlobal.imageWeightAllowed / 1024)}
                                    style={{ marginTop: "0px", marginBottom: "0px" }}
                                    onFilesChange={(e: any) => handleDrop(e)}
                                    fileName={fileNameTemp}
                                    onCancelChange={
                                        () => {
                                            //setFileName("");
                                            setFileNameTemp("");
                                            /*setKeywords("");*/
                                            setFileUpload(null);
                                        }
                                    }
                                />

                                <div>
                                    <TPCheckBox
                                        id="IdCheckBox"
                                        labelText={isActivelabel}
                                        checked={activeFile}
                                        onChange={(e: any) => { setActiveFile(!activeFile); }}
                                    ></TPCheckBox>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            gap: "20px",
                                            alignItems: "center",

                                        }}
                                    >
                                        <  TPButton
                                            type={TPButtonTypes.empty}
                                            style={{
                                                border: "1px solid rgb(120, 0, 150)",
                                                color: "rgb(120, 0, 150)",
                                                padding: "5px",
                                                minWidth: "79px"
                                            }}
                                            onClick={() => handleCancelOnClick()}
                                        >
                                            {cancelButtonLabel}
                                        </TPButton>
                                        <TPButton
                                            type={TPButtonTypes.primary}
                                            isDesignSystem={true}
                                            onClick={() => handleAcceptOnClick()}
                                            disabled={(ValidateFile())}
                                            style={{
                                                padding: "5px",
                                                minWidth: "65px"
                                            }}
                                        >
                                            {saveButtonLabel}
                                        </TPButton>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContainerSliderStyle>
            ) : null}


            {activeFolderDelete &&
                <ContainerSliderStyle
                    style={{ paddingRight: "0px", position: "fixed" }}
                >
                    <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div style={{
                            backgroundColor: "white",
                            padding: "20px",
                            borderRadius: "5px",
                            height: "204px",
                            width: "464px"
                        }} className="modal-content">
                            <div className="modal-body"
                                style={{
                                    paddingTop: "1.5px",
                                    gap: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center"
                                }}>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                    }}
                                >
                                    {msgDelete}
                                </div>

                            </div>
                            <br></br>
                            <div className="modal-footer">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            gap: "20px",
                                            alignItems: "center",

                                        }}
                                    >
                                        <  TPButton
                                            type={TPButtonTypes.empty}
                                            style={{
                                                border: "1px solid rgb(120, 0, 150)",
                                                color: "rgb(120, 0, 150)",
                                                padding: "5px",
                                                minWidth: "56px"
                                            }}
                                            onClick={() => handleCancelOnClick()}
                                        >
                                            {optionNoLabel}
                                        </TPButton>

                                        <TPButton
                                            type={TPButtonTypes.primary}
                                            onClick={() => handleDeleteAllFile()}
                                            style={{ minWidth: "56px", maxWidth: "56px", padding: "7px" }}
                                        >
                                            {optionYesLabel}
                                        </TPButton>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContainerSliderStyle>
            }

            {selectedItem && operationMode === OperationMode.SelectFile ? (
                <ImagesAdminSlider btnSelectLabel={btnSelectLabel} files={listFilesModel} mode={mode} />
            ) : null}


            <DynamicTable
                id={`file-records`}
                hiddenColumns={["localizedFolderName", "folderCode", "type", "id", "parent", "extension", "dateUpload", "userUpload", "keywords", "size", "dimensions", "blobId", "imageUrl", "thumbnailUrl", "folderName", "folderNameLocalizedValues", "childrens"]}
                data={GetFilter()}
                searchPosition="right"
                maxWidthTableContainer="100%"
                minWidthCustomCard="7em"
                customHeight={"300px"}
                columnStyles={customColumns}
                hideExport
                positionHeader={"reverse"}
                childrenPosition={"into"}
                onSelectionChange={(selectedRows) => {
                    handleMultiSelectedFileAll(selectedRows);
                }
                }
                CustomCard={({ item }) => (
                    <ImagesAdminFile action={(e: any) => action(e, item)} file={item} key={item.id} mode={mode} />
                )}
                selectable={!modeSelect || mode == "edit"}
                selectedValues={listMultiFileSelected}
                minorOptions={minorOptions}
                activateSelectionAll={!modeSelect || mode == "edit"}
                switchable
                hideRefresh
                tabActive={TableViews.CARDS}

            >
                <div style={{
                    right: "350px",
                    display: "flex",
                    alignItems: "center"
                }} >
                    <TPButton
                        type={TPButtonTypes.link}
                        onClick={() => { setActiveFolderDelete(true); }}
                        id="delete-file"
                        style={(listMultiFileSelected.length <= 0) ? { color: "gray" } : {}}
                        disabled={(listMultiFileSelected.length <= 0)}
                    >
                        {deleteLabel}
                    </TPButton>
                    {mode !== "view" &&
                        <TPCheckBox
                            id="IdCheckBox-filter"
                            labelText={ActiveOnlyLabel}
                            checked={filterActive}
                            onChange={(e: any) => { setFilterActive(!filterActive) }}
                        ></TPCheckBox>
                    }
                </div>
            </DynamicTable>
        </div >

    );
};

export default ImagesAdminContainerFiles;

