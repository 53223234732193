import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPWizard from "@/components/TPWizard/TPWizard";
import TPWizardContent from "@/components/TPWizard/TPWizardContent";
import TPWizardStep from "@/components/TPWizard/TPWizardStep";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  TPButtonTypes,
  TPQuickSelectDatesEnum,
} from "@/models/Global/TPGlobalEnums";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { TPI18N } from "@/services/I18nService";
import { EmailReaderReportsService } from "@/services/MailReaderReportsService";
import { TimeZonesService } from "@/services/TimeZonesService";
import { useEffect, useState } from "react";

// Definición del enum para los pasos del proceso
enum PageStep {
  Filter = "filter",
  Results = "results",
}

const MailReaderReports = () => {
  const componentFileName: string = "MailReaderReports.tsx";
  const resourceSet: string = "MailReaderReportsComponent";

  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //#region Step 1
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [quickSelectDates, setQuickSelectDates] = useState(
    TPQuickSelectDatesEnum.today,
  );

  const [timezone, setTimezone] = useState(TPGlobal.currentUserTimeZoneId);
  const [timeZoneErrorMessage, setTimeZoneErrorMessage] = useState("");
  const [page, setPage] = useState(PageStep.Filter);
  // Estado para controlar si el botón está habilitado
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedReport, setSelectedReport] = useState<string>("");
  const [isSecondDropdownVisible, setIsSecondDropdownVisible] =
    useState<boolean>(true);
  const [isSecondDropdownDisabled, setIsSecondDropdownDisabled] =
    useState<boolean>(true);
  const [isApplyButtonEnabled, setIsApplyButtonEnabled] =
    useState<boolean>(false);
  // Estado para el segundo dropdown
  const [secondDropdownOptions, setSecondDropdownOptions] = useState<
    Array<TPKeyValue>
  >([]);
  const [selectedMailboxId, setSelectedMailboxId] = useState<string>("");
  const [reportResponse, setReportResponse] = useState<any>([]);

  //#region Resources
  const [pageTitleLabel, setPageTitleLabel] = useState(""); // Email Reports
  const [resultsPageTitle, setResultsPageTitle] = useState("");
  const [quickSelectDatesLabel, setQuickSelectDatesLabel] = useState(""); // Dates
  const [selectReportTypeLabel, setSelectReportTypeLabel] = useState("");
  const [todayLabel, setTodayLabel] = useState("");
  const [yesterdayLabel, setYesterdayLabel] = useState("");
  const [currentWeekLabel, setCurrentWeekLabel] = useState("");
  const [lastWeekLabel, setLastWeekLabel] = useState("");
  const [currentMonthLabel, setCurrentMonthLabel] = useState("");
  const [lastMonthLabel, setLastMonthLabel] = useState("");
  const [currentYearLabel, setCurrentYearLabel] = useState("");
  const [lastTwelveMonthsLabel, setLastTwelveMonthsLabel] = useState("");
  const [startDateLabel, setStartDateLabel] = useState("");
  const [endDateLabel, setEndDateLabel] = useState("");
  const [timezoneLabel, setTimezoneLabel] = useState("");
  const [timezonesToSelect, setTimeZonesToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [nextLabel, setNextLabel] = useState("");
  const [searchLabel, setSearchLabel] = useState("");
  const [resultsLabel, setResultsLabel] = useState("");
  const [applyLabel, setApplyLabel] = useState("");
  const [noDataMessage, setNoDataMessage] = useState("");

  const loadResources = async () => {
    setIsLoadingScreen(true);
    setPageTitleLabel(await TPI18N.GetText(resourceSet, "PageTitle"));
    setResultsPageTitle(
      await TPI18N.GetText(resourceSet, "StandardReportsTitle"),
    );
    setQuickSelectDatesLabel(
      await TPI18N.GetText(resourceSet, "QuickSelectDatesLabel"),
    );
    setTodayLabel(await TPI18N.GetText(resourceSet, "TodayLabel"));
    setYesterdayLabel(await TPI18N.GetText(resourceSet, "YesterdayLabel"));
    setCurrentWeekLabel(await TPI18N.GetText(resourceSet, "CurrentWeekLabel"));
    setLastWeekLabel(await TPI18N.GetText(resourceSet, "LastWeekLabel"));
    setCurrentMonthLabel(
      await TPI18N.GetText(resourceSet, "CurrentMonthLabel"),
    );
    setLastMonthLabel(await TPI18N.GetText(resourceSet, "LastMonthLabel"));
    setCurrentYearLabel(await TPI18N.GetText(resourceSet, "CurrentYearLabel"));
    setLastTwelveMonthsLabel(
      await TPI18N.GetText(resourceSet, "LastTwelveMonthsLabel"),
    );
    setStartDateLabel(await TPI18N.GetText(resourceSet, "StartDateLabel"));
    setEndDateLabel(await TPI18N.GetText(resourceSet, "EndDateLabel"));
    setTimezoneLabel(await TPI18N.GetText(resourceSet, "TimezoneLabel"));
    setNextLabel(await TPI18N.GetText(resourceSet, "NextLabel"));
    setSearchLabel(await TPI18N.GetText(resourceSet, "SearchLabel"));
    setResultsLabel(await TPI18N.GetText(resourceSet, "ResultsLabel"));
    setSelectReportTypeLabel(
      await TPI18N.GetText(resourceSet, "SelectReportTypeLabel"),
    );
    setApplyLabel(await TPI18N.GetText(resourceSet, "ApplyLabel"));
    setTimeZoneErrorMessage(
      await TPI18N.GetText(resourceSet, "InputDTOTimezoneEmpty"),
    );
    setNoDataMessage(await TPI18N.GetText(resourceSet, "NoDataMessage"));

    await getTimezones();
    setIsLoadingScreen(false);
  };

  const getPageTitle = () => {
    if (page === PageStep.Results) {
      return resultsPageTitle;
    }
    return pageTitleLabel;
  };

  const quickSelectDatesValue = [
    {
      key: TPQuickSelectDatesEnum.today,
      value: todayLabel,
    },
    {
      key: TPQuickSelectDatesEnum.yesterday,
      value: yesterdayLabel,
    },
    {
      key: TPQuickSelectDatesEnum.currentWeek,
      value: currentWeekLabel,
    },
    {
      key: TPQuickSelectDatesEnum.lastWeek,
      value: lastWeekLabel,
    },
    {
      key: TPQuickSelectDatesEnum.currentMonth,
      value: currentMonthLabel,
    },
    {
      key: TPQuickSelectDatesEnum.lastMonth,
      value: lastMonthLabel,
    },
    {
      key: TPQuickSelectDatesEnum.currentYear,
      value: currentYearLabel,
    },
    {
      key: TPQuickSelectDatesEnum.lastTwelveMonths,
      value: lastTwelveMonthsLabel,
    },
  ];

  const handleValidateSelect = (e: string) => {
    let item = Number.parseInt(e);
    let select = 1;

    if (page == PageStep.Filter) select = 1;
    if (page == PageStep.Results) select = 2;

    if (item < select) return true;

    return false;
  };

  const getPreviousSunday = (date: any) => {
    const previousSunday = new Date();

    previousSunday.setDate(date.getDate() - date.getDay());

    return previousSunday;
  };

  const getPreviousMonday = (date: any) => {
    const previousMonday = new Date();

    previousMonday.setDate(date.getDate() - date.getDay() + 1);

    return previousMonday;
  };

  const getFirstDayOfTheMonth = (year: any, month: any) => {
    return new Date(year, month, 1);
  };

  const changeDateSelectorHandler = (value: any) => {
    setQuickSelectDates(value);
    const today = new Date();
    const thisYear = today.getFullYear();
    const thisMonth = today.getMonth();
    const previousMonday = getPreviousMonday(today);
    const previousSunday = getPreviousSunday(today);
    switch (value) {
      case TPQuickSelectDatesEnum.today:
        setStartDate(today);
        setEndDate(today);
        break;
      case TPQuickSelectDatesEnum.yesterday:
        const yesterdayMilliseconds = today.getTime() - 24 * 60 * 60 * 1000;
        const yesterdayDate = new Date(yesterdayMilliseconds);
        setStartDate(yesterdayDate);
        setEndDate(yesterdayDate);
        break;
      case TPQuickSelectDatesEnum.currentWeek:
        setStartDate(previousMonday);
        setEndDate(today);
        break;
      case TPQuickSelectDatesEnum.lastWeek:
        const mondayLastWeek =
          previousSunday.getTime() - 6 * 24 * 60 * 60 * 1000;
        const mondayLastWeekDate = new Date(mondayLastWeek);
        setStartDate(mondayLastWeekDate);
        setEndDate(previousSunday);
        break;
      case TPQuickSelectDatesEnum.currentMonth:
        const firstDayOfThisMonth = getFirstDayOfTheMonth(thisYear, thisMonth);
        setStartDate(firstDayOfThisMonth);
        setEndDate(today);
        break;
      case TPQuickSelectDatesEnum.lastMonth:
        const firstDayPreviousMonth = new Date(thisYear, thisMonth - 1, 1);
        setStartDate(firstDayPreviousMonth);
        const lastDayPreviousMonth = new Date(thisYear, thisMonth, 0);
        setEndDate(lastDayPreviousMonth);
        break;
      case TPQuickSelectDatesEnum.currentYear:
        const firstDayOfThisYear = new Date(thisYear, 0, 1);
        setStartDate(firstDayOfThisYear);
        setEndDate(today);
        break;
      case TPQuickSelectDatesEnum.lastTwelveMonths:
        const twelveMonthsAgo = new Date(thisYear - 1, thisMonth, 1);
        setStartDate(twelveMonthsAgo);
        setEndDate(today);
        break;
      default:
        break;
    }
  };

  const handleChangeStartDate = (date: Date) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date: Date) => {
    setEndDate(date);
  };

  const ValidateRequiredFields = async () => {
    let atLeastOneError: boolean = false;

    if (!timezone) {
      atLeastOneError = true;

      setTimeZoneErrorMessage(timeZoneErrorMessage);
    }

    if (!startDate) {
      atLeastOneError = true;
    }

    if (!endDate) {
      atLeastOneError = true;
    }

    setIsButtonDisabled(atLeastOneError);

    return atLeastOneError;
  };

  const getTimezones = async () => {
    const TimeZoneClient = new TimeZonesService();
    const expectedCodes: Array<number> = [200, 404];
    const timezones = await TimeZoneClient.getTimeZonesCollection(
      false,
      true,
      expectedCodes,
    );
    const timezonesToSelectConverted: Array<TPKeyValue> = timezones.map((k) => {
      return {
        key: k.id,
        value: k.displayName,
      };
    });
    timezonesToSelectConverted.unshift({ key: "", value: "--" });
    setTimezone("");
    setTimeZonesToSelect(timezonesToSelectConverted);
    setIsLoadingScreen(false);
  };

  const handleNextClick = async () => {
    const hasError = await ValidateRequiredFields();
    if (!hasError) {
      setPage(PageStep.Results);
    }
  };

  const reportOptions = [
    {
      key: "REPOINBOUNDMAILBOXREADER",
      value: "Report 1: Report Inbound Mails",
    },
    {
      key: "REPOUSAGEGENAIMAILS",
      value: "Report 2: TPGenAI - Usage Reports in Mails",
    },
  ];

  // Función para manejar el cambio en el primer dropdown (Report Type)
  const handleReportSelection = async (e: any) => {
    const selectedValue = e.target.value;
    setSelectedReport(selectedValue);

    if (selectedValue === "REPOINBOUNDMAILBOXREADER") {
      setIsSecondDropdownVisible(true);
      setIsSecondDropdownDisabled(false);
      setIsApplyButtonEnabled(false);

      await getReportOptions();
    } else if (selectedValue === "REPOUSAGEGENAIMAILS") {
      setIsSecondDropdownVisible(false);
      setIsSecondDropdownDisabled(true);
      setIsApplyButtonEnabled(true);
    } else {
      setIsSecondDropdownVisible(false);
      setIsSecondDropdownDisabled(true);
      setIsApplyButtonEnabled(true);
    }
  };

  const getReportOptions = async () => {
    try {
      // Llamada al servicio para obtener las opciones del segundo dropdown
      const emailReaderReportsService = new EmailReaderReportsService();
      const showPositiveMessage = false;
      const showNegativeMessage = true;
      const options = await emailReaderReportsService.getActiveInboundMailboxes(
        1,
        showPositiveMessage,
        showNegativeMessage,
      );

      if (options && Array.isArray(options)) {
        const formattedOptions = options.map((item: any) => ({
          key: item.id,
          value: item.description,
        }));

        setSecondDropdownOptions(formattedOptions);
      }
    } catch (error) {
      console.error(
        "Error al obtener las opciones para el segundo dropdown",
        error,
      );
      setSecondDropdownOptions([]);
    }
  };

  const handleApplyClick = async () => {
    const emailReaderReportsService = new EmailReaderReportsService();
    const reportPayload = {
      ReportId: selectedReport,
      UserGuid: TPGlobal.currentUserGuid,
      StartRecord: 1,
      EndRecord: 100,
      StartDate: startDate.toISOString().replace("Z", ""),
      EndDate: endDate.toISOString().replace("Z", ""),
      DateType: "1",
      Parameter1: selectedMailboxId,
      Parameter2: "",
      TempReportFilter: [],
      isDetailReport: false,
    };

    try {
      setIsLoadingScreen(true);
      const response =
        await emailReaderReportsService.generateEmailReaderReport(
          reportPayload,
          false,
          true,
        );

      const reportDetails = response.data[0].reportDetail;

      const transformedDetails = reportDetails.map((item: any) => {
        const transformedItem: any = {};
        Object.keys(item).forEach((key) => {
          if (key.startsWith("col") && key.endsWith("Header")) {
            const valueKey = key.replace("Header", "Value");
            const newKey = item[key];
            transformedItem[newKey] = item[valueKey] || "";
          }
        });
        return transformedItem;
      });

      type DataRow = {
        [key: string]: any;
      };

      const stripHtmlTags = (str: string): string => {
        if (!str) return '';
        return str
            .replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '')
            .replace(/<br\s*\/?>/gi, ' ')
            .replace(/<\/?p[^>]*>/gi, '')
            .replace(/<\/?span[^>]*>/gi, '')
            .replace(/<[^>]+>/g, '')
            .replace(/&#[0-9]+;/g, '')
            .replace(/&nbsp;/g, ' ')
            .replace(/\n{2,}/g, ' ')
            .replace(/\r\n\r\n \r\n\r\n\r\n/g, ' ')
            .replace(/\r\n\s*\r\n/g, ' ')
            .replace(/\r\n/g, ' ')
            .trim();
      };

      const data2: DataRow[] = transformedDetails;

      if (!data2 || data2.length === 0) {
        console.error("No data to export");
        return;
      }

      const processedData: DataRow[] = data2.map((row) => {
        const newRow: DataRow = {};
        Object.entries(row).forEach(([key, value]) => {
          newRow[key] = (key === "Body" ) && typeof value === "string"
              ? stripHtmlTags(value)
              : value ?? '';
        });
        return newRow;
      });

      setReportResponse(processedData);
    } catch (error) {
    } finally {
      setIsLoadingScreen(false);
    }
  };

  const handleSecondDropdownChange = (event: any) => {
    const selectedId = event.target.value;
    setSelectedMailboxId(selectedId);
    if (selectedId) {
      setIsApplyButtonEnabled(true);
    } else {
      setIsApplyButtonEnabled(false);
    }
  };

  useEffect(() => {
    loadResources();
  }, []);

  useEffect(() => {
    ValidateRequiredFields();
  }, [startDate, endDate, timezone]);

  useEffect(() => {
    if (
      selectedReport &&
      (selectedReport !== "REPOINBOUNDMAILBOXREADER" || selectedMailboxId)
    ) {
      setIsApplyButtonEnabled(true);
    } else {
      setIsApplyButtonEnabled(false);
    }
  }, [selectedReport, selectedMailboxId]);

  return (
    <ContentVerticalNoTabsStyled>
      <div className="container-fluid">
        <TPLoadingOverlay active={isLoadingScreen}>
          <TPPageTitle>{getPageTitle()}</TPPageTitle>

          <div className="row">
            <div className="col">
              <TPWizard>
                <TPWizardStep
                  activeNewComponet={true}
                  isCheck={handleValidateSelect("1")}
                  addLine={true}
                  isActive={page == PageStep.Filter}
                  id={"id_TP_wizard_1" + resourceSet}
                  labelText={searchLabel}
                  numberText="1"
                  name="TPWizardStep"
                  callBackCommands={(e: string) => {
                    setPage(PageStep.Filter);
                  }}
                />
                <TPWizardContent
                  id="tabFilters"
                  isActive={page == PageStep.Filter}
                  name="TPWizardContent"
                >
                  <div className="row">
                    <div className="col">
                      <TPPageSectionTitle>
                        {quickSelectDatesLabel}
                      </TPPageSectionTitle>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <TPRadioGroup
                          id="IdRadioGroup"
                          onChange={(e: any) =>
                            changeDateSelectorHandler(e.target.value)
                          }
                          value={quickSelectDates}
                          source={quickSelectDatesValue}
                        ></TPRadioGroup>
                      </div>
                    </div>
                  </div>

                  <br></br>
                  <div className="row">
                    <div className="col-11">
                      <TPPageSection>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            justifyContent: "start",
                            marginTop: "10px",
                          }}
                        >
                          <div style={{ display: "flex", gap: "10px" }}>
                            <TPDatePicker
                              selectedDate={startDate}
                              onChangeDate={(e: Date) =>
                                handleChangeStartDate(e)
                              }
                              labelText={startDateLabel}
                              isHorizontal={false}
                              isMandatory={true}
                            ></TPDatePicker>
                            <TPDatePicker
                              selectedDate={endDate}
                              onChangeDate={(e: Date) => handleChangeEndDate(e)}
                              labelText={endDateLabel}
                              isHorizontal={false}
                              isMandatory={true}
                            ></TPDatePicker>
                            {timeZoneErrorMessage != "" && (
                              <div style={{ height: "85px" }}></div>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-end",
                            }}
                          >
                            <TPLabel
                              labelText={timezoneLabel}
                              isMandatory={true}
                            />
                            <TPSelect
                              id={"id_select2_" + resourceSet}
                              labelText={""}
                              isMandatory={false}
                              withIcon={false}
                              value={timezone}
                              errorMessage={timeZoneErrorMessage}
                              containerStyle={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              onChange={(e: any) => {
                                setTimezone(e.target.value);
                                setTimeZoneErrorMessage("");
                              }}
                              dataSource={timezonesToSelect}
                            />
                          </div>
                        </div>
                      </TPPageSection>
                    </div>
                  </div>
                  <br></br>
                  <div className="row mt-2">
                    <div className="col-11">
                      <TPPageAcceptCancelButtonsContainer>
                        <TPButton
                          id="Id_btn_1"
                          type={
                            isButtonDisabled
                              ? TPButtonTypes.secondary
                              : TPButtonTypes.primary
                          }
                          onClick={handleNextClick}
                          disabled={isButtonDisabled}
                        >
                          {nextLabel}
                        </TPButton>
                      </TPPageAcceptCancelButtonsContainer>
                    </div>
                  </div>
                </TPWizardContent>
                <TPWizardStep
                  activeNewComponet={true}
                  addLine={false}
                  isCheck={handleValidateSelect("2")}
                  isActive={page == PageStep.Results}
                  id={"Id_results_2" + resourceSet}
                  labelText={resultsLabel}
                  numberText="2"
                  name="TPWizardStep"
                />
                <TPWizardContent
                  id={"Id_tab_results_" + resourceSet}
                  isActive={page == PageStep.Results}
                  name="TPWizardContent"
                >
                  <div className="row m-1">
                    <div className="col">
                      <TPPageSectionTitle>
                        {selectReportTypeLabel}
                      </TPPageSectionTitle>
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: 10 }}>
                    <div>
                      <TPSelect
                        placeholder="Select"
                        onChange={handleReportSelection}
                        dataSource={reportOptions}
                        value={selectedReport}
                        minWidth={256}
                      />
                    </div>
                    {isSecondDropdownVisible && (
                      <div>
                        <TPSelect
                          placeholder="Selected"
                          onChange={handleSecondDropdownChange}
                          dataSource={secondDropdownOptions}
                          value={selectedMailboxId}
                          minWidth={256}
                          disabled={isSecondDropdownDisabled}
                        />
                      </div>
                    )}

                    <TPButton
                      onClick={handleApplyClick}
                      disabled={!isApplyButtonEnabled}
                      type={TPButtonTypes.primary}
                      isDesignSystem
                      style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        height: "35px",
                      }}
                    >
                      {applyLabel}
                    </TPButton>
                  </div>
                  <br />

                  <DynamicTable
                    data={reportResponse}
                    noDataMessage={noDataMessage}
                    withPreferences
                    hiddenSearch
                    exportNameFile={selectedReport}
                  />
                </TPWizardContent>
              </TPWizard>
            </div>
          </div>
        </TPLoadingOverlay>
      </div>
    </ContentVerticalNoTabsStyled>
  );
};

export default MailReaderReports;
