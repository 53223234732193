import { OptionButtonPairStyled } from "@/components/styled/StyledForms";
import { FC, useEffect, useState } from "react";
import TPButton from "../../components/buttons/TPButton";
import allThemes from "@/assets/styles/theme";
import { TPI18N } from "@/services/I18nService";
import TPGlobal from "@/helpers/TPGlobal";

interface TPOptionButtonPairProps {
  onConfirm: () => void;
  onCancel: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmDisabled?: boolean;
  cancelDisabled?: boolean;
}

export const TPOptionButtonPairStyled: FC<TPOptionButtonPairProps> = function({
  onCancel,
  onConfirm,
  cancelLabel,
  confirmLabel,
  confirmDisabled,
  cancelDisabled
 }) {

  const [defaultConfirmLabel, setDefaultConfirmLabel] = useState("");
  const [defaultCancelLabel, setDefaultCancelLabel] = useState("");

  const getLabels = async function() {
    setDefaultCancelLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"));
    setDefaultConfirmLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"));
  }

  useEffect(() => {getLabels()}, []);

  return (
    <OptionButtonPairStyled>
      <TPButton
        onClick={onCancel}
        isDesignSystem
        style={{
          background: "white",
          color: allThemes.base.purplePrimary,
          padding: "11px 16px"
        }}
        disabled={cancelDisabled}
      >
        {cancelLabel ?? defaultCancelLabel}
      </TPButton>
      <TPButton
        onClick={onConfirm}
        isDesignSystem
        style={{ padding: "11px 16px" }}
        disabled={confirmDisabled}
      >
        {confirmLabel ?? defaultConfirmLabel}
      </TPButton>
    </OptionButtonPairStyled>
  )
}