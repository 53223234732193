import { TPFormControlContainerStyled } from "@/helpers/generalStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { FC, forwardRef, useId, useRef } from "react";
import { Input } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import TPLabel from "../bootstrap/forms/TPLabel/TPLabel";
import { InputStyled } from "./TPDatePickerStyles";
import { ComplementsRenderTP } from "../../helpers/TPKeyValue";
import { TPRow } from "../TPPage/tpPageStyles";
import { createGlobalStyle } from "styled-components";
import { format, parse } from "date-fns";

type TPDatePickerProps = {
    labelText: string | JSX.Element;
    isHorizontal?: boolean;
    isMandatory: boolean;
    selectedDate?: Date | null;
    onChangeDate?: Function | null;
    minDate?: Date | null | undefined;
    maxDate?: Date | null | undefined;
    customDateFormat?: string | null;
    disabled?: boolean;
    children?: JSX.Element;
    errorMessage?: string;
    defaultValue?: string;
    containerStyle?: any;
    inputStyle?: React.CSSProperties;
    labelStyle?: any;
    isTextBefore?: boolean;
    complementsRenderTP?: ComplementsRenderTP;
};



const TPDatePicker: FC<TPDatePickerProps> = ({
    labelText,
    selectedDate = null,
    onChangeDate,
    isHorizontal = false,
    isMandatory,
    minDate = null,
    maxDate = null,
    disabled,
    customDateFormat = TPGlobal.dateFormat,
    children = null,
    errorMessage = "",
    containerStyle,
    inputStyle,
    labelStyle,
    isTextBefore = true,
    defaultValue = "",
    complementsRenderTP
}) => {
    const theId = `textbox-${useId()}`;
    const handleOnchange = (date: Date) => {
        if (onChangeDate && date) {      
            onChangeDate(date, customDateFormat);
        }
    };

    const separator = "||";
    const DatePickerWrapperStyles = createGlobalStyle`
    .date_picker.full-width {
        width: 100%;
    }
`;

    const handleValidateCustomDateFormat = (index: number) => {
        if (!customDateFormat) return false;
        let formatDate = TPGlobal.dateFormatsAdditionalData.find(s => s.key == customDateFormat);
        if (!formatDate) return false;
        if (!formatDate.value2 || formatDate.value2 == "") return false;
        if (formatDate.value2.split(separator).length < (index + 1)) return false;
        return true;
    }

    const handleCustomDateFormat = (index: number) => {       
        if (!customDateFormat) return TPGlobal.dateTimeFormat;
        let formatDate = TPGlobal.dateFormatsAdditionalData.find(s => s.key == customDateFormat);
        if (!formatDate) return TPGlobal.dateTimeFormat;
        if (!formatDate.value2 || formatDate.value2 == "") return TPGlobal.dateTimeFormat;
        if (formatDate.value2.split(separator).length >= (index + 1)) {
            return formatDate.value2.split(separator)[index].trim();
        }
        return TPGlobal.dateTimeFormat;
    }

    const handleConverSelectedDate = () => {  
        if (selectedDate) {
            const dateObj = selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
            const newformattedDate = format(dateObj, handleCustomDateFormat(0));
            const formattedDate = parse(newformattedDate, handleCustomDateFormat(0), new Date());
            return formattedDate;
        }
        return null;
    }


    const DateCustomImput = forwardRef((props: any, ref) => {
        return (
            <>
                <TPFormControlContainerStyled
                    isHorizontal={isHorizontal}
                    className={`${true ? "input-group tp-padding-right-0" : ""} ${errorMessage != "" ? "is-invalid" : ""
                        }`}
                    style={containerStyle}
                >
                    <InputStyled
                        {...props}
                        className={`form-control ${errorMessage ? "is-invalid" : ""}`}
                        ref={ref}
                        value={props.value || defaultValue}
                        style={{
                            ...inputStyle,
                            border: errorMessage ? "1px solid red" : "",
                        }}
                    />

                    <div className="input-group-append" style={{ paddingRight: "5px" }}>
                        <span>
                            <TPIcon
                                iconType={TPIconTypes.calendarToday}
                                onClick={props.onClick}
                            />
                        </span>
                    </div>
                </TPFormControlContainerStyled>
            </>
        );
    });
    const inputRef = useRef(null);
    return (
        <>
            <TPFormControlContainerStyled
                isHorizontal={isHorizontal}
                style={containerStyle}
                className={"mt-0 tp-form-input"}
            >
                {isTextBefore && labelText && (
                    <TPLabel
                        htmlFor={theId}
                        isMandatory={isMandatory}
                        labelText={labelText}
                        style={labelStyle}
                    />
                )}              
                <div className={`${errorMessage != "" ? "is-invalid" : ""}`} style={(handleValidateCustomDateFormat(1)) ? { minWidth: "350px" } : {}} >
                    <DatePicker
                        selected={handleConverSelectedDate()}
                        onChange={handleOnchange}
                        minDate={minDate}
                        maxDate={maxDate}
                        disabled={disabled}
                        timeIntervals={1}
                        showTimeSelect={(handleValidateCustomDateFormat(1))}
                        timeFormat={(handleValidateCustomDateFormat(1) ? handleCustomDateFormat(1) : handleCustomDateFormat(0))}
                        dateFormat={`${handleCustomDateFormat(0)}`}
                        customInput={<DateCustomImput ref={inputRef}></DateCustomImput>}
                        placeholderText={(handleCustomDateFormat(0))}

                    >
                        {children ?? null}
                    </DatePicker>
                    <DatePickerWrapperStyles />
                </div>

                {!isTextBefore && labelText && (
                    <TPLabel
                        htmlFor={theId}
                        isMandatory={isMandatory}
                        labelText={labelText}
                        style={labelStyle}
                    />
                )}
                {errorMessage != "" && (
                    <div className="invalid-feedback">{errorMessage}</div>
                )}
            </TPFormControlContainerStyled>
        </>
    );
};

export default TPDatePicker;
