import React, {FC, ReactElement, useContext, useEffect, useState} from "react";
import MainItemTabType from "./MainMenuItemTabType";
import {
    HorizontalTabsFavoritesIconContainerStyled,
    HorizontalTabsFavoritesIconsContainerStyled,
    HorizontalTabsFavoritesIconsStyled,
    HorizontalTabsUserInfoContainerStyled,
    NotificationCenterContainerStyled,
    TPHorizontalTabsContainerStyle,
    TPHorizontalTabsNavTabsContainerStyle,
    TPHorizontalTabsNavTabsContentStyle,
    TPUserDataContainerStyled,
    TPUserDataWrapperContainerStyled,
} from "./mainMenuStyles";
//pages
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import VerticalTabsAdminContainer from "@/layouts/VerticalTabs/VerticalTabsAdminContainer";
import {TPButtonTypes, TPIconTypes} from "@/models/Global/TPGlobalEnums";
import NewCase from "@/pages/NewCase/NewCase";

import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {TPCopilotWindow} from "@/components/TPCopilotWindow/TPCopilotWindow";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import {useCopilot} from "@/contexts/TPCopilotContext";
import TPNotificationContext from "@/contexts/TPNotificationContext";
import TPGlobal from "@/helpers/TPGlobal";
import {notificationCenterItem} from "@/models/NotificationCenter/NotificationCenterModel";
import CaseMassUpdate from "@/pages/CaseMassUpdate/CaseMassUpdate";
import CaseSearchByFilters from "@/pages/CaseSearch/CaseSearchByFilters";
import CaseSearchBuNumber from "@/pages/CaseSearch/CaseSearchByNumber";
import ConfigurationValuesNew from "@/pages/ConfigurationValues/ConfigurationValuesNew";
import DashBoard from "@/pages/Dashboard/Dashboard";
import GroupTakeNextComponent from "@/pages/GroupTakeNext/GroupTakeNext";
import {
    GroupTakeNextSlice,
    GroupTakeNextSliceModel,
} from "@/pages/GroupTakeNext/GroupTakeNextSlice";
import TakeNextQueueComponent from "@/pages/GroupTakeNext/TakeNextQueue";
import ImagesAdmin from "@/pages/ImagesAdmin/ImagesAdmin";
import MergeCustomersMain from "@/pages/MergeCustomers/MergeCustomersMain";
import {MergeCustomersStore} from "@/pages/MergeCustomers/redux/MergeCustomersStore";
import MyTasks from "@/pages/MyTasks/MyTasks";
import ProfileRestrictions from "@/pages/ProfileRestrictions/ProfileRestrictions";
import ReassignUsersAdmin from "@/pages/ReassignUsers/ReassignUsersAdmin";
import StandardReports from "@/pages/Reports/StandardReports";
import SearchComponent from "@/pages/SearchCustomerData/SearchComponent";
import ActiveTeamMembersAdmin from "@/pages/SupervisorCockpit/ActiveTeamMembers/ActiveTeamMembersAdmin";
import TPToastMessages from "@/pages/ToastMessage/TPToastMessages";
import Wallboard from "@/pages/Wallboard/Wallboard";
import WorkflowReAssignment from "@/pages/Workflow/WorkflowReAssignment";
import {StoreModel} from "@/redux/store";
import {TPI18N} from "@/services/I18nService";
import {
    Popover,
    Tooltip,
    Typography,
    Button,
    Skeleton,
    Box,
} from "@mui/material";
import {Provider, useDispatch, useSelector} from "react-redux";
import {AppProviderImagesAdmin} from "../../pages/ImagesAdmin/ImagesAdminContextImagesAdmin";
import {TabsCaseViewerSlice} from "../VerticalTabs/_redux/TabsCaseViewerSlice";
import VerticalTabsCaseViewerContainer from "../VerticalTabs/VerticalTabsCaseViewerContainer";
import {
    HorizontalTabsSlice,
    HorizontalTabsStateModel,
} from "./_redux/HorizonatalTabsSlice";
import FrequentAnswers from "@/pages/FrequentAnswers/FrequentAnswers";
import MailReaderReports from "@/pages/MailReader/MailReaderReports";
import ConfigurationGeneral from "@/pages/ConfigurationGeneral/ConfigurationGeneral";
import NoDataImg from "@/assets/images/TPImages/tp-image-grid.png";
import UserProfile from "@/modules/core/design-system/user-data/UserProfile";
import DefaultSmallAvatarIcon from "@/assets/images/TPIcons/DefaultSmallAvatarIcon";
import TPGeneralContext from "@/contexts/TPGeneralContext";
import TPModalStationId, {
    TPModalStationIdState,
} from "@/layouts/TPModalStationId/TPModalStationId";
import {BrowserKeepAliveService} from "@/services/BrowserKeepAliveService";
import {TPLog, TPLogType} from "@/helpers/TPLog";
import {
    BrowserClientInputDTO,
    BrowserClientUpdateInputDTO,
} from "@/models/BrowserClient/BrowserClientInputDTO";
import CockpitInfoCard from "@/pages/SupervisorCockpit/utils/CockpitInfoCard";
import {UserService} from "@/services/UserService";
import {UserPhotoResponse, UserStatusModel} from "@/models/Users/UserModels";
import {InfoCardTypes} from "@/pages/SupervisorCockpit/supervisor-cockpit.model";
import {Timer} from "@/components/TPTimer/TPTimer";
import {capitalizeText, getImagePrefix} from "@/helpers/TPFunctions";
import {ProfileService} from "@/services/ProfileService";
import {ProfilesGetViewModel} from "@/models/Profiles/ProfilesModels";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import {MenuItemViewModelForRender} from "@/models/MenuItems/MenuItemsModels";
import {MenuItemsService} from "@/services/MenuItemService";

type MainHorizontalTabsProps = {
    horizontalTabsArray: Array<MainItemTabType>;
    handleTabClickCallBack: Function;
    handleCloseClickCallBack: Function;
    handleMouseMoveContentArea: Function;
    menuItemClickCallback: Function;
};

const HORIZONTAL_TAB_REQUIRED =
    "TPClientCloudMain.Client.Components.Cases.CaseViewer";

type TabTitleManager = {
    "TPClientCloudMain.Client.Components.EventsManager.Projects": string;
    "TPClientCloudMain.Client.Components.EventsManager.LoadHistory": string;
    "TPClientCloudMain.Client.Components.SupervisorCockpit.Wallboard": string;
};

type TabsTitleType =
    | "TPClientCloudMain.Client.Components.EventsManager.Projects"
    | "TPClientCloudMain.Client.Components.EventsManager.LoadHistory"
    | "TPClientCloudMain.Client.Components.SupervisorCockpit.Wallboard";

const MainHorizontalTabs: FC<MainHorizontalTabsProps> = ({
                                                             horizontalTabsArray,
                                                             handleTabClickCallBack,
                                                             handleCloseClickCallBack,
                                                             handleMouseMoveContentArea,
                                                             menuItemClickCallback,
                                                         }): ReactElement => {
    const resourceSet: string = "MainHorizontalTabsComponent";
    const componentFileName: string = "MainHorizontalTabs.tsx";
    const dispatch = useDispatch();

    const {tabs} = useSelector(
        (s: StoreModel) => s[HorizontalTabsSlice.name],
    ) as HorizontalTabsStateModel;

    const {currentRecord, online} = useSelector(
        (state: StoreModel) => state[GroupTakeNextSlice.name],
    ) as GroupTakeNextSliceModel;
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const notificationContext: any = React.useContext(TPNotificationContext);
    const generalContext: any = React.useContext(TPGeneralContext);

    const getMenuItems = async (userGuid: string) => {
        const responseRequest: Array<MenuItemViewModelForRender> =
            await MenuItemsService.getMenuItemsByUser(
                userGuid.toUpperCase(),
                false,
                false,
                TPGlobal.defaultExpectedCodes
            );

        return responseRequest;
    };

    const loadProfileInfo = async () => {
        let serviceClient = new ProfileService();
        let expectedCodes: Array<number> = [200];

        try {
            let responseRequest = await serviceClient.getProfileById(
                generalContext.globalUserInfo.profileId,
                false,
                true,
                expectedCodes
            );
            let recordInfo: ProfilesGetViewModel;
            recordInfo = {...responseRequest};
            if (recordInfo.urlLink) {
                let items = await getMenuItems(generalContext.globalUserInfo.userGuid)
                const foundItem = items.find(item => item.menuItemLink === recordInfo.urlLink);
                menuItemClickCallback(recordInfo.urlLink,foundItem!.menuItemName);
                setIsLoadingScreen(false)

            } else {
                setIsLoadingScreen(false)
                menuItemClickCallback("TPClientCloudMain.Client.Components.Cases.NewCaseComponent", await TPI18N.GetText(resourceSet, "NewCaseLabel"),);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getProfileById ex`,
                TPLogType.ERROR,
                error
            );

        }
    }


    const [newCaseLabel, setNewCaseLabel] = useState("");
    const [myTaskLabel, setMyTaskLabel] = useState("");
    const [notificationsLabel, setNotificationsLabel] = useState("");
    const [dashboardLabel, setDashboardLabel] = useState("");
    const [exitLabel, setExitLabel] = useState("");
    const [copilotLabel, setCopilotLabel] = useState("");
    const [isCopilotOpened, setIsCopilotOpened] = useState(false);
    const [userStatusInfo, setUserStatusInfo] = useState<UserStatusModel | null>(
        null,
    );
    const [welcomeLabel, setWelcomeLabel] = useState("");
    const [selectModuleLabel, setSelectModuleLabel] = useState("");
    const [currentStatusLabel, setcurrentStatusLabel] = useState("");
    const [currentUserPhoto, setCurrentUserPhoto] = useState("");
    const casecontext: any = React.useContext(TPCaseViewerContext);

    const hasHorizontalTabs = horizontalTabsArray.length > 0;
    const currentUserGuid: string = TPGlobal.currentUserGuid;

    const handleClickTab = (tabId: number) => {
        handleTabClickCallBack(tabId);
    };

    const handleCloseClick = (e: any, tabId: number) => {
        e?.stopPropagation();
        const tab = tabs.find((t) => t.tabId === tabId);
        if (tab?.tabType === HORIZONTAL_TAB_REQUIRED) {
            dispatch(TabsCaseViewerSlice.actions.remove());
        } else {
            handleCloseClickCallBack(tabId);
        }
    };

    const handleMouseClickEvent = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        tabId: number,
        tabType?: string,
    ) => {
        if (event.button === 1) {
            handleCloseClick(event, tabId);
            if (tabType === "TPClientCloudMain.Client.Components.Cases.CaseViewer")
                dispatch(GroupTakeNextSlice.actions.setCurrentCase(-1));
            if (
                tabType === "TPClientCloudMain.Client.Components.FollowUp.TakeNextQueue"
            )
                dispatch(GroupTakeNextSlice.actions.setCurrentRecord(-1));
        }
    };

    const [isNotificationCenterOpened, setIsNotificationCenterOpened] =
        useState(false);

    const [
        notificationCenterLastNotificationsTitleLabel,
        setNotificationCenterLastNotificationsTitleLabel,
    ] = useState("");

    const loadResources = async () => {
        setIsLoadingScreen(true)
        setNewCaseLabel(await TPI18N.GetText(resourceSet, "NewCaseLabel"));
        setMyTaskLabel(await TPI18N.GetText(resourceSet, "MyTaskLabel"));
        setNotificationsLabel(
            await TPI18N.GetText(resourceSet, "NotificationsLabel"),
        );

        setNotificationCenterLastNotificationsTitleLabel(
            await TPI18N.GetText(resourceSet, "LastNotificationsTitleLabel"),
        );
        setExitLabel(await TPI18N.GetText(resourceSet, "ExitLabel"));

        setDashboardLabel(await TPI18N.GetText(resourceSet, "DashboardLabel"));

        setCopilotLabel(await TPI18N.GetText(resourceSet, "CopilotLabel"));

        setWelcomeLabel(await TPI18N.GetText(resourceSet, "WelcomeLabel"));
        setSelectModuleLabel(
            await TPI18N.GetText(resourceSet, "SelectModuleLabel"),
        );
        setcurrentStatusLabel(
            await TPI18N.GetText(resourceSet, "CurrentStatusLabel"),
        );

        // Launch new case


        // if (
        //   TPGlobal.isDevelopEnviroment &&
        //   TPGlobal.alreadyShowDashBoard === false
        // ) {
        //   TPGlobal.alreadyShowDashBoard = true;
        //   //launch dashboard
        //   menuItemClickCallback(
        //     "TPClientCloudMain.Client.Components.DashBoard",
        //     await TPI18N.GetText(resourceSet, "DashboardLabel"),
        //   );
        // }

        setStationIdLabel(await TPI18N.GetText("MainMenu", "StationIdLabel"));
        setStationIdTitleLabel(
            await TPI18N.GetText("MainMenu", "StationIdTitleLabel"),
        );
        setStationIdYesLabel(await TPI18N.GetText("MainMenu", "StationIdYesLabel"));
        setStationIdNoLabel(await TPI18N.GetText("MainMenu", "StationIdNoLabel"));
    };

    const [isRemoving, setIsRemoving] = useState<Array<number>>([]);

    const getTabLabel = (
        label: string,
        isSubtitle: boolean = false,
    ): JSX.Element => {
        const labelExtended: boolean = label.length > 20;
        const styledTooltip = (
            <Typography sx={{fontFamily: "Noto Sans", fontSize: "10px"}}>
                {label}
            </Typography>
        );
        return (
            <Tooltip title={labelExtended && styledTooltip} placement="right">
                <label
                    className={`${isSubtitle ? "tp-subtitle-1-horizontal-tab" : "tp-title-horizontal-tab"}`}
                >
                    {label}
                </label>
            </Tooltip>
        );
    };

    const handleVerticalCallEvent = async (command: any) => {
        const tabTitles: TabTitleManager = {
            "TPClientCloudMain.Client.Components.EventsManager.Projects":
                await TPI18N.GetText("ProjectsAdminComponent", "TitleLabel"),
            "TPClientCloudMain.Client.Components.EventsManager.LoadHistory":
                await TPI18N.GetText("LoadHistoryComponent", "Title"),
            "TPClientCloudMain.Client.Components.SupervisorCockpit.Wallboard":
                await TPI18N.GetText("WallboardComponent", "TitleLabel"),
        };

        let component: TabsTitleType = command.component;

        menuItemClickCallback(component, tabTitles[component], command);
    };

    const handleCopilotToggle = () => {
        setIsCopilotOpened((previousState) => !previousState);
    };

    const loadUserPhoto = async () => {
        const {postUserPhotos} = new UserService();
        const userEmail: string[] = [TPGlobal.currentUserLogin];
        const userPhotoBase64: UserPhotoResponse[] = await postUserPhotos(
            64,
            64,
            userEmail,
            false,
            false,
            [200, 404],
        );
        const userPhotoBase64Prefix: string = getImagePrefix(
            userPhotoBase64[0].image,
        );
        const userPhotoSrc: string =
            userPhotoBase64Prefix + userPhotoBase64[0].image;
        setCurrentUserPhoto(userPhotoSrc);
    };

    useEffect(() => {
        if (currentUserGuid) {
            loadUserPhoto();
        }
    }, [currentUserGuid]);

    useEffect(() => {
        loadResources();
    }, []);

    const {copilotEnable} = useCopilot();

    useEffect(() => {
        if (!online || currentRecord < 0) {
            const tab = horizontalTabsArray.find(
                (h) =>
                    h.tabType ===
                    "TPClientCloudMain.Client.Components.FollowUp.TakeNextQueue",
            );
            if (tab) {
                handleCloseClick(null, tab.tabId);
            }
        }
    }, [online, currentRecord]);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null,
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        if (isActivityTrackerActive) {
            requestUserStatusInfo();
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    //State modal
    let modalStationIdInitialState: TPModalStationIdState = {
        isShown: false,
        callBackData: "",
    };

    const [modalStationIdState, setModalStationIdState] = useState(
        modalStationIdInitialState,
    );

    const [stationIdLabel, setStationIdLabel] = useState("");
    const [stationIdTitleLabel, setStationIdTitleLabel] = useState("");
    const [stationIdYesLabel, setStationIdYesLabel] = useState("");
    const [stationIdNoLabel, setStationIdNoLabel] = useState("");

    const year = new Date().getFullYear();

    const reportKeepAliveBeat = async () => {
        //check if exists
        //if exist update keepalive and stationId
        //if not exist create it

        let serviceClient = new BrowserKeepAliveService();
        let expectedCodes: Array<number> = [200, 404];
        let bolR: boolean = false;
        try {
            let responseRequest = await serviceClient.getBrowserClientBySesionId(
                TPGlobal.sessionId,
                false,
                false,
                expectedCodes,
            );
            if (responseRequest && responseRequest.length === 1) {
                bolR = await updateKeepAliveBeat();
                if (!bolR) {
                    //todo logs
                }
            } else {
                bolR = await insertKeepAliveBeat();
                if (!bolR) {
                    //todo logs
                }
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} reportKeepAliveBeat ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} reportKeepAliveBeat ex`);
            return null;
        }
    };

    const insertKeepAliveBeat = async () => {
        let serviceClient = new BrowserKeepAliveService();
        let expectedCodes: Array<number> = [200, 429];
        let inputDTO: BrowserClientInputDTO = {
            connectionId: TPGlobal.sessionId,
            iPAddress: "todo", //TODO
            symmetricKey: "todo", //TODO
            loginUser: TPGlobal.currentUserGuid,
            stationId: TPGlobal.stationId ? TPGlobal.stationId : "",
            uRLExternalAPP: "",
        };
        try {
            let responseRequest = await serviceClient.insertBrowserClient(
                inputDTO,
                false,
                false,
                expectedCodes,
            );
            return true;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} insertKeepAliveBeat ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} insertKeepAliveBeat ex`);
            return false;
        }
    };

    const updateKeepAliveBeat = async () => {
        let serviceClient = new BrowserKeepAliveService();
        let expectedCodes: Array<number> = [200];
        let inputDTO: BrowserClientUpdateInputDTO = {
            connectionId: TPGlobal.sessionId,
            stationId: TPGlobal.stationId ? TPGlobal.stationId : "",
        };
        try {
            let responseRequest = await serviceClient.updateBrowserClient(
                inputDTO,
                false,
                false,
                expectedCodes,
            );
            return true;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} updateKeepAliveBeat ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} updateKeepAliveBeat ex`);
            return false;
        }
    };

    const handleStationIdChangeClicked = (): any => {
        let newModalStationIdState: TPModalStationIdState;
        newModalStationIdState = {...modalStationIdState};
        newModalStationIdState.isShown = true;
        newModalStationIdState.callBackData = "";
        setModalStationIdState(newModalStationIdState);
        handleClose();
    };

    const handleStationIdSetted = async (isModalOpened: boolean, data: any) => {
        let newModalStationIdState: TPModalStationIdState;
        newModalStationIdState = {...modalStationIdState};
        newModalStationIdState.isShown = isModalOpened;
        newModalStationIdState.callBackData = data;
        setModalStationIdState(newModalStationIdState);

        TPGlobal.stationId = data;

        await reportKeepAliveBeat();
    };

    const userId = TPGlobal.currentUserGuid;
    const requestUserStatusInfo = async () => {
        const userServices = new UserService();
        const response: UserStatusModel = await userServices.getUserStatusById(
            userId,
            false,
            true,
            [200],
        );
        if (response && response.userName) {
            setUserStatusInfo(response);
        } else {
            console.error("Incomplete data received:", response);
        }
    };

    const isActivityTrackerActive =
        TPGlobal.TPClientConfigurationValues.find(
            (configItem) => configItem.key == "TimeTrackingActive",
        )?.value === "1";

    useEffect(() => {
        if (!userId) return;

        requestUserStatusInfo();

        if (isActivityTrackerActive) {
            const intervalId = setInterval(() => {
                requestUserStatusInfo();
            }, 15000);

            return () => clearInterval(intervalId);
        }
    }, [userId, isActivityTrackerActive]);

    useEffect(() => {
        if (generalContext.globalUserInfo.profileId) {
            loadProfileInfo()
        }
    }, [generalContext.globalUserInfo?.profileId]);

    return (
        <>
            <TPModalStationId
                title={stationIdTitleLabel}
                yesLabel={stationIdYesLabel}
                noLabel={stationIdNoLabel}
                callBackData={modalStationIdState.callBackData}
                isShown={modalStationIdState.isShown}
                callBackAnswer={handleStationIdSetted}
            ></TPModalStationId>
            <TPHorizontalTabsContainerStyle>
                <TPLoadingOverlay active={isLoadingScreen}>
                    <TPHorizontalTabsNavTabsContainerStyle
                        style={{zIndex: isCopilotOpened ? "1" : "0"}}
                    >
                        <div style={{width: "100%"}}>
                            <ul className="nav nav-tabs" style={{borderBottom: "white"}}>
                                {horizontalTabsArray.map((item, index) => {
                                    let key = `tab-item-${item.tabId.toString()}-${index}`;
                                    let classButton = `nav-link ${item.tabIsActive == true ? "active" : ""}`;

                                    return (
                                        <li
                                            style={{marginLeft: `${index !== 0 ? "-1px" : "0"}`}}
                                            className="nav-item"
                                            key={key}
                                            role="tab"
                                            onMouseDown={(e) =>
                                                handleMouseClickEvent(e, item.tabId, item.tabType)
                                            }
                                            onClick={() => handleClickTab(item.tabId)}
                                        >
                                            <button className={classButton}>
                                                {getTabLabel(item.tabTitle)}
                                                {item.tabSubtitle1 &&
                                                    getTabLabel(item.tabSubtitle1, true)}
                                                <label
                                                    onClick={(e: any) => {
                                                        handleCloseClick(e, item.tabId);
                                                        if (
                                                            item.tabType ===
                                                            "TPClientCloudMain.Client.Components.Cases.CaseViewer"
                                                        )
                                                            dispatch(
                                                                GroupTakeNextSlice.actions.setCurrentCase(-1),
                                                            );
                                                        if (
                                                            item.tabType ===
                                                            "TPClientCloudMain.Client.Components.FollowUp.TakeNextQueue"
                                                        )
                                                            dispatch(
                                                                GroupTakeNextSlice.actions.setCurrentRecord(-1),
                                                            );
                                                    }}
                                                    className="tp-close-horizontal-tab"
                                                >
                                                    x
                                                </label>
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <HorizontalTabsFavoritesIconsContainerStyled>
                            <HorizontalTabsFavoritesIconsStyled>
                                <HorizontalTabsFavoritesIconContainerStyled
                                    onClick={async () => {
                                        //launch dashboard
                                        menuItemClickCallback(
                                            "TPClientCloudMain.Client.Components.DashBoard",
                                            await TPI18N.GetText(resourceSet, "DashboardLabel"),
                                        );
                                    }}
                                >
                                    <div id="IdDashboardLabel">
                                        <TPIcon iconType={TPIconTypes.autograph}></TPIcon>
                                    </div>
                                    <label>{dashboardLabel}</label>
                                </HorizontalTabsFavoritesIconContainerStyled>
                                <HorizontalTabsFavoritesIconContainerStyled
                                    onClick={() =>
                                        menuItemClickCallback(
                                            "TPClientCloudMain.Client.Components.Cases.NewCaseComponent",
                                            newCaseLabel,
                                        )
                                    }
                                >
                                    <div id="IdNewCaseLabel">
                                        <TPIcon iconType={TPIconTypes.libraryAdd}></TPIcon>
                                    </div>
                                    <label>{newCaseLabel}</label>
                                </HorizontalTabsFavoritesIconContainerStyled>
                                <HorizontalTabsFavoritesIconContainerStyled
                                    onClick={() =>
                                        menuItemClickCallback(
                                            "TPClientCloudMain.Client.Components.FollowUp.MyTasks",
                                            myTaskLabel,
                                        )
                                    }
                                >
                                    <div id="IdMyTaskLabel">
                                        <TPIcon iconType={TPIconTypes.playlistAddCheck}></TPIcon>
                                    </div>
                                    <label>{myTaskLabel}</label>
                                </HorizontalTabsFavoritesIconContainerStyled>

                                {copilotEnable && (
                                    <HorizontalTabsFavoritesIconContainerStyled
                                        onClick={handleCopilotToggle}
                                    >
                                        <div className="copilotIcon" id="IdCopilotLabel">
                                            <div className="copilotIconWrapper">
                                                <TPIcon iconType={TPIconTypes.copilot}></TPIcon>
                                            </div>
                                        </div>
                                        <label>{copilotLabel}</label>
                                    </HorizontalTabsFavoritesIconContainerStyled>
                                )}

                                {/* Search Customers */}
                                {/* <HorizontalTabsFavoritosIconContainerStyled onClick={() => menuItemClickCallback("SearchCustomers", "Search Customers")}>
                <div id="TPClientCloudMain.Client.Components.Cases.SearchCustomersComponent">
                  <TPIcon iconType={TPIconTypes.libraryAdd}></TPIcon>
                </div>
                <label>Search Customers</label>
              </HorizontalTabsFavoritosIconContainerStyled> */}
                                {/* Search Customers */}

                                {/* <HorizontalTabsFavoritosIconContainerStyled
                onClick={() => {
                  //mark all items as read
                  notificationContext.setAlreadySeenNotificationsCallback();

                  //toggle open state
                  setIsNotificationCenterOpened(!isNotificationCenterOpened);
                }}
              >
                {notificationContext.notificationList.filter(
                  (x: notificationCenterItem) => x.alreadyView == false
                ).length > 0 && (
                    <label
                      style={{
                        position: "absolute",
                        top: 0,
                        right: "10px",
                        width: "16px",
                        height: "16px",
                        background: "#2e2e2e",
                        borderRadius: "8px",
                        color: "white",
                        fontSize: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {
                        notificationContext.notificationList.filter(
                          (x: notificationCenterItem) => x.alreadyView == false
                        ).length
                      }
                    </label>
                  )}
                <div id="IdNotificationLabel">
                  <TPIcon iconType={TPIconTypes.notifications}></TPIcon>
                </div>
                <label>{notificationsLabel}</label>
              </HorizontalTabsFavoritosIconContainerStyled> */}

                                {userStatusInfo ? (
                                    <HorizontalTabsUserInfoContainerStyled>
                                        <section onClick={handleClick}>
                                            <UserProfile
                                                completeName={userStatusInfo.userName}
                                                customSize={{font: "12.5px", size: "38px"}}
                                                withStatus={isActivityTrackerActive}
                                                status={
                                                    userStatusInfo.status.toLowerCase() as InfoCardTypes
                                                }
                                                aria-describedby={id}
                                                imageUrl={currentUserPhoto}
                                            />
                                            <Tooltip
                                                title={userStatusInfo.userName}
                                                slotProps={{
                                                    popper: {
                                                        modifiers: [
                                                            {
                                                                name: "offset",
                                                                options: {
                                                                    offset: [0, -14],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                }}
                                            >
                                                <label>{userStatusInfo.userName}</label>
                                            </Tooltip>
                                        </section>
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                            slotProps={{
                                                paper: {
                                                    sx: {
                                                        backgroundColor: "white",
                                                        borderRadius: "4px",
                                                        boxShadow:
                                                            "0px 10px 15px 0px rgba(149, 157, 165, 0.35)",
                                                        width: "305px",
                                                    },
                                                },
                                            }}
                                            disableScrollLock
                                        >
                                            <TPUserDataContainerStyled className="tp-tab-userInfo">
                                                <UserProfile
                                                    completeName={userStatusInfo.userName}
                                                    customSize={{font: "20px", size: "64px"}}
                                                    imageUrl={currentUserPhoto}
                                                />
                                                <div className="tp-tab-userInfoContainer">
                                                    <TPUserDataWrapperContainerStyled>
                                                        <div className="logo-name-station">
                                                            {generalContext.globalUserInfo.name && (
                                                                <div className="name-station">
                                                                    <div className="name-rol">
                                                                        <p
                                                                            className="name"
                                                                            title={generalContext.globalUserInfo.name}
                                                                        >
                                                                            {generalContext.globalUserInfo.name}
                                                                            {/* {generalContext.globalUserInfo.name.slice(0, 12)}
                                    {generalContext.globalUserInfo.name.length > 12 && "..."} */}
                                                                        </p>
                                                                        <p
                                                                            className="rol"
                                                                            title={
                                                                                generalContext.globalUserInfo
                                                                                    .profileDescription
                                                                            }
                                                                        >
                                                                            {
                                                                                generalContext.globalUserInfo
                                                                                    .profileDescription
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className="station"
                                                                        onClick={(e) =>
                                                                            handleStationIdChangeClicked()
                                                                        }
                                                                    >
                                                                        <p className="label">{stationIdLabel}</p>
                                                                        <p className="number">{TPGlobal.stationId}</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="copyright-text">
                                                            TPClient Copyright &copy; {year} |
                                                            <a href="https://www.teleperformance.com">
                                                                Teleperformance
                                                            </a>
                                                        </div>
                                                    </TPUserDataWrapperContainerStyled>
                                                    {isActivityTrackerActive && (
                                                        <CockpitInfoCard
                                                            label={capitalizeText(userStatusInfo.status)}
                                                            type={
                                                                userStatusInfo.status.toLowerCase() as InfoCardTypes
                                                            }
                                                            isSmallCard
                                                        />
                                                    )}
                                                </div>
                                            </TPUserDataContainerStyled>
                                            {isActivityTrackerActive && (
                                                <TPUserDataContainerStyled className="tp-tab-userTimeTracking">
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "Noto Sans",
                                                            fontSize: "14px",
                                                            fontWeight: "medium",
                                                            userSelect: "none",
                                                        }}
                                                    >
                                                        {currentStatusLabel}
                                                    </Typography>
                                                    {userStatusInfo ? (
                                                        <Timer
                                                            key={userStatusInfo.duration}
                                                            initialSeconds={userStatusInfo.duration}
                                                        />
                                                    ) : (
                                                        <p>Cargando...</p>
                                                    )}
                                                </TPUserDataContainerStyled>
                                            )}
                                            {TPGlobal.tenantSelectorURL && (
                                                <TPUserDataContainerStyled
                                                    className="tp-tab-userLogout"
                                                    onClick={() => {
                                                        window.location.href = TPGlobal.tenantSelectorURL;
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "Noto Sans",
                                                            fontSize: "14px",
                                                            fontWeight: "medium",
                                                        }}
                                                    >
                                                        {exitLabel}
                                                    </Typography>
                                                    <div>
                                                        <TPIcon iconType={TPIconTypes.logOut}></TPIcon>
                                                    </div>
                                                </TPUserDataContainerStyled>
                                            )}
                                        </Popover>
                                    </HorizontalTabsUserInfoContainerStyled>
                                ) : (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Skeleton
                                            variant="circular"
                                            animation="wave"
                                            width={38}
                                            height={38}
                                        />
                                        <Skeleton
                                            variant="text"
                                            animation="wave"
                                            sx={{fontSize: "10px", width: "100%"}}
                                        />
                                    </Box>
                                )}
                            </HorizontalTabsFavoritesIconsStyled>
                        </HorizontalTabsFavoritesIconsContainerStyled>
                        {copilotEnable && <TPCopilotWindow open={isCopilotOpened}/>}
                    </TPHorizontalTabsNavTabsContainerStyle>
                    <TPHorizontalTabsNavTabsContentStyle
                        className="tab-content container-fluid"
                        onMouseMove={() => {
                            handleMouseMoveContentArea();
                        }}
                    >
                        <div className="tp-tab-content">
                            {hasHorizontalTabs &&
                                horizontalTabsArray.map((item, index) => {
                                    let key = "tabcontent" + item.tabId.toString();
                                    let classDiv =
                                        "tab-pane fade " +
                                        (item.tabIsActive == true ? "show active" : "");
                                    let tabContent;
                                    switch (item.tabType) {
                                        case "TPClientCloudMain.Client.Components.Functions.FunctionAssignmentsAdminComponent":
                                        case "TPClientCloudMain.Client.Components.Functions.FunctionsAdminComponent":
                                        case "TPClientCloudMain.Client.Components.Group.GroupContainerComponent":
                                        case "TPClientCloudMain.Client.Components.Organization.OrganizationAdminComponent":
                                        case "TPClientCloudMain.Client.Components.Relation.RelationContainerComponent":
                                        case "TPClientCloudMain.Client.Components.Organization.BaseLevel.BaseLevelContainerComponent":
                                        case "TPClientCloudMain.Client.Components.TaskType.TaskTypeContainerComponent":
                                        case "TPClientCloudMain.Client.Components.Schedule.ScheduleContainerComponent":
                                        case "TPClientCloudMain.Client.Components.WorkFlow.WorkflowTypeContainerComponent":
                                        case "TPClientCloudMain.Client.Components.Tree.TreeContainerComponent":
                                        case "TPClientCloudMain.Client.Components.Calendar.CalendarContainerComponent":
                                        case "TPClientCloudMain.Client.Components.User.UserContainerComponent":
                                        case "TPClientCloudMain.Client.Components.Organization.CustomerType.CustomerTypeContainerComponent":
                                        case "TPClientCloudMain.Client.Components.Organization.QuickClassifiers.QuickClassifiersContainerComponent":
                                        case "TPClientCloudMain.Client.Components.WorkFlow.WorkflowAssignmentContainerComponent":
                                        case "TPClientCloudMain.Client.Components.WorkFlow.CaseCustomState":
                                        case "TPClientCloudMain.Client.Components.RolesAndProfiles.RolesAndProfilesContainerComponent":
                                        case "TPClientCloudMain.Client.Components.Organization.AdditionalDataContainerComponent":
                                        case "TPClientCloudMain.Client.Components.Organization.FormDesigner.FormDesignerContainerComponent":
                                        case "TPClientCloudMain.Client.Components.OutboundMailAccount.OutboundMailAccountAdminComponent":
                                        case "TPClientCloudMain.Client.Components.Configuration.EmailTemplatesAdminComponent":
                                        case "TPClientCloudMain.Client.Components.Menu.MenuItemAdminComponent":
                                        case "TPClientCloudMain.Client.Components.Organization.QuickClassifierShortcutsAdminComponent":
                                        case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesAdminComponent":
                                        case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesRulesAdminComponent":
                                        case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesInProcessAdminComponent":
                                        case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesAzureApplicationAdminComponent":
                                        case "TPClientCloudMain.Client.Components.Configuration.ProcessInboundMailsWithErrorsAdminComponent":
                                        case "TPClientCloudMain.Client.Components.EventsManager.ListAdmin":
                                        case "TPClientCloudMain.Client.Components.EventsManager.LoadHistory":
                                        case "TPClientCloudMain.Client.Components.EventsManager.Projects":
                                        case "TPClientCloudMain.Client.Components.EventsManager.QueueAdmin":
                                        case "TPClientCloudMain.Client.Components.EventsManager.StructureAdmin":
                                        case "TPClientCloudMain.Client.Components.SupervisorCockpit.Dashboard":
                                        case "TPClientCloudMain.Client.Components.FollowUp.GroupDashboard":
                                        case "TPClientCloudMain.Client.Components.FollowUp.CaseCreationDashboard":
                                        case "TPClientCloudMain.Client.Components.User.DistributionListAdministration":
                                        case "TPClientCloudMain.Client.Components.Configuration.SearchIncomingMailAdminComponent":
                                        case "TPClientCloudMain.Client.Components.Scripts.Generics":
                                        case "TPClientCloudMain.Client.Components.Security.CaseToolbar":
                                            tabContent = (
                                                <VerticalTabsAdminContainer
                                                    componentType={item.tabType}
                                                    callBackCommand={handleVerticalCallEvent}
                                                    componentProps={item.componentProps}
                                                />
                                            );
                                            break;
                                        case "TPClientCloudMain.Client.Components.User.AdministrationUsersStation":
                                            tabContent = (
                                                <VerticalTabsAdminContainer
                                                    componentType={item.tabType}
                                                    callBackCommand={handleVerticalCallEvent}
                                                    componentProps={item.componentProps}
                                                />
                                            );
                                            break;
                                        case "TPClientCloudMain.Client.Components.Cases.NewCaseComponent":
                                            tabContent = <NewCase/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.Cases.SearchCustomersComponent":
                                            tabContent = <SearchComponent/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.SupervisorCockpit.TeamActivity":
                                            tabContent = (
                                                <ActiveTeamMembersAdmin
                                                    componentProps={item.componentProps}
                                                />
                                            );
                                            break;
                                        case "TPClientCloudMain.Client.Components.Cases.CaseSearchByNumberComponent":
                                            tabContent = <CaseSearchBuNumber/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.Cases.CaseSearchByFiltersComponent":
                                            tabContent = <CaseSearchByFilters/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.FollowUp.MyTasks":
                                            tabContent = <MyTasks/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.FollowUp.TakeNextQueue":
                                            tabContent = (
                                                <TakeNextQueueComponent
                                                    componentProps={item.componentProps}
                                                />
                                            );
                                            break;
                                        case "TPClientCloudMain.Client.Components.Cases.CaseViewer":
                                            tabContent = <VerticalTabsCaseViewerContainer/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.DashBoard":
                                            tabContent = <DashBoard/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.ConfigurationValues":
                                            // tabContent = <ConfigurationValuesNew />;
                                            tabContent = <ConfigurationGeneral/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.Reports.StandardReportsContainerComponent":
                                            tabContent = <StandardReports/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.FollowUp.MyReminders":
                                            tabContent = <MyTasks mode={"MYREMINDER"}/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.Cases.CaseMassUpdateComponent":
                                            tabContent = <CaseMassUpdate/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.User.UserReassignContainerComponent":
                                            tabContent = <ReassignUsersAdmin/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.Cases.MergeCustomersComponent":
                                            tabContent = (
                                                <Provider store={MergeCustomersStore}>
                                                    <MergeCustomersMain/>
                                                </Provider>
                                            );
                                            break;
                                        case "TPClientCloudMain.Client.Components.User.UserRessignContainerComponent":
                                            tabContent = <ReassignUsersAdmin/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.User.ToastMessagesContainerComponent":
                                            tabContent = <TPToastMessages/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.FollowUp.GroupTakeNext":
                                            tabContent = <GroupTakeNextComponent/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.WorkFlow.WorkflowTypeReassignComponent":
                                            tabContent = <WorkflowReAssignment/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.Profiles.ProfileRestrictionsComponent":
                                            tabContent = <ProfileRestrictions/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.SupervisorCockpit.Wallboard":
                                            tabContent = <Wallboard/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.Configuration.FrequentAnswers":
                                            tabContent = <FrequentAnswers/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.Configuration.ImagesAdminComponent":
                                            tabContent = (
                                                <AppProviderImagesAdmin>
                                                    <ImagesAdmin
                                                        mode="edit"
                                                        modeSelectFolder={false}
                                                        folderIdActive={0}
                                                        ChangeImageSelection={(e: any) => {
                                                        }}
                                                    />
                                                </AppProviderImagesAdmin>
                                            );
                                            break;
                                        case "TPClientCloudMain.Client.Components.Configuration.MailReaderReportComponent":
                                            tabContent = <MailReaderReports/>;
                                            break;
                                        case "TPClientCloudMain.Client.Components.Cases.CaseSearchByNumberComponent":
                                        default:
                                            tabContent = <label>Invalid component type</label>;
                                            break;
                                    }
                                    return (
                                        <div
                                            key={key}
                                            className={classDiv}
                                            style={
                                                item.tabIsActive == true
                                                    ? {height: "100%", display: "block"}
                                                    : {height: "0", display: "none"}
                                            }
                                        >
                                            {tabContent}
                                        </div>
                                    );
                                })}
                            {!hasHorizontalTabs && (
                                <div className="tp-no-module-container">
                                    <img src={NoDataImg} alt="no-data"/>
                                    <p className="text">
                                        <span className="highlighted">{welcomeLabel},</span>
                                        <br/>
                                        {selectModuleLabel}.
                                    </p>
                                </div>
                            )}
                        </div>
                        <NotificationCenterContainerStyled
                            isOpened={isNotificationCenterOpened}
                            className={`animate__animated animate__${isNotificationCenterOpened ? "slideInRight" : "slideOutRight"}`}
                        >
                            <div style={{display: "flex", justifyContent: "end"}}>
                                <TPButton
                                    onClick={() => {
                                        //mark all items as read
                                        notificationContext.setAlreadySeenNotificationsCallback();
                                        //close Notification center
                                        setIsNotificationCenterOpened(false);
                                    }}
                                    type={TPButtonTypes.icon}
                                    icon={TPIconTypes.close}
                                />
                            </div>
                            <h1
                                style={{
                                    fontSize: "10px",
                                    letterSpacing: "0.1em",
                                    color: "#989898",
                                    textTransform: "uppercase",
                                    fontWeight: "600",
                                }}
                            >
                                {notificationCenterLastNotificationsTitleLabel}
                            </h1>
                            <ul>
                                {notificationContext.notificationList.length > 0 &&
                                    notificationContext.notificationList.map(
                                        (notification: notificationCenterItem) => {
                                            return (
                                                <li
                                                    key={notification.id}
                                                    className={`${
                                                        isRemoving.filter((k: number) => k == notification.id)
                                                            .length > 0
                                                            ? "animate__animated animate__zoomOutDown"
                                                            : ""
                                                    }`}
                                                >
                                                    <div
                                                        className={`notification-item-container border border-top-0 border-end-0 border-bottom-0 border-3 border-${
                                                            notification.color == "error"
                                                                ? "danger"
                                                                : notification.color
                                                        }`}
                                                    >
                                                        <label className="notification-title">
                                                            {notification.title}
                                                        </label>
                                                        <label className="notification-text">
                                                            {notification.text}
                                                        </label>
                                                        <label className="notification-date">
                                                            {notification.date}
                                                        </label>
                                                        <TPButton
                                                            type={TPButtonTypes.icon}
                                                            onClick={async () => {
                                                                let oldRemovingList = [...isRemoving];
                                                                oldRemovingList.push(notification.id);
                                                                setIsRemoving(oldRemovingList);
                                                                await new Promise((r) => setTimeout(r, 1000));
                                                                notificationContext.removeNotificationsCallback(
                                                                    notification.id,
                                                                );
                                                            }}
                                                            icon={TPIconTypes.close}
                                                            className={"notification-remove"}
                                                        />
                                                    </div>
                                                </li>
                                            );
                                        },
                                    )}
                            </ul>
                        </NotificationCenterContainerStyled>
                    </TPHorizontalTabsNavTabsContentStyle>
                </TPLoadingOverlay>
            </TPHorizontalTabsContainerStyle>
        </>
    );
};

export default MainHorizontalTabs;
