import styled from "styled-components";

export const TPStyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  font-family: 'Noto Sans';
  font-weight: 500;
  overflow: visible;
`

interface TPStyledInnerInputFormProps {
  width?: string | number;
}

export const TPStyledInnerInputForm = styled.div<TPStyledInnerInputFormProps>`
  display: flex;
  flex-direction: column;
  width: ${props => props.width ?? '75%'};
  gap: 16px;
  overflow: visible;
`

export const TPStyledSubtitle = styled.b`
  font-weight: 700;
  font-size: 16px;
  color: #000000;
`

interface TPStyledFormRowProps {
  wrap?: boolean;
  justify?: string;
  shrink?: boolean;
  wrapWidth?: string | number;
}

export const TPStyledFormRow = styled.div<TPStyledFormRowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: ${props => props.wrap ? 'wrap' : 'none'};
  width: 100%;
  gap: 16px;
  justify-content: ${props => props.justify};

  .tp-form-input, .tp-select {
    width: ${props => props.shrink ? "min-content" : "100%"};

    input {
      border: 1px solid #BFBFBF;
      border-radius: 4px;
    }
  }

  .tp-multilang-input {
    flex: 1 0 100% !important;
  }

  @media (max-width: ${props => props.wrapWidth ?? '750px'}) {
    flex-direction: column;
  }
`

interface TPStyledFormButtonFooterProps {
  width?: string | number;
}

export const TPStyledFormButtonFooter = styled.div<TPStyledFormButtonFooterProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: ${props => props.width ?? '75%'};
`

export const OptionButtonPairStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @media (max-width: 400px) {
    flex-direction: column-reverse;
  }
`

interface TPStyledGridProps {
  columns?: number;
  gap?: string | number;
  wrapWidth?: string | number;
}

export const TPStyledGrid = styled.div<TPStyledGridProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns ?? 3}, 1fr);
  gap: ${props => props.gap};

  @media (max-width: ${props => props.wrapWidth ?? '750px'}) {
    grid-template-columns: repeat(1, 1fr);
  }
`