import allThemes from "@/assets/styles/theme";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";
import { MultilingualTextBoxEvents } from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  CIMTitleSection,
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  CalendarInputDTO,
  CalendarInputDTOValidator,
} from "@/models/Calendars/CalendarInputDTO";
import { CalendarsViewModel } from "@/models/Calendars/CalendarsModels";
import {
  SequenceGeneratorSequencesNameEnum,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { CalendarsService } from "@/services/CalendarsService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { SequenceService } from "@/services/SequenceService";
import { FC, useEffect, useRef, useState } from "react";
import "./styles.css";
import { MdNewLabel } from "react-icons/md";
import { TPStyledForm, TPStyledFormButtonFooter, TPStyledFormRow, TPStyledInnerInputForm, TPStyledSubtitle } from "@/components/styled/StyledForms";
import { TPOptionButtonPairStyled } from "@/components/bootstrap/forms/submit/TPOptionButtonPair";

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
  subtitleCallback?: Function;
};

type InsertUpdateStateType = {
  idCalendar: string;
  recordLanguageList: Array<TPKeyValue>;
  isActive: boolean;

  //validator
  idErrorMessage: string;
  nameErrorMessages: Array<string>;
};

const CalendarInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
  subtitleCallback,
}) => {
  const componentFileName: string = "CalendarInsertUpdate.tsx";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //Screen resources
  const ResourceSet: string = "CalendarInsertUpdateComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [idLabel, setIdLabel] = useState("");
  const [nameLabel, setNameLabel] = useState("");
  const [isActiveLabel, setIsActiveLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");

  const [isNameValue, setIsNameValue] = useState("");

  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [realMode, setRealMode] = useState(mode);
  const [realRecordId, setRealRecordId] = useState(recordId);
  const [referenceId, setReferenceId] = useState("");
  const nameInputRef = useRef<MultilingualTextBoxEvents>();

  //Screen state
  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }
  let insertUpdateInitialState: InsertUpdateStateType = {
    idCalendar: recordId,
    recordLanguageList: [],
    isActive: true,
    idErrorMessage: "",
    nameErrorMessages: initialErrorMessages,
  };
  const [insertUpdateState, setInsertUpdateState] = useState(
    insertUpdateInitialState,
  );

  //Multilanguage const
  const multilanguageTableName: String = "CALEndar";
  const multilanguageFieldName: String = "Description_CALE";
  //#endregion

  const loadResourcesAndLoadFunctionInfo = async () => {
    let i: number;
    //resources state
    setTitleLabel(
      await TPI18N.GetText(ResourceSet, "Title" + realMode + "Label"),
    );
    setSubTitleLabel(await TPI18N.GetText(ResourceSet, "SubtitleLabel"));
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel",
      ),
    );
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );
    setIdLabel(await TPI18N.GetText(ResourceSet, "IdLabel"));
    setNameLabel(await TPI18N.GetText(ResourceSet, "NameLabel"));
    setIsActiveLabel(await TPI18N.GetText(ResourceSet, "IsActiveLabel"));

    //screen state

    if (realMode == "Update") {
      await getCalendarById(realRecordId);
    }
    if (realMode == "Insert") {
      let newInsertUpdateState = { ...insertUpdateState };
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }
  };

  const handleOnIdCalendarChange = (newIdCalendar: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.idCalendar = newIdCalendar;
    newInsertUpdateState.idErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.nameErrorMessages[index] = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleIsActiveChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActive = !newInsertUpdateState.isActive;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleChangeValue = (value: any) => {
    setIsNameValue(value);
  };

  const handleOkButtonClick = async () => {
    if (nameInputRef.current?.isInvalid()) {
      nameInputRef.current?.markAsTouched();
      return;
    }

    const languages = nameInputRef.current?.getValue();
    const mainLanguage = languages?.find(
      ({ id }) => id === TPGlobal.TPClientDefaultLanguage,
    );
    let i: number;
    let n: number;
    let recordInputDTO: CalendarInputDTO = {
      // id: insertUpdateState.idCalendar,
      id: realMode === "Insert" ? referenceId : recordId,
      isActive: insertUpdateState.isActive,
      description: mainLanguage?.value!,
      otherLocalizedValues: [
        {
          languageId: mainLanguage?.id!,
          localizedValue: mainLanguage?.value ?? "",
          order: 1,
        },
        ...languages!
          .filter(({ id }) => id !== TPGlobal.TPClientDefaultLanguage)
          .map(({ id, value }, order) => ({
            languageId: id!,
            localizedValue: value ?? "",
            order: order + 2,
          })),
      ],
    };

    let inputDTOValidator = new CalendarInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      if (resultValidator.id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.id,
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }

      setInsertUpdateState(newInsertUpdateState);
      return;
    }
    if (realMode == "Insert") {
      await insertCalendar(recordInputDTO);
    } else {
      await updateCalendar(recordInputDTO);
    }
  };

  const insertCalendar = async (inputDTO: CalendarInputDTO) => {
    let serviceClient = new CalendarsService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertCalendar(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        //callBackResult({ result: "OK", recordId: recordId });
        callBackResult({
          result: "save_general_insert_and_change_mode",
          recordId: inputDTO.id,
        });

        //change mode
        setTitleLabel(
          await TPI18N.GetText(ResourceSet, "Title" + "Update" + "Label"),
        );
        setRealRecordId(inputDTO.id);
        setOriginalRecordDescription(
          inputDTO.otherLocalizedValues[0].localizedValue.length > 100
            ? inputDTO.otherLocalizedValues[0].localizedValue.substring(
                0,
                100,
              ) + "..."
            : inputDTO.otherLocalizedValues[0].localizedValue,
        );
        setRealMode("Update");
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertCalendar ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} insertCalendar ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateCalendar = async (inputDTO: CalendarInputDTO) => {
    let serviceClient = new CalendarsService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.updateCalendar(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateCalendar ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updateCalendar ex`);
      setIsLoadingScreen(false);
    }
  };

  const getCalendarById = async (pRecordId: string) => {
    let serviceClient = new CalendarsService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getCalendarById(
        pRecordId,
        false,
        true,
        expectedCodes,
      );

      let recordInfo: CalendarsViewModel;
      recordInfo = { ...responseRequest };
      setOriginalRecordDescription(recordInfo.description);

      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.recordLanguageList = [];
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      newInsertUpdateState.isActive = recordInfo.isActive;
      newInsertUpdateState.recordLanguageList[0].value = recordInfo.description;
      recordLanguagesList = await getRecordLanguageList(pRecordId);
      if (recordLanguagesList.length == 0) {
        TPLog.Log(
          `Error ${componentFileName} getCalendarById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty",
        );
        console.error(
          `Error ${componentFileName} getCalendarById getRecordLanguageList is empty`,
        );
        setIsLoadingScreen(false);
        return;
      }
      for (
        i = 0;
        i <= newInsertUpdateState.recordLanguageList.length - 1;
        i++
      ) {
        for (j = 0; j <= recordLanguagesList.length - 1; j++) {
          if (
            newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ==
            recordLanguagesList[j].languageId.toLowerCase()
          ) {
            newInsertUpdateState.recordLanguageList[i].value =
              recordLanguagesList[j].recordDescription;
            break;
          }
        }
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCalendarById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getCalendarById ex`);
      setIsLoadingScreen(false);
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };
  const handleCancelButtonClick = () => {
    callBackResult({ result: "cancel_general", recordId: recordId });
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQCALE,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResourcesAndLoadFunctionInfo();
    realMode === "Insert" && generalAutomaticId();
  }, []);

  useEffect(() => {
    if (subtitleCallback && originalRecordDescription) {
      subtitleCallback(`${subTitleLabel} ${recordId} / ${originalRecordDescription}`);
    }
  }, [originalRecordDescription, subTitleLabel]);

  return (
    //#region  Render
    <>
      <TPLoadingOverlay active={isLoadingScreen} wrapperStyle={{ padding: "0px" }}>
        <div>
          <TPStyledForm className="pb-3">
            <TPStyledInnerInputForm>
              <TPStyledSubtitle>{descriptionSectionLabel}</TPStyledSubtitle>
              <TPStyledFormRow>
                <TPTextBox
                  id="IdTextBox"
                  labelText={idLabel}
                  isMandatory={true}
                  // value={insertUpdateState.idCalendar}
                  value={realMode === "Insert" ? referenceId : recordId}
                  onChange={
                    realMode === "Insert"
                      ? (e: any) => handleOnIdCalendarChange(e.target.value)
                      : () => {
                          TPGlobal.foo();
                        }
                  }
                  maxLength={20}
                  // disabled={realMode !== "Insert"}
                  disabled
                  errorMessage={insertUpdateState.idErrorMessage}
                />
              </TPStyledFormRow>
              <TPStyledFormRow>
                <MultilingualTextBox
                  ref={nameInputRef}
                  style={{ width: "calc(100% + 56px)" }}
                  onChange={handleChangeValue}
                  value={insertUpdateState.recordLanguageList?.map(
                    ({ key, value }) => ({ id: key, value }),
                  )}
                />
              </TPStyledFormRow>
            </TPStyledInnerInputForm>
            <TPStyledInnerInputForm>
              <TPStyledFormRow>
                <TPCheckBox
                  id="IdCheckBox"
                  labelText={isActiveLabel}
                  checked={insertUpdateState.isActive}
                  onChange={(e: any) => handleIsActiveChange()}
                />
              </TPStyledFormRow>
            </TPStyledInnerInputForm>
            <TPStyledFormButtonFooter>
              <TPOptionButtonPairStyled
                cancelLabel={cancelButtonLabel}
                confirmLabel={saveButtonLabel}
                onCancel={handleCancelButtonClick}
                onConfirm={handleOkButtonClick}
                confirmDisabled={isNameValue ? false : true}
              />
            </TPStyledFormButtonFooter>
          </TPStyledForm>
        </div>
      </TPLoadingOverlay>
    </>
    //#endregion
  );
};

export default CalendarInsertUpdate;
