import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { IsActiveIcon } from "@/components/bootstrap/content/tables/tpTableStyles";
import { TPTreeBranchColorConventionsStyled } from "@/components/bootstrap/content/trees/tpTreeBranchColorConventions";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import {
    TPColumn,
    TPPageAcceptCancelButtonsContainer,
    TPPageActionsSpaceBetween,
    TPPageFirstRow,
    TPPageSubTitle,
    TPPageTitle,
    TPRow,
} from "@/components/TPPage/tpPageStyles";
import TPTreeView, {
    TPTreeViewDataModel,
    TPTreeViewProps,
} from "@/components/TPTreeView/TPTreeView";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
    TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import TPModalAdditionalData from "@/layouts/TPModalAdditionalData/TPModalAdditionalData";
import TPModalCloneBranch, {
    TPModalCloneBranchState,
} from "@/layouts/TPModalCloneBranch/TPModalCloneBranch";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import TPModalTreeView, {
    TPModalTreeViewState,
} from "@/layouts/TPModalTreeView/TPModalTreeView";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
    AdditionalDataBranchInputDTO,
    BranchChangeParentInputDTO,
    BranchInputDTO,
    BranchInputDTOValidator,
} from "@/models/Branch/BranchInputDTO";
import {
    AdditionalDataBranchViewModel,
    BranchInfoHierarchyViewModel,
    BranchViewModel,
    KeyList,
    OperationModeEnum,
} from "@/models/Branch/BranchModels";
import {
    ModalSizeEnum,
    SequenceGeneratorSequencesNameEnum,
    TPButtonTypes,
    TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { AdditionalDataBranchViewGridModel, MenuItemsModel, TreeViewModel } from "@/models/Tree/TreeModels";
import { AdditionalDataBranchService } from "@/services/AdditionalDataBranchService";
import { BranchService } from "@/services/BranchService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { SequenceService } from "@/services/SequenceService";
import { TreeService } from "@/services/TreeService";
import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TPHorizontalTabs from "../../components/TPTabs/TPHorizontalTabs";
import { ImageActive } from "../../models/ViewImage/ViewImage";
import ViewImage from "../../components/TPViewImage/ViewImage";
import { BtnModalStyle, ColorTreeStyle, FilterTreeStyle, HeaderGridStyle, MsgAlertTreeStyle, MsgAlertTreeTitleStyle, TreeNoDataImg, XTreeTitleStyle } from "./Style";
import SearchInput from "../../modules/core/design-system/inputs/SearchInput";
import TPSelect from "../../components/bootstrap/forms/select/TPSelect";
import TPIcon from "../../components/bootstrap/extend/TPIcons/TPIcon";
import DynamicTable, { ColumnStyles } from "../../modules/core/components/dynamic-table/DynamicTable";
import TablePrimaryItem from "../../modules/core/utils/table-micro-components/TablePrimaryItem";
import TableChip from "../../modules/core/utils/table-micro-components/TableChip";
import { CustomColumnNames } from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import { TPConfirmationModal } from "../../components/TPModalAlert/ConfirmationModal/TPConfirmationModal";
import TPModal from "../../layouts/TPModal/TPModal";
import TPTextArea from "../../components/bootstrap/forms/textArea/TPTextArea";
import { JsonBranchesMassiveModel, TreeMultipleBranchInputDTO, TreeMultipleBranchInputDTOValidator } from "../../models/Tree/TreeInputDTO";
import { TPEmthyModal } from "../../components/TPModalAlert/TPEmthyModal/TPEmthyModal";
import TPFilter from "./Components/TPFilter";


interface BranchAdminProps {
    treeId: string;
    branchSelected: string;
    changeTreeSelected: Function;
    setIsOpenModal: Function;
    asideMenuState: MenuItemsModel[];
}



type ScreenStateOperationType = {
    isTreeEnabled: boolean;
    isToolbarButtonsVisible: boolean;
    isIdBranchEnabled: boolean;
    isParentNameEnabled: boolean;
    isParentIdEnabled: boolean;
    isNameArrayEnabled: Array<boolean>;
    isTreeNameEnabled: boolean;
    isBranchValueEnabled: boolean;
    isOrderEnabled: boolean;
    isParametersEnabled: boolean;
    isHelpArrayEnabled: Array<boolean>;
    isActiveEnabled: boolean;
    isVisibleEnabled: boolean;
    isSystemRecordEnabled: boolean;
    isSaveCancelButtonEnabled: boolean;
    isAdditionalDataTableEnabled: boolean;
};

type InsertUpdateStateType = {
    idBranch: string;
    parentId: string;
    parentName: string;
    recordLanguageList: Array<TPKeyValue>;
    treeName: string;
    value: string;
    isActive: boolean;
    isVisible: boolean;
    isSystemRecord: boolean;
    hasChildren: boolean;
    order: number;
    parameters: string;
    helpLanguageList: Array<TPKeyValue>;
    //validator
    idErrorMessage: string;
    nameErrorMessages: Array<string>;
    helpErrorMessages: Array<string>;
    valueErrorMessage: string;
    parametersErrorMessage: string;
    //operator
    currentOperatorMode: OperationModeEnum;
};

type BranchButtons = {
    id: string;
    text: string;
    clickEvent: Function;
    icon: TPIconTypes;
    className: string;
    active: boolean;

};

const BranchAdmin: FC<BranchAdminProps> = ({
    treeId,
    changeTreeSelected,
    branchSelected,
    setIsOpenModal,
    asideMenuState
}): ReactElement => {
    const componentFileName: string = "BranchAdmin.tsx";

    // valid resources
    const resourceInvalidHelpComments: string =
        "BranchInsertUpdateComponent|InputDTOInvalidHelpComments";

    const [activeTab, setActiveTab] = useState<number>(1);
    const [dataSourceFilter, setDataSourceFilter] = useState<Array<TPKeyValue>>([]);

    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);

    //Screen resources
    const ResourceSet: string = "BranchAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [subTitleLabel, setSubTitleLabel] = useState("");
    const [currentBranchLabel, setCurrentBranchLabel] = useState("");
    const [colorConventionsLabel, setColorConventionsLabel] = useState("");
    const [activeVisibleLabel, setActiveVisibleLabel] = useState("");
    const [activeInvisibleLabel, setActiveInvisibleLabel] = useState("");
    const [inactiveVisibleLabel, setInactiveVisibleLabel] = useState("");
    const [inactiveInvisibleLabel, setInactiveInvisibleLabel] = useState("");
    const [addRootLabel, setAddRootLabel] = useState("");
    const [addChildLabel, setAddChildLabel] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [cloneLabel, setCloneLabel] = useState("");
    const [changeParentLabel, setChangeParentLabel] = useState("");
    const [selectedNodeIdPlusDescription, setSelectedNodeIdPlusDescription] =
        useState("");
    const [editLabel, setEditLabel] = useState("");
    const [idLabel, setIdLabel] = useState("");
    const [parentIdLabel, setParentIdLabel] = useState("");
    const [parentNameLabel, setParentNameLabel] = useState("");
    const [nameLabel, setNameLabel] = useState("");
    const [valueLabel, setValueLabel] = useState("");
    const [treeNameLabel, setTreeNameLabel] = useState("");
    const [orderLabel, setOrderLabel] = useState("");
    const [parametersLabel, setParametersLabel] = useState("");
    const [helpLabel, setHelpLabel] = useState("");
    const [isActiveLabel, setIsActiveLabel] = useState("");
    const [isVisibleForClassificationLabel, setIsVisibleForClassificationLabel] =
        useState("");
    const [isSystemRecordLabel, setIsSystemRecordLabel] = useState("");
    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [orLabel, setOrLabel] = useState("");
    const [addBranchLabel, setAddBranchLabel] = useState("");
    const [changeBranchLabel, setChangeBranchLabel] = useState("");
    const [addMultipleBranchesLabel, setAddMultipleBranchesLabel] = useState("");
    const [multipleBranchesLabel, setMultipleBranchesLabel] = useState("");
    const [insertYourDescriptionHere, setInsertYourDescriptionHere] = useState("");
    const [insertYourDescriptionHereError, setInsertYourDescriptionHereError] = useState("");
    const [youDonNotHaceBranchesLabel, setYouDonNotHaceBranchesLabel] = useState("");
    const [searchPlaceholder, setSearchPlaceholder] = useState<string>("");
    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");
    const [backLabel, setBackLabel] = useState("");
    const [columnNameGrid, setColumnNameGrid] = useState({
        ID: "",
        Delete: "",
        Mandatory: "",
        Order: "",
        Type: "",
        Name: ""
    });
    const [filter, setFilter] = useState("");
    const [valueMultiBranch, setValueMultiBranch] = useState("");
    const [branchSelectedLabel, setBranchSelectedLabel] = useState("");
    const [enterAListOfBranchesDescriptionLabel, setEnterAListOfBranchesDescriptionLabel] = useState("");
    const [activeAlertTreeBranch, setActiveAlertTreeBranch] = useState(true);

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteQuestionAddBranch, setDeleteQuestionAddBranch] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCancelLabel, setDeleteCancelLabel] = useState("");

    const [referenceId, setReferenceId] = useState("");

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
        isShown: false,
        callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
        modalQuestionInitialState,
    );

    //State modal delete additional data branch
    let modalAddBranchQuestionInitialState: TPModalQuestionState = {
        isShown: false,
        callBackData: {},
    };
    const [modalAddBranchQuestionState, setModalAddBranchQuestionState] =
        useState(modalAddBranchQuestionInitialState);

    //tree
    const [readyToLoadDataWhenNodeChange, setReadyToLoadDataWhenNodeChange] =
        useState(false);
    const [guidSession, setGuidSession] = useState(
        `tree${uuidv4().replaceAll("-", "")}`,
    );
    const isAdminMode: boolean = true;
    const filterIsActive1: number = 2;
    const filterIsVisible1: number = 2;
    const [dataArray1, setTreeviewData1] = useState<Array<TPTreeViewDataModel>>(
        [],
    );
    const [currentSelectedNode1, setCurrentSelectedNode1] = useState("");
    const [currentSelectedLabel1, setCurrentSelectedLabel1] = useState("");
    const [currentSelectedHierarchyLabel1, setCurrentSelectedHierarchyLabel1] =
        useState("");

    //tree change-parent
    let changeParentModalTreeViewInitialState: TPModalTreeViewState = {
        isShown: false,
        guidSession: "",
        treeId: "",
    };
    const [changeParentModalTreeViewState, setChangeParentModalTreeViewState] =
        useState(changeParentModalTreeViewInitialState);
    const [
        changeParentModalTreeViewModalTitle,
        setChangeParentModalTreeViewModalTitle,
    ] = useState("");
    const [changeParentAcceptButtonLabel, setChangeParentAcceptButtonLabel] =
        useState("");
    const [changeParentCancelButtonLabel, setChangeParentCancelButtonLabel] =
        useState("");
    const [changeParentDataArray, setChangeParentTreeviewData] = useState<
        Array<TPTreeViewDataModel>
    >([]);
    const [changeParentCurrentSelectedNode, setChangeParentCurrentSelectedNode] =
        useState("");
    const [
        changeParentCurrentSelectedLabel,
        setChangeParentCurrentSelectedLabel,
    ] = useState("");
    const [
        changeParentCurrentSelectedHierarchyLabel,
        setChangeParentCurrentSelectedHierarchyLabel,
    ] = useState("");
    const [showSelectRootNodeButton, setShowSelectRootNodeButton] =
        useState(true);
    const [selectRootLabel, setSelectRootLabel] = useState("");
    const [activeModalMultiBranche, setActiveModalMultiBranche] = useState(false);
    const [operationModeRootActive, setOperationModeRootActive] = useState(false);
    const [orderActive, setOrderActive] = useState(false);

    // clone branch states
    let cloneBranchModal: TPModalCloneBranchState = {
        isShown: false,
        branchId: "",
        branchName: "",
    };

    const [cloneBranchNode, setCloneBranchNode] = useState("");
    const [cloneBranchLabel, setCloneBranchLabel] = useState("");
    const [changeCloneBranch, setChangeCloneBranch] = useState(cloneBranchModal);

    //Additional Data modal
    const [isShownModalAdditionalData, setIsShownModalAdditionalData] =
        useState(false);
    const [additionalDataModalTitle, setAdditionalDataModalTitle] = useState("");
    const [addAdditionalDataLabel, setAddAdditionalDataLabel] = useState("");

    //Additional Data table
    const [additionalDataLabel, setAdditionalDataLabel] = useState("");
    const [additionalDataIdColumnLabel, setAdditionalDataIdColumnLabel] =
        useState("");
    const [
        additionalDataDescriptionColumnLabel,
        setAdditionalDataDescriptionColumnLabel,
    ] = useState("");
    const [
        additionalDataIsMandatoryColumnLabel,
        setAdditionalDataIsMandatoryColumnLabel,
    ] = useState("");
    const [additionalDataOrderColumnLabel, setAdditionalDataOrderColumnLabel] =
        useState("");
    const [additionalDataTypeColumnLabel, setAdditionalDataTypeColumnLabel] =
        useState("");
    const [languageListLabel, setLanguageListLabel] = useState("");
    const [additionalDataBranchKeyValue, setAdditionalDataBranchKeyValue] =
        useState<Array<AdditionalDataBranchViewModel>>([]);

    //Screen state
    let initialErrorNameMessages: Array<string> = [];
    let initialErrorHelpMessages: Array<string> = [];
    for (
        let i: number = 0;
        i <= TPGlobal.TPClientAvailableLanguages.length - 1;
        i++
    ) {
        initialErrorNameMessages.push("");
        initialErrorHelpMessages.push("");
    }
    let initialNameLanguageList: Array<TPKeyValue> = [];
    let initialHelpLanguageList: Array<TPKeyValue> = [];
    for (
        let i: number = 0;
        i <= TPGlobal.TPClientAvailableLanguages.length - 1;
        i++
    ) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement1: TPKeyValue = { key: item.id, value: "" };
        let keyValueElement2: TPKeyValue = { key: item.id, value: "" };
        initialNameLanguageList.push(keyValueElement1);
        initialHelpLanguageList.push(keyValueElement2);
    }

    let insertUpdateInitialState: InsertUpdateStateType = {
        idBranch: "",
        parentId: "",
        parentName: "",
        recordLanguageList: initialNameLanguageList,
        treeName: "",
        isActive: false,
        isVisible: false,
        isSystemRecord: false,
        hasChildren: false,
        order: 0,
        value: "",
        parameters: "",
        helpLanguageList: initialHelpLanguageList,
        //validator
        idErrorMessage: "",
        nameErrorMessages: initialErrorNameMessages,
        valueErrorMessage: "",
        parametersErrorMessage: "",
        helpErrorMessages: initialErrorHelpMessages,
        currentOperatorMode: OperationModeEnum.normal,
    };

    const [insertUpdateState, setInsertUpdateState] = useState(
        insertUpdateInitialState,
    );

    let initialNameEnabled: Array<boolean> = [];
    let initialHelpEnabled: Array<boolean> = [];
    for (
        let i: number = 0;
        i <= TPGlobal.TPClientAvailableLanguages.length - 1;
        i++
    ) {
        initialNameEnabled.push(false);
        initialHelpEnabled.push(false);
    }

    let initialOperationModeState: ScreenStateOperationType = {
        isTreeEnabled: true,
        isToolbarButtonsVisible: true,
        isIdBranchEnabled: false,
        isParentNameEnabled: false,
        isParentIdEnabled: false,
        isNameArrayEnabled: initialNameEnabled,
        isTreeNameEnabled: false,
        isBranchValueEnabled: false,
        isOrderEnabled: false,
        isParametersEnabled: false,
        isHelpArrayEnabled: initialHelpEnabled,
        isActiveEnabled: false,
        isVisibleEnabled: false,
        isSystemRecordEnabled: false,
        isSaveCancelButtonEnabled: false,
        isAdditionalDataTableEnabled: true,
    };

    const [operationModeState, setOperationModeState] = useState(
        initialOperationModeState,
    );
    const [isTreeAlphabeticOrder, setIsTreeAlphabeticOrder] = useState(false);
    const [originalRecordDescription, setOriginalRecordDescription] =
        useState("");
    const [treeNameValue, setTreeNameValue] = useState("");
    const [ParentNameValue, setParentNameValue] = useState("");
    const [visible, setVisible] = useState<boolean>(false);

    const loadResourcesAndLoadTreeInfo = async () => {
        //resources state
        setTitleLabel(await TPI18N.GetText(ResourceSet, "TitleLabel"));
        setSubTitleLabel(await TPI18N.GetText(ResourceSet, "SubTitleLabel"));
        setCurrentBranchLabel(
            await TPI18N.GetText(ResourceSet, "CurrentBranchLabel"),
        );
        setColorConventionsLabel(
            await TPI18N.GetText(ResourceSet, "ColorConventionsLabel"),
        );
        setActiveVisibleLabel(
            await TPI18N.GetText(ResourceSet, "ActiveVisibleLabel"),
        );
        setActiveInvisibleLabel(
            await TPI18N.GetText(ResourceSet, "ActiveInvisibleLabel"),
        );
        setInactiveVisibleLabel(
            await TPI18N.GetText(ResourceSet, "InactiveVisibleLabel"),
        );
        setInactiveInvisibleLabel(
            await TPI18N.GetText(ResourceSet, "InactiveInvisibleLabel"),
        );
        setAddRootLabel(await TPI18N.GetText(ResourceSet, "AddRootButton"));
        setAddChildLabel(await TPI18N.GetText(ResourceSet, "AddChildButton"));
        setDeleteLabel(await TPI18N.GetText(ResourceSet, "DeleteButton"));
        setCloneLabel(await TPI18N.GetText(ResourceSet, "CloneBranchButton"));
        setChangeParentLabel(
            await TPI18N.GetText(ResourceSet, "ChangeParentButton"),
        );
        setSelectedNodeIdPlusDescription(
            await TPI18N.GetText(ResourceSet, "SelectedNodeIdPlusDescriptionLabel"),
        );
        setEditLabel(await TPI18N.GetText(ResourceSet, "EditButton"));
        setIdLabel(await TPI18N.GetText(ResourceSet, "IdLabel"));
        setParentIdLabel(await TPI18N.GetText(ResourceSet, "ParentIdLabel"));
        setParentNameLabel(await TPI18N.GetText(ResourceSet, "ParentLabel"));
        setNameLabel(await TPI18N.GetText(ResourceSet, "NameLabel"));
        setValueLabel(await TPI18N.GetText(ResourceSet, "ValueLabel"));
        setTreeNameLabel(await TPI18N.GetText(ResourceSet, "TreeNameLabel"));
        setOrderLabel(await TPI18N.GetText(ResourceSet, "OrderLabel"));
        setParametersLabel(await TPI18N.GetText(ResourceSet, "ParametersLabel"));
        setHelpLabel(await TPI18N.GetText(ResourceSet, "HelpCommentsLabel"));
        setIsActiveLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ShowAsActive"));
        setIsVisibleForClassificationLabel(
            await TPI18N.GetText(ResourceSet, "IsVisibleLabel"),
        );
        setIsSystemRecordLabel(
            await TPI18N.GetText(ResourceSet, "IsSystemRecordLabel"),
        );
        setSaveButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
        );
        setCancelButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        );
        setAddBranchLabel(
            await TPI18N.GetText(ResourceSet, "AddBranchLabel"),
        );
        setAddMultipleBranchesLabel(
            await TPI18N.GetText(ResourceSet, "AddMultipleBranchesLabel"),
        );
        setOrLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OrLabel"),
        );
        setYouDonNotHaceBranchesLabel(
            await TPI18N.GetText(ResourceSet, "YouDonNotHaceBranchesLabel"),
        );
        setSearchPlaceholder(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "Search")
        );
        setChangeBranchLabel(
            await TPI18N.GetText(ResourceSet, "ChangeBranchLabel")
        );
        setYesLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
        );
        setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));
        setBackLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "BackLabel"));
        setBranchSelectedLabel(await TPI18N.GetText(ResourceSet, "BranchSelectedLabel"));
        setMultipleBranchesLabel(await TPI18N.GetText(ResourceSet, "MultipleBranchesLabel"));
        setInsertYourDescriptionHere(await TPI18N.GetText(TPGlobal.globalResourceSet, "InsertYourDescriptionHere"));
        setEnterAListOfBranchesDescriptionLabel(await TPI18N.GetText(ResourceSet, "EnterAListOfBranchesDescriptionLabel"));
        setColumnNameGrid
            (
                {
                    ID: await TPI18N.GetText(ResourceSet, "ID"),
                    Delete: await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
                    Mandatory: await TPI18N.GetText(ResourceSet, "Mandatory"),
                    Name: await TPI18N.GetText(TPGlobal.globalResourceSet, "Name"),
                    Order: await TPI18N.GetText(ResourceSet, "AdditionalDataOrderColumnLabel"),
                    Type: await TPI18N.GetText(TPGlobal.globalResourceSet, "Type")
                }
            );

        //modal delete
        setDeleteQuestion(await TPI18N.GetText(ResourceSet, "RecordDeleteConfirm"));
        setDeleteQuestionAddBranch(
            await TPI18N.GetText(ResourceSet, "DeleteQuestionAddaBranch"),
        );
        setDeleteTitle(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
        );
        setDeleteOkLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
        );
        setDeleteCancelLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        );

        //modal change parent
        setChangeParentModalTreeViewModalTitle(
            await TPI18N.GetText(ResourceSet, "ChangeParentTitle"),
        );
        setChangeParentAcceptButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
        );
        setChangeParentCancelButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        );
        setSelectRootLabel(
            await TPI18N.GetText(ResourceSet, "ChangeRootParentButton"),
        );

        //modal additional data
        setAdditionalDataModalTitle(
            await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "AdditionalDataModalTitle",
            ),
        );
        setLanguageListLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
        );

        setAddAdditionalDataLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "AddNew"),
        );

        //additional data table
        setAdditionalDataLabel(
            await TPI18N.GetText(ResourceSet, "AdditionalDataLabel"),
        );
        setAdditionalDataIdColumnLabel(
            await TPI18N.GetText(ResourceSet, "AdditionalDataIdColumnLabel"),
        );
        setAdditionalDataDescriptionColumnLabel(
            await TPI18N.GetText(ResourceSet, "AdditionalDataDescriptionColumnLabel"),
        );
        setAdditionalDataIsMandatoryColumnLabel(
            await TPI18N.GetText(ResourceSet, "AdditionalDataIsMandatoryColumnLabel"),
        );
        setAdditionalDataOrderColumnLabel(
            await TPI18N.GetText(ResourceSet, "AdditionalDataOrderColumnLabel"),
        );
        setAdditionalDataTypeColumnLabel(
            await TPI18N.GetText(ResourceSet, "AdditionalDataTypeColumnLabel"),
        );

        //Get Tree info
        await getTreeById(treeId);

        loadTreeAndBranches();
    };

    const loadTreeAndBranches = async () => {
        let branches: TPTreeViewDataModel[] =
            await loadBranchesForFirstLevelAndExpandedCurrentNode(
                branchSelected ?? "",
            );
        setTreeviewData1([...branches]);

        if (branchSelected)
            setSelectedBranchByClone(
                { key: branchSelected, value: branchSelected },
                branches,
            );

        setReadyToLoadDataWhenNodeChange(true);
        setIsLoadingScreen(false);
    };

    const getTreeById = async (pRecordId: string) => {
        let serviceClient = new TreeService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest = await serviceClient.getTreeById(
                pRecordId,
                false,
                true,
                expectedCodes,
            );
            let recordInfo: TreeViewModel;
            recordInfo = { ...responseRequest };
            setOriginalRecordDescription(recordInfo?.name);
            setIsTreeAlphabeticOrder(recordInfo.isAlphabeticOrder);
        } catch (e) {
            catchErrorMessageBy("getTreeById", e, false);
        }
    };

    const loadHierarchyBranch = async (
        nodeId: string,
    ): Promise<BranchInfoHierarchyViewModel> => {
        let serviceClient = new BranchService();
        let expectedCodes: Array<number> = [200, 404];
        let hierarchyInfo: BranchInfoHierarchyViewModel = {
            hierarchyIds: "",
            hierarchyDescription: "",
        };
        try {
            let responseRequest = await serviceClient.getHierarchyBranch(
                nodeId,
                false,
                true,
                expectedCodes,
            );
            return responseRequest[0];
        } catch (e) {
            catchErrorMessageBy("loadHierarchyBranch", e);
            return hierarchyInfo;
        }
    };

    const loadChildBranches = async (
        nodeId: string,
        filterIsActive: number,
        filterIsVisible: number,
    ): Promise<Array<TPTreeViewDataModel>> => {
        let serviceClient = new BranchService();
        let expectedCodes: Array<number> = [200, 404];
        let i: number;
        let finalBranches: Array<TPTreeViewDataModel> = [];
        try {
            let responseRequest = await serviceClient.getChildBranches(
                nodeId,
                filterIsActive,
                filterIsVisible,
                false,
                true,
                expectedCodes,
            );
            //transform BranchModel to TPTreeViewDataModel
            for (i = 0; i <= responseRequest.length - 1; i++) {
                let nodeElement: BranchViewModel = responseRequest[i];
                let branchElement: TPTreeViewDataModel = {
                    id: nodeElement.id,
                    label:
                        nodeElement.localizedDescription !== null
                            ? nodeElement.localizedDescription
                            : nodeElement.description,
                    parentId: nodeElement.parentId !== null ? nodeElement.parentId : "",
                    hasChild: nodeElement.hasChild,
                    isExpanded: false,
                    isActive: nodeElement.isActive,
                    isVisible: nodeElement.isVisible,
                    hierarchyIds: nodeElement.hierarchyIds,
                    hierarchyDescription: nodeElement.hierarchyDescription,
                };
                finalBranches.push(branchElement);
            }
            return finalBranches;
        } catch (e) {
            catchErrorMessageBy("loadChildBranches", e, false);
            return [];
        }
    };

    const loadBranchesForFirstLevelAndExpandedCurrentNode = async (
        selectedNode: string,
    ): Promise<Array<TPTreeViewDataModel>> => {
        let serviceClient = new BranchService();
        let expectedCodes: Array<number> = [200, 404];
        let i: number;
        let j: number;
        let finalBranches: Array<TPTreeViewDataModel> = [];
        let needToGetHierarchy: boolean;
        let hierarchyInfo: BranchInfoHierarchyViewModel;
        let hierarchyIds: Array<string> = [];
        let childBranches: Array<TPTreeViewDataModel> = [];
        try {
            setIsLoadingScreen(true);
            let filterIsActive: number = filterIsActive1;
            let filterIsVisible: number = filterIsVisible1;

            let responseRequest = await serviceClient.getFirstLevelBranches(
                treeId,
                filterIsActive,
                filterIsVisible,
                false,
                true,
                expectedCodes,
            );
            //transform BranchModel to TPTreeViewDataModel
            for (i = 0; i <= responseRequest.length - 1; i++) {
                let nodeElement: BranchViewModel = responseRequest[i];
                let branchElement: TPTreeViewDataModel = {
                    id: nodeElement.id,
                    label:
                        nodeElement.localizedDescription !== null
                            ? nodeElement.localizedDescription
                            : nodeElement.description,
                    parentId: nodeElement.parentId !== null ? nodeElement.parentId : "",
                    hasChild: isAdminMode
                        ? nodeElement.hasChild
                            ? true
                            : false
                        : nodeElement.hasChildActive && nodeElement.hasChildVisible
                            ? true
                            : false,
                    isExpanded: false,
                    isActive: nodeElement.isActive,
                    isVisible: nodeElement.isVisible,
                    hierarchyIds: nodeElement.hierarchyIds,
                    hierarchyDescription: nodeElement.hierarchyDescription,
                };
                finalBranches.push(branchElement);
            }
            //populate other levels of current selected node
            if (selectedNode !== "") {
                //populate other levels of current selected node
                //if selected node is one of first level nodes there is not need
                //to get hierarchy
                needToGetHierarchy = true;
                for (i = 0; i <= finalBranches.length - 1; i++) {
                    if (finalBranches[i].id === selectedNode) {
                        needToGetHierarchy = false;
                        break;
                    }
                }
                if (needToGetHierarchy) {
                    //get Hierarchy
                    hierarchyInfo = await loadHierarchyBranch(selectedNode);
                    if (hierarchyInfo.hierarchyIds !== "") {
                        hierarchyIds = hierarchyInfo.hierarchyIds.split("--");
                        //trim spaces
                        for (i = 0; i <= hierarchyIds.length - 1; i++) {
                            hierarchyIds[i] = hierarchyIds[i].trim();
                            //get children for each hierarchyIds and add it to finalBranches
                            childBranches = await loadChildBranches(
                                hierarchyIds[i],
                                filterIsActive,
                                filterIsVisible,
                            );
                            for (j = 0; j <= childBranches.length - 1; j++) {
                                finalBranches.push(childBranches[j]);
                            }
                        }
                        //change expand property for all hierarchyIds except the last one
                        for (i = 0; i <= finalBranches.length - 1; i++) {
                            for (j = 0; j <= hierarchyIds.length - 2; j++) {
                                if (finalBranches[i].id === hierarchyIds[j]) {
                                    finalBranches[i].isExpanded = true;
                                }
                            }
                        }
                    } else {
                        //todo
                    }
                }
            }
            if (selectedNode !== "") {
                setTimeout(() => {
                    var element = document.querySelector(
                        "#" + guidSession + "_" + selectedNode,
                    );
                    if (element) {
                        element.scrollIntoView({
                            behavior: "auto",
                            block: "center",
                            inline: "center",
                        });
                    }
                }, 1000);
            }
            setIsLoadingScreen(false);
            return finalBranches;
        } catch (e) {
            catchErrorMessageBy("loadBranchesForFirstLevelAndExpandedCurrentNode", e);
            return [];
        }
    };

    const getBranchById = async (
        recordId: string,
        newMode: OperationModeEnum,
    ) => {
        //todo leave screen empty when fails
        //now when fails is in invalid state
        let serviceClient = new BranchService();
        let expectedCodes: Array<number> = [200];
        let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
        let recordHelpList: Array<MultilanguageFieldValueViewModel> = [];
        let i: number;
        let j: number;
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.getBranchById(
                recordId,
                false,
                true,
                expectedCodes,
            );

            let recordInfo: BranchViewModel;
            recordInfo = { ...responseRequest };
            let newInsertUpdateState = { ...insertUpdateState };
            newInsertUpdateState.idBranch = recordId;
            newInsertUpdateState.parentId =
                recordInfo.parentId === null ? "" : recordInfo.parentId;
            newInsertUpdateState.parentName =
                recordInfo.localizedParent === null ? "" : recordInfo.localizedParent;
            newInsertUpdateState.recordLanguageList = [];
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }
            recordLanguagesList = await getRecordLanguageList(recordId);
            if (recordLanguagesList.length === 0) {
                catchErrorMessageBy("getBranchById", "recordLanguageList is empty");
                return;
            }

            for (
                i = 0;
                i <= newInsertUpdateState.recordLanguageList.length - 1;
                i++
            ) {
                for (j = 0; j <= recordLanguagesList.length - 1; j++) {
                    if (
                        newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ===
                        recordLanguagesList[j].languageId.toLowerCase()
                    ) {
                        newInsertUpdateState.recordLanguageList[i].value =
                            recordLanguagesList[j].recordDescription
                                ? recordLanguagesList[j].recordDescription
                                : "";
                        break;
                    }
                }
            }
            newInsertUpdateState.treeName =
                recordInfo.localizedTree === null ? "" : recordInfo.localizedTree;
            newInsertUpdateState.value =
                recordInfo.value === null ? "" : recordInfo.value;
            newInsertUpdateState.isActive = recordInfo.isActive;
            newInsertUpdateState.isVisible = recordInfo.isVisible;
            newInsertUpdateState.isSystemRecord = recordInfo.isSystemRecord;
            newInsertUpdateState.hasChildren = recordInfo.hasChild;
            newInsertUpdateState.order = recordInfo.order;
            newInsertUpdateState.parameters =
                recordInfo.jsonParameters === null ? "" : recordInfo.jsonParameters;
            //helps
            newInsertUpdateState.helpLanguageList = [];
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.helpLanguageList.push(keyValueElement);
            }
            recordHelpList = await getHelpLanguageList(recordId);
            if (recordHelpList && recordHelpList.length >= 1) {
                for (
                    i = 0;
                    i <= newInsertUpdateState.helpLanguageList.length - 1;
                    i++
                ) {
                    for (j = 0; j <= recordHelpList.length - 1; j++) {
                        if (
                            newInsertUpdateState.helpLanguageList[i].key.toLowerCase() ===
                            recordHelpList[j].languageId.toLowerCase()
                        ) {
                            newInsertUpdateState.helpLanguageList[i].value = recordHelpList[j]
                                .recordDescription
                                ? recordHelpList[j].recordDescription
                                : "";
                            break;
                        }
                    }
                }
            }

            //validators
            newInsertUpdateState.idErrorMessage = "";
            newInsertUpdateState.nameErrorMessages = initialErrorNameMessages;
            newInsertUpdateState.helpErrorMessages = initialErrorHelpMessages;
            //mode
            newInsertUpdateState.currentOperatorMode = newMode;

            setVisible(newInsertUpdateState.isVisible);

            setInsertUpdateState(newInsertUpdateState);

            await getAdditionalDataBranch(recordId);

            setIsLoadingScreen(false);
        } catch (e) {
            catchErrorMessageBy("getBranchById", e);
        }
    };

    const getAdditionalDataBranch = async (branchId: string) => {
        let additionalDataBranchService = new AdditionalDataBranchService();
        let expectedCodesAdditionalDataBranch: Array<number> = [200, 404];
        try {
            //get Additional data branch
            let responseRequestAdditionalDataBranch =
                await additionalDataBranchService.getByBranchId(
                    branchId,
                    false,
                    true,
                    expectedCodesAdditionalDataBranch,
                );

            let recordInfoAdditionalDataBranch: Array<AdditionalDataBranchViewModel>;
            recordInfoAdditionalDataBranch = [...responseRequestAdditionalDataBranch];
            setAdditionalDataBranchKeyValue(recordInfoAdditionalDataBranch);
        } catch (e) {
            catchErrorMessageBy("getAdditionalDataBranch", e, false);
        }
    };

    const getRecordLanguageList = async (
        recordId: String,
    ): Promise<Array<MultilanguageFieldValueViewModel>> => {
        //Multilanguage const
        const multiLanguageTableName: String = "BRANch";
        const multiLanguageFieldName: String = "Description_BRAN";

        let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest =
                await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
                    multiLanguageTableName,
                    multiLanguageFieldName,
                    recordId,
                    false,
                    true,
                    expectedCodes,
                );

            let recordInfo: Array<MultilanguageFieldValueViewModel>;
            recordInfo = [...responseRequest];
            return recordInfo;
        } catch (e) {
            catchErrorMessageBy("getRecordLanguageList", e, false);
            return [];
        }
    };

    const getHelpLanguageList = async (
        recordId: String,
    ): Promise<Array<MultilanguageFieldValueViewModel>> => {
        //Multilanguage const
        const multiLanguageTableName: String = "BRANch";
        const multiLanguageFieldName: String = "HelpComments_BRAN";

        let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
        let expectedCodes: Array<number> = [200, 404];
        try {
            let responseRequest =
                await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
                    multiLanguageTableName,
                    multiLanguageFieldName,
                    recordId,
                    false,
                    false,
                    expectedCodes,
                );

            let recordInfo: Array<MultilanguageFieldValueViewModel>;
            recordInfo = [...responseRequest];
            return recordInfo;
        } catch (e) {
            catchErrorMessageBy("getHelpLanguageList", e, false);
            return [];
        }
    };

    const changeOperationMode = async (
        newMode: OperationModeEnum,
        parentIdForAddChild: string,
    ) => {
        //NOTE:call this only from cancel button or from AddRoot/AddChild buttons
        //If you call from other scenarios you can have issues due to current screen states
        let i: number;
        let newOperationModeState: ScreenStateOperationType;

        let newInsertUpdateState: InsertUpdateStateType;

        switch (newMode) {
            case OperationModeEnum.normal:
                setTitleLabel(await TPI18N.GetText(ResourceSet, "TitleLabel"));

                if (currentSelectedNode1 === "") {
                    //entity state
                    newInsertUpdateState = insertUpdateInitialState;
                    newInsertUpdateState.currentOperatorMode = newMode;
                    setInsertUpdateState(newInsertUpdateState);
                    //screen state
                    newOperationModeState = initialOperationModeState;
                    setOperationModeState(newOperationModeState);
                } else {
                    //entity state
                    await getBranchById(currentSelectedNode1, OperationModeEnum.normal);
                    //screen state
                    newOperationModeState = initialOperationModeState;
                    setOperationModeState(newOperationModeState);
                }

                break;
            case OperationModeEnum.add_root:
            case OperationModeEnum.add_child:
            case OperationModeEnum.edit:
                if (newMode === OperationModeEnum.add_root) {
                    setTitleLabel(await TPI18N.GetText(ResourceSet, "TitleLabelAddRoot"));
                }
                if (newMode === OperationModeEnum.add_child) {
                    setTitleLabel(
                        await TPI18N.GetText(ResourceSet, "TitleLabelAddChild"),
                    );
                }
                if (newMode === OperationModeEnum.edit) {
                    setTitleLabel(await TPI18N.GetText(ResourceSet, "TitleLabelEdit"));
                }
                if (newMode !== OperationModeEnum.edit) {
                    //entity state
                    newInsertUpdateState = insertUpdateInitialState;
                    newInsertUpdateState.currentOperatorMode = newMode;
                    newInsertUpdateState.treeName = originalRecordDescription;
                    if (newMode === OperationModeEnum.add_child) {
                        newInsertUpdateState.parentId = parentIdForAddChild;
                    }
                    setInsertUpdateState(newInsertUpdateState);
                } else {
                    newInsertUpdateState = { ...insertUpdateState };
                    newInsertUpdateState.parentId = parentIdForAddChild;
                    newInsertUpdateState.currentOperatorMode = newMode;
                    setInsertUpdateState(newInsertUpdateState);
                }
                //screen state
                newOperationModeState = initialOperationModeState;
                newOperationModeState.isTreeEnabled = false;
                newOperationModeState.isToolbarButtonsVisible = false;
                if (newMode === OperationModeEnum.edit) {
                    newOperationModeState.isIdBranchEnabled = false;
                } else {
                    newOperationModeState.isIdBranchEnabled = true;
                }
                for (
                    i = 0;
                    i <= newOperationModeState.isNameArrayEnabled.length - 1;
                    i++
                ) {
                    newOperationModeState.isNameArrayEnabled[i] = true;
                }
                newOperationModeState.isBranchValueEnabled = true;
                //todo numeric tree
                newOperationModeState.isOrderEnabled = true;
                newOperationModeState.isParametersEnabled = true;
                for (
                    i = 0;
                    i <= newOperationModeState.isHelpArrayEnabled.length - 1;
                    i++
                ) {
                    newOperationModeState.isHelpArrayEnabled[i] = true;
                }
                newOperationModeState.isActiveEnabled = true;
                newOperationModeState.isVisibleEnabled = true;

                newOperationModeState.isAdditionalDataTableEnabled = false;
                newOperationModeState.isSaveCancelButtonEnabled = true;
                setOperationModeState(newOperationModeState);

                break;

            case OperationModeEnum.clone:
                break;
            case OperationModeEnum.change_parent:
                break;
            default:
                //todo logs
                break;
        }
    };

    //add root
    const handleNewRootClick = async () => {
        await changeOperationMode(OperationModeEnum.add_root, "");
        setTreeNameValue(originalRecordDescription);
        setVisible(false);
        if (!dataArray1 || (dataArray1 && dataArray1.length <= 0))
            setOperationModeRootActive(true);
        generalAutomaticId();
    };

    //add child
    const handleNewChildClick = async (parentId: string) => {
        await changeOperationMode(OperationModeEnum.add_child, parentId);
        setParentNameValue(
            currentSelectedLabel1 && currentSelectedLabel1.length >= 1
                ? currentSelectedLabel1
                : "",
        );
        setVisible(false);
        setOperationModeRootActive(false);
        generalAutomaticId();
    };

    //delete
    const handleDeleteBranchClick = (id: string) => {
        handleDeleteClick(currentSelectedNode1);
        setVisible(false);
    };

    //Modal Question to delete branch
    const handleDeleteClick = (id: string) => {
        let newModalQuestionState: TPModalQuestionState;
        newModalQuestionState = { ...modalQuestionState };
        newModalQuestionState.isShown = true;
        newModalQuestionState.callBackData = { recordId: id };
        setModalQuestionState(newModalQuestionState);
    };

    //Delete branch after question confirmation
    const handleCallBackModal = async (
        confirmDelete: boolean,
        callBackData: any,
    ) => {
        let expectedCodes: Array<number> = [200];
        let serviceClient = new BranchService();
        let newModalQuestionState: TPModalQuestionState;
        newModalQuestionState = { ...modalQuestionState };
        newModalQuestionState.isShown = false;
        newModalQuestionState.callBackData = {};
        setModalQuestionState(newModalQuestionState);
        if (confirmDelete) {
            try {
                setIsLoadingScreen(true);
                let responseRequest = await serviceClient.deleteBranchById(
                    callBackData.recordId,
                    true,
                    true,
                    expectedCodes,
                );
                if (responseRequest.responseResult) {
                    //screen state
                    let newOperationModeState: ScreenStateOperationType;
                    newOperationModeState = initialOperationModeState;
                    setOperationModeState(newOperationModeState);
                    //entity state
                    let newInsertUpdateState: InsertUpdateStateType;
                    newInsertUpdateState = insertUpdateInitialState;
                    setInsertUpdateState(newInsertUpdateState);
                    //other screen states
                    setReadyToLoadDataWhenNodeChange(false);
                    setCurrentSelectedNode1("");
                    setCurrentSelectedLabel1("");
                    let branches: Array<TPTreeViewDataModel> = [];
                    branches = await loadBranchesForFirstLevelAndExpandedCurrentNode("");
                    setTreeviewData1([...branches]);
                    setReadyToLoadDataWhenNodeChange(true);

                    setTimeout(() => {
                        var element = document.querySelector(`#treeadmin${guidSession}`);
                        if (element) {
                            element.scrollTop = 0;
                        }
                    }, 1000);
                } else {
                    setIsLoadingScreen(false);
                }
            } catch (e) {
                catchErrorMessageBy("handleCallBackModal", e);
            }
        }
    };

    // handle clone branch by click event
    const handleCloneBranchClick = () => {
        // set current branch {id, label}
        const branchId = insertUpdateState.idBranch;
        const branchLabel = insertUpdateState.recordLanguageList[0].value;

        setCloneBranchNode(branchId);
        setCloneBranchLabel(branchLabel);

        // set clone branch modal data
        cloneBranchModal.isShown = true;
        cloneBranchModal.branchId = branchId;
        cloneBranchModal.branchName = branchLabel;

        setChangeCloneBranch(cloneBranchModal);

        setVisible(false);
    };

    // when the modal is closed clean data
    const handleCloseCloneBranchModal = async (
        status: boolean,
        response: KeyList = {} as KeyList,
        treeIdSelected: string = "",
    ) => {
        if (!status) resetCloneBranchData();

        if (treeIdSelected === "") return;

        if (treeIdSelected !== "" && treeIdSelected === treeId) {
            let treeUpdated: TPTreeViewDataModel[] =
                await loadBranchesForFirstLevelAndExpandedCurrentNode(response.value);
            setTreeviewData1([...treeUpdated]);
            setSelectedBranchByClone(response, treeUpdated);
            return;
        }

        changeTreeSelected({
            command: "update",
            recordId: treeIdSelected,
            branch: response.value,
        });
    };

    const setSelectedBranchByClone = (
        response: KeyList,
        treeData: TPTreeViewDataModel[],
    ) => {
        let newBranch: TPTreeViewDataModel =
            treeData.find((item) => item.id === response.value) ??
            ({} as TPTreeViewDataModel);

        setCurrentSelectedNode1(newBranch.id);
        setCurrentSelectedLabel1(newBranch.label);
        setCurrentSelectedHierarchyLabel1(newBranch.hierarchyIds);
    };

    // clean all clone branch data
    const resetCloneBranchData = () => {
        setCloneBranchNode("");
        setCloneBranchLabel("");
        setChangeCloneBranch({
            branchId: "",
            branchName: "",
            isShown: false,
        });
    };

    //change parent show modal
    const handleChangeParentBranchClick = async () => {
        let guidSessionTree: string;
        //set current node
        setChangeParentCurrentSelectedNode(insertUpdateState.idBranch);
        setChangeParentCurrentSelectedLabel(
            insertUpdateState.recordLanguageList[0].value,
        );
        //Get first level nodes and expanded nodes for current selected node
        let branches: Array<TPTreeViewDataModel> = [];
        branches =
            await changeParentLoadBranchesForFirstLevelAndExpandedCurrentNode(
                insertUpdateState.idBranch,
            );
        setChangeParentTreeviewData([...branches]);

        //modal
        let newChangeParentModalTreeViewState = {
            ...changeParentModalTreeViewState,
        };
        newChangeParentModalTreeViewState.isShown = true;
        guidSessionTree = "tree" + uuidv4().replaceAll("-", "");
        newChangeParentModalTreeViewState.guidSession = guidSessionTree;
        setChangeParentModalTreeViewState(newChangeParentModalTreeViewState);
        setVisible(false);
        setTimeout(() => {
            var element = document.querySelector(
                "#" + guidSessionTree + "_" + insertUpdateState.idBranch,
            );
            if (element) {
                element.scrollIntoView({
                    behavior: "auto",
                    block: "center",
                    inline: "center",
                });
            }
        }, 1000);
    };

    //populate change parent for modal
    const changeParentLoadBranchesForFirstLevelAndExpandedCurrentNode = async (
        selectedNode: string,
    ): Promise<Array<TPTreeViewDataModel>> => {
        let serviceClient = new BranchService();
        let expectedCodes: Array<number> = [200, 404];
        let i: number;
        let j: number;
        let finalBranches: Array<TPTreeViewDataModel> = [];
        let needToGetHierarchy: boolean;
        let hierarchyInfo: BranchInfoHierarchyViewModel;
        let hierarchyIds: Array<string> = [];
        let childBranches: Array<TPTreeViewDataModel> = [];
        try {
            setIsLoadingScreen(true);
            let filterIsActive: number = filterIsActive1;
            let filterIsVisible: number = filterIsVisible1;

            let responseRequest = await serviceClient.getFirstLevelBranches(
                treeId,
                filterIsActive,
                filterIsVisible,
                false,
                true,
                expectedCodes,
            );
            //transform BranchModel to TPTreeViewDataModel
            for (i = 0; i <= responseRequest.length - 1; i++) {
                let nodeElement: BranchViewModel = responseRequest[i];
                let branchElement: TPTreeViewDataModel = {
                    id: nodeElement.id,
                    label:
                        nodeElement.localizedDescription !== null
                            ? nodeElement.localizedDescription
                            : nodeElement.description,
                    parentId: nodeElement.parentId !== null ? nodeElement.parentId : "",
                    hasChild: isAdminMode
                        ? nodeElement.hasChild
                            ? true
                            : false
                        : nodeElement.hasChildActive && nodeElement.hasChildVisible
                            ? true
                            : false,
                    isExpanded: false,
                    isActive: nodeElement.isActive,
                    isVisible: nodeElement.isVisible,
                    hierarchyIds: nodeElement.hierarchyIds,
                    hierarchyDescription: nodeElement.hierarchyDescription,
                };
                finalBranches.push(branchElement);
            }
            //populate other levels of current selected node
            if (selectedNode !== "") {
                //populate other levels of current selected node
                //if selected node is one of first level nodes there is not need
                //to get hierarchy
                needToGetHierarchy = true;
                for (i = 0; i <= finalBranches.length - 1; i++) {
                    if (finalBranches[i].id === selectedNode) {
                        needToGetHierarchy = false;
                        break;
                    }
                }
                if (needToGetHierarchy) {
                    //get Hierarchy
                    hierarchyInfo = await loadHierarchyBranch(selectedNode);
                    if (hierarchyInfo.hierarchyIds !== "") {
                        hierarchyIds = hierarchyInfo.hierarchyIds.split("--");
                        //trim spaces
                        for (i = 0; i <= hierarchyIds.length - 1; i++) {
                            hierarchyIds[i] = hierarchyIds[i].trim();
                            //get children for each hierarchyIds and add it to finalBranches
                            childBranches = await loadChildBranches(
                                hierarchyIds[i],
                                filterIsActive,
                                filterIsVisible,
                            );
                            for (j = 0; j <= childBranches.length - 1; j++) {
                                finalBranches.push(childBranches[j]);
                            }
                        }
                        //change expand property for all hierarchyIds except the last one
                        for (i = 0; i <= finalBranches.length - 1; i++) {
                            for (j = 0; j <= hierarchyIds.length - 2; j++) {
                                if (finalBranches[i].id === hierarchyIds[j]) {
                                    finalBranches[i].isExpanded = true;
                                }
                            }
                        }
                    } else {
                        //todo
                    }
                }
            }
            setIsLoadingScreen(false);
            return finalBranches;
        } catch (e) {
            catchErrorMessageBy(
                "changeParentLoadBranchesForFirstLevelAndExpandedCurrentNode",
                e,
            );
            return [];
        }
    };

    //call back change parent modal
    const handleCallBackChangeParentModalTreeView = async (
        confirmType: boolean,
        dataToReturn: string,
    ) => {
        let newChangeParentModalTreeViewState = {
            ...changeParentModalTreeViewState,
        };
        if (!confirmType) {
            //clean treeview data
            setChangeParentCurrentSelectedNode("");
            setChangeParentCurrentSelectedLabel("");
            setChangeParentTreeviewData([]);
            //hide modal
            newChangeParentModalTreeViewState.isShown = false;
            newChangeParentModalTreeViewState.guidSession = "";
            setChangeParentModalTreeViewState(newChangeParentModalTreeViewState);
            return;
        }
        //clean treeview data
        setChangeParentCurrentSelectedNode("");
        setChangeParentCurrentSelectedLabel("");
        setChangeParentTreeviewData([]);
        //hide modal
        newChangeParentModalTreeViewState.isShown = false;
        newChangeParentModalTreeViewState.guidSession = "";
        setChangeParentModalTreeViewState(newChangeParentModalTreeViewState);
        if (confirmType) {
            let expectedCodes: Array<number> = [200];
            let serviceClient = new BranchService();
            let inputDTO: BranchChangeParentInputDTO = {
                treeId: treeId,
                currentBranchId: currentSelectedNode1,
                newParentId: dataToReturn,
            };
            try {
                setIsLoadingScreen(true);
                let responseRequest = await serviceClient.changeParentId(
                    inputDTO,
                    true,
                    true,
                    expectedCodes,
                );
                if (responseRequest.responseResult) {
                    //screen state
                    let newOperationModeState: ScreenStateOperationType;
                    newOperationModeState = initialOperationModeState;
                    setOperationModeState(newOperationModeState);
                    //entity state
                    let newInsertUpdateState: InsertUpdateStateType;
                    newInsertUpdateState = insertUpdateInitialState;
                    setInsertUpdateState(newInsertUpdateState);
                    //other screen states
                    setReadyToLoadDataWhenNodeChange(false);
                    let branches: Array<TPTreeViewDataModel> = [];
                    branches =
                        await loadBranchesForFirstLevelAndExpandedCurrentNode(
                            currentSelectedNode1,
                        );
                    setTreeviewData1([...branches]);
                    setReadyToLoadDataWhenNodeChange(true);

                    setTimeout(() => {
                        var element = document.querySelector(
                            "#" + "treeadmin" + guidSession,
                        );
                        if (element) {
                            element.scrollTop = 0;
                        }
                    }, 1000);
                } else {
                    setIsLoadingScreen(false);
                }
            } catch (e) {
                catchErrorMessageBy("handleCallBackChangeParentModalTreeView", e);
            }
        }
    };

    //Edit
    const handleEditBranchClick = async (parentId: string) => {
        await changeOperationMode(OperationModeEnum.edit, parentId);
        setVisible(false);
    };

    //id
    const handleBranchIdChange = (e: any) => {
        let newInsertUpdateState: InsertUpdateStateType;
        newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.idBranch = e.target.value;
        newInsertUpdateState.idErrorMessage = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    //name languages
    const handleLanguageChange = (index: number, newName: string) => {
        let newInsertUpdateState: InsertUpdateStateType;
        newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.recordLanguageList[index].value = newName;
        newInsertUpdateState.nameErrorMessages[index] = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    //value
    const handleBranchValueChange = (e: any) => {
        let newInsertUpdateState: InsertUpdateStateType;
        newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.value = e.target.value;
        newInsertUpdateState.valueErrorMessage = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    //order
    const handleOrderChange = (newOrder: number) => {
        let newInsertUpdateState: InsertUpdateStateType;
        newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.order = newOrder;
        setInsertUpdateState(newInsertUpdateState);
    };

    //parameters
    const handleParametersChange = (e: any) => {
        let newInsertUpdateState: InsertUpdateStateType;
        newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.parameters = e.target.value;
        newInsertUpdateState.parametersErrorMessage = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    //help languages
    const handleHelpChange = (index: number, newName: string) => {
        let newInsertUpdateState: InsertUpdateStateType;
        newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.helpLanguageList[index].value = newName;
        newInsertUpdateState.helpErrorMessages[index] = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    //active
    const handleActiveChange = () => {
        let newInsertUpdateState: InsertUpdateStateType;
        newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.isActive = !newInsertUpdateState.isActive;
        setInsertUpdateState(newInsertUpdateState);
    };

    //isVisible
    const handleIsVisible = () => {
        let newInsertUpdateState: InsertUpdateStateType;
        newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.isVisible = !newInsertUpdateState.isVisible;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleOkButtonClick = async () => {
        let i: number;
        let n: number;

        switch (insertUpdateState.currentOperatorMode) {
            case OperationModeEnum.add_root:
            case OperationModeEnum.add_child:
            case OperationModeEnum.edit:
                let recordInputDTO: BranchInputDTO = {
                    id: insertUpdateState.idBranch,
                    description: insertUpdateState.recordLanguageList[0].value,
                    parentId:
                        insertUpdateState.currentOperatorMode === OperationModeEnum.add_root
                            ? null
                            : insertUpdateState.parentId,
                    descriptionLocalizedValues: [],
                    value: insertUpdateState.value,
                    order: insertUpdateState.order,
                    jsonParameters: insertUpdateState.parameters,
                    helpCommentsLocalizedValues: [],
                    isActive: insertUpdateState.isActive,
                    isVisible: insertUpdateState.isVisible,
                    treeId: treeId,
                    helpComments: insertUpdateState.helpLanguageList[0].value,
                };
                n = insertUpdateState.recordLanguageList.length;
                for (i = 0; i <= n - 1; i++) {
                    let item: TPKeyValue;
                    item = insertUpdateState.recordLanguageList[i];
                    recordInputDTO.descriptionLocalizedValues.push({
                        order: i + 1,
                        languageId: item.key,
                        localizedValue: item.value,
                    });
                }
                n = insertUpdateState.helpLanguageList.length;
                for (i = 0; i <= n - 1; i++) {
                    let item: TPKeyValue;
                    item = insertUpdateState.helpLanguageList[i];
                    recordInputDTO.helpCommentsLocalizedValues.push({
                        order: i + 1,
                        languageId: item.key,
                        localizedValue: item.value,
                    });
                }
                if (
                    insertUpdateState.currentOperatorMode ===
                    OperationModeEnum.add_child ||
                    insertUpdateState.currentOperatorMode === OperationModeEnum.edit
                ) {
                    recordInputDTO.parentId = insertUpdateState.parentId;
                    if (
                        recordInputDTO.parentId !== null &&
                        recordInputDTO.parentId.trim() === ""
                    ) {
                        recordInputDTO.parentId = null;
                    }
                }
                let inputDTOValidator = new BranchInputDTOValidator();
                let resultValidator = inputDTOValidator.validate(recordInputDTO);
                if (!TPGlobal.TPIsEmpty(resultValidator)) {
                    let newInsertUpdateState = { ...insertUpdateState };
                    if (resultValidator.id) {
                        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
                            resultValidator.id,
                        );
                    } else {
                        newInsertUpdateState.idErrorMessage = "";
                    }
                    if (resultValidator.description) {
                        newInsertUpdateState.nameErrorMessages[0] =
                            await TPI18N.GetResource(resultValidator.description);
                    } else {
                        newInsertUpdateState.nameErrorMessages[0] = "";
                    }
                    if (resultValidator.descriptionLocalizedValues) {
                        n = insertUpdateState.recordLanguageList.length;
                        for (i = 1; i <= n - 1; i++) {
                            if (resultValidator.descriptionLocalizedValues[i] != null) {
                                newInsertUpdateState.nameErrorMessages[i] =
                                    await TPI18N.GetResource(
                                        String(resultValidator.descriptionLocalizedValues[i]),
                                    );
                            }
                        }
                    } else {
                        n = insertUpdateState.recordLanguageList.length;
                        for (i = 1; i <= n - 1; i++) {
                            newInsertUpdateState.nameErrorMessages[i] = "";
                        }
                    }
                    if (resultValidator.value) {
                        newInsertUpdateState.valueErrorMessage = await TPI18N.GetResource(
                            resultValidator.value,
                        );
                    } else {
                        newInsertUpdateState.valueErrorMessage = "";
                    }
                    if (resultValidator.jsonParameters) {
                        newInsertUpdateState.parametersErrorMessage =
                            await TPI18N.GetResource(resultValidator.jsonParameters);
                    } else {
                        newInsertUpdateState.parametersErrorMessage = "";
                    }
                    if (resultValidator.helpComments) {
                        newInsertUpdateState.helpErrorMessages[0] =
                            await TPI18N.GetResource(resultValidator.helpComments);
                    } else {
                        newInsertUpdateState.helpErrorMessages[0] = "";
                    }
                    if (resultValidator.helpCommentsLocalizedValues) {
                        if (
                            resultValidator.helpCommentsLocalizedValues ===
                            resourceInvalidHelpComments
                        ) {
                            newInsertUpdateState.helpErrorMessages[0] =
                                await TPI18N.GetResource(String(resourceInvalidHelpComments));
                        } else {
                            n = insertUpdateState.helpLanguageList.length;
                            for (i = 1; i <= n - 1; i++) {
                                if (resultValidator.helpCommentsLocalizedValues[i] != null) {
                                    newInsertUpdateState.helpErrorMessages[i] =
                                        await TPI18N.GetResource(
                                            String(resultValidator.helpCommentsLocalizedValues[i]),
                                        );
                                }
                            }
                        }
                    } else {
                        n = insertUpdateState.helpLanguageList.length;
                        for (i = 1; i <= n - 1; i++) {
                            newInsertUpdateState.helpErrorMessages[i] = "";
                        }
                    }
                    setInsertUpdateState(newInsertUpdateState);
                    return;
                }

                if (recordInputDTO.helpComments === "") {
                    recordInputDTO.helpCommentsLocalizedValues = [];
                }

                if (insertUpdateState.currentOperatorMode === OperationModeEnum.edit) {
                    realUpdateBranch(recordInputDTO);
                } else {
                    await realInsertRootChildNode(recordInputDTO);
                }

                break;
        }
    };

    const realInsertRootChildNode = async (recordInputDTO: BranchInputDTO) => {
        let serviceClient = new BranchService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.insertBranch(
                recordInputDTO,
                true,
                true,
                expectedCodes,
            );
            if (responseRequest.responseResult) {
                //screen state
                let newOperationModeState: ScreenStateOperationType;
                newOperationModeState = initialOperationModeState;
                setOperationModeState(newOperationModeState);
                //entity state
                let newInsertUpdateState: InsertUpdateStateType;
                newInsertUpdateState = insertUpdateInitialState;
                setInsertUpdateState(newInsertUpdateState);
                //other screen states
                //change readyToLoadDataWhenNodeChange, change currentSelectedNode1 to new inserted node
                setReadyToLoadDataWhenNodeChange(false);
                setCurrentSelectedNode1(recordInputDTO.id);
                setCurrentSelectedLabel1(recordInputDTO.description);
                let branches: Array<TPTreeViewDataModel> = [];
                branches = await loadBranchesForFirstLevelAndExpandedCurrentNode(
                    recordInputDTO.id,
                );
                setTreeviewData1([...branches]);
                setReadyToLoadDataWhenNodeChange(true);
                setVisible(true);
            } else {
                setIsLoadingScreen(false);
            }
        } catch (e) {
            catchErrorMessageBy("realInsertRootChildNode", e);
        }
    };

    const realUpdateBranch = async (recordInputDTO: BranchInputDTO) => {
        let serviceClient = new BranchService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.updateBranch(
                recordInputDTO,
                true,
                true,
                expectedCodes,
            );
            if (responseRequest.responseResult) {
                //screen state
                let newOperationModeState: ScreenStateOperationType;
                newOperationModeState = initialOperationModeState;
                setOperationModeState(newOperationModeState);
                //entity state
                let newInsertUpdateState: InsertUpdateStateType;
                newInsertUpdateState = insertUpdateInitialState;
                setInsertUpdateState(newInsertUpdateState);
                //other screen states
                //change readyToLoadDataWhenNodeChange, change currentSelectedNode1 to new inserted node
                setReadyToLoadDataWhenNodeChange(false);
                setCurrentSelectedNode1(recordInputDTO.id);
                setCurrentSelectedLabel1(recordInputDTO.description);
                let branches: Array<TPTreeViewDataModel> = [];
                branches = await loadBranchesForFirstLevelAndExpandedCurrentNode(
                    recordInputDTO.id,
                );
                setTreeviewData1([...branches]);
                setReadyToLoadDataWhenNodeChange(true);
            }
        } catch (e) {
            catchErrorMessageBy("realUpdateBranch", e);
        }
    };

    const handleCancelButtonClick = async () => {
        await changeOperationMode(OperationModeEnum.normal, "");
    };

    const callbackAdditionalDataModalAnswer = async (
        response: boolean,
        pAdditionalDataId: string,
        pOrder: number,
        pIsMandatory: boolean,
    ) => {
        setIsShownModalAdditionalData(false);
        if (response) {
            let recordInputDTO: AdditionalDataBranchInputDTO = {
                branchId: insertUpdateState.idBranch,
                additionalDataId: pAdditionalDataId,
                order: pOrder,
                isMandatory: pIsMandatory,
            };
            //Save additional data branch
            insertAdditionalDataBranch(recordInputDTO);
        }
    };

    const insertAdditionalDataBranch = async (
        inputDTO: AdditionalDataBranchInputDTO,
    ) => {
        let serviceClient = new AdditionalDataBranchService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.insert(
                inputDTO,
                true,
                true,
                expectedCodes,
            );
            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                await getAdditionalDataBranch(inputDTO.branchId);
            }
        } catch (e) {
            catchErrorMessageBy("insertAdditionalDataBranch", e);
        }
    };

    const handlerDeleteAdditionalDataBranch = async (
        branchId: string,
        idAdditionalDataBranch: string,
    ) => {
        let newModalAddBranchQuestionState: TPModalQuestionState;
        newModalAddBranchQuestionState = { ...modalAddBranchQuestionState };
        newModalAddBranchQuestionState.isShown = true;
        newModalAddBranchQuestionState.callBackData = {
            branchId: branchId,
            idAdditionalDataBranch: idAdditionalDataBranch,
        };
        setModalAddBranchQuestionState(newModalAddBranchQuestionState);
    };

    const handleCallBackModalAddData = async (
        confirmDelete: boolean,
        callBackData: any,
    ) => {
        let expectedCodes: Array<number> = [200];
        let serviceClient = new AdditionalDataBranchService();
        let newModalAddBranchQuestionState: TPModalQuestionState;
        newModalAddBranchQuestionState = { ...modalAddBranchQuestionState };
        newModalAddBranchQuestionState.isShown = false;
        newModalAddBranchQuestionState.callBackData = {};
        setModalAddBranchQuestionState(newModalAddBranchQuestionState);
        if (confirmDelete) {
            try {
                setIsLoadingScreen(true);
                let responseRequest = await serviceClient.delete(
                    callBackData.branchId,
                    callBackData.idAdditionalDataBranch,
                    true,
                    true,
                    expectedCodes,
                );
                setIsLoadingScreen(false);
                if (responseRequest.responseData.responseCode !== 500) {
                    await getAdditionalDataBranch(callBackData.branchId);
                }
            } catch (e) {
                catchErrorMessageBy("handleCallBackModalAddData", e);
            }
        }
    };

    const {
        isOpen: isOpenModalLanguageList,
        openModal: handleOpenModalLanguageList,
        closeModal: handleCloseModalLanguageList,
        saveChanges: handleSaveChangesModalLanguageList,
    } = useModal(false);

    const {
        isOpen: isOpenModalHelpLanguageList,
        openModal: handleOpenModalHelpLanguageList,
        closeModal: handleCloseModalHelpLanguageList,
        saveChanges: handleSaveChangesModalHelpLanguageList,
    } = useModal(false);

    const getTreePropsToChangeParent = (): TPTreeViewProps => {
        return {
            treeId: treeId,
            dataSource: changeParentDataArray,
            setDataSource: setChangeParentTreeviewData,
            selectedNode: changeParentCurrentSelectedNode,
            setSelectedNode: setChangeParentCurrentSelectedNode,
            selectedLabel: changeParentCurrentSelectedLabel,
            setSelectedLabel: setChangeParentCurrentSelectedLabel,
            selectedHierarchyLabel: changeParentCurrentSelectedHierarchyLabel,
            setSelectedHierarchyLabel: setChangeParentCurrentSelectedHierarchyLabel,
            filterIsActive: filterIsActive1,
            filterIsVisible: filterIsVisible1,
            guidTree: changeParentModalTreeViewState.guidSession,
            isAdminMode: true,
            mustSelectLastLevelBranch: false,
            renderParent: "",
            setIsLoading: setIsLoadingScreen,
            handleChangeNodeCallBack: handleCallBackChangeParentModalTreeView,
            handleActionTreeView: handleActionTreeView
        };
    };

    // functions to get the error messages by specific tasks
    const catchErrorMessageBy = (
        functionName: string,
        error: unknown,
        stopLoading: boolean = true,
    ) => {
        const errorMsj = getErrorMessageBy(functionName);

        TPLog.Log(errorMsj, TPLogType.ERROR, error);
        console.error(errorMsj);

        stopLoading && setIsLoadingScreen(false);
    };

    const getErrorMessageBy = (functionName: string): string => {
        return `Error ${componentFileName} ${functionName} Exception`;
    };

    // branch buttons
    const getRootButtonClass = (): string => {
        return operationModeState.isToolbarButtonsVisible ? "" : "tpdisabled";
    };

    const getBranchButtonClass = (): string => {
        if (operationModeState.isToolbarButtonsVisible) {
            return currentSelectedNode1 === "" ? "tpdisabled" : "";
        } else {
            return "tpdisabled";
        }
    };

    const getDeleteButtonClass = (): string => {
        if (operationModeState.isToolbarButtonsVisible) {
            return currentSelectedNode1 === "" ? "tpdisabled" : "";
        } else {
            return "tpdisabled";
        }
    };
    const branchButtons: BranchButtons[] = [

        {
            id: "add-child-button",
            text: addBranchLabel,
            clickEvent: () => handleNewChildClick(insertUpdateState.idBranch),
            icon: TPIconTypes.doubleArrowTop,
            className: getBranchButtonClass(),
            active: !operationModeRootActive
        },
        {
            id: "add-root-button",
            text: addRootLabel,
            clickEvent: () => handleNewRootClick(),
            icon: TPIconTypes.doubleArrowBottom,
            className: getRootButtonClass(),
            active: !operationModeRootActive
        },
        {
            id: "add-multiple-child-button",
            text: addMultipleBranchesLabel,
            clickEvent: () => { setActiveModalMultiBranche(true); setValueMultiBranch(""); },
            icon: TPIconTypes.branchIconBottom,
            className: getBranchButtonClass(),
            active: !operationModeRootActive
        },
        {
            id: "change-parent-button",
            text: changeParentLabel,
            clickEvent: () => handleChangeParentBranchClick(),
            icon: TPIconTypes.unionIconBottom,
            className: getBranchButtonClass(),
            active: !operationModeRootActive
        },
        {
            id: "back-parent-button",
            text: backLabel,
            clickEvent: () => { setOperationModeRootActive(false); },
            icon: TPIconTypes.goArrowLeft,
            className: getBranchButtonClass(),
            active: operationModeRootActive
        }

    ];

    //{
    //    id: "clone-branch-button",
    //    text: cloneLabel,
    //    clickEvent: () => handleCloneBranchClick(),
    //    icon: TPIconTypes.clone,
    //    className: getBranchButtonClass(),
    //},

    //{
    //    id: "delete-branch-button",
    //    text: deleteLabel,
    //    clickEvent: () => handleDeleteBranchClick(""),
    //    icon: TPIconTypes.delete,
    //    className: getDeleteButtonClass(),
    //},

    //{
    //    id: "edit-branch-button",
    //    text: editLabel,
    //    clickEvent: () => handleEditBranchClick(insertUpdateState.parentId),
    //    icon: TPIconTypes.edit,
    //    className: getBranchButtonClass(),
    //},

    const generalAutomaticId = async () => {
        let serviceClient = new SequenceService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.generalAutomaticId(
                false,
                true,
                expectedCodes,
                SequenceGeneratorSequencesNameEnum.SQBRAN,
            );

            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                // callBackResult({ result: "OK", recordId: recordId });
                let result = responseRequest?.responseData?.data[0]?.sequenceCode;
                setReferenceId(result);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} updatetFunction ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} updatetFunction ex`);
            setIsLoadingScreen(false);
        }
    };

    const handleTabChange = useCallback(
        (event: React.SyntheticEvent, newValue: number) => {
            let action = asideMenuState[newValue];
            if (action)
                action.onClickHandler();
            setActiveTab(newValue);
        },
        []
    );




    //#region Tree view


    const handleActionTreeView = (obj: any) => {
        let item = obj as { action: OperationModeEnum, value: any };

        if (item.action == OperationModeEnum.add_root) handleNewRootClick();
        if (item.action == OperationModeEnum.edit) handleEditBranchClick((item.value.parentId));
        if (item.action == OperationModeEnum.delete) handleDeleteBranchClick(item.value.id);
        if (item.action == OperationModeEnum.clone) handleCloneBranchClick();

    }

    const formDesignColumns: CustomColumnNames<AdditionalDataBranchViewGridModel> = {
        ID: columnNameGrid.ID,
        Delete: columnNameGrid.Delete,
        Mandatory: columnNameGrid.Mandatory,
        Order: columnNameGrid.Order,
        Type: columnNameGrid.Type,
        Name: columnNameGrid.Name
    };

    const customColumns: ColumnStyles<AdditionalDataBranchViewGridModel> = {
        Delete: ({ value, item }) => (
            <TPButton
                id={`delete-addition-item-${item.ID}`}
                onClick={() => {
                    handlerDeleteAdditionalDataBranch(
                        item.ID,
                        item.AdditionalDataId,
                    );
                }}
                type={TPButtonTypes.icon}
                icon={TPIconTypes.trash}
            ></TPButton>
        ),
        ID: ({ value, item }) => (
            <TablePrimaryItem
                value={value}
                isDisabled={false}
                onClick={() => () => { }}
            />
        ),
        Mandatory: ({ value }) => (
            <TableChip
                value={value}
                onLabel={yesLabel}
                offLabel={noLabel}
                justify="flex-start"
            />
        ),
    };

    const capitalizeFirstLetter = (text: string): string => {
        if (!text) return "";
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };


    const handleOrderAndFilter = (dataSourceOrder: Array<TPTreeViewDataModel>) => {

        let data = dataSourceOrder.sort((a, b) =>
            orderActive ? a.label.localeCompare(b.label) : b.label.localeCompare(a.label)
        );

        if (filter != "") {
            data = [...data].filter(s => s.label.toLowerCase().includes(filter.toLowerCase()));
        }

        return data;
    }

    const handleGetJsonBranchesMassiveModel = (json: string) => {
        let list = Array<JsonBranchesMassiveModel>();

        if (json) {
            if (json.includes("\n")) {
                list = json.split("\n").map((e: string) => {
                    return {
                        branchName: e
                    } as JsonBranchesMassiveModel;
                });
            } else {
                list = [{ branchName: json }];
            }
        }
        return TPGlobal.stringToUTF8(JSON.stringify(list)).toString();
    }


    const saveMultiBranch = async () => {
        //valueMultiBranch        
        setIsLoadingScreen(true);
        let serviceClient = new TreeService();
        let expectedCodes: Array<number> = [200, 404];
        let insert = {
            parentId: insertUpdateState.idBranch,
            treeId: treeId,
            typistId: TPGlobal.currentUserGuid,
            jsonBranchesMassive: handleGetJsonBranchesMassiveModel(valueMultiBranch)
        } as TreeMultipleBranchInputDTO;


        let inputDTOValidator = new TreeMultipleBranchInputDTOValidator();
        let resultValidator = inputDTOValidator.validate(insert);


        let responseRequest = await serviceClient.insertTreeMultipleBranch(
            insert,
            true,
            true,
            expectedCodes,
        );

        if (responseRequest.responseResult) {
            changeParentLoadBranchesForFirstLevelAndExpandedCurrentNode(
                insertUpdateState.idBranch,
            ).then((l) => {
                setTreeviewData1([...l]);
                setIsLoadingScreen(false);
            });
        }

    }

    //#endregion Tree view 


    //Only once to set resources and load function in update mode
    useEffect(() => {
        loadResourcesAndLoadTreeInfo();
    }, []);

    useEffect(() => {
        if (referenceId) {
            let newInsertUpdateState: InsertUpdateStateType = {
                ...insertUpdateState,
                idBranch: referenceId,
                parentName:
                    insertUpdateState.currentOperatorMode === OperationModeEnum.add_child
                        ? currentSelectedLabel1
                        : "",
            };
            setInsertUpdateState(newInsertUpdateState);
        }
    }, [referenceId]);

    //Run when currentSelectedNode1 or readyToLoadDataWhenNodeChange change
    useEffect(() => {
        if (readyToLoadDataWhenNodeChange && currentSelectedNode1 !== "") {
            getBranchById(currentSelectedNode1, OperationModeEnum.normal);
        }
    }, [currentSelectedNode1, readyToLoadDataWhenNodeChange]);

  useEffect(() => {
    const isAnyModalShown = 
      modalQuestionState.isShown || 
      modalAddBranchQuestionState.isShown || 
      changeParentModalTreeViewState.isShown || 
      changeCloneBranch.isShown ||
      isShownModalAdditionalData ||
      isOpenModalHelpLanguageList ||
      isOpenModalLanguageList;
  
    setIsOpenModal(isAnyModalShown);
  }, [
    modalQuestionState.isShown,
    modalAddBranchQuestionState.isShown,
    changeParentModalTreeViewState.isShown,
    changeCloneBranch.isShown,
    isOpenModalHelpLanguageList,
    isOpenModalLanguageList,
    isShownModalAdditionalData,
  ]);

    return (
        <ContentVerticalTabInsertUpdateStyled>

            <TPModal
                modalState={{
                    acceptLabel: "",
                    callBackAnswer: () => { },
                    isShown: activeModalMultiBranche,
                    titleModal: "",
                    hideFooterButtons: true,
                    hideXButton: true,
                    hiddenHeader: true,
                    modalWidth: "464px",
                }}
            >
                <TPColumn minHeight={"300px"}
                    padding={"10px"}
                >
                    <TPLabel labelText={addMultipleBranchesLabel} style={{ fontSize: "20px", fontWeight: "700" }} />
                    <TPLabel labelText={branchSelectedLabel + " : " + (currentSelectedLabel1 != "" ? currentSelectedLabel1 : "Root")} style={{ fontSize: "14px", fontWeight: "500" }} />


                    {activeAlertTreeBranch &&
                        <MsgAlertTreeStyle
                            style={{ backgroundColor: "rgba(211, 225, 252, 1)", boxShadow: "0px 6px 12px -2px   rgba(149, 157, 165, 0.35)", borderRadius: "5px", padding: "8px", color: "rgba(0, 44, 129, 1)" }}
                        >
                            <MsgAlertTreeTitleStyle
                                style={{ height: "30px" }}
                            >
                                <MsgAlertTreeTitleStyle>
                                    <TPIcon iconType={TPIconTypes.circleInfo}
                                        style={{
                                            color: "rgb(0, 44, 129)",
                                            display: "flex",
                                            fontSize: "20px",
                                            transform: "rotate(182deg)",
                                        }} />
                                    <TPLabel labelText={enterAListOfBranchesDescriptionLabel} style={{}} />
                                </MsgAlertTreeTitleStyle>
                                <XTreeTitleStyle onClick={() => setActiveAlertTreeBranch(false)} >X</XTreeTitleStyle>
                            </MsgAlertTreeTitleStyle>
                            <TPLabel labelText={"Branch 1"} style={{ marginLeft: "31px" }} />
                            <TPLabel labelText={"Branch 2"} style={{ marginLeft: "31px" }} />
                            <TPLabel labelText={"..."} style={{ marginLeft: "31px" }} />
                        </MsgAlertTreeStyle>
                    }


                    <TPTextArea
                        id="Id-tree-text-tarea"
                        onChange={(e: any) => {
                            setValueMultiBranch(e.target.value);
                        }}
                        value={valueMultiBranch}
                        isMandatory={true}
                        labelText={multipleBranchesLabel}
                        placeholder={insertYourDescriptionHere}
                        errorMessage={insertYourDescriptionHereError}
                        rows={4}
                    />
                </TPColumn>
                <BtnModalStyle>
                    <TPButton
                        onClick={() => {
                            setValueMultiBranch("");
                            setActiveAlertTreeBranch(true);
                            setActiveModalMultiBranche(false);
                        }}
                        isDesignSystem
                        style={{
                            backgroundColor: "white",
                            color: "#780096",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                        }}
                    >
                        {cancelButtonLabel}
                    </TPButton>
                    <TPButton
                        onClick={() => {
                            saveMultiBranch();
                            setActiveModalMultiBranche(false);
                        }}
                        isDesignSystem
                        style={{
                            paddingLeft: "16px",
                            paddingRight: "16px",
                        }}
                        disabled={(valueMultiBranch == "")}
                    >
                        {saveButtonLabel}
                    </TPButton>
                </BtnModalStyle>
            </TPModal>

            {/*Delete*/}
            <TPConfirmationModal
                message={deleteQuestion.replace(
                    "{recordId}",
                    modalQuestionState.callBackData.recordId,
                )}
                onAccept={() => handleCallBackModal(true, modalQuestionState.callBackData)}
                onCancel={() => handleCallBackModal(false, modalQuestionState.callBackData)}

                title={deleteTitle}
                visible={modalQuestionState.isShown}
            />


            <TPModalQuestion
                id="IdModalQuestion"
                title={deleteTitle}
                yesLabel={deleteOkLabel}
                noLabel={deleteCancelLabel}
                question={deleteQuestionAddBranch.replace(
                    "{recordId}",
                    modalAddBranchQuestionState.callBackData.idAdditionalDataBranch,
                )}
                callBackData={modalAddBranchQuestionState.callBackData}
                isShown={modalAddBranchQuestionState.isShown}
                callBackAnswer={handleCallBackModalAddData}
            ></TPModalQuestion>

            <TPModalTreeView
                title={changeParentModalTreeViewModalTitle}
                title2={branchSelectedLabel + " : " + (currentSelectedLabel1 != "" ? currentSelectedLabel1 : "Root")}
                acceptLabel={changeParentAcceptButtonLabel}
                cancelLabel={changeParentCancelButtonLabel}
                isShown={changeParentModalTreeViewState.isShown}
                callBackAnswer={handleCallBackChangeParentModalTreeView}
                showSelectRootNodeButton={showSelectRootNodeButton}
                selectRootLabel={selectRootLabel}
                selectedNodeIdPlusDescription={selectedNodeIdPlusDescription}
                treeProps={getTreePropsToChangeParent()}
            ></TPModalTreeView>

            <TPModalCloneBranch
                acceptLabel={changeParentAcceptButtonLabel}
                cancelLabel={changeParentCancelButtonLabel}
                treeSubTitle={subTitleLabel}
                isShown={changeCloneBranch.isShown}
                branchId={cloneBranchNode}
                branchName={cloneBranchLabel}
                treeId={treeId}
                treeData={dataArray1}
                guidTree={guidSession}
                callBackAnswer={handleCloseCloneBranchModal}
            />

            <div className="row">
                <div className="col-12">
                    <TPPageTitle>{titleLabel}</TPPageTitle>
                    {/*<TPPageSubTitle>*/}
                    {/*    <div className="text-break">*/}
                    {/*        {subTitleLabel} {treeId} / {originalRecordDescription}*/}
                    {/*    </div>*/}
                    {/*</TPPageSubTitle>*/}
                </div>
                <TPHorizontalTabs
                    labels={asideMenuState.map((e: MenuItemsModel) => {
                        return e.itemLabel
                    })}
                    activeTab={1}
                    onChange={handleTabChange}
                    style={{ maxWidth: "97%" }}
                />
            </div>

            {(dataArray1 && dataArray1.length <= 0 && !operationModeRootActive) &&
                <TreeNoDataImg>
                    <ViewImage
                        testPreview={true}
                        heightImage={"182px"}
                        widthImage={"184px"}
                        image={ImageActive.noFilteredDataImg}
                    />

                    <TPLabel
                        className="form-check-label"
                        labelText={youDonNotHaceBranchesLabel}
                        style={{ fontSize: "16px" }}
                    />

                    <div
                        style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "2px" }}
                    >

                        <TPButton
                            id="add-branches"
                            type={TPButtonTypes.icon}
                            icon={TPIconTypes.doubleArrowTop}
                            orientationIcon="left"
                            text={addBranchLabel}
                            tooltip={""}
                            style={{ color: "rgba(120, 0, 150, 1)" }}
                            onClick={() => { handleNewRootClick() }}
                        />

                        <div>
                            {orLabel.toLowerCase()}
                        </div>
                        <TPButton
                            id="add-multiple-branches"
                            type={TPButtonTypes.icon}
                            icon={TPIconTypes.doubleArrowBottom}
                            orientationIcon="left"
                            text={addMultipleBranchesLabel}
                            tooltip={""}
                            style={{ color: "rgba(120, 0, 150, 1)" }}
                            onClick={() => { setValueMultiBranch("");  setActiveModalMultiBranche(true); }}
                        />


                    </div>
                </TreeNoDataImg>
            }

            {(dataArray1 && dataArray1.length > 0 || operationModeRootActive) &&

                <>
                    <TPRow maxWidth={"100%"} gridTemplateColumnsValue={"1fr 2px 2fr"}>

                        {/* left */}

                        <TPColumn>
                            <div >
                                {/*<div className="mb-2">*/}
                                {/*    <TPTextBox*/}
                                {/*        id="current-selected-branch"*/}
                                {/*        onChange={() => TPGlobal.foo()}*/}
                                {/*        value={*/}
                                {/*            currentSelectedLabel1 && currentSelectedLabel1.length >= 1*/}
                                {/*                ? currentSelectedLabel1*/}
                                {/*                : ""*/}
                                {/*        }*/}
                                {/*        disabled={true}*/}
                                {/*        labelText={currentBranchLabel}*/}
                                {/*        isHorizontal={true}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <div
                                    id={"treeadmin" + guidSession}
                                    style={{
                                        height: "500px",
                                        overflow: "auto",
                                        width: "422px"
                                    }}
                                    className={
                                        operationModeState.isTreeEnabled ? "" : "tpdisabled"
                                    }
                                >
                                    <div style={{ height: "30px" }}  ></div>
                                    {!operationModeRootActive &&
                                        <TPFilter
                                        searchPlaceholder={searchPlaceholder}
                                        onChangeFilter={(e: any) => {
                                            if (!e || e == "") {
                                                changeParentLoadBranchesForFirstLevelAndExpandedCurrentNode(
                                                    insertUpdateState.idBranch,
                                                ).then((l) => {
                                                    setTreeviewData1([...l]);
                                                });
                                            }
                                            setFilter(e);
                                        }}
                                        onChangeOrder={() => { setOrderActive(!orderActive) }}
                                        activeOrder={orderActive }
                                        ></TPFilter>
                                    }
                                    {!operationModeRootActive &&
                                        <>
                                            <div style={{ height: "15px" }}  ></div>
                                            <TPTreeView
                                                setIsLoading={setIsLoadingScreen}
                                                treeId={treeId}
                                                dataSource={handleOrderAndFilter(dataArray1)}
                                                setDataSource={setTreeviewData1}
                                                selectedNode={currentSelectedNode1}
                                                setSelectedNode={setCurrentSelectedNode1}
                                                selectedLabel={currentSelectedLabel1}
                                                setSelectedLabel={setCurrentSelectedLabel1}
                                                selectedHierarchyLabel={currentSelectedHierarchyLabel1}
                                                setSelectedHierarchyLabel={
                                                    setCurrentSelectedHierarchyLabel1
                                                }
                                                renderParent=""
                                                handleChangeNodeCallBack={() => { }}
                                                filterIsActive={filterIsActive1}
                                                filterIsVisible={filterIsVisible1}
                                                guidTree={guidSession}
                                                isAdminMode={isAdminMode}
                                                isFirstUL={true}
                                                mustSelectLastLevelBranch={false}
                                                handleActionTreeView={handleActionTreeView}

                                            ></TPTreeView>
                                        </>
                                    }
                                {operationModeRootActive &&
                                    <div
                                        style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height:"100%" }}
                                    >
                                        <ViewImage
                                            testPreview={true}
                                            heightImage={"182px"}
                                            widthImage={"184px"}
                                            image={ImageActive.noFilteredDataImg}
                                        />
                                        </div>
                                    }
                                </div>
                            </div>

                            <div
                                style={{ marginRight: "0", marginLeft: "0" }}
                            >

                                <TPColumn
                                    gap={"10px"}
                                >
                                    <TPRow>
                                        <TPLabel labelText={colorConventionsLabel} style={{
                                            fontSize: "16px",
                                            fontWeight: "700"
                                        }} />
                                    </TPRow>
                                    <TPRow>
                                        <TPLabel labelText={activeVisibleLabel} style={{ fontSize: "14px" }} />
                                        <ColorTreeStyle color={"rgba(237, 255, 231, 1)"} >
                                            <TPLabel labelText={activeInvisibleLabel} style={{ fontSize: "14px" }} />
                                        </ColorTreeStyle>
                                    </TPRow>

                                    <TPRow>
                                        <TPLabel labelText={inactiveVisibleLabel} style={{ fontSize: "14px", color: "rgba(152, 152, 152, 1)" }} />

                                        <ColorTreeStyle color={"rgba(222, 222, 222, 1)"} >
                                            <TPLabel labelText={inactiveInvisibleLabel} style={{ fontSize: "14px" }} />
                                        </ColorTreeStyle>
                                    </TPRow>
                                </TPColumn>


                            </div>
                        </TPColumn>

                        <hr />
                        {/* Rigth */}
                        <TPColumn
                            width={"99%"}
                            minWidth={"0px"}
                        >
                            <div className="row" style={{ height: "55px" }}>
                                <div className="col-12">
                                    {currentSelectedNode1 !== "" &&
                                        insertUpdateState.isSystemRecord ? (
                                        <div className="tpcenter">
                                            <TPLabel
                                                labelText={isSystemRecordLabel}
                                                className={
                                                    "alert alert-danger tpcenter tpw100per tpmargin-bottom15"
                                                }
                                            ></TPLabel>
                                        </div>
                                    ) : (
                                        <TPPageFirstRow
                                            style={{
                                                maxWidth: "98%",
                                                gap: "15px",
                                                justifyContent: "flex-start"
                                            }}
                                        >

                                            {branchButtons.filter(s => s.active).map((button, index) => (
                                                <TPButton
                                                    key={`branch-${index}`}
                                                    id={button.id}
                                                    type={TPButtonTypes.icon}
                                                    onClick={button.clickEvent}
                                                    text={button.text}
                                                    icon={button.icon}
                                                    style={{ color: "rgba(120, 0, 150, 1)", display: "flex", gap: "10px", fontSize: "14px" }}
                                                    className={(!operationModeRootActive ? button.className : "")}
                                                />
                                            ))}

                                            {(operationModeRootActive) &&
                                                <TPLabel labelText={addBranchLabel} style={{ fontWeight: "600", fontSize: "16px" }} />
                                            }
                                        </TPPageFirstRow>
                                    )}
                                </div>
                            </div>


                            <TPRow maxWidth={"90%"}  >
                                <TPTextBox
                                    id="branch-id-input"
                                    isMandatory={true}
                                    onChange={handleBranchIdChange}
                                    value={insertUpdateState.idBranch}
                                    //value={referenceId}
                                    // disabled={operationModeState.isIdBranchEnabled ? false : true}
                                    disabled
                                    labelText={idLabel.toUpperCase()}
                                    errorMessage={insertUpdateState.idErrorMessage}
                                    maxLength={20}
                                />

                                <TPTextBox
                                    id="parent-name-input"
                                    onChange={() => {
                                        TPGlobal.foo();
                                    }}
                                    value={insertUpdateState.parentName}
                                    disabled={true}
                                    labelText={parentNameLabel}
                                />
                            </TPRow>



                            <TPRow maxWidth={"90%"} gridTemplateColumnsValue={"48% 53%"}  >
                                <TPTextBox
                                    id="parent-id-input"
                                    onChange={() => {
                                        TPGlobal.foo();
                                    }}
                                    value={insertUpdateState.parentId}
                                    disabled={true}
                                    labelText={parentIdLabel}
                                />

                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                    {insertUpdateState.recordLanguageList.length > 0 &&
                                        TPGlobal.TPClientAvailableLanguages.map(
                                            (item, index) =>
                                                index === 0 && (

                                                    <div className="col-11" key={`languageItem-${item.id}`}>

                                                        <TPTextBox
                                                            id={`root-name-${item.id}-input`}
                                                            isMandatory={index === 0}
                                                            labelText={`${nameLabel} (${item.name})`}
                                                            value={
                                                                insertUpdateState.recordLanguageList[index].value
                                                            }
                                                            onChange={(e: any) =>
                                                                handleLanguageChange(index, e.target.value)
                                                            }
                                                            maxLength={500}
                                                            errorMessage={
                                                                insertUpdateState.nameErrorMessages[index]
                                                            }
                                                            disabled={
                                                                !operationModeState.isNameArrayEnabled[index]
                                                            }
                                                        />

                                                    </div>


                                                ),
                                        )}
                                    {insertUpdateState.recordLanguageList.length > 1 && (
                                        <>

                                            <TPModalLanguageList
                                                isOpen={isOpenModalLanguageList}
                                                title={languageListLabel}
                                                acceptLabel={saveButtonLabel}
                                                cancelLabel={cancelButtonLabel}
                                                saveChanges={handleSaveChangesModalLanguageList}
                                                closeModal={handleCloseModalLanguageList}
                                            >
                                                <div
                                                    className="row overflow-auto"
                                                    style={{ height: "200px" }}
                                                >
                                                    {TPGlobal.TPClientAvailableLanguages.map(
                                                        (item, index) =>
                                                            index > 0 && (
                                                                <div
                                                                    className="col-12"
                                                                    key={`languageItem-${item.id}`}
                                                                >
                                                                    <div className="form-group">
                                                                        <TPTextBox
                                                                            id={`root-name-${item.id}-md-input`}
                                                                            isMandatory={false}
                                                                            labelText={`${nameLabel} (${item.name})`}
                                                                            value={
                                                                                insertUpdateState.recordLanguageList[index]
                                                                                    .value
                                                                            }
                                                                            onChange={(e: any) =>
                                                                                handleLanguageChange(index, e.target.value)
                                                                            }
                                                                            maxLength={500}
                                                                            errorMessage={
                                                                                insertUpdateState.nameErrorMessages[index]
                                                                            }
                                                                            disabled={
                                                                                !operationModeState.isNameArrayEnabled[
                                                                                index
                                                                                ]
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ),
                                                    )}
                                                </div>
                                            </TPModalLanguageList>
                                        </>
                                    )}

                                    <TPButton
                                        id="root-name-lang"
                                        type={TPButtonTypes.icon}
                                        icon={TPIconTypes.language}
                                        text={`+${insertUpdateState.recordLanguageList.length - 1}`}
                                        tooltip={languageListLabel}
                                        className={"pt-3"}
                                        style={{ marginTop: "9px" }}
                                        onClick={handleOpenModalLanguageList}
                                    />
                                </div>

                            </TPRow>

                            <TPRow maxWidth={"90%"} >
                                <TPTextBox
                                    id="tree-name-input"
                                    onChange={() => {
                                        TPGlobal.foo();
                                    }}
                                    value={insertUpdateState.treeName}
                                    disabled={true}
                                    labelText={treeNameLabel}
                                />
                                <TPTextBox
                                    id="branch-value-input"
                                    onChange={handleBranchValueChange}
                                    value={insertUpdateState.value}
                                    disabled={
                                        operationModeState.isBranchValueEnabled ? false : true
                                    }
                                    labelText={valueLabel}
                                    errorMessage={insertUpdateState.valueErrorMessage}
                                />
                            </TPRow>

                            <TPRow maxWidth={"90%"} gridTemplateColumnsValue={!isTreeAlphabeticOrder ? "1fr 1fr" : "1fr"} >
                                {!isTreeAlphabeticOrder && (
                                    <TPNumeric
                                        id="root-order-num"
                                        onChange={handleOrderChange}
                                        value={insertUpdateState.order}
                                        labelText={orderLabel}
                                        disabled={operationModeState.isOrderEnabled ? false : true}
                                        fixedDecimal={1}
                                    ></TPNumeric>
                                )}

                                <TPTextBox
                                    id="parameters-input"
                                    onChange={handleParametersChange}
                                    value={insertUpdateState.parameters}
                                    disabled={
                                        operationModeState.isParametersEnabled ? false : true
                                    }
                                    labelText={parametersLabel}
                                    errorMessage={insertUpdateState.parametersErrorMessage}
                                />
                            </TPRow>

                            <TPRow maxWidth={"90%"} gridTemplateColumnsValue={"100%"} >
                                <div className="row">
                                    {insertUpdateState.helpLanguageList.length > 0 &&
                                        TPGlobal.TPClientAvailableLanguages.map(
                                            (item, index) =>
                                                item.id === TPGlobal.TPClientDefaultLanguage && (
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                                        <div
                                                            className="col-12"
                                                            key={`helpLanguageItem-${item.id}`}
                                                        >
                                                            <div className="form-group">
                                                                <TPTextBox
                                                                    id={`help-description-${item.id}-input`}
                                                                    isMandatory={false}
                                                                    labelText={`${helpLabel} (${item.name})`}
                                                                    value={
                                                                        insertUpdateState.helpLanguageList[index].value
                                                                    }
                                                                    onChange={(e: any) =>
                                                                        handleHelpChange(index, e.target.value)
                                                                    }
                                                                    maxLength={500}
                                                                    errorMessage={
                                                                        insertUpdateState.helpErrorMessages[index]
                                                                    }
                                                                    disabled={
                                                                        !operationModeState.isHelpArrayEnabled[index]
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <TPButton
                                                            id={`help-description-lang`}
                                                            type={TPButtonTypes.icon}
                                                            icon={TPIconTypes.language}
                                                            text={`+${insertUpdateState.recordLanguageList.length - 1}`}
                                                            tooltip={languageListLabel}
                                                            className={"pt-3"}
                                                            style={{ marginTop: "-15px" }}
                                                            onClick={handleOpenModalHelpLanguageList}
                                                        />
                                                    </div>
                                                ),
                                        )}
                                    {insertUpdateState.helpLanguageList.length > 0 && (
                                        <>

                                            <TPModalLanguageList
                                                isOpen={isOpenModalHelpLanguageList}
                                                title={languageListLabel}
                                                acceptLabel={saveButtonLabel}
                                                cancelLabel={cancelButtonLabel}
                                                saveChanges={handleSaveChangesModalHelpLanguageList}
                                                closeModal={handleCloseModalHelpLanguageList}
                                            >
                                                <div
                                                    className="row overflow-auto"
                                                    style={{ height: "200px" }}
                                                >
                                                    {TPGlobal.TPClientAvailableLanguages.map(
                                                        (item, index) =>
                                                            item.id != TPGlobal.TPClientDefaultLanguage && (
                                                                <div
                                                                    className="col-12"
                                                                    key={`helpLanguageItem-${item.id}`}
                                                                >
                                                                    <div className="form-group">
                                                                        <TPTextBox
                                                                            id={`help-description-${item.id}-md-input`}
                                                                            isMandatory={false}
                                                                            labelText={`${helpLabel} (${item.name})`}
                                                                            value={
                                                                                insertUpdateState.helpLanguageList[index]
                                                                                    .value
                                                                            }
                                                                            onChange={(e: any) =>
                                                                                handleHelpChange(index, e.target.value)
                                                                            }
                                                                            maxLength={500}
                                                                            errorMessage={
                                                                                insertUpdateState.helpErrorMessages[index]
                                                                            }
                                                                            disabled={
                                                                                !operationModeState.isHelpArrayEnabled[
                                                                                index
                                                                                ]
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ),
                                                    )}
                                                </div>
                                            </TPModalLanguageList>
                                        </>
                                    )}
                                </div>
                            </TPRow>

                            <TPRow maxWidth={"90%"} >
                                <TPCheckBox
                                    id="is-active-check"
                                    labelText={isActiveLabel}
                                    checked={insertUpdateState.isActive}
                                    onChange={(e: any) => handleActiveChange()}
                                    disabled={operationModeState.isActiveEnabled ? false : true}
                                />
                                <TPCheckBox
                                    id="is-visible-check"
                                    labelText={isVisibleForClassificationLabel}
                                    checked={insertUpdateState.isVisible}
                                    onChange={(e: any) => handleIsVisible()}
                                    disabled={operationModeState.isVisibleEnabled ? false : true}
                                />
                            </TPRow>

                            <div
                                className={
                                    operationModeState.isSaveCancelButtonEnabled
                                        ? "row"
                                        : "row tpnone"
                                }
                            >
                                <div className="col-12 mt-3">
                                    <TPPageAcceptCancelButtonsContainer
                                        style={{
                                            paddingRight: "50px",
                                            gap: "10px"
                                        }}
                                    >
                                        <TPButton
                                            id="cancel-root"
                                            type={TPButtonTypes.empty}
                                            onClick={handleCancelButtonClick}
                                            isDesignSystem
                                            style={{
                                                backgroundColor: "white",
                                                color: "#780096",
                                                paddingLeft: "16px",
                                                paddingRight: "16px",
                                                width: "70px"
                                            }}
                                        >
                                            {cancelButtonLabel}
                                        </TPButton>
                                        <TPButton
                                            id="save-root"
                                            type={TPButtonTypes.primary}
                                            onClick={handleOkButtonClick}
                                            isDesignSystem
                                            style={{
                                                paddingLeft: "16px",
                                                paddingRight: "16px",
                                                width: "70px"
                                            }}
                                        >
                                            {saveButtonLabel}
                                        </TPButton>

                                    </TPPageAcceptCancelButtonsContainer>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <TPModalAdditionalData
                                        title={`${additionalDataModalTitle} - Branch: ${insertUpdateState.idBranch}`}
                                        acceptLabel={saveButtonLabel}
                                        cancelLabel={cancelButtonLabel}
                                        isShown={isShownModalAdditionalData}
                                        callBackAnswer={callbackAdditionalDataModalAnswer}
                                    ></TPModalAdditionalData>
                                </div>
                            </div>
                            <HeaderGridStyle >
                                <div >
                                    <TPLabel labelText={capitalizeFirstLetter(additionalDataLabel)} style={{ fontSize: "16px", fontWeight: "600" }} />
                                </div>
                                {operationModeState.isToolbarButtonsVisible ? (
                                    <>
                                        {visible && (
                                            <TPButton
                                                id="new-tree"
                                                isDesignSystem={true}
                                                type={TPButtonTypes.secondary}
                                                withIcon={TPIconTypes.add}
                                                orientationIcon="left"
                                                style={{
                                                    padding: "1px 18px",
                                                    background: "none",
                                                    color: "rgba(120, 0, 150, 1)"
                                                }}
                                                onClick={() => {
                                                    setIsShownModalAdditionalData(true);
                                                }}
                                            >
                                                {addAdditionalDataLabel}
                                            </TPButton>
                                        )}
                                    </>
                                ) :
                                    <div>
                                    </div>
                                }

                            </HeaderGridStyle>

                            {(dataArray1 && dataArray1.length > 0) &&
                                <DynamicTable
                                    data={additionalDataBranchKeyValue.map((e: AdditionalDataBranchViewModel) => {
                                        return {
                                            Delete: e.branchId,
                                            ID: e.branchId,
                                            Name: e.localizedAdditionalDataDescription,
                                            Mandatory: e.isMandatory,
                                            Order: e.order,
                                            Type: e.localizedAdditionalDataTypeDescription,
                                            AdditionalDataId: e.additionalDataId
                                        } as AdditionalDataBranchViewGridModel
                                    })}
                                    hiddenSearch={true}
                                    hideExport={true}
                                    hideRefresh={true}
                                    columnStyles={customColumns}
                                    hiddenColumns={["AdditionalDataId"]}
                                >

                                </DynamicTable>
                            }

                        </TPColumn>
                    </TPRow>

                    <TPLoadingOverlay active={isLoadingScreen} top={"200px"}>
                    </TPLoadingOverlay>
                </>
            }
        </ContentVerticalTabInsertUpdateStyled>
    );
};
export default BranchAdmin;


