import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { QueueTabs } from "@/models/Groups/GroupsModels";
import { FC, useState } from "react";
import useQueueAdminLabels from "../assets/labels";
import QueueAdministrationTab from "./tabs/QueueAdministrationTab";
import QueueAssignUsersTab from "./tabs/QueueAssignUsersTab";
import QueueGeneralInfo from "./tabs/QueueGeneralInfoTab";

interface QueueAdminPanelProperties {
  tabCallback: Function;
  groupId: string;
  updateCallback: Function;
}

const QueueAdminUpdatePanel: FC<QueueAdminPanelProperties> = function ({
  tabCallback,
  updateCallback,
  groupId,
}) {
  const { labels } = useQueueAdminLabels();
  const [currentTab, setCurrentTab] = useState<QueueTabs>(
    QueueTabs.GeneralInfo,
  );

  return (
    <div className="queues-main-container">
      <TPPageTitle>{labels?.UpdateQueue}</TPPageTitle>
      <div
        id="queue-admin-nav-tab"
        className="secondary-style-tp-tabs nav nav-tabs"
        role="tablist"
      >
        <button
          id="queue-admin-general-info-nav-tab"
          className={`nav-link ${currentTab == QueueTabs.GeneralInfo && "active"}`}
          type="button"
          role="tab"
          onClick={() => setCurrentTab(QueueTabs.GeneralInfo)}
        >
          {labels?.GeneralInfo}
        </button>

        <button
          id="queue-admin-assign-users-nav-tab"
          className={`nav-link ${currentTab == QueueTabs.AssignUsers && "active"}`}
          type="button"
          role="tab"
          onClick={() => setCurrentTab(QueueTabs.AssignUsers)}
        >
          {labels?.AssignUsers}
        </button>
      </div>
      <div className="queue-admin-tab" style={{ width: "100%" }}>
        <QueueGeneralInfo
          panelMode={"update"}
          tabCallback={tabCallback}
          groupId={Number(groupId)}
          active={currentTab == QueueTabs.GeneralInfo}
          updateCallback={updateCallback}
          handleChangeActivePreview={() => {}}
        />
        <QueueAssignUsersTab
          groupId={Number(groupId)}
          tabCallback={tabCallback}
          active={currentTab == QueueTabs.AssignUsers}
          updateCallback={updateCallback}
        />
      </div>
    </div>
  );
};

export default QueueAdminUpdatePanel;
