import styled from 'styled-components';

export const WizardContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
`;

export const WizardSteps = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 80%;
`;

export const WizardStep = styled.div<{ isActive?: boolean; isCompleted?: boolean }>`
  text-align: left;
  position: relative;
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 30px;
  justify-content: flex-start;

  ${({ isActive }) => isActive && `
    .step-label {
      color: #a00095;
    }
  `}

  ${({ isCompleted }) => isCompleted && `
    .step-label {
      color: black;
    }
  `}
`;

export const StepNumberName = styled.div`
  display: flex;
  align-items: center;
  font-size: 25px;
  color: #333;
`;

export const StepCircle = styled.div<{ isActive?: boolean; isCompleted?: boolean }>`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #666;
  font-weight: bold;
  margin-right: 10px;
  transition: all 0.3s ease;

  ${({ isCompleted }) => isCompleted && `
    background-color: #009A58;
    color: white;
    border: 6px solid rgba(128, 205, 172);
  `}

  ${({ isActive }) => isActive && `
    background-color: #a00095;
    color: #ffffff;
    border: 6px solid rgb(188, 128, 203);
  `}
`;

export const StepLabel = styled.span`
  font-size: 15px;
  color: rgba(152, 152, 152, 1);
`;

export const ProgressBarContainer = styled.div`
  position: relative;
  height: 8px;
  width: 80%;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-top: 10px;
`;

export const ProgressBarFill = styled.div<{ progress: number }>`
  position: absolute;
  height: 100%;
  background-color: #a00095;
  width: ${({ progress }) => progress}%;
  border-radius: 5px;
  transition: width 0.3s ease;
`;

export const WizardBody = styled.div`
  margin-top: 20px;
  width: 100%;
  min-height: 500px;
  height: 100%;
  display: block;
`;


