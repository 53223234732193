import { TPKeyValue } from "@/helpers/TPKeyValue";
import TPModal from "@/layouts/TPModal/TPModal";
import { EmailTemplateService } from "@/services/EmailTemplateService";
import { FC, useEffect, useState } from "react";
import TPLoadingOverlay from "../bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import {
  TagSelectorContainerStyled,
  TagSelectorFooterStyled,
  TagSelectorTitleStyled
} from "./TPTagSelectorStyles";
import TPSelect from "../bootstrap/forms/select/TPSelect";
import { useTPTagSelectorLabels } from "./TPTagSelectorLabels";
import TPButton from "../bootstrap/components/buttons/TPButton";
import allThemes from "@/assets/styles/theme";

interface TPTagSelectorProps {
  tagGroup?: string;
  tag?: string;
  onClose: (tag?: string) => void;
  visible: boolean;
}

export const TPTagSelector: FC<TPTagSelectorProps> = function({
  tagGroup, tag, onClose, visible
}) {

  const { labels } = useTPTagSelectorLabels();
  const [tagGroups, setTagGroups] = useState<TPKeyValue[]>([]);
  const [tags, setTags] = useState<TPKeyValue[]>([]);
  const [loading, setLoading] = useState(false);

  const [chosenGroup, setChosenGroup] = useState(tagGroup ?? "");
  const [chosenTag, setChosenTag] = useState(tag ?? "");

  const getTagGroups = async function() {
    setLoading(true);
    const serviceInstance = new EmailTemplateService();
    serviceInstance.getTagGroups(false, true, [200, 404])
      .then(tags => setTagGroups(
        tags.map((t: any) => ({ key: t.id, value: t.localizedDescription }))))
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  const getTagsByGroup = async function(groupId: string) {
    if (!groupId) return;
    setLoading(true);
    setChosenTag("");
    const serviceInstance = new EmailTemplateService();
    serviceInstance.getTagsByGroup(groupId, false, true, [200, 404])
      .then(tags => setTags(
        tags.map((t: any) => ({ key: t.value, value: t.localizedDescription })))
      )
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getTagsByGroup(chosenGroup);
  }, [chosenGroup])

  useEffect(() => {
    getTagGroups();
  }, [])

  return (
    <TPModal
      modalState={{
        callBackAnswer: () => {},
        isShown: visible,
        titleModal: "",
        hiddenHeader: true,
        hideFooterButtons: true,
        hideXButton: true,
        zIndex: 2000,
        modalWidth: "400px",
      }}
    >
      <TPLoadingOverlay wrapperStyle={{ overflow: "visible" }} active={loading}>
        <TagSelectorContainerStyled>
          <TagSelectorTitleStyled>{labels.Title}</TagSelectorTitleStyled>
          <div>
            <TPSelect
              dataSource={tagGroups}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setChosenGroup(e.target.value)}
              value={chosenGroup}
              labelText={labels.TagGroup}
              isMandatory
            />
          </div>
          <div>
            <TPSelect
              dataSource={tags}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setChosenTag(e.target.value)}
              value={chosenTag}
              labelText={labels.Tag}
              isMandatory
            />
          </div>
          <TagSelectorFooterStyled>
            <TPButton
              id="freq-ans-general-info-cancel-button"
              onClick={() => onClose()}
              isDesignSystem
              style={{
                background: "white",
                color: allThemes.base.purplePrimary,
                padding: "11px 16px"
              }}
            >
              {labels.Cancel}
            </TPButton>
            <TPButton
              id="freq-ans-general-info-save-button"
              onClick={() => {
                onClose(chosenTag);
              }}
              isDesignSystem
              style={{ padding: "11px 16px" }}
              disabled={!chosenTag || !chosenGroup}
            >
              {labels.Save}
            </TPButton>
          </TagSelectorFooterStyled>
        </TagSelectorContainerStyled>
      </TPLoadingOverlay>
    </TPModal>
  )
}