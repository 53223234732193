export const isStringNull = function(str: string | null | undefined) {
  return str === null;
}

export const isStringUndefined = function(str: string | null | undefined) {
  return str === undefined;
}

export const isStringEmpty = function(str: string | null | undefined) {
  return isStringNull(str) || isStringUndefined(str) || str?.trim().length === 0;
}

export const areStringsValid = function(...list: string[]) {
  let valid = true;

  list.forEach(s => {
    if (!isStringEmpty(s)) valid = false;
  })

  return valid;
}