import styled, { keyframes } from "styled-components";

interface DragProps {
  dragging: boolean;
  fileExist: boolean;
}

interface FileProps {
  isErrorFile: boolean;
}

interface UserProfileProps {
  color: string;
  variant: string;
  size: UserProfileSize;
  customSize?: SizeItem;
}

export type UserProfileSize = "sm" | "md" | "lg";

export type ProfileSize = {
  [key in UserProfileSize]: SizeItem;
};

export type SizeItem = {
  font: string;
  size: string;
};

export type SearchSelectProps = {
  orientation: "horizontal" | "vertical";
};

const profileSizes: ProfileSize = {
  sm: {
    font: "16px",
    size: "40px",
  },
  md: {
    font: "18px",
    size: "60px",
  },
  lg: {
    font: "20px",
    size: "80px",
  },
};

const primaryColor = "#780096";

const uiIcon = (size: string = "24px") => `
	font-size: ${size};
	width: ${size};
	height: ${size};
`;

export const StyledSearchInput = styled.div<{ width: string; focus: boolean, height?:string }>`
  width: ${(p) => p.width};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border: 1px solid ${(p) => (!p.focus ? "#bfbfbf" : primaryColor)};
  padding: 0 16px;
  border-radius: 4px;
  height: ${(p) => (!p.height ? "35px" : p.height)};

  input {
    border: none;
    outline: none;
    font-size: 14px;
    font-family: "Noto Sans", sans-serif;
  }

  .ui-icon {
    color: #656565;
    align-items: center;
    ${uiIcon("22px")}
  }

  .clean-input {
    cursor: pointer;
    border-radius: 3px;
    color: #656565;
    ${uiIcon("20px")}

    &:hover {
      background-color: #dcdfe4;
    }
  }
`;

const StyledPagerBase = `
	display: flex;
	align-items: center;
	gap: 0.5em;
	flex-wrap: wrap;

	button {
		margin: 0;
		font-size: 13px;
		font-weight: 500;
		border: 1px solid #dddddd;
		background-color: white;
		border-radius: 4px;
		padding: 4px 9px;

		&:hover {
			background-color: #f3f3f3;
		}

		&:disabled {
			background-color: inherit;
		}
	}
`;

export const StyledClassicPager = styled.div`
  ${StyledPagerBase}

  .ui-icon {
    font-size: 24px;
    width: 24px;
    height: 29px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    align-items: center;

    &:hover {
      background-color: #f3f3f3;
    }
  }
`;

export const StyledDefaultPager = styled.div(
  ({ theme }) => `
	${StyledPagerBase}

	button.contained {
		background-color: ${theme.colors.cim.button.primary.background};
		color: ${theme.colors.cim.button.primary.color};
		border-color: ${theme.colors.cim.button.primary.border};

		&:hover {
			background-color: ${theme.colors.cim.button.primary.hover.background};
			color: ${theme.colors.cim.button.primary.hover.color};
			border-color: ${theme.colors.cim.button.primary.hover.border};
		}
	}
`
);

export const StyledDialogContent = styled.div`
  padding: 2em;

  .title {
    font-size: 25px;
    font-weight: 700;
    color: #a00095;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding-left: 1em;

    .content-item {
      display: flex;
      align-items: center;
      gap: 0.5em;

      .content-item-title {
        font-weight: 500;
        color: #2e2e2e;
      }
    }
  }

  .email-content {
    display: flex;
    flex-direction: column;
    gap: 1em;

    .title {
      font-size: 22px;
      font-weight: 800;
      color: #2e2e2e;
      margin: 0;
    }

    .email {
      max-height: 60vh;
      overflow: auto;
      scrollbar-width: thin;
      font-size: 14px;

      * {
        font-size: 14px !important;
        font-family: "Noto Sans", sans-serif !important;
      }
      
      p {
        margin: 0;
      }

      div {
        margin-top: 1em;
      }
    }

    .outbound-header {
      display: flex;
      align-items: center;
      gap: 0.5em;

      .ui-icon {
        color: #8051FF;
        ${uiIcon("24px")}
      }

      .title {
        margin: 0;
      }
    }

    .composition-areas {
      display: flex;
      flex-direction: column;
      gap: 1.5em;

      label {
        font-size: 12px;
        font-weight: 500;
        color: #2E2E2E;
      }

      .language-selection {
        display: flex;
        align-items: flex-end;
        gap: 0.8em;
      }

      .area {
        display: flex;
        flex-direction: column;
        gap: 0.3em;
        position: relative;

        .copy-text {
          position: absolute;
          top: 18px;
          right: 10px;
          color: #2E2E2E;
          cursor: pointer;
          user-select: none;
          ${uiIcon("16px")}

          &:hover {
            color: black;
          }
        }

        .text-area-out {
          border: 1px solid #BFBFBF;
          border-radius: 4px;
          padding: 8px;
          padding-right: 50px;
          scrollbar-width: thin;
          width: 700px;
          font-size: 14px;
          color: #2E2E2E;
          outline-color: #780096;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      gap: 1em;
    }
  }
`;

export const StyledLoadFileDialog = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 24px;

  p.dialog-title {
    font-size: 20px;
    font-weight: 700;
    color: #2e2e2e;
  }

  .download-button-ct {
    display: flex;
    justify-content: flex-end;
  }

  .file-list {
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    overflow: auto;
    max-height: 30vh;

    .file-item {
      border-top: 1px solid #bfbfbf;
    }

    .file-item:first-child {
      border-top: none;
      border-radius: 4px 4px 0 0;
    }

    .file-item:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    .file-item:last-child {
      border-radius: 0 0 4px 4px;
    }
  }
`;

export const StyledDragFile = styled.div<DragProps>(
  ({ theme, dragging, fileExist }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px ${fileExist ? "solid" : "dashed"} ${dragging ? "#780096" : "#BFBFBF"};
  cursor: pointer;
  border-radius: 4px;
  min-width: 48em;
  padding: 14px;

  .buttons-container {
    display: flex;
    align-items: center;
    gap: 1em;
    
    .cancel-btn {
      font-size: 24px;
      height: 24px;
      width: 24px;

      &:hover {
        opacity: 0.7;
      }
    }
  }

`
);

export const StyledDragText = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;

  .ui-icon {
    color: #989898;
    font-size: 38px;
    height: 38px;
    width: 38px;
  }

  .text-container {
    display: flex;
    flex-direction: column;

    .drop-label,
    .size-label {
      font-weight: 500;
      font-size: 12px;
      margin: 0;
    }

    .size-label {
      color: #989898;
    }
  }
`;

export const StyledFileStatus = styled.div<FileProps>(
  ({ theme, isErrorFile }) => `
  display: flex;
  align-items: center;
  gap: 1em;

  .ui-icon {
    width: 32px;
    height: 32px;
    font-size: 32px;
    color: white;
    background-color: ${isErrorFile ? "#CC0000" : "#009A58"};
    border-radius: 50%;
  }

  .text-container {
    display: flex;
    flex-direction: column;

    .drop-label,
    .status-label {
      font-weight: 500;
      font-size: 12px;
      margin: 0;
    }

    .status-label {
      color: ${isErrorFile ? "#CC0000" : "#009A58"};
      font-weight: 700;
    }
  }
`
);

export const StyledFileList = styled.div<FileProps>(
  ({ theme, isErrorFile }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  min-width: 48em;
  padding: 14px;

  .file-info {
    display: flex;
    gap: 0.6em;
    
    .status-icons {
      .ui-icon {
        width: 32px;
        height: 32px;
        font-size: 32px;
        color: white;
        background-color: ${isErrorFile ? "#CC0000" : "#009A58"};
        border-radius: 50%;
      }
    }

    .text-container {
      display: flex;
      flex-direction: column;

      .drop-label,
      .status-label {
        font-weight: 500;
        font-size: 12px;
        margin: 0;
      }

      .status-label {
        color: ${isErrorFile ? "#CC0000" : "#009A58"};
        font-weight: 700;
      }
    }
  }

  .cancel-btn {
    font-size: 24px;
    height: 24px;
    width: 24px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`
);

export const StyledActionButtons = styled.div<FileProps>(
  ({ theme, isErrorFile }) => `
  display: flex;
  justify-content: ${isErrorFile ? "space-between" : "flex-end"};
  margin-top: 1.5em;

  .process-buttons {
    display: flex;
    gap: 1em;
  }
`
);

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);z
  }
`;

export const LoaderWrapper = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
`;

export const LoaderRing = styled.div`
  box-sizing: border-box;
  display: block;
  width: 32px;
  height: 32px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #009a58;
  border-radius: 50%;
  animation: ${rotate} 1.2s linear infinite;
`;

export const StyledUserProfile = styled.div<UserProfileProps>(
  ({ theme, color, variant, size, customSize }) => `
  color: ${color};
  background-color: ${variant};
  padding: 10px;
  box-sizing: border-box;
  width: ${customSize ? customSize.size : profileSizes[size].size};
  height: ${customSize ? customSize.size : profileSizes[size].size};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${customSize ? customSize.font : profileSizes[size].font};
  border-radius: 50%;
  font-weight: bold;
  user-select: none;
  position: relative;

  img {
    width: ${customSize ? customSize.size : profileSizes[size].size};
    height: ${customSize ? customSize.size : profileSizes[size].size};
    border-radius: 50%;
  }

  .circle-status {
    height: 12px;
    width: 12px;
    border: 1px solid white;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`
);

export const StyledSearchSelect = styled.div<SearchSelectProps>(
  ({ theme, orientation }) => `
  display: ${orientation === "horizontal" ? "flex" : "block"};
  align-items: ${orientation === "horizontal" ? "center" : "flex-start"};
  flex-direction: ${orientation === "horizontal" ? "row" : "column"};
  gap: ${orientation === "horizontal" ? "0.5em" : "0.2em"};

  label {
    font-size: ${orientation === "horizontal" ? "14px" : "12px"};
    font-weight: 500;
  }
`
);

export const StyledMultiItemsContainer = styled.div(
  ({ theme }) => `
  display: flex;

  p.extra-options {
    margin: 0;
    text-wrap: nowrap;
    font-size: 10px;
    color: gray;
    padding-top: 4px;
  }
`
);

export const StyledMultiItem = styled.p(
  ({ theme }) => `
  margin: 0;
  text-wrap: nowrap;
  font-size: 14px;
  color: #2e2e2e;
  text-overflow: ellipsis;
  max-width: 60px;
  min-width: 60px;
  width: 60px;
  overflow: hidden;
`
);

export const StyledDefaultTableCard = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  padding: 1.5em;
  gap: 1em;

  p {
    margin: 0;
  }

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.4em;

    p.name { 
      font-size: 18px;
      font-weight: 700;
      max-width: 10em;
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap-mode: nowrap;
    }
  }

  p {
    font-size: 14px;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    .customer,
    .ticket {
      display: flex;
      align-items: center;
      padding: 0.2em 0.5em;
      gap: 1.2em;

      .details {
        display: flex;
        justify-content: space-between;
        padding-right: 0.6em;
        width: 100%;
      }

      .ui-icon {
        width: 25px;
        height: 25px;
        font-size: 25px;
      }
    }

    .ticket {
      background-color: #F4F4F4;
    }
  }

  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ready {
      display: flex;
      align-items: center;
      gap: 0.3em;

      p.ce-ready {
        text-wrap-mode: nowrap;
      }
    }
  }
`
);
