import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import {
    WallboardEnum as e,
    Messages,
    TabTypeEnum,
    WallboardEvents,
    wallboardInitialState,
    WallboardModel,
    WallboardProps,
    WallboardState,
    WallboardStateAction,
    WallboardStateActionType,
} from "@/models/Wallboard/WallboardModel";
import { TPI18N } from "@/services/I18nService";
import { Box, Tab, Tabs } from "@mui/material";
import {
    CSSProperties,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useReducer,
    useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { StyledTab, StyledTabs } from "../LoadHistory/load-history-styles";
import WallboardEventView from "./WallboardEventView";
import WallboardGroupView from "./WallboardGroupView";
import WallboardTeamView from "./WallboardTeamView";

/**
 * STYLES
 */
const styles = {
    column: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
    } as CSSProperties,
    row: {
        display: "flex",
        flexDirection: "row",
        boxSizing: "border-box",
    } as CSSProperties,
    container: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        width: "calc(100% - 40px)",
        height: "calc(100% - 25px)",
        position: "absolute",
    } as CSSProperties,
    title: {
        margin: "30px 0",
        color: "#b10091",
        fontWeight: "bold",
    } as CSSProperties,
    tabContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        boxSizing: "border-box",
        gap: "20px",
        borderBottom: "1px solid #bfbfbf",
    } as CSSProperties,
    tab: {
        padding: "10px 20px 20PX",
        cursor: "pointer",
        borderBottom: "3px solid transparent",
    } as CSSProperties,
    tabFocus: {
        borderBottom: "3px solid #c4008c",
        color: "#c4008c",
        fontWeight: "bold",
    } as CSSProperties,
    tabContent: {
        flex: "1 1 0%",
        padding: "15px",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
    } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * component of the wallboard
 */
const Wallboard = forwardRef(
    ({ id, key, style, children }: WallboardProps, ref) => {
        /**
         * ATTRIBUTES
         */
        /**
         * s: state
         * this is the state of the component
         *
         * dispatch: function
         * this function is used to execute the commands
         */
        const [s, dispatch] = useReducer(dispatchAction, wallboardInitialState);
        /**
         * tab value
         */
        const [value, setValue] = useState(0);
        /**
         * ATTRIBUTES END
         */

        /**
         * CALLED FATHER COMPONENT
         */
        useImperativeHandle(
            ref,
            () =>
                ({
                    load() { },
                }) as WallboardEvents
        );
        /**
         * CALLED FATHER COMPONENT END
         */

        /**
         * EVENT LISTENERS
         */
        /**
         * event when component starts
         */
        useEffect(() => {
            dispatch({
                type: WallboardStateActionType.setLoading,
                payload: true,
            });
            loadResources().then(() => {
                dispatch({
                    type: WallboardStateActionType.setLoading,
                    payload: false,
                });
            });
        }, []);
        /**
         * event on component close
         */
        useEffect(
            () => () => {
                dispatch({
                    type: WallboardStateActionType.clean,
                });
            },
            []
        );
        /**
         * EVENT LISTENERS END
         */

        /**
         * FUNCTIONS
         */
        /**
         *
         */
        function dispatchAction(
            state: WallboardState,
            action: WallboardStateAction
        ): WallboardState {
            switch (action.type) {
                case WallboardStateActionType.setLoading:
                    return {
                        ...state,
                        loading: action.payload ?? !state.loading,
                    } as WallboardState;
                case WallboardStateActionType.setMessages:
                    return {
                        ...state,
                        m: action.payload,
                    } as WallboardState;
                case WallboardStateActionType.setTabs:
                    const tabId = uuidv4();

                    return {
                        ...state,
                        focus: tabId,
                        tabs: [
                            {
                                id: tabId,
                                type: TabTypeEnum.TeamView,
                                label: state.m[e.TabTeamViewLabel],
                                active: true
                            },
                            {
                                id: uuidv4(),
                                type: TabTypeEnum.GroupView,
                                label: state.m[e.TabGroupViewLabel],
                                active: true
                            },
                            {
                                id: uuidv4(),
                                type: TabTypeEnum.EventView,
                                label: state.m[e.TabEventViewLabel],
                                active: (TPGlobal.eventsManagerActive == 1)
                            },
                            // {
                            //   id: uuidv4(),
                            //   type: TabTypeEnum.CustomWallboard,
                            //   label: state.m[e.TabCustomWallboard],
                            // },
                        ],
                    };
                case WallboardStateActionType.setTabFocus:
                    return {
                        ...state,
                        focus: action.payload,
                    };
                case WallboardStateActionType.setData:
                    const newState = {
                        ...state,
                        loading: false,
                    };

                    if (action.payload.data) {
                        if (action.payload.type === TabTypeEnum.TeamView) {
                            newState.teamsDataSource = [...action.payload.data];
                        } else if (action.payload.type === TabTypeEnum.GroupView) {
                            newState.groupsDataSource = [...action.payload.data];
                        } else if (action.payload.type === TabTypeEnum.EventView) {
                            newState.eventsDataSource = [...action.payload.data];
                        }
                    }

                    let data =
                        action.payload.data ??
                        (action.payload.type === TabTypeEnum.TeamView
                            ? [...state.teamsDataSource]
                            : action.payload.type === TabTypeEnum.GroupView
                                ? [...state.groupsDataSource]
                                : action.payload.type === TabTypeEnum.EventView
                                    ? [...state.eventsDataSource]
                                    : []);

                    data = action.payload.search
                        ? [...data]?.filter(({ userName }) => {
                            const searchPattern = new RegExp(
                                action.payload.search.toLowerCase()
                            );
                            return searchPattern.test(userName.toLowerCase());
                        })
                        : [...data];

                    if (action.payload.sort) {
                        data.sort((a: WallboardModel, b: WallboardModel) => {
                            switch (action.payload.sort) {
                                case "0":
                                    return a.timeSeconds < b.timeSeconds ? 1 : a.timeSeconds > b.timeSeconds ? -1 : 0;
                                case "1":
                                    return a.timeSeconds > b.timeSeconds ? 1 : a.timeSeconds < b.timeSeconds ? -1 : 0;
                                case "2":
                                    return a.userName.toLowerCase() > b.userName.toLowerCase()
                                        ? 1
                                        : a.userName.toLowerCase() < b.userName.toLowerCase()
                                            ? -1
                                            : 0;
                                case "3":
                                    return a.userName.toLowerCase() < b.userName.toLowerCase()
                                        ? 1
                                        : a.userName.toLowerCase() > b.userName.toLowerCase()
                                            ? -1
                                            : 0;
                                default:
                                    return 0;
                            }
                        });
                    }

                    if (action.payload.type === TabTypeEnum.TeamView) {
                        newState.teams = [...data];
                    } else if (action.payload.type === TabTypeEnum.GroupView) {
                        newState.groups = [...data];
                    } else if (action.payload.type === TabTypeEnum.EventView) {
                        newState.events = [...data];
                    }

                    return newState;
                case WallboardStateActionType.clean:
                    return wallboardInitialState;
                default:
                    return state;
            }
        }
        /**
         * load resources
         */
        async function loadResources(): Promise<void> {
            const messages: Messages = {};

            messages.TitleLabel = await TPI18N.GetText(e.component, e.TitleLabel);
            messages.TabTeamViewLabel = await TPI18N.GetText(
                e.component,
                e.TabTeamViewLabel
            );
            messages.TabGroupViewLabel = await TPI18N.GetText(
                e.component,
                e.TabGroupViewLabel
            );
            messages.TabEventViewLabel = await TPI18N.GetText(
                e.component,
                e.TabEventViewLabel
            );
            messages.TabCustomWallboardLabel = await TPI18N.GetText(
                e.component,
                e.TabCustomWallboardLabel
            );
            messages[e.SectionActionInputSearchLabel] = await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                e.SectionActionInputSearchLabel
            );
            messages[e.SectionActionTooltipRefreshLabel] = await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                e.SectionActionTooltipRefreshLabel
            );
            messages[e.SectionActionSelectSortByLabel] = await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                e.SectionActionSelectSortByLabel
            );
            messages.SectionActionOptionTimeMoreLessLabel = await TPI18N.GetText(
                e.component,
                e.SectionActionOptionTimeMoreLessLabel
            );
            messages.SectionActionOptionTimeLessMoreLabel = await TPI18N.GetText(
                e.component,
                e.SectionActionOptionTimeLessMoreLabel
            );
            messages.SectionActionOptionNameAZLabel = await TPI18N.GetText(
                e.component,
                e.SectionActionOptionNameAZLabel
            );
            messages.SectionActionOptionNameZALabel = await TPI18N.GetText(
                e.component,
                e.SectionActionOptionNameZALabel
            );
            messages.SectionFilterSelectGroupLabel = await TPI18N.GetText(
                e.component,
                e.SectionFilterSelectGroupLabel
            );
            messages.SectionFilterSelectChoosePlaceholderLabel = await TPI18N.GetText(
                e.component,
                e.SectionFilterSelectChoosePlaceholderLabel
            );
            messages.SectionFilterSelectProjectLabel = await TPI18N.GetText(
                e.component,
                e.SectionFilterSelectProjectLabel
            );
            messages.SectionFilterSelectQueueLabel = await TPI18N.GetText(
                e.component,
                e.SectionFilterSelectQueueLabel
            );
            messages.SectionFilterButtonApplyLabel = await TPI18N.GetText(
                e.component,
                e.SectionFilterButtonApplyLabel
            );
            messages.TableColumnTicketTypeCaseLabel = await TPI18N.GetText(
                e.component,
                e.TableColumnTicketTypeCaseLabel
            );
            messages.TableColumnTicketTypeEventLabel = await TPI18N.GetText(
                e.component,
                e.TableColumnTicketTypeEventLabel
            );
            messages.TableColumnTicketTypeMailLabel = await TPI18N.GetText(
                e.component,
                e.TableColumnTicketTypeMailLabel
            );
            messages.TableColumnIdLabel = await TPI18N.GetText(
                e.component,
                e.TableColumnIdLabel
            );
            messages.TableColumnTimeLabel = await TPI18N.GetText(
                e.component,
                e.TableColumnTimeLabel
            );
            messages.TableColumnTimeTooltipStopLabel = await TPI18N.GetText(
                e.component,
                e.TableColumnTimeTooltipStopLabel
            );
            messages.TableTeamNoDataLabel = await TPI18N.GetText(
                e.component,
                e.TableTeamNoDataLabel
            );
            messages.TableGroupNoDataLabel = await TPI18N.GetText(
                e.component,
                e.TableGroupNoDataLabel
            );
            messages.TableEventNoDataLabel = await TPI18N.GetText(
                e.component,
                e.TableEventNoDataLabel
            );
            messages.ModalTitleLabel = await TPI18N.GetText(
                e.component,
                e.ModalTitleLabel
            );
            messages.ModalYesLabel = await TPI18N.GetText(
                e.component,
                e.ModalYesLabel
            );
            messages.ModalNoLabel = await TPI18N.GetText(e.component, e.ModalNoLabel);
            messages.ModalQuestionLabel = await TPI18N.GetText(
                e.component,
                e.ModalQuestionLabel
            );
            messages.AccordionGroupLabel = await TPI18N.GetText(
                e.component,
                e.AccordionGroupLabel
            );
            messages.MessageNothingToSee = await TPI18N.GetText(
                e.component,
                e.MessageNothingToSee
            );
            messages.MessageGroupMustBeSelected = await TPI18N.GetText(
                e.component,
                e.MessageGroupMustBeSelected
            );
            messages.MessageGroupAlreadySelected = await TPI18N.GetText(
                e.component,
                e.MessageGroupAlreadySelected
            );
            messages.MessageProjectMustBeSelected = await TPI18N.GetText(
                e.component,
                e.MessageProjectMustBeSelected
            );

            dispatch({
                type: WallboardStateActionType.setMessages,
                payload: messages,
            });
            dispatch({
                type: WallboardStateActionType.setTabs,
            });
        }
        /**
         * get tab content
         */
        function getTabContent(tab: any): JSX.Element {
            switch (tab.type) {
                case TabTypeEnum.TeamView:
                    return (
                        <WallboardTeamView
                            style={{ display: tab.id === s.focus ? "flex" : "none" }}
                            key={tab.id}
                            itemKey={tab.id}
                            s={s}
                            dispatch={dispatch}
                        />
                    );
                case TabTypeEnum.GroupView:
                    return (
                        <WallboardGroupView
                            style={{ display: tab.id === s.focus ? "flex" : "none" }}
                            key={tab.id}
                            itemKey={tab.id}
                            s={s}
                            dispatch={dispatch}
                        />
                    );
                case TabTypeEnum.EventView:
                    return (
                        <WallboardEventView
                            style={{ display: tab.id === s.focus ? "flex" : "none" }}
                            key={tab.id}
                            itemKey={tab.id}
                            s={s}
                            dispatch={dispatch}
                        />
                    );
                case TabTypeEnum.CustomWallboard:
                    return (
                        <div style={{ display: tab.id === s.focus ? "flex" : "none" }}>
                            Custom Wallboard
                        </div>
                    );
                default:
                    return <div>Tab not found</div>;
            }
        }
        /**
         * FUNCTIONS END
         */

        /**
         * COMPONENT TO RENDER
         */
        return (
            <TPLoadingOverlay active={s.loading}>
                <div
                    id={id ?? e.name}
                    key={key ?? e.name}
                    style={{
                        ...styles.container,
                        ...style,
                    }}
                >
                    <CIMTitleSection isCIM>
                        <TPPageTitle style={{ margin: 0 }}>
                            {s.m?.[e.TitleLabel]}
                        </TPPageTitle>
                    </CIMTitleSection>

                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={value}
                            onChange={(_e, value) => setValue(value)}
                            aria-label="history load tabs"
                            sx={StyledTabs}
                        >
                            {s.tabs.filter(s => s.active).map((tab) => (
                                <Tab
                                    id={`${e.name}Tab${tab.type}${tab.id}`}
                                    key={`${e.name}Tab${tab.type}${tab.id}`}
                                    label={tab.label}
                                    sx={StyledTab}
                                    onClick={() => {
                                        dispatch({
                                            type: WallboardStateActionType.setTabFocus,
                                            payload: tab.id,
                                        });
                                    }}
                                    disableRipple
                                />
                            ))}
                        </Tabs>
                    </Box>

                    <div
                        id={`${e.name}TabContent`}
                        key={`${e.name}TabContent`}
                        style={styles.tabContent}
                    >
                        {s.m.TitleLabel && s.tabs.map((tab) => getTabContent(tab))}
                    </div>
                    {children}
                </div>
            </TPLoadingOverlay>
        );
    }
);

export default Wallboard;
