import ReturnPurpleIcon from "@/assets/images/TPIcons/ReturnPurpleIcon";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ContainerTabStyle, TabActiveStyle } from "@/pages/FormDesigner/StyleFromDesigner";
import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useState } from "react";
import { CockpitView, TeamMemberParsed } from "../supervisor-cockpit.model";
import CockpitSubDate from "../utils/CockpitSubDate";
import CockpitTabs from "../utils/CockpitTabs";
import "./active-team-members-styles.css";
import ActiveTeamMembersPeople from "./ActiveTeamMembersPeople";
import ActiveTeamMembersTickets from "./ActiveTeamMembersTickets";
import ActiveTeamMembersUserView from "./ActiveTeamMembersUserView";
import { ContentVerticalNoTabsStyled, ContentVerticalTabStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { Box, Tab, Tabs } from "@mui/material";
import { StyledTab, StyledTabs } from "@/pages/LoadHistory/load-history-styles";

interface TeamActivityFilters {
    working: boolean;
    ready: boolean;
    online: boolean;
    offline: boolean;
}

enum pageEnum {
    people,
    tickets
}

interface componentProps {
    componentProps?: any;
}

const ActiveTeamMembersAdmin = ({
    componentProps 
}: componentProps) => {

    const componentFileName = "ActiveTeamMembersAdmin.tsx";

        const resourceSet = "ActiveTeamMembersAdmin"

        const [isResourcesLoaded, setIsResourcesLoaded] = useState<boolean>(true);

        const [pageTitleLabel, setPageTitleLabel] = useState<string>("");
        const [peopleLabel, setPeopleLabel] = useState<string>("");
        const [ticketsLabel, setTicketsLabel] = useState<string>("");
        const [myTeamLabel, setMyTeamLabel] = useState<string>("");

        const loadResources = async () => {
            setPeopleLabel(await TPI18N.GetText(resourceSet, "peopleLabel"));
            setTicketsLabel(await TPI18N.GetText(resourceSet, "ticketsLabel"));
            setMyTeamLabel(await TPI18N.GetText("ActiveTeamMembersPeople", "myTeamLabel"))
        }

        const [page, setPage] = useState(pageEnum.people);

        const [isLoading, setIsLoading] = useState(false);

        const [view, setView] = useState<CockpitView>("team");
        const [canReassign, setCanReassign] = useState<boolean>(false);
        const [isUserInfo, setIsUserInfo] = useState<boolean>(false);

        const [employee, setEmployee] = useState<TeamMemberParsed>();
        const [groupId, setGroupId] = useState<number>(0);

        const [lastGroup, setLastGroup] = useState("");

        const [lastProject, setLastProject] = useState("");
        const [lastQueue, setLastQueue] = useState("");

        const setLast = (type: "group" | "project" , id: string, queue?: string) => {
            if (type === "group") {
                setLastGroup(id);
            }

            if (type === "project") {
                setLastProject(id);
                queue && setLastQueue(queue);
            }
        }

        const goToUserPage = (employee: TeamMemberParsed, groupId: number) => {
            setCanReassign(true);
            setIsUserInfo(true);
            setEmployee(employee);
            setGroupId(groupId);
        }

        const changePageTitle = (title: string) => {
            setPageTitleLabel(title);
        }

        const loadingCallback = (status: boolean) => {
            setIsLoading(status);
        }

        useEffect(() => {
            loadResources();
            componentProps && componentProps.view && setView(componentProps.view);
            componentProps && componentProps.groupId && setLastGroup(componentProps.groupId.toString());
            componentProps && componentProps.projectId && setLastProject(componentProps.projectId.key);
            componentProps && componentProps.queueId && setLastQueue(componentProps.queueId.toString());
            componentProps && componentProps.openUserTickets && (goToUserPage(componentProps.employee, componentProps.groupId));
        }, [])

        const resetFromUser = () => {
            setLastGroup("");
            setLastProject("");
            setLastQueue("");
        }

        return (
            <>          
            <ContentVerticalNoTabsStyled>
            <TPLoadingOverlay active={isLoading}>
                    {isResourcesLoaded && (
                        <div className="row">
                            <div className="col" style={{ marginLeft: "10px", marginTop: "10px" }}>
                                {isUserInfo && (
                                    <div className="return-button-container" onClick={() => {
                                        setIsUserInfo(false);
                                        setCanReassign(false);
                                    }}>
                                        <ReturnPurpleIcon />
                                        <u style={{ color: "#780096", marginBottom: "0px" }}>Back</u>
                                    </div>
                                )}
                                <CIMTitleSection isCIM>
                                    <TPPageTitle style={{ margin: 0 }}>
                                        {pageTitleLabel}
                                        <CockpitSubDate />
                                    </TPPageTitle>
                                    {!isUserInfo && <CockpitTabs activeView={view} handleViewChange={setView} />}
                                </CIMTitleSection>
                                {!isUserInfo ? (
                                    <>
                                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                            <Tabs
                                            value={page}
                                            onChange={(_e, value) => {setPage(value)}}
                                            aria-label="structure admin tabs"
                                            sx={StyledTabs}
                                            >
                                            <Tab
                                                label={peopleLabel}
                                                id="general-tab"
                                                sx={StyledTab}
                                                disableRipple
                                            />
                                            <Tab
                                                label={ticketsLabel}
                                                id="details-tab"
                                                sx={StyledTab}
                                                disableRipple
                                            />
                                            </Tabs>
                                        </Box>
                                        {page === pageEnum.people && <ActiveTeamMembersPeople 
                                                                        view={view} 
                                                                        goToUserPage={goToUserPage} 
                                                                        changeTitle={changePageTitle} 
                                                                        loadingCallback={loadingCallback}
                                                                        resetFromUser={resetFromUser} 
                                                                        lastGroup={lastGroup} 
                                                                        lastProject={lastProject} 
                                                                        lastQueue={lastQueue}
                                                                        setLast={setLast}
                                                                        />}
                                        {page === pageEnum.tickets && <ActiveTeamMembersTickets 
                                                                        view={view}
                                                                        
                                                                        changeTitle={changePageTitle} 
                                                                        loadingCallback={loadingCallback}
                                            />}

                                    </>
                                ) : (
                                    <>
                                        {employee && (
                                            <ActiveTeamMembersUserView user={employee} changeTitle={changePageTitle} view={view} loadingCallback={loadingCallback} groupId={groupId}/>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </TPLoadingOverlay>
            </ContentVerticalNoTabsStyled>
                
            </>
        );
}

export default ActiveTeamMembersAdmin;
