import TPGlobal from "@/helpers/TPGlobal";
import {
  RightVerticalMenuContainer,
  RightVerticalMenuLi,
  RightVerticalMenuUl,
  RightVerticalMenuWrapper,
} from "@/layouts/Main/mainMenuStyles";
import { TPI18N } from "@/services/I18nService";
import { FC, useEffect, useReducer, useState } from "react";
import AssignUserToGroup from "./AssignUserToGroup";
import GroupsInsertUpdate from "./GroupsInsertUpdate";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import TPHorizontalTabs from "@/components/TPTabs/TPHorizontalTabs";
import "./GroupStyles.css"
import { TPPageSubTitle, TPPageTitle } from "@/components/TPPage/tpPageStyles";

type GroupsContainerInsertUpdateProps = {
  mode: string;
  recordId: string;
  recordDescription?: string;
  callBackResult: Function;
  tabId: string;
};
enum asideMenuItemTypes {
  "general" = 0,
  "assign_users" = 1,
  "clone" = 2,
}

enum commandsEnum {
  "create_general" = 0,
  "create_assign_users_data_and_change_mode" = 1,
  "set_active_general" = 2,
  "set_active_assign_users" = 3,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

interface asideMenuItemsModel {
  itemLabel: string;
  itemType: asideMenuItemTypes;
  isActive: boolean;
  isEnabled: boolean;
  isVisible: boolean;
  onClickHandler: Function;
}

enum GroupsTabs {
  "GeneralInfo" = 0,
  "AssignUsers" = 1,
}
const GroupsInsertUpdateContainer: FC<GroupsContainerInsertUpdateProps> = ({
  mode,
  recordId,
  recordDescription,
  callBackResult,
  tabId,
}) => {
  //control state
  const resourceSet: string = "GroupsContainerInsertUpdate";
  const [titleGeneralInformation, setTitleGeneralInformation] = useState("");
  const [titleAssignUsers, setTitleAssignUsers] = useState("");
  const [exitLabel, setExitLabel] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [realRecordId, setRealRecordId] = useState(recordId);
  const [realRecordDescription, setRealRecordDescription] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [pageSubtitle, setPageSubtitle] = useState("");

  const [currentTab, setCurrentTab] = useState<GroupsTabs>(GroupsTabs.GeneralInfo);
  //end control state

  const loadResources = async () => {
    //todo recursos del asidemenu

    setPageTitle(
      await TPI18N.GetText("GroupsInsertUpdateComponent", "Title" + mode + "Label")
    );

    setTitleGeneralInformation(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "GeneralInformationTitle",
      ),
    );
    setExitLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ExitLabel"));
    setTitleAssignUsers(await TPI18N.GetText(resourceSet, "AssignUsersTitle"));
    //flag isReady
    setIsReady(true);
  };

  //handler to receive commands from child tabs
  const handleCallBackCommands = (theCommand: any) => {
    if (theCommand.result === "save_general_insert_and_change_mode") {
      //create holidays content
      let command: commandType = {
        type: commandsEnum.create_assign_users_data_and_change_mode,
        payload: {
          recordId: theCommand.recordId,
          recordDescription: theCommand.recordDescription,
        },
      };
      dispatchCommand(command);
      callBackResult({
        result: "ChangeToUpdateMode",
        recordId: theCommand.recordId,
        tabId: tabId,
      });
      return;
    }

    if (theCommand.result === "save_general_insert") {
      callBackResult({ result: "OK", recordId: "" });
      return;
    }
    if (theCommand.result === "save_general_update") {
      return;
    }
    if (theCommand.result === "cancel_general") {
      callBackResult({ result: "cancel_general", recordId: realRecordId });
      return;
    }
  };

  const onGeneralAsideItemClick = () => {
    let currentActive: asideMenuItemTypes;
    if (asideMenuState.filter((x: any) => x.isActive === true).length >= 1) {
      currentActive = asideMenuState.filter((x: any) => x.isActive === true)[0]
        .itemType;
      if (currentActive === asideMenuItemTypes.general) {
        //already on general
        return;
      }
    }
    let command: commandType = {
      type: commandsEnum.set_active_general,
      payload: null,
    };
    dispatchCommand(command);
    return;
  };

  const onDetailsAsideItemClick = () => {
    let command: commandType = {
      type: commandsEnum.set_active_assign_users,
      payload: null,
    };
    dispatchCommand(command);
  };

  const handleOnExitClick = () => {
    callBackResult({
      result: "cancel_general",
      recordId: realRecordId,
    });
  };

  //create home content
  const realCreateGeneralContentCommand = (
    prevState: Array<asideMenuItemsModel>,
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let verticalElement: asideMenuItemsModel = {
      itemLabel: titleGeneralInformation,
      itemType: asideMenuItemTypes.general,
      isActive: true,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onGeneralAsideItemClick,
    };
    newStateHome.push(verticalElement);
    if (mode == "Update") {
      let verticalElement2: asideMenuItemsModel = {
        itemLabel: titleAssignUsers,
        itemType: asideMenuItemTypes.assign_users,
        isActive: false,
        isVisible: true,
        isEnabled: true,
        onClickHandler: onDetailsAsideItemClick,
      };
      newStateHome.push(verticalElement2);
    }
    if (mode == "Clone") {
      newStateHome[0].isActive = false;
      let verticalElement2: asideMenuItemsModel = {
        itemLabel: titleGeneralInformation,
        itemType: asideMenuItemTypes.clone,
        isActive: true,
        isVisible: true,
        isEnabled: true,
        onClickHandler: onDetailsAsideItemClick,
      };
      newStateHome.push(verticalElement2);
    }
    return newStateHome;
  };

  //create details content
  const realCreateDetailsContentCommand = (
    prevState: Array<asideMenuItemsModel>,
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
    }

    let verticalElement: asideMenuItemsModel = {
      itemLabel: titleAssignUsers,
      itemType: asideMenuItemTypes.assign_users,
      isActive: true,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onDetailsAsideItemClick,
    };
    newStateHome.push(verticalElement);
    return newStateHome;
  };

  //set active item
  const setActiveContent = (
    prevState: Array<asideMenuItemsModel>,
    itemType: asideMenuItemTypes,
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
      if (itemType == newStateHome[i].itemType) {
        newStateHome[i].isActive = true;
      }
    }
    return newStateHome;
  };

  //aside menu state with reducer
  let initialState: Array<asideMenuItemsModel> = [];
  const [asideMenuState, dispatchCommand] = useReducer(doCommand, initialState);

  function doCommand(
    prevState: Array<asideMenuItemsModel>,
    command: commandType,
  ) {
    switch (command.type) {
      case commandsEnum.create_general:
        return realCreateGeneralContentCommand(prevState);
      case commandsEnum.create_assign_users_data_and_change_mode:
        const newRecordId: string = command.payload.recordId;
        const newRecordDescription: string = command.payload.recordDescription;
        setRealRecordId(newRecordId);
        setRealRecordDescription(newRecordDescription);
        return realCreateDetailsContentCommand(prevState);
      case commandsEnum.set_active_general:
        return setActiveContent(prevState, asideMenuItemTypes.general);
      case commandsEnum.set_active_assign_users:
        return setActiveContent(prevState, asideMenuItemTypes.assign_users);
    }
  }

  const subtitleCallback = (subtitle: string) => {
    setPageSubtitle(subtitle);
  }

  //run once to include functionsAdmin in first tab
  useEffect(() => {
    //load resources
    if (!isReady) {
      loadResources();
    } else {
      let command: commandType = {
        type: commandsEnum.create_general,
        payload: null,
      };
      dispatchCommand(command);
    }
  }, [isReady]);
  return (
    <>
      <ContentVerticalTabInsertUpdateStyled>
        <div className="groups-main-container">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: "10px" }}>
            <div>
              <TPPageTitle>{pageTitle}</TPPageTitle>
              {mode === "Update" && (
                <TPPageSubTitle>{pageSubtitle}</TPPageSubTitle>
              )}
            </div>
          </div>
          {mode != "Clone" && (
            <TPHorizontalTabs
              labels={[titleGeneralInformation, titleAssignUsers]}
              activeTab={currentTab}
              onChange={(_, t) => {
                setCurrentTab(t);
              }}
              disabledTabs={mode === "Insert" ? [titleAssignUsers] : []}
            />
          )}
          <div className="groups-tab" style={{ width: "100%", overflow: "auto" }}>
            {currentTab === GroupsTabs.GeneralInfo && (
              <GroupsInsertUpdate
                callBackResult={handleCallBackCommands}
                mode={mode}
                recordId={realRecordId}
                groupDescription={
                  !!recordDescription ? recordDescription : realRecordDescription
                }
                callBackSubtitle={subtitleCallback}
              />
            )}
            {currentTab === GroupsTabs.AssignUsers && (
              <AssignUserToGroup
                groupId={+realRecordId}
                groupDescription={realRecordDescription}
              ></AssignUserToGroup>
            )}
          </div>
        </div>
      </ContentVerticalTabInsertUpdateStyled>
      
      {/* <div className="row">
        <div className="col-10">
          <ul>
            {asideMenuState.map(function (item, index) {
              let jsxFragment: any = <></>;
              let visibilityStyle: any;
              if (item.isActive) {
                visibilityStyle = { display: "block" };
              } else {
                visibilityStyle = { display: "none" };
              }
              switch (item.itemType) {
                case asideMenuItemTypes.general:
                  jsxFragment = (
                    <li key={"asidecontent" + index.toString()}>
                      <div style={visibilityStyle}>
                        <GroupsInsertUpdate
                          callBackResult={handleCallBackCommands}
                          mode={mode}
                          recordId={realRecordId}
                          groupDescription={
                            !!recordDescription
                              ? recordDescription
                              : realRecordDescription
                          }
                        />
                      </div>
                    </li>
                  );
                  break;
                case asideMenuItemTypes.assign_users:
                  jsxFragment = (
                    <li key={"asidecontent" + index.toString()}>
                      <div style={visibilityStyle}>
                        <AssignUserToGroup
                          groupId={+realRecordId}
                          groupDescription={realRecordDescription}
                        ></AssignUserToGroup>
                      </div>
                    </li>
                  );
                  break;
                default:
                  jsxFragment = (
                    <li key={"asidecontent" + index.toString()}>
                      <div style={visibilityStyle}>
                        <label>error this should not be here</label>
                      </div>
                    </li>
                  );
                  break;
              }
              return jsxFragment;
            })}
          </ul>
        </div>

        <RightVerticalMenuContainer className="col-2 mt-4">
          <RightVerticalMenuWrapper>
            <RightVerticalMenuUl>
              {asideMenuState.map(function (item, index) {
                //render aside menu
                let jsxFragment: any = <></>;
                let visibilityStyle: any;
                if (item.isVisible) {
                  visibilityStyle = {
                    display: "block",
                    borderBottom: "1px solid #e8e8e8",
                  };
                } else {
                  visibilityStyle = {
                    display: "none",
                    borderBottom: "1px solid #e8e8e8",
                  };
                }
                switch (item.itemType) {
                  case asideMenuItemTypes.general:
                    jsxFragment = (
                      <RightVerticalMenuLi
                        className={item.isActive ? "active" : ""}
                        key={"asidemenuitem" + index.toString()}
                      >
                        <div style={visibilityStyle}>
                          <label onClick={() => item.onClickHandler()}>
                            {item.itemLabel}
                          </label>
                        </div>
                      </RightVerticalMenuLi>
                    );
                    break;
                  case asideMenuItemTypes.assign_users:
                    jsxFragment = (
                      <RightVerticalMenuLi
                        className={item.isActive ? "active" : ""}
                        key={"asidemenuitem" + index.toString()}
                      >
                        <div style={visibilityStyle}>
                          <label onClick={() => item.onClickHandler()}>
                            {item.itemLabel}
                          </label>
                        </div>
                      </RightVerticalMenuLi>
                    );
                    break;
                  case asideMenuItemTypes.clone:
                    break;
                  default:
                    jsxFragment = (
                      <RightVerticalMenuLi
                        className={item.isActive ? "active" : ""}
                        key={"asidemenuitem" + index.toString()}
                      >
                        <div style={visibilityStyle}>
                          <label>error this should not be here</label>
                        </div>
                      </RightVerticalMenuLi>
                    );
                    break;
                }
                return jsxFragment;
              })}
              <RightVerticalMenuLi
                key={"asidemenuitem" + asideMenuState.length.toString()}
                style={{ marginTop: "30px" }}
              ></RightVerticalMenuLi>
              <RightVerticalMenuLi
                key={"asidemenuitem" + (asideMenuState.length + 1).toString()}
              >
                <label onClick={() => handleOnExitClick()}>{exitLabel}</label>
              </RightVerticalMenuLi>
            </RightVerticalMenuUl>
          </RightVerticalMenuWrapper>
        </RightVerticalMenuContainer>
      </div> */}
    </>
  );
};

export default GroupsInsertUpdateContainer;
