import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPRadio } from "@/components/TPRadio/TPRadio";
import {
  ModalSizeEnum,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { GroupsService } from "@/services/GroupsService";
import { FC, useEffect, useState } from "react";
import { useQueuesAdminSelectOptions } from "../../assets/controller";
import useQueueAdminLabels from "../../assets/labels";
import "../../assets/Styles.css";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import { TPTextBoxStyled } from "@/components/bootstrap/forms/textbox/tpTextboxStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import TPModal from "@/layouts/TPModal/TPModal";
import ListManagement from "@/pages/EventsManager/ListAdministration/ListManagement";
import { TPPageSectionTitle } from "@/components/TPPage/tpPageStyles";
import "./styles.css";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { AdditionalDataFormService } from "@/services/AdditionalDataFormService";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import AdditionalDataInsertUpdate from "@/pages/AdditionalData/AdditionalDataInsertUpdate";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import { v4 as uuidv4 } from "uuid";
import { AdditionalDataService } from "@/services/AdditionalDataService";

interface QueueGeneralInfoProperties {
  queueData?: any;
  groupId: number;
  tabCallback: Function;
  updateCallback: Function;
  onInteractionOutcomesChange: (hasOutcomes: boolean) => void;
  onCustomerFormIdChange: (id: string) => void;
}

export enum RecordTypes {
  front = "Frontoffice",
  back = "Backoffice",
}

type InsertUpdateStateType = {
  customerFormId: string;
  customerFormErrorMessage: string;
};

type InsertUpdateStateAdditionalData = {
  customerAdditionalDataId: string;
  customerAdditionalDataErrorMessage: string;
};

const QueueAdministrationTab: FC<
  QueueGeneralInfoProperties & { saveCallback?: Function }
> = function ({
  groupId,
  tabCallback,
  updateCallback,
  onInteractionOutcomesChange,
  saveCallback,
  onCustomerFormIdChange,
}) {
  const componentFileName: string = "QueueAdministrationTab.tsx";
  const { labels, labelsLoaded } = useQueueAdminLabels();
  const [classificationOptionsInput, setClassificationOptions] =
    useState<string>("");
  const [customFieldsInput, setCustomFields] = useState<string>("");
  const [interactionOutcomesInput, setInteractionOutcomes] =
    useState<string>("");
  const [recordType, setRecordType] = useState<RecordTypes>(RecordTypes.back);
  const [warningMinutes, setWarningMinutes] = useState<string>("0");
  const [warningSeconds, setWarningSeconds] = useState<string>("0");
  const [contentLoaded, setContentLoaded] = useState<boolean>(true);
  const [canSave, setCanSave] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState<"IO" | "CO" | "AD" | "none">("none");
  const [newListId, setNewListId] = useState<string>("");

  let initialStateFormList: Array<TPKeyValue> = [];
  const [formKeyValue, setFormKeyValue] = useState(initialStateFormList);

  let initialStateAdditionalDataField: Array<TPKeyValue> = [];
  const [additionalDataKeyValue, setAdditionalDataKeyValue] = useState(
    initialStateAdditionalDataField,
  );

  const {
    optionsLoaded,
    classificationOptions,
    interactionOutcomes,
    customFields,
    setOptionsLoaded,
  } = useQueuesAdminSelectOptions();

  const [interactionOutcomesAutoComplete, setInteractionOutcomesAutoComplete] =
    useState<TPKeyValue[]>([]);
  const [
    clasificationOptionsAutoComplete,
    setClasificationOptionsAutoComplete,
  ] = useState<TPKeyValue[]>([]);
  const [customFieldsAutoComplete, setCustomFieldsAutoComplete] = useState<
    TPKeyValue[]
  >([]);

  let insertUpdateInitialState: InsertUpdateStateType = {
    customerFormId: "",
    customerFormErrorMessage: "",
  };
  const [insertUpdateState, setInsertUpdateState] = useState(
    insertUpdateInitialState,
  );

  const [rows, setRows] = useState([
    {
      id: uuidv4(),
      order: "1",
      additionalDataId: "",
      mandatory: true,
    },
  ]);

  const fetchData = function () {
    setContentLoaded(false);
    const groupServiceInstance = new GroupsService();
    groupServiceInstance
      .getQueueGroupId(String(groupId), false, false, [200])
      .then((queue) => {
        if (queue) {
          setClassificationOptions(queue.clasificationOptions || "");
          setInteractionOutcomes(queue.interactionOutcomes);
          setCustomFields(queue.customFields || "");
          setWarningMinutes(String(queue.activateWarningAfterMinutes));
          setWarningSeconds(String(queue.activateWarningAfterSeconds));
          setRecordType(queue.enventGroupType as RecordTypes);
        }
        setContentLoaded(true);
      })
      .catch((error) => console.error(error));
  };

  const handleInteractionOutcomesChange = (event: any) => {
    const updatedValue = event;
    setInteractionOutcomesAutoComplete(updatedValue);

    if (updateCallback) {
      updateCallback({ interactionOutcomesAutoComplete: updatedValue });
    }
  };

  const handleCustomFieldsChange = (event: any) => {
    const updatedValue = event;
    setCustomFieldsAutoComplete(updatedValue);

    if (updateCallback) {
      updateCallback({
        customFieldsAutoComplete: updatedValue,
      });
    }
  };

  const handleSave = function () {
    if (!canSave) return;
    setCanSave(false);
    setContentLoaded(false);

    const customFieldsTypeValue =
      customFieldsAutoComplete[0]?.value === "Additional Data"
        ? "ADDITIONALDATA"
        : customFieldsAutoComplete[0]?.value === "Form"
          ? "FORM"
          : null;

    const additionalDataPayload =
      customFieldsTypeValue === "ADDITIONALDATA"
        ? rows.map((row) => ({
            additionalDataId: row.additionalDataId,
            order: parseInt(row.order, 10),
            isMandatory: row.mandatory,
          }))
        : [];

    const groupServiceInstance = new GroupsService();
    groupServiceInstance
      .updateQueueAdministration(
        {
          groupId: groupId,
          interactionOutcomes: interactionOutcomesAutoComplete[0].key,
          clasificationOptions:
            clasificationOptionsAutoComplete[0]?.key.length > 0
              ? clasificationOptionsAutoComplete[0].key
              : null,
          customFields:
            customFieldsAutoComplete[0]?.key.length > 0
              ? customFieldsAutoComplete[0].key
              : null,
          activateWarningAfterMinutes: Number(warningMinutes),
          activateWarningAfterSeconds: Number(warningSeconds),
          enventGroupType: recordType,
          customFieldsType: customFieldsTypeValue,
          additionalData:
            customFieldsTypeValue === "ADDITIONALDATA"
              ? additionalDataPayload
              : undefined,
        },
        false,
        true,
        [200],
      )
      .then((response) => {
        if (response.responseData.responseCode === 200) {
          showToast(labels.UpdateSuccess, TPToastTypes.success);
          updateCallback({
            result: "ReloadGrid",
          });
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setCanSave(true);
        setContentLoaded(true);
      });
  };

  const handleTimeInput = function (value: string, type: "min" | "sec") {
    let fixedValue: number = Number(value);
    if (isNaN(fixedValue) || fixedValue < 0 || value.length === 0) {
      fixedValue = 0;
    }
    if (fixedValue > 59) {
      fixedValue = 59;
    }

    if (type === "min")
      setWarningMinutes((p) =>
        String(fixedValue) !== p ? String(fixedValue) : p,
      );
    if (type === "sec") setWarningSeconds(String(fixedValue));
  };

  const handleCustomerFormChange = (newCustomerFormID: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.customerFormId = newCustomerFormID;
    newInsertUpdateState.customerFormErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
    onCustomerFormIdChange(newCustomerFormID);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        id: uuidv4(),
        order: `${rows.length + 1}`,
        additionalDataId: "",
        mandatory: true,
      },
    ]);
  };

  const handleDeleteRow = (id: string) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const loadInfo = async () => {
    //Load Form List
    let formService = new AdditionalDataFormService();
    let expectedCodes: Array<number> = [200];
    try {
      //TODO Change constants
      let responseRequest = await formService.getByFiltersIsActive(
        "1",
        "S_FTCUSTOMER",
        false,
        true,
        expectedCodes,
      );

      let newFormListState: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return {
            key: item.id,
            value: item.localizedDescription
              ? item.localizedDescription
              : item.description,
          };
        },
      );
      newFormListState.unshift({ key: "", value: "--" });
      setFormKeyValue(newFormListState);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResourcesAndLoadInfo ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} loadResourcesAndLoadInfo ex`);
      return;
    }

    let additionalDataFieldService = new AdditionalDataService();
    try {
      let responseRequest = await additionalDataFieldService.getAll(
        false,
        true,
        expectedCodes,
      );
      let newAdditionalDataFieldState: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return {
            key: item.id,
            value: item.description,
          };
        },
      );
      newAdditionalDataFieldState.unshift({ key: "", value: "--" });
      setAdditionalDataKeyValue(newAdditionalDataFieldState);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResourcesAndLoadInfo ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} loadResourcesAndLoadInfo ex`);
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (customFields.length > 1 && customFieldsInput.length > 1) {
      customFields.find(
        (customField) => customField.key === customFieldsInput,
      ) &&
        setCustomFieldsAutoComplete([
          {
            key: customFieldsInput,
            value: customFields.find(
              (customField) => customField.key === customFieldsInput,
            )?.value,
          },
        ]);
    }
  }, [customFields, customFieldsInput]);

  useEffect(() => {
    if (
      classificationOptions.length > 1 &&
      classificationOptionsInput.length > 1 &&
      mode === "none"
    ) {
      classificationOptions.find(
        (classificationOption) =>
          classificationOption.key === classificationOptionsInput,
      ) &&
        setClasificationOptionsAutoComplete([
          {
            key: classificationOptionsInput,
            value: classificationOptions.find(
              (classificationOption) =>
                classificationOption.key === classificationOptionsInput,
            )?.value,
          },
        ]);
    }
  }, [classificationOptions, classificationOptionsInput]);

  useEffect(() => {
    if (
      interactionOutcomes.length > 1 &&
      interactionOutcomesInput.length > 1 &&
      mode === "none"
    ) {
      interactionOutcomes.find(
        (interactionOutcome) =>
          interactionOutcome.key === interactionOutcomesInput,
      ) &&
        setInteractionOutcomesAutoComplete([
          {
            key: interactionOutcomesInput,
            value: interactionOutcomes.find(
              (interactionOutcome) =>
                interactionOutcome.key === interactionOutcomesInput,
            )?.value,
          },
        ]);
    }
  }, [interactionOutcomes, interactionOutcomesInput]);

  const newListCallback = (command: any) => {
    setOpenModal(false);

    if (command.command === "update") {
      setNewListId(command.recordId);
      setOptionsLoaded(false);
    }
  };

  useEffect(() => {
    if (interactionOutcomes.length > 1 && classificationOptions.length > 1) {
      if (mode === "IO") {
        interactionOutcomes.find(
          (interactionOutcome) => interactionOutcome.key === newListId,
        ) &&
          setInteractionOutcomesAutoComplete([
            {
              key: newListId,
              value: interactionOutcomes.find(
                (interactionOutcome) => interactionOutcome.key === newListId,
              )?.value,
            },
          ]);
      } else if (mode === "CO") {
        classificationOptions.find(
          (classificationOption) => classificationOption.key === newListId,
        ) &&
          setClasificationOptionsAutoComplete([
            {
              key: newListId,
              value: classificationOptions.find(
                (classificationOption) =>
                  classificationOption.key === newListId,
              )?.value,
            },
          ]);
      }

      setMode("none");
    }
  }, [interactionOutcomes, classificationOptions]);

  const blankSelectItem = "--";

  useEffect(() => {
    const hasValidOutcomes = interactionOutcomesAutoComplete.some(
      (outcome) =>
        outcome.key.trim() !== "" && outcome.value !== blankSelectItem,
    );
    onInteractionOutcomesChange(hasValidOutcomes);
  }, [interactionOutcomesAutoComplete]);

  useEffect(() => {
    loadInfo();
  }, []);

  useEffect(() => {
    if (saveCallback) saveCallback(() => handleSave());
  }, [saveCallback]);

  return (
    <>
      <TPModal
        modalState={{
          titleModal: "",
          isShown: openModal,
          callBackAnswer: () => {},
          hiddenHeader: true,
          hideFooterButtons: true,
          hideXButton: true,
          modalWidth: ModalSizeEnum.MODALLG,
        }}
      >
        {openModal ? (
          <>
            {mode === "AD" ? (
              <AdditionalDataInsertUpdate
                mode="Insert"
                recordId={""}
                callBackResult={(result: any) => {
                  if (result.result === "CANCEL" || "OK") {
                    handleCloseModal();
                  }
                }}
                style={{
                  overflow: "auto",
                  display: "flex",
                }}
              />
            ) : (
              <ListManagement
                mode="new"
                recordId="--"
                verticalTabCallback={newListCallback}
                updateCallback={() => {}}
              />
            )}
          </>
        ) : (
          <>
            <p>resseting modal</p>
          </>
        )}
      </TPModal>
      <TPLoadingOverlay
        active={!(optionsLoaded && labelsLoaded && contentLoaded)}
      >
        <>
          <div className="row">
            <div className="col-6">
              <TPPageSectionTitle
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  margin: "16px 0",
                }}
              >
                Outcomes
              </TPPageSectionTitle>
            </div>
          </div>

          <div className="menu-def-form">
            <div className="menu-def-form-row">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <TPAutoComplete
                  isMandatory
                  containerStyle={{ flex: 1 }}
                  labelText={labels.InteractionOutcomes}
                  onValueChange={handleInteractionOutcomesChange}
                  selected={interactionOutcomesAutoComplete}
                  options={interactionOutcomes}
                  icon={TPIconTypes.search}
                  withIcon
                  onSearch={(event: any) => {}}
                  downArrowClick={() => {}}
                  onKeyDown={() => {}}
                  isLoading={false}
                  emptyLabel=""
                />
              </div>
              <TPButton
                id="queue-admin-new-list"
                isDesignSystem
                style={{
                  padding: "5px",
                  marginLeft: "5px",
                  display: "flex",
                  alignSelf: "end",
                }}
                onClick={() => {
                  setOpenModal(true);
                  setMode("IO");
                }}
              >
                <TPIcon iconType={TPIconTypes.add} />
              </TPButton>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <TPAutoComplete
                  containerStyle={{ flex: 1 }}
                  labelText={labels.ClassificationOptions}
                  onValueChange={(event: any) =>
                    setClasificationOptionsAutoComplete(event)
                  }
                  selected={clasificationOptionsAutoComplete}
                  options={classificationOptions}
                  icon={TPIconTypes.search}
                  withIcon
                  onSearch={(event: any) => {}}
                  downArrowClick={() => {}}
                  onKeyDown={() => {}}
                  isLoading={false}
                  emptyLabel=""
                />
              </div>
              <TPButton
                id="queue-admin-new-list"
                isDesignSystem
                style={{
                  padding: "5px",
                  marginLeft: "5px",
                  display: "flex",
                  alignSelf: "end",
                }}
                onClick={() => {
                  setOpenModal(true);
                  setMode("CO");
                }}
              >
                <TPIcon iconType={TPIconTypes.add} />
              </TPButton>
            </div>

            <div style={{ width: "100%" }}>
              <TPAutoComplete
                containerStyle={{ width: "100%" }}
                labelText={labels.CustomFields}
                onValueChange={handleCustomFieldsChange}
                selected={customFieldsAutoComplete}
                options={customFields}
                icon={TPIconTypes.search}
                withIcon
                onSearch={(event: any) => {}}
                downArrowClick={() => {}}
                onKeyDown={() => {}}
                isLoading={false}
                emptyLabel=""
              />
            </div>
            {customFieldsAutoComplete[0]?.value === "Form" && (
              <div
                style={{
                  display: "flex",
                  width: "50%",
                  flexDirection: "column",
                }}
              >
                <TPSelect
                  isMandatory={true}
                  onChange={(e: any) =>
                    handleCustomerFormChange(e.target.value)
                  }
                  dataSource={formKeyValue}
                  value={insertUpdateState.customerFormId}
                  labelText={labels.SelectaForm}
                  errorMessage={insertUpdateState.customerFormErrorMessage}
                ></TPSelect>
              </div>
            )}
            {customFieldsAutoComplete[0]?.value === "Additional Data" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  width: "100%",
                }}
              >
                {rows.map((row, index) => (
                  <div
                    key={row.id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "end",
                      gap: "8px",
                      paddingLeft: "41px",
                      position: "relative",
                    }}
                  >
                    {index > 0 && (
                      <TPIcon
                        iconType={TPIconTypes.delete}
                        style={{
                          fontSize: "24px",
                          position: "absolute",
                          left: "8px",
                        }}
                        onClick={() => handleDeleteRow(row.id)}
                      />
                    )}

                    <TPTextBox
                      onChange={(e: any) =>
                        setRows(
                          rows.map((r) =>
                            r.id === row.id
                              ? { ...r, order: e.target.value }
                              : r,
                          ),
                        )
                      }
                      value={row.order}
                      labelText={labels.Order}
                      isMandatory
                      placeholder="1"
                    />

                    <TPSelect
                      isMandatory={true}
                      onChange={(e: any) => {
                        const newValue = e.target.value;

                        const updatedRows = rows.map((r) =>
                          r.id === row.id
                            ? { ...r, additionalDataId: newValue }
                            : r,
                        );
                        setRows(updatedRows);
                      }}
                      dataSource={additionalDataKeyValue}
                      value={row.additionalDataId}
                      labelText={labels.AdditionalData}
                      placeholder="Search"
                      containerStyle={{ width: "100%" }}
                    ></TPSelect>

                    <TPCheckBox
                      checked={row.mandatory}
                      labelText={labels.Mandatory}
                      onChange={(e: any) =>
                        setRows(
                          rows.map((r) =>
                            r.id === row.id
                              ? { ...r, mandatory: e.target.checked }
                              : r,
                          ),
                        )
                      }
                      className="pb-2"
                    />
                    <TPIcon
                      iconType={TPIconTypes.addCircle}
                      style={{ fontSize: "24px", paddingBottom: "8px" }}
                      onClick={handleAddRow}
                    />
                  </div>
                ))}

                <div>
                  <TPButton
                    onClick={() => {
                      setOpenModal(true);
                      setMode("AD");
                    }}
                    type={TPButtonTypes.link}
                    style={{ padding: "6px 0", minWidth: "unset" }}
                  >
                    {labels.CreateAdditionalData}
                  </TPButton>
                </div>
              </div>
            )}
          </div>

          <div className="menu-def-form" style={{ margin: "24px 0" }}>
            <div
              className="menu-def-form-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "24px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "50%",
                  flexDirection: "column",
                }}
              >
                <TPLabel
                  labelText={labels.ActivateWarningAfter}
                  style={{
                    fontWeight: 700,
                    fontSize: "14px",
                    marginBottom: "8px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  <TPLabel
                    labelText={`${labels.Min} : ${labels.Sec}`}
                    style={{ fontWeight: 500, fontSize: "14px" }}
                  />
                  <TPTextBoxStyled
                    type="text"
                    className="form-control"
                    value={warningMinutes}
                    onChange={(e) => handleTimeInput(e.target.value, "min")}
                    style={{ width: "64px" }}
                  />
                  <label>:</label>
                  <TPTextBoxStyled
                    type="text"
                    className="form-control"
                    value={warningSeconds}
                    onChange={(e) => handleTimeInput(e.target.value, "sec")}
                    style={{ width: "64px" }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "50%",
                  flexDirection: "column",
                }}
              >
                <TPLabel
                  labelText={labels.RecordType}
                  style={{
                    fontWeight: 700,
                    fontSize: "14px",
                    marginBottom: "8px",
                  }}
                />

                <TPRadioGroup
                  source={[
                    { key: RecordTypes.front, value: labels.FrontOffice },
                    { key: RecordTypes.back, value: labels.BackOffice },
                  ]}
                  value={recordType}
                  onChange={(e: any) =>
                    setRecordType(e.target.value as RecordTypes)
                  }
                />
              </div>
            </div>
          </div>
        </>
      </TPLoadingOverlay>
    </>
  );
};

export default QueueAdministrationTab;
