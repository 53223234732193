import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import { FC, ReactElement, useCallback, useEffect, useState } from "react";

import {
    TPColumn,
    TPPageAcceptCancelButtonsContainer,
    TPPageSection,
    TPPageSectionTitle,
    TPPageSubTitle,
    TPPageTitle,
    TPRow,
} from "@/components/TPPage/tpPageStyles";

import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
    SequenceGeneratorSequencesNameEnum,
    TPButtonTypes,
    TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import {
    TreeInputDTO,
    TreeInputDTOValidator,
} from "@/models/Tree/TreeInputDTO";
import { MenuItemsModel, TreeViewModel } from "@/models/Tree/TreeModels";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { SequenceService } from "@/services/SequenceService";
import { TreeService } from "@/services/TreeService";
import TPHorizontalTabs from "../../components/TPTabs/TPHorizontalTabs";

type InsertUpdateProps = {
    mode: string;
    recordId: string;
    asideMenuState: MenuItemsModel[];
    callBackResult: Function;
    setIsOpenModal?: Function;
};

type InsertUpdateStateType = {
    idTree: string;
    recordLanguageList: Array<TPKeyValue>;
    isAlphabeticOrder: boolean;
    mustSelectLastBranch: boolean;
    isAutoincrement: boolean;
    isClassifier: boolean;
    comments: string;
    isActive: boolean;

    //validator
    idErrorMessage: string;
    nameErrorMessages: Array<string>;
    commentsErrorMessage: string;
};

const TreesInsertUpdate: FC<InsertUpdateProps> = ({
    mode,
    recordId,
    callBackResult,
    asideMenuState,
    setIsOpenModal
}): ReactElement => {
    //#region  Init
    const componentFileName: string = "TreesInsertUpdate.tsx";

    const [activeTab, setActiveTab] = useState<number>(0);

    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);

    //Screen resources
    const ResourceSet: string = "TreeInsertUpdateComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [subTitleLabel, setSubTitleLabel] = useState("");
    const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
    const [parametersSectionLabel, setParametersSectionLabel] = useState("");
    const [idLabel, setIdLabel] = useState("");
    const [nameLabel, setNameLabel] = useState("");
    const [isAlphabeticOrderLabel, setIsAlphabeticOrderLabel] = useState("");
    const [mustSelectLastBranchLabel, setMustSelectLastBranchLabel] =
        useState("");
    const [isAutoincrementLabel, setIsAutoincrementLabel] = useState("");
    const [isClassifierLabel, setIsClassifierLabel] = useState("");
    const [commentsLabel, setCommentsLabel] = useState("");
    const [insertYourCommentHereLabel, setInsertYourCommentHereLabel] = useState("");
    const [nameLanguageLabel, setNameLanguageLabel] = useState("");
    const [isActiveLabel, setIsActiveLabel] = useState("");
    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [languageListLabel, setLanguageListLabel] = useState("");

    const [originalRecordDescription, setOriginalRecordDescription] =
        useState("");

    const [realMode, setRealMode] = useState(mode);
    const [realRecordId, setRealRecordId] = useState(recordId);

    const [referenceId, setReferenceId] = useState("");
    //Screen state
    let initialErrorMessages: Array<string> = [];
    for (
        let i: number = 0;
        i <= TPGlobal.TPClientAvailableLanguages.length - 1;
        i++
    ) {
        initialErrorMessages.push("");
    }
    let insertUpdateInitialState: InsertUpdateStateType = {
        idTree: recordId,
        recordLanguageList: [],
        isAlphabeticOrder: true,
        mustSelectLastBranch: false,
        isAutoincrement: false,
        isClassifier: false,
        comments: "",
        isActive: true,
        idErrorMessage: "",
        nameErrorMessages: initialErrorMessages,
        commentsErrorMessage: "",
    };
    const [insertUpdateState, setInsertUpdateState] = useState(
        insertUpdateInitialState,
    );

    //Multilanguage const
    const multilanguageTableName: String = "TREE";
    const multilanguageFieldName: String = "Name_TREE";
    //#endregion

    const loadResourcesAndLoadTreeInfo = async () => {
        let i: number;
        //resources state
        setTitleLabel(
            await TPI18N.GetText(ResourceSet, "Title" + realMode + "Label"),
        );
        setSubTitleLabel(await TPI18N.GetText(ResourceSet, "SubtitleLabel"));
        setDescriptionSectionLabel(
            await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "DescriptionSectionLabel",
            ),
        );
        setParametersSectionLabel(
            await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "ParametersSectionLabel",
            ),
        );
        setSaveButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
        );
        setCancelButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        );
        setLanguageListLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
        );
        setIdLabel(await TPI18N.GetText(ResourceSet, "IdLabel"));
        setNameLabel(await TPI18N.GetText(ResourceSet, "NameLabel"));
        setIsAlphabeticOrderLabel(
            await TPI18N.GetText(ResourceSet, "IsAlphabeticOrderLabel"),
        );
        setMustSelectLastBranchLabel(
            await TPI18N.GetText(ResourceSet, "MustSelectLastBranchLabel"),
        );
        setIsAutoincrementLabel(
            await TPI18N.GetText(ResourceSet, "IsAutoincrementLabel"),
        );
        setIsClassifierLabel(
            await TPI18N.GetText(ResourceSet, "IsClassifierLabel"),
        );
        setCommentsLabel(await TPI18N.GetText(ResourceSet, "CommentsLabel"));
        setIsActiveLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ShowAsActive"));
        setInsertYourCommentHereLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "InsertYourCommentsHere"));
        setNameLanguageLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "EnterAValue"));

        //screen state

        if (realMode == "Update") {
            await getTreeById(realRecordId);
        }
        if (realMode == "Insert") {
            let newInsertUpdateState = { ...insertUpdateState };
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }
            setInsertUpdateState(newInsertUpdateState);
            setIsLoadingScreen(false);
        }
    };

    const handleOnIdTreeChange = (newIdTree: string) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.idTree = newIdTree;
        newInsertUpdateState.idErrorMessage = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleLanguageChange = (index: number, newName: string) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.recordLanguageList[index].value = newName;
        newInsertUpdateState.nameErrorMessages[index] = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleIsAlphabeticOrderChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.isAlphabeticOrder =
            !newInsertUpdateState.isAlphabeticOrder;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleMustSelectLastBranchChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.mustSelectLastBranch =
            !newInsertUpdateState.mustSelectLastBranch;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleIsAutoincrementChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.isAutoincrement =
            !newInsertUpdateState.isAutoincrement;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleIsClassifierChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.isClassifier = !newInsertUpdateState.isClassifier;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleOnCommentsChange = (newComments: string) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.comments = newComments;
        newInsertUpdateState.commentsErrorMessage = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleIsActiveChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.isActive = !newInsertUpdateState.isActive;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleOkButtonClick = async () => {
        let i: number;
        let n: number;
        let recordInputDTO: TreeInputDTO = {
            // id: insertUpdateState.idTree,
            id: realMode === "Insert" ? referenceId : recordId,
            isAlphabeticOrder: insertUpdateState.isAlphabeticOrder,
            mustSelectLastBranch: insertUpdateState.mustSelectLastBranch,
            isAutoincrement: insertUpdateState.isAutoincrement,
            isClassifier: insertUpdateState.isClassifier,
            comments: insertUpdateState.comments,
            isActive: insertUpdateState.isActive,
            name: insertUpdateState.recordLanguageList[0].value,
            nameLocalizedValues: [],
        };
        n = insertUpdateState.recordLanguageList.length;
        for (i = 0; i <= n - 1; i++) {
            let item: TPKeyValue;
            item = insertUpdateState.recordLanguageList[i];
            recordInputDTO.nameLocalizedValues.push({
                order: i + 1,
                languageId: item.key,
                localizedValue: item.value,
            });
        }
        let inputDTOValidator = new TreeInputDTOValidator();
        let resultValidator = inputDTOValidator.validate(recordInputDTO);
        if (!TPGlobal.TPIsEmpty(resultValidator)) {
            let newInsertUpdateState = { ...insertUpdateState };
            if (resultValidator.id) {
                newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
                    resultValidator.id,
                );
            } else {
                newInsertUpdateState.idErrorMessage = "";
            }
            if (resultValidator.name) {
                newInsertUpdateState.nameErrorMessages[0] = await TPI18N.GetResource(
                    resultValidator.name,
                );
            } else {
                newInsertUpdateState.nameErrorMessages[0] = "";
            }
            if (resultValidator.nameLocalizedValues) {
                n = insertUpdateState.recordLanguageList.length;
                for (i = 1; i <= n - 1; i++) {
                    if (resultValidator.nameLocalizedValues[i] != null) {
                        newInsertUpdateState.nameErrorMessages[i] =
                            await TPI18N.GetResource(
                                String(resultValidator.nameLocalizedValues[i]),
                            );
                    }
                }
            } else {
                n = insertUpdateState.recordLanguageList.length;
                for (i = 1; i <= n - 1; i++) {
                    newInsertUpdateState.nameErrorMessages[i] = "";
                }
            }
            if (resultValidator.comments) {
                newInsertUpdateState.commentsErrorMessage = await TPI18N.GetResource(
                    resultValidator.comments,
                );
            } else {
                newInsertUpdateState.commentsErrorMessage = "";
            }
            setInsertUpdateState(newInsertUpdateState);
            return;
        }
        if (realMode == "Insert") {
            await insertTree(recordInputDTO);
        } else {
            await updateTree(recordInputDTO);
        }
    };

    const insertTree = async (inputDTO: TreeInputDTO) => {
        let serviceClient = new TreeService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.insertTree(
                inputDTO,
                true,
                true,
                expectedCodes,
            );
            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                //callBackResult({ result: "OK", recordId: recordId });
                callBackResult({
                    result: "save_general_insert_and_change_mode",
                    recordId: inputDTO.id,
                });
                //change mode
                setTitleLabel(await TPI18N.GetText(ResourceSet, "TitleUpdateLabel"));
                setRealRecordId(inputDTO.id);
                setOriginalRecordDescription(
                    inputDTO.nameLocalizedValues[0].localizedValue.length > 100
                        ? inputDTO.nameLocalizedValues[0].localizedValue.substring(0, 100) +
                        "..."
                        : inputDTO.nameLocalizedValues[0].localizedValue,
                );
                setRealMode("Update");
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} insertTree ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} insertTree ex`);
            setIsLoadingScreen(false);
        }
    };

    const updateTree = async (inputDTO: TreeInputDTO) => {
        let serviceClient = new TreeService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.updateTree(
                inputDTO,
                true,
                true,
                expectedCodes,
            );
            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                //callBackResult({ result: "OK", recordId: recordId });
                callBackResult({
                    result: "save_general_update",
                    recordId: inputDTO.id,
                });

                setOriginalRecordDescription(
                    inputDTO.nameLocalizedValues[0].localizedValue.length > 100
                        ? inputDTO.nameLocalizedValues[0].localizedValue.substring(0, 100) +
                        "..."
                        : inputDTO.nameLocalizedValues[0].localizedValue,
                );
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} updateTree ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} updateTree ex`);
            setIsLoadingScreen(false);
        }
    };

    const getTreeById = async (pRecordId: string) => {
        let serviceClient = new TreeService();
        let expectedCodes: Array<number> = [200];
        let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
        let i: number;
        let j: number;
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.getTreeById(
                pRecordId,
                false,
                true,
                expectedCodes,
            );

            let recordInfo: TreeViewModel;
            recordInfo = { ...responseRequest };

            let newInsertUpdateState = { ...insertUpdateState };
            newInsertUpdateState.recordLanguageList = [];
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }
            newInsertUpdateState.isAlphabeticOrder = recordInfo.isAlphabeticOrder;
            newInsertUpdateState.mustSelectLastBranch =
                recordInfo.mustSelectLastBranch;
            newInsertUpdateState.isAutoincrement = recordInfo.isAutoincrement;
            newInsertUpdateState.isClassifier = recordInfo.isClassifier;
            newInsertUpdateState.comments = recordInfo.comments
                ? recordInfo.comments
                : "";
            newInsertUpdateState.isActive = recordInfo.isActive;
            newInsertUpdateState.recordLanguageList[0].value = recordInfo.name;
            recordLanguagesList = await getRecordLanguageList(pRecordId);
            if (recordLanguagesList.length == 0) {
                TPLog.Log(
                    `Error ${componentFileName} getTreeById getRecordLanguageList`,
                    TPLogType.ERROR,
                    "recordLanguagesList is empty",
                );
                console.error(
                    `Error ${componentFileName} getTreeById getRecordLanguageList is empty`,
                );
                setIsLoadingScreen(false);
                return;
            }
            for (
                i = 0;
                i <= newInsertUpdateState.recordLanguageList.length - 1;
                i++
            ) {
                for (j = 0; j <= recordLanguagesList.length - 1; j++) {
                    if (
                        newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ==
                        recordLanguagesList[j].languageId.toLowerCase()
                    ) {
                        newInsertUpdateState.recordLanguageList[i].value =
                            recordLanguagesList[j].recordDescription;
                        break;
                    }
                }
            }
            setInsertUpdateState(newInsertUpdateState);
            setOriginalRecordDescription(
                newInsertUpdateState.recordLanguageList[0].value.length > 100
                    ? newInsertUpdateState.recordLanguageList[0].value.substring(0, 100) +
                    "..."
                    : newInsertUpdateState.recordLanguageList[0].value,
            );
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getTreeById ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getTreeById ex`);
            setIsLoadingScreen(false);
        }
    };

    const getRecordLanguageList = async (
        pRecordId: String,
    ): Promise<Array<MultilanguageFieldValueViewModel>> => {
        let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest =
                await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
                    multilanguageTableName,
                    multilanguageFieldName,
                    pRecordId,
                    false,
                    true,
                    expectedCodes,
                );

            let recordInfo: Array<MultilanguageFieldValueViewModel>;
            recordInfo = [...responseRequest];
            return recordInfo;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getRecordLanguageList ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getRecordLanguageList ex`);
            return [];
        }
    };

    const handleCancelButtonClick = () => {
        callBackResult({ result: "cancel_general", recordId: realRecordId });
    };

    const {
        isOpen: isOpenModalLanguageList,
        openModal: handleOpenModalLanguageList,
        closeModal: handleCloseModalLanguageList,
        saveChanges: handleSaveChangesModalLanguageList,
    } = useModal(false);

    const generalAutomaticId = async () => {
        let serviceClient = new SequenceService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.generalAutomaticId(
                false,
                true,
                expectedCodes,
                SequenceGeneratorSequencesNameEnum.SQTREE,
            );

            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                let result = responseRequest?.responseData?.data[0]?.sequenceCode;
                setReferenceId(result);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} updatetFunction ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} updatetFunction ex`);
            setIsLoadingScreen(false);
        }
    };

    const handleValidateActive = () => {
        if (!insertUpdateState.recordLanguageList) return true;
        let data = insertUpdateState.recordLanguageList.find(e => e.key == TPGlobal.TPClientDefaultLanguage);
        if (!data) return true;
        return (data?.value == "");
    }


    const handleTabChange = useCallback(
        (event: React.SyntheticEvent, newValue: number) => {
            let action = asideMenuState[newValue];
            if (action)
                action.onClickHandler();
            setActiveTab(newValue);
        },
        []
    );

    //Only once to set resources and load function in update mode
    useEffect(() => {
        loadResourcesAndLoadTreeInfo();
        realMode === "Insert" && generalAutomaticId();
    }, []);

    useEffect(() => {
        setIsOpenModal && setIsOpenModal(!!isOpenModalLanguageList)
    }, [setIsOpenModal, isOpenModalLanguageList]);

    return (
        //#region  Render

        <ContentVerticalTabInsertUpdateStyled style={{ padding: "5px" }}  >
            <div className="row">
                <div className="col-10">
                    <TPColumn   >

                        <div className="row">
                            <div className="col-10">
                                <TPPageTitle>{titleLabel}</TPPageTitle>
                                {/*{realMode === "Update" ? (*/}
                                {/*    <TPPageSubTitle>{`${subTitleLabel} ${recordId} / ${originalRecordDescription}`}</TPPageSubTitle>*/}
                                {/*) : null}*/}
                                <TPHorizontalTabs
                                    labels={asideMenuState.map((e: MenuItemsModel) => {
                                        return e.itemLabel
                                    })}
                                    activeTab={0}
                                    onChange={handleTabChange}
                                />
                            </div>
                        </div>


                <TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>

                <TPRow
                    gridTemplateColumnsValue={"1fr"}
                    paddingRight={"71px"}
                >
                    <TPTextBox
                        id="tree-id-input"
                        labelText={idLabel.toUpperCase()}
                        isMandatory={true}
                        // value={insertUpdateState.idTree}
                        value={realMode === "Insert" ? referenceId : recordId}
                        onChange={
                            realMode === "Insert"
                                ? (e: any) => handleOnIdTreeChange(e.target.value)
                                : () => {
                                    TPGlobal.foo();
                                }
                        }
                        maxLength={20}
                        // disabled={realMode !== "Insert"}
                        disabled
                        errorMessage={insertUpdateState.idErrorMessage}
                    />
                </TPRow>

                <TPRow gridTemplateColumnsValue={"1fr 50px"} height={"60px"} >
                    {insertUpdateState.recordLanguageList.length > 0 &&
                        TPGlobal.TPClientAvailableLanguages.map(
                            (item, index) =>
                                item.id === TPGlobal.TPClientDefaultLanguage && (
                                    <div key={`languageItem-${item.id}`}>

                                        <TPTextBox
                                            id={`language-name-input-${item.id}`}
                                            isMandatory={item.id === TPGlobal.TPClientDefaultLanguage}
                                            labelText={`${nameLabel} (${item.name})`}
                                            value={
                                                insertUpdateState.recordLanguageList[index]
                                                    .value
                                            }
                                            onChange={(e: any) =>
                                                handleLanguageChange(index, e.target.value)
                                            }
                                            maxLength={200}
                                            errorMessage={
                                                insertUpdateState.nameErrorMessages[index]
                                            }
                                            placeholder={nameLanguageLabel}
                                        />

                                    </div>
                                ),
                        )}
                    {insertUpdateState.recordLanguageList.length > 0 && (
                        <>
                            <div className="col-4">
                                <div className="pt-4">
                                    <TPButton
                                        id="open-name-languages"
                                        type={TPButtonTypes.icon}
                                        icon={TPIconTypes.language}
                                        text={`+${insertUpdateState.recordLanguageList.length - 1}`}
                                        tooltip={languageListLabel}
                                        className={"pt-3"}
                                        style={{ marginTop: "-4px", height: "0px" }}
                                        onClick={handleOpenModalLanguageList}
                                    />
                                </div>
                            </div>
                            <TPModalLanguageList
                                id="modal-languages-branch-names"
                                isOpen={isOpenModalLanguageList}
                                title={languageListLabel}
                                acceptLabel={saveButtonLabel}
                                cancelLabel={cancelButtonLabel}
                                saveChanges={handleSaveChangesModalLanguageList}
                                closeModal={handleCloseModalLanguageList}
                            >
                                <div
                                    className="row overflow-auto"
                                    style={{ height: "200px" }}
                                >
                                    {TPGlobal.TPClientAvailableLanguages.map(
                                        (item, index) =>
                                            index > 0 && item.id != TPGlobal.TPClientDefaultLanguage && (
                                                <div
                                                    className="col-12"
                                                    key={`languageItem-${item.id}`}
                                                >
                                                    <div className="form-group" style={{ marginBottom: "0px" }}>
                                                        <TPTextBox
                                                            id={`language-name-${item.id}-input`}
                                                            isMandatory={false}
                                                            labelText={`${nameLabel} (${item.name})`}
                                                            value={
                                                                insertUpdateState.recordLanguageList[
                                                                    index
                                                                ].value
                                                            }
                                                            onChange={(e: any) =>
                                                                handleLanguageChange(
                                                                    index,
                                                                    e.target.value,
                                                                )
                                                            }
                                                            maxLength={200}
                                                            errorMessage={
                                                                insertUpdateState.nameErrorMessages[index]
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            ),
                                    )}
                                </div>
                            </TPModalLanguageList>
                        </>
                    )}
                </TPRow>

                <div className="row">
                    <div className="col-10">
                        <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
                    </div>
                </div>

                {/*<TPRow gridTemplateColumnsValue={"1fr"}  >*/}
                {/*    <div className="form-group d-none">*/}
                {/*        <TPCheckBox*/}
                {/*            id="last-level-check"*/}
                {/*            labelText={isAlphabeticOrderLabel}*/}
                {/*            checked={insertUpdateState.isAlphabeticOrder}*/}
                {/*            onChange={(e: any) => handleIsAlphabeticOrderChange()}*/}
                {/*        ></TPCheckBox>*/}
                {/*    </div>*/}
                {/*</TPRow>*/}

                <TPRow gridTemplateColumnsValue={"1fr"} >
                    <div className="form-group" style={{ marginBottom: "0px" }}>
                        <TPCheckBox
                            id="must-select-check"
                            labelText={mustSelectLastBranchLabel}
                            checked={insertUpdateState.mustSelectLastBranch}
                            onChange={(e: any) =>
                                handleMustSelectLastBranchChange()
                            }
                        ></TPCheckBox>
                    </div>
                </TPRow>

                {/*<TPRow gridTemplateColumnsValue={"1fr"} >*/}
                {/*    <div className="form-group d-none">*/}
                {/*        <TPCheckBox*/}
                {/*            id="is-auto-increment-check"*/}
                {/*            labelText={isAutoincrementLabel}*/}
                {/*            checked={insertUpdateState.isAutoincrement}*/}
                {/*            onChange={(e: any) => handleIsAutoincrementChange()}*/}
                {/*        ></TPCheckBox>*/}
                {/*    </div>*/}
                {/*</TPRow>*/}

                <TPRow gridTemplateColumnsValue={"1fr"}>
                    <div className="form-group" style={{ marginBottom: "0px" }}>
                        <TPCheckBox
                            id="is-classifier-check"
                            labelText={isClassifierLabel}
                            checked={insertUpdateState.isClassifier}
                            onChange={(e: any) => handleIsClassifierChange()}
                        ></TPCheckBox>
                    </div>
                </TPRow>

                <TPRow
                    gridTemplateColumnsValue={"1fr"}
                    paddingRight={"71px"}
                >
                    <TPTextArea
                        id="tree-comments-area"
                        labelText={commentsLabel}
                        value={insertUpdateState.comments}
                        onChange={(e: any) =>
                            handleOnCommentsChange(e.target.value)
                        }
                        maxLength={5000}
                        rows={4}
                        placeholder={insertYourCommentHereLabel}
                        errorMessage={insertUpdateState.commentsErrorMessage}
                    />
                </TPRow>

                <TPRow gridTemplateColumnsValue={"1fr"}>
                    <TPCheckBox
                        id="is-tree-active-check"
                        labelText={isActiveLabel}
                        checked={insertUpdateState.isActive}
                        onChange={(e: any) => handleIsActiveChange()}
                    ></TPCheckBox>
                        </TPRow>

                <div className="row">
                    <div className="col-10">
                        <TPRow
                            gridTemplateColumnsValue={"1fr 80px 80px"}
                            maxWidth={"68%"}
                            paddingRight={"198px"}
                            minWidth={"100%"}
                            gap={"2px"}
                        >
                            <div></div>
                            <TPButton
                                id="cancel-tree"
                                type={TPButtonTypes.empty}
                                onClick={handleCancelButtonClick}
                                isDesignSystem
                                style={{
                                    backgroundColor: "white",
                                    color: "#780096",
                                    paddingLeft: "16px",
                                    paddingRight: "16px",
                                    width: "70px"
                                }}
                            >
                                {cancelButtonLabel}
                            </TPButton>

                            <TPButton
                                id="save-tree"
                                type={TPButtonTypes.primary}
                                onClick={handleOkButtonClick}
                                isDesignSystem
                                disabled={handleValidateActive()}
                                style={{
                                    paddingLeft: "16px",
                                    paddingRight: "16px",
                                    width: "70px"
                                }}
                            >
                                {saveButtonLabel}
                            </TPButton>


                        </TPRow>
                    </div>
                </div>


            </TPColumn>
        </div>
            </div >
    <TPLoadingOverlay active={isLoadingScreen}>
    </TPLoadingOverlay>
        </ContentVerticalTabInsertUpdateStyled >
        //#endregion
    );
};

export default TreesInsertUpdate;
