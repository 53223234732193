import { ReactElement, useEffect, useState } from "react";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { BranchService } from "@/services/BranchService";
import { TPTreeViewStyled } from "./TPTreeViewStyles";
import ExpandLessIcon from "@/assets/images/TPIcons/ExpandLessIcon";
import ExpandMoreIcon from "@/assets/images/TPIcons/ExpandMoreIcon";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "../../models/Global/TPGlobalEnums";
import { FilterTreeStyle, MenuItemTree, ParentTreeStyle, UlTreeStyle } from "../../pages/Trees/Style";
import { TPRow } from "../TPPage/tpPageStyles";
import TPGlobal from "../../helpers/TPGlobal";
import { TPI18N } from "../../services/I18nService";
import SearchInput from "../../modules/core/design-system/inputs/SearchInput";
import TPSelect from "../bootstrap/forms/select/TPSelect";
import { TPKeyValue } from "../../helpers/TPKeyValue";
import TPButton from "../bootstrap/components/buttons/TPButton";
import { OperationModeEnum } from "@/models/Branch/BranchModels";

export interface TPTreeViewDataModel {
    label: string;
    id: string;
    parentId: string;
    hasChild: boolean;
    isExpanded: boolean;
    isActive: boolean;
    isVisible: boolean;
    hierarchyIds: string;
    hierarchyDescription: string;
}

export interface TPTreeViewProps {
    treeId: string;
    selectedNode: string;
    selectedLabel: string;
    selectedHierarchyLabel: string;
    renderParent: string;
    guidTree: string;
    dataSource: Array<TPTreeViewDataModel>;
    mustSelectLastLevelBranch: boolean;
    filterIsActive?: number;
    filterIsVisible?: number;
    isAdminMode?: boolean;
    isFirstUL?: boolean;
    disableMenu?: boolean;
    customStyle?: any;
    onTrySelectLastLevelBranch?: any;
    setIsLoading: Function;
    setDataSource: Function;
    setSelectedNode: Function;
    setSelectedLabel: Function;
    setSelectedHierarchyLabel: Function;
    handleChangeNodeCallBack: Function;
    handleActionTreeView?: ({ action, value }: { action: OperationModeEnum, value: any }) => void;

}

//todo styles
const TPTreeView = ({
    setIsLoading,
    treeId,
    dataSource,
    setDataSource,
    selectedNode,
    setSelectedNode,
    selectedLabel,
    setSelectedLabel,
    selectedHierarchyLabel,
    setSelectedHierarchyLabel,
    renderParent,
    handleChangeNodeCallBack,
    filterIsActive = 1,
    filterIsVisible = 1,
    guidTree,
    isAdminMode = false,
    isFirstUL = false,
    disableMenu = false,
    customStyle,
    mustSelectLastLevelBranch,
    onTrySelectLastLevelBranch = null,
    handleActionTreeView,

}: TPTreeViewProps): ReactElement => {
    const componentFileName = "TPTreeView.tsx";


    const [addRootActive, setAddRootActive] = useState(false);
    const [dataSourceTemp, setDataSourceTemp] = useState(dataSource);
    const [searchPlaceholder, setSearchPlaceholder] = useState<string>("");


    const loadUtilsResources = async () => {

        setSearchPlaceholder(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "Search")
        );
    }

    //collapse
    const handleCollapse = (nodeId: string) => {
        let copyData = [...dataSource];
        let i: number;
        for (i = 0; i <= copyData.length - 1; i++) {
            if (copyData[i].id === nodeId) {
                copyData[i].isExpanded = false;
                break;
            }
        }
        setDataSource(copyData);
    };
    //expand
    const handleExpand = async (item: TPTreeViewDataModel) => {
        let nodeId = item.id;
        let copyData = [...dataSource];
        let i: number;
        let childrenFound: boolean;
        for (i = 0; i <= copyData.length - 1; i++) {
            if (copyData[i].id === nodeId) {
                copyData[i].isExpanded = true;
                break;
            }
        }
        childrenFound = false;
        for (i = 0; i <= copyData.length - 1; i++) {
            if (copyData[i].parentId === nodeId) {
                childrenFound = true;
                break;
            }
        }
        if (!childrenFound && item.hasChild) {
            let childBranches: Array<TPTreeViewDataModel> = [];
            setIsLoading(true);
            childBranches = await loadChildBranches(nodeId);
            for (i = 0; i <= childBranches.length - 1; i++) {
                copyData.push(childBranches[i]);
            }
            setIsLoading(false);
        }
        setDataSource(copyData);
    };

    const loadChildBranches = async (
        nodeId: string,
    ): Promise<Array<TPTreeViewDataModel>> => {
        let serviceClient = new BranchService();
        let expectedCodes: Array<number> = [200];
        let i: number;
        let finalBranches: Array<TPTreeViewDataModel> = [];
        try {
            let responseRequest = await serviceClient.getChildBranches(
                nodeId,
                filterIsActive,
                filterIsVisible,
                false,
                true,
                expectedCodes,
            );
            //transform BranchModel to TPTreeViewDataModel
            for (i = 0; i <= responseRequest.length - 1; i++) {
                let nodeElement: BranchViewModel = responseRequest[i];
                let branchElement: TPTreeViewDataModel = {
                    id: nodeElement.id,
                    label:
                        nodeElement.localizedDescription !== null
                            ? nodeElement.localizedDescription
                            : nodeElement.description,
                    parentId: nodeElement.parentId !== null ? nodeElement.parentId : "",
                    hasChild: isAdminMode
                        ? nodeElement.hasChild
                            ? true
                            : false
                        : nodeElement.hasChildActive && nodeElement.hasChildVisible
                            ? true
                            : false,
                    isExpanded: false,
                    isActive: nodeElement.isActive,
                    isVisible: nodeElement.isVisible,
                    hierarchyIds: nodeElement.hierarchyIds,
                    hierarchyDescription: nodeElement.hierarchyDescription,
                };
                finalBranches.push(branchElement);
            }
            return finalBranches;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} loadChildBranches ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} loadChildBranches ex`);
            return [];
        }
    };

    //change selectedNode
    const handleChangeSelectedNode = (
        nodeId: string,
        label: string,
        hierarchyDescription: string,
        hasChildren: boolean,
    ) => {
        if (mustSelectLastLevelBranch && hasChildren) {
            if (onTrySelectLastLevelBranch) {
                onTrySelectLastLevelBranch();
            }
            return;
        }

        setSelectedNode(nodeId);
        setSelectedLabel(label);
        setSelectedHierarchyLabel(hierarchyDescription);
        handleChangeNodeCallBack(nodeId, label, hierarchyDescription);
    };

    const getItemClass = (nodeId: string, item: TPTreeViewDataModel) => {
        let classes = "";
        classes += item.isActive ? " is-active " : " is-inactive ";
        classes += item.isVisible ? " is-visible " : " is-invisible ";
        classes += nodeId === selectedNode ? " is-selected " : "";
        return classes;
    };

    const getItemStyle = (nodeId: string, item: TPTreeViewDataModel) => {
        let nodeStyle: any = {
            padding: "5px",
            marginLeft: "0px",
            margin: "3px",
            whiteSpace: "nowrap",
            cursor: "pointer",
            borderRadius: "5px",
            display: "flex",
            color: "rgba(0, 0, 0, 1)",
            marginTop: "0px"

        };

        if (nodeId === selectedNode) {
            nodeStyle = {
                ...nodeStyle,
                marginLeft: "0px",
                color: "rgba(0, 0, 0, 1)",
                whiteSpace: "nowrap",
                fontWeight: "500",
                width: "100%"
            };
        }

        //if (!item.isActive && item.isVisible) {
        //    nodeStyle.color = "black";
        //}

        //if (!item.isActive && !item.isVisible) {
        //    nodeStyle.color = "gray";

        //}

        //if (item.isActive && !item.isVisible) {
        //    nodeStyle.color = "yellow";
        //    /* nodeStyle.backgroundColor = "yellow";*/

        //}

        if (!handleIsParentId(item.id)) {
            marginTop: "5px"
        }

        return nodeStyle;
    };

    const handleIsParentId = (id: string) => {
        let exist = dataSource.find(s => s.id == id);
        return (exist?.parentId=="");
    }

    const handleIsActive = (id: string) => {        
        return (id == selectedNode && selectedNode != "");
    }



    //#region Tree view


    const handleActionTree = (action: OperationModeEnum, value: TPTreeViewDataModel) => {
        let item = { action: action, value: value };
        //handleChangeSelectedNode(
        //    item.value.id,
        //    item.value.label,
        //    item.value.hierarchyDescription,
        //    item.value.hasChild,
        //);
       
        if (handleActionTreeView) handleActionTreeView(item);
    }



    //#endregion Tree view




    const getRender = () => {
        const separatorStyle = { paddingLeft: "40px" };
        const separatorTopStyle = { marginTop: "4px" };

        if (dataSource.length === 0) {
            return <></>;
        } else {
            let ulMarginLeft: string;
            if (isAdminMode) {
                if (isFirstUL) {
                    ulMarginLeft = "20px";
                } else {
                    ulMarginLeft = "30px";
                }
            } else {
                ulMarginLeft = "30px";
            }
            return (
                <TPTreeViewStyled
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        width: "100%",
                        listStyleType: "none",
                        borderLeft: "none",
                        paddingLeft: "0px",
                        ...customStyle,
                    }}
                >

                    {dataSource.map((item, index) => {
                        let styleArrowSpan: any;
                        styleArrowSpan = {
                            paddingRight: "0px",
                            pad: "0px",
                            paddingLeft: "15px",
                            whiteSpace: "nowrap",
                        };

                        return item.parentId === renderParent ? (
                            <ParentTreeStyle
                                id={guidTree + "_" + item.id}
                                key={item.id}
                            >
                                <UlTreeStyle
                                    style={(!handleIsParentId(item.id)) ? separatorTopStyle : {}}
                                    id={item.id + "_ul-1"}
                                    active={handleIsActive(item.id)}
                                    disable={!item.isActive}
                                >
                                    <TPRow
                                        alignItems={"center"}
                                        gridTemplateColumnsValue={"10px 100px"}
                                        paddingRight={"15px"}
                                    >
                                        {item.hasChild || (handleIsParentId(item.id)) ? (
                                            item.isExpanded ? (
                                                <span
                                                    onClick={() => { handleCollapse(item.id) }}
                                                    style={styleArrowSpan}
                                                >
                                                    <ExpandLessIcon style={{ cursor: "pointer", color: "trasparent" }} />
                                                </span>
                                            ) : (
                                                <span
                                                    onClick={() => handleExpand(item)}
                                                    style={styleArrowSpan}
                                                >
                                                    <ExpandMoreIcon style={{ cursor: "pointer" }} />
                                                </span>
                                            )
                                        ) : null}
                                        <span
                                            onClick={() =>
                                                handleChangeSelectedNode(
                                                    item.id,
                                                    item.label,
                                                    item.hierarchyDescription,
                                                    item.hasChild,
                                                )
                                            }
                                            style={getItemStyle(item.id, item)}
                                            className={getItemClass(item.id, item)}
                                            id={`${item.id}-branch`}
                                        >
                                            <span style={(!handleIsParentId(item.id)) ? separatorStyle : {}}>{item.label}</span>
                                        </span>
                                    </TPRow>
                                    {handleIsActive(item.id) && (!disableMenu)  &&
                                        <TPRow
                                            width={"auto"}
                                            paddingLeft={"10px"}
                                            alignItems={"center"}
                                            gridTemplateColumnsValue={"20px 20px 20px"}
                                            style={{ marginTop: "-12px" }}
                                            marginRight={"15px"}
                                            gap={"10px"}
                                        >

                                            <TPIcon iconType={TPIconTypes.editV1}
                                                style={{ cursor: "pointer", color: "rgba(46, 46, 46, 1)" }}
                                                onClick={() => {
                                                    handleActionTree(OperationModeEnum.edit, item);
                                                }}
                                            />
                                            <TPIcon iconType={TPIconTypes.copyV1}
                                                style={{ cursor: "pointer", color: "rgba(46, 46, 46, 1)" }} onClick={() => {
                                                    handleActionTree(OperationModeEnum.clone, item);
                                                }}
                                            />
                                            <TPIcon iconType={TPIconTypes.deleteV1}
                                                style={{ cursor: "pointer", color: "rgba(46, 46, 46, 1)" }} onClick={() => { handleActionTree(OperationModeEnum.delete, item); }}
                                            />
                                        </TPRow>
                                    }
                                </UlTreeStyle>

                                {item.hasChild && item.isExpanded && (
                                    <TPTreeView
                                        setIsLoading={setIsLoading}
                                        treeId={treeId}
                                        dataSource={dataSource}
                                        setDataSource={setDataSource}
                                        selectedNode={selectedNode}
                                        setSelectedNode={setSelectedNode}
                                        selectedLabel={selectedLabel}
                                        setSelectedLabel={setSelectedLabel}
                                        selectedHierarchyLabel={selectedHierarchyLabel}
                                        setSelectedHierarchyLabel={setSelectedHierarchyLabel}
                                        renderParent={item.id}
                                        handleChangeNodeCallBack={handleChangeNodeCallBack}
                                        filterIsActive={filterIsActive}
                                        filterIsVisible={filterIsVisible}
                                        guidTree={guidTree}
                                        isAdminMode={isAdminMode}
                                        isFirstUL={false}
                                        mustSelectLastLevelBranch={mustSelectLastLevelBranch}
                                        handleActionTreeView={handleActionTreeView}
                                    />
                                )}
                            </ParentTreeStyle>
                        ) : null;
                    })}
                </TPTreeViewStyled>
            );
        }
    };

    //useEffect(() => {
    //    if (selectedNode) {
    //        handleChangeNodeCallBack(selectedNode, selectedLabel ?? "", selectedHierarchyLabel ?? "");
    //    }
    //}, [selectedNode, selectedLabel, selectedHierarchyLabel]);

    useEffect(() => {
        loadUtilsResources();
    }, []);

    useEffect(() => {
        if (addRootActive) {
            setDataSourceTemp(dataSource);
            setAddRootActive(false);
            console.log("Actualizado", dataSource);
        }
    }, [dataSource]);

    return getRender();
};

export default TPTreeView;
