import { useSelector } from "react-redux";
import { MergeCustomersState } from "../redux/MergeCustomersStore";
import CustomerSearchAccordion from "./CustomerSearchAccordion";
import useLabels from "../styles/labels";
import { useState } from "react";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";

const MergeCustomersSearch = function () {
  const { labels } = useLabels();
  const { currentView } = useSelector(
    (state: MergeCustomersState) => state.mainData,
  );

  const [loadingContent, setLoadingContent] = useState<boolean>(false);

  const setLoading = (data: boolean) => setLoadingContent(data);

  return (
    <>
      {currentView == "search" && (
        <TPLoadingOverlay active={loadingContent}>
          <div style={{ display: "flex", flexDirection: "column", gap: "32px", height: "800px" }}>
            <CustomerSearchAccordion
              type="main"
              id="main"
              title={labels.MainCustomerRecord}
              loadingCallback={setLoading}
            />
            <CustomerSearchAccordion
              type="merge"
              id="merge"
              title={labels.MergeCustomerRecord}
              loadingCallback={setLoading}
            />
          </div>
        </TPLoadingOverlay>
      )}
    </>
  );
};

export default MergeCustomersSearch;
