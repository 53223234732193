import { FC, useEffect, useState } from "react";
import '../../Assets/styles.css';
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ScriptAdditionalDataLogic, ScriptAvailableDataTypes, ScriptLogicStep, ScriptStep } from "@/models/Scripts/ScriptModel";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import FileUploader from "@/components/TPDragAndDropUploadFile/FileUploader";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPGlobal from "@/helpers/TPGlobal";
import { areEqual } from "@/helpers/ValidateChanges";
import TPBranchSelection2, { TPBranchSelectionValue } from "@/components/TPBranchSelection/TPBranchSelection2";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import { AdditionalDataService } from "@/services/AdditionalDataService";
import { AdditionalDataExportModel, AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { BranchService } from "@/services/BranchService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { formatNumberString, parseFormattedNumber } from "@/helpers/FormatNumber";
import { decodeUrl } from "@/helpers/DecodeURL";
import { TPTooltip } from "@/components/TPTooltip/TPTooltip";
import { useScriptsLabels } from "../../Assets/labelling";

const blankBranch: TPBranchSelectionValue = {
  branchDescription: "",
  branchHierarchyDescription: "",
  branchId: ""
}

interface ScriptPreviewTab {
  saveCallback: Function;
  onEditRequest: (stepId: string) => void;
  steps: ScriptStep[];
  scriptId: string;
  scriptName?: string;
}

interface PreviewInput {
  actualValue: any;
  order: number;
  displayValue: any;
  additionalDataId: string;
  logicList: ScriptAdditionalDataLogic[];
  required: boolean;
  error?: string;
}

interface PreviewHistory {
  [stepId: string]: PreviewInput[]
}

interface AdditionalDataMap {
  [key: string]: AdditionalDataExportModel;
}

interface ParameterMap {
  [key: string]: any;
}

interface DataSourcesMap {
  [key: string]: TPKeyValue[];
}

const getFirstStep = function (steps: ScriptStep[]) {
  const start = steps.find(s => s.order == 0);
  if (!start) return null;
  return steps.find(s => s.id == start.defaultNextStepId) || null;
}

const getNextStepById = function (steps: ScriptStep[], nextStepId: string) {
  return steps.find(s => s.id === nextStepId) || null;
}

const getNextStepByOrder = function (steps: ScriptStep[], nextStepOrder: number) {
  return steps.find(s => s.order === nextStepOrder) || null;
}

const createHistory = function (steps: ScriptStep[]) {
  const newHistory: PreviewHistory = {};
  steps.filter(s => s.order > 0).forEach(st => {
    newHistory[st.order] = [
      ...st.additionalData.map(ad => {
        return {
          order: ad.order,
          logicList: ad.logics,
          required: ad.isMandatory,
          additionalDataId: ad.additionalDataId,
          actualValue: "",
          displayValue: "",
          error: ""
        } as PreviewInput
      })
    ];
  })
  return newHistory;
}

export const ScriptPreviewTab: FC<ScriptPreviewTab> = function ({ scriptId, steps, scriptName, onEditRequest }) {

  const [currentStep, setCurrentStep] = useState<ScriptStep | null>(null);
  const [trace, setTrace] = useState<string[]>([]); // Step ids we have visited
  const [history, setHistory] = useState<PreviewHistory>({} as PreviewHistory); // Save values that user typed
  const [additionalDataMap, setAdditionalDataMap] = useState<AdditionalDataMap>({} as AdditionalDataMap);
  const [parametersMap, setParametersMap] = useState<ParameterMap>({} as ParameterMap);
  const [dataSourcesMap, setDataSourcesMap] = useState<DataSourcesMap>({} as DataSourcesMap);
  const [loading, setLoading] = useState(false);
  const { labels } = useScriptsLabels();

  const getInputError = function (input: any, dataType: string, required: boolean, parameters?: any) {

    let error = "";

    if (required && ((input == null) || (input == undefined) || (String(input).trim().length == 0))) return labels.InputRequired;
    if (required && (typeof input == "object" && input.length == 0)) return labels.InputRequired;

    if (input.length === 0) return "";

    switch (dataType) {
      case (ScriptAvailableDataTypes.EMAIL):
        if (!String(input).match(TPGlobal.regularExpressionCheckEmail)) {
          error = labels.InvalidEmail;
        }
        break;
      case (ScriptAvailableDataTypes.NUMERIC):
        if (Number.isNaN(Number(input))) {
          error = labels.InvalidNumber;
        } else if (parameters) {
          const n = Number(input);
          if (parameters.minValue && n < parameters.minValue) {
            error = String(labels.InputGtEq).concat(" ", String(parameters.minValue));
          } else if (parameters.maxValue && n > parameters.maxValue) {
            error = String(labels.InputLtEq).concat(" ", String(parameters.maxValue));
          }
        }
        break;
      case (ScriptAvailableDataTypes.PHONE):
        if (parameters) {
          if (parameters.phoneRegEXPRE && !String(input).match(parameters.phoneRegEXPRE || "")) {
            error = labels.InvalidPhone;
          } else if (parameters.maxLength && String(input).length > parameters.maxLength) {
            error = String(labels.PhoneMaxLength).concat(" ", String(parameters.maxLength));
          } else if (parameters.minLength && String(input).length < parameters.minLength) {
            error = String(labels.PhoneMinLength).concat(" ", String(parameters.minLength));
          } else {
            if (parameters.type == "WhiteList") {
              const validChars = new RegExp(`^[${String(parameters.whiteList.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')).trim() || ""
                }]*$`);
              if (!validChars.test(String(input))) {
                error = labels.PhoneWhitelist
              }
            }
          }
        }
        break;
    }

    return error;
  }

  const getAdditionalDataTypes = async function (dataIds: string[]) {
    if (dataIds.length == 0) return;
    setLoading(true);
    const addDataServiceInstance = new AdditionalDataService();
    const data = await addDataServiceInstance.getDataByIds(dataIds, false, true, [200])

    if (data) {
      const newAddDataMap: AdditionalDataMap = {};
      const newParameterMap: ParameterMap = {};
      data.forEach(d => {
        const dataCopy = d;
        if (d.jsonParametersUtf8) {
          dataCopy.jsonParametersUtf8 = TPGlobal.uint8ArrayToString(d.jsonParametersUtf8) || "";
          newParameterMap[d.id] = JSON.parse(dataCopy.jsonParametersUtf8 || "");
          if (dataCopy.additionalDataTypeId == ScriptAvailableDataTypes.LISTVALUE) {
            getDataSource(dataCopy.id, JSON.parse(dataCopy.jsonParametersUtf8).parentId);
          }
        }
        newAddDataMap[d.id] = dataCopy;
      });
      setAdditionalDataMap(newAddDataMap);
      setParametersMap(newParameterMap);
    }
    setLoading(false);
  }

  const getDataSource = async function (addDataId: string, branchId: string) {
    if (!addDataId || !branchId) return;
    const branchServiceInstance = new BranchService();
    branchServiceInstance.getChildBranches(branchId, 1, 1, false, false, [200, 404])
      .then(source => {
        const dataSourcesCopy = dataSourcesMap;
        dataSourcesCopy[addDataId] = source.map(s => (
          {
            key: s.id,
            value: s.localizedDescription || s.description
          }
        ));
        setDataSourcesMap({ ...dataSourcesCopy });
      })
  }

  const proceed = function () {
    if (!currentStep) return;
    let nextStepId = currentStep.defaultNextStepId;

    const inputs = history[currentStep.order];
    inputs?.forEach(input => {
      const match = input.logicList.find(l => areEqual(l.key_SSDA, input.actualValue));
      if (match) { nextStepId = getNextStepById(steps, match.nextStepId)?.id || ""; }
    })

    if (nextStepId == "0") {
      nextStepId = getNextStepByOrder(steps, -1)?.id || "";
    }

    if (nextStepId && nextStepId != "0") {
      setTrace([...trace, nextStepId]);
      setCurrentStep(getNextStepById(steps, nextStepId));
    }
  }

  const goBack = function (step: ScriptStep) {
    if (!step) return;
    setCurrentStep(step);
    const newTrace = trace;
    newTrace.splice(trace.indexOf(step.id));
    setTrace([...newTrace, step.id]);
  }

  useEffect(() => {
    if (steps) {
      const firstStep = getFirstStep(steps);
      if (firstStep) {
        setCurrentStep(firstStep);
        setTrace([firstStep.id]);
      }
      setHistory(createHistory(steps));
      const ids: string[] = [];
      steps.forEach(s => {
        s.additionalData.forEach(a => {
          ids.push(a.additionalDataId)
        })
      });
      getAdditionalDataTypes(ids);
    }
  }, [])

  const handleCurrentFormChange = function (dataOrder: number, actualValue: any, displayValue: any) {
    if (!currentStep) return;
    const target = history[currentStep.order].find(d => d.order == dataOrder);
    if (!target) return;

    const targetAdditionalData = additionalDataMap[target.additionalDataId];
    const targetParameters = parametersMap[target.additionalDataId];

    target.displayValue = displayValue;
    target.actualValue = actualValue;

    if (targetAdditionalData && targetParameters) {
      if (targetAdditionalData.additionalDataTypeId == ScriptAvailableDataTypes.NUMERIC) {
        target.actualValue = parseFormattedNumber(target.actualValue, {
          currencySymbol: targetParameters.currencySymbol || "",
          decimalSeparator: targetParameters.decimalSeparator || "",
          thousandsSeparator: targetParameters.thousandsSeparator || ""
        });
        target.displayValue = formatNumberString(String(target.actualValue), {
          currencyPlacement: targetParameters.currencyPlacement || "",
          currencySymbol: targetParameters.currencySymbol || "",
          decimalSeparator: targetParameters.decimalSeparator || "",
          fixedDecimal: targetParameters.fixedDecimal || "",
          maxValue: targetParameters.maxValue || "",
          minValue: targetParameters.minValue || "",
          percentage: targetParameters.percentage || false,
          thousandsSeparator: targetParameters.thousandsSeparator || "",
        })
      }
    }

    target.error = getInputError(
      target.actualValue,
      targetAdditionalData ? targetAdditionalData.additionalDataTypeId : "",
      target.required,
      targetParameters ? targetParameters : null
    );

    const newHistory = structuredClone(history);
    let newTarget = newHistory[currentStep.order].find(d => d.order == dataOrder);
    newTarget = { ...target };
    setHistory(newHistory);
  }

  const isCurrentFormValid = function () {
    let isValid = true;
    if (!currentStep) return false;
    const inputs = history[currentStep.order];
    inputs.forEach(entry => {
      if (additionalDataMap[entry.additionalDataId] && getInputError(
        entry.actualValue,
        additionalDataMap[entry.additionalDataId].additionalDataTypeId,
        entry.required,
        parametersMap[entry.additionalDataId]
      ).trim().length != 0)
        isValid = false;
    })
    return isValid;
  }

  

  return (
    <TPLoadingOverlay active={loading}>
    <div className="preview-container">
      <div className="preview-info">
        <TPIcon style={{ fontSize: '20px' }} iconType={TPIconTypes.alert} />
          <div>{labels.ThisIsOnlyAPreview}</div>
      </div>
      <b style={{ fontSize: '16px' }}>{scriptName || ""}</b>
        {currentStep && steps.filter(st => (Number(st.id) != 0) && st.order > 0 && trace.includes(st.id)).map((step) => (
        <div key={step.id} className="outer-preview-form" >
          <div className="step-title">
            <b>{step.order > 0 && `${step.order}.`}</b>
            <b dangerouslySetInnerHTML={{ __html: step.description }} />
            <div className="step-buttons-container">
                {step.helpText && (
                  <TPTooltip text={step.helpText} isRichText width={"512px"} backgroundColor="#d8d2dd" foregroundColor="black">
                    <TPIcon style={{ fontSize: '20px', color: "#780096" }} iconType={TPIconTypes.info} />
                  </TPTooltip>
                )}
                {step.id != currentStep.id && (
                  <button type="button" className="step-edit-button" onClick={() => goBack(step)}>
                  <TPIcon iconType={TPIconTypes.edit} />
                  </button>
                )}
            </div>
          </div>
          <div className="preview-form-row">
              {step.additionalData
              .sort((dA, dB) => {
                if (dA.order < dB.order) return -1;
                if (dA.order > dB.order) return 1;
                return 0;
              })
              .map((data, idx) => {
              const mappedData = additionalDataMap[data.additionalDataId];
              const additionalDataParameters = parametersMap[data.additionalDataId];
              const targetDataSource = dataSourcesMap[data.additionalDataId];

                const controlData = history[step.order].find(m => m.order == data.order);
              return (
                <>
                  {mappedData?.additionalDataTypeId == ScriptAvailableDataTypes.DATE && (
                    <>
                      {(data.isAddNewLine && (idx != 0)) && <div className="line-breaker" />}
                      <div className="preview-input">
                        <TPDatePicker
                          isMandatory={data.isMandatory}
                          labelText={data.isWhiteSpace ? "" : data.label}
                          selectedDate={controlData?.actualValue ? new Date(controlData.actualValue) : null}
                          onChangeDate={(date: Date) =>
                            handleCurrentFormChange(data.order, date?.toDateString() || "", date?.toDateString() || "")
                          }
                          errorMessage={controlData?.error}
                          disabled={step.order !== currentStep.order}
                          minDate={additionalDataParameters.minDate}
                          maxDate={additionalDataParameters.maxDate}
                        />
                      </div>
                    </>)}
                  {mappedData?.additionalDataTypeId == ScriptAvailableDataTypes.YESNO &&
                    <>
                    {Boolean(data.isAddNewLine && idx != 0) && <div className="line-breaker" />}
                      <div className="preview-input">
                      {!data.isWhiteSpace && (
                        <label style={{ fontSize: '12px' }}>
                          {data.label}
                          {data.isMandatory && <b style={{ color: 'red' }}>*</b>}
                        </label>
                      )}
                        <TPRadioGroup
                        id={String(currentStep.id).concat("-",data.id)}
                          disabled={step.order !== currentStep.order}
                          source={[
                            { key: "yes", value: labels.Yes },
                            { key: "no", value: labels.No },
                          ]}
                        value={controlData?.actualValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleCurrentFormChange(data.order, e.target.value, e.target.value)
                        }
                        errorMessage={controlData?.error}
                        />
                      </div>
                    </>}
                  {mappedData?.additionalDataTypeId == ScriptAvailableDataTypes.ATTACHMENT &&
                    <>
                    {data.isAddNewLine && (idx != 0) && <div className="line-breaker" />}
                      <div className="preview-input">
                      {!data.isWhiteSpace && (
                        <label style={{ fontSize: '12px' }}>
                          {data.label}
                          {data.isMandatory && <b style={{ color: 'red' }}>*</b>}
                        </label>
                      )}
                        <FileUploader
                        height={"min-content"}
                        UploadButtonText={labels.SelectFile}
                        fileOrFiles={controlData?.actualValue}
                        onSelect={f => handleCurrentFormChange(data.order, (f as File[])[0], (f as File[])[0].name)}
                          disabled={step.order !== currentStep.order}
                        />
                      <label>{labels.File}: {controlData?.displayValue}</label>
                      <b className="invalid-script-step-data-input">{controlData?.error}</b>
                      </div>
                    </>}
                  {mappedData?.additionalDataTypeId == ScriptAvailableDataTypes.EMAIL && (
                    <>
                      {data.isAddNewLine && (idx != 0) && <div className="line-breaker" />}
                      <div className="preview-input">
                        <TPTextBox
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleCurrentFormChange(data.order, e.target.value, e.target.value)}
                          value={controlData?.actualValue}
                          labelText={data.isWhiteSpace ? "" : data.label}
                          isMandatory={data.isMandatory}
                          errorMessage={controlData?.error}
                          disabled={step.order !== currentStep.order}
                        />
                      </div>
                    </>)}
                  {mappedData?.additionalDataTypeId == ScriptAvailableDataTypes.LABEL && (
                    <>
                      {data.isAddNewLine && (idx != 0) && <div className="line-breaker" />}
                      <div className="preview-input">
                        <TPTextBox
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleCurrentFormChange(data.order, e.target.value, e.target.value)}
                          value={controlData?.actualValue}
                          labelText={data.isWhiteSpace ? "" : data.label}
                          isMandatory={data.isMandatory}
                          errorMessage={controlData?.error}
                          disabled={step.order !== currentStep.order}
                        />
                      </div>
                    </>)}
                  {mappedData?.additionalDataTypeId == ScriptAvailableDataTypes.LINK && (
                    <>
                      {data.isAddNewLine && (idx != 0) && <div className="line-breaker" />}
                      <div className="preview-input">
                        {!data.isWhiteSpace && (
                          <label style={{ fontSize: '12px' }}>
                            {data.label}
                            {data.isMandatory && <b style={{ color: 'red' }}>*</b>}
                          </label>
                        )}
                        <a
                          style={{
                            alignSelf: "flex-end",
                            justifySelf: "flex-end",
                            color: "#780096"
                          }}
                          href={decodeUrl(additionalDataParameters.url) || ""}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => handleCurrentFormChange(data.order, "ok", "ok")}
                        >
                          {decodeUrl(additionalDataParameters.url)}
                        </a>
                      </div>
                    </>)}
                  {mappedData?.additionalDataTypeId == ScriptAvailableDataTypes.NUMERIC && (
                    <>
                      {data.isAddNewLine && (idx != 0) && <div className="line-breaker" />}
                      <div className="preview-input">
                        <TPTextBox
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleCurrentFormChange(data.order, e.target.value, e.target.value)}
                          value={controlData?.displayValue || controlData?.actualValue}
                          labelText={data.isWhiteSpace ? "" : data.label}
                          isMandatory={data.isMandatory}
                          errorMessage={controlData?.error}
                          disabled={step.order !== currentStep.order}
                        />
                      </div>
                    </>)}
                  {mappedData?.additionalDataTypeId == ScriptAvailableDataTypes.PHONE && (
                    <>
                      {data.isAddNewLine && (idx != 0) && <div className="line-breaker" />}
                      <div className="preview-input">
                        <TPTextBox
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleCurrentFormChange(data.order, e.target.value, e.target.value)}
                          value={controlData?.actualValue}
                          labelText={data.isWhiteSpace ? "" : data.label}
                          isMandatory={data.isMandatory}
                          errorMessage={controlData?.error}
                          disabled={step.order !== currentStep.order}
                        />
                      </div>
                    </>)}
                  {mappedData?.additionalDataTypeId == ScriptAvailableDataTypes.INTEGERLIST && (
                    <>
                      {data.isAddNewLine && (idx != 0) && <div className="line-breaker" />}
                      <div className="preview-input">
                        <TPSelect
                          dataSource={
                            [
                              ...Array.from({
                                length: (additionalDataParameters.endValue || 0) - (additionalDataParameters.initialValue || 0)
                              },
                                (_, i) => i + 1).map(n => ({ key: String(n), value: n })),
                              { key: "", value: "--" }
                            ]}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleCurrentFormChange(data.order, e.target.value, e.target.value)}
                          value={controlData?.actualValue}
                          labelText={data.isWhiteSpace ? "" : data.label}
                          isMandatory={data.isMandatory}
                          errorMessage={controlData?.error}
                          disabled={step.order !== currentStep.order}
                        />
                      </div>
                    </>)}
                  {mappedData?.additionalDataTypeId == ScriptAvailableDataTypes.TEXTAREA && (
                    <>
                      {data.isAddNewLine && (idx != 0) && <div className="line-breaker" />}
                      <div className="preview-input">
                        <TPTextArea
                          rows={5}
                          textAreaStyle={{ minHeight: '128px', maxHeight: '128px' }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleCurrentFormChange(data.order, e.target.value, e.target.value)}
                          value={controlData?.actualValue}
                          labelText={data.isWhiteSpace ? "" : data.label}
                          isMandatory={data.isMandatory}
                          errorMessage={controlData?.error}
                          disabled={step.order !== currentStep.order}
                        />
                      </div>
                    </>)}
                  {mappedData?.additionalDataTypeId == ScriptAvailableDataTypes.TEXTBOX && (
                    <>
                      {data.isAddNewLine && (idx != 0) && <div className="line-breaker" />}
                      <div className="preview-input">
                        <TPTextBox
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleCurrentFormChange(data.order, e.target.value, e.target.value)}
                          value={controlData?.actualValue}
                          labelText={data.isWhiteSpace ? "" : data.label}
                          isMandatory={data.isMandatory}
                          errorMessage={controlData?.error}
                          disabled={step.order !== currentStep.order}
                        />
                      </div>
                    </>)}
                  {mappedData?.additionalDataTypeId == ScriptAvailableDataTypes.TREE && (
                    <>
                      {data.isAddNewLine && (idx != 0) && <div className="line-breaker" />}
                      <div className="preview-input">
                        {!data.isWhiteSpace && (
                          <label style={{ fontSize: '12px' }}>
                          {data.label}
                          {data.isMandatory && <b style={{ color: 'red' }}>*</b>}
                        </label>
                        )}
                        <TPBranchSelection2
                          emptyLabel={labels.NoTrees}
                          modalAcceptLabel={labels.Ok}
                          modalCancelLabel={labels.Cancel}
                          modalSelectedBranchLabel=""
                          disabledTextBox={step.order !== currentStep.order}
                          modalTitle={labels.SelectTree}
                          mustSelectLastLevelBranch={false}
                          onChange={(brId: string, brDesc: string, brHieDesc: string) =>
                            handleCurrentFormChange(data.order,
                              brId,
                              {
                                branchDescription: brDesc,
                                branchHierarchyDescription: brHieDesc,
                                branchId: brId
                              } as TPBranchSelectionValue)}
                          treeId={additionalDataParameters?.treeId || ""}
                          value={controlData?.displayValue || structuredClone(blankBranch)}
                          autoCloseTreeModalWhenSelect
                        />
                        {controlData?.error && (
                          <p className="invalid-script-step-data-input">{controlData.error}</p>
                        )}
                      </div>
                    </>)}
                  {mappedData?.additionalDataTypeId == ScriptAvailableDataTypes.LISTVALUE && (
                    <>
                      {data.isAddNewLine && (idx != 0) && <div className="line-breaker" />}
                      <div className="preview-input">
                        {!data.isWhiteSpace && (
                          <label style={{ fontSize: '12px' }}>
                          {data.label}
                          {data.isMandatory && <b style={{ color: 'red' }}>*</b>}
                        </label>
                        )}
                        {additionalDataParameters &&
                          (additionalDataParameters.renderMethod == "Combobox") && (
                          <TPSelect
                            dataSource={targetDataSource ? [
                              ...targetDataSource,
                              {
                                key: "",
                                value: "--"
                              }
                            ] : [{ key: "", value: "--" }]}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleCurrentFormChange(data.order, e.target.value, e.target.value)
                            }
                            value={controlData?.actualValue}
                            isMandatory={data.isMandatory}
                            disabled={step.order !== currentStep.order}
                            />)}
                        {additionalDataParameters &&
                          (additionalDataParameters.renderMethod == "Option") && (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                          <TPRadioGroup
                              id={String(currentStep.id).concat("-",data.id)}
                              source={targetDataSource || []}
                              value={controlData?.actualValue}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleCurrentFormChange(data.order, e.target.value, e.target.value)
                              }
                            disabled={step.order !== currentStep.order}
                                isHorizontal={false}
                              />
                            </div>)}
                        {additionalDataParameters &&
                          (additionalDataParameters.renderMethod == "Checkbox") && (
                          <div style={{ display: 'flex', flexDirection: 'column', gap: "4px" }}>
                            {targetDataSource?.map((item) => (
                              <TPCheckBox
                                key={item.key}
                                checked={typeof controlData?.actualValue == "object" ? controlData?.actualValue.find((v: any) => v == item.key) : false}
                                labelText={item.value}
                                disabled={step.order !== currentStep.order}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  handleCurrentFormChange(
                                    data.order,
                                    e.target.checked ?
                                      [...controlData?.actualValue, item.key]
                                      :
                                      [...controlData?.actualValue.filter((d: any) => d != item.key)],
                                    ""
                                  )
                                }}
                              />
                            ))}
                            </div>)}
                        {controlData?.error && (
                          <p className="invalid-script-step-data-input">{controlData.error}</p>
                        )}
                      </div>
                    </>)}
                </>
              )
            })}
          </div>
          {currentStep && (currentStep.order == step.order) && (currentStep.order >= 0) &&
            <TPButton
              onClick={() => proceed()}
              isDesignSystem
              style={{
                paddingLeft: '16px',
                paddingRight: '16px',
                alignSelf: 'flex-end',
                right: 0
              }}
                disabled={!isCurrentFormValid()}
            >
                {labels.Next}
            </TPButton>}
        </div>
      ))}
      {currentStep?.order === -1 &&
        <div className="outer-preview-form">
          <div style={{ alignSelf: 'flex-start', display: 'flex' }}>
            <b dangerouslySetInnerHTML={{ __html: steps.find(s => s.order == -1)?.description || "" }} />
          </div>
        </div>}

    </div>
    </TPLoadingOverlay>
  )
}