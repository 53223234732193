import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ReactElement } from "react";
import { exportDataToExcel } from "../../utils/export-utils";
import { camelCaseToId } from "../../utils/text-regex";
import { StyledTableIcon } from "./dynamic-table-styles";
import { CustomColumnNames, ExportCustomValues } from "./DynamicTable";

type TableIconsProps<T extends object> = {
  id?: string;
  icons?: TableIcon[];
  exportData?: T[];
  hiddenColumns?: (keyof T)[];
  visibleColumns?: (keyof T)[];
  columnNames?: CustomColumnNames<T>;
  exportCustomValues?: ExportCustomValues<T>;
  hideExport?: boolean;
  hideRefresh?: boolean;
  exportLabel: string;
  refreshLabel: string;
  exportNameFile?: string;
  onIconClick?: (item: TPIconTypes) => void;
  overrideExport?: boolean;
};

export type TableIcon = {
  type: TPIconTypes;
  status: boolean;
  tooltip?: string;
};

export interface CaseIcon extends TableIcon {
  onIconClick: () => void;
}

function TableIcons<T extends object>({
  id,
  icons,
  exportData,
  hiddenColumns,
  visibleColumns,
  columnNames,
  exportCustomValues,
  hideExport,
  hideRefresh,
  exportLabel,
  refreshLabel,
  exportNameFile,
  onIconClick = () => { },
  overrideExport,
}: TableIconsProps<T>): ReactElement {
  const fileType: string =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const defaultIcons: TableIcon[] = [
    ...(icons ?? []),
    {
      type: TPIconTypes.excel,
      tooltip: exportLabel,
      status: !hideExport,
    },
    { type: TPIconTypes.loop, tooltip: refreshLabel, status: !hideRefresh },
  ];

  const getIconId = (iconType: TPIconTypes): string | undefined => {
    return id && iconType
      ? `${id}-${camelCaseToId(iconType)}-button`
      : undefined;
  };

  const handleManageIconAction = (type: TPIconTypes) => {
    if (type !== TPIconTypes.excel || overrideExport) {
      return onIconClick(type);
    }

    exportTableToExcel();
  };

  const getFileName = () => {
    return `${exportNameFile || (id ? `${id}-table-data` : "table-data")}.xlsx`;
  };

  const getFilteredTableByVisibility = () => {
    if (!exportData || !visibleColumns) {
      return [];
    }

    return exportData.map((item) => {
      const filteredItem: Partial<T> = {};

      visibleColumns
        .filter((key) => !hiddenColumns?.includes(key as keyof T))
        .forEach((column) => {
          const customColumnName = columnNames?.[column as keyof T];
          const customValue = exportCustomValues?.[column as keyof T]?.({
            value: item[column],
            item,
          });

          if (isCustomColumnsEnabled(column) || isCustomColumnValue(column)) {
            const columnKey = customColumnName
              ? (customColumnName as keyof T)
              : column;

            filteredItem[columnKey] = customValue
              ? (customValue as T[keyof T])
              : item[column];
          } else {
            filteredItem[column] = item[column];
          }
        });

      return filteredItem;
    });
  };

  const isCustomColumnsEnabled = (key: keyof T): boolean => {
    return (columnNames && columnNames[key] !== undefined) || false;
  };

  const isCustomColumnValue = (key: keyof T): boolean => {
    return (
      (exportCustomValues && exportCustomValues[key] !== undefined) || false
    );
  };

  const exportTableToExcel = () => {
    if (exportData && exportData.length > 0) {
      exportDataToExcel(getFilteredTableByVisibility(), getFileName());
    } else {
      console.error("No data for export");
    }
  };

  return (
    <StyledTableIcon>
      {defaultIcons
        .filter((icon) => icon.status)
        .map((icon, index) => (
          <TPIcon
            key={index}
            id={getIconId(icon.type)}
            iconType={icon.type}
            tooltip={icon.tooltip}
            isTooltip={icon.tooltip ? true : false}
            onClick={() => handleManageIconAction(icon.type)}
          />
        ))}
    </StyledTableIcon>
  );
}

export default TableIcons;
