import React, { useEffect, useRef, useState } from "react";

import ParentFolder from "./ImagesAdminParentFolder";
import {
    FileDivStyle,
    ContainerImageStyle,
    ImageStyle
} from "./StyleImageAdmin";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import { OperationMode } from "./useStatusImagesAdmin";
import { ImagesAdminPopupMenu } from "./ImagesAdminPopupMenu";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";
import { TPI18N } from "@/services/I18nService";
import { StyledDisabledCheck, StyledTableCell } from "../../modules/core/components/dynamic-table/dynamic-table-styles";
import TPCheckBox from "../../components/bootstrap/forms/checkbox/TPCheckBox";
import TPButton from "../../components/bootstrap/components/buttons/TPButton";
import { TPIconTypes } from "../../models/Global/TPGlobalEnums";
import TPIcon from "../../components/bootstrap/extend/TPIcons/TPIcon";
import TableHead from "@mui/material/TableHead/TableHead";
import TPGlobal from "../../helpers/TPGlobal";

type ContainerFolderProps = {
    file: RecursiveRoutes;
    mode?: string;
    action: Function
};



const ImagesAdminFile: React.FC<ContainerFolderProps> = ({ file, mode, action }: ContainerFolderProps) => {
    const componentFileName: string = "ImagesAdminFile.tsx";
    const resourceSet: string = "ImagesAdminComponent";
    const resourceSetAdmin: string = "ImagesAdmin";
    const resourceSetUpload: string = "DragFileModal";

    const [editName, setEditName] = React.useState<string>("");

    //Label
    const [minorOptionsLabel, setMinorOptionsLabel] = useState<string>("");
    const [namePopuLabel, setNamePopuLabel] = useState("");
    const [sizePopuLabel, setSizePopuLabelLabel] = useState("");
    const [dimensionPopuLabel, setDimensionPopuLabel] = useState("");
    const [keyWordsPopuLabel, setKeyWordsLabel] = useState("");
    const [creationDatePopuLabel, setCreationDatePopuLabel] = useState("");

    useEffect(() => {
        loadResourcesAndOrganizations();
    }, []);

    const loadResourcesAndOrganizations = async () => {
        setNamePopuLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Name"));
        setSizePopuLabelLabel(await TPI18N.GetText(resourceSetUpload, "MaxSizeLabel"));
        setDimensionPopuLabel(
            await TPI18N.GetText(resourceSet, "DimensionPopuLabel"),
        );
        setKeyWordsLabel(await TPI18N.GetText(resourceSetAdmin, "keywordsLabel"));
        setCreationDatePopuLabel(
            await TPI18N.GetText(resourceSet, "CreationDatePopuLabel"),
        );
        setMinorOptionsLabel(
            await TPI18N.GetText("FormDesignerComponent", "ActionColumnLabel")
        );
    };

    const value = useAppConctextImageAdmin();
    const {
        handleSelected,
        handleUpdateFolder,
        handleMultiSelectedFile,
        handleMultiSelectedFileClear,
        handleAllSelectedFile,
        listFilesModel,
        selectedFolderParent,
        selectedItem,
        operationMode,
        handleMultiSelectedFileAll,
        listMultiFileSelected,
        modeSelect
    } = value;


    const Cancel = () => {
        if (listMultiFileSelected.length <= 0) {
            handleSelected(selectedFolderParent, OperationMode.ActiveFolder);
        } else {
            handleMultiSelectedFileClear();
        }
    };

    const handleSeletedFileAction = (fileAction: RecursiveRoutes) => {
        if (listMultiFileSelected.length <= 0) {
            action("Select");
            Cancel();
            handleSelected(fileAction, OperationMode.SelectFile);
        }
    };


    const handleMultiSelectedFileAction = (e: any) => {
        e.preventDefault();
        let temp = [...listMultiFileSelected];
        if (!e.target.checked)
            temp = [...listMultiFileSelected].filter(s => s.id != file.id);
        else
            temp.push(file);
        handleMultiSelectedFileAll(temp);
    };


    const DropdownMenu = ({ action }: any): JSX.Element => {
        const [isOpen, setIsOpen] = useState(false);

        const toggleMenu = () => setIsOpen((prev) => !prev);

        const options: any[] = [
            {
                key: "edit",
                label: "Update",
                icon: <TPIcon iconType={TPIconTypes.edit} />,
                action: () => action("Edit"),
            },
            {
                key: "delete",
                label: "Delete",
                icon: <TPIcon iconType={TPIconTypes.delete} />,
                action: () => action("Delete"),
            },
        ];

        return (

            <div style={{ position: "relative", display: "inline-block" }}>
                {
                    isOpen && (
                        <div style={{
                            height: "100vh",
                            width: "100vw",
                            position: "fixed",
                            zIndex: "1",
                            top: "0",
                            left: "0"
                        }}
                            onClick={() => { setIsOpen(false); }}
                        ></div>
                    )}

                <button
                    style={{
                        background: "none",
                        border: "none",
                        fontSize: "24px",
                        cursor: "pointer",
                    }}
                    onClick={toggleMenu}
                >
                    &#x22EE;
                </button>

                {
                    isOpen && (
                        <div
                            style={{
                                position: "absolute",
                                top: "30px",
                                right: "-90",
                                background: "#fff",
                                border: "1px solid #ddd",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                borderRadius: "4px",
                                zIndex: 10000,
                                overflow: "hidden",
                            }}
                        >
                            {options.map((option) => (
                                <div
                                    key={option.key}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "8px 16px",
                                        cursor: "pointer",
                                        backgroundColor: "#f8f9fa",
                                        transition: "background-color 0.3s",
                                    }}
                                    onClick={() => {
                                        option.action();
                                        setIsOpen(false);
                                    }}
                                    onMouseOver={(e) =>
                                        (e.currentTarget.style.backgroundColor = "#f1c7d9")
                                    }
                                    onMouseOut={(e) =>
                                        (e.currentTarget.style.backgroundColor = "#f8f9fa")
                                    }
                                >
                                    <span style={{ marginRight: "8px" }}>{option.icon}</span>
                                    <span>{option.label}</span>
                                </div>
                            ))}
                        </div>
                    )
                }
            </div >
        );
    };

    return (
        <>
            <FileDivStyle
            >
                <ContainerImageStyle
                >
                    {mode != "view" &&
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "30px",
                        padding: "4px",
                        alignItems: "center"
                    }}>

                        {(modeSelect != true || mode == "edit") ?
                            <TPCheckBox
                                id={`img-file-head-check`}
                                checked={
                                    listMultiFileSelected.find((s) => s.id == file.id) != undefined
                                }
                                onChange={handleMultiSelectedFileAction}
                            />
                            :
                            <div></div>
                        }
                       
                            <DropdownMenu action={(e: any, id: string) => { if (action && (modeSelect != true || mode == "edit")) action(e, file) }} />

                    </div>
                        }
                    <ImageStyle
                        onClick={() => {
                            handleSeletedFileAction(file);
                        }}
                        src={file.thumbnailUrl}
                        alt={file.fileName}
                        style={file.isActive ? {} : { filter: "grayscale(1)" }}
                    ></ImageStyle>
                </ContainerImageStyle>


                {/*<PopupDetailDivStyle>*/}
                {/*    <p>*/}
                {/*        {namePopuLabel} : {file.fileName}*/}
                {/*        <br />*/}
                {/*        {sizePopuLabel}: {file.size}*/}
                {/*        <br />*/}
                {/*        {dimensionPopuLabel}: {file.dimensions}*/}
                {/*        <br />*/}
                {/*        {keyWordsPopuLabel}: {file.keywords}*/}
                {/*        <br />*/}
                {/*        {creationDatePopuLabel}: {file.dateUpload}{" "}*/}
                {/*    </p>*/}
                {/*</PopupDetailDivStyle>*/}

                {/*{selectedItem.id != null &&*/}
                {/*    selectedItem.id === file.id &&*/}
                {/*    operationMode == OperationMode.EditFile &&*/}
                {/*    listMultiFileSelected.length <= 0 ? (*/}
                {/*    <NameFolderInputStyle*/}
                {/*        type="text"*/}
                {/*        placeholder={file.fileName.trim()}*/}
                {/*        onChange={(e) => {*/}
                {/*            setEditName(e.target.value);*/}
                {/*        }}*/}
                {/*        width={"100px"}*/}
                {/*    />*/}
                {/*) : (*/}
                {/*    <NameFolderPStyle*/}
                {/*        onClick={() => {*/}
                {/*            handleSeletedFileAction(file);*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        {" "}*/}
                {/*        {file.fileName}*/}
                {/*    </NameFolderPStyle>*/}
                {/*)}*/}
            </FileDivStyle>
        </>
    );
};

export default ImagesAdminFile;
