import allThemes from "@/assets/styles/theme";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { FC, useEffect, useRef, useState } from "react";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import "./TPCustomAutocompleteStyles.css";
import { CustomSpan } from "../misc/CustomSpan";

interface TPCustomAutocompleteProperties {
  key?: string;
  input: string;
  onQuery: (input: string) => void;
  children: React.ReactElement[];
  suggestionTimeout?: number;
  title?: string;
  placeholder?: string;
  mandatory?: boolean;
  disabled?: boolean;
  focused?: string;
  dropdownWidth?: string | number;
  icon?: TPIconTypes;
  onIconClick?: Function;
  errorMessage?: string;
  className?: string;
  onBlur?: Function;
}

const TPCustomAutocomplete: FC<TPCustomAutocompleteProperties> = function ({
  key,
  input,
  onQuery,
  children,
  suggestionTimeout,
  placeholder,
  title,
  mandatory,
  disabled,
  focused,
  dropdownWidth,
  icon,
  onIconClick,
  errorMessage,
  className = "",
  onBlur
}) {
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const autocompleteRef = useRef<any>(null);

  useEffect(() => {
    if (focused == "off") {
      setFocus(false);
      setShowSuggestion(false);
    }
  }, [focused]);

  useEffect(() => {
    if (focus) setShowSuggestion(true);

    const intervalId = setTimeout(() => {
      if (!focus) {
        setShowSuggestion(false);
        if (onBlur) onBlur();
      }
    }, suggestionTimeout ?? 200);

    return () => clearInterval(intervalId);
  }, [focus]);

  const handleClickOutside = function (e: MouseEvent) {
    if (
      autocompleteRef.current &&
      !autocompleteRef.current.contains(e.target)
    ) {
      setShowSuggestion(false);
      setFocus(false);
    }
  };

  const getErrorColor = function() {
    return errorMessage ? "red" : "gainsboro";
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`${errorMessage !== "" ? "is-invalid" : ""} ${className}`}
    >
      <div
        key={key}
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
        ref={autocompleteRef}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: "13px",
            fontWeight: "500",
          }}
        >
          {title && (
            <label htmlFor="tp-custom-autocomplete" className="form-label">
              {title}
            </label>
          )}
          {mandatory && <CustomSpan style={{ color: "red" }} >*</CustomSpan>}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            flex: 1,
          }}
        >
          <input
            id="tp-custom-autocomplete"
            type="text"
            autoComplete="off"
            autoCorrect="off"
            className="tp-custom-autocomplete form-control"
            onChange={(e) => onQuery(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            placeholder={placeholder ?? ""}
            value={input}
            style={{
              borderRadius: "4px",
              border: focus
                ? `1px solid ${allThemes.base.primary}`
                : `1px solid ${getErrorColor()}`,
              backgroundColor: disabled ? "gainsboro" : "white",
              padding: "4px",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              boxShadow: "none",
            }}
            disabled={disabled}
          />
          <button
            className="tp-custom-autocomplete-icon"
            style={{
              borderTopRightRadius: icon ? 0 : "4px",
              borderBottomRightRadius: icon ? 0 : "4px",
              borderColor: errorMessage ? "red" : "gainsboro",
            }}
            onClick={() => {
              if (!disabled) setFocus(!focus);
            }}
            onBlur={() => {
              if (!disabled) setFocus(!focus);
            }}
          >
            <TPIcon iconType={TPIconTypes.caretDown} />
          </button>
          {icon && (
            <button
              className="tp-custom-autocomplete-icon"
              style={{
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
                borderColor: errorMessage ? "red" : "gainsboro",
              }}
              onClick={() => {
                if (disabled) return;
                if (onIconClick) onIconClick();
              }}
            >
              <TPIcon iconType={icon} style={{ fontSize: "20px" }} />
            </button>
          )}
        </div>
        <div
          style={{
            display: focus || (showSuggestion && !disabled) ? "flex" : "none",
            width: dropdownWidth ?? "400px",
          }}
          className="tp-custom-autocomplete-list"
        >
          {children.map(
            (component) =>
              component.key
                ?.toLowerCase()
                .includes(input.trim().toLowerCase()) && <>{component}</>
          )}
        </div>
      </div>
      {errorMessage && <div className="invalid-feedback" style={{display:"block"}}>{errorMessage}</div>}
    </div>
  );
};

export default TPCustomAutocomplete;
