import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";

export class LocationLanguages {
    getLocationNameInsertUpdate: LocationNameInsertUpdate = new LocationNameInsertUpdate();
    getLocationNameTable: LocationNameTable = new LocationNameTable();
    getLocationNameGlobal: LocationNameGlobal = new LocationNameGlobal();

    public locationDistributionListInsertUpdate = async (resourceSet: string) => {
        this.getLocationNameInsertUpdate.title = await TPI18N.GetText(resourceSet, "TitleLabel");
        this.getLocationNameInsertUpdate.titleEdit = await TPI18N.GetText(resourceSet, "UpdateLabel");
        this.getLocationNameInsertUpdate.titleNew = await TPI18N.GetText(resourceSet, "InsertLabel");
        this.getLocationNameInsertUpdate.subtitleLabel = await TPI18N.GetText(resourceSet, "SubtitleLabel");
        this.getLocationNameInsertUpdate.descriptionSectionLabel = await TPI18N.GetText(resourceSet, "DescriptionSectionLabel");
        this.getLocationNameInsertUpdate.parametersSectionLabel = await TPI18N.GetText(resourceSet, "ParametersSectionLabel");
        this.getLocationNameInsertUpdate.saveButton = await TPI18N.GetText(resourceSet, "SaveButton");
        this.getLocationNameInsertUpdate.cancelButton = await TPI18N.GetText(resourceSet, "CancelButton");
        this.getLocationNameInsertUpdate.languageList = await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList");
        this.getLocationNameInsertUpdate.idLabel = await TPI18N.GetText(resourceSet, "IdLabel");
        this.getLocationNameInsertUpdate.nameLabel = await TPI18N.GetText(resourceSet, "NameLabel");
        this.getLocationNameInsertUpdate.isActiveLabel = await TPI18N.GetText(TPGlobal.globalResourceSet, "ShowAsActive");
        this.getLocationNameInsertUpdate.okButton = await TPI18N.GetText(resourceSet, "IsActiveLabel");
        this.getLocationNameInsertUpdate.inputDTOEmailDuplicate = await TPI18N.GetText(resourceSet, "InputDTOEmailDuplicate");


        this.getLocationNameInsertUpdate.invalidEmailHotTable = await TPI18N.GetText(resourceSet, "InvalidEmailLabel");
        this.getLocationNameInsertUpdate.invalidNameHotTable = await TPI18N.GetText(resourceSet, "InvalidNameLabel");
        this.getLocationNameInsertUpdate.instructionsLabel = await TPI18N.GetText(resourceSet, "InstructionsForBulkLoadDistributionListLabel");
        this.getLocationNameInsertUpdate.copyFromLabel = await TPI18N.GetText(resourceSet, "CopyFromLabel");
        this.getLocationNameInsertUpdate.selectTheEmailLabel = await TPI18N.GetText(resourceSet, "SelectTheEmailLabel");
        this.getLocationNameInsertUpdate.clickSaveAndReviewLabel = await TPI18N.GetText(resourceSet, "ClickSaveAndReviewLabel");
        this.getLocationNameInsertUpdate.noteLabel = await TPI18N.GetText(resourceSet, "NoteLabel");

    };


    public locationDistributionTable = async (resourceSet: string) => {
        this.getLocationNameTable.title = await TPI18N.GetText(resourceSet, "TitleLabel");      
        this.getLocationNameTable.nameIdColumn = await TPI18N.GetText(resourceSet, "NameIdColumnLabel");
        this.getLocationNameTable.nameColumn = await TPI18N.GetText(resourceSet, "NameColumnLabel");
        this.getLocationNameTable.nameIsActiveColumn = await TPI18N.GetText(resourceSet, "NameIsActiveColumnLabel");
        this.getLocationNameTable.titleModalDelete = await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle");
        this.getLocationNameTable.thereAreNoRecordsToShow = await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyText");
        this.getLocationNameTable.newDistributionListLabel = await TPI18N.GetText(resourceSet, "NewDistributionListLabel");
        this.getLocationNameTable.filterIsActiveLabel = await TPI18N.GetText(resourceSet, "FilterLabel");


    };


    public locationGlobal = async (resourceSet: string) => {
        this.getLocationNameGlobal.confirmAlertTitle = await TPI18N.GetText(resourceSet, "ConfirmTitle");
        this.getLocationNameGlobal.okAlertButton = await TPI18N.GetText(resourceSet, "OkButton");
        this.getLocationNameGlobal.cancelAlertButton = await TPI18N.GetText(resourceSet, "CancelButton");
        this.getLocationNameGlobal.deleteLabel = await TPI18N.GetText(resourceSet, "DeleteLabel");
        this.getLocationNameGlobal.updateLabel = await TPI18N.GetText(resourceSet, "UpdateLabel");
        this.getLocationNameGlobal.buikLoad = await TPI18N.GetText(resourceSet, "BuikLoad");
        this.getLocationNameGlobal.search = await TPI18N.GetText(resourceSet, "Search");
        this.getLocationNameGlobal.refreshLabel = await TPI18N.GetText(resourceSet, "ReloadButton");
        this.getLocationNameGlobal.deleteLabelQuestion = await TPI18N.GetText("AdditionalDataRelateComponent", "RecordDeleteConfirm");
        this.getLocationNameGlobal.required = await TPI18N.GetText(resourceSet, "InputDTORequired");


    };


}

export class LocationNameInsertUpdate {
    subtitleLabel: string = "";
    descriptionSectionLabel: string = "";
    parametersSectionLabel: string = "";
    saveButton: string = "";
    cancelButton: string = "";
    okButton: string = "";
    languageList: string = "";
    idLabel: string = "";
    nameLabel: string = "";
    isActiveLabel: string = "";
    title: string = "";
    titleEdit: string = "";
    titleNew: string = "";
    invalidEmailHotTable: string = "";
    invalidNameHotTable: string = "";
    instructionsLabel: string = "";
    copyFromLabel: string = "";
    selectTheEmailLabel: string = "";
    clickSaveAndReviewLabel: string = "";
    noteLabel: string = "";
    inputDTOEmailDuplicate: string = "";
}

export class LocationNameTable {
    nameIdColumn: string = "";
    nameColumn: string = "";
    nameIsActiveColumn: string = "";
    title: string = "";
    titleModalDelete: string = "";
    thereAreNoRecordsToShow: string = "";
    newDistributionListLabel: string = "";
    filterIsActiveLabel: string = "";


}




export class LocationNameGlobal {
    confirmAlertTitle: string = "";
    okAlertButton: string = "";
    cancelAlertButton: string = "";
    deleteLabel: string = "";
    deleteLabelQuestion: string = "";
    updateLabel: string = "";
    buikLoad: string = "";
    search: string = "";
    refreshLabel: string = "";
    required: string = "";
}


