import { forwardRef, useEffect, useRef, useState } from "react";

import { TPI18N } from "@/services/I18nService";
import { BorderActiveStyle, ChildStyle, IcoAddStyle } from "./StyleFromDesigner";
import TPRenderAdditionalDataSwitch from "@/components/TPAdditionalData/TPRenderAdditionalDataSwitch";
import { Detail, ItemRow } from "@/models/FormDesigner/FormDesigner";
import { TPAddtionalDataUIModes } from "@/components/TPAdditionalData/TPAdditionalDataUImodes";
import { ComplementsRenderTP, TPKeyValue } from "@/helpers/TPKeyValue";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DraggableView from "./FormDesignerDraggable";

interface RowModel {
    rowNumber: number; // number to display
    isSelectDropdownList?: number; // number to display
    controlActive?: ItemRow; //   Paint selected red color
    color: string;
    isDropdownList: boolean;
    handleSelectDropdownList?: (number: number) => void;
    handleSelectControl?: (item: ItemRow) => void;
    style?: any;
    listControl?: Array<ItemRow>;
    aditionalDataModels?: Array<AdditionalDataViewModel>;
}

const stylesOn = {
    backgroundColor: "#FFB8DC",
    padding: "5px",
    width: "100%",
};

const stylesOff = {
    padding: "5px",
    width: "100%",
};

const myKeyValue: TPKeyValue = {
    key: "",
    value: "",
    value2: "",
    value3: "",
    value4: "",
};

// O si no necesitas los valores opcionales:

const anotherKeyValue: TPKeyValue = {
    key: "anotherKey",
    value: 42,
};

const FormDesignerRow = forwardRef(
    ({
        rowNumber,
        isDropdownList,
        listControl,
        aditionalDataModels,
        isSelectDropdownList,
        handleSelectControl,
        handleSelectDropdownList,
        color,
        style,
        controlActive,
    }: RowModel) => {
        const resourceSet: string = "FormDesignerComponent";
        const childRefArray: any = useRef([]);
        const [rowActive, setRowActive] = useState(isSelectDropdownList);
        // const [controlActive, setControlActive] = useState(controlActive);

        const handleSelectActive = (data?: any) => {
            if (isDropdownList && handleSelectDropdownList) {
                setRowActive(rowNumber);
                handleSelectDropdownList(rowNumber);
            } else {
                if (data && handleSelectControl) {
                    handleSelectControl(data);
                    //  setControlActive(data.id);
                }
            }
            if (data == false && handleSelectControl) {
                handleSelectControl(new ItemRow());
            }
        };

        const handleIsActive = (value: number) => {
            if (rowActive == undefined) return false;
            if (isDropdownList && value == rowActive) return true;
            return false;
        };

        const handleIsNull = (value: number) => {
            if (listControl == undefined) return false;
            if (!aditionalDataModels) return false;
            if (aditionalDataModels.length <= 0) return false;
            if (listControl.find((s) => s.order == value) == undefined) return false;
            if (listControl.find((s) => s.order == value)?.idControl == "")
                return false;
            return true;
        };

        const handleIsNullIdControl = (value: number) => {
            if (listControl == undefined) return "";
            if (listControl[value] == undefined) return "";
            return listControl[value].idControl;
        };

        const handleControlId = (value: number) => {
            if (listControl == undefined) return "";
            if (listControl[value] == undefined) return "";
            return listControl[value].id;
        };

        const handleIsNullControll = (value: number) => {
            if (listControl == undefined) return "";
            if (listControl[value] == undefined) return "";
            return listControl[value];
        };

        const handleGetRenderer = (id: number) => {
            let myKeyValue2: TPKeyValue = {
                key: "",
                value: "",
            };

            if (listControl == undefined) return myKeyValue2;
            if (listControl[id] == undefined) return myKeyValue2;

            let data;
            if (aditionalDataModels && aditionalDataModels.length > 0) {
                data = aditionalDataModels.find(
                    (s) => s.id == listControl[id].idControl,
                );

                if (data) {
                    data.localizedDescription = listControl[id].title;
                    data.enableEditingModifyBasicData = listControl[id].isReadOnly;
                }

                myKeyValue2.key = listControl[id].idControl;

                myKeyValue2.value2 = data;
            } else {
                myKeyValue2.key = listControl[id].idControl;
                myKeyValue2.value2 = new Array<AdditionalDataViewModel>();
            }

            return myKeyValue2;
        };


        const complementsRenderTP = (id: number): ComplementsRenderTP => {
            let complements = new ComplementsRenderTP();

            if (listControl == undefined) return new ComplementsRenderTP;
            if (listControl[id] == undefined) return new ComplementsRenderTP;

            let data;
            if (listControl && listControl.length >= id) {
               
                complements.placeholder = listControl[id].placeholder;
                complements.visible = listControl[id].isVisible;
                complements.dateFormatForDesignerForms = listControl[id].placeholder ;
               
            } else {
                complements.placeholder = "";
                complements.visible = false;
            }

            return complements;
        };

       
        useEffect(() => { }, [controlActive]);

        return (
            <>
                {rowNumber == 1 && (
                    <div style={handleIsActive(1) ? stylesOn : stylesOff}>
                        <ChildStyle
                            style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(0) ? "2px solid " : "3px solid rgb(195 194 194)") }}
                            onClick={() => handleSelectActive()}>
                            {handleIsNull(0) ? (
                                <>
                                    {/* <h1>{controlActive?.id +" -- "+ handleControlId(0)}</h1> */}
                                    <BorderActiveStyle
                                        onClick={() => handleSelectActive(handleIsNullControll(0))}
                                        active={false}
                                    >
                                        <TPRenderAdditionalDataSwitch
                                            guidControl={handleIsNullIdControl(0)}
                                            modeUI={TPAddtionalDataUIModes.Collect}
                                            itemToRender={handleGetRenderer(0)}
                                            
                                            complementsRenderTP={complementsRenderTP(0) }
                                            ref={(element: any) => { }}
                                            onChange={() => { }}
                                        />
                                    </BorderActiveStyle>
                                </>
                            ) : (
                                !isDropdownList && (
                                    <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                        <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                    </IcoAddStyle>
                                )
                            )}
                        </ChildStyle>
                    </div>
                )}

                {rowNumber == 2 && (
                    <div style={handleIsActive(2) ? stylesOn : stylesOff}>
                        <ChildStyle
                            onClick={() => handleSelectActive()}
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr"

                            }}
                        >
                            <div style={{
                                backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(0) ? "2px solid black" : "1px solid rgb(195 194 194 / 44%)"),
                               
                            }}>
                                {handleIsNull(0) ? (
                                    <DraggableView id={handleControlId(0)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(0))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(0)}
                                                itemToRender={handleGetRenderer(0)}
                                                complementsRenderTP={complementsRenderTP(0)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        /*  <DraggableView id={handleControlId(0)} > */
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                    /*     </DraggableView> */
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid black" : "1px solid rgb(195 194 194 / 44%)") }} >
                                {handleIsNull(1) ? (
                                    <DraggableView id={handleControlId(1)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(1))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(1)}
                                                itemToRender={handleGetRenderer(1)}
                                                complementsRenderTP={complementsRenderTP(1)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        /*   <DraggableView id={handleControlId(1)} > */
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                    /*    </DraggableView > */
                                )}
                            </div>
                        </ChildStyle>
                    </div>
                )}
                {rowNumber == 3 && (
                    <div style={handleIsActive(3) ? stylesOn : stylesOff}>
                        <ChildStyle
                            onClick={() => handleSelectActive()}
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 3fr",
                            }}
                        >
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(0) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(0) ? (
                                    <DraggableView id={handleControlId(0)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(0))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(0)}
                                                itemToRender={handleGetRenderer(0)}
                                                complementsRenderTP={complementsRenderTP(0)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(1) ? (
                                    <DraggableView id={handleControlId(1)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(1))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(1)}
                                                itemToRender={handleGetRenderer(1)}
                                                complementsRenderTP={complementsRenderTP(1)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                        </ChildStyle>
                    </div>
                )}
                {rowNumber == 4 && (
                    <div style={handleIsActive(4) ? stylesOn : stylesOff}>
                        <ChildStyle
                            onClick={() => handleSelectActive()}
                            style={{
                                display: "grid",
                                gridTemplateColumns: "3fr 1fr",
                            }}
                        >
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(0) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)")}}>
                                {handleIsNull(0) ? (
                                    <DraggableView id={handleControlId(0)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(0))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(0)}
                                                itemToRender={handleGetRenderer(0)}
                                                complementsRenderTP={complementsRenderTP(0)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(1) ? (
                                    <DraggableView id={handleControlId(1)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(1))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(1)}
                                                itemToRender={handleGetRenderer(1)}
                                                complementsRenderTP={complementsRenderTP(1)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                        </ChildStyle>
                    </div>
                )}
                {rowNumber == 5 && (
                    <div style={handleIsActive(5) ? stylesOn : stylesOff}>
                        <ChildStyle
                            onClick={() => handleSelectActive()}
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr",
                            }}
                        >
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(0) ? (
                                    <DraggableView id={handleControlId(0)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(0))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(0)}
                                                itemToRender={handleGetRenderer(0)}
                                                complementsRenderTP={complementsRenderTP(0)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)")}}>
                                {handleIsNull(1) ? (
                                    <DraggableView id={handleControlId(1)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(1))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(1)}
                                                itemToRender={handleGetRenderer(1)}
                                                complementsRenderTP={complementsRenderTP(1)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(2) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(2) ? (
                                    <DraggableView id={handleControlId(2)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(2))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(2)}
                                                itemToRender={handleGetRenderer(2)}
                                                complementsRenderTP={complementsRenderTP(2)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                        </ChildStyle>
                    </div>
                )}

                {rowNumber == 6 && (
                    <div style={handleIsActive(6) ? stylesOn : stylesOff}>
                        <ChildStyle
                            onClick={() => handleSelectActive()}
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 2fr",
                            }}
                        >
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(0) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)")}}>
                                {handleIsNull(0) ? (
                                    <DraggableView id={handleControlId(0)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(0))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(0)}
                                                itemToRender={handleGetRenderer(0)}
                                                complementsRenderTP={complementsRenderTP(0)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)")}}>
                                {handleIsNull(1) ? (
                                    <DraggableView id={handleControlId(1)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(1))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(1)}
                                                itemToRender={handleGetRenderer(1)}
                                                complementsRenderTP={complementsRenderTP(1)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(2) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(2) ? (
                                    <DraggableView id={handleControlId(2)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(2))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(2)}
                                                itemToRender={handleGetRenderer(2)}
                                                complementsRenderTP={complementsRenderTP(2)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                        </ChildStyle>
                    </div>
                )}
                {rowNumber == 7 && (
                    <div style={handleIsActive(7) ? stylesOn : stylesOff}>
                        <ChildStyle
                            onClick={() => handleSelectActive()}
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 2fr 1fr",
                            }}
                        >
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(0) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(0) ? (
                                    <DraggableView id={handleControlId(0)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(0))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(0)}
                                                itemToRender={handleGetRenderer(0)}
                                                complementsRenderTP={complementsRenderTP(0)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(1) ? (
                                    <DraggableView id={handleControlId(1)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(1))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(1)}
                                                itemToRender={handleGetRenderer(1)}
                                                complementsRenderTP={complementsRenderTP(1)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid " : "1px solid rgb(195 194 194)") }}>
                                {handleIsNull(2) ? (
                                    <DraggableView id={handleControlId(2)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(2))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(2)}
                                                itemToRender={handleGetRenderer(2)}
                                                complementsRenderTP={complementsRenderTP(2)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                        </ChildStyle>
                    </div>
                )}
                {rowNumber == 8 && (
                    <div style={handleIsActive(8) ? stylesOn : stylesOff}>
                        <ChildStyle
                            onClick={() => handleSelectActive()}
                            style={{
                                display: "grid",
                                gridTemplateColumns: "2fr 1fr 1fr",
                            }}
                        >
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(0) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(0) ? (
                                    <DraggableView id={handleControlId(0)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(0))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(0)}
                                                itemToRender={handleGetRenderer(0)}
                                                complementsRenderTP={complementsRenderTP(0)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(1) ? (
                                    <DraggableView id={handleControlId(1)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(1))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                itemToRender={handleGetRenderer(1)}
                                                guidControl={handleIsNullIdControl(1)}
                                                complementsRenderTP={complementsRenderTP(1)}
                                                defaultValue={""}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(2) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }} >
                                {" "}
                                {handleIsNull(2) ? (
                                    <DraggableView id={handleControlId(2)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(2))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(2)}
                                                itemToRender={handleGetRenderer(2)}
                                                complementsRenderTP={complementsRenderTP(2)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                        </ChildStyle>
                    </div>
                )}

                {rowNumber == 9 && (
                    <div style={handleIsActive(9) ? stylesOn : stylesOff}>
                        <ChildStyle
                            onClick={() => handleSelectActive()}
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                            }}
                        >
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(0) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(0) ? (
                                    <DraggableView id={handleControlId(0)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(0))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(0)}
                                                itemToRender={handleGetRenderer(0)}
                                                complementsRenderTP={complementsRenderTP(0)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(1) ? (
                                    <DraggableView id={handleControlId(1)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(1))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(1)}
                                                itemToRender={handleGetRenderer(1)}
                                                complementsRenderTP={complementsRenderTP(1)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(2) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(2) ? (
                                    <DraggableView id={handleControlId(2)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(2))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(2)}
                                                itemToRender={handleGetRenderer(2)}
                                                complementsRenderTP={complementsRenderTP(2)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(3) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(3) ? (
                                    <DraggableView id={handleControlId(3)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(3))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(3)}
                                                itemToRender={handleGetRenderer(3)}
                                                complementsRenderTP={complementsRenderTP(3)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                        </ChildStyle>
                    </div>
                )}
                {rowNumber == 10 && (
                    <div style={handleIsActive(10) ? stylesOn : stylesOff}>
                        <ChildStyle
                            onClick={() => handleSelectActive()}
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 2fr 2fr",
                            }}
                        >
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(0) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(0) ? (
                                    <DraggableView id={handleControlId(0)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(0))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(0)}
                                                itemToRender={handleGetRenderer(0)}
                                                complementsRenderTP={complementsRenderTP(0)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(1) ? (
                                    <DraggableView id={handleControlId(1)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(1))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(1)}
                                                itemToRender={handleGetRenderer(1)}
                                                complementsRenderTP={complementsRenderTP(1)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(2) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(2) ? (
                                    <DraggableView id={handleControlId(2)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(2))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(2)}
                                                itemToRender={handleGetRenderer(2)}
                                                complementsRenderTP={complementsRenderTP(2)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(3) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }} >
                                {handleIsNull(3) ? (
                                    <DraggableView id={handleControlId(3)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(3))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(3)}
                                                itemToRender={handleGetRenderer(3)}
                                                complementsRenderTP={complementsRenderTP(3)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                        </ChildStyle>
                    </div>
                )}

                {rowNumber == 11 && (
                    <div style={handleIsActive(11) ? stylesOn : stylesOff}>
                        <ChildStyle
                            onClick={() => handleSelectActive()}
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 2fr 1fr 2fr",
                            }}
                        >
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(0) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(0) ? (
                                    <DraggableView id={handleControlId(0)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(0))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(0)}
                                                itemToRender={handleGetRenderer(0)}
                                                complementsRenderTP={complementsRenderTP(0)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(1) ? (
                                    <DraggableView id={handleControlId(1)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(1))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(1)}
                                                itemToRender={handleGetRenderer(1)}
                                                complementsRenderTP={complementsRenderTP(1)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(2) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(2) ? (
                                    <DraggableView id={handleControlId(2)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(2))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(2)}
                                                itemToRender={handleGetRenderer(2)}
                                                complementsRenderTP={complementsRenderTP(2)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(3) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(3) ? (
                                    <DraggableView id={handleControlId(3)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(3))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(3)}
                                                itemToRender={handleGetRenderer(3)}
                                                complementsRenderTP={complementsRenderTP(3)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                        </ChildStyle>
                    </div>
                )}

                {rowNumber == 12 && (
                    <div style={handleIsActive(12) ? stylesOn : stylesOff}>
                        <ChildStyle
                            onClick={() => handleSelectActive()}
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 2fr 2fr 1fr",
                            }}
                        >
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(0) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(0) ? (
                                    <DraggableView id={handleControlId(0)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(0))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(0)}
                                                itemToRender={handleGetRenderer(0)}
                                                complementsRenderTP={complementsRenderTP(0)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(1) ? (
                                    <DraggableView id={handleControlId(1)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(1))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(1)}
                                                itemToRender={handleGetRenderer(1)}
                                                complementsRenderTP={complementsRenderTP(1)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(2) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(2) ? (
                                    <DraggableView id={handleControlId(2)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(2))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(2)}
                                                itemToRender={handleGetRenderer(2)}
                                                complementsRenderTP={complementsRenderTP(2)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(3) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") } }>
                                {handleIsNull(3) ? (
                                    <DraggableView id={handleControlId(3)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(3))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(3)}
                                                itemToRender={handleGetRenderer(3)}
                                                complementsRenderTP={complementsRenderTP(3)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                        </ChildStyle>
                    </div>
                )}

                {rowNumber == 13 && (
                    <div style={handleIsActive(13) ? stylesOn : stylesOff}>
                        <ChildStyle
                            onClick={() => handleSelectActive()}
                            style={{
                                display: "grid",
                                gridTemplateColumns: "2fr 1fr 2fr 1fr",
                            }}
                        >
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(0) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(0) ? (
                                    <DraggableView id={handleControlId(0)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(0))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(0)}
                                                itemToRender={handleGetRenderer(0)}
                                                complementsRenderTP={complementsRenderTP(0)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(1) ? (
                                    <DraggableView id={handleControlId(1)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(1))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(1)}
                                                itemToRender={handleGetRenderer(1)}
                                                complementsRenderTP={complementsRenderTP(1)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(2) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(2) ? (
                                    <DraggableView id={handleControlId(2)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(2))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(2)}
                                                itemToRender={handleGetRenderer(2)}
                                                complementsRenderTP={complementsRenderTP(2)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(3) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") } }>
                                {handleIsNull(3) ? (
                                    <DraggableView id={handleControlId(3)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(3))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(3)}
                                                itemToRender={handleGetRenderer(3)}
                                                complementsRenderTP={complementsRenderTP(3)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                        </ChildStyle>
                    </div>
                )}

                {rowNumber == 14 && (
                    <div style={handleIsActive(14) ? stylesOn : stylesOff}>
                        <ChildStyle
                            onClick={() => handleSelectActive()}
                            style={{
                                display: "grid",
                                gridTemplateColumns: "2fr 2fr 1fr 1fr",
                            }}
                        >
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(0) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(0) ? (
                                    <DraggableView id={handleControlId(0)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(0))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(0)}
                                                itemToRender={handleGetRenderer(0)}
                                                complementsRenderTP={complementsRenderTP(0)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(1) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") }}>
                                {handleIsNull(1) ? (
                                    <DraggableView id={handleControlId(1)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(1))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(1)}
                                                itemToRender={handleGetRenderer(1)}
                                                complementsRenderTP={complementsRenderTP(1)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(2) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)")  }}>
                                {handleIsNull(2) ? (
                                    <DraggableView id={handleControlId(2)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(2))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(2)}
                                                itemToRender={handleGetRenderer(2)}
                                                complementsRenderTP={complementsRenderTP(2)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                            <div style={{ backgroundColor: (!isDropdownList ? color : ""), border: (controlActive?.id == handleControlId(3) ? "2px solid " : "1px solid rgb(195 194 194 / 44%)") } }>
                                {handleIsNull(3) ? (
                                    <DraggableView id={handleControlId(3)}>
                                        <BorderActiveStyle
                                            color={!isDropdownList ? color : ""}
                                            onClick={() =>
                                                handleSelectActive(handleIsNullControll(3))
                                            }
                                            active={false}
                                        >
                                            <TPRenderAdditionalDataSwitch
                                                guidControl={handleIsNullIdControl(3)}
                                                itemToRender={handleGetRenderer(3)}
                                                complementsRenderTP={complementsRenderTP(3)}
                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                defaultValue={""}
                                                ref={(element: any) => { }}
                                                onChange={() => { }}
                                            />
                                        </BorderActiveStyle>
                                    </DraggableView>
                                ) : (
                                    !isDropdownList && (
                                        <IcoAddStyle onClick={() => handleSelectActive(false)}>
                                            <TPIcon iconType={TPIconTypes.addCircle} style={{}} />
                                        </IcoAddStyle>
                                    )
                                )}
                            </div>
                        </ChildStyle>
                    </div>
                )}
            </>
        );
    },
);

export default FormDesignerRow;
