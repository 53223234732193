import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
    DataTableContainer,
    IsActiveIcon,
    TableContainer,
    tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
    TPPageFirstRow,
    TPPageSearchContainer,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
    TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import { VerticalTabsAdminContainerSlice } from "@/layouts/VerticalTabs/VerticalTabsAdminContainer/_redux/VerticalTabsAdminContainerSlice";
import { FormDeignerGrid, FormDeignerModel } from "@/models/FormDesigner/FormDesigner";
import {
    TPActiveOptions,
    TPButtonTypes,
    TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { AdditionalDataFormService } from "@/services/AdditionalDataFormService";
import { TPI18N } from "@/services/I18nService";
import * as FileSaver from "file-saver";
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useReducer,
    useState,
} from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import {
    ChildContainerStyle,
    ContainerCheck,
    ContainerInputSelectStyle,
    RightRowContainerStyle,
    RowConfigStyle,
    SelectStyle,
} from "./StyleFromDesigner";
import DynamicTable, { CustomColumnNames } from "@/modules/core/components/dynamic-table/DynamicTable";
import { ColumnStyles } from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import { useAppConctextFormDesigner } from "./ContextFormDesignerAdmin";
import TPLoadingOverlay from "../../components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";

interface FormDesignerTableModel {
    callBackCommands: Function;
}

type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<FormDeignerGrid>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};

//State grid and current filter
const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: "",
};

enum commandsEnum {
    "set_filterIsLoaded" = 0,
    "setup_grid_columns" = 1,
    "reload_grid" = 2,
    "change_selectedFilter" = 3,
    "change_search_pattern" = 4,
}

type commandType = {
    type: commandsEnum;
    payload: any;
};

//State modal
let modalQuestionInitialState: TPModalQuestionState = {
    isShown: false,
    callBackData: {},
};

//State filter dropdown
let initialStateFilter: Array<TPKeyValue> = [];

const FormDesignerTable = forwardRef(
    ({ callBackCommands }: FormDesignerTableModel, ref) => {
        const dispatch = useDispatch();

        const resourceSet: string = "FormDesignerComponent";

        const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

        const [deleteLabel, setDeleteLabel] = useState("");
        const [updateLabel, setUpdateLabel] = useState("");
        const [exportLabel, setExportLabel] = useState("");
        const [importLabel, setImportLabel] = useState("");
        const [cloneLabel, setCloneLabel] = useState("");
        const [activeSelect, setActiveSelect] = useState(false);

        const [modalQuestionState, setModalQuestionState] = useState(
            modalQuestionInitialState
        );

        //grid columns
        const [actionColumnLabel, setActionColumnLabel] = useState("");
        const [idColumnLabel, setIdColumnLabel] = useState("");
        const [descriptionColumnLabel, setDescriptionColumnLabel] = useState("");
        const [typeColumnLabel, setTypeColumnLabel] = useState("");
        const [languageColumnLabel, setLanguageColumnLabel] = useState("");
        const [imageColumnLabel, setImageColumnLabel] = useState("");
        const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");
        const [visibleByCustomerColumnLabel, setVisibleByCustomerColumnLabel] =
            useState("");
        const [userAuthenticationColumnLabel, setUserAuthenticationColumnLabel] =
            useState("");
        const [preferencesLabel, setPreferencesLabel] = useState("");
        const [filterIsActiveLabel, setFilterLabel] = useState("");

        //modal resources
        const [deleteQuestion, setDeleteQuestion] = useState("");
        const [deleteTitle, setDeleteTitle] = useState("");
        const [deleteOkLabel, setDeleteOkLabel] = useState("");
        const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

        const [visibleColumns, setVisibleColumns] = useState<Array<string>>([]);
        const [allCoclumn, setAllCoclumn] = useState<Array<string>>([]);

        const [searchLabel, setSearchLabel] = useState("");
        const [newLabel, setNewLabel] = useState("");
        const [saveAsDefaul, setSaveAsDefaul] = useState("");
        const [resetToDefault, setResetToDefault] = useState("");

        const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

        const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
        const [yesLabel, setYesLabel] = useState("");
        const [noLabel, setNoLabel] = useState("");


        const loadResourcesAndCustomerTypeFilter = async () => {
            setDeleteQuestion(
                await TPI18N.GetText(resourceSet, "RecordDeleteConfirm")
            );
            setDeleteTitle(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle")
            );
            setDeleteOkLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
            );
            setDeleteCancelLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
            );

            setDeleteLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel")
            );
            setUpdateLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel")
            );
            setExportLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportLabel")
            );
            setImportLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "ImportLabel")
            );
            setCloneLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "CloneLabel")
            );
            setActionColumnLabel(
                await TPI18N.GetText(resourceSet, "ActionColumnLabel")
            );
            setIdColumnLabel(await TPI18N.GetText(resourceSet, "IdColumnLabel"));
            setDescriptionColumnLabel(
                await TPI18N.GetText(resourceSet, "DescriptionColumnLabel")
            );
            setTypeColumnLabel(await TPI18N.GetText(resourceSet, "TypeColumnLabel"));
            setLanguageColumnLabel(
                await TPI18N.GetText(resourceSet, "LanguageColumnLabel")
            );
            setImageColumnLabel(
                await TPI18N.GetText(resourceSet, "ImageColumnLabel")
            );
            setIsActiveColumnLabel(
                await TPI18N.GetText(resourceSet, "IsActiveColumnLabel")
            );
            setVisibleByCustomerColumnLabel(
                await TPI18N.GetText(resourceSet, "VisibleByCustomerLabel")
            );
            setUserAuthenticationColumnLabel(
                await TPI18N.GetText(resourceSet, "UserAuthenticationLabel")
            );
            setNewLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "NewLabel"));
            setSaveAsDefaul(await TPI18N.GetText(resourceSet, "SaveAsDefaul"));
            setPreferencesLabel(
                await TPI18N.GetText(resourceSet, "PreferencesLabel")
            );
            setThereAreNoRecordsToShow(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "DataTableNoCurrentData")
            );
            setResetToDefault(await TPI18N.GetText(resourceSet, "ResetToDefault"));
            setSearchLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "Search")
            );
            setYesLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
            );
            setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));
            setFilterLabel(await TPI18N.GetText(resourceSet, "FilterIsActiveLabel"));
            //Filter
            let newFilterKeyValue: Array<TPKeyValue> = [];
            newFilterKeyValue.push({
                key: TPActiveOptions.ALL.toString(),
                value: await TPI18N.GetText(resourceSet, "All"),
            });
            newFilterKeyValue.push({
                key: TPActiveOptions.ACTIVE.toString(),
                value: await TPI18N.GetText(resourceSet, "Active"),
            });
            newFilterKeyValue.push({
                key: TPActiveOptions.INACTIVE.toString(),
                value: await TPI18N.GetText(resourceSet, "Inactive"),
            });
            setFilterKeyValue(newFilterKeyValue);
        };

      
        const setupGridColumns = (prevState: AdminStateType) => {
            let newState: AdminStateType = { ...prevState };
            newState.gridColumns = [prevState.gridData];
            return newState;
        };


        //Funtion  Table
        const handleRowsPerPageChanged = (e: any) => {
            const recordSize = fixedHeaderScrollHeight / 10;
            const newRecordSize = recordSize * e;
            setFixedHeaderScrollHeight(newRecordSize);
        };

        const [adminState, dispatchCommand] = useReducer(
            doCommand,
            initialStateBLL
        );

        function doCommand(prevState: AdminStateType, command: commandType) {
            switch (command.type) {
                case commandsEnum.set_filterIsLoaded:
                    let newStateFilter: AdminStateType;
                    newStateFilter = { ...prevState };
                    newStateFilter.filterIsLoaded = true;
                    return newStateFilter;
                case commandsEnum.setup_grid_columns:
                    let newStateColumns: AdminStateType = setupGridColumns(prevState);
                    newStateColumns.columnsAreLoaded = true;
                    return newStateColumns;
                case commandsEnum.reload_grid:
                    let newStateGrid: AdminStateType;
                    newStateGrid = { ...prevState };
                    newStateGrid.gridData = command.payload;
                    return newStateGrid;
                case commandsEnum.change_selectedFilter:
                    let newStateChangeFilter: AdminStateType;
                    newStateChangeFilter = { ...prevState };
                    newStateChangeFilter.selectedFilter = command.payload;
                    return newStateChangeFilter;
                case commandsEnum.change_search_pattern:
                    let newStatePattern: AdminStateType;
                    newStatePattern = { ...prevState };
                    newStatePattern.searchPattern = command.payload;
                    return newStatePattern;
                default:
                    return prevState;
            }
        }

        const handleColumnActive = (name: string) => {
            let list = [...visibleColumns];
            let exist = list.find((s) => s == name);
            if (exist) list = list.filter((s) => s != name);
            if (!exist) list.push(name);
            setVisibleColumns(list);
            handleLoad();
        };

        const handleUpdateClick = (id: string) => {
            let command: any = { command: "update", recordId: id };
            callBackCommands(command);
        };

        const onClicExportHandler = (element: any) => {
            dispatch(VerticalTabsAdminContainerSlice.actions.export(element));
        };

        //Clone
        const handleCloneClick = (id: string) => {
            let command: any = { command: "clone", recordId: id };
            callBackCommands(command);
        };

        //Modal Question to delete CustomerType
        const handleDeleteClick = (id: string) => {
            let newModalQuestionState: TPModalQuestionState;
            newModalQuestionState = { ...modalQuestionState };
            newModalQuestionState.isShown = true;
            newModalQuestionState.callBackData = { recordId: id };
            setModalQuestionState(newModalQuestionState);
        };

        const formDesignColumns: CustomColumnNames<FormDeignerGrid> = {
            id: idColumnLabel,
            description: descriptionColumnLabel,
            languageId: languageColumnLabel,
            isActive: isActiveColumnLabel,
            formTypeLocalizedDescription: typeColumnLabel,
        };
        const customColumns: ColumnStyles<FormDeignerGrid> = {
            id: ({ value, item }) => (
                <TablePrimaryItem
                    value={value}
                    isDisabled={false}
                    onClick={() => handleUpdateClick(item.id)}
                />
            ),
            isActive: ({ value }) => (
                <TableChip
                    value={value}
                    onLabel={yesLabel}
                    offLabel={noLabel}
                    justify="flex-start"
                />
            ),
        };
        const minorOptions = [
            {
                key: updateLabel,
                type: "edit",
                icon: TPIconTypes.edit,
                onOptionChange: (e: { id: string; languageId: any; }) => handleUpdateClick(e.id),
            },
            {
                key: deleteLabel,
                type: "delete",
                icon: TPIconTypes.delete,
                onOptionChange: (e: { id: string; languageId: any; }) => handleDeleteClick(e.id),
            },
            {
                key: exportLabel,
                type: "export",
                icon: TPIconTypes.import,
                onOptionChange: (e: { id: string; languageId: any; }) => onClicExportHandler(e),
            },
            {
                key: cloneLabel,
                type: "clone",
                icon: TPIconTypes.clone,
                onOptionChange: (e: { id: string; }) => handleCloneClick(e.id),
            },
        ];
        const handleIconClick = (event: TPIconTypes) => {
            if (event == TPIconTypes.loop || event == TPIconTypes.refresh) loadAdditionalDataFormHandler();
            if (event == TPIconTypes.import) onClickButtonImportHandler();
        };

        //Delete function after question confirmation
        const handleCallBackModal = async (
            confirmDelete: boolean,
            callBackData: any
        ) => {
            let expectedCodes: Array<number> = [200];
            let serviceClient = new AdditionalDataFormService();
            let newModalQuestionState: TPModalQuestionState;
            newModalQuestionState = { ...modalQuestionState };
            newModalQuestionState.isShown = false;
            newModalQuestionState.callBackData = {};
            setModalQuestionState(newModalQuestionState);
            if (confirmDelete) {
                try {
                    // setIsLoadingScreen(true);
                    let responseRequest = await serviceClient.deleteFormDesingnerById(
                        callBackData.recordId,
                        true,
                        true,
                        expectedCodes
                    );
                    //  setIsLoadingScreen(false);
                    if (responseRequest.responseData.responseCode !== 500) {
                        loadAdditionalDataFormHandler();
                        callBackCommands({
                            command: "delete",
                            recordId: callBackData.recordId,
                        });
                    }
                } catch (error) {
                    TPLog.Log(
                        `Error ${resourceSet} handleCallBackModal ex`,
                        TPLogType.ERROR,
                        error
                    );
                    console.error(`Error ${resourceSet} handleCallBackModal ex`);
                    // setIsLoadingScreen(false);
                }
            }
        };

        //Filtered data based on selected pattern on search box
        const filteredData = () => {
            let searcheableColumns: Array<string> = [
                "id",
                "description",
                "localizedDescription",
            ];
            let i: number;
            let search: string;
            search = adminState.searchPattern.trim();

            return adminState.gridData.filter(function (item) {
                if (search == "" || search.length <= 2) {
                    return item;
                }
                for (i = 0; i <= searcheableColumns.length - 1; i++) {
                    let itemany: any;
                    itemany = item;
                    if (
                        itemany[searcheableColumns[i]] &&
                        itemany[searcheableColumns[i]]
                            .toString()
                            .toLowerCase()
                            .includes(search.toLowerCase())
                    ) {
                        return item;
                    }
                }
            });
        };

        const callBackVerticalTabTemp = () => {
            let command: any = { command: "new", recordId: 0 };
            callBackCommands(command);
        };

        //Command
        const handleNewClick = () => {
            let command: any = { command: "new" };
            callBackCommands(command);
        };

        function onClickButtonImportHandler() {
            callBackCommands({ command: "import" });
        }

        const handleFilterChange = (e: any) => {
            let command1: commandType = {
                type: commandsEnum.change_selectedFilter,
                payload: e.target.value,
            };
            dispatchCommand(command1);
        };

        //Handler to filter data inside data grid
        const handleSearchPatternChange = (newValue: string) => {
            let command1: commandType = {
                type: commandsEnum.change_search_pattern,
                payload: newValue,
            };
            dispatchCommand(command1);
        };

        const exportToCSV = (
            apiData = filteredData(),
            fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        ) => {
            const ws = XLSX.utils.json_to_sheet(apiData);
            /* custom headers */
            XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            FileSaver.saveAs(data, fileName + ".xlsx");
        };

        const handleLoad = () => {
            let command1: commandType = {
                type: commandsEnum.setup_grid_columns,
                payload: null,
            };
            dispatchCommand(command1);
        };

        useImperativeHandle(ref, () => ({
            refreshGridFromParent() {
                loadAdditionalDataFormHandler();
            },
        }));


        function loadAdditionalDataFormHandler() {
            const { filter } = new AdditionalDataFormService();

            filter({
                filterIsActive: 2,
                // formTypeId: "S_FTCUSTOMER" /get-by-filters
            })
                .then((dataSource) => {
                    dispatchCommand({
                        type: commandsEnum.reload_grid,
                        payload: dataSource,
                    } as commandType);
                    setStatusLoading(false);
                })
                .catch((error) => {
                    console.error(error)
                    setStatusLoading(false);
                })
        }

        useEffect(() => {
            loadResourcesAndCustomerTypeFilter()
                .then(function () {
                    setStatusLoading(false);
                    //set filter is loaded
                    let command1: commandType = {
                        type: commandsEnum.set_filterIsLoaded,
                        payload: null,
                    };
                    dispatchCommand(command1);
                })
                .catch(function (error) {
                    setStatusLoading(false);
                    TPLog.Log(
                        `Error ${resourceSet} loadResourcesAndCustomerTypeFilter ex`,
                        TPLogType.ERROR,
                        error
                    );
                    console.error(
                        `Error ${resourceSet} loadResourcesAndCustomerTypeFilter ex`
                    );
                });
            setStatusLoading(true);
            loadAdditionalDataFormHandler();
        }, []);

        const value = useAppConctextFormDesigner();
        const {
            setStatusLoading, statusLoading
        } = value;


        useEffect(() => {
            setStatusLoading(true);
            if (adminState.filterIsLoaded) {
                handleLoad();
            }
        }, [adminState.filterIsLoaded]);


        return (
            <>
                <TPModalQuestion
                    id="IdModalQuestion"
                    title={deleteTitle}
                    yesLabel={deleteOkLabel}
                    noLabel={deleteCanceLabel}
                    question={deleteQuestion.replace(
                        "{recordId}",
                        modalQuestionState.callBackData.recordId
                    )}
                    callBackData={modalQuestionState.callBackData}
                    isShown={modalQuestionState.isShown}
                    callBackAnswer={handleCallBackModal}
                ></TPModalQuestion>

                <div>
                    <div>
                        <TPLoadingOverlay active={statusLoading}>
                            <DynamicTable
                                data={adminState.gridData.map((e) => {
                                    return {
                                        id: e.id,
                                        description: e.description,
                                        languageId: e.languageId,
                                        formTypeLocalizedDescription: e.formTypeLocalizedDescription,
                                        isActive: e.isActive
                                    }
                                })}
                                columnNames={formDesignColumns}
                                columnStyles={customColumns}
                                minorOptions={minorOptions}
                                hiddenColumns={["languageId"]}
                                noDataMessage={`${thereAreNoRecordsToShow}.`}
                                onIconClicked={(event) => handleIconClick(event)}
                                exportNameFile="form-designer"
                                icons={[
                                    {
                                        status: true,
                                        type: TPIconTypes.import,
                                        tooltip: importLabel
                                    },
                                ]}
                            />
                        </TPLoadingOverlay>
                    </div>
                </div>
            </>
        );
    }
);

export default FormDesignerTable;
