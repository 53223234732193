import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPLanguageFilterEnum } from "@/models/Global/TPGlobalEnums";
import LanguageService from "@/services/LanguageService";
import { useCallback, useEffect, useState } from "react";
import { TPFieldContainer } from "../TPFieldContainer/TPFieldContainer";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";

interface LanguageSelectorProps {
  selectedLanguages: Array<TPKeyValue>;
  onChange: (languages: Array<TPKeyValue>) => void;
  emptyLabel: string;
  labelText: string;
  languageFilterType: TPLanguageFilterEnum;
  id: string;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  selectedLanguages,
  onChange,
  emptyLabel,
  labelText,
  languageFilterType,
  id,
}) => {
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    Array<TPKeyValue>
  >([]);
  const [topNOptions, setTopNOptions] = useState<Array<TPKeyValue>>([]);

  const handleAvailableLanguagesOnAutocompleteQuery = useCallback(
    async (query: string) => {
      const languageClient = new LanguageService();
      const expectedCodes = [200, 404];

      try {
        const response = await languageClient.getLanguageByFilter(
          languageFilterType,
          false,
          true,
          expectedCodes
        );

        const languages: Array<TPKeyValue> = response.responseData.data.map(
          (k: any) => ({
            key: k.id,
            value: k.name,
          })
        );

        setAutocompleteOptions(languages);
        return languages;
      } catch (error) {
        TPLog.Log(
          `Error handleAvailableLanguagesOnAutocompleteQuery ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error handleAvailableLanguagesOnAutocompleteQuery`);
        return [];
      }
    },
    []
  );

  const handleSelectLanguage = useCallback(
    (newSelectedValue: TPKeyValue[]) => {
      if (
        !!newSelectedValue.length &&
        !selectedLanguages.some((lang) => lang.key === newSelectedValue[0].key)
      ) {
        const updatedLanguages = [...selectedLanguages, newSelectedValue[0]];
        onChange(updatedLanguages);
      }
    },
    [selectedLanguages, onChange]
  );

  const handleRemoveLanguage = (languageKey: string) => {
    const updatedLanguages = selectedLanguages.filter(
      (lang) => lang.key !== languageKey
    );
    onChange(updatedLanguages);
  };

  const handleAvailableLanguagesOnAutocompleteKeyDown = (
    event: React.KeyboardEvent
  ) => {
    const skipKeys = [37, 39, 13, 35, 36]; // left, right, enter, home, end
    if (!skipKeys.includes(event.keyCode)) {
      setAutocompleteOptions([]);
    }
  };

  const handleAutoCompleteTopNClick = useCallback(async () => {
    if (topNOptions.length === 0) {
      const fetchedOptions =
        await handleAvailableLanguagesOnAutocompleteQuery("");
      if (fetchedOptions.length >= 1) {
        setTopNOptions(fetchedOptions);
        setAutocompleteOptions(fetchedOptions);
      }
    } else {
      setAutocompleteOptions(topNOptions);
    }
  }, [topNOptions, handleAvailableLanguagesOnAutocompleteQuery]);

  return (
    <>
      <TPFieldContainer>
        <TPAutoComplete
          id={"autocomplete-" + id}
          isMandatory={true}
          labelText={labelText}
          onValueChange={handleSelectLanguage}
          onSearch={handleAvailableLanguagesOnAutocompleteQuery}
          isLoading={false}
          options={
            Array.isArray(autocompleteOptions) && Array.isArray(selectedLanguages) 
              ? autocompleteOptions.filter(
                  (option: any) => 
                    option && 
                    option.key && 
                    !selectedLanguages.some(
                      (lang: any) => lang && lang.key === option.key
                    )
                )
              : []
          }          
          withIcon={true}
          emptyLabel={emptyLabel}
          onKeyDown={handleAvailableLanguagesOnAutocompleteKeyDown}
          downArrowClick={handleAutoCompleteTopNClick}
        />
      </TPFieldContainer>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          marginTop: "10px",
        }}
      >
        {selectedLanguages.map((language) => (
          <div
            key={language.key}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "5px 10px",
              backgroundColor: "#f1f1f1",
              borderRadius: "16px",
            }}
          >
            {language.value}
            <button
              onClick={() => handleRemoveLanguage(language.key)}
              style={{
                marginLeft: "8px",
                background: "transparent",
                border: "none",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              ✕
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default LanguageSelector;
