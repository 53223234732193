import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPBranchSelection2 from "@/components/TPBranchSelection/TPBranchSelection2";
import TPConditionsTable from "@/components/TPConditionsTable/TPConditionsTable";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import {
    TPPageAcceptCancelButtonsContainer,
    TPPageSection,
    TPPageSectionTitle,
    TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModal from "@/layouts/TPModal/TPModal";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
    ReportFilterType,
    ReportIdNames,
    TPButtonTypes,
    TPIconTypes,
    TPQuickSelectDatesEnum,
    taskStatusEnum,
    taskStatusNameEnum,
} from "@/models/Global/TPGlobalEnums";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { OrganizationsViewModel } from "@/models/Organizations/OrganizationsModels";
import { OrganizationsRelationsViewModel } from "@/models/OrganizationsRelations/OrganizationsRelationsModels";
import { RelationsWithRestrictionsViewModel } from "@/models/Relations/RelationsModels";
import {
    ReportDetailFiltersModel,
    ReportDetailInputDTO,
} from "@/models/Reports/ReportDetailInputDTO";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import { exportDataToExcel } from "@/modules/core/utils/export-utils";
import { CaseService } from "@/services/CaseService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";
import { OrganizationsService } from "@/services/OrganizationsService";
import { RelationsService } from "@/services/RelationsService";
import { ReportsService } from "@/services/ReportsService";
import { TreeService } from "@/services/TreeService";
import moment from "moment";
import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import TPLabel from "../../components/bootstrap/forms/TPLabel/TPLabel";
import DynamicTable, { ColumnStyles, HeaderAlignment } from "../../modules/core/components/dynamic-table/DynamicTable";
import { CustomColumnNames } from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import { ContainerBodyStyle, PopupContainerChildreStyle, PopupContainerStyle } from "../SearchIncomingMail/StyleSearchIncomingMail";
import { StandardReportsDataGrid } from "../../models/StandardReports/StandardReportsModel";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import TPWizardStepper from "@/components/TPWizardStepper/TPWizardStepper";
import TPConditionsDinamycTable from "@/components/TPConditionsDinamycTable/TPConditionsDinamycTable";
import TPBranchSelectionTree from "@/components/TPBranchSelectionTree/TPBranchSelectionTree";
import TableNotFound from "@/modules/core/components/dynamic-table/TableNotFound";
import { TPWizardContent } from "@/components/TPWizardStepper/TPWizardContent";
import {TPWizardStep} from "@/components/TPWizardStepper/TPWizardStep";
import { showToast, TPToastTypes } from "@/components/bootstrap/components/toasts/TPToast";

enum commandsEnum {
    "change_CurrentOrganization" = 0,
    "change_CurrentRelation" = 1,
    "change_CurrentBranch" = 2,
}

type commandType = {
    type: commandsEnum;
    payload: any;
};

type ReportPermission = {
    id_REPO: string;
};

type AdminStateType = {
    currentOrganization: string;
    currentRelation: string;

    currentBaseLevel: string;
    currentTree1: string;
    currentTree2: string;
    currentTree3: string;
    currentTree4: string;
    currentTree5: string;

    mustSelectLastLevelBranch1: boolean;
    mustSelectLastLevelBranch2: boolean;
    mustSelectLastLevelBranch3: boolean;
    mustSelectLastLevelBranch4: boolean;
    mustSelectLastLevelBranch5: boolean;

    currentLabelTree1: string;
    currentLabelTree2: string;
    currentLabelTree3: string;
    currentLabelTree4: string;
    currentLabelTree5: string;
    currentBranch1: string;
    currentBranch2: string;
    currentBranch3: string;
    currentBranch4: string;
    currentBranch5: string;
    currentDescriptionBranch1: string;
    currentDescriptionBranch2: string;
    currentDescriptionBranch3: string;
    currentDescriptionBranch4: string;
    currentDescriptionBranch5: string;
};

type PaginationGridType = {
    rowsPerPage: number;
    totalRows: number;
    currentPage: number;
};

type ColumnProps = {
    columnLabel: string;
};

type ModalSettingsColumnsType = {
    isShown: boolean;
    localRowsPerPage: number;
};

const StandardReports = () => {
    const componentFileName: string = "StandardReports.tsx";
    const resourceSet: string = "StandardReportsComponent";

    const [reportColumns, setReportColumns] = useState<Array<ColumnProps>>([]);
    const [reportData, setReportData] = useState<Array<any>>([]);
    const [reportDataDetail4, setReportDataDetail4] = useState<Array<any>>([]);
    const [reportDataDetail5, setReportDataDetail5] = useState<Array<any>>([]);
    const [inputDTOTemp, setIputDTOTemp] = useState<ReportDetailInputDTO>();
    const branch1SelectorRef: any = useRef(null);
    const branch2SelectorRef: any = useRef(null);
    const branch3SelectorRef: any = useRef(null);
    const branch4SelectorRef: any = useRef(null);
    const branch5SelectorRef: any = useRef(null);
    const [bodyText, seBodyText] = useState("");
    const [conditionQuantity, setConditionQuantity] = useState("");

    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);

    //#region Step 1
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [quickSelectDates, setQuickSelectDates] = useState(
        TPQuickSelectDatesEnum.today
    );
    const [dateType, setDateType] = useState("0");
    const [caseStatus, setCaseStatus] = useState("");
    const [privateCase, setPrivateCase] = useState("2");

    //#endregion
    //#region Step 2
    const [dummyClassfierLabel, setDummyClassfierLabel] = useState("");

    const initialModalSettingColumType: ModalSettingsColumnsType = {
        isShown: false,
        localRowsPerPage: 100,
    };
    const [modalSettingColumnsState, setModalSettingColumnsState] =
        useState<ModalSettingsColumnsType>(initialModalSettingColumType);

    //#endregion

    //#region Resources
    const [IsActiveAll, setIsActiveAll] = useState("");
    const [IsActiveYes, setIsActiveYes] = useState("");
    const [IsActiveNo, setIsActiveNo] = useState("");
    const [pageTitleLabel, setPageTitleLabel] = useState("");
    const [filterLabel, setFilterLabel] = useState("");
    const [otherFiltersLabel, setOthersFiltersLabel] = useState("");
    const [summaryLabel, setSummaryLabel] = useState("");
    const [resultsLabel, setResultsLabel] = useState("");
    const [organizationRelationLabel, setOrganizationRelationLabel] =
        useState("");
    const [organizationLabel, setOrganizationLabel] = useState("");
    const [relationLabel, setRelationLabel] = useState("");
    const [todayLabel, setTodayLabel] = useState("");
    const [yesterdayLabel, setYesterdayLabel] = useState("");
    const [currentWeekLabel, setCurrentWeekLabel] = useState("");
    const [lastWeekLabel, setLastWeekLabel] = useState("");
    const [currentMonthLabel, setCurrentMonthLabel] = useState("");
    const [lastMonthLabel, setLastMonthLabel] = useState("");
    const [currentYearLabel, setCurrentYearLabel] = useState("");
    const [lastTwelveMonthsLabel, setLastTwelveMonthsLabel] = useState("");
    const [startDateLabel, setStartDateLabel] = useState("");
    const [endDateLabel, setEndDateLabel] = useState("");
    const [moreFiltersLabel, setMoreFiltersLabel] = useState("");
    const [quickSelectDatesLabel, setQuickSelectDatesLabel] = useState("");
    const [dateTypeLabel, setDateTypeLabel] = useState("");
    const [caseStatusLabel, setCaseStatusLabel] = useState("");
    const [privateCaseLabel, setPrivateCaseLabel] = useState("");
    const [treeToGroupReportLabel, setTreeToGroupReportLabel] = useState("");
    const [classificatorLabel, setClassificatorLabel] = useState("");
    const [createDateLabel, setcreateDateLabel] = useState("");
    const [updateDateLabel, setupdateDateLabel] = useState("");
    const [totalRowsLabel, setTotalRowsLabel] = useState("");
    const [okButtonLabel, setOkButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [rowsPerPageLabel, setRowsPerPageLabel] = useState("");
    const [selectReportLabel, setSelectReportLabel] = useState("");
    const [reportLabel, setReportLabel] = useState("");
    const [nextLabel, setNextLabel] = useState("");
    const [applyButtonLabel, setApplyButtonLabel] = useState("");
    const [reportPermissions, setReportPermissions] = useState<ReportPermission[]>([]);
    const [caseDetailsLabel, setCaseDetailsLabel] = useState("");
    const [caseDetailsWithADLabel, setCaseDetailsWithADLabel] = useState("");
    const [caseDetailWithTasksLabel, setCaseDetailWithTasksLabel] = useState("");
    const [averageTimeResolvingLabel, setAverageTimeResolvingLabel] = useState("");
    const [tasksCompletedOnTimeLabel, setTasksCompletedOnTimeLabel] = useState("");
    const [reportCaseTimerDetailsLabel, setReportCaseTimerDetailsLabel] = useState("");
    const [reportCaseTimerSummaryLabel, setReportCaseTimerSummaryLabel] = useState("");
    const [timeTrackingLabel, setTimeTrackingLabel] = useState("");
    const [currentStep, setCurrentStep] = useState(0);

    const [statusesListKeyValue, setStatusesListKeyValue] = useState<
        Array<TPKeyValue>
    >([]);

    const [organizationListKeyValue, setOrganizationListKeyValue] = useState<
        Array<TPKeyValue>
    >([]);
    const [relationListKeyValue, setRelationListKeyValue] = useState<
        Array<TPKeyValue>
    >([]);

    const [modalAcceptLabel, setModalAcceptLabel] = useState("");
    const [modalCancelLabel, setModalCancelLabel] = useState("");

    //branch select
    const [modalSelectedBranchLabel, setModalSelectedBranchLabel] = useState("");
    const [modalTitleLabel, setModalTitleLabel] = useState("");
    const [emptyLabel, setEmptyLabel] = useState("");

    const [
        defaultBranchHierarchyDescription1,
        setDefaultBranchHierarchyDescription1,
    ] = useState("");
    const [
        defaultBranchHierarchyDescription2,
        setDefaultBranchHierarchyDescription2,
    ] = useState("");
    const [
        defaultBranchHierarchyDescription3,
        setDefaultBranchHierarchyDescription3,
    ] = useState("");
    const [
        defaultBranchHierarchyDescription4,
        setDefaultBranchHierarchyDescription4,
    ] = useState("");
    const [
        defaultBranchHierarchyDescription5,
        setDefaultBranchHierarchyDescription5,
    ] = useState("");

    const [filtersSelected, setFiltersSelected] = useState<any[]>([]);
    const [organizationSelected, setOrganizationSelected] = useState("");
    const [relationSelected, setRelationSelected] = useState("");
    const [parameter1Selected, setParameter1Selected] = useState("");
    const [reportNameSelected, setReportNameSelected] = useState("");
    const [currentReportId, setCurrentReportId] = useState("");
    const [otherSettingsLabel, setOtherSettingsLabel] = useState("");
    const [noDataMessageLabel, setNoDataMessageLabel] = useState("");
    const [secondaryNoDataMessageLabel, setSecondaryNoDataMessageLabel] = useState("");
    const [noDataMessageTableLabel, setNoDataMessageTableLabel] = useState("");
    const [secondaryNoDataMessageTableLabel, setSecondaryNoDataMessageTableLabel] = useState("");
    const [exportTableLabel, setExportTableLabel] = useState<string>('');
    const casecontext: any = React.useContext(TPCaseViewerContext);
    const initialPaginationGridState: PaginationGridType = {
        rowsPerPage: 10,
        totalRows: 100,
        currentPage: 1,
    };
    const [paginationGridState, setPaginationGridState] = useState(
        initialPaginationGridState
    );
    const [selectedReport, setSelectedReport] = useState<string>("");
    const initialRowsPerPageList: Array<TPKeyValue> = [
        { key: "10", value: "10" },
        { key: "100", value: "100" },
        { key: "200", value: "200" },
        { key: "500", value: "500" },
        { key: "1000", value: "1000" },
        { key: "5000", value: "5000" },
    ];

    const [rowsPerPageList, setRowsPerPageList] = useState(
        initialRowsPerPageList
    );

    const [isExcelReport, setIsExcelReport] = useState(false)

    //Load Resources
    const loadResourcesAndOrganizations = async () => {
        setIsActiveAll(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveAll")
        );
        setIsActiveYes(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveYes")
        );
        setIsActiveNo(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveNo")
        );

        setClassificatorLabel(
            await TPI18N.GetText(resourceSet, "ClassificatorLabel")
        );
        setcreateDateLabel(await TPI18N.GetText(resourceSet, "createDateLabel"));
        setupdateDateLabel(await TPI18N.GetText(resourceSet, "updateDateLabel"));

        setFilterLabel(
            await TPI18N.GetText(resourceSet, "FilterLabel")
        );
        setOthersFiltersLabel(
            await TPI18N.GetText(resourceSet, "Step2TitleLabel")
        );
        setSummaryLabel(await TPI18N.GetText(resourceSet, "Summarylabel"));
        setResultsLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "ResultsLabel")
        );
        setOrganizationRelationLabel(
            await TPI18N.GetText(resourceSet, "OrganizationRelationLabel")
        );
        setOrganizationLabel(
            await TPI18N.GetText(resourceSet, "OrganizationLabel")
        );
        setRelationLabel(await TPI18N.GetText(resourceSet, "RelationLabel"));
        setNoDataMessageTableLabel(await TPI18N.GetText(resourceSet, "NoDataMessageTableLabel"));
        setSecondaryNoDataMessageTableLabel(await TPI18N.GetText(resourceSet, "SecondaryNoDataMessageTableLabel"));
        setNoDataMessageLabel(await TPI18N.GetText(resourceSet, "NoDataMessageLabel"));
        setSecondaryNoDataMessageLabel(await TPI18N.GetText(resourceSet, "SecondaryNoDataMessageLabel"));
        setTodayLabel(await TPI18N.GetText(resourceSet, "TodayLabel"));
        setYesterdayLabel(await TPI18N.GetText(resourceSet, "YesterdayLabel"));
        setCurrentWeekLabel(await TPI18N.GetText(resourceSet, "CurrentWeekLabel"));
        setLastWeekLabel(await TPI18N.GetText(resourceSet, "LastWeekLabel"));
        setCurrentMonthLabel(
            await TPI18N.GetText(resourceSet, "CurrentMonthLabel")
        );
        setLastMonthLabel(await TPI18N.GetText(resourceSet, "LastMonthLabel"));
        setCurrentYearLabel(await TPI18N.GetText(resourceSet, "CurrentYearLabel"));
        setLastTwelveMonthsLabel(
            await TPI18N.GetText(resourceSet, "LastTwelveMonthsLabel")
        );
        setStartDateLabel(await TPI18N.GetText(resourceSet, "StartDateLabel"));
        setEndDateLabel(await TPI18N.GetText(resourceSet, "EndDateLabel"));

        setQuickSelectDatesLabel(
            await TPI18N.GetText(resourceSet, "QuickSelectDatesLabel")
        );
        setDateTypeLabel(await TPI18N.GetText(resourceSet, "DateTypeLabel"));
        setMoreFiltersLabel(await TPI18N.GetText(resourceSet, "MoreFiltersLabel"));
        setCaseStatusLabel(await TPI18N.GetText(resourceSet, "CaseStatusLabel"));
        setPrivateCaseLabel(await TPI18N.GetText(resourceSet, "PrivateCaseLabel"));
        setTotalRowsLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "TotalRowsLabel")
        );
        setOkButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
        );
        setCancelButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
        );

        setDummyClassfierLabel(
            await TPI18N.GetText(resourceSet, "ClassifierLabel")
        );

        setTreeToGroupReportLabel(
            await TPI18N.GetText(resourceSet, "TreeToGroupReportLabel")
        );

        setPageTitleLabel(await TPI18N.GetText(resourceSet, "PageTitle"));
        setOtherSettingsLabel(
            await TPI18N.GetText(resourceSet, "OtherSettingsLabel")
        );
        setRowsPerPageLabel(await TPI18N.GetText(resourceSet, "RowsPerPageLabel"));
        setSelectReportLabel(
            await TPI18N.GetText(resourceSet, "SelectReportLabel")
        );
        setReportLabel(
            await TPI18N.GetText(resourceSet, "ReportLabel")
        );
        setApplyButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "ApplyFilterText")
        );
        setNextLabel(await TPI18N.GetText(resourceSet, "NextLabel"));

        setModalAcceptLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
        );
        setModalCancelLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
        );
        setModalTitleLabel(await TPI18N.GetText(resourceSet, "ModalTitleLabel"));
        setModalSelectedBranchLabel(
            await TPI18N.GetText(resourceSet, "ModalSelectedBranchLabel")
        );
        setEmptyLabel(await TPI18N.GetText(resourceSet, "EmptyLabel"));
        setExportTableLabel(await TPI18N.GetText('DynamicTable', 'Export'));

        setCaseDetailsLabel(await TPI18N.GetText(resourceSet, 'CaseDetailsLabel'));
        setCaseDetailsWithADLabel(await TPI18N.GetText(resourceSet, 'CaseDetailsWithADLabel'));
        setCaseDetailWithTasksLabel(await TPI18N.GetText(resourceSet, 'CaseDetailWithTasksLabel'));
        setAverageTimeResolvingLabel(await TPI18N.GetText(resourceSet, 'AverageTimeResolvingLabel'));
        setTasksCompletedOnTimeLabel(await TPI18N.GetText(resourceSet, 'TasksCompletedOnTimeLabel'));
        setReportCaseTimerDetailsLabel(await TPI18N.GetText(resourceSet, 'ReportCaseTimerDetailsLabel'));
        setReportCaseTimerSummaryLabel(await TPI18N.GetText(resourceSet, 'ReportCaseTimerSummaryLabel'));
        setTimeTrackingLabel(await TPI18N.GetText(resourceSet, 'TimeTrackingLabel'));


        //load selects
        await getStatusesList();
        await getOrganizationsList();
    };

    const quickSelectDatesValue = [
        {
            key: TPQuickSelectDatesEnum.today,
            value: todayLabel,
        },
        {
            key: TPQuickSelectDatesEnum.currentYear,
            value: currentYearLabel,
        },
        {
            key: TPQuickSelectDatesEnum.currentMonth,
            value: currentMonthLabel,
        },
        {
            key: TPQuickSelectDatesEnum.currentWeek,
            value: currentWeekLabel,
        },
    ];

    const handleStatus = (item: string) => {
        switch (item) {
            case taskStatusNameEnum.Solved:
                return (
                    <TPIcon

                        iconType={TPIconTypes.checkCircle}
                        style={{ color: "#69c169", fontSize: "20px" }}
                        inline={true}
                    />
                );
            case taskStatusNameEnum.Pending:
                return (
                    <TPIcon
                        iconType={TPIconTypes.watchLater}
                        style={{ color: "rgb(11 122 187)", fontSize: "20px" }}
                        inline={true}
                    />
                );
            case taskStatusNameEnum.Expired:
                return (
                    <TPIcon
                        iconType={TPIconTypes.warning}
                        style={{ color: "red", fontSize: "20px" }}
                        inline={true}
                    />
                );
            case taskStatusNameEnum.Lag:
                return (
                    <TPIcon
                        iconType={TPIconTypes.hourglassTop}
                        style={{ color: "#e57348", fontSize: "20px" }}
                        inline={true}
                    />
                );
        }
    }



    const handleNullColumnName = (item: number) => {
        if (reportColumns && reportColumns[item]) {
            return reportColumns[item].columnLabel;
        }
        return "";
    };


    const treeColumns: CustomColumnNames<StandardReportsDataGrid> = {
        col1Value: handleNullColumnName(0),
        col2Value: handleNullColumnName(1),
        col3Value: handleNullColumnName(2),
        col4Value: handleNullColumnName(3),
        col5Value: handleNullColumnName(4),
        col6Value: handleNullColumnName(5),
        col7Value: handleNullColumnName(6),
        col8Value: handleNullColumnName(7),
        col9Value: handleNullColumnName(8),
        col10Value: handleNullColumnName(9),
        col11Value: handleNullColumnName(10),
        col12Value: handleNullColumnName(11),
        col13Value: handleNullColumnName(12),
        col14Value: handleNullColumnName(13),
        col15Value: handleNullColumnName(14),
        col16Value: handleNullColumnName(15),
        col17Value: handleNullColumnName(16),
        col18Value: handleNullColumnName(17),
        col19Value: handleNullColumnName(18),
        col20Value: handleNullColumnName(19),
        col21Value: handleNullColumnName(20),
        col22Value: handleNullColumnName(21),
        col23Value: handleNullColumnName(22),
        col24Value: handleNullColumnName(23),
        col25Value: handleNullColumnName(24),
        col26Value: handleNullColumnName(25),
        col27Value: handleNullColumnName(26),
        col28Value: handleNullColumnName(27),
        col29Value: handleNullColumnName(28),
        col30Value: handleNullColumnName(29),
        col31Value: handleNullColumnName(30)
    };

    const columnStyles: ColumnStyles<StandardReportsDataGrid> = {
        col20Value: ({ value, item }) => (
            <div style={{ display: "flex", justifyContent: "start", width: "200px", alignContent: "space-around" }}>
                <TPLabel style={{ textAlign: "start", width: "100%" }} labelText={handleTextLimit(extractPlainText(value))} />
                {handleIsTextLimit(extractPlainText(value)) &&
                    <TPIcon iconType={TPIconTypes.zoomIn} style={{}} onClick={() => { seBodyText(value); }} />
                }
            </div>
        )
        ,
        col24Value: ({ value, item }) => (
            <>
                {handleStatus(value)}
            </>
        )
    };

    const handleIconClick = (event: TPIconTypes) => {
        if (event === TPIconTypes.loop) {
            handleGenerateReport(currentReportId);
        }
    };



    function extractPlainText(input: string): string {
        if (!input) return '';
        const plainText = input.replace(/<[^>]+>/g, '');
        return plainText;
    }

    const handleTextLimit = (value: string) => {
        let count = TPGlobal.characterNumberCutStringComments;
        if (count == 0) count = 30;
        if (!value) return "";
        if (value.length <= count) return value;

        return value.slice(0, count) + "...";
    }

    const handleIsTextLimit = (value: string) => {
        let count = TPGlobal.characterNumberCutStringComments;
        if (count == 0) count = 30;
        if (!value) return false;
        if (value.length <= count) return false;

        return true;
    }

    const transformReportData = (reportData: Array<{ [key: string]: any }>) => {
        return reportData.map((item) => {
            const transformedItem: { [key: string]: any } = {};

            Object.keys(item).forEach(key => {
                if (key.endsWith('Header')) {
                    const valueKey = key.replace('Header', 'Value');
                    if (valueKey in item) {

                        const sanitizedValue = String(item[valueKey] || '').replace(/<[^>]*>/g, '');
                        transformedItem[item[key]] = sanitizedValue;
                    }
                }
            });

            return transformedItem;
        });
    };

    const handleGetDataGrid = (): Array<StandardReportsDataGrid> => {
        return reportData.map((e: any) => {
            return {
                col1Value: e.col1Value,
                col2Value: e.col2Value,
                col3Value: e.col3Value,
                col4Value: e.col4Value,
                col5Value: e.col5Value,
                col6Value: e.col6Value,
                col7Value: e.col7Value,
                col8Value: e.col8Value,
                col9Value: e.col9Value,
                col10Value: e.col10Value,
                col11Value: e.col11Value,
                col12Value: e.col12Value,
                col13Value: e.col13Value,
                col14Value: e.col14Value,
                col15Value: e.col15Value,
                col16Value: e.col16Value,
                col17Value: e.col17Value,
                col18Value: e.col18Value,
                col19Value: e.col19Value,
                col20Value: e.col20Value,
                col21Value: e.col21Value,
                col22Value: e.col22Value,
                col23Value: e.col23Value,
                col24Value: e.col24Value,
                col25Value: e.col25Value,
                col26Value: e.col26Value,
                col27Value: e.col27Value,
                col28Value: e.col28Value,
                col29Value: e.col29Value,
                col30Value: e.col30Value,
                col31Value: e.col31Value
            };

        });
    }

    const datGridReport8 = useMemo(() => transformReportData(reportData), [reportData]);

    const dataGridReport4 = useMemo(() => transformReportData(reportData), [reportData]);
    
    const columnStylesReport4 = useMemo(() => {
        if (!reportData.length || !dataGridReport4.length) return {};
    
        const [
            idOrgaKey,
            classifierTreeKey,
            totalKey,
            averageKey,
        ] = [
            reportData[0].col1Header,
            reportData[0].col3Header,
            reportData[0].col4Header,
            reportData[0].col5Header,
        ];
    
        const handleClick = async  (item: any) => {
            const inputDTO: ReportDetailInputDTO = getInputDTO(currentReportId, true, '1', `${item[idOrgaKey]},${item[classifierTreeKey]}`);
            setIsLoadingScreen(true);
            const data = await getReportInfo(inputDTO);
            setIsLoadingScreen(false);
            setReportDataDetail4(transformReportData(data))
        };
    
        return Object.keys(dataGridReport4[0] || {}).reduce(
            (acc, columnKey) => {
                acc[columnKey] = ({ value, item }) => {
                    const isCentered = [totalKey, averageKey].includes(columnKey);
                    const isClickable = columnKey === totalKey;
                    const displayValue =
                        columnKey === averageKey && typeof value === "number"
                            ? `${value}%`
                            : value;
    
                    return (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: isCentered ? "center" : "flex-start",
                                alignItems: "center",
                                cursor: isClickable ? "pointer" : "default",
                                color: isClickable ? "#a00095" : "inherit",
                                fontWeight: isClickable ? "bold" : "normal",
                            }}
                            onClick={() => isClickable && handleClick(item)}
                        >
                            {displayValue || ""}
                        </div>
                    );
                };
                return acc;
            },
            {} as Record<
                string,
                (props: {
                    value: number | string;
                    item: Record<string, number | string>;
                }) => JSX.Element
            >
        );
    }, [reportData, dataGridReport4]);

    const headerAlignmentReport4 = useMemo(() => {
        if (!reportData.length) return {};
    
        const totalKey = reportData[0].col4Header;
        const averageKey = reportData[0].col5Header;
    
        return {
            [totalKey]: 'center',
            [averageKey]: 'center',
        } as HeaderAlignment<Record<string, string>>;
    }, [reportData]);
    
    const dataGridReport5 = useMemo(() => transformReportData(reportData), [reportData]);

    const columnStylesReport5 = useMemo(() => {
        if (!reportData.length || !dataGridReport5.length) return {};
    
        const [
            idUserColumnKey,
            userColumnKey,
            pendingBeforeInitialDateColumnKey,
            receivedInDateRangeColumnKey,
            resolvedOnTimeColumnKey,
            resolvedAfterDeadlineColumnKey,
            pendingWithinDeadlineColumnKey,
            pendingPastDueColumnKey,
            averageColumnKey,
        ] = [
            reportData[0].col1Header,
            reportData[0].col2Header,
            reportData[0].col3Header,
            reportData[0].col4Header,
            reportData[0].col5Header,
            reportData[0].col6Header,
            reportData[0].col7Header,
            reportData[0].col8Header,
            reportData[0].col9Header,
            reportData[0].col10Header
        ];
    
        const actionMap: Record<string, string> = {
            [userColumnKey]: 'ALL',
            [pendingBeforeInitialDateColumnKey]: 'PenAnte',
            [receivedInDateRangeColumnKey]: 'IngRF',
            [resolvedOnTimeColumnKey]: 'SolRFAT',
            [resolvedAfterDeadlineColumnKey]: 'SolRFFT',
            [pendingWithinDeadlineColumnKey]: 'PFFAT',
            [pendingPastDueColumnKey]: 'PFFFT',
        };
    
        const handleClick = async (columnKey: string, id: string) => {
            const actionType = actionMap[columnKey];
            if(columnKey == "Case Number" && id){
                casecontext.handleAddNewCaseViewerCallBack(
                    id,
                )
            }

            if (!actionType) return;
    
            const inputDTO: ReportDetailInputDTO = getInputDTO(currentReportId, true, id, actionType);
            setIsLoadingScreen(true);
            const data = await getReportInfo(inputDTO);
            setIsLoadingScreen(false);

            setReportDataDetail5(transformReportData(data))
        };
    
        return Object.keys(dataGridReport5[0] || {}).reduce(
            (acc, columnKey) => {
                acc[columnKey] = ({ value, item }) => {
                    const isCol2Header = columnKey === userColumnKey;
                    const isCentered = ![userColumnKey].includes(columnKey);
                    const isClickable = !isCol2Header &&
                        averageColumnKey !== columnKey &&
                        (typeof value === "number" && value > 0);


                    return (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: isCentered ? 'center' : 'flex-start',
                                alignItems: "flex-start",
                                cursor: isClickable ? "pointer" : "default",
                                color: isClickable ? "#a00095" : "inherit",
                                fontWeight: isClickable ? "bold" : "normal",
                            }}
                            onClick={() => isClickable && handleClick(columnKey, String(item[idUserColumnKey]))}
                        >
                            {columnKey === averageColumnKey ? (value || "0") : (value || "")} {averageColumnKey === columnKey && '%'}
                        </div>
                    );
                };
                return acc;
            },
            {} as Record<
                string,
                (props: {
                    value: number | string;
                    item: Record<string, number | string>;
                }) => JSX.Element
            >
        );
    }, [reportData, dataGridReport5, currentReportId, setIsLoadingScreen]);    

    const headerAlignmentReport5 = useMemo(() => {
        if (!reportData.length) return {};
    
        return Object.keys(reportData[0] || {}).reduce(
            (acc, key) => {
                const headerKey = reportData[0][key as keyof typeof reportData[0]];
                acc[headerKey] = key === "col2Header" ? "left" : "center";
                return acc;
            },
            {} as Record<string, "center" | "left">
        );
    }, [reportData]);    

    const getStatusesList = async () => {
        let serviceClient = new CaseService();
        let expectedCodes: Array<number> = [200];
        let i: number;
        try {
            setIsLoadingScreen(true);
            //TODO: Change ProfileId=ADMIN&RestrictionId=S_ADWFRE&PartialRestriction=false
            let responseRequest = await serviceClient.getCasesStatus(
                false,
                true,
                expectedCodes
            );

            let caseStatusesListData: Array<TPKeyValue>;
            caseStatusesListData = responseRequest.map((status) => ({
                key: status.id,
                value: status.localizedDescription
                    ? status.localizedDescription
                    : status.description,
            }));
            let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
            caseStatusesListData.unshift(intitialKeyValue);
            setStatusesListKeyValue(caseStatusesListData);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getStatusesList ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getStatusesList ex`);
            setIsLoadingScreen(false);
        }
    };

    const getOrganizationsList = async () => {
        let serviceClient = new OrganizationsService();
        let expectedCodes: Array<number> = [200];
        let i: number;
        try {
            setIsLoadingScreen(true);
            //TODO: Change ProfileId=ADMIN&RestrictionId=S_ADWFRE&PartialRestriction=false
            let responseRequest =
                await serviceClient.getOrganizationsByProfileAndRestrictions(
                    "ADMIN",
                    "S_ADWFRE",
                    false,
                    false,
                    true,
                    expectedCodes
                );
            let newOrganizationsListState: Array<TPKeyValue> = [];

            let organizationsListData: Array<OrganizationsViewModel>;
            organizationsListData = responseRequest;
            for (i = 0; i <= organizationsListData.length - 1; i++) {
                let keyvalue: TPKeyValue = {
                    key: organizationsListData[i].id,
                    value: organizationsListData[i].localizedName,
                };
                newOrganizationsListState.push(keyvalue);
            }
            let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
            newOrganizationsListState.unshift(intitialKeyValue);
            setOrganizationListKeyValue(newOrganizationsListState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getOrganizationsList ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getOrganizationsList ex`);
            setIsLoadingScreen(false);
        }
    };

    const getRelationsList = async (newOrganization: string) => {
        let serviceClient = new RelationsService();
        let expectedCodes: Array<number> = [200];
        let i: number;
        let newRelationsListState: Array<TPKeyValue> = [];
        try {
            setIsLoadingScreen(true);
            if (newOrganization === "") {
                setRelationListKeyValue(newRelationsListState);
                setIsLoadingScreen(false);
                return;
            }

            //TODO: Change ProfileId=ADMIN&RestrictionId=S_ADWFRE&PartialRestriction=false
            let responseRequest =
                await serviceClient.getRelationsByOrganizationIdAndProfileRestriction(
                    newOrganization,
                    "ADMIN",
                    "S_ADWFRE",
                    false,
                    false,
                    true,
                    expectedCodes
                );
            let relationsListData: Array<RelationsWithRestrictionsViewModel>;
            relationsListData = responseRequest;
            for (i = 0; i <= relationsListData.length - 1; i++) {
                //legacy stuff :(   baselevel|relationid on baseLevelAndRelationlId field)
                //to avoid two arrays
                //todo fix Typo baseLevelAndRelationlId remove 'l' character
                let keyvalue: TPKeyValue = {
                    key: relationsListData[i].baseLevelAndRelationlId,
                    value: relationsListData[i].localizedName
                        ? relationsListData[i].localizedName
                        : relationsListData[i].name,
                };
                newRelationsListState.push(keyvalue);
            }
            let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
            newRelationsListState.unshift(intitialKeyValue);
            setRelationListKeyValue(newRelationsListState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getRelationsList ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getRelationsList ex`);
            setIsLoadingScreen(false);
        }
    };

    const getTreeInfo = async (treeId: string) => {
        let serviceClient = new TreeService();
        let expectedCodes: Array<number> = [200];
        let treeData: TreeViewModel | null = null;
        try {
            let responseRequest = await serviceClient.getTreeById(
                treeId,
                false,
                true,
                expectedCodes
            );
            treeData = responseRequest;
            return treeData;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getTreeInfo ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getTreeInfo ex`);
            return null;
        }
    };

    const getBaseLevelInfo = async (BaseLevelId: string) => {
        let serviceClient = new OrganizationsRelationsService();
        let expectedCodes: Array<number> = [200];
        let baseLevelData: OrganizationsRelationsViewModel | null = null;
        try {
            let responseRequest = await serviceClient.getById(
                BaseLevelId,
                false,
                true,
                expectedCodes
            );

            baseLevelData = responseRequest;
            return baseLevelData;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getBaseLevelInfo ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getBaseLevelInfo ex`);
            return null;
        }
    };

    const getRecordLanguageList = async (
        recordId: string,
        multilanguageTableName: string,
        multilanguageFieldName: string
    ): Promise<Array<MultilanguageFieldValueViewModel>> => {
        let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest =
                await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
                    multilanguageTableName,
                    multilanguageFieldName,
                    recordId,
                    false,
                    true,
                    expectedCodes
                );

            let recordInfo: Array<MultilanguageFieldValueViewModel>;
            recordInfo = [...responseRequest];
            return recordInfo;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getRecordLanguageList ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getRecordLanguageList ex`);
            return [];
        }
    };

    const getClassificatorsConfiguration = async (
        newRelation: string,
        newBaseLevel: string
    ) => {
        let newTree1: string;
        let newTree2: string;
        let newTree3: string;
        let newTree4: string;
        let newTree5: string;

        let mustSelectLastBranch1: boolean;
        let mustSelectLastBranch2: boolean;
        let mustSelectLastBranch3: boolean;
        let mustSelectLastBranch4: boolean;
        let mustSelectLastBranch5: boolean;

        let newLabelTree1: string | null;
        let newLabelTree2: string | null;
        let newLabelTree3: string | null;
        let newLabelTree4: string | null;
        let newLabelTree5: string | null;

        let baseLevelData: OrganizationsRelationsViewModel | null = null;
        let treeData: TreeViewModel | null = null;
        let localizedField: Array<MultilanguageFieldValueViewModel> = [];
        let i;
        let found: boolean;

        newTree1 = "";
        newTree2 = "";
        newTree3 = "";
        newTree4 = "";
        newTree5 = "";

        mustSelectLastBranch1 = false;
        mustSelectLastBranch2 = false;
        mustSelectLastBranch3 = false;
        mustSelectLastBranch4 = false;
        mustSelectLastBranch5 = false;

        newLabelTree1 = "";
        newLabelTree2 = "";
        newLabelTree3 = "";
        newLabelTree4 = "";
        newLabelTree5 = "";

        baseLevelData = await getBaseLevelInfo(newBaseLevel);
        if (baseLevelData === null) {
            //todo logs
            return null;
        }
        newTree1 = baseLevelData.tree1Id;
        newTree2 = baseLevelData.tree2Id;
        newTree3 = baseLevelData.tree3Id;
        newTree4 = baseLevelData.tree4Id;
        newTree5 = baseLevelData.tree5Id;
        //get tree Info 1
        if (newTree1 !== TPGlobal.Tree.NA_TreeCode) {
            treeData = await getTreeInfo(newTree1);
            if (treeData === null) {
                //todo logs
                return null;
            } else {
                mustSelectLastBranch1 = treeData.mustSelectLastBranch;
                //get label from multilanguage field value
                localizedField = await getRecordLanguageList(
                    newRelation.split("|")[0] + "_C1",
                    "BAseLEvel",
                    "Tree1_Classify1Caption"
                );
                if (localizedField === null || localizedField.length === 0) {
                    //todo logs
                    return null;
                }
                found = false;
                for (i = 0; i <= localizedField.length - 1; i++) {
                    if (localizedField[i].languageId === TPGlobal.language) {
                        newLabelTree1 = localizedField[i].recordDescription;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (
                            localizedField[i].languageId ===
                            TPGlobal.TPClientAvailableLanguages[0].id
                        ) {
                            newLabelTree1 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        //todo logs
                        return null;
                    }
                }
            }
        }
        if (newTree2 !== TPGlobal.Tree.NA_TreeCode) {
            //get tree Info 2
            treeData = await getTreeInfo(newTree2);
            if (treeData === null) {
                //todo logs
                return null;
            } else {
                mustSelectLastBranch2 = treeData.mustSelectLastBranch;
                //get label from multilanguage field value
                localizedField = await getRecordLanguageList(
                    newRelation.split("|")[0] + "_C2",
                    "BAseLEvel",
                    "Tree2_Classify2Caption"
                );
                if (localizedField === null || localizedField.length === 0) {
                    //todo logs
                    return null;
                }
                found = false;
                for (i = 0; i <= localizedField.length - 1; i++) {
                    if (localizedField[i].languageId === TPGlobal.language) {
                        newLabelTree2 = localizedField[i].recordDescription;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (
                            localizedField[i].languageId ===
                            TPGlobal.TPClientAvailableLanguages[0].id
                        ) {
                            newLabelTree2 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        //todo logs
                        return null;
                    }
                }
            }
        }
        if (newTree3 !== TPGlobal.Tree.NA_TreeCode) {
            //get tree Info 3
            treeData = await getTreeInfo(newTree3);
            if (treeData === null) {
                //todo logs
                return null;
            } else {
                mustSelectLastBranch3 = treeData.mustSelectLastBranch;
                //get label from multilanguage field value
                localizedField = await getRecordLanguageList(
                    newRelation.split("|")[0] + "_C3",
                    "BAseLEvel",
                    "Tree3_Classify3Caption"
                );
                if (localizedField === null || localizedField.length === 0) {
                    //todo logs
                    return null;
                }
                found = false;
                for (i = 0; i <= localizedField.length - 1; i++) {
                    if (localizedField[i].languageId === TPGlobal.language) {
                        newLabelTree3 = localizedField[i].recordDescription;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (
                            localizedField[i].languageId ===
                            TPGlobal.TPClientAvailableLanguages[0].id
                        ) {
                            newLabelTree3 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        //todo logs
                        return null;
                    }
                }
            }
        }
        if (newTree4 !== TPGlobal.Tree.NA_TreeCode) {
            //get tree Info 4
            treeData = await getTreeInfo(newTree4);
            if (treeData === null) {
                //todo logs
                return null;
            } else {
                mustSelectLastBranch4 = treeData.mustSelectLastBranch;
                //get label from multilanguage field value
                localizedField = await getRecordLanguageList(
                    newRelation.split("|")[0] + "_C4",
                    "BAseLEvel",
                    "Tree4_Classify4Caption"
                );
                if (localizedField === null || localizedField.length === 0) {
                    //todo logs
                    return null;
                }
                found = false;
                for (i = 0; i <= localizedField.length - 1; i++) {
                    if (localizedField[i].languageId === TPGlobal.language) {
                        newLabelTree4 = localizedField[i].recordDescription;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (
                            localizedField[i].languageId ===
                            TPGlobal.TPClientAvailableLanguages[0].id
                        ) {
                            newLabelTree4 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        //todo logs
                        return null;
                    }
                }
            }
        }
        if (newTree5 !== TPGlobal.Tree.NA_TreeCode) {
            //get tree Info 5
            treeData = await getTreeInfo(newTree5);
            if (treeData === null) {
                //todo logs
                return null;
            } else {
                mustSelectLastBranch5 = treeData.mustSelectLastBranch;
                //get label from multilanguage field value
                localizedField = await getRecordLanguageList(
                    newRelation.split("|")[0] + "_C5",
                    "BAseLEvel",
                    "Tree5_Classify5Caption"
                );
                if (localizedField === null || localizedField.length === 0) {
                    //todo logs
                    return null;
                }
                found = false;
                for (i = 0; i <= localizedField.length - 1; i++) {
                    if (localizedField[i].languageId === TPGlobal.language) {
                        newLabelTree5 = localizedField[i].recordDescription;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    for (i = 0; i <= localizedField.length - 1; i++) {
                        if (
                            localizedField[i].languageId ===
                            TPGlobal.TPClientAvailableLanguages[0].id
                        ) {
                            newLabelTree5 = localizedField[i].recordDescription;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        //todo logs
                        return null;
                    }
                }
            }
        }

        return {
            newTree1,
            newTree2,
            newTree3,
            newTree4,
            newTree5,
            mustSelectLastBranch1,
            mustSelectLastBranch2,
            mustSelectLastBranch3,
            mustSelectLastBranch4,
            mustSelectLastBranch5,
            newLabelTree1,
            newLabelTree2,
            newLabelTree3,
            newLabelTree4,
            newLabelTree5,
        };
    };

    const handlerRelationChange = async (e: any) => {
        setDefaultBranchHierarchyDescription1("");
        setDefaultBranchHierarchyDescription2("");
        setDefaultBranchHierarchyDescription3("");
        setDefaultBranchHierarchyDescription4("");
        setDefaultBranchHierarchyDescription5("");
        let newRelation: string = e.target.value;
        setRelationSelected(newRelation);
        let newBaseLevel: string;
        let newTree1: string;
        let newTree2: string;
        let newTree3: string;
        let newTree4: string;
        let newTree5: string;
        let mustSelectLastBranch1: boolean;
        let mustSelectLastBranch2: boolean;
        let mustSelectLastBranch3: boolean;
        let mustSelectLastBranch4: boolean;
        let mustSelectLastBranch5: boolean;
        let newLabelTree1: string | null;
        let newLabelTree2: string | null;
        let newLabelTree3: string | null;
        let newLabelTree4: string | null;
        let newLabelTree5: string | null;
        let classificationData: any;
        newBaseLevel = "";
        newTree1 = "";
        newTree2 = "";
        newTree3 = "";
        newTree4 = "";
        newTree5 = "";
        mustSelectLastBranch1 = false;
        mustSelectLastBranch2 = false;
        mustSelectLastBranch3 = false;
        mustSelectLastBranch4 = false;
        mustSelectLastBranch5 = false;
        newLabelTree1 = "";
        newLabelTree2 = "";
        newLabelTree3 = "";
        newLabelTree4 = "";
        newLabelTree5 = "";
        setIsLoadingScreen(true);
        if (newRelation !== "") {
            //get base level info
            newBaseLevel = newRelation.split("|")[0];

            //clasificators
            classificationData = await getClassificatorsConfiguration(
                newRelation,
                newBaseLevel
            );
            if (classificationData === null) {
                //todo logs
                return;
            }
            newTree1 = classificationData.newTree1;
            newTree2 = classificationData.newTree2;
            newTree3 = classificationData.newTree3;
            newTree4 = classificationData.newTree4;
            newTree5 = classificationData.newTree5;
            mustSelectLastBranch1 = classificationData.mustSelectLastBranch1;
            mustSelectLastBranch2 = classificationData.mustSelectLastBranch2;
            mustSelectLastBranch3 = classificationData.mustSelectLastBranch3;
            mustSelectLastBranch4 = classificationData.mustSelectLastBranch4;
            mustSelectLastBranch5 = classificationData.mustSelectLastBranch5;
            newLabelTree1 = classificationData.newLabelTree1;
            newLabelTree2 = classificationData.newLabelTree2;
            newLabelTree3 = classificationData.newLabelTree3;
            newLabelTree4 = classificationData.newLabelTree4;
            newLabelTree5 = classificationData.newLabelTree5;
        }
        let command1: commandType = {
            type: commandsEnum.change_CurrentRelation,
            payload: {
                newRelation: newRelation,
                newBaseLevel: newBaseLevel,
                newTree1: newTree1,
                newTree2: newTree2,
                newTree3: newTree3,
                newTree4: newTree4,
                newTree5: newTree5,
                newMustSelectLastLevelBranch1: mustSelectLastBranch1,
                newMustSelectLastLevelBranch2: mustSelectLastBranch2,
                newMustSelectLastLevelBranch3: mustSelectLastBranch3,
                newMustSelectLastLevelBranch4: mustSelectLastBranch4,
                newMustSelectLastLevelBranch5: mustSelectLastBranch5,
                newLabelTree1: newLabelTree1,
                newLabelTree2: newLabelTree2,
                newLabelTree3: newLabelTree3,
                newLabelTree4: newLabelTree4,
                newLabelTree5: newLabelTree5,
            },
        };
        dispatchCommand(command1);

        setIsLoadingScreen(false);
    };

    const handlerOrganizationChange = async (e: any) => {
        // setDefaultBranchHierarchyDescription1("");
        // setDefaultBranchHierarchyDescription2("");
        // setDefaultBranchHierarchyDescription3("");
        // setDefaultBranchHierarchyDescription4("");
        // setDefaultBranchHierarchyDescription5("");
        let newOrganization: string = e.target.value;
        setOrganizationSelected(newOrganization);
        setRelationSelected("");
        await getRelationsList(newOrganization);
        let command1: commandType = {
            type: commandsEnum.change_CurrentOrganization,
            payload: { newOrganization: newOrganization },
        };
        dispatchCommand(command1);
    };

    const handleDeleteConditionGroupClick = (index: number) => {
        setBranchHierarchyDescription(index)
        const filterSelectedCopy = filtersSelected.filter(
            (k) => k.classificatorIdx != index
        );

        setFiltersSelected(filterSelectedCopy);
    };

    const setBranchHierarchyDescription = (num: number) => {
        switch (num) {
            case 1:
                setDefaultBranchHierarchyDescription1("");
                break;
            case 2:
                setDefaultBranchHierarchyDescription2("");
                break;
            case 3:
                setDefaultBranchHierarchyDescription3("");
                break;
            case 4:
                setDefaultBranchHierarchyDescription4("");
                break;
            case 5:
                setDefaultBranchHierarchyDescription5("");
                break;
            default:
                console.error("Número inválido. Debe ser un número entre 1 y 5.");
        }
    };

    const handleOnBranchChange = async (
        label: string,
        index: number,
        branchId: string,
        branchDescription: string,
        branchHierachyDescription: string
    ) => {
        const prevFilters: any[] = [...filtersSelected];

        if (branchId && branchDescription && branchHierachyDescription) {
            const newConsecutive = conditionQuantity + 1;
            const filtersToPush: any = {
                conditionCode: branchId,
                conditionText: branchDescription,
                filterType: label,
                id: newConsecutive,
                organizationRelation: `${organizationSelected} - ${relationSelected}`,
                classificatorIdx: index,
            };
            setConditionQuantity(newConsecutive.toString());
            prevFilters.push(filtersToPush);
            setFiltersSelected(prevFilters);
        }

        let branch1: string;
        let branch2: string;
        let branch3: string;
        let branch4: string;
        let branch5: string;
        switch (index) {
            case 1:
                setDefaultBranchHierarchyDescription1(branchHierachyDescription);
                break;
            case 2:
                setDefaultBranchHierarchyDescription2(branchHierachyDescription);
                break;
            case 3:
                setDefaultBranchHierarchyDescription3(branchHierachyDescription);
                break;
            case 4:
                setDefaultBranchHierarchyDescription4(branchHierachyDescription);
                break;
            case 5:
                setDefaultBranchHierarchyDescription5(branchHierachyDescription);
                break;
            default:
                break;
        }
        branch1 = "";
        branch2 = "";
        branch3 = "";
        branch4 = "";
        branch5 = "";

        if (adminState.currentTree1 !== TPGlobal.Tree.NA_TreeCode) {
            if (index == 1) {
                branch1 = branchId;
            } else {
                branch1 = adminState.currentBranch1;
            }
        }
        if (adminState.currentTree2 !== TPGlobal.Tree.NA_TreeCode) {
            if (index == 2) {
                branch2 = branchId;
            } else {
                branch2 = adminState.currentBranch2;
            }
        }
        if (adminState.currentTree3 !== TPGlobal.Tree.NA_TreeCode) {
            if (index == 3) {
                branch3 = branchId;
            } else {
                branch3 = adminState.currentBranch3;
            }
        }
        if (adminState.currentTree4 !== TPGlobal.Tree.NA_TreeCode) {
            if (index == 4) {
                branch4 = branchId;
            } else {
                branch4 = adminState.currentBranch4;
            }
        }
        if (adminState.currentTree5 !== TPGlobal.Tree.NA_TreeCode) {
            if (index == 5) {
                branch5 = branchId;
            } else {
                branch5 = adminState.currentBranch5;
            }
        }

        let command1: commandType = {
            type: commandsEnum.change_CurrentBranch,
            payload: {
                treeNumber: index,
                newBranch: branchId,
                newDescriptionBranch: branchDescription,
            },
        };
        dispatchCommand(command1);
    };

    const getPreviousSunday = (date: any) => {
        const previousSunday = new Date();

        previousSunday.setDate(date.getDate() - date.getDay());

        return previousSunday;
    };

    const getPreviousMonday = (date: any) => {
        const previousMonday = new Date();

        previousMonday.setDate(date.getDate() - date.getDay() + 1);

        return previousMonday;
    };

    const getFirstDayOfTheMonth = (year: any, month: any) => {
        return new Date(year, month, 1);
    };

    const changeDateSelectorHandler = (value: any) => {
        setQuickSelectDates(value);
        const today = new Date();
        const thisYear = today.getFullYear();
        const thisMonth = today.getMonth();
        const previousMonday = getPreviousMonday(today);
        const previousSunday = getPreviousSunday(today);
        switch (value) {
            case TPQuickSelectDatesEnum.today:
                setStartDate(today);
                setEndDate(today);
                break;
            case TPQuickSelectDatesEnum.currentWeek:
                setStartDate(previousMonday);
                setEndDate(today);
                break;
            case TPQuickSelectDatesEnum.currentMonth:
                const firstDayOfThisMonth = getFirstDayOfTheMonth(thisYear, thisMonth);
                setStartDate(firstDayOfThisMonth);
                setEndDate(today);
                break;
            case TPQuickSelectDatesEnum.currentYear:
                const firstDayOfThisYear = new Date(thisYear, 0, 1);
                setStartDate(firstDayOfThisYear);
                setEndDate(today);
                break;
            default:
                break;
        }
    };

    const handleChangeStartDate = (date: Date) => {
        setStartDate(date);
        //do something
    };

    const handleChangeEndDate = (date: Date) => {
        setEndDate(date);
        //do something
    };

    const handleSearchButtonClick = () => { };

    const handleDeleteCondition = (conditionId: string) => {
        if (conditionId) {
            const filtersSelectedCopy = [...filtersSelected];
            const newState = filtersSelectedCopy.filter((k) => k.id != conditionId);
            setFiltersSelected(newState);
        }
    };

    //Initial State
    const initialStateBLL: AdminStateType = {
        currentOrganization: "",
        currentRelation: "",

        currentBaseLevel: "",

        currentTree1: "",
        currentTree2: "",
        currentTree3: "",
        currentTree4: "",
        currentTree5: "",

        mustSelectLastLevelBranch1: false,
        mustSelectLastLevelBranch2: false,
        mustSelectLastLevelBranch3: false,
        mustSelectLastLevelBranch4: false,
        mustSelectLastLevelBranch5: false,

        currentLabelTree1: "",
        currentLabelTree2: "",
        currentLabelTree3: "",
        currentLabelTree4: "",
        currentLabelTree5: "",

        currentBranch1: "",
        currentBranch2: "",
        currentBranch3: "",
        currentBranch4: "",
        currentBranch5: "",
        currentDescriptionBranch1: "",
        currentDescriptionBranch2: "",
        currentDescriptionBranch3: "",
        currentDescriptionBranch4: "",
        currentDescriptionBranch5: "",
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(doCommand, initialStateBLL);
    function doCommand(prevState: AdminStateType, command: commandType) {
        let newAdminState: AdminStateType;
        switch (command.type) {
            case commandsEnum.change_CurrentOrganization:
                newAdminState = { ...prevState };
                newAdminState.currentOrganization = command.payload.newOrganization;
                newAdminState.currentTree1 = "";
                newAdminState.currentTree2 = "";
                newAdminState.currentTree3 = "";
                newAdminState.currentTree4 = "";
                newAdminState.currentTree5 = "";

                newAdminState.mustSelectLastLevelBranch1 = false;
                newAdminState.mustSelectLastLevelBranch2 = false;
                newAdminState.mustSelectLastLevelBranch3 = false;
                newAdminState.mustSelectLastLevelBranch4 = false;
                newAdminState.mustSelectLastLevelBranch5 = false;

                newAdminState.currentLabelTree1 = "";
                newAdminState.currentLabelTree2 = "";
                newAdminState.currentLabelTree3 = "";
                newAdminState.currentLabelTree4 = "";
                newAdminState.currentLabelTree5 = "";
                newAdminState.currentRelation = "";

                newAdminState.currentBaseLevel = "";

                newAdminState.currentBranch1 = "";
                newAdminState.currentBranch2 = "";
                newAdminState.currentBranch3 = "";
                newAdminState.currentBranch4 = "";
                newAdminState.currentBranch5 = "";
                newAdminState.currentDescriptionBranch1 = "";
                newAdminState.currentDescriptionBranch2 = "";
                newAdminState.currentDescriptionBranch3 = "";
                newAdminState.currentDescriptionBranch4 = "";
                newAdminState.currentDescriptionBranch5 = "";
                return newAdminState;
            case commandsEnum.change_CurrentRelation:
                newAdminState = { ...prevState };
                newAdminState.currentRelation = command.payload.newRelation;
                newAdminState.currentBaseLevel = command.payload.newBaseLevel;

                newAdminState.currentTree1 = command.payload.newTree1;
                newAdminState.currentTree2 = command.payload.newTree2;
                newAdminState.currentTree3 = command.payload.newTree3;
                newAdminState.currentTree4 = command.payload.newTree4;
                newAdminState.currentTree5 = command.payload.newTree5;

                newAdminState.mustSelectLastLevelBranch1 =
                    command.payload.newMustSelectLastLevelBranch1;
                newAdminState.mustSelectLastLevelBranch2 =
                    command.payload.newMustSelectLastLevelBranch2;
                newAdminState.mustSelectLastLevelBranch3 =
                    command.payload.newMustSelectLastLevelBranch3;
                newAdminState.mustSelectLastLevelBranch4 =
                    command.payload.newMustSelectLastLevelBranch4;
                newAdminState.mustSelectLastLevelBranch5 =
                    command.payload.newMustSelectLastLevelBranch5;

                newAdminState.currentLabelTree1 = command.payload.newLabelTree1;
                newAdminState.currentLabelTree2 = command.payload.newLabelTree2;
                newAdminState.currentLabelTree3 = command.payload.newLabelTree3;
                newAdminState.currentLabelTree4 = command.payload.newLabelTree4;
                newAdminState.currentLabelTree5 = command.payload.newLabelTree5;
                newAdminState.currentBranch1 = "";
                newAdminState.currentBranch2 = "";
                newAdminState.currentBranch3 = "";
                newAdminState.currentBranch4 = "";
                newAdminState.currentBranch5 = "";
                newAdminState.currentDescriptionBranch1 = "";
                newAdminState.currentDescriptionBranch2 = "";
                newAdminState.currentDescriptionBranch3 = "";
                newAdminState.currentDescriptionBranch4 = "";
                newAdminState.currentDescriptionBranch5 = "";
                return newAdminState;
            case commandsEnum.change_CurrentBranch:
                newAdminState = { ...prevState };
                switch (command.payload.treeNumber) {
                    case 1:
                        newAdminState.currentBranch1 = command.payload.newBranch;
                        newAdminState.currentDescriptionBranch1 =
                            command.payload.newDescriptionBranch;
                        break;
                    case 2:
                        newAdminState.currentBranch2 = command.payload.newBranch;
                        newAdminState.currentDescriptionBranch2 =
                            command.payload.newDescriptionBranch;
                        break;
                    case 3:
                        newAdminState.currentBranch3 = command.payload.newBranch;
                        newAdminState.currentDescriptionBranch3 =
                            command.payload.newDescriptionBranch;
                        break;
                    case 4:
                        newAdminState.currentBranch4 = command.payload.newBranch;
                        newAdminState.currentDescriptionBranch4 =
                            command.payload.newDescriptionBranch;
                        break;
                    case 5:
                        newAdminState.currentBranch5 = command.payload.newBranch;
                        newAdminState.currentDescriptionBranch5 =
                            command.payload.newDescriptionBranch;
                        break;
                }
                return newAdminState;
        }
    }

    const handleGenerateReport = async (reportId: string) => {
        setCurrentReportId(reportId);
        let newReportNameSelected: string = "";
        switch (reportId) {
            case ReportIdNames.REPOSTANDAVERAGERESPON:
                newReportNameSelected = `${await TPI18N.GetText(resourceSet, "AverageTimeResolvingLabel")}`;
                break;
            case ReportIdNames.REPOSTANDCADE:
                newReportNameSelected = `${await TPI18N.GetText(resourceSet, "CaseDetailsLabel")}`;
                break;
            case ReportIdNames.REPOSTANDCADEWITHADDA:
                newReportNameSelected = `${await TPI18N.GetText(resourceSet, "CaseDetailsWithADLabel")}`;
                break;
            case ReportIdNames.REPOSTANDCADEWITHTASK:
                newReportNameSelected = `${await TPI18N.GetText(resourceSet, "CaseDetailWithTasksLabel")}`;
                break;
            case ReportIdNames.REPOSTANTASKCOMPLETED:
                newReportNameSelected = `${await TPI18N.GetText(resourceSet, "TasksCompletedOnTimeLabel")}`;
                break;
            case ReportIdNames.REPOSTANCASETIMERDETAILS:
                newReportNameSelected = `${await TPI18N.GetText(resourceSet, "ReportCaseTimerDetailsLabel")}`;
                break;
            case ReportIdNames.REPOSTANCASETIMERSUMMARY:
                newReportNameSelected = `${await TPI18N.GetText(resourceSet, "ReportCaseTimerSummaryLabel")}`;
                break;
            case ReportIdNames.REPOSTANACTIVITYTRACKED:
                newReportNameSelected = `${await TPI18N.GetText(resourceSet, "ReportActivityTrackingLabel")}`;
                break;

            default:
                break;
        }
        setReportNameSelected(newReportNameSelected);
        let inputDTO: ReportDetailInputDTO;
        inputDTO = getInputDTO(reportId);

        setIsLoadingScreen(true);
        let data = await getReportInfo(inputDTO);
        setIsLoadingScreen(false);

        if (data && data.length > 0) {
            let newReportColumns: Array<ColumnProps> = await getColumnsForReport(
                [...data][0]
            );
            setReportColumns(newReportColumns);
            setReportData(data);
            if (isExcelReport) {
                generateExcelReport(
                    newReportNameSelected,
                    newReportColumns,
                    data
                );
            } else {
                let newPaginationGridState = { ...paginationGridState };
                newPaginationGridState.currentPage = 1;
                newPaginationGridState.totalRows =
                    data[0][`col${newReportColumns.length.toString()}Value`];
                setPaginationGridState(newPaginationGridState);
                // this fragment code is to switch to the tab result when data fetch en resolve success
                const tabresult = document.querySelector(
                    "#idtabResultsReport"
                ) as HTMLDivElement | null;
                if (!!tabresult) tabresult.click();
            }
        } else {
            setReportColumns([]);
            setReportData([]);
        }
    };

    const getInputDTO = (reportId: string, isDetail: boolean = false, parameter1: string = '', parameter2: string = ''): ReportDetailInputDTO => {
        let inputDTO: ReportDetailInputDTO;

        inputDTO = {
          reportId: reportId,
          userGuid: TPGlobal.currentUserGuid,
          startRecord: 1,
          endRecord: paginationGridState.rowsPerPage,
          startDate: moment(startDate).format("YYYY-MM-DDT00:00:00.000"),
          endDate: moment(endDate).format("YYYY-MM-DDT00:00:00.000"),
          dateType: +dateType,
          parameter1: isDetail
            ? parameter1
            : reportId === ReportIdNames.REPOSTANDAVERAGERESPON
              ? parameter1Selected
              : "",
          parameter2: isDetail ? parameter2 : "",
          tempReportFilter: [],
          isDetailReport: isDetail,
        };
        let baseLevelId: string = relationSelected.split("|")[0];
        if (baseLevelId) {
            let newDetailFilter: ReportDetailFiltersModel = {
                filterName: ReportFilterType.BaseLevelId,
                filterValue: baseLevelId,
                filterType: "string",
            };
            inputDTO.tempReportFilter.push(newDetailFilter);
        }
        if (caseStatus) {
            let newDetailFilter: ReportDetailFiltersModel = {
                filterName: ReportFilterType.CaseStatus,
                filterValue: caseStatus,
                filterType: "string",
            };
            inputDTO.tempReportFilter.push(newDetailFilter);
        }
        if (privateCase) {
            let newDetailFilter: ReportDetailFiltersModel = {
                filterName: ReportFilterType.PrivateCase,
                filterValue: privateCase,
                filterType: "bit",
            };
            inputDTO.tempReportFilter.push(newDetailFilter);
        }

        if (filtersSelected && filtersSelected.length > 0) {
            for (let i = 0; i < filtersSelected.length; i++) {
                const element = filtersSelected[i];
                let clasificatorType: string = ReportFilterType.Classificator.replace(
                    "{id}",
                    element.classificatorIdx.toString()
                );
                let newDetailFilter: ReportDetailFiltersModel = {
                    filterName: clasificatorType,
                    filterValue: element.conditionCode,
                    filterType: "string",
                };
                inputDTO.tempReportFilter.push(newDetailFilter);
            }
        }
        return inputDTO;
    };

    const handleChangePage = async (direction: string) => {
        let newPageNumber: number;

        if (direction === "FIRST" && paginationGridState.currentPage === 1) {
            return;
        }
        if (direction === "PREVIOUS" && paginationGridState.currentPage === 1) {
            return;
        }
        if (
            direction === "NEXT" &&
            paginationGridState.currentPage === calcMaxPages()
        ) {
            return;
        }
        if (
            direction === "LAST" &&
            paginationGridState.currentPage === calcMaxPages()
        ) {
            return;
        }
        newPageNumber = paginationGridState.currentPage;
        if (direction === "FIRST") {
            newPageNumber = 1;
        }
        if (direction === "PREVIOUS") {
            newPageNumber = newPageNumber - 1;
        }
        if (direction === "NEXT") {
            newPageNumber = newPageNumber + 1;
        }
        if (direction === "LAST") {
            newPageNumber = calcMaxPages();
        }

        let inputDTO: ReportDetailInputDTO;
        inputDTO = getInputDTO(currentReportId);
        inputDTO.startRecord =
            (newPageNumber - 1) * paginationGridState.rowsPerPage + 1;
        inputDTO.endRecord = newPageNumber * paginationGridState.rowsPerPage;
        setIsLoadingScreen(true);
        let data = await getReportInfo(inputDTO);
        setIsLoadingScreen(false);

        if (data && data.length > 0) {
            let newReportColumns: Array<ColumnProps> = getColumnsForReport(data[0]);
            setReportColumns(newReportColumns);
            setReportData(data);
            let newPaginationGridState = { ...paginationGridState };
            newPaginationGridState.totalRows =
                data[0][`col${newReportColumns.length.toString()}Value`];
            newPaginationGridState.currentPage = newPageNumber;
            setPaginationGridState(newPaginationGridState);
        } else {
            setReportColumns([]);
            setReportData([]);
        }
    };

    const calcMaxPages = () => {
        var maxPages: number = 0;
        maxPages = Math.trunc(
            paginationGridState.totalRows / paginationGridState.rowsPerPage
        );
        if (
            maxPages * paginationGridState.rowsPerPage <
            paginationGridState.totalRows
        ) {
            maxPages = maxPages + 1;
        }
        return maxPages;
    };

    const getColumnsForReport = (data: any): Array<ColumnProps> => {
        const newColumns: Array<ColumnProps> = [];

        Object.keys(data).forEach((x, idx) => {
            if (idx % 2 === 0) {
                newColumns.push({
                    columnLabel: data[x],
                });
            }
        });
        return newColumns;
    };

    const getRowsForReport = (data: any): Array<any> => {
        const newColumns: Array<any> = [];

        Object.keys(data).forEach((x, idx) => {
            if (idx % 2 === 1) {
                newColumns.push({
                    columnValue: data[x],
                });
            }
        });
        return newColumns;
    };
    //123
    const getReportInfo = async (inputDTO: ReportDetailInputDTO) => {
        let serviceClient = new ReportsService();
        let expectedCodes: Array<number> = [200, 404];
        inputDTO.startRecord = 1;
        inputDTO.endRecord = 1000;
        setIputDTOTemp(inputDTO);
        setIsLoadingScreen(true);
        try {
            let responseRequest = await serviceClient.getReportData(
                inputDTO,
                false,
                true,
                expectedCodes
            );

            const data = responseRequest?.responseData?.data;

            if(responseRequest?.responseData?.responseCode === 404) {
                showToast(responseRequest?.responseData?.responseMessage.message, TPToastTypes.error);
            }

            const reportDetail = data?.[0]?.reportDetail || [];

            return [...reportDetail];            
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getReportInfo ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getReportInfo ex`);
            setIsLoadingScreen(false);
            return [];
        } finally {
            setIsLoadingScreen(false);
        }
    };
    const getReportPermissions = async () => {
        let serviceClient = new ReportsService();
        let expectedCodes: Array<number> = [200, 404];
        let userGuid= TPGlobal.currentUserGuid;
        try {
            let responseRequest = await serviceClient.getReportPermissions(
                userGuid,
                false,
                true,
                expectedCodes
            );
            setReportPermissions(responseRequest)
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getReportInfo ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getReportInfo ex`);
            setIsLoadingScreen(false);
            return [];
        }
    };

    const isReportVisible = (id_REPO: string): boolean => {
        return Array.isArray(reportPermissions) && reportPermissions.some((permission) => permission.id_REPO === id_REPO);
    };

    const generateExcelReport = (
        name: string,
        columns: { columnLabel: string }[],
        data: { [key: string]: any }[]
    ): void => {
        const dataFormat: { [key: string]: any }[] = [];
    
        data.forEach((item) => {
            const formattedObject: { [key: string]: any } = {};
    
            columns.forEach((column, index) => {
                const colKey = `col${index + 1}Value`;
                const columnLabel = column.columnLabel;
                formattedObject[columnLabel] = !!item[colKey] ? item[colKey] : '';
            });
    
            dataFormat.push(formattedObject);
        });
    
        exportDataToExcel(dataFormat, name);
    };
    

    const handleOtherSettingsColumns = () => {
        let newModalSettingColumnsState: ModalSettingsColumnsType = {
            ...modalSettingColumnsState,
        };

        newModalSettingColumnsState.isShown = true;
        newModalSettingColumnsState.localRowsPerPage =
            paginationGridState.rowsPerPage;
        setModalSettingColumnsState(newModalSettingColumnsState);
    };
    //123
    const handleCallbackAnswerModal = async (status: boolean) => {
        let newModalSettingColumnsState: ModalSettingsColumnsType = {
            ...modalSettingColumnsState,
        };
        newModalSettingColumnsState.isShown = false;
        setModalSettingColumnsState(newModalSettingColumnsState);
        if (status) {
            let newPageNumber: number;
            newPageNumber = 1;
            let inputDTO: ReportDetailInputDTO;
            inputDTO = getInputDTO(currentReportId);
            inputDTO.startRecord =
                (newPageNumber - 1) * modalSettingColumnsState.localRowsPerPage + 1;
            inputDTO.endRecord =
                newPageNumber * modalSettingColumnsState.localRowsPerPage;
            setIsLoadingScreen(true);
            let data = await getReportInfo(inputDTO);
            setIsLoadingScreen(false);

            if (data && data.length > 0) {
                let newReportColumns: Array<ColumnProps> = await getColumnsForReport(
                    data[0]
                );
                setReportColumns(newReportColumns);
                setReportData(data);

                let newPaginationGridState = { ...paginationGridState };
                newPaginationGridState.rowsPerPage =
                    modalSettingColumnsState.localRowsPerPage;
                newPaginationGridState.totalRows =
                    data[0][`col${newReportColumns.length.toString()}Value`];
                newPaginationGridState.currentPage = newPageNumber;
                setPaginationGridState(newPaginationGridState);
            } else {
                setReportColumns([]);
                setReportData([]);
            }
        }
    };

    useEffect(() => {
        loadResourcesAndOrganizations();
        getReportPermissions()
    }, []);

    const handleNextClick = () => {
        if (currentStep < 3 - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleStepClick = (stepIndex: number) => {
        setCurrentStep(stepIndex);
    };

    //#endregion

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const validateFields = () => {

        const areRequiredFieldsFilled =
            startDate &&
            endDate &&
            dateType &&
            privateCase;

        setIsButtonDisabled(!areRequiredFieldsFilled);
    };

    useEffect(() => {
        validateFields();
    }, [adminState, startDate, endDate, dateType, privateCase]);

    return (

        <div style={{ position: "relative", height:"100%" }} >
            <ContentVerticalNoTabsStyled style={{height:"85%"}}>
                <div className="container-fluid" style={{height: "100%"}}>
                    <TPLoadingOverlay active={isLoadingScreen}>
                        <TPPageTitle>{pageTitleLabel}</TPPageTitle>


                        <div className="row">
                            <div className="col">
                                <TPWizardStepper currentStep={currentStep}>
                                    <TPWizardStep
                                        id="tabFiltersReport"
                                        labelText={filterLabel}
                                        numberText="1"
                                        isActive={currentStep === 0}
                                        isCompleted={currentStep > 0}
                                        onClick={() => handleStepClick(0)}
                                    />
                                    <TPWizardContent
                                        id="tabFiltersReport"
                                        isActive={currentStep === 0}
                                    >
                                        <div className="row">
                                            <div className="col">
                                                <TPPageSectionTitle>
                                                    {organizationRelationLabel}
                                                </TPPageSectionTitle>
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginBottom:"15px"}}>
                                            <div className="col-11">
                                                <TPPageSection>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <TPSelect
                                                                    id="IdSelect"
                                                                    onChange={handlerOrganizationChange}
                                                                    dataSource={organizationListKeyValue}
                                                                    value={adminState.currentOrganization}
                                                                    labelText={organizationLabel}
                                                                    isHorizontal={false}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <TPSelect
                                                                    id="IdSelect"
                                                                    onChange={handlerRelationChange}
                                                                    dataSource={relationListKeyValue}
                                                                    value={adminState.currentRelation}
                                                                    labelText={relationLabel}
                                                                    isHorizontal={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TPPageSection>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <TPPageSectionTitle style={{marginTop:"20px", marginBottom:"20px"}}>
                                                    {quickSelectDatesLabel}
                                                </TPPageSectionTitle>
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginBottom:"15px"}}>
                                            <div className="col-11">
                                                <TPPageSection>
                                                    <div className="row" style={{ marginBottom:"15px"}}>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <TPRadioGroup
                                                                    id="IdRadioGroup"
                                                                    onChange={(e: any) =>
                                                                        changeDateSelectorHandler(e.target.value)
                                                                    }
                                                                    value={quickSelectDates}
                                                                    source={quickSelectDatesValue}
                                                                ></TPRadioGroup>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <TPDatePicker
                                                                    selectedDate={startDate}
                                                                    onChangeDate={(e: Date) =>
                                                                        handleChangeStartDate(e)
                                                                    }
                                                                    labelText={startDateLabel}
                                                                    isHorizontal={false}
                                                                    isMandatory={true}
                                                                ></TPDatePicker>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <TPDatePicker
                                                                    selectedDate={endDate}
                                                                    onChangeDate={(e: Date) =>
                                                                        handleChangeEndDate(e)
                                                                    }
                                                                    labelText={endDateLabel}
                                                                    isHorizontal={false}
                                                                    isMandatory={true}
                                                                ></TPDatePicker>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <TPSelect
                                                                    id="IdSelect"
                                                                    labelText={dateTypeLabel}
                                                                    isMandatory={true}
                                                                    value={dateType}
                                                                    onChange={(e: any) => {
                                                                        setDateType(e.target.value);
                                                                    }}
                                                                    dataSource={[
                                                                        {
                                                                            key: "0",
                                                                            value: createDateLabel,
                                                                        },
                                                                        {
                                                                            key: "1",
                                                                            value: updateDateLabel,
                                                                        },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TPPageSection>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <TPPageSectionTitle style={{marginTop:"20px", marginBottom:"20px"}}>
                                                    {moreFiltersLabel}
                                                </TPPageSectionTitle>
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginBottom:"15px"}}>
                                            <div className="col-11">
                                                <TPPageSection>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <TPSelect
                                                                            id="IdSelect"
                                                                            labelText={caseStatusLabel}
                                                                            isMandatory={false}
                                                                            value={caseStatus}
                                                                            onChange={(e: any) => {
                                                                                setCaseStatus(e.target.value);
                                                                            }}
                                                                            dataSource={statusesListKeyValue}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <TPSelect
                                                                        id="IdSelect"
                                                                        labelText={privateCaseLabel}
                                                                        isMandatory={true}
                                                                        value={privateCase}
                                                                        onChange={(e: any) => {
                                                                            setPrivateCase(e.target.value);
                                                                        }}
                                                                        dataSource={[
                                                                            {
                                                                                key: "2",
                                                                                value: IsActiveAll,
                                                                            },
                                                                            {
                                                                                key: "1",
                                                                                value: IsActiveYes,
                                                                            },
                                                                            {
                                                                                key: "0",
                                                                                value: IsActiveNo,
                                                                            },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TPPageSection>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-11 ">
                                                <TPPageAcceptCancelButtonsContainer>
                                                    <TPButton
                                                        id="nextStepButton1"
                                                        onClick={handleNextClick}
                                                        type={TPButtonTypes.primary}
                                                        disabled={isButtonDisabled}
                                                    >
                                                        {nextLabel}
                                                    </TPButton>
                                                </TPPageAcceptCancelButtonsContainer>
                                            </div>
                                        </div>
                                    </TPWizardContent>

                                    <TPWizardStep
                                        id="tabOtherFiltersReport"
                                        labelText={otherFiltersLabel}
                                        numberText="2"
                                        isActive={currentStep === 1}
                                        isCompleted={currentStep > 1}
                                        onClick={() => handleStepClick(1)}
                                    />
                                    <TPWizardContent
                                        id="tabOtherFiltersReport"
                                        isActive={currentStep === 1}
                                    >
                                        <div className="row">
                                            <div className="col">
                                                <TPPageSectionTitle>
                                                    {classificatorLabel}
                                                </TPPageSectionTitle>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-11">
                                                <TPPageSection>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            {/* Classificator 1 */}
                                                            {adminState.currentRelation === "" &&
                                                                adminState.currentTree1 !==
                                                                TPGlobal.Tree.NA_TreeCode ? (
                                                                <>
                                                                    <div className="col-12 mt-2">
                                                                        <div className="form-group">
                                                                            <span className="tpcircle-text">1</span>
                                                                            <TPTextBox
                                                                                id="IdTextBox"
                                                                                onChange={() => {
                                                                                    TPGlobal.foo();
                                                                                }}
                                                                                value=""
                                                                                disabled={true}
                                                                                labelText={dummyClassfierLabel}
                                                                                withCircleText
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {adminState.currentTree1 !==
                                                                        TPGlobal.Tree.NA_TreeCode && (
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <TPBranchSelectionTree
                                                                                        withDelete={false}
                                                                                        onDeleteClick={(treeId: string) => {
                                                                                            handleDeleteConditionGroupClick(1);
                                                                                        }}
                                                                                        ref={(element: any) => {
                                                                                            branch1SelectorRef.current = element;
                                                                                        }}
                                                                                        labelText={adminState.currentLabelTree1}
                                                                                        treeId={adminState.currentTree1}
                                                                                        modalAcceptLabel={modalAcceptLabel}
                                                                                        modalCancelLabel={modalCancelLabel}
                                                                                        modalSelectedBranchLabel={
                                                                                            modalSelectedBranchLabel
                                                                                        }
                                                                                        modalTitle={modalTitleLabel}
                                                                                        mustSelectLastLevelBranch={
                                                                                            adminState.mustSelectLastLevelBranch1
                                                                                        }
                                                                                        value={{
                                                                                            branchId: adminState.currentBranch1,
                                                                                            branchDescription:
                                                                                                adminState.currentDescriptionBranch1,
                                                                                            branchHierarchyDescription:
                                                                                                defaultBranchHierarchyDescription1,
                                                                                        }}
                                                                                        onChange={(
                                                                                            branchId: string,
                                                                                            branchDescription: string,
                                                                                            branchHierachyDescription: string
                                                                                        ) => {
                                                                                            handleOnBranchChange(
                                                                                                adminState.currentLabelTree1,
                                                                                                1,
                                                                                                branchId,
                                                                                                branchDescription,
                                                                                                branchHierachyDescription
                                                                                            );
                                                                                        }}
                                                                                        emptyLabel={emptyLabel}
                                                                                        errorMessage="" //todo
                                                                                        autoCloseTreeModalWhenSelect={true}
                                                                                        onIsLoadingProgress={(
                                                                                            value: boolean
                                                                                        ) => {
                                                                                            setIsLoadingScreen(value);
                                                                                        }}
                                                                                    ></TPBranchSelectionTree>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="col-6">
                                                            {/* Classificator 2 */}
                                                            {adminState.currentRelation === "" &&
                                                                adminState.currentTree2 !==
                                                                TPGlobal.Tree.NA_TreeCode ? (
                                                                <>
                                                                    <div className="col-12 mt-2">
                                                                        <div className="form-group">
                                                                            <span className="tpcircle-text">2</span>

                                                                            {"-"}

                                                                            <TPTextBox
                                                                                id="IdTextBox"
                                                                                onChange={() => {
                                                                                    TPGlobal.foo();
                                                                                }}
                                                                                value=""
                                                                                disabled={true}
                                                                                labelText={dummyClassfierLabel}
                                                                                withCircleText
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {adminState.currentTree2 !==
                                                                        TPGlobal.Tree.NA_TreeCode && (
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <TPBranchSelectionTree
                                                                                        withDelete={false}
                                                                                        onDeleteClick={(treeId: string) => {
                                                                                            handleDeleteConditionGroupClick(2);
                                                                                        }}
                                                                                        ref={(element: any) => {
                                                                                            branch2SelectorRef.current = element;
                                                                                        }}
                                                                                        labelText={adminState.currentLabelTree2}
                                                                                        treeId={adminState.currentTree2}
                                                                                        modalAcceptLabel={modalAcceptLabel}
                                                                                        modalCancelLabel={modalCancelLabel}
                                                                                        modalSelectedBranchLabel={
                                                                                            modalSelectedBranchLabel
                                                                                        }
                                                                                        modalTitle={modalTitleLabel}
                                                                                        mustSelectLastLevelBranch={
                                                                                            adminState.mustSelectLastLevelBranch2
                                                                                        }
                                                                                        value={{
                                                                                            branchId: adminState.currentBranch2,
                                                                                            branchDescription:
                                                                                                adminState.currentDescriptionBranch2,
                                                                                            branchHierarchyDescription:
                                                                                                defaultBranchHierarchyDescription2,
                                                                                        }}
                                                                                        onChange={(
                                                                                            branchId: string,
                                                                                            branchDescription: string,
                                                                                            branchHierachyDescription: string
                                                                                        ) => {
                                                                                            handleOnBranchChange(
                                                                                                adminState.currentLabelTree2,
                                                                                                2,
                                                                                                branchId,
                                                                                                branchDescription,
                                                                                                branchHierachyDescription
                                                                                            );
                                                                                        }}
                                                                                        emptyLabel={emptyLabel}
                                                                                        errorMessage="" //todo
                                                                                        autoCloseTreeModalWhenSelect={true}
                                                                                        onIsLoadingProgress={(
                                                                                            value: boolean
                                                                                        ) => {
                                                                                            setIsLoadingScreen(value);
                                                                                        }}
                                                                                    ></TPBranchSelectionTree>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            {/* Classificator 3 */}
                                                            {adminState.currentRelation === "" &&
                                                                adminState.currentTree3 !==
                                                                TPGlobal.Tree.NA_TreeCode ? (
                                                                <>
                                                                    <div className="col-12 mt-2">
                                                                        <div className="form-group">
                                                                            <span className="tpcircle-text">3</span>

                                                                            {"-"}

                                                                            <TPTextBox
                                                                                id="IdTextBox"
                                                                                onChange={() => {
                                                                                    TPGlobal.foo();
                                                                                }}
                                                                                value=""
                                                                                disabled={true}
                                                                                labelText={dummyClassfierLabel}
                                                                                withCircleText
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {adminState.currentTree3 !==
                                                                        TPGlobal.Tree.NA_TreeCode && (
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <TPBranchSelectionTree
                                                                                        withDelete={false}
                                                                                        onDeleteClick={(treeId: string) => {
                                                                                            handleDeleteConditionGroupClick(3);
                                                                                        }}
                                                                                        ref={(element: any) => {
                                                                                            branch3SelectorRef.current = element;
                                                                                        }}
                                                                                        labelText={adminState.currentLabelTree3}
                                                                                        treeId={adminState.currentTree3}
                                                                                        modalAcceptLabel={modalAcceptLabel}
                                                                                        modalCancelLabel={modalCancelLabel}
                                                                                        modalSelectedBranchLabel={
                                                                                            modalSelectedBranchLabel
                                                                                        }
                                                                                        modalTitle={modalTitleLabel}
                                                                                        mustSelectLastLevelBranch={
                                                                                            adminState.mustSelectLastLevelBranch3
                                                                                        }
                                                                                        value={{
                                                                                            branchId: adminState.currentBranch3,
                                                                                            branchDescription:
                                                                                                adminState.currentDescriptionBranch3,
                                                                                            branchHierarchyDescription:
                                                                                                defaultBranchHierarchyDescription3,
                                                                                        }}
                                                                                        onChange={(
                                                                                            branchId: string,
                                                                                            branchDescription: string,
                                                                                            branchHierachyDescription: string
                                                                                        ) => {
                                                                                            handleOnBranchChange(
                                                                                                adminState.currentLabelTree3,
                                                                                                3,
                                                                                                branchId,
                                                                                                branchDescription,
                                                                                                branchHierachyDescription
                                                                                            );
                                                                                        }}
                                                                                        emptyLabel={emptyLabel}
                                                                                        errorMessage="" //todo
                                                                                        autoCloseTreeModalWhenSelect={true}
                                                                                        onIsLoadingProgress={(
                                                                                            value: boolean
                                                                                        ) => {
                                                                                            setIsLoadingScreen(value);
                                                                                        }}
                                                                                    ></TPBranchSelectionTree>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="col-6">
                                                            {/* Classificator 4 */}
                                                            {adminState.currentRelation === "" &&
                                                                adminState.currentTree4 !==
                                                                TPGlobal.Tree.NA_TreeCode ? (
                                                                <>
                                                                    <div className="col-12 mt-2">
                                                                        <div className="form-group">
                                                                            <span className="tpcircle-text">4</span>

                                                                            {"-"}

                                                                            <TPTextBox
                                                                                id="IdTextBox"
                                                                                onChange={() => {
                                                                                    TPGlobal.foo();
                                                                                }}
                                                                                value=""
                                                                                disabled={true}
                                                                                labelText={dummyClassfierLabel}
                                                                                withCircleText
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {adminState.currentTree4 !==
                                                                        TPGlobal.Tree.NA_TreeCode && (
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <TPBranchSelectionTree
                                                                                        withDelete={false}
                                                                                        onDeleteClick={(treeId: string) => {
                                                                                            handleDeleteConditionGroupClick(4);
                                                                                        }}
                                                                                        ref={(element: any) => {
                                                                                            branch4SelectorRef.current = element;
                                                                                        }}
                                                                                        labelText={adminState.currentLabelTree4}
                                                                                        treeId={adminState.currentTree4}
                                                                                        modalAcceptLabel={modalAcceptLabel}
                                                                                        modalCancelLabel={modalCancelLabel}
                                                                                        modalSelectedBranchLabel={
                                                                                            modalSelectedBranchLabel
                                                                                        }
                                                                                        modalTitle={modalTitleLabel}
                                                                                        mustSelectLastLevelBranch={
                                                                                            adminState.mustSelectLastLevelBranch4
                                                                                        }
                                                                                        value={{
                                                                                            branchId: adminState.currentBranch4,
                                                                                            branchDescription:
                                                                                                adminState.currentDescriptionBranch4,
                                                                                            branchHierarchyDescription:
                                                                                                defaultBranchHierarchyDescription4,
                                                                                        }}
                                                                                        onChange={(
                                                                                            branchId: string,
                                                                                            branchDescription: string,
                                                                                            branchHierachyDescription: string
                                                                                        ) => {
                                                                                            handleOnBranchChange(
                                                                                                adminState.currentLabelTree4,
                                                                                                4,
                                                                                                branchId,
                                                                                                branchDescription,
                                                                                                branchHierachyDescription
                                                                                            );
                                                                                        }}
                                                                                        emptyLabel={emptyLabel}
                                                                                        errorMessage="" //todo
                                                                                        autoCloseTreeModalWhenSelect={true}
                                                                                        onIsLoadingProgress={(
                                                                                            value: boolean
                                                                                        ) => {
                                                                                            setIsLoadingScreen(value);
                                                                                        }}
                                                                                    ></TPBranchSelectionTree>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            {/* Classificator 5 */}
                                                            {adminState.currentRelation === "" &&
                                                                adminState.currentTree5 !==
                                                                TPGlobal.Tree.NA_TreeCode ? (
                                                                <>
                                                                    <div className="col-12 mt-2">
                                                                        <div className="form-group">
                                                                            <span className="tpcircle-text">5</span>

                                                                            {"-"}

                                                                            <TPTextBox
                                                                                id="IdTextBox"
                                                                                onChange={() => {
                                                                                    TPGlobal.foo();
                                                                                }}
                                                                                value=""
                                                                                disabled={true}
                                                                                labelText={dummyClassfierLabel}
                                                                                withCircleText
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {adminState.currentTree5 !==
                                                                        TPGlobal.Tree.NA_TreeCode && (
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <TPBranchSelectionTree
                                                                                        withDelete={false}
                                                                                        onDeleteClick={(treeId: string) => {
                                                                                            handleDeleteConditionGroupClick(5);
                                                                                        }}
                                                                                        ref={(element: any) => {
                                                                                            branch5SelectorRef.current = element;
                                                                                        }}
                                                                                        labelText={adminState.currentLabelTree5}
                                                                                        treeId={adminState.currentTree5}
                                                                                        modalAcceptLabel={modalAcceptLabel}
                                                                                        modalCancelLabel={modalCancelLabel}
                                                                                        modalSelectedBranchLabel={
                                                                                            modalSelectedBranchLabel
                                                                                        }
                                                                                        modalTitle={modalTitleLabel}
                                                                                        mustSelectLastLevelBranch={
                                                                                            adminState.mustSelectLastLevelBranch5
                                                                                        }
                                                                                        value={{
                                                                                            branchId: adminState.currentBranch5,
                                                                                            branchDescription:
                                                                                                adminState.currentDescriptionBranch5,
                                                                                            branchHierarchyDescription:
                                                                                                defaultBranchHierarchyDescription5,
                                                                                        }}
                                                                                        onChange={(
                                                                                            branchId: string,
                                                                                            branchDescription: string,
                                                                                            branchHierachyDescription: string
                                                                                        ) => {
                                                                                            handleOnBranchChange(
                                                                                                adminState.currentLabelTree5,
                                                                                                5,
                                                                                                branchId,
                                                                                                branchDescription,
                                                                                                branchHierachyDescription
                                                                                            );
                                                                                        }}
                                                                                        emptyLabel={emptyLabel}
                                                                                        errorMessage="" //todo
                                                                                        autoCloseTreeModalWhenSelect={true}
                                                                                        onIsLoadingProgress={(
                                                                                            value: boolean
                                                                                        ) => {
                                                                                            setIsLoadingScreen(value);
                                                                                        }}
                                                                                    ></TPBranchSelectionTree>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="row" style={{marginTop:"30px"}}>
                                                        <TPConditionsDinamycTable
                                                            conditions={filtersSelected}
                                                            onDelete={(e: any) => handleDeleteCondition(e)}
                                                            deletable={true}
                                                            showQuery={true}
                                                        />
                                                    </div>
                                                </TPPageSection>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-11 ">
                                                <TPPageAcceptCancelButtonsContainer>
                                                    <TPButton
                                                        id="nextStepButton2"
                                                        onClick={handleNextClick}
                                                        type={TPButtonTypes.primary}
                                                    >
                                                        {nextLabel}
                                                    </TPButton>
                                                </TPPageAcceptCancelButtonsContainer>
                                            </div>
                                        </div>
                                    </TPWizardContent>

                                    {/* hide summary step*/}
                                    <div className="tp-none">
                                        <TPWizardStep
                                            id="tabSummaryReport"
                                            labelText={resultsLabel}
                                            numberText="3"
                                            isActive={currentStep === 2}
                                            isCompleted={currentStep > 2}
                                            onClick={() => handleStepClick(2)}
                                        />
                                        <TPWizardContent
                                            id="tabSummaryReport"
                                            isActive={currentStep === 2}
                                        >
                                            <div className="row">
                                                <div className="col">
                                                    <TPPageSectionTitle>
                                                        {quickSelectDatesLabel}
                                                    </TPPageSectionTitle>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-11">
                                                    <TPPageSection>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <TPDatePicker
                                                                        selectedDate={startDate}
                                                                        onChangeDate={(e: Date) =>
                                                                            handleChangeStartDate(e)
                                                                        }
                                                                        labelText={startDateLabel}
                                                                        isHorizontal={false}
                                                                        isMandatory={false}
                                                                        disabled={true}
                                                                    ></TPDatePicker>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <TPDatePicker
                                                                        selectedDate={endDate}
                                                                        onChangeDate={() => { }}
                                                                        labelText={endDateLabel}
                                                                        isHorizontal={false}
                                                                        isMandatory={false}
                                                                        disabled={true}
                                                                    ></TPDatePicker>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <TPSelect
                                                                        id="IdSelect"
                                                                        labelText={dateTypeLabel}
                                                                        isMandatory={false}
                                                                        value={dateType}
                                                                        onChange={() => { }}
                                                                        dataSource={[
                                                                            {
                                                                                key: "0",
                                                                                value: createDateLabel,
                                                                            },
                                                                            {
                                                                                key: "1",
                                                                                value: updateDateLabel,
                                                                            },
                                                                        ]}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TPPageSection>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <TPPageSectionTitle>
                                                        {moreFiltersLabel}
                                                    </TPPageSectionTitle>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-11">
                                                    <TPPageSection>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className="form-group">
                                                                            <TPSelect
                                                                                id="IdSelect"
                                                                                labelText={caseStatusLabel}
                                                                                isMandatory={false}
                                                                                value={caseStatus}
                                                                                onChange={() => { }}
                                                                                dataSource={statusesListKeyValue}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <TPSelect
                                                                            labelText={privateCaseLabel}
                                                                            isMandatory={true}
                                                                            value={privateCase}
                                                                            onChange={() => { }}
                                                                            dataSource={[
                                                                                {
                                                                                    key: "2",
                                                                                    value: IsActiveAll,
                                                                                },
                                                                                {
                                                                                    key: "1",
                                                                                    value: IsActiveYes,
                                                                                },
                                                                                {
                                                                                    key: "0",
                                                                                    value: IsActiveNo,
                                                                                },
                                                                            ]}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TPPageSection>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <TPPageSectionTitle>
                                                        {classificatorLabel}
                                                    </TPPageSectionTitle>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-11">
                                                    <TPPageSection>
                                                        <TPConditionsTable
                                                            conditions={filtersSelected}
                                                            onDelete={handleDeleteCondition}
                                                            deletable={false}
                                                            showQuery={false}
                                                        />
                                                    </TPPageSection>
                                                </div>
                                            </div>
                                        </TPWizardContent>
                                    </div>

                                    <TPWizardStep
                                        id="tabReportsReport"
                                        labelText={resultsLabel}
                                        numberText="3"
                                        isActive={currentStep === 2}
                                        isCompleted={currentStep > 2}
                                        onClick={() => handleStepClick(2)}
                                    />

                                    <TPWizardContent
                                        id="tabReportsReport"
                                        isActive={currentStep === 2}
                                    >
                                        {/* TODO get endpoint to show reports ist dynamic list*/}
                                        <div className="row">
                                            <div className="col">
                                                <TPPageSectionTitle>
                                                    {selectReportLabel}
                                                </TPPageSectionTitle>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center gap-2" >
                                            <TPSelect
                                                id="reportsSelect"
                                                minWidth={300}
                                                maxWidth={300}
                                                value={selectedReport}
                                                dataSource={[
                                                    {key: "", value: "Select"},
                                                    {
                                                        key: ReportIdNames.REPOSTANDCADE,
                                                        value: reportLabel + " 1: " + caseDetailsLabel,
                                                        show: isReportVisible(ReportIdNames.REPOSTANDCADE)
                                                    },
                                                    {
                                                        key: ReportIdNames.REPOSTANDCADEWITHADDA,
                                                        value: reportLabel + " 2: " + caseDetailsWithADLabel,
                                                        show: isReportVisible(ReportIdNames.REPOSTANDCADEWITHADDA)
                                                    },
                                                    {
                                                        key: ReportIdNames.REPOSTANDCADEWITHTASK,
                                                        value: reportLabel + " 3: " + caseDetailWithTasksLabel,
                                                        show: isReportVisible(ReportIdNames.REPOSTANDCADEWITHTASK)
                                                    },
                                                    {
                                                        key: ReportIdNames.REPOSTANDAVERAGERESPON,
                                                        value: reportLabel + " 4: " + averageTimeResolvingLabel,
                                                        show: isReportVisible(ReportIdNames.REPOSTANDAVERAGERESPON)
                                                    },
                                                    {
                                                        key: ReportIdNames.REPOSTANTASKCOMPLETED,
                                                        value: reportLabel + " 5: " + tasksCompletedOnTimeLabel,
                                                        show: isReportVisible(ReportIdNames.REPOSTANTASKCOMPLETED)
                                                    },
                                                    {
                                                        key: ReportIdNames.REPOSTANCASETIMERDETAILS,
                                                        value: reportLabel + " 6: " + reportCaseTimerDetailsLabel,
                                                        show: isReportVisible(ReportIdNames.REPOSTANCASETIMERDETAILS),
                                                    },
                                                    {
                                                        key: ReportIdNames.REPOSTANCASETIMERSUMMARY,
                                                        value: reportLabel + " 7: " + reportCaseTimerSummaryLabel,
                                                        show: isReportVisible(ReportIdNames.REPOSTANCASETIMERSUMMARY),
                                                    },
                                                    {
                                                        key: ReportIdNames.REPOSTANACTIVITYTRACKED,
                                                        value: reportLabel + " 8: " + timeTrackingLabel,
                                                        show: isReportVisible(ReportIdNames.REPOSTANACTIVITYTRACKED),
                                                    },
                                                ].filter(item => item.show).map(item => ({
                                                    key: item.key,
                                                    value: item.value
                                                }))}

                                                onChange={(e: any) => {
                                                    setSelectedReport(e.target.value);
                                                }}
                                            />
                                            <div>
                                                <TPSelect
                                                    id="IdSelect"
                                                    disabled={!(selectedReport === ReportIdNames.REPOSTANDAVERAGERESPON && relationSelected.length > 0)}
                                                    dataSource={[
                                                        {key: "", value: treeToGroupReportLabel},
                                                        {
                                                            key: "1",
                                                            value: adminState.currentLabelTree1,
                                                        },
                                                        {
                                                            key: "2",
                                                            value: adminState.currentLabelTree2,
                                                        },
                                                        {
                                                            key: "3",
                                                            value: adminState.currentLabelTree3,
                                                        },
                                                        {
                                                            key: "4",
                                                            value: adminState.currentLabelTree4,
                                                        },
                                                        {
                                                            key: "5",
                                                            value: adminState.currentLabelTree5,
                                                        },
                                                    ]}
                                                    value={parameter1Selected}
                                                    onChange={(e: any) =>
                                                        setParameter1Selected(e.target.value)
                                                    }
                                                />
                                            </div>

                                            <TPButton style={{alignSelf: "end"}}
                                                      id="applyButton"
                                                      onClick={() =>
                                                          handleGenerateReport(selectedReport)
                                                      }
                                                      type={TPButtonTypes.primary}
                                                      disabled={!selectedReport}
                                            >
                                                {applyButtonLabel}
                                            </TPButton>
                                        </div>

                                        <br></br>
                                        {selectedReport === ReportIdNames.REPOSTANDAVERAGERESPON && (
                                            <div className="alert alert-info d-flex align-items-center">
                                                <TPIcon iconType={TPIconTypes.info}/>
                                                <span className="mb-0 ms-2">
                                                  <TPLanguage
                                                      resourceId="ShowAverageTimeLabel"
                                                      resourceSet={resourceSet}
                                                  />
                                                </span>
                                            </div>
                                        )}

                                        {selectedReport === ReportIdNames.REPOSTANTASKCOMPLETED && (
                                            <div className="alert alert-info d-flex align-items-center">
                                                <TPIcon iconType={TPIconTypes.info}/>
                                                <span className="mb-0 ms-2">
                                                  <TPLanguage
                                                      resourceId="ShowsSummaryPendingSolved"
                                                      resourceSet={resourceSet}
                                                  />
                                                </span>
                                            </div>
                                        )}

                                        {selectedReport === ReportIdNames.REPOSTANCASETIMERDETAILS && (
                                            <div className="alert alert-info d-flex align-items-center">
                                                <TPIcon iconType={TPIconTypes.info}/>
                                                <span className="mb-0 ms-2">
                                                  <TPLanguage
                                                      resourceId="ShowsReportCaseTimerDetailsLabel"
                                                      resourceSet={resourceSet}
                                                  />
                                                </span>
                                            </div>
                                        )}

                                        {selectedReport === ReportIdNames.REPOSTANCASETIMERSUMMARY && (
                                            <div className="alert alert-info d-flex align-items-center">
                                                <TPIcon iconType={TPIconTypes.info}/>
                                                <span className="mb-0 ms-2">
                                                  <TPLanguage
                                                      resourceId="ShowsReportCaseTimerSummaryLabel"
                                                      resourceSet={resourceSet}
                                                  />
                                                </span>
                                            </div>
                                        )}

                                        {selectedReport === ReportIdNames.REPOSTANACTIVITYTRACKED && (
                                            <div className="alert alert-info d-flex align-items-center">
                                                <TPIcon iconType={TPIconTypes.info}/>
                                                <span  className="mb-0 ms-2">
                                                  <TPLanguage
                                                      resourceId="ShowsInformationTimeTrackingLabel"
                                                      resourceSet={resourceSet}
                                                  />
                                                </span>
                                            </div>
                                        )}

                                        <div>
                                            {bodyText != "" &&
                                                <PopupContainerStyle style={{width: "100%", right: "1%"}}
                                                                     onClick={() => {
                                                                         seBodyText("")
                                                                     }}>
                                                    <PopupContainerChildreStyle>
                                                        <TPIcon iconType={TPIconTypes.close} style={{
                                                            display: 'flex',
                                                            right: '0px',
                                                            position: 'absolute',
                                                            height: '40px',
                                                            width: '40px',
                                                            backgroundColor: '#a00095',
                                                            color: "white",
                                                            textAlign: 'center',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            boxShadow: '0px 0px 5px gray',
                                                            borderRadius: '5px',
                                                            cursor: 'pointer'
                                                        }} onClick={() => {
                                                            seBodyText("")
                                                        }}/>
                                                        <ContainerBodyStyle>
                                                            <span dangerouslySetInnerHTML={{__html: bodyText}}/>
                                                        </ContainerBodyStyle>
                                                    </PopupContainerChildreStyle>
                                                </PopupContainerStyle>
                                            }

                                            <br></br>

                                            {!selectedReport &&(
                                                <TableNotFound
                                                    isSearchData
                                                    newOneLabel={secondaryNoDataMessageLabel}
                                                    noResultsLabel={"noResultsLabel"}
                                                    text={noDataMessageLabel}
                                                />
                                            )}

                                            {
                                                ['REPOSTANDAVERAGERESPON'].includes(currentReportId) && (
                                                    !!reportDataDetail4?.length ? (
                                                        <DynamicTable
                                                            id="DynamicTableStandarReport-REPOSTANTASKCOMPLETED"
                                                            data={reportDataDetail4}
                                                            columnStyles={columnStylesReport4}
                                                            exportNameFile={reportNameSelected}
                                                            headerAlignment={{Total: 'center'}}
                                                            // hiddenColumns={['xRowNumber', 'TotalRecords_CASE']}
                                                            hiddenColumns={[]}
                                                            onIconClicked={handleIconClick}
                                                            hideAllTableOnNoData
                                                            withPreferences
                                                            hiddenSearch={true}
                                                            style={{height:"auto"}}
                                                            noDataMessage={noDataMessageTableLabel}
                                                            secondaryNoDataMessage={secondaryNoDataMessageTableLabel}
                                                        >
                                                            <TPIcon
                                                                iconType={TPIconTypes.close}
                                                                onClick={() => setReportDataDetail4([])}
                                                                style={{
                                                                    textAlign: 'center',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                        </DynamicTable>
                                                    ) : (
                                                        <DynamicTable
                                                            id="DynamicTableStandarReport-REPOSTANTASKCOMPLETED"
                                                            data={dataGridReport4}
                                                            columnStyles={columnStylesReport4}
                                                            exportNameFile={reportNameSelected}
                                                            headerAlignment={headerAlignmentReport4}
                                                            hiddenColumns={[]}
                                                            hideAllTableOnNoData
                                                            // hiddenColumns={['xRowNumber', 'TotalRecords_CASE', 'Id_ORGA']}
                                                            onIconClicked={handleIconClick}
                                                            withPreferences
                                                            hiddenSearch={true}
                                                            style={{height:"auto"}}
                                                            noDataMessage={noDataMessageTableLabel}
                                                            secondaryNoDataMessage={secondaryNoDataMessageTableLabel}
                                                        />
                                                    )
                                                )
                                            }

                                            {
                                                ['REPOSTANTASKCOMPLETED', 'REPOSTANCASETIMERSUMMARY', 'REPOSTANDCADE', 'REPOSTANDCADEWITHADDA', 'REPOSTANDCADEWITHTASK', 'REPOSTANCASETIMERDETAILS'].includes(currentReportId) && (
                                                    !!reportDataDetail5?.length ? (
                                                        <DynamicTable
                                                            id="DynamicTableStandarReport-REPOSTANTASKCOMPLETED"
                                                            data={reportDataDetail5}
                                                            columnStyles={columnStylesReport5}
                                                            exportNameFile={reportNameSelected}
                                                            hideAllTableOnNoData
                                                            hiddenColumns={['Id_USER', 'TotalRecords_CASE']}
                                                            onIconClicked={handleIconClick}
                                                            withPreferences
                                                            hiddenSearch={true}
                                                            style={{height:"auto"}}
                                                            noDataMessage={noDataMessageTableLabel}
                                                            secondaryNoDataMessage={secondaryNoDataMessageTableLabel}
                                                        >
                                                            <TPIcon
                                                                iconType={TPIconTypes.close}
                                                                onClick={() => setReportDataDetail5([])}
                                                                style={{
                                                                    textAlign: 'center',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                        </DynamicTable>
                                                    ) : (
                                                        <DynamicTable
                                                            id="DynamicTableStandarReport-REPOSTANTASKCOMPLETED"
                                                            data={dataGridReport5}
                                                            columnStyles={columnStylesReport5}
                                                            exportNameFile={reportNameSelected}
                                                            headerAlignment={headerAlignmentReport5}
                                                            hideAllTableOnNoData
                                                            hiddenColumns={['Id_USER', 'TotalRecords_CASE']}
                                                            onIconClicked={handleIconClick}
                                                            withPreferences
                                                            hiddenSearch={true}
                                                            style={{height:"auto"}}
                                                            noDataMessage={noDataMessageTableLabel}
                                                            secondaryNoDataMessage={secondaryNoDataMessageTableLabel}

                                                        />
                                                    )
                                                )
                                            }

                                            {
                                                ['REPOSTANACTIVITYTRACKED'].includes(currentReportId) && (
                                                    <DynamicTable
                                                        id={"DynamicTableStandarReport"}
                                                        data={handleGetDataGrid()}
                                                        columnNames={treeColumns}
                                                        columnStyles={columnStyles}
                                                        hiddenSearch={true}
                                                        hideAllTableOnNoData
                                                        hiddenColumns={['col10Value',
                                                            'col11Value',
                                                            'col12Value',
                                                            'col13Value',
                                                            'col14Value',
                                                            'col15Value',
                                                            'col16Value',
                                                            'col17Value',
                                                            'col18Value',
                                                            'col19Value',
                                                            'col20Value',
                                                            'col21Value',
                                                            'col22Value',
                                                            'col23Value',
                                                            'col24Value',
                                                            'col25Value',
                                                            'col26Value',
                                                            'col27Value',
                                                            'col28Value',
                                                            'col29Value',
                                                            'col30Value',
                                                            'col31Value',]}
                                                        exportNameFile={reportNameSelected}
                                                        onIconClicked={event => handleIconClick(event)}
                                                        style={{height:"auto"}}
                                                        noDataMessage={noDataMessageTableLabel}
                                                        secondaryNoDataMessage={secondaryNoDataMessageTableLabel}
                                                    />
                                                )
                                            }

                                            {/* {
                                                !['REPOSTANDAVERAGERESPON', 'REPOSTANTASKCOMPLETED', 'REPOSTANACTIVITYTRACKED'].includes(currentReportId) && (
                                                    <DynamicTable
                                                        id={"DynamicTableStandarReport"}
                                                        data={handleGetDataGrid()}
                                                        columnNames={treeColumns}
                                                        columnStyles={columnStyles}
                                                        hiddenColumns={['col22Value', 'col25Value']}
                                                        exportNameFile={reportNameSelected}
                                                        onIconClicked={event => handleIconClick(event)}
                                                        hideAllTableOnNoData
                                                    />
                                                )
                                            } */}

                                            {/*<div className="row" style={{display: "flex"}}>
                                                <div className="col">
                                                    <div className="table-responsive">
                                                        <table
                                                            className="tptable-report table table-striped table-sm table-bordered caption-top">
                                                            <caption style={{fontSize: "18px"}}>
                                                                {reportNameSelected}
                                                            </caption>
                                                             123

                                                                  <TPLabel labelText={item.columnLabel} />
                                                            <thead>
                                                            <tr>
                                                                {reportColumns.map(function (item, idx) {
                                                                    if (idx === reportColumns.length - 1) {
                                                                        return null;
                                                                    }
                                                                    return (
                                                                        <th key={`th${idx}`}>
                                                                            <span>{item.columnLabel}</span>
                                                                        </th>
                                                                    );
                                                                })}
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {reportData.map(function (item) {
                                                                let row = getRowsForReport(item);
                                                                return (
                                                                    <tr>
                                                                        {row.map(function (itemRow, idx) {
                                                                            if (idx === row.length - 1) {
                                                                                return null;
                                                                            }
                                                                            return (
                                                                                <td
                                                                                    key={`td${idx}`}
                                                                                    style={{whiteSpace: "nowrap"}}
                                                                                >
                                                                                        <span
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: itemRow.columnValue,
                                                                                            }}
                                                                                        ></span>
                                                                                </td>
                                                                            );
                                                                        })}
                                                                    </tr>
                                                                );
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    {reportData && reportData.length > 0 && (
                                                        <div
                                                            style={{fontSize: "13px"}}
                                                            className="col d-flex justify-content-end align-items-center pt-2"
                                                        >
                                                            <div>
                                                                <span style={{color: "gray"}}>
                                                                    {(paginationGridState.currentPage - 1) *
                                                                        paginationGridState.rowsPerPage +
                                                                        1}
                                                                    &nbsp;-&nbsp;
                                                                    {(paginationGridState.currentPage - 1) *
                                                                    paginationGridState.rowsPerPage +
                                                                    paginationGridState.rowsPerPage <
                                                                    paginationGridState.totalRows
                                                                        ? (paginationGridState.currentPage - 1) *
                                                                        paginationGridState.rowsPerPage +
                                                                        paginationGridState.rowsPerPage
                                                                        : paginationGridState.totalRows}
                                                                    &nbsp;&nbsp;{totalRowsLabel}&nbsp;
                                                                    {paginationGridState.totalRows}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span
                                                                    className="page-button"
                                                                    style={
                                                                        paginationGridState.currentPage === 1
                                                                            ? {color: "gray"}
                                                                            : {color: "#a00095"}
                                                                    }
                                                                >
                                                                    <TPIcon
                                                                        iconType={TPIconTypes.firstPage}
                                                                        style={{fontSize: "25px", cursor: "pointer"}}
                                                                        onClick={() => {
                                                                            if (paginationGridState.currentPage === 1) {
                                                                                return;
                                                                            }
                                                                            handleChangePage("FIRST");
                                                                        }}
                                                                    ></TPIcon>
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span
                                                                    className="page-button"
                                                                    style={
                                                                        paginationGridState.currentPage === 1
                                                                            ? {color: "gray"}
                                                                            : {color: "#a00095"}
                                                                    }
                                                                >
                                                                    <TPIcon
                                                                        onClick={() => {
                                                                            if (paginationGridState.currentPage === 1) {
                                                                                return;
                                                                            }
                                                                            handleChangePage("PREVIOUS");
                                                                        }}
                                                                        iconType={TPIconTypes.chevronLeft}
                                                                        style={{fontSize: "25px", cursor: "pointer"}}
                                                                    />
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span
                                                                    className="page-button"
                                                                    style={
                                                                        paginationGridState.currentPage < calcMaxPages()
                                                                            ? {color: "#a00095"}
                                                                            : {color: "gray"}
                                                                    }
                                                                >
                                                                    <TPIcon
                                                                        onClick={() => {
                                                                            if (
                                                                                paginationGridState.currentPage ===
                                                                                calcMaxPages()
                                                                            ) {
                                                                                return;
                                                                            }
                                                                            handleChangePage("NEXT");
                                                                        }}
                                                                        iconType={TPIconTypes.chevronRight}
                                                                        style={{fontSize: "25px", cursor: "pointer"}}
                                                                    />
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span
                                                                    className="page-button"
                                                                    style={
                                                                        paginationGridState.currentPage < calcMaxPages()
                                                                            ? {color: "#a00095"}
                                                                            : {color: "gray"}
                                                                    }
                                                                >
                                                                    <TPIcon
                                                                        iconType={TPIconTypes.lastPage}
                                                                        style={{fontSize: "25px", cursor: "pointer"}}
                                                                        onClick={() => {
                                                                            if (
                                                                                paginationGridState.currentPage ===
                                                                                calcMaxPages()
                                                                            ) {
                                                                                return;
                                                                            }
                                                                            handleChangePage("LAST");
                                                                        }}
                                                                    ></TPIcon>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>*/}

                                        </div>
                                    </TPWizardContent>
                                </TPWizardStepper>
                                {modalSettingColumnsState.isShown && (
                                    <TPModal
                                        modalState={{
                                            isShown: modalSettingColumnsState.isShown,
                                            titleModal: otherSettingsLabel,
                                            acceptLabel: okButtonLabel,
                                            cancelLabel: cancelButtonLabel,
                                            callBackAnswer: handleCallbackAnswerModal,
                                        }}
                                    >
                                        <TPPageSection>
                                            <div className="form-group">
                                                <TPSelect
                                                    id="IdSelect"
                                                    onChange={(e: any) => {
                                                        let newvalue: string;
                                                        newvalue = e.target.value;
                                                        let newModalSettingColumnsState: ModalSettingsColumnsType =
                                                            { ...modalSettingColumnsState };
                                                        newModalSettingColumnsState.localRowsPerPage =
                                                            parseInt(newvalue, 10);
                                                        setModalSettingColumnsState(
                                                            newModalSettingColumnsState
                                                        );
                                                    }}
                                                    dataSource={rowsPerPageList}
                                                    value={modalSettingColumnsState.localRowsPerPage}
                                                    labelText={rowsPerPageLabel}
                                                />
                                            </div>
                                        </TPPageSection>
                                    </TPModal>
                                )}
                            </div>
                        </div>
                    </TPLoadingOverlay>
                </div>
            </ContentVerticalNoTabsStyled>

        </div>
    );
};

export default StandardReports;
