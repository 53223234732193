import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import {
    AdditionalDataCaseViewModel,
    AdditionalDataViewModel,
} from "@/models/AdditionalData/AdditionalDataModels";
import { TPI18N } from "@/services/I18nService";
import { forwardRef, useEffect, useRef, useState } from "react";

import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPAddtionalDataUIModes } from "@/components/TPAdditionalData/TPAdditionalDataUImodes";
import TPRenderAdditionalDataSwitch from "@/components/TPAdditionalData/TPRenderAdditionalDataSwitch";
import TPGlobal from "@/helpers/TPGlobal";
import { ComplementsRenderTP, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
    FormDesignerRowModel,
    FormModel,
    FormesignerInputDTO,
    ItemRow
} from "@/models/FormDesigner/FormDesigner";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPLoadingOverlay from "../../../components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { AdditionalDataFormService } from "../../../services/AdditionalDataFormService";
import { AdditionalDataService } from "../../../services/AdditionalDataService";
import { ChildStyle, ContainerViewStyle, ControlStyle, FormViewStyle, IcoAddStyle, RequiredStyle, RowViewStyle } from "./StyleFromView";

class FormModelInput {
    onChangedJson: Function = (keyValues: string) => { };
    onChangedReadyForm: Function = (status: boolean) => { };
    onChangedAllAdditionalJsonParameters?: Function = (list: Array<TPKeyValue>) => { };
    inputFormId: string = "";
    id?: string = "";
    disable?: boolean = false;
    inputTryingToSave: boolean = false;
    stylePaddingBottom?: string = "20px";
    inputValueDefault?: Array<TPKeyValue> = Array();
}

const languageDefaul = {
    languageId: "",
    localizedValue: "",
    order: 0,
};

const styleChildPrewview = {
    border: "none",
    padding: "2px",
    borderRadius: "4px",
    minHeight: "90px",
    justifyContent: "start",
    display: "flex",
    alignItems: "end",
};

const FormView = forwardRef(
    ({ onChangedReadyForm,
        onChangedJson,
        onChangedAllAdditionalJsonParameters,
        inputFormId,
        stylePaddingBottom = "20px",
        inputValueDefault,
        inputTryingToSave,
        id,
        disable }: FormModelInput) => {
        const resourceSet: string = "FormViewComponent";
        let listData = Array<string>;

        const [column, setColumn] = useState([
            { code: 1, columns: 1 },
            { code: 2, columns: 2 },
            { code: 3, columns: 2 },
            { code: 4, columns: 2 },
            { code: 5, columns: 3 },
            { code: 6, columns: 3 },
            { code: 7, columns: 3 },
            { code: 8, columns: 3 },
            { code: 9, columns: 4 },
            { code: 10, columns: 4 },
            { code: 11, columns: 4 },
            { code: 12, columns: 4 },
            { code: 13, columns: 4 },
            { code: 14, columns: 4 },
        ]);

        //Property
        const [inputFormIdTemp, setInputFormIdTemp] = useState(inputFormId);
        const [inputValueDefaultTemp, setInputValueDefaultTemp] = useState(inputValueDefault);
        const [rowsFormDesingner, setRowFormDesingner] = useState<FormModel>(new FormModel());
        const [allAdditionalDataService, setAllAdditionalDataService] = useState<Array<AdditionalDataViewModel>>([]);
        const [additionalData, setAdditionalData] = useState<Array<AdditionalDataViewModel>>([]);
        const [additionalDataToSend, setAdditionalDataToSend] = useState<Array<TPKeyValue>>(inputValueDefault ? inputValueDefault : []);
        const [requiredData, setRequiredData] = useState<Array<string>>([]);
        const childRefArray: any = useRef([]);

        //Util
        const [statusLoading, setStatusLoading] = useState<boolean>(false);
        const [requireInput, setRequireInput] = useState("");
        const [readyForm, setReadyForm] = useState("");

        //Label
        const loadResourcesAndLoadInfo = async () => {
            setRequireInput(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),
            );
        };


        //Util
        const handleIsNull = (listControl: Array<ItemRow>, value: number) => {
            if (listControl == undefined) return false;
            if (!additionalData) return false;
            if (additionalData.length <= 0) return false;
            if (listControl.find((s) => s.order == value) == undefined) return false;
            if (listControl.find((s) => s.order == value)?.isVisible == false) return false;
            return true;
        };

        const handleIsNullIdControl = (
            listControl: Array<ItemRow>,
            value: number,
        ) => {
            if (listControl == undefined) return "";
            if (listControl[value] == undefined) return "";
            return listControl[value].idControl;
        };



        const handleGetRenderer = (listControl: Array<ItemRow>, order: number) => {
            let myKeyValue2: TPKeyValue = {
                key: "",
                value: "",
            };

            if (listControl == undefined) return myKeyValue2;
            if (listControl[order] == undefined) return myKeyValue2;

            let data;
            if (additionalData && additionalData.length > 0) {
                data = [...additionalData].find(
                    (s) => s.id == listControl[order].idControl,
                );

                if (data) {
                    data.localizedDescription = listControl[order].title;
                    data.enableEditingModifyBasicData = listControl[order].isReadOnly;
                    data.isMandatory = listControl[order].isMandatory;
                }

                myKeyValue2.key = listControl[order].idControl;
                let valueJson = handleValueJson(data?.id + "");
              
                myKeyValue2.value2 = {
                    id: data?.id,
                    description: data?.description,
                    validationURL: data?.validationURL,
                    comments: data?.comments,
                    additionalDataTypeId: data?.additionalDataTypeId,
                    additionalDataTypeDescription:
                        data?.additionalDataCategoryDescription,
                    jsonParameters: data?.jsonParameters,
                    categoryId: data?.categoryId,
                    additionalDataCategoryDescription:
                        data?.additionalDataCategoryDescription,
                    enableEditingModifyBasicData: data?.enableEditingModifyBasicData,
                    isSystemRecord: false,
                    localizedDescription: data?.localizedDescription,
                    order: data?.order,
                    isMandatory: ((disable == undefined || disable == false) ? listControl[order].isMandatory : false),
                    value: valueJson,
                };

            } else {
                myKeyValue2.key = listControl[order].idControl;
                myKeyValue2.value2 = new Array<AdditionalDataCaseViewModel>();
            }

            return myKeyValue2;
        };

        const handleGetJsonParameters = (listControl: Array<ItemRow>, order: number) => {
            let myKeyValue2: TPKeyValue = {
                key: "",
                value: "",
            };

            let temp = { parentId: "", renderMethod: "", treeId: "", targetTable: "" }

            if (listControl == undefined) return temp;
            if (listControl[order] == undefined) return temp;

            let data;
            if (additionalData && additionalData.length > 0) {
                data = [...additionalData].find(
                    (s) => s.id == listControl[order].idControl,
                );

                if (data && data?.jsonParameters && data?.jsonParameters != "") {
                    return JSON.parse(data?.jsonParameters);
                }
            }

            return temp;
        };

        const handleLoadLisRequireJson = (form: FormModel, additionalDataTemp: Array<any>) => {
            var data = true;
            let tempStatusListRequire = Array();
            if (form.rows && form.rows.length > 0) {
                form.rows.forEach((e: FormDesignerRowModel) => {
                    e.controls.forEach((r: ItemRow) => {
                        let value = handleValueJson(r.idControl);
                        if (r.isMandatory) {
                            tempStatusListRequire = handleListIsRequire(additionalDataTemp, tempStatusListRequire, r.idControl + "", value, r.isMandatory);
                            if (tempStatusListRequire.length <= 0) data = false;
                        }
                    });
                });
            }

            setStatusLoading(false);
            setRequiredData(tempStatusListRequire);
            onChangedReadyForm((tempStatusListRequire.length <= 0));
        };

        const handleListIsRequire = (additionalDataTemp: Array<any>, requiredDataTemp: Array<string>, additionalDataId: string, value: string, mandatory: boolean) => {

            let temp = [...requiredDataTemp];
            let data;
            let required = false;
            if (additionalDataTemp && additionalDataTemp.length > 0) {
                data = [...additionalDataTemp].find(
                    (s) => s.id == additionalDataId,
                );
                if (data) {
                    //required = data.enableEditingModifyBasicData;
                    //if (required) mandatory = true;
                }
            }

            if (mandatory) {
                if (!value || value + "" == "") {

                    let exists = [...temp].find(s => s == additionalDataId);
                    if (!exists) {
                        temp.push(additionalDataId);
                        return temp;
                    }

                }

            }

            return temp;
        }

        const handleChangedRequire = (id: string, value: string) => {

            let temp = [...requiredData];
            if (value + "".trim() != "") {
                temp = temp.filter(s => s != id);
            } else if (requiredData && requiredData.length > 0) {
                let exist = [...temp].find(s => s == id);
                if (!exist)
                    temp.push(id);
            }

            onChangedReadyForm(temp.length <= 0);
            setRequiredData(temp);
        }

        const handleIsRequire = (id: string) => {
            let exists = [...requiredData].find(s => s == id);
            if (exists && inputTryingToSave) {
                validateAdditonalData(exists);
                return true;
            }
            return false;
        }

        const handleValueJson = (additionalDataId: string) => {
            if (inputValueDefault) {
                let temp = [...inputValueDefault];
                if (temp && temp.length > 0) {
                    let exists = temp.find(d => d.key == additionalDataId);
                    if (exists) {
                        return exists.value;
                    }
                }
            }
            return "";
        }

        const handleFomatDateJson = (additionalDataId: string) => {
            if (inputValueDefault) {
                let temp = [...inputValueDefault];
                if (temp && temp.length > 0) {
                    let exists = temp.find(d => d.key == additionalDataId);
                    if (exists) {
                        return exists.value2;
                    }
                }
            }
            return "";
        }

        const handleClear = () => {
            setRowFormDesingner(new FormModel());
            setAllAdditionalDataService([]);
            setAdditionalData([]);
            setRequiredData([]);
        }

        //Load
        const getFormDesingnerById = async () => {
            let insert = new FormesignerInputDTO();
            setStatusLoading(true);
            let serviceClient = new AdditionalDataFormService();
            let expectedCodes: Array<number> = [200];
            try {
                let responseRequest = await serviceClient.getFormDesingnerById(
                    inputFormIdTemp + "",
                    false,
                    true,
                    expectedCodes,
                );

                let data = { ...rowsFormDesingner };

                data = JSON.parse(responseRequest.jsonDesigner);
                data.id = responseRequest.id;

                if (responseRequest.jsonDesigner.replaceAll("{}", "") == "") {
                    data.img = { key: responseRequest.logo, value: responseRequest.logo };
                }

                if (responseRequest.descriptions) {
                    data.descriptions = responseRequest.descriptions
                    data.recordLocalizedModel = TPGlobal.TPClientAvailableLanguages
                        .map(({ id: languageId }, order) => ({
                            languageId,
                            localizedValue: responseRequest.descriptions
                                .find((d: any) => d.languageId === languageId)?.localizedValue ?? "",
                            order,
                        }))
                }

                if (responseRequest.formTypeId) {
                    data.formType = {
                        key: responseRequest.formTypeId,
                        value: responseRequest.formTypeLocalizedDescription,
                    };
                }

                data.showAsActive = responseRequest.isActive
                data.isHidePersonalData = responseRequest.applyPersonalData

                let allControlIds: string[] = data.rows.flatMap((row) =>
                    row.controls.map((control) => control.idControl),
                );
                if (allControlIds) {
                    handleGetAllAdditional(data, allControlIds);
                }

                setRowFormDesingner(data);
                setStatusLoading(false);
                return responseRequest;
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} getAditionDataById ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${resourceSet} getAditionDataById ex`);
                setStatusLoading(false);
                return [];
            }
        };

        const handleGetAllAdditional = async (form: FormModel, list: Array<string>) => {
            let serviceClient = new AdditionalDataService();
            let expectedCodes: Array<number> = [200, 404];

            if (additionalData == undefined || additionalData.length <= 0) {
                try {
                    let responseRequest = await serviceClient.getAllByTypeId(
                        list,
                        false,
                        true,
                        expectedCodes,
                    );
                    handleLoadLisRequireJson(form, responseRequest);
                    setAdditionalData(responseRequest);
                    return [...responseRequest];
                } catch (error) {
                    TPLog.Log(
                        `Error ${resourceSet} handleGetAllAdditional ex`,
                        TPLogType.ERROR,
                        error,
                    );
                    console.error(`Error ${resourceSet} handleGetAllAdditional ex`);
                    setStatusLoading(false);
                    return [];
                }
            }
        };

        const handleChanged = (idControl: string, newValue: string, additionalDataId: string) => {

            let temp = [...additionalDataToSend];
            let exists = temp.find(d => d.key == additionalDataId);
            if (exists) {
                exists.value = newValue;
            } else {
                temp.push({ key: additionalDataId, value: newValue + "" });
            }           
            handleChangedRequire(additionalDataId, newValue);           
            setAdditionalDataToSend(temp.filter(s => s.value != ""));
            if (onChangedJson) onChangedJson(JSON.stringify(temp.filter(s => s.value != "")));

        };

        const validateAdditonalData = (key: any): boolean => {
            if (childRefArray.current) {
                const keys = Object.keys(childRefArray.current);

                if (keys && keys.findIndex((x: any) => x === key) !== -1) {
                    let controlref: any = childRefArray.current[key];
                    if (!controlref.validateFromParent()) {
                        return false;
                    }
                }
            }

            return true;
        };

        const complementsRenderTP = (listControl: Array<ItemRow>, order: number): ComplementsRenderTP => {
            let complements = new ComplementsRenderTP();

            if (listControl == undefined) return new ComplementsRenderTP;
            if (listControl[order] == undefined) return new ComplementsRenderTP;

           

            if (listControl && listControl.length >= order) {
                complements.placeholder = listControl[order].placeholder;
                complements.visible = listControl[order].isVisible;
                complements.dateFormatForDesignerForms = listControl[order].placeholder;
                let formate = handleFomatDateJson(listControl[order].idControl);

                //if (formate && formate != "") {
                 
                //    console.log("formatDate", formate);
                //    complements.dateFormatForDesignerForms = formate;
                   
                //}
            } else {
                complements.placeholder = "";
            }
            return complements;
        };


        useEffect(() => {
            loadResourcesAndLoadInfo();
        }, []);

        useEffect(() => {
            if (inputFormIdTemp)
                getFormDesingnerById();
        }, [inputFormIdTemp]);

        useEffect(() => {
            handleClear();
            setInputFormIdTemp(inputFormId);
        }, [inputFormId]);

        useEffect(() => {
            setInputValueDefaultTemp(inputValueDefault);
        }, [inputValueDefault]);

        useEffect(() => {
            if (additionalData) {
                let data = additionalData.map((e) => {
                    return {
                        key: e.id,
                        value: e.jsonParameters
                    }
                })
                if (data && onChangedAllAdditionalJsonParameters)
                    onChangedAllAdditionalJsonParameters(data);
            }

        }, [additionalData]);

        return (

            <>
                {inputValueDefault && inputFormId != "" &&
                    <TPLoadingOverlay isModal active={statusLoading} overflow={"initial"}>
                        <ContainerViewStyle id={(id ? id : "form_view")} style={{ position: "relative" }}  >
                            {disable && <div style={{ position: "absolute", height: "100%", width: "100%", zIndex: "1" }}> </div>}
                            <FormViewStyle>
                                {
                                    rowsFormDesingner.title && rowsFormDesingner.title != "" ?
                                        < TPPageTitle style={{ color: "black", margin: "margin 5px 0px", height: "0px" }}>
                                            {rowsFormDesingner.title}
                                        </TPPageTitle>
                                        :
                                        <div style={{ height: "10px" }} ></div>
                                }

                                {rowsFormDesingner.sections.map((section, indexSection) => (
                                    <>
                                        {
                                            section.title && section.title != "" &&
                                            <div
                                                style={{
                                                    height: "auto",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingTop: "5px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                <TPLabel
                                                    className="form-check-label"
                                                    labelText={section.title}
                                                    style={{ height: "auto", paddingTop: "0px" }}
                                                />
                                            </div>
                                        }

                                        <RowViewStyle>
                                            {rowsFormDesingner.rows
                                                .filter((s) => s.idSection == section.id)
                                                .map((row, index) => (
                                                    <>
                                                        {row.columnTypeIdentifier == 1 && (
                                                            <ChildStyle
                                                                color={rowsFormDesingner.color}
                                                                style={{
                                                                    backgroundColor: rowsFormDesingner.color,
                                                                    border: "none",
                                                                    padding: "8px",
                                                                    borderRadius: "4px",
                                                                    minHeight: "18%",
                                                                    justifyContent: "start",
                                                                    alignItems: "stretch",
                                                                    position: "relative"
                                                                }}
                                                            >

                                                                {handleIsNull(row.controls, 0) ? (

                                                                    <TPRenderAdditionalDataSwitch
                                                                        guidControl={handleIsNullIdControl(
                                                                            row.controls,
                                                                            0,
                                                                        )}
                                                                        modeUI={TPAddtionalDataUIModes.Collect}
                                                                        defaultValue={
                                                                            handleGetRenderer(row.controls, 0).value2
                                                                                .value
                                                                        }
                                                                        itemToRender={handleGetRenderer(
                                                                            row.controls,
                                                                            0,
                                                                        )}
                                                                        ref={(element: any) => {
                                                                            childRefArray.current[handleIsNullIdControl(
                                                                                row.controls,
                                                                                0,
                                                                            )] = element;
                                                                        }}
                                                                        complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                        onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                    />

                                                                ) : (

                                                                    <IcoAddStyle></IcoAddStyle>

                                                                )}
                                                            </ChildStyle>
                                                        )}

                                                        {row.columnTypeIdentifier == 2 && (
                                                            <ChildStyle
                                                                style={{
                                                                    backgroundColor: rowsFormDesingner.color,
                                                                    display: "grid",
                                                                    gridTemplateColumns: "1fr 1fr",
                                                                    border: "none",
                                                                    padding: "5px",
                                                                    borderRadius: "4px",
                                                                    minHeight: "18%",
                                                                    justifyContent: "start",
                                                                    alignItems: "start",
                                                                }}
                                                            >
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 0) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 0).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    0,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle>
                                                                        </IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 0))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 1) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 1).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    1,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 1))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                            </ChildStyle>
                                                        )}

                                                        {row.columnTypeIdentifier == 3 && (
                                                            <ChildStyle
                                                                style={{
                                                                    backgroundColor: rowsFormDesingner.color,
                                                                    display: "grid",
                                                                    gridTemplateColumns: "1fr 3fr",
                                                                    border: "none",
                                                                    padding: "5px",
                                                                    borderRadius: "4px",
                                                                    minHeight: "18%",
                                                                    justifyContent: "start",
                                                                    alignItems: "end",
                                                                }}
                                                            >
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 0) ? (
                                                                        <div>
                                                                            <TPRenderAdditionalDataSwitch
                                                                                guidControl={handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    0,
                                                                                )}
                                                                                modeUI={TPAddtionalDataUIModes.Collect}
                                                                                defaultValue={
                                                                                    handleGetRenderer(row.controls, 0).value2
                                                                                        .value
                                                                                }
                                                                                itemToRender={handleGetRenderer(
                                                                                    row.controls,
                                                                                    0,
                                                                                )}
                                                                                ref={(element: any) => {
                                                                                    childRefArray.current[handleIsNullIdControl(
                                                                                        row.controls,
                                                                                        0,
                                                                                    )] = element;
                                                                                }}
                                                                                complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                                onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 0))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 1) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 1).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    1,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle>
                                                                            {/*   <TPIcon iconType={TPIconTypes.refresh} style={{}} /> */}
                                                                        </IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 1))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                            </ChildStyle>
                                                        )}

                                                        {row.columnTypeIdentifier == 4 && (
                                                            <ChildStyle
                                                                color={rowsFormDesingner.color}
                                                                style={{
                                                                    backgroundColor: rowsFormDesingner.color,
                                                                    display: "grid",
                                                                    gridTemplateColumns: "3fr 1fr",
                                                                    border: "none",
                                                                    padding: "5px",
                                                                    borderRadius: "4px",
                                                                    minHeight: "18%",
                                                                    justifyContent: "start",
                                                                    alignItems: "end",
                                                                }}
                                                            >
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 0) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 0).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    0,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 0))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 1) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 1).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    1,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 1))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                            </ChildStyle>
                                                        )}

                                                        {row.columnTypeIdentifier == 5 && (
                                                            <ChildStyle
                                                                color={rowsFormDesingner.color}
                                                                style={{
                                                                    backgroundColor: rowsFormDesingner.color,
                                                                    display: "grid",
                                                                    gridTemplateColumns: "1fr 1fr 1fr",
                                                                    border: "none",
                                                                    padding: "5px",
                                                                    borderRadius: "4px",
                                                                    minHeight: "18%",
                                                                    justifyContent: "start",
                                                                    alignItems: "end",
                                                                }}
                                                            >
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 0) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 0).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    0,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 0))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 1) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 1).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    1,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 1))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 2) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 2).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    2,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 2))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                            </ChildStyle>
                                                        )}

                                                        {row.columnTypeIdentifier == 6 && (
                                                            <ChildStyle
                                                                color={rowsFormDesingner.color}
                                                                style={{
                                                                    display: "grid",
                                                                    gridTemplateColumns: "1fr 1fr 2fr",
                                                                    border: "none",
                                                                    padding: "5px",
                                                                    borderRadius: "4px",
                                                                    minHeight: "18%",
                                                                    justifyContent: "start",
                                                                    alignItems: "end",
                                                                }}
                                                            >
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 0) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 0).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    0,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 0))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 1) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 1).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    1,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 1))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 2) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 2).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    2,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 2))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                            </ChildStyle>
                                                        )}

                                                        {row.columnTypeIdentifier == 7 && (
                                                            <ChildStyle
                                                                color={rowsFormDesingner.color}
                                                                style={{
                                                                    display: "grid",
                                                                    gridTemplateColumns: "1fr 1fr 2fr",
                                                                    border: "none",
                                                                    padding: "5px",
                                                                    borderRadius: "4px",
                                                                    minHeight: "18%",
                                                                    justifyContent: "start",
                                                                    alignItems: "end",
                                                                }}
                                                            >
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 0) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 0).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    0,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 0))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 1) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 1).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    1,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 1))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 2) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 2).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    2,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 2))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                            </ChildStyle>
                                                        )}

                                                        {row.columnTypeIdentifier == 8 && (
                                                            <ChildStyle
                                                                color={rowsFormDesingner.color}
                                                                style={{
                                                                    display: "grid",
                                                                    gridTemplateColumns: "2fr 1fr 1fr",
                                                                    border: "none",
                                                                    padding: "5px",
                                                                    borderRadius: "4px",
                                                                    minHeight: "18%",
                                                                    justifyContent: "start",
                                                                    alignItems: "end",
                                                                }}
                                                            >
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 0) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 0).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    0,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 0))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 1) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 1).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    1,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={true} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 2) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 2).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    2,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 2))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                            </ChildStyle>
                                                        )}

                                                        {row.columnTypeIdentifier == 9 && (
                                                            <ChildStyle
                                                                color={rowsFormDesingner.color}
                                                                style={{
                                                                    display: "grid",
                                                                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                                                                    border: "none",
                                                                    padding: "5px",
                                                                    borderRadius: "4px",
                                                                    minHeight: "18%",
                                                                    justifyContent: "start",
                                                                    alignItems: "end",
                                                                }}
                                                            >
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 0) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 0).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    0,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 0))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 1) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 1).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    1,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 1))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 2) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 2).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    2,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 2))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 3) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                3,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 3).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                3,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    3,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 3)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 3))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                            </ChildStyle>
                                                        )}

                                                        {row.columnTypeIdentifier == 10 && (
                                                            <ChildStyle
                                                                color={rowsFormDesingner.color}
                                                                style={{
                                                                    display: "grid",
                                                                    gridTemplateColumns: "1fr 1fr 2fr 2fr",
                                                                    border: "none",
                                                                    padding: "5px",
                                                                    borderRadius: "4px",
                                                                    minHeight: "18%",
                                                                    justifyContent: "start",
                                                                    alignItems: "end",
                                                                }}
                                                            >
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 0) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 0).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    0,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 0))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 1) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 1).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    1,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 1))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 2) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 2).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    2,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 2))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 3) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                3,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 3).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                3,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    3,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 3)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 3))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                            </ChildStyle>
                                                        )}

                                                        {row.columnTypeIdentifier == 11 && (
                                                            <ChildStyle
                                                                color={rowsFormDesingner.color}
                                                                style={{
                                                                    display: "grid",
                                                                    gridTemplateColumns: "1fr 2fr 1fr 2fr",
                                                                    border: "none",
                                                                    padding: "5px",
                                                                    borderRadius: "4px",
                                                                    minHeight: "18%",
                                                                    justifyContent: "start",
                                                                    alignItems: "end",
                                                                }}
                                                            >
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 0) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 0).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    0,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 0))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 1) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 1).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    1,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 1))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 2) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 2).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    2,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 2))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 3) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                3,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 3).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                3,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    3,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 3)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 3))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                            </ChildStyle>
                                                        )}

                                                        {row.columnTypeIdentifier == 12 && (
                                                            <ChildStyle
                                                                color={rowsFormDesingner.color}
                                                                style={{
                                                                    display: "grid",
                                                                    gridTemplateColumns: "1fr 2fr 2fr 1fr",
                                                                    border: "none",
                                                                    padding: "5px",
                                                                    borderRadius: "4px",
                                                                    minHeight: "18%",
                                                                    justifyContent: "start",
                                                                    alignItems: "end",
                                                                }}
                                                            >
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 0) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 0).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    0,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 0))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 1) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 1).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    1,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 1))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 2) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 2).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    2,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 2))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 3) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                3,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 3).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                3,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    3,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 3)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 3))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                            </ChildStyle>
                                                        )}

                                                        {row.columnTypeIdentifier == 13 && (
                                                            <ChildStyle
                                                                color={rowsFormDesingner.color}
                                                                style={{
                                                                    display: "grid",
                                                                    gridTemplateColumns: "2fr 1fr 2fr 1fr",
                                                                    border: "none",
                                                                    padding: "5px",
                                                                    borderRadius: "4px",
                                                                    minHeight: "18%",
                                                                    justifyContent: "start",
                                                                    alignItems: "end",
                                                                }}
                                                            >
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 0) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 0).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    0,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 0))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 1) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 1).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    1,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 1))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 2) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 2).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    2,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 2))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 3) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                3,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 3).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                3,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    3,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 3)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 3))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                            </ChildStyle>
                                                        )}

                                                        {row.columnTypeIdentifier == 14 && (
                                                            <ChildStyle
                                                                color={rowsFormDesingner.color}
                                                                style={{
                                                                    display: "grid",
                                                                    gridTemplateColumns: "2fr 2fr 1fr 1fr",
                                                                    border: "none",
                                                                    padding: "5px",
                                                                    borderRadius: "4px",
                                                                    minHeight: "18%",
                                                                    justifyContent: "start",
                                                                    alignItems: "end",
                                                                }}
                                                            >
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 0) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 0).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                0,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    0,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 0)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 0))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 1) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 1).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                1,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    1,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 1)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 1))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 2) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 2).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                2,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    2,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 2)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 2))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                                <ControlStyle>
                                                                    {handleIsNull(row.controls, 3) ? (
                                                                        <TPRenderAdditionalDataSwitch
                                                                            guidControl={handleIsNullIdControl(
                                                                                row.controls,
                                                                                3,
                                                                            )}
                                                                            modeUI={TPAddtionalDataUIModes.Collect}
                                                                            defaultValue={
                                                                                handleGetRenderer(row.controls, 3).value2
                                                                                    .value
                                                                            }
                                                                            itemToRender={handleGetRenderer(
                                                                                row.controls,
                                                                                3,
                                                                            )}
                                                                            ref={(element: any) => {
                                                                                childRefArray.current[handleIsNullIdControl(
                                                                                    row.controls,
                                                                                    3,
                                                                                )] = element;
                                                                            }}
                                                                            complementsRenderTP={complementsRenderTP(row.controls, 3)}
                                                                            onChange={(idControl: any, newValue: any, additionalDataId: any, formatDate: string) => { handleChanged(idControl, newValue, additionalDataId) }}
                                                                        />
                                                                    ) : (
                                                                        <IcoAddStyle></IcoAddStyle>
                                                                    )}
                                                                    <RequiredStyle active={handleIsRequire(handleIsNullIdControl(row.controls, 3))} >
                                                                        <TPLabel style={{ display: "none" }} labelText={requireInput} />
                                                                    </RequiredStyle>
                                                                </ControlStyle>
                                                            </ChildStyle>
                                                        )}
                                                    </>
                                                ))}
                                            <div style={{ height: stylePaddingBottom }}  ></div>
                                        </RowViewStyle>
                                    </>
                                ))}
                            </FormViewStyle>
                        </ContainerViewStyle>
                    </TPLoadingOverlay>
                }
            </>

        );
    },
);

export default FormView;
