import styled from "styled-components";

export const TreeNoDataImg = styled.div`
     display: flex;
    -webkit-box-pack: start;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    flex-direction:column;
    gap:10px;
`;

export const MenuItemTree = styled.div`
      display: flex; 
    flex-direction: row;
`;


export const FilterTreeStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;


export const ParentTreeStyle = styled.li`
   display: "flex";
   flex-direction: "column";
   gap: "15px";
   width: "100%";
   color: "rgba(0, 0, 0, 1)" ;
`;

interface ModelActive {
    active?: boolean;
    disable?: boolean;
}


export const UlTreeStyle = styled.ul<ModelActive>`
    background-color: ${({ disable, active }) =>
        disable && active ? "rgba(222, 222, 222, 1)" : active ? "rgb(238, 242, 255)" : "transparent"};
    padding: 7px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

interface ColorModel {
    color?: string;
}

export const ColorTreeStyle = styled.ul<ColorModel>`
    background-color: ${({ color }) => color || "transparent"};
       width: 150px;
    display: flex;
    height: auto;
    padding: 5px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    border: none;
    border-color: transparent;
`;

export const HeaderGridStyle = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: -50px;
    align-items: center;
    padding-left: 6px;
    padding-right: 20px;
    z-index:1;
`;


interface BtnModel {
    justifyContent?:string
}

export const BtnModalStyle = styled.div<BtnModel>`
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent || "end"};
    width: 100%;
    gap: 10px;
`;

export const MsgAlertTreeStyle = styled.div`
    display: flex;
    justify-content: start;
    width: 100%;   
     flex-direction: column;
`;


export const MsgAlertTreeTitleStyle = styled.div`
     display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: start;
    gap: 10px;
`;
export const XTreeTitleStyle = styled.div`
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: 20px;
    color: black;
    cursor: pointer;
`;