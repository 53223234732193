import { TPFormControlContainerStyled } from "@/helpers/generalStyles";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { useEffect, useId, useRef, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import {TbBinaryTreeFilled} from "react-icons/tb";


interface TPAutoCompleteInterface {
  id?: string;
  onValueChange: Function;
  onSearch: Function;
  onKeyDown: Function;
  onText?: Function;
  isLoading: boolean;
  options: Array<TPKeyValue>;
  selected?: Array<TPKeyValue>;
  withIcon?: boolean;
  icon?: TPIconTypes | Array<TPIconTypes>;
  iconClick?: any;
  isHorizontal?: boolean;
  labelText?: string | JSX.Element;
  isMandatory?: boolean;
  errorMessage?: string;
  defaultInputValue?: string;
  emptyLabel: string;
  autoCompleteStyle?: any;
  containerStyle?: any;
  labelStyle?: any;
  disabled?: boolean;
  downArrowClick?: any;
  className?: string;
}
const TPAutoCompleteTree = ({
  id,
  onValueChange,
  onSearch,
  onKeyDown,
  onText,
  isLoading,
  options,
  selected = undefined,
  withIcon = false,
  icon = TPIconTypes.default,
  iconClick = undefined,
  isHorizontal = false,
  labelText,
  isMandatory = false,
  errorMessage = "",
  defaultInputValue = "",
  emptyLabel,
  autoCompleteStyle,
  containerStyle,
  labelStyle,
  disabled = false,
  downArrowClick = null,
  className = "",
}: TPAutoCompleteInterface) => {
  const theId = `${id ? id : useId()}-autocomplete-selection`;

  const [isOpenList, setIsOpenList] = useState(false);

  const handlerOnValueChange = (selected: any) => {
    setIsOpenList(false);
    if (onValueChange) {
      onValueChange(selected);
    }
  };
  const handlerOnSearch = (query: string) => {
    if (onSearch) {
      onSearch(query);
    }
  };

  const handlerOnText = (text: string) => {
    if (onText) {
      onText(text);
    }
  };

  const drawLabel = () => {
    return (
      <TPLabel
        htmlFor={theId}
        isMandatory={isMandatory}
        labelText={labelText}
        style={labelStyle}
      />
    );
  };
  const drawInvalidFeedBack = () => {
    return <div className="invalid-feedback">{errorMessage}</div>;
  };
  const handlerOnKeyDown = (event: any) => {
    setIsOpenList(true);
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const autocompleteRef = useRef<any>(null);

  const handlerOnBlur = (event: any) => {
    setIsOpenList(false);
  };

  const handleClickOutside = function (e: MouseEvent) {
    if (autocompleteRef.current && !autocompleteRef.current.contains(e.target)) {
      handlerOnBlur(e);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [])

  useEffect(() => {
    const inputElement = document.getElementById(theId);

    if (!inputElement) return;
  
    const handleFocus = () => setIsOpenList(true)

    inputElement.addEventListener("focus", handleFocus);
  
    return () => inputElement.removeEventListener("focus", handleFocus);
  }, [theId]);

  const handlerDownArrowKey = () => {
    if (disabled) return;
    setIsOpenList(true);
    downArrowClick();
  };

  const handleDropdownToggle = (isOpen: boolean) => {
    if (isOpen) {
      setTimeout(() => {
        const inputElement = document.getElementById(theId);
        if (inputElement) {
          inputElement.focus();
        }
      }, 0);
    }
  };

  const styleContainsWidth: boolean = !containerStyle
    ? false
    : Object.keys(containerStyle as string).findIndex(
          (x: string) => x === "height"
        ) === -1
      ? false
      : true;
  const realContainerStyle: any = styleContainsWidth
    ? { ...containerStyle, paddingRight: "0px" }
    : { ...containerStyle, height: "35px", paddingRight: "0px" };

  const borderErrorStyle = errorMessage
    ? { ...realContainerStyle, border: "1px solid red" }
    : realContainerStyle;
  return (
    // filterBy={['firstName', 'lastName', 'email']}
    <>
      {!isHorizontal && drawLabel()}
      <TPFormControlContainerStyled
        ref={autocompleteRef}
        isHorizontal={isHorizontal}
        className={` ${withIcon ? "input-group " : ""} ${errorMessage !== "" ? "is-invalid" : ""}${className}`}
        style={{...borderErrorStyle, borderRadius: "4px"}}
      >
        {isHorizontal && drawLabel()}
        <div
          className={`form-control autocomplete-container ${errorMessage !== "" ? "is-invalid" : ""}`}
          style={{ height: "32px" }}
        >
          <AsyncTypeahead
            disabled={disabled}
            id={theId}
            isLoading={isLoading}
            key="key"
            labelKey={"value"}
            onChange={handlerOnValueChange}
            onSearch={(query) => {
              handlerOnSearch(query);
            }}
            onInputChange={(text) => {
              handlerOnText(text);
            }}
            defaultInputValue={defaultInputValue}
            options={options}
            emptyLabel={emptyLabel}
            onKeyDown={handlerOnKeyDown}
            selected={selected}
            onBlur={handlerOnBlur}
            open={isOpenList}
            onMenuToggle={handleDropdownToggle}
            inputProps={{ id: theId }}
            className={errorMessage !== "" ? "is-invalid" : ""}
          />
        </div>
        {withIcon && (
          <>
            {!Array.isArray(icon) && (
              <div className="input-group-prepend" style={{borderRight:"1px solid gainsboro"}} onClick={iconClick}>
                  <TbBinaryTreeFilled size={30} />
              </div>
            )}
            {downArrowClick && (
                <div className="input-group-prepend" onClick={handlerDownArrowKey}>
                  <TPIcon iconType={TPIconTypes.keyboardArrowDown} />
                </div>
            )}
          </>
        )}

        {!withIcon && errorMessage != "" && drawInvalidFeedBack()}
      </TPFormControlContainerStyled>
      {withIcon && errorMessage != "" && drawInvalidFeedBack()}
    </>
  );
};

export default TPAutoCompleteTree;
