import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { QueueTabs } from "@/models/Groups/GroupsModels";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import useQueueAdminLabels from "../assets/labels";
import QueueAdministrationTab from "./tabs/QueueAdministrationTab";
import QueueAssignUsersTab from "./tabs/QueueAssignUsersTab";
import QueueGeneralInfo from "./tabs/QueueGeneralInfoTab";
import "../assets/Styles.css";

interface QueueAdminPanelProperties {
  tabCallback: Function;
  updateCallback: Function;
}

const QueueAdminNewPanel: FC<QueueAdminPanelProperties> = function ({
  tabCallback,
  updateCallback,
}) {
  const dispatch = useDispatch();
  const { labels } = useQueueAdminLabels();
  const [currentTab, setCurrentTab] = useState<QueueTabs>(
    QueueTabs.GeneralInfo,
  );
  const [activePreview, setActivePreview] = useState(false);

  const handleChangeActivePreview = (mode: boolean) => {
    setActivePreview(mode);
  };

  return (
    <div className="queues-main-container">
      {!activePreview && (
        <>
          <CIMTitleSection style={{ padding: "16px 0px 12px" }}>
            <TPPageTitle style={{ margin: 0, padding: 0 }}>
              {labels?.NewQueue}
            </TPPageTitle>
          </CIMTitleSection>
          <div
            id="queue-admin-nav-tab"
            className="secondary-style-tp-tabs nav nav-tabs"
            role="tablist"
          >
            <button
              id="queue-admin-general-info-nav-tab"
              className={`nav-link ${currentTab == QueueTabs.GeneralInfo && "active"}`}
              type="button"
              role="tab"
              onClick={() => setCurrentTab(QueueTabs.GeneralInfo)}
              style={{ fontSize: "14px", fontWeight: "700" }}
            >
              {labels?.GeneralInfo}
            </button>

            <button
              id="queue-admin-assign-users-nav-tab"
              className={`nav-link ${currentTab == QueueTabs.AssignUsers && "active"}`}
              type="button"
              role="tab"
              onClick={() => setCurrentTab(QueueTabs.AssignUsers)}
              disabled
              style={{ color: "#969696", fontSize: "14px", fontWeight: "700" }}
            >
              {labels?.AssignUsers}
            </button>
          </div>
        </>
      )}

      <QueueGeneralInfo
        panelMode={"new"}
        groupId={0}
        tabCallback={tabCallback}
        active={currentTab == QueueTabs.GeneralInfo}
        updateCallback={updateCallback}
        activePreview={activePreview}
        handleChangeActivePreview={handleChangeActivePreview}
      />
      {!activePreview && (
        <QueueAssignUsersTab
          groupId={0}
          tabCallback={tabCallback}
          active={currentTab == QueueTabs.AssignUsers}
          updateCallback={updateCallback}
        />
      )}
    </div>
  );
};

export default QueueAdminNewPanel;
