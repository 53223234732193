export type TPKeyValue = {
  key: string;
  value: any;
  value2?: any;
  value3?: any;
  value4?: any;
};

export type AdditionalFilter = {
  key: string;
  data: TPKeyValue[];
  label: string;
  selectedValue?: string | number | null | undefined | boolean;
  placeholder?: string;
  width?: number;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

export type AdditionalCheckbox = {
  key: string;
  label: string;
  checked: boolean;
  styles?: React.CSSProperties;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export class ComplementsRenderTP {
    placeholder: string = "";
    dateFormatForDesignerForms: string = "";
    visible: boolean = true;
}