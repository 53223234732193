import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTreeView, {
    TPTreeViewDataModel,
    TPTreeViewProps,
} from "@/components/TPTreeView/TPTreeView";
import TPGlobal from "@/helpers/TPGlobal";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { FC, ReactElement, useEffect, useState } from "react";
import TPFilter from "../../pages/Trees/Components/TPFilter";
import { TPEmthyModal } from "../../components/TPModalAlert/TPEmthyModal/TPEmthyModal";

export type TPModalTreeViewState = {
    isShown: boolean;
    guidSession: string;
    treeId: string;
};

interface TPModalTreeViewProps {
    title: string;
    title2?: string;
    acceptLabel: string;
    cancelLabel: string;
    searchPlaceholder?: string;
    isShown: boolean;
    callBackAnswer: Function;
    treeProps: TPTreeViewProps;
    showSelectRootNodeButton?: boolean;
    selectRootLabel?: string;
    selectedNodeIdPlusDescription: string;
    autoCloseWhenSelect?: boolean;
    loadChildrenFromThisParent?: string;
}

const TPModalTreeView: FC<TPModalTreeViewProps> = ({
    title,
    title2,
    acceptLabel,
    cancelLabel,
    isShown,
    callBackAnswer,
    treeProps,
    showSelectRootNodeButton = false,
    selectRootLabel = "",
    selectedNodeIdPlusDescription,
    autoCloseWhenSelect = false,
    loadChildrenFromThisParent = "",
    searchPlaceholder = "Search"
}): ReactElement => {
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(false);

    const [filter, setFilter] = useState("");
    const [orderActive, setOrderActive] = useState(false);
    const [listData, setListData] = useState<Array<TPTreeViewDataModel>>(treeProps.dataSource);
    const [listDataTemp, setListDataTemp] = useState<Array<TPTreeViewDataModel>>(new Array<TPTreeViewDataModel>());

    const [selectedNode, setSelectedNode] = useState("");
    const [dataToReturn, setDataToReturn] = useState("");
    const [labelToReturn, setLabelToReturn] = useState("");
    const [hierarchyToReturn, setHierarchyToReturn] = useState("");

    const [
        mustSelectLastLevelBranchMessage,
        setMustSelectLastLevelBranchMessage,
    ] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    let classModal: string;
    let styleModal: any = {};
    let backdropClass: string;

    if (isShown) {
        classModal = "modal show";
        styleModal["display"] = "block";
        backdropClass = "modal-backdrop show";
    } else {
        classModal = "modal";
        styleModal["display"] = "none";
        backdropClass = "";
    }

    //Load Resources
    const loadResources = async () => {
        setMustSelectLastLevelBranchMessage(
            await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "MustSelectLastLevelBranch"
            )
        );
    };

    const handleChangeNodeCallBack = (
        nodeId: string,
        label: string,
        hierachyDescription: string
    ) => {
        setErrorMessage("");
        setDataToReturn(nodeId);
        setLabelToReturn(label);
        setHierarchyToReturn(hierachyDescription);
        if (autoCloseWhenSelect) {
            callBackAnswer(true, nodeId, label, hierachyDescription);
        }
    };

    const handleOrderAndFilter = (dataSourceOrder: Array<TPTreeViewDataModel>) => {

        let data = dataSourceOrder.sort((a, b) =>
            orderActive ? a.label.localeCompare(b.label) : b.label.localeCompare(a.label)
        );

        if (filter != "") {
            data = [...data].filter(s => s.label.toLowerCase().includes(filter.toLowerCase()));
        }

        return data;
    }

    const botonesPersonalizados = (
        
        <div style={{ display: "flex", gap: "10px", justifyContent: "start", width: "100%" }}>
            {showSelectRootNodeButton && (
                <TPButton
                    id="select-root-node"
                    type={TPButtonTypes.link}
                    style={{ color:"rgba(120, 0, 150, 1)" }}
                    onClick={() => {
                        setErrorMessage("");
                        callBackAnswer(true, null, null, null);
                    }}
                >
                    {selectRootLabel}
                </TPButton>
            )}
        </div>
    
    );

    const handleTrySelectLastLevelBranch = () => {
        setErrorMessage(mustSelectLastLevelBranchMessage);
        setTimeout(function () {
            setErrorMessage("");
        }, 1500);
    };

    //run only once
    useEffect(() => {
        loadResources();
    }, []);

    useEffect(() => {
        if (listDataTemp.length <= 0) {
         setListData(treeProps.dataSource);
         setListDataTemp(treeProps.dataSource);
        }
    }, [treeProps.dataSource]);
  
    return (
        <>
            <TPEmthyModal
                disabled={(selectedNode == "")}
                title={title}
                title2={title2}
                visible={isShown}
                onAccept={() => {
                    setErrorMessage("");
                    callBackAnswer(
                        true,
                        dataToReturn,
                        labelToReturn,
                        hierarchyToReturn
                    );
                }}
                onCancel={() => {
                    setErrorMessage("");
                    callBackAnswer(false, null, null, null);
                }}
                btnChildren={
                    botonesPersonalizados
                }
            >
                <div className="modal-body" style={{ height: "350px" }}>

                    <TPFilter
                        searchPlaceholder={searchPlaceholder}
                        onChangeFilter={(e: any) => {                            
                            if (!e || e == "") {
                                let data = [...listDataTemp];
                                setListData(data);
                            } else {
                                let data = treeProps.dataSource.slice();
                                data = data.filter(s => s.label.toLowerCase().includes(filter.toLowerCase()));
                                setListData(data);
                            }
                            setFilter(e);
                        }}
                        onChangeOrder={() => { setOrderActive(!orderActive) }}
                        activeOrder={orderActive}

                    ></TPFilter>
                    <div style={{ height:"10px" }} ></div>

                    <TPTreeView
                        setIsLoading={setIsLoadingScreen}
                        treeId={treeProps.treeId}
                        dataSource={handleOrderAndFilter(listData)}
                        setDataSource={treeProps.setDataSource}
                        selectedNode={selectedNode}
                        setSelectedNode={treeProps.setSelectedNode}
                        selectedLabel={treeProps.selectedLabel}
                        setSelectedLabel={treeProps.setSelectedLabel}
                        selectedHierarchyLabel={treeProps.selectedHierarchyLabel}
                        setSelectedHierarchyLabel={
                            treeProps.setSelectedHierarchyLabel
                        }
                        renderParent={loadChildrenFromThisParent}
                        handleChangeNodeCallBack={(
                            nodeId: string,
                            label: string,
                            hierarchyDescription: string
                        ) => {
                            setSelectedNode(nodeId);
                            handleChangeNodeCallBack(
                                nodeId,
                                label,
                                hierarchyDescription
                            )
                        }
                        }
                        filterIsActive={treeProps.filterIsActive}
                        filterIsVisible={treeProps.filterIsVisible}
                        guidTree={treeProps.guidTree}
                        isAdminMode={treeProps.isAdminMode}
                        mustSelectLastLevelBranch={
                            treeProps.mustSelectLastLevelBranch
                        }
                        onTrySelectLastLevelBranch={handleTrySelectLastLevelBranch}
                        disableMenu={true}
                    ></TPTreeView>
                </div>
            </TPEmthyModal>
            <div className={backdropClass}></div>
        </>
    );
};

export default TPModalTreeView;
