import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
    ProjectNewEnum as e,
    FieldType,
    ProjectNewModel, ProjectType,
    SetConditionControl, SetConditionEnum, SetOrderControl,
    SetControl,
    SetOrderByTypeEnum,
    SetOrderByAscDescTypeEnum
} from "@/models/Project/Projects";
import ProjectNewStep2SetOptions from "@/pages/Projects/ProjectNew/ProjectNewStep2SetOptions";
import { EventLoadStructureService } from "@/services/EventLoadStructureService";
import { GroupsService } from "@/services/GroupsService";
import moment from "moment/moment";
import { CSSProperties, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";

const defaulGroup = {key: '', value: '---'} as TPKeyValue;

interface ProjectNewStep2BackProps {
    m: { [attribute: string]: any };
    project: ProjectNewModel;
    onChange: Function;
    onChangeProject: Function;
}

export default function ProjectNewStep2Back({
                                                m,
                                                project,
                                                onChange,
                                                onChangeProject,
                                            }: ProjectNewStep2BackProps) {
    const [sets, setSets] = useState([...project.conditionsOrQueues!])
    const [open, setOpen] = useState(project.conditionsOrQueues!.map((_, i) => i === 0))
    const [groups, setGroups] = useState<Array<TPKeyValue>>([]);
    const [fields, setFields] = useState<Array<any>>([]);
    const connectors: Array<TPKeyValue> = [
        {key: SetConditionEnum.AND, value: m?.[e.Step2SetInputAndOptionLabel]},
        {key: SetConditionEnum.OR, value: m?.[e.Step2SetInputOrOptionLabel]},
    ]
    const inputRef = useRef<HTMLInputElement>(null);
    const [editMode, setEditMode] = useState(-1);

    const OrderByOptions: Array<TPKeyValue> = [
        {key: SetOrderByTypeEnum.LIFO, value: m?.[e.LIFOOptionText]},
        {key: SetOrderByTypeEnum.FIFO, value: m?.[e.FIFOOptionText]}
    ]

    const OrderByAscDescCOptions: Array<TPKeyValue> = [
        {key: SetOrderByAscDescTypeEnum.ASC, value: m?.[e.ASCOptionText]},
        {key: SetOrderByAscDescTypeEnum.DESC, value: m?.[e.DESCOptionText]}
    ]

    useEffect(() => {
        load()
    }, []);

    useEffect(() => {
        onChange(sets)
    }, [sets]);

    function load() {
        const {findByGroupId} = new EventLoadStructureService()
        findByGroupId(project.structureTypeId!)
            .then(response => {
                const fl = response.map(f => ({...f, key: f.eventLoadStructureDetailId, value: f.field}))
                setFields(fl)
            })
            .catch(error => console.error(error));

        const {findByType} = new GroupsService()
        findByType(ProjectType.BACKOFFICE)
            .then(response => {
                const g = response.map(({id: key, name: value}) => ({key, value}))
                setGroups([defaulGroup, ...g])
            })
            .catch(error => console.error(error));
    }

    function onDoubleClickHandler(index: number) {
        setEditMode(index)
        setTimeout(() => inputRef.current?.focus(), 500)
    }

    function clone(index: number) {
        const ss = structuredClone(sets[index])
        const nl = [...sets]
        nl.splice(index + 1, 0, {...ss, name: getConsecutive(ss.name!)})
        setSets(nl)
    }

    function getConsecutive(text: string) {
        const last = text.trim().split(' ').reverse()

        if (!isNaN(+last[0])) {
            let newText: any = text.split(' ')
            newText.pop()
            newText = newText.join(' ')
            return `${newText} ${+last[0] + 1}`
        } else {
            return `${text} 2`
        }
    }

    function deleteOne(index: number) {
        const l = [...sets]
        l.splice(index, 1)
        setSets(l)
    }

    function add() {
        setSets([
            ...sets,
            {
                ...SetControl.initialValues,
                ...SetOrderControl.initialValues,
                name: `${m?.[e.Step2SetLabel]} ${sets.length + 1}`,
                priority: sets.length + 1
            }
        ])
    }

    function onChangeSetHandler(attribute: string,
                                value: any,
                                index: number,
                                conditionIndex?: number) {
        const ss: any = structuredClone([...sets])

        if (conditionIndex !== undefined) {
            if (!!ss && (ss?.length ?? 0) - 1 >= index && !!ss[index]?.conditions &&
                (ss[index]?.conditions?.length ?? 0) - 1 >= conditionIndex) {
                ss[index].conditions[conditionIndex] = value
            }
        } else {
            if (!!ss && (ss?.length ?? 0) - 1 >= index) {
                ss[index][attribute] = value
            }
        }
        setSets(ss)
    }

    function onChangeSetHandlerOrder(attribute: string,
                                    value: any,
                                    index: number,
                                    orderIndex?: number) {
        const ss: any = structuredClone([...sets])

        if (orderIndex !== undefined) {
            if (!!ss && (ss?.length ?? 0) - 1 >= index && !!ss[index]?.order &&
            (ss[index]?.order?.length ?? 0) - 1 >= orderIndex) {
                ss[index].order[orderIndex] = value
            }
        } else {
            if (!!ss && (ss?.length ?? 0) - 1 >= index) {
                ss[index][attribute] = value
            }
        }
        setSets(ss)
    }

    function deleteCondition(index: number, indexCondition: number) {
        const ss = structuredClone([...sets])
        const cs = structuredClone([...ss[index].conditions!])
        cs.splice(indexCondition, 1)
        if (cs.length === 1) {
            cs[0].conditionType = null
        }
        ss[index].conditions = cs

        setSets(ss)
    }

    function deleteOrderItem(index: number, indexOrder: number) {
        const ss = structuredClone([...sets])
        const cs = structuredClone([...ss[index].order!])
        cs.splice(indexOrder, 1)
        if (cs.length === 1) {
            cs[0].orderType = null
        }
        ss[index].order = cs

        setSets(ss)
    }

    function addCondition(index: number, indexCondition: number) {
        try {
            const ss = structuredClone([...sets])
            const cs = structuredClone([...ss[index]?.conditions!])
            if (indexCondition === cs.length - 1) {
                cs.push({...SetConditionControl?.initialValues})
            } else {
                cs.splice(indexCondition + 1, 0, {...SetConditionControl?.initialValues})
            }
            ss[index].conditions = [...cs]

            setSets(ss)
        } catch (e) {
            console.error(e)
        }
    }

    function addOrderItem(index: number, indexOrder: number) {
        try {
            const ss = structuredClone([...sets])
            const cs = structuredClone([...ss[index]?.order!])
            if (indexOrder === cs.length - 1) {
                cs.push({...SetOrderControl?.initialValues})
            } else {
                cs.splice(indexOrder + 1, 0, {...SetOrderControl?.initialValues})
            }
            ss[index].order = [...cs]

            setSets(ss)
        } catch (e) {
            console.error(e)
        }
    }

    function isValidDate(dateString: string) {
        const regex = /^\d{4}\/\d{2}\/\d{2}$/;
        if (!regex.test(dateString)) {
            return false;
        }

        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return false;
        }

        const [year, month, day] = dateString.split('/').map(Number);
        if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
            return false;
        }

        return true;
    }

    return (
        <div style={styles.column}>
            <div
                className="alert alert-primary"
                style={styles.alert}
                role="alert">
                <TPIcon iconType={TPIconTypes.circleInfo}/>
                <span>{m?.[e.Step2AlertLabel]}</span>
            </div>

            <h5 style={styles.title}>
                {m?.[e.Step2DescriptionLabel]}
            </h5>

            <div style={styles.defaultInput}>
                <TPSelect
                    key="defaultQueueId"
                    id="defaultQueueId"
                    isMandatory={true}
                    labelText={m?.[e.Step2SetInputDefaultAssignLabel]}
                    dataSource={groups}
                    value={project.defaultQueueId}
                    onChange={({target}: any) => onChangeProject({...project, defaultQueueId: target.value})}
                    disabled={project.projectInUse}
                />
            </div>

            <div id="accordionSets"
                 className="accordion"
                 style={styles.setContainer}>
                {sets.map(({name, groupId, priority, conditions, order}, index) => (
                    <div id={`set-${index}`}
                         className="accordion-item"
                         style={styles.column}>
                        <div id={`set${index}`}
                             className="accordion-header"
                             style={styles.sectionTitle}>

                            <div
                                style={{
                                    pointerEvents: project.projectInUse ? 'none' : 'auto',
                                }}
                            >
                                <ProjectNewStep2SetOptions
                                    m={m}
                                    index={index}
                                    length={sets.length}
                                    onClickCloneHandler={clone}
                                    onDelete={deleteOne}
                                />
                            </div>

                            <div style={styles.containerTitle}>
                                {editMode !== index && (
                                    <div
                                        key={`set-title-${index}`}
                                        style={styles.titleContainer}
                                        onDoubleClick={() => onDoubleClickHandler(index)}>
                                        <p style={styles.name}>{`${name ?? ""}`}</p>
                                    </div>
                                )}

                                {editMode === index && (
                                    <input
                                        key={`set-input-title-${index}`}
                                        ref={inputRef}
                                        style={styles.nameInput}
                                        value={`${name ?? ""}`}
                                        onChange={({target}) => onChangeSetHandler("name", target.value, index)}
                                        onBlur={() => setEditMode(-1)}
                                        readOnly={project.projectInUse}
                                    />
                                )}

                                <button className="accordion-button"
                                        style={styles.button}
                                        disabled={project.projectInUse}
                                        type="button"
                                        data-bs-toggle="collapse" data-bs-target={`#collapse${index}`}
                                        aria-expanded={`${index === 0}`} aria-controls={`collapse${index}`}>

                                </button>
                            </div>
                        </div>

                        <div id={`collapse${index}`}
                             className={`accordion-collapse collapse${open ? ' show' : ''}`}
                             aria-labelledby={`set${index}`}
                             data-bs-parent="#accordionSets">
                            <h6 style={styles.subtitle}>{m?.[e.Step2SetConfigurationLabel]}</h6>

                            <div style={styles.row}>
                                <div style={styles.input}>
                                    <TPSelect
                                        key={`groupId-${index}`}
                                        id={`groupId-${index}`}
                                        isMandatory={true}
                                        labelText={m?.[e.Step2SetInputAssignLabel]}
                                        dataSource={groups}
                                        value={groupId}
                                        onChange={({target}: any) => onChangeSetHandler("groupId", target.value, index)}
                                        disabled={project.projectInUse}
                                    />
                                </div>

                                <div style={styles.input}>
                                    <TPTextBox
                                        key={`priority-${index}`}
                                        id={`priority-${index}`}
                                        isMandatory={true}
                                        labelText={m?.[e.Step2SetInputPriorityLabel]}
                                        value={`${priority ?? ''}`}
                                        onChange={({target}: any) => onChangeSetHandler("priority", target.value, index)}
                                        disabled={project.projectInUse}
                                    />
                                </div>
                            </div>

                            <h6 style={styles.subtitle}>{m?.[e.Step2SetConditionLabel]}</h6>

                            {conditions!.map(({
                                                  eventLoadStructureDetailId,
                                                  field,
                                                  operation,
                                                  value,
                                                  conditionType
                                              }, conditionIndex) => (
                                <div style={styles.row}>
                                    {conditions!.length > 1 && (
                                        <div style={styles.start}>
                                            <TPButton
                                                id={`condition-remove-Button-${conditionIndex}`}
                                                style={styles.conditionDeleteIcon}
                                                type={TPButtonTypes.icon}
                                                icon={TPIconTypes.delete}
                                                onClick={() => deleteCondition(index, conditionIndex)}
                                                disabled={project.projectInUse}
                                            />
                                        </div>
                                    )}

                                    <div style={styles.input}>
                                        <TPSelect
                                            key={`field-${index}-${conditionIndex}`}
                                            id={`field-${index}-${conditionIndex}`}
                                            isMandatory={true}
                                            disabled={project.projectInUse}
                                            labelText={m?.[e.Step2SetInputBaseFieldLabel]}
                                            dataSource={[defaulGroup, ...fields]}
                                            value={eventLoadStructureDetailId}
                                            onChange={({target}: any) => {
                                                const id = target.value
                                                const fd = fields.find(f => `${f.eventLoadStructureDetailId}` === `${id}`)
                                                onChangeSetHandler(
                                                    "",
                                                    {
                                                        eventLoadStructureDetailId: fd ? `${fd.eventLoadStructureDetailId}` : "",
                                                        field: fd ? fd.field : "",
                                                        operation: "",
                                                        value: "",
                                                        conditionType: null,
                                                    },
                                                    index,
                                                    conditionIndex
                                                )
                                            }}
                                        />
                                    </div>

                                    <div style={styles.input}>
                                        <TPSelect
                                            key={`operation-${index}-${conditionIndex}`}
                                            id={`operation-${index}-${conditionIndex}`}
                                            isMandatory={true}
                                            disabled={project.projectInUse}
                                            labelText={m?.[e.Step2SetInputComparisonOperationLabel]}
                                            dataSource={!!field && !!fields && fields?.length > 0 ?
                                                [
                                                    defaulGroup,
                                                    ...fields
                                                        ?.find(f => `${f.eventLoadStructureDetailId}` === `${eventLoadStructureDetailId}`)
                                                        ?.dictionaryOptions
                                                ] :
                                                []
                                            }
                                            value={`${operation ?? ""}`}
                                            onChange={({target}: any) => {
                                                onChangeSetHandler(
                                                    "",
                                                    {
                                                        eventLoadStructureDetailId,
                                                        field,
                                                        operation: target.value,
                                                        value,
                                                        conditionType,
                                                    },
                                                    index,
                                                    conditionIndex
                                                )
                                            }}
                                        />
                                    </div>

                                    <div style={styles.input}>
                                        {(() => {
                                            const type = fields
                                                ?.find(f => `${f.eventLoadStructureDetailId}` === `${eventLoadStructureDetailId}`)
                                                ?.type;

                                            const dateFormat = fields
                                                ?.find(f => `${f.eventLoadStructureDetailId}` === `${eventLoadStructureDetailId}`)
                                                ?.additionalDataDateFormat;

                                            const dateFormatString = TPGlobal.dateFormatsAdditionalData.find(format => format.key === dateFormat)?.value.toUpperCase() || null;


                                            if (type === FieldType.DATE) {
                                                return (
                                                    <TPDatePicker
                                                        key={`value-${index}-${conditionIndex}`}
                                                        labelText={m?.[e.Step2SetInputReferenceValueLabel]}
                                                        isMandatory={true}
                                                        customDateFormat={dateFormat}
                                                        disabled={project.projectInUse}
                                                        isHorizontal={false}
                                                        selectedDate={value && moment(value, dateFormatString, true).isValid()
                                                            ? moment(value, dateFormatString).toDate()
                                                            : (console.log("Fecha incorrecta"), null)}
                                                        onChangeDate={(date: Date) => {
                                                            console.log("Fecha seleccionada:", date);
                                                            console.log("Formato de fecha usado:", dateFormatString);

                                                            const formattedDate = moment(date).format(dateFormatString);
                                                            console.log("Fecha formateada:", formattedDate);

                                                            onChangeSetHandler(
                                                                "",
                                                                {
                                                                    eventLoadStructureDetailId,
                                                                    field,
                                                                    operation,
                                                                    value: formattedDate,
                                                                    conditionType,
                                                                },
                                                                index,
                                                                conditionIndex
                                                            );
                                                        }}
                                                    />
                                                );
                                            }

                                            if ([FieldType.PHONE, FieldType.NUMERIC, FieldType.EMAIL].includes(type)) {
                                                return (
                                                    <TPTextBox
                                                        key={`value-${index}-${conditionIndex}`}
                                                        id={`value-${index}-${conditionIndex}`}
                                                        type={[FieldType.PHONE, FieldType.NUMERIC].includes(type) ? 'number' : 'text'}
                                                        isMandatory={true}
                                                        disabled={project.projectInUse}
                                                        labelText={m?.[e.Step2SetInputReferenceValueLabel]}
                                                        value={`${value}`}
                                                        onChange={(e: any) => {
                                                            onChangeSetHandler(
                                                                "",
                                                                {
                                                                    eventLoadStructureDetailId,
                                                                    field,
                                                                    operation,
                                                                    value: e.target.value,
                                                                    conditionType,
                                                                },
                                                                index,
                                                                conditionIndex
                                                            )
                                                        }}
                                                        isInvalidChars={false}
                                                    />
                                                );
                                            }

                                            if (type && ![FieldType.DATE, FieldType.PHONE, FieldType.NUMERIC, FieldType.EMAIL].includes(type)) {
                                                return (
                                                    <TPTextArea
                                                        key={`value-${index}-${conditionIndex}`}
                                                        id={`value-${index}-${conditionIndex}`}
                                                        isMandatory={true}
                                                        rows={1}
                                                        disabled={project.projectInUse}
                                                        labelText={m?.[e.Step2SetInputReferenceValueLabel]}
                                                        value={`${value}`}
                                                        onChange={(e: any) => {
                                                            onChangeSetHandler(
                                                                "",
                                                                {
                                                                    eventLoadStructureDetailId,
                                                                    field,
                                                                    operation,
                                                                    value: e.target.value,
                                                                    conditionType,
                                                                },
                                                                index,
                                                                conditionIndex
                                                            )
                                                        }}
                                                        isInvalidChars={false}
                                                    />
                                                );
                                            }

                                            return (
                                                <TPTextBox
                                                    key={`value-${index}-${conditionIndex}`}
                                                    id={`value-${index}-${conditionIndex}`}
                                                    isMandatory={true}
                                                    disabled={project.projectInUse}
                                                    labelText={m?.[e.Step2SetInputReferenceValueLabel]}
                                                    value={`${value}`}
                                                    onChange={(e: any) => {
                                                        onChangeSetHandler(
                                                            "",
                                                            {
                                                                eventLoadStructureDetailId,
                                                                field,
                                                                operation,
                                                                value: e.target.value,
                                                                conditionType,
                                                            },
                                                            index,
                                                            conditionIndex
                                                        )
                                                    }}
                                                    isInvalidChars={false}
                                                />
                                            );
                                        })()}
                                    </div>

                                    {!(conditionIndex === conditions!.length - 1) && (
                                        <div style={styles.center}>
                                            <TPRadioGroup
                                                key={`conditionType-${index}-${conditionIndex}`}
                                                id={`conditionType-${index}-${conditionIndex}`}
                                                radioStyle={styles.radioGruop}
                                                isHorizontal={true}
                                                value={conditionType as any}
                                                source={connectors}
                                                disabled={project.projectInUse}
                                                onChange={(e: any) => {
                                                    onChangeSetHandler(
                                                        "",
                                                        {
                                                            eventLoadStructureDetailId,
                                                            field,
                                                            operation,
                                                            value,
                                                            conditionType: e.target.value,
                                                        },
                                                        index,
                                                        conditionIndex
                                                    )
                                                }}
                                            />
                                        </div>
                                    )}

                                    <div style={styles.center}>
                                        <TPButton
                                            id={`condition-add-Button-${conditionIndex}`}
                                            style={styles.setIcon}
                                            type={TPButtonTypes.icon}
                                            icon={TPIconTypes.addCircle}
                                            disabled={project.projectInUse}
                                            onClick={() => addCondition(index, conditionIndex)}
                                        />
                                    </div>
                                </div>
                            ))}

                            <h6 style={styles.subtitle}>{m?.[e.Step2SetOrderLabel]}</h6>

                            {order!.map(({
                                                  eventLoadStructureDetailId,
                                                  field,
                                                  orderType
                                              }, orderIndex) => (
                                <div style={styles.row}>
                                    {order!.length > 1 && (
                                        <div style={styles.start}>
                                            <TPButton
                                                id={`order-remove-Button-${orderIndex}`}
                                                style={styles.conditionDeleteIcon}
                                                type={TPButtonTypes.icon}
                                                icon={TPIconTypes.delete}
                                                onClick={() => deleteOrderItem(index, orderIndex)}
                                                disabled={project.projectInUse}
                                            />
                                        </div>
                                    )}

                                    <div style={styles.input}>
                                        <TPSelect
                                            key={`order-field-${index}-${orderIndex}`}
                                            id={`order-field-${index}-${orderIndex}`}
                                            disabled={project.projectInUse}
                                            labelText={m?.[e.Step2SetInputBaseFieldLabel]}
                                            dataSource={[defaulGroup, ...fields]}
                                            value={eventLoadStructureDetailId}
                                            onChange={({target}: any) => {
                                                const id = target.value
                                                const fd = fields.find(f => `${f.eventLoadStructureDetailId}` === `${id}`)
                                                onChangeSetHandlerOrder(
                                                    "",
                                                    {
                                                        eventLoadStructureDetailId: fd ? `${fd.eventLoadStructureDetailId}` : "",
                                                        field: fd ? fd.field : "",
                                                        orderType: ""
                                                    },
                                                    index,
                                                    orderIndex
                                                )
                                            }}
                                        />
                                    </div>

                                    <div style={styles.input}>
                                        {(() => {
                                            const type = fields
                                                ?.find(f => `${f.eventLoadStructureDetailId}` === `${eventLoadStructureDetailId}`)
                                                ?.type;

                                            if (type === FieldType.DATE) {
                                                return (
                                                <TPSelect
                                                    key={`field-${index}-${orderIndex}-ORDERBY`}
                                                    id={`field-${index}-${orderIndex}-ORDERBY`}
                                                    disabled={project.projectInUse}
                                                    labelText={m?.[e.Step2SetInputReferenceValueLabel]}
                                                    dataSource={[defaulGroup, ...OrderByOptions]}
                                                    value={`${orderType ?? ""}`}
                                                    onChange={(e: any) => {
                                                        onChangeSetHandlerOrder(
                                                            "",
                                                            {
                                                                eventLoadStructureDetailId,
                                                                field,
                                                                orderType: e.target.value
                                                            },
                                                            index,
                                                            orderIndex
                                                        )
                                                    }}
                                                />
                                            );
                                            }

                                            if ([FieldType.PHONE, FieldType.NUMERIC, FieldType.EMAIL, FieldType.OTHER, FieldType.TEXTBOX].includes(type)) {
                                                return (
                                                    <TPSelect
                                                        key={`field-${index}-${orderIndex}-ORDERBYAD`}
                                                        id={`field-${index}-${orderIndex}-ORDERBYAD`}

                                                        disabled={project.projectInUse}
                                                        labelText={m?.[e.Step2SetInputReferenceValueLabel]}
                                                        dataSource={[defaulGroup, ...OrderByAscDescCOptions]}
                                                        value={`${orderType ?? ""}`}
                                                        onChange={(e: any) => {
                                                            onChangeSetHandlerOrder(
                                                                "",
                                                                {
                                                                    eventLoadStructureDetailId,
                                                                    field,
                                                                    orderType: e.target.value
                                                                },
                                                                index,
                                                                orderIndex
                                                            )
                                                        }}
                                                    />
                                                );
                                            }

                                        })()}
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <div>
                <TPButton
                    type={TPButtonTypes.link}
                    onClick={add}
                    disabled={project.projectInUse}
                >
                    {m?.[e.Step2SetAddNewButtonLabel]}
                </TPButton>
            </div>
        </div>
    );
}

const styles = {
    column: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    } as CSSProperties,
    row: {
        display: "flex",
        flexDirection: "row",
        gap: "10px",
    } as CSSProperties,
    alert: {
        minHeight: "20px",
        height: "max-content",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        padding: "5px 10px",
        margin: "10px 0",
    } as CSSProperties,
    title: {
        margin: "20px 0",
    } as CSSProperties,
    setContainer: {
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        placeContent: "stretch flex-start",
        alignItems: "stretch",
        gap: "20px",
    } as CSSProperties,
    sectionTitle: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        placeContent: "center flex-start",
        alignItems: "center",
        gap: "10px",
    } as CSSProperties,
    containerTitle: {
        boxSizing: "border-box",
        display: "flex",
        flex: "1 1 0%",
        flexDirection: "row",
        placeContent: "center space-between",
        alignItems: "center",
        padding: "0 10px",
        border: "2px solid #c7c8c9",
        borderRadius: "5px",
    } as CSSProperties,
    button: {
        width: "30px",
        height: "30px",
        flex: "1 1 30px",
        boxSizing: "border-box",
        maxWidth: "30px",
        borderBottom: "0",
    } as CSSProperties,
    subtitle: {
        margin: "20px 0",
    } as CSSProperties,
    input: {
        width: "20%",
    } as CSSProperties,
    start: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        placeContent: "flex-start",
        alignItems: "flex-start",
    } as CSSProperties,
    center: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        placeContent: "center",
        alignItems: "center",
    } as CSSProperties,
    radioGruop: {
        transform: "translateY(13px)",
    } as CSSProperties,
    conditionDeleteIcon: {
        transform: "translateY(30px)",
    } as CSSProperties,
    setIcon: {
        transform: "translateY(13px)",
    } as CSSProperties,
    titleContainer: {
        width: "100%",
    } as CSSProperties,
    name: {
        margin: "0",
    } as CSSProperties,
    nameInput: {
        width: "100%",
        border: "none",
    } as CSSProperties,
    defaultInput: {
        marginBottom: "20px",
        width: "200px",
    } as CSSProperties,
}
