import TPGlobal from "@/helpers/TPGlobal";
import {
  RightVerticalMenuContainer,
  RightVerticalMenuLi,
  RightVerticalMenuUl,
  RightVerticalMenuWrapper,
} from "@/layouts/Main/mainMenuStyles";
import { TPI18N } from "@/services/I18nService";
import React, {
  ReactElement,
  FC,
  useState,
  useEffect,
  useReducer,
} from "react";
import CalendarInsertUpdate from "./CalendarInsertUpdate";
import HolidaysAdmin from "./HolidaysAdmin";
import { TPPageSectionTitle, TPPageSubTitle, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPHorizontalTabs from "@/components/TPTabs/TPHorizontalTabs";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";

type CalendarContainerInsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
  tabId: string;
};

enum asideMenuItemTypes {
  "general" = 0,
  "holidays" = 1,
}

interface asideMenuItemsModel {
  itemLabel: string;
  itemType: asideMenuItemTypes;
  isActive: boolean;
  isEnabled: boolean;
  isVisible: boolean;
  onClickHandler: Function;
}

enum commandsEnum {
  "create_general" = 0,
  "create_holidays_data_and_change_mode" = 1,
  "set_active_general" = 2,
  "set_active_holidays" = 3,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

enum CalendarTabs {
  "GeneralInfo" = 0,
  "Holidays" = 1,
}

const CalendarContainerInsertUpdate: FC<CalendarContainerInsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
  tabId,
}): ReactElement => {
  //control state
  const resourceSet: string = "CalendarContainerInsertUpdate";
  const [titleGeneralInformation, setTitleGeneralInformation] = useState("");
  const [titleHolidays, setTitleHolidays] = useState("");
  const [pageTitleCalendar, setPageTitleCalendar] = useState("");
  const [pageTitleHolidays, setPageTitleHolidays] = useState("");
  const [exitLabel, setExitLabel] = useState("Exit");
  const [isReady, setIsReady] = useState(false);
  const [realRecordId, setRealRecordId] = useState(recordId);
  const [pageSubtitle, setPageSubtitle] = useState("");
  const [newLabel, setNewLabel] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [openNewItem, setOpenNewItem] = useState(false);

  const [currentTab, setCurrentTab] = useState<CalendarTabs>(CalendarTabs.GeneralInfo);

  const loadResources = async () => {
    //todo recursos del asidemenu

    setNewLabel(await TPI18N.GetText("HolidayAdminComponent", "NewButton"));
    
    setPageTitleCalendar(
      await TPI18N.GetText(
        "CalendarInsertUpdateComponent",
        "Title"+mode+"Label"
      )
    )

    setPageTitleHolidays(
      await TPI18N.GetText(
        "HolidayAdminComponent",
        "TitleLabel"
      )
    )

    setTitleGeneralInformation(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "GeneralInformationTitle",
      ),
    );
    setTitleHolidays(await TPI18N.GetText(resourceSet, "HolidaysTitle"));
    setExitLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ExitLabel"));
    //flag isReady
    setIsReady(true);
  };

  //handler to receive commands from child tabs
  const handleCallBackCommands = (theCommand: any) => {
    if (theCommand.result === "save_general_insert_and_change_mode") {
      //create holidays content
      let command: commandType = {
        type: commandsEnum.create_holidays_data_and_change_mode,
        payload: { recordId: theCommand.recordId },
      };
      dispatchCommand(command);
      callBackResult({
        result: "ChangeToUpdateMode",
        recordId: theCommand.recordId,
        tabId: tabId,
      });
      return;
    }
    if (theCommand.result === "OK") {
      callBackResult({ result: "OK", recordId: theCommand.recordId });
      return;
    }
    if (theCommand.result === "save_general_insert") {
      callBackResult({ result: "OK", recordId: "" });
      return;
    }
    if (theCommand.result === "save_general_update") {
      return;
    }
    if (theCommand.result === "cancel_general") {
      callBackResult({ result: "CANCEL", recordId: realRecordId });
      return;
    }
  };

  const onGeneralAsideItemClick = () => {
    let currentActive: asideMenuItemTypes;
    if (asideMenuState.filter((x) => x.isActive === true).length >= 1) {
      currentActive = asideMenuState.filter((x) => x.isActive === true)[0]
        .itemType;
      if (currentActive === asideMenuItemTypes.general) {
        //already on general
        return;
      }
    }
    let command: commandType = {
      type: commandsEnum.set_active_general,
      payload: null,
    };
    dispatchCommand(command);
    return;
  };

  const onDetailsAsideItemClick = () => {
    let command: commandType = {
      type: commandsEnum.set_active_holidays,
      payload: null,
    };
    dispatchCommand(command);
  };

  const handleOnExitClick = () => {
    callBackResult({ result: "CANCEL", recordId: realRecordId });
  };

  //create home content
  const realCreateGeneralContentCommand = (
    prevState: Array<asideMenuItemsModel>,
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let verticalElement: asideMenuItemsModel = {
      itemLabel: titleGeneralInformation,
      itemType: asideMenuItemTypes.general,
      isActive: true,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onGeneralAsideItemClick,
    };
    newStateHome.push(verticalElement);
    if (mode == "Update") {
      let verticalElement2: asideMenuItemsModel = {
        itemLabel: titleHolidays,
        itemType: asideMenuItemTypes.holidays,
        isActive: false,
        isVisible: true,
        isEnabled: true,
        onClickHandler: onDetailsAsideItemClick,
      };
      newStateHome.push(verticalElement2);
    }
    return newStateHome;
  };

  //create details content
  const realCreateDetailsContentCommand = (
    prevState: Array<asideMenuItemsModel>,
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = true;
    }

    let verticalElement: asideMenuItemsModel = {
      itemLabel: titleHolidays,
      itemType: asideMenuItemTypes.holidays,
      isActive: false,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onDetailsAsideItemClick,
    };
    newStateHome.push(verticalElement);
    return newStateHome;
  };

  //set active item
  const setActiveContent = (
    prevState: Array<asideMenuItemsModel>,
    itemType: asideMenuItemTypes,
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
      if (itemType == newStateHome[i].itemType) {
        newStateHome[i].isActive = true;
      }
    }
    return newStateHome;
  };

  //aside menu state with reducer
  let initialState: Array<asideMenuItemsModel> = [];
  const [asideMenuState, dispatchCommand] = useReducer(doCommand, initialState);

  function doCommand(
    prevState: Array<asideMenuItemsModel>,
    command: commandType,
  ) {
    switch (command.type) {
      case commandsEnum.create_general:
        return realCreateGeneralContentCommand(prevState);
      case commandsEnum.create_holidays_data_and_change_mode:
        let newRecordId: string;
        newRecordId = command.payload.recordId;
        setRealRecordId(newRecordId);
        return realCreateDetailsContentCommand(prevState);
      case commandsEnum.set_active_general:
        return setActiveContent(prevState, asideMenuItemTypes.general);
      case commandsEnum.set_active_holidays:
        return setActiveContent(prevState, asideMenuItemTypes.holidays);
    }
  }

  //run once to include functionsAdmin in first tab
  useEffect(() => {
    //load resources
    if (!isReady) {
      loadResources();
    } else {
      let command: commandType = {
        type: commandsEnum.create_general,
        payload: null,
      };
      dispatchCommand(command);
    }
  }, [isReady]);

  const subtitleCallback = (subtitle: string) => {
    setPageSubtitle(subtitle);
  };

  const openNewCallback = () => {
    setOpenNewItem(false);
  }

  const reloadCallback = (status: boolean) => {
    setIsLoading(status);
  }

  return (
    <>
    <ContentVerticalTabInsertUpdateStyled>
    <div className="calendar-main-container">
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: "10px" }}>
          <div>
            {currentTab == CalendarTabs.GeneralInfo ? (
              <TPPageTitle>{pageTitleCalendar}</TPPageTitle>
            ) : (
              <TPPageTitle>{pageTitleHolidays}</TPPageTitle>
            )}
            {mode == "Update" && (
              <TPPageSubTitle>{pageSubtitle}</TPPageSubTitle>
            )}
          </div>
          {
            mode == "Update" && currentTab == CalendarTabs.Holidays && (
              <TPButton
                id="new-customer-type"
                isDesignSystem={true}
                onClick={() => {setOpenNewItem(true)}}
                withIcon={TPIconTypes.add}
                orientationIcon="left"
                style={{ padding: "1px 18px" }}
              >
                {newLabel}
              </TPButton>
            )
          }
        </div>
        <TPHorizontalTabs
          labels={[titleGeneralInformation, titleHolidays]}
          activeTab={currentTab}
          onChange={(_, t) => {
            setCurrentTab(t);
          }}
          disabledTabs={mode == "Insert" ? [titleHolidays] : []}
        >
        </TPHorizontalTabs>
        <div className="calendar-tab" style={{ width: "100%" }}>
          {currentTab == CalendarTabs.GeneralInfo && 
            <CalendarInsertUpdate
              mode={mode}
              recordId={realRecordId}
              callBackResult={handleCallBackCommands}
              subtitleCallback={subtitleCallback}
            />
          }
          {currentTab == CalendarTabs.Holidays && 
            <HolidaysAdmin
              calendarId={realRecordId}
              openNewItem={openNewItem}
              openNewCallback={openNewCallback}
              reloadCallback={reloadCallback}
            />
          }
        </div>
      </div>
    </ContentVerticalTabInsertUpdateStyled>
      
    </>
  );
};

export default CalendarContainerInsertUpdate;
