import { Children, useEffect, useReducer, useState } from "react";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import {
    RecursiveRoutes,
    Data,
    LocalizedValues,
    InserFolder,
    InserFolderAndFile,
} from "@/models/ImagesAdmin/ImagesAdminModel";
import { ImageService } from "@/services/ImageService";
import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";
import { RecordLocalizedModel } from "../../models/Global/TPGlobalModels";

export class DataType {
    static file: string = "Image";
    static folder: string = "Folder";
}

export class OperationMode {
    static SelectFolder: string = "SelectFolder";
    static ActiveFolder: string = "ActiveFolder";
    static EditFolder: string = "EditFolder";
    static SelectFile: string = "SelectFile";
    static EditFile: string = "EditFile";
    static ActiveMenu: string = "ActiveMenu";
    static ActiveMenuFile: string = "ActiveMenuFile";
    static Defaul: string = "";
}

enum commandsEnum {
    selected_item,
    selected_all,
    initialize_folders,
    initialize_files,
    add_folder,
    add_file,
    disable_folder,
    disable_file,
    update_item,
    filter_data,
    clear_folder
}

class modelTPKeyValue {
    key: string = "";
    value: any = "";
    value2?: any = null;
    value3?: any = null;
    value4?: any = null;
    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }
}
type ContainerFolderArgs = {
    mode?: string;
    startFolder?: string;
};

export const useStatusImagesAdmin = () => {
    const resourceSet: string = "ImagesAdminComponent";
    const [listFolderModel, setListFolder] = useState<Array<RecursiveRoutes>>(
        Array<RecursiveRoutes>,
    );
    const [listFilesModel, setListFiles] = useState<Array<RecursiveRoutes>>(
        Array<RecursiveRoutes>,
    );
    const [listFilesModelTemp, setListFilesTemp] = useState<
        Array<RecursiveRoutes>
    >(Array<RecursiveRoutes>);
    const [listMultiFileSelected, setlistMultiFileSelected] = useState<
        Array<RecursiveRoutes>
    >(Array<RecursiveRoutes>);
    const [selectedItem, setSelectedItem] = useState<RecursiveRoutes>(
        new RecursiveRoutes(),
    );

    const [listTempFolder, setlistTempFolder] = useState<Array<Data>>([]);
    const [containerFolderArgs, setContainerFolderArgs] = useState<ContainerFolderArgs>({ mode: "", startFolder: "" });


    const [selectedFolderParent, setSelectedFolderParent] =
        useState<RecursiveRoutes>(new RecursiveRoutes());
    const [operationMode, setOperationMode] = useState<string>("");

    //option
    const [optionList, setOptionList] = useState<Array<TPKeyValue>>([]);
    const [optionStatusList, setOptionStatusList] = useState<Array<TPKeyValue>>(
        [],
    );
    const [sortAscendingOrDescending, setSortAscendingOrDescending] =
        useState<boolean>(false);
    const [filterStatus, setFlterStatus] = useState<string>("All");
    const [orderBy, setOrderBy] = useState<string>("Name");
    const [filter, setFiltery] = useState<string>("");
    const [viewListAndGrid, setViewList] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFileLoading, setFileIsLoading] = useState<boolean>(false);
    const [modeSelect, setModeSelect] = useState<boolean>(false);

    // Label
    const [nameItem, setNameItem] = useState("");
    const [sizeItem, setSizeItem] = useState("");
    const [dateItem, setDateItem] = useState("");
    const [nameFolder, setNameFolder] = useState("");
    const [folderCode, setFolderCode] = useState("");
    const [folderIdActive, setFolderIdActive] = useState<number>(0);

    const [allItem, setAllItem] = useState("");
    const [activeItem, setActiveItem] = useState("");
    const [inactiveItem, setInactiveItem] = useState("");

    useEffect(() => {
        var data = [...optionList];
        if (nameItem != "" && !data.find((s) => s.value == nameItem))
            data.push(new modelTPKeyValue("Name", nameItem));
        if (sizeItem != "" && !data.find((s) => s.value == sizeItem))
            data.push(new modelTPKeyValue("Size", sizeItem));
        if (dateItem != "" && !data.find((s) => s.value == dateItem))
            data.push(new modelTPKeyValue("Date", dateItem));

        setOptionList(data);
    }, [nameItem, sizeItem, dateItem]);

    useEffect(() => {
        var data = [...optionList];
        if (allItem != "" && !data.find((s) => s.value == allItem))
            data.push(new modelTPKeyValue("All", allItem));
        if (sizeItem != "" && !data.find((s) => s.value == activeItem))
            data.push(new modelTPKeyValue("Active", activeItem));
        if (dateItem != "" && !data.find((s) => s.value == inactiveItem))
            data.push(new modelTPKeyValue("Inactive", inactiveItem));

        setOptionStatusList(data);
    }, [allItem, activeItem, inactiveItem]);

    const loadResourcesAndOrganizations = async () => {
        setNameItem(await TPI18N.GetText(resourceSet, "NameItem"));
        setSizeItem(await TPI18N.GetText(resourceSet, "SizeItem"));
        setDateItem(await TPI18N.GetText(resourceSet, "DateItem"));

        setAllItem(await TPI18N.GetText(resourceSet, "AllItem"));
        setActiveItem(await TPI18N.GetText(resourceSet, "ActiveItem"));
        setInactiveItem(await TPI18N.GetText(resourceSet, "InactiveItem"));

        setNameFolder(await TPI18N.GetText(TPGlobal.globalResourceSet, "Name"));
        setFolderCode(await TPI18N.GetText(resourceSet, "FolderCodeLabel"));
    };

    const reducerAction = (
        status = Array<RecursiveRoutes>(),
        action: { type: commandsEnum; payload: RecursiveRoutes },
    ) => {
        switch (action.type) {
            case commandsEnum.initialize_folders:
                if (status == undefined || status == null)
                    status = Array<RecursiveRoutes>();
                var temp = [...status];
                temp.push(action.payload);
                getSortTPKeyValue();
                iterateData(temp, action.payload, commandsEnum.initialize_folders);
                return temp;
            case commandsEnum.clear_folder:
                status = Array<RecursiveRoutes>();
                return status;
            case commandsEnum.initialize_files:
                var temp = [...status];
                iterateData(temp, selectedItem, commandsEnum.initialize_files);
                return temp;
                break;
                return temp;
            case commandsEnum.selected_item:
                var temp = [...status];
                setSelectedItem(action.payload);
                if (action.payload.type == DataType.folder) {
                    setSelectedFolderParent(action.payload);
                }
                return status;
                break;
            case commandsEnum.add_folder:
                if (status == undefined || status == null)
                    status = Array<RecursiveRoutes>();
                var temp = [...status];
                selectedItem.childrens.push(action.payload);
                return temp;
                break;
            case commandsEnum.update_item:
                if (status == undefined || status == null)
                    status = Array<RecursiveRoutes>();
                var temp = [...status];

                if (action.payload.type == DataType.folder) {
                    var tempFolder = selectedItem.childrens.find(
                        (s) => s.id != action.payload.id,
                    );
                    tempFolder = action.payload;
                }

                if (action.payload.type == DataType.file) {
                    var tempFilter = selectedItem.childrens.find(
                        (s) => s.id == action.payload.id,
                    );
                    tempFilter = action.payload;
                }
                return temp;
                break;
            case commandsEnum.disable_folder:
                if (status == undefined || status == null)
                    status = Array<RecursiveRoutes>();
                var temp = [...status];
                var temp1 = selectedItem.childrens.find(
                    (s) => s.id != action.payload.id,
                );
                temp1 = action.payload;
                return temp;
                break;
            default:
                return status;
        }
    };

    //Iterate the routes as recursive and search by parent id
    const iterateFolder = (
        list: Array<RecursiveRoutes>,
        newFolder: RecursiveRoutes,
        action: commandsEnum,
        level: number = 0,
    ) => {
        var mode = false;
        list.forEach((element) => {
            const node = element;
            if (element.id == newFolder.parent) {
                mode = true;
                if (action == commandsEnum.disable_folder) {
                    element.isActive = !element.isActive;
                    LoadData(list, newFolder);
                }
            }
            if (node.childrens.length > 0 && !mode) {
                iterateFolder(node.childrens, newFolder, level + 1);
            }
        });
    };

    //Iterate routes as recursive and search by route id
    const iterateData = (
        list: Array<RecursiveRoutes>,
        newFolder: RecursiveRoutes,
        action: commandsEnum,
        level: number = 0,
    ) => {
        var mode = false;

        list.forEach((element) => {
            const node = element;

            if (element.id == newFolder.id) {
                mode = true;

                if (
                    action == commandsEnum.initialize_folders &&
                    element.type == DataType.folder
                ) {

                    var data = [...element.childrens].filter(
                        (s) => s.type == DataType.folder,
                    );
                    var temp = { ...element };
                    temp.childrens = data;

                    var folder;
                    if (listFolderModel)
                        folder = listFolderModel.find((s) => s.id == element.id);

                    if (folder) {
                        if (folder.childrens == undefined)
                            folder.childrens = Array<RecursiveRoutes>();
                        folder.childrens = data;
                    } else {
                        listFolderModel.push(temp);
                    }

                    setListFolder(listFolderModel);
                }
            }
            if (node.childrens.length > 0 && !mode) {
                iterateFolder(node.childrens, newFolder, level + 1);
            }
        });
    };

    //Common to reset folders and files
    const LoadData = (
        list: Array<RecursiveRoutes>,
        newFolder: RecursiveRoutes,
    ) => {
        setListFolder(new Array<RecursiveRoutes>());
        iterateData(list, newFolder, commandsEnum.initialize_folders);
        iterateData(list, selectedFolderParent, commandsEnum.initialize_files);
    };

    //Initializes values
    const getSortTPKeyValue = () => {
        loadResourcesAndOrganizations();
    };

    const [status, dispatch] = useReducer(
        reducerAction,
        new Array<RecursiveRoutes>(),
    );

    //Called when initializing the component to show folders and files
    const handleInitializeFolders = (id: number = -1, modeSelectFolder: boolean = false) => {
        setIsLoading(true);
        let _expectedCodes: number[] = [200];
        let request = new ImageService(_expectedCodes);
        let mode = 2;
        if (modeSelectFolder) mode = 1;
        if (id == -1) id = 0;
        var responseData = request.getFilterAllFolderAndFile(0, DataType.folder, mode);
        if (responseData) {
            responseData.then((e: Array<Data>) => {
                var temp = Array<RecursiveRoutes>();
                if (e) {
                    setlistTempFolder(e.filter(s => s.id != 0));

                    //if (modeSelectFolder)
                    //    temp = convertToRecursiveRoutes(e.filter(s => s.id === id));
                    //else                    
                    temp = convertToRecursiveRoutes(e.filter(s => s.id != 0));
                    temp.forEach((f) => {                       
                        dispatch({ type: commandsEnum.initialize_folders, payload: f });
                    });
                    handleLoading(false);

                } else {
                    handleLoading(false);
                }
            });
        }

    };

    const handleLoading = (mode: boolean) => {
        if (!isFileLoading)
            setIsLoading(mode);
    };

    //Called when initializing the component create the list and files
    const handleInitializeFiles = (
        data: RecursiveRoutes = new RecursiveRoutes(),
    ) => {
        setIsLoading(true);
        setFileIsLoading(true);
        let _expectedCodes: number[] = [200];
        let request = new ImageService(_expectedCodes);
        var responseData;
        let mode = 2;
        if (modeSelect) mode = 1;    
        if (data.type == DataType.folder) {
            responseData = request.getFilterAllFolderAndFile(
                data.id,
                DataType.file,
                mode,
            );


            if (responseData) {
                responseData.then((e: Array<Data>) => {
                    var temp = Array<RecursiveRoutes>();
                    let dataList = e.filter(s => s && s.type && s.type != "Folder");
                    if (dataList && dataList.length > 0)
                        temp = convertToRecursiveRoutesFiles(dataList);
                    setListFiles(temp);
                    setListFilesTemp(temp);

                    setFileIsLoading(false);
                    setIsLoading(false);
                });
            } else {
                setFileIsLoading(false);
                setIsLoading(false);
            }

        }
    };


    const handleInitializeFilesModeSelect = (id: number) => {
        handleLoading(true);
        let _expectedCodes: number[] = [200];
        let request = new ImageService(_expectedCodes);
        var responseData;
        let mode = 1;

        if (true) {
            responseData = request.getFilterAllFolderAndFile(
                id,
                DataType.file,
                mode,
            );
            if (responseData) {
                responseData.then((e: Array<Data>) => {

                    let route = listTempFolder.find(s => s.id == id);
                    if (route != undefined) {
                        let dataList = e.filter(s => s && s.type && s.type != "Folder");
                        if (dataList && dataList.length > 0) {
                            var folder = convertToRecursiveRoutes(dataList);
                            if (folder)
                                handleSelected(folder[0], OperationMode.ActiveFolder);
                        }
                    }


                    var temp = Array<RecursiveRoutes>();
                    temp = convertToRecursiveRoutesFiles(e.filter(s => s.type && s.type != "Folder"));

                    setListFiles(temp);
                    setListFilesTemp(temp);
                    handleLoading(false);
                });
            }
        }
    };

    //Action to select folders or files and the type of selection, folder, file, fileEdit, etc.
    const handleSelected = (data: RecursiveRoutes, _operationMode: string) => {
        setSelectedItem(data);       
        setOperationMode(_operationMode);
        dispatch({ type: commandsEnum.selected_item, payload: data });
        if (
            data.type == DataType.folder &&
            _operationMode == OperationMode.SelectFolder
        ) {
            handleInitializeFiles(data);
            handleLoading(true);
        }
    };

    //Add or remove multiple items to the multiple list as selected selection
    const handleMultiSelectedFile = (item: RecursiveRoutes) => {
        var list = [...listMultiFileSelected];
        /* setlistMultiFileSelected(new Array<RecursiveRoutes>());*/
        if (list.find((s) => s.id == item.id)) {
            //list = list.filter((s) => s.id != item.id);
            //setlistMultiFileSelected(list);
        } else {
            list.push(item);
            setlistMultiFileSelected(list);
        }
    };

    const handleMultiSelectedFileAll = (list: Array<RecursiveRoutes>) => {
        setlistMultiFileSelected(list);
    };

    //Allows you to select all the files in the selected folder
    const handleAllSelectedFile = () => {
        setlistMultiFileSelected(listFilesModel);
    };

    //Eliminate multi-selection of files
    const handleMultiSelectedFileClear = () => {
        setlistMultiFileSelected(new Array<RecursiveRoutes>());
    };

    //Temporarily create a new folder
    const handleNewFolder = (folder: RecursiveRoutes, folderNew: Data) => {
        setIsLoading(true);
        var newFolder = new RecursiveRoutes();

        let tempName = folderNew.folderNameLocalizedValues.find(s => s.languageId == TPGlobal.TPClientDefaultLanguage);
        if (tempName)
            newFolder.folderName = tempName.localizedValue;


        newFolder.localizedFolderName = newFolder.folderName;
        newFolder.folderCode = folderNew.folderCode;
        newFolder.type = DataType.folder;
        newFolder.parent = folder.id;
        newFolder.isActive = true;
        newFolder.id = 0;
        newFolder.userUpload = TPGlobal.currentUserGuid;
        newFolder.folderNameLocalizedValues = folderNew.folderNameLocalizedValues;

        let temp = handleConvertRecursiveRouterToInserFolder(newFolder);
        let _expectedCodes: number[] = [200,404];
        let request = new ImageService(_expectedCodes);
        request.insertFolder(temp).then((e) => {
            dispatch({ type: commandsEnum.add_folder, payload: newFolder });
            handleInitializeFolders();
            setIsLoading(false);
        });
    };

    //Update folders
    const handleUpdateFolder = (folder: RecursiveRoutes) => {
        handleLoading(true);
        let _expectedCodes: number[] = [200];
        let request = new ImageService(_expectedCodes);
        let temp = handleConvertRecursiveRouterToUpdate(folder);
        request.updateFolder(temp).then((e) => {
            setListFolder([]);
            dispatch({
                type: commandsEnum.clear_folder,
                payload: new RecursiveRoutes(),
            });
            handleInitializeFolders();
            handleLoading(false);
        });
    };

    //Update files
    const handleUpdateFile = (file: RecursiveRoutes) => {
        handleLoading(true);
        let _expectedCodes: number[] = [200];
        let request = new ImageService(_expectedCodes);
        let temp = handleConvertRecursiveRouterToUpdate(file);
        request.updateFolder(temp).then((e) => {
            handleLoading(false);
        });
    };

    const DeleteFileOrFolderById = (id: string, type: DataType) => {
        setIsLoading(true);
        let _expectedCodes: number[] = [200];
        let request = new ImageService(_expectedCodes);
        request.DeleteFileById(id, true).then((e) => {

            if (type == DataType.file) {
                handleInitializeFiles(selectedFolderParent);
            }
            if (type == DataType.folder) {
                setListFolder([]);
                handleInitializeFolders();
            }

        });
    }

    //Deactivate a folder
    const handleDisableFolder = (folder: RecursiveRoutes) => {
        let _expectedCodes: number[] = [200];
        let request = new ImageService(_expectedCodes);
        let temp = handleConvertRecursiveRouterToUpdate(folder);
        request.updateFolder(temp).then((e) => {
            if (e)
                if (
                    e.responseData.responseMessage.code &&
                    e.responseData.responseMessage.code == 200
                ) {
                    dispatch({ type: commandsEnum.update_item, payload: folder });
                    handleInitializeFiles(selectedFolderParent);
                }
        });
    };

    //Deactivate a file
    const handleDisableFile = (folder: RecursiveRoutes) => {
        let _expectedCodes: number[] = [200];
        let request = new ImageService(_expectedCodes);
        let temp = handleConvertRecursiveRouterToUpdate(folder);
        request.updateFolder(temp).then((e) => {
            if (e)
                if (
                    e.responseData.responseMessage.code &&
                    e.responseData.responseMessage.code == 200
                ) {
                    dispatch({ type: commandsEnum.update_item, payload: folder });
                    handleInitializeFiles(selectedFolderParent);
                }
        });
    };

    //Order
    const handleOrderData = (mode: string) => {
        dispatch({
            type: commandsEnum.filter_data,
            payload: new RecursiveRoutes(),
        });
    };

    //Sort by any descending and ascending
    const handleSortAscendingOrDescending = (mode: boolean) => {
        setSortAscendingOrDescending(mode);
        dispatch({
            type: commandsEnum.filter_data,
            payload: new RecursiveRoutes(),
        });
    };

    const handleSortFolderActive = (mode: string) => {
        setFlterStatus(mode);
        dispatch({
            type: commandsEnum.filter_data,
            payload: new RecursiveRoutes(),
        });
    };

    //Switch between list or grid views
    const handleSortView = (mode: boolean) => {
        setViewList(mode);
    };

    //Sort by name, weight etc.
    const handleOrderBy = (mode: string) => {
        setOrderBy(mode);
        dispatch({
            type: commandsEnum.filter_data,
            payload: new RecursiveRoutes(),
        });
    };


    //Filter files
    const handleFilter = (mode: string) => {
        setFiltery(mode);
        dispatch({
            type: commandsEnum.filter_data,
            payload: new RecursiveRoutes(),
        });
    };

    //Upload files
    const handleUploadImage = (file: any, fileName: string, keywords: string, isActive: boolean, isUpdate: boolean, id?: number) => {
        handleLoading(true);
        let _expectedCodes: number[] = [200];
        let request = new ImageService(_expectedCodes, true);
        if (!isUpdate && selectedFolderParent) {
            request.UploadFile(file, selectedFolderParent.id, fileName, keywords, isActive).then((e) => {
                handleInitializeFiles(selectedFolderParent);

            });
        } else
            if (isUpdate) {
                request.UpdateUploadFile(file, id + "", fileName, keywords, isActive).then((e) => {
                    handleInitializeFiles(selectedFolderParent);

                });
            }
    };

    const handleConvertRecursiveRouterToInserFolder = (
        data: RecursiveRoutes,
    ): InserFolder => {
        let temp = new InserFolder();
        temp.folderName = data.folderName;
        temp.keywords = data.keywords;
        temp.isActive = data.isActive;
        temp.parent = data.parent;
        temp.guidUserUpload = data.userUpload;
        temp.folderNameLocalizedValues = data.folderNameLocalizedValues;

        return temp;
    };

    const handleConvertRecursiveRouterToUpdate = (
        data: RecursiveRoutes,
    ): InserFolderAndFile => {
        let temp = new InserFolderAndFile();

        if (data.folderNameLocalizedValues) {
            let tempName = data.folderNameLocalizedValues.find(s => s.languageId == TPGlobal.TPClientDefaultLanguage);
            if (tempName) {
                temp.folderName = tempName.localizedValue;
            }
        }

        temp.id = data.id;
        temp.fileName = data.fileName;
        temp.keywords = data.keywords;
        temp.isActive = data.isActive;
        temp.type = data.type;
        temp.parent = data.parent;
        temp.folderCode = data.folderCode;
        temp.guidUserUpload = TPGlobal.currentUserGuid;

        if (data.folderNameLocalizedValues) {
            let list = data.folderNameLocalizedValues.map((e: any, index) => ({
                languageId: e.languageId,
                localizedValue: e.localizedValue,
                order: index
            }));
            temp.folderNameLocalizedValues = list;

        } else {
            temp.folderNameLocalizedValues = [
                { languageId: TPGlobal.TPClientDefaultLanguage, localizedValue: "System", order: 0 },
            ];
        }
        return temp;
    };

    function convertToRecursiveRoutes(dataList: Data[]): RecursiveRoutes[] {
        const map = new Map<number, RecursiveRoutes>();

        dataList.forEach((data) => {
            map.set(data.id, {
                id: data.id,
                folderName: data.folderName,
                folderCode: data.folderCode,
                localizedFolderName: data.localizedFolderName,
                type: data.type ? data.type : "",
                parent: data.parent,
                fileName: data.fileName,
                extension: data.extension,
                dateUpload: data.dateUpload,
                userUpload: data.userUpload,
                keywords: data.keywords,
                size: data.size,
                dimensions: data.dimensions,
                blobId: data.blobId,
                isActive: data.isActive,
                imageUrl: data.imageUrl?.trim() || "",
                thumbnailUrl: data.thumbnailUrl?.trim() || "",
                folderNameLocalizedValues: data.folderNameLocalizedValues,
                childrens: [],
            });
        });

        const roots: RecursiveRoutes[] = [];

        map.forEach((route) => {
            if (route.parent !== undefined && map.has(route.parent)) {
                map.get(route.parent)!.childrens.push(route);
            } else {
                roots.push(route);
            }
        });

        return roots;
    }

    function convertToRecursiveRoutesFiles(dataList: Data[]): RecursiveRoutes[] {
        let list = new Array<RecursiveRoutes>();

        let data = dataList.map((data) => {
            list.push({
                id: data.id,
                folderName: data.folderName,
                folderCode: data.folderCode,
                localizedFolderName: data.localizedFolderName,
                type: data.type ? data.type : "",
                parent: data.parent,
                fileName: data.fileName,
                extension: data.extension,
                dateUpload: data.dateUpload,
                userUpload: data.userUpload,
                keywords: data.keywords,
                size: data.size,
                dimensions: data.dimensions,
                blobId: data.blobId,
                isActive: data.isActive,
                imageUrl: data.imageUrl?.trim() || "",
                thumbnailUrl: data.thumbnailUrl?.trim() || "",
                folderNameLocalizedValues: data.folderNameLocalizedValues,
                childrens: [],
            });
        });

        return list;
    }



    return {
        status,
        listFilesModel,
        listFolderModel,
        selectedItem,
        selectedFolderParent,
        optionList,
        optionStatusList,
        sortAscendingOrDescending,
        viewListAndGrid,
        orderBy,
        filterStatus,
        listMultiFileSelected,
        operationMode,
        isLoading,
        modeSelect,
        setModeSelect,
        folderIdActive,
        setFolderIdActive,
        handleMultiSelectedFile,
        handleMultiSelectedFileAll,
        handleMultiSelectedFileClear,
        handleAllSelectedFile,
        handleSelected,
        handleNewFolder,
        handleInitializeFolders,
        handleInitializeFiles,
        handleInitializeFilesModeSelect,
        handleUpdateFolder,
        handleUpdateFile,
        handleDisableFolder,
        handleDisableFile,
        handleOrderData,
        handleSortAscendingOrDescending,
        handleSortFolderActive,
        handleSortView,
        handleOrderBy,
        handleFilter,
        handleUploadImage,
        handleLoading,
        listTempFolder,
        containerFolderArgs,
        setContainerFolderArgs,
        DeleteFileOrFolderById

    };
};
