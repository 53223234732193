import TPGlobal from "@/helpers/TPGlobal";
import { ComplementsRenderTP, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { AdditionalDataDateTypeEnum } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import moment from "moment";
import React, { useEffect, useImperativeHandle, useState } from "react";
import TPDatePicker from "../TPDatePicker/TPDatePicker";
import { TPAddtionalDataUIModes } from "./TPAdditionalDataUImodes";
import { format, parse } from "date-fns";

export type TPAdditionalDataDateProps = {
    idControl: string;
    modeUI: TPAddtionalDataUIModes;
    labelText: string;
    labelStyles?: Array<TPKeyValue>;
    isMandatory: boolean;
    defaultValue: string;
    onValueChange?: Function | null;
    type: string;
    minDate: string | null;
    maxDate: string | null;
    customDateFormat?: string | null;
    complementsRenderTP?: ComplementsRenderTP;
};

const TPAdditionalDataDate = React.forwardRef(
    (
        {
            idControl,
            modeUI,
            labelText,
            labelStyles = [],
            isMandatory,
            defaultValue,
            onValueChange = null,
            type,
            minDate,
            maxDate,
            customDateFormat,
            complementsRenderTP
        }: TPAdditionalDataDateProps,
        ref,
    ) => {
        const componentFileName: string = "TPAdditionalDataDate.tsx";
        const resourceSet: string = "TPAdditionalDataDate";
        //screen resources
        const [dateRangeLabel, setDateRangeLabel] = useState("");

        const [additionalDataValue, setAdditionalDataValue] = useState<Date>();
        const [errorMessageValue, setErrorMessageValue] = useState("");
        const [mandatoryErrorMessage, setMandatoryErrorMessage] = useState("");
        const [isLoading, setIsLoading] = useState(true);
        const [realMinDate, setRealMinDate] = useState<Date | null>(null);
        const [realMaxDate, setRealMaxDate] = useState<Date | null>(null);
        const [helpMessage, setHelpMessage] = useState<string | null>("");
        var minDateDate: Date;
        var maxDateDate: Date;
        const separator = "||";

        const loadResourcesAndDates = async () => {
            setMandatoryErrorMessage(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),
            );

            setDateRangeLabel(await TPI18N.GetText(resourceSet, "DateRangeLabel"));
            if (type == TPGlobal.dateTimeFormat) {
                if (minDate) {
                    if (minDate.includes("Now")) {
                        minDateDate = moment()
                            .add(+minDate.replaceAll("Now", ""), "d")
                            .toDate();
                        setRealMinDate(minDateDate);
                    } else {
                        minDateDate = moment(
                            minDate,
                            (TPGlobal.dateTimeFormat).toUpperCase(),
                        ).toDate();
                        setRealMinDate(minDateDate);
                    }
                }

                if (maxDate) {
                    if (maxDate.includes("Now")) {
                        maxDateDate = moment()
                            .add(+maxDate.replaceAll("Now", ""), "d")
                            .toDate();
                        setRealMaxDate(maxDateDate);
                    } else {
                        maxDateDate = moment(
                            maxDate,
                            (TPGlobal.dateTimeFormat).toUpperCase(),
                        ).toDate();
                        setRealMaxDate(maxDateDate);
                    }
                }

                if (minDate === null && maxDate !== null) {
                    setHelpMessage(
                        `<=${moment(maxDateDate).format((TPGlobal.dateTimeFormat).toUpperCase())}`,
                    );
                }

                if (minDate !== null && maxDate === null) {
                    setHelpMessage(
                        `>=${moment(minDateDate).format((TPGlobal.dateTimeFormat).toUpperCase())}`,
                    );
                }

                if (minDate && maxDate) {
                    setHelpMessage(
                        `${minDate.includes("Now")
                            ? moment()
                                .add(+minDate.replaceAll("Now", ""), "d")
                                .format((TPGlobal.dateTimeFormat).toUpperCase())
                            : moment(
                                minDate,
                                (TPGlobal.dateTimeFormat).toUpperCase(),
                            ).format((TPGlobal.dateTimeFormat).toUpperCase())
                        } -- ${maxDate.includes("Now")
                            ? moment()
                                .add(+maxDate.replaceAll("Now", ""), "d")
                                .format((TPGlobal.dateTimeFormat).toUpperCase())
                            : moment(
                                maxDate,
                                (TPGlobal.dateTimeFormat).toUpperCase(),
                            ).format((TPGlobal.dateTimeFormat).toUpperCase())
                        }`,
                    );
                }
            } else {
                setRealMinDate(null);
                setRealMaxDate(null);
            }

            overrideDefaultValue();

            setIsLoading(false);
        };

        const handleCustomDateFormat = () => {
            return TPGlobal.dateTimeFormat;
        }


        const overrideDefaultValue = () => {
            try {

                if (defaultValue) {
                    //check if default value is on range
                   
                    let defaultValueDate = moment(
                        defaultValue.toUpperCase(),
                        handleCustomDateFormat(),
                    ).toDate();

                    let theDateIsValidated = moment(
                            defaultValue.toUpperCase(),
                            handleCustomDateFormat(),
                        );

                    if (theDateIsValidated.isValid()) {

                        if (type == AdditionalDataDateTypeEnum.dateRange) {
                            if (minDateDate != undefined && maxDateDate != undefined) {
                                if (
                                    moment(defaultValueDate).isBetween(minDateDate, maxDateDate)
                                ) {
                                    setAdditionalDataValue(defaultValueDate);
                                } else {
                                    setAdditionalDataValue(minDateDate);
                                }
                            }
                        } else {
                            setAdditionalDataValue(defaultValueDate);
                        }
                    }
                } else {
                    if (type == AdditionalDataDateTypeEnum.dateRange) {
                        if (minDateDate != undefined && maxDateDate != undefined) {
                            setAdditionalDataValue(minDateDate);
                        }
                    }
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} loadResourcesAndDates ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${componentFileName} loadResourcesAndDates ex`);
            }
        };

        useImperativeHandle(ref, () => ({
            getValueFromParent() {
                return !additionalDataValue
                    ? ""
                    : moment(additionalDataValue).format(
                        (handleCustomDateFormat()),
                    );
            },
            validateFromParent() {
                if (
                    isMandatory &&
                    (!additionalDataValue || !moment(additionalDataValue).isValid())
                ) {
                    setErrorMessageValue(mandatoryErrorMessage);
                    return false;
                }
                return true;
            },
        }));

        const renderCollectMode = () => {
            let labelStyle: any = null;
            let i: number;
            for (i = 0; i <= labelStyles.length - 1; i++) {
                if (labelStyle == null) {
                    labelStyle = {};
                }
                labelStyle[labelStyles[i].key] = labelStyles[i].value;
            }

            return !isLoading ? (
                <div className="form-group">
                    <TPDatePicker
                        labelText={labelText}
                        isMandatory={isMandatory}
                        selectedDate={additionalDataValue}
                        onChangeDate={handlerOnValueChange}
                        minDate={realMinDate}
                        maxDate={realMaxDate}
                        customDateFormat={customDateFormat}
                        errorMessage={errorMessageValue}
                        complementsRenderTP={complementsRenderTP}
                    >
                        {helpMessage ? (
                            <div className="m-2">
                                <div>{dateRangeLabel}</div>
                                {helpMessage}
                            </div>
                        ) : undefined}
                    </TPDatePicker>
                </div>
            ) : null;
        };

        const renderViewMode1 = () => {
            let labelStyle: any = null;
            let i: number;
            for (i = 0; i <= labelStyles.length - 1; i++) {
                if (labelStyle == null) {
                    labelStyle = {};
                }
                labelStyle[labelStyles[i].key] = labelStyles[i].value;
            }

            return (
                //todo labelstyles
                //todo horizontal
                <div className="form-group">
                    <TPDatePicker
                        labelText={labelText}
                        isMandatory={isMandatory}
                        selectedDate={additionalDataValue}
                        onChangeDate={handlerOnValueChange}
                        disabled={true}
                        customDateFormat={customDateFormat}
                        errorMessage={errorMessageValue}
                        defaultValue={defaultValue}
                    ></TPDatePicker>
                </div>
            );
        };

        const renderViewMode2 = () => {
            let labelStyle: any = null;
            let i: number;
            for (i = 0; i <= labelStyles.length - 1; i++) {
                if (labelStyle == null) {
                    labelStyle = {};
                }
                labelStyle[labelStyles[i].key] = labelStyles[i].value;
            }

            return (
                <React.Fragment>
                    <div className="div">
                        <div className="col">
                            <span
                                className="tpbold"
                                style={labelStyle}
                            >{`${labelText}: `}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span>
                                {moment(additionalDataValue).format(
                                    (customDateFormat ? customDateFormat : TPGlobal.dateTimeFormat).toUpperCase(),
                                )}
                            </span>
                        </div>
                    </div>
                </React.Fragment>
            );
        };

        const handlerOnValueChange = (e: Date) => {
            setErrorMessageValue("");
            setAdditionalDataValue(e);
            if (onValueChange) {
                const newformattedDate = format(e, TPGlobal.dateTimeFormat);
                onValueChange(newformattedDate.toLowerCase(), idControl);
            }
        };

        useEffect(() => {
            //load validation error messages
            loadResourcesAndDates();
        }, []);


        useEffect(() => {
            overrideDefaultValue();
        }, [defaultValue]);

        return (
            <>
                {modeUI == TPAddtionalDataUIModes.Collect
                    ? renderCollectMode()
                    : modeUI == TPAddtionalDataUIModes.View1
                        ? renderViewMode1()
                        : renderViewMode2()}
            </>
        );
    },
);

export default TPAdditionalDataDate;
