import NoDataImg from "@/assets/images/TPImages/tp-image-grid.png";
import NoResultsImg from "@/assets/images/no-data.png";
import { TPChip } from "@/components/TPChip/TPChip";
import { TPPaginator } from "@/components/TPPaginator/TPPaginator";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPModal from "@/layouts/TPModal/TPModal";
import {
  ModalSizeEnum,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { StyledNoDataImage } from "@/modules/core/components/dynamic-table/dynamic-table-styles";
import { GroupsService } from "@/services/GroupsService";
import { UserGroupService } from "@/services/UserGroupService";
import "assets/images/TPImages/tp-image-grid.png";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import "../../assets/Styles.css";
import { useQueuesAdminSelectOptions } from "../../assets/controller";
import useQueueAdminLabels from "../../assets/labels";
import QueueAdminUserCheckbox from "../UserCheckbox";
import TablePager from "@/modules/core/components/dynamic-table/TablePager";
import allThemes from "@/assets/styles/theme";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPAvatar } from "@/components/TPAvatar/TPAvatar";
import {
  ColumnStyles,
  CustomColumnNames,
} from "@/pages/Projects/ProjectDetail/ProjectDetailDynamicTable";
import { UserService } from "@/services/UserService";

interface QueueAssignUsersTabProperties {
  groupId: number;
  tabCallback: Function;
  active: boolean;
  updateCallback: Function;
}

interface QueueAssignModel {
  id: string;
  name: string;
  login: string;
  selected: boolean;
  active: boolean;
  avatar: string;
}

type ColumnComponentProps<T> = {
  value: any;
  item: T;
};

const QueueAssignUsersTab: FC<QueueAssignUsersTabProperties> = function ({
  groupId,
  tabCallback,
  updateCallback,
  active,
}) {
  const { labels, labelsLoaded } = useQueueAdminLabels();
  const [usersLoaded, setUsersLoaded] = useState<boolean>(false);
  const [avatarsLoaded, setAvatarsLoaded] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState<QueueAssignModel[]>([]);
  const [filteredAssignedUsers, setFilteredAssignedUsers] = useState<
    QueueAssignModel[]
  >([]);

  const [assignedUsersQuery, setAssignedUsersQuery] = useState<string>("");

  const [allUnassignedSelected, setAllUnassignedSelected] =
    useState<boolean>(false);
  const [unassignedUsers, setUnassignedUsers] = useState<QueueAssignModel[]>(
    [],
  );
  const [filteredUnassignedUsers, setFilteredUnassignedUsers] = useState<
    QueueAssignModel[]
  >([]);

  const [unassignedUsersQuery, setUnassignedUsersQuery] = useState<string>("");

  const [profileFilter, setProfileFilter] = useState<string>("");
  const [teamFilter, setTeamFilter] = useState<string>("");
  const [superiorFilter, setSuperiorFilter] = useState<string>("");
  const [functionsFilter, setFunctionsFilter] = useState<string>("");
  const [nameFilter, setNameFilter] = useState<string>("");

  const [dataSorted, setDataSorted] = useState<boolean>(true);
  const [contentLoaded, setContentLoaded] = useState<boolean>(false);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [cancelWarningVisible, setCancelWarningVisible] =
    useState<boolean>(false);
  const [deselectedUser, setDeselectedUser] = useState<QueueAssignModel | null>(
    null,
  );
  const [deselectedUsers, setDeselectedUsers] = useState<QueueAssignModel[]>(
    [],
  );

  const [canAssign, setCanAssign] = useState(false);
  const [processingAssign, setProcessingAssign] = useState(false);

  const [isAddUsersModalVisible, setIsAddUsersModalVisible] =
    useState<boolean>(false);
  const {
    teamsFilterOptions,
    profileFilterOptions,
    superiorFilterOptions,
    functionsFilterOptions,
  } = useQueuesAdminSelectOptions();

  const redrawTable = function () {
    setContentLoaded(false);
    setFilteredAssignedUsers(
      assignedUsers.filter(
        (user) =>
          user.name?.toLowerCase().includes(assignedUsersQuery.toLowerCase()) ||
          user.login
            ?.toLocaleLowerCase()
            .includes(assignedUsersQuery.toLowerCase()),
      ),
    );

    setFilteredUnassignedUsers(
      unassignedUsers.filter(
        (user) =>
          (user.name
            ?.toLowerCase()
            .includes(unassignedUsersQuery.toLowerCase()) ||
            user.login
              ?.toLocaleLowerCase()
              .includes(unassignedUsersQuery.toLowerCase())) &&
          !assignedUsers.find((assigned) => assigned.id == user.id),
      ),
    );

    setContentLoaded(true);
  };

  const handleMassiveUpdate = function () {
    if (!canAssign) return;
    setCanAssign(false);
    setProcessingAssign(true);
    setAllUnassignedSelected(false);
    const groupServiceInstance = new GroupsService();
    const unassignedSelected = filteredUnassignedUsers.filter(
      (user) => user.selected,
    );

    if (unassignedSelected.length > 0) {
      groupServiceInstance
        .assignUsersToGroup(
          {
            idGroup: groupId,
            usersIds: unassignedSelected.map((user) => Number(user.id)),
          },
          true,
          true,
          [200],
        )
        .then(() => {
          setContentLoaded(false);
          setCanAssign(true);
          setIsAddUsersModalVisible(false);
          updateCallback({
            result: "ReloadGrid",
          });
        })
        .catch((err) => console.error(err))
        .finally(() => setProcessingAssign(false));
    }
  };

  const unassignUserById = function (id: any) {
    const groupServiceInstance = new GroupsService();
    groupServiceInstance
      .unassignUserToGroup(String(groupId), id, true, true, [200])
      .then(() => {
        const updatedUsers = assignedUsers.filter((user) => user.id !== id);
        setAssignedUsers(updatedUsers);

        if (updatedUsers.length === 0) {
          setFilteredAssignedUsers([]);
          setNoDataFound(true);
        } else {
          setFilteredAssignedUsers(updatedUsers);
        }
      });
  };

  const columnNames: CustomColumnNames<QueueAssignModel> = {
    selected: "",
    avatar: "Photo",
    name: "User",
    login: "Email",
  };

  const columnStyles = {
    selected: ({ value, item }: any) => (
      <TPCheckBox
        checked={item.selected}
        onChange={(e: any) => {
          const updatedUsers = assignedUsers.map((user) =>
            user.id === item.id
              ? { ...user, selected: e.target.checked }
              : user,
          );
          setAssignedUsers(updatedUsers);
        }}
      />
    ),
    avatar: ({ value, item }: any) => (
      <TPAvatar
        src={item.avatar}
        size={30}
        circular={true}
        enableFullscreen={true}
      />
    ),
  };

  const unassignedColumnStyles = {
    selected: ({ value, item }: any) => (
      <TPCheckBox
        checked={item.selected}
        onChange={(e: any) => {
          const updatedUsers = unassignedUsers.map((user) =>
            user.id === item.id
              ? { ...user, selected: e.target.checked }
              : user,
          );
          setUnassignedUsers(updatedUsers);
        }}
      />
    ),
    avatar: ({ value, item }: any) => (
      <TPAvatar
        src={item.avatar}
        size={30}
        circular={true}
        enableFullscreen={true}
      />
    ),
  };

  const getUsersByFilter = async function () {
    setContentLoaded(false);
    const userGroupServiceInstance = new UserGroupService();
    try {
      const response = await userGroupServiceInstance.getUsersByFilters(
        {
          groupId: groupId,
          id_FUNC:
            functionsFilter.replace("--", "").length > 0
              ? functionsFilter
              : null,
          id_PROF:
            profileFilter.replace("--", "").length > 0 ? profileFilter : null,
          name:
            nameFilter.replace("--", "").length > 0
              ? nameFilter.trim().toLocaleLowerCase()
              : null,
          superior_Id_USER:
            superiorFilter.replace("--", "").length > 0 ? superiorFilter : null,
          team_Id_BRAN:
            teamFilter.replace("--", "").length > 0 ? teamFilter : null,
        },
        false,
        false,
        [200],
      );

      if (response && response.length > 0) {
        const users = response.map((item) => ({
          login: item.user.login,
          id: item.user.id,
          name: item.user.name,
          selected: false,
          active: item.user.isActive,
          avatar: item.user.avatar,
        }));
        const usersWithAvatars = await loadUserAvatars(
          users as QueueAssignModel[],
        );
        setUnassignedUsers(usersWithAvatars);
      } else {
        setUnassignedUsers([]);
      }
    } catch (error) {
      console.error("Error fetching unassigned users:", error);
    } finally {
      setContentLoaded(true);
    }
  };

  const loadUserAvatars = async (users: QueueAssignModel[]) => {
    const validLogins = users
      .filter(({ login }) => login.includes("@"))
      .map(({ login }) => login);

    const avatarDimensions = { height: 648, width: 648 };
    const notificationSettings = {
      showPositiveMessage: false,
      showNegativeMessage: false,
      expectedCodes: [200],
    };
    const userService = new UserService();
    const batchSize = 10;

    const chunkArray = (array: any[], size: number) =>
      array.reduce((chunks, item, index) => {
        const chunkIndex = Math.floor(index / size);
        if (!chunks[chunkIndex]) chunks[chunkIndex] = [];
        chunks[chunkIndex].push(item);
        return chunks;
      }, []);

    const processBatch = async (batch: string[]) => {
      try {
        const response = await userService.postUserPhotos(
          avatarDimensions.height,
          avatarDimensions.width,
          batch,
          notificationSettings.showPositiveMessage,
          notificationSettings.showNegativeMessage,
          notificationSettings.expectedCodes,
        );

        return response;
      } catch (error) {
        console.error(`Failed to load avatars for batch:`, batch, error);
        return [];
      }
    };

    try {
      const loginBatches = chunkArray(validLogins, batchSize);

      const avatarResponses = (
        await Promise.all(loginBatches.map((batch: any) => processBatch(batch)))
      ).flat();

      const usersWithAvatars = users.map((user) => {
        const avatarData = avatarResponses.find(
          (response: any) => response.userLogin === user.login,
        );
        return avatarData ? { ...user, avatar: avatarData.image } : user;
      });
      return usersWithAvatars;
    } catch (error) {
      console.error("Failed to load user avatars:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (contentLoaded) return;

    const fetchAssignedUsers = async () => {
      const groupServiceInstance = new GroupsService();
      try {
        const response = await groupServiceInstance.getAssignedUsers(
          String(groupId),
          false,
          false,
          [200],
        );

        if (response && response.length > 0) {
          const users = response.map((item) => ({
            id: item.user.id,
            name: item.user.name,
            login: item.user.login,
            selected: true,
            active: item.user.isActive,
            avatar: item.user.avatar,
          }));

          const usersWithAvatars = await loadUserAvatars(
            users as QueueAssignModel[],
          );
          setAssignedUsers(usersWithAvatars);
        }
      } catch (error) {
        console.error("Error loading assigned users:", error);
      } finally {
        setContentLoaded(true);
      }
    };

    fetchAssignedUsers();
  }, [contentLoaded]);

  useEffect(() => {
    redrawTable();
  }, [
    assignedUsers,
    unassignedUsers,
    assignedUsersQuery,
    unassignedUsersQuery,
  ]);

  useEffect(() => {
    if (!dataSorted) {
      setDataSorted(true);
      setAssignedUsers([...assignedUsers.map((user) => user)]);
      setUnassignedUsers([...unassignedUsers.map((user) => user)]);
    }
  }, [dataSorted]);

  useEffect(() => {
    setFilteredUnassignedUsers([
      ...filteredUnassignedUsers.map((user) => {
        return {
          ...user,
          selected: allUnassignedSelected,
        } as QueueAssignModel;
      }),
    ]);
    setUnassignedUsers([
      ...unassignedUsers.map((user) => {
        return {
          ...user,
          selected: allUnassignedSelected,
        } as QueueAssignModel;
      }),
    ]);
  }, [allUnassignedSelected]);

  useEffect(() => {
    setCanAssign(
      filteredUnassignedUsers.length > 0 &&
        filteredUnassignedUsers.filter((user) => user.selected).length > 0,
    );
  }, [filteredUnassignedUsers]);

  return (
    <>
      {cancelWarningVisible && (
        <TPModal
          modalState={{
            id: "cancel-user-assign-modal",
            acceptLabel: labels.Yes,
            callBackAnswer: () => setCancelWarningVisible(false),
            cancelLabel: labels.No,
            isShown: true,
            titleModal: "",
            hideFooterButtons: true,
            modalWidth: "512px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              gap: "24px",
              padding: "24px",
            }}
          >
            <h5>
              <b>{labels.SystemAlert}</b>
            </h5>
            <label>{labels.CancelConfirm}</label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "50%",
                gap: "16px",
              }}
            >
              <TPButton
                isDesignSystem
                onClick={() => {
                  setCancelWarningVisible(false);
                }}
                style={{
                  backgroundColor: "white",
                  color: "purple",
                  paddingTop: "11px",
                  paddingBottom: "11px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {labels.No}
              </TPButton>
              <TPButton
                isDesignSystem
                onClick={() => {
                  tabCallback({
                    command: "delete",
                    recordId: String(groupId),
                  });
                  setCancelWarningVisible(false);
                }}
                style={{
                  paddingTop: "11px",
                  paddingBottom: "11px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {labels.Yes}
              </TPButton>
            </div>
          </div>
        </TPModal>
      )}
      {deselectedUsers.length > 0 && (
        <TPModal
          modalState={{
            id: "cancel-user-assign-modal",
            acceptLabel: labels.Yes,
            callBackAnswer: () => {
              setDeselectedUsers([]);
            },
            cancelLabel: labels.No,
            isShown: true,
            titleModal: "",
            hideFooterButtons: true,
            modalWidth: "512px",
          }}
        >
          <TPLoadingOverlay
            active={(!usersLoaded && !contentLoaded) || processingAssign}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-evenly",
                gap: "24px",
                padding: "24px",
              }}
            >
              <h5>
                <b>{labels.SystemAlert}</b>
              </h5>
              <label>
                {labels.SureRemove} {deselectedUsers.length} {labels.PeopleList}
                {/* {Boolean(deselectedUser?.email) && " / " + deselectedUser?.email}?*/}
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "50%",
                  gap: "16px",
                }}
              >
                <TPButton
                  isDesignSystem
                  onClick={() => {
                    setDeselectedUsers([]);
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "purple",
                    paddingTop: "11px",
                    paddingBottom: "11px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                >
                  {labels.No}
                </TPButton>
                <TPButton
                  isDesignSystem
                  onClick={() => {
                    deselectedUsers.forEach((user) =>
                      unassignUserById(user.id),
                    );
                    setDeselectedUsers([]);
                  }}
                  style={{
                    paddingTop: "11px",
                    paddingBottom: "11px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                >
                  {labels.Yes}
                </TPButton>
              </div>
            </div>
          </TPLoadingOverlay>
        </TPModal>
      )}
      {isAddUsersModalVisible && (
        <TPModal
          modalState={{
            id: "add-user-modal",
            isShown: true,
            callBackAnswer: (response: any) => {
              if (response) handleMassiveUpdate();
              setIsAddUsersModalVisible(false);
            },
            titleModal: "",
            hideFooterButtons: true,
            modalWidth: ModalSizeEnum.MODALXL,
          }}
        >
          <TPLoadingOverlay
            active={(!usersLoaded && !contentLoaded) || processingAssign}
          >
            <h5>
              <b>{labels.SearchNewUsers}</b>
            </h5>
            <div id="unassigned-users-table">
              {/* <div
              className="top-table-controls"
              style={{
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <div
                id="queue-unassigned-users-profile-filter"
                style={{ width: "100%" }}
              >
                <TPSelect
                  dataSource={profileFilterOptions}
                  onChange={(event: any) =>
                    setProfileFilter(event.target.value)
                  }
                  value={profileFilter}
                  labelText={labels.Profile}
                />
              </div>

              <div
                id="queue-unassigned-users-team-filter"
                style={{ width: "100%" }}
              >
                <TPSelect
                  dataSource={teamsFilterOptions}
                  onChange={(event: any) => setTeamFilter(event.target.value)}
                  value={teamFilter}
                  labelText={labels.Teams}
                />
              </div>

              <div
                id="queue-unassigned-users-functions-filter"
                style={{ width: "100%" }}
              >
                <TPSelect
                  dataSource={functionsFilterOptions}
                  onChange={(event: any) =>
                    setFunctionsFilter(event.target.value)
                  }
                  value={functionsFilter}
                  labelText={labels.Functions}
                />
              </div>

              <div
                id="queue-unassigned-users-schedule-filter"
                style={{ width: "100%" }}
              >
                <TPSelect
                  dataSource={superiorFilterOptions}
                  onChange={(event: any) =>
                    setSuperiorFilter(event.target.value)
                  }
                  value={superiorFilter}
                  labelText={labels.Superior}
                />
              </div>

              <div
                id="queue-unassigned-users-name-filter"
                style={{ width: "100%" }}
              >
                <TPTextBox
                  onChange={(event: any) => setNameFilter(event.target.value)}
                  value={nameFilter}
                  labelText={labels.Name}
                />
              </div>

              <TPButton
                type={TPButtonTypes.primary}
                onClick={() => getUsersByFilter()}
                isDesignSystem
                style={{
                  paddingTop: "11px",
                  paddingBottom: "11px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {labels.Apply}
              </TPButton>
            </div> */}
              <div className="container">
                <div className="row">
                  <div
                    className="col-2"
                    id="queue-unassigned-users-profile-filter"
                  >
                    <TPSelect
                      dataSource={profileFilterOptions}
                      onChange={(event: any) =>
                        setProfileFilter(event.target.value)
                      }
                      value={profileFilter}
                      labelText={labels.Profile}
                    />
                  </div>

                  <div
                    className="col-2"
                    id="queue-unassigned-users-team-filter"
                  >
                    <TPSelect
                      dataSource={teamsFilterOptions}
                      onChange={(event: any) =>
                        setTeamFilter(event.target.value)
                      }
                      value={teamFilter}
                      labelText={labels.Teams}
                    />
                  </div>
                  <div
                    className="col-2"
                    id="queue-unassigned-users-functions-filter"
                  >
                    <TPSelect
                      dataSource={functionsFilterOptions}
                      onChange={(event: any) =>
                        setFunctionsFilter(event.target.value)
                      }
                      value={functionsFilter}
                      labelText={labels.Functions}
                    />
                  </div>
                  <div
                    className="col-2"
                    id="queue-unassigned-users-schedule-filter"
                  >
                    <TPSelect
                      dataSource={superiorFilterOptions}
                      onChange={(event: any) =>
                        setSuperiorFilter(event.target.value)
                      }
                      value={superiorFilter}
                      labelText={labels.Superior}
                    />
                  </div>
                  <div
                    className="col-2"
                    id="queue-unassigned-users-name-filter"
                  >
                    <TPTextBox
                      onChange={(event: any) =>
                        setNameFilter(event.target.value)
                      }
                      value={nameFilter}
                      labelText={labels.Name}
                    />
                  </div>
                  <div className="col-2 mt-4">
                    <TPButton
                      type={TPButtonTypes.primary}
                      onClick={() => getUsersByFilter()}
                      isDesignSystem
                      style={{
                        paddingTop: "11px",
                        paddingBottom: "11px",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                      }}
                    >
                      {labels.Apply}
                    </TPButton>
                  </div>
                </div>
              </div>

              {unassignedUsers.length > 0 && (
                <div
                  className="top-table-controls"
                  style={{ justifyContent: "space-between" }}
                >
                  {filteredUnassignedUsers.length > 0 && (
                    <TPCheckBox
                      checked={allUnassignedSelected}
                      onChange={(event: any) =>
                        setAllUnassignedSelected(event.target.checked)
                      }
                      labelText={labels.SelectAll}
                    />
                  )}
                  <TPTextBox
                    onChange={(event: any) =>
                      setUnassignedUsersQuery(event.target.value)
                    }
                    value={unassignedUsersQuery}
                    icon={TPIconTypes.search}
                    withIcon
                    iconClick={() => {}}
                    containerStyle={{ width: "25%" }}
                    placeholder={labels.Search}
                  />
                </div>
              )}
              <DynamicTable
                data={filteredUnassignedUsers.map((user) => ({
                  id: user.id,
                  selected: user.selected,
                  avatar: user.avatar,
                  name: user.name,
                  login: user.login,
                  active: user.active,
                }))}
                columnNames={columnNames}
                columnStyles={unassignedColumnStyles}
                hideExport
                hideRefresh
                hiddenSearch
                hiddenColumns={["id", "active"]}
              />
            </div>
            <div className="option-button-pair">
              <TPButton
                onClick={() => setCancelWarningVisible(true)}
                isDesignSystem
                style={{
                  backgroundColor: "white",
                  color: "purple",
                  paddingTop: "11px",
                  paddingBottom: "11px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {labels.Cancel}
              </TPButton>
              <TPButton
                onClick={() => handleMassiveUpdate()}
                type={TPButtonTypes.primary}
                disabled={
                  filteredUnassignedUsers.length == 0 ||
                  filteredUnassignedUsers.filter((user) => user.selected)
                    .length == 0 ||
                  !contentLoaded ||
                  !canAssign
                }
                isDesignSystem
                style={{
                  paddingTop: "11px",
                  paddingBottom: "11px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {labels.AssignUsers}
              </TPButton>
            </div>
          </TPLoadingOverlay>
        </TPModal>
      )}

      {active && (
        <TPLoadingOverlay
          active={(!usersLoaded && !contentLoaded) || processingAssign}
        >
          <div
            id="queue-general-info-container"
            className="queue-admin-tab"
            style={{ width: "100%", height: "min-content" }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "8px 0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <label style={{ fontSize: "20px", fontWeight: "700" }}>
                    {labels.UsersAssigned}
                  </label>
                  <TPChip
                    label={`(${assignedUsers.length})`}
                    style={{ fontSize: "20px", fontWeight: "700" }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    justifyContent: "flex-end",
                    paddingRight: "8px",
                  }}
                >
                  <TPTextBox
                    onChange={(event: any) =>
                      setAssignedUsersQuery(event.target.value)
                    }
                    value={assignedUsersQuery}
                    icon={TPIconTypes.search}
                    withIcon
                    iconClick={() => {}}
                    placeholder={labels.Search}
                    containerStyle={{ width: "50%" }}
                  />
                  <TPButton
                    isDesignSystem
                    onClick={() => setIsAddUsersModalVisible(true)}
                    style={{
                      backgroundColor: "white",
                      color: allThemes.base.purplePrimary,
                      padding: "11px 16px",
                    }}
                  >
                    {labels.AddUsers}
                  </TPButton>
                  <TPButton
                    isDesignSystem
                    onClick={() => {
                      const deselected = assignedUsers.filter(
                        (user) => !user.selected,
                      );
                      if (deselected.length > 0) {
                        setDeselectedUsers(deselected);
                      }
                    }}
                    style={{
                      padding: "11px 16px",
                    }}
                  >
                    {labels.Update}
                  </TPButton>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  backgroundColor: "#D3E1FC",
                  alignItems: "center",
                }}
              >
                <TPIcon
                  iconType={TPIconTypes.alert}
                  style={{
                    fontSize: "24px",
                    color: "#3047B0",
                    paddingLeft: "8px",
                  }}
                />
                <TPLabel
                  labelText={labels.Remember}
                  style={{
                    color: "#002C81",
                    fontSize: "14px",
                    fontWeight: "400",
                    height: "53px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "5px",
                    padding: "16px",
                    margin: "8px 0",
                  }}
                />
              </div>

              <div>
                <DynamicTable
                  data={filteredAssignedUsers.map((user) => ({
                    id: user.id,
                    selected: user.selected,
                    avatar: user.avatar,
                    name: user.name,
                    login: user.login,
                  }))}
                  columnNames={columnNames}
                  columnStyles={columnStyles}
                  hideExport
                  hideRefresh
                  hiddenSearch
                  hiddenColumns={["id"]}
                />
              </div>
            </div>
          </div>
        </TPLoadingOverlay>
      )}
    </>
  );
};

export default QueueAssignUsersTab;
