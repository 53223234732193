import { useSelector } from "react-redux";
import { MergeCustomersState } from "../redux/MergeCustomersStore";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { useState } from "react";
import ConflictResolveModal from "./ConflictResolveModal";
import { useDispatch } from "react-redux";
import {
  resetCustomerMerge,
  setAltValueInput,
  setChosenAttribute,
  setConcatenateValueInput,
  setConflicts,
  setCustomValueInput,
  setMainValueInput,
  setMergeWarningVisible,
} from "../redux/MergeCustomersSlice";
import MergeConfirmationModal from "./MergeConfirmationModal";
import { MergeService } from "@/services/MergeService";
import useLabels from "../styles/labels";

enum ConflictTypes {
  new = "new",
  conflict = "conflict",
}

enum Colors {
  "main" = "#EBEFFF",
  "mainBorder" = "#1586ED",
  "merge" = "#F9E2FF",
  "mergeBorder" = "#C30091",
  "conflict" = "#FFFAD1",
  "conflictBorder" = "#5B4900",
  "new" = "#DCFFDD",
  "newBorder" = "#009A58",
}

const MergeCustomersConflictResolution = function () {
  const dispatch = useDispatch();
  const { labels } = useLabels();
  const {
    currentView,
    mainCustomerData,
    mergeCustomerData,
    chosenAttribute,
    interestValues,
    mergeWarningVisible,
  } = useSelector((state: MergeCustomersState) => state.mainData);
  const [modalShown, setModalShown] = useState<boolean>(false);
  return (
    <div id="merge-customers-conflict-resolve-container">
      {currentView == "conflict" && (
        <div id="merge-customers-conflict-resolve">
          <div className="merge-customers-data-column">
            {Object.entries(mainCustomerData).map(([key, value], index) => {
              if (!interestValues.includes(key)) return;
              const mainVal = String(
                Object.entries(mainCustomerData)[index][1] || "",
              );
              const altVal = String(
                Object.entries(mergeCustomerData)[index][1] || "",
              );
              const newValue =
                mainVal.trim().length != 0 && altVal.trim().length == 0;
              const conflict =
                mainVal != altVal &&
                mainVal.trim().length > 0 &&
                altVal.trim().length > 0;
              if (conflict) dispatch(setConflicts(true));
              return (
                <div className="merge-customers-text-box">
                  <b>{labels[key]}</b>
                  <label style={{ color: "red" }}>*</label>
                  <TPTextBox
                    value={value || ""}
                    onChange={() => null}
                    withIcon={conflict}
                    icon={TPIconTypes.eye}
                    disabled
                    iconClick={() => {
                      dispatch(setChosenAttribute(key));
                      dispatch(setMainValueInput(mainVal));
                      dispatch(setAltValueInput(altVal));
                      dispatch(setCustomValueInput(mainVal + ", " + altVal));
                      dispatch(setConcatenateValueInput(mainVal + altVal));
                      setModalShown(true);
                    }}
                    textStyle={{
                      backgroundColor: newValue
                        ? Colors.new
                        : conflict
                          ? Colors.conflict
                          : Colors.main,
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="merge-customers-data-column">
            {Object.entries(mergeCustomerData).map(([key, value], index) => {
              if (!interestValues.includes(key)) return;
              const mainVal = String(
                Object.entries(mainCustomerData)[index][1] || "",
              );
              const altVal = String(
                Object.entries(mergeCustomerData)[index][1] || "",
              );
              const newValue =
                altVal.trim().length != 0 && mainVal.trim().length == 0;
              const conflict =
                mainVal != altVal &&
                mainVal.trim().length > 0 &&
                altVal.trim().length > 0;
              if (conflict) dispatch(setConflicts(true));
              return (
                <div className="merge-customers-text-box">
                  <b>{labels[key]}</b>
                  <label style={{ color: "red" }}>*</label>
                  <TPTextBox
                    value={value || ""}
                    onChange={() => null}
                    disabled
                    textStyle={{
                      backgroundColor: newValue
                        ? Colors.new
                        : conflict
                          ? Colors.conflict
                          : Colors.merge,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div id="conflict-colors-container">
        <div className="conflict-color-help">
          <TPIcon
            iconType={TPIconTypes.circle}
            style={{
              color: Colors.main,
              border: `1px solid ${Colors.mainBorder}`,
              borderRadius: "50%",
            }}
          />
          {labels.MainCustomerRecord}
        </div>
        <div className="conflict-color-help">
          <TPIcon
            iconType={TPIconTypes.circle}
            style={{
              color: Colors.merge,
              border: `1px solid ${Colors.mergeBorder}`,
              borderRadius: "50%",
            }}
          />
          {labels.MergeCustomerRecord}
        </div>
        <div className="conflict-color-help">
          <TPIcon
            iconType={TPIconTypes.circle}
            style={{
              color: Colors.conflict,
              border: `1px solid ${Colors.conflictBorder}`,
              borderRadius: "50%",
            }}
          />
          {labels.Conflict}
        </div>
        <div className="conflict-color-help">
          <TPIcon
            iconType={TPIconTypes.circle}
            style={{
              color: Colors.new,
              border: `1px solid ${Colors.newBorder}`,
              borderRadius: "50%",
            }}
          />
          {labels.NewValue}
        </div>
      </div>
      {chosenAttribute.length > 0 && (
        <ConflictResolveModal
          shown={modalShown}
          showCallback={() => setModalShown(false)}
          attribute={chosenAttribute}
        />
      )}
      {mergeWarningVisible && (
        <MergeConfirmationModal
          onAction={(confirm: boolean) => {
            dispatch(setMergeWarningVisible(false));
            if (confirm) {
              MergeService.mergeCustomers(
                {
                  id_CLIE_Base: mainCustomerData.id,
                  id_CLIE_Merge: mergeCustomerData.id,
                  id_SUBS_Base: mainCustomerData.subsidiaryId,
                  id_SUBS_Merge: mergeCustomerData.subsidiaryId,
                  id_SUOR_Base: mainCustomerData.subsidiaryOrganizationId,
                  id_SUOR_Merge: mergeCustomerData.subsidiaryOrganizationId,
                  name_CLIE: mainCustomerData.name,
                  lastName_CLIE: mainCustomerData.lastName,
                  additionalInfo_CLIE: mainCustomerData.additionalInfo,
                  isActive_CLIE: mainCustomerData.isActive,
                  typistGuid_USER: mainCustomerData.typist_Guid,
                  internalCode_SUBS: mainCustomerData.internalCode,
                  subsidiaryName_SUBS: mainCustomerData.subsidiaryName,
                  preferredPhone_SUBS: mainCustomerData.preferredPhone,
                  preferredAddress_SUBS: mainCustomerData.preferredAddress,
                  preferredAddressInfo_SUBS:
                    mainCustomerData.preferredAddressInfo,
                  preferredEmail_SUBS: mainCustomerData.preferredEmail,
                  geography_SUBS: mainCustomerData.geography,
                },
                true,
                true,
                [200],
              )
                .then(() => {
                  setConflicts(true);
                  dispatch(resetCustomerMerge());
                })
                .catch((error) => console.error(error));
            }
          }}
          shown={true}
        />
      )}
    </div>
  );
};

export default MergeCustomersConflictResolution;
