import TPGlobal from "@/helpers/TPGlobal";
import {
  RightVerticalMenuContainer,
  RightVerticalMenuLi,
  RightVerticalMenuUl,
  RightVerticalMenuWrapper,
} from "@/layouts/Main/mainMenuStyles";
import { TPI18N } from "@/services/I18nService";
import React, {
  FC,
  ReactElement,
  useEffect,
  useReducer,
  useState,
} from "react";
import OutboundMailAccountInsertUpdate from "./OutboundMailAccountInsertUpdate";
import OutboundMailAccountUnsentMailAdmin from "./OutboundMailAccountUnsentMailAdmin";
import TPHorizontalTabs from "@/components/TPTabs/TPHorizontalTabs";

type OutboundMailAccountContainerInsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
  tabId: string;
};

enum asideMenuItemTypes {
  "general" = 0,
  "unsentOutboundMails" = 1,
}

interface asideMenuItemsModel {
  itemLabel: string;
  itemType: asideMenuItemTypes;
  isActive: boolean;
  isEnabled: boolean;
  isVisible: boolean;
  onClickHandler: Function;
}

enum commandsEnum {
  "create_general" = 0,
  "create_unsent_outbound_mails_and_change_mode" = 1,
  "set_active_general" = 2,
  "set_active_unsent_outbound_mails" = 3,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const OutboundMailAccountContainerInsertUpdate: FC<
  OutboundMailAccountContainerInsertUpdateProps
> = ({ mode, recordId, callBackResult, tabId }): ReactElement => {
  //control state
  const ResourceSet: string = "OutboundMailAccountContainerInsertUpdate";
  const [isReady, setIsReady] = useState(false);
  const [titleGeneralInformation, setTitleGeneralInformation] = useState("");
  const [titleUnsentOutboundMails, setTitleUnsentOutboundMails] = useState("");
  const [exitLabel, setExitLabel] = useState("Exit");
  const [realRecordId, setRealRecordId] = useState(recordId);
  const [tab, setTab] = useState(0);

  const loadResources = async () => {
    //todo recursos del asidemenu

    setTitleGeneralInformation(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "GeneralInformationTitle"
      )
    );
    setTitleUnsentOutboundMails(
      await TPI18N.GetText(ResourceSet, "UnsentOutboundMails")
    );
    setExitLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ExitLabel"));
    //flag isReady
    setIsReady(true);
  };

  //handler to receive commands from child tabs
  const handleCallBackCommands = (theCommand: any) => {
    if (theCommand.result === "save_general_insert_and_change_mode") {
      //create details content
      let command: commandType = {
        type: commandsEnum.create_unsent_outbound_mails_and_change_mode,
        payload: { recordId: theCommand.recordId },
      };
      dispatchCommand(command);
      callBackResult({
        result: "ChangeToUpdateMode",
        recordId: theCommand.recordId,
        tabId: tabId,
      });
      return;
    }
    if (theCommand.result === "OK") {
      callBackResult({ result: "OK", recordId: recordId });
      return;
    }
    if (theCommand.result === "cancel_general") {
      callBackResult({ result: "CANCEL", recordId: recordId });
      return;
    }
  };

  const onGeneralAsideItemClick = () => {
    let currentActive: asideMenuItemTypes;
    if (asideMenuState.filter((x) => x.isActive === true).length >= 1) {
      currentActive = asideMenuState.filter((x) => x.isActive === true)[0]
        .itemType;
      if (currentActive === asideMenuItemTypes.general) {
        //already on general
        return;
      }
    }
    let command: commandType = {
      type: commandsEnum.set_active_general,
      payload: null,
    };
    dispatchCommand(command);
    return;
  };

  const onUnsentOutboundMailAsideItemClick = () => {
    let command: commandType = {
      type: commandsEnum.set_active_unsent_outbound_mails,
      payload: null,
    };
    dispatchCommand(command);
  };

  const handleOnExitClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  //create home content
  const realCreateGeneralContentCommand = (
    prevState: Array<asideMenuItemsModel>
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let verticalElement: asideMenuItemsModel = {
      itemLabel: titleGeneralInformation,
      itemType: asideMenuItemTypes.general,
      isActive: true,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onGeneralAsideItemClick,
    };
    newStateHome.push(verticalElement);
    if (mode == "Update") {
      let verticalElement2: asideMenuItemsModel = {
        itemLabel: titleUnsentOutboundMails,
        itemType: asideMenuItemTypes.unsentOutboundMails,
        isActive: false,
        isVisible: true,
        isEnabled: true,
        onClickHandler: onUnsentOutboundMailAsideItemClick,
      };
      newStateHome.push(verticalElement2);
    }
    return newStateHome;
  };

  //create details content
  const realCreateDetailsContentCommandAndChangeMode = (
    prevState: Array<asideMenuItemsModel>
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
    }

    let verticalElement: asideMenuItemsModel = {
      itemLabel: titleUnsentOutboundMails,
      itemType: asideMenuItemTypes.unsentOutboundMails,
      isActive: true,
      isVisible: true,
      isEnabled: true,
      onClickHandler: onUnsentOutboundMailAsideItemClick,
    };
    newStateHome.push(verticalElement);

    return newStateHome;
  };

  //set active item
  const setActiveContent = (
    prevState: Array<asideMenuItemsModel>,
    itemType: asideMenuItemTypes
  ) => {
    let newStateHome: Array<asideMenuItemsModel> = [...prevState];
    let i: number;
    for (i = 0; i <= newStateHome.length - 1; i++) {
      newStateHome[i].isActive = false;
      if (itemType == newStateHome[i].itemType) {
        newStateHome[i].isActive = true;
      }
    }
    return newStateHome;
  };

  //aside menu state with reducer
  let initialState: Array<asideMenuItemsModel> = [];
  const [asideMenuState, dispatchCommand] = useReducer(doCommand, initialState);

  function doCommand(
    prevState: Array<asideMenuItemsModel>,
    command: commandType
  ) {
    switch (command.type) {
      case commandsEnum.create_general:
        return realCreateGeneralContentCommand(prevState);
      case commandsEnum.create_unsent_outbound_mails_and_change_mode:
        let newRecordId: string;
        newRecordId = command.payload.recordId;
        setRealRecordId(newRecordId);
        return realCreateDetailsContentCommandAndChangeMode(prevState);
      case commandsEnum.set_active_general:
        return setActiveContent(prevState, asideMenuItemTypes.general);
      case commandsEnum.set_active_unsent_outbound_mails:
        return setActiveContent(
          prevState,
          asideMenuItemTypes.unsentOutboundMails
        );
    }
  }

  //run once to include functionsAdmin in first tab
  useEffect(() => {
    //load resources
    if (!isReady) {
      loadResources();
    } else {
      let command: commandType = {
        type: commandsEnum.create_general,
        payload: null,
      };
      dispatchCommand(command);
    }
  }, [isReady]);

  const renderTab = (
    <TPHorizontalTabs
      activeTab={tab}
      labels={asideMenuState.map((item) => item.itemLabel)}
      onChange={(_, t) => {
        setTab(t);
        asideMenuState[t].onClickHandler();
      }}
    />
  );

  function getRender(tab: asideMenuItemsModel) {
    switch (tab.itemType) {
      case asideMenuItemTypes.general:
        return (
          <div style={{ display: tab.isActive ? "block" : "none" }}>
            <OutboundMailAccountInsertUpdate
              callBackResult={handleCallBackCommands}
              mode={mode}
              recordId={realRecordId}
              renderTab={renderTab}
            />
          </div>
        );
      case asideMenuItemTypes.unsentOutboundMails:
        return (
          <div style={{ display: tab.isActive ? "block" : "none" }}>
            <OutboundMailAccountUnsentMailAdmin
              idAccount={realRecordId}
              renderTab={renderTab}
            />
          </div>
        );
      default:
        return (
          <li>
            <span>error this should not be here</span>
          </li>
        );
    }
  }

  return (
    <div className="d-flex flex-column p-3" style={{ gap: "24px" }}>
      {asideMenuState.map((tab) => getRender(tab))}
    </div>
  );
};

export default OutboundMailAccountContainerInsertUpdate;
