import styled from "styled-components";

interface TPSelectStyledProps {
  minWidth: number;
  maxWidth?: number;
  isInvalid?: boolean;
  withIcon?: boolean;
}

export const TPSelectInputStyled = styled.select<TPSelectStyledProps>(
  ({ theme, minWidth, maxWidth, isInvalid, withIcon }) => `
    width: 100%;
    min-width: ${minWidth}px;
    ${maxWidth ? `max-width: ${maxWidth}px;` : ""}
    padding: 0 2.5em 0 0.6em;
    border: 1px solid ${isInvalid ? theme.colors.errorColor : "#ced4da"};
    border-radius: 4px;
    height: 35px;
    font-size: 14px;

    background: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1l4 4 4-4' stroke='%23495057' stroke-width='1.5' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat right 0.75em center;
    background-size: 10px 6px;

    ${withIcon ? "border-top-right-radius: 0px; border-bottom-right-radius: 0px;" : ""}

    &:focus, &.focus {
      box-shadow: none;
      border-color: ${isInvalid ? theme.colors.errorColor : theme.colors.primary} !important;
      outline: 1px solid ${isInvalid ? theme.colors.errorColor : theme.colors.primary} !important;
    }

    &.is-invalid {
      border-color: ${theme.colors.errorColor} !important;
      outline: 1px solid ${theme.colors.errorColor} !important;
    }

    &::placeholder {
      color: #6c757d;
      opacity: 1;
    }
  `
);

export default TPSelectInputStyled;
