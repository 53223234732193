import NoDataImg from "@/assets/images/no-data.png";
import NoFilteredDataImg from "@/assets/images/TPImages/tp-image-grid.png";
import successImg from "@/assets/images/success.png";



import { ReactElement, useState } from "react";
import { ViewImageStyle } from "./StyleI";
import { ImageActive } from "../../models/ViewImage/ViewImage";


type Props = {
    image: ImageActive;
    heightImage?: string;
    widthImage?: string;
    testPreview ?: boolean;
};

function ViewImage({
    image,
    testPreview,
    heightImage ="100%",
    widthImage = "100%"
}: Props): ReactElement {
    const [selectedImage, setSelectedImage] = useState(image);
   

   const handleImgActive = () => {
       let imageActive = NoDataImg;
       if (image == ImageActive.noDataImg) imageActive = NoDataImg;
       if (image ==  ImageActive.noFilteredDataImg) imageActive = NoFilteredDataImg;
       if (image ==  ImageActive.successImg) imageActive = successImg;
       return imageActive;
    }


    return (
        <ViewImageStyle
            height={heightImage}
            width={widthImage}
        >
            <img src={handleImgActive()} alt={"no-data"} height={heightImage} width={widthImage} />
        </ViewImageStyle>
    );
}

export default ViewImage;
