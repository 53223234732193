import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import TPBranchSelection2, {
  TPBranchSelectionValue,
} from "@/components/TPBranchSelection/TPBranchSelection2";
import TPDatePicker from "@/components/TPDatePicker/TPDatePicker";
import {
  TPColumn,
  TPPageSection,
  TPPageSectionTitle,
  TPPageTitle,
  TPRow,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { ComplementsRenderTP, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  AAdditionalDataInputDTOValidator,
  AdditionalDataInputDTO,
} from "@/models/AdditionalData/AdditionalDataInputDTO";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import {
  AdditionalDataCategoryEnum,
  AdditionalDataDateTypeEnum,
  AdditionalDataOpenTextTypesEnum,
  AdditionalDataTypeEnum,
  SequenceGeneratorSequencesNameEnum,
  TPActiveOptions,
  TPButtonTypes,
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import { AdditionalDataService } from "@/services/AdditionalDataService";
import { AdditionalDataTypeService } from "@/services/AdditionalDataTypeService";
import { BranchService } from "@/services/BranchService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { ParametersService } from "@/services/ParametersService";
import { SequenceService } from "@/services/SequenceService";
import { TreeService } from "@/services/TreeService";
import moment from "moment";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import { showToast, TPToastTypes } from "@/components/bootstrap/components/toasts/TPToast";
import TPModalQuestion from "@/layouts/ModalQuestion/TPModalQuestion";
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";
import { MultilingualTextBoxEvents } from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";
import TPAdditionalDataPreview from "../../components/TPAdditionalData/TPAdditionalDataPreview";

interface CallBackResult2Props {
  success: boolean;
  data: any;
}

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  labelsButton?: { backButton: string; createButton: string };
  callBackResult2?: (result: CallBackResult2Props) => void;
};

type InsertUpdateStateType = {
  idAdditionalData: string;
  recordLanguageList: Array<TPKeyValue>;
  idAdditionalDataCategory: string;
  idAdditionalDataType: string;
  enableEditingModifyBasicData: boolean;
  comments: string;
  description: string;
  jsonParameters: string;
  validationUrl: string;
  idErrorMessage: string;
  descriptionsErrorMessages: Array<string>;
  idAdditionalDataCategoryErrorMessage: string;
  idAdditionalDataTypeErrorMessage: string;
  enableEditingModifyBasicDataErrorMessage: string;
  commentsErrorMessage: string;
  validationUrlErrorMessage: string;
};

interface DateProps {
  type: string;
  minDateType: string;
  formatDate: string;
  maxDateType: string;
  minDateSymbol: string;
  maxDateSymbol: string;
  maxDateDays: number;
  minDateDays: number;
  minDate?: Date;
  maxDate?: Date;
  minDateErrorMessage: string;
  maxDateErrorMessage: string;
}

interface IntegerListProps {
  initialValue: number;
  endValue: number;
  initialValueErrorMessage: string;
}

interface LinkProps {
  url: string;
  urlErrorMessage: string;
}

interface PhoneProps {
  validationType: string;
  minimumCharacters: number;
  maximumCharacters: number;
  charactersWhiteList: string;
  regularExpresion: string;
  minimumCharactersErrorMessage: string;
  charactersWhiteListErrorMessage: string;
  regularExpresionErrorMessage: string;
}

interface NumericProps {
  minValueValidationType: string;
  maxValueValidationType: string;
  minValue: number;
  maxValue: number;
  percentage: boolean;
  fixedDecimal: string;
  currencySymbol: string;
  currencyPlacement: string;
  decimalSeparator: string;
  thousandsSeparator: string;
  negativeNumber: string;
  currencySymbolDisabled: boolean;
  currencyplacementDisabled: boolean;
  minValueTypeErrorMessage: string;
  maxValueTypeErrorMessage: string;
  minValueErrorMessage: string;
  maxValueErrorMessage: string;
  currencyPlacementErrorMessage: string;
  thousandsSeparatorErrorMessage: string;
}

interface OpenTextProps {
  type: string;
  maxLength: number;
  whiteList: string;
  inputMask: string;
  placeHolder: string;
  targetTable: string;
  inputMaskErrorMessage: string;
  whiteListErrorMessage: string;
  maxLengthErrorMessage?: string;
}

interface OpenTextMultilineProps {
  type: string;
  maxLength: number;
  whiteList: string;
  whiteListErrorMessage: string;
  maxLengthErrorMessage?: string;
}

interface YesNoProps {
  renderMethod: string;
  caption: string;
  renderMethodErrorMessage: string;
  captionErrorMessage: string;
}

interface ListValueProps {
  treeId: string;
  renderMethod: string;
  treeIdErrorMessage: string;
  branchSelectedErrorMessage: string;
  renderMethodErrorMessage: string;
}

interface TreeProps {
  treeId: string;
  treeIdErrorMessage: string;
  parentIdErrorMessage: string;
}

const AdditionalDataInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
  style,
  callBackResult2,
  buttonStyle,
  labelsButton,
}): ReactElement => {
  const componentFileName: string = "AdditionalDataInsertUpdate.tsx";

  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  const resourceSet: string = "AdditionalDataInsertUpdateComponent";
  const [insertUpdateStatePreview, setInsertUpdateStatePreview] = useState<any>();
  const [complementsRenderTP, setComplementsRenderTP] = useState<ComplementsRenderTP>(
    new ComplementsRenderTP()
  );

  const [formatDateLabel, setFormatDateLabel] = useState("");
  const [titleLabel, setTitleLabel] = useState("");
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [configurationSectionLabel, setConfigurationSectionLabel] = useState("");
  const [idLabel, setIdLabel] = useState("");
  const [additionalDataCategoryLabel, setAdditionalDataCategoryLabel] = useState("");
  const [additionalDataTypeLabel, setAdditionalDataTypeLabel] = useState("");
  const [additionalDataCommentsLabel, setAdditionalDataCommentsLabel] = useState("");
  const [enableEditingModifyBasicDataLabel, setEnableEditingModifyBasicDataLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [
    additionalDataCategoryList,
    setAdditionalDataCategoryList
  ] = useState<Array<TPKeyValue>>([]);
  const [dataAddaTypeListState, setDataAddaTypeListState] = useState<Array<TPKeyValue>>([]);
  const [targetTableLabel, setTargetTableLabel] = useState("");
  const [previewLabel, setPreviewLabel] = useState("");
  const [targetTableListState, setTargetTableListState] = useState<Array<TPKeyValue>>([]);
  const [showAsActiveLabel, setShowAsActiveLabel] = useState("");
  const [targetTableSelectedValue, setTargetTableSelectedValue] = useState("");
  const [targetTableErrorMessage, setTargetTableErrorMessage] = useState("");
  const [modalAcceptLabel, setModalAcceptLabel] = useState("");
  const [modalCancelLabel, setModalCancelLabel] = useState("");
  const [modalSelectedBranchLabel, setModalSelectedBranchLabel] = useState("");
  const [modalTitleLabel, setModalTitleLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");
  const [dateTypeLabel, setDateTypeLabel] = useState("");
  const [minDateLabel, setMinDateLabel] = useState("");
  const [minimumDateValueLabel, setMinimumDateValueLabel] = useState("");
  const [maxDateLabel, setMaxDateLabel] = useState("");
  const [maximumDateValueLabel, setMaximumDateValueLabel] = useState("");
  const [fixedValueLabel, setFixedValueLabel] = useState("");
  const [variableValueLabel, setVariableValueLabel] = useState("");
  const [todayDateLabel, setTodayDateLabel] = useState("");
  const [daysDateLabel, setDaysDateLabel] = useState("");
  const [anyDateItemLabel, setAnyDateItemLabel] = useState("");
  const [dateRangeItemLabel, setDateRangeItemLabel] = useState("");
  const [initialValueLabel, setInitialValueLabel] = useState("");
  const [endValueLabel, setEndValueLabel] = useState("");
  const [urlLabel, setUrlLabel] = useState("");
  const [validUrlErrorMessage, setValidUrlErrorMessage] = useState("");
  const [minimumValueLabel, setMinimumValueLabel] = useState("");
  const [maximumValueLabel, setMaximumValueLabel] = useState("");
  const [minimumValueTypeLabel, setMinimumValueTypeLabel] = useState("");
  const [maximumValueTypeLabel, setMaximumValueTypeLabel] = useState("");
  const [naLabel, setNaLabel] = useState("");
  const [valueLabel, setValueLabel] = useState("");
  const [percentageLabel, setPercentageLabel] = useState("");
  const [fixedDecimalPlacesLabel, setFixedDecimalPlacesLabel] = useState("");
  const [currencySymbolLabel, setCurrencySymbolLabel] = useState("");
  const [currencyPlacementLabel, setCurrencyPlacementLabel] = useState("");
  const [decimalSeparatorLabel, setDecimalSeparatorLabel] = useState("");
  const [thousandsSeparatorLabel, setThousandsSeparatorLabel] = useState("");
  const [noneLabel, setNoneLabel] = useState("");
  const [beforeLabel, setBeforeLabel] = useState("");
  const [afterLabel, setAfterLabel] = useState("");
  const [spaceLabel, setSpaceLabel] = useState("");
  const [textboxTypeLabel, setTextboxTypeLabel] = useState("");
  const [openLabel, setOpenLabel] = useState("");
  const [whiteListLabel, setWhiteListLabel] = useState("");
  const [inputMaskLabel, setInputMaskLabel] = useState("");
  const [maximumCharactersLabel, setMaximumCharactersLabel] = useState("");
  const [charactersWhiteListLabel, setCharactersWhiteListLabel] = useState("");
  const [placeholderLabel, setPlaceholderLabel] = useState("");
  const [validationTypeLabel, setValidationTypeLabel] = useState("");
  const [minimumCharactersLabel, setMinimumCharactersLabel] = useState("");
  const [basicLabel, setBasicLabel] = useState("");
  const [advanceLabel, setAdvanceLabel] = useState("");
  const [regularExpressionLabel, setRegularExpressionLabel] = useState("");
  const [treeLabel, setTreeLabel] = useState("");
  const [parentIdLabel, setParentIdLabel] = useState("");
  const [controlLabel, setControlLabel] = useState("");
  const [comboboxLabel, setComboboxLabel] = useState("");
  const [optionLabel, setOptionLabel] = useState("");
  const [checkboxLabel, setCheckboxLabel] = useState("");
  const [isYesNoError, setIsYesNoError] = useState(false);
  const [showAsActive, setShowAsActive] = useState(true);
  const [captionLabel, setCaptionLabel] = useState("");
  const [yesNoLabel, setYesNoLabel] = useState("");
  const [trueFalseLabel, setTrueFalseLabel] = useState("");
  const [referenceId, setReferenceId] = useState("");
  const [enterAValueLabel, setEnterAValueLabel] = useState("");
  const [selectAValueLabel, setSelectAValueLabel] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const nameInputRef = useRef<MultilingualTextBoxEvents>();

  let insertUpdateInitialState: InsertUpdateStateType = {
    idAdditionalData: recordId,
    recordLanguageList: [],
    idErrorMessage: "",
    descriptionsErrorMessages: [],
    idAdditionalDataCategory: "",
    idAdditionalDataType: "",
    enableEditingModifyBasicData: false,
    comments: "",
    description: "",
    jsonParameters: "",
    idAdditionalDataCategoryErrorMessage: "",
    idAdditionalDataTypeErrorMessage: "",
    enableEditingModifyBasicDataErrorMessage: "",
    commentsErrorMessage: "",
    validationUrl: "",
    validationUrlErrorMessage: "",
  };
  const [insertUpdateState, setInsertUpdateState] = useState(insertUpdateInitialState);
  let datePropsInitialState: DateProps = {
    minDateType: "fixed",
    maxDateType: "fixed",
    minDateSymbol: "+",
    maxDateSymbol: "+",
    maxDateDays: 0,
    minDateDays: 0,
    minDate: undefined,
    maxDate: undefined,
    minDateErrorMessage: "",
    maxDateErrorMessage: "",
    type: "AnyDate",
    formatDate: "0",
  };
  const [datePropsState, setDatePropsState] = useState<DateProps>(datePropsInitialState);

  let integerListPropsInitialState: IntegerListProps = {
    initialValue: 0,
    endValue: 1,
    initialValueErrorMessage: "",
  };
  const [integerListPropsState, setIntegerListPropsState] = useState<IntegerListProps>(
    integerListPropsInitialState
  );

  let linkPropsInitialState: LinkProps = {
    url: "",
    urlErrorMessage: "",
  };
  const [linkPropsState, setLinkPropsState] = useState<LinkProps>(linkPropsInitialState);

  let phonePropsInitialState: PhoneProps = {
    validationType: "BASIC",
    minimumCharacters: 1,
    maximumCharacters: 1,
    charactersWhiteList: "0123456789()+- ,;#p",
    regularExpresion: "",
    minimumCharactersErrorMessage: "",
    charactersWhiteListErrorMessage: "",
    regularExpresionErrorMessage: "",
  };
  const [phonePropsState, setPhonePropsState] = useState<PhoneProps>(phonePropsInitialState);

  let numericPropsInitialState: NumericProps = {
    minValue: 0,
    maxValue: 1,
    percentage: false,
    fixedDecimal: "None",
    currencySymbol: "",
    currencyPlacement: "",
    decimalSeparator: "",
    thousandsSeparator: "",
    negativeNumber: "",
    minValueValidationType: "",
    maxValueValidationType: "",
    currencySymbolDisabled: false,
    currencyplacementDisabled: false,
    currencyPlacementErrorMessage: "",
    minValueErrorMessage: "",
    minValueTypeErrorMessage: "",
    maxValueTypeErrorMessage: "",
    maxValueErrorMessage: "",
    thousandsSeparatorErrorMessage: "",
  };
  const [numericPropsState, setNumericPropsState] =
    useState<NumericProps>(numericPropsInitialState);

  let openTextPropsInitialState: OpenTextProps = {
    type: "Open",
    maxLength: 1,
    whiteList: "",
    inputMask: "",
    placeHolder: "",
    targetTable: "",
    inputMaskErrorMessage: "",
    whiteListErrorMessage: "",
  };
  const [openTextPropsState, setOpenTextPropsState] =
    useState<OpenTextProps>(openTextPropsInitialState);

  let openTextMultilinePropsInitialState: OpenTextMultilineProps = {
    type: "Open",
    maxLength: 1,
    whiteList: "",
    whiteListErrorMessage: "",
  };
  const [openTextMultilinePropsState, setOpenTextMultilinePropsState] =
    useState<OpenTextMultilineProps>(openTextMultilinePropsInitialState);
  let yesNoPropsInitialState: YesNoProps = {
    renderMethod: "",
    caption: "",
    renderMethodErrorMessage: "",
    captionErrorMessage: "",
  };
  const [yesNoPropsState, setYesNoPropsState] = useState<YesNoProps>(yesNoPropsInitialState);

  const [treeSourceList, setTreeSourceList] = useState<Array<TPKeyValue>>([]);
  let listValuePropsInitialState: ListValueProps = {
    treeId: "",
    renderMethod: "",
    renderMethodErrorMessage: "",
    treeIdErrorMessage: "",
    branchSelectedErrorMessage: "",
  };
  const [listValuePropsState, setListValuePropsState] = useState<ListValueProps>(
    listValuePropsInitialState
  );

  const initialSelectedBranch: TPBranchSelectionValue = {
    branchId: "",
    branchDescription: "",
    branchHierarchyDescription: "",
  };
  const [selectedBranchListValue, setSelectedBranchListValue] =
    useState<TPBranchSelectionValue>(initialSelectedBranch);

  let treePropsInitialState: TreeProps = {
    treeId: "",
    parentIdErrorMessage: "",
    treeIdErrorMessage: "",
  };
  const [treePropsState, setTreePropsState] = useState<TreeProps>(treePropsInitialState);

  const initialSelectedTreeBranch: TPBranchSelectionValue = {
    branchId: "",
    branchDescription: "",
    branchHierarchyDescription: "",
  };
  const [selectedTreeBranchListValue, setSelectedTreeBranchListValue] =
    useState<TPBranchSelectionValue>(initialSelectedTreeBranch);

  const multilanguageTableName: string = "ADditionalDAta";
  const multilanguageFieldName: string = "Description_ADDA";

  const loadResourcesAndLoadAdditionalDataInfo = async () => {
    let i: number;
    setTitleLabel(await TPI18N.GetText(resourceSet, "Title" + mode + "Label"));
    setDescriptionSectionLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "DescriptionSectionLabel")
    );
    setParametersSectionLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ParametersSectionLabel")
    );
    setSaveButtonLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"));
    setCancelButtonLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"));
    setPreviewLabel(await TPI18N.GetText(resourceSet, "PreviewTitle"));
    setFormatDateLabel(await TPI18N.GetText(resourceSet, "FormatDateLabel"));
    setShowAsActiveLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ShowAsActive"));
    setSelectAValueLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "SelectAValue"));
    setEnterAValueLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "EnterAValue"));
    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setAdditionalDataCategoryLabel(await TPI18N.GetText(resourceSet, "CategoryLabel"));
    setAdditionalDataTypeLabel(await TPI18N.GetText(resourceSet, "TypeLabel"));
    setAdditionalDataCommentsLabel(await TPI18N.GetText(resourceSet, "CommentsLabel"));
    setEnableEditingModifyBasicDataLabel(
      await TPI18N.GetText(resourceSet, "EnableEditingModifyBasicLabel")
    );
    setConfigurationSectionLabel(await TPI18N.GetText(resourceSet, "ConfigurationSectionLabel"));
    setModalAcceptLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalAcceptLabel"));
    setModalTitleLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalSelectBranchTitleLabel")
    );
    setModalCancelLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalCancelLabel"));
    setModalSelectedBranchLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalSelectedBranchLabel")
    );
    setEmptyLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel"));
    setTargetTableLabel(await TPI18N.GetText(resourceSet, "TargetTableLabel"));

    setDateTypeLabel(await TPI18N.GetText(resourceSet, "DateTypeLabel"));
    setMinDateLabel(await TPI18N.GetText(resourceSet, "MinDateLabel"));
    setMinimumDateValueLabel(await TPI18N.GetText(resourceSet, "MinimumDateValueLabel"));
    setMaxDateLabel(await TPI18N.GetText(resourceSet, "MaxDateLabel"));
    setMaximumDateValueLabel(await TPI18N.GetText(resourceSet, "MaximumDateValueLabel"));
    setFixedValueLabel(await TPI18N.GetText(resourceSet, "FixedValueLabel"));
    setVariableValueLabel(await TPI18N.GetText(resourceSet, "VariableValueLabel"));
    setTodayDateLabel(await TPI18N.GetText(resourceSet, "TodayDateLabel"));
    setDaysDateLabel(await TPI18N.GetText(resourceSet, "DaysDateLabel"));
    setAnyDateItemLabel(await TPI18N.GetText(resourceSet, "AnyDateItemLabel"));
    setDateRangeItemLabel(await TPI18N.GetText(resourceSet, "DateRangeItemLabel"));

    setInitialValueLabel(await TPI18N.GetText(resourceSet, "InitialValueLabel"));
    setEndValueLabel(await TPI18N.GetText(resourceSet, "EndValueLabel"));

    setUrlLabel(await TPI18N.GetText(resourceSet, "UrlLabel"));

    setValidUrlErrorMessage(await TPI18N.GetText(resourceSet, "ValidUrlErrorMessage"));

    setMinimumValueLabel(await TPI18N.GetText(resourceSet, "MinimumValueLabel"));
    setMaximumValueLabel(await TPI18N.GetText(resourceSet, "MaximumValueLabel"));

    setMinimumValueTypeLabel(await TPI18N.GetText(resourceSet, "MinimumValueTypeLabel"));
    setMaximumValueTypeLabel(await TPI18N.GetText(resourceSet, "MaximumValueTypeLabel"));

    setNaLabel(await TPI18N.GetText(resourceSet, "NaLabel"));
    setValueLabel(await TPI18N.GetText(resourceSet, "ValueLabel"));
    setPercentageLabel(await TPI18N.GetText(resourceSet, "PercentageLabel"));
    setFixedDecimalPlacesLabel(await TPI18N.GetText(resourceSet, "FixedDecimalPlacesLabel"));
    setCurrencySymbolLabel(await TPI18N.GetText(resourceSet, "CurrencySymbolLabel"));
    setCurrencyPlacementLabel(await TPI18N.GetText(resourceSet, "CurrencyPlacementLabel"));
    setDecimalSeparatorLabel(await TPI18N.GetText(resourceSet, "DecimalSeparatorLabel"));
    setThousandsSeparatorLabel(await TPI18N.GetText(resourceSet, "ThousandsSeparatorLabel"));
    setNoneLabel(await TPI18N.GetText(resourceSet, "NoneLabel"));
    setBeforeLabel(await TPI18N.GetText(resourceSet, "BeforeLabel"));
    setAfterLabel(await TPI18N.GetText(resourceSet, "AfterLabel"));
    setSpaceLabel(await TPI18N.GetText(resourceSet, "SpaceLabel"));

    setTextboxTypeLabel(await TPI18N.GetText(resourceSet, "TextboxTypeLabel"));
    setOpenLabel(await TPI18N.GetText(resourceSet, "OpenLabel"));
    setWhiteListLabel(await TPI18N.GetText(resourceSet, "WhiteListLabel"));
    setInputMaskLabel(await TPI18N.GetText(resourceSet, "InputMaskLabel"));
    setMaximumCharactersLabel(await TPI18N.GetText(resourceSet, "MaximumCharactersLabel"));
    setCharactersWhiteListLabel(await TPI18N.GetText(resourceSet, "CharactersWhiteListLabel"));
    setPlaceholderLabel(await TPI18N.GetText(resourceSet, "PlaceholderLabel"));

    setValidationTypeLabel(await TPI18N.GetText(resourceSet, "ValidationTypeLabel"));
    setMinimumCharactersLabel(await TPI18N.GetText(resourceSet, "MinimumCharactersLabel"));
    setBasicLabel(await TPI18N.GetText(resourceSet, "BasicLabel"));
    setAdvanceLabel(await TPI18N.GetText(resourceSet, "AdvanceLabel"));
    setRegularExpressionLabel(await TPI18N.GetText(resourceSet, "RegularExpressionLabel"));

    setTreeLabel(await TPI18N.GetText(resourceSet, "TreeLabel"));
    setParentIdLabel(await TPI18N.GetText(resourceSet, "ParentIdLabel"));

    setControlLabel(await TPI18N.GetText(resourceSet, "ControlLabel"));
    setComboboxLabel(await TPI18N.GetText(resourceSet, "ComboboxLabel"));
    setOptionLabel(await TPI18N.GetText(resourceSet, "OptionLabel"));
    setCheckboxLabel(await TPI18N.GetText(resourceSet, "CheckboxLabel"));

    setCaptionLabel(await TPI18N.GetText(resourceSet, "CaptionLabel"));
    setYesNoLabel(await TPI18N.GetText(resourceSet, "YesNoLabel"));
    setTrueFalseLabel(await TPI18N.GetText(resourceSet, "TrueFalseLabel"));

    let parametersService = new ParametersService();
    let additionalDataTypeService = new AdditionalDataTypeService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest = await parametersService.getByParentIdAndFilterIsActive(
        "S_ADDACATE",
        TPActiveOptions.ACTIVE.toString(),
        false,
        true,
        expectedCodes
      );

      let newadditionalDataCategoryList: Array<TPKeyValue> = responseRequest.map(function (item) {
        return { key: item.id, value: item.localizedDescription };
      });
      newadditionalDataCategoryList.unshift({ key: "", value: "--" });
      setAdditionalDataCategoryList(newadditionalDataCategoryList);
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} loadResourcesAndLoadInfo ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} loadResourcesAndLoadInfo ex`);
      return;
    }

    try {
      let responseRequest = await additionalDataTypeService.getByFilterIsActive(
        TPActiveOptions.ACTIVE.toString(),
        false,
        true,
        expectedCodes
      );

      let newadditionalDataTypeList: Array<TPKeyValue> = responseRequest.map(function (item) {
        return { key: item.id, value: item.localizedDescription };
      });
      newadditionalDataTypeList.unshift({ key: "", value: "--" });
      setDataAddaTypeListState(newadditionalDataTypeList);
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} loadResourcesAndLoadInfo ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} loadResourcesAndLoadInfo ex`);
      return;
    }

    let newTargetTableListState = new Array<TPKeyValue>();
    newTargetTableListState.push({ key: "", value: "--" });

    newTargetTableListState.push({
      key: "CONTACTS",
      value: await TPI18N.GetText(resourceSet, "ContactsItemLabel"),
    });
    newTargetTableListState.push({
      key: "CLIENT",
      value: await TPI18N.GetText(resourceSet, "CustomerItemLabel"),
    });
    newTargetTableListState.push({
      key: "SUBSIDIARY",
      value: await TPI18N.GetText(resourceSet, "SubsidiaryItemLabel"),
    });
    newTargetTableListState.push({
      key: "SUBSIDIARYORGANIZATION",
      value: await TPI18N.GetText(resourceSet, "SubsidiaryOrganizationItemLabel"),
    });

    setTargetTableListState(newTargetTableListState);

    await loadTreeList();

    if (mode == "Update") {
      await getAdditionalDataById(recordId);
    }
    if (mode == "Insert") {
      let newInsertUpdateState = { ...insertUpdateState };
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }
  };
  const getBranchInfoService = async (branchId: string) => {
    let serviceClient = new BranchService();
    let expectedCodes: Array<number> = [200];
    let branchData: BranchViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getBranchById(branchId, false, true, expectedCodes);
      branchData = responseRequest;
      return branchData;
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} getBranchInfo ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} getBranchInfo ex`);
      return null;
    }
  };
  const getBranchInfo = async (parentId: string) => {
    let branchData: BranchViewModel | null = null;
    branchData = await getBranchInfoService(parentId);

    if (branchData == null) {
      return false;
    }

    let newSelectedBranchListValue: TPBranchSelectionValue = {
      ...selectedBranchListValue,
    };
    newSelectedBranchListValue.branchId = parentId;
    newSelectedBranchListValue.branchDescription = branchData.description;
    newSelectedBranchListValue.branchHierarchyDescription = branchData.hierarchyDescription;
    setSelectedBranchListValue(newSelectedBranchListValue);
  };
  const getTreeBranchInfo = async (parentId: string) => {
    let branchData: BranchViewModel | null = null;
    branchData = await getBranchInfoService(parentId);

    if (branchData == null) {
      return false;
    }

    let newSelectedTreeBranchListValue: TPBranchSelectionValue = {
      ...selectedTreeBranchListValue,
    };
    newSelectedTreeBranchListValue.branchId = parentId;
    newSelectedTreeBranchListValue.branchDescription = branchData.description;
    newSelectedTreeBranchListValue.branchHierarchyDescription = branchData.hierarchyDescription;
    setSelectedTreeBranchListValue(newSelectedTreeBranchListValue);
  };
  const loadTreeList = async () => {
    let serviceClient = new TreeService();
    let expectedCodes: Array<number> = [200];
    let i: number;
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getTreeByFilterIsClassifier(
        TPActiveOptions.ACTIVE.toString(),
        false,
        true,
        expectedCodes
      );
      let newListValue: Array<TPKeyValue> = [];

      let treesListData: Array<TreeViewModel>;
      treesListData = responseRequest;
      for (i = 0; i <= treesListData.length - 1; i++) {
        let keyvalue: TPKeyValue = {
          key: treesListData[i].id,
          value: treesListData[i].localizedName,
        };
        newListValue.push(keyvalue);
      }
      let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
      newListValue.unshift(intitialKeyValue);
      setTreeSourceList(newListValue);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} loadTreeList ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} loadTreeList ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleOnIdAdditionalDataChange = (newIdAdditionalData: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.idAdditionalData = newIdAdditionalData;
    newInsertUpdateState.idErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleDescriptionChange = (value: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.description = value;
    newInsertUpdateState.recordLanguageList = insertUpdateState.recordLanguageList.map(l => (
      {
        key: l.key,
        value: l.key == TPGlobal.language ? value : l.value
      }
    ))
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleLanguageListChange = (values: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.recordLanguageList = values.map((l: any) => (
      {
        key: l.id,
        value: l.value
      }
    ));
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleEnableEditingModifyBasicDataChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.enableEditingModifyBasicData =
      !newInsertUpdateState.enableEditingModifyBasicData;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleAdditionalDataCategoryChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.idAdditionalDataCategory = e.target.value;
    newInsertUpdateState.idAdditionalDataCategoryErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleAdditionalDataTypeChange = (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.idAdditionalDataType = e.target.value;
    newInsertUpdateState.idAdditionalDataTypeErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
    setTargetTableSelectedValue("");
    setDatePropsState(datePropsInitialState);
    setPhonePropsState(phonePropsInitialState);
    setIntegerListPropsState(integerListPropsInitialState);
    setLinkPropsState(linkPropsInitialState);
    setNumericPropsState(numericPropsInitialState);
    setOpenTextPropsState(openTextPropsInitialState);
    setOpenTextMultilinePropsState(openTextMultilinePropsInitialState);
    setYesNoPropsState(yesNoPropsInitialState);
    setListValuePropsState(listValuePropsInitialState);
    setTreePropsState(treePropsInitialState);

    let temp = { ...complementsRenderTP };
    temp.placeholder = "";
    if (newInsertUpdateState.idAdditionalDataType == AdditionalDataTypeEnum.date) {
      temp.placeholder = TPGlobal.dateTimeFormat;
    }
    setComplementsRenderTP(temp);
  };

  const handlerTargetTableChange = (e: any) => {
    setTargetTableSelectedValue(e.target.value);
    setTargetTableErrorMessage("");
  };

  const handleAdditionalDataCommentsChange = (newAdditionalDataComments: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.comments = newAdditionalDataComments;
    newInsertUpdateState.commentsErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  function conditionIsValidByType() {
    switch (insertUpdateState.idAdditionalDataType) {
      case AdditionalDataTypeEnum.date:
        return true;
      case AdditionalDataTypeEnum.label:
        return true;
      case AdditionalDataTypeEnum.integerlist:
        return true;
      case AdditionalDataTypeEnum.link:
        return true;
      case AdditionalDataTypeEnum.phone:
        return true;
      case AdditionalDataTypeEnum.numeric:
        return true;
      case AdditionalDataTypeEnum.textbox:
        switch (openTextPropsState.type) {
          case AdditionalDataOpenTextTypesEnum.whiteList:
            return true;
          case AdditionalDataOpenTextTypesEnum.inputMask:
            return true;
          default:
            return false;
        }
      case AdditionalDataTypeEnum.textarea:
        switch (openTextMultilinePropsState.type) {
          case "Open":
            return true;
          case "WhiteList":
            return true;
          default:
            return false;
        }
      case AdditionalDataTypeEnum.yesno:
        return true;
      case AdditionalDataTypeEnum.listvalue:
        return true;
      case AdditionalDataTypeEnum.tree:
        return true;
      default:
        return false;
    }
  }

  function isVisible() {
    return (
      insertUpdateState?.description &&
      insertUpdateState?.idAdditionalDataCategory &&
      insertUpdateState?.idAdditionalDataType
    );
  }
  function isDisable() {
    if (nameInputRef.current?.isInvalid()) return true;
    if (!insertUpdateState.idAdditionalDataType || (insertUpdateState.idAdditionalDataType == ""))
      return true;
    if (
      !insertUpdateState.idAdditionalDataCategory ||
      (insertUpdateState.idAdditionalDataCategory == "")
    )
      return true;
    return false;
  }

  function isDisablePreview(additinalData: any) {
    if (!additinalData) return false;
    if (!additinalData.additionalDataTypeId) return false;

    if (additinalData.additionalDataTypeId == AdditionalDataTypeEnum.integerlist) {
      let data = JSON.parse(additinalData.jsonParameters);
      if (!additinalData.jsonParameters || additinalData.jsonParameters == "") return false;
      if (!data.initialValue || data.initialValue == "") return false;
      if (!data.endValue || data.endValue == "") return false;
    }

    if (additinalData.additionalDataTypeId == AdditionalDataTypeEnum.tree) {
      let data = JSON.parse(additinalData.jsonParameters);
      if (!additinalData.jsonParameters || additinalData.jsonParameters == "") return false;
      if (!data.parentId || data.parentId == "") return false;
      if (!data.treeId || data.treeId == "") return false;
    }

    if (additinalData.additionalDataTypeId == AdditionalDataTypeEnum.yesno) {
      let data = JSON.parse(additinalData.jsonParameters);
      if (!additinalData.jsonParameters || additinalData.jsonParameters == "") return false;
      if (!data.renderMethod || data.renderMethod == "") return false;
      if (!data.caption || data.caption == "") return false;
    }

    if (additinalData.additionalDataTypeId == AdditionalDataTypeEnum.listvalue) {
      let data = JSON.parse(additinalData.jsonParameters);
      if (!additinalData.jsonParameters || additinalData.jsonParameters == "") return false;
      if (!data.parentId || data.parentId == "") return false;
      if (!data.treeId || data.treeId == "") return false;
      if (!data.renderMethod || data.renderMethod == "") return false;
    }

    return true;
  }

  useEffect(() => {
    isVisible();
  }, [nameInputRef]);

  const handleOkButtonClick = async () => {
    if (nameInputRef.current?.isInvalid()) {
      nameInputRef.current?.markAsTouched();
      return;
    }

    const languages = nameInputRef.current?.getValue();
    const mainLanguage = languages?.find(({ id }) => id === TPGlobal.TPClientDefaultLanguage);
    let i: number;
    let n: number;
    let recordInputDTO: AdditionalDataInputDTO = {
      id: mode === "Insert" ? referenceId : recordId,
      description: mainLanguage?.value!,
      otherLocalizedValues: [
        {
          languageId: mainLanguage?.id!,
          localizedValue: mainLanguage?.value ?? "",
          order: 1,
        },
        ...languages!
          .filter(({ id }) => id !== TPGlobal.TPClientDefaultLanguage)
          .map(({ id, value }, order) => ({
            languageId: id,
            localizedValue: value ?? "",
            order: order + 2,
          })),
      ],
      validationURL: insertUpdateState.validationUrl,
      comments: insertUpdateState.comments,
      additionalDataTypeId: insertUpdateState.idAdditionalDataType,
      jsonParameters: insertUpdateState.jsonParameters,
      categoryId: insertUpdateState.idAdditionalDataCategory,
      enableEditingModifyBasicData: insertUpdateState.enableEditingModifyBasicData,
    };

    let inputDTOValidator = new AAdditionalDataInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    let controlValidation: boolean = true;

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      if (resultValidator.id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(resultValidator.id);
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }
      if (resultValidator.comments) {
        newInsertUpdateState.commentsErrorMessage = await TPI18N.GetResource(
          resultValidator.comments
        );
      } else {
        newInsertUpdateState.commentsErrorMessage = "";
      }
      if (resultValidator.categoryId) {
        newInsertUpdateState.idAdditionalDataCategoryErrorMessage = await TPI18N.GetResource(
          resultValidator.categoryId
        );
      } else {
        newInsertUpdateState.idAdditionalDataCategoryErrorMessage = "";
      }
      if (resultValidator.additionalDataTypeId) {
        newInsertUpdateState.idAdditionalDataTypeErrorMessage = await TPI18N.GetResource(
          resultValidator.additionalDataTypeId
        );
      } else {
        newInsertUpdateState.idAdditionalDataTypeErrorMessage = "";
      }
      setInsertUpdateState(newInsertUpdateState);
      controlValidation = false;
    }

    const dataTypesNotRequiredForValidation = [
      "ATTACHMENT",
      "EMAIL",
      "TEXTBOX",
      "PruebaADTY",
      "TEXTBOX_PRUEBA_INS",
      "TEXTBOX_PRUEBA1",
      "TEXTBOX_PRUEBA12",
      "TEXTBOX_PRUEBA123",
      "TEXTBOX_PRUEBA1234",
    ];

    if (
      insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT &&
      (!dataTypesNotRequiredForValidation.includes(insertUpdateState.idAdditionalDataType) ||
        (insertUpdateState.idAdditionalDataType === "TEXTBOX" &&
          openTextPropsState.type === AdditionalDataOpenTextTypesEnum.whiteList))
    ) {
      if (!targetTableSelectedValue) {
        setTargetTableErrorMessage(await TPI18N.GetText(resourceSet, "TargetTableErrorMessage"));
        controlValidation = false;
      }
    }

    let objJson: any = new Object();
    switch (insertUpdateState.idAdditionalDataType) {
      case AdditionalDataTypeEnum.date: {
        let controlDate: boolean = true;
        let newDatePropsState: DateProps = { ...datePropsState };
        objJson.type = datePropsState.type;

        if (datePropsState.type == AdditionalDataDateTypeEnum.dateRange) {
          if (datePropsState.minDateType === "fixed" && datePropsState.maxDateType === "fixed") {
            if (!datePropsState.minDate) {
              newDatePropsState.minDateErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOMinDateEmpty"
              );
              controlDate = false;
            }
            if (!datePropsState.maxDate) {
              newDatePropsState.maxDateErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOMaxDateEmpty"
              );
              controlDate = false;
            }
            if (!controlDate) {
              setDatePropsState(newDatePropsState);
              return;
            }
          }

          if (datePropsState.minDateType === "variable") {
            objJson.minDate = `Now${datePropsState.minDateSymbol}${datePropsState.minDateDays}`;
          } else {
            if (!datePropsState.minDate) {
              newDatePropsState.minDateErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOMinDateEmpty"
              );
              setDatePropsState(newDatePropsState);
              return;
            }
            objJson.minDate = moment(datePropsState.minDate).format(
              TPGlobal.dateFormatAdditionalData.toUpperCase()
            );
          }
          if (datePropsState.maxDateType === "variable") {
            objJson.maxDate = `Now${datePropsState.maxDateSymbol}${datePropsState.maxDateDays}`;
          } else {
            if (!datePropsState.maxDate) {
              newDatePropsState.maxDateErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOMaxDateEmpty"
              );
              setDatePropsState(newDatePropsState);
              return;
            }
            objJson.maxDate = moment(datePropsState.maxDate).format(
              TPGlobal.dateFormatAdditionalData.toUpperCase()
            );
          }
        } else {
          objJson.minDate = "";
          objJson.maxDate = "";
        }
        if (newDatePropsState.formatDate && newDatePropsState.formatDate != "")
          objJson.formatDate = newDatePropsState.formatDate;
        else objJson.formatDate = "0";

        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      }
      case AdditionalDataTypeEnum.email:
      case AdditionalDataTypeEnum.label:
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.integerlist:
        if (
          parseInt(integerListPropsState.initialValue.toString(), 10) >=
          parseInt(integerListPropsState.endValue.toString(), 10)
        ) {
          let newIntegerListPropsState: IntegerListProps = {
            ...integerListPropsState,
          };
          newIntegerListPropsState.initialValueErrorMessage = await TPI18N.GetText(
            resourceSet,
            "MinValueError"
          );
          setIntegerListPropsState(newIntegerListPropsState);
          return;
        }
        objJson.initialValue = integerListPropsState.initialValue.toString();
        objJson.endValue = integerListPropsState.endValue.toString();
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.link: {
        let newLinkPropsState = { ...linkPropsState };
        if (!linkPropsState.url) {
          newLinkPropsState.urlErrorMessage = await TPI18N.GetText(resourceSet, "InputDTOUrlEmpty");
          setLinkPropsState(newLinkPropsState);
          return;
        }
        try {
          new URL(linkPropsState.url);
          newLinkPropsState.urlErrorMessage = "";
        } catch (_) {
          newLinkPropsState.urlErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOUrlInvalid"
          );
          setLinkPropsState(newLinkPropsState);
          return;
        }
        setLinkPropsState(newLinkPropsState);
        objJson.url = encodeURIComponent(linkPropsState.url);
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      }
      case AdditionalDataTypeEnum.phone:
        if (
          phonePropsState.validationType.toUpperCase() === "ADVANCE" &&
          !phonePropsState.regularExpresion
        ) {
          let newPhonePropsState: PhoneProps = { ...phonePropsState };
          newPhonePropsState.regularExpresionErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTORegularExpresionEmpty"
          );
          setPhonePropsState(newPhonePropsState);
          return;
        }
        if (
          phonePropsState.validationType.toUpperCase() === "BASIC" &&
          !phonePropsState.charactersWhiteList
        ) {
          let newPhonePropsState: PhoneProps = { ...phonePropsState };
          newPhonePropsState.charactersWhiteListErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOCharacterWhiteListEmpty"
          );
          setPhonePropsState(newPhonePropsState);
          return;
        }
        if (
          phonePropsState.validationType.toUpperCase() === "BASIC" &&
          phonePropsState.minimumCharacters >= phonePropsState.maximumCharacters
        ) {
          let newPhonePropsState: PhoneProps = { ...phonePropsState };
          newPhonePropsState.minimumCharactersErrorMessage = await TPI18N.GetText(
            resourceSet,
            "MinimumCharactersErrorMessage"
          );
          setPhonePropsState(newPhonePropsState);
          return;
        }
        if (phonePropsState.validationType.toUpperCase() === "ADVANCE") {
          objJson.type = "WhiteList";
          objJson.phoneValType = phonePropsState.validationType.toUpperCase();
          objJson.minLength = "..";
          objJson.maxLength = "..";
          objJson.phoneRegEXPRE = phonePropsState.regularExpresion;
          objJson.whiteList = phonePropsState.charactersWhiteList;
          objJson.targetTable =
            insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
              ? targetTableSelectedValue
              : "";
          recordInputDTO.jsonParameters = JSON.stringify(objJson);
        } else {
          objJson.type = "WhiteList";
          objJson.phoneValType = phonePropsState.validationType.toUpperCase();
          objJson.minLength = phonePropsState.minimumCharacters;
          objJson.maxLength = phonePropsState.maximumCharacters;
          objJson.phoneRegEXPRE = "";
          objJson.whiteList = phonePropsState.charactersWhiteList;
          objJson.targetTable =
            insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
              ? targetTableSelectedValue
              : "";
          recordInputDTO.jsonParameters = JSON.stringify(objJson);
        }
        break;
      case AdditionalDataTypeEnum.numeric: {
        let control: boolean = true;
        let newNumericPropsState: NumericProps = { ...numericPropsState };
        if (!numericPropsState.minValueValidationType) {
          newNumericPropsState.minValueTypeErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOMinValueTypeEmpty"
          );
          control = false;
        }
        if (!numericPropsState.maxValueValidationType) {
          newNumericPropsState.maxValueTypeErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOMaxValueTypeEmpty"
          );

          control = false;
        }
        if (!control) {
          setNumericPropsState(newNumericPropsState);
          return;
        }
        if (numericPropsState.minValue <= 0 && numericPropsState.minValueValidationType != "IN") {
          newNumericPropsState.minValueErrorMessage = await TPI18N.GetText(
            resourceSet,
            "MinValueMustBePositive"
          );
          setNumericPropsState(newNumericPropsState);
          return;
        }
        if (numericPropsState.maxValue <= 0 && numericPropsState.maxValueValidationType != "IN") {
          newNumericPropsState.maxValueErrorMessage = await TPI18N.GetText(
            resourceSet,
            "MaxValueMustBePositive"
          );
          setNumericPropsState(newNumericPropsState);
          return;
        }

        if (
          numericPropsState.minValueValidationType.toUpperCase() === "VAL" &&
          numericPropsState.maxValueValidationType.toUpperCase() === "VAL"
        ) {
          if (numericPropsState.minValue >= numericPropsState.maxValue) {
            let newNumericPropsState: NumericProps = { ...numericPropsState };
            newNumericPropsState.minValueErrorMessage = await TPI18N.GetText(
              resourceSet,
              "MinValueError"
            );
            setNumericPropsState(newNumericPropsState);
            return;
          }
        }
        if (!numericPropsState.percentage && !numericPropsState.currencyPlacement) {
          if (numericPropsState.currencySymbol) {
            let newNumericPropsState: NumericProps = { ...numericPropsState };
            newNumericPropsState.currencyPlacementErrorMessage = await TPI18N.GetText(
              resourceSet,
              "InputDTOCurrencyPlacementEmpty"
            );
            setNumericPropsState(newNumericPropsState);
            return;
          }
        }

        if (numericPropsState.decimalSeparator == numericPropsState.thousandsSeparator) {
          newNumericPropsState.thousandsSeparatorErrorMessage = await TPI18N.GetText(
            resourceSet,
            "DecimalAndThousandsSeparatorNotEqualsErrorMessage"
          );
          setNumericPropsState(newNumericPropsState);
          return;
        }

        if (numericPropsState.minValueValidationType === "VAL") {
          objJson.minValue = numericPropsState.minValue;
        } else {
          objJson.minValue = numericPropsState.minValueValidationType;
        }
        if (numericPropsState.maxValueValidationType === "VAL") {
          objJson.maxValue = numericPropsState.maxValue;
        } else {
          objJson.maxValue = numericPropsState.maxValueValidationType;
        }
        objJson.percentage = numericPropsState.percentage;
        objJson.fixedDecimal = numericPropsState.fixedDecimal;
        objJson.currencySymbol = numericPropsState.currencySymbol;
        objJson.currencyPlacement = numericPropsState.currencyPlacement;
        objJson.decimalSeparator = numericPropsState.decimalSeparator;
        objJson.thousandsSeparator = numericPropsState.thousandsSeparator;
        objJson.negativeNumber = "-";
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      }
      case AdditionalDataTypeEnum.textbox:
        objJson.type = openTextPropsState.type;
        //1. Validations
        switch (openTextPropsState.type) {
          case AdditionalDataOpenTextTypesEnum.open:
            if (
              openTextPropsState.maxLength &&
              !isNaN(Number(openTextPropsState.maxLength)) &&
              Number(openTextPropsState.maxLength) <= 0
            ) {
              let newOpenTextPropsState: OpenTextProps = {
                ...openTextPropsState,
              };
              newOpenTextPropsState.maxLengthErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOMaxLengthMustBePositive"
              );
              setOpenTextPropsState(newOpenTextPropsState);
              return;
            }
            objJson.maxLength = openTextPropsState.maxLength;
            break;
          case AdditionalDataOpenTextTypesEnum.whiteList:
            if (!openTextPropsState.whiteList) {
              let newOpenTextPropsState: OpenTextProps = {
                ...openTextPropsState,
              };
              newOpenTextPropsState.whiteListErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOCharacterWhiteListEmpty"
              );
              setOpenTextPropsState(newOpenTextPropsState);
              return;
            }
            if (
              openTextPropsState.maxLength &&
              !isNaN(Number(openTextPropsState.maxLength)) &&
              Number(openTextPropsState.maxLength) <= 0
            ) {
              let newOpenTextPropsState: OpenTextProps = {
                ...openTextPropsState,
              };
              newOpenTextPropsState.maxLengthErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOMaxLengthMustBePositive"
              );
              setOpenTextPropsState(newOpenTextPropsState);
              return;
            }
            objJson.maxLength = openTextPropsState.maxLength;
            objJson.whiteList = openTextPropsState.whiteList;
            objJson.inputMask = openTextPropsState.inputMask;
            objJson.placeHolder = openTextPropsState.placeHolder;
            objJson.targetTable =
              insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
                ? targetTableSelectedValue
                : "";

            break;
          case AdditionalDataOpenTextTypesEnum.inputMask:
            if (!openTextPropsState.inputMask) {
              let newOpenTextPropsState: OpenTextProps = {
                ...openTextPropsState,
              };
              newOpenTextPropsState.inputMaskErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOInputMaskEmpty"
              );
              setOpenTextPropsState(newOpenTextPropsState);
              return;
            }
            objJson.maxLength = "..";
            objJson.whiteList = "..";
            objJson.inputMask = openTextPropsState.inputMask;
            objJson.placeHolder = openTextPropsState.placeHolder;
            objJson.targetTable =
              insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
                ? targetTableSelectedValue
                : "";
            break;
          default:
            break;
        }
        //End Validations

        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.textarea:
        objJson.type = openTextMultilinePropsState.type;
        //1. Validations
        switch (openTextMultilinePropsState.type) {
          case "Open":
            if (
              openTextMultilinePropsState.maxLength &&
              !isNaN(Number(openTextMultilinePropsState.maxLength)) &&
              Number(openTextMultilinePropsState.maxLength) <= 0
            ) {
              let newOpenTextPropsState: OpenTextMultilineProps = {
                ...openTextMultilinePropsState,
              };
              newOpenTextPropsState.maxLengthErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOMaxLengthMustBePositive"
              );
              setOpenTextMultilinePropsState(newOpenTextPropsState);
              return;
            }
            objJson.maxLength = openTextMultilinePropsState.maxLength;
            objJson.targetTable =
              insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
                ? targetTableSelectedValue
                : "";
            break;
          case "WhiteList":
            if (!openTextMultilinePropsState.whiteList) {
              let newOpenTextMultilinePropsState: OpenTextMultilineProps = {
                ...openTextMultilinePropsState,
              };
              newOpenTextMultilinePropsState.whiteListErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOCharacterWhiteListEmpty"
              );
              setOpenTextMultilinePropsState(newOpenTextMultilinePropsState);
              return;
            }
            if (
              openTextMultilinePropsState.maxLength &&
              !isNaN(Number(openTextMultilinePropsState.maxLength)) &&
              Number(openTextMultilinePropsState.maxLength) <= 0
            ) {
              let newOpenTextPropsState: OpenTextMultilineProps = {
                ...openTextMultilinePropsState,
              };
              newOpenTextPropsState.maxLengthErrorMessage = await TPI18N.GetText(
                resourceSet,
                "InputDTOMaxLengthMustBePositive"
              );
              setOpenTextMultilinePropsState(newOpenTextPropsState);
              return;
            }
            objJson.maxLength = openTextMultilinePropsState.maxLength;
            objJson.whiteList = openTextMultilinePropsState.whiteList;
            objJson.targetTable =
              insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
                ? targetTableSelectedValue
                : "";
            break;

          default:
            break;
        }
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.yesno: {
        let controlYesNo: boolean = true;
        let newYesNoPropsState: YesNoProps = { ...yesNoPropsState };

        if (!yesNoPropsState.renderMethod) {
          newYesNoPropsState.renderMethodErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTORenderMethodEmpty"
          );
          setYesNoPropsState(newYesNoPropsState);
          controlYesNo = false;
        }

        if (!yesNoPropsState.caption) {
          newYesNoPropsState.captionErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOCampionEmpty"
          );
          setYesNoPropsState(newYesNoPropsState);
          controlYesNo = false;
        }
        if (!controlYesNo) {
          setYesNoPropsState(newYesNoPropsState);
          return;
        }
        //End validations
        objJson.renderMethod = yesNoPropsState.renderMethod;
        objJson.caption = yesNoPropsState.caption;
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      }
      case AdditionalDataTypeEnum.listvalue: {
        let controlListValue: boolean = true;
        let newListValuePropsState: ListValueProps = {
          ...listValuePropsState,
        };
        if (!listValuePropsState.treeId) {
          newListValuePropsState.treeIdErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOTreeIdEmpty"
          );
          controlListValue = false;
        }

        if (!selectedBranchListValue.branchId) {
          newListValuePropsState.branchSelectedErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOParentIdEmpty"
          );
          controlListValue = false;
        }

        if (!listValuePropsState.renderMethod) {
          newListValuePropsState.renderMethodErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTORenderMethodEmpty"
          );
          controlListValue = false;
        }
        if (!controlListValue) {
          setListValuePropsState(newListValuePropsState);
          return;
        }
        //End validations
        objJson.parentId = selectedBranchListValue.branchId;
        objJson.renderMethod = listValuePropsState.renderMethod;
        objJson.treeId = listValuePropsState.treeId;
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      }
      case AdditionalDataTypeEnum.tree: {
        let controlTree: boolean = true;
        let newTreePropsState: TreeProps = {
          ...treePropsState,
        };
        if (!treePropsState.treeId) {
          newTreePropsState.treeIdErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOTreeIdEmpty"
          );
          controlTree = false;
        }

        if (!selectedTreeBranchListValue.branchId) {
          newTreePropsState.parentIdErrorMessage = await TPI18N.GetText(
            resourceSet,
            "InputDTOParentIdEmpty"
          );
          controlTree = false;
        }
        if (!controlTree) {
          setTreePropsState(newTreePropsState);
          return;
        }
        //End validations
        objJson.parentId = selectedTreeBranchListValue.branchId;
        objJson.treeId = treePropsState.treeId;
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      }
      default:
        recordInputDTO.jsonParameters = "";
        break;
    }

    if (recordInputDTO.jsonParameters) {
      recordInputDTO.jsonParameters = TPGlobal.stringToUTF8(
        recordInputDTO.jsonParameters
      ).toString();
    }

    if (!controlValidation) {
      return;
    }

    if (mode == "Insert") {
      await insertAdditionalData(recordInputDTO);
    } else {
      await updateAdditionalData(recordInputDTO);
    }
  };

  const handleBuilderData = async () => {
    let newDatePropsState: DateProps = { ...datePropsState };
    let recordInputDTO: AdditionalDataInputDTO = {
      id: mode === "Insert" ? referenceId : recordId,
      description: insertUpdateState.description,
      otherLocalizedValues: [],
      validationURL: insertUpdateState.validationUrl,
      comments: insertUpdateState.comments,
      additionalDataTypeId: insertUpdateState.idAdditionalDataType,
      jsonParameters: insertUpdateState.jsonParameters,
      categoryId: insertUpdateState.idAdditionalDataCategory,
      enableEditingModifyBasicData: insertUpdateState.enableEditingModifyBasicData,
    };
    let objJson: any = new Object();
    objJson.placeholder = complementsRenderTP.placeholder;
    switch (insertUpdateState.idAdditionalDataType) {
      case AdditionalDataTypeEnum.date: {
        objJson.type = datePropsState.type;
        if (datePropsState.type == AdditionalDataDateTypeEnum.dateRange) {
          if (datePropsState.minDateType === "variable") {
            objJson.minDate = `Now${datePropsState.minDateSymbol}${datePropsState.minDateDays}`;
          } else {
            objJson.minDate = moment(datePropsState.minDate).format(
              TPGlobal.dateFormatAdditionalData.toUpperCase()
            );
          }
          if (datePropsState.maxDateType === "variable") {
            objJson.maxDate = `Now${datePropsState.maxDateSymbol}${datePropsState.maxDateDays}`;
          } else {
            objJson.maxDate = moment(datePropsState.maxDate).format(
              TPGlobal.dateFormatAdditionalData.toUpperCase()
            );
          }
        } else {
          objJson.minDate = "";
          objJson.maxDate = "";
        }
        objJson.formatDate = newDatePropsState.formatDate;
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      }
      case AdditionalDataTypeEnum.email:
      case AdditionalDataTypeEnum.label:
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.integerlist:
        objJson.initialValue = integerListPropsState.initialValue.toString();
        objJson.endValue = integerListPropsState.endValue.toString();
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.link: {
        let newLinkPropsState = { ...linkPropsState };
        try {
          new URL(linkPropsState.url);
          newLinkPropsState.urlErrorMessage = "";
        } catch (_) {}
        setLinkPropsState(newLinkPropsState);
        objJson.url = encodeURIComponent(linkPropsState.url);
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      }
      case AdditionalDataTypeEnum.phone:
        if (phonePropsState.validationType.toUpperCase() === "ADVANCE") {
          objJson.type = "WhiteList";
          objJson.phoneValType = phonePropsState.validationType.toUpperCase();
          objJson.minLength = "..";
          objJson.maxLength = "..";
          objJson.phoneRegEXPRE = phonePropsState.regularExpresion;
          objJson.whiteList = phonePropsState.charactersWhiteList;
          objJson.targetTable =
            insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
              ? targetTableSelectedValue
              : "";
          recordInputDTO.jsonParameters = JSON.stringify(objJson);
        } else {
          objJson.type = "WhiteList";
          objJson.phoneValType = phonePropsState.validationType.toUpperCase();
          objJson.minLength = phonePropsState.minimumCharacters;
          objJson.maxLength = phonePropsState.maximumCharacters;
          objJson.phoneRegEXPRE = "";
          objJson.whiteList = phonePropsState.charactersWhiteList;
          objJson.targetTable =
            insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
              ? targetTableSelectedValue
              : "";
          recordInputDTO.jsonParameters = JSON.stringify(objJson);
        }
        break;
      case AdditionalDataTypeEnum.numeric: {
        let control: boolean = true;
        let newNumericPropsState: NumericProps = { ...numericPropsState };
        if (!control) {
          setNumericPropsState(newNumericPropsState);
          return;
        }
        if (numericPropsState.minValueValidationType === "VAL") {
          objJson.minValue = numericPropsState.minValue;
        } else {
          objJson.minValue = numericPropsState.minValueValidationType;
        }
        if (numericPropsState.maxValueValidationType === "VAL") {
          objJson.maxValue = numericPropsState.maxValue;
        } else {
          objJson.maxValue = numericPropsState.maxValueValidationType;
        }
        objJson.percentage = numericPropsState.percentage;
        objJson.fixedDecimal = numericPropsState.fixedDecimal;
        objJson.currencySymbol = numericPropsState.currencySymbol;
        objJson.currencyPlacement = numericPropsState.currencyPlacement;
        objJson.decimalSeparator = numericPropsState.decimalSeparator;
        objJson.thousandsSeparator = numericPropsState.thousandsSeparator;
        objJson.negativeNumber = "-";
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      }
      case AdditionalDataTypeEnum.textbox:
        objJson.type = openTextPropsState.type;
        switch (openTextPropsState.type) {
          case AdditionalDataOpenTextTypesEnum.open:
            objJson.maxLength = openTextPropsState.maxLength;
            break;
          case AdditionalDataOpenTextTypesEnum.whiteList:
            objJson.maxLength = openTextPropsState.maxLength;
            objJson.whiteList = openTextPropsState.whiteList;
            objJson.inputMask = openTextPropsState.inputMask;
            objJson.placeHolder = openTextPropsState.placeHolder;
            objJson.targetTable =
              insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
                ? targetTableSelectedValue
                : "";

            break;
          case AdditionalDataOpenTextTypesEnum.inputMask:
            objJson.maxLength = "..";
            objJson.whiteList = "..";
            objJson.inputMask = openTextPropsState.inputMask;
            objJson.placeHolder = openTextPropsState.placeHolder;
            objJson.targetTable =
              insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
                ? targetTableSelectedValue
                : "";
            break;
          default:
            break;
        }
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.textarea:
        objJson.type = openTextMultilinePropsState.type;
        switch (openTextMultilinePropsState.type) {
          case "Open":
            objJson.maxLength = openTextMultilinePropsState.maxLength;
            objJson.targetTable =
              insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
                ? targetTableSelectedValue
                : "";
            break;
          case "WhiteList":
            objJson.maxLength = openTextMultilinePropsState.maxLength;
            objJson.whiteList = openTextMultilinePropsState.whiteList;
            objJson.targetTable =
              insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
                ? targetTableSelectedValue
                : "";
            break;

          default:
            break;
        }
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.yesno:
        objJson.renderMethod = yesNoPropsState.renderMethod;
        objJson.caption = yesNoPropsState.caption;
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.listvalue:
        objJson.parentId = selectedBranchListValue.branchId;
        objJson.renderMethod = listValuePropsState.renderMethod;
        objJson.treeId = listValuePropsState.treeId;

        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      case AdditionalDataTypeEnum.tree:
        objJson.parentId = selectedTreeBranchListValue.branchId;
        objJson.treeId = treePropsState.treeId;
        objJson.targetTable =
          insertUpdateState.idAdditionalDataCategory == AdditionalDataCategoryEnum.S_CUSTCONT
            ? targetTableSelectedValue
            : "";
        recordInputDTO.jsonParameters = JSON.stringify(objJson);
        break;
      default:
        recordInputDTO.jsonParameters = "";
        break;
    }
    setInsertUpdateStatePreview(recordInputDTO);
    return recordInputDTO;
  };

  const openModalAndInsert = () => {
    if (callBackResult2) {
      setIsModalVisible(true);
    } else {
      handleOkButtonClick();
    }
  };

  const handleCallBackModalConfirm = async (confirm: boolean) => {
    if (confirm) {
      handleOkButtonClick();
      setIsModalVisible(false);
    } else {
      setIsModalVisible(false);
    }
  };

  const insertAdditionalData = async (inputDTO: AdditionalDataInputDTO) => {
    let serviceClient = new AdditionalDataService();
    let expectedCodes: Array<number> = [200];
    if (callBackResult2) {
      try {
        let responseRequest = await serviceClient.getAll(false, true, expectedCodes);
        const duplicate = await responseRequest.some(
          (item) => item.description === inputDTO.description
        );

        if (duplicate) {
          showToast("This Additional Data already exists.", TPToastTypes.warning);
          return;
        }
      } catch (error) {
        TPLog.Log(`Error ${componentFileName} insertAdditionalData ex`, TPLogType.ERROR, error);
        console.error(`Error ${componentFileName} insertAdditionalData ex`);
      }
    }

    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertAdditionalData(
        inputDTO,
        true,
        true,
        expectedCodes
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
        if (callBackResult2) {
          callBackResult2({ success: true, data: inputDTO });
        }
      }
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} insertAdditionalData ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} insertAdditionalData ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateAdditionalData = async (inputDTO: AdditionalDataInputDTO) => {
    let serviceClient = new AdditionalDataService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.updateAdditionalData(
        inputDTO,
        true,
        true,
        expectedCodes
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} updateAdditionalData ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} updateAdditionalData ex`);
      setIsLoadingScreen(false);
    }
  };

  const getAdditionalDataById = async (pRecordId: string) => {
    let serviceClient = new AdditionalDataService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getAdditionalDataById(
        pRecordId,
        false,
        true,
        expectedCodes
      );

      let recordInfo: AdditionalDataViewModel;
      recordInfo = { ...responseRequest };

      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.recordLanguageList = [];
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      newInsertUpdateState.idAdditionalDataType = recordInfo.additionalDataTypeId;
      newInsertUpdateState.idAdditionalDataCategory = recordInfo.categoryId;
      newInsertUpdateState.comments = recordInfo.comments;
      newInsertUpdateState.enableEditingModifyBasicData = recordInfo.enableEditingModifyBasicData;
      newInsertUpdateState.validationUrl = recordInfo.validationURL;
      newInsertUpdateState.description = recordInfo.description;
      newInsertUpdateState.recordLanguageList[0].value = recordInfo.description;
      recordLanguagesList = await getRecordLanguageList(pRecordId);
      if (recordLanguagesList.length == 0) {
        TPLog.Log(
          `Error ${componentFileName} getAdditionalDataById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty"
        );
        console.error(
          `Error ${componentFileName} getAdditionalDataById getRecordLanguageList is empty`
        );
        setIsLoadingScreen(false);
        return;
      }
      for (i = 0; i <= newInsertUpdateState.recordLanguageList.length - 1; i++) {
        for (j = 0; j <= recordLanguagesList.length - 1; j++) {
          if (
            newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ==
            recordLanguagesList[j].languageId.toLowerCase()
          ) {
            newInsertUpdateState.recordLanguageList[i].value =
              recordLanguagesList[j].recordDescription;
            break;
          }
        }
      }

      switch (recordInfo.additionalDataTypeId) {
        case AdditionalDataTypeEnum.date:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newDatePropsState: DateProps = { ...datePropsState };
            if (objJson.type && objJson.type === AdditionalDataDateTypeEnum.dateRange) {
              newDatePropsState.type = objJson.type;
              if (objJson.minDate.includes("Now")) {
                newDatePropsState.minDateSymbol = objJson.minDate.substring(4, 3);
                newDatePropsState.minDateType = "variable";
                newDatePropsState.minDateDays = objJson.minDate.substring(4);
              } else {
                newDatePropsState.minDate = moment(
                  objJson.minDate,
                  TPGlobal.dateFormatAdditionalData.toUpperCase()
                ).toDate();
              }
              if (objJson.maxDate.includes("Now")) {
                newDatePropsState.maxDateSymbol = objJson.maxDate.substring(4, 3);
                newDatePropsState.maxDateType = "variable";
                newDatePropsState.maxDateDays = objJson.maxDate.substring(4);
              } else {
                newDatePropsState.maxDate = moment(
                  objJson.maxDate,
                  TPGlobal.dateFormatAdditionalData.toUpperCase()
                ).toDate();
              }
            } else {
              newDatePropsState.type = objJson.type;
            }

            if (objJson.formatDate) newDatePropsState.formatDate = objJson.formatDate;
            else newDatePropsState.formatDate = "0";

            let date = handleDateFormatsAdditionalData().find(
              (s) => s.key == newDatePropsState.formatDate
            );

            let temp = { ...complementsRenderTP };
            if (newDatePropsState.formatDate == "0") temp.placeholder = TPGlobal.dateTimeFormat;
            else temp.placeholder = date?.value;

            newInsertUpdateState.jsonParameters = objJson;
            setComplementsRenderTP(temp);

            setTargetTableSelectedValue(objJson.targetTable);
            setDatePropsState(newDatePropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert json object date`,
              TPLogType.ERROR,
              error
            );
            console.error(`Error ${componentFileName} Error while try to convert json object date`);
          }
          handleBuilderData();
          break;
        case AdditionalDataTypeEnum.email: {
          let objJson: any = JSON.parse(recordInfo.jsonParameters);
          let temp = { ...complementsRenderTP };
          temp.placeholder = objJson.placeholder;
          setComplementsRenderTP(temp);
          break;
        }
        case AdditionalDataTypeEnum.label:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            setTargetTableSelectedValue(objJson.targetTable);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert json objects email or label`,
              TPLogType.ERROR,
              error
            );
            console.error(
              `Error ${componentFileName} Error while try to convert json objects email or label`
            );
          }
          break;
        case AdditionalDataTypeEnum.integerlist:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newIntegerListPropsState: IntegerListProps = {
              ...integerListPropsState,
            };
            newIntegerListPropsState.initialValue = objJson.initialValue;
            newIntegerListPropsState.endValue = objJson.endValue;

            let temp = { ...complementsRenderTP };
            temp.placeholder = objJson.placeholder;
            setComplementsRenderTP(temp);
            setTargetTableSelectedValue(objJson.targetTable);
            setIntegerListPropsState(newIntegerListPropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert json object integer list`,
              TPLogType.ERROR,
              error
            );
            console.error(
              `Error ${componentFileName} Error while try to convert json object integer list`
            );
          }
          break;
        case AdditionalDataTypeEnum.link:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newLinkPropsState: LinkProps = {
              ...linkPropsState,
            };
            newLinkPropsState.url = decodeURIComponent(objJson.url);
            setTargetTableSelectedValue(objJson.targetTable);
            setLinkPropsState(newLinkPropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert json object link`,
              TPLogType.ERROR,
              error
            );
            console.error(`Error ${componentFileName} Error while try to convert json object link`);
          }
          break;
        case AdditionalDataTypeEnum.phone:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newPhonePropsState: PhoneProps = {
              ...phonePropsState,
            };
            let temp = { ...complementsRenderTP };
            temp.placeholder = objJson.placeholder;
            setComplementsRenderTP(temp);
            if (objJson.phoneValType === "ADVANCE") {
              //advance
              newPhonePropsState.validationType = "ADVANCE";
              newPhonePropsState.regularExpresion = objJson.phoneRegEXPRE;
            } else {
              newPhonePropsState.validationType = "BASIC";
              newPhonePropsState.charactersWhiteList = objJson.whiteList;
              newPhonePropsState.minimumCharacters =
                objJson.minLength === ".." ? 0 : +objJson.minLength;
              newPhonePropsState.maximumCharacters =
                objJson.maxLength === ".." ? 0 : +objJson.maxLength;
            }
            setTargetTableSelectedValue(objJson.targetTable);
            setPhonePropsState(newPhonePropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert json object link`,
              TPLogType.ERROR,
              error
            );
            console.error(`Error ${componentFileName} Error while try to convert json object link`);
          }
          break;
        case AdditionalDataTypeEnum.numeric:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newNumericPropsState: NumericProps = {
              ...numericPropsState,
            };
            let temp = { ...complementsRenderTP };
            temp.placeholder = objJson.placeholder;
            setComplementsRenderTP(temp);
            if (objJson.minValue !== "IN" && objJson.minValue !== "") {
              newNumericPropsState.minValueValidationType = "VAL";
              newNumericPropsState.minValue = objJson.minValue;
            } else {
              newNumericPropsState.minValueValidationType = objJson.minValue;
            }
            if (objJson.maxValue !== "IN" && objJson.maxValue !== "") {
              newNumericPropsState.maxValueValidationType = "VAL";
              newNumericPropsState.maxValue = objJson.maxValue;
            } else {
              newNumericPropsState.maxValueValidationType = objJson.maxValue;
            }
            newNumericPropsState.percentage = objJson.percentage;
            newNumericPropsState.currencySymbolDisabled = objJson.percentage;
            newNumericPropsState.currencyplacementDisabled = objJson.percentage;
            newNumericPropsState.fixedDecimal = objJson.fixedDecimal;
            newNumericPropsState.currencySymbol = objJson.currencySymbol;
            newNumericPropsState.currencyPlacement = objJson.currencyPlacement;
            newNumericPropsState.decimalSeparator = objJson.decimalSeparator;
            newNumericPropsState.thousandsSeparator = objJson.thousandsSeparator;
            setTargetTableSelectedValue(objJson.targetTable);
            setNumericPropsState(newNumericPropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert numeric json object`,
              TPLogType.ERROR,
              error
            );
            console.error(
              `Error ${componentFileName} Error while try to convert numeric json object`
            );
          }
          break;
        case AdditionalDataTypeEnum.textbox:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newOpenTextPropsState: OpenTextProps = {
              ...openTextPropsState,
            };
            let temp = { ...complementsRenderTP };
            temp.placeholder = objJson.placeholder;
            setComplementsRenderTP(temp);
            newOpenTextPropsState.type = objJson.type;
            newOpenTextPropsState.maxLength = objJson.maxLength === ".." ? 0 : +objJson.maxLength;
            newOpenTextPropsState.inputMask = objJson.inputMask;
            newOpenTextPropsState.placeHolder = objJson.placeHolder;
            newOpenTextPropsState.whiteList = objJson.whiteList;
            setTargetTableSelectedValue(objJson.targetTable);
            setOpenTextPropsState(newOpenTextPropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert open text json object`,
              TPLogType.ERROR,
              error
            );
            console.error(
              `Error ${componentFileName} Error while try to convert open textjson object`
            );
          }
          break;
        case AdditionalDataTypeEnum.textarea:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newOpenTextMultilinePropsState: OpenTextMultilineProps = {
              ...openTextMultilinePropsState,
            };
            let temp = { ...complementsRenderTP };
            temp.placeholder = objJson.placeholder;
            setComplementsRenderTP(temp);
            newOpenTextMultilinePropsState.type = objJson.type;
            newOpenTextMultilinePropsState.maxLength =
              objJson.maxLength === ".." ? 0 : +objJson.maxLength;
            newOpenTextMultilinePropsState.whiteList = objJson.whiteList;
            setTargetTableSelectedValue(objJson.targetTable);
            setOpenTextMultilinePropsState(newOpenTextMultilinePropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert open text multiline json object`,
              TPLogType.ERROR,
              error
            );
            console.error(
              `Error ${componentFileName} Error while try to convert open textjson multiline object`
            );
          }
          break;
        case AdditionalDataTypeEnum.yesno:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newYesNoPropsState: YesNoProps = {
              ...yesNoPropsState,
            };
            newYesNoPropsState.renderMethod = objJson.renderMethod;
            newYesNoPropsState.caption = objJson.caption;
            setTargetTableSelectedValue(objJson.targetTable);
            setYesNoPropsState(newYesNoPropsState);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert Yes-No json object`,
              TPLogType.ERROR,
              error
            );
            console.error(
              `Error ${componentFileName} Error while try to convert Yes-No json object`
            );
          }
          break;
        case AdditionalDataTypeEnum.listvalue:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newListValuePropsState: ListValueProps = {
              ...listValuePropsState,
            };
            newListValuePropsState.treeId = objJson.treeId;
            newListValuePropsState.renderMethod = objJson.renderMethod;
            setTargetTableSelectedValue(objJson.targetTable);
            setListValuePropsState(newListValuePropsState);
            await getBranchInfo(objJson.parentId);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert list value json object`,
              TPLogType.ERROR,
              error
            );
            console.error(
              `Error ${componentFileName} Error while try to convert list value json object`
            );
          }
          break;
        case AdditionalDataTypeEnum.tree:
          try {
            let objJson: any = JSON.parse(recordInfo.jsonParameters);
            let newTreePropsState: TreeProps = {
              ...treePropsState,
            };
            let temp = { ...complementsRenderTP };
            temp.placeholder = objJson.placeholder;
            setComplementsRenderTP(temp);
            newTreePropsState.treeId = objJson.treeId;
            setTargetTableSelectedValue(objJson.targetTable);
            setTreePropsState(newTreePropsState);
            await getTreeBranchInfo(objJson.parentId);
          } catch (error) {
            TPLog.Log(
              `Error ${componentFileName} Error while try to convert list value json object`,
              TPLogType.ERROR,
              error
            );
            console.error(
              `Error ${componentFileName} Error while try to convert list value json object`
            );
          }
          break;
        default:
          break;
      }

      setInsertUpdateState(newInsertUpdateState);

      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} getAdditionalDataById ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} getAdditionalDataById ex`);
      setIsLoadingScreen(false);
    }
  };

  const getRecordLanguageList = async (
    pRecordId: string
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} getRecordLanguageList ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  const handleMinDateTypeChange = (e: any) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.minDateType = e.target.value;
    setDatePropsState(newDatePropsState);
  };
  const handleMinDateSymbolChange = (e: any) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.minDateSymbol = e.target.value;
    setDatePropsState(newDatePropsState);
  };
  const handleMinDateChange = (newMinDate: number) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.minDateDays = newMinDate;
    setDatePropsState(newDatePropsState);
  };
  const handleMinDateValueChange = (newMinDate: Date) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.minDate = newMinDate;
    newDatePropsState.minDateErrorMessage = "";
    setDatePropsState(newDatePropsState);
  };
  const handleMaxDateTypeChange = (e: any) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.maxDateType = e.target.value;
    setDatePropsState(newDatePropsState);
  };
  const handleMaxDateSymbolChange = (e: any) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.maxDateSymbol = e.target.value;
    setDatePropsState(newDatePropsState);
  };
  const handleMaxDateChange = (newMinDate: number) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.maxDateDays = newMinDate;
    setDatePropsState(newDatePropsState);
  };
  const handleMaxDateValueChange = (newMaxDate: Date) => {
    let newDatePropsState = { ...datePropsState };
    newDatePropsState.maxDate = newMaxDate;
    newDatePropsState.maxDateErrorMessage = "";
    setDatePropsState(newDatePropsState);
  };
  const handlerDateTypeChange = (e: any) => {
    let newDateState = { ...datePropsState };
    newDateState.type = e.target.value;
    setDatePropsState(newDateState);
  };
  const handlerInitialValueChange = (newInitialValue: number) => {
    let newIntegerListPropsState = { ...integerListPropsState };
    newIntegerListPropsState.initialValue = newInitialValue;
    newIntegerListPropsState.initialValueErrorMessage = "";
    setIntegerListPropsState(newIntegerListPropsState);
  };
  const handlerEndValueChange = (newEndValue: number) => {
    let newIntegerListPropsState = { ...integerListPropsState };
    newIntegerListPropsState.endValue = newEndValue;
    setIntegerListPropsState(newIntegerListPropsState);
  };

  const handleValidateControlType = () => {
    if (!insertUpdateStatePreview) return false;
    let status = false;
    let additionalDataTypeId = insertUpdateStatePreview.additionalDataTypeId;
    switch (additionalDataTypeId) {
      case AdditionalDataTypeEnum.textbox:
      case AdditionalDataTypeEnum.textarea:
      case AdditionalDataTypeEnum.email:
      case AdditionalDataTypeEnum.integerlist:
      case AdditionalDataTypeEnum.phone:
      case AdditionalDataTypeEnum.tree:
      case AdditionalDataTypeEnum.listvalue:
      case AdditionalDataTypeEnum.numeric:
        status = true;
        break;
    }
    return status;
  };

  useEffect(() => {
    handleBuilderData();
  }, [
    insertUpdateState,
    datePropsState,
    selectedTreeBranchListValue,
    treePropsState,
  ]);

  const handlerOnUrlChange = (e: any) => {
    const url = e.target.value.trim();
    let newLinkPropsState = { ...linkPropsState };
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)*$/;

    if (url === "" || urlPattern.test(url)) {
      newLinkPropsState.url = url;
      newLinkPropsState.urlErrorMessage = "";
    } else {
      newLinkPropsState.url = url;
      newLinkPropsState.urlErrorMessage = validUrlErrorMessage;
    }

    setLinkPropsState(newLinkPropsState);
  };

  const handleValidationTypeChange = (e: any) => {
    let newPhonePropsState = { ...phonePropsState };
    newPhonePropsState.validationType = e.target.value;
    setPhonePropsState(newPhonePropsState);
  };
  const handlerOnMinimumValueChange = (newMinimumCharacters: number) => {
    let newPhonePropsState = { ...phonePropsState };
    newPhonePropsState.minimumCharacters = newMinimumCharacters;
    newPhonePropsState.minimumCharactersErrorMessage = "";
    setPhonePropsState(newPhonePropsState);
  };
  const handlerOnMaximumValueChange = (newMaximumCharacters: number) => {
    let newPhonePropsState = { ...phonePropsState };
    newPhonePropsState.maximumCharacters = newMaximumCharacters;
    newPhonePropsState.minimumCharactersErrorMessage = "";
    setPhonePropsState(newPhonePropsState);
  };
  const handlerOnWhiteListChange = (e: any) => {
    let newPhonePropsState = { ...phonePropsState };
    newPhonePropsState.charactersWhiteList = e.target.value;
    newPhonePropsState.charactersWhiteListErrorMessage = "";
    setPhonePropsState(newPhonePropsState);
  };
  const handlerOnRegularExpresionChange = (e: any) => {
    let newPhonePropsState = { ...phonePropsState };
    newPhonePropsState.regularExpresion = e.target.value;
    newPhonePropsState.regularExpresionErrorMessage = "";
    setPhonePropsState(newPhonePropsState);
  };
  const handlerMinValueValidationChange = (e: any) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.minValueValidationType = e.target.value;
    newNumericPropsState.minValueTypeErrorMessage = "";
    setNumericPropsState(newNumericPropsState);
  };
  const handlerMaxValueValidationChange = (e: any) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.maxValueValidationType = e.target.value;
    newNumericPropsState.maxValueTypeErrorMessage = "";
    setNumericPropsState(newNumericPropsState);
  };
  const handlerMinValueNumericChange = (newMinValue: number) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.minValue = newMinValue;
    newNumericPropsState.minValueErrorMessage = "";
    setNumericPropsState(newNumericPropsState);
  };
  const handlerMaxValueNumericChange = (newMaxValue: number) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.maxValue = newMaxValue;
    newNumericPropsState.maxValueErrorMessage = "";
    setNumericPropsState(newNumericPropsState);
  };
  const handlerPercentageChange = () => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.percentage = !newNumericPropsState.percentage;
    newNumericPropsState.currencySymbolDisabled = newNumericPropsState.percentage;
    newNumericPropsState.currencyplacementDisabled = newNumericPropsState.percentage;
    newNumericPropsState.currencyPlacement = "";
    newNumericPropsState.currencySymbol = "";
    newNumericPropsState.currencyPlacementErrorMessage = newNumericPropsState.percentage
      ? ""
      : newNumericPropsState.currencyPlacementErrorMessage;
    setNumericPropsState(newNumericPropsState);
  };
  const handlerFixedDecimalPlacesChange = (e: any) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.fixedDecimal = e.target.value;
    setNumericPropsState(newNumericPropsState);
  };
  const handlerOnCurrencySymbolChange = (e: any) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.currencySymbol = e.target.value;
    newNumericPropsState.currencyPlacementErrorMessage = "";
    setNumericPropsState(newNumericPropsState);
  };
  const handlerPlacementChange = (e: any) => {
    let newNumericPropsState = { ...numericPropsState };
    newNumericPropsState.currencyPlacement = e.target.value;
    newNumericPropsState.currencyPlacementErrorMessage = "";
    setNumericPropsState(newNumericPropsState);
  };
  const handlerDecimalSeparatorChange = (e: any) => {
    const newDecimalSeparator = e.target.value;
    let newNumericPropsState = { ...numericPropsState };

    if (newDecimalSeparator === numericPropsState.thousandsSeparator) {
      newNumericPropsState.thousandsSeparatorErrorMessage = "Decimal and thousand separators cannot be the same.";
    } else {
      newNumericPropsState.decimalSeparator = newDecimalSeparator;
      newNumericPropsState.thousandsSeparatorErrorMessage = "";
    }

    setNumericPropsState(newNumericPropsState);
  };
  const handlerThousandsSeparatorChange = async (e: any) => {
    const newThousandsSeparator = e.target.value;
    let newNumericPropsState = {...numericPropsState};

    if (newThousandsSeparator === numericPropsState.decimalSeparator) {
      newNumericPropsState.thousandsSeparatorErrorMessage = await TPI18N.GetText(
          resourceSet,
          "DecimalAndThousandsSeparatorNotEqualsErrorMessage",
      );
    } else {
      newNumericPropsState.thousandsSeparator = newThousandsSeparator;
      newNumericPropsState.thousandsSeparatorErrorMessage = "";
    }

    setNumericPropsState(newNumericPropsState);
  };
  const handlerOpenTextTypeChange = (e: any) => {
    let newOpenTextState = { ...openTextPropsState };
    newOpenTextState.type = e.target.value;
    setOpenTextPropsState(newOpenTextState);
  };
  const handlerMaxLengthValueChange = (newMaxLength: number) => {
    let newOpenTextState = { ...openTextPropsState };
    newOpenTextState.maxLength = newMaxLength;
    newOpenTextState.maxLengthErrorMessage = "";
    setOpenTextPropsState(newOpenTextState);
  };
  const handlerOnInputMaskChange = (e: any) => {
    let newOpenTextState = { ...openTextPropsState };
    newOpenTextState.inputMask = e.target.value;
    newOpenTextState.inputMaskErrorMessage = "";
    setOpenTextPropsState(newOpenTextState);
  };
  const handlerOnCharactersWhiteListChange = (e: any) => {
    let newOpenTextState = { ...openTextPropsState };
    newOpenTextState.whiteList = e.target.value;
    newOpenTextState.whiteListErrorMessage = "";
    setOpenTextPropsState(newOpenTextState);
  };
  const handlerOnPlaceHolderChange = (e: any) => {
    let newOpenTextState = { ...openTextPropsState };
    newOpenTextState.placeHolder = e.target.value;
    setOpenTextPropsState(newOpenTextState);
  };
  const handlerOpenTextMultilineTypeChange = (e: any) => {
    let newOpenTextMultilineState = { ...openTextMultilinePropsState };
    newOpenTextMultilineState.type = e.target.value;
    setOpenTextMultilinePropsState(newOpenTextMultilineState);
  };
  const handlerMaxLengthMultilineValueChange = (newMaxLength: number) => {
    let newOpenTextMultilineState = { ...openTextMultilinePropsState };
    newOpenTextMultilineState.maxLength = newMaxLength;
    newOpenTextMultilineState.maxLengthErrorMessage = "";
    setOpenTextMultilinePropsState(newOpenTextMultilineState);
  };
  const handlerOnCharactersWhiteListMultilineChange = (e: any) => {
    let newOpenTextMultilineState = { ...openTextMultilinePropsState };
    newOpenTextMultilineState.whiteList = e.target.value;
    newOpenTextMultilineState.whiteListErrorMessage = "";
    setOpenTextMultilinePropsState(newOpenTextMultilineState);
  };
  const handlerRenderMethodChange = (e: any) => {
    let newYesNoState = { ...yesNoPropsState };
    newYesNoState.renderMethod = "";
    setYesNoPropsState(newYesNoState);
    newYesNoState.renderMethod = e.target.value;
    newYesNoState.renderMethodErrorMessage = "";
    if (newYesNoState.renderMethod.length > 0) {
      setIsYesNoError(false);
    }
    setYesNoPropsState(newYesNoState);
  };
  const handlerCaptionChange = (e: any) => {
    let newYesNoState = { ...yesNoPropsState };
    newYesNoState.caption = e.target.value;
    newYesNoState.captionErrorMessage = "";
    setYesNoPropsState(newYesNoState);
  };
  const handlerTreeIdChange = (e: any, topic: string) => {
    switch (topic) {
      case "listvalue": {
        let newListValueState = { ...listValuePropsState };
        newListValueState.treeId = e.target.value;
        newListValueState.treeIdErrorMessage = "";
        setListValuePropsState(newListValueState);

        let newselectedBranch: TPBranchSelectionValue = {
          ...selectedBranchListValue,
        };
        newselectedBranch.branchId = "";
        newselectedBranch.branchDescription = "";
        newselectedBranch.branchHierarchyDescription = "";
        setSelectedBranchListValue(newselectedBranch);
        break;
      }
      case "tree": {
        let newTreeState = { ...treePropsState };
        newTreeState.treeId = e.target.value;
        newTreeState.treeIdErrorMessage = "";
        setTreePropsState(newTreeState);

        let newselectedTreeBranch: TPBranchSelectionValue = {
          ...selectedTreeBranchListValue,
        };
        newselectedTreeBranch.branchId = "";
        newselectedTreeBranch.branchDescription = "";
        newselectedTreeBranch.branchHierarchyDescription = "";
        setSelectedTreeBranchListValue(newselectedTreeBranch);
        break;
      }
      default:
        break;
    }
  };

  const handleOnBranchChange = (
    index: number,
    newBranchId: string,
    newBranchDescription: string,
    newBranchHierarchyDescription: string
  ) => {
    let newListValueState = { ...listValuePropsState };
    newListValueState.branchSelectedErrorMessage = "";
    setListValuePropsState(newListValueState);

    let newSelectedBranchListValue: TPBranchSelectionValue = {
      ...selectedBranchListValue,
    };
    newSelectedBranchListValue.branchId = newBranchId;
    newSelectedBranchListValue.branchDescription = newBranchDescription;
    newSelectedBranchListValue.branchHierarchyDescription = newBranchHierarchyDescription;
    setSelectedBranchListValue(newSelectedBranchListValue);
  };

  const handlerListValueRenderMethodChange = (e: any) => {
    let newListValueState = { ...listValuePropsState };
    newListValueState.renderMethod = e.target.value;
    newListValueState.renderMethodErrorMessage = "";
    setListValuePropsState(newListValueState);
  };

  const handleOnTreeBranchChange = (
    index: number,
    newBranchId: string,
    newBranchDescription: string,
    newBranchHierarchyDescription: string
  ) => {
    let newTreeState = { ...treePropsState };
    newTreeState.parentIdErrorMessage = "";
    setTreePropsState(newTreeState);

    let newSelectedTreeBranchListValue: TPBranchSelectionValue = {
      ...selectedTreeBranchListValue,
    };
    newSelectedTreeBranchListValue.branchId = newBranchId;
    newSelectedTreeBranchListValue.branchDescription = newBranchDescription;
    newSelectedTreeBranchListValue.branchHierarchyDescription = newBranchHierarchyDescription;
    setSelectedTreeBranchListValue(newSelectedTreeBranchListValue);
  };

  const handleDateFormatsAdditionalData = () => {
    let list = TPGlobal.dateFormatsAdditionalData;
    let item = {
      key: "0",
      value: TPGlobal.dateTimeFormat,
      value2: TPGlobal.dateTimeFormat,
    } as TPKeyValue;
    if (list.find((s) => s.key == "0") == undefined) list.push(item);
    return list;
  };


  const renderSwitch = (param: string) => {
    return null;
  };

  const renderFirtsRowOpenTextType = (param: string) => {
    switch (param) {
      case "Open":
        return (
          <div className="row">
            <div className="col-10">
              <TPRow gridTemplateColumnsValue={"1fr"} paddingRight={"25px"}>
                <TPNumeric
                  id="max-length-value-numeric"
                  onChange={handlerMaxLengthValueChange}
                  value={openTextPropsState.maxLength}
                  labelText={maximumCharactersLabel}
                  errorMessage={openTextPropsState.maxLengthErrorMessage}
                />
              </TPRow>
            </div>
          </div>
        );
      case "WhiteList":
        return (
          <div className="row">
              <div className="col-10">
                <TPRow gridTemplateColumnsValue={"1fr"} paddingRight={"25px"}>
                  <TPNumeric
                    id="max-length-value-numeric"
                    onChange={handlerMaxLengthValueChange}
                    value={openTextPropsState.maxLength}
                    labelText={maximumCharactersLabel}
                    errorMessage={openTextPropsState.maxLengthErrorMessage}
                  ></TPNumeric>
                  <TPTextBox
                    id="white-list-input"
                    onChange={handlerOnCharactersWhiteListChange}
                    value={openTextPropsState.whiteList}
                    labelText={charactersWhiteListLabel}
                    isMandatory={true}
                    isInvalidChars={false}
                    errorMessage={openTextPropsState.whiteListErrorMessage}
                    placeholder={enterAValueLabel}
                  ></TPTextBox>
                </TPRow>
              </div>
            </div>
        );
      case "InputMask":
        return (
          <div className="row">
            <TPRow gridTemplateColumnsValue={"1fr"} paddingRight={"25px"}>
              <TPTextBox
                id="input-mask-text"
                onChange={handlerOnInputMaskChange}
                value={openTextPropsState.inputMask}
                labelText={inputMaskLabel}
                isMandatory={true}
                errorMessage={openTextPropsState.inputMaskErrorMessage}
                placeholder={enterAValueLabel}
              ></TPTextBox>
            </TPRow>
            <TPRow gridTemplateColumnsValue={"1fr"} paddingRight={"25px"}>
              <TPSelect
                id="placeholder"
                onChange={handlerOnPlaceHolderChange}
                dataSource={[
                  { key: " ", value: "" },
                  { key: "LP", value: "--" },
                ]}
                value={openTextPropsState.placeHolder}
                labelText={placeholderLabel}
                isHorizontal={false}
                placeholder={selectAValueLabel}
              ></TPSelect>
            </TPRow>
          </div>
        );
      default:
        break;
    }
  };
  const renderFirtsRowOpenTextMultilineType = (param: string) => {
    switch (param) {
      case "Open":
        return (
          <TPRow gridTemplateColumnsValue={"1fr"} paddingRight={"25px"}>
            <TPNumeric
              id="max-multi-length-numeric"
              onChange={handlerMaxLengthMultilineValueChange}
              value={openTextMultilinePropsState.maxLength}
              labelText={maximumCharactersLabel}
              errorMessage={openTextMultilinePropsState.maxLengthErrorMessage}
            ></TPNumeric>
          </TPRow>
        );
      case "WhiteList":
        return (
          <div className="col-10">
            <TPRow paddingRight={"25px"}>
              <TPNumeric
                id="max-length-multi-text"
                onChange={handlerMaxLengthMultilineValueChange}
                value={openTextMultilinePropsState.maxLength}
                labelText={maximumCharactersLabel}
                errorMessage={openTextMultilinePropsState.maxLengthErrorMessage}
              ></TPNumeric>
              <TPTextBox
                id="white-list-multi-text"
                onChange={handlerOnCharactersWhiteListMultilineChange}
                value={openTextMultilinePropsState.whiteList}
                labelText={charactersWhiteListLabel}
                isMandatory={true}
                errorMessage={openTextMultilinePropsState.whiteListErrorMessage}
                placeholder={enterAValueLabel}
              ></TPTextBox>
            </TPRow>
          </div>
        );

      default:
        break;
    }
  };

  const renderMinMaxValues = (toRender: any) => {
    const jsxMin = (
      <TPNumeric
        id="min-value-numeric"
        onChange={handlerMinValueNumericChange}
        value={numericPropsState.minValue}
        labelText={minimumValueLabel}
        isMandatory={numericPropsState.minValueValidationType === "VAL"}
        errorMessage={numericPropsState.minValueErrorMessage}
      ></TPNumeric>
    );
    const jsxMax = (
      <TPNumeric
        id="max-value-numeric"
        onChange={handlerMaxValueNumericChange}
        value={numericPropsState.maxValue}
        labelText={maximumValueLabel}
        isMandatory={numericPropsState.maxValueValidationType === "VAL"}
        errorMessage={numericPropsState.maxValueErrorMessage}
      ></TPNumeric>
    );
    let jsxToReturn;
    switch (toRender) {
      case "min":
        jsxToReturn = <TPRow paddingRight={"25px"}>{jsxMin}</TPRow>;
        break;
      case "max":
        jsxToReturn = <TPRow paddingRight={"25px"}>{jsxMax}</TPRow>;
        break;
      case "both":
        jsxToReturn = (
          <TPRow paddingRight={"25px"}>
            {jsxMin}
            {jsxMax}
          </TPRow>
        );
        break;
      default:
        break;
    }
    return jsxToReturn;
  };

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQADDA
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} updatetFunction ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  useEffect(() => {
    loadResourcesAndLoadAdditionalDataInfo();
    mode === "Insert" && generalAutomaticId();
  }, []);

  const languageValuesMap: Record<string, string> = {};

  insertUpdateState.recordLanguageList.forEach((lang) => {
    languageValuesMap[lang.key] = lang.value;
  });

  const getFragmentByType = function(dataType: string) {
    switch (dataType) {
      case AdditionalDataTypeEnum.date:
        return (
          <TPColumn>
            <div className="col-5">
              <TPPageSectionTitle>{configurationSectionLabel}</TPPageSectionTitle>
            </div>
            <div className="row">
              <div className="col-10 ">
                <TPPageSection>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <TPRadioGroup
                          id="additional-data"
                          value={datePropsState.type}
                          onChange={handlerDateTypeChange}
                          isHorizontal={false}
                          source={[
                            {
                              key: AdditionalDataDateTypeEnum.anyDate,
                              value: anyDateItemLabel,
                            },
                            {
                              key: AdditionalDataDateTypeEnum.dateRange,
                              value: dateRangeItemLabel,
                            },
                          ]}
                          labelText={dateTypeLabel}
                        ></TPRadioGroup>
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </div>
            </div>
            {datePropsState.type == AdditionalDataDateTypeEnum.dateRange && (
              <>
                <div className="row mt-3">
                  <div className="col-10 ">
                    <TPPageSection>
                      <div className="row">
                        <div className="col-12 d-flex gap-3">
                          <div className="form-group">
                            <TPSelect
                              id="min-date"
                              isMandatory={false}
                              onChange={handleMinDateTypeChange}
                              dataSource={[
                                { key: "fixed", value: fixedValueLabel },
                                {
                                  key: "variable",
                                  value: variableValueLabel,
                                },
                              ]}
                              value={datePropsState.minDateType}
                              labelText={minDateLabel}
                              placeholder={selectAValueLabel}
                            ></TPSelect>
                          </div>
                          {datePropsState.minDateType == "variable" ? (
                            <>
                              <div className="form-group">
                                <TPSelect
                                  id="min-date-symbol"
                                  isMandatory={false}
                                  onChange={handleMinDateSymbolChange}
                                  dataSource={[
                                    { key: "+", value: "+" },
                                    { key: "-", value: "-" },
                                  ]}
                                  value={datePropsState.minDateSymbol}
                                  labelText={todayDateLabel}
                                  placeholder={selectAValueLabel}
                                ></TPSelect>
                              </div>
                              <div className="form-group">
                                <TPNumeric
                                  id="min-date-numeric"
                                  onChange={handleMinDateChange}
                                  value={datePropsState.minDateDays}
                                  labelText={daysDateLabel}
                                ></TPNumeric>
                              </div>
                            </>
                          ) : (
                            <div className="form-group">
                              <TPDatePicker
                                labelText={minimumDateValueLabel}
                                isMandatory={true}
                                onChangeDate={handleMinDateValueChange}
                                customDateFormat={TPGlobal.dateTimeFormat}
                                selectedDate={datePropsState.minDate}
                                errorMessage={datePropsState.minDateErrorMessage}
                                maxDate={datePropsState.maxDate}
                              ></TPDatePicker>
                            </div>
                          )}
                        </div>
                      </div>
                    </TPPageSection>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 ">
                    <TPPageSection>
                      <div className="row">
                        <div className="col-12 d-flex gap-3">
                          <div className="form-group">
                            <TPSelect
                              id="max-date"
                              isMandatory={false}
                              onChange={handleMaxDateTypeChange}
                              dataSource={[
                                { key: "fixed", value: fixedValueLabel },
                                {
                                  key: "variable",
                                  value: variableValueLabel,
                                },
                              ]}
                              value={datePropsState.maxDateType}
                              labelText={maxDateLabel}
                              placeholder={selectAValueLabel}
                            ></TPSelect>
                          </div>
                          {datePropsState.maxDateType == "variable" ? (
                            <>
                              <div className="form-group">
                                <TPSelect
                                  id="max-date-symbol"
                                  isMandatory={false}
                                  onChange={handleMaxDateSymbolChange}
                                  dataSource={[
                                    { key: "+", value: "+" },
                                    { key: "-", value: "-" },
                                  ]}
                                  value={datePropsState.maxDateSymbol}
                                  labelText={todayDateLabel}
                                  placeholder={selectAValueLabel}
                                ></TPSelect>
                              </div>
                              <div className="form-group">
                                <TPNumeric
                                  id="max-date-numeric"
                                  onChange={handleMaxDateChange}
                                  value={datePropsState.maxDateDays}
                                  labelText={daysDateLabel}
                                ></TPNumeric>
                              </div>
                            </>
                          ) : (
                            <div className="form-group">
                              <TPDatePicker
                                labelText={maximumDateValueLabel}
                                isMandatory={true}
                                onChangeDate={handleMaxDateValueChange}
                                customDateFormat={TPGlobal.dateTimeFormat}
                                selectedDate={datePropsState.maxDate}
                                errorMessage={datePropsState.maxDateErrorMessage}
                                minDate={datePropsState.minDate}
                              ></TPDatePicker>
                            </div>
                          )}
                        </div>
                      </div>
                    </TPPageSection>
                  </div>
                </div>
              </>
            )}

            <div className="col-10">
              <TPRow gridTemplateColumnsValue={"1fr"} maxWidth={"68%"} paddingRight={"23px"}>
                <TPSelect
                  id="placeholder-and-format-date"
                  onChange={(e: any) => {
                    let temp = { ...complementsRenderTP };
                    if (temp) {
                      let date = handleDateFormatsAdditionalData().find(
                        (s) => s.key == e.target.value
                      );
                      let newDatePropsState: DateProps = {
                        ...datePropsState,
                      };

                      if (date) {
                        temp.placeholder = date.value;
                        newDatePropsState.formatDate = date.key;
                        setDatePropsState(newDatePropsState);
                        setComplementsRenderTP(temp);
                      }
                    }
                  }}
                  disabled={mode != "Insert"}
                  dataSource={handleDateFormatsAdditionalData()}
                  value={datePropsState.formatDate}
                  labelText={formatDateLabel}
                  isHorizontal={false}
                  selectStyle={{}}
                  placeholder={selectAValueLabel}
                  isMandatory
                ></TPSelect>
                <br></br>
              </TPRow>
            </div>
          </TPColumn>
        )
      case AdditionalDataTypeEnum.integerlist:
        return (
          <TPColumn>
            <div className="col-10">
              <TPPageSectionTitle>{configurationSectionLabel}</TPPageSectionTitle>
            </div>

            <div className="col-10">
              <TPRow paddingRight={"25px"}>
                <TPNumeric
                  id="initial-value-numeric"
                  onChange={handlerInitialValueChange}
                  value={integerListPropsState.initialValue}
                  labelText={initialValueLabel}
                  errorMessage={integerListPropsState.initialValueErrorMessage}
                ></TPNumeric>

                <TPNumeric
                  id="end-value-numeric"
                  onChange={handlerEndValueChange}
                  value={integerListPropsState.endValue}
                  labelText={endValueLabel}
                  onKeyDownCallBack={(e: any) => {}}
                ></TPNumeric>
              </TPRow>
            </div>
          </TPColumn>
        )
      case AdditionalDataTypeEnum.link:
        return (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>{configurationSectionLabel}</TPPageSectionTitle>
              </div>
            </div>
            <div className="row">
              <div className="col-10">
                <TPPageSection>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPTextArea
                          id="url-text-area"
                          isMandatory={true}
                          onChange={handlerOnUrlChange}
                          value={linkPropsState.url}
                          rows={3}
                          columns={4}
                          labelText={urlLabel}
                          errorMessage={linkPropsState.urlErrorMessage}
                          placeholder={enterAValueLabel}
                        ></TPTextArea>
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </div>
            </div>
          </>
        )
      case AdditionalDataTypeEnum.phone:
        return (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>{configurationSectionLabel}</TPPageSectionTitle>
              </div>
            </div>
            <div className="row">
              <div className="col-10">
                <TPColumn>
                  <TPRow gridTemplateColumnsValue={"1fr"} paddingRight={"25px"}>
                    <TPSelect
                      id="validation-type"
                      isMandatory={true}
                      onChange={handleValidationTypeChange}
                      dataSource={[
                        { key: "BASIC", value: basicLabel },
                        { key: "ADVANCE", value: advanceLabel },
                      ]}
                      value={phonePropsState.validationType}
                      labelText={validationTypeLabel}
                      isHorizontal={false}
                      placeholder={selectAValueLabel}
                    ></TPSelect>
                  </TPRow>
                  {phonePropsState.validationType === "BASIC" ? (
                    <TPRow gridTemplateColumnsValue={"1fr 1fr 1fr"} paddingRight={"25px"}>
                      <TPNumeric
                        id="minimum-characters-numeric"
                        onChange={handlerOnMinimumValueChange}
                        value={phonePropsState.minimumCharacters}
                        labelText={minimumCharactersLabel}
                        errorMessage={phonePropsState.minimumCharactersErrorMessage}
                      ></TPNumeric>

                      <TPNumeric
                        id="maximum-characters-numeric"
                        onChange={handlerOnMaximumValueChange}
                        value={phonePropsState.maximumCharacters}
                        labelText={maximumCharactersLabel}
                      ></TPNumeric>

                      <TPTextBox
                        id="whitelist-characters-text"
                        onChange={handlerOnWhiteListChange}
                        value={phonePropsState.charactersWhiteList}
                        labelText={charactersWhiteListLabel}
                        isMandatory={true}
                        errorMessage={phonePropsState.charactersWhiteListErrorMessage}
                        placeholder={enterAValueLabel}
                      ></TPTextBox>
                    </TPRow>
                  ) : (
                    <TPRow gridTemplateColumnsValue={"1fr"} paddingRight={"25px"}>
                      <TPTextBox
                        id="regular-expression-text"
                        onChange={handlerOnRegularExpresionChange}
                        value={phonePropsState.regularExpresion}
                        labelText={regularExpressionLabel}
                        isMandatory={true}
                        errorMessage={phonePropsState.regularExpresionErrorMessage}
                        placeholder={enterAValueLabel}
                      ></TPTextBox>
                    </TPRow>
                  )}
                </TPColumn>
              </div>
            </div>
            {renderSwitch(insertUpdateState.idAdditionalDataType)}
          </>
        )
      case AdditionalDataTypeEnum.numeric:
        return (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>{configurationSectionLabel}</TPPageSectionTitle>
              </div>
            </div>
            <div className="row">
              <div className="col-10">
                <TPColumn>
                  <TPRow paddingRight={"25px"}>
                    <TPSelect
                      id="min-value"
                      isMandatory={true}
                      onChange={handlerMinValueValidationChange}
                      dataSource={[
                        { key: "", value: "--" },
                        { key: "IN", value: naLabel },
                        { key: "VAL", value: valueLabel },
                      ]}
                      value={numericPropsState.minValueValidationType}
                      labelText={minimumValueTypeLabel}
                      isHorizontal={false}
                      errorMessage={numericPropsState.minValueTypeErrorMessage}
                      placeholder={selectAValueLabel}
                    ></TPSelect>
                    <TPSelect
                      id="max-value"
                      isMandatory={true}
                      onChange={handlerMaxValueValidationChange}
                      dataSource={[
                        { key: "", value: "--" },
                        { key: "IN", value: naLabel },
                        { key: "VAL", value: valueLabel },
                      ]}
                      value={numericPropsState.maxValueValidationType}
                      labelText={maximumValueTypeLabel}
                      isHorizontal={false}
                      errorMessage={numericPropsState.maxValueTypeErrorMessage}
                      placeholder={selectAValueLabel}
                    ></TPSelect>
                  </TPRow>
                  {numericPropsState.minValueValidationType === "VAL" &&
                    numericPropsState.maxValueValidationType !== "VAL" &&
                    renderMinMaxValues("min")}
                  {numericPropsState.minValueValidationType !== "VAL" &&
                    numericPropsState.maxValueValidationType === "VAL" &&
                    renderMinMaxValues("max")}
                  {numericPropsState.minValueValidationType === "VAL" &&
                    numericPropsState.maxValueValidationType === "VAL" &&
                    renderMinMaxValues("both")}
                  <div className="row">
                    <div className="col-6 mt-3">
                      <div className="form-group">
                        <TPCheckBox
                          id="percentage-text"
                          labelText={percentageLabel}
                          checked={numericPropsState.percentage}
                          onChange={(e: any) => handlerPercentageChange()}
                        ></TPCheckBox>
                      </div>
                    </div>
                  </div>
                  <TPRow paddingRight={"25px"}>
                    <TPTextBox
                      id="currency-symbol-text"
                      onChange={handlerOnCurrencySymbolChange}
                      value={numericPropsState.currencySymbol}
                      labelText={currencySymbolLabel}
                      maxLength={3}
                      disabled={numericPropsState.currencySymbolDisabled}
                      placeholder={enterAValueLabel}
                    ></TPTextBox>
                    <TPSelect
                      id="currency-placement"
                      isMandatory={Boolean(numericPropsState.currencySymbol)}
                      onChange={handlerPlacementChange}
                      dataSource={[
                        { key: "", value: ".." },
                        { key: "Before", value: beforeLabel },
                        { key: "After", value: afterLabel },
                      ]}
                      value={numericPropsState.currencyPlacement}
                      labelText={currencyPlacementLabel}
                      isHorizontal={false}
                      disabled={numericPropsState.currencyplacementDisabled}
                      errorMessage={numericPropsState.currencyPlacementErrorMessage}
                      placeholder={selectAValueLabel}
                    ></TPSelect>
                  </TPRow>
                  <TPRow paddingRight={"25px"}>
                    <TPSelect
                      id="decimal-places"
                      isMandatory={true}
                      onChange={handlerFixedDecimalPlacesChange}
                      dataSource={[
                        { key: "None", value: noneLabel },
                        { key: "0", value: "0" },
                        { key: "1", value: "1" },
                        { key: "2", value: "2" },
                        { key: "3", value: "3" },
                        { key: "4", value: "4" },
                        { key: "5", value: "5" },
                        { key: "6", value: "6" },
                      ]}
                      value={numericPropsState.fixedDecimal}
                      labelText={fixedDecimalPlacesLabel}
                      isHorizontal={false}
                      placeholder={selectAValueLabel}
                    ></TPSelect>
                    <TPSelect
                      id="decimal-separator"
                      isMandatory={true}
                      onChange={handlerDecimalSeparatorChange}
                      dataSource={[
                        { key: "", value: "--" },
                        { key: ".", value: "." },
                        { key: ",", value: "," },
                      ]}
                      value={numericPropsState.decimalSeparator}
                      labelText={decimalSeparatorLabel}
                      isHorizontal={false}
                      placeholder={selectAValueLabel}
                    ></TPSelect>
                  </TPRow>
                  <TPRow paddingRight={"25px"}>
                    <TPSelect
                      id="thousand-separator"
                      isMandatory={true}
                      onChange={handlerThousandsSeparatorChange}
                      dataSource={[
                        { key: "", value: noneLabel },
                        { key: ".", value: "." },
                        { key: ",", value: "," },
                        { key: " ", value: spaceLabel },
                      ]}
                      value={numericPropsState.thousandsSeparator}
                      labelText={thousandsSeparatorLabel}
                      isHorizontal={false}
                      errorMessage={numericPropsState.thousandsSeparatorErrorMessage}
                      placeholder={selectAValueLabel}
                    ></TPSelect>
                  </TPRow>
                </TPColumn>
              </div>
            </div>
            {renderSwitch(insertUpdateState.idAdditionalDataType)}
          </>
        )
      case AdditionalDataTypeEnum.textbox:
        return (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>{configurationSectionLabel}</TPPageSectionTitle>
              </div>
            </div>
            <div className="row ">
              <div className="col justify-content-center">
                <TPPageSection>
                  <div className="form-group">
                    <TPRadioGroup
                      id="state"
                      value={openTextPropsState.type}
                      onChange={handlerOpenTextTypeChange}
                      isHorizontal={false}
                      source={[
                        { key: "Open", value: openLabel },
                        { key: "WhiteList", value: whiteListLabel },
                      ]}
                      labelText={textboxTypeLabel}
                    ></TPRadioGroup>
                  </div>
                </TPPageSection>
              </div>
            </div>
            {renderFirtsRowOpenTextType(openTextPropsState.type)}
          </>
        )
      case AdditionalDataTypeEnum.textarea:
        return (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>{configurationSectionLabel}</TPPageSectionTitle>
              </div>
            </div>
            <div className="col-10">
              <TPColumn>
                <TPRow paddingRight={"25px"}>
                  <TPRadioGroup
                    id="multiline"
                    value={openTextMultilinePropsState.type}
                    onChange={handlerOpenTextMultilineTypeChange}
                    isHorizontal={false}
                    source={[
                      { key: "Open", value: openLabel },
                      { key: "WhiteList", value: whiteListLabel },
                    ]}
                    labelText={textboxTypeLabel}
                  ></TPRadioGroup>
                </TPRow>
                {renderFirtsRowOpenTextMultilineType(openTextMultilinePropsState.type)}
              </TPColumn>
            </div>
          </>
        )
      case AdditionalDataTypeEnum.yesno:
        return (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>{configurationSectionLabel}</TPPageSectionTitle>
              </div>
            </div>
            <div className="col-10">
              <TPRow paddingRight={"25px"}>
                <TPSelect
                  id="render-method"
                  isMandatory={true}
                  onChange={handlerRenderMethodChange}
                  dataSource={[
                    { key: "", value: ".." },
                    { key: "Combobox", value: comboboxLabel },
                    { key: "Option", value: optionLabel },
                    { key: "Checkbox", value: checkboxLabel },
                  ]}
                  value={yesNoPropsState.renderMethod}
                  errorMessage={yesNoPropsState.renderMethodErrorMessage}
                  labelText={controlLabel}
                  className={isYesNoError ? "is-invalid" : ""}
                  placeholder={selectAValueLabel}
                ></TPSelect>
                <TPSelect
                  id="caption"
                  isMandatory={true}
                  onChange={handlerCaptionChange}
                  dataSource={[
                    { key: "", value: ".." },
                    { key: "YesNoOption", value: yesNoLabel },
                    { key: "TrueFalseOption", value: trueFalseLabel },
                  ]}
                  value={yesNoPropsState.caption}
                  errorMessage={yesNoPropsState.captionErrorMessage}
                  labelText={captionLabel}
                  placeholder={selectAValueLabel}
                ></TPSelect>
              </TPRow>
            </div>
          </>
        )
      case AdditionalDataTypeEnum.listvalue:
        return (
          <TPColumn>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>{configurationSectionLabel}</TPPageSectionTitle>
              </div>
            </div>

            <div className="col-10">
              <TPRow paddingRight={"25px"}>
                <TPSelect
                  id="tree"
                  isMandatory={true}
                  onChange={(e: any) => {
                    let newListValueState = { ...listValuePropsState };
                    newListValueState.renderMethod = "";
                    setListValuePropsState(newListValueState);
                    handlerTreeIdChange(e, "listvalue");
                  }}
                  dataSource={treeSourceList}
                  value={listValuePropsState.treeId}
                  errorMessage={listValuePropsState.treeIdErrorMessage}
                  labelText={treeLabel}
                  placeholder={selectAValueLabel}
                ></TPSelect>
                <div>
                  <TPBranchSelection2
                    isMandatory={true}
                    labelText={parentIdLabel}
                    treeId={listValuePropsState.treeId}
                    modalAcceptLabel={modalAcceptLabel}
                    modalCancelLabel={modalCancelLabel}
                    modalSelectedBranchLabel={modalSelectedBranchLabel}
                    modalTitle={modalTitleLabel}
                    mustSelectLastLevelBranch={false}
                    value={selectedBranchListValue}
                    onChange={(
                      branchId: string,
                      branchDescription: string,
                      branchHierachyDescription: string
                    ) => {
                      let newListValueState = { ...listValuePropsState };
                      newListValueState.renderMethod = "";
                      setListValuePropsState(newListValueState);
                      handleOnBranchChange(
                        1,
                        branchId,
                        branchDescription,
                        branchHierachyDescription
                      );
                    }}
                    emptyLabel={emptyLabel}
                    errorMessage={listValuePropsState.branchSelectedErrorMessage}
                    onIsLoadingProgress={(value: boolean) => {
                      setIsLoadingScreen(value);
                    }}
                  ></TPBranchSelection2>
                </div>
              </TPRow>
            </div>
            <div className="col-10">
              <TPRow gridTemplateColumnsValue={"1fr"} paddingRight={"25px"}>
                <TPSelect
                  id="render-value-method"
                  isMandatory={true}
                  onChange={handlerListValueRenderMethodChange}
                  dataSource={[
                    { key: "", value: ".." },
                    { key: "Combobox", value: comboboxLabel },
                    { key: "Option", value: optionLabel },
                    { key: "Checkbox", value: checkboxLabel },
                  ]}
                  value={listValuePropsState.renderMethod}
                  errorMessage={listValuePropsState.renderMethodErrorMessage}
                  labelText={controlLabel}
                  placeholder={selectAValueLabel}
                ></TPSelect>
              </TPRow>
            </div>
          </TPColumn>
        )
      case AdditionalDataTypeEnum.tree:
        return (
          <>
            <div className="row">
              <div className="col-5">
                <TPPageSectionTitle>{configurationSectionLabel}</TPPageSectionTitle>
              </div>
            </div>
            <div className="row">
              <div className="col-10">
                <TPRow paddingRight={"25px"}>
                  <TPSelect
                    id="tree-id"
                    isMandatory={true}
                    onChange={(e: any) => handlerTreeIdChange(e, "tree")}
                    dataSource={treeSourceList}
                    value={treePropsState.treeId}
                    errorMessage={treePropsState.treeIdErrorMessage}
                    labelText={treeLabel}
                    placeholder={selectAValueLabel}
                  ></TPSelect>

                  <div>
                    <TPBranchSelection2
                      isMandatory={true}
                      labelText={parentIdLabel}
                      treeId={treePropsState.treeId}
                      modalAcceptLabel={modalAcceptLabel}
                      modalCancelLabel={modalCancelLabel}
                      modalSelectedBranchLabel={modalSelectedBranchLabel}
                      modalTitle={modalTitleLabel}
                      mustSelectLastLevelBranch={false}
                      value={selectedTreeBranchListValue}
                      onChange={(
                        branchId: string,
                        branchDescription: string,
                        branchHierachyDescription: string
                      ) => {
                        handleOnTreeBranchChange(
                          1,
                          branchId,
                          branchDescription,
                          branchHierachyDescription
                        );
                      }}
                      emptyLabel={emptyLabel}
                      errorMessage={treePropsState.parentIdErrorMessage}
                      onIsLoadingProgress={(value: boolean) => {
                        setIsLoadingScreen(value);
                      }}
                    ></TPBranchSelection2>
                  </div>
                </TPRow>
              </div>
            </div>
          </>
        )
      default:
        return <></>;
    }
  }

  return (
    <TPLoadingOverlay active={isLoadingScreen}>
      <div className="row">
        <div className="col-10">
          <TPPageTitle>{titleLabel}</TPPageTitle>
        </div>
      </div>
      <TPColumn gap={"24px"}>
        <div className="row">
          <div className="col-10">
            <TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <TPPageSection>
              <TPRow>
                <TPTextBox
                  id="record-id-text"
                  labelText={idLabel}
                  isMandatory={true}
                  value={mode === "Insert" ? referenceId : recordId}
                  onChange={
                    mode === "Insert"
                      ? (e: any) => handleOnIdAdditionalDataChange(e.target.value)
                      : () => {}
                  }
                  maxLength={20}
                  disabled
                  placeholder={enterAValueLabel}
                  errorMessage={insertUpdateState.idErrorMessage}
                />
                <MultilingualTextBox
                  ref={nameInputRef}
                  style={{ width: "calc(100% + 35px)" }}
                  value={insertUpdateState.recordLanguageList?.map(({ key, value }) => ({
                    id: key,
                    value,
                  }))}
                  onChange={(value) => handleDescriptionChange(value)}
                  onChangeLanguages={values => handleLanguageListChange(values)}
                />
              </TPRow>
            </TPPageSection>
            <TPModalQuestion
              title={"Confirm"}
              yesLabel={"Accept"}
              noLabel={"Cancel"}
              question={"Are you sure you want to create this new Additional Data?"}
              callBackAnswer={handleCallBackModalConfirm}
              callBackData={""}
              isShown={isModalVisible}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <TPRow gridTemplateColumnsValue={"1fr"} paddingRight={"23px"}>
              <TPSelect
                id="category"
                isMandatory={true}
                onChange={handleAdditionalDataCategoryChange}
                dataSource={additionalDataCategoryList}
                value={insertUpdateState.idAdditionalDataCategory}
                labelText={additionalDataCategoryLabel}
                errorMessage={insertUpdateState.idAdditionalDataCategoryErrorMessage}
                containerStyle={{}}
                placeholder={selectAValueLabel}
              ></TPSelect>
            </TPRow>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <TPRow gridTemplateColumnsValue={"1fr"} paddingRight={"23px"}>
              <TPTextArea
                id="additional-comments-area"
                labelText={additionalDataCommentsLabel}
                value={insertUpdateState.comments}
                onChange={(e: any) => handleAdditionalDataCommentsChange(e.target.value)}
                maxLength={500}
                errorMessage={insertUpdateState.commentsErrorMessage}
                rows={3}
                columns={7}
                placeholder={enterAValueLabel}
              ></TPTextArea>
            </TPRow>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <TPRow gridTemplateColumnsValue={"1fr"} maxWidth={"70%"} paddingRight={"23px"}>
              <TPSelect
                id="type"
                isMandatory={true}
                onChange={handleAdditionalDataTypeChange}
                dataSource={dataAddaTypeListState}
                value={insertUpdateState.idAdditionalDataType}
                labelText={additionalDataTypeLabel}
                errorMessage={insertUpdateState.idAdditionalDataTypeErrorMessage}
                containerStyle={{}}
                placeholder={selectAValueLabel}
              ></TPSelect>
            </TPRow>

            {conditionIsValidByType() &&
              insertUpdateState.idAdditionalDataCategory ===
                AdditionalDataCategoryEnum.S_CUSTCONT &&
              insertUpdateState.idAdditionalDataType && (
                <TPRow gridTemplateColumnsValue={"1fr"} paddingRight={"23px"}>
                  <TPSelect
                    id="target-table"
                    isMandatory={true}
                    onChange={handlerTargetTableChange}
                    dataSource={targetTableListState}
                    value={targetTableSelectedValue}
                    labelText={targetTableLabel}
                    errorMessage={targetTableErrorMessage}
                    placeholder={selectAValueLabel}
                  />
                </TPRow>
              )}
          </div>
        </div>
        {handleValidateControlType() && (
          <div className="col-10">
            <TPRow gridTemplateColumnsValue={"1fr"} paddingRight={"23px"}>
              <TPTextBox
                id="placeholder-value"
                isMandatory={false}
                onChange={(e: any) => {
                  let temp = { ...complementsRenderTP };
                  if (temp) {
                    temp.placeholder = e.target.value;
                    setComplementsRenderTP(temp);
                  }
                }}
                value={complementsRenderTP.placeholder}
                labelText={placeholderLabel}
                placeholder={enterAValueLabel}
                errorMessage={""}
              ></TPTextBox>
            </TPRow>
          </div>
        )}
        <TPRow>
          <TPCheckBox
            id="modify-data-check"
            labelText={enableEditingModifyBasicDataLabel}
            checked={insertUpdateState.enableEditingModifyBasicData}
            onChange={(e: any) => handleEnableEditingModifyBasicDataChange()}
          ></TPCheckBox>
        </TPRow>
        <TPRow>
          <TPCheckBox
            id="show-as-active"
            labelText={showAsActiveLabel}
            checked={showAsActive}
            onChange={(e: any) => {
              setShowAsActive(!showAsActive);
            }}
          ></TPCheckBox>
        </TPRow>
        {getFragmentByType(insertUpdateState.idAdditionalDataType)}
      </TPColumn>
      <br></br>
      <TPColumn>
        <div className="col-10">
          <TPPageSectionTitle style={{ textTransform: "capitalize", marginBottom: "24px" }}>
            {previewLabel}
          </TPPageSectionTitle>
        </div>
      </TPColumn>
      <div className="col-10">
        <TPRow gridTemplateColumnsValue={"1fr"} maxWidth={"68%"} paddingRight={"23px"}>
          {isDisablePreview(insertUpdateStatePreview) && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <TPAdditionalDataPreview
                additionalData={insertUpdateStatePreview}
                complementsRender={complementsRenderTP}
              />
            </div>
          )}
        </TPRow>
        <br></br>
      </div>
      <TPRow
        gridTemplateColumnsValue={"1fr 140px 140px"}
        maxWidth={"68%"}
        paddingRight={"23px"}
        minWidth={"100%"}
        gap={"8px"}
      >
        <div></div>
        <TPButton
          id="cancel"
          type={TPButtonTypes.empty}
          onClick={handleCancelButtonClick}
          isDesignSystem={false}
          style={{
            border: "1px solid rgb(120, 0, 150)",
            color: "rgb(120, 0, 150)",
            padding: "11px 16px 11px 16px;",
            borderRadius: "4px",
          }}
        >
          {labelsButton?.backButton ?? cancelButtonLabel}
        </TPButton>
        <TPButton
          id="save-additional-data"
          isDesignSystem={true}
          type={TPButtonTypes.primary}
          onClick={openModalAndInsert}
          style={
            isDisable()
              ? {
                  borderRadius: "4px",
                  padding: "11px 16px 11px 16px;",
                  border: "1px solid rgb(223, 223, 223)",
                }
              : {
                  borderRadius: "4px",
                  padding: "11px 16px 11px 16px;",
                  border: "1px solid rgb(120, 0, 150)",
                }
          }
          disabled={callBackResult2 ? !isVisible() : isDisable()}
        >
          {labelsButton?.createButton ?? saveButtonLabel}
        </TPButton>
      </TPRow>
      <br></br>
    </TPLoadingOverlay>
  );
};

export default AdditionalDataInsertUpdate;
