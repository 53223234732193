import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";

export type OutboundMailAccountInputDTO = {
  idAccount: string;
  description: string;
  ipServer: string;
  fromAddress: string;
  fromName: string;
  user: string;
  password: string;
  isEnableSsl: boolean;
  port: number | null;
  emailAddressBcc: string;
  emailAddressReplyTo: string;
  timeNotRepeatMail: number | null;
  maxTries: number | null;
  timeBetweenRetries: number | null;
};

export class OutboundMailAccountInputDTOValidator extends Validator<OutboundMailAccountInputDTO> {
  constructor() {
    super();

    const resourceSet = "OutboundMailAccountInsertUpdateComponent";

    try {
      this.ruleFor("idAccount")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) === id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("description")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTODescriptionEmpty")
        .must((description) => {
          return TPGlobal.TPSanitize(description) === description;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .maxLength(200)
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      this.ruleFor("ipServer")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIpServerEmpty")
        .must((ipServer) => {
          return TPGlobal.TPSanitize(ipServer) === ipServer;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidipServerChar",
        )
        // .must((ipServer) => {
        //   return TPGlobal.validateIPv4Address(ipServer);
        // })
        // .withMessage(resourceSet + "|InputDTOInvalidipServer")
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidipServerLength");

      this.ruleFor("port")
        .notNull()
        .withMessage(resourceSet + "|InputDTOPortEmpty")
        .greaterThanOrEqualTo(1)
        .withMessage(resourceSet + "|InputDTOPortValue")
        .lessThanOrEqualTo(65535)
        .withMessage(resourceSet + "|InputDTOPortValue");

      this.ruleFor("fromName")
        .notEmpty()
        .withMessage(resourceSet + "|InputFromNameEmpty")
        .must((fromName) => {
          return TPGlobal.TPSanitize(fromName) === fromName;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidFromNameChar",
        )
        .maxLength(100)
        .withMessage(resourceSet + "|InputDTOInvalidFromNameLength");

      this.ruleFor("fromAddress")
        .notEmpty()
        .withMessage(resourceSet + "|InputFromAddressEmpty")
        .must((fromAddress) => {
          return TPGlobal.TPSanitize(fromAddress) === fromAddress;
        })
        .withMessage(
          TPGlobal.globalResourceSet + "|InputDTOInvalidFromAddressChar",
        )
        .must((value) => {
          if (!TPGlobal.regularExpressionCheckEmail.test(value)) {
            return false;
          } else {
            return true;
          }
        })
        .withMessage(resourceSet + "|InputDTOInvalidFromAddress")
        .maxLength(100)
        .withMessage(resourceSet + "|InputDTOInvalidFromAddressLength");

      this.ruleFor("timeNotRepeatMail")
        .notNull()
        .withMessage(resourceSet + "|InputDTOtimeNotRepeatMailEmpty");

      this.ruleFor("timeBetweenRetries")
        .notNull()
        .must((value) => {
          if (value && value > 0) {
            return true;
          } else {
            return false;
          }
        })
        .withMessage(resourceSet + "|InputDTOtimeBetweenRetriesEmpty");

      this.ruleFor("maxTries")
        .notNull()
        .must((value) => {
          if (value && value > 0) {
            return true;
          } else {
            return false;
          }
        })
        .withMessage(resourceSet + "|InputDTOmaxTriesEmpty");
    } catch (error) {}
  }
}
