export interface BranchViewModel {
  id: string;
  description: string;
  parentId: string;
  value: string;
  order: number;
  treeId: string;
  isActive: boolean;
  isVisible: boolean;
  jsonParameters: string;
  helpComments: string;
  typistId: number;
  isSystemRecord: boolean;
  localizedDescription: string;
  localizedHelpComments: string;
  localizedParent: string;
  localizedTree: string;
  isActiveCalc: boolean;
  isVisibleCalc: boolean;
  hasChild: boolean;
  hasChildActive: boolean;
  hasChildVisible: boolean;
  hierarchyIds: string;
  hierarchyDescription: string;
}

export interface BranchInfoHierarchyViewModel {
  hierarchyIds: string;
  hierarchyDescription: string;
}

export interface BranchInfoHierarchyViewModelComplete {
  id: string;
  description: string;
  parentId: string;
  value: string;
  order: number;
  treeId: string;
  isActive: boolean;
  isVisible: boolean;
  jsonParameters: string;
  helpComments: string;
  typistId: number;
  isSystemRecord: boolean;
  localizedDescription: string;
  localizedHelpComments: string;
  localizedParent: string;
  localizedTree: string;
  isActiveCalc: boolean;
  isVisibleCalc: boolean;
  hasChild: boolean;
  hasChildActive: boolean;
  hasChildVisible: boolean;
  hierarchyIds: string;
  hierarchyDescription: string;
}

export interface AdditionalDataBranchViewModel {
  branchId: string;
  additionalDataId: string;
  order: number;
  isMandatory: boolean;
  localizedAdditionalDataDescription: string;
  localizedAdditionalDataTypeDescription: string;
}

export interface ClonedBranchResponse {
  keyList: KeyList[];
  responseCode: number;
  traceId: any;
  responseMessage: ResponseMessage;
}

export interface KeyList {
  key: string;
  value: string;
}

export interface ResponseMessage {
  code: number;
  message: string;
}


export  enum OperationModeEnum {
    "normal" = 0,
    "add_root" = 1,
    "add_child" = 2,
    "clone" = 3,
    "change_parent" = 4,
    "edit" = 5,
    "delete" = 6
}