import { StoreModel } from "@/redux/store";
import { FC, forwardRef, useEffect, useState } from "react";
import { ScriptsManagementSlice, ScriptsManagementSliceModel } from "./ScriptsManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import './Assets/styles.css';
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import TPGlobal from "@/helpers/TPGlobal";
import { ScriptHeader } from "@/models/Scripts/ScriptModel";
import { ScriptsService } from "@/services/Scripts/ScriptsService";
import { TPChip } from "@/components/TPChip/TPChip";
import { useScriptsLabels } from "./Assets/labelling";

interface ScriptsAdminProperties {
  verticalTabCallback: Function;
  updateCallback: Function;
}

const ScriptsAdmin: FC<ScriptsAdminProperties> = function ({ updateCallback, verticalTabCallback }) {

  const {
    scriptsLoaded
  } = useSelector((store: StoreModel) => store[ScriptsManagementSlice.name]) as ScriptsManagementSliceModel;
  const dispatch = useDispatch();
  const { labels } = useScriptsLabels();
  const [scriptList, setScriptList] = useState<ScriptHeader[]>([]);
  const [loading, setLoading] = useState(false);
  const [favoritesSelected, setFavoritesSelected] = useState(false);

  const fetchScripts = function () {
    if (loading) return;
    setLoading(true);
    ScriptsService.getScripts(false, false, [200, 404])
      .then((scripts) => setScriptList(scripts))
      .catch(err => console.error(err))
      .finally(() => {
        dispatch(ScriptsManagementSlice.actions.setScriptsLoaded(true));
        setLoading(false);
      });
  }

  const handleFavoriteSwitch = function (scriptId: string, isFavorite: boolean) {
    setLoading(true);
    ScriptsService.updateFavorite({
      scriptId: scriptId,
      isFavorite: !isFavorite
    }, false, true, [200])
      .finally(() => {
        setLoading(false);
        fetchScripts();
      });
  }

  const deleteScript = function (scriptId: string) {
    setLoading(true);
    ScriptsService.deleteScript(scriptId, true, true, [200])
      .then(() => fetchScripts())
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchScripts();
  }, [])

  useEffect(() => {
    if (!scriptsLoaded) fetchScripts();
  }, [scriptsLoaded])

  return (
    <TPLoadingOverlay active={loading}>
      <div id='scripts-admin-container' className="scripts-main-container" style={{ justifyContent: 'flex-start' }}>
        <div className='script-admin-header'>
          <TPPageTitle>{labels.ScriptAdministration}</TPPageTitle>
          <TPButton
            onClick={() => verticalTabCallback({ command: 'new' })}
            isDesignSystem
          >
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              paddingLeft: '16px',
              paddingRight: '16px',
              justifyContent: 'space-between'
            }}>
              <TPIcon iconType={TPIconTypes.add} />
              <label>{labels.NewScript}</label>
            </div>
          </TPButton>
        </div>
        <div id='script-admin-table'>
          <DynamicTable
            id="scripts"
            data={scriptList
              .filter(script => favoritesSelected ? script.isFavorite : true)
              .map(s => ({...s, language: s.language.name}))
            }
            withPreferences
            additionalCheckboxes={[
              {
                checked: favoritesSelected,
                key: '???',
                label: labels.Favorites,
                onChange: (e) => setFavoritesSelected(e.target.checked),
              }
            ]}
            columnStyles={{
              id: (row) => (
                <button
                  style={{
                    color: 'purple',
                    border: 'none',
                    background: 'none'
                  }}
                  onClick={() => verticalTabCallback({
                    command: 'update',
                    recordId: row.item.id,
                    languageId: TPGlobal.language,
                    recordDescription: row.item.name
                  })}
                >
                  <b><u>{row.item.id}</u></b>
                </button>
              ),
              isFavorite: (row) => (
                <button
                  type="button"
                  style={{
                    border: 'none',
                    background: 'none',
                    color: row.item.isFavorite ? '#FFE500' : '#989898'
                  }}
                  onClick={() => handleFavoriteSwitch(row.item.id, row.item.isFavorite)}
                >
                  <TPIcon iconType={TPIconTypes.star} />
                </button>
              ),
              isActive: (row) => (
                <TPChip
                  backgroundColor={row.item.isActive ? '#B1F2D7' : '#FFD7DD'}
                  label={row.item.isActive ? labels.Yes : labels.No} />
              ),
            }}
            minorOptions={[
              {
                key: labels.Update,
                onOptionChange:(script) => verticalTabCallback({
                  command: 'update',
                  recordId: script.id,
                  languageId: TPGlobal.language,
                  recordDescription: script.name
                }),
                type: "edit",
                icon: TPIconTypes.edit
              },
              {
                key: labels.Delete,
                onOptionChange: (script) => deleteScript(script.id),
                type: 'delete',
                icon: TPIconTypes.delete
              },
              {
                key: labels.Clone,
                onOptionChange: (script) => verticalTabCallback({
                  command: 'clone',
                  recordId: script.id,
                  languageId: TPGlobal.language,
                  recordDescription: script.name
                }),
                type: "clone",
                icon: TPIconTypes.clone
              }
            ]}
            onIconClicked={(icon) => {
              if (icon == TPIconTypes.loop || icon == TPIconTypes.refresh) fetchScripts();
            }}
            columnNames={{
              id: labels.Id,
              isActive: labels.Active,
              isFavorite: labels.Favorite,
              name: labels.Name,
              language: labels.Language
            }}
            hiddenColumns={["localizedName"]}
          />
        </div>
      </div>
    </TPLoadingOverlay>
  )
}

export default ScriptsAdmin;