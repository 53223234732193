import { TPKeyValue } from "@/helpers/TPKeyValue";
import { ReactElement, useState, useEffect, useRef } from "react";

import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPFormControlContainerStyled } from "@/helpers/generalStyles";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { CSSProperties } from "styled-components";
import TPIcon from "../../extend/TPIcons/TPIcon";
import TPSelectInputStyled from "./tpSelectStyles";
import { CustomSpan } from "@/components/misc/CustomSpan";

interface TPSelectInterface {
  onChange: Function;
  className?: string;
  dataSource: Array<TPKeyValue>;
  value: any;
  labelText?: string | JSX.Element;
  isMandatory?: boolean;
  minWidth?: number;
  maxWidth?: number;
  isHorizontal?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  size?: number;
  multiple?: boolean;
  containerStyle?: any;
  labelStyle?: any;
  selectStyle?: any;
  withIcon?: boolean;
  icon?: TPIconTypes;
  iconClick?: any;
  id?: string;
  isDynamic?: boolean;
  placeholder?: string;
  iconStyle?: CSSProperties;
}

const defaultIconStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: "10px",
  paddingRight: "10px",
  border: "1px solid rgb(206, 212, 218)",
  borderTopRightRadius: "4px",
  borderBottomRightRadius: "4px",
  borderLeft: "0px",
  fontSize: "22px",
  cursor: "pointer",
};

const TPSelect = ({
  onChange,
  className = "",
  dataSource,
  value,
  labelText,
  isMandatory = false,
  minWidth = 100,
  maxWidth,
  isHorizontal = false,
  errorMessage = "",
  disabled = false,
  size = 0,
  multiple = false,
  containerStyle,
  labelStyle,
  selectStyle,
  withIcon = false,
  icon = TPIconTypes.default,
  iconClick = undefined,
  isDynamic = false,
  placeholder,
  id,
  iconStyle,
}: TPSelectInterface): ReactElement => {
  const elementId = `${id ?? "values"}-selection`;

  const [inputValue, setInputValue] = useState<string>("");

  const [filteredOptions, setFilteredOptions] = useState<TPKeyValue[]>([]);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const prevShowOptionsRef = useRef<boolean>(showOptions);

  useEffect(() => {
    if (dataSource && dataSource.length > 0) {
      if (multiple && Array.isArray(value)) {
        const selectedValues = value.map((valKey: string) => {
          const foundItem = dataSource.find((item) => item.key === valKey);
          return foundItem ? foundItem.value : valKey;
        });
        setInputValue(selectedValues.join(","));
      } else {
        const foundItem = dataSource.find((item) => item.key === value);
        if (foundItem) {
          setInputValue(foundItem.value);
        } else if (value === "") {
          setInputValue("");
        }
      }
    }
  }, [value, dataSource, multiple]);

  useEffect(() => {
    const justOpened = !prevShowOptionsRef.current && showOptions;
    prevShowOptionsRef.current = showOptions;

    if (!showOptions) {
      setFilteredOptions([]);
      return;
    }

    if (justOpened) {
      setFilteredOptions(dataSource);
      return;
    }

    if (inputValue.trim() && !multiple) {
      let newOptions = dataSource.filter((x) => {
        const xValueStr = String(x.value).toLowerCase();
        const inputStr = String(inputValue).toLowerCase();

        return xValueStr.includes(inputStr);
      });

      if (newOptions.length === 1 && newOptions[0].key === "") {
        newOptions = dataSource;
      }

      setFilteredOptions(newOptions);
      return;
    }

    setFilteredOptions(dataSource);
  }, [inputValue, dataSource, multiple, showOptions]);

  const handleOnChange = (e: any) => {
    const val = e.target.value;
    setInputValue(val);
  };

  const handleOptionSelect = (item: TPKeyValue) => {
    if (!multiple) {
      setInputValue(item.value);
      onChange({ target: { value: item.key } }, item);
      setShowOptions(false);
    } else {
      let currentVals = inputValue ? inputValue.split(",") : [];
      if (!currentVals.includes(item.value)) {
        currentVals.push(item.value);
      }
      setInputValue(currentVals.join(","));
      const selectedItems = currentVals.map((val) => {
        const found = dataSource.find((x) => x.value === val);
        return found ? found : { key: val, value: val };
      });
      onChange(
        {
          target: {
            options: selectedItems.map((s) => ({
              value: s.key,
              text: s.value,
              selected: true,
            })),
          },
        },
        selectedItems
      );
    }
  };

  const drawLabel = () => (
    <TPLabel
      htmlFor={elementId}
      isMandatory={isMandatory}
      labelText={labelText}
      style={labelStyle}
    />
  );

  const drawInvalidFeedBack = () => (
    <div className="invalid-feedback" style={{ display: "block" }}>
      {errorMessage}
    </div>
  );

  const handleFocus = () => {
    setShowOptions(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setShowOptions(false);
    }, 200);
  };

  const highlightMatch = (text: string | number, query: string) => {
    const textStr = typeof text === "number" ? text.toString() : text;

    if (!query) return <>{textStr}</>;

    const index = textStr.toLowerCase().indexOf(query.toLowerCase());
    if (index === -1) return <>{textStr}</>;

    const before = textStr.substring(0, index);
    const match = textStr.substring(index, index + query.length);
    const after = textStr.substring(index + query.length);

    return (
      <>
        {before}
        <CustomSpan style={{ fontWeight: "bold", backgroundColor: "yellow" }}>
          {match}
        </CustomSpan>
        {after}
      </>
    );
  };

  return (
    <>
      <TPFormControlContainerStyled
        isHorizontal={isHorizontal}
        isDynamic={isDynamic}
        className={`tp-select`}
        style={containerStyle}
      >
        {labelText && drawLabel()}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            width: "100%",
          }}
        >
          <TPSelectInputStyled
            as="input"
            disabled={disabled}
            id={elementId}
            value={inputValue}
            onChange={handleOnChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={`${className} ${errorMessage !== "" ? "is-invalid" : ""}`}
            minWidth={minWidth}
            maxWidth={maxWidth}
            isInvalid={errorMessage !== ""}
            withIcon={withIcon}
            style={selectStyle}
            autoComplete="off"
            placeholder={
              "Select or type " +
              (typeof labelText === "string" ? labelText.toLowerCase() : "")
            }
          />
          {withIcon && (
            <TPIcon
              style={{
                ...defaultIconStyle,
                ...iconStyle,
              }}
              iconType={icon}
              onClick={iconClick}
            />
          )}
          {showOptions && filteredOptions.length > 0 && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                left: 0,
                right: 0,
                backgroundColor: "#fff",
                border: "1px solid #ced4da",
                borderRadius: "4px",
                maxHeight: "200px",
                overflowY: "auto",
                zIndex: 999,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {filteredOptions.map((item, index) => (
                <button
                  key={"option" + index.toString()}
                  type="button"
                  style={{
                    padding: "5px 10px",
                    cursor: "pointer",
                    border: "none",
                    borderBottom: "1px solid #f1f1f1",
                    textAlign: "start",
                    backgroundColor:
                      hoverIndex === index ? "#f1f1f1" : "transparent",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleOptionSelect(item);
                  }}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                >
                  {typeof highlightMatch(item.value, inputValue) === "string"
                    ? item.value
                    : highlightMatch(item.value, inputValue)}
                </button>
              ))}
            </div>
          )}
        </div>
        {!withIcon && errorMessage !== "" && drawInvalidFeedBack()}
      </TPFormControlContainerStyled>
      {withIcon && errorMessage !== "" && drawInvalidFeedBack()}
    </>
  );
};

export default TPSelect;
