import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPModal from "@/layouts/TPModal/TPModal";
import useListAdminLabels from "@/pages/EventsManager/ListAdministration/assets/Labeling";
import { FC, ReactNode, useEffect, useState } from "react";
import { BtnModalStyle } from "../../../pages/Trees/Style";
import { TPColumn } from "../../TPPage/tpPageStyles";
import TPLabel from "../../bootstrap/forms/TPLabel/TPLabel";
import { TPEmthyModalModel, TypeNameButton } from "../../../models/EmthyModal/EmthyModalModel";
import { TPI18N } from "../../../services/I18nService";
import TPGlobal from "../../../helpers/TPGlobal";

class ModelButton {
    primary: string = "";
    secondary: string = "";
}


export const TPEmthyModal: FC<TPEmthyModalModel> = function ({
    visible,
    children,
    btnChildren,
    onAccept,
    onCancel,
    title,
    title2,
    width,
    gap,
    buttonNameActive,
    buttonSecondaryActive,
    btnJustifyContent,
    disabled,
    marginTopContainer
}) {

    const [saveLabel, setSaveLabel] = useState(new ModelButton);
    const [yesLabel, setYesLabel] = useState(new ModelButton);
    const [applyLabel, setPallyLabel] = useState(new ModelButton);

    const loadResources = async () => {
        setSaveLabel({
            primary: await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
            secondary: await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        });

        setYesLabel({
            primary: await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes"),
            secondary: await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"),
        });

        setYesLabel({
            primary: await TPI18N.GetText(TPGlobal.globalResourceSet, "ApplyFilterText"),
            secondary: await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        });
    };


    const handleLabelActive = (): ModelButton => {
        if (buttonNameActive == TypeNameButton.saveAndCancel)
            return saveLabel;

        if (buttonNameActive == TypeNameButton.yesAndNo)
            return yesLabel;

        if (buttonNameActive == TypeNameButton.applyAnCancel)
            return yesLabel;

        return saveLabel;
    }


    useEffect(() => {
        loadResources();
    }, []);

    return (
        <TPModal
            modalState={{
                acceptLabel: "",
                callBackAnswer: () => { },
                isShown: visible,
                titleModal: "",
                hideFooterButtons: true,
                hideXButton: true,
                hiddenHeader: true,
                width: (width ? width : "464px")
            }}
        >

            <TPColumn
                minHeight={"300px"}
                width={width}
                padding={"10px"}
                gap={gap || "5px"}

            >
                {title &&
                    <TPLabel labelText={title} style={{ fontSize: "20px", fontWeight: "700" }} />
                }

                {title2 &&
                    <TPLabel labelText={title2} style={{ fontSize: "12px", fontWeight: "500" }} />
                }
                <div style={marginTopContainer ? { height: marginTopContainer } : { height: "15px" }} ></div>
                {children}
            </TPColumn>
            <BtnModalStyle
                justifyContent={btnJustifyContent}
            >

                {btnChildren && btnChildren}

                {(!buttonSecondaryActive || buttonSecondaryActive == true) &&
                    <TPButton
                        onClick={onCancel}
                        isDesignSystem
                        style={{
                            backgroundColor: "white",
                            color: "#780096",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                        }}
                    >
                        {handleLabelActive().secondary}
                    </TPButton>
                }
                <TPButton
                    disabled={disabled}
                    onClick={onAccept}
                    isDesignSystem
                    style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                    }}
                >
                    {handleLabelActive().primary}
                </TPButton>
            </BtnModalStyle>

        </TPModal>
    );
};
