import { TPKeyValue } from "@/helpers/TPKeyValue";
import { CSSProperties, ReactNode } from "react";

/**
 * child component events
 */
export interface WallboardEvents {
    /**
     * allows you to reload the data source
     */
    load: () => void;
}

/**
 * properties that the child component needs
 */
export interface WallboardProps {
    /**
     * id of the wallboard
     */
    id?: string;
    /**
     * key of the wallboard
     */
    key?: string;
    /**
     * style of the wallboard
     */
    style?: CSSProperties;
    /**
     * children of the wallboard
     */
    children?: ReactNode;
}

/**
 * properties that the child component needs
 */
export enum WallboardEnum {
    /**
     * name
     */
    name = "Wallboard",
    /**
     * child name team view component
     */
    nameTeam = "WallboardTeamView",
    /**
     * child name group view component
     */
    nameGroup = "WallboardGroupView",
    /**
     * child name event view component
     */
    nameEvent = "WallboardEventView",
    /**
     * child name list container component
     */
    nameListContainer = "WallboardListContainer",
    /**
     * child name list component
     */
    nameList = "WallboardList",
    /**
     * file name
     */
    filename = "Wallboard.tsx",
    /**
     * name of the component
     */
    component = "WallboardComponent",
    /**
     * title label of the component
     */
    TitleLabel = "TitleLabel",
    /**
     * tab team view label
     */
    TabTeamViewLabel = "TabTeamViewLabel",
    /**
     * tab group view label
     */
    TabGroupViewLabel = "TabGroupViewLabel",
    /**
     * tab event view label
     */
    TabEventViewLabel = "TabEventViewLabel",
    /**
     * tab custom wallboard label
     */
    TabCustomWallboardLabel = "TabCustomWallboardLabel",
    /**
     * section action input search
     */
    SectionActionInputSearchLabel = "Search",
    /**
     * section action refresh label
     */
    SectionActionTooltipRefreshLabel = "Refresh",
    /**
     * section action select sort by label
     */
    SectionActionSelectSortByLabel = "SortBy",
    /**
     * section action option time more less label
     */
    SectionActionOptionTimeMoreLessLabel = "SectionActionOptionTimeMoreLessLabel",
    /**
     * section action option time less more label
     */
    SectionActionOptionTimeLessMoreLabel = "SectionActionOptionTimeLessMoreLabel",
    /**
     * section action option name A - Z label
     */
    SectionActionOptionNameAZLabel = "SectionActionOptionNameAZLabel",
    /**
     * section action option name Z - A label
     */
    SectionActionOptionNameZALabel = "SectionActionOptionNameZALabel",
    /**
     * section filter select Group label
     */
    SectionFilterSelectGroupLabel = "SectionFilterSelectGroupLabel",
    /**
     * section filter select choose placeholder label
     */
    SectionFilterSelectChoosePlaceholderLabel = "SectionFilterSelectChoosePlaceholderLabel",
    /**
     * section filter select project label
     */
    SectionFilterSelectProjectLabel = "SectionFilterSelectProjectLabel",
    /**
     * section filter select queue label
     */
    SectionFilterSelectQueueLabel = "SectionFilterSelectQueueLabel",
    /**
     * section filter button apply label
     */
    SectionFilterButtonApplyLabel = "SectionFilterButtonApplyLabel",
    /**
     * Table column ticket type case label
     */
    TableColumnTicketTypeCaseLabel = "TableColumnTicketTypeCaseLabel",
    /**
     * Table column ticket type event label
     */
    TableColumnTicketTypeEventLabel = "TableColumnTicketTypeEventLabel",
    /**
     * Table column ticket type mail label
     */
    TableColumnTicketTypeMailLabel = "TableColumnTicketTypeMailLabel",
    /**
     * table column id label
     */
    TableColumnIdLabel = "TableColumnIdLabel",
    /**
     * table column time label
     */
    TableColumnTimeLabel = "TableColumnTimeLabel",
    /**
     * table column time tooltip stop label
     */
    TableColumnTimeTooltipStopLabel = "TableColumnTimeTooltipStopLabel",
    /**
     * table team no data label
     */
    TableTeamNoDataLabel = "TableTeamNoDataLabel",
    /**
     * table group no data label
     */
    TableGroupNoDataLabel = "TableGroupNoDataLabel",
    /**
     * table event no data label
     */
    TableEventNoDataLabel = "TableEventNoDataLabel",
    /**
     * modal title label
     */
    ModalTitleLabel = "ModalTitleLabel",
    /**
     * modal yes label
     */
    ModalYesLabel = "ModalYesLabel",
    /**
     * modal no label
     */
    ModalNoLabel = "ModalNoLabel",
    /**
     * modal question label
     */
    ModalQuestionLabel = "ModalQuestionLabel",
    /**
     * accordion group label
     */
    AccordionGroupLabel = "AccordionGroupLabel",
    /**
     * message group must be selected
     */
    MessageGroupMustBeSelected = "MessageGroupMustBeSelected",
    /**
     * message group already selected
     */
    MessageGroupAlreadySelected = "MessageGroupAlreadySelected",
    /**
     * message project must be selected
     */
    MessageProjectMustBeSelected = "MessageProjectMustBeSelected",
    /**
     * message nothing to see
     */
    MessageNothingToSee = "MessageNothingToSee",
}

/**
 * messages
 */
export type Messages = {
    [key in WallboardEnum]?: string;
};

/**
 * tab type enum
 */
export enum TabTypeEnum {
    /**
     * team view
     */
    TeamView = "TeamView",
    /**
     * group view
     */
    GroupView = "GroupView",
    /**
     * event view
     */
    EventView = "EventView",
    /**
     * custom wallboard
     */
    CustomWallboard = "CustomWallboard",
}

/**
 * tab
 */
export interface Tab {
    /**
     * identifier
     */
    id: string;
    /**
     * type
     */
    type: TabTypeEnum;
    /**
     * label
     */
    label?: string;
    /**
    * active
    */
    active: boolean;
}

/**
 * wallboard state
 */
export interface WallboardState {
    /**
     * loading
     */
    loading?: boolean;
    /**
     * messages
     */
    m: Messages;
    /**
     * tabs
     */
    tabs: Tab[];
    /**
     * focused tab identifier
     */
    focus: string;
    /**
     * list of wallboard for team view data source
     */
    teamsDataSource: Array<WallboardModel>;
    /**
     * list of wallboard for team view
     */
    teams: Array<WallboardModel>;
    /**
     * list of wallboard for group view data source
     */
    groupsDataSource: Array<WallboardModel>;
    /**
     * list of wallboard for group view
     */
    groups: Array<WallboardModel>;
    /**
     * list of wallboard for event view data source
     */
    eventsDataSource: Array<WallboardModel>;
    /**
     * list of wallboard for event view
     */
    events: Array<WallboardModel>;
}

/**
 * wallboard state action type
 */
export enum WallboardStateActionType {
    /**
     * set loading
     */
    setLoading = "Wallboard/setLoading",
    /**
     * set messages
     */
    setMessages = "Wallboard/setMessages",
    /**
     * set tabs
     */
    setTabs = "Wallboard/setTabs",
    /**
     * set tab focus
     */
    setTabFocus = "Wallboard/setTabFocus",
    /**
     * set teams
     */
    setData = "Wallboard/setData",
    /**
     * clear
     */
    clean = "Wallboard/clean",
}

/**
 * wallboard state action
 */
export type WallboardStateAction = {
    /**
     * type of action
     */
    type: WallboardStateActionType;
    /**
     * payload
     */
    payload?: any;
};

/**
 * wallboard initial state
 */
export const wallboardInitialState: WallboardState = {
    m: {},
    tabs: [],
    focus: "",
    teams: [],
    teamsDataSource: [],
    groups: [],
    groupsDataSource: [],
    events: [],
    eventsDataSource: [],
};

/**
 * ticket type enum
 */
export enum TicketTypeEnum {
    /**
     * case
     */
    CASE = "CASE",
    /**
     * event
     */
    EVENT = "EVENT",
    /**
     * mail
     */
    MAIL = "MAIL",
}

/**
 * wallboard model
 */
export interface WallboardModel {
    /**
     * user name
     */
    userName: string;
    /**
     * user email
     */
    userEmail: string;
    /**
     * ticket identifier
     */
    ticketId: string;
    /**
     * ticket type
     */
    ticketType: TicketTypeEnum;
    /**
     * time in seconds
     */
    timeSeconds: string;
    /**
     * queue identifier
     */
    queueId: number;
    /**
     * queue name
     */
    queueName: string;
    /**
     * url
     */
    url?: string;
}

/**
 * queue wallboard model
 */
export interface QueueWallboardModel {
    /**
     * queue identifier
     */
    queueId: number;
    /**
     * queue name
     */
    queueName: string;
    /**
     * wallboards
     */
    wallboards: Array<WallboardModel>;
}

/**
 * child component events
 */
export interface WallboardTeamViewEvents {
    /**
     * allows you to reload the data source
     */
    load: () => void;
}

/**
 * properties that the child component needs
 */
export interface WallboardTeamViewProps {
    /**
     * id of the wallboard
     */
    id?: string;

    /**
     * style of the wallboard
     */
    style?: CSSProperties;
    /**
     * children of the wallboard
     */
    children?: ReactNode;

    itemKey?: string;
    /**
     * state of the wallboard
     */
    s: WallboardState;
    /**
     * dispatch function
     */
    dispatch: React.Dispatch<WallboardStateAction>;
}

/**
 * properties that the child component needs
 */
export interface WallboardListContainerProps {
    /**
     * id of the wallboard
     */
    id?: string;
    /**
     * key of the wallboard
     */
    key?: string;
    /**
     * style of the wallboard
     */
    style?: CSSProperties;
    /**
     * messages
     */
    m: Messages;
    /**
     * state of the wallboard
     */
    data: Array<WallboardModel>;
    /**
     * message no data
     */
    noDataMessage: string;
    /**
     * queues
     */
    queuesSelected?: Array<TPKeyValue>;
    /**
     * is group or queue
     */
    type?: "group" | "queue";
}

/**
 * properties that the child component needs
 */
export interface WallboardListProps {
    /**
     * id of the wallboard
     */
    id?: string;
    /**
     * key of the wallboard
     */
    key?: string;
    /**
     * style of the wallboard
     */
    style?: CSSProperties;
    /**
     * type
     */
    type?: "completed" | "simple";
    /**
     * messages
     */
    m: Messages;
    /**
     * state of the wallboard
     */
    data: Array<WallboardModel>;
}
