import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import { FC, ReactElement, useEffect, useRef, useState } from "react";

import {
  CIMTitleSection,
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";

import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  FunctionsInputDTO,
  FunctionsInputDTOValidator,
} from "@/models/Functions/FunctionsInputDTO";
import { FunctionsViewModel } from "@/models/Functions/FunctionsModels";
import {
  SequenceGeneratorSequencesNameEnum,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { FunctionsService } from "@/services/FunctionsService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { SequenceService } from "@/services/SequenceService";
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";
import { MultilingualTextBoxEvents } from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";
import allThemes from "@/assets/styles/theme";
import { useMenuDefinitionLabels } from "../MenuDefinition/Assets/MenuDefinitionLabels";

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};

type InsertUpdateStateType = {
  idFunction: string;
  recordLanguageList: Array<TPKeyValue>;
  isActive: boolean;

  //validator
  idErrorMessage: string;
  nameErrorMessages: Array<string>;
};

const FunctionsInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
}): ReactElement => {
  //#region  Init
  const componentFileName: string = "FunctionsInsertUpdate.tsx";

  const [isNameValue, setIsNameValue] = useState("");
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //Screen resources
  const resourceSet: string = "FunctionsInsertUpdateComponent";
  const { labels } = useMenuDefinitionLabels();
  const [titleLabel, setTitleLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [idLabel, setIdLabel] = useState("");
  const [nameLabel, setNameLabel] = useState("");
  const [isActiveLabel, setIsActiveLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");
  const [referenceId, setReferenceId] = useState("");
  const nameInputRef = useRef<MultilingualTextBoxEvents>();
  //Screen state
  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }
  let insertUpdateInitialState: InsertUpdateStateType = {
    idFunction: recordId,
    recordLanguageList: [],
    isActive: true,
    idErrorMessage: "",
    nameErrorMessages: initialErrorMessages,
  };
  const [insertUpdateState, setInsertUpdateState] = useState(
    insertUpdateInitialState
  );

  //Multilanguage const
  const multilanguageTableName: String = "FUNCtions";
  const multilanguageFieldName: String = "Description_FUNC";
  //#endregion

  const loadResourcesAndLoadFunctionInfo = async () => {
    let i: number;
    //resources state
    setTitleLabel(await TPI18N.GetText(resourceSet, "Title" + mode + "Label"));
    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel"
      )
    );
    setParametersSectionLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ParametersSectionLabel")
    );
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton")
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
    );
    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList")
    );

    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setNameLabel(await TPI18N.GetText(resourceSet, "NameLabel"));
    setIsActiveLabel(await TPI18N.GetText(resourceSet, "IsActiveLabel"));

    //screen state

    if (mode == "Update") {
      await getFunctionById(recordId);
    }
    if (mode == "Insert") {
      let newInsertUpdateState = { ...insertUpdateState };
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }
  };

  const handleOnIdFunctionChange = (newIdFunction: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.idFunction = newIdFunction;
    newInsertUpdateState.idErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.nameErrorMessages[index] = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleIsActiveChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActive = !newInsertUpdateState.isActive;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleOkButtonClick = async () => {
    if (nameInputRef.current?.isInvalid()) {
      nameInputRef.current?.markAsTouched();
      return;
    }

    const languages = nameInputRef.current?.getValue();
    const mainLanguage = languages?.find(
      ({ id }) => id === TPGlobal.TPClientDefaultLanguage
    );
    let i: number;
    let n: number;
    let recordInputDTO: FunctionsInputDTO = {
      // id: insertUpdateState.idFunction,
      id: mode === "Insert" ? referenceId : recordId,
      isActive: insertUpdateState.isActive,
      name: mainLanguage?.value!,
      nameLocalizedValues: [
        {
          languageId: mainLanguage?.id!,
          localizedValue: mainLanguage?.value ?? "",
          order: 1,
        },
        ...languages!
          .filter(({ id }) => id !== TPGlobal.TPClientDefaultLanguage)
          .map(({ id, value }, order) => ({
            languageId: id!,
            localizedValue: value ?? "",
            order: order + 2,
          })),
      ],
    };

    let inputDTOValidator = new FunctionsInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      if (resultValidator.id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.id
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }

      setInsertUpdateState(newInsertUpdateState);
      return;
    }
    if (mode == "Insert") {
      await insertFunction(recordInputDTO);
    } else {
      await updateFunction(recordInputDTO);
    }
  };

  const insertFunction = async (inputDTO: FunctionsInputDTO) => {
    let serviceClient = new FunctionsService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertFunction(
        inputDTO,
        true,
        true,
        expectedCodes
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertFunction ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} insertFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateFunction = async (inputDTO: FunctionsInputDTO) => {
    let serviceClient = new FunctionsService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.updateFunction(
        inputDTO,
        true,
        true,
        expectedCodes
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  const getFunctionById = async (pRecordId: string) => {
    let serviceClient = new FunctionsService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getFunctionById(
        pRecordId,
        false,
        true,
        expectedCodes
      );

      let recordInfo: FunctionsViewModel;
      recordInfo = { ...responseRequest };
      setOriginalRecordDescription(
        recordInfo.name.length <= 100
          ? recordInfo.name
          : recordInfo.name.substring(0, 100) + "..."
      );

      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.recordLanguageList = [];
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      newInsertUpdateState.isActive = recordInfo.isActive;
      newInsertUpdateState.recordLanguageList[0].value = recordInfo.name;
      recordLanguagesList = await getRecordLanguageList(pRecordId);
      if (recordLanguagesList.length == 0) {
        TPLog.Log(
          `Error ${componentFileName} getFunctionById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty"
        );
        console.error(
          `Error ${componentFileName} getFunctionById getRecordLanguageList is empty`
        );
        setIsLoadingScreen(false);
        return;
      }
      for (
        i = 0;
        i <= newInsertUpdateState.recordLanguageList.length - 1;
        i++
      ) {
        for (j = 0; j <= recordLanguagesList.length - 1; j++) {
          if (
            newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ==
            recordLanguagesList[j].languageId.toLowerCase()
          ) {
            newInsertUpdateState.recordLanguageList[i].value =
              recordLanguagesList[j].recordDescription;
            break;
          }
        }
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getFunctionById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getFunctionById ex`);
      setIsLoadingScreen(false);
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQFUNC
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleChangeValue = (value: any) => {
    setIsNameValue(value);
  };

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResourcesAndLoadFunctionInfo();
    mode === "Insert" && generalAutomaticId();
  }, []);

  return (
    //#region  Render
    // <ContentVerticalTabInsertUpdateStyled>
    <TPLoadingOverlay active={isLoadingScreen}>
      <div className="row menu-def-main-form-container">
        <div className="col menu-def-main-form-inner-container">
          <CIMTitleSection style={{ margin: 0, padding: 0 }}>
            <TPPageTitle style={{ margin: 0, padding: 0 }}>
              {titleLabel}
            </TPPageTitle>
            {mode == "Update" ? (
              <TPPageSubTitle>
                {`${subTitleLabel}: ${recordId} / ${originalRecordDescription}`}
              </TPPageSubTitle>
            ) : null}
          </CIMTitleSection>

          <div className="menu-def-form">
            <div style={{ width: "100%" }}>
              <TPTextBox
                id="IdTextBox"
                labelText={idLabel}
                isMandatory={true}
                // value={insertUpdateState.idFunction}
                value={mode === "Insert" ? referenceId : recordId}
                onChange={
                  mode === "Insert"
                    ? (e: any) => handleOnIdFunctionChange(e.target.value)
                    : () => {
                        TPGlobal.foo();
                      }
                }
                maxLength={20}
                // disabled={mode !== "Insert"}
                disabled
                errorMessage={insertUpdateState.idErrorMessage}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{ width: "100%", marginRight: "16px", flexShrink: 0 }}
              >
                <MultilingualTextBox
                  ref={nameInputRef}
                  maxLength={200}
                  style={{ width: "calc(101.5% + 35px)" }}
                  value={insertUpdateState.recordLanguageList?.map(
                    ({ key, value }) => ({ id: key, value })
                  )}
                  onChange={handleChangeValue}
                />
              </div>
            </div>

            <TPCheckBox
              id="IdCheckBox"
              labelText={labels.ShowAsActive}
              checked={insertUpdateState.isActive}
              onChange={(e: any) => handleIsActiveChange()}
            ></TPCheckBox>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div className="menu-def-option-buttons">
                <TPButton
                  isDesignSystem
                  onClick={handleCancelButtonClick}
                  style={{
                    backgroundColor: "white",
                    color: allThemes.base.purplePrimary,
                    padding: "11px 16px",
                  }}
                >
                  {labels.Cancel}
                </TPButton>
                <TPButton
                  isDesignSystem
                  onClick={handleOkButtonClick}
                  style={{ padding: "11px 16px" }}
                  // disabled={!isFormValid(mainForm)}
                  disabled={isNameValue ? false : true}
                >
                  {labels.Save}
                </TPButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TPLoadingOverlay>
    // </ContentVerticalTabInsertUpdateStyled>
    //#endregion
  );
};

export default FunctionsInsertUpdate;
