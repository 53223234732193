import NoDataImg from "@/assets/images/no-data.png";
import NoFilteredDataImg from "@/assets/images/no-filtered-data.svg";
import { ReactElement } from "react";
import { StyledNoDataImage } from "./dynamic-table-styles";

type TableNotFoundProps = {
    text?: string;
    isSearchData?: boolean;
    noResultsLabel: string;
    newOneLabel: string;
    styleNoDataImage?: React.CSSProperties;
};

function TableNotFound({
    text,
    isSearchData = false,
    noResultsLabel,
    newOneLabel,
    styleNoDataImage
}: TableNotFoundProps): ReactElement {
    const image = isSearchData ? NoFilteredDataImg : NoDataImg;
    return (
        <StyledNoDataImage style={styleNoDataImage}>
            <img src={image} alt="no-data" />
            <p>
                {text ? text : noResultsLabel}
                <br />
                <strong>{text && newOneLabel}</strong>
            </p>
        </StyledNoDataImage>
    );
}

export default TableNotFound;
