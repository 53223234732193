import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";
import { useEffect, useState } from "react"

const RESOURCE_SET = "TagSelector"

interface TagSelectorLabels {
  Title: string;
  TagGroup: string;
  Tag: string;
  Cancel: string;
  Save: string;
  Tags: string;
}

export const useTPTagSelectorLabels = function() {

  const [labels, setLabels] = useState<TagSelectorLabels>({} as TagSelectorLabels);

  const getLabels = async function() {
    setLabels({
      Tag: await TPI18N.GetText(RESOURCE_SET,"Tag"),
      Tags: await TPI18N.GetText(RESOURCE_SET,"Tags"),
      TagGroup: await TPI18N.GetText(RESOURCE_SET,"TagGroup"),
      Title: await TPI18N.GetText(RESOURCE_SET,"Title"),
      Cancel: await TPI18N.GetText(TPGlobal.globalResourceSet,"CancelButton"),
      Save: await TPI18N.GetText(TPGlobal.globalResourceSet,"SaveButton"),
    })
  }

  useEffect(() => {
    getLabels();
  }, [])

  return { labels };
}