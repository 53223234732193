import {
  DataTableContainer,
  TableContainer,
  TableHeaderContainer,
  TableSearchContainer,
  tableStyles,
  TableToolbar,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPFixedTable from "@/components/TPFixedTable/TPFixedTable";
import {
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ModalSizeEnum, TPActiveOptions, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { UserGroupInputDTO } from "@/models/UserGroups/UserGroupInputDTO";
import { UserGroupViewModel } from "@/models/UserGroups/UserGroupModels";
import { TPI18N } from "@/services/I18nService";
import { UserGroupService } from "@/services/UserGroupService";
import { GroupsService } from "@/services/GroupsService";
import React, { useEffect, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import { TPChip } from "@/components/TPChip/TPChip";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import allThemes from "@/assets/styles/theme";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { CustomColumnNames } from "../Projects/ProjectDetail/ProjectDetailDynamicTable";
import { TPAvatar } from "@/components/TPAvatar/TPAvatar";
import { isEqual } from "lodash";
import TPModal from "@/layouts/TPModal/TPModal";
import { QueueFilter } from "@/models/Groups/GroupsModels";

interface GroupsAdminInterface {
  groupId: number;
  groupDescription: string;
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

enum commandsEnum {
  "change_selectedFilter" = 1,
  "set_griddata" = 2,
}

type AdminStateType = {
  selectedFilter: string;
  gridData: Array<UserGroupViewModel>;
};

interface GroupAssignModel {
  id: string;
  name: string;
  email: string;
  selected: boolean;
  active: boolean;
  avatar: string;
}

const AssignUserToGroup = React.forwardRef(
  ({ groupId, groupDescription }: GroupsAdminInterface, ref) => {
    const componentFileName: string = "AssignUserToGroup.tsx";
    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const resourceSet: string = "AssignUserToGroupComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [subTitleLabel, setSubtitleLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    //grid columns
    const [userLoginColumnLabel, setLoginColumnLabel] = useState("");
    const [userNameColumnLabel, setUserNameColumnLabel] = useState("");

    const [updateLabel, setUpdateLabel] = useState("");
    const [removeLabel, setRemoveLabel] = useState("");
    const [usersAssignedLabel, setUsersAssignedLabel] = useState("");
    const [addUsersLabel, setAddUsersLabel] = useState("")
    const [reminderLabel, setReminderLabel] = useState("");
    const [photoLabel, setPhotoLabel] = useState("");
    const [userLabel, setUserLabel] = useState("");
    const [emailLabel, setEmailLabel] = useState("");
    const [assignUsersLabel, setAssignUsersLabel] = useState("");
    const [cancelLabel, setCancelLabel] = useState("");
    const [clearAllLabel, setClearAllLabel] = useState("");
    const [profileLabel, setProfileLabel] = useState("");
    const [teamsLabel, setTeamsLabel] = useState("");
    const [functionsLabel, setFunctionsLabel] = useState(""); 
    const [suiperiorLabel, setSuperiorLabel] = useState("");
    const [nameLabel, setNameLabel] = useState("");
    const [applyLabel, setApplyLabel] = useState("");
    const [systemAlertLabel, setSystemAlertLabel] = useState("");
    const [sureLabel, setSureLabel] = useState("");
    const [listLabel, setListLabel] = useState("");
    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");
    //end resources

    //State filter dropdown
    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
    const [assignToUserGroup, setAssignToUserGroup] = useState(false);
    const [searchPattern, setSearchPattern] = useState("");
    const [canSetupColumns, setCanSetupColumns] = useState(false);
    const [gridColumns, setGridColumns] = useState<Array<any>>([]);

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    const [isRemoving, setIsRemoving] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    const [groupIdDisplay, setGroupIdDisplay] = useState("");
    const [descriptionDisplay, setDescriptionDisplay] = useState("");

    const [profileFilter, setProfileFilter] = useState<string>("");
    const [teamFilter, setTeamFilter] = useState<string>("");
    const [superiorFilter, setSuperiorFilter] = useState<string>("");
    const [functionsFilter, setFunctionsFilter] = useState<string>("");
    const [nameFilter, setNameFilter] = useState<string>("");

    const blankSelectItem = '';

    const [teamsFilterOptions, setTeamsFilterOptions] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);
    const [profileFilterOptions, setProfileFilterOptions] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);
    const [superiorFilterOptions, setSuperiorFilterOptions] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);
    const [functionsFilterOptions, setFunctionsFilterOptions] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);

    const [modalData, setModalData] = useState<Array<UserGroupViewModel>>([]);

    const loadFilters = async () => {
      const userGroupServiceInstance = new UserGroupService();
      userGroupServiceInstance.getFilters(false, false, [200])
      .then((response) => {
        if (response) { 
        setProfileFilterOptions([
          ...profileFilterOptions,
          ...response.filter(item => item.itemType == QueueFilter.Profile).map(item => {
            return {
              key: item.itemId,
              value: item.itemDescription
            }
          })
        ])
        setTeamsFilterOptions([
          ...teamsFilterOptions,
          ...response.filter(item => item.itemType == QueueFilter.Team).map(item => {
            return {
              key: item.itemId,
              value: item.itemDescription
            }
          })
        ])
        setFunctionsFilterOptions([
          ...functionsFilterOptions,
          ...response.filter(item => item.itemType == QueueFilter.Function).map(item => {
            return {
              key: item.itemId,
              value: item.itemDescription
            }
          })
        ])
        setSuperiorFilterOptions([
          ...superiorFilterOptions,
          ...response.filter(item => item.itemType == QueueFilter.Superior).map(item => {
            return {
              key: item.itemId,
              value: item.itemDescription
            }})
          ])
        }
      }
    )}

    const loadResources = async () => {
      setUpdateLabel(
        await TPI18N.GetText(resourceSet, "UpdateLabel"),
      );
      setRemoveLabel(
        await TPI18N.GetText(resourceSet, "RemoveLabel"),
      );
      setUsersAssignedLabel(
        await TPI18N.GetText(resourceSet, "UsersAssignedLabel"),
      );
      setAddUsersLabel(
        await TPI18N.GetText(resourceSet, "AddUsersLabel"),
      );
      setReminderLabel(
        await TPI18N.GetText(resourceSet, "ReminderLabel"),
      );
      setPhotoLabel(
        await TPI18N.GetText(resourceSet, "PhotoLabel"),
      );
      setUserLabel(
        await TPI18N.GetText(resourceSet, "UserLabel"),
      );
      setEmailLabel(
        await TPI18N.GetText(resourceSet, "EmailLabel"),
      );
      setAssignUsersLabel(
        await TPI18N.GetText(resourceSet, "AssignUsersLabel"),
      );
      setCancelLabel(
        await TPI18N.GetText(resourceSet, "CancelLabel"),
      );
      setClearAllLabel(
        await TPI18N.GetText(resourceSet, "ClearAllLabel"),
      );
      setProfileLabel(
        await TPI18N.GetText(resourceSet, "ProfileLabel"),
      );
      setTeamsLabel(
        await TPI18N.GetText(resourceSet, "TeamsLabel"),
      );
      setFunctionsLabel(
        await TPI18N.GetText(resourceSet, "FunctionsLabel"),
      );
      setSuperiorLabel(
        await TPI18N.GetText(resourceSet, "SuperiorLabel"),
      );
      setNameLabel(
        await TPI18N.GetText(resourceSet, "NameLabel"),
      );
      setApplyLabel(
        await TPI18N.GetText(resourceSet, "ApplyLabel"),
      );
      setSystemAlertLabel(
        await TPI18N.GetText(resourceSet, "SystemAlertLabel"),
      );
      setSureLabel(
        await TPI18N.GetText(resourceSet, "SureLabel"),
      );
      setListLabel(
        await TPI18N.GetText(resourceSet, "ListLabel"),
      );
      setYesLabel(
        await TPI18N.GetText(resourceSet, "YesLabel"),
      );
      setNoLabel(
        await TPI18N.GetText(resourceSet, "NoLabel"),
      );

      setLoginColumnLabel(
        await TPI18N.GetText(resourceSet, "LoginColumnLabel"),
      );
      setUserNameColumnLabel(
        await TPI18N.GetText(resourceSet, "UserNameColumnLabel"),
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      );
      setFilterLabel(await TPI18N.GetText(resourceSet, "FilterIsActiveLabel"));
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );

      setTitleLabel(await TPI18N.GetText(resourceSet, "EditGroupTitleLabel"));
      setSubtitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
      //Filter
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(resourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "NoAsigned"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Asigned"),
      });

      setFilterKeyValue(newFilterKeyValue);

      setCanSetupColumns(true);
    };

    const setupGridColumns = () => {
      try {
        let newColumns: Array<any> = [];

        //Login
        newColumns.push({
          name: userLoginColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["user"]["login"];
          },
          selector: (row: { [x: string]: any }) => row["user"]["login"],
          sortable: true,
          width: "350px",
        });

        //Login
        newColumns.push({
          name: userNameColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["user"]["name"];
          },
          selector: (row: { [x: string]: any }) => row["user"]["name"],
          sortable: true,
          width: "350px",
        });

        setGridColumns(newColumns);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
      }
    };

    const filteredModalData = () => {
      let searcheableColumns: Array<string> = ["id", "login", "name", "email"];
      let i: number;
      let search: string;
      search = nameFilter.trim();
      let modalData = adminState.gridData.filter(x => x.tpselectedRow == false).filter(function (item) {
        if (profileFilter == "" && teamFilter == "" &&
          functionsFilter == "" && superiorFilter == "" && 
          search == "" && search.length <= 2) {
          return true;
        }
        if (profileFilter !== "" && profileFilter !== item.user.profileId) {
          return false;
        }
        if (teamFilter !== "" && teamFilter !== item.user.teamId) {
          return false;
        }
        if (functionsFilter !== "" && functionsFilter !== item.user.functionId) {
          return false;
        }
        if (superiorFilter !== "") {
          let superiorGuid = adminState.gridData.find(x => x.user.id == superiorFilter)?.user.userGuid;
          let isEqual = false;

          if (superiorGuid && superiorGuid == item.user.superiorIdGuid) {
            isEqual = true;
          }

          if (!isEqual) return false;
        }

        let found = false;

        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany.user[searcheableColumns[i]] &&
            itemany.user[searcheableColumns[i]]
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            found = true;
          }
        }

        if (!found) return false;

        return true;
      });
      setModalData(modalData);
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = ["id", "login", "name", "email"];
      let i: number;
      let search: string;
      search = searchPattern.trim();
      return adminState.gridData.filter(x => x.tpselectedRow == true).filter(function (item: any, index: number) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany.user[searcheableColumns[i]] &&
            itemany.user[searcheableColumns[i]]
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(({ result, assignedUsers }) => {
          const newGridData = result.map((item: UserGroupViewModel) => {
            const existingItem = adminState.gridData.find(
              (prevItem: UserGroupViewModel) =>
                prevItem.user.userGuid === item.user.userGuid,
            );
            if (existingItem) {
              item.tpselectedRow = existingItem.tpselectedRow;
            } else {
              // Ensure tpselectedRow is set based on filter
              if (
                adminState.selectedFilter === TPActiveOptions.ALL.toString()
              ) {
                const assigned = assignedUsers.find(
                  (assignedItem: UserGroupViewModel) =>
                    assignedItem.user.userGuid === item.user.userGuid,
                );
                item.tpselectedRow = !!assigned;
              } else {
                item.tpselectedRow =
                  adminState.selectedFilter ===
                  TPActiveOptions.ACTIVE.toString();
              }
            }
            return item;
          });

          newGridData.sort((a, b) => a.user.login.localeCompare(b.user.login));

          dispatchCommand({
            type: commandsEnum.set_griddata,
            payload: { newGridData },
          });
        })
        .catch((error) => {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    const getById = async () => {
      let serviceClient = new GroupsService();
      let expectedCodes: Array<number> = [200];
      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.getGroupById(
          groupId.toString(),
          false,
          true,
          expectedCodes,
        );
        setGroupIdDisplay(responseRequest.user.login);
        setDescriptionDisplay(responseRequest.user.name);
        setIsLoadingScreen(false);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getById ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getById ex`);
        setIsLoadingScreen(false);
      }
    };

    //Get organizations relations by Filter
    const reloadDataGrid = async (
      selectedFilter: string,
    ): Promise<{
      result: UserGroupViewModel[];
      assignedUsers: UserGroupViewModel[];
    }> => {
      const serviceClient = new UserGroupService();
      const expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        let responseRequest: Array<UserGroupViewModel> = [];
        let assignedUsers: Array<UserGroupViewModel> = [];
        switch (selectedFilter) {
          case TPActiveOptions.INACTIVE.toString(): // Not Assigned
            responseRequest = await serviceClient.getAllNoGroupByGroupId(
              groupId.toString(),
              false,
              true,
              expectedCodes,
            );
            break;
          case TPActiveOptions.ACTIVE.toString(): // Assigned
            responseRequest = await serviceClient.getAllGroupByGroupId(
              groupId.toString(),
              false,
              true,
              expectedCodes,
            );
            assignedUsers = responseRequest;
            break;
          case TPActiveOptions.ALL.toString(): // All
            const notAssigned = await serviceClient.getAllNoGroupByGroupId(
              groupId.toString(),
              false,
              true,
              expectedCodes,
            );
            assignedUsers = await serviceClient.getAllGroupByGroupId(
              groupId.toString(),
              false,
              true,
              expectedCodes,
            );
            responseRequest = [...notAssigned, ...assignedUsers];
            break;
        }
        setIsLoadingScreen(false);
        return { result: responseRequest, assignedUsers };
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return { result: [], assignedUsers: [] };
      }
    };

    

    const handleRowClick = async (row: any, event: any) => {
      //only one row selected policy
      let i: number;
      let isChecked: boolean = false;
      let newGridData: Array<any> = [...adminState.gridData];
      for (i = 0; i <= newGridData.length - 1; i++) {
        if (row.user.userGuid === newGridData[i].user.userGuid) {
          isChecked = !newGridData[i].tpselectedRow;
        }
      }

      if (isChecked) {
        let recordInputDTO: UserGroupInputDTO = {
          groupId: +groupId,
          guid_USER: row.user.userGuid,
        };
        if (await addUserToCurrentGroup(recordInputDTO)) {
          for (i = 0; i <= newGridData.length - 1; i++) {
            if (row.user.userGuid === newGridData[i].user.userGuid) {
              newGridData[i].tpselectedRow = !newGridData[i].tpselectedRow;
            }
          }
        }
      } else {
        if (await removeUserFromCurrentGroup(row.user.userGuid)) {
          for (i = 0; i <= newGridData.length - 1; i++) {
            if (row.user.userGuid === newGridData[i].user.userGuid) {
              newGridData[i].tpselectedRow = !newGridData[i].tpselectedRow;
            }
          }
        }
      }

      let command1: commandType = {
        type: commandsEnum.set_griddata,
        payload: { newGridData: newGridData },
      };
      dispatchCommand(command1);
    };

    const addUserToCurrentGroup = async (inputDTO: UserGroupInputDTO) => {
      let serviceClient = new UserGroupService();
      let expectedCodes: Array<number> = [200];
      try {
        //setIsLoadingScreen(true);

        let responseRequest = await serviceClient.insert(
          inputDTO,
          false,
          true,
          expectedCodes,
        );
        //setIsLoadingScreen(false);
        return responseRequest.responseResult;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} insertFunction ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} insertFunction ex`);
        //setIsLoadingScreen(false);
      }
    };

    const removeUserFromCurrentGroup = async (userGuid: string) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new UserGroupService();
      try {
        //setIsLoadingScreen(true);
        let responseRequest = await serviceClient.delete(
          groupId.toString(),
          userGuid,
          false,
          true,
          expectedCodes,
        );
        //setIsLoadingScreen(false);
        return responseRequest.responseData.responseCode !== 500;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} removeUserFromCurrentGroup ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} removeUserFromCurrentGroup ex`,
        );
        //setIsLoadingScreen(false);
      }
    };

    const onSearchPatternChange2 = (newValue: string) => {
      setSearchPattern(newValue);
    };

    const onColumnSort = (columnSelector: any, direction: string) => {
      let sortedGridData: Array<any>;
      sortedGridData = TPGlobal.datatableCustomSort(
        adminState.gridData,
        columnSelector,
        direction,
      );
      let command1: commandType = {
        type: commandsEnum.set_griddata,
        payload: { newGridData: sortedGridData },
      };
      dispatchCommand(command1);
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
    const [selectedModalRows, setSelectedModalRows] = useState<Array<any>>([]);

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridData: [],
    };
    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );

    function doCommand(prevState: AdminStateType, command: commandType) {
      let i: number;
      switch (command.type) {
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.set_griddata:
          let oldSelectedfilter = { ...prevState };
          let newGridData: Array<any> = [...command.payload.newGridData];
          let newAdminState: AdminStateType = {
            gridData: newGridData,
            selectedFilter: oldSelectedfilter.selectedFilter,
          };
          setSelectedRows(newAdminState.gridData.filter(x => x.tpselectedRow == true).map((user) => ({
            id: user.user.id,
            selected: user.tpselectedRow,
            avatar: user.user.avatar,
            name: user.user.name,
            email: user.user.email
          })))
          setIsRemoving(false);
          return newAdminState;
        default:
          return prevState;
      }
    }

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResources();
      loadFilters();
      getById();
    }, []);

    //user change filter
    useEffect(() => {
      reloadGridCommand();
    }, [adminState.selectedFilter]);

    useEffect(() => {
      if (canSetupColumns) {
        setupGridColumns();
      }
    }, [canSetupColumns]);

    const ColumnNames: CustomColumnNames<GroupAssignModel> = {
      selected: "",
      avatar: photoLabel,
      name: userLabel,
      email: emailLabel,
    };

    const ColumnStyles = {
      avatar: ({ value, item }: any) => (
        <TPAvatar
          src={item.avatar}
          size={30}
          circular={true}
          enableFullscreen={true}
        />
      ),
    }

    const HandleModalSelectionChange = async () => {
      let newGridData = [...adminState.gridData];

      for (let i = 0; i < selectedModalRows.length; i++) {
        let currentItem = newGridData.find(x => x.user.id == selectedModalRows[i].id)

        if (currentItem) {
          let recordInputDTO: UserGroupInputDTO = {
            groupId: +groupId,
            guid_USER: currentItem.user.userGuid,
          };

          if (await addUserToCurrentGroup(recordInputDTO)) {
            for (i = 0; i <= newGridData.length - 1; i++) {
              if (currentItem.user.userGuid === newGridData[i].user.userGuid) {
                newGridData[i].tpselectedRow = !newGridData[i].tpselectedRow;
              }
            }
          }

          let command1: commandType = {
            type: commandsEnum.set_griddata,
            payload: { newGridData: newGridData },
          };
          dispatchCommand(command1);
        }
      }
    }

    const HandleSelectionChange = async () => {
      let currentGridData: Array<any> = adminState.gridData.filter(x => x.tpselectedRow == true).map((user) => ({
        id: user.user.id,
        selected: user.tpselectedRow,
        avatar: user.user.avatar,
        name: user.user.name,
        email: user.user.email
      }));

      if (selectedRows.length < currentGridData.length) {
        setIsLoadingScreen(true);

        

        let newGridData = [...adminState.gridData];

        for (let i = 0; i < currentGridData.length; i++) {
          let currentItem = newGridData.find(x => x.user.id == currentGridData[i].id)
          let isChecked = true

          if (currentItem != undefined) {
            if (!selectedRows.find(x => x.id == currentItem?.user.id)){
              isChecked = false
            }

            if (!isChecked) {
              if (await removeUserFromCurrentGroup(currentItem.user.userGuid)) {
                for (i = 0; i <= newGridData.length - 1; i++) {
                  if (currentItem.user.userGuid === newGridData[i].user.userGuid) {
                    newGridData[i].tpselectedRow = !newGridData[i].tpselectedRow;
                  }
                }
              }
            }

            let command1: commandType = {
              type: commandsEnum.set_griddata,
              payload: { newGridData: newGridData },
            };
            dispatchCommand(command1);
          }
        }

        setIsLoadingScreen(false);
        setIsRemoving(false);
      } else {
        reloadGridCommand();
      }
    }

    const callBackAnswer = (status: boolean, data: any) => {
      if (data == "Clear all") {
        setSelectedModalRows([]);
      } else {
        setIsAdding(false);
        if (status) {
          HandleModalSelectionChange();
          reloadGridCommand();
        }
        setProfileFilter("");
        setTeamFilter("");
        setTeamFilter("");
        setSuperiorFilter("");
        setNameFilter("");
        setModalData([]);
      }
    }

    return (
      <>
        {isAdding && (
          <TPModal
          modalState={{
            id: "add-user-modal",
            isShown: true,
            callBackAnswer: callBackAnswer,
            titleModal: "",
            modalWidth: ModalSizeEnum.MODALXL,
            hiddenHeader: true,
            acceptLabel: assignUsersLabel + (selectedModalRows.length > 0 ? ` (${selectedModalRows.length})` : ""),
            cancelLabel: cancelLabel,
            showSecondaryButton: true,
            secondaryButtonLabel: clearAllLabel,
            secondaryAction: "Clear all",
            enableAcceptButton: selectedModalRows.length > 0, 
          }}
        >
          <div style={{ gap: "16px", padding: "16px" }}>
            <h5>
              <b>{addUsersLabel}</b>
            </h5>
            <div className="row">
              <div
                className="col-2"
                id="groups-unassigned-users-profile-filter"
              >
                <TPSelect
                  dataSource={profileFilterOptions}
                  onChange={(event: any) =>
                    setProfileFilter(event.target.value)
                  }
                  value={profileFilter}
                  labelText={profileLabel}
                />
              </div>
              <div className="col-2" id="groups-unassigned-users-team-filter">
                <TPSelect
                  dataSource={teamsFilterOptions}
                  onChange={(event: any) => setTeamFilter(event.target.value)}
                  value={teamFilter}
                  labelText={teamsLabel}
                />
              </div>
              <div
                className="col-2"
                id="groups-unassigned-users-functions-filter"
              >
                <TPSelect
                  dataSource={functionsFilterOptions}
                  onChange={(event: any) =>
                    setFunctionsFilter(event.target.value)
                  }
                  value={functionsFilter}
                  labelText={functionsLabel}
                />
              </div>
              <div
                className="col-2"
                id="groups-unassigned-users-schedule-filter"
              >
                <TPSelect
                  dataSource={superiorFilterOptions}
                  onChange={(event: any) =>
                    setSuperiorFilter(event.target.value)
                  }
                  value={superiorFilter}
                  labelText={suiperiorLabel}
                />
              </div>
              <div className="col-2" id="groups-unassigned-users-name-filter">
                <TPTextBox
                  onChange={(event: any) => setNameFilter(event.target.value)}
                  value={nameFilter}
                  labelText={nameLabel}
                />
              </div>
              <div className="col-2 mt-4">
                <TPButton
                  type={TPButtonTypes.primary}
                  onClick={() => {filteredModalData()}}
                  isDesignSystem
                  style={{
                    paddingTop: "11px",
                    paddingBottom: "11px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                >
                  {applyLabel}
                </TPButton>
              </div>
            </div>
            <DynamicTable
              id={"Groups-assign-users-table"}
              data={modalData.map((user) => ({
                id: user.user.id,
                selected: user.tpselectedRow,
                avatar: user.user.avatar,
                name: user.user.name,
                email: user.user.email
              }))}
              columnNames={ColumnNames}
              columnStyles={ColumnStyles}
              selectable
              selectedValues={selectedModalRows}
              onSelectionChange={sel => setSelectedModalRows(sel)}
              hideExport
              hiddenSearch
              hideRefresh
              hiddenColumns={["id", "selected"]}
            />
          </div>
        </TPModal>
        )}
        {isRemoving && selectedRows.length < adminState.gridData.filter(x => x.tpselectedRow == true).length && (
          <TPModal
          modalState={{
            id: "cancel-user-assign-modal",
            acceptLabel: "Yes",
            callBackAnswer: () => {
              setIsRemoving(false);
            },
            cancelLabel: "No",
            isShown: true,
            titleModal: "",
            hideFooterButtons: true,
            modalWidth: "512px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              gap: "24px",
              padding: "24px",
            }}
          >
            <h5>
              <b>{systemAlertLabel}</b>
            </h5>
            <label>
              {sureLabel} {adminState.gridData.filter(x => x.tpselectedRow == true).length - selectedRows.length} {listLabel}
              {/* {Boolean(deselectedUser?.email) && " / " + deselectedUser?.email}?*/}
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "50%",
                gap: "16px",
              }}
            >
              <TPButton
                isDesignSystem
                onClick={() => {
                  setIsRemoving(false)
                }}
                style={{
                  backgroundColor: "white",
                  color: "purple",
                  paddingTop: "11px",
                  paddingBottom: "11px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {noLabel}
              </TPButton>
              <TPButton
                isDesignSystem
                onClick={HandleSelectionChange}
                style={{
                  paddingTop: "11px",
                  paddingBottom: "11px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                {yesLabel}
              </TPButton>
            </div>
          </div>
        </TPModal>
        )}
        <TPLoadingOverlay active={isLoadingScreen}>
          <TPPageFirstRow>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <label style={{ fontSize: "20px", fontWeight: "700" }}>
                {usersAssignedLabel}
              </label>
              <TPChip
                label={`(${adminState.gridData.filter(x => x.tpselectedRow == true).length})`}
                style={{ fontSize: "20px", fontWeight: "700" }}
              />
            </div>
            <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    justifyContent: "flex-end",
                    paddingRight: "8px",
                  }}
                >
                  <TPTextBox
                    onChange={(event: any) =>
                      setSearchPattern(event.target.value)
                    }
                    value={searchPattern}
                    icon={TPIconTypes.search}
                    withIcon
                    iconClick={() => {}}
                    placeholder={searchLabel}
                    containerStyle={{ width: "50%" }}
                  />
                  <TPButton
                    isDesignSystem
                    onClick={() => setIsAdding(true)}
                    style={{
                      backgroundColor: "white",
                      color: allThemes.base.purplePrimary,
                      padding: "11px 16px",
                    }}
                  >
                    <label style={{ display: 'flex', gap: '4px' }}><TPIcon iconType={TPIconTypes.add} /> {addUsersLabel}</label>
                  </TPButton>
                  <TPButton
                    isDesignSystem
                    onClick={() => {
                      selectedRows.length < adminState.gridData.filter(x => x.tpselectedRow == true).length ? setIsRemoving(true) : HandleSelectionChange()
                    }}
                    style={{
                      padding: "11px 16px",
                    }}
                  >
                    {selectedRows.length < adminState.gridData.filter(x => x.tpselectedRow == true).length ? removeLabel : updateLabel}
                  </TPButton>
                </div>
          </TPPageFirstRow>
          <div
            style={{
              display: "flex",
              backgroundColor: "#D3E1FC",
              alignItems: "center",
            }}
          >
            <TPIcon
              iconType={TPIconTypes.alert}
              style={{
                fontSize: "24px",
                color: "#3047B0",
                paddingLeft: "8px",
              }}
            />
            <TPLabel
              labelText={reminderLabel}
              style={{
                color: "#002C81",
                fontSize: "14px",
                fontWeight: "400",
                height: "53px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                borderRadius: "5px",
                padding: "16px",
                margin: "8px 0",
              }}
            />
          </div>
          <DynamicTable
            id={"Groups-assign-users-table"}
            data={filteredData().map((user) => ({
              id: user.user.id,
              selected: user.tpselectedRow,
              avatar: user.user.avatar,
              name: user.user.name,
              email: user.user.email
            }))}
            columnNames={ColumnNames}
            columnStyles={ColumnStyles}
            selectable
            selectedValues={selectedRows}
            onSelectionChange={sel => setSelectedRows(sel)}
            hideExport
            hiddenSearch
            hideRefresh
            hiddenColumns={["id", "selected"]}
          />
          <div style={{ display: "none"}}>
          <TableHeaderContainer>
            <TableToolbar></TableToolbar>
            <TableSearchContainer></TableSearchContainer>
          </TableHeaderContainer>
          <TPFixedTable
            keyField={"tpguid"}
            columns={gridColumns}
            dataGrid={filteredData()}
            tableHeight={`${fixedHeaderScrollHeight}px`}
            onRowClicked={(row: any, event: any) => {}}
            searchLabel={searchLabel}
            onSearchChange={onSearchPatternChange2}
            sortFunction={onColumnSort}
            highlightOnHover={true}
            selectableRows={true}
            multipleSelection={false}
          />
          </div>
          
          {/* <div className="row">
            <div className="col">
              <TableContainer>
                <TableHeaderContainer>
                  <TableToolbar></TableToolbar>
                  <TableSearchContainer></TableSearchContainer>
                </TableHeaderContainer>
                <DataTableContainer>
                  <DataTable
                    fixedHeader={true}
                    persistTableHead={true}
                    fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                    onChangeRowsPerPage={handleRowsPerPageChanged}
                    responsive={true}
                    dense={true}
                    striped={true}
                    highlightOnHover={true}
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={
                      TPGlobal.paginationComponentOptions
                    }
                    columns={adminState.gridColumns}
                    data={filteredData()}
                    noDataComponent={thereAreNoRecordsToShow}
                    sortFunction={TPGlobal.datatableCustomSort}
                    customStyles={tableStyles}
                  />
                </DataTableContainer>
              </TableContainer>
            </div>
          </div> */}
        </TPLoadingOverlay>
      </>
    );
  },
);

export default AssignUserToGroup;
