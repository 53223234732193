import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { CustomSpan } from "@/components/misc/CustomSpan";
import FileUploader from "@/components/TPDragAndDropUploadFile/FileUploader";
import { TPPageSubTitle, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import TPModal from "@/layouts/TPModal/TPModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  ModalSizeEnum,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import DynamicTable, {
  CustomActionProps,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import { MinorOption } from "@/modules/core/components/dynamic-table/TableActionItem";
import { EmailTemplateAttachmentService } from "@/services/EmailTemplateAttachmentService";
import { TPI18N } from "@/services/I18nService";
import { FC, ReactElement, useEffect, useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

interface Attachment {
  id: number;
  emailTemplateId: string;
  blobId: string;
  filename: string;
  extensionName: string;
  date: string;
  sender: string;
  description: string;
  userGuid: string;
  canDeleteTemplateAttachment: boolean;
  dateFormatted: string;
  pathFile: string;
}

interface AttachmentProps {
  recordId: string;
  referenceId?: any;
}

const EmailTemplatesAttachment: FC<AttachmentProps> = ({
  recordId,
  referenceId,
}): ReactElement => {
  const resourceSet = "EmailTemplatesAttachmentComponent";
  const resourceKeys = [
    "TitleLabel",
    "SubTitleLabel",
    "AddAttachmentLabel",
    "RefreshAttachmentLabel",
    "FilenameLabel",
    "DescriptionLabel",
    "ModalTitleLabel",
    "ModalAcceptLabel",
    "ModalCancelLabel",
    "SenderLabel",
    "DescriptionLabelText",
    "DropFilesHereLabel",
    "UploadFileButtonLabel",
    "TabAttachmentsLabel",
    "ModalDeleteTitle",
    "ModalDeleteAcceptLabel",
    "ModalDeleteCancelLabel",
    "ModalConfirmDeleteLabel",
  ];
  const initialCaseAttachmentState = {
    sender: TPGlobal.currentUserName,
    senderErrorMessage: "",
    description: "",
    descriptionErrorMessage: "",
    notFilesErrorMessage: "",
    filesDraggedList: [] as any[],
  };

  const [isDeleteConfirmationShown, setIsDeleteConfirmationShown] =
    useState<boolean>(false);
  const [selectedAttachmentToDelete, setSelectedAttachmentToDelete] =
    useState<Attachment | null>(null);

  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [resources, setResources] = useState<Record<string, string>>({});
  const [deleteLabel, setDeleteLabel] = useState("");
  const [downloadLabel, setDownloadLabel] = useState("");

  const [caseAttachmentState, setCaseAttachmentState] = useState(
    initialCaseAttachmentState
  );

  const loadResources = useCallback(async () => {
    try {
      const resourceValues = await Promise.all(
        resourceKeys.map((key) => TPI18N.GetText(resourceSet, key))
      );

      setResources(
        Object.fromEntries(
          resourceKeys.map((key, index) => [
            `${key.charAt(0).toLowerCase()}${key.slice(1)}`,
            resourceValues[index],
          ])
        )
      );
      setDownloadLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Download"));
      setDeleteLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"));
    } catch (error) {
      console.error("Error loading resources:", error);
    }
  }, []);

  const loadAttachments = useCallback(async () => {
    setIsLoading(true);
    try {
      const attachmentService = new EmailTemplateAttachmentService();
      const response = await attachmentService.getAttachmentByEmailTemplateId(
        recordId || referenceId,
        false,
        true,
        [200, 404]
      );
      setAttachments(response);
    } catch (error) {
      console.error("Error loading attachments:", error);
    } finally {
      setIsLoading(false);
    }
  }, [recordId, referenceId]);

  const handleAddAttachment = useCallback(() => {
    setIsModalShown(true);
  }, []);

  const handleCallBackAddAttachmentModal = useCallback(
    async (response: any) => {
      setIsModalShown(false);
      setIsLoading(true);
      if (
        response === true &&
        caseAttachmentState.filesDraggedList.length > 0
      ) {
        try {
          const attachmentService = new EmailTemplateAttachmentService();

          const uploadPromises = caseAttachmentState.filesDraggedList.map(
            (file) => {
              const formData = new FormData();
              formData.append("fileData", file);
              formData.append(
                "blobId",
                "templateblob" + uuidv4().replaceAll("-", "")
              );
              formData.append("filename", file.name);
              formData.append(
                "extensionName",
                file.name.split(".").pop() || ""
              );
              formData.append("AzurePathFile", "/EmailTemplate");
              formData.append("Sender", caseAttachmentState.sender);
              formData.append("Description", caseAttachmentState.description);
              formData.append("Guid_USER", TPGlobal.currentUserGuid);
              formData.append("emailtemplateId", recordId || referenceId);

              return attachmentService.insertAttachment(
                formData,
                false,
                true,
                [200, 404]
              );
            }
          );

          await Promise.allSettled(uploadPromises);

          handleReload();
        } catch (error) {
          console.error(
            "An unexpected error occurred during the upload process:",
            error
          );
        } finally {
          setIsLoading(false);
          setCaseAttachmentState(initialCaseAttachmentState);
        }
      } else {
        setIsLoading(false);
      }
    },
    [
      caseAttachmentState.filesDraggedList,
      caseAttachmentState.sender,
      caseAttachmentState.description,
      recordId,
      referenceId,
    ]
  );

  const handleUploadFilesDraggedChange = useCallback((filesList: any) => {
    setCaseAttachmentState((prevState) => ({
      ...prevState,
      filesDraggedList: filesList,
    }));
  }, []);

  const handleOnAttachmentValueChange = useCallback(
    (value: string, field: string) => {
      setCaseAttachmentState((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    },
    []
  );

  const handleOnDeleteAttachment = useCallback((fileName: string) => {
    setCaseAttachmentState((prevState) => ({
      ...prevState,
      filesDraggedList: prevState.filesDraggedList.filter(
        (file) => file.name !== fileName
      ),
    }));
  }, []);

  const handleReload = useCallback(() => {
    loadAttachments();
  }, [loadAttachments]);

  const handleDelete = useCallback((attachment: Attachment) => {
    setSelectedAttachmentToDelete(attachment);
    setIsDeleteConfirmationShown(true);
  }, []);

  const confirmDelete = useCallback(async () => {
    if (selectedAttachmentToDelete) {
      setIsLoading(true);
      setIsDeleteConfirmationShown(false);
      setSelectedAttachmentToDelete(null);
      try {
        const attachmentService = new EmailTemplateAttachmentService();
        await attachmentService.deleteAttachmentById(
          selectedAttachmentToDelete.blobId,
          selectedAttachmentToDelete.pathFile,
          true,
          true,
          [200, 404]
        );
        setAttachments((prevAttachments) =>
          prevAttachments.filter(
            (attachment) =>
              attachment.blobId !== selectedAttachmentToDelete.blobId
          )
        );
      } catch (error) {
        console.error("Error deleting attachment:", error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [selectedAttachmentToDelete]);

  const handleDownload = useCallback(
    async (blobId: string, attachmentType: string) => {
      setIsLoading(true);
      try {
        const attachmentService = new EmailTemplateAttachmentService();
        const response = await attachmentService.downloadAttachment(
          blobId,
          "EMAILTEMPLATE",
          false,
          true,
          [200, 404]
        );

        const { sasUri, originalFileName } = response;

        const link = document.createElement("a");
        link.href = sasUri;
        link.setAttribute("download", originalFileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading attachment:", error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      await loadResources();
      await loadAttachments();
    };
    fetchData();
  }, [loadResources, loadAttachments]);

  const {
    titleLabel,
    subTitleLabel,
    addAttachmentLabel,
    refreshAttachmentLabel,
    filenameLabel,
    descriptionLabel,
    modalTitleLabel,
    modalAcceptLabel,
    modalCancelLabel,
    senderLabel,
    descriptionLabelText,
    dropFilesHereLabel,
    uploadFileButtonLabel,
    tabAttachmentsLabel,
    modalDeleteTitleLabel,
    modalDeleteAcceptLabel,
    modalDeleteCancelLabel,
    modalConfirmDeleteLabel,
  } = resources;

  const additionalAction: React.FC<CustomActionProps<Attachment>> = ({
    item,
  }) => (
    <div style={{ display: "flex", gap: "8px" }}>
      <TPButton
        type={TPButtonTypes.icon}
        onClick={() => handleDownload(item.blobId, item.extensionName)}
        icon={TPIconTypes.download}
      />

      <TPButton
        type={TPButtonTypes.icon}
        onClick={() => handleDelete(item)}
        style={{ color: "red" }}
        icon={TPIconTypes.delete}
      />
    </div>
  );

  const tableOptions: MinorOption<Attachment>[] = [
    {
      key: downloadLabel,
      onOptionChange: attachment => handleDownload(attachment.blobId, attachment.extensionName),
      type: "download",
      icon: TPIconTypes.downloadTemplate
    },
    {
      key: deleteLabel,
      onOptionChange: attachment => handleDelete(attachment),
      type: "delete",
      icon: TPIconTypes.delete
    },
  ]

  return (
    <>
      {isDeleteConfirmationShown && (
        <TPModal
          modalState={{
            titleModal: modalDeleteTitleLabel,
            acceptLabel: modalDeleteAcceptLabel,
            cancelLabel: modalDeleteCancelLabel,
            callBackAnswer: (confirm: any) => {
              if (confirm) {
                confirmDelete();
              } else {
                setIsDeleteConfirmationShown(false);
                setSelectedAttachmentToDelete(null);
              }
            },
            isShown: isDeleteConfirmationShown,
            modalWidth: ModalSizeEnum.MODALMD,
          }}
        >
          <div>
            <p>{`${modalConfirmDeleteLabel} "${selectedAttachmentToDelete?.filename}"?`}</p>
          </div>
        </TPModal>
      )}
      {isModalShown && (
        <TPModal
          modalState={{
            titleModal: modalTitleLabel,
            acceptLabel: modalAcceptLabel,
            cancelLabel: modalCancelLabel,
            callBackAnswer: handleCallBackAddAttachmentModal,
            callBackData: null,
            isShown: isModalShown,
            modalWidth: ModalSizeEnum.MODALMD,
          }}
        >
          <div className="row">
            <div className="col">
              <div className="form-group">
                <TPTextBox
                  id="IdTextBox"
                  onChange={(e: any) =>
                    handleOnAttachmentValueChange(e.target.value, "sender")
                  }
                  value={caseAttachmentState.sender}
                  isMandatory={true}
                  labelText={senderLabel}
                  errorMessage={caseAttachmentState.senderErrorMessage}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <TPTextArea
                  id="IdTextArea"
                  onChange={(e: any) =>
                    handleOnAttachmentValueChange(e.target.value, "description")
                  }
                  value={caseAttachmentState.description}
                  isMandatory={false}
                  labelText={descriptionLabelText}
                  errorMessage={caseAttachmentState.descriptionErrorMessage}
                  rows={4}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FileUploader
                label={dropFilesHereLabel}
                multiple={true}
                handleChange={handleUploadFilesDraggedChange}
                UploadButtonText={uploadFileButtonLabel}
              />

              {caseAttachmentState.notFilesErrorMessage && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "0.25rem",
                      fontSize: "0.875em",
                      color: "#DC3545",
                    }}
                  >
                    {caseAttachmentState.notFilesErrorMessage}
                  </div>
                </div>
              )}
              {caseAttachmentState.filesDraggedList.length > 0 && (
                <div className="mt-2">
                  <h5 className="tpbold tp-primary-color">
                    {tabAttachmentsLabel}
                  </h5>
                  <hr />
                  <ul className="new_case__attachments-container">
                    {caseAttachmentState.filesDraggedList.map(
                      (item: any, index: number) => (
                        <li
                          className="d-flex mt-1"
                          key={"lifile" + index.toString()}
                        >
                          <label>{item.name}</label>
                          <label className="new_case__attachments-btn-delete">
                            <TPIcon
                              className="tphover"
                              key={"deletefile" + index.toString()}
                              style={{
                                fontSize: "25px",
                                cursor: "pointer",
                              }}
                              iconType={TPIconTypes.delete}
                              onClick={() =>
                                handleOnDeleteAttachment(item.name)
                              }
                            />
                          </label>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </TPModal>
      )}
      <TPLoadingOverlay active={isLoading}>
        <DynamicTable
          data={attachments}
          hideExport
          onIconClicked={icon => {
            if (icon == TPIconTypes.loop) handleReload();
          }}
          hiddenColumns={["blobId", "canDeleteTemplateAttachment", "date", "dateFormatted",
            "emailTemplateId", "extensionName", "id", "pathFile", "sender", "userGuid"]}
          minorOptions={tableOptions}
          columnNames={{
            filename: filenameLabel,
            description: descriptionLabel
          }}
        >
          <TPButton
            isDesignSystem
            onClick={handleAddAttachment}
            style={{ padding: "11px 16px" }}
          >
            <div className="d-flex flex-row gap-1 align-items-center">
              <TPIcon iconType={TPIconTypes.add} />
              {addAttachmentLabel}
            </div>
          </TPButton>
        </DynamicTable>
      </TPLoadingOverlay>
    </>
  );
};

export default EmailTemplatesAttachment;
