import React from "react";
import {
    WizardStep,
    StepNumberName,
    StepCircle,
    StepLabel
} from "./TPWizardStepperStyles";

interface TPWizardStepProps {
    id: string;
    labelText: string;
    numberText: string;
    onClick: () => void;
    isActive?: boolean;
    isCompleted?: boolean;
}

export const TPWizardStep: React.FC<TPWizardStepProps> = ({
                                                              id,
                                                              labelText,
                                                              numberText,
                                                              isActive,
                                                              isCompleted,
                                                              onClick
                                                          }) => {
    return (
        <WizardStep
            isActive={isActive}
            isCompleted={isCompleted}
            onClick={onClick}
        >
            <StepNumberName>
                <StepCircle
                    isActive={isActive}
                    isCompleted={isCompleted}
                >
                    {isCompleted ? "✓" : numberText}
                </StepCircle>
                <StepLabel>{labelText}</StepLabel>
            </StepNumberName>
        </WizardStep>
    );
};

