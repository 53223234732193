
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { CaseService } from "@/services/CaseService";
import React, { forwardRef, useEffect, useReducer, useState } from "react";
import { LocationLanguagesCaseCreationStats, LocationNameGlobal, LocationNameTable } from "./CaseCreationStatsLocalization";
import { useStatusCaseCreationStats } from "./useStatusCaseCreationStatst";
import { RightRowContainerStyle } from "../FormDesigner/StyleFromDesigner";
import { TPPageSection, TPPageSectionTitle, TPPageTitle } from "../../components/TPPage/tpPageStyles";
import TPWizard from "../../components/TPWizard/TPWizard";
import TPWizardStep from "../../components/TPWizard/TPWizardStep";
import TPGlobal from "../../helpers/TPGlobal";
import TPWizardContent from "../../components/TPWizard/TPWizardContent";
import TPSelect from "../../components/bootstrap/forms/select/TPSelect";
import { TimeZonesService } from "../../services/TimeZonesService";
import { TPKeyValue } from "../../helpers/TPKeyValue";
import TPDatePicker from "../../components/TPDatePicker/TPDatePicker";
import TPLabel from "../../components/bootstrap/forms/TPLabel/TPLabel";
import TPRadioGroup from "../../components/bootstrap/forms/radio/TPRadioGroup";
import { TPActiveOptions, TPButtonTypes, TPIconTypes, TPQuickSelectDatesEnum } from "../../models/Global/TPGlobalEnums";
import { CaseCreationStatsService } from "../../services/CaseCreationStatsService";
import TPModalSuccess from "../../layouts/TPModalSuccess/TPModalSuccess";
import TPModalMsgAlertOk, { enumTypeAlert } from "../../layouts/TPModalMsgAlertOk/TPModalMsgAlertOk";
import TPButton from "../../components/bootstrap/components/buttons/TPButton";
import { DateEditor } from "handsontable/editors";
import DynamicTable, { ColumnStyles, CustomColumnNames } from "../../modules/core/components/dynamic-table/DynamicTable";
import { UserStatsModel, UserStatsModelGrid, inputDashboardModel } from "../../models/CaseCreationStats/CaseCreationStats";
import TPLoadingOverlay from "../../components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import CockpitInfoCard from "../SupervisorCockpit/utils/CockpitInfoCard";
import { InfoCardTypes } from "../SupervisorCockpit/supervisor-cockpit.model";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";



interface Model {
    callBackCommands: Function;
}

enum pageEnum {
    filter,
    List
}

enum pageContainerEnum {
    Calendar
}

type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<any>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};
type commandType = {
    type: commandsEnum;
    payload: any;
};
const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: ''
};

enum commandsEnum {
    'set_filterIsLoaded' = 0,
    'setup_grid_columns' = 1,
    'reload_grid' = 2,
    'change_selectedFilter' = 3,
    'change_search_pattern' = 4
}


const CaseCreationStats = forwardRef(
    ({ callBackCommands }: Model, ref) => {
        const casecontext: any = React.useContext(TPCaseViewerContext);
        const [location, setLocation] = useState(new LocationNameTable);
        const [locationGlobal, setlocationGlobal] = useState(new LocationNameGlobal);

        const [timezone, setTimezone] = useState(TPGlobal.currentUserTimeZoneId);
        const [timezonesToSelect, setTimeZonesToSelect] = useState<Array<TPKeyValue>>([]);
        const [organizationListKeyValue, setOrganizationListKeyValue] = useState<Array<TPKeyValue>>([]);
        const [startDate, setStartDate] = useState<Date>(new Date());
        const [endDate, setEndDate] = useState<Date>(new Date());
        const [quickSelectDates, setQuickSelectDates] = useState(TPQuickSelectDatesEnum.today);
        const [modeAlert, setModeAlert] = useState(enumTypeAlert.success);
        const [page, setPage] = useState(pageEnum.filter);
        const [modalAlert, setModalAlert] = useState(false);
        const [groupSelected, setGroupSelected] = useState("");
        const [groupError, setGroupError] = useState("");
        const [timeZoneErrorMessage, setTimeZoneErrorMessage] = useState("");
        const [groupChangeErrorMessage, setGroupChangeErrorMessage] = useState("");
        const [msgAlert, setMsgAlert] = useState("");
        const [pageActive, setPageActive] = useState("1");
        const [dateStartActive, setDateStartActive] = useState("");


        const setTreeTableData = (prevState: AdminStateType): AdminStateType => {
            let newState: AdminStateType = { ...prevState };
            newState.gridColumns = [prevState.gridData];
            return newState;
        };


        const [adminState, dispatchCommand] = useReducer(doCommand, initialStateBLL);
        function doCommand(prevState: AdminStateType, command: commandType) {
            switch (command.type) {
                case commandsEnum.set_filterIsLoaded:
                    let newStateFilter: AdminStateType;
                    newStateFilter = { ...prevState };
                    newStateFilter.filterIsLoaded = true;
                    return newStateFilter;

                case commandsEnum.setup_grid_columns:
                    let newStateColumns: AdminStateType = setTreeTableData(prevState);
                    newStateColumns.columnsAreLoaded = true;
                    return newStateColumns;

                case commandsEnum.reload_grid:
                    let newStateGrid: AdminStateType;
                    newStateGrid = { ...prevState };
                    newStateGrid.gridData = command.payload;
                    return newStateGrid;

                case commandsEnum.change_selectedFilter:
                    let newStateChangeFilter: AdminStateType;
                    newStateChangeFilter = { ...prevState };
                    newStateChangeFilter.selectedFilter = command.payload;
                    return newStateChangeFilter;

                default:
                    return prevState;
            }
        }

        const getTimezones = async () => {
            const TimeZoneClient = new TimeZonesService();
            const expectedCodes: Array<number> = [200, 404];
            const timezones = await TimeZoneClient.getTimeZonesCollection(
                false,
                true,
                expectedCodes
            );
            const timezonesToSelectConverted: Array<TPKeyValue> = timezones.map((k) => {
                return {
                    key: k.id,
                    value: k.displayName,
                };
            });
            timezonesToSelectConverted.unshift({ key: "", value: "--" });
            setTimezone("");
            setTimeZonesToSelect(timezonesToSelectConverted);
            setLoading(false);
        };

        const handleChangeStartDate = (date: Date) => {
            setStartDate(date);
        };

        const handleChangeEndDate = (date: Date) => {
            setEndDate(date);
        };

        const quickSelectDatesValue = [
            {
                key: TPQuickSelectDatesEnum.today,
                value: location.todayLabel,
            },
            {
                key: TPQuickSelectDatesEnum.yesterday,
                value: location.yesterdayLabel,
            },
            {
                key: TPQuickSelectDatesEnum.currentWeek,
                value: location.currentWeekLabel,
            },
            {
                key: TPQuickSelectDatesEnum.lastWeek,
                value: location.lastWeekLabel,
            },
            {
                key: TPQuickSelectDatesEnum.currentMonth,
                value: location.currentMonthLabel,
            },
            {
                key: TPQuickSelectDatesEnum.lastMonth,
                value: location.lastMonthLabel,
            },
            {
                key: TPQuickSelectDatesEnum.currentYear,
                value: location.currentYearLabel,
            },
            {
                key: TPQuickSelectDatesEnum.lastTwelveMonths,
                value: location.lastTwelveMonthsLabel,
            },
        ];

        const changeDateSelectorHandler = (value: any) => {
            setQuickSelectDates(value);
            const today = new Date();
            const thisYear = today.getFullYear();
            const thisMonth = today.getMonth();
            const previousMonday = getPreviousMonday(today);
            const previousSunday = getPreviousSunday(today);
            switch (value) {
                case TPQuickSelectDatesEnum.today:
                    setStartDate(today);
                    setEndDate(today);
                    break;
                case TPQuickSelectDatesEnum.yesterday:
                    const yesterdayMilliseconds = today.getTime() - 24 * 60 * 60 * 1000;
                    const yesterdayDate = new Date(yesterdayMilliseconds);
                    setStartDate(yesterdayDate);
                    setEndDate(yesterdayDate);
                    break;
                case TPQuickSelectDatesEnum.currentWeek:
                    setStartDate(previousMonday);
                    setEndDate(today);
                    break;
                case TPQuickSelectDatesEnum.lastWeek:
                    const mondayLastWeek =
                        previousSunday.getTime() - 6 * 24 * 60 * 60 * 1000;
                    const mondayLastWeekDate = new Date(mondayLastWeek);
                    setStartDate(mondayLastWeekDate);
                    setEndDate(previousSunday);
                    break;
                case TPQuickSelectDatesEnum.currentMonth:
                    const firstDayOfThisMonth = getFirstDayOfTheMonth(thisYear, thisMonth);
                    setStartDate(firstDayOfThisMonth);
                    setEndDate(today);
                    break;
                case TPQuickSelectDatesEnum.lastMonth:
                    const firstDayPreviousMonth = new Date(thisYear, thisMonth - 1, 1);
                    setStartDate(firstDayPreviousMonth);
                    const lastDayPreviousMonth = new Date(thisYear, thisMonth, 0);
                    setEndDate(lastDayPreviousMonth);
                    break;
                case TPQuickSelectDatesEnum.currentYear:
                    const firstDayOfThisYear = new Date(thisYear, 0, 1);
                    setStartDate(firstDayOfThisYear);
                    setEndDate(today);
                    break;
                case TPQuickSelectDatesEnum.lastTwelveMonths:
                    const twelveMonthsAgo = new Date(thisYear - 1, thisMonth, 1);
                    setStartDate(twelveMonthsAgo);
                    setEndDate(today);
                    break;
                default:
                    break;
            }
        };

        const getFirstDayOfTheMonth = (year: any, month: any) => {
            return new Date(year, month, 1);
        };

        const getPreviousMonday = (date: any) => {
            const previousMonday = new Date();
            previousMonday.setDate(date.getDate() - date.getDay() + 1);
            return previousMonday;
        };

        const getPreviousSunday = (date: any) => {
            const previousSunday = new Date();
            previousSunday.setDate(date.getDate() - date.getDay());
            return previousSunday;
        };

        const loadResourcesAndLoadInfo = async () => {
            setLoading(true);
            let temp = new LocationLanguagesCaseCreationStats();
            await temp.handlelocationTable(resourceSet);
            setLocation(temp.getLocationNameTable);

            let tempGlobal = new LocationLanguagesCaseCreationStats();
            await tempGlobal.handlelocationGlobal(TPGlobal.globalResourceSet);
            setlocationGlobal(tempGlobal.getLocationNameGlobal);

            await getTimezones();
            await handleGetGroupList();

            setLoading(false);
        }

        const handleGroupChange = async (e: any) => {
            setGroupChangeErrorMessage("");
            let newOrganization: string = e.target.value;
            setGroupSelected(newOrganization);

        };

        const handleGetGroupList = async (query?: string) => {
            let tasktypeService = new CaseCreationStatsService();
            let expectedCodes: Array<number> = [200, 404];
            // setBaseLanguageOptions(new Array<TPKeyValue>);

            if (query == undefined) query = "";
            try {
                //Load users by search
                let responseRequest =
                    await tasktypeService.getActiveGroup(
                        query,
                        false,
                        true,
                        expectedCodes
                    );

                let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
                    function (item: any) {
                        return {
                            key: item.userId,
                            value: `${item.firstNameUser} ${item.lastNameUser}`,
                        };
                    }
                );
                newToKeyValueList.push({ key: "", value: "--" });
                setGroupSelected("");
                setOrganizationListKeyValue(newToKeyValueList);
                setLoading(false);
                return newToKeyValueList; // filter
            }
            catch (error) {

                TPLog.Log(
                    `Error ${resourceSet} handleGetGroupList ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${resourceSet} handleGetGroupList ex`
                );
                setLoading(false);
                return [];
            }


        };

        const handleGetTableData = async () => {
            let tasktypeService = new CaseCreationStatsService();
            let expectedCodes: Array<number> = [200, 404];
            // setBaseLanguageOptions(new Array<TPKeyValue>);
            setLoading(true);

            let data = new inputDashboardModel();
            data.endDate = endDate;
            data.initialDate = startDate;
            data.timeZone = timezone;
            

            try {
                //Load users by search
                let responseRequest =
                    await tasktypeService.getFilter(
                        groupSelected,
                        data,
                        false,
                        false,
                        expectedCodes
                    );

                let gridList = [...responseRequest];
                gridList = gridList.filter(s => s.id != -1);

                let invalidDateRangeTemp = [...responseRequest].find(s => s.id == -1);

                let dateNew;
               
                if (invalidDateRangeTemp) {

                    const dateString: string = invalidDateRangeTemp.date;
                    const [year, month, day] = dateString.split('/');
                    dateNew = new Date(Number(year), Number(month) - 1, Number(day));                  
                }

              
                if (invalidDateRangeTemp != undefined && dateNew && startDate < dateNew) {

                    setDateStartActive(invalidDateRangeTemp.date);
                    setModeAlert(enumTypeAlert.error);
                    setModalAlert(true);
                }
                else
                    if (!Array.isArray(gridList) || gridList.length <= 0) {
                        setModeAlert(enumTypeAlert.success);
                        setModalAlert(true);
                    }

                let command1: commandType = {
                    type: commandsEnum.reload_grid,
                    payload: gridList.map(({
                        fullname,
                        status,
                        userStatus,
                        createdCases,
                        timeLogged,
                        workingTime,
                        average,
                        directSolution
                    }: UserStatsModelGrid) => {
                        return {
                            fullname,
                            status: capitalizeFirstLetter(userStatus || status),
                            createdCases,
                            timeLogged,
                            workingTime,
                            average,
                            directSolution
                        };
                    })
                }
                dispatchCommand(command1);

                setLoading(false);

            }
            catch (error) {

                TPLog.Log(
                    `Error ${resourceSet} handleGetGroupList ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(
                    `Error ${resourceSet} handleGetGroupList ex`
                );
                setLoading(false);
                return [];
            }
        };

        function capitalizeFirstLetter(text: string): string {
            if (!text) return text;
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        }

        function formatDate(date: Date): string {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); 
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}/${month}/${day}`;
        }

        const handleValidate = () => {
            let status = true;

          
            if (startDate > endDate) {
                let startDateTemp = formatDate(startDate);
                setDateStartActive(startDateTemp + "");
                setModeAlert(enumTypeAlert.errorThevalueInTheEnd);
                setModalAlert(true);
                status = false;
            } 


            setGroupChangeErrorMessage("");
            setGroupError("");

            if (timezone == "" && status) {
                setTimeZoneErrorMessage(locationGlobal.inputRequire);
                status = false;
            }


            if (groupSelected == "" && status) {
                setGroupError(locationGlobal.inputRequire);
                setGroupChangeErrorMessage(locationGlobal.inputRequire);
                status = false;
            }

            if (status) setPage(pageEnum.List);

            if (startDate <= endDate && (status)) {
                setModalAlert(false);
                setLoading(true);
                handleGetTableData();
            } 
        }

        const handleValidateSelect = (e: string) => {

            let item = Number.parseInt(e);
            let select = 1;

            if (page == pageEnum.filter) select = 1;
            if (page == pageEnum.List) select = 2;

            if (item < select) return true;

            return false;
        }

        const handleMsg = () => {
            let msg = location.msgOkAlert;

            if (modeAlert == enumTypeAlert.error)
                msg = location.msgError.replace("yyyy/mm/dd", " " + dateStartActive);

            if (modeAlert == enumTypeAlert.errorThevalueInTheEnd)
                msg = location.msgDateRangeError.replace("yyyy/mm/dd", " " + dateStartActive)

            return msg;
        }

        const getInfoCardType = (item: string): InfoCardTypes => item.toUpperCase() === "ONLINE" ? "online" : "offline";
          
        const getInfoCardValue = (item: string): string =>  item.toUpperCase() === "ONLINE" ? "online" : "offline";

        const columnStyles: ColumnStyles<UserStatsModelGrid> = {
            status: (row) => (
                <CockpitInfoCard type={getInfoCardType(row.value)} label={getInfoCardValue(row.value)} isSmallCard />
            )
            , timeLogged: row => (
                <span>{handleFormatTerm(row.value)}</span>
            )
            , workingTime: row => (
                <span>{handleFormatTerm(row.value)}</span>
            ), average: row => (
                <span>{handleFormatTerm(row.value)}</span>
            )           

        };

        const handleFormatExport = (term: number): string => {
            let days: number;
            let hours: number;
            let minutes: number;
            let negative: boolean = false;

            if (term < 0) {
                negative = true;
                term = Math.abs(term);
            }

            days = Math.floor(term / (24 * 60));
            term = term % (24 * 60);
            hours = Math.floor(term / 60);
            minutes = term % 60;

            let formated = `${negative ? '- ' : ''}${days > 0 ? `${days} d ` : ''}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

            console.log(formated);

            return formated;
        }

        const handleFormatTerm = (term: number): JSX.Element => {
            let days: number;
            let hours: number;
            let minutes: number;
            let negative: boolean = false;

            if (term < 0) {
                negative = true;
                term = Math.abs(term);
            }

            days = Math.floor(term / (24 * 60));
            term = term % (24 * 60);
            hours = Math.floor(term / 60);
            minutes = term % 60;

            const formattedTime = (
                <>
                    {days > 0 && `${days} d `}
                    {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}
                </>
            );

            return (
                <span style={negative ? { color: "red", marginBottom: 0 } : { color: "green", marginBottom: 0 }}>
                    {negative && '-'}
                    {formattedTime}
                </span>
            );
        }



        const treeColumns: CustomColumnNames<UserStatsModelGrid> = {
            fullname: location.userColumn,
            average: location.averageCaseTimeColumn,
            createdCases: location.createCaseColumn,
            directSolution: location.directSolutionCasesColumn,
            status: location.statusColumn,
            timeLogged: location.timmeLoggedColumn,
            workingTime: location.timmeCreateCasesColumn
        };

        const exportToExcel = (fileName = "case-creation-stats") => {
            let filteredData = [];
            for (let i = 0; i < adminState.gridData.length; i++) {
                let item = adminState.gridData[i];
                filteredData.push({
                    [`${location.userColumn}`]: item.fullname,
                    [`${location.statusColumn}`]: item.status,
                    [`${location.createCaseColumn}`]: item.createdCases,
                    [`${location.timmeLoggedColumn}`]: handleFormatExport(item.timeLogged),
                    [`${location.timmeCreateCasesColumn}`]: handleFormatExport(item.workingTime),
                    [`${location.averageCaseTimeColumn}`]: handleFormatExport(item.average),
                    [`${location.directSolutionCasesColumn}`]: item.directSolution
                });
            }
            const ws = XLSX.utils.json_to_sheet(filteredData);
            XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            FileSaver.saveAs(data, fileName + ".xlsx");
        }

        const handleIconClick = (event: TPIconTypes) => {
            if (event === TPIconTypes.loop) handleGetTableData();
            if (event === TPIconTypes.excel) exportToExcel();
        };

        const value = useStatusCaseCreationStats();
        const {
            resourceSet,
            setLoading,
            loading
        } = value;

        useEffect(() => {           
            setLoading(true);
            loadResourcesAndLoadInfo();
        }, []);


        return (
            <TPLoadingOverlay active={loading}>
                <>
                    {modalAlert &&
                        <TPModalMsgAlertOk
                            modalState={{
                                modeAlert: modeAlert,
                                yesLabel: locationGlobal.okAlertButton,
                                isShown: true,
                                callBackFunction: () => {
                                    setPage(pageEnum.filter);
                                    setModalAlert(false);
                                },
                            }} >
                            <TPLabel
                                labelText={ handleMsg()} />
                        </TPModalMsgAlertOk>
                    }

                    <RightRowContainerStyle>
                        <TPPageTitle> {location.title}</TPPageTitle>
                    </RightRowContainerStyle>
                    <hr />


                    <div className="row">
                        <div className="col">
                            <TPWizard>
                                <TPWizardStep
                                    activeNewComponet={true}
                                    isCheck={handleValidateSelect("1")}
                                    addLine={true}
                                    isActive={(page == pageEnum.filter)}
                                    id="tabFilters"
                                    labelText={locationGlobal.filterLabel}
                                    numberText="1"
                                    name="TPWizardStep"
                                    callBackCommands={(e: string) => {
                                        setPage(pageEnum.filter);
                                    }}
                                />
                                <TPWizardContent
                                    id="tabFilters"
                                    isActive={(page == pageEnum.filter)}
                                    name="TPWizardContent"
                                >

                                    <div className="row">
                                        <div className="col-11">
                                            <TPPageSection>
                                                <div style={{ display: "flex", gap: "5px", justifyContent: "start", marginTop: "10px" }}>
                                                    <TPDatePicker
                                                        selectedDate={startDate}
                                                        onChangeDate={(e: Date) =>
                                                            handleChangeStartDate(e)
                                                        }
                                                        labelText={location.startDateLabel}
                                                        isHorizontal={false}
                                                        isMandatory={true}
                                                    ></TPDatePicker>
                                                    <TPDatePicker
                                                        selectedDate={endDate}
                                                        onChangeDate={(e: Date) =>
                                                            handleChangeEndDate(e)
                                                        }
                                                        labelText={location.endDateLabel}
                                                        isHorizontal={false}
                                                        isMandatory={true}
                                                    ></TPDatePicker>

                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }} >
                                                        <TPLabel
                                                            labelText={location.timezoneLabel}
                                                            isMandatory={true}
                                                        />

                                                        <TPSelect
                                                            id="IdSelect"
                                                            labelText={""}
                                                            isMandatory={false}
                                                            value={timezone}
                                                            containerStyle={{ display: "flex", flexDirection: "column" }}
                                                            onChange={(e: any) => {
                                                                setTimezone(e.target.value);
                                                                setTimeZoneErrorMessage("");

                                                            }}
                                                            dataSource={timezonesToSelect}
                                                            errorMessage={timeZoneErrorMessage}
                                                        />
                                                    </div>
                                                </div>

                                            </TPPageSection>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col">
                                            <TPPageSectionTitle>
                                                {location.quickSelectDatesLabel}
                                            </TPPageSectionTitle>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <TPRadioGroup
                                                    id="IdRadioGroup"
                                                    onChange={(e: any) =>
                                                        changeDateSelectorHandler(e.target.value)
                                                    }
                                                    value={quickSelectDates}
                                                    source={quickSelectDatesValue}
                                                ></TPRadioGroup>
                                            </div>
                                        </div>
                                    </div>


                                    <div style={{ width: "200px", marginTop: "10px" }}>
                                        <TPSelect
                                            id="IdSelect"
                                            onChange={handleGroupChange}
                                            dataSource={organizationListKeyValue}
                                            value={groupSelected}
                                            labelText={location.group}
                                            isHorizontal={false}
                                            isMandatory={true}
                                            errorMessage={groupChangeErrorMessage}
                                        />
                                    </div>

                                    <div
                                        style={{ width: "100%", display: "flex", justifyContent: "end", marginTop: "20px" }}
                                    >
                                        <TPButton
                                            id="IdButton"
                                            type={TPButtonTypes.primary}
                                            onClick={() => { handleValidate(); }}
                                        >
                                            {locationGlobal.search}
                                        </TPButton>

                                    </div>

                                    <div style={{ paddingBottom: "240px" }} ></div>
                                </TPWizardContent>

                                <TPWizardStep
                                    activeNewComponet={true}
                                    addLine={false}
                                    isCheck={handleValidateSelect("2")}
                                    isActive={(page == pageEnum.List)}
                                    id="tabResults"
                                    labelText={locationGlobal.resultsLabel}
                                    numberText="2"
                                    name="TPWizardStep"
                                    callBackCommands={(e: string) => {
                                        setPage(pageEnum.List);
                                    }}
                                />

                                <TPWizardContent
                                    id="tabResults"
                                    isActive={(page == pageEnum.List)}
                                    name="TPWizardContent"
                                >
                                    <br></br>

                                    <DynamicTable
                                        id="CaseCreationStatsId"
                                        data={adminState.gridData}
                                        withPreferences={true}
                                        overrideExportFunction
                                        columnNames={treeColumns}
                                        onIconClicked={event => handleIconClick(event)}
                                        columnStyles={columnStyles}
                                    />

                                </TPWizardContent>
                            </TPWizard>
                        </div>
                    </div>
                </>
            </TPLoadingOverlay>

        );
    }
);

export default CaseCreationStats;
