import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { TPOptionButtonPairStyled } from "@/components/bootstrap/forms/submit/TPOptionButtonPair";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import { TPStyledFormButtonFooter } from "@/components/styled/StyledForms";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  SequenceGeneratorSequencesNameEnum,
  TPButtonTypes,
} from "@/models/Global/TPGlobalEnums";
import {
  OutboundMailAccountInputDTO,
  OutboundMailAccountInputDTOValidator,
} from "@/models/OutboundMailAccount/OutboundMailAccountInputDTO";
import { OutboundMailAccountViewModel } from "@/models/OutboundMailAccount/OutboundMailAccountModel";
import { TPI18N } from "@/services/I18nService";
import { OutboundMailAccountService } from "@/services/OutboundMailAccountService";
import { SequenceService } from "@/services/SequenceService";
import { FC, useEffect, useState } from "react";

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
  renderTab: any;
};

type InsertUpdateStateType = {
  id: string;
  description: string;
  IPServer: string;
  isEnableSSL: boolean;
  port: number | null;
  fromAddress: string;
  fromName: string;
  emailBCC: string;
  replyTo: string;
  userAuth: string;
  passwordAuth: string;
  timeNotrepeat: number ;
  maxTries: number ;
  timeBetweenTries: number;

  //validator
  idErrorMessage: string;
  descriptionErrorMessage: string;
  ipServerErrorMessage: string;
  enableSSLErrorMessage: string;
  portErrorMessage: string;
  fromAddressErrorMessage: string;
  fromNameErrorMessage: string;
  timeNotRepeatErrorMessage: string;
  maxTriesErrorMessage: string;
  timeBetweenTriesErrorMessage: string;
};

const OutboundMailAccountInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
  renderTab
}) => {
  //#region  Init
  const componentFileName: string = "OutboundMailAccountInsertUpdate.tsx";

  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //Screen resources
  const resourceSet: string = "OutboundMailAccountInsertUpdateComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [idLabel, setIdLabel] = useState("");
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [ipServerLabel, setIpServerLabel] = useState("");
  const [sslEnabledLabel, setSslEnabledLabel] = useState("");
  const [portLabel, setPortLabel] = useState("");
  const [fromAddressLabel, setFromAddressLabel] = useState("");
  const [fromNameLabel, setFromNameLabel] = useState("");
  const [emailBccLabel, setEmailBccLabel] = useState("");
  const [replyToAddressLabel, setReplyToAddressLabel] = useState("");
  const [userAuthenticationLabel, setUserAuthenticationLabel] = useState("");
  const [passwordAuthenticationLabel, setPasswordAuthenticationLabel] =
    useState("");
  const [timeNotRepeatLabel, setTimeNotRepeatLabel] = useState("");
  const [maxTriesLabel, setMaxTriesLabel] = useState("");
  const [timeBetweenTriesLabel, setTimeBetweenTriesLabel] = useState("");
  const [isActiveLabel, setIsActiveLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [realMode, setRealMode] = useState(mode);
  const [realRecordId, setRealRecordId] = useState(recordId);
  const [referenceId, setReferenceId] = useState("");

  //Screen state
  let insertUpdateInitialState: InsertUpdateStateType = {
    id: recordId,
    description: "",
    IPServer: "",
    isEnableSSL: true,
    port: null,
    fromAddress: "",
    fromName: "",
    emailBCC: "",
    replyTo: "",
    userAuth: "",
    passwordAuth: "",
    timeNotrepeat: 0,
    maxTries: 0,
    timeBetweenTries: 0,
    idErrorMessage: "",
    descriptionErrorMessage: "",
    ipServerErrorMessage: "",
    enableSSLErrorMessage: "",
    portErrorMessage: "",
    fromAddressErrorMessage: "",
    fromNameErrorMessage: "",
    timeNotRepeatErrorMessage: "",
    maxTriesErrorMessage: "",
    timeBetweenTriesErrorMessage: "",
  };

  //current mode
  const [currentMode, setCurrentMode] = useState(mode);

  const [insertUpdateState, setInsertUpdateState] = useState(
    insertUpdateInitialState,
  );

  //#endregion

  const loadResourcesAndLoadRoleInfo = async () => {
    let i: number;
    //resources state
    setTitleLabel(
      await TPI18N.GetText(resourceSet, "Title" + currentMode + "Label"),
    );
    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel",
      ),
    );
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    //form labels
    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setDescriptionLabel(await TPI18N.GetText(resourceSet, "DescriptionLabel"));
    setIpServerLabel(await TPI18N.GetText(resourceSet, "IpServerLabel"));
    setSslEnabledLabel(await TPI18N.GetText(resourceSet, "SSLIsEnabledLabel"));
    setPortLabel(await TPI18N.GetText(resourceSet, "PortLabel"));
    setFromAddressLabel(await TPI18N.GetText(resourceSet, "FromAddressLabel"));
    setFromNameLabel(await TPI18N.GetText(resourceSet, "FromNameLabel"));
    setEmailBccLabel(await TPI18N.GetText(resourceSet, "EmailBCCLabel"));
    setReplyToAddressLabel(
      await TPI18N.GetText(resourceSet, "ReplyToAdressLabel"),
    );
    setUserAuthenticationLabel(
      await TPI18N.GetText(resourceSet, "UserAuthenticationLabel"),
    );
    setPasswordAuthenticationLabel(
      await TPI18N.GetText(resourceSet, "PasswordAuthenticationLabel"),
    );
    setTimeNotRepeatLabel(
      await TPI18N.GetText(resourceSet, "TimeNotRepeatLabel"),
    );
    setMaxTriesLabel(await TPI18N.GetText(resourceSet, "MaxTriesLabel"));
    setTimeBetweenTriesLabel(
      await TPI18N.GetText(resourceSet, "TimeBetweenTriesLabel"),
    );
    setIsActiveLabel(await TPI18N.GetText(resourceSet, "IsActiveLabel"));

    //screen state

    if (realMode == "Update") {
      await getMailAccountById(realRecordId);
    }
    if (realMode == "Insert") {
      let newInsertUpdateState = { ...insertUpdateState };
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }
  };

  const handleOnIdChange = (newId: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.id = newId;
    newInsertUpdateState.idErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleDescriptionChange = (newDescription: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.description = newDescription;
    newInsertUpdateState.descriptionErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleIPServerChange = (newIpServer: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.IPServer = newIpServer;
    newInsertUpdateState.ipServerErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleSSLChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isEnableSSL = !newInsertUpdateState.isEnableSSL;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handlePortChange = (newPort: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.port = newPort ? parseInt(newPort) : null;
    newInsertUpdateState.portErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleFromAddressChange = (newFromAddress: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.fromAddress = newFromAddress;
    newInsertUpdateState.fromAddressErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleFromNameChange = (newFromName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.fromName = newFromName;
    newInsertUpdateState.fromNameErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleEmailBccChange = (newBCCMail: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.emailBCC = newBCCMail;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleReplyEmailChange = (newReplyEmail: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.replyTo = newReplyEmail;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleUserAuthChange = (newUserAuth: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.userAuth = newUserAuth;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handlePasswordAuthChange = (newPasswordAuth: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.passwordAuth = newPasswordAuth;
    setInsertUpdateState(newInsertUpdateState);
  };

  const hourList: Array<TPKeyValue> = [
    { key: "0", value: "0:00" },
    { key: "15", value: "0:15" },
    { key: "30", value: "0:30" },
    { key: "60", value: "1:00" },
    { key: "120", value: "2:00" },
    { key: "180", value: "3:00" },
    { key: "240", value: "4:00" },
    { key: "300", value: "5:00" },
    { key: "360", value: "6:00" },
    { key: "540", value: "9:00" },
    { key: "720", value: "12:00" },
    { key: "1080", value: "18:00" },
    { key: "1440", value: "24:00" },
  ];

  const minList: Array<TPKeyValue> = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
    { key: "4", value: "4" },
    { key: "5", value: "5" },
    { key: "10", value: "10" },
    { key: "15", value: "15" },
    { key: "20", value: "20" },
    { key: "25", value: "25" },
    { key: "30", value: "30" },
    { key: "45", value: "45" },
    { key: "60", value: "60" },
  ];

  const handleTimeNotRepeatChange = (newTimeNotRepeat: number) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.timeNotrepeat = newTimeNotRepeat;
    newInsertUpdateState.timeNotRepeatErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const triesList: Array<TPKeyValue> = [
    { key: "1", value: "1" },
    { key: "2", value: "2"},
    { key: "3", value: "3" },
    { key: "4", value: "4" },
    { key: "5", value: "5" },
    { key: "6", value: "6" },
    { key: "7", value: "7" },
    { key: "8", value: "8" },
    { key: "9", value: "9" },
    { key: "10", value: "10" },
  ];

  const handleMaxTriesChange = (newMaxTries: number) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.maxTries = newMaxTries;
    newInsertUpdateState.maxTriesErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleTimeBetweenTriesChange = (newTime: number) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.timeBetweenTries = newTime;
    newInsertUpdateState.timeBetweenTriesErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleOkButtonClick = async () => {
    let isInValid: boolean = false;
    let i: number;
    let recordInputDTO: OutboundMailAccountInputDTO = {
      // idAccount: insertUpdateState.id,
      idAccount: realMode == "Insert" ? referenceId : recordId,
      description: insertUpdateState.description,
      ipServer: insertUpdateState.IPServer,
      port: insertUpdateState.port,
      fromName: insertUpdateState.fromName,
      fromAddress: insertUpdateState.fromAddress,
      timeNotRepeatMail: insertUpdateState.timeNotrepeat,
      timeBetweenRetries: insertUpdateState.timeBetweenTries,
      maxTries: insertUpdateState.maxTries,
      user: insertUpdateState.userAuth,
      password: insertUpdateState.passwordAuth,
      isEnableSsl: insertUpdateState.isEnableSSL,
      emailAddressBcc: insertUpdateState.emailBCC,
      emailAddressReplyTo: insertUpdateState.replyTo,
    };

    const inputDTOValidator = new OutboundMailAccountInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      if (resultValidator.idAccount) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.idAccount,
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }

      if (resultValidator.description) {
        newInsertUpdateState.descriptionErrorMessage = await TPI18N.GetResource(
          resultValidator.description,
        );
      } else {
        newInsertUpdateState.descriptionErrorMessage = "";
      }

      if (resultValidator.fromAddress) {
        newInsertUpdateState.fromAddressErrorMessage = await TPI18N.GetResource(
          resultValidator.fromAddress,
        );
      } else {
        newInsertUpdateState.fromAddressErrorMessage = "";
      }

      if (resultValidator.fromName) {
        newInsertUpdateState.fromNameErrorMessage = await TPI18N.GetResource(
          resultValidator.fromName,
        );
      } else {
        newInsertUpdateState.fromNameErrorMessage = "";
      }

      if (resultValidator.ipServer) {
        newInsertUpdateState.ipServerErrorMessage = await TPI18N.GetResource(
          resultValidator.ipServer,
        );
      } else {
        newInsertUpdateState.ipServerErrorMessage = "";
      }

      if (resultValidator.port) {
        newInsertUpdateState.portErrorMessage = await TPI18N.GetResource(
          resultValidator.port,
        );
      } else {
        newInsertUpdateState.portErrorMessage = "";
      }

      if (resultValidator.timeNotRepeatMail) {
        newInsertUpdateState.timeNotRepeatErrorMessage = await TPI18N.GetResource(
          resultValidator.timeNotRepeatMail,
        );
      } else {
        newInsertUpdateState.timeNotRepeatErrorMessage = "";
      }

      if (resultValidator.timeBetweenRetries) {
        newInsertUpdateState.timeBetweenTriesErrorMessage = await TPI18N.GetResource(
          resultValidator.timeBetweenRetries,
        );
      } else {
        newInsertUpdateState.timeBetweenTriesErrorMessage = "";
      }

      if (resultValidator.maxTries) {
        newInsertUpdateState.maxTriesErrorMessage = await TPI18N.GetResource(
          resultValidator.maxTries,
        );
      } else {
        newInsertUpdateState.maxTriesErrorMessage = "";
      }

      setInsertUpdateState(newInsertUpdateState);
      isInValid = true;
    }

    if (!isInValid) {
      if (realMode == "Insert") {
        await insertMailAccount(recordInputDTO);
      } else {
        await updateMailAccount(recordInputDTO);
      }
    }
  };

  const insertMailAccount = async (inputDTO: OutboundMailAccountInputDTO) => {
    let serviceClient = new OutboundMailAccountService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertMailAccount(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        // callBackResult({
        //   //result: "save_general_insert_and_change_mode",
        //   result: "save_general_update",
        //   recordId: inputDTO.idAccount,
        // });
        callBackResult({ result: "OK", recordId: recordId });
        //change mode
        // setTitleLabel(await TPI18N.GetText(resourceSet, "TitleUpdateLabel"));
        // setRealRecordId(inputDTO.idAccount);
        // setOriginalRecordDescription(
        //   inputDTO.description.length > 100
        //     ? inputDTO.description.substring(0, 100) + "..."
        //     : inputDTO.description
        // );
        // setRealMode("Update");
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertMailAccount ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} insertMailAccount ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateMailAccount = async (inputDTO: OutboundMailAccountInputDTO) => {
    let serviceClient = new OutboundMailAccountService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.updateMailAccount(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "cancel_general", recordId: recordId });
  };

  const getMailAccountById = async (pRecordId: string) => {
    let serviceClient = new OutboundMailAccountService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getMailAccountById(
        pRecordId,
        false,
        true,
        expectedCodes,
      );

      let recordInfo: OutboundMailAccountViewModel;
      recordInfo = { ...responseRequest };
      setOriginalRecordDescription(
        recordInfo.description.length <= 100
          ? recordInfo.description
          : recordInfo.description.substring(0, 100) + "...",
      );

      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.description = recordInfo.description;
      newInsertUpdateState.IPServer = recordInfo.ipServer;
      newInsertUpdateState.isEnableSSL = recordInfo.isEnableSSL;
      newInsertUpdateState.port = recordInfo.port;
      newInsertUpdateState.fromAddress = recordInfo.fromAddress;
      newInsertUpdateState.fromName = recordInfo.fromName;
      newInsertUpdateState.emailBCC = recordInfo.emailAddressBCC;
      newInsertUpdateState.replyTo = recordInfo.emailAddressReplyTo;
      newInsertUpdateState.userAuth = recordInfo.user;
      newInsertUpdateState.passwordAuth = recordInfo.password;
      newInsertUpdateState.timeNotrepeat = recordInfo.timeNotRepeatMail;
      newInsertUpdateState.maxTries = recordInfo.maxTries;
      newInsertUpdateState.timeBetweenTries = recordInfo.timeBetweenRetries;
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getFunctionById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getFunctionById ex`);
      setIsLoadingScreen(false);
    }
  };

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQMAAC,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        // callBackResult({ result: "OK", recordId: recordId });
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResourcesAndLoadRoleInfo();
    realMode == "Insert" && generalAutomaticId();
  }, []);

  return (
    //#region  Render
      <TPLoadingOverlay active={isLoadingScreen}>

        <div className="row">
          <div className="col-6">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            {realMode == "Update" ? (
              <TPPageSubTitle>
                {`${subTitleLabel}: ${recordId} / ${originalRecordDescription}`}
              </TPPageSubTitle>
            ) : null}
          </div>
        </div>

          <div  style={{marginBottom: '1rem'}}>
            {renderTab}
          </div>

        <div className="row">
          <div className="col-6">
            <TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            <TPPageSection>
              <div className="form-group">
                <TPTextBox
                  id="IdTextBox"
                  labelText={idLabel}
                  isMandatory={true}
                  // value={insertUpdateState.id}
                  value={realMode == "Insert" ? referenceId : recordId}
                  onChange={
                    realMode == "Insert"
                      ? (e: any) => handleOnIdChange(e.target.value)
                      : () => {
                          TPGlobal.foo();
                        }
                  }
                  maxLength={50}
                  // disabled={mode == "Insert" ? false : true}
                  disabled
                  errorMessage={insertUpdateState.idErrorMessage}
                />
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      isMandatory={true}
                      labelText={descriptionLabel}
                      value={insertUpdateState.description}
                      onChange={(e: any) =>
                        handleDescriptionChange(e.target.value)
                      }
                      maxLength={200}
                      errorMessage={insertUpdateState.descriptionErrorMessage}
                    />
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            <div className="form-group">
              <div className="row">
                <div className="col-6">
                  <TPPageSection>
                    <TPTextBox
                      id="IdTextBox"
                      isMandatory={true}
                      labelText={ipServerLabel}
                      value={insertUpdateState.IPServer}
                      onChange={(e: any) =>
                        handleIPServerChange(e.target.value)
                      }
                      maxLength={50}
                      errorMessage={insertUpdateState.ipServerErrorMessage}
                    />
                  </TPPageSection>
                </div>
                <div
                  className="col-6"
                  // style={{ display: "flex", alignItems: "center" }}
                >
                  <TPPageSection>
                    <TPNumeric
                      id="IdTPNumeric"
                      isMandatory={true}
                      onChange={(e: any) => handlePortChange(e)}
                      value={insertUpdateState.port}
                      labelText={portLabel}
                      maxLength={5}
                      errorMessage={insertUpdateState.portErrorMessage}
                    />
                  </TPPageSection>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            <div className="form-group">
              <div className="row">
                <div className="col-6">
                  <TPPageSection>
                    <TPTextBox
                      id="IdTextBox"
                      isMandatory={true}
                      labelText={fromNameLabel}
                      value={insertUpdateState.fromName}
                      onChange={(e: any) =>
                        handleFromNameChange(e.target.value)
                      }
                      maxLength={100}
                      errorMessage={insertUpdateState.fromNameErrorMessage}
                    />
                  </TPPageSection>
                </div>
                <div className="col-6">
                  <TPPageSection>
                    <TPTextBox
                      id="IdTextBox"
                      isMandatory={true}
                      labelText={fromAddressLabel}
                      value={insertUpdateState.fromAddress}
                      onChange={(e: any) =>
                        handleFromAddressChange(e.target.value)
                      }
                      maxLength={100}
                      errorMessage={insertUpdateState.fromAddressErrorMessage}
                    />
                  </TPPageSection>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            <div className="form-group">
              <div className="row">
                <div className="col-6">
                  <TPPageSection>
                    <TPTextBox
                      id="IdTextBox"
                      labelText={emailBccLabel}
                      value={insertUpdateState.emailBCC}
                      onChange={(e: any) =>
                        handleEmailBccChange(e.target.value)
                      }
                      maxLength={100}
                    />
                  </TPPageSection>
                </div>
                <div className="col-6">
                  <TPPageSection>
                    <TPTextBox
                      id="IdTextBox"
                      labelText={replyToAddressLabel}
                      value={insertUpdateState.replyTo}
                      onChange={(e: any) =>
                        handleReplyEmailChange(e.target.value)
                      }
                      maxLength={500}
                    />
                  </TPPageSection>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            <div className="form-group">
              <div className="row">
                <div className="col-6">
                  <TPPageSection>
                    <TPTextBox
                      id="IdTextBox"
                      labelText={userAuthenticationLabel}
                      value={insertUpdateState.userAuth}
                      onChange={(e: any) =>
                        handleUserAuthChange(e.target.value)
                      }
                      maxLength={100}
                    />
                  </TPPageSection>
                </div>
                <div className="col-6">
                  <TPPageSection>
                    <TPTextBox
                      id="IdTextBox"
                      type="password"
                      labelText={passwordAuthenticationLabel}
                      value={insertUpdateState.passwordAuth}
                      onChange={(e: any) =>
                        handlePasswordAuthChange(e.target.value)
                      }
                      maxLength={100}
                    />
                  </TPPageSection>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            <div className="form-group">
              <div className="row">
                <div className="col-6">
                  <TPPageSection>
                    <TPSelect
                      id="timeNotrepeat"
                      isMandatory={true}
                      onChange={(e: any) =>
                        handleTimeNotRepeatChange(parseInt(e.target.value))
                      }
                      dataSource={hourList}
                      value={insertUpdateState.timeNotrepeat.toString()}
                      labelText={timeNotRepeatLabel}
                      errorMessage={insertUpdateState.timeNotRepeatErrorMessage}
                    />
                  </TPPageSection>
                </div>
                <div className="col-6">
                  <TPPageSection>
                    <TPSelect
                      id="timeBetweenTries"
                      isMandatory={true}
                      onChange={(e: any) =>
                        handleTimeBetweenTriesChange(parseInt(e.target.value))
                      }
                      dataSource={minList}
                      value={insertUpdateState.timeBetweenTries.toString()}
                      labelText={timeBetweenTriesLabel}
                      errorMessage={
                        insertUpdateState.timeBetweenTriesErrorMessage
                      }
                    />
                  </TPPageSection>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            <div className="form-group">
              <div className="row">
                <div className="col-12">
                  <TPPageSection>
                    <TPSelect
                      id="maxTries"
                      isMandatory={true}
                      onChange={(e: any) =>
                        handleMaxTriesChange(parseInt(e.target.value))
                      }
                      dataSource={triesList}
                      value={insertUpdateState.maxTries.toString()}
                      labelText={maxTriesLabel}
                      errorMessage={insertUpdateState.maxTriesErrorMessage}
                    ></TPSelect>
                  </TPPageSection>
                </div>
                <div className="col-6 mt-4">
                  <TPPageSection>
                    <div className="form-group">
                      <TPCheckBox
                        id="IdCheckBox"
                        labelText={sslEnabledLabel}
                        checked={insertUpdateState.isEnableSSL}
                        onChange={(e: any) => handleSSLChange()}
                      ></TPCheckBox>
                    </div>
                  </TPPageSection>
                </div>
              </div>
            </div>
          </div>
        </div>

        <TPStyledFormButtonFooter>
          <TPOptionButtonPairStyled
            cancelLabel={cancelButtonLabel}
            confirmLabel={saveButtonLabel}
            onCancel={handleCancelButtonClick}
            onConfirm={handleOkButtonClick}
            confirmDisabled={
              !(
                insertUpdateState.description &&
                insertUpdateState.IPServer &&
                insertUpdateState.fromName &&
                insertUpdateState.fromAddress &&
                insertUpdateState.timeBetweenTries !== 0 &&
                insertUpdateState.maxTries !== 0 &&
                (insertUpdateState.port?.toString().trim() || "")
              )
            }
          />
        </TPStyledFormButtonFooter>
      </TPLoadingOverlay>
    //#endregion
  );
};

export default OutboundMailAccountInsertUpdate;
