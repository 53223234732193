import React, { ReactNode } from "react";
import { WizardBody } from "./TPWizardStepperStyles";

interface TPWizardContentProps {
    id: string;
    isActive: boolean;
    children: ReactNode;
}

export const TPWizardContent: React.FC<TPWizardContentProps> = ({
                                                                    id,
                                                                    isActive,
                                                                    children
                                                                }) => {
    if (!isActive) return null;
    return <WizardBody>{children}</WizardBody>;
};
