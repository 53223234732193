import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSectionTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  commandType,
  commandsEnum as event,
} from "@/layouts/VerticalTabs/VerticalTabsAdminContainer";
import {
  SequenceGeneratorSequencesNameEnum,
  TPButtonTypes,
} from "@/models/Global/TPGlobalEnums";
import { StoreModel } from "@/redux/store";
import { EmailTemplateService } from "@/services/EmailTemplateService";
import { SequenceService } from "@/services/SequenceService";
import { useFormik } from "formik";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EmailTemplateCloneFormSlice,
  EmailTemplateCloneFormStateModel,
} from "./_redux/EmailTemplateCloneFormSlice";
import e from "./models/EmailTemplateCloneEnum";
import { EmailTemplateCloneFormControl } from "./models/EmailTemplateCloneFormControl";
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";
import {
  MultilingualTextBoxEvents
} from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";
import { TPStyledForm, TPStyledFormButtonFooter, TPStyledFormRow, TPStyledInnerInputForm, TPStyledSubtitle } from "@/components/styled/StyledForms";
import { TPI18N } from "@/services/I18nService";
import { TPOptionButtonPairStyled } from "@/components/bootstrap/forms/submit/TPOptionButtonPair";

interface EmailTemplateCloneFormProps {
  tabId: string;
  element: any;
  globalDispatch: (action: commandType) => void;
}

export default function EmailTemplateCloneForm({
  tabId,
  element,
  globalDispatch,
}: EmailTemplateCloneFormProps) {
  const dispatch = useDispatch();

  const {
    message: m,
    mailAccounts,
    result,
  } = useSelector(
    (s: StoreModel) => s[EmailTemplateCloneFormSlice.name]
  ) as EmailTemplateCloneFormStateModel;

  const componentFileName = "EmailTemplateCloneForm";
  const [languageLabel, setLanguageLabel] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [languages, setLanguages] = useState<any>({});
  const [isActiveLabel, setIsActiveLabel] = useState("");
  const nameInputRef = useRef<MultilingualTextBoxEvents>();
  const formik = useFormik({
    initialValues: {
      ...EmailTemplateCloneFormControl.initialValues,
      sourceId: element.id as string,
    },
    validationSchema: EmailTemplateCloneFormControl.validationSchema,
    validateOnChange: false,
    onSubmit: async () => {
      setIsModelOpen(true);
    },
  });

  const getResources = async function() {
    setIsActiveLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ShowAsActive"));
  }

  useEffect(() => {
    dispatch(
      EmailTemplateCloneFormSlice.actions.message([
        e.TitleLabel,
        e.ElementToCloneLabel,
        e.NewTemplateIdLabel,
        e.DescriptionLabel,
        e.EmailAccountLabel,
        e.ParametersLabel,
        e.IsActiveLabel,
        e.CanBeUsedLabel,
        e.RequiredFieldLabel,
        e.Maximum20CharactersAllowedLabel,
        e.Maximum100CharactersAllowedLabel,
        e.OkButtonLabel,
        e.CancelButtonLabel,
        e.LanguageButtonTooltipLabel,
        e.LanguageModalTitleLabel,
        e.ConfirmModalTitleLabel,
        e.ConfirmModalQuestionLabel,
        e.ConfirmModalOkButtonLabel,
      ])
    );
    dispatch(EmailTemplateCloneFormSlice.actions.getMailAccounts());
    setLanguageLabel(
      TPGlobal.TPClientAvailableLanguages.find(
        ({ id }) => id === TPGlobal.language
      )?.name
    );
    getResources();
    setLoading(false);
  }, []);

  useEffect(
    () => () => {
      dispatch(EmailTemplateCloneFormSlice.actions.clean());
    },
    []
  );

  useEffect(() => {
    generalAutomaticId();
  }, []);

  useEffect(() => {
    const l = Object.entries(languages).map(
      ([languageId, localizedValue], order) => ({
        languageId,
        localizedValue,
        order,
      })
    );
    formik.setFieldValue("descriptionLocalizedValues", l);
  }, [languages]);

  async function responseModelHandler(response: boolean) {
    setIsModelOpen(false);
    if (response) {
      let list = nameInputRef.current?.getValue().filter((s) => s.value);
      const firstLanguage = list?.find(
        (s) => s.id == TPGlobal.TPClientDefaultLanguage
      );
      if (firstLanguage) {
        formik.setFieldValue("description", firstLanguage.value || "");
      }
      let temp = new Array();
      if (list) {
        temp = list?.map((e: any, index) => {
          return {
            languageId: e.id,
            localizedValue: e.value,
            order: index,
          };
        });
        formik.values.descriptionLocalizedValues = temp;
      }
      const request: any = {
        ...formik.values,
        descriptionLocalizedValues: temp,
      };

      const { cloneEmailTemplate } = new EmailTemplateService();
      cloneEmailTemplate(request, true, true, [200])
        .then((e: any) => {
          globalDispatch({ type: event.vertical_tab_close, payload: tabId });
          globalDispatch({ type: event.reloadGrid });
          if (e.responseResult)
            globalDispatch({
              type: event.update_vertical_tab,
              payload: {
                recordId: `${request.destinationId}`,
                languageId: TPGlobal.language,
                recordDescription: `${request.destinationId}`,
              },
            });
        })
        .catch((error) => console.error(error));
    }
  }

  const handleValidate = () => {
    let language = nameInputRef.current
      ?.getValue()
      .find((s) => (s.id = TPGlobal.TPClientDefaultLanguage));
    if (
      !language ||
      !language.value ||
      language.value == "" ||
      language.value.length == 0
    )
      return true;
    return false;
  };
  function onChangeHandler(event: any, attribute: string) {
    const value = event.target.value;
    formik.setFieldValue(attribute, value);
  }

  function onClickCancelButtonHandler() {
    globalDispatch({ type: event.vertical_tab_close, payload: tabId });
    dispatch(EmailTemplateCloneFormSlice.actions.close(element.id));
  }

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQEMTE
      );

      if (responseRequest.responseResult) {
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        formik.setFieldValue("destinationId", result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setLoading(false);
    }
  };

  return (
    <>
      {isModelOpen && (
        <TPModalQuestion
          title={m?.[e.ConfirmModalTitleLabel]}
          yesLabel={m?.[e.ConfirmModalOkButtonLabel]}
          noLabel={m?.[e.CancelButtonLabel]}
          question={m?.[e.ConfirmModalQuestionLabel]}
          callBackData={{}}
          isShown={isModelOpen}
          callBackAnswer={responseModelHandler}
        />
      )}
      <TPLoadingOverlay wrapperStyle={{overflow: "visible"}} active={loading}>
        <div className="d-flex flex-column p-3" style={{ gap: "24px" }}>
          <TPPageTitle style={{ margin: 0 }}>{m?.[e.TitleLabel]}</TPPageTitle>
          <TPStyledForm>
            <TPStyledInnerInputForm>
              <TPStyledFormRow>
                <TPTextBox
                  id="email-templates-clone-source-output"
                  disabled
                  labelText={m?.[e.ElementToCloneLabel]}
                  value={`${element.description ?? ""}${element.description
                    ? ` (${formik.values.sourceId})` : formik.values.sourceId}`}
                  onChange={(e: any) => onChangeHandler(e, "sourceId")}
                  maxLength={20}
                  errorMessage={formik.errors.sourceId && m?.[formik.errors.sourceId]}
                />
              </TPStyledFormRow>
              <TPStyledFormRow>
                <TPTextBox
                  id="email-templates-clone-destionation-id-output"
                  disabled
                  labelText={m?.[e.NewTemplateIdLabel]}
                  value={formik.values.destinationId}
                  onChange={(e: any) => onChangeHandler(e, "destinationId")}
                  maxLength={20}
                  errorMessage={formik.errors.destinationId && m?.[formik.errors.destinationId]}
                />
              </TPStyledFormRow>
              <TPStyledFormRow>
                <MultilingualTextBox
                  ref={nameInputRef}
                  style={{ width: "calc(100% + 40px)" }}
                  value={formik.values.descriptionLocalizedValues?.map(
                    ({ languageId, localizedValue }) => ({
                      id: languageId,
                      value: localizedValue,
                    })
                  )}
                  onChange={(e: any) => {
                    let list = nameInputRef.current
                      ?.getValue()
                      .filter((s) => s.value);
                    let temp = list?.map((e: any, index) => {
                      return {
                        languageId: e.id,
                        localizedValue: e.localizedValue,
                        order: index,
                      };
                    });
                    const firstLanguage = list?.find((s) => s.id == TPGlobal.TPClientDefaultLanguage);
                    if (firstLanguage) formik.setFieldValue("description", firstLanguage.value || "");
                  }}
                />
              </TPStyledFormRow>
              <TPStyledFormRow>
                <TPSelect
                  id="email-templates-clone-account-input"
                  isMandatory={false}
                  labelText={m?.[e.EmailAccountLabel]}
                  onChange={(e: any) => onChangeHandler(e, "idAccount")}
                  dataSource={mailAccounts}
                  value={formik.values.idAccount}
                  errorMessage={formik.errors.idAccount}
                />
              </TPStyledFormRow>
            </TPStyledInnerInputForm>
            <TPStyledInnerInputForm>
              <TPStyledSubtitle>{m?.[e.ParametersLabel]}</TPStyledSubtitle>
              <TPStyledFormRow>
                <TPCheckBox
                  id="email-templates-clone-can-be-used-input"
                  labelText={m?.[e.CanBeUsedLabel]}
                  checked={formik.values.isAddable}
                  onChange={() =>
                    onChangeHandler({ target: { value: !formik.values.isAddable } }, "isAddable")}
                />
                <TPCheckBox
                  id="email-templates-clone-is-active-input"
                  labelText={isActiveLabel}
                  checked={formik.values.isActive}
                  onChange={() =>
                    onChangeHandler({ target: { value: !formik.values.isActive } }, "isActive")}
                />
              </TPStyledFormRow>
            </TPStyledInnerInputForm>
            <TPStyledFormButtonFooter>
              <TPOptionButtonPairStyled
                cancelLabel={m?.[e.CancelButtonLabel]}
                confirmLabel={m?.[e.OkButtonLabel]}
                onCancel={onClickCancelButtonHandler}
                onConfirm={() => {
                  let list = nameInputRef.current
                    ?.getValue()
                    .filter((s) => s.value);
                  const firstLanguage = list?.find(
                    (s) => s.id == TPGlobal.TPClientDefaultLanguage
                  );
                  if (firstLanguage && firstLanguage.value)
                    formik.handleSubmit();
                }}
                confirmDisabled={handleValidate()}
              />
            </TPStyledFormButtonFooter>
          </TPStyledForm>
        </div>
      </TPLoadingOverlay>
    </>
  );
}

const styles = {
  iconButton: {
    marginTop: "auto",
  } as CSSProperties,
};
