import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
    TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import { TPActiveOptions, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TreeTableData, TreeViewModel } from "@/models/Tree/TreeModels";
import DynamicTable, {
    ColumnStyles,
    CustomColumnNames,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import { MinorOption } from "@/modules/core/components/dynamic-table/TableActionItem";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { TPI18N } from "@/services/I18nService";
import { TreeService } from "@/services/TreeService";
import React, {
    useEffect,
    useImperativeHandle,
    useReducer,
    useState,
} from "react";
import { boolean } from "yup";
import { FilterTreeStyle } from "../Style";
import SearchInput from "../../../modules/core/design-system/inputs/SearchInput";
import TPIcon from "../../../components/bootstrap/extend/TPIcons/TPIcon";


interface TreesFilterModel {
    onChangeFilter?: Function;
    onChangeOrder?: Function;
    onchangedSelect?: Function;
    searchPlaceholder: string;
    dataSourceFilter?: Array<TPKeyValue>;
    activeOrder?: boolean;
}


const TPFilter = React.forwardRef(
    ({ onChangeFilter,
        onChangeOrder,
        activeOrder,
        searchPlaceholder }: TreesFilterModel, ref) => {
        const componentFileName: string = "TPFilter.tsx";

        //Functions called form parent VerticalTabsAdminContainer
        useImperativeHandle(ref, () => ({
            refreshGridFromParent() {

            },
        }));


        return (
            <>
                <FilterTreeStyle>
                    <div>
                        <SearchInput
                            id={"search-input-tree"}
                            onChange={(e: any) => {
                                if (onChangeFilter) onChangeFilter(e);
                            }}
                            placeholder={searchPlaceholder}
                            width="auto"
                            height={"36px"}

                        />
                    </div>
                    <div>
                        {/*<TPSelect*/}
                        {/*    key={"select-tree"}*/}
                        {/*    id={`select-tree`}*/}
                        {/*    onChange={(e: any) => {onchangedSelect() }}*/}
                        {/*    dataSource={dataSourceFilter}*/}
                        {/*    value={null}*/}
                        {/*    placeholder={"..."}*/}
                        {/*    maxWidth={100}*/}
                        {/*    isHorizontal={true}*/}
                        {/*    isDynamic={false}*/}
                        {/*/>*/}
                    </div>

                    <TPIcon iconType={TPIconTypes.filterList}
                        style={activeOrder ? {
                            cursor: "pointer",  fontSize: "24px",
                            padding: "5px", borderRadius: "5px", backgroundColor: "#a00095", color:"#ffffff"

                        } :
                            {
                                cursor: "pointer", color: "rgba(46, 46, 46, 1)", fontSize: "24px",
                                padding: "5px", borderRadius: "5px"

                            }

                        } onClick={() => {
                            if (onChangeOrder) onChangeOrder();
                        }}
                    />

                </FilterTreeStyle>
            </>
        );
    }
);

export default TPFilter;
