import { ReactNode } from "react";

export interface TPEmthyModalModel {
    visible: boolean
    onAccept: Function;
    onCancel: Function;
    title?: string;
    title2?: string;
    gap?: string;
    children?: ReactNode;
    buttonNameActive?: TypeNameButton,
    buttonSecondaryActive?: boolean,
    btnJustifyContent?:  "start" | "center" | "end"
    disabled: boolean; 
    marginTopContainer?: string;
    btnChildren?: ReactNode;
    width?: string;
}

export enum TypeNameButton {
    saveAndCancel,
    yesAndNo,
    applyAnCancel
} 