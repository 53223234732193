import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
    TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import { TPActiveOptions, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TreeTableData, TreeViewModel } from "@/models/Tree/TreeModels";
import DynamicTable, {
    ColumnStyles,
    CustomColumnNames,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import { MinorOption } from "@/modules/core/components/dynamic-table/TableActionItem";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { TPI18N } from "@/services/I18nService";
import { TreeService } from "@/services/TreeService";
import React, {
    useEffect,
    useImperativeHandle,
    useReducer,
    useState,
} from "react";
import { boolean } from "yup";

type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<TreeTableData>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};

type Car = {
    id: number;
    name: string;
    color: string;
    plate: string;
    isSold: boolean;
    carDescription: string;
};

interface TreesAdminInterface {
    callBackCommands: Function;
}

enum commandsEnum {
    "set_filterIsLoaded" = 0,
    "setup_grid_columns" = 1,
    "reload_grid" = 2,
    "change_selectedFilter" = 3,
}
type commandType = {
    type: commandsEnum;
    payload: any;
};

const TreesAdmin = React.forwardRef(
    ({ callBackCommands }: TreesAdminInterface, ref) => {
        const componentFileName: string = "TreesAdmin.tsx";

        //Functions called form parent VerticalTabsAdminContainer
        useImperativeHandle(ref, () => ({
            refreshGridFromParent() {
                reloadGridCommand();
            },
        }));

        //#region Init
        //screen loading
        const [isLoadingScreen, setIsLoadingScreen] = useState(true);
        //Screen resources
        const ResourceSet: string = "TreeAdminComponent";
        const [titleLabel, setTitleLabel] = useState("");
        const [newLabel, setNewLabel] = useState("");
        const [deleteLabel, setDeleteLabel] = useState("");
        const [updateLabel, setUpdateLabel] = useState("");
        const [yesLabel, setYesLabel] = useState("");
        const [noLabel, setNoLabel] = useState("");
        const [noTreesFound, setNoTreesFound] = useState("");
        const [filterIsActiveLabel, setFilterLabel] = useState("");
        //const [checkboxFilterActiveLabel, setCheckboxFilterActiveLabel] = useState("");
        //const [checkboxFilterInActiveLabel, setCheckboxFilterInActiveLabel] = useState("");

        //grid columns
        const [idColumnLabel, setIdColumnLabel] = useState("");
        const [nameColumnLabel, setNameColumnLabel] = useState("");
        const [
            mustSelectLastBranchColumnLabel,
            setMustSelectLastBranchColumnLabel,
        ] = useState("");
        const [commentsColumnLabel, setCommentsColumnLabel] = useState("");
        const [isClassifierColumnLabel, setIsClassifierColumnLabel] = useState("");
        const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");
        const [isSystemRecordColumnLabel, setIsSystemColumnLabel] = useState("");
        const [importLabel, setImportLabel] = useState("");

        const [statusCheckboxes, setStatusCheckboxes] = useState({
            active: false,
            inActive: false,
        });

        //modal resources
        const [deleteQuestion, setDeleteQuestion] = useState("");
        const [deleteTitle, setDeleteTitle] = useState("");
        const [deleteOkLabel, setDeleteOkLabel] = useState("");
        const [deleteCancelLabel, setDeleteCancelLabel] = useState("");

        //State modal
        let modalQuestionInitialState: TPModalQuestionState = {
            isShown: false,
            callBackData: {},
        };
        const [modalQuestionState, setModalQuestionState] = useState(
            modalQuestionInitialState
        );

        //State filter dropdown
        const [filterKeyValue, setFilterKeyValue] = useState<TPKeyValue[]>([]);

        //#endregion

        //Load Resources and fill Active Filter
        const loadResourcesAndTreeFilter = async () => {
            //modal
            setDeleteQuestion(
                await TPI18N.GetText(ResourceSet, "RecordDeleteConfirm")
            );
            setDeleteTitle(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle")
            );
            setDeleteOkLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
            );
            setDeleteCancelLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
            );

            //screen
            setDeleteLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel")
            );
            setUpdateLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel")
            );
            setYesLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
            );
            setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));
            setNewLabel(await TPI18N.GetText(ResourceSet, "NewButton"));
            setTitleLabel(await TPI18N.GetText(ResourceSet, "TitleLabel"));
            setFilterLabel(await TPI18N.GetText(ResourceSet, "FilterIsActiveLabel"));
            setNoTreesFound(await TPI18N.GetText(ResourceSet, "NoTreesFound"));

            //grid columns
            setIdColumnLabel(await TPI18N.GetText(ResourceSet, "Id"));
            setNameColumnLabel(await TPI18N.GetText(ResourceSet, "Name"));
            setMustSelectLastBranchColumnLabel(
                await TPI18N.GetText(ResourceSet, "MustSelectLastBranch")
            );
            setCommentsColumnLabel(await TPI18N.GetText(ResourceSet, "Comments"));
            setIsClassifierColumnLabel(
                await TPI18N.GetText(ResourceSet, "IsClassifier")
            );
            setIsActiveColumnLabel(await TPI18N.GetText(ResourceSet, "IsActive"));
            setIsSystemColumnLabel(
                await TPI18N.GetText(ResourceSet, "IsSystemRecord")
            );
            setImportLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "ImportLabel")
            );

            setFilterKeyValue([
                {
                    key: `${TPActiveOptions.ALL}`,
                    value: await TPI18N.GetText(ResourceSet, "All"),
                },
                {
                    key: `${TPActiveOptions.ACTIVE}`.toString(),
                    value: await TPI18N.GetText(ResourceSet, "Active"),
                },
                {
                    key: `${TPActiveOptions.INACTIVE}`,
                    value: await TPI18N.GetText(ResourceSet, "Inactive"),
                },
            ]);
        };

        const setTreeTableData = (prevState: AdminStateType): AdminStateType => {
            let newState: AdminStateType = { ...prevState };
            newState.gridColumns = [prevState.gridData];
            return newState;
        };


        const handleCheckboxFilter = function (type: TPActiveOptions, checked: boolean) {
            let status = 2;
            let check = {
                ...statusCheckboxes as {
                    active: boolean,
                    inActive: boolean,
                }
            };
            let active = check.active;
            let inActive = check.inActive;

            if (type === TPActiveOptions.ACTIVE) {
                active = checked;
            }

            if (type === TPActiveOptions.INACTIVE) {
                inActive = checked;
            }

            setStatusCheckboxes({
                active: active,
                inActive: inActive,
            });

            if (active && inActive) {
                status = 2;
            } else
                if (active) {
                    status = 1;
                } else
                    if (inActive) {
                        status = 0;
                    }

            let command1: commandType = {
                type: commandsEnum.change_selectedFilter,
                payload: status,
            };
            dispatchCommand(command1);
            reloadGridCommand();
        }

        const handleGetfilterKeyValue = function (mode: TPActiveOptions) {
            let data = "";
            if (filterKeyValue) {

                let temp = filterKeyValue.find(s => s.key == mode + "");
                if (temp)
                    data = temp.value;
            }
            console.log("log", data);
            return data;
        }


        //Get trees by Filter
        const getTreeTableData = async (selectedFilter: string) => {
            let serviceClient = new TreeService();
            let expectedCodes: Array<number> = [200, 404];
            try {
                setIsLoadingScreen(true);
                let response: TreeViewModel[] = await serviceClient.getTreesByFilter(
                    selectedFilter,
                    false,
                    true,
                    expectedCodes
                );
                let treeData = getTreeDataBy(response);
                setIsLoadingScreen(false);
                return [...treeData];
            } catch (error) {
                TPLog.Log(
                    `Error ${componentFileName} getTreeTableData ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${componentFileName} getTreeTableData ex`);
                setIsLoadingScreen(false);
                return [];
            }
        };

        const reloadGridCommand = () => {
            getTreeTableData(adminState.selectedFilter)
                .then(function (result) {
                    let command1: commandType = {
                        type: commandsEnum.reload_grid,
                        payload: result,
                    };
                    dispatchCommand(command1);
                })
                .catch(function (error) {
                    TPLog.Log(
                        `Error ${componentFileName} reloadGridCommand ex`,
                        TPLogType.ERROR,
                        error
                    );
                    console.error(`Error ${componentFileName} reloadGridCommand ex`);
                });
        };

        //New Tree
        const handleNewClick = () => {
            let command: any = { command: "new" };
            callBackCommands(command);
        };

        //Refresh
        const handleRefreshClick = () => {
            reloadGridCommand();
        };

        //Update tree
        const handleUpdateClick = (id: string) => {
            let command: any = {
                command: "update",
                recordId: id,
            };
            callBackCommands(command);
        };

        //Filter Active Change
        const handleFilterChange = (e: any) => {
            let command1: commandType = {
                type: commandsEnum.change_selectedFilter,
                payload: e.target.value,
            };
            dispatchCommand(command1);
        };

        //Modal Question to delete tree
        const handleDeleteClick = (id: string) => {
            let newModalQuestionState: TPModalQuestionState;
            newModalQuestionState = { ...modalQuestionState };
            newModalQuestionState.isShown = true;
            newModalQuestionState.callBackData = { recordId: id };
            setModalQuestionState(newModalQuestionState);
        };

        //Delete Tree after question confirmation
        const handleCallBackModal = async (
            confirmDelete: boolean,
            callBackData: any
        ) => {
            let expectedCodes: Array<number> = [200];
            let serviceClient = new TreeService();
            let newModalQuestionState: TPModalQuestionState;
            newModalQuestionState = { ...modalQuestionState };
            newModalQuestionState.isShown = false;
            newModalQuestionState.callBackData = {};
            setModalQuestionState(newModalQuestionState);
            if (confirmDelete) {
                try {
                    setIsLoadingScreen(true);
                    let responseRequest = await serviceClient.deleteTreeById(
                        callBackData.recordId,
                        true,
                        true,
                        expectedCodes
                    );
                    setIsLoadingScreen(false);
                    if (responseRequest.responseData.responseCode !== 500) {
                        reloadGridCommand();
                        callBackCommands({
                            command: "delete",
                            recordId: callBackData.recordId,
                        });
                    }
                } catch (error) {
                    TPLog.Log(
                        `Error ${componentFileName} handleCallBackModal ex`,
                        TPLogType.ERROR,
                        error
                    );
                    console.error(`Error ${componentFileName} handleCallBackModal ex`);
                    setIsLoadingScreen(false);
                }
            }
        };

        //State grid and current filter
        const initialStateBLL: AdminStateType = {
            filterIsLoaded: false,
            columnsAreLoaded: false,
            selectedFilter: TPActiveOptions.ALL.toString(),
            gridColumns: [],
            gridData: [],
            searchPattern: "",
        };

        //reducer definition
        const [adminState, dispatchCommand] = useReducer(
            doCommand,
            initialStateBLL
        );
        function doCommand(prevState: AdminStateType, command: commandType) {
            switch (command.type) {
                case commandsEnum.set_filterIsLoaded:
                    let newStateFilter: AdminStateType;
                    newStateFilter = { ...prevState };
                    newStateFilter.filterIsLoaded = true;
                    return newStateFilter;

                case commandsEnum.setup_grid_columns:
                    let newStateColumns: AdminStateType = setTreeTableData(prevState);
                    newStateColumns.columnsAreLoaded = true;
                    return newStateColumns;

                case commandsEnum.reload_grid:
                    let newStateGrid: AdminStateType;
                    newStateGrid = { ...prevState };
                    newStateGrid.gridData = command.payload;
                    return newStateGrid;

                case commandsEnum.change_selectedFilter:
                    let newStateChangeFilter: AdminStateType;
                    newStateChangeFilter = { ...prevState };
                    newStateChangeFilter.selectedFilter = command.payload;
                    return newStateChangeFilter;

                default:
                    return prevState;
            }
        }

        // Set DynamicTable styles and configurations

        /**
         * Return a new custom order and values for Dynamic Table
         *
         * @param {TreeViewModel[]} response - Response from the Tree service
         * @returns {TreeTableData[]} - Return a TreeTableData object
         */
        const getTreeDataBy = (response: TreeViewModel[]): TreeTableData[] => {
            let treeData: TreeTableData[] = response.map(
                ({
                    id,
                    name,
                    mustSelectLastBranch,
                    comments,
                    isClassifier,
                    isActive,
                    isSystemRecord,
                }: TreeViewModel) => {
                    return {
                        id,
                        name,
                        mustSelectLastBranch,
                        comments: comments ?? "",
                        isClassifier,
                        isActive,
                        isSystemRecord,
                    };
                }
            );

            return treeData;
        };

        /**
         * Return name column value and current language extension
         *
         * @returns {string} - return name column value with the extension
         */
        const getNameColumnValue = (): string => {
            const firstLanguage = TPGlobal.TPClientAvailableLanguages[0].name;
            const globalLanguage = TPGlobal.language;
            const languageColumn =
                firstLanguage !== globalLanguage
                    ? TPGlobal.getLanguageDescriptionByCode(TPGlobal.language)
                    : firstLanguage;

            return `${nameColumnLabel} (${languageColumn})`;
        };

        /**
         * Add custom resource to the columns name
         * using the interface model of the table data
         *
         * @param {CustomColumnNames<TreeTableData>} treeColumns - Interface model of the table data
         */
        const treeColumns: CustomColumnNames<TreeTableData> = {
            id: idColumnLabel,
            name: getNameColumnValue(),
            mustSelectLastBranch: mustSelectLastBranchColumnLabel.replaceAll("?",""),
            comments: commentsColumnLabel,
            isClassifier: isClassifierColumnLabel,
            isActive: isActiveColumnLabel,
            isSystemRecord: isSystemRecordColumnLabel,
        };

        /**
         * Add custom filters to the DynamicTable
         */
        const treeFilters: AdditionalFilter[] = [
            {
                key: "status",
                data: filterKeyValue,
                label: filterIsActiveLabel,
                selectedValue: adminState.selectedFilter,
                onChange: handleFilterChange,
            },
        ];

        /**
         * Add the minor options into the tree dots icon
         */
        const options: MinorOption<TreeTableData>[] = [
            {
                key: updateLabel,
                type: "edit",
                icon: TPIconTypes.edit,
                onOptionChange: (e) => handleUpdateClick(e.id),
            },
            {
                key: deleteLabel,
                type: "delete",
                icon: TPIconTypes.delete,
                onOptionChange: (e) => handleDeleteClick(e.id),
            },
        ];

        /**
         * Return micro table component to map the TableChip
         *
         * @param {boolean} value - boolean property to define label and style of the component
         *
         * @returns {JSX.Element} - return custom component to reduce the code repeated
         */
        const getTableChipElement = (value: boolean): JSX.Element => {
            return <TableChip value={value} onLabel={yesLabel} offLabel={noLabel} />;
        };

        /**
         * Object to add custom columns elements for specific object
         * properties
         */
        const columnStyles: ColumnStyles<TreeTableData> = {
            id: ({ value, item }) => (
                <TablePrimaryItem
                    value={value}
                    onClick={() => handleUpdateClick(item.id)}
                />
            ),
            isActive: ({ value }) => getTableChipElement(value),
            mustSelectLastBranch: ({ value }) => getTableChipElement(value),
            isClassifier: ({ value }) => getTableChipElement(value),
            isSystemRecord: ({ value }) => getTableChipElement(value),
        };

        /**
         * Event from DynamicTable to identify click action of
         * the table icons
         *
         * @param {TPIconTypes} event - icon type to define the click action
         */
        const handleIconClick = (event: TPIconTypes) => {
            if (event === TPIconTypes.loop) handleRefreshClick();
        };

        //Run only once to load resources and active filters
        useEffect(() => {
            loadResourcesAndTreeFilter()
                .then(function () {
                    //set filter is loaded
                    let command1: commandType = {
                        type: commandsEnum.set_filterIsLoaded,
                        payload: null,
                    };
                    dispatchCommand(command1);
                })
                .catch(function (error) {
                    TPLog.Log(
                        `Error ${componentFileName} loadResourcesAndTreeFilter ex`,
                        TPLogType.ERROR,
                        error
                    );
                    console.error(
                        `Error ${componentFileName} loadResourcesAndTreeFilter ex`
                    );
                });
        }, []);

        //Run when filter is loaded to get columns
        useEffect(() => {
            if (adminState.filterIsLoaded) {
                let command1: commandType = {
                    type: commandsEnum.setup_grid_columns,
                    payload: null,
                };
                dispatchCommand(command1);
            }
        }, [adminState.filterIsLoaded]);

        //Run to populate grid columns when columns are loaded or
        //user change filter
        useEffect(() => {
            if (adminState.columnsAreLoaded) {
                reloadGridCommand();
            }
        }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

        return (
            <>
                <TPModalQuestion
                    id="IdModalQuestion"
                    title={deleteTitle}
                    yesLabel={deleteOkLabel}
                    noLabel={deleteCancelLabel}
                    question={deleteQuestion.replace(
                        "{recordId}",
                        modalQuestionState.callBackData.recordId
                    )}
                    callBackData={modalQuestionState.callBackData}
                    isShown={modalQuestionState.isShown}
                    callBackAnswer={handleCallBackModal}
                ></TPModalQuestion>
                <TPLoadingOverlay active={isLoadingScreen}>
                    <div className="row">
                        <div className="col">
                            <CIMTitleSection>
                                <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                                <TPButton
                                    id="new-tree"
                                    isDesignSystem={true}
                                    onClick={handleNewClick}
                                    withIcon={TPIconTypes.add}
                                    orientationIcon="left"
                                    style={{ padding: "1px 18px" }}
                                >
                                    {newLabel}
                                </TPButton>
                            </CIMTitleSection>
                            {/* additionalFilters={treeFilters}*/}
                            <DynamicTable
                                id="tree"
                                data={adminState.gridData}
                                columnNames={treeColumns}
                                minorOptions={options}
                                columnStyles={columnStyles}
                                noDataMessage={noTreesFound}
                                withPreferences={true}
                               
                                onIconClicked={(event) => handleIconClick(event)}
                                additionalCheckboxes={[

                                    {
                                        checked: statusCheckboxes ? statusCheckboxes.active : false,
                                        key: handleGetfilterKeyValue(TPActiveOptions.ACTIVE),
                                        label: handleGetfilterKeyValue(TPActiveOptions.ACTIVE),
                                        onChange: (e) => handleCheckboxFilter(TPActiveOptions.ACTIVE, e.target.checked)
                                    },
                                    {
                                        checked: statusCheckboxes ? statusCheckboxes.inActive : false,
                                        key: handleGetfilterKeyValue(TPActiveOptions.INACTIVE),
                                        label: handleGetfilterKeyValue(TPActiveOptions.INACTIVE),
                                        onChange: (e) => handleCheckboxFilter(TPActiveOptions.INACTIVE, e.target.checked)
                                    }
                                ]
                                }
                                icons={[
                                    {
                                        status: true,
                                        type: TPIconTypes.rightExportImport,
                                        tooltip: importLabel                                      
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </TPLoadingOverlay>
            </>
        );
    }
);

export default TreesAdmin;
