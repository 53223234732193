import { handleInvalidCharactersQuillEditor } from "@/helpers/TPFunctions";
import TPGlobal from "@/helpers/TPGlobal";
import TPModal from "@/layouts/TPModal/TPModal";
import { AttachmentType, ModalSizeEnum } from "@/models/Global/TPGlobalEnums";
import ImagesAdmin from "@/pages/ImagesAdmin/ImagesAdmin";
import { AppProviderImagesAdmin } from "@/pages/ImagesAdmin/ImagesAdminContextImagesAdmin";
import { TPI18N } from "@/services/I18nService";
import { StorageService } from "@/services/StorageService";
import { DeltaOperation, DeltaStatic } from "quill";
import QuillResizeImage from 'quill-resize-image';
import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CustomToolbar } from "./CustomToolbar";
import { TaskService } from "@/services/TaskService";
import { GrammarBox } from "./GrammarBox/GrammarBox";
import { GrammarBoxData, GrammarCheckData, GrammarCheckWord } from "@/models/GrammarCheck/GrammarCheck";
import { hover } from "@testing-library/user-event/dist/hover";
import { TPTagSelector } from "../TPTagSelector/TPTagSelectors";
import FrequentAnswers from "@/pages/FrequentAnswers/FrequentAnswers";

const ImageBlot = Quill.import("formats/image");

class CustomImageBlot extends ImageBlot {
    static create(value: any) {
        const node = super.create(value);
        node.setAttribute("src", value.img_src);
        node.setAttribute("img_blobId", value.img_blobId);
        node.style.width = `${value.width}px`;
        node.style.height = `${value.height}px`;
        return node;
    }
    static value(node: any) {
        return {
            img_src: node.getAttribute("src"),
            img_blobId: node.getAttribute("img_blobId"),
            width: node.style.width.replace('px', ''),
            height: node.style.height.replace('px', ''),
        };
    }
}

Quill.register({ "formats/image": CustomImageBlot });
Quill.register("modules/resize", QuillResizeImage);

const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
    "align",
];

const GRAMMAR_CHECK_COOLDOWN = 3000; // miliseconds

const componentFileName: string = 'TPEditor.tsx';

const getWord = function(fullText: string, cursorPos: number): {word: string, index: number} {
    if (!fullText) return { word: "", index: 0 };
    let fixedPos = cursorPos === 0 ? 1 : cursorPos;
    const textBeforeCursor = fullText.replaceAll(/\s/g,'_').replaceAll(/\t/g,'_').slice(0, fixedPos);
    const textAfterCursor = fullText.replaceAll(/\s/g,'_').replaceAll(/\t/g,'_').slice(fixedPos);
    const wordBeforeCursor = textBeforeCursor.split(/_+/).pop() || '';
    const wordAfterCursor = textAfterCursor.split(/_+/)[0] || '';
    let wordIndex = cursorPos;
    while (
        (wordIndex > 0) &&
        (textBeforeCursor[wordIndex-1] &&
            textBeforeCursor[wordIndex-1] !== '_')
    ) {
        wordIndex -= 1;
    }
    const fullWord = wordBeforeCursor + wordAfterCursor;
    return { word: fullWord , index: wordIndex };
}

export const TPEditor = ({
    id,
    value,
    onChange,
    popUpOpen,
    referece,
    initialValue,
    isVisible,
    placeholder,
    style = { height: "140px", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px" },
    icons,
    onClickCustomIcon,
    isEdit,
    isInvalidChars = true,
    grammarChecking = false,
    errorMessage = "",
    onTagSelect,
    showFrequentAnswer = false,
}: any) => {
    const random = Math.floor(Math.random() * 999);
    const toolbarId = "toolbar" + random;
    const editorId = `${id && `${id}-`}text-editor`;
    const quillRef = useRef<ReactQuill>(null);
    const [toolbarNode, setToolbarNode] = useState<HTMLDivElement | null>(null);
    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
    const [isFrequentAnswersModalOpen, setIsFrequentAnswersModalOpen] = useState<boolean>(false);
    const [localErrorMessage, setLocalErrorMessage] = useState("");
    const [inputValue, setInputValue] = useState(value || "");
    const [invalidCharacterLabel, setInvalidCharacterLabel] = useState('');
    const [tagModalVisible, setTagModalVisible] = useState(false);
    const [frequentAnswerLabel, setFrequentAnswerLabel] = useState('');

    useEffect(() => {
        const fetchText = async () => {
            const invalidCharacter = await TPI18N.GetText(TPGlobal.globalResourceSet, 'InvalidCharacter');
            setInvalidCharacterLabel(invalidCharacter);

            const frequentAnswer = await TPI18N.GetText(TPGlobal.globalResourceSet, 'FrequentAnswer');
            setFrequentAnswerLabel(frequentAnswer);
        };
        fetchText();
        if (quillRef.current) {
            quillRef.current.getEditor().root.setAttribute('spellcheck', 'false'); // Disable spellcheck
        }
    }, []);

    useEffect(() => {
        setInputValue(value || "");
    }, [value])

    const handleChange = (content: string, delta: DeltaStatic) => {
        if (isInvalidChars) {
            handleInvalidCharactersQuillEditor({
                delta,
                content,
                invalidCharRegex: new RegExp(`[${TPGlobal.TPClientInvalidCharRegex}]`, 'g'),
                invalidCharacterLabel,
                setLocalErrorMessage,
                setInputValue,
                onChange,
                quillRef
            });
        } else {
            setInputValue(content);
            onChange && onChange(content);
        }
        setHoveringWord(null);
    };

    const handleOpenImageModal = () => {
        setIsImageModalOpen(true);
    };

    const handleCloseImageModal = () => {
        setIsImageModalOpen(false);
    };

    const handleOpenFrequentAnswerModal = () => {
        setIsFrequentAnswersModalOpen(true);
    };

    const handleCloseFrequentAnswerModal = () => {
        setIsFrequentAnswersModalOpen(false);
    };

    useEffect(() => {
        if (quillRef.current && referece)
            referece(quillRef.current);
    }, [quillRef, referece]);

    const updateImageText = async () => {
        const editor = quillRef.current?.getEditor();
        if (editor) {
            let content = editor.root.innerHTML;
            const regex = /\[ImageEmbedded\|([a-zA-Z0-9-]{32,36})\|(\d+)\|(\d+)\]/g;
            const matches = [];
            let match;
            while ((match = regex.exec(content)) !== null) {
                const [fullMatch, blobId, width, height] = match;
                matches.push(
                    new Promise((resolve, reject) => {
                        const attachmentService = new StorageService();
                        attachmentService
                            .getFileUriSas(blobId, AttachmentType.IMAGE, false, false, [])
                            .then((response) => {
                                resolve({ fullMatch, blobId, width, height, sasUri: response.sasUri });
                            })
                            .catch((error) => {
                                console.error(error);
                                resolve({ fullMatch, blobId, width, height, sasUri: null });
                            });
                    })
                );
            }
            const results = await Promise.all(matches);
            results.forEach(({ fullMatch, blobId, width, height, sasUri }: any) => {
                if (sasUri) {
                    content = content.replace(
                        fullMatch,
                        `<img src='${sasUri}' img_blobId='${blobId}' style='width: ${width}px; height: ${height}px' />`
                    );
                }
            });
            if (editor.root.innerHTML !== content) {
                editor.root.innerHTML = content;
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const quillValue = quillRef?.current?.value?.toString();
            if (quillValue) {
                const imagePattern = /\[ImageEmbedded\|([a-zA-Z0-9-]{32,36})\|(\d+)\|\d+\]/g;
                const matchesPattern = imagePattern.test(quillValue);
                if (matchesPattern) {
                    updateImageText();
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [quillRef]);

    const modules = React.useMemo(() => {
        return {
            toolbar: { container: toolbarNode },
            clipboard: { matchVisual: false },
            resize: { locale: {} },
        };
    }, [toolbarNode]);

    // Grammar checking
    const [loadingGrammarCheck, setLoadingGrammarCheck] = useState(false);
    const [hoveringWord, setHoveringWord] = useState<GrammarCheckWord | null>(null);
    const [grammarBoxVisible, setGrammarBoxVisible] = useState(false);
    const [grammarBoxPosition, setGrammarBoxPosition] = useState({ top: 0, left: 0 });
    const [grammarCheckData, setGrammarCheckData] = useState<GrammarCheckData | null>(null);
    const [grammarBoxData, setGrammarBoxData] = useState<GrammarBoxData | null>(null);
    const [grammarCheckLanguage, setGrammarCheckLanguage] = useState<string>(TPGlobal.language || "");
    const [grammarWhiteList, setGrammarWhiteList] = useState<string[]>([]);

    const checkGrammar = async function() {
        const editor = quillRef.current?.getEditor();
        if (!grammarChecking || !editor) return;
        if(editor.getText().trim().length == 0) return;
        setLoadingGrammarCheck(true);
        setGrammarCheckData(null);
        let taskService = new TaskService();
        let content = TPGlobal.stringToUTF8(editor.getText()).toString();
        taskService.checkGrammar("1", content, "auto"/*TPGlobal.getLanguageDescriptionByCode(grammarCheckLanguage) || */)
        .then(response => setGrammarCheckData(response[0] as GrammarCheckData || null))
        .catch(err => console.error(err))
        .finally(() => setLoadingGrammarCheck(false))
    }

    const replaceWord = function(word: string) {
        const editor = quillRef.current?.getEditor();
        if (!editor || !hoveringWord) return;
        editor.deleteText(hoveringWord.index, hoveringWord.word.length);
        editor.insertText(hoveringWord.index, word);
        editor.formatText(hoveringWord.index, word.length+1, "color", "black");
        editor.formatText(hoveringWord.index, word.length+1, "underline", false);
        setGrammarCheckData(null);
    }

    const resetGrammarCheck = function() {
        setHoveringWord(null);
        setGrammarBoxVisible(false);
        setGrammarBoxData(null);
    }

    const enableGrammarBox = (pos?: number) => {
        if (!grammarChecking) return;
        const editor = quillRef.current?.getEditor();
        const selection = editor?.getSelection();
        if (editor && selection && (selection.index !== undefined) && !loadingGrammarCheck) {
            setGrammarBoxVisible(true);
          const cursorPosition = pos || selection.index;
          const { word: fullWord, index: wordIndex } = getWord(editor.getText(), cursorPosition);
          if (fullWord && grammarCheckData) {
            const match = grammarCheckData.matches.find(m => m.offset === wordIndex);
            if (match && !grammarWhiteList.includes(fullWord)) {
                setHoveringWord(prev => !prev || (prev?.word !== fullWord) 
                    ? { word: fullWord, index: wordIndex } : prev);
                setGrammarBoxPosition({
                    left: editor.getBounds(wordIndex + Math.ceil(fullWord.length/2)).left - 35,
                    top: editor.getBounds(cursorPosition).top + 32
                  })
                setGrammarBoxData({
                    errorDescription: match.message,
                    wordSuggestions: match.replacements.map(r => r.value),
                    problem: match.category
                })
            } else {
                resetGrammarCheck();
            }
          } else {
            resetGrammarCheck();
          }
        } else {
            resetGrammarCheck();
        }
    };

    const highlightErrors = function() {
        const editor = quillRef.current?.getEditor();
        if (editor && grammarCheckData) {
            const words = editor.getText().split(" ");
            let i=0;
            words.forEach(w => {
                if (grammarCheckData.matches.find(m => m.offset === i) && !grammarWhiteList.includes(w)) {
                    const start = i;
                    const end = w.length;
                    editor.formatText(start, w.length, "color", "red");
                    editor.formatText(start, w.length, "underline", true);
                    editor.formatText(start, w.length, "background", "white");
                    editor.formatText(start+end, 1, "color", "red");
                    editor.formatText(start+end, 1, "underline", true);
                    editor.formatText(start+end, 1, "background", "white");
                }
                i += w.length+1;
            })
        }
    }

    useEffect(() => {
        setGrammarBoxVisible(false);
        const timeoutId = setTimeout(() => {
            checkGrammar();
        }, GRAMMAR_CHECK_COOLDOWN)
        return () => clearInterval(timeoutId);
    }, [inputValue])

    useEffect(() => {
        if (grammarChecking && grammarCheckData) {
            enableGrammarBox();
            highlightErrors();
        }
    }, [grammarCheckData])

    useEffect(() => {
        checkGrammar();
    }, [grammarCheckLanguage])

    return (
        <>
            <TPTagSelector
                onClose={tag => {
                    setTagModalVisible(false);
                    if (onTagSelect && tag) onTagSelect(tag);
                }}
                visible={tagModalVisible}
            />
            <CustomToolbar
                ref={setToolbarNode}
                id={toolbarId}
                isPopUp={popUpOpen}
                isVisible={isVisible}
                openImageModal={handleOpenImageModal}
                icons={icons}
                onClickCustomIcon={onClickCustomIcon}
                onTagClick={() => setTagModalVisible(true)}
                showFrequentAnswer={showFrequentAnswer}
                frequentAnswerText={frequentAnswerLabel}
                onClickFrequentAnswer={handleOpenFrequentAnswerModal}
            />
            {!!toolbarNode && (
                <div>
                    {grammarChecking && grammarBoxVisible && grammarBoxData && grammarCheckData && (
                        <div style={{position: "relative"}}>
                            <GrammarBox
                                language={grammarCheckLanguage}
                                onLanguageSelect={(lang) => setGrammarCheckLanguage(lang)}
                                data={grammarBoxData}
                                onCorrection={newWord => replaceWord(newWord)}
                                position={grammarBoxPosition}
                                onIgnore={() => {
                                    replaceWord(hoveringWord?.word || "");
                                    if (hoveringWord?.word) setGrammarWhiteList([...grammarWhiteList, hoveringWord.word])
                                    setGrammarBoxVisible(false);
                                }}
                                onClose={() => setGrammarBoxVisible(false)}
                            />
                        </div>
                    )}
                    <div onClick={() => enableGrammarBox()}>
                        <ReactQuill
                            id={editorId}
                            defaultValue={initialValue}
                            ref={quillRef}
                            value={inputValue}
                            onChange={handleChange}
                            placeholder={placeholder}
                            modules={modules}
                            formats={formats}
                            theme={"snow"}
                            style={style}
                            readOnly={(isEdit != undefined && !isEdit)}
                        />
                    </div>
                </div>
            )}
            {localErrorMessage && (
                <div style={{
                    color: "#dc3545",
                    fontSize: "14px",
                    margin: "4px 0px 0px 0px",
                }}>
                    {localErrorMessage}
                </div>
            )}
            {errorMessage && (
                <div className="invalid-feedback" style={{display:"block"}}>
                    {errorMessage}
                </div>
            )}
            <ImageSelectorModal
                quillRef={quillRef}
                isModalOpen={isImageModalOpen}
                handleCloseModal={handleCloseImageModal}
            />
            <FrequentAnswerModal
                quillRef={quillRef}
                isModalOpen={isFrequentAnswersModalOpen}
                handleCloseModal={handleCloseFrequentAnswerModal}
            />
        </>
    );
};

const ImageSelectorModal: React.FC<{
    quillRef: React.RefObject<ReactQuill>;
    isModalOpen: boolean;
    handleCloseModal: () => void;
}> = ({ quillRef, isModalOpen, handleCloseModal }) => {
    const handleImageInsert = (imageData: any) => {
        const { imageUrl, blobId, width, height } = imageData;
        const editor = quillRef.current?.getEditor();
        if (editor) {
            const range = editor.getSelection();
            const length = editor.getLength();
            const index = range ? range.index : length;
            editor.insertEmbed(index, "image", {
                img_src: imageUrl,
                img_blobId: blobId,
                width,
                height,
            });
            editor.setSelection({ index: length - 1, length: 0 });
        }
    };

    return (
        <TPModal
            modalState={{
                hideFooterButtons: true,
                titleModal: "",
                acceptLabel: "",
                callBackAnswer: handleCloseModal,
                callBackData: null,
                isShown: isModalOpen,
                modalWidth: ModalSizeEnum.MODALXL,
                isFullscreen: true,
                zIndex: 9999,
            }}
        >
            <AppProviderImagesAdmin>
                <ImagesAdmin
                    modeSelectFolder={true}
                    folderIdActive={0}
                    ChangeImageSelection={async (e: any) => {
                        handleImageInsert(e);
                        handleCloseModal();
                    }}
                />
            </AppProviderImagesAdmin>
        </TPModal>
    );
};

const FrequentAnswerModal: React.FC<{
    quillRef: React.RefObject<ReactQuill>;
    isModalOpen: boolean;
    handleCloseModal: () => void;
}> = ({ quillRef, isModalOpen, handleCloseModal }) => {

    const [selectedFrequentAnswer, setSelectedFrequentAnswer] = useState<string>('')
    
    const handleChangeFrequentAnswer = (e: any) => setSelectedFrequentAnswer(e)
    
    const addComment = () => {
        if (selectedFrequentAnswer) {
            const editor = quillRef.current?.getEditor();
            if (editor) {
                const range = editor.getSelection();
                const length = editor.getLength();
                const index = range ? range.index : length;

                editor.clipboard.dangerouslyPasteHTML(index, selectedFrequentAnswer);
                editor.setSelection({ index: index + selectedFrequentAnswer.length, length: 0 });
            }
        }
        handleCloseModal();
    };

    return (
        <TPModal
            modalState={{
                titleModal: "Search new frequent answer",
                acceptLabel: "Add to comments",
                cancelLabel: "Cancel",
                callBackAnswer: addComment,
                callBackData: null,
                isShown: isModalOpen,
                modalWidth: ModalSizeEnum.MODALXL,
                isFullscreen: false,
            }}
        >
            <FrequentAnswers changeFrequentAnswer={handleChangeFrequentAnswer}  isModal />
        </TPModal>
    );
};