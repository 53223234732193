import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  SequenceGeneratorSequencesNameEnum,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { GroupsViewModel } from "@/models/Groups/GroupsModels";
import { GroupsService } from "@/services/GroupsService";
import { SequenceService } from "@/services/SequenceService";
import { FC, useEffect, useRef, useState } from "react";
import { useQueuesAdminSelectOptions } from "../../assets/controller";
import useQueueAdminLabels from "../../assets/labels";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import QueueAdministrationTab from "./QueueAdministrationTab";
import {
  CIMTitleSection,
  TPPageSectionTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import "./styles.css";
import allThemes from "@/assets/styles/theme";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import FormView from "@/pages/FormDesigner/FormView/FormView";

const componentFileName = "QueueGeneralInfoTab.tsx";

interface QueueGeneralInfoProperties {
  queueData?: any;
  panelMode: "new" | "update";
  id?: string;
  tabCallback: Function;
  groupId: number;
  active: boolean;
  updateCallback: Function;
}

const QueueGeneralInfo: FC<
  QueueGeneralInfoProperties & {
    activePreview?: boolean;
    handleChangeActivePreview: (mode: boolean) => void;
  }
> = function ({
  panelMode,
  id,
  tabCallback,
  updateCallback,
  groupId,
  active,
  activePreview,
  handleChangeActivePreview,
}) {
  const { labels, labelsLoaded } = useQueueAdminLabels();
  const [contentLoaded, setContentLoaded] = useState<boolean>(false);
  const [idInput, setIdInput] = useState<string>("");
  const [nameInput, setNameInput] = useState<string>("");
  const [scheduleInput, setScheduleInput] = useState<string>("");
  const [timezoneInput, setTimezoneInput] = useState<string>("");
  const [calendarInput, setCalendarInput] = useState<string>("");
  const [superiorInput, setSuperiorInput] = useState<string>("");
  const [preferredCustomerSelected, setPreferredCustomerSelected] =
    useState<boolean>(false);
  const [relatedCaseSelected, setRelatedCaseSelected] =
    useState<boolean>(false);
  const [recentAgentSelected, setRecentAgentSelected] =
    useState<boolean>(false);
  const [showAsActive, setShowAsActive] = useState<boolean>(true);
  const [currentGroup, setCurrentGroup] = useState<GroupsViewModel>();
  const [canSave, setCanSave] = useState(true);
  const [isValidFirstAccordion, setIsValidFirstAccordion] = useState(false);
  const [hasInteractionOutcomes, setHasInteractionOutcomes] = useState(false);
  const [interactionOutcomesAutoComplete, setInteractionOutcomesAutoComplete] =
    useState<TPKeyValue[]>([]);

  const queueAdminSaveRef = useRef<Function | null>(null);

  const [customFieldsAutocomplete, setCustomFieldsAutocomplete] =
    useState<string>("");

  const [customerFormId, setCustomerFormId] = useState<string>("");

  const {
    optionsLoaded,
    scheduleOptions,
    timeZoneOptions,
    calendarOptions,
    defaultLanguageOptions,
    superiorOptions,
  } = useQueuesAdminSelectOptions();

  useEffect(() => {
    if (scheduleOptions.length > 0) setScheduleInput(scheduleOptions[0].key);
    if (timeZoneOptions.length > 0) setTimezoneInput(timeZoneOptions[0].key);
    if (calendarOptions.length > 0) setCalendarInput(calendarOptions[0].key);
  }, [scheduleOptions, timeZoneOptions, calendarOptions]);

  const handleQueueSave = async function () {
    if (!canSave) return;
    setCanSave(false);
    setContentLoaded(false);

    if (queueAdminSaveRef.current) {
      await queueAdminSaveRef.current();
    }

    if (panelMode == "new") {
      const groupServiceInstance = new GroupsService();
      groupServiceInstance
        .insertGroup(
          {
            isRotating: false,
            isUpdatable: false,
            isPicking: false,
            finishPendingCaseBeforeRecivingNewOne: false,
            preferredAgent: preferredCustomerSelected,
            relatedCase: relatedCaseSelected,
            recentAgent: recentAgentSelected,
            prioritizeRelatedCases: relatedCaseSelected,
            topSearchCoefficient: 2,
            assignCasesToUsersLogged: false,
            taskResponsibleForSocialMediaAnswer: 1,
            priorityWeight: 2,
            groupId: 0,
            minutesLogoutReassign: 0,
            maximumCasesPerUser: 0,
            isEventTakeNextGroup: true,
            user: {
              userGuid: "",
              login: idInput,
              firstName: nameInput,
              lastName: "",
              name: nameInput,
              initials: "",
              profileId: null,
              profileDescription: "",
              superiorIdGuid:
                superiorInput.trim().length == 0 ? null : superiorInput,
              email: "",
              teamId: "",
              teamDescription: "",
              functionId: "",
              functionDescription: "",
              areaId: "",
              areaDescription: "",
              phone: "",
              mobile: "",
              typistIdGuid: TPGlobal.currentUserGuid,
              calendarId: calendarInput,
              calendarDescription: "",
              timeZoneId: timezoneInput,
              scheduleId: scheduleInput,
              scheduleDescription: "",
              authenticationMethodId: "",
              microsoftIdentity: "",
              defaultLanguage: TPGlobal.language,
              maxSimultaneousChat: 0,
              isActive: showAsActive,
              isSystemRecord: false,
            },
          },
          false,
          true,
          [200],
        )
        .then((response) => {
          if (
            response.responseData.responseCode === 200 &&
            panelMode === "new"
          ) {
            tabCallback({
              command: "delete",
              recordId: "--",
              languageId: "",
              recordDescription: "",
            });
            tabCallback({
              command: "update",
              recordId: response.responseData.keyList[0].value,
              languageId: TPGlobal.language,
              recordDescription: nameInput,
            });
            updateCallback({
              result: "ReloadGrid",
            });
            showToast(labels.InsertSuccess, TPToastTypes.success);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setCanSave(true);
          setContentLoaded(true);
        });
    } else if (panelMode === "update" && currentGroup !== undefined) {
      const groupServiceInstance = new GroupsService();
      groupServiceInstance
        .updateGroup(
          {
            isRotating: currentGroup.isRotating,
            isUpdatable: currentGroup.isUpdatable,
            isPicking: currentGroup.isPicking,
            finishPendingCaseBeforeRecivingNewOne:
              currentGroup.finishPendingCaseBeforeRecivingNewOne,
            preferredAgent: preferredCustomerSelected,
            relatedCase: relatedCaseSelected,
            recentAgent: recentAgentSelected,
            prioritizeRelatedCases: relatedCaseSelected,
            topSearchCoefficient: currentGroup.topSearchCoefficient,
            assignCasesToUsersLogged: currentGroup.assignCasesToUsersLogged,
            taskResponsibleForSocialMediaAnswer:
              currentGroup.taskResponsibleForSocialMediaAnswer,
            priorityWeight: currentGroup.priorityWeight,
            groupId: Number(currentGroup.groupId),
            minutesLogoutReassign: Number(currentGroup.minutesLogoutReassign),
            maximumCasesPerUser: Number(currentGroup.maximumCasesPerUser),
            isEventTakeNextGroup: true,
            user: {
              userGuid: currentGroup.user.userGuid,
              login: currentGroup.user.login,
              firstName: nameInput,
              lastName: currentGroup.user.lastName,
              name: nameInput,
              initials: currentGroup.user.initials,
              profileId: currentGroup.user.profileId,
              profileDescription: currentGroup.user.profileDescription,
              superiorIdGuid:
                superiorInput.trim().length == 0 ? null : superiorInput,
              email: currentGroup.user.email,
              teamId: currentGroup.user.teamId,
              teamDescription: "...",
              functionId: currentGroup.user.functionId,
              functionDescription: "...",
              areaId: currentGroup.user.areaId,
              areaDescription: "...",
              phone: currentGroup.user.phone,
              mobile: currentGroup.user.mobile,
              typistIdGuid: TPGlobal.currentUserGuid,
              calendarId: calendarInput,
              calendarDescription: "...",
              timeZoneId: timezoneInput,
              scheduleId: scheduleInput,
              scheduleDescription: "...",
              authenticationMethodId: currentGroup.user.authenticationMethodId,
              microsoftIdentity: currentGroup.user.microsoftIdentity,
              defaultLanguage: TPGlobal.language,
              maxSimultaneousChat: currentGroup.user.maxSimultaneousChat,
              isActive: showAsActive,
              isSystemRecord: currentGroup.user.isSystem,
            },
          },
          false,
          true,
          [200],
        )
        .then((response) => {
          if (response.responseData.responseCode === 200) {
            showToast(labels.UpdateSuccess, TPToastTypes.success);
            updateCallback({
              result: "ReloadGrid",
            });
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setCanSave(true);
          setContentLoaded(true);
        });
    }
  };

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setContentLoaded(false);
      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQEVQU,
      );
      setContentLoaded(true);
      if (responseRequest.responseResult) {
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setIdInput(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setContentLoaded(true);
    }
  };

  const handleUpdateCallback = (updates: any) => {
    if (updates.interactionOutcomesAutoComplete) {
      setInteractionOutcomesAutoComplete(
        updates.interactionOutcomesAutoComplete,
      );
    }

    if (updates.customFieldsAutoComplete) {
      setCustomFieldsAutocomplete(
        updates.customFieldsAutoComplete[0]?.value || "",
      );
    }
  };

  useEffect(() => {
    if (panelMode === "new") {
      generalAutomaticId();
    } else if (panelMode === "update" && groupId) {
      setContentLoaded(false);
      const userServiceInstance = new GroupsService();
      userServiceInstance
        .getGroupById(String(groupId), false, false, [200])
        .then((response) => {
          if (response) {
            setCurrentGroup(response);
            setIdInput(response.user.login || "");
            setNameInput(response.user.firstName || "");
            setCalendarInput(response.user.calendarId || "");
            setScheduleInput(response.user.scheduleId || "");
            setSuperiorInput(response.user.superiorIdGuid || "");
            setShowAsActive(response.user.isActive || false);
            setPreferredCustomerSelected(response.preferredAgent || false);
            setRelatedCaseSelected(response.prioritizeRelatedCases || false);
            setRecentAgentSelected(response.recentAgent || false);
            setTimezoneInput(response.user.timeZoneId || "");
            setContentLoaded(true);
          }
        });
    }
  }, []);

  useEffect(() => {
    const isNameValid = nameInput.trim() !== "";
    setIsValidFirstAccordion(isNameValid);
  }, [nameInput]);

  return (
    <>
      {active && (
        <TPLoadingOverlay
          active={!(optionsLoaded && labelsLoaded && contentLoaded)}
        >
          {activePreview ? (
            <>
              <div
                style={{
                  width: "95%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CIMTitleSection style={{ margin: 0, padding: 0 }}>
                  <TPPageTitle style={{ margin: 0, padding: 0 }}>
                    {labels.Preview}
                  </TPPageTitle>
                </CIMTitleSection>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => handleChangeActivePreview(false)}
                >
                  <TPIcon
                    iconType={TPIconTypes.arrowLeft}
                    style={{ color: "#780096", fontSize: "24px" }}
                  />
                  <TPButton
                    onClick={() => {}}
                    type={TPButtonTypes.link}
                    style={{
                      backgroundColor: "white",
                      color: allThemes.base.purplePrimary,
                      padding: "11px 16px",
                    }}
                  >
                    {labels.ExitPreview}
                  </TPButton>
                </div>
              </div>
              <div>
                <FormView
                  inputValueDefault={[]}
                  onChangedJson={(e: any) => {}}
                  onChangedReadyForm={(e: any) => {}}
                  inputFormId={customerFormId}
                  inputTryingToSave={false}
                />
              </div>
            </>
          ) : (
            <>
              <div
                id={"fist-accordion"}
                className="accordion"
                style={{ marginTop: "16px", padding: "0" }}
              >
                <div
                  className="accordion-item"
                  style={{
                    width: "85%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="accordion-header " style={{ flex: 1 }}>
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${id}-information`}
                    >
                      <TPLabel
                        labelText={labels.GeneralInfo}
                        isMandatory
                        style={{ fontSize: "18px", fontWeight: "700" }}
                      />
                    </button>
                  </div>
                  {isValidFirstAccordion && (
                    <TPIcon
                      iconType={TPIconTypes.checkCircle}
                      style={{
                        color: "#009A58",
                        fontSize: "24px",
                        marginLeft: "8px",
                      }}
                    />
                  )}
                </div>

                <div
                  id={`${id}-information`}
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body">
                    <div className="row menu-def-main-form-container">
                      <div className="col menu-def-main-form-inner-container">
                        <div className="menu-def-form">
                          <div className="menu-def-form-row">
                            <div style={{ width: "100%" }}>
                              <TPTextBox
                                labelText={labels.ID}
                                isMandatory
                                onChange={() => {}}
                                value={idInput}
                                disabled
                              />
                            </div>
                            <div style={{ width: "100%" }}>
                              <TPTextBox
                                onChange={(event: any) =>
                                  setNameInput(event.target.value)
                                }
                                value={nameInput}
                                labelText={labels.Name}
                                isMandatory
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <TPPageSectionTitle
                              style={{ fontWeight: "700", fontSize: "18px" }}
                            >
                              {labels.Parameters}
                            </TPPageSectionTitle>
                          </div>
                        </div>

                        <div className="menu-def-form">
                          <div style={{ width: "100%" }}>
                            <TPTextBox
                              onChange={() => {}}
                              labelText={labels.GroupType}
                              isMandatory
                              value={labels.EventTakeNext}
                              disabled
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            <TPSelect
                              labelText={labels.Superior}
                              dataSource={superiorOptions}
                              onChange={(event: any) =>
                                setSuperiorInput(event.target.value)
                              }
                              value={superiorInput}
                            />
                          </div>
                        </div>

                        <div className="menu-def-form">
                          <div style={{ width: "100%" }}>
                            <TPLabel
                              className="fw-bolder"
                              labelText={labels.GroupBehavior}
                            />
                            <div style={{ display: "flex", gap: 16 }}>
                              <TPCheckBox
                                checked={preferredCustomerSelected}
                                onChange={(event: any) =>
                                  setPreferredCustomerSelected(
                                    event.target.checked,
                                  )
                                }
                                labelText={labels.preferredCustomers}
                              />
                              <TPCheckBox
                                checked={relatedCaseSelected}
                                onChange={(event: any) =>
                                  setRelatedCaseSelected(event.target.checked)
                                }
                                labelText={labels.RelatedCase}
                              />
                              <TPCheckBox
                                checked={recentAgentSelected}
                                onChange={(event: any) =>
                                  setRecentAgentSelected(event.target.checked)
                                }
                                labelText={labels.RecentAgent}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        id="queue-general-info-params-form"
                        className="queue-form-grid2"
                      >
                        {/* <div id='queue-general-info-schedule-input'>
              <TPSelect
                dataSource={scheduleOptions}
                isMandatory
                labelText={labels.Schedule}
                onChange={(event: any) => setScheduleInput(event.target.value)}
                value={scheduleInput}
              />
            </div>
            <div id='queue-general-info-timezone-input'>
              <TPSelect
                labelText={labels.TimeZone}
                isMandatory
                dataSource={timeZoneOptions}
                onChange={(event: any) => setTimezoneInput(event.target.value)}
                value={timezoneInput}
              />
            </div>
            <div id='queue-general-info-calendar-input'>
              <TPSelect
                labelText={labels.Calendar}
                isMandatory
                dataSource={calendarOptions}
                onChange={(event: any) => setCalendarInput(event.target.value)}
                value={calendarInput}
              />
            </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id={"second-accordion"}
                className="accordion"
                style={{ marginTop: "16px", padding: "0" }}
              >
                <div
                  className="accordion-item"
                  style={{
                    width: "85%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="accordion-header" style={{ flex: 1 }}>
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${id}-administration`}
                    >
                      <TPLabel
                        labelText={labels.QueueAdministration}
                        isMandatory
                        style={{ fontSize: "18px", fontWeight: "700" }}
                      />
                    </button>
                  </div>
                  {hasInteractionOutcomes && (
                    <TPIcon
                      iconType={TPIconTypes.checkCircle}
                      style={{
                        color: "#009A58",
                        fontSize: "24px",
                        marginLeft: "8px",
                      }}
                    />
                  )}
                </div>

                <div
                  id={`${id}-administration`}
                  className="accordion-collapse collapse show"
                >
                  <div className="row menu-def-main-form-container">
                    <div
                      className="menu-def-main-form-inner-container"
                      style={{ height: "50%" }}
                    >
                      <QueueAdministrationTab
                        groupId={0}
                        tabCallback={tabCallback}
                        updateCallback={handleUpdateCallback}
                        onInteractionOutcomesChange={setHasInteractionOutcomes}
                        saveCallback={(saveFn: Function) =>
                          (queueAdminSaveRef.current = saveFn)
                        }
                        onCustomerFormIdChange={setCustomerFormId}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ margin: " 16px 0" }}>
                <TPCheckBox
                  checked={showAsActive}
                  labelText={labels.ShowAsActive}
                  onChange={(event: any) =>
                    setShowAsActive(event.target.checked)
                  }
                />
              </div>

              <div
                className="menu-def-option-buttons"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                }}
              >
                <TPButton
                  onClick={() =>
                    tabCallback({
                      command: "delete",
                      recordId: String(groupId),
                    })
                  }
                  type={TPButtonTypes.link}
                  style={{ padding: "6px 0", minWidth: "unset" }}
                >
                  {labels.Close}
                </TPButton>
                <div style={{ display: "flex", gap: "16px" }}>
                  <TPButton
                    onClick={() => handleChangeActivePreview(true)}
                    isDesignSystem
                    style={{
                      backgroundColor: "white",
                      color: allThemes.base.purplePrimary,
                      padding: "11px 16px",
                    }}
                    disabled={customFieldsAutocomplete !== "Form"}
                  >
                    {labels.Preview}
                  </TPButton>
                  <TPButton
                    onClick={() => handleQueueSave()}
                    type={TPButtonTypes.primary}
                    isDesignSystem
                    style={{
                      padding: "11px 16px",
                    }}
                    disabled={
                      nameInput.trim().length == 0 ||
                      interactionOutcomesAutoComplete.length === 0 ||
                      // scheduleInput.replace('--', '').trim().length == 0 ||
                      // timezoneInput.replace('--', '').trim().length == 0 ||
                      // calendarInput.replace('--', '').trim().length == 0
                      !canSave
                    }
                  >
                    {labels.Save}
                  </TPButton>
                </div>
              </div>
            </>
          )}
        </TPLoadingOverlay>
      )}
    </>
  );
};

export default QueueGeneralInfo;
