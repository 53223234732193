import styled from "styled-components";


interface ViewImageModel {
    height?: string;
    width?: string;
    borderRadius?: string;
}
export const ViewImageStyle = styled.div<ViewImageModel>`
  width: ${({ width }) => width || "100%"};
  height:${({ height }) => height || "100%"};
  border-radius:${({ borderRadius }) => borderRadius || "0"};
`;

