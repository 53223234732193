import DefaultSmallAvatarIcon from "@/assets/images/TPIcons/DefaultSmallAvatarIcon";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPTextBoxStyled } from "@/components/bootstrap/forms/textbox/tpTextboxStyles";
import TPGeneralContext from "@/contexts/TPGeneralContext";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalStationId, {
  TPModalStationIdState,
} from "@/layouts/TPModalStationId/TPModalStationId";
import {
  BrowserClientInputDTO,
  BrowserClientUpdateInputDTO,
} from "@/models/BrowserClient/BrowserClientInputDTO";
import * as availableIcons from "react-icons/md";
import { IconType } from "react-icons";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { MenuItemViewModelForRender } from "@/models/MenuItems/MenuItemsModels";
import {ProfilesGetViewModel, ProfilesViewModel} from "@/models/Profiles/ProfilesModels";
import { camelCaseToId } from "@/modules/core/utils/text-regex";
import { BrowserKeepAliveService } from "@/services/BrowserKeepAliveService";
import { TPI18N } from "@/services/I18nService";
import { MenuItemsService } from "@/services/MenuItemService";
import { ProfileService } from "@/services/ProfileService";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useImperativeHandle, useState } from "react";
import Skeleton from "react-loading-skeleton";
import MainHeader from "./MainHeader";
import {
  ExpanderContainerStyled,
  LeftMenuHeaderStyled,
  MenuContainerStyled,
  MenuItemContainerStyled,
  MenuItemTitleContainerStyled,
  MenuTitleItem,
  SectionContainerStyled,
  SectionItemContainerStyled,
  SectionTitle,
  SubmenuContainerStyled,
  SubmenuItemContainerStyled,
  SubMenuTitle,
  TPMainMenuContainerStyle,
  TPSearchMenuContainer,
} from "./mainMenuStyles";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import { MenuDefinitionSlice, MenuDefinitionSliceModel } from "@/pages/MenuDefinition/Assets/MenuDefinitionSlice";
import { useMenuReload } from "@/contexts/MenuReloadContext";

const year = new Date().getFullYear();

type MainMenuProps = {
  menuItemClickCallback: Function;
  // children: JSX.Element;
};

const MainMenu = React.forwardRef(
  ({ menuItemClickCallback }: MainMenuProps, ref) => {
    const componentFileName = "MainMenu.tsx";

    const generalContext: any = React.useContext(TPGeneralContext);
    const { isLeftMenuCollapsed } = generalContext;
    const { reloadMenu } = useMenuReload();

    const [stationIdLabel, setStationIdLabel] = useState("");
    const [stationIdTitleLabel, setStationIdTitleLabel] = useState("");
    const [stationIdYesLabel, setStationIdYesLabel] = useState("");
    const [stationIdNoLabel, setStationIdNoLabel] = useState("");
    const [searchBoxPlaceholder, setSearchBoxPlaceholder] = useState("");
    const [expandedMenu, setExpandedMenu] = useState<string | null>("");

    const { menus: menuDefMenus } = useSelector((s: StoreModel) => s[MenuDefinitionSlice.name]) as MenuDefinitionSliceModel;

    const loadResourcesAndGetProfileInfo = async () => {
      setStationIdLabel(await TPI18N.GetText("MainMenu", "StationIdLabel"));
      setStationIdTitleLabel(
        await TPI18N.GetText("MainMenu", "StationIdTitleLabel")
      );
      setStationIdYesLabel(
        await TPI18N.GetText("MainMenu", "StationIdYesLabel")
      );
      setStationIdNoLabel(await TPI18N.GetText("MainMenu", "StationIdNoLabel"));
      setSearchBoxPlaceholder(
        await TPI18N.GetText("MainMenu", "SearchBoxPlaceholder")
      );
    };

    const getProfileInfo = async () => {
      let serviceClient = new ProfileService();
      let expectedCodes: Array<number> = [200];

      try {
        let responseRequest = await serviceClient.getProfileById(
          generalContext.globalUserInfo.profileId,
          false,
          true,
          expectedCodes
        );

        let recordInfo: ProfilesGetViewModel;
        recordInfo = { ...responseRequest };
        if (recordInfo.requireSupplyStationId) {
          handleStationIdChangeClicked();
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getProfileById ex`,
          TPLogType.ERROR,
          error
        );
      }
    };

    useEffect(() => {
      loadResourcesAndGetProfileInfo();
    }, []);

    //Functions called form parent VerticalTabsAdminContainer.
    useImperativeHandle(ref, () => ({
      mouseMoveFromParent() {
        if (isLeftMenuCollapsed && expandedMenu) setExpandedMenu(null);
          // let menuHasOpenedSubmenu: boolean = false;
          // const menuItemsCopy = menuItems.map((sec) => ({
          //   ...sec,
          //   subMenus: sec.subMenus.map((men) => {
          //     if (sec.subMenus.find((k) => k.isExpanded)) {
          //       menuHasOpenedSubmenu = true;
          //     }
          //     return { ...men, isExpanded: false };
          //   }),
          // }));
          // if (menuHasOpenedSubmenu) {
          //   setMenuItems(menuItemsCopy);
          // }
        
      },
    }));

    const [menuItems, setMenuItems] = useState<
      Array<MenuItemViewModelForRender>
    >([]);
    const [unfilteredMenu, setUnfilteredMenu] = useState<any>([]);
    const [searchBoxValue, setSearchBoxValue] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    //State modal
    let modalStationIdInitialState: TPModalStationIdState = {
      isShown: false,
      callBackData: "",
    };

    const [modalStationIdState, setModalStationIdState] = useState(
      modalStationIdInitialState
    );

    const appInsights = useAppInsightsContext();

    //Call callback function
    const handleMenuItemClick = (
      option: string,
      localizedDescription: string
    ): void => {
      let eventName: any = { name: "navigationevent" };
      let payload: any = { visitedmenu: option };
      appInsights.trackEvent(eventName, payload);
      menuItemClickCallback(option, localizedDescription);
    };

    

    const handleExpandCurrentMenu = (currentMenu: any, show: boolean) => {
      const menuItemsCopy = menuItems.map((sec) => ({
        ...sec,
        subMenus: sec.subMenus.map((men) =>
          men.menuItemId === currentMenu?.menuItemId
            ? { ...men, isExpanded: show }
            : {
                ...men,
                isExpanded: men.isExpanded ? false : men.isExpanded,
              }
        ),
      }));
      setMenuItems(menuItemsCopy);
    };

    const handleMenuItemSelect = (e: any) => {
      setSearchBoxValue(e.target.value);
      const itemSelected: any = unfilteredMenu.find(
        (item: any) => item.localizedDescription === e.target.value
      );

      if (itemSelected) {
        let eventName: any = { name: "navigationevent" };
        let payload: any = { visitedmenu: itemSelected.menuItemLink };
        appInsights.trackEvent(eventName, payload);
        menuItemClickCallback(
          itemSelected.menuItemLink,
          itemSelected.localizedDescription
        );
        setSearchBoxValue("");
      }
    };

    const reportKeepAliveBeat = async () => {
      //check if exists
      //if exist update keepalive and stationId
      //if not exist create it

      let serviceClient = new BrowserKeepAliveService();
      let expectedCodes: Array<number> = [200, 404];
      let bolR: boolean = false;
      try {
        let responseRequest = await serviceClient.getBrowserClientBySesionId(
          TPGlobal.sessionId,
          false,
          false,
          expectedCodes
        );
        if (responseRequest && responseRequest.length === 1) {
          bolR = await updateKeepAliveBeat();
          if (!bolR) {
            //todo logs
          }
        } else {
          bolR = await insertKeepAliveBeat();
          if (!bolR) {
            //todo logs
          }
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reportKeepAliveBeat ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reportKeepAliveBeat ex`);
        return null;
      }
    };

    const insertKeepAliveBeat = async () => {
      let serviceClient = new BrowserKeepAliveService();
      let expectedCodes: Array<number> = [200, 429];
      let inputDTO: BrowserClientInputDTO = {
        connectionId: TPGlobal.sessionId,
        iPAddress: "todo", //TODO
        symmetricKey: "todo", //TODO
        loginUser: TPGlobal.currentUserGuid,
        stationId: TPGlobal.stationId ? TPGlobal.stationId : "",
        uRLExternalAPP: "",
      };
      try {
        let responseRequest = await serviceClient.insertBrowserClient(
          inputDTO,
          false,
          false,
          expectedCodes
        );
        return true;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} insertKeepAliveBeat ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} insertKeepAliveBeat ex`);
        return false;
      }
    };

    const updateKeepAliveBeat = async () => {
      let serviceClient = new BrowserKeepAliveService();
      let expectedCodes: Array<number> = [200];
      let inputDTO: BrowserClientUpdateInputDTO = {
        connectionId: TPGlobal.sessionId,
        stationId: TPGlobal.stationId ? TPGlobal.stationId : "",
      };
      try {
        let responseRequest = await serviceClient.updateBrowserClient(
          inputDTO,
          false,
          false,
          expectedCodes
        );
        return true;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} updateKeepAliveBeat ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} updateKeepAliveBeat ex`);
        return false;
      }
    };

    const handleStationIdChangeClicked = (): any => {
      let newModalStationIdState: TPModalStationIdState;
      newModalStationIdState = { ...modalStationIdState };
      newModalStationIdState.isShown = true;
      newModalStationIdState.callBackData = "";
      setModalStationIdState(newModalStationIdState);
    };

    const handleStationIdSetted = async (isModalOpened: boolean, data: any) => {
      let newModalStationIdState: TPModalStationIdState;
      newModalStationIdState = { ...modalStationIdState };
      newModalStationIdState.isShown = isModalOpened;
      newModalStationIdState.callBackData = data;
      setModalStationIdState(newModalStationIdState);

      TPGlobal.stationId = data;

      await reportKeepAliveBeat();
    };

    // Get menu and submenu unique id by {item} {isSubItem}
    const getMenuIdBy = (item: string, isSubItem: boolean = false) => {
      return `${camelCaseToId(item)}-${isSubItem ? "submenu" : "menu"}-id`;
    };

    const getMenuItems = async (userGuid: string) => {
      const responseRequest: Array<MenuItemViewModelForRender> =
        await MenuItemsService.getMenuItemsByUser(
          userGuid.toUpperCase(),
          false,
          false,
          TPGlobal.defaultExpectedCodes
        );

      setUnfilteredMenu(responseRequest);

      const rootElements = responseRequest.filter(
        (menuItem) => menuItem.menuItemParent === null
      );

      const finalMenu = rootElements.map((root) => {
        return {
          ...root,
          isExpanded: false,
          subMenus: responseRequest
            .filter((menuItem) => menuItem.menuItemParent === root.menuItemId)
            .map((secondLevel) => {
              return {
                ...secondLevel,
                isExpanded: false,
                subMenus: responseRequest.filter(
                  (menuItem2) =>
                    menuItem2.menuItemParent === secondLevel.menuItemId
                ),
              };
            }),
        };
      });
      setMenuItems(finalMenu);
    };

    useEffect(() => {
      if (isLeftMenuCollapsed) setExpandedMenu(null);
      // if (isLeftMenuCollapsed) handleExpandCurrentMenu(null, true);
    }, [isLeftMenuCollapsed]);

    useEffect(() => {
      if (generalContext.globalUserInfo.userGuid) {
        getMenuItems(generalContext.globalUserInfo.userGuid);
        getProfileInfo();
      }
    }, [reloadMenu, generalContext.globalUserInfo, menuDefMenus]);

    useEffect(() => {
      if (menuItems.length > 0) setIsLoading(false);
    }, [menuItems]);

    return (
      <>
        <TPModalStationId
          title={stationIdTitleLabel}
          yesLabel={stationIdYesLabel}
          noLabel={stationIdNoLabel}
          callBackData={modalStationIdState.callBackData}
          isShown={modalStationIdState.isShown}
          callBackAnswer={handleStationIdSetted}
        ></TPModalStationId>
        <TPMainMenuContainerStyle>
          <LeftMenuHeaderStyled>
            <MainHeader></MainHeader>
          </LeftMenuHeaderStyled>
          {!isLoading && (
            <TPSearchMenuContainer>
              <TPTextBoxStyled
                list="menuItems"
                name="menuItems"
                id="general-search"
                onChange={handleMenuItemSelect}
                value={searchBoxValue}
                placeholder={searchBoxPlaceholder}
                className="form-control"
              />
              <datalist id="menuItems">
                {menuItems &&
                  menuItems.map((section) =>
                    section.subMenus.map((menu) =>
                      menu.subMenus.map((subMenu) => (
                        <option
                          key={subMenu.menuItemId}
                          value={subMenu.localizedDescription}
                        ></option>
                      ))
                    )
                  )}
              </datalist>
            </TPSearchMenuContainer>
          )}
          <SectionContainerStyled>
            {(isLoading && <Skeleton count={10} />) ||
              (menuItems &&
                menuItems.sort((mA, mB) => {
                  if (mA.order < mB.order) return -1;
                  if (mA.order > mB.order) return 1;
                  return 0;
                }).map((section) => (
                  <SectionItemContainerStyled key={section.menuItemId}>
                    <SectionTitle>{section.menuItemName}</SectionTitle>
                    <MenuContainerStyled>
                      {section.subMenus &&
                        section.subMenus.sort((mA, mB) => {
                          if (mA.order < mB.order) return -1;
                          if (mA.order > mB.order) return 1;
                          return 0;
                        }).map((menu) => (
                          <MenuItemContainerStyled key={menu.menuItemId}>
                            <MenuItemTitleContainerStyled
                              id={getMenuIdBy(menu.menuItemId)}
                              onClick={() => setExpandedMenu(expandedMenu === menu.menuItemId ? null : menu.menuItemId)}
                            >
                              {Boolean(availableIcons[menu.menuItemImage as keyof IconType]) ?
                                (availableIcons[menu.menuItemImage as keyof IconType] as IconType)({})
                                :
                                <TPIcon
                                  iconType={
                                    TPIconTypes[
                                      menu.menuItemImage as keyof typeof TPIconTypes
                                    ]
                                  }
                                />
                              }
                              <MenuTitleItem>{menu.menuItemName}</MenuTitleItem>
                              <ExpanderContainerStyled>
                                <TPIcon
                                  iconType={
                                    expandedMenu === menu.menuItemId
                                      ? TPIconTypes.expandLess
                                      : TPIconTypes.expandMore
                                  }
                                />
                              </ExpanderContainerStyled>
                            </MenuItemTitleContainerStyled>
                            {expandedMenu === menu.menuItemId && (
                              <SubmenuContainerStyled
                                isLeftMenuCollapsed={isLeftMenuCollapsed}
                              >
                                {menu.subMenus &&
                                  menu.subMenus.sort((mA, mB) => {
                                    if (mA.order < mB.order) return -1;
                                    if (mA.order > mB.order) return 1;
                                    return 0;
                                  }).map((submenu) => (
                                    <SubmenuItemContainerStyled
                                      id={getMenuIdBy(submenu.menuItemId, true)}
                                      key={submenu.menuItemId}
                                      onClick={() => {
                                        handleMenuItemClick(
                                          submenu.menuItemLink,
                                          submenu.localizedDescription
                                        );
                                      }}
                                    >
                                      <SubMenuTitle>
                                        {submenu.menuItemName}
                                      </SubMenuTitle>
                                    </SubmenuItemContainerStyled>
                                  ))}
                              </SubmenuContainerStyled>
                            )}
                          </MenuItemContainerStyled>
                        ))}
                    </MenuContainerStyled>
                    <hr />
                  </SectionItemContainerStyled>
                )))}
          </SectionContainerStyled>
          
        </TPMainMenuContainerStyle>
      </>
    );
  }
);

export default MainMenu;
