import TPGlobal from "@/helpers/TPGlobal";
import { ComplementsRenderTP, TPKeyValue } from "@/helpers/TPKeyValue";
import TPModal from "@/layouts/TPModal/TPModal";
import {
    AdditionalDataOpenTextTypesEnum,
    AdditionalDataTypeEnum,
    AttachmentType,
    ModalSizeEnum,
    TPButtonTypes,
    TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { CaseService } from "@/services/CaseService";
import { TPI18N } from "@/services/I18nService";
import React, { useState, useImperativeHandle, useEffect, useRef } from "react";
import TPButton from "../bootstrap/components/buttons/TPButton";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "../bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextArea from "../bootstrap/forms/textArea/TPTextArea";
import TPTextBox from "../bootstrap/forms/textbox/TPTextBox";
import TPLabel from "../bootstrap/forms/TPLabel/TPLabel";
import FileUploader from "../TPDragAndDropUploadFile/FileUploader";
import { TPAddtionalDataUIModes } from "./TPAdditionalDataUImodes";
import { v4 as uuidv4 } from "uuid";
import {
    AttachmentInputDTO,
    AttachmentInputDTODTOValidator,
} from "@/models/Attachments/AttachmentInputDTO";
import {
    showToast,
    TPToastTypes,
} from "../bootstrap/components/toasts/TPToast";
import { TemporaryAttachmentService } from "@/services/TemporaryAttachments";
import { StorageService } from "@/services/StorageService";
import { DownloadFileService } from "@/services/DownloadFileService";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { AdditionalDataViewModel } from "../../models/AdditionalData/AdditionalDataModels";
import TPRenderAdditionalDataSwitch from "./TPRenderAdditionalDataSwitch";


export type TPAdditionalDataPreviewModel = {
    additionalData?: any;
    complementsRender?: ComplementsRenderTP,
};




const TPAdditionalDataPreview = React.forwardRef(
    (
        {
            additionalData,
            complementsRender
        }: TPAdditionalDataPreviewModel,
        ref,
    ) => {


        const [additionalDataTemp, setAdditionalDataTemp] = useState(additionalData);
        const [recordIsOutdated, setRecordIsOutdated] = useState("");
        const [complementsRenderTP, setComplementsRenderTP] = useState<ComplementsRenderTP | undefined>(complementsRender);

        const childRef: any = useRef();



        const loadLabel = async () => {
            setRecordIsOutdated(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "RecordIsOutdated"),
            );
        }


        const handleVisible = (): boolean => {
            if (!additionalData) return false
            if (!additionalData.additionalDataTypeId) return false
            if (additionalData.additionalDataTypeId + "" == "") return false
            if (additionalData.categoryId + "" == "") return false


            return true;
        }


        const handleValidateTypeData = () => {
            let mode = false;
            switch (additionalData.additionalDataTypeId) {
                case AdditionalDataTypeEnum.date:
                    mode = true;
                    break;
                case AdditionalDataTypeEnum.datelag:
                    mode = true;
                    break;
                case AdditionalDataTypeEnum.email:
                    mode = true;
                    break;
                case AdditionalDataTypeEnum.integerlist:
                    mode = true;
                    break;
                case AdditionalDataTypeEnum.internallist:
                    mode = true;
                    break;
                case AdditionalDataTypeEnum.label:
                    mode = true;
                    break;
                case AdditionalDataTypeEnum.link:
                    mode = true;
                    break;
                case AdditionalDataTypeEnum.listvalue:
                    mode = true;
                    break;
                case AdditionalDataTypeEnum.numeric:
                    mode = true;
                    break;
                case AdditionalDataTypeEnum.phone:
                    mode = true;
                    break;
                case AdditionalDataTypeEnum.textarea:
                    mode = true;
                    break;
                case AdditionalDataTypeEnum.textbox:
                    mode = true;
                    break;
                case AdditionalDataTypeEnum.tree:
                    mode = true;
                    break;
                case AdditionalDataTypeEnum.yesno:
                    mode = true;
                    break;
                case AdditionalDataTypeEnum.attachment:
                    mode = true;
                    break;

            }

            return mode;
        }




        const handleAdditionalDataRender = (): AdditionalDataViewModel => {
            let temp: AdditionalDataViewModel =
            {
                id: additionalData.id,
                description: additionalData.description,
                validationURL: additionalData.validationURL,
                comments: additionalData.comments,
                additionalDataTypeId: additionalData.additionalDataTypeId,
                additionalDataTypeDescription: additionalData.description,
                additionalDataCategoryDescription: additionalData.description,
                jsonParameters: additionalData.jsonParameters,
                categoryId: additionalData.categoryId,
                enableEditingModifyBasicData: additionalData.enableEditingModifyBasicData,
                isSystemRecord: false,
                localizedDescription: additionalData.description,
                order: 0,
                isMandatory: false
            }
            return temp;
        }


        useEffect(() => {
            loadLabel();
        }, []);

        useEffect(() => {
            setAdditionalDataTemp(additionalData);
        }, [additionalData]);

        useEffect(() => {
            setComplementsRenderTP(complementsRender);
        }, [complementsRender]);



        return (
            <>
                {handleVisible() ? (
                    <div style={{ width: "auto" }} >
                        <div>
                            <>
                                {handleValidateTypeData() ?

                                    <TPRenderAdditionalDataSwitch
                                        guidControl={additionalDataTemp.additionalDataTypeId}
                                        modeUI={TPAddtionalDataUIModes.Collect}
                                        itemToRender={{ key: additionalData.additionalDataTypeId, value: "", value2: handleAdditionalDataRender() }}
                                        ref={childRef}
                                        complementsRenderTP={complementsRenderTP}
                                        onChange={(idControl: any, newValue: any, additionalDataId: any) => { console.log("Date",newValue ); }}
                                    />

                                    :
                                    <TPLabel style={{ color: "red" }} labelText={recordIsOutdated} />
                                }
                            </>
                        </div>
                    </div>
                ) : <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} ></div>}
            </>
        );

    }
);

export default TPAdditionalDataPreview;
