import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ReactElement } from "react";
import { ToastContainer, ToastOptions, toast } from "react-toastify";
import TPIcon from "../../extend/TPIcons/TPIcon";
import allThemes from "@/assets/styles/theme";

export enum TPToastTypes {
  info = "info",
  success = "success",
  warning = "warning",
  error = "error",
}

const DEFAULT_ICON_SIZE = "22px";
const TOAST_ICONS = {
  success: 
    <TPIcon 
      iconType={TPIconTypes.solved} 
      style={{fontSize: DEFAULT_ICON_SIZE}} 
    />,
  error:
    <TPIcon 
      iconType={TPIconTypes.alert} 
      style={{fontSize: DEFAULT_ICON_SIZE}}
    />,
  warning: 
    <TPIcon 
      iconType={TPIconTypes.AlertTriangle} 
      style={{fontSize: DEFAULT_ICON_SIZE}}
    />,
  info: 
    <TPIcon 
      iconType={TPIconTypes.alert} 
      style={{fontSize: DEFAULT_ICON_SIZE}}
    />,
}

export const showToast = (
  message: string, 
  toastType: TPToastTypes, 
  icon?: TPIconTypes, 
  options?: ToastOptions
) => {
  toast(message, {
    type: toastType,
    icon: icon ? (
      <TPIcon
        iconType={icon} 
        style={{fontSize: DEFAULT_ICON_SIZE}}
      />) : 
      TOAST_ICONS[toastType],
    style: {
      backgroundColor: allThemes.base.toast.background[toastType],
      color: allThemes.base.toast.foreground[toastType],
    },
    closeButton: () => (
      <div 
        style={{
          display:"flex",
          alignItems: "center",
          fontSize: DEFAULT_ICON_SIZE
        }}
      >
        <TPIcon iconType={TPIconTypes.close} />
      </div>
    ),
    ...options,
  });
};

const TPToast = (): ReactElement => {
  return <ToastContainer />;
};

export default TPToast;
