import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";
import { RecordLocalizedModel } from "../Global/TPGlobalModels";

export interface EmailTemplateInputDTO {
  id: string;
  languageId: string;
  description: string;
  idAccount: string;
  to: string;
  cc: string;
  bcc: string;
  subject: string;
  body: string;
  isAddable: boolean;
  attachCaseInformation: number;
  isActive: boolean;
  descriptionLocalizedValues: Array<RecordLocalizedModel>;
}

export interface CloneEmailTemplateInputDTO {
  SourceId: string;
  DestinationId: string;
  IdAccount: string;
  IsAddable: boolean;
  IsActive: boolean;
  Description?: string;
  DescriptionLocalizedValues: Array<RecordLocalizedModel>;
}

export interface EmailTemplateAdminDetailInputDTO {
  emailTemplateId: string;
  languageId: string;
  subject: string;
  body: string;
  isSystemRecord?: boolean;
  description?: string;
}

export class EmailTemplateInputDTOValidator extends Validator<EmailTemplateInputDTO> {
  constructor() {
    super();

    const resourceSet = "EmailTemplateInputDTOValidator";
    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("description")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTODescriptionEmpty")
        .must((x) => {
          return TPGlobal.TPSanitize(x) == x;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .maxLength(200)
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      this.ruleForEach("descriptionLocalizedValues")
        .must((descriptionLocalizedValues) => {
          if (
            descriptionLocalizedValues.localizedValue.trim() === "" ||
            descriptionLocalizedValues.order === 1
          ) {
            return true;
          }
          return (
            TPGlobal.TPSanitize(descriptionLocalizedValues.localizedValue) ===
            descriptionLocalizedValues.localizedValue
          );
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLocalizedValues")
        .must((descriptionLocalizedValues) => {
          if (
            descriptionLocalizedValues.localizedValue.trim() === "" ||
            descriptionLocalizedValues.order === 1
          ) {
            return true;
          }
          return descriptionLocalizedValues.localizedValue.length <= 200;
        })
        .withMessage(
          resourceSet + "|InputDTOInvalidDescriptionLocalizedValuesLength",
        );

      this.ruleFor("idAccount")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOAccountEmpty")

        .must((x) => {
          return TPGlobal.TPSanitize(x) == x;
        })
        .withMessage(resourceSet + "|InputDTOInvalidIdAccount")
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidIdAccountLength");

      this.ruleFor("languageId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOLanguageIdEmpty")

        .must((x) => {
          return TPGlobal.TPSanitize(x) === x;
        })
        .withMessage(resourceSet + "|InputDTOInvalidLanguageId");

      this.ruleFor("subject")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOSubjectEmpty")
        .must((x) => {
          return x ? TPGlobal.TPSanitize(x) === x : true;
        })
        .withMessage(resourceSet + "|InputDTOInvalidSubject")
        .maxLength(500)
        .withMessage(resourceSet + "|InputDTOInvalidSubjectLength");

      this.ruleFor("body")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOBodyEmpty")
        .must((x) => {
          return x ? TPGlobal.TPSanitize(x) === x : true;
        })
    } catch (error) {}
  }
}

export class CloneEmailTemplateInputDTOValidator extends Validator<CloneEmailTemplateInputDTO> {
  constructor() {
    super();

    const resourceSet = "EmailTemplateInputDTOValidator";
    try {
      this.ruleFor("SourceId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("DestinationId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(resourceSet + "|InputDTOInvalidId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleForEach("DescriptionLocalizedValues")
        .must((DescriptionLocalizedValues) => {
          if (
            DescriptionLocalizedValues.localizedValue.trim() === "" ||
            DescriptionLocalizedValues.order === 1
          ) {
            return true;
          }
          return (
            TPGlobal.TPSanitize(DescriptionLocalizedValues.localizedValue) ===
            DescriptionLocalizedValues.localizedValue
          );
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLocalizedValues")
        .must((DescriptionLocalizedValues) => {
          if (
            DescriptionLocalizedValues.localizedValue.trim() === "" ||
            DescriptionLocalizedValues.order === 1
          ) {
            return true;
          }
          return DescriptionLocalizedValues.localizedValue.length <= 200;
        })
        .withMessage(
          resourceSet + "|InputDTOInvalidDescriptionLocalizedValuesLength",
        );

      this.ruleFor("IdAccount")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOAccountEmpty")

        .must((x) => {
          return TPGlobal.TPSanitize(x) == x;
        })
        .withMessage(resourceSet + "|InputDTOInvalidIdAccount")
        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidIdAccountLength");
    } catch (error) {}
  }
}
