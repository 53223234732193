import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalSuccess, {
  TPModalSuccessStateType,
} from "@/layouts/TPModalSuccess/TPModalSuccess";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { TPActiveOptions, TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { ToastMessagesDTO } from "@/models/ToastMessages/ToastMessagesDTO";
import { GroupsService } from "@/services/GroupsService";
import { TPI18N } from "@/services/I18nService";
import { UserService } from "@/services/UserService";
import { useEffect, useState } from "react";
import { MdAdd, MdInfo } from "react-icons/md";
import {
  AddButton,
  AddButtonSmall,
  AddButtonSmaller,
  DescriptionMessage,
  FlexContainer,
  TextInputWrapper,
  TPStyledBody,
  TPStyledBodyCell,
  TPStyledBodyRow,
  TPStyledTable,
} from "./TPToastMessagesStyled";

type UserNameAndLoginType = {
  newUserNameAndLogin: Array<TPKeyValue>;
  reportUserNameAndLogin: string;
  newUserNameAndLoginErrorMessage: string;
  reportUserNameAndLoginErrorMessage: string;
  [x: string]: any;
};

type GroupType = {
  newGroup: Array<TPKeyValue>;
  reportGroup: string;
  newGroupErrorMessage: string;
  reportGroupErrorMessage: string;
  [x: string]: any;
};

const TPToastMessages = () => {
  const componentFileName: string = "TPToastMessages.tsx";
  const resourceSet: string = "ToastMessagesContainerComponent";

  const [pageTitleLabel, setPageTitleLabel] = useState("");
  const [allUsersLabel, setAllUsersLabel] = useState("");
  const [userNameAndLoginLabel, setUserNameAndLoginLabel] = useState("");
  const [groupLabel, setGroupLabel] = useState("");
  const [userNameAndLoginEmptyLabel, setUserNameAndLoginEmptyLabel] =
    useState("");
  const [groupEmptyLabel, setGroupEmptyLabel] = useState("");
  const [stationIdLabel, setStationIdLabel] = useState("");
  const [messageLabel, setMessageLabel] = useState("");
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [isAllUsers, setIsAllUsers] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // state to track loading status
  const [saveButtonLabel, setSaveButtonLabel] = useState("");

  const [stationIdTable, setStationIdTable] = useState<Array<TPKeyValue>>([]);
  const [userNameAndLoginTable, setUserNameAndLoginTable] = useState<
    Array<TPKeyValue>
  >([]);
  const [groupTable, setGroupTable] = useState<Array<TPKeyValue>>([]);

  // UserNameAndLogin
  const initialUserNameAndLoginState: UserNameAndLoginType = {
    newUserNameAndLogin: [],
    reportUserNameAndLogin: "",
    newUserNameAndLoginErrorMessage: "",
    reportUserNameAndLoginErrorMessage: "",
  };
  const [userNameAndLoginState, setUserNameAndLoginState] =
    useState<UserNameAndLoginType>(initialUserNameAndLoginState);
  const [
    autocompleteUserNameAndLoginOptions,
    setAutocompleteUserNameAndLoginOptions,
  ] = useState<Array<TPKeyValue>>([]);
  const [
    autocompleteUserNameAndLoginTopNOptions,
    setAutocompleteUserNameAndLoginTopNOptions,
  ] = useState<Array<TPKeyValue>>([]);

  // Group
  const initialGroupState: GroupType = {
    newGroup: [],
    reportGroup: "",
    newGroupErrorMessage: "",
    reportGroupErrorMessage: "",
  };
  const [groupState, setGroupState] = useState<GroupType>(initialGroupState);
  const [autocompleteGroupOptions, setAutocompleteGroupOptions] = useState<
    Array<TPKeyValue>
  >([]);
  const [autocompleteGroupTopNOptions, setAutocompleteGroupTopNOptions] =
    useState<Array<TPKeyValue>>([]);

  //modal success
  const initialModalSuccess: TPModalSuccessStateType = {
    isShown: false,
    title: "",
    successMessage: "",
    yesLabel: "",
    callBackFunction: TPGlobal.foo,
  };
  const [modalSuccessState, setModalSuccessState] =
    useState<TPModalSuccessStateType>(initialModalSuccess);

  // StationId
  const [stationIdValue, setStationIdValue] = useState("");
  const [stationIdErrorMessage, setStationIdErrorMessage] = useState("");

  // Message
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");

  const formatTable = (table: Array<TPKeyValue>): string => {
    // Assuming TPKeyValue has a property `value` you want to join
    return table.map((item) => item.key).join("|");
  };

  // useEffect(() => {

  //     // Format and log the formatted values when any of the tables change
  //     const formattedStationIdTable = formatTable(stationIdTable);
  //     console.log('Formatted stationIdTable:', formattedStationIdTable);

  //     const formattedUserNameAndLoginTable = formatTable(userNameAndLoginTable);
  //     console.log('Formatted userNameAndLoginTable:', formattedUserNameAndLoginTable);

  //     const formattedGroupTable = formatTable(groupTable);
  //     console.log('Formatted groupTable:', formattedGroupTable);

  // }, [stationIdTable, userNameAndLoginTable, groupTable]);

  const handleAllUsers = () => {
    setIsAllUsers(!isAllUsers);
    setUserNameAndLoginState({
      ...userNameAndLoginState,
      newUserNameAndLogin: [],
    });
    setGroupState({
      ...groupState,
      newGroup: [],
    });

    setStationIdTable([]);
    setGroupTable([]);
    setUserNameAndLoginTable([]);
  };

  const handleStationIdChange = (e: any) => {
    let value: string = e.target.value;
    setStationIdValue(value);
    setMessageError(""); // Clear any previous error message
  };

  const handleMessageChange = (e: any) => {
    let value: string = e.target.value;
    setMessage(value);
    setMessageError(""); // Clear any previous error message
  };

  const handleUserNameAndLoginChange = (
    newSelectedValue: Array<TPKeyValue>
  ) => {
    let newUserNameAndLoginState = { ...userNameAndLoginState };
    newUserNameAndLoginState.newUserNameAndLogin = newSelectedValue;
    newUserNameAndLoginState.newUserNameAndLoginErrorMessage = "";
    setUserNameAndLoginState(newUserNameAndLoginState);
  };

  const handleUserNameAndLoginOnAutocompleteQuery = async (query: string) => {
    let tasktypeService = new UserService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      let responseRequest = await tasktypeService.getUsersByFilter(
        TPActiveOptions.ACTIVE.toString(),
        false,
        true,
        expectedCodes
      );
      let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
        (item) => ({
          key: item.login,
          value: `${item.name}`,
        })
      );
      setAutocompleteUserNameAndLoginOptions(newToKeyValueList);
      return newToKeyValueList;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleUserNameAndLoginOnAutocompleteQuery ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} handleUserNameAndLoginOnAutocompleteQuery ex`
      );
      return [];
    }
  };

  const handleUserNameAndLoginOnAutocompleteKeyDown = (event: any) => {
    if (![37, 39, 13, 35, 36].includes(event.keyCode)) {
      setAutocompleteUserNameAndLoginOptions([]);
      let newUserNameAndLoginState = { ...userNameAndLoginState };
      newUserNameAndLoginState.newUserNameAndLogin = [];
      newUserNameAndLoginState.newUserNameAndLoginErrorMessage = "";
      setUserNameAndLoginState(newUserNameAndLoginState);
    }
  };

  const handleAutoCompleteUserNameAndLoginNClick = async () => {
    let newUserNameAndLoginTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteUserNameAndLoginTopNOptions.length === 0) {
      newUserNameAndLoginTopNOptions =
        await handleUserNameAndLoginOnAutocompleteQuery("");

      if (newUserNameAndLoginTopNOptions.length >= 1) {
        setAutocompleteUserNameAndLoginTopNOptions([
          ...newUserNameAndLoginTopNOptions,
        ]);
        setAutocompleteUserNameAndLoginOptions([
          ...newUserNameAndLoginTopNOptions,
        ]);
        let newUserNameAndLoginState = { ...userNameAndLoginState };
        newUserNameAndLoginState.newUserNameAndLogin = [];
        newUserNameAndLoginState.newUserNameAndLoginErrorMessage = "";
        setUserNameAndLoginState(newUserNameAndLoginState);
      }
    } else {
      setAutocompleteUserNameAndLoginOptions([
        ...autocompleteUserNameAndLoginTopNOptions,
      ]);
      let newUserNameAndLoginState = { ...userNameAndLoginState };
      newUserNameAndLoginState.newUserNameAndLogin = [];
      newUserNameAndLoginState.newUserNameAndLoginErrorMessage = "";
      setUserNameAndLoginState(newUserNameAndLoginState);
    }
  };

  const handleGroupChange = (newSelectedValue: Array<TPKeyValue>) => {
    let newGroupState = { ...groupState };
    newGroupState.newGroup = newSelectedValue;
    newGroupState.newGroupErrorMessage = "";
    setGroupState(newGroupState);
  };

  const handleGroupOnAutocompleteQuery = async (query: string) => {
    const groupClient = new GroupsService();
    const expectedCodes: Array<number> = [200, 404];

    try {
      let responseRequest = await groupClient.getGroupsByFilter(
        TPActiveOptions.ACTIVE.toString(),
        false,
        true,
        expectedCodes
      );
      let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
        (item) => ({
          key: item.groupId,
          value: `${item.user.name}`,
        })
      );
      setAutocompleteGroupOptions(newToKeyValueList);
      return newToKeyValueList;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleGroupOnAutocompleteQuery ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} handleGroupOnAutocompleteQuery ex`
      );
      return [];
    }
  };

  const handleGroupOnAutocompleteKeyDown = (event: any) => {
    if (![37, 39, 13, 35, 36].includes(event.keyCode)) {
      setAutocompleteGroupOptions([]);
      let newGroupState = { ...groupState };
      newGroupState.newGroup = [];
      newGroupState.newGroupErrorMessage = "";
      setGroupState(newGroupState);
    }
  };

  const handleAutoCompleteGroupNClick = async () => {
    let newGroupTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteGroupTopNOptions.length === 0) {
      newGroupTopNOptions = await handleGroupOnAutocompleteQuery("");

      if (newGroupTopNOptions.length >= 1) {
        setAutocompleteGroupTopNOptions([...newGroupTopNOptions]);
        setAutocompleteGroupOptions([...newGroupTopNOptions]);
        let newGroupState = { ...groupState };
        newGroupState.newGroup = [];
        newGroupState.newGroupErrorMessage = "";
        setGroupState(newGroupState);
      }
    } else {
      setAutocompleteGroupOptions([...autocompleteGroupTopNOptions]);
      let newGroupState = { ...groupState };
      newGroupState.newGroup = [];
      newGroupState.newGroupErrorMessage = "";
      setGroupState(newGroupState);
    }
  };

  const ERROR_MESSAGES = {
    messageRequired: async () =>
      await TPI18N.GetText(resourceSet, "MessageRequired"),
    atLeastOneDestination: async () =>
      await TPI18N.GetText(resourceSet, "AtLeastOneDestination"),
    failedToSendMessage: async () =>
      await TPI18N.GetText(resourceSet, "FailedToSendMessage"),
    unexpectedError: async () =>
      await TPI18N.GetText(resourceSet, "UnexpectedError"),
  };

  const loadResourcesAndTaskInfo = async () => {
    setPageTitleLabel(await TPI18N.GetText(resourceSet, "PageTitle"));
    setAllUsersLabel(await TPI18N.GetText(resourceSet, "AllUsersLabel"));
    setUserNameAndLoginLabel(
      await TPI18N.GetText(resourceSet, "UserNameAndLoginLabel")
    );
    setGroupLabel(await TPI18N.GetText(resourceSet, "GroupLabel"));
    setUserNameAndLoginEmptyLabel(
      await TPI18N.GetText(resourceSet, "UserNameAndLoginEmptyLabel")
    );
    setGroupEmptyLabel(await TPI18N.GetText(resourceSet, "GroupEmptyLabel"));
    setStationIdLabel(await TPI18N.GetText(resourceSet, "StationIdLabel"));
    setMessageLabel(await TPI18N.GetText(resourceSet, "MessageLabel"));
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton")
    );
  };

  useEffect(() => {
    loadResourcesAndTaskInfo();
  }, []);

  const handleDelete = (
    keyToDelete: string,
    type: "stationId" | "group" | "userLogin"
  ) => {
    if (type === "stationId") {
      // Delete from stationIdTable
      const updatedStationIdTable = stationIdTable.filter(
        (item) => item.key !== keyToDelete
      );
      setStationIdTable(updatedStationIdTable);
    } else if (type === "group") {
      // Delete from groupTable
      const updatedGroupTable = groupTable.filter(
        (item) => item.key !== keyToDelete
      );
      setGroupTable(updatedGroupTable);
    } else if (type === "userLogin") {
      // Delete from userLogin
      const updatedData = userNameAndLoginTable.filter(
        (item) => item.key !== keyToDelete
      );
      setUserNameAndLoginTable(updatedData);
    }
  };

  const handleClickUserNameAndLogin = () => {
    // Get the currently selected value from userNameAndLoginState
    const selectedValue = userNameAndLoginState.newUserNameAndLogin[0];

    if (!selectedValue) {
      return; // No selected value, exit the function
    }

    // Check if the key already exists in userNameAndLoginTable
    const keyExists = userNameAndLoginTable.some(
      (item) => item.key === selectedValue.key
    );
    const formattedString = userNameAndLoginTable
      .map((item) => `${item.key}$${item.value}`)
      .join("|");

    if (!keyExists) {
      // If the key does not exist, add the new value to userNameAndLoginTable
      setUserNameAndLoginTable((prevTable) => [...prevTable, selectedValue]);
    }

    // Clear the selection and error message after adding
    setUserNameAndLoginState({
      ...userNameAndLoginState,
      newUserNameAndLogin: [],
      newUserNameAndLoginErrorMessage: "",
    });
    setMessageError("");
  };

  const handleClickGroup = () => {
    const selectedValue = groupState.newGroup[0];
    if (!selectedValue) return;

    // Check if the group already exists in the groupTable
    const keyExists = groupTable.some((item) => item.key === selectedValue.key);
    if (!keyExists) {
      // If the key does not exist, add the new value to the groupTable
      setGroupTable((prevTable) => [...prevTable, selectedValue]);
    }

    // Clear the selection and error message after adding
    setGroupState({
      ...groupState,
      newGroup: [],
      newGroupErrorMessage: "",
    });
    setMessageError("");
  };

  const handleClickStationId = () => {
    if (!stationIdValue.trim()) {
      return; // No value, exit the function
    }

    // Check if the station ID already exists in the stationIdTable
    const idExists = stationIdTable.some((item) => item.key === stationIdValue);

    if (!idExists) {
      // If the ID does not exist, add the new value to the stationIdTable
      setStationIdTable((prevTable) => [
        ...prevTable,
        { key: stationIdValue, value: stationIdValue }, // Assuming key and value are the same here
      ]);
    }

    // Clear the station ID input and error message after adding
    setStationIdValue("");
    setMessageError("");
  };

  // const handleOnOkButtonClick = async () => {
  //     let stationID = formatTable(stationIdTable);
  //     let userNameAndLogin = formatTable(userNameAndLoginTable);
  //     let group = formatTable(groupTable);
  //     try {
  //         if (!isAllUsers) {
  //             if (!message && !stationID && !userNameAndLogin && !group) {
  //                 setMessageError("Please must supply value for message");
  //             } else if (message) {
  //                 if (stationIdTable.length === 0 && userNameAndLoginTable.length === 0 && groupTable.length === 0) {
  //                     setMessageError("You must select at least one destination");
  //                 } else {
  //                     setMessageError("");
  //                     const data = {
  //                         allUsers: isAllUsers,
  //                         stationIDs: stationID,
  //                         loginIDs: userNameAndLogin,
  //                         groupsIDs: group,
  //                         message: message,
  //                     };
  //                     console.log("data", data)
  //                 }
  //             }
  //         } else if (isAllUsers) {
  //             if (!message) {
  //                 setMessageError("Please must supply value for message");
  //             } else {
  //                 setMessageError("");
  //                 const data = {
  //                     allUsers: isAllUsers,
  //                     stationIDs: stationID,
  //                     loginIDs: userNameAndLogin,
  //                     groupsIDs: group,
  //                     message: message,
  //                 };
  //                 console.log("data", data)
  //             }
  //         }
  //     } catch (error) {
  //         TPLog.Log(`Error ${componentFileName} handleOnOkButtonClick`, TPLogType.ERROR, error);
  //     } finally {
  //     }
  // };

  //called after close modal success
  const callBackModalSuccess = () => {
    //hide modal and reset state
    //preserve callback function, title,and button level
    let newModalSuccessState: TPModalSuccessStateType = {
      ...modalSuccessState,
    };
    newModalSuccessState.successMessage = "";
    newModalSuccessState.isShown = false;
    setModalSuccessState(newModalSuccessState);
  };

  const handleOnOkButtonClick = async () => {
    const stationID = formatTable(stationIdTable);
    const userNameAndLogin = formatTable(userNameAndLoginTable);
    const group = formatTable(groupTable);

    try {
      // Validate input based on `isAllUsers`
      if (!isAllUsers) {
        if (!message && !stationID && !userNameAndLogin && !group) {
          setMessageError(await ERROR_MESSAGES.messageRequired());
          return; // Exit early if there is an error
        }
        if (message) {
          if (
            stationIdTable.length === 0 &&
            userNameAndLoginTable.length === 0 &&
            groupTable.length === 0
          ) {
            setMessageError(await ERROR_MESSAGES.atLeastOneDestination());
            return; // Exit early if there is an error
          }
          // Proceed with data preparation if there are no errors
          clearErrorAndLogData(stationID, userNameAndLogin, group);
        }
      } else if (isAllUsers) {
        if (!message) {
          setMessageError(await ERROR_MESSAGES.messageRequired());
          return; // Exit early if there is an error
        }
        // Proceed with data preparation if there are no errors
        clearErrorAndLogData(stationID, userNameAndLogin, group);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleOnOkButtonClick`,
        TPLogType.ERROR,
        error
      );
    }
  };

  // Function to clear error message and log the data
  async function clearErrorAndLogData(
    stationID: string,
    userNameAndLogin: string,
    group: string
  ) {
    let successMessage: string = "Message has been sent successfully!";
    let newModalSuccessState: TPModalSuccessStateType;

    // Construct the inputDTO object
    const inputDTO: ToastMessagesDTO = {
      AllUsers: isAllUsers, // Ensure isAllUsers is defined elsewhere
      StationIDs: stationID,
      LoginIDs: userNameAndLogin,
      GroupsIDs: group,
      Message: message, // Ensure message is defined elsewhere
      SenderGUIDUser: TPGlobal.currentUserGuid, // If this is optional, you might want to handle it differently
    };

    // Log the inputDTO for debugging
    // console.log("inputDTO", inputDTO);

    // Create a service client instance
    let serviceClient = new UserService();

    // Expected response codes
    let expectedCodes: Array<number> = [200];

    try {
      // Set loading screen state
      setIsLoadingScreen(true);

      // Send the toast message
      let responseRequest = await serviceClient.sendToastMessage(
        inputDTO,
        false, // showPositiveMessage
        true, // showNegativeMessage
        expectedCodes
      );

      // Process the response
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        // Clear any previous error messages
        setMessageError("");
        // Optional: Handle success, e.g., notify user, update state
        successMessage = `${successMessage}`;
        newModalSuccessState = {
          callBackFunction: callBackModalSuccess,
          title: await TPI18N.GetText(
            TPGlobal.globalResourceSet,
            "ModalSuccessTitle"
          ),
          yesLabel: await TPI18N.GetText(
            TPGlobal.globalResourceSet,
            "ModalAcceptLabel"
          ),
          successMessage: successMessage,
          isShown: true,
        };
        setModalSuccessState(newModalSuccessState);
      } else {
        // Handle the case where responseResult is false
        setMessageError(await ERROR_MESSAGES.failedToSendMessage());
      }
    } catch (error) {
      // Log the error
      TPLog.Log(
        `Error ${componentFileName} clearErrorAndLogData ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} clearErrorAndLogData ex`,
        error
      );

      // Update UI to reflect loading has stopped
      setIsLoadingScreen(false);

      // Optionally, set an error message for the user
      setMessageError("An unexpected error occurred.");
    }
  }

  return (
    <ContentVerticalNoTabsStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="container-fluid">
          <TPPageTitle>{pageTitleLabel}</TPPageTitle>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <DescriptionMessage>
                <MdInfo size={16} /> Only received by those with role Receive
                IM.
              </DescriptionMessage>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <TPCheckBox
                labelText={allUsersLabel}
                checked={isAllUsers}
                onChange={(e: any) => handleAllUsers()}
              />
            </div>
          </div>
          {!isAllUsers && (
            <>
              {/* <div className="row">
                <div className="col-md-6">
                  <FlexContainer>
                    <TextInputWrapper>
                      <TPTextBox
                        id="StationIdTextBox"
                        labelText={stationIdLabel}
                        value={stationIdValue}
                        onChange={handleStationIdChange}
                        maxLength={200}
                        errorMessage={stationIdErrorMessage}
                        textStyle={{
                          flex: 1,
                          borderTopRightRadius: "0",
                          borderBottomRightRadius: "0",
                        }}
                      />
                    </TextInputWrapper>
                    <AddButton onClick={() => handleClickStationId()}>
                      <MdAdd size={20} />
                    </AddButton>
                  </FlexContainer>
                </div>
              </div> */}

              <div className="row">
                <div className="col-md-6">
                  <FlexContainer>
                    <TextInputWrapper>
                      <TPTextBox
                        id="StationIdTextBox"
                        labelText={stationIdLabel}
                        value={stationIdValue}
                        onChange={handleStationIdChange}
                        maxLength={200}
                        errorMessage={stationIdErrorMessage}
                        textStyle={{
                          flex: 1,
                          borderTopRightRadius: "0",
                          borderBottomRightRadius: "0",
                        }}
                      />
                    </TextInputWrapper>
                    <AddButton onClick={() => handleClickStationId()}>
                      <MdAdd size={20} />
                    </AddButton>
                  </FlexContainer>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TPStyledTable>
                    <TPStyledBody>
                      {stationIdTable.map((item) => (
                        <TPStyledBodyRow key={item.key}>
                          <TPStyledBodyCell
                            onClick={() => handleDelete(item.key, "stationId")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#EB0027"
                              className="bi bi-trash3-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                            </svg>
                          </TPStyledBodyCell>
                          <TPStyledBodyCell width={"100%"}>
                            {item.value}
                          </TPStyledBodyCell>
                        </TPStyledBodyRow>
                      ))}
                    </TPStyledBody>
                  </TPStyledTable>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <FlexContainer>
                    <TextInputWrapper>
                      <TPAutoComplete
                        labelText={userNameAndLoginLabel}
                        onValueChange={handleUserNameAndLoginChange}
                        onSearch={(query: string) => {
                          handleUserNameAndLoginOnAutocompleteQuery(query);
                        }}
                        isLoading={isLoading}
                        options={autocompleteUserNameAndLoginOptions}
                        withIcon={true}
                        emptyLabel={userNameAndLoginEmptyLabel}
                        onKeyDown={handleUserNameAndLoginOnAutocompleteKeyDown}
                        selected={userNameAndLoginState.newUserNameAndLogin}
                        errorMessage={
                          userNameAndLoginState.newUserNameAndLoginErrorMessage
                        }
                        downArrowClick={
                          handleAutoCompleteUserNameAndLoginNClick
                        }
                        containerStyle={{
                          borderTopRightRadius: "0",
                          borderBottomRightRadius: "0",
                        }}
                      />
                    </TextInputWrapper>
                    <AddButtonSmall
                      onClick={() => handleClickUserNameAndLogin()}
                    >
                      <MdAdd size={20} />
                    </AddButtonSmall>
                  </FlexContainer>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TPStyledTable>
                    <TPStyledBody>
                      {userNameAndLoginTable.map((item) => (
                        <TPStyledBodyRow key={item.key}>
                          <TPStyledBodyCell
                            onClick={() => handleDelete(item.key, "userLogin")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#EB0027"
                              className="bi bi-trash3-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                            </svg>
                          </TPStyledBodyCell>
                          <TPStyledBodyCell width={"100%"}>
                            {item.value}
                          </TPStyledBodyCell>
                        </TPStyledBodyRow>
                      ))}
                    </TPStyledBody>
                  </TPStyledTable>
                </div>
              </div>
              
              <div className="row">
                <div className="col-md-6">
                  <FlexContainer>
                    <TextInputWrapper>
                      <TPAutoComplete
                        labelText={groupLabel}
                        onValueChange={handleGroupChange}
                        onSearch={(query: string) => {
                          handleGroupOnAutocompleteQuery(query);
                        }}
                        isLoading={isLoading}
                        options={autocompleteGroupOptions}
                        withIcon={true}
                        emptyLabel={groupEmptyLabel}
                        onKeyDown={handleGroupOnAutocompleteKeyDown}
                        selected={groupState.newGroup}
                        errorMessage={groupState.newGroupErrorMessage}
                        downArrowClick={handleAutoCompleteGroupNClick}
                        containerStyle={{
                          borderTopRightRadius: "0",
                          borderBottomRightRadius: "0",
                        }}
                      />
                    </TextInputWrapper>
                    <AddButtonSmaller onClick={() => handleClickGroup()}>
                      <MdAdd size={20} />
                    </AddButtonSmaller>
                  </FlexContainer>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TPStyledTable>
                    <TPStyledBody>
                      {groupTable.map((item) => (
                        <TPStyledBodyRow key={item.key}>
                          <TPStyledBodyCell
                            onClick={() => handleDelete(item.key, "group")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#EB0027"
                              className="bi bi-trash3-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                            </svg>
                          </TPStyledBodyCell>
                          <TPStyledBodyCell width={"100%"}>
                            {item.value}
                          </TPStyledBodyCell>
                        </TPStyledBodyRow>
                      ))}
                    </TPStyledBody>
                  </TPStyledTable>
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col-md-6">
              <TPTextArea
                id="MessageTextArea"
                onChange={handleMessageChange}
                value={message}
                isMandatory={true}
                labelText={messageLabel}
                errorMessage={messageError}
                rows={4}
              />
            </div>
          </div>
          <div className="row mt-4 mb-4">
            <div className="col-md-6">
              <TPPageAcceptCancelButtonsContainer>
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.primary}
                  onClick={handleOnOkButtonClick}
                >
                  {saveButtonLabel}
                </TPButton>
              </TPPageAcceptCancelButtonsContainer>
            </div>
          </div>
        </div>
        {modalSuccessState.isShown && (
          <TPModalSuccess modalState={modalSuccessState} />
        )}
      </TPLoadingOverlay>
    </ContentVerticalNoTabsStyled>
  );
};

export default TPToastMessages;
