import { forwardRef, ReactNode } from "react";
import { TPIconTypes } from "../../models/Global/TPGlobalEnums";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import allThemes from "@/assets/styles/theme";
import { useTPTagSelectorLabels } from "../TPTagSelector/TPTagSelectorLabels";

interface Props {
  id: string;
  children?: ReactNode;
  isPopUp?: any;
  isVisible?: boolean;
  icons: Array<TPIconTypes>;
  openImageModal?: () => void;
  onClickCustomIcon?: (nameIco: string) => void;
  onTagClick: React.MouseEventHandler<HTMLButtonElement>;
  showFrequentAnswer?: boolean;
  frequentAnswerText?: string;
  onClickFrequentAnswer?: () => void; 
}
export type Ref = HTMLDivElement;

export const CustomToolbar = forwardRef<Ref, Props>((props, ref) => {

  const { labels: tagLabels } = useTPTagSelectorLabels();

  return (
    <div
      ref={ref}
      className=""
      id={props.id}
      style={{
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        borderBottom: "none"
      }}
    >
      <select className="ql-header ql-picker" />
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <select className="ql-align" />
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
      <select className="ql-color" />
      <select className="ql-background" />
      <button className="ql-clean" />
      {props.icons != undefined &&
        props.icons.map((icon, index) => (
          <button
            key={index}
            className="ql-handleImageClick p-0"
            style={{ justifyItems: "center" }}
          >
            <TPIcon iconType={icon} style={{ with: "20px" }} onClick={() => { if (props?.onClickCustomIcon) props?.onClickCustomIcon(icon.toString()) }} />
          </button>

        ))
      }

      {props?.isVisible && (
        <>
          <button
            className="ql-handleImageClick p-0"
            onClick={props?.openImageModal}
          >
            <div>📷</div>
          </button>
          <button
            style={{color: allThemes.base.purplePrimary, marginLeft: "8px"}}
            className="ql-handlePopupClick d-flex flex-row gap-1 align-items-center"
            onClick={props?.onTagClick}
          >
            <TPIcon iconType={TPIconTypes.tag} />
            <b><u>{tagLabels.Tags}</u></b>
          </button>
        </>
      )}
      {props?.showFrequentAnswer && (
      <a
        href="#"
        style={{
          marginLeft: "8px",
          textDecoration: "underline",
          color: "purple",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.preventDefault();
          if (props?.onClickFrequentAnswer) {
            props?.onClickFrequentAnswer();
          }
        }}
      >
        {props?.frequentAnswerText}
      </a>
    )}
  </div>
  )
});
