import { ReactElement } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import TPLoadingSpinner from "./TPLoadingSpinner";

import styled, { CSSProperties } from "styled-components";

const LoadingOverlayContainer = styled(LoadingOverlay)(
    ({ theme }) => `
  .tp-loading-overlay-loader-overlay {
    background-color: ${theme.colors.loading.loadingOverlay.backgroundColor};
  }
`
);

interface TPLoadingOverlayProperties {
    children?: any;
    active?: boolean;
    top?: string;
    styles?: CSSProperties;
  wrapperStyle?: CSSProperties;
    overflow?: string;
    isModal?: boolean;
}

const TPLoadingOverlay = ({
    children,
    active = false,
    top,
    styles,
  wrapperStyle,
    isModal,
    overflow
}: TPLoadingOverlayProperties): ReactElement => {
    return (
        <LoadingOverlayContainer
            classNamePrefix="tp-loading-overlay-loader-"
            className="container-fluid"
            styles={{
                overlay: {
                    position: "absolute",
                    width: `${isModal ? "100%" : "calc(100% - 23px)"}`,
                    height: "100%",
                    top: "0px",
                    left: "0px",
                    display: "flex",
                    textAlign: "center",
                    fontSize: "1.2em",
                    color: "#FFF",
                    zIndex: "800",
                    transition: "opacity 500ms ease-in",
                    opacity: "1",
                    img: {
                        height: "50px",
                    },
                },
                wrapper: {
                    overflow: overflow ? overflow:"auto",
                    height: "100%",
          ...wrapperStyle,
                },
            }}
            active={active}
            spinner={<TPLoadingSpinner top={top} />}
        >
            {children}
        </LoadingOverlayContainer>
    );
};

export default TPLoadingOverlay;
