import PlusIcon from "@/assets/images/TPIcons/PlusIcon";
import { TPChip } from "@/components/TPChip/TPChip";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, { TPModalQuestionState } from "@/layouts/ModalQuestion/TPModalQuestion";
import { TPActiveOptions, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { TPI18N } from "@/services/I18nService";
import { StructureService } from "@/services/StructureService";
import FileSaver from "file-saver";
import React, { useEffect, useImperativeHandle, useReducer, useState } from "react";
import * as XLSX from "xlsx";
import "./style.css";

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<any>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface StructureAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "change_filterByLanguage" = 2,
  "reload_grid" = 3,
  "change_search_pattern" = 4,
  "change_selectedFilter" = 5,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

enum whenCustomerExistsEnum {
  ignoreData = "IGNOREDATA",
  addNewData = "ADDNEWDATAANDUPDATEEXISTS",
  onlyaddNewData = "ONLYADDNEWDATA",
}

const StructureAdmin = React.forwardRef(
  ({ callBackCommands }: StructureAdminInterface, ref) => {
    const componentFileName: string = "StructureAdmin.tsx";

    const resourceSet = "StructureAdmin";

    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

    const [pageTitle, setPageTitle] = useState<string>("");
    const [newStructureLabel, setNewStructureLabel] = useState<string>("");

    const [exportLabel, setExportLabel] = useState<string>("");
    const [refreshLabel, setRefreshLabel] = useState<string>("");
    const [searchLabel, setSearchLabel] = useState<string>("");

    const [downloadTemplateLabel, setDownloadTemplateLabel] = useState("");
    const [updateLabel,setUpdateLabel] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [cloneLabel, setCloneLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");

    const [actionsLabel, setActionsLabel] = useState<string>("");
    const [idLabel, setIdLabel] = useState<string>("");
    const [nameLabel, setNameLabel] = useState<string>("");
    const [addDescriptionLabel, setAddDescriptionLabel] = useState<string>("");
    const [whenTheCustomerExistsLabel, setWhenTheCustomerExistsLabel] = useState<string>("");
    const [structureTypeLabel, setStructureTypeLabel] = useState<string>("");
    const [activeLabel, setActiveLabel] = useState<string>("");

    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    const [ignoreDataLabel, setIgnoreDataLabel] = useState("");
    const [addNewDataLabel, setAddNewDataLabel] = useState("");

    const [noStructuresLabel, setNoStructuresLabel] = useState("");

    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState<string>("");



    const loadResources = async () => {
      setDeleteTitle(await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"));
      setDeleteOkLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"));
      setDeleteCancelLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"));
      setSearchLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"));
      setExportLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"));
      setRefreshLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"));
      setCloneLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "CloneLabel"));
      setDeleteLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"));
      setUpdateLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"));

      setPageTitle(await TPI18N.GetText(resourceSet, "PageTitle"));
      setNewStructureLabel(await TPI18N.GetText(resourceSet, "NewStructure"));
      setDownloadTemplateLabel(await TPI18N.GetText(resourceSet, "DownloadTemplate"));
      setActionsLabel(await TPI18N.GetText(resourceSet, "ActionsLabel"));
      setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
      setNameLabel(await TPI18N.GetText(resourceSet, "NameLabel"));
      setAddDescriptionLabel(await TPI18N.GetText(resourceSet, "AddDescriptionLabel"));
      setWhenTheCustomerExistsLabel(await TPI18N.GetText(resourceSet, "WhenTheCustomerExistsLabel"));
      setStructureTypeLabel(await TPI18N.GetText(resourceSet, "StructureTypeLabel"));
      setActiveLabel(await TPI18N.GetText(resourceSet, "ActiveLabel"));
      setDeleteQuestion(await TPI18N.GetText(resourceSet, "DeleteQuestion"));
      setYesLabel(await TPI18N.GetText(resourceSet, "YesLabel"));
      setNoLabel(await TPI18N.GetText(resourceSet, "NoLabel"));
      setThereAreNoRecordsToShow(await TPI18N.GetText(resourceSet, "ThereAreNoRecordsToShowLabel"));

      setIgnoreDataLabel(await TPI18N.GetText("StructureAdminClone", "IgnoreDataLabel"));
      setAddNewDataLabel(await TPI18N.GetText("StructureAdminClone", "AddNewDataLabel"));

      setNoStructuresLabel(await TPI18N.GetText(resourceSet, "NoStructuresLabel"));

      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(resourceSet, "All"), //await TPI18N.GetText(resourceSet, "All")
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };

    const [isLoadingScreen, setIsLoadingScreen] = useState(false);

    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };

    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState
    );

    const setupGridColumns = (prevState: AdminStateType) => {
      try {
        let newState: AdminStateType;
        newState = { ...prevState };
        let newColumns: Array<any> = [];

        //function
        newColumns.push({
          name: actionsLabel,
          cell: (row: { [x: string]: any }) => {
            return (
              <div className="dropdown">
                <TPButton
                  id="IdButton"
                  dataBsToggle={true}
                  type={TPButtonTypes.empty}
                  onClick={() => {
                    TPGlobal.foo();
                  }}
                  className={"menu-button"}
                >
                  <TPIcon iconType={TPIconTypes.moreVert} />
                </TPButton>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      onClick={() => handleDownloadClick(row["id"])}
                      className="dropdown-item"
                      href="#"
                    >
                      {downloadTemplateLabel}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleCloneClick(row["id"], row["description"])}
                      className="dropdown-item"
                      href="#"
                    >
                      {cloneLabel}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleDeleteClick(row["id"])}
                      className="dropdown-item"
                      href="#"
                    >
                      {deleteLabel}
                    </a>
                  </li>
                </ul>
              </div>
            );
          }
        });

        //id
        newColumns.push({
          name: idLabel,
          cell: (row: { [x: string]: any }) => (
            <div
              onClick={() => handleUpdateClick(row["id"])}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {row["id"]}
            </div>
          ),
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });

        //name
        newColumns.push({
          name: nameLabel,
          selector: (row: { [x: string]: any }) => row["Name"],
          cell: (row: { [x: string]: any }) => row["localizedDescription"],
          sortable: true,
        });

        //structureType
        newColumns.push({
          name: structureTypeLabel,
          selector: (row: { [x: string]: any }) => row["structureType"],
          cell: (row: { [x: string]: any }) => row["descriptionStructureType"],
          sortable: true,
        });

        //whenTheCustomerExists
        newColumns.push({
          name: whenTheCustomerExistsLabel,
          selector: (row: { [x: string]: any }) => row["whenTheCustomerExists"],
          cell: (row: { [x: string]: any }) => {
            return (
              <>
                {row["actionWhenClientExists"] === whenCustomerExistsEnum.ignoreData && (
                  ignoreDataLabel
                )}
                {row["actionWhenClientExists"] === whenCustomerExistsEnum.addNewData && (
                  addNewDataLabel
                )}
              </>
            )
          },
          sortable: true,
        });

        //active
        newColumns.push({
          name: activeLabel,
          selector: (row: { [x: string]: any }) => row["active"],
          cell: (row: { [x: string]: any }) => {
            return (
              <TPChip label={row["isActive"] ? (yesLabel) : (noLabel)} backgroundColor={row["isActive"] ? "lightgreen" : "#ffd7dd"} />
            );
          },
          center: true,
          sortable: true,
        });

        newState.gridColumns = [...newColumns];
        return newState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
        return prevState;
      }
    };

    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    const getStructureDetails = async (id: string) => {
      let serviceClient = new StructureService();
      let expectedCodes = [200, 404];

      try {
        let result = await serviceClient.getStructureDetails(
          id,
          false,
          true,
          expectedCodes
        );

        return result;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getStructureDetails ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getStructureDetails ex`);
        setIsLoadingScreen(false);
        return [];
      }
    }

    const handleDownloadClick = async (id: string) => {
      let data = await getStructureDetails(id);
      let templateName: string;

      if (data) {
        templateName = `structure-template-${id}`;
        const columnNameObject = TPGlobal.createColumnNameObject(data);
        exportToCSV(columnNameObject, templateName)
      }
    }

    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    }

    const handleCloneClick = (id: string, description: string) => {
      let command: any = { command: "cloneEmail", recordId: id, recordDescription: description };
      callBackCommands(command);
    };

    const filteredData = () => {
      let searcheableColumns: Array<string> = [
        "id",
        "Name",
      ];
      let i: number;
      let search: string;
      search = adminState.searchPattern.trim();
      return adminState.gridData.filter(function (item, index) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany[searcheableColumns[i]]
              ? itemany[searcheableColumns[i]]
                .toLowerCase()
                .includes(search.toLowerCase())
              : false
          ) {
            return item;
          }
        }
      });
    };

    const reloadDataGrid = async (filter: string) => {
      let serviceClient = new StructureService();
      let expectedCodes = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getStructureList(
          filter,
          false,
          true,
          expectedCodes
        )
        setIsLoadingScreen(false);
        return responseRequest;

      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    }

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL
    );

    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
          break;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
          break;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
          break;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
          break;
        default:
          return prevState;
      }
    }

    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    const handleRowsPerPageChanged = (e: any) => {
      const recordSize = fixedHeaderScrollHeight / 10;
      const newRecordSize = recordSize * e;
      setFixedHeaderScrollHeight(newRecordSize);
    };

    useEffect(() => {
      loadResources()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndUsersFilter ex`,
            TPLogType.ERROR,
            error
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndUsersFilter ex`
          );
        });
    }, []);

    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new StructureService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest: any = await serviceClient.deleteStructure(
            callBackData.recordId,
            true,
            true,
            expectedCodes
          );
          setIsLoadingScreen(false);
          reloadGridCommand();
          if (responseRequest.responseData.responseCode !== 500) {
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    const handleRefreshClick = () => {
      reloadGridCommand();
    }

    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    const exportToCSV = (apiData = filteredData(), fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8") => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      /* custom headers */
      XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace("{recordId}", modalQuestionState.callBackData.recordId)}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        />
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <CIMTitleSection>
                <TPPageTitle style={{ margin: "0" }}>{pageTitle}</TPPageTitle>
                <TPButton
                  id="new-structure"
                  isDesignSystem={true}
                  onClick={handleNewClick}
                  withIcon={TPIconTypes.add}
                  orientationIcon="left"
                  style={{ padding: "1px 18px" }}
                >
                  {newStructureLabel}
                </TPButton>
              </CIMTitleSection>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <DynamicTable
                data={filteredData().map(item => {
                  return {
                    id: item.id,
                    description: item.description,
                    localizedDescription: item.localizedDescription,
                    descriptionStructureType: item.descriptionStructureType,
                    actionWhenClientExists: item.actionWhenClientExists,
                    isActive: item.isActive
                  }
                })}
                columnNames={{
                  actionWhenClientExists: whenTheCustomerExistsLabel,
                  description: nameLabel,
                  descriptionStructureType: structureTypeLabel,
                  id: idLabel,
                  isActive: activeLabel
                }}
                hiddenColumns={TPGlobal.TPClientDefaultLanguage === TPGlobal.language ? ["localizedDescription"] : []}
                withPreferences
                noDataMessage={noStructuresLabel}
                hideAllTableOnNoData
                columnStyles={{
                  id: ({item, value}) => (
                    <button style={{border:'none', background:'none'}} onClick={() => handleUpdateClick(item.id)}>
                      <b><u style={{color:'#780096'}}>{item.id}</u></b>
                    </button>
                  ),
                  actionWhenClientExists: ({item, value}) => {
                    switch (item.actionWhenClientExists) {
                      case whenCustomerExistsEnum.addNewData:
                        return <label>{addNewDataLabel}</label>;
                      case whenCustomerExistsEnum.ignoreData:
                        return <label>{ignoreDataLabel}</label>;
                      default:
                        return <label>???</label>
                    }
                  },
                  isActive: ({item, value}) => (
                    <TPChip
                      backgroundColor={item.isActive ? '#B1F2D7' : '#FFD7DD'}
                      label={item.isActive ? yesLabel : noLabel}
                    />
                  )
                }}
                minorOptions={[
                  {
                    key: downloadTemplateLabel,
                    onOptionChange: (item) => handleDownloadClick(item.id),
                    type: 'download',
                    icon: TPIconTypes.downloadTemplate
                  },
                  {
                    key: cloneLabel,
                    onOptionChange: (item) => handleCloneClick(item.id, item.description),
                    type: 'clone',
                    icon: TPIconTypes.clone
                  },
                  {
                    key: updateLabel,
                    type: "edit",
                    icon: TPIconTypes.edit,
                    onOptionChange: (item) => handleUpdateClick(item.id),
                  },
                  {
                    key: deleteLabel,
                    onOptionChange: (item) => handleDeleteClick(item.id),
                    type: 'delete',
                    icon: TPIconTypes.delete
                  },
                ]}
                onIconClicked={icon => {
                  if (icon === TPIconTypes.loop || icon === TPIconTypes.refresh) handleRefreshClick();
                }}
              />
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  }
)

export default StructureAdmin;