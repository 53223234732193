import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";
import { useEffect, useState } from "react";

const labelsResourceSet = "QueueAdmin";

interface QueueAdminLabelsModel {
  QueueAdmin: string;
  AddQueue: string;
  Search: string;
  ExportToCSV: string;
  Refresh: string;
  Preferences: string;
  NoResults: string;
  NewQueue: string;
  UpdateQueue: string;
  GeneralInfo: string;
  QueueAdministration: string;
  AssignUsers: string;
  ID: string;
  Name: string;
  Description: string;
  Parameters: string;
  preferredCustomers: string;
  relatedCases: string;
  recentAgents: string;
  Update: string;
  Delete: string;
  Workmode: string;
  ClassificationOptions: string;
  AdditionalData: string;
  Active: string;
  People: string;
  Schedule: string;
  TimeZone: string;
  Calendar: string;
  Superior: string;
  DefaultLanguage: string;
  GroupType: string;
  GroupBehavior: string;
  PreferredCustomer: string;
  RelatedCase: string;
  RecentAgent: string;
  ShowAsActive: string;
  Cancel: string;
  Save: string;
  UsersAssigned: string;
  SearchNewUsers: string;
  Profile: string;
  Teams: string;
  Functions: string;
  SelectAll: string;
  InteractionOutcomes: string;
  CustomFields: string;
  ActivateWarningAfter: string;
  Min: string;
  Sec: string;
  RecordType: string;
  FrontOffice: string;
  BackOffice: string;
  EventTakeNext: string;
  Yes: string;
  No: string;
  DeleteRecordConfirm: string;
  DeleteModalTitle: string;
  Apply: string;
  NothingToSeeYet: string;
  SearchToGetResults: string;
  NoDataFound: string;
  SystemAlert: string;
  CancelConfirm: string;
  DeselectConfirm: string;
  NextPage: string;
  InsertSuccess: string;
  UpdateSuccess: string;
  QueueDeleted: string;
  QueueNotDeleted: string;
  Preview: string;
  Close: string;
  Order: string;
  Mandatory: string;
  CreateAdditionalData: string;
  SelectaForm: string;
  AddUsers: string;
  Remember: string;
  SureRemove: string;
  PeopleList: string;
  ExitPreview: string;
}

const useQueueAdminLabels = function () {
  const [labels, setLabels] = useState<QueueAdminLabelsModel>(
    {} as QueueAdminLabelsModel,
  );
  const [labelsLoaded, setLabelsLoaded] = useState<boolean>(false);

  const loadLabels = async function () {
    setLabels({
      QueueAdmin: await TPI18N.GetText(labelsResourceSet, "QueueAdmin"),
      AddQueue: await TPI18N.GetText(labelsResourceSet, "AddQueue"),
      Search: await TPI18N.GetText(labelsResourceSet, "Search"),
      ExportToCSV: await TPI18N.GetText(labelsResourceSet, "ExportToCSV"),
      Refresh: await TPI18N.GetText(labelsResourceSet, "Refresh"),
      Preferences: await TPI18N.GetText(labelsResourceSet, "Preferences"),
      NoResults: await TPI18N.GetText(labelsResourceSet, "NoResults"),
      NewQueue: await TPI18N.GetText(labelsResourceSet, "NewQueue"),
      UpdateQueue: await TPI18N.GetText(labelsResourceSet, "UpdateQueue"),
      GeneralInfo: await TPI18N.GetText(labelsResourceSet, "GeneralInfo"),
      QueueAdministration: await TPI18N.GetText(
        labelsResourceSet,
        "QueueAdministration",
      ),
      AssignUsers: await TPI18N.GetText(labelsResourceSet, "AssignUsers"),
      ID: await TPI18N.GetText(labelsResourceSet, "ID"),
      Name: await TPI18N.GetText(labelsResourceSet, "Name"),
      Description: await TPI18N.GetText(labelsResourceSet, "Description"),
      Parameters: await TPI18N.GetText(labelsResourceSet, "Parameters"),
      preferredCustomers: await TPI18N.GetText(
        labelsResourceSet,
        "preferredCustomers",
      ),
      relatedCases: await TPI18N.GetText(labelsResourceSet, "relatedCases"),
      recentAgents: await TPI18N.GetText(labelsResourceSet, "recentAgents"),
      Update: await TPI18N.GetText(labelsResourceSet, "Update"),
      Delete: await TPI18N.GetText(labelsResourceSet, "Delete"),
      Workmode: await TPI18N.GetText(labelsResourceSet, "Workmode"),
      ClassificationOptions: await TPI18N.GetText(
        labelsResourceSet,
        "ClassificationOptions",
      ),
      AdditionalData: await TPI18N.GetText(labelsResourceSet, "AdditionalData"),
      Active: await TPI18N.GetText(labelsResourceSet, "Active"),
      People: await TPI18N.GetText(labelsResourceSet, "People"),
      Schedule: await TPI18N.GetText(labelsResourceSet, "Schedule"),
      TimeZone: await TPI18N.GetText(labelsResourceSet, "TimeZone"),
      Calendar: await TPI18N.GetText(labelsResourceSet, "Calendar"),
      Superior: await TPI18N.GetText(labelsResourceSet, "Superior"),
      DefaultLanguage: await TPI18N.GetText(
        labelsResourceSet,
        "DefaultLanguage",
      ),
      GroupType: await TPI18N.GetText(labelsResourceSet, "GroupType"),
      GroupBehavior: await TPI18N.GetText(labelsResourceSet, "GroupBehavior"),
      PreferredCustomer: await TPI18N.GetText(
        labelsResourceSet,
        "PreferredCustomer",
      ),
      RelatedCase: await TPI18N.GetText(labelsResourceSet, "RelatedCase"),
      RecentAgent: await TPI18N.GetText(labelsResourceSet, "RecentAgent"),
      ShowAsActive: await TPI18N.GetText(labelsResourceSet, "ShowAsActive"),
      Cancel: await TPI18N.GetText(labelsResourceSet, "Cancel"),
      Save: await TPI18N.GetText(labelsResourceSet, "Save"),
      UsersAssigned: await TPI18N.GetText(labelsResourceSet, "UsersAssigned"),
      SearchNewUsers: await TPI18N.GetText(labelsResourceSet, "SearchNewUsers"),
      Profile: await TPI18N.GetText(labelsResourceSet, "Profile"),
      Teams: await TPI18N.GetText(labelsResourceSet, "Teams"),
      Functions: await TPI18N.GetText(labelsResourceSet, "Functions"),
      SelectAll: await TPI18N.GetText(labelsResourceSet, "SelectAll"),
      InteractionOutcomes: await TPI18N.GetText(
        labelsResourceSet,
        "InteractionOutcomes",
      ),
      CustomFields: await TPI18N.GetText(labelsResourceSet, "CustomFields"),
      ActivateWarningAfter: await TPI18N.GetText(
        labelsResourceSet,
        "ActivateWarningAfter",
      ),
      Min: await TPI18N.GetText(labelsResourceSet, "Min"),
      Sec: await TPI18N.GetText(labelsResourceSet, "Sec"),
      RecordType: await TPI18N.GetText(labelsResourceSet, "RecordType"),
      FrontOffice: await TPI18N.GetText(labelsResourceSet, "FrontOffice"),
      BackOffice: await TPI18N.GetText(labelsResourceSet, "BackOffice"),
      EventTakeNext: await TPI18N.GetText(labelsResourceSet, "EventTakeNext"),
      Yes: await TPI18N.GetText(labelsResourceSet, "Yes"),
      No: await TPI18N.GetText(labelsResourceSet, "No"),
      DeleteRecordConfirm: await TPI18N.GetText(
        "GroupsAdminComponent",
        "RecordDeleteConfirm",
      ),
      DeleteModalTitle: await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ConfirmTitle",
      ),
      Apply: await TPI18N.GetText(labelsResourceSet, "Apply"),
      NothingToSeeYet: await TPI18N.GetText(
        labelsResourceSet,
        "NothingToSeeYet",
      ),
      SearchToGetResults: await TPI18N.GetText(
        labelsResourceSet,
        "SearchToGetResults",
      ),
      NoDataFound: await TPI18N.GetText(labelsResourceSet, "NoDataFound"),
      SystemAlert: await TPI18N.GetText(labelsResourceSet, "SystemAlert"),
      CancelConfirm: await TPI18N.GetText(labelsResourceSet, "CancelConfirm"),
      DeselectConfirm: await TPI18N.GetText(
        labelsResourceSet,
        "DeselectConfirm",
      ),
      NextPage: await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DataTableNextPageCounter",
      ),
      InsertSuccess: await TPI18N.GetText(labelsResourceSet, "InsertSuccess"),
      UpdateSuccess: await TPI18N.GetText(labelsResourceSet, "UpdateSuccess"),
      QueueDeleted: await TPI18N.GetText(labelsResourceSet, "QueueDeleted"),
      QueueNotDeleted: await TPI18N.GetText(
        labelsResourceSet,
        "QueueNotDeleted",
      ),
      Preview: await TPI18N.GetText(labelsResourceSet, "Preview"),
      Close: await TPI18N.GetText(labelsResourceSet, "Close"),
      Order: await TPI18N.GetText(labelsResourceSet, "Order"),
      Mandatory: await TPI18N.GetText(labelsResourceSet, "Mandatory"),
      CreateAdditionalData: await TPI18N.GetText(
        labelsResourceSet,
        "CreateAdditionalData",
      ),
      SelectaForm: await TPI18N.GetText(labelsResourceSet, "SelectaForm"),
      AddUsers: await TPI18N.GetText(labelsResourceSet, "AddUsers"),
      Remember: await TPI18N.GetText(labelsResourceSet, "Remember"),
      SureRemove: await TPI18N.GetText(labelsResourceSet, "SureRemove"),
      PeopleList: await TPI18N.GetText(labelsResourceSet, "PeopleList"),
      ExitPreview: await TPI18N.GetText(labelsResourceSet, "ExitPreview"),
    });
    setLabelsLoaded(true);
  };
  useEffect(() => {
    if (!labelsLoaded) loadLabels();
  });
  return { labels, labelsLoaded };
};

export default useQueueAdminLabels;
