
import React, {
    useEffect,
    useImperativeHandle,
    useState
} from "react";
import DynamicTable from "../../../../modules/core/components/dynamic-table/DynamicTable";
import { DistributionListAdminService } from "../../../../services/DistributionListAdminService";
import { EmailAddressSelectModel, TableDistributionModel, TypeActionDistributionSelector } from "../../../../models/DistributionListAdmin/DistributionListAdmin";
import TPSelect from "../../../../components/bootstrap/forms/select/TPSelect";
import { TPKeyValue } from "../../../../helpers/TPKeyValue";
import TPButton from "../../../../components/bootstrap/components/buttons/TPButton";
import TPModal from "../../../../layouts/TPModal/TPModal";
import { TPEmthyModal } from "../../../../components/TPModalAlert/TPEmthyModal/TPEmthyModal";
import { TPI18N } from "../../../../services/I18nService";
import { TPAvatar } from "../../../../components/TPAvatar/TPAvatar";
import { ColumnStyles } from "../../../Projects/ProjectDetail/ProjectDetailDynamicTable";
import TPLoadingOverlay from "../../../../components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPGlobal from "../../../../helpers/TPGlobal";
import { TypeNameButton } from "../../../../models/EmthyModal/EmthyModalModel";


interface AdminInterface {
    dataSource?: string;
    onChange?: ({ action, value }: { action: TypeActionDistributionSelector, value: any }) => void;
}

const DistributedListSelector = React.forwardRef(
    ({ dataSource, onChange }: AdminInterface, ref) => {
        const componentFileName: string = "DistributedListSelector.tsx";
        const ResourceSet: string = "DistributionListAdmin";

        const [distributionList, setDistributionList] = useState(new Array<TableDistributionModel>);
        const [emailList, setEmailList] = useState(new Array<EmailAddressSelectModel>);
        const [emailListSelect, setEmailListSelect] = useState(new Array<EmailAddressSelectModel>);
        const [emailListRemoveSelect, setEmailListRmoveSelect] = useState(new Array<string>);
        const [distributionListActive, setDistributionListActive] = useState("");

        const [distributionListLabel, setDistributionListLabel] = useState("");
        const [applyLabel, setApplyLabel] = useState("");
        const [separator, setSeparator] = useState(";");

        const [style, setStyle] = useState<React.CSSProperties>(
            {
                width: "100%",
                height: "50px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px"
            });


        useImperativeHandle(ref, () => ({
            Load() {
            },
        }));

        const [isLoadingScreen, setIsLoadingScreen] = useState(true);

        const loadResourcesAndTreeFilter = async () => {
            setDistributionListLabel(
                await TPI18N.GetText(ResourceSet, "DistributionListLabel")
            );
            setApplyLabel(
                await TPI18N.GetText(TPGlobal.globalResourceSet, "ApplyFilterText")
            );
        };

        function loadData(status: string) {
            setIsLoadingScreen(true);
            const { getByFiltersIsActive } = new DistributionListAdminService();
            let expectedCodes: Array<number> = [200, 404];
            getByFiltersIsActive(status, false, true, expectedCodes)
                .then((data: any) => {
                    setDistributionList(data);
                    setIsLoadingScreen(false);
                })
        }

        function getDistributionListAddressByDistributionId(status: string) {
            setIsLoadingScreen(true);
            const { getDistributionListAddressByDistributionId } = new DistributionListAdminService();
            let expectedCodes: Array<number> = [200, 404];
            getDistributionListAddressByDistributionId(status, false, true, expectedCodes)
                .then((data: any) => {
                    setEmailList([]);
                    if (data) {
                        let temp = data.map((e: any) => {
                            return {
                                ico: "",
                                email: e.email,
                                name: e.name
                            } as EmailAddressSelectModel
                        });
                        setEmailList(temp);
                        handleChangedSelect(temp);
                        setIsLoadingScreen(false);
                    }
                })
        }

        const customColumns: ColumnStyles<EmailAddressSelectModel> = {
            ico: ({ value, item }) => (
                <TPAvatar
                    src={item.ico}
                    size={30}
                    circular={true}
                    enableFullscreen={true}
                />
            )
        };

        const handleEmailString = () => {
            return emailListSelect
                .map(e => e.email)
                .filter((email, index, self) => self.indexOf(email) === index)
                .join(separator);
        };

        const handleGetDistributionList = () => {
            if (distributionList) {
                return distributionList.map((e: TableDistributionModel) => {
                    return {
                        key: e.id,
                        value: e.description
                    } as TPKeyValue
                })
            }
            else {
                return new Array<TPKeyValue>();
            }
        }

        const handleChangedSelect = (temp?: Array<EmailAddressSelectModel>) => {
            if (dataSource) {
                let list = [...emailListSelect];
                dataSource.split(separator).forEach((i: string) => {
                    if (temp) {

                        let exists = temp.find(s => s.email.trim() == i + "".trim());
                        let exists2 = list.find(s => s.email == i);
                        if (exists) {
                            if (!exists2)
                                list.push(exists);
                            else {
                                exists2.email = exists.email;
                                exists2.name = exists.name;
                                exists2.ico = exists.ico;
                                list.push(exists2);
                            }

                        }
                        else if (!exists2) {
                            list.push({
                                ico: "",
                                email: i,
                                name: i
                            });
                        }

                    }
                    else {

                        let exists2 = list.find(s => s.email == i);
                        if (!exists2)
                        list.push({
                            ico: "",
                            email: i,
                            name: i
                        });
                    }
                });                     
                setEmailListSelect(list);
            }
        }


        useEffect(() => {
            handleChangedSelect();
            loadResourcesAndTreeFilter();
            loadData("2");
        }, []);

       

        return (
            <>
                <TPEmthyModal
                    disabled={false}
                    title={distributionListLabel}
                    buttonNameActive={TypeNameButton.applyAnCancel}
                    visible={true}
                    width={"900px"}
                    onAccept={() => { if (onChange) onChange({ action: TypeActionDistributionSelector.ok, value: handleEmailString() }); }}
                    onCancel={() => { if (onChange) onChange({ action: TypeActionDistributionSelector.cancel, value: "" }); }}
                >
                    <div style={style}>
                        <TPSelect
                            id="email-templates-account-input"
                            isMandatory={true}
                            labelText={"Distribution List"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.value != "") {
                                    setDistributionListActive(e.target.value);
                                }
                            }
                            }
                            dataSource={handleGetDistributionList()}
                            value={distributionListActive}
                            errorMessage={""}
                        />

                        <TPButton
                            onClick={() => {
                                getDistributionListAddressByDistributionId(distributionListActive);
                            }}
                            isDesignSystem
                            style={{
                                paddingLeft: "16px",
                                paddingRight: "16px",
                                marginTop: "25px"
                            }}
                            disabled={distributionListActive == ""}
                        >
                            {applyLabel}
                        </TPButton>
                    </div>

                    <div style={{ minWidth: "700px" }}  >
                        <DynamicTable
                            data={emailList}
                            columnStyles={customColumns}
                            hiddenSearch={true}
                            hideExport={true}
                            hideRefresh={true}
                            customHeight={"200px"}
                            selectable
                            selectedValues={emailListSelect}        
                            onDeselectedChangeItem={
                                (e, status) => {
                                    if (status==false) {
                                        let list = [...emailListSelect];
                                        list = list.filter(s => s.email != e.email);
                                        setEmailListSelect(list);                                      
                                    }
                                }
                            }
                            onSelectionChange={(e, status) => {
                                let list = [...emailListSelect];
                                if (status== false) {
                                    e.forEach((i) => {
                                        let exists = list.find(s => s.email == i.email);
                                        if (!exists)
                                            list.push(i);
                                    });
                                    setEmailListSelect(list);                                 
                                } else if (status) {
                                    const list = emailListSelect.filter(item => !emailList.includes(item));                                   
                                }
                              
                            }}
                        >

                        </DynamicTable>
                    </div>
                    <TPLoadingOverlay isModal={true} active={isLoadingScreen}>
                    </TPLoadingOverlay>
                </TPEmthyModal>

            </>
        );
    }
);

export default DistributedListSelector;
