import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import './InboundMailboxesRulesStyles.css';
import allThemes from "@/assets/styles/theme";
import {
  CIMTitleSection,
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  BaseFieldType,
  RuleActionType,
  RuleBaseFieldType,
  RuleComparisonOperatorsType,
  RuleLogicalOperatorType,
  SequenceGeneratorSequencesNameEnum,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
  TPLanguageFilterEnum,
  WebServiceClassifierType
} from "@/models/Global/TPGlobalEnums";
import {
  InboundMailRuleDefinitionDTO,
  InboundMailRuleDefinitionInputDTOValidator,
} from "@/models/InboundMailRuleDefinition/InboundMailRuleDefinitionDTO";
import {
  InboundMailRuleDefinition2DTO,
  InboundMailRuleDefinitionJsonRuleViewModel,
} from "@/models/InboundMailRuleDefinition/InboundMailRuleDefinitionViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { TPI18N } from "@/services/I18nService";
import { InboundMailboxesRulesService } from "@/services/InboundMailboxesRulesService";
import LanguageService from "@/services/LanguageService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { QuickClassifierService } from "@/services/QuickClassifierService";
import { SequenceService } from "@/services/SequenceService";
import {FC, useEffect, useRef, useState} from "react";
import RuleApplied from "../InboundMailboxes/RuleApplied";
import {MultilingualTextBoxEvents} from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBoxModel";
import MultilingualTextBox from "@/components/bootstrap/forms/multilingualTextBox/MultilingualTextBox";

type InboundMailboxesRulesInsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};
type InsertUpdateStateType = {
  id: string;
  description: string;
  recordLanguageList: TPKeyValue[];
  classifier: string;
  action: string;
  active: boolean;
  jsonRule: InboundMailRuleDefinitionJsonRuleViewModel[];

  idErrorMessage: string;
  descriptionErrorMessage: string;
  recordLanguageListErrorMessages: string[];
  classifierErrorMessage: string;
  actionErrorMessage: string;
  activeErrorMessage: string;
  jsonRuleErrorMessages: string[];

  [key: string]: any;
};

type PropertyName =
  //  | "RuleNumber"
  | "ParenthesisOpen"
  | "BaseField"
  | "ComparisonOperator"
  | "ReferenceValue"
  | "ParenthesisClose"
  | "Connector";

const InboundMailboxesRulesInsertUpdate: FC<
  InboundMailboxesRulesInsertUpdateProps
> = ({ mode, recordId, callBackResult }) => {
  const componentFileName: string = "InboundMailboxesRulesInsertUpdate.tsx";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  //Screen resources
  const resourceSet: string = "InboundMailboxesRulesContainerInsertUpdate"; //InboundMailboxesRulesInsertUpdateComponent
  const [titleLabel, setTitleLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");

  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");

  const [idLabel, setIdLabel] = useState("");
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [actionLabel, setActionLabel] = useState("");
  const [classifierLabel, setClassifierLabel] = useState("");
  const [showAsActiveLabel, setShowAsActiveLabel] = useState("");
  const [isNameValue, setIsNameValue] = useState("");
  const [isActiveLabel, setIsActiveLabel] = useState("");

  //table add more translation
  const [conditionLabel, setConditionLabel] = useState("");
  const [baseFieldLabel, setBaseFieldLabel] = useState("");
  const [comparisonOperatorLabel, setComparisonOperatorLabel] = useState("");
  const [referenceValueLabel, setReferenceValueLabel] = useState("");
  const [addNewConditionLabel, setAddNewConditionLabel] = useState("");
  const [conditionsDefinitionLabel, setConditionsDefinitionLabel] =
    useState("");

  const [languageListLabel, setLanguageListLabel] = useState("");
  const [addConditionLabel, setAddConditionLabel] = useState("");
  const [deleteConditionLabel, setDeleteConditionLabel] = useState("");
  const [queryExpressionTitle, setQueryExpressionTitle] = useState("");

  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [realMode, setRealMode] = useState(mode);
  const [realRecordId, setRealRecordId] = useState(recordId);

  //Multilanguage const
  const multilanguageTableName: String = "InboundMailRuleDefinition";
  const multilanguageFieldName: String = "Description_IMRD";

  const [mailActionToSelect, setMailActionToSelect] = useState<
    Array<TPKeyValue>
  >([]);
  const [mailBaseFieldsValuesToSelect, setMailBaseFieldsValuesToSelect] =
    useState<Array<TPKeyValue>>([]);
  const [mailComparisionValuesToSelect, setMailComparisionValuesToSelect] =
    useState<Array<TPKeyValue>>([]);

  const [languagesToSelect, setLanguagesToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [quickClassifiersToSelect, setQuickClassifiersToSelect] = useState<
    Array<TPKeyValue>
  >([]);

  const [logicalOperatorToSelect, setLogicalOperatorToSelect] = useState<
    Array<TPKeyValue>
  >([]);

  const [referenceId, setReferenceId] = useState("");
  const nameInputRef = useRef<MultilingualTextBoxEvents>();
  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }

  const initInboundMailRuleDefinitionJson = [
    {
      RuleNumber: 1,
      ParenthesisOpen: "",
      BaseField: "",
      ComparisonOperator: "",
      ReferenceValue: "",
      ParenthesisClose: "",
      Connector: "",
    },
  ];

  let insertUpdateInitialState: InsertUpdateStateType = {
    id: "",
    description: "",
    recordLanguageList: [],
    classifier: "",
    action: "",
    active: true,
    jsonRule: [...initInboundMailRuleDefinitionJson],

    idErrorMessage: "",
    descriptionErrorMessage: "",
    recordLanguageListErrorMessages: [...initialErrorMessages],
    classifierErrorMessage: "",
    actionErrorMessage: "",
    activeErrorMessage: "",
    jsonRuleErrorMessages: [],
  };

  const [insertUpdateState, setInsertUpdateState] =
    useState<InsertUpdateStateType>(insertUpdateInitialState);

  const [ruleAppliedComponent, setRuleAppliedComponent] = useState<
    InboundMailRuleDefinitionJsonRuleViewModel[] | never[]
  >([]);

  const loadResourcesAndLoadInfo = async () => {
    //resources state
    setTitleLabel(
      await TPI18N.GetText(resourceSet, "Title" + realMode + "Label"),
    );
    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel",
      ),
    );
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );

    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setShowAsActiveLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ShowAsActive"));
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );

    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setDescriptionLabel(await TPI18N.GetText(resourceSet, "DescriptionLabel"));

    setActionLabel(await TPI18N.GetText(resourceSet, "ActionLabel"));
    setClassifierLabel(await TPI18N.GetText(resourceSet, "ClassifierLabel"));
    setConditionLabel(await TPI18N.GetText(resourceSet, "ConditionLabel"));
    setBaseFieldLabel(await TPI18N.GetText(resourceSet, "BaseFieldLabel"));
    setComparisonOperatorLabel(
      await TPI18N.GetText(resourceSet, "ComparisonOperatorLabel"),
    );
    setReferenceValueLabel(
      await TPI18N.GetText(resourceSet, "ReferenceValueLabel"),
    );
    setAddNewConditionLabel(
      await TPI18N.GetText(resourceSet, "AddNewConditionLabel"),
    );
    setConditionsDefinitionLabel(
      await TPI18N.GetText(resourceSet, "ConditionsDefinitionLabel"),
    );
    setIsActiveLabel(await TPI18N.GetText(resourceSet, "ActiveLabel"));
    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );
    setAddConditionLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "AddConditionLabel"),
    );
    setDeleteConditionLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteConditionLabel"),
    );
    setQueryExpressionTitle(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "QueryExpressionTitle"),
    );

    //screen state

    await getMailRuleAction();
    await getBaseFields();
    await getComparesonOperators();
    await getLanguages();
    await getQuickClassifiersByType();
    await getLogicalOperator();

    if (realMode === "Update") {
      await getInboundMailRuleDefinitionById(realRecordId);
    }

    if (realMode === "Insert") {
      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.recordLanguageList =
        TPGlobal.TPClientAvailableLanguages.map((item) => ({
          key: item.id,
          value: "",
        }));

      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }

    setIsLoadingScreen(false);
  };

  const getInboundMailRuleDefinitionById = async (pRecordId: string) => {
    let serviceClient = new InboundMailboxesRulesService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;

    try {
      setIsLoadingScreen(true);
      let responseRequest =
        await serviceClient.getInboundMailRuleDefinitionById(
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfo = { ...responseRequest };
      setOriginalRecordDescription(`${recordInfo.localizedDescription}`);

      let newInsertUpdateState = { ...insertUpdateState };

      newInsertUpdateState.recordLanguageList =
        TPGlobal.TPClientAvailableLanguages.map((item) => ({
          key: item.id,
          value: "",
        }));

      newInsertUpdateState.id = recordInfo.id;
      newInsertUpdateState.description = recordInfo.description;
      newInsertUpdateState.classifier = recordInfo.webServiceCLassifierId;
      newInsertUpdateState.action = recordInfo.actionType;
      newInsertUpdateState.active = recordInfo.isActive;
      newInsertUpdateState.jsonRule = JSON.parse(recordInfo.jsonRule);

      recordLanguagesList = await getRecordLanguageList(pRecordId);
      if (recordLanguagesList.length === 0) {
        TPLog.Log(
          `Error ${componentFileName} getInboundMailRuleDefinitionById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty",
        );
        console.error(
          `Error ${componentFileName} getInboundMailRuleDefinitionById getRecordLanguageList is empty`,
        );
      } else {
        for (
          i = 0;
          i <= newInsertUpdateState.recordLanguageList.length - 1;
          i++
        ) {
          for (j = 0; j <= recordLanguagesList.length - 1; j++) {
            if (
              newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ===
              recordLanguagesList[j].languageId.toLowerCase()
            ) {
              newInsertUpdateState.recordLanguageList[i].value =
                recordLanguagesList[j].recordDescription;
              break;
            }
          }
        }
      }

      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getInboundMailRuleDefinitionById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} getInboundMailRuleDefinitionById ex`,
      );
      setIsLoadingScreen(false);
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes,
        );
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const getMailRuleAction = async () => {
    const mailboxRuleTypesEntries = Object.entries(RuleActionType);

    const mailRuleActionToSelectConverted: Array<TPKeyValue> =
      await Promise.all(
        mailboxRuleTypesEntries.map(async ([key, value]) => {
          return {
            key: value,
            value: await TPI18N.GetText(
              TPGlobal.globalResourceSet,
              `ACTION${key}`,
            ),
          };
        }),
      );

    setMailActionToSelect([
      { key: "", value: "--" },
      ...mailRuleActionToSelectConverted,
    ]);
  };

  const getBaseFields = async () => {
    const mailBaseFieldsEntries = Object.entries(RuleBaseFieldType);

    const mailBaseFieldsValuesToSelectConverted: Array<TPKeyValue> =
      await Promise.all(
        mailBaseFieldsEntries.map(async ([key, value]) => {
          return {
            key: `${BaseFieldType.MAILELEMENT}.${value}`,
            value: await TPI18N.GetText(
              TPGlobal.globalResourceSet,
              `MAILELEMENT${key}`,
            ),
          };
        }),
      );
    setMailBaseFieldsValuesToSelect([
      { key: "", value: "--" },
      ...mailBaseFieldsValuesToSelectConverted,
    ]);
  };

  const getComparesonOperators = async () => {
    const mailComparesonOperatorsToSelectConvertedTypesEntries = Object.entries(
      RuleComparisonOperatorsType,
    );

    const mailComparesonOperatorsToSelectConverted: Array<TPKeyValue> =
      await Promise.all(
        mailComparesonOperatorsToSelectConvertedTypesEntries.map(
          async ([key, value]) => {
            return {
              key: value,
              value: await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                `OPERATOR${key}`,
              ),
            };
          },
        ),
      );

    setMailComparisionValuesToSelect([
      { key: "", value: "--" },
      ...mailComparesonOperatorsToSelectConverted,
    ]);
  };

  const getLogicalOperator = async () => {
    const connectionProtocolsValues = Object.entries(RuleLogicalOperatorType);

    const connectionProtocolsToSelectConverted: Array<TPKeyValue> =
      await Promise.all(
        connectionProtocolsValues.map(async ([key, value]) => {
          return {
            key: value,
            value: await TPI18N.GetText(
              TPGlobal.globalResourceSet,
              `LogicalOperator${key}`,
            ),
          };
        }),
      );

    setLogicalOperatorToSelect(connectionProtocolsToSelectConverted);
  };

  const getLanguages = async () => {
    const languageClient = new LanguageService();
    const expectedCodes: Array<number> = [200, 404];
    const response = await languageClient.getLanguageByFilter(
      TPLanguageFilterEnum.ConfiguredForTPClientUI,
      false,
      true,
      expectedCodes,
    );

    const languages = response.responseData.data;

    const languagesToSelectConverted: Array<TPKeyValue> = languages.map(
      (item: any) => {
        return {
          key: item.id,
          value: item.name,
        };
      },
    );
    languagesToSelectConverted.unshift({ key: "", value: "--" });
    setLanguagesToSelect(languagesToSelectConverted);
  };

  const getQuickClassifiersByType = async () => {
    const quickClassifierClient = new QuickClassifierService();
    const expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);

      const responseRequest =
        await quickClassifierClient.getQuickClassifiersByType(
          WebServiceClassifierType.MailReader,
          TPActiveOptions.ACTIVE,
          false,
          true,
          expectedCodes,
        );
      setIsLoadingScreen(false);
      const quickClassifierList = responseRequest.map((res) => ({
        key: res.id,
        value: res.description,
      }));
      setQuickClassifiersToSelect([
        { key: "", value: "--" },
        ...quickClassifierList,
      ]);
      return quickClassifierList;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getQuickClassifiersByType ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getQuickClassifiersByType ex`);
      setIsLoadingScreen(false);
      setQuickClassifiersToSelect([]);
      return [];
    }
  };

  //#endregion "Autoresponse Email Template Autocomplete Functions"

  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.recordLanguageListErrorMessages[index] = "";
    if (index === 0) newInsertUpdateState.descriptionErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleChangeValue = (value: any) => {
    setIsNameValue(value);
  };

  const handleInputTextChange = (propertyName: string, newValue: any) => {
    const newInsertUpdateState = { ...insertUpdateState };
    if (
      propertyName === "action" &&
      `${RuleActionType.CREATECASE}` !== newValue
    ) {
      newInsertUpdateState.classifier = "";
    }
    setInsertUpdateState({
      ...newInsertUpdateState,
      [propertyName]: newValue,
      [`${propertyName}ErrorMessage`]: "",
    });
  };

  const handleIsActiveChange = (newValue: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.active = !newInsertUpdateState.active;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleOkButtonClick = async () => {
    if (nameInputRef.current?.isInvalid()) {
      nameInputRef.current?.markAsTouched();
      return;
    }

    const languages = nameInputRef.current?.getValue();
    const mainLanguage = languages?.find(({id}) => id === TPGlobal.TPClientDefaultLanguage);
    let i: number;
    let n: number;

    let recordInputDTO: InboundMailRuleDefinitionDTO = {
      // id: insertUpdateState.id,
      id: realMode === "Insert" ? referenceId : recordId,
      description: mainLanguage?.value!,
      webServiceCLassifierId: insertUpdateState.classifier,
      actionType: insertUpdateState.action,
      jsonRule: insertUpdateState.jsonRule,
      isActive: insertUpdateState.active,
      descriptionLocalizedValues: [
        {
          languageId: mainLanguage?.id!,
          localizedValue: mainLanguage?.value ?? "",
          order: 1
        },
        ...languages!
            .filter(({id}) => id !== TPGlobal.TPClientDefaultLanguage)
            .map(({id, value}, order) => ({languageId: id!, localizedValue: value ?? "", order: order + 2}))
      ],
    };

    let newInsertUpdateState = { ...insertUpdateState };
    let inputDTOGroupValidator =
      new InboundMailRuleDefinitionInputDTOValidator();
    let hasError: boolean = false;
    let resultValidator = inputDTOGroupValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      if (resultValidator.id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.id,
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }



      if (resultValidator.webServiceCLassifierId) {
        newInsertUpdateState.classifierErrorMessage = await TPI18N.GetResource(
          resultValidator.webServiceCLassifierId,
        );
      } else {
        newInsertUpdateState.classifierErrorMessage = "";
      }

      if (resultValidator.actionType) {
        newInsertUpdateState.actionErrorMessage = await TPI18N.GetResource(
          resultValidator.actionType,
        );
      } else {
        newInsertUpdateState.actionErrorMessage = "";
      }

      if (resultValidator.jsonRule) {
        n = insertUpdateState.jsonRule.length;
        for (i = 1; i <= n - 1; i++) {
          if (resultValidator.jsonRule[i]) {
            newInsertUpdateState.jsonRuleErrorMessages[i] =
              await TPI18N.GetResource(`${resultValidator.jsonRule[i]}`);
          }
        }
      } else {
        n = insertUpdateState.jsonRule.length;
        for (i = 1; i <= n - 1; i++) {
          newInsertUpdateState.jsonRuleErrorMessages[i] = "";
        }
      }

      if (resultValidator.isActive) {
        newInsertUpdateState.isActiveErrorMessage = await TPI18N.GetResource(
          resultValidator.isActive,
        );
      } else {
        newInsertUpdateState.isActiveErrorMessage = "";
      }
      hasError = true;
    }

    if (hasError) {
      setInsertUpdateState(newInsertUpdateState);
      return;
    }

    const dataRecordInputDTO = {
      id: recordInputDTO.id,
      description: recordInputDTO.description,
      webServiceCLassifierId: recordInputDTO.webServiceCLassifierId || null,
      actionType: recordInputDTO.actionType,
      jsonRule: TPGlobal.stringToUTF8(
        JSON.stringify(recordInputDTO.jsonRule),
      ).toString(),
      isActive: recordInputDTO.isActive,
      descriptionLocalizedValues: recordInputDTO.descriptionLocalizedValues,
    };

    if (realMode === "Insert") {
      await insertInboundMailRuleDefinition(dataRecordInputDTO);
    } else {
      await updateInboundMailRuleDefinition(dataRecordInputDTO);
    }
  };

  const handleCancelButtonClick = () => {
    //callBackResult({ result: "CANCEL", recordId: recordId });
    callBackResult({ result: "cancel_general", recordId: recordId });
  };

  const insertInboundMailRuleDefinition = async (
    inputDTO: InboundMailRuleDefinition2DTO,
  ) => {
    let serviceClient = new InboundMailboxesRulesService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertInboundMailRuleDefinition(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setRealMode("Update");
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        //callBackResult({ result: "OK", recordId: recordId });
        callBackResult({ result: "save_general_insert", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertInboundMailRuleDefinition ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} insertInboundMailRuleDefinition ex`,
      );
      setIsLoadingScreen(false);
    }
  };

  const updateInboundMailRuleDefinition = async (
    inputDTO: InboundMailRuleDefinition2DTO,
  ) => {
    let serviceClient = new InboundMailboxesRulesService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.updateInboundMailRuleDefinition(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "save_general_update", recordId: recordId });
        //callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateInboundMailRuleDefinition ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} updateInboundMailRuleDefinition ex`,
      );
      setIsLoadingScreen(false);
    }
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  const handleAddRow = () => {
    const newJsonRule = {
      ...initInboundMailRuleDefinitionJson[0],
      RuleNumber: insertUpdateState.jsonRule.length + 1,
    };

    setInsertUpdateState({
      ...insertUpdateState,
      jsonRule: [...insertUpdateState.jsonRule, newJsonRule],
    });
  };

  const handleDeleteRows = (rowIndex: number) => {
    const newJsonRule = insertUpdateState.jsonRule.filter(
      (_, index) => index < rowIndex,
    );
    newJsonRule[rowIndex - 1].Connector = "";
    setInsertUpdateState({
      ...insertUpdateState,
      jsonRule: newJsonRule,
    });
  };

  const handleInputChange = (
    rowIndex: number,
    propertyName: PropertyName,
    newValue: string,
  ) => {
    const updateJsonRule = [...insertUpdateState.jsonRule];
    updateJsonRule[rowIndex][propertyName] = newValue;
    setInsertUpdateState({
      ...insertUpdateState,
      jsonRule: updateJsonRule,
    });
  };

  // Add form validation function
const isFormValid = () => {
  return (
    isNameValue &&
    insertUpdateState.action &&
    (insertUpdateState.action !== RuleActionType.CREATECASE || insertUpdateState.classifier) &&
    insertUpdateState.jsonRule.every(rule => 
      rule.BaseField && 
      rule.ComparisonOperator && 
      rule.ReferenceValue
    )
  );
};

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQIMRD,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        // callBackResult({ result: "OK", recordId: recordId });
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  useEffect(() => {
    setRuleAppliedComponent([]);

    const isArrayEqueals =
      JSON.stringify(initInboundMailRuleDefinitionJson) ===
      JSON.stringify(insertUpdateState.jsonRule);

    if (!isArrayEqueals) {
      setTimeout(() => setRuleAppliedComponent(insertUpdateState.jsonRule), 50);
    }
  }, [insertUpdateState.jsonRule]);

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResourcesAndLoadInfo();
    realMode === "Insert" && generalAutomaticId();
  }, []);

  return (
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row inbound-rules-def-main-form-container">
          <div className="col inbound-rules-def-main-form-inner-container">
            <CIMTitleSection style={{ margin: 0, padding: 0 }}>
              <TPPageTitle style={{ margin: 0, padding: 0 }}>
                {titleLabel}
              </TPPageTitle>
              {realMode === "Update" && (
                <TPPageSubTitle>
                  {subTitleLabel} {recordId}
                </TPPageSubTitle>
              )}
            </CIMTitleSection>
  
            <TPPageSection>
              <div className="row">
                <div className="col-12">
                  <TPTextBox
                    id="IdTextBox"
                    labelText={idLabel}
                    isMandatory={true}
                    value={realMode === "Insert" ? referenceId : recordId}
                    disabled
                    errorMessage={insertUpdateState.idErrorMessage}
                    onChange={
                      realMode === "Insert"
                        ? (e: any) =>
                            handleInputTextChange("id", e.target.value)
                        : () => TPGlobal.foo()
                    }
                  />
                </div>
              </div>
            </TPPageSection>

            <TPPageSection>
              <div className="row">
                <div className="col-12">
                <MultilingualTextBox
                  ref={nameInputRef}
                  style={{ width: "calc(100% + 56px)" }}
                  onChange={handleChangeValue}
                  value={insertUpdateState.recordLanguageList?.map(
                    ({ key, value }) => ({ id: key, value }),
                  )}
                />
                </div>
              </div>
            </TPPageSection>
  
            <TPPageSection>
              <div className="row">
                <div className={insertUpdateState.action === RuleActionType.CREATECASE ? "col-6" : "col-12"}>
                  <TPSelect
                    id="action-select"
                    isMandatory={true}
                    onChange={(e:any) => handleInputTextChange("action", e.target.value)}
                    dataSource={mailActionToSelect}
                    value={insertUpdateState.action}
                    labelText={actionLabel}
                    errorMessage={insertUpdateState.actionErrorMessage}
                  />
                </div>
                {insertUpdateState.action === `${RuleActionType.CREATECASE}` && (
                  <div className="col-6">
                    <TPSelect
                      id="classifier-select"
                      isMandatory={true}
                      onChange={(e:any) => handleInputTextChange("classifier", e.target.value)}
                      dataSource={quickClassifiersToSelect}
                      value={insertUpdateState.classifier}
                      labelText={classifierLabel}
                      errorMessage={insertUpdateState.classifierErrorMessage}
                    />
                  </div>
                )}
              </div>
            </TPPageSection>

            {/* <div className="row">
              <div className="col-6">
                <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
              </div>
            </div> */}

            
  
            <TPPageSection>
              <div className="inbound-rules-section-title">
                {conditionsDefinitionLabel}
              </div>
              <table className="inbound-rules-table">
                <thead>
                  <tr>
                    <th>{conditionLabel}</th>
                    <th className="text-danger">(</th>
                    <th>
                      {baseFieldLabel}
                      <span className="text-danger">*</span>
                    </th>
                    <th>
                      {comparisonOperatorLabel}
                      <span className="text-danger">*</span>
                    </th>
                    <th>
                      {referenceValueLabel}
                      <span className="text-danger">*</span>
                    </th>
                    <th className="text-danger">)</th>
                    <th>{addNewConditionLabel}</th>
                  </tr>
                </thead>
                <tbody>
                  {insertUpdateState.jsonRule.map((row, index) => (
                  <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <span>{row.RuleNumber}</span>
                      {index > 0 && (
                        <TPButton
                          type={TPButtonTypes.icon}
                          icon={TPIconTypes.delete}
                          tooltip={deleteConditionLabel}
                          onClick={() => handleDeleteRows(index)}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <TPTextBox
                      id={`parenthesisOpen-${index}`}
                      value={row.ParenthesisOpen}
                      onChange={(e:any) => handleInputChange(index, "ParenthesisOpen", e.target.value)}
                      maxLength={1}
                      containerStyle={{ width: "70px" }}
                      errorMessage={insertUpdateState.jsonRuleErrorMessages[index]}
                    />
                  </td>
                  <td>
                    <TPSelect
                      id={`baseField-${index}`}
                      onChange={(e:any) => handleInputChange(index, "BaseField", e.target.value)}
                      dataSource={mailBaseFieldsValuesToSelect}
                      value={row.BaseField}
                      isMandatory={true}
                      errorMessage={insertUpdateState.jsonRuleErrorMessages[index]}
                    />
                  </td>
                  <td>
                    <TPSelect
                      id={`comparison-${index}`}
                      onChange={(e:any) => handleInputChange(index, "ComparisonOperator", e.target.value)}
                      dataSource={mailComparisionValuesToSelect}
                      value={row.ComparisonOperator}
                      isMandatory={true}
                      errorMessage={insertUpdateState.jsonRuleErrorMessages[index]}
                    />
                  </td>
                  <td>
                    <TPTextBox
                      id={`reference-${index}`}
                      value={row.ReferenceValue}
                      onChange={(e:any) => handleInputChange(index, "ReferenceValue", e.target.value)}
                      maxLength={200}
                      isMandatory={true}
                      errorMessage={insertUpdateState.jsonRuleErrorMessages[index]}
                    />
                  </td>
                  <td>
                    <TPTextBox
                      id={`parenthesisClose-${index}`}
                      value={row.ParenthesisClose}
                      onChange={(e:any) => handleInputChange(index, "ParenthesisClose", e.target.value)}
                      maxLength={1}
                      containerStyle={{ width: "70px" }}
                      errorMessage={insertUpdateState.jsonRuleErrorMessages[index]}
                    />
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      {index !== insertUpdateState.jsonRule.length - 1 ? (
                        <TPRadioGroup
                          id={`connector-${index}`}
                          value={row.Connector}
                          source={logicalOperatorToSelect}
                          onChange={(e:any) => handleInputChange(index, "Connector", e.target.value)}
                          isHorizontal={true}
                          errorMessage={insertUpdateState.jsonRuleErrorMessages[index]}
                        />
                      ) : (
                        <TPButton
                          type={TPButtonTypes.icon}
                          icon={TPIconTypes.addCircle}
                          tooltip={addConditionLabel}
                          onClick={handleAddRow}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                  ))}
                </tbody>
              </table>
            </TPPageSection>
  
            <TPPageSection>
              <TPLabel
                isMandatory={false}
                labelText={queryExpressionTitle}
                className="mb-3"
              />
              <div className="query-expression-container">
                {ruleAppliedComponent.length > 0 && (
                  <RuleApplied
                    inboundMailRuleJson={ruleAppliedComponent}
                    isHorizontal={true}
                  />
                )}
              </div>
            </TPPageSection>


            <TPPageSection>
              <div className="form-group">
                <TPCheckBox
                  id="active-checkbox"
                  labelText={showAsActiveLabel}
                  checked={insertUpdateState.active}
                  onChange={handleIsActiveChange}
                />
              </div>
            </TPPageSection>
  
            <div className="inbound-rules-def-option-buttons">
              <TPButton
                isDesignSystem
                id="cancel-button"
                onClick={handleCancelButtonClick}
                style={{
                  backgroundColor: "white",
                  color: allThemes.base.purplePrimary,
                  padding: "11px 16px",
                  marginRight: "10px"
                }}
              >
                {cancelButtonLabel}
              </TPButton>
              <TPButton
                isDesignSystem
                id="save-button"
                onClick={handleOkButtonClick}
                style={{ padding: "11px 16px" }}
                disabled={!isFormValid()}
              >
                {saveButtonLabel}
              </TPButton>
            </div>            	
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>
  );
};

export default InboundMailboxesRulesInsertUpdate;
