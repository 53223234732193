import React from "react";
import { Tabs, Tab, Box, TabsProps } from "@mui/material";
import "./TPHorizontalTabs.css";

interface TPHorizontalTabsProps extends Omit<TabsProps, "onChange"> {
  labels: string[];
  activeTab: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
    disabledTabs?: string[];
    style?: React.CSSProperties;
}

const TPHorizontalTabs: React.FC<TPHorizontalTabsProps> = ({
  labels,
  activeTab,
  onChange,
    disabledTabs,
    style
}) => {
  return (
    <Tabs
          value={activeTab}
          onChange={onChange}
          variant="standard"
          textColor="secondary"
          indicatorColor="secondary"
          className="custom-tabs__container"
          style={style ? style:{}}
    >
      {labels.map((label, index) => (
        <Tab
          key={index}
          label={label}
          className={`custom-tabs__tab ${
            activeTab === index && "custom-tabs__tab--active"
          }`}
          sx={{
            textTransform: "none",
          }}
          disabled={disabledTabs?.includes(label)}
        />
      ))}
    </Tabs>
  );
};

export default TPHorizontalTabs;
