import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { TPOptionButtonPairStyled } from "@/components/bootstrap/forms/submit/TPOptionButtonPair";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import { TPStyledForm, TPStyledFormButtonFooter, TPStyledFormRow, TPStyledInnerInputForm, TPStyledSubtitle } from "@/components/styled/StyledForms";
import TPBranchSelection2, {
  TPBranchSelectionValue,
} from "@/components/TPBranchSelection/TPBranchSelection2";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import {
  SequenceGeneratorSequencesNameEnum,
  TPActiveOptions,
  TPButtonTypes,
  TPLanguageFilterEnum,
  UserGroupTypesEnum,
} from "@/models/Global/TPGlobalEnums";
import {
  GroupsInputDTO,
  GroupsInputDTOValidator,
} from "@/models/Groups/GroupsInputDTO";
import { GroupsViewModel } from "@/models/Groups/GroupsModels";
import {
  UserInputDTOValidator,
  ValidationUserGroupModeEnum,
} from "@/models/Users/UserInputDTO";
import { UserViewModel } from "@/models/Users/UserModels";
import { BranchService } from "@/services/BranchService";
import { CalendarsService } from "@/services/CalendarsService";
import { GroupsService } from "@/services/GroupsService";
import { TPI18N } from "@/services/I18nService";
import LanguageService from "@/services/LanguageService";
import { SchedulesService } from "@/services/SchedulesService";
import { SequenceService } from "@/services/SequenceService";
import { TimeZonesService } from "@/services/TimeZonesService";
import { UserService } from "@/services/UserService";
import { FC, useEffect, useState } from "react";
type InsertUpdateProps = {
  mode: string;
  recordId: string;
  groupDescription: string;
  callBackResult: Function;
  callBackSubtitle?: Function;
};
type InsertUpdateStateType = {
  groupId: string;
  userGroupDescription: string;
  initials: string;
  email: string;
  timeZoneId: string;
  calendarId: string;
  scheduleId: string;
  defaultLanguage: string;
  superiorIdGuid: string;
  groupType: string;
  addTaskToCaseOption: string;
  rotatingUmbral: number;
  assigToLoggedUser: boolean;
  rotatingPreferredCustomer: boolean;
  rotatingRecentAgent: boolean;
  recentAgent: boolean;
  finishPendingCase: boolean;
  maxCaseUser: number;
  priorityWeight: string;
  preferredCustomer: boolean;
  prioritizeRelatedCases: boolean;
  topSearchCoefficient: string;
  isActive: boolean;
  userGuid: string;
  login: string;

  //validator
  loginErrorMessage: string;
  idErrorMessage: string;
  userGroupDescriptionErrorMessage: string;
  initialsErrorMessage: string;
  emailErrorMessage: string;
  teamIdErrorMessage: string;
  departmentErrorMessage: string;
  timeZoneIdErrorMessage: string;
  calendarIdErrorMessage: string;
  scheduleIdErrorMessage: string;
  defaultLanguageErrorMessage: string;
  superiorIdGuidErrorMessage: string;
  groupTypeErrorMessage: string;
  addTaskToCaseOptionErrorMessage: string;
  rotatingUmbralErrorMessage: string;
  maxCaseUserErrorMessage: string;
  priorityWeightErrorMessage: string;
  topSearchCoefficientErrorMessage: string;
  [key: string]: any;
};

const GroupsInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  groupDescription,
  callBackResult,
  callBackSubtitle,
}) => {
  const teamTreeJson: any = JSON.parse(TPGlobal.userTreeBranchTeamMapping);
  const departmentTreeJson: any = JSON.parse(
    TPGlobal.userTreeBranchAreaMapping
  );
  const componentFileName: string = "GroupsInsertUpdate.tsx";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  //Screen resources
  const resourceSet: string = "GroupsInsertUpdateComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [parametersGroupTypeSectionLabel, setParametersGroupTypeSectionLabel] =
    useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");

  const [idLabel, setIdLabel] = useState("");
  const [userGroupDescriptionLabel, setUserGroupDescriptionLabel] =
    useState("");
  const [initialsLabel, setInitialsLabel] = useState("");
  const [emailLabel, setEmailLabel] = useState("");
  const [teamsLabel, setTeamsLabel] = useState("");
  const [timezoneLabel, setTimezoneLabel] = useState("");
  const [departmentLabel, setDepartmentLabel] = useState("");
  const [calendarLabel, setCalendarLabel] = useState("");
  const [scheduleLabel, setScheduleLabel] = useState("");
  const [defaultLanguageLabel, setDefaultLanguageLabel] = useState("");
  const [superiorLabel, setSuperiorLabel] = useState("");
  const [groupTypeLabel, setGroupTypeLabel] = useState("");
  const [addTaskToCaseOptionLabel, setAddTaskToCaseOptionLabel] = useState("");
  const [rotatingUmbralLabel, setRotatingUmbralLabel] = useState("");
  const [priorityWeightLabel, setPriorityWeightLabel] = useState("");
  const [maxCaseUserLabel, setMaxCaseUserLabel] = useState("");
  const [topSearchCoefficientLabel, setTopSearchCoefficientLabel] =
    useState("");
  const [isActiveLabel, setIsActiveLabelLabel] = useState("");

  const [assignCasesToUserLoggedLabel, setAssignCasesToUserLoggedLabel] =
    useState("");
  const [customerPreferredLabel, setCustomerPreferredLabel] = useState("");
  const [recentAgentLabel, setRecentAgentLabel] = useState("");
  const [finishPendingCaseBeforeLabel, setFinishPendingCaseBeforeLabel] =
    useState("");
  const [relatedCasesLabel, setRelatedCasesLabel] = useState("");

  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [realMode, setRealMode] = useState(mode);
  const [realRecordId, setRealRecordId] = useState(recordId);

  const [timezonesToSelect, setSTimeZonesToSelect] = useState<
    Array<TPKeyValue>
  >([]);

  //TPBranchSelect Resources
  const [modalAcceptLabel, setModalAcceptLabel] = useState("");
  const [modalCancelLabel, setModalCancelLabel] = useState("");
  const [modalSelectedBranchLabel, setModalSelectedBranchLabel] = useState("");
  const [modalTitleLabel, setmodalTitleLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");
  const [referenceId, setReferenceId] = useState("");

  //Teams state
  const initialSelectedTeamBranch: TPBranchSelectionValue = {
    branchId: "",
    branchDescription: "",
    branchHierarchyDescription: "",
  };
  const [selectedTeamBranchListValue, setSelectedTeamBranchListValue] =
    useState<TPBranchSelectionValue>(initialSelectedTeamBranch);

  //Department state
  const initialSelectedDepartmentBranch: TPBranchSelectionValue = {
    branchId: "",
    branchDescription: "",
    branchHierarchyDescription: "",
  };
  const [
    selectedDepartmentBranchListValue,
    setSelectedDepartmentBranchListValue,
  ] = useState<TPBranchSelectionValue>(initialSelectedDepartmentBranch);

  const [calendarsToSelect, setCalendarsToSelect] = useState<Array<TPKeyValue>>(
    []
  );

  const [schedulesToSelect, setSchedulesToSelect] = useState<Array<TPKeyValue>>(
    []
  );
  const [languagesToSelect, setLanguagesToSelect] = useState<Array<TPKeyValue>>(
    []
  );
  const [superiorsToSelect, setSuperiorsToSelect] = useState<Array<TPKeyValue>>(
    []
  );
  const [groupTypeToSelect, setGroupTypeToSelect] = useState<Array<TPKeyValue>>(
    []
  );

  const [addTaskToCaseOptionToSelect, setAddTaskToCaseOptionToSelect] =
    useState<Array<TPKeyValue>>([]);

  const [rotatingUmbralToSelect, setRotatingUmbralToSelect] = useState<
    Array<TPKeyValue>
  >([
    { key: "0", value: "--" },
    { key: "5", value: "5" },
    { key: "10", value: "10" },
    { key: "15", value: "15" },
    { key: "20", value: "20" },
    { key: "25", value: "25" },
    { key: "30", value: "30" },
    { key: "45", value: "45" },
    { key: "60", value: "60" },
    { key: "90", value: "90" },
    { key: "120", value: "120" },
  ]);

  const [priorityWeightToSelect, setPriorityWeightToSelect] = useState<
    Array<TPKeyValue>
  >([]);

  const [topSearchCoefficientToSelect, setTopSearchCoefficientToSelect] =
    useState<Array<TPKeyValue>>([]);

  let insertUpdateInitialState: InsertUpdateStateType = {
    groupId: recordId,
    userGroupDescription: "",
    initials: "",
    idErrorMessage: "",
    userGroupDescriptionErrorMessage: "",
    initialsErrorMessage: "",
    email: "",
    emailErrorMessage: "",
    timeZoneId: "",
    timeZoneIdErrorMessage: "",
    teamIdErrorMessage: "",
    departmentErrorMessage: "",
    calendarId: "",
    calendarIdErrorMessage: "",
    scheduleId: "",
    scheduleIdErrorMessage: "",
    defaultLanguage: "",
    defaultLanguageErrorMessage: "",
    superiorIdGuid: "",
    superiorIdGuidErrorMessage: "",
    groupType: "",
    groupTypeErrorMessage: "",
    addTaskToCaseOption: "",
    addTaskToCaseOptionErrorMessage: "",
    rotatingUmbral: 0,
    rotatingUmbralErrorMessage: "",
    assigToLoggedUser: false,
    rotatingPreferredCustomer: false,
    recentAgent: false,
    maxCaseUser: 0,
    maxCaseUserErrorMessage: "",
    finishPendingCase: false,
    priorityWeight: "0",
    priorityWeightErrorMessage: "",
    preferredCustomer: false,
    topSearchCoefficient: "0",
    topSearchCoefficientErrorMessage: "",
    prioritizeRelatedCases: false,
    rotatingRecentAgent: false,
    isActive: true,
    userGuid: "",
    login: "",
    loginErrorMessage: "",
  };
  const [insertUpdateState, setInsertUpdateState] =
    useState<InsertUpdateStateType>(insertUpdateInitialState);

  const loadResourcesAndLoadGroupInfo = async () => {
    let i: number;
    //resources state
    setTitleLabel(
      await TPI18N.GetText(resourceSet, "Title" + realMode + "Label")
    );
    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel"
      )
    );
    setParametersSectionLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ParametersSectionLabel")
    );
    setParametersGroupTypeSectionLabel(
      await TPI18N.GetText(resourceSet, "ParametersGroupTypeSectionLabel")
    );
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton")
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
    );
    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setUserGroupDescriptionLabel(
      await TPI18N.GetText(resourceSet, "UserGroupDescriptionLabel")
    );
    setInitialsLabel(await TPI18N.GetText(resourceSet, "InitialsLabel"));
    setEmailLabel(await TPI18N.GetText(resourceSet, "EmailLabel"));
    setTeamsLabel(await TPI18N.GetText(resourceSet, "TeamsLabel"));
    setTimezoneLabel(await TPI18N.GetText(resourceSet, "TimezoneLabel"));
    setDepartmentLabel(await TPI18N.GetText(resourceSet, "DepartmentLabel"));
    setCalendarLabel(await TPI18N.GetText(resourceSet, "CalendarLabel"));
    setScheduleLabel(await TPI18N.GetText(resourceSet, "ScheduleLabel"));
    setDefaultLanguageLabel(
      await TPI18N.GetText(resourceSet, "DefaultLanguageLabel")
    );
    setSuperiorLabel(await TPI18N.GetText(resourceSet, "SuperiorLabel"));
    setGroupTypeLabel(await TPI18N.GetText(resourceSet, "GroupTypeLabel"));
    setAddTaskToCaseOptionLabel(
      await TPI18N.GetText(resourceSet, "AddTaskToCaseOptionLabel")
    );
    setRotatingUmbralLabel(
      await TPI18N.GetText(resourceSet, "RotatingUmbralLabel")
    );
    setMaxCaseUserLabel(await TPI18N.GetText(resourceSet, "MaxCaseUserLabel"));
    setPriorityWeightLabel(
      await TPI18N.GetText(resourceSet, "PriorityWeightLabel")
    );
    setTopSearchCoefficientLabel(
      await TPI18N.GetText(resourceSet, "TopSearchCoefficientLabel")
    );
    setIsActiveLabelLabel(
      await TPI18N.GetText(resourceSet, "IsActiveLabelLabel")
    );
    setAssignCasesToUserLoggedLabel(
      await TPI18N.GetText(resourceSet, "AssignCasesToUserLoggedLabel")
    );
    setCustomerPreferredLabel(
      await TPI18N.GetText(resourceSet, "CustomerPreferredLabel")
    );
    setRecentAgentLabel(await TPI18N.GetText(resourceSet, "RecentAgentLabel"));
    setFinishPendingCaseBeforeLabel(
      await TPI18N.GetText(resourceSet, "FinishPendingCaseBeforeLabel")
    );
    setRelatedCasesLabel(
      await TPI18N.GetText(resourceSet, "RelatedCasesLabel")
    );
    setModalAcceptLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalAcceptLabel")
    );
    setmodalTitleLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalTitleLabel")
    );
    setModalCancelLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalCancelLabel")
    );
    setModalSelectedBranchLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ModalSelectedBranchLabel"
      )
    );
    setEmptyLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel")
    );
    //screen state

    let newPriorityWeightToSelect: Array<TPKeyValue> = [];
    for (let i = 1; i <= 10; i++) {
      newPriorityWeightToSelect.push({ key: i.toString(), value: i });
    }

    let newTopSearchCoefficientToSelect: Array<TPKeyValue> = [];
    for (let i = 1; i <= 20; i++) {
      newTopSearchCoefficientToSelect.push({ key: i.toString(), value: i });
    }

    setPriorityWeightToSelect(newPriorityWeightToSelect);
    setTopSearchCoefficientToSelect(newTopSearchCoefficientToSelect);

    let newGroupTypeToSelect = [...groupTypeToSelect];
    newGroupTypeToSelect.push({ key: "", value: "--" });
    newGroupTypeToSelect.push({
      key: UserGroupTypesEnum.update,
      value: await TPI18N.GetText(resourceSet, "CommonUpdateItemLabel"),
    });
    newGroupTypeToSelect.push({
      key: UserGroupTypesEnum.noUpdate,
      value: await TPI18N.GetText(resourceSet, "CommonNoUpdateItemLabel"),
    });
    newGroupTypeToSelect.push({
      key: UserGroupTypesEnum.rotating,
      value: await TPI18N.GetText(resourceSet, "RotatingItemLabel"),
    });
    newGroupTypeToSelect.push({
      key: UserGroupTypesEnum.takeNext,
      value: await TPI18N.GetText(resourceSet, "TakeNextItemLabel"),
    });
    setGroupTypeToSelect(newGroupTypeToSelect);

    let newAddTaskToCaseOptionToSelect = [...addTaskToCaseOptionToSelect];
    newAddTaskToCaseOptionToSelect.push({ key: "", value: "--" });
    newAddTaskToCaseOptionToSelect.push({
      key: "1",
      value: await TPI18N.GetText(resourceSet, "ResponsibleGroupItemLabel"),
    });
    newAddTaskToCaseOptionToSelect.push({
      key: "2",
      value: await TPI18N.GetText(
        resourceSet,
        "AgentWhoAnsweredPreviouslyItemLabel"
      ),
    });

    setAddTaskToCaseOptionToSelect(newAddTaskToCaseOptionToSelect);

    await getTimezones();
    await getCalendars();
    await getSchedules();
    await getLanguages();
    await getSuperiors();

    if (realMode == "Update") {
      await getById(realRecordId);
    }
    setIsLoadingScreen(false);
  };

  const getById = async (pRecordId: string) => {
    let serviceClient = new GroupsService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getGroupById(
        pRecordId,
        false,
        true,
        expectedCodes
      );
      let recordInfo: GroupsViewModel;
      recordInfo = { ...responseRequest };
      let newInsertUpdateState = { ...insertUpdateState };
      if (recordInfo.isRotating) {
        newInsertUpdateState.groupType = UserGroupTypesEnum.rotating;
        newInsertUpdateState.rotatingPreferredCustomer =
          recordInfo.preferredAgent;
        newInsertUpdateState.rotatingRecentAgent = recordInfo.recentAgent;
      } else if (recordInfo.isPicking) {
        newInsertUpdateState.groupType = UserGroupTypesEnum.takeNext;
        newInsertUpdateState.preferredCustomer = recordInfo.preferredAgent;
        newInsertUpdateState.recentAgent = recordInfo.recentAgent;
      } else if (recordInfo.isUpdatable) {
        newInsertUpdateState.groupType = UserGroupTypesEnum.update;
      } else {
        newInsertUpdateState.groupType = UserGroupTypesEnum.noUpdate;
      }
      newInsertUpdateState.assigToLoggedUser =
        recordInfo.assignCasesToUsersLogged;
      newInsertUpdateState.finishPendingCase =
        recordInfo.finishPendingCaseBeforeRecivingNewOne;
      newInsertUpdateState.groupId = recordInfo.groupId.toString();
      newInsertUpdateState.maxCaseUser = recordInfo.maximumCasesPerUser
        ? recordInfo.maximumCasesPerUser
        : 0;
      newInsertUpdateState.rotatingUmbral = recordInfo.minutesLogoutReassign
        ? recordInfo.minutesLogoutReassign
        : 0;
      newInsertUpdateState.prioritizeRelatedCases =
        recordInfo.prioritizeRelatedCases;
      newInsertUpdateState.priorityWeight = recordInfo.priorityWeight.toString();

      newInsertUpdateState.addTaskToCaseOption =
        recordInfo.taskResponsibleForSocialMediaAnswer.toString();
      newInsertUpdateState.topSearchCoefficient =
        recordInfo.topSearchCoefficient.toString();
      newInsertUpdateState.calendarId = recordInfo.user.calendarId;
      newInsertUpdateState.defaultLanguage = recordInfo.user.defaultLanguage;
      newInsertUpdateState.email = recordInfo.user.email;
      newInsertUpdateState.initials = recordInfo.user.initials;
      newInsertUpdateState.isActive = recordInfo.user.isActive;
      newInsertUpdateState.scheduleId = recordInfo.user.scheduleId;
      newInsertUpdateState.superiorIdGuid = recordInfo.user.superiorIdGuid;
      newInsertUpdateState.timeZoneId = recordInfo.user.timeZoneId;
      newInsertUpdateState.userGroupDescription = recordInfo.user.firstName;
      newInsertUpdateState.userGuid = recordInfo.user.userGuid;
      newInsertUpdateState.login = recordInfo.user.login;
      setInsertUpdateState(newInsertUpdateState);
      await getTeamBranchInfo(recordInfo.user.teamId);
      await getAreaBranchInfo(recordInfo.user.areaId);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getById ex`);
      setIsLoadingScreen(false);
    }
  };

  const getTeamBranchInfo = async (parentId: string) => {
    let branchData: BranchViewModel | null = null;
    branchData = await getBranchInfoService(parentId);

    if (branchData == null) {
      //todo logs
      return false;
    }

    let newSelectedTeamBranchListValue: TPBranchSelectionValue = {
      ...selectedTeamBranchListValue,
    };
    newSelectedTeamBranchListValue.branchId = parentId;
    newSelectedTeamBranchListValue.branchDescription = branchData.description;
    newSelectedTeamBranchListValue.branchHierarchyDescription =
      branchData.hierarchyDescription;
    setSelectedTeamBranchListValue(newSelectedTeamBranchListValue);
  };
  const getAreaBranchInfo = async (parentId: string) => {
    let branchData: BranchViewModel | null = null;
    branchData = await getBranchInfoService(parentId);

    if (branchData == null) {
      //todo logs
      return false;
    }

    let newSelectedAreaBranchListValue: TPBranchSelectionValue = {
      ...selectedDepartmentBranchListValue,
    };
    newSelectedAreaBranchListValue.branchId = parentId;
    newSelectedAreaBranchListValue.branchDescription = branchData.description;
    newSelectedAreaBranchListValue.branchHierarchyDescription =
      branchData.hierarchyDescription;
    setSelectedDepartmentBranchListValue(newSelectedAreaBranchListValue);
  };

  const getBranchInfoService = async (branchId: string) => {
    let serviceClient = new BranchService();
    let expectedCodes: Array<number> = [200];
    let branchData: BranchViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getBranchById(
        branchId,
        false,
        false,
        expectedCodes
      );
      branchData = responseRequest;
      return branchData;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getBranchInfo ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getBranchInfo ex`);
      return null;
    }
  };

  const getTimezones = async () => {
    const TimeZoneClient = new TimeZonesService();
    const expectedCodes: Array<number> = [200, 404];
    const timezones = await TimeZoneClient.getTimeZonesCollection(
      false,
      true,
      expectedCodes
    );
    const timezonesToSelectConverted: Array<TPKeyValue> = timezones.map((k) => {
      return {
        key: k.id,
        value: k.displayName,
      };
    });
    timezonesToSelectConverted.unshift({ key: "", value: "--" });
    setSTimeZonesToSelect(timezonesToSelectConverted);
  };

  const getCalendars = async () => {
    const calendarClient = new CalendarsService();
    const expectedCodes: Array<number> = [200, 404];
    const schedules = await calendarClient.getCalendarsByFilter(
      TPActiveOptions.ALL.toString(),
      false,
      true,
      expectedCodes
    );
    const calendarsToSelectConverted: Array<TPKeyValue> = schedules
      .filter((k) => k.isActive)
      .map((k) => {
        return {
          key: k.id,
          value: k.description,
        };
      });
    calendarsToSelectConverted.unshift({ key: "", value: "--" });
    setCalendarsToSelect(calendarsToSelectConverted);
  };

  const getSchedules = async () => {
    const scheduleClient = new SchedulesService();
    const expectedCodes: Array<number> = [200, 404];
    const schedules = await scheduleClient.getSchedulesByFilter(
      TPActiveOptions.ALL.toString(),
      false,
      true,
      expectedCodes
    );
    const schedulesToSelectConverted: Array<TPKeyValue> = schedules
      .filter((k) => k.isActive)
      .map((k) => {
        return {
          key: k.id,
          value: k.description,
        };
      });
    schedulesToSelectConverted.unshift({ key: "", value: "--" });
    setSchedulesToSelect(schedulesToSelectConverted);
  };

  const getLanguages = async () => {
    const languageClient = new LanguageService();
    const expectedCodes: Array<number> = [200, 404];
    const response = await languageClient.getLanguageByFilter(
      TPLanguageFilterEnum.ConfiguredForTPClientUI,
      false,
      true,
      expectedCodes
    );

    const languages = response.responseData.data;

    const languagesToSelectConverted: Array<TPKeyValue> = languages.map(
      (k: any) => {
        return {
          key: k.id,
          value: k.name,
        };
      }
    );
    languagesToSelectConverted.unshift({ key: "", value: "--" });
    setLanguagesToSelect(languagesToSelectConverted);
  };

  const getSuperiors = async () => {
    const userClient = new UserService();
    const expectedCodes: Array<number> = [200, 404];
    //TODO: Guardar esta constante en un archivo "TEXTTREE"
    const superiors: Array<UserViewModel> = await userClient.getUsersByFilter(
      TPActiveOptions.ALL.toString(),
      false,
      true,
      expectedCodes
    );
    const superiorsToSelectConverted: Array<TPKeyValue> = superiors.map((k) => {
      return {
        key: k.userGuid,
        value: k.name,
      };
    });
    superiorsToSelectConverted.unshift({ key: "", value: "--" });
    setSuperiorsToSelect(superiorsToSelectConverted);
  };

  const handleInputTextChange = (propertyName: string, newValue: any) => {
    let newInsertUpdateState = { ...insertUpdateState };

    newInsertUpdateState[propertyName] = newValue;
    newInsertUpdateState[propertyName + "ErrorMessage"] = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleCheckBoxChange = (propertyName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState[propertyName] = !newInsertUpdateState[propertyName];
    if (
      propertyName === "assigToLoggedUser" &&
      !newInsertUpdateState[propertyName]
    ) {
      newInsertUpdateState.rotatingUmbral = 0;
    }
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleOnTeamBranchChange = (
    newBranchId: string,
    newBranchDescription: string,
    newBranchHierarchyDescription: string
  ) => {
    let newInsertUpdateState: InsertUpdateStateType = { ...insertUpdateState };
    newInsertUpdateState.teamIdErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);

    let newSelectedTeamBranchListValue: TPBranchSelectionValue = {
      ...selectedTeamBranchListValue,
    };
    newSelectedTeamBranchListValue.branchId = newBranchId;
    newSelectedTeamBranchListValue.branchDescription = newBranchDescription;
    newSelectedTeamBranchListValue.branchHierarchyDescription =
      newBranchHierarchyDescription;
    setSelectedTeamBranchListValue(newSelectedTeamBranchListValue);
  };

  const handleOnDepartmentBranchChange = (
    newBranchId: string,
    newBranchDescription: string,
    newBranchHierarchyDescription: string
  ) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.departmentErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);

    let newSelectedDepartmentBranchListValue: TPBranchSelectionValue = {
      ...selectedDepartmentBranchListValue,
    };
    newSelectedDepartmentBranchListValue.branchId = newBranchId;
    newSelectedDepartmentBranchListValue.branchDescription =
      newBranchDescription;
    newSelectedDepartmentBranchListValue.branchHierarchyDescription =
      newBranchHierarchyDescription;
    setSelectedDepartmentBranchListValue(newSelectedDepartmentBranchListValue);
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "cancel_general", recordId: realRecordId });
  };

  const isValid = () => {
    let recordInputDTO: GroupsInputDTO = {
      groupId: realMode == "Insert" ? 0 : (insertUpdateState.groupId as any),
      isUpdatable: insertUpdateState.groupType == UserGroupTypesEnum.update,
      isRotating: insertUpdateState.groupType == UserGroupTypesEnum.rotating,
      isPicking: insertUpdateState.groupType == UserGroupTypesEnum.takeNext,
      finishPendingCaseBeforeRecivingNewOne:
        insertUpdateState.finishPendingCase,
      preferredAgent:
        insertUpdateState.groupType == UserGroupTypesEnum.rotating
          ? insertUpdateState.rotatingPreferredCustomer
          : insertUpdateState.preferredCustomer,
      relatedCase:
      insertUpdateState.groupType == UserGroupTypesEnum.rotating
        ? insertUpdateState.rotatingRelatedCase
        : insertUpdateState.prioritizeRelatedCases,
      recentAgent:
        insertUpdateState.groupType == UserGroupTypesEnum.rotating
          ? insertUpdateState.rotatingRecentAgent
          : insertUpdateState.recentAgent,
      prioritizeRelatedCases: insertUpdateState.prioritizeRelatedCases,
      topSearchCoefficient: Number(insertUpdateState.topSearchCoefficient),
      assignCasesToUsersLogged:
        insertUpdateState.groupType == UserGroupTypesEnum.rotating
          ? insertUpdateState.assigToLoggedUser
          : false,
      minutesLogoutReassign: insertUpdateState.rotatingUmbral,
      maximumCasesPerUser: insertUpdateState.maxCaseUser,
      //TODO: Remove when social media already exists in core.
      taskResponsibleForSocialMediaAnswer: 1, //insertUpdateState.addTaskToCaseOption
      priorityWeight: Number(insertUpdateState.priorityWeight),
      isEventTakeNextGroup: false,
      user: {
        userGuid: insertUpdateState.userGuid.toUpperCase(),
        login:
          realMode == "Insert"
            ? referenceId
            : (groupDescription ?? insertUpdateState?.login),
        firstName: insertUpdateState.userGroupDescription,
        lastName: "",
        name: "",
        initials: insertUpdateState.initials,
        profileId: null,
        profileDescription: "",
        superiorIdGuid: insertUpdateState.superiorIdGuid,
        email: insertUpdateState.email,
        teamId: selectedTeamBranchListValue.branchId,
        teamDescription: selectedTeamBranchListValue.branchDescription,
        functionId: "",
        functionDescription: "",
        areaId: selectedDepartmentBranchListValue.branchId,
        areaDescription: selectedDepartmentBranchListValue.branchDescription,
        phone: "",
        mobile: "",
        typistIdGuid: TPGlobal.currentUserGuid,
        calendarId: insertUpdateState.calendarId,
        calendarDescription: "",
        timeZoneId: insertUpdateState.timeZoneId,
        scheduleId: insertUpdateState.scheduleId,
        scheduleDescription: "",
        authenticationMethodId: "",
        microsoftIdentity: "",
        defaultLanguage: insertUpdateState.defaultLanguage,
        maxSimultaneousChat: null,
        isActive: insertUpdateState.isActive,
        isSystemRecord: false,
      },
    };

    if (insertUpdateState.groupType === UserGroupTypesEnum.rotating) {
      recordInputDTO.finishPendingCaseBeforeRecivingNewOne = false;
      recordInputDTO.priorityWeight = 1;
      recordInputDTO.topSearchCoefficient = 1;
      recordInputDTO.prioritizeRelatedCases = false;
    }
    if (insertUpdateState.groupType === UserGroupTypesEnum.takeNext) {
      recordInputDTO.assignCasesToUsersLogged = false;
      recordInputDTO.maximumCasesPerUser = null;
      recordInputDTO.minutesLogoutReassign = null;
    }

    let inputDTOGroupValidator = new GroupsInputDTOValidator(
      insertUpdateState.groupType as UserGroupTypesEnum
    );
    let hasError: boolean = false;
    let resultValidatorGroup = inputDTOGroupValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidatorGroup)) {
      hasError = true;
    }

    let inputDTOValidator = new UserInputDTOValidator(
      ValidationUserGroupModeEnum.group
    );
    let resultValidator = inputDTOValidator.validate(recordInputDTO.user);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      hasError = true;
    }

    if (!insertUpdateState.groupType) {
      hasError = true;
    }

    return hasError;
  }

  const handleOkButtonClick = async () => {
    let recordInputDTO: GroupsInputDTO = {
      groupId: realMode == "Insert" ? 0 : (insertUpdateState.groupId as any),
      isUpdatable: insertUpdateState.groupType == UserGroupTypesEnum.update,
      isRotating: insertUpdateState.groupType == UserGroupTypesEnum.rotating,
      isPicking: insertUpdateState.groupType == UserGroupTypesEnum.takeNext,
      finishPendingCaseBeforeRecivingNewOne:
        insertUpdateState.finishPendingCase,
      preferredAgent:
        insertUpdateState.groupType == UserGroupTypesEnum.rotating
          ? insertUpdateState.rotatingPreferredCustomer
          : insertUpdateState.preferredCustomer,
      relatedCase:
      insertUpdateState.groupType == UserGroupTypesEnum.rotating
        ? insertUpdateState.rotatingRelatedCase
        : insertUpdateState.prioritizeRelatedCases,
      recentAgent:
        insertUpdateState.groupType == UserGroupTypesEnum.rotating
          ? insertUpdateState.rotatingRecentAgent
          : insertUpdateState.recentAgent,
      prioritizeRelatedCases: insertUpdateState.prioritizeRelatedCases,
      topSearchCoefficient: Number(insertUpdateState.topSearchCoefficient),
      assignCasesToUsersLogged:
        insertUpdateState.groupType == UserGroupTypesEnum.rotating
          ? insertUpdateState.assigToLoggedUser
          : false,
      minutesLogoutReassign: insertUpdateState.rotatingUmbral,
      maximumCasesPerUser: insertUpdateState.maxCaseUser,
      //TODO: Remove when social media already exists in core.
      taskResponsibleForSocialMediaAnswer: 1, //insertUpdateState.addTaskToCaseOption
      priorityWeight: Number(insertUpdateState.priorityWeight),
      isEventTakeNextGroup: false,
      user: {
        userGuid: insertUpdateState.userGuid.toUpperCase(),
        login:
          realMode == "Insert"
            ? referenceId
            : (groupDescription ?? insertUpdateState?.login),
        firstName: insertUpdateState.userGroupDescription,
        lastName: "",
        name: "",
        initials: insertUpdateState.initials,
        profileId: null,
        profileDescription: "",
        superiorIdGuid: insertUpdateState.superiorIdGuid,
        email: insertUpdateState.email,
        teamId: selectedTeamBranchListValue.branchId,
        teamDescription: selectedTeamBranchListValue.branchDescription,
        functionId: "",
        functionDescription: "",
        areaId: selectedDepartmentBranchListValue.branchId,
        areaDescription: selectedDepartmentBranchListValue.branchDescription,
        phone: "",
        mobile: "",
        typistIdGuid: TPGlobal.currentUserGuid,
        calendarId: insertUpdateState.calendarId,
        calendarDescription: "",
        timeZoneId: insertUpdateState.timeZoneId,
        scheduleId: insertUpdateState.scheduleId,
        scheduleDescription: "",
        authenticationMethodId: "",
        microsoftIdentity: "",
        defaultLanguage: insertUpdateState.defaultLanguage,
        maxSimultaneousChat: null,
        isActive: insertUpdateState.isActive,
        isSystemRecord: false,
      },
    };

    if (insertUpdateState.groupType === UserGroupTypesEnum.rotating) {
      recordInputDTO.finishPendingCaseBeforeRecivingNewOne = false;
      recordInputDTO.priorityWeight = 1;
      recordInputDTO.topSearchCoefficient = 1;
      recordInputDTO.prioritizeRelatedCases = false;
    }
    if (insertUpdateState.groupType === UserGroupTypesEnum.takeNext) {
      recordInputDTO.assignCasesToUsersLogged = false;
      recordInputDTO.maximumCasesPerUser = null;
      recordInputDTO.minutesLogoutReassign = null;
    }

    let newInsertUpdateState = { ...insertUpdateState };
    let inputDTOGroupValidator = new GroupsInputDTOValidator(
      insertUpdateState.groupType as UserGroupTypesEnum
    );
    let hasError: boolean = false;
    let resultValidatorGroup = inputDTOGroupValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidatorGroup)) {
      if (resultValidatorGroup.minutesLogoutReassign) {
        newInsertUpdateState.rotatingUmbralErrorMessage =
          await TPI18N.GetResource(resultValidatorGroup.minutesLogoutReassign);
      } else {
        newInsertUpdateState.rotatingUmbralErrorMessage = "";
      }
      if (resultValidatorGroup.maximumCasesPerUser) {
        newInsertUpdateState.maxCaseUserErrorMessage = await TPI18N.GetResource(
          resultValidatorGroup.maximumCasesPerUser
        );
      } else {
        newInsertUpdateState.maxCaseUserErrorMessage = "";
      }
      if (resultValidatorGroup.priorityWeight) {
        newInsertUpdateState.priorityWeightErrorMessage =
          await TPI18N.GetResource(resultValidatorGroup.priorityWeight);
      } else {
        newInsertUpdateState.priorityWeightErrorMessage = "";
      }
      if (resultValidatorGroup.topSearchCoefficient) {
        newInsertUpdateState.topSearchCoefficientErrorMessage =
          await TPI18N.GetResource(resultValidatorGroup.topSearchCoefficient);
      } else {
        newInsertUpdateState.topSearchCoefficientErrorMessage = "";
      }
      hasError = true;
    }

    let inputDTOValidator = new UserInputDTOValidator(
      ValidationUserGroupModeEnum.group
    );
    let resultValidator = inputDTOValidator.validate(recordInputDTO.user);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      if (resultValidator.login) {
        newInsertUpdateState.loginErrorMessage = await TPI18N.GetResource(
          resultValidator.login
        );
      } else {
        newInsertUpdateState.loginErrorMessage = "";
      }
      if (resultValidator.firstName) {
        newInsertUpdateState.userGroupDescriptionErrorMessage =
          await TPI18N.GetResource(resultValidator.firstName);
      } else {
        newInsertUpdateState.userGroupDescriptionErrorMessage = "";
      }
      if (resultValidator.initials) {
        newInsertUpdateState.initialsErrorMessage = await TPI18N.GetResource(
          resultValidator.initials
        );
      } else {
        newInsertUpdateState.initialsErrorMessage = "";
      }
      if (resultValidator.email) {
        newInsertUpdateState.emailErrorMessage = await TPI18N.GetResource(
          resultValidator.email
        );
      } else {
        newInsertUpdateState.emailErrorMessage = "";
      }
      if (resultValidator.teamId) {
        newInsertUpdateState.teamIdErrorMessage = await TPI18N.GetResource(
          resultValidator.teamId
        );
      } else {
        newInsertUpdateState.teamIdErrorMessage = "";
      }
      if (resultValidator.timeZoneId) {
        newInsertUpdateState.timeZoneIdErrorMessage = await TPI18N.GetResource(
          resultValidator.timeZoneId
        );
      } else {
        newInsertUpdateState.timeZoneIdErrorMessage = "";
      }
      if (resultValidator.areaId) {
        newInsertUpdateState.departmentErrorMessage = await TPI18N.GetResource(
          resultValidator.areaId
        );
      } else {
        newInsertUpdateState.departmentErrorMessage = "";
      }

      if (resultValidator.calendarId) {
        newInsertUpdateState.calendarIdErrorMessage = await TPI18N.GetResource(
          resultValidator.calendarId
        );
      } else {
        newInsertUpdateState.calendarIdErrorMessage = "";
      }

      if (resultValidator.scheduleId) {
        newInsertUpdateState.scheduleIdErrorMessage = await TPI18N.GetResource(
          resultValidator.scheduleId
        );
      } else {
        newInsertUpdateState.scheduleIdErrorMessage = "";
      }
      if (resultValidator.defaultLanguage) {
        newInsertUpdateState.defaultLanguageErrorMessage =
          await TPI18N.GetResource(resultValidator.defaultLanguage);
      } else {
        newInsertUpdateState.defaultLanguageErrorMessage = "";
      }

      if (!insertUpdateState.addTaskToCaseOption) {
        newInsertUpdateState.addTaskToCaseOptionErrorMessage =
          await TPI18N.GetText(resourceSet, "InputDTOAddTaskToCaseOptionEmpty");
      }

      if (!insertUpdateState.groupType) {
        newInsertUpdateState.groupTypeErrorMessage = await TPI18N.GetText(
          resourceSet,
          "InputDTOGroupTypeEmpty"
        );
      }
      hasError = true;
    }
    if (hasError) {
      setInsertUpdateState(newInsertUpdateState);
      return;
    }

    // if (!insertUpdateState.addTaskToCaseOption) {
    //   newInsertUpdateState.addTaskToCaseOptionErrorMessage =
    //     await TPI18N.GetText(resourceSet, "InputDTOAddTaskToCaseOptionEmpty");
    //   setInsertUpdateState(newInsertUpdateState);
    //   return;
    // }
    if (!insertUpdateState.groupType) {
      newInsertUpdateState.groupTypeErrorMessage = await TPI18N.GetText(
        resourceSet,
        "InputDTOGroupTypeEmpty"
      );
      setInsertUpdateState(newInsertUpdateState);
      return;
    }

    if (realMode == "Insert") {
      await insertGroup(recordInputDTO);
    } else {
      await updateGroup(recordInputDTO);
    }
  };

  const insertGroup = async (inputDTO: GroupsInputDTO) => {
    let serviceClient = new GroupsService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertGroup(
        inputDTO,
        true,
        true,
        expectedCodes
      );
      if (responseRequest.responseResult) {
        callBackResult({
          result: "save_general_insert_and_change_mode",
          recordId: responseRequest.responseData.keyList[0].value,
          recordDescription: inputDTO.user.login,
        });
        //change mode
        setTitleLabel(await TPI18N.GetText(resourceSet, "TitleUpdateLabel"));
        setRealRecordId(responseRequest.responseData.keyList[0].value);
        setOriginalRecordDescription(
          inputDTO.user.firstName.length > 100
            ? inputDTO.user.firstName.substring(0, 100) + "..."
            : inputDTO.user.login
        );
        getById(responseRequest.responseData.keyList[0].value);
        setRealMode("Update");
      }

      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} insert ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} insert ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateGroup = async (inputDTO: GroupsInputDTO) => {
    let serviceClient = new GroupsService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.updateGroup(
        inputDTO,
        true,
        true,
        expectedCodes
      );
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} update ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} update ex`);
      setIsLoadingScreen(false);
    }
  };

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequenceGeneratorSequencesNameEnum.SQGROU
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  useEffect(() => {
    if (callBackSubtitle) {
      callBackSubtitle(`${subTitleLabel} ${(realMode as any) == "Insert" ? referenceId : (groupDescription ?? insertUpdateState?.login)} / ${insertUpdateState.userGroupDescription}`);
    }
  }, [subTitleLabel, groupDescription, insertUpdateState.login, insertUpdateState.userGroupDescription]);

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResourcesAndLoadGroupInfo();
    realMode == "Insert" && generalAutomaticId();
  }, []);
  return (
    <TPLoadingOverlay active={isLoadingScreen} wrapperStyle={{ padding: "0px"}}>
        <div>
          <TPStyledForm className="pb-3">
            <TPStyledInnerInputForm>
              <TPStyledSubtitle>{descriptionSectionLabel}</TPStyledSubtitle>
              <TPStyledFormRow>
                <TPTextBox
                  id="IdTextBox"
                  labelText={idLabel}
                  value={`${realMode == "Insert" ? referenceId : !!groupDescription ? groupDescription : insertUpdateState.login}`}
                  onChange={(e: any) => {}}
                  maxLength={50}
                  disabled
                />
                <TPTextBox
                  id="IdTextBox"
                  labelText={initialsLabel}
                  isMandatory={true}
                  value={insertUpdateState.initials}
                  onChange={(e: any) =>
                    handleInputTextChange("initials", e.target.value)
                  }
                  maxLength={10}
                  errorMessage={insertUpdateState.initialsErrorMessage}
                />
              </TPStyledFormRow>
              <TPStyledFormRow>
                <TPTextBox
                  id="IdTextBox"
                  labelText={userGroupDescriptionLabel}
                  isMandatory={true}
                  value={insertUpdateState.userGroupDescription}
                  onChange={(e: any) =>
                    handleInputTextChange(
                      "userGroupDescription",
                      e.target.value
                    )
                  }
                  maxLength={50}
                  errorMessage={
                    insertUpdateState.userGroupDescriptionErrorMessage
                  }
                />
              </TPStyledFormRow>
            </TPStyledInnerInputForm>
            <TPStyledInnerInputForm>
              <TPStyledSubtitle>{parametersSectionLabel}</TPStyledSubtitle>
              <TPStyledFormRow>
                <TPSelect
                  id="IdSelect"
                  onChange={(e: any) => {
                    handleInputTextChange("timeZoneId", e.target.value);
                  }}
                  dataSource={timezonesToSelect}
                  value={insertUpdateState.timeZoneId}
                  labelText={timezoneLabel}
                  isMandatory={true}
                  errorMessage={insertUpdateState.timeZoneIdErrorMessage}
                />
                <TPSelect
                  id="IdSelect"
                  onChange={(e: any) => {
                    handleInputTextChange("calendarId", e.target.value);
                  }}
                  dataSource={calendarsToSelect}
                  value={insertUpdateState.calendarId}
                  labelText={calendarLabel}
                  isMandatory={true}
                  errorMessage={insertUpdateState.calendarIdErrorMessage}
                />
              </TPStyledFormRow>
              <TPStyledFormRow>
                <TPSelect
                  id="IdSelect"
                  onChange={(e: any) => {
                    handleInputTextChange("scheduleId", e.target.value);
                  }}
                  dataSource={schedulesToSelect}
                  value={insertUpdateState.scheduleId}
                  labelText={scheduleLabel}
                  isMandatory={true}
                  errorMessage={insertUpdateState.scheduleIdErrorMessage}
                />
                <TPSelect
                  id="IdSelect"
                  onChange={(e: any) => {
                    handleInputTextChange(
                      "defaultLanguage",
                      e.target.value
                    );
                  }}
                  isMandatory={true}
                  dataSource={languagesToSelect}
                  value={insertUpdateState.defaultLanguage}
                  labelText={defaultLanguageLabel}
                  errorMessage={
                    insertUpdateState.defaultLanguageErrorMessage
                  }
                />
              </TPStyledFormRow>
              <TPStyledFormRow>
                <TPTextBox
                  id="IdTextBox"
                  labelText={emailLabel}
                  value={insertUpdateState.email}
                  onChange={(e: any) =>
                    handleInputTextChange("email", e.target.value)
                  }
                  maxLength={450}
                  errorMessage={insertUpdateState.emailErrorMessage}
                />
                <TPSelect
                  id="IdSelect"
                  onChange={(e: any) => {
                    handleInputTextChange("superiorIdGuid", e.target.value);
                  }}
                  dataSource={superiorsToSelect}
                  value={insertUpdateState.superiorIdGuid}
                  labelText={superiorLabel}
                  errorMessage={
                    insertUpdateState.superiorIdGuidErrorMessage
                  }
                />
              </TPStyledFormRow>
              <TPStyledFormRow>
                <div style={{ width: "100%" }}>
                  <TPBranchSelection2
                    labelText={teamsLabel}
                    disabledTextBox={false}
                    treeId={teamTreeJson.TreeCode}
                    modalAcceptLabel={modalAcceptLabel}
                    modalCancelLabel={modalCancelLabel}
                    modalSelectedBranchLabel={modalSelectedBranchLabel}
                    modalTitle={modalTitleLabel}
                    mustSelectLastLevelBranch={true}
                    value={selectedTeamBranchListValue}
                    onChange={(
                      branchId: string,
                      branchDescription: string,
                      branchHierachyDescription: string
                    ) => {
                      handleOnTeamBranchChange(
                        branchId,
                        branchDescription,
                        branchHierachyDescription
                      );
                    }}
                    emptyLabel={emptyLabel}
                    errorMessage={insertUpdateState.teamIdErrorMessage}
                    onIsLoadingProgress={(value: boolean) => {
                      setIsLoadingScreen(value);
                    }}
                    loadChildsFromThisParent={
                      teamTreeJson.ParentCode.startsWith("[Root:")
                        ? ""
                        : teamTreeJson.ParentCode
                    }
                  ></TPBranchSelection2>
                </div>
                <div style={{ width: "100%" }}>
                  <TPBranchSelection2
                    labelText={departmentLabel}
                    disabledTextBox={false}
                    treeId={departmentTreeJson.TreeCode}
                    modalAcceptLabel={modalAcceptLabel}
                    modalCancelLabel={modalCancelLabel}
                    modalSelectedBranchLabel={modalSelectedBranchLabel}
                    modalTitle={modalTitleLabel}
                    mustSelectLastLevelBranch={true}
                    value={selectedDepartmentBranchListValue}
                    onChange={(
                      branchId: string,
                      branchDescription: string,
                      branchHierachyDescription: string
                    ) => {
                      handleOnDepartmentBranchChange(
                        branchId,
                        branchDescription,
                        branchHierachyDescription
                      );
                    }}
                    emptyLabel={emptyLabel}
                    errorMessage={insertUpdateState.departmentErrorMessage}
                    onIsLoadingProgress={(value: boolean) => {
                      setIsLoadingScreen(value);
                    }}
                    loadChildsFromThisParent={
                      departmentTreeJson.ParentCode.startsWith("[Root:")
                        ? ""
                        : departmentTreeJson.ParentCode
                    }
                  ></TPBranchSelection2>
                </div>
              </TPStyledFormRow>
              <TPStyledFormRow>
                <TPSelect
                  id="IdSelect"
                  isMandatory={true}
                  onChange={(e: any) => {
                    handleInputTextChange("groupType", e.target.value);
                  }}
                  dataSource={groupTypeToSelect}
                  value={insertUpdateState.groupType}
                  labelText={groupTypeLabel}
                  errorMessage={insertUpdateState.groupTypeErrorMessage}
                />
              </TPStyledFormRow>
            </TPStyledInnerInputForm>
            <TPStyledInnerInputForm>
              {(insertUpdateState.groupType === UserGroupTypesEnum.rotating ||
                insertUpdateState.groupType === UserGroupTypesEnum.takeNext) && (
                  <TPStyledSubtitle>{parametersGroupTypeSectionLabel}</TPStyledSubtitle>
              )}
            </TPStyledInnerInputForm>
            <TPStyledInnerInputForm>
              {insertUpdateState.groupType === UserGroupTypesEnum.rotating && (
                <>
                  <TPStyledFormRow>
                    <TPCheckBox
                      id="IdCheckBox"
                      checked={insertUpdateState.assigToLoggedUser}
                      labelText={assignCasesToUserLoggedLabel}
                      onChange={(e: any) =>
                        handleCheckBoxChange("assigToLoggedUser")
                      }
                    ></TPCheckBox>
                  </TPStyledFormRow>
                  <TPStyledFormRow>
                    <TPCheckBox
                      id="IdCheckBox"
                      checked={insertUpdateState.rotatingPreferredCustomer}
                      labelText={customerPreferredLabel}
                      onChange={(e: any) =>
                        handleCheckBoxChange("rotatingPreferredCustomer")
                      }
                    ></TPCheckBox>
                  </TPStyledFormRow>
                  <TPStyledFormRow>
                    <TPCheckBox
                      id="IdCheckBox"
                      checked={insertUpdateState.rotatingRecentAgent}
                      labelText={recentAgentLabel}
                      onChange={(e: any) =>
                        handleCheckBoxChange("rotatingRecentAgent")
                      }
                    ></TPCheckBox>
                  </TPStyledFormRow>
                  <TPStyledFormRow>
                    {insertUpdateState.assigToLoggedUser && (
                      <TPSelect
                        id="IdSelect"
                        isMandatory={true}
                        onChange={(e: any) => {
                          handleInputTextChange(
                            "rotatingUmbral",
                            e.target.value
                          );
                        }}
                        dataSource={rotatingUmbralToSelect}
                        value={insertUpdateState.rotatingUmbral}
                        labelText={rotatingUmbralLabel}
                        errorMessage={
                          insertUpdateState.rotatingUmbralErrorMessage
                        }
                      />
                    )}
                    <TPNumeric
                      id="IdTPNumeric"
                      isMandatory={true}
                      onChange={(value: number) => {
                        handleInputTextChange("maxCaseUser", value);
                      }}
                      value={
                        insertUpdateState.maxCaseUser
                          ? insertUpdateState.maxCaseUser
                          : null
                      }
                      labelText={maxCaseUserLabel}
                      errorMessage={
                        insertUpdateState.maxCaseUserErrorMessage
                      }
                      fixedDecimal={0}
                      maxLength={2}
                    ></TPNumeric>
                  </TPStyledFormRow>
                </>
              )}
              {insertUpdateState.groupType === UserGroupTypesEnum.takeNext && (
                <>
                  <TPStyledFormRow>
                    <TPCheckBox
                      id="IdCheckBox"
                      checked={insertUpdateState.finishPendingCase}
                      labelText={finishPendingCaseBeforeLabel}
                      onChange={(e: any) =>
                        handleCheckBoxChange("finishPendingCase")
                      }
                    ></TPCheckBox>
                  </TPStyledFormRow>
                  <TPStyledFormRow>
                    <TPSelect
                      id="IdSelect"
                      isMandatory={true}
                      onChange={(e: any) => {
                        handleInputTextChange(
                          "priorityWeight",
                          e.target.value
                        );
                      }}
                      dataSource={priorityWeightToSelect}
                      value={insertUpdateState.priorityWeight}
                      labelText={priorityWeightLabel}
                      errorMessage={
                        insertUpdateState.priorityWeightErrorMessage
                      }
                    />
                  </TPStyledFormRow>
                  {(insertUpdateState.preferredCustomer ||
                    insertUpdateState.prioritizeRelatedCases ||
                    insertUpdateState.recentAgent) && (
                      <TPStyledFormRow>
                        <TPSelect
                            id="IdSelect"
                            isMandatory={true}
                            onChange={(e: any) => {
                              handleInputTextChange(
                                "topSearchCoefficient",
                                e.target.value
                              );
                            }}
                            dataSource={topSearchCoefficientToSelect}
                            value={insertUpdateState.topSearchCoefficient}
                            labelText={topSearchCoefficientLabel}
                            errorMessage={
                              insertUpdateState.topSearchCoefficientErrorMessage
                            }
                          />
                      </TPStyledFormRow>
                  )}
                </>
              )}
            </TPStyledInnerInputForm>
            {insertUpdateState.groupType === UserGroupTypesEnum.takeNext && (
              <TPStyledInnerInputForm>
                <TPStyledFormRow>
                    <div className="input-group-append">
                      <span className="tpcircle-text">1</span>
                    </div>
                    <TPCheckBox
                        id="IdCheckBox"
                        checked={insertUpdateState.preferredCustomer}
                        labelText={customerPreferredLabel}
                        onChange={(e: any) =>
                          handleCheckBoxChange("preferredCustomer")
                        }
                      ></TPCheckBox>
                </TPStyledFormRow>
                <TPStyledFormRow>
                     <div className="input-group-append">
                      <span className="tpcircle-text">2</span>
                    </div>
                    <TPCheckBox
                        id="IdCheckBox"
                        checked={insertUpdateState.prioritizeRelatedCases}
                        labelText={relatedCasesLabel}
                        onChange={(e: any) =>
                          handleCheckBoxChange("prioritizeRelatedCases")
                        }
                      ></TPCheckBox>
                </TPStyledFormRow>
                <TPStyledFormRow>
                  <div className="input-group-append">
                    <span className="tpcircle-text">3</span>
                  </div>
                  <TPCheckBox
                    id="IdCheckBox"
                    checked={insertUpdateState.recentAgent}
                    labelText={recentAgentLabel}
                    onChange={(e: any) =>
                      handleCheckBoxChange("recentAgent")
                    }
                  ></TPCheckBox>
                </TPStyledFormRow>
              </TPStyledInnerInputForm>
            )}
            <TPStyledInnerInputForm>
              <TPStyledFormRow>
                  <TPCheckBox
                      id="IdCheckBox"
                      checked={insertUpdateState.isActive}
                      labelText={isActiveLabel}
                      onChange={(e: any) => handleCheckBoxChange("isActive")}
                    ></TPCheckBox>
                </TPStyledFormRow>
            </TPStyledInnerInputForm>
            <TPStyledFormButtonFooter>
              <TPOptionButtonPairStyled
                cancelLabel={cancelButtonLabel}
                confirmLabel={saveButtonLabel}
                onCancel={handleCancelButtonClick}
                onConfirm={handleOkButtonClick}
                confirmDisabled={isValid()}
              />
            </TPStyledFormButtonFooter>
          </TPStyledForm>
        </div>
        <div className="row">
          <div className="col-10">
            <TPPageSection>
              <div className="row">
                <div className="col-4 d-none">
                  <div className="form-group">
                    <TPSelect
                      id="IdSelect"
                      onChange={(e: any) => {
                        handleInputTextChange(
                          "addTaskToCaseOption",
                          e.target.value
                        );
                      }}
                      dataSource={addTaskToCaseOptionToSelect}
                      value={insertUpdateState.addTaskToCaseOption}
                      labelText={addTaskToCaseOptionLabel}
                      errorMessage={
                        insertUpdateState.addTaskToCaseOptionErrorMessage
                      }
                    />
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>
      </TPLoadingOverlay>
  );
};

export default GroupsInsertUpdate;
