import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { FC, useEffect, useRef, useState } from "react";
import "../../Assets/styles.css";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ScriptAdditionalData, ScriptStep } from "@/models/Scripts/ScriptModel";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { ScriptAdditionalDataStepTable } from "../../Components/ScriptAdditionalDataStepTable";
import { useScriptsLabels } from "../../Assets/labelling";

interface ScriptStepsTab {
  chosenStepCallback: (step?: ScriptStep) => void;
  defineAdditionalDataCallback: (step?: ScriptStep, data?: ScriptAdditionalData | null) => void;
  steps: ScriptStep[];
  visible: boolean;
  onNextStepChange: (nextStepId: string, stepId: string) => void;
  onStepDelete: (stepId: string) => void;
  onAdditionalDataDelete: (stepId: string, dataId: string) => void;
}

export const ScriptStepsTab: FC<ScriptStepsTab> = function ({
  steps,
  chosenStepCallback,
  visible,
  defineAdditionalDataCallback,
  onNextStepChange,
  onStepDelete,
  onAdditionalDataDelete,
}) {
  const [query, setQuery] = useState("");
  const [visibleMenu, setVisibleMenu] = useState<string | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const { labels } = useScriptsLabels();

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setVisibleMenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return visible ? (
    <TPLoadingOverlay>
      <div
        id="script-management-steps-overview"
        className="script-tab"
        style={{ width: "100%", gap: "32px" }}
      >
        <div className="script-form-row" style={{ justifyContent: "space-between" }}>
          <div>
            <TPTextBox
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
              value={query}
              icon={TPIconTypes.search}
              withIcon
              placeholder={labels.Search}
            />
          </div>
          <TPButton
            onClick={() => chosenStepCallback()}
            isDesignSystem
            style={{
              backgroundColor: "white",
              color: "purple",
              paddingLeft: "16px",
              paddingRight: "16px",
              height: "32px",
            }}
          >
            {labels.NewStep}
          </TPButton>
        </div>
        <div>
          <table style={{ width: "100%" }}>
            <tr>
              <th style={{ width: "10%" }}>{labels.Actions}</th>
              <th style={{ width: "10%" }}>{labels.Order}</th>
              <th style={{ width: "30%", textAlign: "start" }}>{labels.Description}</th>
              <th style={{ width: "35%", textAlign: "start" }}>{labels.AdditionalData}</th>
              <th style={{ width: "15%", textAlign: "center" }}>{labels.DefaultNextStep}</th>
            </tr>
            {steps
              .filter((step) =>
                query.trim().length > 0
                  ? String(step.order).toLowerCase().includes(query.trim().toLowerCase()) ||
                    String(step.description).toLowerCase().includes(query.trim().toLowerCase())
                  : true
              )
              .filter((step) => step.order >= 0)
              .map((step, idx) => (
                <tr className="scripts-steps-table-row" key={step.id}>
                  <td>
                    {step.order > 0 && (
                      <button
                        style={{ border: "none", background: "none" }}
                        onClick={() => setVisibleMenu(visibleMenu !== step.id ? step.id : null)}
                      >
                        <TPIcon iconType={TPIconTypes.moreVert} />
                        {visibleMenu === step.id && (
                          <div className="steps-list-menu" ref={menuRef}>
                            <button
                              type="button"
                              className="steps-list-menu-option"
                              onClick={(e) => {
                                e.stopPropagation();
                                onStepDelete(step.id);
                              }}
                            >
                              {labels.Delete}
                              <TPIcon iconType={TPIconTypes.delete} style={{ fontSize: "24px" }} />
                            </button>
                          </div>
                        )}
                      </button>
                    )}
                  </td>
                  <td>
                    {step.order == 0 ? (
                      <label>{step.order}</label>
                    ) : (
                      <button
                        type="button"
                        onClick={() => chosenStepCallback(step)}
                        style={{
                          color: "purple",
                          background: "none",
                          border: "none",
                        }}
                      >
                        <b>
                          <u>{step.order}</u>
                        </b>
                      </button>
                    )}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {step.order == 0 ? (
                      <b style={{ color: "#CC0000" }}>{step.description}</b>
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: step.description }} />
                    )}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {step.order == 0 ? (
                      labels.Pending
                    ) : (
                      <ScriptAdditionalDataStepTable
                        defineAdditionalDataCallback={(data) =>
                          defineAdditionalDataCallback(step, data)
                        }
                        key={step.id}
                        data={step.additionalData}
                        onAdditionalDataDelete={(dataId) => onAdditionalDataDelete(step.id, dataId)}
                      />
                    )}
                  </td>
                  <td>
                    <TPSelect
                      dataSource={[
                        { key: "", value: "--" },
                        ...steps
                          .filter((st) => st.order === -1 || st.order > step.order)
                          .filter((st) => (step.order == 0 ? st.order != -1 : true))
                          .map((st) => {
                            return {
                              key: st.id,
                              value: st.order == -1 ? labels.End : String(st.order),
                            };
                          }),
                      ]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onNextStepChange(e.target.value, step.id)
                      }
                      value={step.defaultNextStepId}
                    />
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
    </TPLoadingOverlay>
  ) : (
    <></>
  );
};
