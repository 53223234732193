import React, { useEffect, useMemo, useState } from "react";
import useTranslations from "../hooks/useTranslations";
import TPVerticalTabs from "@/components/TPTabs/TPVerticalTabs";
import TPDescription from "@/components/bootstrap/forms/TPDescription/TPDescription";
import { useConfiguration } from "../context/ConfigurationContext";
import TabLabelWithSwitch from "../common/TabLabelWithSwitch/TabLabelWithSwitch";
import { TPFieldContainer } from "../common/TPFieldContainer/TPFieldContainer";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPCollapsableSection from "@/components/bootstrap/components/collapsable/TPCollapsableSection";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ParametersService } from "@/services/ParametersService";
import {
    SystemParametersEnum,
    TPActiveOptions,
    TPButtonTypes,
    TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { useCopilot } from "@/contexts/TPCopilotContext";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPSwitch from "@/components/bootstrap/forms/switch/TPSwitch";
import { v4 as uuidv4 } from "uuid";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import { useMenuReload } from "@/contexts/MenuReloadContext";
import TPGlobal from "../../../helpers/TPGlobal";
import { TPConfirmationModal } from "../../../components/TPModalAlert/ConfirmationModal/TPConfirmationModal";
import { TPI18N } from "../../../services/I18nService";

const COMPONENT_NAME = "ModulesComponent.tsx";

enum confirmType {
    yes,
    no,
    cancel
}

class TypeName {
    public static eventsManagerActive = "eventsManagerActive"
}

const ModulesComponent: React.FC = () => {
    const RESOURCE_SET = "ConfigurationGeneralComponent";


    const [activeConfirm, setActiveConfirm] = useState(false);
    const [tabKeyConfigActive, setTabKeyConfigActive] = useState("eventsManagerActive");
    const [msgConfirmLabel, setMsgConfirmLabel] = useState("EventsManagerActiveConfirmLabel");
    const [eventsManagerActiveConfirmLabel, setEventsManagerActiveConfirmLabel] = useState("");

    const { changeValueForKey, keyValues, saveFieldsConfiguration } =
        useConfiguration();
    const { setCopilotEnable } = useCopilot();
    const { triggerMenuReload } = useMenuReload();

    const initialText = useMemo(
        () => ({
            casesTitle: "CasesTitle",
            casesDescriptions: "CasesDescriptions",
            eventManagerTitle: "EventManagerTitle",
            eventManagerDescriptions: "EventManagerDescriptions",
            kbWikiTitle: "KbWikiTitle",
            kbWikiDescriptions: "KbWikiDescriptions",
            timeTrackingTitle: "TimeTrackingTitle",
            timeTrackingDescriptions: "TimeTrackingDescriptions",
            mailReaderTitle: "MailReaderTitle",
            mailReaderDescriptions: "MailReaderDescriptions",
            activeLabel: "ActiveLabel",
            inboundEmailCaseNumberPatternLabel: "InboundEmailCaseNumberPatternLabel",
            MailReaderMaxLengthAddressLabel: "MailReaderMaxLengthAddressLabel",
            maximumLengthMailBodyLabel: "MaximumLengthMailBodyLabel",
            copilotURLKBLabel: "CopilotURLKBLabel",
            timeTrackingPasswordLabel: "TimeTrackingPasswordLabel",
            timeTrackingUserLabel: "TimeTrackingUserLabel",
            timeTrackingAPIKeyLabel: "TimeTrackingAPIKeyLabel",
            taskEscalationTitle: "TaskEscalationTitle",
            taskEscalationDescription: "TaskEscalationDescription",
            escalatedTaskTermLabel: "EscalatedTaskTermLabel",
            escalatedTaskTermUnitLabel: "EscalatedTaskTermUnitLabel",
            topRowCountCasesTitle: "TopRowCountCasesTitle",
            topRowCountCasesDescription: "TopRowCountCasesDescription",
            topViewLabel: "TopViewLabel",
            topExportLabel: "TopExportLabel",
            topViewCustomerHistoryLabel: "TopViewCustomerHistoryLabel",
            parameterShortenCommentsTitle: "ParameterShortenCommentsTitle",
            parameterShortenCommentsDescription:
                "ParameterShortenCommentsDescription",
            charactersNumberLabel: "CharactersNumberLabel",
            cloneCaseTitle: "CloneCaseTitle",
            cloneCaseDescription: "CloneCaseDescription",
            slaRecalationTitle: "SlaRecalationTitle",
            slaRecalationDescription: "SlaRecalationDescription",
            caseResponseTaskOrderTitle: "CaseResponseTaskOrderTitle",
            caseResponseTaskOrderDescription: "CaseResponseTaskOrderDescription",
            taskOrderLabel: "TaskOrderLabel",
            privateCasesTastksTitle: "PrivateCasesTastksTitle",
            privateCasesTastksDescription: "PrivateCasesTastksDescription",
            customStatusLabel: "CustomStatusLabel",
            customNameLabel: "CustomNameLabel",
            customTypeLabel: "CustomTypeLabel",
            statusTypeEmpty: "statusTypeEmpty",
            statusNameEmpty: "statusNameEmpty",
            displayTimeLabel: "DisplayTimeLabel",
            saveButton: "SaveButton",
        }),
        []
    );

    const translationsText = useTranslations(
        initialText,
        RESOURCE_SET,
        COMPONENT_NAME
    );


    const Confirm = async function (mode: confirmType) {
      
        if (tabKeyConfigActive == TypeName.eventsManagerActive) {
            setMsgConfirmLabel(eventsManagerActiveConfirmLabel);
        } else {
            setMsgConfirmLabel(eventsManagerActiveConfirmLabel);
        }

        if ((tabKeyConfigActive && tabKeyConfigActive != "" && mode == confirmType.yes)) {
            const newValue = keyValues[tabKeyConfigActive]?.value === "1" ? "0" : "1";
            changeValueForKey(tabKeyConfigActive, newValue);
            await saveFieldsConfiguration({ [tabKeyConfigActive]: newValue });
            triggerMenuReload();
        }

        setActiveConfirm(false);
    }

    const loadUtilsResources = async () => {
        setEventsManagerActiveConfirmLabel(await TPI18N.GetText(RESOURCE_SET, "EventsManagerActiveConfirmLabel"))
    }

    const tabConfigs = useMemo(
        () => [
            {
                key: "casesModuleActive",
                title: translationsText.casesTitle,
            },
            {
                key: "eventsManagerActive",
                title: translationsText.eventManagerTitle,
            },
            {
                key: "copilotEnable",
                title: translationsText.kbWikiTitle,
                onToggleCallback: () =>
                    setCopilotEnable(keyValues["copilotEnable"]?.value !== "1"),
            },
            {
                key: "timeTrackingActive",
                title: translationsText.timeTrackingTitle,
            },
            {
                key: "mailReaderModuleActive",
                title: translationsText.mailReaderTitle,
            },
        ],
        [translationsText]
    );

    const tabLabels = useMemo(
        () =>
            tabConfigs.map(({ key, title, onToggleCallback }) => (
                <TabLabelWithSwitch
                    key={key}
                    title={title}
                    isActive={keyValues[key]?.value === "1"}
                    onToggle={async () => {
                        setTabKeyConfigActive(key);

                        const newValue = keyValues[key]?.value === "1" ? "0" : "1";
                        if (key != TypeName.eventsManagerActive || keyValues[key]?.value === "0") {
                            changeValueForKey(key, newValue);
                            await saveFieldsConfiguration({ [key]: newValue });
                            triggerMenuReload()
                            if (onToggleCallback) {
                                onToggleCallback();
                            }
                        } else {
                            setActiveConfirm(true);
                        }


                    }}
                    labelText={translationsText.activeLabel}
                />
            )),
        [tabConfigs, keyValues, translationsText, changeValueForKey]
    );

    const tabContent = useMemo(
        () => [
            <CasesConfiguration key="cases" translationsText={translationsText} />,
            <EventManagerConfiguration
                key="eventManager"
                translationsText={translationsText}
            />,
            <KBWikiConfiguration key="kbWiki" translationsText={translationsText} />,
            <TimeTrackingConfiguration
                key="timeTracking"
                translationsText={translationsText}
            />,
            <MailReaderConfiguration
                key="mailReader"
                translationsText={translationsText}
            />,
        ],
        [translationsText]
    );

    useEffect(() => {
        loadUtilsResources().then();
    }, [])

     useEffect(() => {
         if (tabKeyConfigActive == TypeName.eventsManagerActive) {
             setMsgConfirmLabel(eventsManagerActiveConfirmLabel);
         } else {
             setMsgConfirmLabel(eventsManagerActiveConfirmLabel);
         }
     }, [eventsManagerActiveConfirmLabel])


    return <>
        <TPConfirmationModal
            message={msgConfirmLabel}
            onAccept={() => Confirm(confirmType.yes)}
            onCancel={() => Confirm(confirmType.cancel)}
            hideTitle
            visible={activeConfirm}
        />
        <TPVerticalTabs tabLabels={tabLabels} tabContent={tabContent} />;
    </>
};



export default ModulesComponent;

const CasesConfiguration: React.FC<{ translationsText: any }> = ({
    translationsText,
}) => {
    const { changeValueForKey, keyValues, errorState, someChange, handleSave } =
        useConfiguration();
    const [unitTermList, setUnitTermList] = useState<TPKeyValue[]>([]);

    const getUnitTermFromParameters = async () => {
        let parametersService = new ParametersService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest =
                await parametersService.getByParentIdAndFilterIsActive(
                    SystemParametersEnum.TEUNI,
                    TPActiveOptions.ACTIVE.toString(),
                    false,
                    true,
                    expectedCodes
                );

            let newTypeKeyValueList: Array<TPKeyValue> = responseRequest.map(
                function (item) {
                    return {
                        key: item.id.toLowerCase(),
                        value: item.localizedDescription,
                    };
                }
            );
            newTypeKeyValueList.unshift({ key: "", value: "--" });
            setUnitTermList(newTypeKeyValueList);
        } catch (error) {
            TPLog.Log(
                `Error ${COMPONENT_NAME} getUnitTermFromParameters ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${COMPONENT_NAME} getUnitTermFromParameters ex`);
            return;
        }
    };

    useEffect(() => {
        getUnitTermFromParameters();
    }, []);

    return (
        <div className="col">
            <TPDescription text={translationsText.casesDescriptions} />

            {keyValues["casesModuleActive"]?.value === "1" && (
                <>
                    <TPCollapsableSection
                        title={translationsText.taskEscalationTitle}
                        description={translationsText.taskEscalationDescription}
                    >
                        <>
                            <TPFieldContainer>
                                <TPNumeric
                                    id="IdTPNumeric"
                                    labelText={translationsText.escalatedTaskTermLabel}
                                    isMandatory={true}
                                    value={keyValues["escalatedTaskTerm"]?.value}
                                    onChange={(e: any) =>
                                        changeValueForKey("escalatedTaskTerm", e)
                                    }
                                    errorMessage={errorState.escalatedTaskTermErrorMessage}
                                />
                            </TPFieldContainer>

                            <TPFieldContainer>
                                <TPSelect
                                    id="IdSelect"
                                    labelText={translationsText.escalatedTaskTermUnitLabel}
                                    isMandatory={true}
                                    value={keyValues["escalatedTaskTermUnit"]?.value}
                                    onChange={(e: any) =>
                                        changeValueForKey("escalatedTaskTermUnit", e.target.value)
                                    }
                                    errorMessage={errorState.escalatedTaskTermUnitErrorMessage}
                                    dataSource={unitTermList}
                                />
                            </TPFieldContainer>
                        </>
                    </TPCollapsableSection>

                    <TPCollapsableSection
                        title={translationsText.topRowCountCasesTitle}
                        description={translationsText.topRowCountCasesDescription}
                    >
                        <>
                            <TPFieldContainer>
                                <TPNumeric
                                    id="IdTPNumeric"
                                    labelText={translationsText.topViewLabel}
                                    isMandatory={true}
                                    value={keyValues["topView"]?.value}
                                    onChange={(e: any) => changeValueForKey("topView", e)}
                                    errorMessage={errorState.topViewErrorMessage}
                                />
                            </TPFieldContainer>

                            <TPFieldContainer>
                                <TPNumeric
                                    id="IdTPNumeric"
                                    labelText={translationsText.topExportLabel}
                                    isMandatory={true}
                                    value={keyValues["topExport"]?.value}
                                    onChange={(e: any) => changeValueForKey("topExport", e)}
                                    errorMessage={errorState.topExportErrorMessage}
                                />
                            </TPFieldContainer>

                            <TPFieldContainer>
                                <TPNumeric
                                    id="IdTPNumeric"
                                    labelText={translationsText.topViewCustomerHistoryLabel}
                                    isMandatory={true}
                                    value={keyValues["topViewCustomerHistory"]?.value}
                                    onChange={(e: any) =>
                                        changeValueForKey("topViewCustomerHistory", e)
                                    }
                                    errorMessage={errorState.topViewCustomerHistoryErrorMessage}
                                />
                            </TPFieldContainer>
                        </>
                    </TPCollapsableSection>

                    <TPCollapsableSection
                        title={translationsText.parameterShortenCommentsTitle}
                        description={translationsText.parameterShortenCommentsDescription}
                    >
                        <TPFieldContainer>
                            <TPNumeric
                                id="IdTPNumeric"
                                labelText={translationsText.charactersNumberLabel}
                                isMandatory={true}
                                value={keyValues["characterNumberCutStringComments"]?.value}
                                onChange={(e: any) =>
                                    changeValueForKey("characterNumberCutStringComments", e)
                                }
                                errorMessage={
                                    errorState.characterNumberCutStringCommentsErrorMessage
                                }
                            />
                        </TPFieldContainer>
                    </TPCollapsableSection>

                    <TPCollapsableSection
                        title={translationsText.cloneCaseTitle}
                        description={translationsText.cloneCaseDescription}
                        enableSwitch
                        switchState={keyValues["cloneCaseWithTodayDate"]?.value === "1"}
                        switchLabel={translationsText.activeLabel}
                        onSwitchChange={() => {
                            changeValueForKey(
                                "cloneCaseWithTodayDate",
                                keyValues["cloneCaseWithTodayDate"]?.value === "1" ? "0" : "1"
                            );
                        }}
                    />

                    <TPCollapsableSection
                        title={translationsText.slaRecalationTitle}
                        description={translationsText.slaRecalationDescription}
                        enableSwitch
                        switchState={keyValues["reclassifyMovesLimitDate"]?.value === "1"}
                        switchLabel={translationsText.activeLabel}
                        onSwitchChange={() => {
                            changeValueForKey(
                                "reclassifyMovesLimitDate",
                                keyValues["reclassifyMovesLimitDate"]?.value === "1" ? "0" : "1"
                            );
                        }}
                    />

                    <TPCollapsableSection
                        title={translationsText.caseResponseTaskOrderTitle}
                        description={translationsText.caseResponseTaskOrderDescription}
                    >
                        <TPFieldContainer>
                            <TPFieldContainer>
                                <TPSelect
                                    id="IdSelect"
                                    labelText={translationsText.taskOrderLabel}
                                    isMandatory={true}
                                    value={keyValues["taskOrder"]?.value}
                                    onChange={(e: any) =>
                                        changeValueForKey("taskOrder", e.target.value)
                                    }
                                    errorMessage={errorState.taskOrderErrorMessage}
                                    dataSource={[
                                        { key: "", value: "..." },
                                        {
                                            key: "1",
                                            value: "Ascending",
                                        },
                                        {
                                            key: "0",
                                            value: "Descending",
                                        },
                                    ]}
                                />
                            </TPFieldContainer>
                        </TPFieldContainer>
                    </TPCollapsableSection>

                    <TPCollapsableSection
                        title={translationsText.privateCasesTastksTitle}
                        description={translationsText.privateCasesTastksDescription}
                        enableSwitch
                        switchState={keyValues["privateCasesAndTasks"]?.value === "1"}
                        switchLabel={translationsText.activeLabel}
                        onSwitchChange={() => {
                            changeValueForKey(
                                "privateCasesAndTasks",
                                keyValues["privateCasesAndTasks"]?.value === "1" ? "0" : "1"
                            );
                        }}
                    />

                    <TPButton
                        id="IdButton"
                        onClick={handleSave}
                        type={TPButtonTypes.primary}
                        className="fixed-save-button"
                        disabled={!someChange}
                        style={
                            !someChange
                                ? {
                                    backgroundColor: "#e6e6e6",
                                    borderColor: "#efefef",
                                    color: "#989898",
                                    cursor: "not-allowed",
                                }
                                : {}
                        }
                    >
                        {translationsText.saveButton}
                    </TPButton>
                </>
            )}
        </div>
    );
};

const EventManagerConfiguration: React.FC<{ translationsText: any }> = ({
    translationsText,
}) => {
    return (
        <div className="col">
            <TPDescription text={translationsText.eventManagerDescriptions} />
        </div>
    );
};

const KBWikiConfiguration: React.FC<{ translationsText: any }> = ({
    translationsText,
}) => {
    const { changeValueForKey, keyValues, errorState, handleSave, someChange } =
        useConfiguration();

    return (
        <div className="col">
            <TPDescription text={translationsText.kbWikiDescriptions} />

            {keyValues["copilotEnable"]?.value === "1" && (
                <TPFieldContainer>
                    <TPTextBox
                        id="IdTextBox"
                        isMandatory={true}
                        onChange={(e: any) =>
                            changeValueForKey("copilotLinkToken", e.target.value)
                        }
                        value={keyValues["copilotLinkToken"]?.value}
                        labelText={translationsText.copilotURLKBLabel}
                        errorMessage={errorState.copilotLinkTokenErrorMessage}
                        isInvalidChars={false}
                    />

                    <TPButton
                        id="IdButton"
                        onClick={handleSave}
                        type={TPButtonTypes.primary}
                        className="fixed-save-button"
                        disabled={!someChange}
                        style={
                            !someChange
                                ? {
                                    backgroundColor: "#e6e6e6",
                                    borderColor: "#efefef",
                                    color: "#989898",
                                    cursor: "not-allowed",
                                }
                                : {}
                        }
                    >
                        {translationsText.saveButton}
                    </TPButton>
                </TPFieldContainer>
            )}
        </div>
    );
};

// interface Row {
//   id: string;
//   statusName: string;
//   statusType: string;
//   isActive: boolean;
// }

const TimeTrackingConfiguration: React.FC<{ translationsText: any }> = ({
    translationsText,
}) => {
    const {
        changeValueForKey,
        keyValues,
        /* errorState, */
        someChange,
        handleSave,
    } = useConfiguration();
    // const [rows, setRows] = useState<Row[]>([]);

    // useEffect(() => {
    //   const storedString = keyValues["customStatus"]?.value || "[]";
    //   try {
    //     const parsedRows: Row[] = JSON.parse(storedString);
    //     setRows(
    //       parsedRows.length > 0
    //         ? parsedRows
    //         : [
    //             {
    //               id: uuidv4().replaceAll("-", ""),
    //               statusName: "",
    //               statusType: "",
    //               isActive: true,
    //             },
    //           ]
    //     );
    //   } catch (error) {
    //     console.error("Failed to parse custom status string:", error);
    //     setRows([
    //       {
    //         id: uuidv4().replaceAll("-", ""),
    //         statusName: "",
    //         statusType: "",
    //         isActive: true,
    //       },
    //     ]);
    //   }
    // }, [keyValues]);

    // const updateRows = (updatedRows: Row[]) => {
    //   setRows(updatedRows);
    //   const stringRepresentation = JSON.stringify(updatedRows);
    //   changeValueForKey("customStatus", stringRepresentation);
    // };

    // const handleFieldChange = (id: string, field: keyof Row, value: any) => {
    //   updateRows(
    //     rows.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    //   );
    // };

    // const addRow = () => {
    //   updateRows([
    //     ...rows,
    //     {
    //       id: uuidv4().replaceAll("-", ""),
    //       statusName: "",
    //       statusType: "",
    //       isActive: true,
    //     },
    //   ]);
    // };

    // const deleteRow = (id: string) => {
    //   updateRows(rows.filter((row) => row.id !== id));
    // };

    useEffect(() => {
        keyValues["timeTrackingActive"]?.value !== "1" &&
            changeValueForKey("displayCaseAverageHandleTime", "0");
    }, [keyValues]);

    return (
        <div className="col">
            <TPDescription text={translationsText.timeTrackingDescriptions} />

            {keyValues["timeTrackingActive"]?.value === "1" && (
                <>
                    <TPFieldContainer>
                        <TPCheckBox
                            checked={keyValues["displayCaseAverageHandleTime"]?.value === "1"}
                            onChange={(e: any) => {
                                changeValueForKey(
                                    "displayCaseAverageHandleTime",
                                    e.target.checked ? "1" : "0"
                                );
                            }}
                            disabled={keyValues["timeTrackingActive"]?.value !== "1"}
                            labelText={translationsText.displayTimeLabel}
                        />
                    </TPFieldContainer>

                    {/* <div style={{ marginTop: 15 }}>
            <h3>{translationsText.customStatusLabel}</h3>
            {rows.map((row, idx) => (
              <div
                key={idx}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginBottom: "10px",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    width: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  {idx !== 0 && (
                    <TPButton
                      type={TPButtonTypes.icon}
                      onClick={() => deleteRow(row.id)}
                      icon={TPIconTypes.remove}
                    />
                  )}
                </div>

                <div style={{ flex: "1" }}>
                  <TPTextBox
                    id={`statusName-${row.id}`}
                    isMandatory={true}
                    onChange={(e: any) =>
                      handleFieldChange(row.id, "statusName", e.target.value)
                    }
                    value={row.statusName}
                    labelText={translationsText.customNameLabel}
                    errorMessage={!row.statusName ? translationsText.statusNameEmpty : ""}
                  />
                </div>

                <div style={{ flex: "1" }}>
                  <TPSelect
                    id={`statusType-${row.id}`}
                    labelText={translationsText.customTypeLabel}
                    isMandatory={true}
                    value={row.statusType}
                    onChange={(e: any) =>
                      handleFieldChange(row.id, "statusType", e.target.value)
                    }
                    dataSource={[
                      { key: "", value: "..." },
                      { key: "1", value: "=" },
                      { key: "2", value: "!=" },
                    ]}
                    errorMessage={!row.statusType ? translationsText.statusTypeEmpty : ""}
                  />
                </div>

                <div
                  style={{
                    width: "80px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TPSwitch
                    checked={row.isActive}
                    onChange={() => {
                      handleFieldChange(row.id, "isActive", !row.isActive);
                    }}
                    labelText={translationsText.ActiveLabel}
                  />
                </div>

                <div
                  style={{
                    width: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  {idx === rows.length - 1 && (
                    <TPButton
                      type={TPButtonTypes.icon}
                      onClick={addRow}
                      icon={TPIconTypes.addCircle}
                    />
                  )}
                </div>
              </div>
            ))}
          </div> */}

                    <TPButton
                        id="IdButton"
                        onClick={handleSave}
                        type={TPButtonTypes.primary}
                        className="fixed-save-button"
                        disabled={!someChange}
                        style={
                            !someChange
                                ? {
                                    backgroundColor: "#e6e6e6",
                                    borderColor: "#efefef",
                                    color: "#989898",
                                    cursor: "not-allowed",
                                }
                                : {}
                        }
                    >
                        {translationsText.saveButton}
                    </TPButton>
                </>
            )}
        </div>
    );
};

const MailReaderConfiguration: React.FC<{ translationsText: any }> = ({
    translationsText,
}) => {
    const { changeValueForKey, keyValues, errorState, someChange, handleSave } =
        useConfiguration();

    return (
        <div className="col">


            <TPDescription text={translationsText.mailReaderDescriptions} />

            {keyValues["mailReaderModuleActive"]?.value === "1" && (
                <>
                    <TPFieldContainer>
                        <TPTextBox
                            id="IdTextBox"
                            isMandatory={true}
                            onChange={(e: any) =>
                                changeValueForKey(
                                    "inboundEmailCaseNumberPattern",
                                    e.target.value
                                )
                            }
                            value={keyValues["inboundEmailCaseNumberPattern"]?.value}
                            labelText={translationsText.inboundEmailCaseNumberPatternLabel}
                            errorMessage={
                                errorState.inboundEmailCaseNumberPatternErrorMessage
                            }
                        />
                    </TPFieldContainer>

                    <TPFieldContainer>
                        <TPTextBox
                            id="IdTextBox"
                            isMandatory={true}
                            onChange={(e: any) =>
                                changeValueForKey("mailReaderMaxLengthAddress", e.target.value)
                            }
                            value={keyValues["mailReaderMaxLengthAddress"]?.value}
                            labelText={translationsText.MailReaderMaxLengthAddressLabel}
                            errorMessage={errorState.mailReaderMaxLengthAddressErrorMessage}
                        />
                    </TPFieldContainer>

                    <TPFieldContainer>
                        <TPTextBox
                            id="IdTextBox"
                            isMandatory={true}
                            onChange={(e: any) =>
                                changeValueForKey("maximumLengthMailBody", e.target.value)
                            }
                            value={keyValues["maximumLengthMailBody"]?.value}
                            labelText={translationsText.maximumLengthMailBodyLabel}
                            errorMessage={errorState.maximumLengthMailBodyErrorMessage}
                        />
                    </TPFieldContainer>

                    <TPButton
                        id="IdButton"
                        onClick={handleSave}
                        type={TPButtonTypes.primary}
                        className="fixed-save-button"
                        disabled={!someChange}
                        style={
                            !someChange
                                ? {
                                    backgroundColor: "#e6e6e6",
                                    borderColor: "#efefef",
                                    color: "#989898",
                                    cursor: "not-allowed",
                                }
                                : {}
                        }
                    >
                        {translationsText.saveButton}
                    </TPButton>
                </>
            )}
        </div>
    );
};
