import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { ClientePreferredAgentsModel } from "@/models/Client/ClientAdminViewModel";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { ClientService } from "@/services/ClientService";
import { FC, useEffect, useState } from "react";
import "../../styles/Styles.css";
import useLabels from "../../styles/labels";

interface CustomerPreferredAgentsTabProperties {
  subsidiaryId: number;
}

const CustomerPreferredAgentsTab: FC<CustomerPreferredAgentsTabProperties> =
  function ({ subsidiaryId }) {
    const { labels, labelsLoaded } = useLabels();
    const [loading, setLoading] = useState<boolean>(false);
    const [customerPreferredAgents, setCustomerPreferredAgents] = useState<ClientePreferredAgentsModel[]>([]);
    const [query, setQuery] = useState<string>("");
    const [filteredAgents, setFilteredAgents] = useState<ClientePreferredAgentsModel[]>([]);
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const loadPreferredAgents = function () {
      setLoading(true);
      ClientService.getPreferredAgents(subsidiaryId, false, false, [200])
        .then((response) => {
          if (response) {
            setCustomerPreferredAgents(response);
            setFilteredAgents(response);
          }
          setLoading(false);
        })
        .catch((error) => console.error(error));
    };

    useEffect(() => {
      if (subsidiaryId) loadPreferredAgents();
    }, []);

    useEffect(() => {
      if (query.trim().length > 0) {
        const lowercaseQuery = query.toLowerCase();
        setFilteredAgents([
          ...customerPreferredAgents.filter((agent) => {
            return (
              String(agent.id).toLowerCase().includes(lowercaseQuery) ||
              String(agent.idSubsidiary)
                .toLowerCase()
                .includes(lowercaseQuery) ||
              String(agent.agentName).toLowerCase().includes(lowercaseQuery) ||
              String(agent.agentLogin).toLowerCase().includes(lowercaseQuery) ||
              String(agent.idUser).toLowerCase().includes(lowercaseQuery) ||
              String(agent.insertDate).toLowerCase().includes(lowercaseQuery) ||
              String(agent.priority).toLowerCase().includes(lowercaseQuery)
            );
          }),
        ]);
      } else {
        setFilteredAgents(customerPreferredAgents);
      }
    }, [query]);

    return (
      <>
        <TPLoadingOverlay active={loading}>
          <div id="merge-customers-contacts-data-table">
            <DynamicTable
              id="merge-customers-preferred-agents"
              uppercaseActions
              data={filteredAgents.map(item => {
                return {
                  id: item.id,
                  idUser: item.idUser,
                  idSubsidiary: item.idSubsidiary,
                  agentName: item.agentName,
                  agentLogin: item.agentLogin,
                  insertDate: item.insertDate,
                  priority: item.priority
                } as ClientePreferredAgentsModel
              })}
              columnNames={{
                id: labels["id"]?.toUpperCase(),
                idUser: labels.UserId?.toUpperCase(),
                idSubsidiary: labels["subsidiaryId"]?.toUpperCase(),
                agentName: labels.AgentName?.toUpperCase(),
                agentLogin: labels.AgentLogin?.toUpperCase(),
                insertDate: labels.InsertDate?.toUpperCase(),
                priority: labels.Priority?.toUpperCase()
              }}
              columnStyles={{
                id: (entry) => (<div>{entry.value || ''}</div>),
                idUser: (entry) => (<div>{entry.value || ''}</div>),
                idSubsidiary: (entry) => (<div>{entry.value || ''}</div>),
                agentName: (entry) => (<div>{entry.value || ''}</div>),
                agentLogin: (entry) => (<div>{entry.value || ''}</div>),
                insertDate: (entry) => (<div>{entry.value || ''}</div>),
                priority: (entry) => (<div>{entry.value || ''}</div>),
              }}
              onIconClicked={(icon) => {
                if (icon == TPIconTypes.loop || icon == TPIconTypes.refresh) loadPreferredAgents();
              }}
            />
          </div>
        </TPLoadingOverlay>
      </>
    );
  };

export default CustomerPreferredAgentsTab;
